import React, {useEffect, useState} from 'react'
import {Button, Modal, notification} from "antd"
import {CloudUploadOutlined} from '@ant-design/icons'
import {getLocalData, setLocalData} from "../../../../services/StorageServices"
import {exportRequestUpdate} from "../../../../services/api/ExportOrderServices"

function ExportRequestGoogleSheet(props) {
    const {orderID, requestID, storeID, type, status, created, updated, sources, needCharge} = props
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState('')
    const [name, setName] = useState('')
    const [link, setLink] = useState('')
    const [isOpen, setIsOpen] = useState(false)

    const _onShowModal = () => {
        setIsOpen(true)
    }

    const _onRequestExport = async () => {
        setLoading(true)
        setError('')

        try {

            const payload = {
                order_numbers: orderID,
                _ids: requestID,
                store_ids: storeID,
                request_types: type,
                progress_statuses: status,
                created_at: created,
                updated_at: updated,
                sources: sources,
                need_charge: needCharge,
                link: link,
                sheetName: name,
            }
            const response = await exportRequestUpdate(payload)

            const {success, message: error} = response

            if (!success) {
                setLoading(false)

                return setError(error)
            }

            setLoading(false)
            setIsOpen(false)
            notification.success({
                message: 'Export success',
                description: `Please check sheet ${name} in link ${link}.`,
            })
        } catch (e) {
            setLoading(false)
            setError(e.message)
        }
    }

    const _onCancelModal = () => {
        setIsOpen(false)
    }

    const _handleChangeName = (e) => {
        const {value} = e.target

        setName(value)
        setLocalData('export.request.sheetName', value)
    }

    const _handleChangeLink = (e) => {
        const {value} = e.target

        setLink(value)
        setLocalData('export.request.link', value)
    }

    useEffect(() => {
        const link = getLocalData('export.request.link')
        const name = getLocalData('export.request.sheetName')

        setLink(link)
        setName(name)
    }, [])

    return (
        <div className='ExportRequestGoogleSheet'>
            <Button type="primary" icon={<CloudUploadOutlined/>} onClick={_onShowModal}>
                Export Request
            </Button>
            <Modal
                className="ExportRequestUpdate"
                title="Export request update"
                visible={isOpen}
                onCancel={_onCancelModal}
                footer={[
                    <Button key="back" onClick={_onCancelModal}>
                        Close
                    </Button>,
                    <Button key="submit" type="primary" loading={loading} onClick={_onRequestExport}>
                        Export
                    </Button>
                ]}
            >
                <div className="Content">
                    {error && <div className="text-danger mb-3">{error}</div>}
                    <div className="FormItem">
                        <label htmlFor="sheetName">Sheet Name</label>
                        <input type="text" value={name} id="sheetName" placeholder="Input sheet name"
                               onChange={_handleChangeName}/>
                    </div>
                    <div className="FormItem">
                        <label htmlFor="link">Link Google Sheet</label>
                        <input type="text" value={link} id="link" placeholder="Input link google sheet"
                               onChange={_handleChangeLink}/>
                    </div>
                    <div>
                        Share your Google Sheet to this account:
                        <br/>
                        <strong>exporter@platform126.iam.gserviceaccount.com</strong>
                    </div>
                </div>
            </Modal>
        </div>
    )
}

export default ExportRequestGoogleSheet
