import React from 'react'

const CatalogVariantsTableHead = () => {
    return (
        <thead className="ShippingsTableHead">
            <tr>
                <th className="SkuVariantMerchize">SKU Variant Merchize</th>
                <th className="VariantMerchize">Variant Merchize</th>
                <th className="SkuVariantSupplier">SKU Variant Supplier</th>
                <th className="ProductTypeFulfill">Product Type Fulfill</th>
                <th className="VariantSupplier">Variant Supplier</th>
                <th className="Mode">Mode</th>
                <th className="Supplier">Supplier</th>
                <th className="Status">Status</th>
                <th className="UpdateAt">Updated at</th>
                <th className="Action">Action</th>
            </tr>
        </thead>
    )
}

export default CatalogVariantsTableHead