import React, {Component} from 'react'
import PropTypes from 'prop-types'

class OrderItemMapActions extends Component {
    _handleClickRemove = e => {
        e.preventDefault()
        this.props.changeMapPrice()
        this.props._handleRemoveItemMapped()
    }

    _handleClickSelect = e => {
        e.preventDefault()

        this.props._handleToggleModal()
    }

    render() {
        const {isMapped} = this.props

        return (
            <div className="OrderItemMapActions">
                <button className="btn btn-info btn-sm" onClick={this._handleClickSelect}>Select product</button>
                {
                    isMapped &&
                    <button className="btn btn-outline-secondary btn-sm mt-2" onClick={this._handleClickRemove}>Remove
                    </button>
                }
            </div>
        )
    }
}

OrderItemMapActions.propTypes = {
    _handleToggleModal: PropTypes.func.isRequired,
    _handleRemoveItemMapped: PropTypes.func.isRequired,
    isMapped: PropTypes.bool,
}

export default OrderItemMapActions
