import React, {Component} from 'react'
import PropTypes from 'prop-types'
import {CloseOutlined} from '@ant-design/icons'
import {Button} from 'antd'


class PackageSingleItemEditor extends Component {
    _handleChangeInput = (field, obj) => e => {
        const {value} = e.target
        const {onChangeItem, item} = this.props

        Object.assign(obj, {[field]: value})

        onChangeItem(item)
    }

    _handleClickRemove = () => {
        const {item, onRemoveItem} = this.props

        onRemoveItem(item)
    }

    render() {
        const {item, onRemoveItem} = this.props
        const {mockups = {}, artworks = {}, size, product_type, color, quantity} = item
        const hightLight = !!item.itemHasBeenUpdated ? 'ItemHightLight': null

        return (
            <tr data-index={item.item_index} className={`PackageSingleItemEditor ${hightLight}`}>
                <td>
                    {
                        onRemoveItem && <Button icon={<CloseOutlined/>} type='link' className='text-danger'
                                                onClick={this._handleClickRemove}/>
                    }
                </td>
                <td style={{verticalAlign: 'top'}}>
                    <p>
                        Front
                        <input value={mockups.front} onChange={this._handleChangeInput('front', mockups)}
                               placeholder="mockUpFront"
                               className="form-control"/>
                    </p>
                    <p>
                        Back
                        <input value={mockups.back} onChange={this._handleChangeInput('back', mockups)}
                               placeholder="mockUpBack"
                               className="form-control"/>
                    </p>
                </td>
                <td>
                    <p>Front
                        <input value={artworks.front} onChange={this._handleChangeInput('front', artworks)}
                               placeholder="designFront"
                               className="form-control"/>
                    </p>
                    <p>Back
                        <input value={artworks.back} onChange={this._handleChangeInput('back', artworks)}
                               placeholder="designBack"
                               className="form-control"/>
                    </p>
                    <p>Sleeves
                        <input value={artworks.sleeves} onChange={this._handleChangeInput('sleeves', artworks)}
                               placeholder="designSleeves"
                               className="form-control"/>
                    </p>
                    <p>Hood
                        <input value={artworks.hood} onChange={this._handleChangeInput('hood', artworks)}
                               placeholder="designHood"
                               className="form-control"/>
                    </p>
                </td>
                <td style={{verticalAlign: 'top'}}>
                    <p>Size
                        <input value={size} onChange={this._handleChangeInput('size', item)} placeholder="size"
                               className="form-control"/>
                    </p>
                    <p>Color
                        <input value={color} onChange={this._handleChangeInput('color', item)} placeholder="color"
                               className="form-control"/>
                    </p>
                    <p>Type
                        <input value={product_type} onChange={this._handleChangeInput('product_type', item)}
                               placeholder="type"
                               className="form-control"/>
                    </p>
                </td>
                <td style={{verticalAlign: 'top'}}>
                    <input value={quantity} onChange={this._handleChangeInput('quantity', item)} placeholder="quantity"
                           className="form-control" type="number" min={1}/>
                </td>
            </tr>
        )
    }
}

PackageSingleItemEditor.propTypes = {
    item: PropTypes.object.isRequired,
    onChangeItem: PropTypes.func.isRequired
}

export default PackageSingleItemEditor
