import React, {Component} from 'react'
import {Link} from 'react-router-dom'
import PropTypes from 'prop-types'
import {settings} from '../../platforms/statistics/settings'
import {getPlatformDetails} from '../../../services/api/PlatformsServices'

class PlatformSettingsPage extends Component {
    state = {
        platform: {},
    }

    componentDidMount() {
        return this._fetchPlatform()
    }

    _changeLoading = (loadingState, errState) => {
        const {loading, err} = this.state
        return {
            loading: {
                ...loading,
                ...loadingState
            },
            err: (errState) ? err : {
                ...err,
                ...errState
            }
        }
    }

    _fetchPlatform = async () => {
        this.setState({...this._changeLoading({fetchPlatform: true}, {fetchPlatform: null})})

        try {
            const {platformId} = this.props

            const {success, data, message} = await getPlatformDetails(platformId)
            if (success) return this.setState({
                ...this._changeLoading({fetchPlatform: false}),
                platform: data,
            })

            return this.setState({
                ...this._changeLoading({fetchPlatform: false}, {fetchPlatform: message})
            })
        } catch (e) {
            this.setState({...this._changeLoading({fetchPlatform: false}, {fetchPlatform: e.message || e})})
        }
    }

    _getUrl = (index) => {
        const {platformId} = this.props
        return settings[index].location(platformId)
    }

    render() {
        const {platform} = this.state

        return (
            <div className="PlatformSettingsPage">
                <h3><Link to={'/a/platforms'}><i className="fas fa-chevron-left mr-3"/></Link>{platform.name}</h3>

                <div className="SectionInner">
                    <div className="SettingsWrapper row">
                        {settings.map((setting, index) =>
                            <Link
                                to={this._getUrl(index)}
                                key={index}>
                                <div className="SettingWrapper col-12">
                                    <div className="SettingInner d-flex">
                                        <div className="IconWrapper">
                                            <i className={setting.icon}/>
                                        </div>
                                        <div className="Info">
                                            <h6 className="Title">{setting.title}</h6>
                                            <div className="Description">{setting.description}</div>
                                        </div>
                                    </div>
                                </div>
                            </Link>
                        )}
                    </div>
                </div>
            </div>
        )
    }
}

PlatformSettingsPage.propTypes = {
    platformId: PropTypes.string.isRequired,
}

export default PlatformSettingsPage
