import React, {Component} from 'react'
import {getListHistories} from "../../../../services/api/OrdersServices"
import PropTypes from 'prop-types'
import OrderHistoriesItem from "./OrderHistoriesItem"
import broker from "../../OrderBroker"
import {ReactComponent as ArrowDown} from "../../../../statics/assets/icons/arrowDown.svg"
import {Button} from 'antd'

class OrderHistoriesList extends Component {
    state = {
        histories: [],
        error: "",
        showHistories: [],
        isShowLimit: false,
        limit: 50
    }

    _subscription = null

    componentDidMount() {
        this._subscription = broker.subscribe("ORDER_REFRESHED_ORDER", this._refreshHistories)

        this._fetchListHistories()
    }

    componentWillUnmount() {
        broker.unsubscribe(this._subscription)
    }

    _refreshHistories = () => {
        console.log('Refreshing histories...')
        this._fetchListHistories()
    }

    _fetchListHistories = async () => {
        try {
            const {orderId} = this.props
            const {data, success, message} = await getListHistories(orderId)

            if (!success) {
                return this.setState({
                    error: message,
                })
            }

            this.setState({
                histories: data,
                showHistories: data,
                error: "",
            })
        } catch (e) {
            this.setState({
                error: e.message,
            })
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.keyHistory !== this.props.keyHistory) {
            const {histories} = this.state
            const itemSelect = {
                "All": "",
                "Order push": "push",
                "Order tracking": "tracking",
                "Order invoice": "invoice"
            }
            const isFilterSearch = Object.keys(itemSelect).some(item => item === this.props.keyHistory)
            if (this.props.keyHistory !== '') {
                const newData = histories.filter(item => item.event.match(!isFilterSearch ? this.props.keyHistory : itemSelect[this.props.keyHistory]))

                this.setState({
                    showHistories: newData
                })
            } else {
                this.setState({
                    showHistories: histories
                })
            }
        }

        if (prevProps.fetchHistoryCounter !== this.props.fetchHistoryCounter) {
            setTimeout(this._fetchListHistories, 3000)
        }
    }

    _handleChangeShow = () => {
        this.setState(preState => ({
            isShowLimit: (preState.limit + 50) < preState.showHistories.length ? true : false,
            limit: preState.limit + 50
        }))
    }

    render() {
        const {error, showHistories, isShowLimit, limit} = this.state
        const newShowHistories = showHistories.filter((item, index) => index < limit)

        return (
            <div className="OrderHistoriesList">
                {
                    showHistories.length <= 0 ?
                        <i>No results</i>
                        : showHistories.length < limit || isShowLimit ?
                        showHistories.map(history => {
                            return <OrderHistoriesItem key={history._id} history={history}/>
                        }) : newShowHistories.map(history => {
                            return <OrderHistoriesItem key={history._id} history={history}/>
                        })
                }
                <div className="ActionShow d-flex justify-content-center">
                    {showHistories.length > limit && !isShowLimit &&
                    <Button className='d-flex align-items-center px-2' onClick={this._handleChangeShow}>
                        <ArrowDown width={12}/> <span className='ml-1'>Show more</span>
                    </Button>
                    }

                </div>

                {
                    !!error && <div className="text-danger mt-3">{error}</div>
                }
            </div>
        )
    }
}

OrderHistoriesList.propTypes = {
    orderId: PropTypes.string.isRequired,
    keyHistory: PropTypes.string
}

export default OrderHistoriesList
