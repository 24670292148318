import React, {useEffect, useState} from 'react'
import {Input} from "antd"
import {
    SearchOutlined,
} from '@ant-design/icons'

const InputSearch = (props) => {
    const {name, value, label, regex = /\s|,\s|,|\n/, loading} = props
    const [valueInput, _setValue] = useState(value.length > 0 ? value.join(',') : '')

    const _handleSetValue = () => {
        _setValue('')
    }

    const _convertString = (string) => {
        const parsed = string.split(regex)

        return !!parsed && parsed.length > 0 && parsed.filter(item => item !== "")
    }


    const _handleChangeInput = (e) => {
        const {name, value} = e.target
        const searchValue = _convertString(value)

        _setValue(value)

        props.onChange(name, searchValue)
    }

    useEffect(() => {
        props.value.length === 0 && _handleSetValue()
    }, [props.value])

    return (
        <div className="InputSearch">
            <div className="font-weight-500 LabelFilter mb-1">{label}</div>

            <Input
                name={name}
                value={valueInput}
                placeholder='Enter to search...'
                onChange={_handleChangeInput}
                style={{width: "100%"}}
                disabled={loading}
            />

            {
                value.length === 0 && <span className="icon">
                    <SearchOutlined/>
                </span>
            }
        </div>
    )
}

export default InputSearch
