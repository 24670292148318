import React, { useState } from 'react'
import moment from "moment"
import { Tooltip, Popover } from "antd"
import { Link } from "react-router-dom"
import StatusColumn from "./StatusColumn"
import NotePackage from "./NotePackage"
import { getLocalData } from '../../../../services/StorageServices'
import Truncate from '../../../../helpers/common/formatNote'


const TableRow = (props) => {
    const { item, indexRow, listSelected, onChangeSelectedPackage, allowedUpdateTags } = props
    const {
        order, supplier, factory_info, package_status,
        pushed_type, production_overtime, did_actions = [],
        box_tracking_info, shipping_carrier, last_mile_tracking_info,
    } = { ...item }
    const { trackingNumber, providerSite } = { ...last_mile_tracking_info }
    const address = order ? order.address : {}
    const [visible, setVisible] = useState(false)

    const _convertTime = (string, field) => {
        if (!!string) {
            if (shipping_carrier && shipping_carrier === 'HW YUN' && field === 'lastUpdate') {
                return `${moment(string).utcOffset(0).format('HH:mm DD/MM/YYYY')} (location time)`
            }
            return moment(string).format('HH:mm DD/MM/YYYY')
        } else {
            return ''
        }
    }

    const _detectChecked = () => {
        return listSelected.includes(item._id)
    }

    const _handleChange = () => {
        const isChecked = _detectChecked()

        if (typeof onChangeSelectedPackage === 'function') {
            onChangeSelectedPackage(item._id, !isChecked)
        }
    }

    const isTrackingModified = Array.isArray(did_actions) && did_actions.includes('tracking_modified')

    const _onToggleVisible = (visible) => {
        setVisible(visible)
    }
    const permissionsData = !!getLocalData('permissions') ? Object.keys(getLocalData('permissions')) : []
    const changeStatusPermission = permissionsData.includes('ffm_package_change_status')
    const updateNotePermission = permissionsData.includes('ffm_package_update_note')

    return (
        <tr>
            {(!!changeStatusPermission || !!updateNotePermission) &&
                <td>
                    <input type="checkbox" checked={_detectChecked()} onChange={_handleChange} />
                </td>
            }
            <td className="Info">
                <div className="PackageName">
                    <strong>
                        <Link to={`/a/orders-supplier/${item._id}`}>{item.name}</Link>
                    </strong>
                </div>
                <div className="StoreName">
                    <Tooltip title="Store ID" placement='right'>{order && order.namespace ? order.namespace : ''}</Tooltip>
                </div>
                <div className="OrderReference">
                    <Tooltip title="Order Reference ID" placement='right'>
                        {factory_info !== undefined && factory_info.order_reference_id}
                    </Tooltip>
                </div>
                <div className="PackagePushTime">
                    <Tooltip title="Date Push At" placement='right'>
                        {_convertTime(item.pushed_at)}
                    </Tooltip>
                </div>
                <div className="CustomReference">
                    {
                        factory_info !== undefined &&
                        !!factory_info.metadata &&
                        (factory_info.metadata.customer_reference ||
                            factory_info.metadata.CustomerPo || factory_info.metadata.mc_order_id || '')
                    }
                </div>
            </td>
            <td>{(!!address && !!address.country_code && address.country_code) || ''}</td>
            <td>
                {!!supplier && supplier.name ? supplier.name : ''}
                <p className="text-muted small">{item.shipping_carrier || 'Other'}</p>
            </td>
            <td>
                {!!package_status &&
                    <StatusColumn
                        status={package_status || ''}
                        metaData={factory_info.metadata || ''}
                        isShowReject={true}
                        isShowTags={true}
                        did_actions={did_actions}
                        allowedUpdateTags={allowedUpdateTags}
                    />
                }
                {did_actions.length > 0 &&
                    <div className='Tags'>
                        {did_actions.map(i => (
                            <span className='Tag badge badge-secondary mr-1' style={{background:'rgb(255, 88, 88)'}}>{allowedUpdateTags[i]}</span>
                        ))}
                    </div>
                }
            </td>
            <td>{(factory_info !== undefined && factory_info.status !== null) &&
                <StatusColumn status={factory_info.status || ''} metaData={factory_info.metadata || ''}
                    orderNote={factory_info.order_status_note} />}
            </td>
            <td>{production_overtime}</td>
            <td>{pushed_type || ''}</td>
            <td>
                {(item.hasOwnProperty('tracking_number') && item.tracking_number !== '') && (
                    <a rel="noopener noreferrer" target="_blank"
                        href={item.tracking_url}>{item.tracking_number || ''}</a>
                )}
                <div>{item.tracking_company || ''}</div>
                <div>{item.tracking_status || ''}</div>
                <div>{_convertTime(item.tracking_updated_at)}</div>
                {
                    isTrackingModified &&
                    <div><span className='tracking-modified'>Modified</span></div>
                }
            </td>
            <td className="ShippingMethod">
                {
                    item.interior_shipment_status &&
                    <div><span>Status: </span>{item.interior_shipment_status}</div>
                }

                {
                    item.interior_progress_at &&
                    <div><span>Progress at: </span>{_convertTime(item.interior_progress_at)}</div>
                }

                {
                    item.hasOwnProperty('sub_tracking_real_last_node') && <>
                        {
                            item.sub_tracking_real_last_node.description &&
                            <div>
                                <Popover
                                    placement="topLeft"
                                    className="CustomPopover"
                                    content={item.sub_tracking_note}
                                    title={`Note`}
                                    trigger="click"
                                    visible={visible}
                                    onVisibleChange={_onToggleVisible}
                                >
                                    <span className='ShowMore'>Last note: </span>
                                </Popover>
                                {item.sub_tracking_real_last_node.description}
                            </div>
                        }

                        {
                            item.sub_tracking_real_last_node.eventTime &&
                            <div>
                                <span>Last update: </span>{_convertTime(item.sub_tracking_real_last_node.eventTime, 'lastUpdate')}
                            </div>

                        }

                        {
                            item.sub_tracking_real_last_node.location &&
                            <div><span>Location: </span>{item.sub_tracking_real_last_node.location}</div>
                        }
                        {trackingNumber && shipping_carrier === 'HW YUN' &&
                            <div><span>Last mile: </span>
                                <a href={providerSite ? providerSite : undefined} target='_blank'>{trackingNumber}</a>
                            </div>
                        }
                    </>
                }

                {
                    item.hasOwnProperty('box_tracking_info') && <>
                        {(item.box_tracking_info.hasOwnProperty('tracking_number') && item.box_tracking_info.tracking_number !== '') && (
                            <div className="NextBoxTrakingInfoItem">
                                <span>Tracking: </span>
                                <a rel="noopener noreferrer" target="_blank" href={item.box_tracking_info.tracking_url}>
                                    {item.box_tracking_info.tracking_number || ''}
                                </a>
                            </div>
                        )}
                        {
                            item.box_tracking_info.factory_sync_at && (
                                <div className="NextBoxTrakingInfoItem">
                                    <span>Ship date: </span>{_convertTime(item.box_tracking_info.factory_sync_at)}
                                </div>
                            )
                        }
                        {
                            item.box_tracking_info.box_id && (
                                <div className="NextBoxTrakingInfoItem">
                                    <span>Box id: </span>{item.box_tracking_info.box_id}
                                </div>
                            )
                        }
                        {
                            item.box_tracking_info.box_code && (
                                <div className="NextBoxTrakingInfoItem">
                                    <span>Box code: </span>{item.box_tracking_info.box_code}
                                </div>
                            )
                        }
                        {!!box_tracking_info.tracking_carrier &&
                            <div>
                                <span>Carrier: </span>{box_tracking_info.tracking_carrier}
                            </div>
                        }
                    </>
                }
            </td>
            <td className="Note">
                <div className="NoteOrder">
                    {item.order && item.order.note ?
                        <Truncate
                            string={item.order.note}
                            length={20}
                            text={'Note order'}
                        />
                        : ''
                    }
                </div>
                <NotePackage fulfillmentId={item._id} note={item.note} indexRow={indexRow} />
            </td>
        </tr>
    )
}

export default TableRow
