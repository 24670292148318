import React, {Component} from 'react'
import PropTypes from 'prop-types'

class HeadAddress extends Component {
    render() {
        const {addressType} = this.props

        return (
            <thead className="HeadAddress">
            {
                addressType === 'Normal' ? <tr>
                        <th>Marketplace</th>
                        <th>Order Number</th>
                        <th>Name</th>
                        <th>Address 1</th>
                        <th>Address 2</th>
                        <th>City</th>
                        <th>State</th>
                        <th>Country</th>
                        <th>Phone</th>
                        <th>Postal Code</th>
                        <th>QShip</th>
                    </tr> :
                    <tr>
                        <th>Marketplace</th>
                        <th>Order number</th>
                        <th>Name</th>
                        <th>Address</th>
                        <th>Province</th>
                        <th>District</th>
                        <th>Ward</th>
                        <th>Phone</th>
                    </tr>
            }
            </thead>
        )
    }
}

HeadAddress.propTypes = {
    addressType: PropTypes.string.isRequired
}

export default HeadAddress
