import React, {Component} from 'react'
import {Switch, Redirect, Route, Link} from 'react-router-dom'
import {settings} from '../../../statics/settings'
import SettingStorageContainer from '../../setting-storage/components/SettingStorageContainer'
import ListingUsersPageContainer from '../../users/list-all-users/components/ListingUsersPageContainer'
import SettingAutoContainer from "../../setting-auto/components/SettingAutoContainer"
import FastProductionRulesContainer from '../../setting-fast-production-rules/components/FastProductionRulesContainer'
import { getLocalData } from '../../../services/StorageServices'

class Setting extends Component {
    _getMenuTitle = () => {
        const {pathname} = this.props.location
        const path = pathname.endsWith('/') ? pathname.slice(0, -1) : pathname
        const currentMenu = settings.find(setting => setting.location === path)

        if (currentMenu) return currentMenu.title

        return 'Setting'
    }

    render() {
        const permissionsData = !!getLocalData('permissions') ? Object.keys(getLocalData('permissions')) : []
        const {pathname} = this.props.location
        const path = pathname.endsWith('/') ? pathname.slice(0, -1) : pathname
        const currentMenu = settings.find(setting => setting.location === path)
        const canCreateFastProductionRule = permissionsData.includes('ffm_fast_production_rule_create')

        return (
            <div className="Setting">
                <div className="container-fluid">
                    <div className="BackWrapper">
                        <Link className="nav-link BackButton" to="/a/settings">
                            <i className="fas fa-chevron-left"/>
                            <span>Settings</span>
                        </Link>
                    </div>
                    <div className="MenuTitle d-flex justify-content-between align-items-center">
                        {this._getMenuTitle()}
                        {!!currentMenu && currentMenu.title === 'Fast Production Rule' && canCreateFastProductionRule &&
                            <Link to='/a/settings/fast-production/create' className='ant-btn ant-btn-primary px-4 rounded btn-primary'>New Rule</Link>
                        }
                    </div>
                    <Switch>
                        {permissionsData.includes('ffm_setting_storage_read') &&
                            <Route path="/a/settings/storage" component={SettingStorageContainer}/>
                        }
                        {permissionsData.includes('ffm_setting_users_read') &&
                            <Route path={`/a/settings/users`} component={ListingUsersPageContainer}/>
                        }
                        {permissionsData.includes('ffm_setting_auto_read') &&
                            <Route path={`/a/settings/auto`} component={SettingAutoContainer}/>
                        }
                        {permissionsData.includes('ffm_fast_production_rule_list') &&
                            <Route path={`/a/settings/fast-production`} component={FastProductionRulesContainer}/>
                        }
                        <Redirect to="/a/settings"/>
                    </Switch>
                </div>
            </div>
        )
    }
}

export default Setting
