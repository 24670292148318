import React, {useContext, useState} from 'react'
import {Modal, Progress, Input} from "antd"
import OrderPartnersContext from "../../context/OrderPartnersContext"
import {ExclamationCircleOutlined, CheckCircleOutlined} from '@ant-design/icons'
import { updateNotePackage } from '../../../../services/api/OrdersServices'

const {TextArea} = Input

const UpdatePackageNote =(props)=>{
    const {visible, onCancel, warning } = props
    const {listSelected, packageLists, onChangeListOrder, onChangePackageNotes} = useContext(OrderPartnersContext)
    const [buttonLoading, setButtonLoading] = useState(false)
    const [listProcessing, setListProcessing] = useState(packageLists.filter(item => listSelected.includes(item._id)))
    const [newNote, setNewNote] = useState(listProcessing[0].note)
    const [listSuccess, setListSuccess] = useState([])
    const [listError, setListError] = useState([])

    const _handleChangeNote= (e)=>{
        const {value} = e.target
        setNewNote(value)
    }

    const _onChangePackageNote = async (id) => {
        try {
            const data = {
                note: newNote
            }

            const response = await updateNotePackage(id, data)

            const {success, message} = response

            if (!success) {
                return {success: success, message: message}
            }

            return {success: success}
        } catch (e) {
            return {success: false, message: e.message}
        }
    }

    const _handleOk = async()=>{
        setButtonLoading(true)
        let processed = []

        for (const item of listProcessing) {
            const selectedItem= packageLists.filter(li =>li._id===item._id)
            const response = await _onChangePackageNote(item._id)
            const {success, message} = response

            if(success!==true){
                processed= [...processed,{...item, success:success, error: message}]

            }
            else{
                const index= packageLists.indexOf(selectedItem[0])
                await _onChangeNote(index,newNote)
                processed= [...processed,{...item, success:success}]
            }
        }
        
        let newListSuccess = []
        let newListError = []
    
        processed.map(process => {
            if (process.success === true) {
                return newListSuccess.push(process)
            }
    
            if (process.error.length > 0) {
                return newListError.push(process)
            }
        })
        setListSuccess(newListSuccess)
        setListError(newListError)
        setListProcessing(processed)
        setButtonLoading(false)
    }

    const _onChangeNote = (index,value) => {
        if (typeof onChangeListOrder === 'function') {
            onChangePackageNotes(index, value)
        }
    }

    return (
        <Modal
            className="ChangeStatusPackageModal"
            visible={visible}
            title="Update package note"
            onOk={_handleOk}
            okText={"Save note"}
            onCancel={onCancel}
            confirmLoading={buttonLoading}
        >
            {
                warning!=="" &&
                <label style={{color:"#faad14"}} ><ExclamationCircleOutlined/> {warning}</label>
            }
            <div className="FormItem"></div>
            <label><b>Package Note:</b></label>
            <TextArea
                rows={5}
                value={newNote}
                onChange={_handleChangeNote}
                placeholder="Enter your note package..."
            />
            <div/>
            <h3>List processing <span>{listSelected.length}</span> items</h3>
            {
                (listSuccess.length > 0 || listError.length > 0) &&
                <div className="Results">
                    <div className="ResultSuccess">
                        Success: <span>{listSuccess.length}</span> items
                    </div>
                    <div className="ResultError">
                        Error: <span>{listError.length}</span> items
                    </div>
                </div>
            }



            <div className="ListProcessing">
                <ul>
                    {
                        !!listProcessing && listProcessing.length > 0 && listProcessing.map(item => (
                                <li key={item._id}>
                                    <span>{item.name}</span>
                                    {
                                        item.success===false && !!item.error && 
                                        <span className="Error"><ExclamationCircleOutlined/> {item.error}</span>
                                    }
                                    {
                                        item.success===true &&
                                        <span className="Success"><CheckCircleOutlined/> Change success</span>
                                    }
                                    <Progress
                                        percent={100}
                                        size="small"
                                        status={`${(item.success === true) ? 'success' : ((item.success === false) ? 'exception' : 'active')}`}
                                        showInfo={false}
                                    />
                                </li>
                            )
                        )
                    }
                </ul>
            </div>
        </Modal>
    )
}

export default UpdatePackageNote