import React, {Component} from 'react'
import PropTypes from 'prop-types'
import Link from "react-router-dom/es/Link"
import formatCurrency from "../../../../helpers/common/formatCurrency"
import imageDefault from "../../../../helpers/common/imageDefault"
import {getProductImages} from "../../../../services/api/ProductsServices"

class OrderItemMapDetails extends Component {
    state = {
        image: {}
    }
    _fetchImage = async () => {
        try {
            const {product} = this.props
            const response = await getProductImages(product._id)

            const {success, message: error, data} = response

            if (!success) {
                console.log(error)
            }

            this.setState({
                image: data[0]
            })
        } catch (e) {
            console.log(e.message)
        }
    }

    componentDidMount() {
        this._fetchImage()
    }

    render() {
        const {image} = this.state
        const {product, variant} = this.props
        const {currency} = product
        const {base_cost, additional_price, options, sku} = variant
        const imageObject = Object.assign({}, image)

        return (
            <td className="OrderItemMapDetails">
                <div className="row">
                    <div className="col-4">
                        <div className="ThumbnailWrapper position-relative">
                            {
                                imageObject.hasOwnProperty("url") &&
                                <img
                                    src={imageObject.url}
                                    alt="Product line thumbnail"
                                    onError={(e) => {
                                        e.target.onerror = null;
                                        e.target.src = imageDefault
                                    }}
                                />
                            }
                        </div>
                    </div>

                    <div className="col-8">
                        <Link to={`/a/products/${product._id}`} className="mb-2 d-block">{product.title}</Link>

                        <div className="d-flex mb-1">
                            <span className="Label font-weight-500">Price:</span>
                            <span className="Value">{formatCurrency(currency, base_cost)}</span>
                        </div>

                        {
                            (additional_price !== undefined && additional_price > 0) &&
                            <div className="d-flex mb-1">
                                <span className="Label font-weight-500">Additional Price:</span>
                                <span className="Value">{formatCurrency(currency, additional_price)}</span>
                            </div>
                        }

                        {
                            options.map(option => {
                                const {attribute, name: optionName} = option
                                const {name: attributeName} = attribute

                                return (
                                    <div className="d-flex mb-1" key={option._id}>
                                        <span className="Label font-weight-500">{attributeName}:</span>
                                        <span className="Value">{optionName}</span>
                                    </div>
                                )
                            })
                        }

                        <div className="d-flex mb-1">
                            <span className="Label font-weight-500">SKU:</span>
                            <span className="Value">{sku}</span>
                        </div>
                    </div>
                </div>
            </td>
        )
    }
}

OrderItemMapDetails.propTypes = {
    product: PropTypes.object,
    variant: PropTypes.object
}

export default OrderItemMapDetails
