import createApiServices from './createApiServices'

const prefixURL = process.env.NODE_ENV === 'production' ? '/api/order' : 'https://fulfillment-staging.merchize.com/api/order'
const api = createApiServices({baseUrl: prefixURL})

export const createDiscount = (data) => {
    return api.makeAuthRequest({
        url: '/discounts',
        method: 'POST',
        data: data,
    })
}

export const updateDiscount = (id, data) => {
    return api.makeAuthRequest({
        url: `/discounts/${id}`,
        method: 'PUT',
        data: data,
    })
}

export const getDetailDiscount = (id) => {
    return api.makeAuthRequest({
        url: `/discounts/${id}`,
        method: 'GET'
    })
}

export const getHistoryDiscount = (id, query) => {
    return api.makeAuthRequest({
        url: `/discounts/${id}/histories?${query}`,
        method: 'GET'
    })
}

