import React, {Component} from 'react'
import PropTypes from 'prop-types'
import {Modal} from "reactstrap"
import PackageCheckDetailInner from './PackageCheckDetailInner'

class PackageCheckDetail extends Component {
    render() {
        const {isOpen} = this.props

        return (
            <div className="PackageCheckDetail">
                <Modal className="PackageCheckDetailModal" size="lg" backdrop="static" isOpen={isOpen}>
                    <PackageCheckDetailInner {...this.props}/>
                </Modal>
            </div>
        )
    }
}

PackageCheckDetail.propTypes = {
    isOpen: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
}


export default PackageCheckDetail
