import React, {Component} from 'react'
import PropTypes from 'prop-types'

class WebhookType extends Component {
    render() {
        const {webhook} = this.props
        const {type} = webhook
        return (
            <td className="WebhookType">
                {type}
            </td>
        )
    }
}

WebhookType.propTypes = {
    webhook: PropTypes.object.isRequired,
}

export default WebhookType
