import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Popover } from 'antd'

class ProductDescription extends Component {
    state = {
        visible: false,
    }

    handleVisibleChange = visible => {
        this.setState({ visible });
    };

    render() {
        const { description } = this.props
        return (
            <td className="ProductDescription">
                 {!!description &&
                     <Popover
                        content={description}
                        title="Description"
                        overlayStyle={{
                            width: "20vw",
                        }}
                        placement="topRight"
                        trigger="click"
                        visible={this.state.visible}
                        onVisibleChange={this.handleVisibleChange}
                    >
                        <span className="cursor-pointer">
                            {`${description.substring(0, 5)}...`}
                        </span>
                </Popover>}
            </td>
        )
    }
}

ProductDescription.propTypes = {
    description: PropTypes.string
}

export default ProductDescription
