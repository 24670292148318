import { Input } from 'antd'
import React, {Component} from 'react'
import NumberFormat from 'react-number-format'

class RefundChargeValueTableRow extends Component {

    _handleChangeValue = (e) => {
        const {onChangeValues, item, index} = this.props
        const {name, value} = e.target

        const newItem = Object.assign(item, {'value': value})

        onChangeValues(newItem, index)
    }

    render() {
        const {item} = this.props
        const {orderCode, value} = item

        return (
            <tr className='RefundChargeValueTableRow'>
                <td className='orderCode'>{orderCode}</td>
                <td>
                    <NumberFormat
                        thousandSeparator={true}
                        decimalSeparator='.'
                        decimalScale={2}
                        prefix=""
                        value={item.value}
                        onChange={this._handleChangeValue}
                    />
                </td>
            </tr>
        )
    }
}

export default RefundChargeValueTableRow