import React from 'react'
import DocTitleByStore from "../../dashboard/shared/DocTitleByStore"
import ReturnPage from '../../shared/ReturnPage'
import ShippingEditPage from './ShippingFeeEditPage'

const ShippingFeeEditPageContainer = (props) => {

    const idCost = props.match.params.id

    return (
        <div className="ShippingFeeEditPageContainer">
            <ReturnPage url="/a/shipping-fee" title="Shipping fee" />
            <DocTitleByStore title="Update shipping fee" />
            <ShippingEditPage idCost={idCost}/>
        </div>
    )
}

export default ShippingFeeEditPageContainer