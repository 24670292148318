import createApiServices from "./createApiServices"

const baseUrl = process.env.NODE_ENV === 'production' ? '/api/platform' : 'https://fulfillment-staging.merchize.com/api/platform'
const api = createApiServices({baseUrl})

export const getNotifications = (limit, page, payload) => {
    return api.makeAuthRequest({
        url: `/notifications?limit=${limit}&page=${page}`,
        method: 'POST',
        data: payload
    })
}

export const sendNotification = (id) => {
    return api.makeAuthRequest({
        url: `/notifications/send/${id}`,
        method: 'POST',
    })
}

export const sendBulkNotification = (ids) => {
    return api.makeAuthRequest({
        url: `/notifications/bulk/send`,
        method: 'POST',
        data: ids
    })
}
