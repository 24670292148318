import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Link from "react-router-dom/es/Link"
import OrderItemMapActions from "./OrderItemMapActions"
import formatCurrency from "../../../../../../helpers/common/formatCurrency"
import imageDefault from "../../../../../../helpers/common/imageDefault"
import { Button, message, Tooltip } from "antd"
import { addMappingRule } from "../../../../../../services/api/ArtworksServices"
import broker from "../../../../OrderBroker"
import { updateBaseCostMapping, updateCustomCostItemMapping } from '../../../../../../services/api/ProductsServices'
import NumberFormat from "react-number-format"
import { ReactComponent as PriceInfo } from "../../../../../../statics/assets/images/icons8-info.svg"
import { getLocalData } from '../../../../../../services/StorageServices'
import { parseJwt } from '../../../../../../helpers/parseToken'

class OrderItemMapDetails extends Component {
    state = {
        loading: false,
        showEditPrice: false,
        mapPrice: 0,
        newMapPrice: 0
    }


    limitInputValue = ({ floatValue, formattedValue }) => {
        if (floatValue == null) return formattedValue === ""

        return (floatValue >= 0)
    }

    handleShowEditPrice = () => {
        const { base_cost, custom_base_cost, price } = this.props.productLineVariant
        const priceStore = price ? price.price : ''
        this.setState({
            showEditPrice: true,
            newMapPrice: !!custom_base_cost ? custom_base_cost : !!priceStore ? priceStore : base_cost
        })
    }

    handleHiddenEditPrice = () => {
        setTimeout(() => {
            this.setState({ showEditPrice: false })
        }, 200)
    }

    handleChangePrice = (e) => {
        const { value } = e.target
        this.setState({ newMapPrice: value })
    }

    savePrice = async () => {
        const { orderItemId, _fetchOrderItems, order } = this.props
        const {_id: orderId, shipping_plan = {}} = Object.assign({}, order)
        const {slug = ''} = shipping_plan

        const payload = {
            base_cost: this.state.newMapPrice
        }

        try {
            const response = slug === "express" ? await updateCustomCostItemMapping(orderId, orderItemId, payload) : await updateBaseCostMapping(orderItemId, payload)
            const { data, success, message: err } = response
            if (success) {
                this.setState({ mapPrice: data.custom_base_cost })
                message.success("Update Base Cost is Success!!!")
            } else { message.error(err) }
        } catch (error) {
            message.error(error)
        }
        await _fetchOrderItems()
        this.setState({ showEditPrice: false })
    }

    _onToggleMappingRule = async () => {
        this.setState({
            loading: true
        })

        try {
            const { mapping_rule, orderItemId } = this.props
            const payload = {
                order_item_id: orderItemId
            }

            if (mapping_rule) {
                Object.assign(payload, { mapping_rule: mapping_rule._id })
            }

            const response = await addMappingRule(payload)

            const { success, message: error } = response

            if (!success) {
                this.setState({
                    loading: false
                })

                return message.error(error)
            }

            this.setState({
                loading: false
            }, () => {
                message.success('Created rule success')
                broker.publish("ORDER_REFRESH_ORDER")
                broker.publish('PRODUCT_MAP_UPDATED')
            })
        } catch (e) {
            this.setState({
                loading: false
            })

            message.error(e.message)
        }
    }

    _changeMapPrice = () => {
        this.setState({
            mapPrice: 0,
            newMapPrice: 0
        })
    }

    render() {
        const { loading, newMapPrice } = this.state
        const {
            productLineVariant,
            _handleToggleModal,
            _handleRemoveItemMapped,
            isMapped,
            outOfStock,
            mapping_rule,
            artworkType,
            fulfillmentStatus,
            orderType,
            order,
            listSuppliers,
        } = this.props
    
        const {shipping_plan = {}} = Object.assign({}, order)
        const {slug = ''} = shipping_plan
        const { product, base_cost, options, image, additional_price, sku, currency, custom_base_cost, price,  supplier = '' } = productLineVariant
        const priceStore = price ? price.price : ''
        const imageObject = Object.assign({}, image)

        const permissionsData = getLocalData('permissions') ? getLocalData('permissions') : {}
        const supplierVariant = listSuppliers.find(sup => sup._id === supplier) || {}
        const {sku_prefix = ''} = Object.assign({}, supplierVariant)
        const isAllowEditPrice = ['1C', 'TCVN', 'MCVN'].includes(sku_prefix)

        return (
            <div className="OrderItemMapDetails">
                {isMapped ? (
                    <div className="row">
                        <div className="col-4">
                            <div className="ThumbnailWrapper position-relative">
                                {imageObject.hasOwnProperty("url") && (
                                    <img
                                        src={imageObject.url}
                                        alt="Product line thumbnail"
                                        onError={(e) => {
                                            e.target.onerror = null
                                            e.target.src = imageDefault
                                        }}
                                    />
                                )}

                                {
                                    permissionsData.hasOwnProperty('ffm_order_detail_mapping') &&
                                    <div className="position-absolute Actions">
                                        <OrderItemMapActions
                                            _handleToggleModal={_handleToggleModal}
                                            _handleRemoveItemMapped={_handleRemoveItemMapped}
                                            isMapped={isMapped}
                                            changeMapPrice={this._changeMapPrice}
                                        />
                                    </div>
                                }

                            </div>
                        </div>

                        <div className="col-8">
                            <Link
                                to={`/a/products/${product._id}`}
                                className="mb-2 d-block"
                            >
                                {product.title}
                            </Link>

                            <div className="d-flex mb-1">
                                <span className="Label font-weight-500">Price:</span>
                                {this.state.showEditPrice === false ? (
                                    <div
                                        className="PriceValue"
                                    >
                                        <span className="Value">
                                            {(orderType === "FBA" || slug === "express") ? formatCurrency(currency, custom_base_cost || priceStore || base_cost || 0) : formatCurrency(currency, priceStore || base_cost || 0)}
                                        </span>
                                        {(fulfillmentStatus === "unfulfilled" && (orderType === "FBA" || (slug === "express" && isAllowEditPrice))) && (
                                            <span
                                                className="text-danger cursor-pointer px-2"
                                                title="Edit Price"
                                            >
                                                <i
                                                    className="ti-pencil"
                                                    onClick={this.handleShowEditPrice}
                                                ></i>
                                            </span>
                                        )}
                                        {
                                            (orderType === 'FBA' || slug === "express") && (custom_base_cost === undefined || custom_base_cost === null || custom_base_cost === '') && priceStore ?
                                                <Tooltip title="Price apply for seller">
                                                    <span><PriceInfo width="20px" height="20px" style={{ position: 'relative', top: '4px', marginLeft: '4px' }} /></span>
                                                </Tooltip> : ''
                                        }

                                        {
                                            (orderType !== 'FBA' && slug !== "express") && priceStore ?
                                                <Tooltip title="Price apply for seller">
                                                    <span><PriceInfo width="20px" height="20px" style={{ position: 'relative', top: '4px', marginLeft: '4px' }} /></span>
                                                </Tooltip> : ''
                                        }

                                    </div>) : (
                                    <div className="EditPrice" >
                                        <NumberFormat
                                            className="ant-input ant-input-sm InputPrice"
                                            onChange={this.handleChangePrice}
                                            value={newMapPrice || 0}
                                            allowNegative={false}
                                            isAllowed={this.limitInputValue}
                                            style={{ width: "50%" }}
                                            onBlur={this.handleHiddenEditPrice}
                                            autoFocus
                                        >
                                        </NumberFormat>
                                        <Button
                                            style={{ background: "#477BF6", border: "none" }}
                                            type={"primary"}
                                            size={"small"}
                                            onClick={this.savePrice}
                                        >
                                            Save
                                        </Button>
                                    </div>)}
                            </div>

                            {additional_price !== undefined && additional_price > 0 && (orderType !== "FBA" && slug !== "express") && (
                                <div className="d-flex mb-1">
                                    <span className="Label font-weight-500">
                                        Additional Price:
                                    </span>
                                    <span className="Value">
                                        {formatCurrency(currency, additional_price)}
                                    </span>
                                </div>
                            )}

                            {options.map((option) => {
                                const { attribute, name: optionName } = option
                                const { name: attributeName } = attribute

                                return (
                                    <div className="d-flex mb-1" key={option._id}>
                                        <span className="Label font-weight-500">
                                            {attributeName}:
                                        </span>
                                        <span className="Value">{optionName}</span>
                                    </div>
                                )
                            })}

                            <div className="d-flex mb-1">
                                <span className="Label font-weight-500">SKU:</span>
                                <span className="Value">{sku}</span>
                            </div>

                            {mapping_rule && artworkType !== "productvariant" && (
                                <div className="d-flex mb-1">
                                    <span className="Label font-weight-500">Rule ID:</span>
                                    <span className="Value">
                                        <strong>{mapping_rule.id}</strong>
                                    </span>
                                </div>
                            )}
                        </div>

                        {permissionsData.hasOwnProperty('ffm_order_detail_create_rule') && artworkType !== "productvariant" && (
                            <div className="col-12">
                                <Button
                                    onClick={this._onToggleMappingRule}
                                    className={`CustomButton ${mapping_rule ? "ReplaceButton" : "AddNewButton"
                                        }`}
                                    disabled={loading}
                                    loading={loading}
                                >
                                    {mapping_rule ? `Replace old rule` : "Create rule"}
                                </Button>
                            </div>
                        )}

                        <div className="col-12 Stock">{outOfStock}</div>
                    </div>
                ) : (
                    permissionsData.hasOwnProperty('ffm_order_detail_mapping') && <OrderItemMapActions
                        _handleToggleModal={_handleToggleModal}
                        _handleRemoveItemMapped={_handleRemoveItemMapped}
                        isMapped={isMapped}
                    />
                )}
            </div>
        )
    }
}

OrderItemMapDetails.defaultProps = {
    isMapped: false,
}

OrderItemMapDetails.propTypes = {
    productLineVariant: PropTypes.object,
    isMapped: PropTypes.bool,
    _handleToggleModal: PropTypes.func.isRequired,
}

export default OrderItemMapDetails
