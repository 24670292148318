import React, {Component} from 'react'
import PropTypes from 'prop-types'
import PushPackageItem from './package-push/PushPackageItem'

class PackagePusherContainer extends Component {
    render() {
        const {fulfillment, orderId, supplierSelected, onUpdateTotalPushToProduction, paymentStatus, preference} = this.props

        return (
            <div className="PackagesPusherContainer ml-auto w-100">
                <PushPackageItem
                    handleEmbroiderySurcharge={this.props.handleEmbroiderySurcharge}
                    getButtonEmbroiderySurchargeText={this.props.getButtonEmbroiderySurchargeText}
                    showEmbroiderySurchargeButton={this.props.showEmbroiderySurchargeButton}
                    hasEmbroiderySurcharge={this.props.hasEmbroiderySurcharge}
                    isAllowEmbroiderySurcharge={this.props.isAllowEmbroiderySurcharge}
                    showPushButton={this.props.showPushButton}
                    orderId={orderId}
                    fulfillment={fulfillment}
                    supplierSelected={supplierSelected}
                    paymentStatus={paymentStatus}
                    onUpdateTotalPushToProduction={onUpdateTotalPushToProduction}
                    preference={preference}
                />
            </div>
        )
    }
}


PackagePusherContainer.propTypes = {
    fulfillment: PropTypes.object.isRequired,
    orderId: PropTypes.string.isRequired,
    supplierSelected: PropTypes.object,
    onUpdateTotalPushToProduction: PropTypes.func.isRequired,
    paymentStatus: PropTypes.string
}


export default PackagePusherContainer
