import React, { useEffect, useState } from 'react'
import BulkEditPrice from "./BulkEditPrice"
import { Switch, Popconfirm, Modal, Spin, Pagination, Button, Select } from "antd"
import isMatchVariant from '../../../product/helpers/isMatchVariant'
import { name } from 'store/storages/cookieStorage'
import { searchOrderHistories } from '../../../../services/api/OrdersServices'
import moment from 'moment'
import { PopoverBody, PopoverHeader, UncontrolledPopover } from 'reactstrap'
import { ObjectInspector } from 'react-inspector'

function ProductVariantsHistory(props) {
    const { objectType, objectId, isOpen, toggleShowModal, group, storeOptions = [] } = props
    const [error, setError] = useState('')
    const [loading, setLoading] = useState(false)
    const [listHistories, setListHistories] = useState([])
    const [filterStore, setFilterStore] = useState(objectId)
    const [page, setPage] = useState(1)
    const [limit, setLimit] = useState(100)
    const [total, setTotal] = useState(0)

    const _handlePageChange = (page, pageSize) => {
        setLimit(pageSize)
        setPage(pageSize !== limit ? 1 : page)
    }

    const _fetchVariantHistory = async () => {
        setLoading(true)
        setError('')
        setListHistories([])

        try {
            const payload = {
                object_type: objectType,
                object_id: filterStore,
                page: page,
                limit: limit
            }
            const { data, success, message: error } = await searchOrderHistories(payload)

            if (!success) {
                setLoading(false)

                return setError(error)
            }

            const { histories, total } = data
            setLoading(false)
            setListHistories(histories)
            setTotal(total)
        } catch (e) {
            setLoading(false)
            setError(e.message)
        }
    }

    const handleCancel = () => {
        toggleShowModal()
    }

    useEffect(() => {
        if (isOpen && !!objectId) {
            _fetchVariantHistory()
        }
    }, [isOpen, filterStore, objectId])

    const handleChangeStore = (value) => {
        setFilterStore(value)
    }


    return (
        <Modal title="Created Histories" visible={isOpen} onCancel={handleCancel} okButtonProps={{ style: { display: 'none' } }} className='created-history' width={900}>
            {group && objectId &&
                <div className="Filter d-flex justify-content-end mb-3">
                    <div className="filterItem d-flex align-items-center">
                        <span className='font-weight-bold mr-2'>Store:</span>
                        <Select
                            placeholder='Store'
                            value={filterStore || []}
                            onChange={handleChangeStore}
                            options={storeOptions}
                            style={{ minWidth: 200 }}
                            disabled={loading}
                        />
                    </div>
                </div>
            }
            <Spin spinning={loading} tip="Đang lấy dữ liệu...">
                <div className="HistoryTable" style={{ overflow: 'auto', maxHeight: 353 }}>
                    <table className="table">
                        <thead>
                            <tr>
                                <th className="colID">#</th>
                                <th className="colTime">Time</th>
                                <th className="colDescription">Description</th>
                                <th className="colUser">Người tạo</th>
                                <th className="colIP">IP</th>
                                <th className="colMeta">Key</th>
                            </tr>
                        </thead>
                        <tbody>
                            {(listHistories && listHistories.length > 0) ?
                                listHistories.map((history, index) => {
                                    const { _id, meta, created_at, description, user, event } = history
                                    const { IP } = meta
                                    return (
                                        <tr key={_id}>
                                            <td className="colID">{index + 1}</td>
                                            <td className="colTime">{created_at ? moment(created_at).format('HH:mm DD/MM/YYYY') : '-'}</td>
                                            <td className="colDescription">{description || ''}</td>
                                            <td className="colUser">{user || ''}</td>
                                            <td className="colIP">{IP || '--'}</td>
                                            <td className="colMeta">
                                                <span>{event}</span>
                                                <code>
                                                    <ObjectInspector
                                                        depth={0}
                                                        path="root.value"
                                                        data={meta !== undefined ? (Object.keys(meta).length === 0 ? '--' : meta) : '--'}
                                                    />
                                                </code>
                                                {/* <Button id={`mypopover_${event}_${_id}`} color="link">
                                                <code>{event}</code>
                                            </Button>
                                            <UncontrolledPopover target={`mypopover_${event}_${_id}`} trigger="legacy">
                                                <PopoverHeader><code>{event}</code></PopoverHeader>
                                                <PopoverBody>
                                                    <ObjectInspector depth={0} path="root.value"
                                                                     data={meta !== undefined ? (Object.keys(meta).length === 0 ? '--' : meta) : '--'}/>
                                                </PopoverBody>
                                            </UncontrolledPopover> */}
                                            </td>
                                        </tr>
                                    )
                                }) :
                                <tr>
                                    <td colSpan={6}>Không có lịch sử</td>
                                </tr>
                            }

                            {
                                error && error.length > 0 &&
                                <tr>
                                    <td colSpan={6} className="text-danger">{error}</td>
                                </tr>
                            }
                        </tbody>
                    </table>
                </div>

                {
                    (listHistories && listHistories.length > 0) &&
                    <div className="OrdersPagination d-flex justify-content-end Pagination">
                        <Pagination
                            current={page}
                            total={total}
                            pageSize={limit}
                            onChange={_handlePageChange}
                            className='text-right'
                            showSizeChanger
                            pageSizeOptions={['10', '20', '50', '100']}
                        />
                    </div>
                }
            </Spin>
        </Modal>
    )
}

export default ProductVariantsHistory
