import React, { useMemo, useState } from 'react'
import { DatePicker } from 'antd'
import moment from 'moment'

const { MonthPicker } = DatePicker

const TableStorePackageItem = (props) => {

    const { month1, month2, onChangeMonth2, storePackageReportItem } = props
    const [sortBy, setSortBy] = useState('prevPackageIssueTotal')
    const [time, setTime] = useState('previous')

    const [sortPrevPackageIssueTotal, setSortPrevPackageIssueTotal] = useState(true)
    const [sortPrevPackageTotal, setSortPrevPackageTotal] = useState(true)
    const [sortPrevPercent, setSortPrevPercent] = useState(true)
    const [sortPrevApprove, setSortPrevApprove] = useState(true)
    const [sortPrevHalf, setSortPrevHalf] = useState(true)
    const [sortPrevReject, setSortPrevReject] = useState(true)


    const [sortTargetPackageIssueTotal, setSortTargetPackageIssueTotal] = useState(true)
    const [sortTargetPackageTotal, setSortTargetPackageTotal] = useState(true)
    const [sortTargetPercent, setSortTargetPercent] = useState(true)
    const [sortTargetApprove, setSortTargetApprove] = useState(true)
    const [sortTargetHalf, setSortTargetHalf] = useState(true)
    const [sortTargetReject, setSortTargetReject] = useState(true)

    const showPercentIssue = (number, total) => {
        if (total === 0) return 0

        const percent = ((+number / +total) * 100)
        return !!percent ? Number(percent.toFixed(2)) : 0
    }

    const {
        groupByStore = {},
        prevPackageIssueTotal = 0,
        prevApprovedIssueTotal = 0,
        prevHalfIssueTotal = 0,
        prevRejectIssueTotal = 0,
        prevPackageTotal = 0,
        targetPackageIssueTotal = 0,
        targetApprovedIssueTotal = 0,
        targetHalfIssueTotal = 0,
        targetRejectIssueTotal = 0,
        targetPackageTotal = 0
    } = Object.assign({}, storePackageReportItem)

    const data = useMemo(() => {
        return Object.entries(groupByStore).map(([key, value]) => ({
            store: key,
            ...value
        }))
    }, [storePackageReportItem])

    const dataSorted = React.useMemo(() => {
        if (sortBy === 'prevPackageIssueTotal') {
            if (sortPrevPackageIssueTotal) {
                return data.sort((a, b) => a[time].packageIssues.total - b[time].packageIssues.total)
            } else {
                return data.sort((a, b) => b[time].packageIssues.total - a[time].packageIssues.total)
            }
        }

        if (sortBy === 'prevApprovedIssueTotal') {
            if (sortPrevApprove) {
                return data.sort((a, b) => a[time].packageIssues.approve - b[time].packageIssues.approve)
            } else {
                return data.sort((a, b) => b[time].packageIssues.approve - a[time].packageIssues.approve)
            }
        }

        if (sortBy === 'prevHalfIssueTotal') {
            if (sortPrevHalf) {
                return data.sort((a, b) => a[time].packageIssues.half - b[time].packageIssues.half)
            } else {
                return data.sort((a, b) => b[time].packageIssues.half - a[time].packageIssues.half)
            }
        }

        if (sortBy === 'prevRejectIssueTotal') {
            if (sortPrevReject) {
                return data.sort((a, b) => a[time].packageIssues.reject - b[time].packageIssues.reject)
            } else {
                return data.sort((a, b) => b[time].packageIssues.reject - a[time].packageIssues.reject)
            }
        }

        if (sortBy === 'prevPackageTotal') {
            if (sortPrevPackageTotal) {
                return data.sort((a, b) => a[time].packageTotal - b[time].packageTotal)
            } else {
                return data.sort((a, b) => b[time].packageTotal - a[time].packageTotal)
            }
        }

        if (sortBy === 'prevPercent') {
            if (sortPrevPercent) {
                return data.sort((a, b) => {
                    const a1 = Number(((a[time].packageIssues.total / (a[time].packageTotal || 0)) * 100))
                    const b1 = Number(((b[time].packageIssues.total / (b[time].packageTotal || 0)) * 100))
                    return a1 - b1
                })
            } else {
                return data.sort((a, b) => {
                    const a1 = Number(((a[time].packageIssues.total / (a[time].packageTotal || 0)) * 100))
                    const b1 = Number(((b[time].packageIssues.total / (b[time].packageTotal || 0)) * 100))
                    return b1 - a1
                })
            }
        }

        if (sortBy === "targetPackageIssueTotal") {
            if (sortTargetPackageIssueTotal) {
                return data.sort((a, b) => a[time].packageIssues.total - b[time].packageIssues.total)
            } else {
                return data.sort((a, b) => b[time].packageIssues.total - a[time].packageIssues.total)
            }
        }

        if (sortBy === 'targetApprovedIssueTotal') {
            if (sortTargetApprove) {
                return data.sort((a, b) => a[time].packageIssues.approve - b[time].packageIssues.approve)
            } else {
                return data.sort((a, b) => b[time].packageIssues.approve - a[time].packageIssues.approve)
            }
        }

        if (sortBy === 'targetHalfIssueTotal') {
            if (sortTargetHalf) {
                return data.sort((a, b) => a[time].packageIssues.half - b[time].packageIssues.half)
            } else {
                return data.sort((a, b) => b[time].packageIssues.half - a[time].packageIssues.half)
            }
        }

        if (sortBy === 'targetRejectIssueTotal') {
            if (sortTargetReject) {
                return data.sort((a, b) => a[time].packageIssues.reject - b[time].packageIssues.reject)
            } else {
                return data.sort((a, b) => b[time].packageIssues.reject - a[time].packageIssues.reject)
            }
        }

        if (sortBy === 'targetPackageTotal') {
            if (sortTargetPackageTotal) {
                return data.sort((a, b) => a[time].packageTotal - b[time].packageTotal)
            } else {
                return data.sort((a, b) => b[time].packageTotal - a[time].packageTotal)
            }
        }

        if (sortBy === 'targetPercent') {
            if (sortTargetPercent) {
                return data.sort((a, b) => {
                    const a1 = Number(((a[time].packageIssues.total / (a[time].packageTotal || 0)) * 100))
                    const b1 = Number(((b[time].packageIssues.total / (b[time].packageTotal || 0)) * 100))
                    return a1 - b1
                })
            } else {
                return data.sort((a, b) => {
                    const a1 = Number(((a[time].packageIssues.total / (a[time].packageTotal || 0)) * 100))
                    const b1 = Number(((b[time].packageIssues.total / (b[time].packageTotal || 0)) * 100))
                    return b1 - a1
                })
            }
        }

    }, [
        data,
        sortBy,
        sortPrevHalf,
        sortPrevReject,
        sortTargetHalf,
        sortPrevPercent,
        sortPrevApprove,
        sortTargetReject,
        sortTargetApprove,
        sortTargetPercent,
        sortPrevPackageTotal,
        sortTargetPackageTotal,
        sortPrevPackageIssueTotal,
        sortTargetPackageIssueTotal,
    ])

    const handleSortPackage = (field, sortField, setSort, time) => {
        setTime(time)
        setSortBy(field)
        setSort(!sortField)
    }

    return (
        <div className="table IssuePackageItem d-flex">
            <table className="table-left">
                <thead>
                    <tr>
                        <th rowSpan={4} className='col-store'>Store</th>
                        <th colSpan={6} className="text-center">
                            <MonthPicker
                                allowClear={false}
                                value={month1}
                                format="MMMM YYYY"
                                style={{ pointerEvents: 'none' }}
                                className='custom-month-issue-report-store'
                            />
                        </th>
                    </tr>
                    <tr>
                        <th rowSpan={2} style={{ verticalAlign: 'middle', textAlign: 'center' }}>Total issue item</th>
                        <th colSpan={3} className='col-supplier-confirm text-center'>Partner's confirm</th>
                        <th rowSpan={2} style={{ verticalAlign: 'middle', textAlign: 'center' }}>Total item</th>
                        <th rowSpan={2} style={{ verticalAlign: 'middle', textAlign: 'center' }}>Issue item rate</th>
                    </tr>
                    <tr>
                        <th>Approve</th>
                        <th>50/50</th>
                        <th>Reject</th>
                    </tr>
                    <tr>
                        <th className='text-right'>
                            {prevPackageIssueTotal}
                            {
                                sortPrevPackageIssueTotal ?
                                    <i
                                        className={`fa fa-long-arrow-up cursor-pointer p-1 ${sortBy === "prevPackageIssueTotal" && "text-primary"}`}
                                        onClick={() => handleSortPackage('prevPackageIssueTotal', sortPrevPackageIssueTotal, setSortPrevPackageIssueTotal, 'previous')}
                                    /> :
                                    <i
                                        className={`fa fa-long-arrow-down cursor-pointer p-1 ${sortBy === "prevPackageIssueTotal" && "text-primary"}`}
                                        onClick={() => handleSortPackage('prevPackageIssueTotal', sortPrevPackageIssueTotal, setSortPrevPackageIssueTotal, 'previous')}
                                    />
                            }
                        </th>
                        <th className='text-right'>
                            {prevApprovedIssueTotal}
                            {
                                sortPrevApprove ?
                                    <i
                                        className={`fa fa-long-arrow-up cursor-pointer p-1 ${sortBy === "prevApprovedIssueTotal" && "text-primary"}`}
                                        onClick={() => handleSortPackage('prevApprovedIssueTotal', sortPrevApprove, setSortPrevApprove, 'previous')}
                                    /> :
                                    <i
                                        className={`fa fa-long-arrow-down cursor-pointer p-1 ${sortBy === "prevApprovedIssueTotal" && "text-primary"}`}
                                        onClick={() => handleSortPackage('prevApprovedIssueTotal', sortPrevApprove, setSortPrevApprove, 'previous')}
                                    />
                            }
                        </th>
                        <th className='text-right'>
                            {prevHalfIssueTotal}
                            {
                                sortPrevHalf ?
                                    <i
                                        className={`fa fa-long-arrow-up cursor-pointer p-1 ${sortBy === "prevHalfIssueTotal" && "text-primary"}`}
                                        onClick={() => handleSortPackage('prevHalfIssueTotal', sortPrevHalf, setSortPrevHalf, 'previous')}
                                    /> :
                                    <i
                                        className={`fa fa-long-arrow-down cursor-pointer p-1 ${sortBy === "prevHalfIssueTotal" && "text-primary"}`}
                                        onClick={() => handleSortPackage('prevHalfIssueTotal', sortPrevHalf, setSortPrevHalf, 'previous')}
                                    />
                            }
                        </th>
                        <th className='text-right'>
                            {prevRejectIssueTotal}
                            {
                                sortPrevReject ?
                                    <i
                                        className={`fa fa-long-arrow-up cursor-pointer p-1 ${sortBy === "prevRejectIssueTotal" && "text-primary"}`}
                                        onClick={() => handleSortPackage('prevRejectIssueTotal', sortPrevReject, setSortPrevReject, 'previous')}
                                    /> :
                                    <i
                                        className={`fa fa-long-arrow-down cursor-pointer p-1 ${sortBy === "prevRejectIssueTotal" && "text-primary"}`}
                                        onClick={() => handleSortPackage('prevRejectIssueTotal', sortPrevReject, setSortPrevReject, 'previous')}
                                    />
                            }
                        </th>
                        <th className='text-right'>
                            {prevPackageTotal}
                            {
                                sortPrevPackageTotal ?
                                    <i
                                        className={`fa fa-long-arrow-up cursor-pointer p-1 ${sortBy === "prevPackageTotal" && "text-primary"}`}
                                        onClick={() => handleSortPackage('prevPackageTotal', sortPrevPackageTotal, setSortPrevPackageTotal, 'previous')}
                                    /> :
                                    <i
                                        className={`fa fa-long-arrow-down cursor-pointer p-1 ${sortBy === "prevPackageTotal" && "text-primary"}`}
                                        onClick={() => handleSortPackage('prevPackageTotal', sortPrevPackageTotal, setSortPrevPackageTotal, 'previous')}
                                    />
                            }
                        </th>
                        <th className='text-right'>
                            {`${showPercentIssue(prevPackageIssueTotal, prevPackageTotal)}%`}
                            {
                                sortPrevPercent ?
                                    <i
                                        className={`fa fa-long-arrow-up cursor-pointer p-1 ${sortBy === "prevPercent" && "text-primary"}`}
                                        onClick={() => handleSortPackage('prevPercent', sortPrevPercent, setSortPrevPercent, 'previous')}
                                    /> :
                                    <i
                                        className={`fa fa-long-arrow-down cursor-pointer p-1 ${sortBy === "prevPercent" && "text-primary"}`}
                                        onClick={() => handleSortPackage('prevPercent', sortPrevPercent, setSortPrevPercent, 'previous')}
                                    />
                            }
                        </th>
                    </tr>
                </thead>
                <tbody>
                    {dataSorted.map((row, index) => {
                        const { previous = {}, store = '' } = Object.assign({}, row)
                        const { packageIssues = {}, packageTotal = 0 } = Object.assign({}, previous)
                        const { total = 0, reject = 0, half = 0, approve = 0 } = Object.assign({}, packageIssues)
                        const isNotification = showPercentIssue(total, packageTotal) > 2
                        return (
                            <tr key={index}>
                                <td className='col-store'>{store}</td>
                                <td className='text-right' style={{ backgroundColor: isNotification ? "#f7d9da" : 'transparent' }}>{total}</td>
                                <td className='text-right' style={{ backgroundColor: isNotification ? "#f7d9da" : 'transparent' }}>{approve}</td>
                                <td className='text-right' style={{ backgroundColor: isNotification ? "#f7d9da" : 'transparent' }}>{half}</td>
                                <td className='text-right' style={{ backgroundColor: isNotification ? "#f7d9da" : 'transparent' }}>{reject}</td>
                                <td className='text-right' style={{ backgroundColor: isNotification ? "#f7d9da" : 'transparent' }}>{packageTotal}</td>
                                <td className='text-right' style={{ backgroundColor: isNotification ? "#f7d9da" : 'transparent' }}>{`${showPercentIssue(total, packageTotal)}%`}</td>
                            </tr>
                        )
                    })}
                </tbody>
            </table>
            <table className="table-right">
                <thead>
                    <tr>
                        <th colSpan={6} className="text-center">
                            <MonthPicker
                                allowClear={false}
                                value={month2}
                                onChange={onChangeMonth2}
                                format="MMMM YYYY"
                                className='custom-month-issue-report-store'
                            />
                        </th>
                    </tr>
                    <tr>
                        <th rowSpan={2} style={{ verticalAlign: 'middle', textAlign: 'center' }}>Total issue item</th>
                        <th colSpan={3} className='col-supplier-confirm text-center'>Partner's confirm</th>
                        <th rowSpan={2} style={{ verticalAlign: 'middle', textAlign: 'center' }}>Total item</th>
                        <th rowSpan={2} style={{ verticalAlign: 'middle', textAlign: 'center' }}>Issue item rate</th>
                    </tr>
                    <tr>
                        <th>Approve</th>
                        <th>50/50</th>
                        <th>Reject</th>
                    </tr>
                    <tr>
                        <th className='text-right'>
                            {targetPackageIssueTotal}
                            {
                                sortTargetPackageIssueTotal ?
                                    <i
                                        className={`fa fa-long-arrow-up cursor-pointer p-1 ${sortBy === "targetPackageIssueTotal" && "text-primary"}`}
                                        onClick={() => handleSortPackage('targetPackageIssueTotal', sortTargetPackageIssueTotal, setSortTargetPackageIssueTotal, 'target')}
                                    /> :
                                    <i
                                        className={`fa fa-long-arrow-down cursor-pointer p-1 ${sortBy === "targetPackageIssueTotal" && "text-primary"}`}
                                        onClick={() => handleSortPackage('targetPackageIssueTotal', sortTargetPackageIssueTotal, setSortTargetPackageIssueTotal, 'target')}
                                    />
                            }
                        </th>
                        <th className='text-right'>
                            {targetApprovedIssueTotal}
                            {
                                sortTargetApprove ?
                                    <i
                                        className={`fa fa-long-arrow-up cursor-pointer p-1 ${sortBy === "targetApprovedIssueTotal" && "text-primary"}`}
                                        onClick={() => handleSortPackage('targetApprovedIssueTotal', sortTargetApprove, setSortTargetApprove, 'target')}
                                    /> :
                                    <i
                                        className={`fa fa-long-arrow-down cursor-pointer p-1 ${sortBy === "targetApprovedIssueTotal" && "text-primary"}`}
                                        onClick={() => handleSortPackage('targetApprovedIssueTotal', sortTargetApprove, setSortTargetApprove, 'target')}
                                    />
                            }
                        </th>
                        <th className='text-right'>
                            {targetHalfIssueTotal}
                            {
                                sortTargetHalf ?
                                    <i
                                        className={`fa fa-long-arrow-up cursor-pointer p-1 ${sortBy === "targetHalfIssueTotal" && "text-primary"}`}
                                        onClick={() => handleSortPackage('targetHalfIssueTotal', sortTargetHalf, setSortTargetHalf, 'target')}
                                    /> :
                                    <i
                                        className={`fa fa-long-arrow-down cursor-pointer p-1 ${sortBy === "targetHalfIssueTotal" && "text-primary"}`}
                                        onClick={() => handleSortPackage('targetHalfIssueTotal', sortTargetHalf, setSortTargetHalf, 'target')}
                                    />
                            }
                        </th>
                        <th className='text-right'>
                            {targetRejectIssueTotal}
                            {
                                sortTargetReject ?
                                    <i
                                        className={`fa fa-long-arrow-up cursor-pointer p-1 ${sortBy === "targetRejectIssueTotal" && "text-primary"}`}
                                        onClick={() => handleSortPackage('targetRejectIssueTotal', sortTargetReject, setSortTargetReject, 'target')}
                                    /> :
                                    <i
                                        className={`fa fa-long-arrow-down cursor-pointer p-1 ${sortBy === "targetRejectIssueTotal" && "text-primary"}`}
                                        onClick={() => handleSortPackage('targetRejectIssueTotal', sortTargetReject, setSortTargetReject, 'target')}
                                    />
                            }
                        </th>
                        <th className='text-right'>
                            {targetPackageTotal}
                            {
                                sortTargetPackageTotal ?
                                    <i
                                        className={`fa fa-long-arrow-up cursor-pointer p-1 ${sortBy === "targetPackageTotal" && "text-primary"}`}
                                        onClick={() => handleSortPackage('targetPackageTotal', sortTargetPackageTotal, setSortTargetPackageTotal, 'target')}
                                    /> :
                                    <i
                                        className={`fa fa-long-arrow-down cursor-pointer p-1 ${sortBy === "targetPackageTotal" && "text-primary"}`}
                                        onClick={() => handleSortPackage('targetPackageTotal', sortTargetPackageTotal, setSortTargetPackageTotal, 'target')}
                                    />
                            }
                        </th>
                        <th className='text-right'>
                            {`${showPercentIssue(targetPackageIssueTotal, targetPackageTotal)}%`}
                            {
                                sortTargetPercent ?
                                    <i
                                        className={`fa fa-long-arrow-up cursor-pointer p-1 ${sortBy === "targetPercent" && "text-primary"}`}
                                        onClick={() => handleSortPackage('targetPercent', sortTargetPercent, setSortTargetPercent, 'target')}
                                    /> :
                                    <i
                                        className={`fa fa-long-arrow-down cursor-pointer p-1 ${sortBy === "targetPercent" && "text-primary"}`}
                                        onClick={() => handleSortPackage('targetPercent', sortTargetPercent, setSortTargetPercent, 'target')}
                                    />
                            }
                        </th>
                    </tr>
                </thead>
                <tbody>
                    {dataSorted.map((row, index) => {
                        const { target = {} } = Object.assign({}, row)
                        const { packageIssues = {}, packageTotal = 0 } = Object.assign({}, target)
                        const { total = 0, reject = 0, half = 0, approve = 0 } = Object.assign({}, packageIssues)
                        const isNotification = showPercentIssue(total, packageTotal) > 2
                        return (
                            <tr key={index}>
                                <td className='text-right' style={{ backgroundColor: isNotification ? "#f7d9da" : 'transparent' }}>{total}</td>
                                <td className='text-right' style={{ backgroundColor: isNotification ? "#f7d9da" : 'transparent' }}>{approve}</td>
                                <td className='text-right' style={{ backgroundColor: isNotification ? "#f7d9da" : 'transparent' }}>{half}</td>
                                <td className='text-right' style={{ backgroundColor: isNotification ? "#f7d9da" : 'transparent' }}>{reject}</td>
                                <td className='text-right' style={{ backgroundColor: isNotification ? "#f7d9da" : 'transparent' }}>{packageTotal}</td>
                                <td className='text-right' style={{ backgroundColor: isNotification ? "#f7d9da" : 'transparent' }}>{`${showPercentIssue(total, packageTotal)}%`}</td>
                            </tr>
                        )
                    })}
                </tbody>
            </table>

            <table className="table" style={{ display: 'none' }} id="Issue_Store_Package_Item_Report">
                <thead style={{ display: 'none' }}>
                    <tr>
                        <th rowSpan={4} className='col-store'>Store</th>
                        <th colSpan={6} className="text-center">
                            {moment(month1).format('MMMM YYYY')}
                        </th>
                        <th colSpan={6} className="text-center">
                            {moment(month2).format('MMMM YYYY')}
                        </th>
                    </tr>
                    <tr>
                        <th rowSpan={2} style={{ verticalAlign: 'middle', textAlign: 'center' }}>Total issue item</th>
                        <th colSpan={3} className='col-supplier-confirm text-center'>Partner's confirm</th>
                        <th rowSpan={2} style={{ verticalAlign: 'middle', textAlign: 'center' }}>Total item</th>
                        <th rowSpan={2} style={{ verticalAlign: 'middle', textAlign: 'center' }}>Issue item rate</th>
                        <th rowSpan={2} style={{ verticalAlign: 'middle', textAlign: 'center' }}>Total issue item</th>
                        <th colSpan={3} className='col-supplier-confirm text-center'>Partner's confirm</th>
                        <th rowSpan={2} style={{ verticalAlign: 'middle', textAlign: 'center' }}>Total item</th>
                        <th rowSpan={2} style={{ verticalAlign: 'middle', textAlign: 'center' }}>Issue item rate</th>
                    </tr>
                    <tr>
                        <th>Approve</th>
                        <th>50/50</th>
                        <th>Reject</th>
                        <th>Approve</th>
                        <th>50/50</th>
                        <th>Reject</th>
                    </tr>
                    <tr>
                        <th className='text-right'>
                            {prevPackageIssueTotal}
                            {
                                sortPrevPackageIssueTotal ?
                                    <i
                                        className={`fa fa-long-arrow-up cursor-pointer p-1 ${sortBy === "prevPackageIssueTotal" && "text-primary"}`}
                                        onClick={() => handleSortPackage('prevPackageIssueTotal', sortPrevPackageIssueTotal, setSortPrevPackageIssueTotal, 'previous')}
                                    /> :
                                    <i
                                        className={`fa fa-long-arrow-down cursor-pointer p-1 ${sortBy === "prevPackageIssueTotal" && "text-primary"}`}
                                        onClick={() => handleSortPackage('prevPackageIssueTotal', sortPrevPackageIssueTotal, setSortPrevPackageIssueTotal, 'previous')}
                                    />
                            }
                        </th>
                        <th className='text-right'>
                            {prevApprovedIssueTotal}
                            {
                                sortPrevApprove ?
                                    <i
                                        className={`fa fa-long-arrow-up cursor-pointer p-1 ${sortBy === "prevApprovedIssueTotal" && "text-primary"}`}
                                        onClick={() => handleSortPackage('prevApprovedIssueTotal', sortPrevApprove, setSortPrevApprove, 'previous')}
                                    /> :
                                    <i
                                        className={`fa fa-long-arrow-down cursor-pointer p-1 ${sortBy === "prevApprovedIssueTotal" && "text-primary"}`}
                                        onClick={() => handleSortPackage('prevApprovedIssueTotal', sortPrevApprove, setSortPrevApprove, 'previous')}
                                    />
                            }
                        </th>
                        <th className='text-right'>
                            {prevHalfIssueTotal}
                            {
                                sortPrevHalf ?
                                    <i
                                        className={`fa fa-long-arrow-up cursor-pointer p-1 ${sortBy === "prevHalfIssueTotal" && "text-primary"}`}
                                        onClick={() => handleSortPackage('prevHalfIssueTotal', sortPrevHalf, setSortPrevHalf, 'previous')}
                                    /> :
                                    <i
                                        className={`fa fa-long-arrow-down cursor-pointer p-1 ${sortBy === "prevHalfIssueTotal" && "text-primary"}`}
                                        onClick={() => handleSortPackage('prevHalfIssueTotal', sortPrevHalf, setSortPrevHalf, 'previous')}
                                    />
                            }
                        </th>
                        <th className='text-right'>
                            {prevRejectIssueTotal}
                            {
                                sortPrevReject ?
                                    <i
                                        className={`fa fa-long-arrow-up cursor-pointer p-1 ${sortBy === "prevRejectIssueTotal" && "text-primary"}`}
                                        onClick={() => handleSortPackage('prevRejectIssueTotal', sortPrevReject, setSortPrevReject, 'previous')}
                                    /> :
                                    <i
                                        className={`fa fa-long-arrow-down cursor-pointer p-1 ${sortBy === "prevRejectIssueTotal" && "text-primary"}`}
                                        onClick={() => handleSortPackage('prevRejectIssueTotal', sortPrevReject, setSortPrevReject, 'previous')}
                                    />
                            }
                        </th>
                        <th className='text-right'>
                            {prevPackageTotal}
                            {
                                sortPrevPackageTotal ?
                                    <i
                                        className={`fa fa-long-arrow-up cursor-pointer p-1 ${sortBy === "prevPackageTotal" && "text-primary"}`}
                                        onClick={() => handleSortPackage('prevPackageTotal', sortPrevPackageTotal, setSortPrevPackageTotal, 'previous')}
                                    /> :
                                    <i
                                        className={`fa fa-long-arrow-down cursor-pointer p-1 ${sortBy === "prevPackageTotal" && "text-primary"}`}
                                        onClick={() => handleSortPackage('prevPackageTotal', sortPrevPackageTotal, setSortPrevPackageTotal, 'previous')}
                                    />
                            }
                        </th>
                        <th className='text-right'>
                            {`${showPercentIssue(prevPackageIssueTotal, prevPackageTotal)}%`}
                            {
                                sortPrevPercent ?
                                    <i
                                        className={`fa fa-long-arrow-up cursor-pointer p-1 ${sortBy === "prevPercent" && "text-primary"}`}
                                        onClick={() => handleSortPackage('prevPercent', sortPrevPercent, setSortPrevPercent, 'previous')}
                                    /> :
                                    <i
                                        className={`fa fa-long-arrow-down cursor-pointer p-1 ${sortBy === "prevPercent" && "text-primary"}`}
                                        onClick={() => handleSortPackage('prevPercent', sortPrevPercent, setSortPrevPercent, 'previous')}
                                    />
                            }
                        </th>
                        <th className='text-right'>
                            {prevPackageIssueTotal}
                            {
                                sortPrevPackageIssueTotal ?
                                    <i
                                        className={`fa fa-long-arrow-up cursor-pointer p-1 ${sortBy === "prevPackageIssueTotal" && "text-primary"}`}
                                        onClick={() => handleSortPackage('prevPackageIssueTotal', sortPrevPackageIssueTotal, setSortPrevPackageIssueTotal, 'previous')}
                                    /> :
                                    <i
                                        className={`fa fa-long-arrow-down cursor-pointer p-1 ${sortBy === "prevPackageIssueTotal" && "text-primary"}`}
                                        onClick={() => handleSortPackage('prevPackageIssueTotal', sortPrevPackageIssueTotal, setSortPrevPackageIssueTotal, 'previous')}
                                    />
                            }
                        </th>
                        <th className='text-right'>
                            {prevApprovedIssueTotal}
                            {
                                sortPrevApprove ?
                                    <i
                                        className={`fa fa-long-arrow-up cursor-pointer p-1 ${sortBy === "prevApprovedIssueTotal" && "text-primary"}`}
                                        onClick={() => handleSortPackage('prevApprovedIssueTotal', sortPrevApprove, setSortPrevApprove, 'previous')}
                                    /> :
                                    <i
                                        className={`fa fa-long-arrow-down cursor-pointer p-1 ${sortBy === "prevApprovedIssueTotal" && "text-primary"}`}
                                        onClick={() => handleSortPackage('prevApprovedIssueTotal', sortPrevApprove, setSortPrevApprove, 'previous')}
                                    />
                            }
                        </th>
                        <th className='text-right'>
                            {prevHalfIssueTotal}
                            {
                                sortPrevHalf ?
                                    <i
                                        className={`fa fa-long-arrow-up cursor-pointer p-1 ${sortBy === "prevHalfIssueTotal" && "text-primary"}`}
                                        onClick={() => handleSortPackage('prevHalfIssueTotal', sortPrevHalf, setSortPrevHalf, 'previous')}
                                    /> :
                                    <i
                                        className={`fa fa-long-arrow-down cursor-pointer p-1 ${sortBy === "prevHalfIssueTotal" && "text-primary"}`}
                                        onClick={() => handleSortPackage('prevHalfIssueTotal', sortPrevHalf, setSortPrevHalf, 'previous')}
                                    />
                            }
                        </th>
                        <th className='text-right'>
                            {prevRejectIssueTotal}
                            {
                                sortPrevReject ?
                                    <i
                                        className={`fa fa-long-arrow-up cursor-pointer p-1 ${sortBy === "prevRejectIssueTotal" && "text-primary"}`}
                                        onClick={() => handleSortPackage('prevRejectIssueTotal', sortPrevReject, setSortPrevReject, 'previous')}
                                    /> :
                                    <i
                                        className={`fa fa-long-arrow-down cursor-pointer p-1 ${sortBy === "prevRejectIssueTotal" && "text-primary"}`}
                                        onClick={() => handleSortPackage('prevRejectIssueTotal', sortPrevReject, setSortPrevReject, 'previous')}
                                    />
                            }
                        </th>
                        <th className='text-right'>
                            {prevPackageTotal}
                            {
                                sortPrevPackageTotal ?
                                    <i
                                        className={`fa fa-long-arrow-up cursor-pointer p-1 ${sortBy === "prevPackageTotal" && "text-primary"}`}
                                        onClick={() => handleSortPackage('prevPackageTotal', sortPrevPackageTotal, setSortPrevPackageTotal, 'previous')}
                                    /> :
                                    <i
                                        className={`fa fa-long-arrow-down cursor-pointer p-1 ${sortBy === "prevPackageTotal" && "text-primary"}`}
                                        onClick={() => handleSortPackage('prevPackageTotal', sortPrevPackageTotal, setSortPrevPackageTotal, 'previous')}
                                    />
                            }
                        </th>
                        <th className='text-right'>
                            {`${showPercentIssue(prevPackageIssueTotal, prevPackageTotal)}%`}
                            {
                                sortPrevPercent ?
                                    <i
                                        className={`fa fa-long-arrow-up cursor-pointer p-1 ${sortBy === "prevPercent" && "text-primary"}`}
                                        onClick={() => handleSortPackage('prevPercent', sortPrevPercent, setSortPrevPercent, 'previous')}
                                    /> :
                                    <i
                                        className={`fa fa-long-arrow-down cursor-pointer p-1 ${sortBy === "prevPercent" && "text-primary"}`}
                                        onClick={() => handleSortPackage('prevPercent', sortPrevPercent, setSortPrevPercent, 'previous')}
                                    />
                            }
                        </th>
                    </tr>
                </thead>
                <tbody style={{ display: 'none' }}>
                    {dataSorted.map((row, index) => {
                        const { previous = {}, target = {}, store = '' } = Object.assign({}, row)
                        const { packageIssues: packageIssuesTarget = {}, packageTotal: packageTotalTarget = 0 } = Object.assign({}, target)
                        const { packageIssues: packageIssuesPrev = {}, packageTotal: packageTotalPrev = 0 } = Object.assign({}, previous)

                        const isNotificationPrev = showPercentIssue(packageIssuesPrev.total, packageTotalPrev) > 2
                        const isNotificationTarget = showPercentIssue(packageIssuesTarget.total, packageTotalTarget) > 2

                        return (
                            <tr key={index}>
                                <td className='col-store'>{store}</td>
                                <td className='text-right' style={{ backgroundColor: isNotificationPrev ? "#f7d9da" : 'transparent' }}>{packageIssuesPrev.total}</td>
                                <td className='text-right' style={{ backgroundColor: isNotificationPrev ? "#f7d9da" : 'transparent' }}>{packageIssuesPrev.approve}</td>
                                <td className='text-right' style={{ backgroundColor: isNotificationPrev ? "#f7d9da" : 'transparent' }}>{packageIssuesPrev.half}</td>
                                <td className='text-right' style={{ backgroundColor: isNotificationPrev ? "#f7d9da" : 'transparent' }}>{packageIssuesPrev.reject}</td>
                                <td className='text-right' style={{ backgroundColor: isNotificationPrev ? "#f7d9da" : 'transparent' }}>{packageTotalPrev}</td>
                                <td className='text-right' style={{ backgroundColor: isNotificationPrev ? "#f7d9da" : 'transparent' }}>{`${showPercentIssue(packageIssuesPrev.total, packageTotalPrev)}%`}</td>

                                <td className='text-right' style={{ backgroundColor: isNotificationTarget ? "#f7d9da" : 'transparent' }}>{packageIssuesTarget.total}</td>
                                <td className='text-right' style={{ backgroundColor: isNotificationTarget ? "#f7d9da" : 'transparent' }}>{packageIssuesTarget.approve}</td>
                                <td className='text-right' style={{ backgroundColor: isNotificationTarget ? "#f7d9da" : 'transparent' }}>{packageIssuesTarget.half}</td>
                                <td className='text-right' style={{ backgroundColor: isNotificationTarget ? "#f7d9da" : 'transparent' }}>{packageIssuesTarget.reject}</td>
                                <td className='text-right' style={{ backgroundColor: isNotificationTarget ? "#f7d9da" : 'transparent' }}>{packageTotalTarget}</td>
                                <td className='text-right' style={{ backgroundColor: isNotificationTarget ? "#f7d9da" : 'transparent' }}>{`${showPercentIssue(packageIssuesTarget.total, packageTotalTarget)}%`}</td>
                            </tr>
                        )
                    })}
                </tbody>
            </table>
        </div>
    )
}

export default TableStorePackageItem