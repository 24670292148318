import React, { Component } from 'react'
import PropTypes from 'prop-types'
import OrderRestore from './actions/OrderRestore'
import PushOrderExport from './actions/PushOrderExport'
import SyncShopifyTracking from './actions/SyncShopifyTracking'
import UndoArchiveOrder from './actions/UndoArchiveOrder'
import ArchiveOrder from './actions/ArchiveOrder'
import { DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown } from 'reactstrap'
import { getLocalData } from '../../../../services/StorageServices'
import { parseJwt } from '../../../../helpers/parseToken'

class OrderAdvanceActions extends Component {

    render() {
        const { fulfilledPackages } = this.props
        const {
            completedAt,
            canRestore,
            orderId,
            fulfillmentStatus,
            onUpdateTotalPushToProduction,
            order,
            onFetchOrder,
            paymentStatus
        } = this.props
        const { is_archive, _id } = order

        const permissionsData = getLocalData('permissions') ? getLocalData('permissions') : {}

        return (
            <div className='OrderAdvanceActions position-relative'>
                <UncontrolledDropdown>
                    <DropdownToggle tag='a' className='nav-link' caret>
                        More actions
                    </DropdownToggle>

                    <DropdownMenu>
                        {
                            permissionsData.hasOwnProperty('ffm_order_detail_restore_order') &&
                            <DropdownItem>
                                <OrderRestore completedAt={completedAt} canRestore={canRestore} orderId={orderId}
                                    fulfilledPackages={fulfilledPackages}
                                    fulfillmentStatus={fulfillmentStatus} />
                            </DropdownItem>
                        }

                        {
                            permissionsData.hasOwnProperty('ffm_order_detail_push_ggsheet_1c') && (fulfillmentStatus !== 'onhold' && fulfillmentStatus !== 'cancelled') &&
                            <>
                                <DropdownItem>
                                    <PushOrderExport order={order} orderId={orderId} supplier="1c"
                                        paymentStatus={paymentStatus}
                                        onUpdateTotalPushToProduction={onUpdateTotalPushToProduction} />
                                </DropdownItem>
                                <DropdownItem divider />
                            </>
                        }

                        {
                            permissionsData.hasOwnProperty('ffm_order_detail_push_ggsheet_basegift') && (fulfillmentStatus !== 'onhold' && fulfillmentStatus !== 'cancelled') &&
                            <>
                                <DropdownItem>
                                    <PushOrderExport order={order} orderId={orderId} supplier="basegift"
                                        paymentStatus={paymentStatus}
                                        onUpdateTotalPushToProduction={onUpdateTotalPushToProduction} />
                                </DropdownItem>
                                <DropdownItem divider />
                            </>
                        }

                        {
                            permissionsData.hasOwnProperty('ffm_order_detail_push_ggsheet_tcvn') &&
                                (fulfillmentStatus !== 'onhold' && fulfillmentStatus !== 'cancelled') &&
                            <>
                                <DropdownItem>
                                    <PushOrderExport order={order} orderId={orderId} supplier="tcvn" textSupplier='TCVN'
                                        paymentStatus={paymentStatus}
                                        onUpdateTotalPushToProduction={onUpdateTotalPushToProduction} />
                                </DropdownItem>
                            </>
                        }

                        <DropdownItem>
                            <SyncShopifyTracking order={order} />
                        </DropdownItem>

                        {
                            permissionsData.hasOwnProperty('ffm_order_detail_archive') &&
                            <DropdownItem>
                                {
                                    is_archive
                                        ?
                                        <UndoArchiveOrder orderId={_id} onSuccess={onFetchOrder} />
                                        :
                                        <ArchiveOrder orderId={_id} onSuccess={onFetchOrder} />
                                }
                            </DropdownItem>
                        }

                    </DropdownMenu>
                </UncontrolledDropdown>
            </div>
        )
    }
}

OrderAdvanceActions.propTypes = {
    order: PropTypes.object.isRequired,
    orderId: PropTypes.string.isRequired,
    fulfillmentStatus: PropTypes.string.isRequired,
    completedAt: PropTypes.string,
    canRestore: PropTypes.bool,
    onUpdateTotalPushToProduction: PropTypes.func.isRequired,
    paymentStatus: PropTypes.string
}

export default OrderAdvanceActions
