import React, {Component} from 'react'
import PlatformSettingPage from './PlatformSettingPage'

class PlatformSettingContainer extends Component {
    render() {
        const {id} = this.props.match.params

        return (
            <div className="PlatformSettingContainer">
                <PlatformSettingPage
                    {...this.props}
                    platformId={id}
                />
            </div>
        )
    }
}

export default PlatformSettingContainer
