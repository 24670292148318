import React from 'react'
import ReportFastProductionTime from './ReportFastProductionTime'

function FastProductionReport(props) {
    const {suppliers} = props
    return (
        <div className="ShipmentReports FastProductionReport">
            <div className="mb-5">
                <ReportFastProductionTime suppliers={suppliers} />
            </div>
        </div>
    )
}

export default FastProductionReport