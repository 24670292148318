import React, {Component} from 'react'
import PlatformPage from './PlatformPage'

class PlatformContainer extends Component {
    render() {
        const {id} = this.props.match.params

        return (
            <div className="PlatformContainer">
                <PlatformPage
                    {...this.props}
                    platformId={id}
                />
            </div>
        )
    }
}

export default PlatformContainer
