import React, {Component} from 'react'
import PropTypes from 'prop-types'
import CodAddress from "./previewAddress/CodAddress"
import StandardAddress from "./previewAddress/StandardAddress"
import {Select} from "antd"

const {Option} = Select

class PackageAddressEditor extends Component {
    _handleChangeShippingCarrier = (value) => {
        const {onChangeShippingCarrier} = this.props

        if (typeof onChangeShippingCarrier === 'function') {
            onChangeShippingCarrier(value)
        }
    }

    render() {
        const {previews, supplierSku, shippingCarrier, factoryInfo} = this.props
        const {shipping = {}} = factoryInfo

        return (
            <div className="PackageAddressEditor">
                <div className="HeadingPackage">
                    <div className="HeadingTitle">Package Information</div>
                    <div className="ShippingCarrier">
                        {
                            supplierSku === 'YC' && <>
                                <label>Shipping carrier</label>
                                <Select value={shipping.carrier ? shipping.carrier : shippingCarrier} onChange={this._handleChangeShippingCarrier}>
                                    <Option value="Standard shipping">Standard shipping</Option>
                                    <Option value="Fast shipping">Fast shipping</Option>
                                    <Option value="DHL Express">DHL Express</Option>
                                    <Option value="USPS">USPS</Option>
                                </Select>
                            </>
                        }
                    </div>
                </div>

                {
                    previews.hasOwnProperty('ghtk_info') ?
                        <CodAddress previews={previews} {...this.props}/> :
                        <StandardAddress previews={previews} {...this.props}/>
                }
            </div>
        )
    }
}

PackageAddressEditor.propTypes = {
    shippingCarrier: PropTypes.string,
    supplierSku: PropTypes.string,
    previews: PropTypes.object.isRequired,
    onChangePreview: PropTypes.func.isRequired,
}


export default PackageAddressEditor
