import React from 'react'
import DocTitleByStore from '../../dashboard/shared/DocTitleByStore'
import PackageSplittingRulesPage from './PackageSplittingRulesPage'
import '../styles/_index.scss'

function PackageSplittingRulesContainer() {
    return (
        <div className='PackageSplittingRulesContainer'>
            <DocTitleByStore title="Package Splitting Rules" />
            <PackageSplittingRulesPage />
        </div>
    )
}

export default PackageSplittingRulesContainer