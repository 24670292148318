import React, {Component} from 'react'
import PropTypes from 'prop-types'
import { Select } from 'antd'

class FulfilledPackageSupplier extends Component {
    _handleChangeSelect = value => {
        const {handleChangeSupplier} = this.props
        // const {value} = e.target

        handleChangeSupplier(value)
    }

    render() {
        const {suppliers, supplierCurrent, fulfilledPackage, paymentStatus, permissionsData} = this.props
        const {factory_info = {}, shipping_carrier = ''} = fulfilledPackage
        const {shipping = {}} = factory_info
        const disabled = !permissionsData.hasOwnProperty('ffm_order_detail_change_supplier')

        return (
            <>
                <div className="FulfilledPackageSupplier d-flex align-items-center">
                    <label htmlFor="updateSupplier" className="font-weight-500 mr-3" style={{minWidth: '67px'}}>Supplier:</label>

                    <Select 
                        // className="form-control" 
                        disabled={disabled} 
                        value={supplierCurrent}
                        onChange={this._handleChangeSelect}
                        id="updateSupplier"
                        placeholder="Select supplier..."
                        style={{width: 236, borderRadius: 4}}
                        showSearch
                        filterOption={(input, option) => 
                            option.children.join('').toLocaleLowerCase().includes((input || "").toLocaleLowerCase())
                        }
                    >
                        {/* <Select.Option value="" disabled>Select supplier...</Select.Option> */}
                        {
                            suppliers.map(supplier => {
                                return <Select.Option key={supplier._id} value={supplier._id}>{supplier.name}{supplier.need_charge_have_sleeves ? ` - $${supplier.need_charge_have_sleeves} for sleeve` : ''}</Select.Option>
                            })
                        }
                    </Select>
                </div>
                {
                    !!shipping_carrier && <div className="ShippingCarrier mt-1">
                        <label className="font-weight-500 mr-3 mb-0">Shipping carrier:</label>{shipping_carrier}
                    </div>
                }
                {
                    !!shipping.carrier && <div className="ShippingCarrier mt-1">
                        <label className="font-weight-500 mr-3 mb-0">Shipping method:</label>{shipping.carrier}
                    </div>
                }
            </>
        )
    }
}

FulfilledPackageSupplier.propTypes = {
    fulfilledPackageCarrier: PropTypes.string,
    supplierCurrent: PropTypes.string.isRequired,
    supplierSelect: PropTypes.string.isRequired,
    fulfillmentId: PropTypes.string.isRequired,
    orderId: PropTypes.string.isRequired,
    suppliers: PropTypes.array.isRequired,
    handleChangeSupplier: PropTypes.func.isRequired,
    paymentStatus: PropTypes.string
}

export default FulfilledPackageSupplier
