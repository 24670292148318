import React, { useState } from 'react'
import className from 'classnames'
import { Modal, Popover } from 'antd'
import UpdateDesign from '../../actions/UpdateDesign'
import { Typography } from 'antd'
import { EditOutlined, EyeOutlined } from '@ant-design/icons'
import LazyLoad from 'react-lazyload'

const { Paragraph } = Typography

const ItemSideImage = (props) => {
    const { side, url, tagFalseDesign } = props

    const [popover, setPopover] = useState(false)
    const [modal, setModal] = useState(false)


    const _hide = () => {
        setPopover(false)
    }

    const _handlePopoverChange = popover => {
        setPopover(popover)
    }

    const _showModal = () => {
        setModal(true)
    }

    const _hideModal = () => {
        setModal(false)
    }

    return (
        <LazyLoad>
            <div className={className('ItemSideImage', { 'hasPreview': url, 'srcEditing': popover })}>
                <div className='Preview position-relative bg-light'>
                    {
                        url &&
                        <img src={url} alt='preview' className='position-absolute' />
                    }

                    <span className='Side position-absolute'><span className='badge bg-white'>{side}</span></span>

                    <div className='Actions position-absolute'>
                        {/* <Popover popover={popover} title='Edit url' trigger='click' placement='bottom'
                            content={<UpdateDesign {...props} onSuccess={_hide} />}
                            onVisibleChange={_handlePopoverChange}>
                            <span className={`Icon ${tagFalseDesign ? 'FalseDesignAction' : ''}`}>
                                <EditOutlined style={{ color: url ? '#ffffff' : '' }} />
                            </span>
                        </Popover> */}

                        {
                            url &&
                            <>
                                <div className={`Icon ${tagFalseDesign ? 'FalseDesignAction' : ''}`}>
                                    <EyeOutlined onClick={_showModal} style={{ color: '#ffffff' }} />
                                    <Modal visible={modal} closable={false} centered={true} footer={null} width={1000}
                                        onCancel={_hideModal}>
                                        <img src={url} alt='full' className='d-block m-auto' />
                                    </Modal>
                                </div>
                                <span className='Icon'><Paragraph copyable={{ text: url }} className='mb-0' /></span>
                            </>
                        }
                    </div>
                </div>
            </div>
        </LazyLoad>

    )
}

export default ItemSideImage
