import React from 'react'

const ShippingsTableHead = () => {
    return (
        <thead className="ShippingsTableHead">
            <tr>
                <th className="Supplier">Supplier</th>
                <th className="Shipping_plan">Shipping plan</th>
                <th className="Zone">Zone</th>
                <th className="Store">Store</th>
                <th className="Product_title">Product title</th>
                <th className="First_item">First item</th>
                <th className="Additional_item">Additional item</th>
                <th className="Action">Action</th>
            </tr>
        </thead>
    )
}

export default ShippingsTableHead