import React, {Component} from 'react'
import PropTypes from 'prop-types'
import {Input} from 'antd'

class Namespace extends Component {
    state = {
        namespace: this.props.namespace,
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.namespace !== this.props.namespace) {
            this.setState({
                namespace: this.props.namespace,
            })
        }
    }

    _handleChangeInput = e => {
        const {value} = e.target

        this.setState({
            namespace: value,
        })
    }

    _handleFormSubmit = value => {
        this.props.onChangeFilterState({'namespace': value})
    }

    render() {
        const {namespace} = this.state

        return (
            <div className="Namespace mr-3 mb-3 StoreNamePLF">
                <div className="font-weight-500 LabelFilter mb-1">Namespace:</div>

                <Input.Search value={namespace} placeholder='Enter to search...' onChange={this._handleChangeInput}
                              onSearch={this._handleFormSubmit} style={{width: 220}} allowClear/>
            </div>
        )
    }
}

Namespace.propTypes = {
    onChangeFilterState: PropTypes.func.isRequired,
    namespace: PropTypes.string.isRequired,
}

export default Namespace
