import createAPIServices from './createApiServices'

const baseUrl = process.env.NODE_ENV === 'production' ? '/api/setting' : 'https://fulfillment-staging.merchize.com/api/setting'

const api = createAPIServices({baseUrl})

export const getSettings = (category) => {
    return api.makeAuthRequest({
        url: '/global/settings',
        params: {category},
        method: 'get'
    })
}

export const saveSettings = (settings) => {
    return api.makeAuthRequest({
        url: '/global/settings',
        data: settings,
        method: 'post',
    })
}
