import React, {Component} from 'react'
import  WebhookStatusStyle from '../../../../shared/WebhookStatusStyle'
import PropTypes from 'prop-types'
class WebhookStatus extends Component {
    render() {
        const {webhook} = this.props
        const {status, is_sent} = webhook

        return (
            <td className="WebhookStatus">
                <WebhookStatusStyle status={status} is_sent={is_sent}/>
            </td>
        )
    }
}

WebhookStatus.propTypes = {
    webhook: PropTypes.object.isRequired,
}

export default WebhookStatus
