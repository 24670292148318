import React, { Component, createRef } from 'react'
import PropTypes from 'prop-types'
import DatePaid from "../orders/components/filters/DatePaid"
import CategoriesSelector from "../productsV2/components/CategoriesSelector"
import SuppliersSelector from "../productsV2/components/SuppliersSelector"
import { Select } from "antd"
import {availableTiktokOptions, listStoreModes, productLabelOptions } from "../../statics/storeModes"

const { Option } = Select

class TableFilter extends Component {
    constructor(props) {
        super(props)
        const { isDateOn } = this.props
        this.state = {
            entityPerPage: this.props.defaultLimit || 10,
            search: '',
            searchField: this.props.searchField.length ? this.props.searchField[0] : null,
            dateOn: isDateOn,
            selectorOn: this.props.selectorStatus,
            productLabel: ''
        }

        this.timer = null
        this._input = createRef()
    }

    _onChangeSelectEntity = (e) => {
        const { value } = e.target

        this.setState({
            entityPerPage: value,
        }, () => this.props.onSubmitForm({
            limit: this.state.entityPerPage,
            value: this.state.search,
            field: this.state.searchField,
        }))
    }

    _onChangeInputFilter = (e) => {
        const { value } = e.target

        this.setState({
            search: value
        }, () => {
            if (this.timer) clearTimeout(this.timer)
            this.timer = setTimeout(this._submitForm, 500)
        })
    }

    _onChangeSelectSearchField = (e) => {
        const { value } = e.target
        this._input.focus()

        this.setState({
            searchField: value,
            search: '',
        })
    }

    _submitForm = () => {
        const { entityPerPage, search, searchField } = this.state

        this.props.onSubmitForm({
            limit: entityPerPage,
            value: search,
            field: searchField,
        })
    }

    _onSubmitForm = (e) => {
        e.preventDefault()
        if (this.timer) clearTimeout(this.timer)

        this._submitForm()
    }

    _handleChangeStoreMode = (value) => {
        const { onChangeStoreMode } = this.props

        if (typeof onChangeStoreMode === 'function') {
            onChangeStoreMode(value)
        }
    }

    _handleChangeCurrency = (value) => {
        const { onChangeCurrency } = this.props

        if (typeof onChangeCurrency === 'function') {
            onChangeCurrency(value)
        }
    }

    render() {
        const {
            total, placeholder, searchField, placeholders, categoriesId, handleSelectCategory, onChangeLabel,
            storeModes, showStoreMode, showProductLabel, showSupplier, onChangeSupplier, supplier, showAvailableTiktok, onChangeAvailableTiktok
        } = this.props
        const { entityPerPage, search, dateOn, selectorOn } = this.state
        
        return (
            <div className="TableFilter mb-3">
                <div className="Filter">
                    <div className="d-flex flex-wrap align-items-baseline justify-content-start">
                        {dateOn ?
                            <div className="mr-3">
                                <DatePaid onChangeFiltersState={this.props.onChangeFiltersState} />
                            </div>
                            : null
                        }
                        <div className="FilterWrapper FilterOptions mb-3 mr-3">
                            <label className="Heading">{this.state.searchField}</label>
                            <form className="d-flex align-items-stretch justify-content-end"
                                onSubmit={this._onSubmitForm}
                            >
                                <div className="input-group-prepend SelectWrapper">
                                    {searchField.length > 0 ? <select
                                        value={this.state.searchField}
                                        className="form-control"
                                        onChange={this._onChangeSelectSearchField}
                                    >
                                        {searchField.map((item, i) => <option key={i} value={item}>{item}</option>)}
                                    </select> :
                                        <span className="input-group-text">
                                            <i className="ti ti-search" />
                                        </span>}
                                </div>

                                <div className="InputWrapper position-relative">
                                    <i className="ti-search position-absolute d-none" />
                                    <input
                                        value={search}
                                        onChange={this._onChangeInputFilter}
                                        className="form-control InputSearch"
                                        placeholder={searchField.length ? placeholders[this.state.searchField] : placeholder}
                                        ref={input => this._input = input}
                                    />
                                </div>
                            </form>
                        </div>

                        {showStoreMode && (
                            <div className='CustomSelect FilterOptions mr-3 mb-3'>
                                <label className="d-block Heading">Store mode</label>
                                <Select
                                    mode="multiple"
                                    size={'large'}
                                    allowClear
                                    showArrow
                                    style={{ width: 200 }}
                                    defaultValue={storeModes}
                                    value={storeModes}
                                    onChange={this._handleChangeStoreMode}
                                    placeholder={'Store Mode'}
                                >
                                    {
                                        listStoreModes.map(item => {
                                            return <Option value={item.value} key={item.value}>{item.name}</Option>
                                        })
                                    }
                                </Select>
                            </div>
                        )}

                        {selectorOn ?
                            <div className="selector-group FilterOptions mr-3 mb-3">
                                <label className="Heading">Categories</label>
                                <form>
                                    <CategoriesSelector
                                        categories={categoriesId}
                                        handleSelectCategory={handleSelectCategory}
                                    />
                                </form>
                            </div>
                            : null
                        }

                        {
                            showProductLabel && (
                                <div className='FilterOptions mr-3 mb-3'>
                                    <label className="d-block Heading">Product label</label>
                                    <Select
                                        size={'large'}
                                        allowClear
                                        showArrow
                                        onChange={onChangeLabel}
                                        style={{ width: 200 }}
                                        placeholder={'Product label'}
                                    >
                                        {
                                            productLabelOptions.map(item => {
                                                return <Option value={item.value} key={item.value}>{item.name}</Option>
                                            })
                                        }
                                    </Select>
                                </div>
                            )
                        }

                        {
                            showSupplier && <div className='CustomSelect FilterOptions mr-3 mb-3'>
                                <label className="d-block Heading">Supplier</label>
                                <form>
                                    <SuppliersSelector
                                        supplier={supplier}
                                        handleSelectSupplier={onChangeSupplier}
                                    />
                                </form>
                            </div>
                        }

                        {
                            showAvailableTiktok && (
                                <div className='FilterOptions mr-3 mb-3'>
                                    <label className="d-block Heading">Available Tiktok</label>
                                    <Select
                                        size={'large'}
                                        allowClear
                                        showArrow
                                        onChange={onChangeAvailableTiktok}
                                        style={{width: 200}}
                                        placeholder={'Available Tiktok'}
                                    >
                                        {
                                            availableTiktokOptions.map(item => {
                                                return <Option value={item.value} key={item.value}>{item.name}</Option>
                                            })
                                        }
                                    </Select>
                                </div>
                            )
                        }
                    </div>
                </div>

                <div className="d-sm-flex align-items-center ShowTotal d-flex justify-content-end mt-2 ">
                    <div className="TableLength ml-3 d-flex flex-nowrap align-items-center">Show
                        <select
                            value={entityPerPage}
                            onChange={this._onChangeSelectEntity}
                        >
                            <option value="10">10 rows</option>
                            <option value="20">20 rows</option>
                            <option value="50">50 rows</option>
                            <option value="100">100 rows</option>
                        </select> entries
                    </div>
                    <div className="TotalEntity ml-3 customTotal">
                        Total <span className="Entity ml-2 mr-2">{total}</span> items
                    </div>
                </div>

            </div>
        )
    }
}

TableFilter.defaultProps = {
    defaultLimit: 10,
    placeholder: '',
    searchField: [],
    placeholders: {},
    storeModes: [],
    showStoreMode: false,
    showProductLabel: false,
    showCurrency: false,
    showAvailableTiktok: false,
}

TableFilter.propsTypes = {
    total: PropTypes.number.isRequired,
    defaultLimit: PropTypes.number,
    onSubmitForm: PropTypes.func.isRequired,
    placeholder: PropTypes.string,
    placeholders: PropTypes.object,
    searchField: PropTypes.array,
    storeModes: PropTypes.func,
    showStoreMode: PropTypes.bool,
    showProductLabel: PropTypes.bool,
    showCurrency: PropTypes.bool,
    onChangeCurrency: PropTypes.func
}

export default TableFilter
