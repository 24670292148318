import React from 'react'

const OrdersError = (props) => {
    return (
        <tr className='OrdersError'>
            <td colSpan={14}>
                <div className='text-danger'>
                    <span className='font-weight-bold'>ERROR:</span> {props.error}
                </div>
            </td>
        </tr>
    )
}

export default OrdersError