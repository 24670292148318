import React from 'react'
import ItemSideDefault from './ItemSideDefault'
import ItemSideImage from './ItemSideImage'

const ItemSide = (props) => {
    const { layout, ...rest } = props
    const _renderLayout = () => {
        if ('default' === layout) {
            return <ItemSideDefault {...rest} />
        } else if ('image' === layout) {
            return <ItemSideImage {...rest} />
        }
        return ''
    }

    const content = _renderLayout()
    return (
        <div className='ItemSide'>
            {content}
        </div>
    )
}

export default ItemSide
