import React, {Component} from 'react'
import {Button, notification} from 'antd'
import PropTypes from 'prop-types'
import {reqGenerateLabel} from '../helper/shippingLabelActions'
import {PrinterOutlined} from '@ant-design/icons'
import print from '../../shared/printImage'

class ShippingLabelActions extends Component {
    state = {
        loading: false,
    }

    _handleClickPrint = e => {
        e.preventDefault()
        const {order} = this.props

        this.setState({
            loading: true,
        })

        reqGenerateLabel(order._id).then(labelUrl => {
            console.log('Label url', labelUrl)

            if (labelUrl) {
                print(labelUrl)
            }
        }).catch(error => {
            notification.error({
                message: 'ERROR',
                description: error.message,
                duration: 0,
            })
        }).finally(() => {
            this.setState({
                loading: false,
            })
        })
    }

    render() {
        const {loading} = this.state

        return (
            <div className='ShippingLabelActions'>
                <Button type='primary' ghost icon={<PrinterOutlined />} onClick={this._handleClickPrint} loading={loading}>Print
                    QR</Button>
            </div>
        )
    }
}

ShippingLabelActions.propTypes = {
    order: PropTypes.object.isRequired,
}

export default ShippingLabelActions
