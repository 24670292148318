import React, {Fragment, useState} from 'react'
import {Spin} from 'antd'
import TableHead from './data/TableHead'
import TableRow from './data/TableRow'
import emptyData from '../../../statics/assets/images/empty-img-gray.png'
import OrderPartnersPagination from "./OrderPartnersPagination"

const OrderPartnersTable = (props) => {
    const {loading, orders, selectAll, listSelected, onChangeSelectAll, onChangeSelectedPackage, allowedUpdateTags} = props
    const [loadingSend, _setLoadingSend] = useState(false)


    return (
        <div className="NotificationTable OrderSupplies">
            <Spin spinning={loading} tip='Loading...'>
                {orders.length > 0 ? (
                    <div>
                        <div className="wrapTable">
                            <table className='table'>
                                <TableHead
                                    selectAll={selectAll}
                                    listSelected={listSelected}
                                    onChangeSelectAll={onChangeSelectAll}
                                    orders={orders}
                                />
                                <tbody>
                                {
                                    orders.map((item, index) => {
                                        return (
                                            <Fragment key={item._id}>
                                                <TableRow
                                                    indexRow={index}
                                                    loadingSend={loadingSend}
                                                    item={item}
                                                    currentPage={1}
                                                    listSelected={listSelected}
                                                    handleSendLoading={_setLoadingSend}
                                                    onChangeSelectedPackage={onChangeSelectedPackage}
                                                    allowedUpdateTags={allowedUpdateTags}
                                                />
                                            </Fragment>
                                        )
                                    })
                                }
                                </tbody>
                            </table>
                        </div>
                        <OrderPartnersPagination {...props}/>
                    </div>
                ) : !loading && (
                    <div className="noData d-flex flex-column align-items-center justify-content-center">
                        <img src={emptyData} alt="No data"/>
                        Không có bản ghi!
                    </div>
                )}
            </Spin>
        </div>
    )
}

export default OrderPartnersTable
