import React, {Component} from 'react'
import PropTypes from 'prop-types'
import {Link} from 'react-router-dom'
import platformSchema from '../statistics/table-schema'

class PlatformsTable extends Component {
    _onChangeStatus = (platform) => (e) => {
        const {value} = e.target
        this.props.changeStatus(platform, value)
    }

    _onClickEditPlatform = (platform) => () => {
        this.props.onOpenEditPlatform(platform)
    }

    _onClickEditWebhook = (platformId) => () => {
        this.props.onOpenEditWebhook(platformId)
    }

    render() {
        const {platforms, loading, permissionsData} = this.props
        const disableAll = !permissionsData.includes('ffm_platform_update')

        return (
            <div className="PlatformsTable table-responsive">
                <table className="table table-striped Table">
                    <thead className="thead-light">
                        <tr>{platformSchema.map((row) =>
                            !(row.name === 'action' && !permissionsData.includes('ffm_platform_update')) &&
                            <th key={row.name}>{row.title}</th>
                        )}
                        </tr>
                    </thead>

                    {
                        !loading.fetchPlatforms && <tbody>
                        {
                            platforms.map((platform) => <tr key={platform._id}>
                                <td>
                                    <Link to={`/a/platforms/${platform._id}`}>
                                        {platform.name}
                                    </Link>
                                </td>
                                <td>
                                    <select
                                        disabled={loading.changeStatus || disableAll}
                                        className="custom-select FocusOutline"
                                        value={platform.status}
                                        onChange={this._onChangeStatus(platform)}>
                                        <option value={'active'}>Active</option>
                                        <option value={'inactive'}>Inactive</option>
                                    </select>
                                </td>
                                {!disableAll && 
                                    <td>
                                        <button
                                            className="EditButton mr-3"
                                            onClick={this._onClickEditPlatform(platform)}>
                                            Edit Name <i className="fas fa-pen"/>
                                        </button>
                                        <button
                                            className="EditButton"
                                            onClick={this._onClickEditWebhook(platform._id)}>
                                            Edit Webhook <i className="fas fa-pen"/>
                                        </button>
                                    </td>
                                }
                            </tr>)
                        }
                        </tbody>
                    }
                </table>
            </div>
        )
    }
}

PlatformsTable.propTypes = {
    platforms: PropTypes.array.isRequired,
    loading: PropTypes.object.isRequired,
    changeStatus: PropTypes.func.isRequired,
    onOpenEditPlatform: PropTypes.func.isRequired,
}

export default PlatformsTable
