import React, {Component} from 'react'
import {Link} from "react-router-dom"

class ShippingPlanTitle extends Component {
    render() {
        const {title, _id} = this.props
        return (
            <td className="ShippingPlanTitle">
                <Link to={`/a/shipping/${_id}`}>{title}</Link>
            </td>
        )
    }
}

export default ShippingPlanTitle
