import React from 'react'
import {
    CheckCircleOutlined,
    CloseCircleOutlined,
} from '@ant-design/icons'

const SendModal = (props) => {
    const {data} = props

    return (
        <div className="mt-3">
            <div className="listResults">
                {data.hasOwnProperty('failed') && (
                    data.failed.length > 0 && data.failed.map(itemFailed => {
                        return <div className="item mb-2">
                            <strong>{itemFailed.store_id}</strong> - Order number: {itemFailed.order_number}
                            <div className="text-danger d-flex align-items-center">
                                <CloseCircleOutlined style={{fontsize: 16}}/>
                                <span className="d-sm-block text-secondary ml-1">{itemFailed.send_note}</span>
                            </div>
                        </div>
                    })
                )}
                {data.hasOwnProperty('errors') && (
                    data.errors.length > 0 && data.errors.map(itemError => {
                        return <div className="item mb-2">
                            <strong>{itemError.store_id}</strong> - Order number: {itemError.order_number}
                            <div className="text-danger d-flex align-items-center">
                                <CloseCircleOutlined style={{fontsize: 16}}/>
                                <span className="d-sm-block text-secondary ml-1">{itemError.send_note}</span>
                            </div>
                        </div>
                    })
                )}
                {data.hasOwnProperty('success') && (
                    data.success.length > 0 && data.success.map(itemSuccess => {
                        return <div className="item mb-2">
                            <strong>{itemSuccess.store_id}</strong> - Order number: {itemSuccess.order_number}
                            <div className="text-success d-flex align-items-center">
                                <CheckCircleOutlined style={{fontsize: 16}}/>
                                <span className="d-sm-block text-secondary ml-1">Item sent success!</span>
                            </div>
                        </div>
                    })
                )}
            </div>
        </div>
    )
}

export default SendModal
