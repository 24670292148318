import React from 'react'

function OrderProfitHead() {
    return (
    <thead 
        className="OrderProfitHead" 
        style={{ background :'#e9ecef'}}
    >
        <tr>
            <th className="Order_id" style={{minWidth:'81px'}}>Order</th>
            <th className="Supplier">Supplier</th>
            <th className="Revenue">Revenue</th>
            <th className="Charge">Charge</th>
            <th className="Refund">Refund</th>
            <th className="Production_fee">Production fee</th>
            <th className="Shipping_fee">Shipping fee</th>
            <th className="Tax">Tax</th>
            <th className="Profit">Profit</th>

        </tr>
    </thead>
    )
}

export default OrderProfitHead