import React from 'react'
import { Modal, notification, message } from 'antd'
import { updatePrintFilesStatus } from '../../../../services/api/OrdersServices'

const bulkRetry = (selectedOrders, reload, onToggleSelectAll) => {

    const _generateTemplate = async (fulfillmentId, orderId) => {
        try {
            const response = await updatePrintFilesStatus(orderId, fulfillmentId, 'retry')
            return response
        } catch (e) {
            message.error(e.message, 3)
        }
    }

    const handleMultipleRequests = async () => {
        try {
            const promises = selectedOrders.map((item) => _generateTemplate(item.orderItem, item.fulfillmentItem))
            return await Promise.all(promises)
        } catch (error) {
            throw new Error(error)
        }
    }

    Modal.confirm({
        title: 'Be sure to retry the selected orders ?',
        okText: 'Ok',
        cancelText: 'Cancel',
        async onOk() {
            const result = await handleMultipleRequests()
            const itemsFail = result.filter(item => !item.success)
            
            if (itemsFail.length > 0) {
                return itemsFail.map(item => (
                    notification.error({
                        message: 'Request error',
                        description: item.message || 'Request retry failed',
                        duration: 10
                    })
                ))
            } else {
                notification.success({
                    message: 'Request successful',
                    description: 'Request retry successfully',
                    duration: 3
                })
                reload()
                onToggleSelectAll(false)
            }
        }
    })
}

export default bulkRetry