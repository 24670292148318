import {createStoreAPI} from "./createStoreAPIService"

const api = createStoreAPI({
    getBaseURL: (currentStore, isProduction, getStoreSetting) => {
        return isProduction ? '/api/seller' : 'https://fulfillment-staging.merchize.com/api/seller'
    }
})

export const getListStores = () => {
    return api.makeAuthRequest({
        url: '/stores',
        method: 'GET'
    })
}

export const getListPendingStores = () => {
    return api.makeAuthRequest({
        url: '/stores/pending',
        method: 'GET'
    })
}

export const createStore = (data = {}) => {
    return api.makeAuthRequest({
        url: '/stores',
        method: 'POST',
        data
    })
}

export const fetchStoreSettingsBySlug = slug => {
    return api.makeAuthRequest({
        url: `/stores/by-slug/${slug}/settings`,
        method: 'GET',
    })
}

export const authorizeWithStoreSlug = slug => {
    return api.makeAuthRequest({
        url: `/stores/by-slug/${slug}/authorize`,
        method: 'GET',
    })
}

export const goToAdmin = (env = '') => {
    return api.makeAuthRequest({
        url: `/admin/redirect`,
        method: 'POST',
        data: {
            env
        }
    })
}
