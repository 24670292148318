import React, {Component} from 'react'
import Modal from "reactstrap/es/Modal"
import {Input, Label} from "reactstrap/es"
import ModalBody from "reactstrap/es/ModalBody"
import ModalHeader from "reactstrap/es/ModalHeader"
import {updateOrderFulfillmentTracking} from "../../../../services/api/OrdersServices"
import broker from '../../OrderBroker'
import { getLocalData } from '../../../../services/StorageServices'
import { Tooltip } from 'antd'

class FulfilledPackageTracking extends Component {
    state = {
        trackingNumber: this.props.number,
        trackingUrl: this.props.url,
        carrier: this.props.company || "Other",
        showModal: false,
        error: "",
        lockedTracking: this.props.fulfillment.lock_tracking_number
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const {fulfillment} = this.props
        const {fulfillment: prevFulfillment} = prevProps

        if (prevFulfillment.lock_tracking_number === fulfillment.lock_tracking_number) {
            return
        }

        if (fulfillment.lock_tracking_number !== this.state.lockedTracking) {
            this.setState({
                lockedTracking: fulfillment.lock_tracking_number
            })
        }
    }

    _handleClickToggleModal = e => {
        this.setState({
            showModal: !this.state.showModal,
        }, () => {
            if (!this.state.showModal) {
                const {trackingNumber, trackingUrl, carrier} = this.state
                const {number, url, company} = this.props

                if (trackingNumber !== number || trackingUrl !== url || carrier !== company) {
                    this.setState({
                        trackingNumber: number,
                        trackingUrl: url,
                        carrier: company || "Other",
                    })
                }
            }
        })
    }

    _handleChangeFormControl = e => {
        const {name, value} = e.target

        this.setState({
            [name]: value,
        })
    }

    _handleSaveTracking = async () => {
        try {
            const {fulfillmentId, orderId, fetchHistory} = this.props
            const {trackingNumber, trackingUrl, carrier, lockedTracking} = this.state

            const sendData = {
                tracking_number: trackingNumber,
                tracking_company: carrier,
                lock_tracking_number: lockedTracking
            }

            if (carrier === "Other") {
                sendData.tracking_url = trackingUrl
            }

            const {success, message} = await updateOrderFulfillmentTracking(orderId, fulfillmentId, sendData)

            if (!success) {
                return this.setState({
                    error: message,
                })
            }

            fetchHistory && fetchHistory()

            broker.publish("TRACKING_UPDATED")

            this.setState({
                showModal: false,
                error: "",
            })


        } catch (e) {
            return this.setState({
                error: e.message,
            })
        }
    }

    setLockedTracking = (e) => {
        const {checked} = e.target
        const {lock_tracking_number} = this.props.fulfillment

        if (lock_tracking_number && !checked) {
            if (!window.confirm('Do you want to do this?')) {
                return
            }
        }

        this.setState({
            lockedTracking: checked
        })
    }

    render() {
        const {showModal, error, trackingNumber, trackingUrl, carrier, lockedTracking} = this.state
        const {company, number, url, fulfillment} = this.props

        const permissionsData = getLocalData('permissions') ? getLocalData('permissions') : {}

        return (
            <div className="FulfilledPackageTracking">
                <div className="d-flex justify-content-end">
                    {
                        fulfillment.lock_tracking_number &&
                        <span className="mr-1 ml-1 text-muted" title={`Locked auto update tracking number`}>
                            <i className="fas fa-lock"/></span>
                    }
                    {
                        !!number && <a href={url} target="_blank" rel="noopener noreferrer">{number}</a>
                    }
                    {
                        !!company && <span>, {company}</span>
                    }

                    {
                        permissionsData.hasOwnProperty('ffm_order_detail_update_tracking') &&
                        <Tooltip title="Edit tracking">
                            <span className="text-primary cursor-pointer ml-3" onClick={this._handleClickToggleModal}>
                                <i className="ti-pencil"/>
                            </span>
                        </Tooltip>
                    }
                    
                </div>

                <Modal isOpen={showModal} toggle={this._handleClickToggleModal} size="lg" className="ModalEditTracking">
                    <ModalHeader toggle={this._handleClickToggleModal}>Edit tracking</ModalHeader>
                    <ModalBody>
                        <div className="form-group">
                            <label htmlFor="trackingNumber">Tracking number*</label>
                            <input type="text" value={trackingNumber || ""} name="trackingNumber"
                                   className="form-control" id="trackingNumber"
                                   required onChange={this._handleChangeFormControl}/>
                        </div>

                        <div className="form-group">
                            <label htmlFor="shippingCarrier">Shipping carrier*</label>
                            <select name="carrier" value={carrier || "Other"} id="shippingCarrier"
                                    className="form-control" required
                                    onChange={this._handleChangeFormControl}>
                                <option value="USPS">USPS</option>
                                <option value="DHL eCommerce">DHL eCommerce</option>
                                <option value="SF Express">SF Express</option>
                                <option value="UPS">UPS</option>
                                <option value="Deutsche Post">Deutsche Post</option>
                                <option value='Parcelforce'>Parcelforce</option>
                                <option value='DHL Express'>DHL Express</option>
                                <option value='FedEx'>FedEx</option>
                                <option value='Yun Express'>Yun Express</option>
                                <option value='SFC Service'>SFC Service</option>
                                <option value='UK Royal Mail'>UK Royal Mail</option>
                                <option value='Hermesworld'>Hermesworld</option>
                                <option value="Spring GDS">Spring GDS</option>
                                <option value='Giao Hàng Tiết Kiệm'>Giao Hàng Tiết Kiệm</option>
                                <option value="Other">Other</option>
                            </select>
                        </div>

                        {
                            carrier === "Other" &&
                            <div className="form-group">
                                <label htmlFor="trackingUrl">Tracking url</label>
                                <input type="text" value={trackingUrl || ""} name="trackingUrl"
                                       className="form-control"
                                       id="trackingUrl"
                                       onChange={this._handleChangeFormControl}/>
                            </div>
                        }

                        <div className="d-flex justify-content-end mt-4">
                            <Label className="CheckboxLockTracking">
                                <Input type="checkbox" checked={lockedTracking} onChange={this.setLockedTracking}/>
                                {lockedTracking && <i className="fas fa-lock"/>}
                                {!lockedTracking && <i className="fas fa-unlock"/>}
                                Lock auto updating tracking
                            </Label>
                            <button className="btn border" onClick={this._handleClickToggleModal}>Cancel</button>
                            <button className="btn btn-primary ml-3" onClick={this._handleSaveTracking}>
                                Save changes
                            </button>
                        </div>

                        {
                            !!error && <div className="mt-3 text-danger">{error}</div>
                        }
                    </ModalBody>
                </Modal>
            </div>
        )
    }
}

export default FulfilledPackageTracking
