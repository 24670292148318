import humanizeTime from "../../../helpers/time/humanizeTime"
import ObjectInspector from "react-object-inspector"
import {Button, Spin, Select} from "antd"
import React, {useEffect, useState} from "react"
import {getMappingConditionHistory} from "../../../services/api/MappingConditionServices"

const MappingConditionHistory = (props) => {
    const {
        mappingCondition,
        onFetched,
        reload
    } = {...props}
    const [history, setHistory] = useState([])
    const [loading, setLoading] = useState(true)
    const [query, setQuery] = useState({
        page: 1,
        total: 0,
        pages: 0,
        limit: 10
    })
    const fetchHistory = () => {
        setLoading(true)
        getMappingConditionHistory(mappingCondition._id, {
            page: query.page,
            limit: query.limit
        }).then((response) => {
            const {
                items,
                total,
                pages
            } = {...response.data}

            setHistory(items)
            setQuery({
                ...query,
                total,
                pages
            })

            onFetched && onFetched({total})
            setLoading(false)
        })
    }

    const moveToPage = (inc) => () => {
        setQuery({
            ...query,
            page: query.page + inc
        })
    }

    const handleChangeLimit = (n) => {
        setQuery({
            ...query,
            limit: +n
        })
    }

    useEffect(() => {
        fetchHistory()
    }, [query.page, query.limit, mappingCondition._id, reload])

    return mappingCondition._id
        ? <div className={[`tab-history`, loading && 'loading'].filter(Boolean).join(' ')}>
            {
                !history || !history.length && <p>No data</p>
            }
            {
                !!history && !!history.length && <div align="right" className="mb-4">
                    Show items <Select defaultValue="10" style={{width: 120}} onChange={handleChangeLimit}>
                    {
                        [5, 10, 20, 50, 100].map(n => {
                            return <Select.Option key={n} value={n} disabled={n === query.limit}>{n}</Select.Option>
                        })
                    }
                </Select>
                </div>
            }
            {
                !!history && <ul>
                    {
                        history.map(h => {
                            const {
                                _id: historyId,
                                event,
                                created_at: createdAt,
                                description,
                                meta
                            } = h

                            return <li className="card" key={historyId}>
                                <div className="card-header">
                                    <time title={createdAt}>{humanizeTime(createdAt)}</time>
                                </div>
                                <div className="card-body">
                                    <div className="row align-items-baseline mb-3">
                                        <div className="col-4"><code>{event}</code>
                                        </div>
                                        <div className="col-8 text-muted">{description}</div>
                                    </div>
                                    <div className="row align-items-baseline">
                                        <div className="col-4">Value</div>
                                        <div className="col-8">
                                            <ObjectInspector depth={0} patxh="root.value" data={meta}/>
                                        </div>
                                    </div>
                                </div>
                            </li>
                        })
                    }
                </ul>
            }
            {
                query.pages > 1 && <div className="history-actions">
                    <Button disabled={query.page <= 1} onClick={moveToPage(-1)}>
                        <i className="ti-arrow-left"/>
                    </Button>
                    <span>{query.page} / {query.pages}</span>
                    <Button disabled={query.page >= query.pages} onClick={moveToPage(1)}>
                        <i className="ti-arrow-right"/>
                    </Button>
                </div>
            }

            {
                loading && <div className="loading"><Spin size="large"/></div>
            }
        </div>
        : ''
}

export default MappingConditionHistory