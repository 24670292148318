import React, {Component} from 'react'
import formatCurrency from "../../../../../../helpers/common/formatCurrency"

class ProductLineVariantPrice extends Component {
    render() {
        const {price, currency} = this.props

        return (
            <td className="ProductLineVariantPrice">
                {formatCurrency(currency, price)}
            </td>
        )
    }
}

export default ProductLineVariantPrice
