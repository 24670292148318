import React from 'react'
import PropTypes from 'prop-types'
import ReactPaginate from "react-paginate"

const ProductsPagination = props => {
    const _handlePageChange = (data) => {
        const { selected } = data

        props.onPagePagination(selected + 1)
    }

    const { pages, page } = props

    return (
        pages > 1 &&
        <div className="ProductsPagination d-flex justify-content-end Pagination">
            <ReactPaginate
                previousLabel="Previous"
                nextLabel="Next"
                pageCount={pages}
                forcePage={page - 1}
                marginPagesDisplayed={2}
                pageRangeDisplayed={5}
                onPageChange={_handlePageChange}
                containerClassName="pagination"
                pageClassName='page-item'
                previousClassName='Previous page-item'
                nextClassName='Next page-item'
                pageLinkClassName='page-link'
                previousLinkClassName='page-link'
                nextLinkClassName='page-link'
                subContainerClassName="pages pagination"
                activeClassName='active'
            />
        </div>
    )
}

ProductsPagination.propTypes = {
    pages: PropTypes.number.isRequired,
    page: PropTypes.number.isRequired,
    onPagePagination: PropTypes.func.isRequired,
}

export default ProductsPagination
