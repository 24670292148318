import React from 'react'
import ProductsTableHead from "./ProductsTable/ProductsTableHead"
import ProductsTableRow from "./ProductsTable/ProductsTableRow"
import PropTypes from 'prop-types'

const ProductsTable = props => {
    const { products, onChangeStoreMode, onChangeSupplier } = props

    return (
        <div className="ProductsTable">
            <table className='table'>
                <ProductsTableHead />
                <tbody>
                    {
                        products.map((product, i) => {
                            return <ProductsTableRow key={product._id} index={i} product={product}
                                onChangeStoreMode={onChangeStoreMode} onChangeSupplier={onChangeSupplier}
                                />
                        })
                    }
                </tbody>
            </table>
        </div>
    )
}

ProductsTable.propTypes = {
    products: PropTypes.array.isRequired,
}

export default ProductsTable
