import React, {useState} from 'react'
import {Button, Modal} from "antd"
import {ScanOutlined} from '@ant-design/icons'
import {Select} from 'antd'
import {addTrackingScan} from '../../../../services/api/ShippingServices'
import getHistory from "../../../../store/getHistory"

const {Option} = Select

function ScanTracking(props) {
    const {filters, disabled} = props
    const [visible, setVisible] = useState(false)
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState('')
    const [carrier, setCarrier] = useState('')

    const _showModal = () => {
        setVisible(true)
    }

    const _handleChange = (value) => {
        setCarrier(value)
    }

    const _addTrackingScan = async () => {
        setLoading(true)
        setError('')

        try {
            const history = getHistory()
            const payload = {
                ...filters,
                carrier
            }
            const response = await addTrackingScan(payload)

            const {success, message, data} = response

            if (!success) {
                setLoading(false)
                return setError(message)
            }

            setLoading(false)

            if (data._id) {
                history.push(`/a/switch-carrier/${data._id}?status=${data.status}`)
            }
        } catch (e) {
            setLoading(false)
            setError(e.message)
        }
    }

    const _handleOk = async () => {
        await _addTrackingScan()
    }

    const _handleCancel = () => {
        setVisible(false)
    }

    return (
        <div className="ScanTracking">
            <Button type="primary" onClick={_showModal} disabled={disabled}>
                <ScanOutlined/>
                Scan Tracking
            </Button>
            <Modal
                className="ScanTracking"
                width={550}
                visible={visible}
                title="Scan tracking with other carrier"
                onOk={_handleOk}
                onCancel={_handleCancel}
                footer={[
                    <Button key="back" onClick={_handleCancel}>
                        Cancel
                    </Button>,
                    <Button key="submit" type="primary" disabled={carrier === ''} loading={loading} onClick={_handleOk}>
                        Scan
                    </Button>
                ]}
            >
                <p className='text-danger'>*The system only rescans the tracking numbers other than the delivery
                    status.</p>
                <div className="Content">
                    <label htmlFor="carrier">Shipping carrier</label>
                    <Select defaultValue={carrier} onChange={_handleChange}>
                        <Option value="">Choose shipping carrier</Option>
                        <Option value="DHL eCommerce">DHL eCommerce</Option>
                        <Option value="USPS">USPS</Option>
                        <Option value="UPS">UPS</Option>
                    </Select>
                </div>

                {!!error && error.length > 0 && <div className="text-danger mt-3">{error}</div>}
            </Modal>
        </div>
    )
}

export default ScanTracking
