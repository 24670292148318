import React, { useState, useContext } from 'react'
import { Modal, Input, Progress, Select } from 'antd'
import { addOrderTag, updatePackageTags } from "../../../../services/api/OrdersServices"
import OrderPartnersContext from "../../context/OrderPartnersContext"
import { ExclamationCircleOutlined, CheckCircleOutlined } from '@ant-design/icons'

const { TextArea } = Input

function BulkActionAddTags(props) {
    const { open, onCancel } = { ...props }
    const { listSelected, packageLists, allowedUpdateTags = {}, loadOrders, onChangePackageData } = useContext(OrderPartnersContext)
    const [tags, setTags] = useState([])
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState('')
    const [listProcessing, setListProcessing] = useState(packageLists.filter(item => listSelected.includes(item._id)))
    const [listSuccess, setListSuccess] = useState([])
    const [listError, setListError] = useState([])

    const handleCancel = () => {
        onCancel()
        // loadOrders()
    }

    const _handleAddTags = async () => {
        setListSuccess([])
        setListError([])
        setLoading(true)

        let processed = []

        try {
            if (!tags || !(tags.length > 0)) {
                return setError('Tags is required')
            }
            const payload = {
                bulkActions: [
                    {
                        action: 'add',
                        packages: listProcessing.length > 0 ? listProcessing.map(i => ({
                            _id: i._id,
                            orderId: i.order._id,
                        })) : [],
                        tags: tags,
                    }
                ]
            }

            const response = await updatePackageTags(payload)

            const { success, message: mess } = { ...response }
            if (!success) {
                listProcessing.map(async item => {
                    return processed.push({ ...item, success: success, error: mess, processing: false })
                })

            } else {
                listProcessing.map(async item => {
                    const selectedItem= packageLists.filter(li =>li._id===item._id)
                    const index= packageLists.indexOf(selectedItem[0])
                    onChangePackageData(index,'did_actions',[...new Set([...tags, ...selectedItem[0].did_actions])])
                    return processed.push({ ...item, success: success, processing: false })
                })
            }


            let newListSuccess = []
            let newListError = []

            processed.map(process => {
                if (process.success === true) {
                    return newListSuccess.push(process)
                }

                if (process.error.length > 0) {
                    return newListError.push(process)
                }
            })
            setListSuccess(newListSuccess)
            setListError(newListError)
            setListProcessing(processed)
            return

        } catch (e) {
            return { success: false, message: e.message }
        } finally {
            setLoading(false)
        }
    }

    const handleChangeTags = value => {
        setError('')
        setLoading(false)
        setTags(value)
    }

    const tagOptions = Object.keys(allowedUpdateTags).length ? Object.keys(allowedUpdateTags).map(key => ({
        value: key,
        label: allowedUpdateTags[key]
    })) : []

    return (
        <Modal
            title="Add tags"
            visible={open}
            onOk={_handleAddTags}
            onCancel={handleCancel}
            okText="Add"
            confirmLoading={loading}
            className='addTagsModal BulkActionAddTags'
        >
            <div className='font-weight-bold mb-2'>Tags:</div>
            <Select
                placeholder="Select tags..."
                onChange={handleChangeTags}
                value={tags || []}
                allowClear
                showSearch
                showArrow
                style={{ width: '100%' }}
                className='mb-3'
                mode='multiple'
                options={tagOptions}
            />
            {error && <span className='text-danger'>{error}</span>}
            <h6>List processing <span>{listSelected.length}</span> items</h6>
            {
                (listSuccess.length > 0 || listError.length > 0) &&
                <div className="Results d-flex align-items-center">
                    <div className="ResultSuccess mr-2">
                        Success: <span className='text-success font-weight-bold'>{listSuccess.length}</span> items
                    </div>
                    <div className="ResultError">
                        Error: <span className='text-danger font-weight-bold'>{listError.length}</span> items
                    </div>
                </div>
            }
            <div className="ListProcessing">
                <ul>
                    {!!listProcessing && listProcessing.length > 0 &&
                        listProcessing.map(item => (
                            <li key={item._id}>
                                <span>{item.name}</span>
                                {
                                    item.success === false && !!item.error &&
                                    <span className="Error"><ExclamationCircleOutlined /> {item.error}</span>
                                }
                                {
                                    item.success === true &&
                                    <span className="Success"><CheckCircleOutlined /> Added tags</span>
                                }
                                <Progress
                                    percent={100}
                                    size="small"
                                    status={`${(item.success === true) ? 'success' : ((item.success === false) ? 'exception' : 'active')}`}
                                    showInfo={false}
                                />
                            </li>
                        ))
                    }
                </ul>
            </div>
        </Modal>
    )
}

export default BulkActionAddTags