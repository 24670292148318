import React from 'react'
import FileDetailHead from './FileDetailTable/FileDetailHead'
import FileDetailRow from './FileDetailTable/FileDetailRow'

function FileDetailTable(props) {
    const { listFileDetail, loading, reload } = props
    return (
        <div className="FileDetailTable">
            <div className="wrapTable">
                <table className='table'>
                    <FileDetailHead />
                    <tbody>
                        {
                            !!listFileDetail && listFileDetail.map((item, i) => {
                                return <FileDetailRow key={item._id} index={i} item={item} reload={reload}/>
                            })
                        }
                        {
                            !loading && listFileDetail.length === 0 && 
                            <tr>
                                <td colSpan={9}>
                                    No result
                                </td>
                            </tr>
                        }
                    </tbody>
                </table>
            </div>
        </div>
    )
}

export default FileDetailTable