import React, {useState, useEffect, useRef, Component, Fragment, createRef} from 'react'
import {
    updateMappingCondition,
    addMappingCondition,
} from '../../../services/api/MappingConditionServices'
import {Button, Modal, Form, Input} from 'antd'
import MappingConditionHistory from "./MappingConditionHistory"
import { message } from 'antd'

const MappingConditionForm = (props) => {
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState('')
    const [hasChanged, setHasChanged] = useState(false)
    const [confirmedClose, setConfirmedClose] = useState(false)
    const [currentTab, setCurrentTab] = useState('content')
    const [totalHistory, setTotalHistory] = useState(0)
    const [reload, setReload] = useState(0)
    const tabs = [
        {
            title: 'Condition',
            id: 'content'
        },
        {
            title: 'History',
            id: 'history'
        }
    ]
    const {
        mappingCondition,
        onClose,
        nextItem,
        itemIndex,
        itemsLength,
        fetchMappingCondition,
        setMappingCondition
    } = {...props}

    const [mappingConditionSnap, setMappingConditionSnap] = useState(mappingCondition)

    const {
        _id: mappingConditionId,
        user,
        store,
        mapping_context: mappingContext,
    } = {...mappingCondition}

    const switchTab = (tab) => () => {
        setCurrentTab(tab.id)
    }

    const onCancel = (force) => () => {
        if (!force && !confirmedClose && hasChanged) {
            return setConfirmedClose(true)
        }
        onClose && onClose()
    }

    const onConfirmClose = () => {
        setConfirmedClose(false)
        storeInputRef.current.focus()
    }

    const onOk = (close, cb) => () => {
        if (!mappingConditionSnap.store) {
            setError('Store is required!')
            return storeInputRef.current.focus()
        }

        if (!mappingConditionSnap.user) {
            setError('User is required!')
            return userInputRef.current.focus()
        }

        if (!mappingConditionSnap.mapping_context) {
            setError('Mapping context is required!')
            return mappingContextRef.current.focus()
        }
        setError('')
        setLoading(close ? 'save-close' : true)

        ;(mappingConditionId
                ? updateMappingCondition(mappingConditionId, mappingConditionSnap)
                : addMappingCondition(mappingConditionSnap)
        ).then((res) => {
            const {
                success,
                message:mess,
                data
            } = res
            setLoading(false)

            if (!success) {
                return setError(mess || 'Request error!')
            }

            setHasChanged(false)
            !close && fetchMappingCondition(() => {
                setMappingCondition(data)
                setReload(reload + 1)
                cb && cb()
            })
            close && onClose && onClose(true)
            message.success((mappingConditionId?"Edit ": "Add ")+ "Mapping Conditions Succcess!")
        }).catch((error) => {
            console.log(error)
            setError(error.message)
            setLoading(false)
        })
    }

    const updateProp = (prop) => (e) => {
        setMappingConditionSnap({
            ...mappingConditionSnap,
            [prop]: e.target.value
        })

        setHasChanged(true)
    }

    const onFetchedHistory = (data) => {
        setTotalHistory(data.total)
    }

    const addNew = (e) => {
        e && e.preventDefault()
        setMappingCondition({})
        setCurrentTab('content')
    }

    const saveAndNew = (e) => {
        e && e.preventDefault()
        onOk(false, () => {
            setMappingCondition({})
            console.log({mappingConditionId})
        })(e)
    }

    const storeInputRef = createRef()
    const userInputRef = createRef()
    const mappingContextRef = createRef()

    return mappingCondition
        ? <Modal visible={true}
                 title={mappingConditionId ? 'Edit Mapping Condition' : 'Create Mapping Condition'}
                 onOk={onOk(true)}
                 width={720}
                 onCancel={onCancel()}
                 className={[`MappingConditionForm`, mappingConditionId && 'Edit'].filter(Boolean).join(' ')}
                 footer={[
                     error && <p key='error' className="text-danger">{error}</p>,
                     // mappingConditionId &&
                     // <Fragment key="add-new">
                     //     <a className="add-new" href="" key="add-new-c" onClick={addNew}>Add New</a>
                     //     <span>|</span>
                     // </Fragment>,
                     // <Fragment key="save-and-new">
                     //     <a className={['save-add-new', !hasChanged && 'disabled'].filter(Boolean).join(' ')} href=""
                     //        key="save-add-new-c" onClick={saveAndNew}>
                     //         {mappingConditionId ? 'Update' : 'Save'} & New
                     //     </a>
                     //     <span>|</span>
                     // </Fragment>,
                     <Button className="cancel" key="cancel" onClick={onCancel()} disabled={loading}>Cancel</Button>,
                     // <Button key="submit-close" loading={loading === 'save-close'} onClick={onOk(true)}
                     //         disabled={!hasChanged}>
                     //     {mappingConditionId ? 'Update' : 'Save'} & Close
                     // </Button>,
                     <Button key="submit" type="primary" loading={loading === true} onClick={onOk(true)}
                             disabled={!hasChanged}>
                         {mappingConditionId ? 'Update' : 'Save'}
                     </Button>
                 ]}>
            <div className="MappingConditionFormInner" data-tab={currentTab}>
                {/*{*/}
                {/*    itemIndex > 0 && mappingConditionId &&*/}
                {/*    <Button className="move-item move-left" onClick={nextItem(-1)} title="Back Mapping Condition"> <i*/}
                {/*        className="ti-arrow-left"/>*/}
                {/*    </Button>*/}
                {/*}*/}

                {/*{*/}
                {/*    itemIndex < itemsLength - 1 && mappingConditionId &&*/}
                {/*    <Button className="move-item move-right" onClick={nextItem(1)} title="Next Mapping Condition"> <i*/}
                {/*        className="ti-arrow-right"/>*/}
                {/*    </Button>*/}
                {/*}*/}

                {
                    mappingConditionId &&
                    <h6 className="tabs">
                        {
                            tabs.map(tab => {
                                return <a onClick={switchTab(tab)} key={tab.id}
                                          className={currentTab === tab.id ? 'active' : ''}>
                                    {tab.title}
                                    {tab.id === 'history' ? ` (${totalHistory})` : ''}
                                </a>
                            })
                        }
                    </h6>
                }
                <div className="tab-content">
                    <Form.Item>
                        <label>Store</label>
                        <Input autoFocus={true} ref={storeInputRef} defaultValue={store}
                               key={`store-${mappingConditionId}`}
                               onChange={updateProp('store')}/>
                    </Form.Item>
                    <Form.Item>
                        <label>User</label>
                        <Input defaultValue={user} ref={userInputRef} onChange={updateProp('user')}
                               key={`user-${mappingConditionId}`}/>
                    </Form.Item>
                    <Form.Item>
                        <label>Mapping Context</label>
                        <Input.TextArea rows={10} defaultValue={mappingContext} ref={mappingContextRef}
                                        key={`context-${mappingConditionId}`}
                                        onChange={updateProp('mapping_context')}/>
                    </Form.Item>
                </div>
                {
                    mappingCondition &&
                    <MappingConditionHistory reload={reload} mappingCondition={mappingCondition}
                                             onFetched={onFetchedHistory}/>
                }
                {
                    confirmedClose &&
                    <div className="ConfirmCancel">
                        <div>
                            <p>Do you want to discard changes?</p>
                            <div className="actions">
                                <Button onClick={onCancel(true)}>Yes, discard changes</Button>
                                <Button onClick={onConfirmClose} className="btn-primary">No, continue</Button>
                            </div>
                        </div>
                    </div>
                }
            </div>
        </Modal>
        : ''
}

export default MappingConditionForm