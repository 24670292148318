import React, {Component} from 'react'
import PropTypes from 'prop-types'
import {saveOrderDimension} from "../../../services/api/ExpressServices"
import classNames from 'classnames'


class OrderDimension extends Component {
    state = {
        loading: false,
    }

    _handleChangeDimension = field => e => {
        const {value} = e.target
        const {dimension} = this.props.order

        const vDimension = Object.assign({}, dimension, {
            [field]: value
        })

        this.props.onChangeDimension(vDimension)
    }

    _saveDimension = async () => {
        if (this.state.loading) return true

        this.setState({
            loading: true
        })

        try {
            const {_id: orderId, dimension} = this.props.order

            const {success, data, message} = await saveOrderDimension(orderId, dimension)

            if (!success) {
                throw new Error(message)
            }

            const {dimension: vDimension} = Object.assign({}, data)

            this.setState({
                loading: false
            }, () => {
                this.props.onChangeDimension(vDimension)
            })
        } catch (e) {
            window.alert(e.message)

            this.setState({
                loading: false
            })
        }
    }

    _handleSubmit = e => {
        e.preventDefault()

        this._saveDimension()
    }

    render() {
        const {dimension} = this.props.order
        const {weight, length, width, height} = Object.assign({}, dimension)
        const {loading} = this.state


        return (
            <div className={classNames("OrderDimension", {loading})}>
                <h3>Kích thước</h3>

                <form onSubmit={this._handleSubmit} className="Form form-inline">
                    <div className="form-group">
                        <label>Cân nặng</label>
                        <input value={weight || ''} onChange={this._handleChangeDimension('weight')}
                               disabled={loading}
                               className="form-control" type="number"
                               placeholder="Cân nặng (gram)"/>
                    </div>

                    <div className="form-group">
                        <label>Chiều dài (cm)</label>
                        <input value={length || ''} onChange={this._handleChangeDimension('length')}
                               disabled={loading}
                               className="form-control" type="number"
                               placeholder="Chiều dài (cm)"/>
                    </div>

                    <div className="form-group">
                        <label>Chiều rộng (cm)</label>
                        <input value={width || ''} onChange={this._handleChangeDimension('width')}
                               disabled={loading}
                               className="form-control" type="number"
                               placeholder="Chiều rộng (cm)"/>
                    </div>

                    <div className="form-group">
                        <label>Chiều cao (cm)</label>
                        <input value={height || ''} onChange={this._handleChangeDimension('height')}
                               disabled={loading}
                               className="form-control" type="number"
                               placeholder="Chiều cao (cm)"/>
                    </div>

                    <button className="btn btn-secondary" disabled={loading} type="submit">Save</button>
                </form>
            </div>
        )
    }
}

OrderDimension.propTypes = {
    order: PropTypes.object.isRequired,
    onChangeDimension: PropTypes.func.isRequired,
}

export default OrderDimension
