import React, {Component} from 'react'

class ShippingPlansHead extends Component {
    render() {
        return (
            <thead className="ShippingPlansHead">
                <tr>
                    <th>#</th>
                    <th>Title</th>
                    <th>Slug</th>
                    <th>Default</th>
                </tr>
            </thead>
        )
    }
}

export default ShippingPlansHead
