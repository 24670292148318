import createApiServices from './createApiServices'

const prefixURL = process.env.NODE_ENV === 'production' ? '/api/coolie-v1' : 'https://fulfillment-staging.merchize.com/api/coolie-v1'
const api = createApiServices({ baseUrl: prefixURL })

export const getListPackageSplittingRules = (data = {}) => {
    return api.makeAuthRequest({
        url: `/split-package-rule/search`,
        method: 'POST',
        data,
    })
}

export const updatePackageSplittingRulesStatus = (id, status) => {
    return api.makeAuthRequest({
        url: `/split-package-rule/${id}/toggle`,
        method: 'PUT',
        data: {status}
    })
}

export const deletePackageSplittingRules = (id) => {
    return api.makeAuthRequest({
        url: `/split-package-rule/${id}/delete`,
        method: 'DELETE',
    })
}

export const getPackageSplittingRuleDetails = (id) => {
    return api.makeAuthRequest({
        url: `/split-package-rule/${id}`,
        method: 'GET',
    })
}

export const createPackageSplittingRule = (data) => {
    return api.makeAuthRequest({
        url: `/split-package-rule/create`,
        method: 'POST',
        data: data,
    })
}

export const updatePackageSplittingRule = (id, data) => {
    return api.makeAuthRequest({
        url: `/split-package-rule/${id}/update`,
        method: 'PUT',
        data: data,
    })
}

export const exportPackageSplittingRules = (data) => {
    return api.makeAuthRequest({
        url: `/split-package-rule/export/csv`,
        method: 'POST',
        data: data,
    })
}