import React from 'react'
import PropTypes from 'prop-types'
import ProductAttributesList from "./ProductAttributesList"
import ProductAttributesActions from "./ProductAttributesActions"

ProductAttributes.propTypes = {
    loading: PropTypes.bool,
    error: PropTypes.string,
    listAtt: PropTypes.array.isRequired,
    onAddAtt: PropTypes.func.isRequired,
    onChangeAtt: PropTypes.func.isRequired,
    onDiscardChangeAtt: PropTypes.func.isRequired,
    onDeleteAtt: PropTypes.func.isRequired,
}

function ProductAttributes(props) {
    const {loading, error, productDetail, listAtt, onAddAtt, onChangeAtt, onDiscardChangeAtt, onDeleteAtt, disableAll} = props

    return (
        <div className='ProductAttributes mt-3'>
            <div className="SectionInner">
                <h3 className="SectionHeading mb-3">Attributes</h3>

                <ProductAttributesList
                    attributes={listAtt}
                    onChangeAtt={onChangeAtt}
                    onDeleteAtt={onDeleteAtt}
                    disableAll={disableAll}
                />

                <ProductAttributesActions
                    loading={loading}
                    error={error}
                    onAddAtt={onAddAtt}
                    productDetail={productDetail}
                    listAtt={listAtt}
                    onDiscardChangeAtt={onDiscardChangeAtt}
                    disableAll={disableAll}
                />
            </div>
        </div>
    )
}

export default ProductAttributes
