export const DISCOUNT_TYPE = [
    {
        name: 'All',
        value: ''
    },
    {
        name: 'Percentage',
        value: 'percentage'
    },
    {
        name: 'Fixed value',
        value: 'fixed'
    },
]

export const DISCOUNT_STATUS = [
    {
        name: 'All',
        value: ''
    },
    {
        name: 'Active',
        value: 'Active'
    },
    {
        name: 'Inactive',
        value: 'Inactive'
    },
]