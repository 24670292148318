import React, {Component} from 'react'
import PropTypes from 'prop-types'

class OrderJobError extends Component {
    render() {
        const {errorMessage} = this.props

        return (
            <td className="OrderJobError">
                {!!errorMessage && errorMessage}
            </td>
        )
    }
}

OrderJobError.propTypes = {
    errorMessage: PropTypes.string,
}

export default OrderJobError
