import React, {useState} from 'react'
import {Button, Input, message, Checkbox} from 'antd'
import {SaveOutlined} from '@ant-design/icons'
import {createQuickReports, editQuickReports} from "../../../../services/api/OrdersServices"
import {getLocalData, setLocalData} from "../../../../services/StorageServices";

function PopupSave(props) {
    const {filters, togglePopupSave} = props
    const user = getLocalData('user')
    const [error, setError] = useState('')
    const [loading, setLoading] = useState(false)
    const itemQuickCurrent = getLocalData('ordersPage.QuickReportItem', {}, false, 'sessionStorage')
    const [nameFilter, setName] = useState(itemQuickCurrent.name || '')
    const [isCommonFilter, setCommonFilter] = useState(false)

    const _handleChangeInput = (e) => {
        const {value} = e.target
        setError('')
        setName(value)
    }

    const _handleChangeCheckbox = (e) => {
        setCommonFilter(e.target.checked)
    }

    const _handleSaveFilter = async () => {
        if (nameFilter.trim().length > 100) return setError('Name filter max length is 100 characters.')
        if (nameFilter.trim() === '') return setError('Please enter name filter.')
        setError('')
        setLoading(true)

        try {
            const payload = {
                name: nameFilter,
                payload: filters,
                type: 'order',
                isCommonFilter: isCommonFilter
            }
            const {
                data,
                success,
                message: error
            } = itemQuickCurrent._id !== undefined ? await editQuickReports(itemQuickCurrent._id, payload) : await createQuickReports(payload)
            if (!success) {
                setLoading(false)
                return setError(error)
            }
            !isCommonFilter && setLocalData('ordersPage.QuickReportItem', data, false, 'sessionStorage')
            togglePopupSave()
            message.success(`${itemQuickCurrent._id !== undefined ? 'Update' : 'Add new'} filter successfully!`);
            setLoading(false)
        } catch (e) {
            setLoading(false)
            setError(e.message)
        }
    }

    return <>
        <div onClick={togglePopupSave} className="overlaySave"/>
        <div className="PopupSave">
            <span className="arrow"/>

            <div className="SaveForm">
                <label>Save as:</label>
                <Input name="nameFilter" value={nameFilter} className="mb-2" placeholder='Enter filter name...'
                       onChange={_handleChangeInput}/>
                <div className="note"><i className="text-secondary">
                    Filter are saved as a new filter at the quick report.</i>
                </div>
            </div>

            {
                error && <div className="text-danger mt-2 mb-2">{error}</div>
            }

            <div className="d-flex justify-content-between align-items-center">
                <div className="">
                    <Checkbox onChange={_handleChangeCheckbox}>Mark as default</Checkbox>
                </div>
                <div className="Actions d-flex align-items-center justify-content-end">
                    <Button onClick={togglePopupSave} disabled={loading}>Cancel</Button>
                    <Button className="ml-2" onClick={_handleSaveFilter} loading={loading} type="primary">Save</Button>
                </div>
            </div>
        </div>
    </>
}

function SaveFilter(props) {
    const [isOpen, setIsOpen] = useState(false)

    const togglePopupSave = () => {
        setIsOpen(!isOpen)
    }

    return <div className="SaveFilter">
        <Button id="btnSaveFilter" icon={<SaveOutlined/>} size="" onClick={togglePopupSave}>Save filter</Button>
        {isOpen && <PopupSave togglePopupSave={togglePopupSave} {...props}/>}
    </div>
}

export default SaveFilter
