import React from 'react'
import DocTitleByStore from "../../dashboard/shared/DocTitleByStore"
import ProductsPage from "./ProductsPage"

const ProductsPageContainer =()=> {
    return (
        <section className="ProductsPageContainer">
            <DocTitleByStore title="Products Line" />
            <ProductsPage />
        </section>
    )
}

export default ProductsPageContainer
