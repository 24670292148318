import React, {useState, useEffect} from 'react'
import PropTypes from 'prop-types'
import {getHistory} from "../../../../services/api/OrdersServices"
import OrderHistoriesItem from "./OrderHistoriesItem"
import {Spin} from "antd"

OrderHistoriesList.propTypes = {
    fufillmentId: PropTypes.string.isRequired
}

function OrderHistoriesList(props) {
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState('')
    const [histories, setHistory] = useState([])

    const _fetchHistory = async () => {
        setLoading(true)
        setError('')

        try {
            const {fufillmentId} = props
            const response = await getHistory(fufillmentId)

            const {data, success, message} = response

            if (!success) {
                setLoading(false)
                return setError(message)
            }

            setLoading(false)
            setHistory(data)
        } catch (e) {
            setLoading(false)
            setError(e.message)
        }
    }

    useEffect(() => {
        _fetchHistory()

    }, [])

    return (
        <div className="OrderHistoriesList">
            <Spin spinning={loading} tip="Get histories...">
                {
                    histories.length <= 0 ?
                        <i>No results</i>
                        :
                        histories.map(history => {
                            return <OrderHistoriesItem key={history._id} history={history}/>
                        })
                }
            </Spin>

            {
                !!error && <div className="text-danger mt-3">{error}</div>
            }
        </div>
    )
}

export default OrderHistoriesList