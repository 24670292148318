import React from 'react'
import PropTypes from 'prop-types'
import {Spin} from "antd"
import RuleThead from "./RuleThead"
import RuleBody from "./RuleBody"
import emptyData from "../../../../statics/assets/images/empty-img-gray.png"

RuleTable.propTypes = {
    loading: PropTypes.bool,
    error: PropTypes.string,
    listRules: PropTypes.array,
    selectAll: PropTypes.bool,
    listSelected: PropTypes.array,
    onChangeSelectAll: PropTypes.func.isRequired,
    onChangeSelectedRule: PropTypes.func.isRequired
}

function RuleTable(props) {
    const {loading, error, listRules, selectAll, listSelected, onChangeSelectAll, onChangeSelectedRule} = props

    return (
        <div className='RuleTable'>
            <Spin spinning={loading} tip="Getting list rules...">
                {
                    listRules.length > 0 ? (
                        <table>
                            <RuleThead
                                selectAll={selectAll}
                                listSelected={listSelected}
                                onChangeSelectAll={onChangeSelectAll}
                            />
                            <RuleBody
                                error={error}
                                listRules={listRules}
                                listSelected={listSelected}
                                onChangeSelectedRule={onChangeSelectedRule}
                            />
                        </table>
                    ) : !loading && (
                        <div className="noData d-flex flex-column align-items-center justify-content-center">
                            <img src={emptyData} alt="No data"/>
                            Không có bản ghi!
                        </div>
                    )
                }
            </Spin>
        </div>
    )
}

export default RuleTable
