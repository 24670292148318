import React from 'react'
import PropTypes from 'prop-types'
import {Popconfirm, Tooltip, Table} from "antd"
import ListTable from './ListTable'

ShippingPriceTabContent.propTypes = {
    listShippingPrice: PropTypes.array,
    error: PropTypes.string
}

function ShippingPriceTabContent(props) {
    const {listShippingPrice, error, supplier} = props

    const _onChangeValue = (e, index, from, to) => {
        const {value} = e.target
        const {onEditValues} = props
        console.log({value, index, from, to, onEditValues})
        if (typeof onEditValues === 'function') {
            onEditValues(index, from, to, value)
        }
    }

    const _onChangeCountryCode = (e, index) => {
        const {value} = e.target
        const {onEditCountryCode} = props

        if (typeof onEditCountryCode === 'function') {
            onEditCountryCode(index, value)
        }
    }

    const _onAddNewRow = () => {
        const {onAddNewRow} = props

        if (typeof onAddNewRow === 'function') {
            onAddNewRow()

            setTimeout(() => {
                const items = document.getElementsByClassName('InputCountryCode')

                items[items.length - 1].focus()
            }, 300)
        }
    }

    const _onAddNewColumn = () => {
        const {onAddNewColumn} = props

        if (typeof onAddNewColumn === 'function') {
            onAddNewColumn()
        }
    }

    const _onEditValueRange = (index) => (e) => {
        const {value, name} = e.target
        const {onEditValueRange} = props

        if (typeof onEditValueRange === 'function') {
            onEditValueRange(index, name, value)
        }
    }

    const _confirm = (countryCode, index) => {
        const {onDeleteRow} = props

        if (typeof onDeleteRow === 'function') {
            onDeleteRow(countryCode, index)
        }
    }

    const _confirmDelete = (from, to, index) => {
        const {onDeleteColumn} = props

        if (typeof onDeleteColumn === 'function') {
            onDeleteColumn(from, to, index)
        }
    }

    const onRendered = () => {
        const container = document.getElementsByClassName('TabContent')[0]

        if (!container) {
            return
        }

        if (container.getAttribute('rendered')) {
            return
        }

        container.setAttribute('rendered', 'true')
        container.addEventListener('scroll', () => {
            let className = container.getAttribute('class')
            className = `${className}`.split(' ').filter(c => c.trim() !== 'fixed-x')

            if (container.scrollLeft) {
                className.push('fixed-x')
            }
            container.setAttribute('class', className.join(' '))
        })
    }

    const highlightRow = (rowData) => (e) => {
        const rows = document.getElementById('ShippingCosts').getElementsByClassName('ant-table-tbody')[0].getElementsByTagName('tr')

        ;[...new Set(rows)].forEach((row) => {
            const group = row.getAttribute('data-group')
            let className = row.getAttribute('class')
            className = className ? `${className}`.replace('Highlight', '') : ''

            if (rowData && group === rowData.isDuplicate) {
                className = `${className} Highlight`
            }

            row.setAttribute('class', className.replace(/\s+/g, ' ').trim())
        })
    }

    const headerValues = !!listShippingPrice && listShippingPrice.length > 0 && !!listShippingPrice[0].values &&
        listShippingPrice[0].values.length > 0 && listShippingPrice[0].values || []

    return (
        <>
            <div className='TabContent mb-4'>
                {
                    !!listShippingPrice && listShippingPrice.length > 0 && !error ?
                        <ListTable
                            shippingCosts={listShippingPrice}
                            onAddNewColumn={_onAddNewColumn}
                            onChangeCountryCode={_onChangeCountryCode}
                            onChangeValue={_onChangeValue}
                            onConfirm={_confirm}
                            onConfirmDelete={_confirmDelete}
                            onEditValueRange={_onEditValueRange}
                            headers={headerValues}
                            highlightRow={highlightRow}
                            supplier={supplier}
                        />
                        : !error && <table className="table">
                        <thead>
                        <tr>
                            <th className="CountryCode">Country code</th>
                            {supplier === "1C" && <th className="AddColumnShippingCost">
                                <Tooltip title='Add new column'>
                                    <i className="ti-plus" onClick={_onAddNewColumn}/>
                                </Tooltip>
                            </th>}
                        </tr>
                        </thead>
                        <tbody>
                        {supplier === "1C" && <tr className="AddRowShippingCost">
                            <td colSpan={2}>
                                <Tooltip title='Add new row'>
                                    <i className="ti-plus" onClick={_onAddNewRow}/>
                                </Tooltip>
                            </td>
                        </tr>}
                        </tbody>
                    </table>
                }

            </div>
            {supplier === "1C" && <div className="AddRowShippingCost">
                <Tooltip title='Add new row'>
                    <i className="ti-plus" onClick={_onAddNewRow}/>
                </Tooltip>
            </div>}
            {
                (() => {
                    setTimeout(onRendered, 300)
                })()
            }
        </>
    )
}

export default ShippingPriceTabContent
