export const PRODUCTION_STATUS_SUPPLIER = [
    {
        name: 'All',
        value: '',
    },
    {
        name: 'Pending',
        value: 'pending',
    },
    {
        name: 'In production',
        value: 'in_production',
    },
    {
        name: 'Packaged',
        value: 'packaged',
    },
    {
        name: 'Picked up',
        value: 'picked_up',
    },
    {
        name: 'Shipping',
        value: 'shipping',
    },
    {
        name: 'Delivered',
        value: 'delivered',
    },
    {
        name: 'On hold',
        value: 'on_hold',
    },
    {
        name: 'Waiting push',
        value: 'waiting_push',
    },
    {
        name: 'Cancelled',
        value: 'cancelled',
    },
    {
        name: 'Error',
        value: 'error',
    },
]

export const PACKAGE_STATUS = [
    {
        name: 'Waiting push',
        value: 'waiting_push',
    },
    {
        name: 'Pushed',
        value: 'pushed',
    },
    {
        name: 'In production',
        value: 'in_production',
    },
    {
        name: 'Completed',
        value: 'completed',
    },
    {
        name: 'Pre transit',
        value: 'pre_transit',
    },
    {
        name: 'In transit',
        value: 'in_transit',
    },
    {
        name: 'Delivered',
        value: 'delivered',
    },
    {
        name: 'Cancelled',
        value: 'cancelled',
    },
    {
        name: 'Cancel Ship',
        value: 'cancel-ship',
    },
    {
        name: 'Cancelled by Supplier',
        value: 'cancelled-by-supplier',
        subLevel: [
            {
                name: 'Reject by Trademark',
                value: 'ip_violation',
            },
            {
                name: 'Invalid Artwork',
                value: 'invalid_artwork',
            },
            {
                name: 'Invalid Address',
                value: 'invalid_address',
            },
            {
                name: 'Out of stock',
                value: 'out_of_stock'
            },
            {
                name: 'Customer Changed Mind',
                value: 'customer_changed_mind'
            },
        ],
    },
    {
        name: 'Exception',
        value: 'exception',
        subLevel: [
            {
                name: 'Reshipped VN',
                value: 'reshipped-vn',
            },
            {
                name: 'Reshipped',
                value: 'reshipped',
            },
            {
                name: 'Refund',
                value: 'refund',
            },
            {
                name: 'Lost',
                value: 'lost',
            },
        ],
    },
]

export const DID_ACTIONS = [
    {
        name: "Tracking modified",
        value: "tracking_modified",
        focus: 'tracking_modified',
    },
    {
        name: "LLC delay us hub",
        value: "llc_delay_us_hub",
        focus: 'llc_delay_us_hub',
    },
    {
        name: "Wrong zipcode delivered",
        value: "maybe_delivered_wrong_zipcode",
        focus: 'maybe_delivered_wrong_zipcode',
    },
    {
        name: "Recycle tracking",
        value: "may_use_recycle_tracking",
        focus: 'may_use_recycle_tracking',
    },
    {
        name: "Tiktok ship by seller",
        value: "tiktokshipbyseller",
        focus: 'tiktokshipbyseller',
    },
    {
        name: "Tiktok ship by Tiktok",
        value: "shipbytiktok",
        focus: 'shipbytiktok',
    },
    {
        name: "Fast production",
        value: "fastproduction",
        focus: 'fastproduction',
    },
    {
        name: "Large amount",
        value: "size_over_15",
        focus: 'size_over_15',
    },
]

export const LIST_SUB = [
    'reshipped-vn', 'reshipped', 'refund', 'lost', 'cancel-ship', 'cancelled-by-supplier'
]

export const REASON_REJECT = [
    'ip_violation', 'invalid_artwork', 'invalid_address', 'out_of_stock', 'customer_changed_mind'
]

export const LIST_SUB_EXCEPTION = [
    'reshipped-vn', 'reshipped', 'refund', 'lost',
]

export const PUSH_TYPE = [
    {
        name: 'All',
        value: '',
    },
    {
        name: 'API',
        value: 'api',
    },
    {
        name: 'Export',
        value: 'export',
    },
]

export const ORDER_TYPE = [
    {
        name: 'All',
        value: '',
    },
    {
        name: 'Normal',
        value: 'normal',
    },
    {
        name: 'FBA',
        value: 'fba',
    },
    {
        name: 'TIKTOK',
        value: 'TIKTOK'
    },
]

export const SHIPPING_ZONE = [
    {
        name: 'US+WW',
        value: 'US+WW',
    },
    {
        name: 'EU',
        value: 'EU',
    },
]

export const STORE_MODE = [
    {
        name: 'All',
        value: '',
    },
    {
        name: 'Normal',
        value: 'normal',
    },
    {
        name: 'Marketplace',
        value: 'marketplace',
    },
]

export const DELAY_STATUS = [
    {
        name: 'All',
        value: '',
    },
    {
        name: 'In_transit Delay (From Completed at)',
        value: 'in_transit_delay',
    },
    {
        name: 'Ship Delay (From Completed at)',
        value: 'ship_delay_from_completed_at',
    },
    {
        name: 'Production Delay (From pushed at)',
        value: 'production_delay_from_pushed_at',
    },
    {
        name: 'Production Delay For Seller (From pushed at)',
        value: 'seller_production_delay_from_pushed_at',
    },
    {
        name: 'Production Processing (From pushed at)',
        value: 'production_processing_from_pushed_at',
    },
    {
        name: 'Production Completed (From pushed at)',
        value: 'production_completed_from_pushed_at',
    },
    {
        name: 'Multrans US pre-transit delay',
        value: 'multrans_us_pre_transit_delay',
    },
    {
        name: 'Delay ship > 13BDs',
        value: 'ship_delay_gt_days_13',
    },
    {
        name: 'Delay ship > 16BDs',
        value: 'ship_delay_gt_days_16',
    },
    {
        name: 'Delay ship > 21BDs',
        value: 'ship_delay_gt_days_21',
    },
]

export const DELAY_DAYS = [
    {
        name: '-6',
        value: '-6',
    },
    {
        name: '-5',
        value: '-5',
    },
    {
        name: '-4',
        value: '-4',
    },
    {
        name: '-3',
        value: '-3',
    },
    {
        name: '-2',
        value: '-2',
    },
    {
        name: '-1',
        value: '-1',
    },
    {
        name: '1',
        value: '1',
    },
    {
        name: '2',
        value: '2',
    },
    {
        name: '3',
        value: '3',
    },
    {
        name: '4',
        value: '4',
    },
    {
        name: '5',
        value: '5',
    },
    {
        name: '6',
        value: '6',
    },
]

export const TRACKING_STATUS = [
    {
        name: 'Unknown',
        value: 'unknown',
        focus: 'unknown',
    },
    {
        name: 'Pre_transit',
        value: 'pre_transit',
        focus: 'pretransit',
    },
    {
        name: 'In_transit',
        value: 'in_transit',
        focus: 'intransit',
    },
    {
        name: 'Out for delivery',
        value: 'out_for_delivery',
        focus: 'outfordelivery',
    },
    {
        name: 'Delivered',
        value: 'delivered',
        focus: 'delivere',
    },
    {
        name: 'Exception trackingmore',
        value: 'exception_trackingmore',
        focus: 'exceptiontrackingmore',
    },
    {
        name: 'Exception easypost',
        value: 'exception_easypost',
        focus: 'exceptioneasypost',
    },
    {
        name: 'Exception aftership',
        value: 'exception_aftership',
        focus: 'exceptionaftership',
    },
    {
        name: 'Exception Crawler',
        value: 'exception',
        focus: 'exceptioncrawler',
    },
]

export const SHIPPING_CARRIER = [
    {
        name: 'All',
        value: '',
    },
    {
        name: 'Multrans US',
        value: 'Multrans US',
    },
    {
        name: 'Multrans EU',
        value: 'Multrans EU',
    },
    {
        name: 'Royal mail',
        value: 'Royal Mail',
    },
    {
        name: 'Shippo US',
        value: 'Shippo US',
    },
    {
        name: 'Ib blue',
        value: 'IB Blue',
    },
    {
        name: 'UPS',
        value: 'UPS',
    },
    {
        name: 'LLC USPS',
        value: 'LLC USPS',
    },
    {
        name: 'UPS UK',
        value: 'UPS UK',
    },
    {
        name: 'DHL eCommerce',
        value: 'DHL eCommerce',
    },
    {
        name: 'DHL Express',
        value: 'DHL Express',
    },
    {
        name: 'USPS',
        value: 'USPS',
    },
    {
        name: 'Tim US',
        value: 'Tim US',
    },
    {
        name: 'Yun Express',
        value: 'Yun Express',
    },
    {
        name: 'Deutsche Post',
        value: 'Deutsche Post',
    },
    {
        name: 'Spring GDS',
        value: 'Spring GDS',
    },
    {
        name: 'DPDUK',
        value: 'DPDUK',
    },
    {
        name: 'Canada post',
        value: 'Canada post',
    },
    {
        name: 'Singapore Post',
        value: 'Singapore Post',
    },
    {
        name: 'Shopee',
        value: 'Shopee',
    },
    {
        name: 'Giao hàng tiết kiệm',
        value: 'Giao hàng tiết kiệm',
    },
    {
        name: 'Other',
        value: 'Other',
    },
]

export const SHIPPING_LEVELS = [
    {
        name: 'All',
        value: '',
    },
    {
        name: 'Priority',
        value: '60',
    },
    {
        name: 'First class',
        value: '50',
    },
]

export const UPDATED_TRACKING = [
    {
        name: 'All',
        value: '',
    },
    {
        name: 'Yes',
        value: '1',
    },
    {
        name: 'No',
        value: '0',
    },
]

export const ORIGINAL_CARRIERS = [
    {
        name: 'All',
        value: '',
    },
    {
        "name": "DHL eCommerce",
        "value": "DHL eCommerce"
    },
    {
        "name": "USPS",
        "value": "USPS"
    },
    {
        "name": "DHL Express",
        "value": "DHL Express"
    },
    {
        "name": "UPS",
        "value": "UPS"
    },
    {
        "name": "Yun Express",
        "value": "Yun Express"
    },
    {
        "name": "Deutsche Post",
        "value": "Deutsche Post"
    },
    {
        "name": "DPDUK",
        "value": "DPDUK"
    },
    {
        "name": "Canada post",
        "value": "Canada post"
    },
    {
        "name": "Giao hàng tiết kiệm",
        "value": "Giao hàng tiết kiệm"
    },
    {
        "name": "Other",
        "value": "Other"
    },
    {
        "name": "FedEx",
        "value": "FedEx"
    },
    {
        "name": "VN Post",
        "value": "VN Post"
    },
    {
        "name": "Spring GDS",
        "value": "Spring GDS"
    },
    {
        "name": "JCEX",
        "value": "JCEX"
    },
    {
        "name": "Eshipping",
        "value": "Eshipping"
    },
    {
        "name": "YANWEN",
        "value": "YANWEN"
    },
    {
        "name": "Poland Post",
        "value": "Poland Post"
    },
    {
        "name": "Jetlogistic",
        "value": "Jetlogistic"
    }
].sort((a, b) => a.name.localeCompare(b.name))