import React from 'react'
import PropTypes from 'prop-types'

ProductLineHead.propTypes = {
    selectAll: PropTypes.bool,
    onSelectAll: PropTypes.func.isRequired
}

function ProductLineHead(props) {
    const {selectAll, onSelectAll} = props

    const _handleChangeChecked = (e) => {
        const {checked} = e.target

        if (typeof onSelectAll === 'function') {
            onSelectAll(checked)
        }
    }

    return (
        <thead className="ProductLineHead">
        <tr>
            <th className="SelectProductLine">
                <input type="checkbox" checked={selectAll} onChange={_handleChangeChecked}/>
            </th>
            <th className="Title">
                Title
            </th>
            <th className="ProductLine">
                Product type
            </th>
            <th className="ProductSku">
                Sku
            </th>
            <th className="Actions"/>
        </tr>
        </thead>
    )
}

export default ProductLineHead
