import React, {Component} from 'react'
import PropTypes from 'prop-types'
import ArtworkImages from "../shared/ArtworkImages"

class GroupArtworkItem extends Component {
    state = {
        background: 'black'
    }

    _handleChangeColor = color => {
        this.setState({
            background: color
        })
    }

    render() {
        const {artwork} = this.props
        const {background} = this.state

        return (
            <div className='GroupArtworkItem'>
                <div className="Actions">
                    <div className="QuantityArtwork"/>
                    <div className="ActionBackground">
                        <button
                            className={background === 'black' ? 'Active' : ''}
                            onClick={() => this._handleChangeColor('black')}
                        >
                            Black
                        </button>
                        <button
                            className={background === 'white' ? 'Active' : ''}
                            onClick={() => this._handleChangeColor('white')}
                        >
                            White
                        </button>
                    </div>
                </div>
                <ul className="ArtworkListNew row">
                    {
                        !!artwork.artworks && artwork.artworks.length > 0 && artwork.artworks.map((item, index) => (
                            <li className="col-6" key={index}>
                                <ArtworkImages
                                    background={background}
                                    correctDimensions={[]}
                                    preview={item.preview}
                                    resize={item.resize}
                                    side={item.side}
                                    width={item.width}
                                    height={item.height}
                                    artworkID={item._id}
                                />
                            </li>
                        ))
                    }
                </ul>
            </div>
        )
    }
}

GroupArtworkItem.propTypes = {
    artwork: PropTypes.object
}

export default GroupArtworkItem
