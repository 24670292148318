import React, {useContext, useState, useEffect} from 'react'
import PropTypes from 'prop-types'
import ProductContext from "../../context/ProductContext"
import { getListSuppliersV2 } from '../../../../services/api/SuppliersServices'
import {Modal, ModalHeader, ModalBody, ModalFooter} from "reactstrap"
import {Select} from "antd"

const {Option} = Select

ProductAttributesActions.propTypes = {
    loading: PropTypes.bool,
    error: PropTypes.string,
    onAddAtt: PropTypes.func.isRequired,
    onDiscardChangeAtt: PropTypes.func.isRequired
}

function ProductAttributesActions(props) {
    const {onUpdateListAttributes} = useContext(ProductContext)
    const {loading, error, onAddAtt, onDiscardChangeAtt, listAtt, productDetail, disableAll} = props
    const [suppliers, setSuppliers] = useState([])
    const [listSuppliers, setListSuppliers] = useState([])
    const [showModal, setShowModal] = useState(false)
    const [err, setErr] = useState('')

    const _handleClickAdd = () => {
        if (typeof onAddAtt === 'function') {
            onAddAtt()
        }
    }

    const _handleClickUpdateAttributes = () => {
        if (typeof onAddAtt === 'function') {
            let payload = {
                attributes: listAtt
            }
            const newListSuppliers = productDetail.suppliers.map(item => item._id)
            
            if(newListSuppliers.length>0){
                payload = {
                    ...payload,
                    suppliers: newListSuppliers,
                    create_variants: true
                }
                onUpdateListAttributes(payload)
            }else{
                setShowModal(true)
            }
        }
    }

    const _handleClickDiscard = () => {
        if (typeof onDiscardChangeAtt === 'function') {
            onDiscardChangeAtt()
        }
    }

    const handleToggleModal = () => {
        setShowModal(!showModal)
        setSuppliers([])
    }

    const handleChangeSupplier = (value) => {
        setSuppliers([value])
        setErr('')
        console.log(value)
    }

    const handleOnSubmit = () => {
        if (typeof onAddAtt === 'function') {
            if(!!suppliers & suppliers.length>0){
                let payload = {
                    attributes: listAtt,
                    suppliers: suppliers,
                    create_variants: true
                }
                onUpdateListAttributes(payload)
                setSuppliers([])
                setShowModal(false)
            }else{
                setErr("Please select Supplier first!!!")
            }
        }
    }

    const getListSuppliers = async() => {
        try {
            const {success, data, messase} = await getListSuppliersV2({limit: 100, active: true})
            if(success){
                setListSuppliers(data.suppliers)
            }else{
                console.log(messase)
            }
        } catch (error) {
            console.log(error)
        }
    }
    useEffect(()=> {
        getListSuppliers()
    },[])

    return (!disableAll &&
        <div className="ProductAttributesActions">
            <div className="d-flex">
                <button className="btn btn-success mr-auto" onClick={_handleClickAdd}>
                    Add attribute
                </button>

                <button type="button" className="btn btn-secondary mr-3" onClick={_handleClickDiscard}>
                    Discard
                </button>

                <button type="button" className="btn btn-primary" onClick={_handleClickUpdateAttributes}>
                    {
                        loading ? "Saving..." : "Save attributes"
                    }
                </button>
            </div>

            {
                !!error &&
                <div className="text-danger text-right mt-3">{error}</div>
            }
            <Modal isOpen={showModal} toggle={handleToggleModal} className="">
                <ModalHeader>Select Supplier</ModalHeader>

                <ModalBody>
                    <label>Supplier</label>
                    <Select 
                        placeholder="--- Select Supplier ---" 
                        className='w-100' 
                        size={'large'}
                        onChange={handleChangeSupplier}
                    >
                            {
                                !!listSuppliers && listSuppliers.length > 0 && listSuppliers.map((item, index) => {
                                    return <Option key={index} value={item._id}>{item.name}</Option>
                                })
                            }
                    </Select>
                    {err &&
                        <small className='text-danger'>{err}</small> 
                    }
                </ModalBody>
                <ModalFooter>
                    <button type="button" className="btn btn-secondary mr-3" onClick={handleToggleModal}>
                        Cancel
                    </button>

                    <button type="button" className="btn btn-primary" onClick={handleOnSubmit}>
                        {
                            loading ? "Saving..." : "Select & Save"
                        }
                    </button>
                </ModalFooter>
            </Modal>
        </div>
    )
}

export default ProductAttributesActions
