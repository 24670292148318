import React from 'react'
import ShippingPricePage from "./ShippingPricePage"
import DocTitleByStore from "../../dashboard/shared/DocTitleByStore"

function ShippingPriceContainer() {

    return (
        <section className='ShippingPriceContainer'>
            <DocTitleByStore title="Shipping Price"/>
            <ShippingPricePage/>
        </section>
    )
}

export default ShippingPriceContainer
