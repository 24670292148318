import React from 'react'
import moment from 'moment'
import { Switch, Popconfirm } from 'antd'
import { Link } from 'react-router-dom'

const TableRow = (props) => {
    const { data, onChangeStatusSpecialCase, onDeleteSpecialCase, permissionsData } = props

    const showColRecord = (item = {}, isCheck = false, colName = '') => {
        let { allow = [], disallow = [] } = { ...item }

        if (allow.length === 0 && disallow.length === 0 && (colName === 'shipping_plan' || colName === 'store')) return "All"
        if (allow.length === 0 && disallow.length === 0) return "-"

        if (isCheck) {
            allow = !!item.allow && item.allow.map(i => i && i.title)
            disallow = !!item.disallow && item.disallow.map(i => i && i.title)
        }
        return (
            <>
                {
                    Array.isArray(allow) &&
                    <div className='text-break'>
                        {allow.join(", ")}
                    </div>

                }
                {
                    Array.isArray(disallow) &&
                    <div className='text-danger text-break'>
                        {disallow.join(", ")}
                    </div>
                }
            </>
        )
    }

    return data.length && data.map((item) => {
        const { _id, store = {}, country = {}, tags = {}, product_title = {}, shipping_plan = {}, product_catalog_sku = [], supplier = [], status = 0, created_at = "", updated_at = "" } = item

        return (
            <tr key={item._id} className='border-bottom'>
                <td className="Store">
                    {showColRecord(store, false, 'store')}
                </td>
                <td className="Country">
                    {showColRecord(country)}
                </td>
                <td className="OrderTag">
                    {showColRecord(tags)}
                </td>
                <td className="Title">
                    {showColRecord(product_title)}
                </td>
                <td className="ShippingPlan">
                    {showColRecord(shipping_plan, true, 'shipping_plan')}
                </td>
                <td className="ProductCatalogSku text-break">
                    {product_catalog_sku.join(', ') || '-'}
                </td>
                <td className="SupplierApply">
                    {
                        Array.isArray(supplier) ? supplier.map(sup => !!sup && !!sup.name && sup.name).join(', ') : '-'
                    }
                </td>
                <td className="Date">
                    {
                        !!created_at ?
                            <div>
                                {moment(created_at).format("HH:mm DD/MM/YYYY")}
                            </div> : '-'
                    }
                    {
                        !!updated_at ?
                            <div>
                                {moment(updated_at).format("HH:mm DD/MM/YYYY")}
                            </div> : '-'
                    }
                </td>
                <td className="Status">
                    <Switch
                        checked={status}
                        onChange={() => onChangeStatusSpecialCase(status === 0 ? true : false, _id)}
                        disabled={!permissionsData.includes("ffm_mapping_special_case_update")}
                    />
                </td>
                <td className="Action">
                    {
                        permissionsData.includes("ffm_mapping_special_case_update") &&
                        <Link Link to={`/a/special-case/${_id}`}>Edit</Link>
                    }
                    {
                        permissionsData.includes("ffm_mapping_special_case_delete") &&
                        <Popconfirm
                            title="Are you sure to delete this item?"
                            onConfirm={() => onDeleteSpecialCase(_id)}
                            okText="Yes"
                            cancelText="No"
                        >
                            <span className='text-danger ml-2 cursor-pointer'>Delete</span>
                        </Popconfirm>
                    }
                </td>
            </tr >
        )
    })
}

export default TableRow