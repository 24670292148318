import React, {Component} from 'react'
import {addOrderTag, updateOrderTags} from "../../../services/api/OrdersServices"
import OrderTagsCreate from "./OrderTags/OrderTagsCreate"
import OrderTagsList from "./OrderTags/OrderTagsList"
import PropTypes from 'prop-types'
import PopularTagsContainer from "./OrderTags/popular-tags/PopularTagsContainer"
import broker from "../OrderBroker"
import { getLocalData } from '../../../services/StorageServices'
import { parseJwt } from '../../../helpers/parseToken'
import classNames from 'classnames'

class OrderTags extends Component {
    state = {
        tags: this.props.tags,
        error: "",
    }

    _subscription = null

    componentDidMount() {
        this._subscription = broker.subscribe("ORDER_REFRESHED_ORDER", this._refreshTags)
    }

    componentWillUnmount() {
        broker.unsubscribe(this._subscription)
    }

    _refreshTags = () => {
        console.log('Refreshing tags:', this.props.tags)

        this.setState({
            tags: this.props.tags
        })
    }

    _handleCreateOrderTags = async (tag) => {
        try {
            const {orderId} = this.props
            const {success, message, data} = await addOrderTag(orderId, {
                title: tag,
            })

            if (!success) {
                this.setState({
                    error: message,
                })

                return false
            }

            const {tags} = data

            this.setState({
                tags: tags,
                error: "",
            })

            return true
        } catch (e) {
            this.setState({
                error: e.message,
            })

            return false
        }
    }

    _handleRemoveOrderTag = async (selectedTag) => {
        try {
            const {orderId} = this.props
            const {tags} = this.state

            const newTags = tags.filter(tag => tag !== selectedTag)

            const {data, success, message} = await updateOrderTags(orderId, {
                tags: newTags,
            })

            if (!success) {
                return this.setState({
                    error: message,
                })
            }

            const {tags: updatedTags} = data

            return this.setState({
                tags: updatedTags,
                error: "",
            })
        } catch (e) {
            return this.setState({
                error: e.message,
            })
        }
    }

    render() {
        const {tags, error} = this.state
        const {orderId} = this.props

        const permissionsData = getLocalData('permissions') ? getLocalData('permissions') : {}

        return (
            <div className="OrderTags">
                <div className="card">
                    <div className="card-header font-weight-bold">Order Tags</div>

                    <div className="card-body">
                        {
                            permissionsData.hasOwnProperty('ffm_order_detail_update_order_tags') &&
                            <>
                                <div className="mb-2">Select popular tags or enter a new tag</div>

                                <PopularTagsContainer onCreateOrderTags={this._handleCreateOrderTags} tags={tags}
                                                    orderID={orderId}/>

                                <OrderTagsCreate _handleCreateOrderTags={this._handleCreateOrderTags}/>
                            </>
                        }
                        

                        {/* <div className="pt-3 mt-3 border-top"> */}
                        <div className={classNames({'pt-3 mt-3 border-top' : permissionsData.hasOwnProperty('ffm_order_detail_update_order_tags')})}>
                            <OrderTagsList tags={tags} _handleRemoveOrderTag={this._handleRemoveOrderTag}/>
                        </div>

                        {
                            !!error && <div className="text-danger mt-3">{error}</div>
                        }
                    </div>
                </div>
            </div>
        )
    }
}

OrderTags.propTypes = {
    orderId: PropTypes.string.isRequired,
    tags: PropTypes.array,
}

export default OrderTags
