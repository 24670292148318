import React, {Component} from 'react'
import PropTypes from 'prop-types'
import className from 'classnames'

class SpecificPopularTag extends Component {
    _handleClickTag = e => {
        e.preventDefault()

        const {tag} = this.props
        this.props.onCreateOrderTags(tag.title)

    }

    render() {
        const {tag} = this.props
        const {selected, title} = tag

        return (
            <li className="SpecificPopularTag d-inline-block">
                <span className={className("badge badge-info p-2 mr-3 mb-3 cursor-pointer", {"d-none": selected})} onClick={this._handleClickTag}>
                    {title}
                </span>
            </li>
        )
    }
}

SpecificPopularTag.propTypes = {
    onCreateOrderTags: PropTypes.func.isRequired,
}

export default SpecificPopularTag
