import React, {Component} from 'react'
import ProductLineItem from "./ProductLineItems/ProductLineItem"
import {searchProducts} from "../../../../services/api/ProductsServices"
import ProductLinePagination from "./ProductLineItems/ProductLinePagination"
import PropTypes from 'prop-types'
import { Spin } from 'antd'

class ProductLineItems extends Component {
    state = {
        products: [],
        pages: 1,
        error: "",
        loading: false
    }

    componentDidMount() {
        this._fetchListProductLine()
    }

    componentDidUpdate(prevProps) {
        if (prevProps.filter.term !== this.props.filter.term || prevProps.filter.category !== this.props.filter.category || prevProps.filter.page !== this.props.filter.page
            || prevProps.filter.supplier !== this.props.filter.supplier || prevProps.filter.available_tiktok !== this.props.filter.available_tiktok) {
            this._fetchListProductLine()
        }
    }

    _fetchListProductLine = async () => {
        this.setState({
            loading: true,
            error: ''
        })

        try {
            const {filter} = this.props
            const {data, success, message} = await searchProducts(filter)

            if (!success) {
                return this.setState({
                    error: message,
                    loading: false
                })
            }

            const {products, pages} = data

            return this.setState({
                products: [...products],
                pages: pages,
                loading: false
            })
        } catch (e) {
            return this.setState({
                error: e.message,
                loading: false
            })
        }
    }

    render() {
        const {_handUpdateState, filter} = this.props
        const {products, pages, loading} = this.state

        return (
            products.length > 0 ? <div className="ProductLineItems">
                {
                    <Spin spinning={loading} tip='Data Loading...'>
                        <>
                            <div className="Wrapper mb-4">
                                {
                                    products.map(product => {
                                        return <ProductLineItem
                                            key={product._id}
                                            product={product}
                                            _handUpdateState={_handUpdateState}
                                        />
                                    })
                                }
                            </div>

                            <ProductLinePagination pages={pages} filter={filter} _handUpdateState={_handUpdateState}/>
                        </>
                    </Spin> 

                }
            </div>: products.length===0 && loading===false ? <b style={{color:"red"}}>Not found products</b> : "Loading..."
        )
    }
}

ProductLineItems.propTypes = {
    _handUpdateState: PropTypes.func.isRequired,
    filter: PropTypes.object.isRequired,
}

export default ProductLineItems
