import React, { useState, useEffect } from 'react'
import PackageIssuesTable from './PackageIssuesTable'
import { Link } from 'react-router-dom'
import { Button, Input, Select, message, Spin, Pagination, DatePicker, Tooltip } from 'antd'
import { getIssues, getIssueType, exportIssues } from '../../../services/api/PackageIssuesServices'
import { getListSuppliersV2 } from '../../../services/api/SuppliersServices'
import { getLocalData } from '../../../services/StorageServices'
import UseDebounce from '../../shared/UseDebounce'
import downloadFileFromData from '../helps/downloadFileFromData'
import moment from 'moment'
import { supplierConfirms, IssueCategorys, Solutions, Status, Accepts, Notifications, IssueResolutionStatus, partnerRefunds } from '../helps/ListOptionSelect'
import PackageIssueConfirm from './PackageIssueConfirm'
import ModalImportIssue from './ModalImportIssue'

const { RangePicker } = DatePicker

function PackageIssuesPage(props) {
    const { isOpenIssueConfirm, totalIssueConfirm, totalIssueChecking, listIssueConfirm, listIssueCheck, onCloseIssueConfirm, listIssueReplace, totalIssueReplace, onChangeLoad, params } = props
    const { issue_type = '', created_at = {}, supplier: supplierFilter = '', supplier_confirm = [], product_type = '', statusParams = [] } = Object.assign({}, params)
    const { from = '', to = '' } = Object.assign({}, created_at)
    const rangeValue = (from && to) ? [moment(from, 'DD/MM/YYYY'), moment(to, 'DD/MM/YYYY')] : null
    const permissionsData = !!getLocalData('permissions') ? Object.keys(getLocalData('permissions')) : []
    const [loading, setLoading] = useState(false)
    const [listData, setListData] = useState([])
    const [page, setPage] = useState(1)
    const [limit, setLimit] = useState(10)
    const [total, setTotal] = useState(0)
    const [selectField, setSelectField] = useState(!!product_type ? 'product_type' : 'package_name')
    const [selectField2, setSelectField2] = useState('creator')
    const [packageName, setPackageName] = useState('')
    const [productType, setProductType] = useState(!!product_type ? product_type : '')
    const [referenceNumber, setReferenceNumber] = useState('')
    const [store, setStore] = useState('')
    const [creator, setCreator] = useState('')
    const [approver, setApprover] = useState('')
    const [issueCategory, setIssueCategoty] = useState('')
    const [issueType, setIssueType] = useState(!!issue_type ? issue_type : '')
    const [supplier, setSupplier] = useState(!!supplierFilter ? supplierFilter : '')
    const [allSupplier, setAllSupplier] = useState([])
    const [accepted, setAccepted] = useState('')
    const [solution, setSolution] = useState('')
    const [status, setStatus] = useState(!!statusParams.length ? statusParams : [])
    const [supplierConfirm, setSupplierConfirm] = useState(!!supplier_confirm.length ? supplier_confirm : [])
    const [createdAt, setCreatedAt] = useState((!!from && !!to) ? rangeValue : ['', ''])
    const [pushedAt, setPushedAt] = useState(['', ''])
    const [error, setError] = useState('')
    const [ProductionIssueTypes, setProductionIssueTypes] = useState([])
    const [ShipmentIssueTypes, setShipmentIssueTypes] = useState([])
    const [confirmationOverdueTotal, setConfirmationOverdueTotal] = useState('')
    const [notificationType, setNotificationType] = useState('')
    const [issueResolutionStatus, setIssueResolutionStatus] = useState('')
    const [partnerRefund, setPartnerRefund] = useState('')
    const [isOpenImport, setIsOpenImport] = useState(false)
    const [selectedAll, setSelectedAll] = useState(false)
    const [listSelecteds, setListSelecteds] = useState([])

    const debouncePackageName = UseDebounce(packageName, 1000)
    const debounceProductType = UseDebounce(productType, 1000)
    const debounceReferenceNumber = UseDebounce(referenceNumber, 1000)
    const debounceStore = UseDebounce(store, 1000)
    const debounceCreator = UseDebounce(creator, 1000)
    const debounceApprover = UseDebounce(approver, 1000)
    const unknownError = 'Unknown error'

    const canCreate = permissionsData.includes('ffm_upsert_issue')

    const fetchIssueTypes = async () => {
        try {
            const { success, data, message: mess } = await getIssueType()
            if (!success) {
                throw new Error(mess)
            }
            const { production, shipment } = { ...data }

            const newProduction = !!production && production.length > 0 ?
                production.map(i => ({ name: i.name, value: i._id, label: i.name, category: i.category, })) : []

            const newShipping = !!shipment && shipment.length > 0 ?
                shipment.map(i => ({ name: i.name, value: i._id, label: i.name, category: i.category, })) : []

            setProductionIssueTypes(newProduction)
            setShipmentIssueTypes(newShipping)
        } catch (error) {
            message.error(error.message)
        }
    }

    function convertString(input) {
        let values = input.split(/[, ]+/)

        let uniqueValues = Array.from(new Set(values.filter(value => value.trim() !== '')))

        return uniqueValues
    }

    const fetchListData = async () => {
        setLoading(true)
        setError('')
        const payload = {
            page: page,
            limit: limit,
            query: {},
            sort: "-updated_at",
        }
        if (packageName) payload.query.package_name = convertString(packageName)
        if (productType) payload.query.product_type = convertString(productType)
        if (referenceNumber) payload.query.reference_number = convertString(referenceNumber)
        if (store) payload.query.store = convertString(store)
        if (issueCategory) payload.query.issue_cat = issueCategory
        if (issueType) payload.query.issue_type = issueType
        if (accepted) payload.query.is_accepted = accepted === 'yes' ? '1' : '0'
        if (solution) payload.query.solution = solution
        if (!!status.length) payload.query.status = status
        if (creator) payload.query.created_by = convertString(creator)
        if (approver) payload.query.approved_by = convertString(approver)
        if (supplier) payload.query.supplier = supplier
        if (!!supplierConfirm.length) payload.query.supplier_confirm = supplierConfirm
        if (notificationType) payload.query.notification_type = notificationType
        if (createdAt && createdAt[0] && createdAt[1]) {
            payload.query.created_at = {
                from: createdAt[0].format("DD/MM/YYYY"),
                to: createdAt[1].format("DD/MM/YYYY"),
            }
        }
        if (pushedAt && pushedAt[0] && pushedAt[1]) {
            payload.query.pushed_at = {
                from: pushedAt[0].format("DD/MM/YYYY"),
                to: pushedAt[1].format("DD/MM/YYYY"),
            }
        }
        if (issueResolutionStatus) payload.query.resolution = issueResolutionStatus
        if (partnerRefund) payload.query.partner_refunded = +partnerRefund

        if (!Object.keys(payload.query).length) delete payload.query

        try {
            const { success, data, message: mess } = await getIssues(payload)
            if (!success) {
                throw new Error(mess)
            }
            setListData(data.issues)
            setTotal(data.total)
            setConfirmationOverdueTotal(data.confirmationOverdueTotal || '')
        } catch (error) {
            if (error.response && error.response.status !== 200) {
                const { data = {} } = { ...error.response }
                message.error(data.message || unknownError)
                setError(data.message || unknownError)
            } else {
                message.error(error.message || unknownError)
                setError(error.message || unknownError)
            }
        } finally {
            setLoading(false)
        }
    }

    const toQueryString = (obj, prefix) => {
        const str = []
        for (const p in obj) {
            if (obj.hasOwnProperty(p)) {
                const k = prefix ? `${prefix}[${p}]` : p, v = obj[p]
                str.push((v !== null && typeof v === "object") ?
                    toQueryString(v, k) :
                    `${encodeURIComponent(k)}=${encodeURIComponent(v)}`)
            }
        }
        return str.join("&")
    }

    const exportListData = async () => {
        setLoading(true)
        const payload = {
            page: page,
            limit: limit,
            query: {},
            filename: `Issues-Management-${moment().format('YYYY-MM-DD-HH-mm-ss')}.csv`
        }
        if (packageName) payload.query.package_name = convertString(packageName)
        if (productType) payload.query.product_type = convertString(productType)
        if (referenceNumber) payload.query.reference_number = convertString(referenceNumber)
        if (store) payload.query.store = convertString(store)
        if (issueCategory) payload.query.issue_cat = issueCategory
        if (issueType) payload.query.issue_type = issueType
        if (accepted) payload.query.is_accepted = accepted === 'yes' ? '1' : '0'
        if (solution) payload.query.solution = solution
        if (!!status.length) payload.query.status = status
        if (creator) payload.query.created_by = convertString(creator)
        if (approver) payload.query.approved_by = convertString(approver)
        if (supplier) payload.query.supplier = supplier
        if (!!supplierConfirm.length) payload.query.supplier_confirm = supplierConfirm
        if (notificationType) payload.query.notification_type = notificationType
        if (createdAt && createdAt[0] && createdAt[1]) {
            payload.query.created_at = {
                from: createdAt[0].format("DD/MM/YYYY"),
                to: createdAt[1].format("DD/MM/YYYY"),
            }
        }
        if (pushedAt && pushedAt[0] && pushedAt[1]) {
            payload.query.pushed_at = {
                from: pushedAt[0].format("DD/MM/YYYY"),
                to: pushedAt[1].format("DD/MM/YYYY"),
            }
        }
        if (issueResolutionStatus) payload.query.resolution = issueResolutionStatus
        if (partnerRefund) payload.query.partner_refunded = +partnerRefund

        if (!Object.keys(payload.query).length) delete payload.query

        const queryString = toQueryString(payload)

        try {
            const data = await exportIssues(queryString)
            if (data) {
                downloadFileFromData(data, { filename: `Issues-Management-${moment().format('YYYY-MM-DD-HH-mm-ss')}.csv` })
            }
        } catch (error) {
            if (error.response && error.response.status !== 200) {
                const { data = {} } = { ...error.response }
                message.error(data.message || unknownError)
                setError(data.message || unknownError)
            } else {
                message.error(error.message || unknownError)
                setError(error.message || unknownError)
            }
        } finally {
            setLoading(false)
        }
    }

    const getSuppliers = async () => {
        try {
            const { success, data, message: mess } = await getListSuppliersV2()
            if (!success) {
                throw new Error(mess)
            }
            const { suppliers = [] } = { ...data }
            const listSupplier = suppliers.filter(i => i && i.sku_prefix && !i.is_deleted)
            setAllSupplier(listSupplier)
        } catch (error) {
            if (error.response && error.response.status !== 200) {
                const { data = {} } = { ...error.response }
                message.error(data.message || unknownError)
            } else {
                message.error(error.message || unknownError)
            }
        }
    }

    const onChangeInput = (field, value) => {
        if (field === 'package_name') {
            setPackageName(value)
            setProductType('')
            setReferenceNumber('')
            setStore('')
        }
        if (field === 'product_type') {
            setPackageName('')
            setProductType(value)
            setReferenceNumber('')
            setStore('')
        }
        if (field === 'reference_number') {
            setPackageName('')
            setProductType('')
            setReferenceNumber(value)
            setStore('')
        }
        if (field === 'store') {
            setPackageName('')
            setProductType('')
            setReferenceNumber('')
            setStore(value)
        }
        if (field === 'creator') {
            setCreator(value)
            setApprover('')
        }
        if (field === 'approver') {
            setCreator('')
            setApprover(value)
        }
        setSelectedAll(false)
        setListSelecteds([])
        setPage(1)
    }

    const onChangeKeyPress = (e, field, value) => {
        if (e.key === 'Enter') {
            fetchListData()
        }
    }

    const handleChangeSelect = (field, value) => {
        if (field === 'issueCategory') setIssueCategoty(value)
        if (field === 'issueType') setIssueType(value)
        if (field === 'accepted') setAccepted(value)
        if (field === 'solution') setSolution(value)
        if (field === 'Status') setStatus(value)
        if (field === 'supplier') setSupplier(value)
        if (field === 'supplierConfirm') setSupplierConfirm(value)
        if (field === 'notificationType') setNotificationType(value)
        if (field === 'IssueResolutionStatus') setIssueResolutionStatus(value)
        if (field === 'partner_refunded') setPartnerRefund(value)
        setSelectedAll(false)
        setListSelecteds([])
        setPage(1)
    }

    const handPagePagination = (value) => {
        setPage(value)
        setSelectedAll(false)
        setListSelecteds([])
    }

    const handleShowSizeChange = (current, size) => {
        setLimit(size)
        setPage(1)
        setSelectedAll(false)
        setListSelecteds([])
    }

    const _handleChageSelectField = (value) => {
        setPackageName('')
        setProductType('')
        setReferenceNumber('')
        setStore('')
        setSelectField(value)
    }

    const _handleChageSelectField2 = (value) => {
        setCreator('')
        setApprover('')
        setSelectField2(value)
        setSelectedAll(false)
        setListSelecteds([])
    }

    const handleChangeDate = (field, value, dateString) => {
        if (field === 'created') {
            setCreatedAt(value)
        }
        if (field === 'pushed') {
            setPushedAt(value)
        }
        setSelectedAll(false)
        setListSelecteds([])
        setPage(1)
    }

    const clickConfirmationOverdueTotal = () => {
        setSelectField('package_name')
        setSelectField2('creator')
        setPackageName('')
        setProductType('')
        setReferenceNumber('')
        setStore('')
        setCreator('')
        setApprover('')
        setIssueCategoty('')
        setIssueType('')
        setSupplier('')
        setAccepted('')
        setSolution('')
        setStatus('confirmation_overdue')
        setSupplierConfirm('')
        setCreatedAt(['', ''])
        setPushedAt(['', ''])
        setNotificationType('')
        setSelectedAll(false)
        setListSelecteds([])
        setPage(1)
    }

    useEffect(() => {
        fetchListData()
    }, [
        debounceProductType, page, limit, debouncePackageName, debounceReferenceNumber, debounceStore, issueCategory, notificationType,
        issueType, accepted, solution, supplier, status, supplierConfirm, createdAt, pushedAt,
        issueResolutionStatus, partnerRefund
    ])

    useEffect(() => {
        getSuppliers()
        fetchIssueTypes()
    }, [])

    const Suppliers = allSupplier.map(i => ({
        name: i.name,
        value: i._id,
        label: i.name
    })).sort((a, b) => a.label.toLowerCase().localeCompare(b.label.toLowerCase()))

    let IssueType = []

    switch (issueCategory) {
        case 'production':
            IssueType = ProductionIssueTypes
            break
        case 'shipment':
            IssueType = ShipmentIssueTypes
            break
        default:
            IssueType = [...new Set([...ProductionIssueTypes, ...ShipmentIssueTypes])].reduce((acc, current) => {
                const x = acc.find(item => item.name === current.name)
                if (!x) {
                    return acc.concat([current])
                } else {
                    return acc
                }
            }, []).sort((a, b) => a.name.localeCompare(b.name))
            break
    }

    const groupValue = {
        package_name: packageName,
        product_type: productType,
        reference_number: referenceNumber,
        store: store,
        creator: creator,
        approver: approver,
    }

    const groupPlaceHolder = {
        package_name: 'Search for Package Name',
        product_type: 'Search for Product Type',
        reference_number: 'Search for Reference Number',
        store: 'Search for Store',
        creator: 'Search for Creator (Press Enter)',
        approver: 'Search for Approver (Press Enter)',
    }

    const handleCloseImport = (isReload = false) => {
        setIsOpenImport(false)
        if(isReload) fetchListData()
    }

    return (
        <div className='PackageIssuesPage'>
            <div className="container-fluid">
                <div className="MenuTitle pt-3 d-flex justify-content-between align-items-center">
                    Issues Management
                    <div className="Actions d-flex align-items-center justify-content-end">
                        <Link to='/a/package-issues/report' className='btn btn-outline-primary btn-sm px-4 rounded ml-3'>
                            <i className="fa fa-bar-chart mr-1" />Report
                        </Link>
                        <Button className='btn btn-outline-primary btn-sm px-4 rounded ml-3' disabled={loading} onClick={() => setIsOpenImport(true)}>Import</Button>
                        <Button className='btn btn-outline-primary btn-sm px-4 rounded ml-3' disabled={loading} onClick={exportListData}>Export</Button>
                        {(canCreate) &&
                            <Link className="ant-btn ant-btn-primary px-4 rounded ml-3" to='/a/package-issues/new'>Add Issue</Link>
                        }
                    </div>
                </div>
                <div className="SectionInner mt-3">
                    <div className="p-3">
                        <div className="filter">
                            <div className="align-items-center justify-content-between">
                                <div className="d-flex GroupFilter flex-wrap">
                                    <div className="itemFilter mb-3 mr-3" style={{ minWidth: '399px' }}>
                                        <Input.Group compact>
                                            <Select
                                                value={selectField}
                                                style={{ width: 150 }}
                                                onChange={_handleChageSelectField}
                                                disabled={loading}
                                                className='Group'
                                            >
                                                <Select.Option value="package_name">Package name</Select.Option>
                                                <Select.Option value="product_type">Product Type</Select.Option>
                                                <Select.Option value="reference_number">Reference number</Select.Option>
                                                <Select.Option value="store">Store</Select.Option>
                                            </Select>
                                            <Input
                                                style={{ width: 250 }}
                                                placeholder={groupPlaceHolder[selectField] || ''}
                                                onChange={e => onChangeInput(selectField, e.target.value)}
                                                value={groupValue[selectField] || ''}
                                                disabled={loading}
                                                allowClear
                                            />
                                        </Input.Group>
                                    </div>
                                    <div className="itemFilter mb-3 mr-3">
                                        <Select
                                            placeholder='Issue category'
                                            value={issueCategory || []}
                                            style={{ width: "150px" }}
                                            showArrow
                                            showSearch
                                            options={IssueCategorys}
                                            onChange={(value) => handleChangeSelect('issueCategory', value)}
                                            allowClear
                                            disabled={loading}
                                            filterOption={(inputValue, option) =>
                                                !!option && option.label.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
                                            }
                                        />
                                    </div>
                                    <div className="itemFilter mb-3 mr-3">
                                        <Select
                                            placeholder='Issue type'
                                            value={issueType || []}
                                            style={{ width: "170px" }}
                                            showArrow
                                            showSearch
                                            options={IssueType}
                                            onChange={(value) => handleChangeSelect('issueType', value)}
                                            allowClear
                                            disabled={loading}
                                            filterOption={(inputValue, option) =>
                                                !!option && option.label.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
                                            }
                                        />
                                    </div>
                                    <div className="itemFilter mb-3 mr-3">
                                        <Select
                                            placeholder={`Partner refund`}
                                            value={partnerRefund || []}
                                            style={{ width: "190px" }}
                                            showArrow
                                            options={partnerRefunds}
                                            onChange={(value) => handleChangeSelect('partner_refunded', value)}
                                            allowClear
                                            disabled={loading}
                                        />
                                    </div>
                                    <div className="itemFilter mb-3 mr-3">
                                        <Select
                                            placeholder='Solution'
                                            value={solution || []}
                                            style={{ width: "150px" }}
                                            showArrow
                                            showSearch
                                            options={Solutions}
                                            onChange={(value) => handleChangeSelect('solution', value)}
                                            allowClear
                                            disabled={loading}
                                            filterOption={(inputValue, option) =>
                                                !!option && option.label.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
                                            }
                                        />
                                    </div>
                                    <div className="itemFilter mb-3 mr-3">
                                        <Select
                                            mode='multiple'
                                            placeholder='Status'
                                            value={status || []}
                                            style={{ width: "150px" }}
                                            showArrow
                                            showSearch
                                            options={Status}
                                            onChange={(value) => handleChangeSelect('Status', value)}
                                            allowClear
                                            disabled={loading}
                                            filterOption={(inputValue, option) =>
                                                !!option && option.label.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
                                            }
                                        />
                                    </div>
                                    <div className="itemFilter mb-3 mr-3">
                                        <Input.Group className='d-flex align-items-center'>
                                            <RangePicker
                                                value={createdAt || []}
                                                placeholder={['Created Start', 'Created End']}
                                                onChange={(value, dateString) => handleChangeDate('created', value, dateString)}
                                                disabled={loading}
                                                allowClear
                                                format={"DD/MM/YYYY"}
                                                style={{ minWidth: 316 }}
                                                ranges={{
                                                    'Today': [moment().startOf('day'), moment().endOf('day')],
                                                    'Last 7 Days': [moment().subtract(6, 'day').startOf('day'), moment().endOf('day')],
                                                    'This Month': [moment().startOf('month').startOf('day'), moment().endOf('month').endOf('day')],
                                                }}
                                            />
                                        </Input.Group>
                                    </div>
                                </div>
                                <div className="d-flex GroupFilter flex-wrap">
                                    <div className="itemFilter mb-3 mr-3">
                                        <Input.Group compact>
                                            <Select
                                                value={selectField2}
                                                style={{ width: 150 }}
                                                onChange={_handleChageSelectField2}
                                                disabled={loading}
                                                className='Group'
                                            >
                                                <Select.Option value="creator">Creator</Select.Option>
                                                <Select.Option value="approver">Approver</Select.Option>
                                            </Select>
                                            <Input
                                                style={{ width: 250 }}
                                                placeholder={groupPlaceHolder[selectField2] || ''}
                                                onChange={e => onChangeInput(selectField2, e.target.value)}
                                                onKeyDown={e => onChangeKeyPress(e, selectField2, e.target.value)}
                                                value={groupValue[selectField2] || ''}
                                                disabled={loading}
                                                allowClear
                                            />
                                        </Input.Group>
                                    </div>
                                    <div className="itemFilter mb-3 mr-3">
                                        <Select
                                            placeholder='Supplier'
                                            value={supplier || []}
                                            style={{ width: "150px" }}
                                            showArrow
                                            showSearch
                                            options={Suppliers}
                                            onChange={(value) => handleChangeSelect('supplier', value)}
                                            allowClear
                                            disabled={loading}
                                            filterOption={(inputValue, option) =>
                                                !!option && option.label.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
                                            }
                                        />
                                    </div>
                                    <div className="itemFilter mb-3 mr-3">
                                        <Select
                                            mode='multiple'
                                            placeholder={`Partner's confirm`}
                                            value={supplierConfirm || []}
                                            style={{ width: "170px" }}
                                            showArrow
                                            showSearch
                                            options={supplierConfirms}
                                            onChange={(value) => handleChangeSelect('supplierConfirm', value)}
                                            allowClear
                                            disabled={loading}
                                            filterOption={(inputValue, option) =>
                                                !!option && option.label.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
                                            }
                                        />
                                    </div>
                                    <div className="itemFilter mb-3 mr-3">
                                        <Select
                                            placeholder='Issue resolution status'
                                            value={issueResolutionStatus || []}
                                            style={{ width: "190px" }}
                                            showArrow
                                            showSearch
                                            options={IssueResolutionStatus}
                                            onChange={(value) => handleChangeSelect('IssueResolutionStatus', value)}
                                            allowClear
                                            disabled={loading}
                                            filterOption={(inputValue, option) =>
                                                !!option && option.label.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
                                            }
                                        />
                                    </div>
                                    <div className="itemFilter mb-3 mr-3">
                                        <Select
                                            placeholder='Acceptable'
                                            value={accepted || []}
                                            style={{ width: "150px" }}
                                            showArrow
                                            showSearch
                                            options={Accepts}
                                            onChange={(value) => handleChangeSelect('accepted', value)}
                                            allowClear
                                            disabled={loading}
                                            filterOption={(inputValue, option) =>
                                                !!option && option.label.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
                                            }
                                        />
                                    </div>
                                    <div className="itemFilter mb-3 mr-3">
                                        <Select
                                            placeholder='Notification'
                                            value={notificationType || []}
                                            style={{ width: "150px" }}
                                            showArrow
                                            showSearch
                                            options={Notifications}
                                            onChange={(value) => handleChangeSelect('notificationType', value)}
                                            allowClear
                                            disabled={loading}
                                            filterOption={(inputValue, option) =>
                                                !!option && option.label.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
                                            }
                                        />
                                    </div>
                                    <div className="itemFilter mb-3 mr-3">
                                        <Input.Group className='d-flex align-items-center'>
                                            <RangePicker
                                                value={pushedAt || []}
                                                placeholder={['Pushed Start', 'Pushed End']}
                                                onChange={(value, dateString) => handleChangeDate('pushed', value, dateString)}
                                                disabled={loading}
                                                allowClear
                                                format={"DD/MM/YYYY"}
                                                style={{ minWidth: 316 }}
                                                ranges={{
                                                    'Today': [moment().startOf('day'), moment().endOf('day')],
                                                    'Last 7 Days': [moment().subtract(6, 'day').startOf('day'), moment().endOf('day')],
                                                    'This Month': [moment().startOf('month').startOf('day'), moment().endOf('month').endOf('day')],
                                                }}
                                            />
                                        </Input.Group>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="TotalEntity ml-3 customTotal">
                            {!!confirmationOverdueTotal &&
                                <Tooltip title='The issues have not been confirmed by the factory for over 24 hours.' placement='topRight'>
                                    <span className="text-danger mr-3 font-weight-bold cursor-pointer" onClick={() => clickConfirmationOverdueTotal()}>{`${confirmationOverdueTotal} issues are overdue for confirmation!`}</span>
                                </Tooltip>
                            }
                            Total: <span className="Entity ml-2">{total}</span>
                        </div>
                        {error && <label className='text-danger mb-0'>{error}</label>}
                        <Spin spinning={loading} tip='Loading...'>
                            <PackageIssuesTable
                                listData={listData}
                                refresh={fetchListData}
                                permissionsData={permissionsData}
                                setLoading={setLoading}
                                ProductionIssueTypes={ProductionIssueTypes}
                                ShipmentIssueTypes={ShipmentIssueTypes}
                                onChangeLoad={onChangeLoad}
                                issueCategory={issueCategory}
                                listSelecteds={listSelecteds}
                                setListSelecteds={setListSelecteds}
                                selectedAll={selectedAll}
                                setSelectedAll={setSelectedAll}
                            />
                        </Spin>
                        <Pagination
                            current={page}
                            total={total}
                            pageSize={limit}
                            showTotal={(sum, range) => sum > 0 &&
                                <div>
                                    {`Hiển thị ${range[0]} đến ${range[1]} của `}<strong>{`${total}`}</strong> issues
                                </div>}
                            onChange={handPagePagination}
                            className='text-right' showSizeChanger
                            pageSizeOptions={['10', '20', '50', '100']}
                            onShowSizeChange={handleShowSizeChange}
                        />

                        <PackageIssueConfirm
                            totalIssueConfirm={totalIssueConfirm}
                            totalIssueChecking={totalIssueChecking}
                            totalIssueReplace={totalIssueReplace}
                            listIssueConfirm={listIssueConfirm}
                            isOpenIssueConfirm={isOpenIssueConfirm}
                            onCloseIssueConfirm={onCloseIssueConfirm}
                            listIssueCheck={listIssueCheck}
                            listIssueReplace={listIssueReplace}
                            onChangeInput={onChangeInput}
                            onChangeSelect={handleChangeSelect}
                            onChageSelectField={_handleChageSelectField}
                        />

                        {
                            isOpenImport &&
                            <ModalImportIssue
                                isOpenImport={isOpenImport}
                                onCloseImport={handleCloseImport}
                                listSuppliers={Suppliers}
                                shipmentIssueTypes={ShipmentIssueTypes}
                            />
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}

export default PackageIssuesPage