import React from 'react'
import formatCurrency from "../../../../helpers/common/formatCurrency"

function ShippingAddressCod(props) {
    const {address, shipmentInfo} = props

    if (address === undefined || typeof address !== 'object' || Object.keys(address).length === 0) {
        return null
    }

    const {full_name, email, address: address1, province, district, ward, phone, street} = address

    return (
        <div className='ShippingAddressCod'>
            <div className="Info d-flex align-items-center justify-content-between">
                <div className="Title">Tên người nhận</div>
                <div className="Value font-weight-bold">{full_name}</div>
            </div>
            <div className="Info d-flex align-items-center justify-content-between">
                <div className="Title">Email</div>
                <div className="Value">{email}</div>
            </div>
            <div className="Info d-flex align-items-center justify-content-between">
                <div className="Title">Địa chỉ</div>
                <div className="Value">{address1}</div>
            </div>
            <div className="Info d-flex align-items-center justify-content-between">
                <div className="Title">Tỉnh / Thành</div>
                <div className="Value">{province}</div>
            </div>
            <div className="Info d-flex align-items-center justify-content-between">
                <div className="Title">Quận / Huyện</div>
                <div className="Value">{district}</div>
            </div>
            {
                !!ward && <div className="Info d-flex align-items-center justify-content-between">
                    <div className="Title">Phường / Xã</div>
                    <div className="Value">{ward}</div>
                </div>
            }
            {
                !!street && <div className="Info d-flex align-items-center justify-content-between">
                    <div className="Title">Đường phố</div>
                    <div className="Value">{street}</div>
                </div>
            }
            {
                !street && !ward && <div className="Info d-flex align-items-center justify-content-between">
                    <div className="text-danger">Thiếu thông tin Phường/Xã hoặc Đường phố</div>
                </div>
            }
            <div className="Info d-flex align-items-center justify-content-between">
                <div className="Title">Giá trị đơn hàng <small>không bao gồm giá ship</small></div>
                <div className="Value">
                    {
                        shipmentInfo !== undefined &&
                        shipmentInfo.hasOwnProperty('currency') &&
                        formatCurrency(shipmentInfo.currency || 'USD', shipmentInfo.subtotal || 0)
                    }
                </div>
            </div>
            <div className="Info d-flex align-items-center justify-content-between">
                <div className="Title">Số điện thoại</div>
                <div className="Value">{phone}</div>
            </div>
        </div>
    )
}

export default ShippingAddressCod