import React, { useEffect, useState } from 'react'
import { roleDetails, editRole } from '../../../services/api/UserService'
import { Modal, message, Input, Select } from 'antd'

function ModalEditRole(props) {
    const { visible, onClose, listPermission, editId, reset } = props
    const [name, setName] = useState('')
    const [permisstionsSelected, setPermisstionsSelected] = useState([])
    const { Option } = Select

    const getRoleDetails = async () => {
        try {
            const { data, success, message: mess } = await roleDetails(editId)
            if (!success) throw new Error(mess)
            setName(data.name || '')
            setPermisstionsSelected(data.permissions.map(i => i._id) || [])
        } catch (error) {
            if (error.response.status !== 200) {
                message.error(error.response.data.message)
            } else {
                message.error(error.message)
            }
        }
    }

    const handleClose = () => {
        onClose()
        setName('')
        setPermisstionsSelected([])
    }

    const handleEdit = async () => {
        try {
            const payload = {
                name: name,
                permissions: permisstionsSelected
            }
            const { success, data, message: mess } = await editRole(payload, editId)
            if (!success) throw new Error(mess)
            message.success('Role updated!!!')
            onClose()
            reset()
        } catch (error) {
            if (error.response.status !== 200) {
                message.error(error.response.data.message)
            } else {
                message.error(error.message)
            }
        }
    }
    const onchangeInput = (e) => {
        setName(e.target.value)
    }
    const onChangeSelect = (value) => {
        setPermisstionsSelected(value)
    }

    useEffect(() => {
        if (editId) {
            getRoleDetails()
        }
    }, [editId])
    return (
        <Modal
            visible={visible}
            title='Edit Role'
            width={970}
            className='ModalEditRole'
            onCancel={handleClose}
            okText='Save'
            onOk={handleEdit}
        >
            <div className='FormRole'>
                <div className="roleName mb-3">
                    <label>Role name</label>
                    <Input
                        placeholder='Role name'
                        value={name || ''}
                        onChange={onchangeInput}
                    >
                    </Input>

                </div>
                <div className="rolePermissions">
                    <label>Permissions</label>
                    <Select
                        placeholder='Select permissions'
                        className='W-100'
                        mode={'multiple'}
                        style={{ width: '100%' }}
                        allowClear
                        onChange={onChangeSelect}
                        value={permisstionsSelected || []}
                        optionFilterProp="children"
                    >
                        {listPermission && listPermission.length > 0 && listPermission.map((item, index) => (
                            <Option key={index} value={item._id}>{item.key}</Option>
                        ))}
                    </Select>
                </div>
            </div>
        </Modal>
    )
}

export default ModalEditRole