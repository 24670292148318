import React from 'react'

function SwitchCarrierThead(props) {
    return (
        <thead className='RequestThead'>
        <tr>
            <th className="RequestId">ID</th>
            <th className="Created">Created at</th>
            <th className="Package">Package</th>
            <th className="Carrier">Shipping carrier</th>
            <th className="status">Status</th>
            <th className="Carrier">User</th>
        </tr>
        </thead>
    )
}

export default SwitchCarrierThead
