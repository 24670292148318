import React, {Component} from 'react'
import PropTypes from 'prop-types'
import ArtworksTableRow from "./ArtworksTableRow"


class ArtworkTableInner extends Component {
    _renderTableBody = (artworks) => {
        if (!Array.isArray(artworks)) return null

        return artworks.map(artwork => {
                const {_id: artworkId} = artwork

                return (
                    <ArtworksTableRow artwork={artwork} key={artworkId}/>
                )
            }
        )
    }

    render() {
        const {artworks} = this.props

        return (
            <div className='ArtworkTableInner'>
                <div className="table-responsive">
                    <table className="table artworks-table Table">
                        <thead>
                        <tr>
                            <th>Thumbnail</th>
                            <th>Product ID</th>
                            <th>Order Item ID</th>
                            <th>Level</th>
                            <th>Attributes</th>
                            <th>Side</th>
                            <th>Platform</th>
                            <th>Type</th>
                            <th>Size</th>
                            <th>Created</th>
                        </tr>
                        </thead>
                        <tbody>
                        {this._renderTableBody(artworks)}
                        </tbody>
                    </table>
                </div>
            </div>
        )
    }
}

ArtworkTableInner.propTypes = {
    artworks: PropTypes.array.isRequired
}

export default ArtworkTableInner
