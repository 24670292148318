import React, {Component} from 'react'
import BulkSelectOrder from './head/BulkSelectOrder'

class OrdersTableHead extends Component {
    render() {
        return (
            <thead className="OrdersTableHead thead-light">
            <tr>
                <BulkSelectOrder {...this.props}/>
                <th>Number</th>
                {/* <th>Platform</th> */}
                <th>Paid at</th>
                <th>Address</th>
                <th>Store Mode</th>
                <th>Payment Status</th>
                <th>Fulfillment Status</th>
                <th>Shipping Plan</th>
                <th>Tags</th>
                <th className='OrderNote'>Order Note</th>
                <th className='MappingCondition'>Mapping Condition</th>
                <th className="text-center">Artwork</th>
                <th className="text-center">Tracking</th>
            </tr>
            </thead>
        )
    }
}

export default OrdersTableHead
