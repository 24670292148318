import React from 'react'
import PropTypes from 'prop-types'
import {Radio, Checkbox} from "antd"

Store.propTypes = {
    storeType: PropTypes.string,
    storeString: PropTypes.string,
    storeList: PropTypes.any,
    storeMode: PropTypes.array,
    validateStoreMode: PropTypes.string,
    validateStoreList: PropTypes.string,
    onChangeStoreType: PropTypes.func.isRequired,
    onChangeStoreList: PropTypes.func.isRequired,
    onRemoveValueFirst: PropTypes.func.isRequired,
    onClickAddStoreList: PropTypes.func.isRequired,
    onChangeStoreMode: PropTypes.func.isRequired
}

function Store(props) {
    const {
        storeType,
        storeString,
        storeList,
        storeMode,
        onChangeStoreType,
        onChangeStoreList,
        onRemoveValueFirst,
        onClickAddStoreList,
        validateStoreMode,
        validateStoreList
    } = props

    const listStoreModes = [
        {label: 'Normal', value: 'normal'},
        {label: 'Built-in payment', value: 'merchize-payment'},
        {label: 'Marketplace', value: 'marketplace'}
    ]

    const onChange = (checkedValues) => {
        const {onChangeStoreMode} = props

        if (typeof onChangeStoreMode === 'function') {
            onChangeStoreMode(checkedValues)
        }
    }

    return (
        <div className="FormItem">
            <label htmlFor="store" className="LabelDiscount">Store</label>
            <Radio.Group id="store" onChange={onChangeStoreType} value={storeType}>
                <Radio value='all'>All</Radio>

                <div className="d-flex">
                    <Radio value='store_mode' className='mr-3'>Store mode</Radio>
                    {
                        !!validateStoreMode && validateStoreMode.length > 0 && <span className="SmallText text-danger">
                            {validateStoreMode}
                        </span>
                    }
                </div>

                <Checkbox.Group
                    options={listStoreModes}
                    disabled={storeType !== 'store_mode'}
                    value={storeMode}
                    onChange={onChange}
                />

                <Radio value='custom'>Specific store</Radio>
            </Radio.Group>
            <input
                className={`TypeInput Custom ${!!validateStoreList && validateStoreList.length > 0 ? 'Error' : ''}`}
                type="text"
                disabled={storeType !== 'custom'}
                placeholder="" value={storeString}
                onChange={onChangeStoreList}
                onKeyPress={onChangeStoreList}
            />
            <span
                className={`TypeCharacter Custom ${storeType !== 'custom' ? 'Disable' : ''}`}
                onClick={onClickAddStoreList}
            >
                Add
            </span>

            {
                !!validateStoreList && validateStoreList.length > 0 && <div className="SmallText text-danger">
                    {validateStoreList}
                </div>
            }

            <ul className="ListStoreSelected">
                {
                    !!storeList && storeList.length > 0 && storeList.map((item, index) => (
                        <li key={index}>{item}
                            <span
                                className="Remove ti-close"
                                onClick={() => onRemoveValueFirst(item)}
                            />
                        </li>
                    ))
                }
            </ul>
        </div>
    )
}

export default Store
