import React, {Component} from 'react'
import {userChangePassword} from '../../../services/api/UserService'
import getHistory from '../../../store/getHistory'
import {getLocalData} from '../../../services/StorageServices'

class ChangePasswordPage extends Component {
    state = {
        loading: false,
        error: '',
    }

    _formData = {}

    _handleSubmit = e => {
        e.preventDefault()

        const isValid = this._changePasswordValidator()

        if (!isValid) return

        this._reqChangePassword()
    }

    _handleChange = e => {
        const {name, value} = e.target

        this._formData[name] = value.trim()
    }

    _changePasswordValidator = () => {
        const {email, new_password} = this._formData

        if (!email || !new_password) {
            this.setState({
                error: 'Invalid password!',
            })

            return false
        }

        return true
    }

    _reqChangePassword = async () => {
        try {
            this.setState({
                loading: true,
                error: '',
            })

            const {success, message} = await userChangePassword(this._formData)

            if (!success) throw new Error(message)
    
            const history = getHistory()
            history.push('/a/user')

        } catch (e) {
            this.setState({
                error: e.message,
            })
        } finally {
            this.setState({
                loading: false,
            })
        }
    }

    render() {
        const {error, loading} = this.state

        const user = getLocalData('user')
        const {email} = Object.assign({}, user)
        this._formData['email'] = email.trim()

        return (
            <div className='ChangePasswordPage pt-5'>
                <div className='row'>
                    <div className='col-12 col-xl-8 m-auto'>
                        <div className='bg-white p-4 shadow-sm rounded'>
                            <h1 className='PageTitle'>Account Change Password</h1>

                            <form onSubmit={this._handleSubmit} autocomplete="off">

                                <div className='form-group'>
                                    <label htmlFor=''>Email</label>
                                    <input type='email' className='form-control' autoFocus required name='email'
                                           value={email} readOnly/>
                                </div>

                                <div className='form-group'>
                                    <label htmlFor=''>New Password</label>
                                    <input id="fakepassword" style={{display: "none"}} type="password" name="fakenewpassword"></input>
                                    <input type='password' className='form-control' required name='new_password' autocomplete="new-password"
                                    onChange={this._handleChange}
                                    />
                                </div>

                                <button type='submit' className='btn btn-primary px-4' disabled={loading}>Change Password
                                </button>
                            </form>

                            {
                                error && <div className='text-danger mt-3'>{error}</div>
                            }
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default ChangePasswordPage
