import React, {Component} from 'react'
import PropTypes from 'prop-types'

class OrderDelayGenLabel extends Component {
    render() {

        const {generate_label_config} = this.props.preference

        return (
            <div className="OrderPreferenceShipping">
                <div className="ShippingInner mt-3">
                    <div className="Title">Delay generate tracking</div>
                    <div className="Content">
                        <div className="Row">
                            <div className="Label">Option:</div>
                            {
                                (!generate_label_config || typeof generate_label_config !== 'object' || !Object.keys(generate_label_config).length)
                                    ? <div className="Value">
                                        Default
                                    </div>
                                    : generate_label_config.hasOwnProperty('delay_days') ?
                                    <div className="Value">Custom {generate_label_config.delay_days}
                                        {generate_label_config.delay_days > 1 ? ' days' : ' day'}
                                    </div>
                                    : <div className="Value">
                                        {generate_label_config.mode === 'after_pushed' ? 'ASAP' : 'Packaging stage'}
                                    </div>
                            }

                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

OrderDelayGenLabel.propTypes = {
    preference: PropTypes.object.isRequired
}

export default OrderDelayGenLabel