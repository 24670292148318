import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Route, Switch, Redirect } from 'react-router'
import HomeContainer from '../../home/components/HomeContainer'
import OrdersPageContainer from '../../orders/components/OrdersPageContainer'
import OrderFulfillmentContainer from '../../order-fulfillment/components/OrderFulfillmentContainer'
import NavbarLeft from './NavbarLeft'
import SettingsPageContainer from '../../settings/components/SettingsPageContainer'
import SettingContainer from '../../setting/components/SettingContainer'
import classNames from 'classnames'
import ArtworksPageContainer from '../../artworks/components/ArtworksPageContainer'
import PlatformsContainer from '../../platforms/components/PlatformsContainer'
import PlatformSettingsContainer from '../../platform-settings/components/PlatformSettingsContainer'
import PlatformSettingContainer from '../../platform-setting/components/PlatformSettingContainer'
import PlatformContainer from '../../platform/components/PlatformContainer'
import OrderPageContainer from '../../order/components/OrderPageContainer'
import ProductsPageContainer from '../../productsV2/components/ProductsPageContainer'
import CreateProductPageContainer from '../../create-product/components/CreateProductPageContainer'
import ProductPageContainer from '../../product-v2/components/ProductPageContainer'
import SuppliersPageContainer from '../../suppliers/components/SuppliersPageContainer'
import ShippingPlansPageContainer from '../../shipping-plans/components/ShippingPlansPageContainer'
import ShippingPlanPageContainer from '../../shipping-plan/components/ShippingPlanPageContainer'
import ShippingPlanNewPageContainer from '../../shipping-plan-new/ShippingPlanNewPageContainer'
// import ShippingZonesPageContainer from '../../shipping-zones/ShippingZonesPageContainer'
import ShippingZonesPageContainer from '../../shipping-zones-v2/ShippingZonesPageContainer'
import ShippingZonesCreate from '../../shipping-zones-v2/actions/ShippingZonesCreate'//
import ShippingZonePageContainer from '../../shipping-zone/components/ShippingZonePageContainer'
import ShippingFeePageContainer from '../../shipping-fee/components/ShippingFeePageContainer'
import ShippingFeeNewPageContainer from '../../shipping-fee/shippingfee-new/ShippingFeeNewPageContainer'
import ShippingFeeEditPageContainer from '../../shipping-fee/shippingfee-edit/ShippingFeeEditPageContainer'
import ShippingZoneNewPageContainer from '../../shipping-zone-new/components/ShippingZoneNewPageContainer'
import WebhookPageContainer from '../../webhook-job/components/WebhookPageContainer'
import ShippingLabelPageContainer from '../../shipping-label/components/ShippingLabelPageContainer'
import ChangePasswordPageContainer from '../../change-password/components/ChangePasswordPageContainer'
import NotificationContainer from '../../notification/components/NotificationContainer'
import OrderPartnersContainer from '../../order-partners/components/OrderPartnersContainer'
import OrderPartnersContainerV2 from '../../order-partners-v2/components/OrderPartnersContainer'
import OrderPartnerContainer from "../../order-partner/components/OrderPartnerContainer"
import StaticsContainer from "../../statics/components/StaticsContainer"
import FulfillmentDiscountsContainer from "../../fulfillment-discounts/components/FulfillmentDiscountsContainer"
import DiscountNewContainer from "../../discount-new/components/DiscountNewContainer"
import DiscountEditContainer from "../../discount-edit/components/DiscountEditContainer"
import RequestContainer from "../../requests/components/RequestsContainer"
import SwitchCarrierContainer from "../../switch-carrier/components/SwitchCarrierContainer"
import ScanDetailContainer from "../../scan-detail/components/ScanDetailContainer"
import ShippingPriceContainer from "../../shipping-price/components/ShippingPriceContainer"
import ManageAutoMapContainer from "../../manage-auto-map/components/ManageAutoMapContainer"
import VariantsContainer from '../../list-variants/components/VariantsContainer'
import MappingConditionPageContainer from "../../mapping-condition/components/MappingConditionPageContainer"
import ChargeRefundPageContainer from '../../charge-refund/components/ChargeRefundPageContainer'
import CostControlPageContainer from '../../cost-control/components/CostControlPageContainer'
import FileDetailPageContainer from '../../cost-control/components/FileDetailPageContainer'
import OrderProfitPageContainer from '../../order-profit/components/OrderProfitPageContainer'
import ListPrintFileContainer from '../../print-files/components/ListPrintFileContainer'
import RolesPageContainer from '../../roles/components/RolesPageContainer'
import PackageSplittingRulesContainer from '../../package-splitting-rules/components/PackageSplittingRulesContainer'
import PackageSplittingRuleCreateContainer from '../../package-splitting-rule-create/components/PackageSplittingRuleCreateContainer'
import withPermissions from '../../shared/WithPermissions'
import NotAuthorizedContainer from '../../not-authorized/components/NotAuthorizedContainer'
import SettingHolidayDetailContainer from '../../setting-holiday-detail/components/SettingHolidayDetailContainer'
import {getOrderFailed} from "../../../services/api/OrdersServices"
import { getIssueConfirm } from '../../../services/api/PackageIssuesServices'
import { getFBAOrdersAlerts, getManiFestWarning } from '../../../services/api/ExportOrderServices'
import { getLocalData } from '../../../services/StorageServices'
import MappingVariantsPageContainer from '../../mapping-variants/components/MappingVariantsPageContainer'
import MappingVariantDetail from '../../mapping-variant-detail/components/MappingVariantDetail'
import SettingHolidayContainer from '../../setting-holiday/components/SettingHolidayContainer'
import CreateFastProductionRuleContainer from '../../setting-fast-production-rules-create/components/CreateFastProductionRuleContainer'
import SpecialCaseContainer from '../../special-case/components/SpecialCaseContainer'
import SpecialCaseNewContainer from '../../special-case-new/components/SpecialCaseNewContainer'
import SpecialCaseEditContainer from '../../special-case-edit/components/SpecialCaseEditContainer'
import PackageIssuesContainer from '../../package-issues/components/PackageIssuesContainer'
import CreatePackageIssueContainer from '../../package-issues-create/components/CreatePackageIssueContainer'
import PackageIssueReportContainer from '../../package-issue-report/components/PackageIssueReportContainer'

class Dashboard extends Component {
    state = {
        isCollapsed: true,
        listOrderFailed: [],
        totalsFailed: 0,
        openOrderFailed: false,
        newFBAOrders: [],
        FBAUpdateLabel: [],
        FBAUpdateBoxInfo: [],
        totalFBAWarning: 0,
        page: 1,
        totalManiFestWarning: 0,
        waitingPaidOrders: [],
        waitingMappingOrders: [],
        waitingFulfillOrders : [],
        validateWarnings : [],
        totalIssueConfirm: 0,
        totalIssueChecking: 0,
        totalIssueReplace: 0,
        listIssueConfirm: [],
        listIssueCheck: [],
        listIssueReplace: [],
        isOpenIssueConfirm: false,
        isLoad : false
    }

    _fetchOrderFailed = async () => {
        try {

            const payload = {
                page: this.state.page,
                limit: 5,
                pushed_to_supplier_status: "not_found"
            }
            const data = await getOrderFailed(payload)
            const { success, message, data: response } = data
            const { fulfillments, totalFulfillments } = response

            if (!success) throw new Error(message)

            this.setState({
                listOrderFailed: fulfillments,
                totalsFailed: totalFulfillments
            })

        } catch (e) {
            throw new Error(e.message)
        }
    }

    _fetchFBAOrdersAlerts = async () => {
        try {
            const res = await getFBAOrdersAlerts()
            const { data, success, message } = res
            if (!success) throw new Error(message)
            const { newFBAOrders, FBAOrderReceivedPlfBoxLabels, FBAOrderWithBoxInfoUpdated } = data
            this.setState({
                newFBAOrders: newFBAOrders || [],
                FBAUpdateLabel: FBAOrderReceivedPlfBoxLabels || [],
                FBAUpdateBoxInfo: FBAOrderWithBoxInfoUpdated || [],
                totalFBAWarning: (newFBAOrders.length + FBAOrderReceivedPlfBoxLabels.length + FBAOrderWithBoxInfoUpdated.length) || 0,
            })
        } catch (error) {
            throw new Error(error.message)
        }
    }

    _fetchManiFestWarning = async () => {
        try {
            const res = await getManiFestWarning()
            const {data, success, message} = res
            if(!success) throw new Error(message) 

            const {validateWarnings, waitingFulfillOrders, waitingMappingOrders, waitingPaidOrders} = data

            this.setState({
                validateWarnings: validateWarnings || [],
                waitingFulfillOrders: waitingFulfillOrders || [],
                waitingMappingOrders: waitingMappingOrders || [],
                waitingPaidOrders: waitingPaidOrders || [],
                totalManiFestWarning: (validateWarnings.length + waitingFulfillOrders.length + waitingMappingOrders.length + waitingPaidOrders.length)
            })
            
        } catch (error) {
            throw new Error(error.message)
        }
    }

    _fetchIssueConfirm = async () => {
        const {listIssueCheck, listIssueConfirm, listIssueReplace} = this.state
        try {
            const data = await getIssueConfirm({limit: 1000})
            const { success, message, data: response } = data
            if (!success) throw new Error(message)
            const { checking, confirmed, need_replace } = response
            const { total: totalChecking = 0, issues: issueChecking = [] } = Object.assign({}, checking)
            const { total: totalConfirm = 0, issues: issueConfirm = [] } = Object.assign({}, confirmed)
            const { total: totalReplace = 0, issues: issueReplace = [] } = Object.assign({}, need_replace)

            this.setState({
                listIssueConfirm: issueConfirm.length > 0 ? issueConfirm : listIssueConfirm,
                listIssueCheck: issueChecking.length > 0 ? issueChecking : listIssueCheck,
                listIssueReplace: issueReplace.length > 0 ? issueReplace : listIssueReplace,
                totalIssueConfirm: totalConfirm,
                totalIssueChecking: totalChecking,
                totalIssueReplace: totalReplace,
            })

        } catch (e) {
            throw new Error(e.message)
        }
    }

    _handlePageChange = current => {
        this.setState({
            page: current,
        }, () => {
            if (this._timeOut) clearTimeout(this._timeOut)
            this._timeOut = setTimeout(async () => {

                this._fetchOrderFailed()
            }, 100)
        })
    }

    componentDidMount() {
        const permissionsData = !!getLocalData('permissions') ? Object.keys(getLocalData('permissions')) : []
        if (permissionsData.includes('ffm_order_view')) {
            this._fetchOrderFailed()
            this._fetchFBAOrdersAlerts()
            this._fetchManiFestWarning()
            this._fetchIssueConfirm()
        }
    }

    componentDidUpdate(prevProps, prevState){
        if(this.state.isLoad !== prevState.isLoad) {
            const permissionsData = !!getLocalData('permissions') ? Object.keys(getLocalData('permissions')) : []
            if (permissionsData.includes('ffm_order_view')) {
                this._fetchIssueConfirm()
            }
        }
    }

    _handleClick = (e) => {
        e.preventDefault()
        this.setState({
            isCollapsed: !this.state.isCollapsed,
        })
    }

    _handleClickLinkNav = () => {
        this.setState({
            isCollapsed: true,
        })
        if (localStorage['tab']) localStorage.removeItem('tab')
    }

    _onIsCloseOrderFailed = () => {
        this.setState({
            openOrderFailed: false
        })
    }

    _onIsOpenOrderFailed = () => {
        this.setState({
            openOrderFailed: true
        })
        this._fetchOrderFailed()
        this._fetchFBAOrdersAlerts()
        this._fetchManiFestWarning()
    }

    _onIsCloseIssueConfirm = () => {
        this.setState({
            isOpenIssueConfirm: false
        })
    }

    _onIsOpenIssueConfirm = () => {
        this.setState({
            isOpenIssueConfirm: true
        })
    }

    _handleChangeLoad = () => {
        this.setState({isLoad: !this.state.isLoad})
    }


    render() {
        const { basePath } = this.props
        const {
            isCollapsed, listOrderFailed, totalsFailed, openOrderFailed, page, newFBAOrders, FBAUpdateLabel, FBAUpdateBoxInfo, totalFBAWarning,
            totalManiFestWarning, waitingPaidOrders, waitingMappingOrders, waitingFulfillOrders, validateWarnings, totalIssueConfirm, listIssueConfirm, listIssueCheck,
            isOpenIssueConfirm, totalIssueChecking, totalIssueReplace, listIssueReplace
        } = this.state

        const permissionsData = !!getLocalData('permissions') ? Object.keys(getLocalData('permissions')) : []

        const OrdersPageWithPermission = withPermissions(
            OrdersPageContainer,
            'ffm_order_view'
        )
        
        const IssuePageWithPermission = withPermissions(
            PackageIssuesContainer,
            'ffm_issue_list'
        )

        return (
            <div className={classNames('DashboardContainer', { isCollapsed })}>

                <NavbarLeft
                    onClick={this._handleClick}
                    onClickLinkNav={this._handleClickLinkNav}
                    totalsFailed={totalsFailed}
                    handleShowOrderFailed={this._onIsOpenOrderFailed}
                    totalFBAWarning={totalFBAWarning}
                    permissionsData={permissionsData}
                    totalIssueConfirm={totalIssueConfirm}
                    totalIssueChecking={totalIssueChecking}
                    totalIssueReplace={totalIssueReplace}
                    onOpenIssueConfirm={this._onIsOpenIssueConfirm}
                    isCollapsed={isCollapsed}
                    totalManiFestWarning={totalManiFestWarning}
                />

                <div className="DashboardInner">
                    <div className="MainContent">
                        <div className="MainContentInner">
                            <Switch>
                                <Route path={`${basePath}`} exact component={HomeContainer} />

                                <Route path={`${basePath}/platforms`} exact component={withPermissions(PlatformsContainer, 'ffm_platform_read')} />

                                <Route path={`${basePath}/platforms/:id`} exact component={withPermissions(PlatformsContainer, 'ffm_platform_read')} />

                                <Route path={`${basePath}/platforms/:id/settings`} exact
                                    component={withPermissions(PlatformSettingsContainer, 'ffm_platform_read')} />

                                <Route path={`${basePath}/platforms/:id/settings/:category`} exact
                                    component={withPermissions(PlatformSettingsContainer, 'ffm_platform_read')} />

                                <Route path={`${basePath}/roles`} exact component={withPermissions(RolesPageContainer, 'ffm_role_management')} />

                                <Route
                                    path={`${basePath}/orders`} exact
                                    render={props =>
                                        <OrdersPageWithPermission
                                            {...props}
                                            listOrderFailed={listOrderFailed}
                                            onIsCloseOrderFailed={this._onIsCloseOrderFailed}
                                            openOrderFailed={openOrderFailed}
                                            totalsFailed={totalsFailed}
                                            handlePageChange={this._handlePageChange}
                                            pageOrderMiss={page}
                                            newFBAOrders={newFBAOrders}
                                            FBAUpdateLabel={FBAUpdateLabel}
                                            FBAUpdateBoxInfo={FBAUpdateBoxInfo}
                                            totalFBAWarning={totalFBAWarning}
                                            totalManiFestWarning={totalManiFestWarning}
                                            waitingPaidOrders={waitingPaidOrders}
                                            waitingMappingOrders={waitingMappingOrders}
                                            waitingFulfillOrders={waitingFulfillOrders}
                                            validateWarnings={validateWarnings}
                                        />}
                                />

                                <Route path={`${basePath}/orders/:id`} exact component={withPermissions(OrderPageContainer, 'ffm_order_detail_view')} />

                                <Route path={`${basePath}/orders/:id/fulfillment`} exact
                                    component={withPermissions(OrderFulfillmentContainer, 'ffm_order_detail_view')} />

                                <Route path={`${basePath}/artworks`} exact component={withPermissions(ArtworksPageContainer, 'ffm_artwork_read')} />

                                <Route path={`${basePath}/suppliers`} exact component={withPermissions(SuppliersPageContainer, 'ffm_supplier_read')} />

                                <Route path={`${basePath}/settings`} exact component={withPermissions(SettingsPageContainer, '')} />

                                <Route path={`${basePath}/settings/:setting`} exact component={withPermissions(SettingContainer, '')} />

                                <Route path={`${basePath}/settings/fast-production/create`} exact component={withPermissions(CreateFastProductionRuleContainer, 'ffm_fast_production_rule_create')} />

                                <Route path={`${basePath}/settings/fast-production/:ruleId`} exact component={withPermissions(CreateFastProductionRuleContainer, 'ffm_fast_production_rule_detail')} />

                                <Route path={`${basePath}/products/`} exact component={withPermissions(ProductsPageContainer, 'ffm_product_line_read')} />

                                <Route path={`${basePath}/products/new`} component={withPermissions(CreateProductPageContainer, 'ffm_product_line_create')} />

                                <Route path={`${basePath}/products/:productId`} component={withPermissions(ProductPageContainer, '')} />

                                <Route path={`${basePath}/shipping`} exact component={withPermissions(ShippingPlansPageContainer, 'ffm_shipping_plan_read')} />

                                <Route path={`${basePath}/shipping/new`} exact
                                    component={withPermissions(ShippingPlanNewPageContainer, 'ffm_shipping_plan_create')} />

                                <Route path={`${basePath}/shipping/:id`} component={withPermissions(ShippingPlanPageContainer, 'ffm_shipping_plan_read')} />

                                <Route path={`${basePath}/shipping-zones`} exact
                                    component={withPermissions(ShippingZonesPageContainer, 'ffm_shipping_zone_read')} />

                                <Route path={`${basePath}/shipping-zones/create`}
                                    component={withPermissions(ShippingZonesCreate, 'ffm_shipping_zone_create')} />

                                <Route path={`${basePath}/shipping-zones/new`}
                                    component={withPermissions(ShippingZoneNewPageContainer, 'ffm_shipping_plan_read')} />

                                <Route path={`${basePath}/shipping-zones/:id`} component={withPermissions(ShippingZonePageContainer, 'ffm_shipping_zone_read')} />

                                <Route path={`${basePath}/orders-supplier`} exact component={withPermissions(OrderPartnersContainerV2, 'ffm_package_view')} />

                                <Route path={`${basePath}/orders-supplier/:fufillmentId`} exact
                                    component={withPermissions(OrderPartnerContainer, 'ffm_package_detail_read')} />

                                <Route path={`${basePath}/orders-supplier-old`} exact
                                    component={withPermissions(OrderPartnersContainer, 'ffm_package_view')} />

                                <Route path={`${basePath}/fulfillment-discounts`}
                                    component={withPermissions(FulfillmentDiscountsContainer, 'ffm_fulfillment_discounts_read')} />

                                <Route path={`${basePath}/notification`} component={withPermissions(NotificationContainer, 'ffm_notification_read')} />

                                <Route path={`${basePath}/webhook-jobs`} component={withPermissions(WebhookPageContainer, 'ffm_webhook_job_read')} />


                                <Route path={`${basePath}/shipping-label`} exact
                                    component={withPermissions(ShippingLabelPageContainer, '')} />

                                <Route path={`${basePath}/change-password`} exact
                                    component={withPermissions(ChangePasswordPageContainer, '')} />

                                <Route path={`${basePath}/analytics`} exact component={withPermissions(StaticsContainer, 'ffm_dashboard_read')} />

                                <Route path={`${basePath}/discounts/new`} exact component={withPermissions(DiscountNewContainer, 'ffm_fulfillment_discounts_create')} />

                                <Route path={`${basePath}/discounts/:discountId`} exact
                                    component={withPermissions(DiscountEditContainer, 'ffm_fulfillment_discounts_read')} />

                                <Route path={`${basePath}/requests`} exact component={withPermissions(RequestContainer, 'ffm_request_update_read')} />

                                <Route path={`${basePath}/switch-carriers`} exact component={withPermissions(SwitchCarrierContainer, 'ffm_request_update_read')} />

                                <Route path={`${basePath}/switch-carrier/:scanId`} exact
                                    component={withPermissions(ScanDetailContainer, 'ffm_request_update_read')} />

                                <Route path={`${basePath}/print-files`} exact component={withPermissions(ListPrintFileContainer, 'ffm_printing_file_read')} />

                                <Route path={`${basePath}/mapping-variants`} exact component={withPermissions(MappingVariantsPageContainer, 'ffm_catalog_variant_read')} />

                                <Route path={`${basePath}/mapping-variants/:catalog_id`} exact component={withPermissions(MappingVariantDetail, 'ffm_catalog_variant_read')} />

                                <Route path={`${basePath}/shipping-cost`} exact component={withPermissions(ShippingPriceContainer, '')} />

                                <Route path={`${basePath}/list-variants`} exact component={withPermissions(VariantsContainer, 'ffm_fulfillment_variants_read')} />

                                <Route path={`${basePath}/mapping-rules`} exact component={withPermissions(ManageAutoMapContainer, '')} />

                                <Route path={`${basePath}/shipping-fee`} exact component={withPermissions(ShippingFeePageContainer, 'ffm_shipping_fee_read')} />

                                <Route path={`${basePath}/shipping-fee/new`} exact
                                    component={withPermissions(ShippingFeeNewPageContainer, 'ffm_shipping_fee_create')} />

                                <Route path={`${basePath}/shipping-fee/:id`} exact
                                    component={withPermissions(ShippingFeeEditPageContainer, 'ffm_shipping_fee_read')} />

                                <Route path={`${basePath}/mapping-condition/:id?`} exact
                                    component={withPermissions(MappingConditionPageContainer, 'ffm_mapping_condition_read')} />

                                <Route path={`${basePath}/charge-refund`} exact component={withPermissions(ChargeRefundPageContainer, 'ffm_charge_and_refund_view_filter')} />

                                <Route path={`${basePath}/cost-control`} exact component={withPermissions(CostControlPageContainer, 'ffm_cost_control_read')} />

                                <Route path={`${basePath}/cost-control/:id`} exact component={withPermissions(FileDetailPageContainer, 'ffm_cost_control_read')} />

                                <Route path={`${basePath}/order-profit`} exact component={withPermissions(OrderProfitPageContainer, 'ffm_order_profit_all')} />

                                <Route path={`${basePath}/analytics/daily-report/:tab?/:store?`} exact component={withPermissions(StaticsContainer, 'ffm_dashboard_read')} />

                                <Route path={`${basePath}/setting-holiday`} exact component={withPermissions(SettingHolidayContainer, '')} />

                                <Route path={`${basePath}/setting-holiday/:id`} exact component={withPermissions(SettingHolidayDetailContainer, '')} />


                                <Route path={`${basePath}/special-case`} exact component={withPermissions(SpecialCaseContainer, 'ffm_mapping_special_case_read')} />

                                <Route path={`${basePath}/special-case-create`} exact component={withPermissions(SpecialCaseNewContainer, 'ffm_mapping_special_case_create')} />

                                <Route path={`${basePath}/special-case/:id`} exact component={withPermissions(SpecialCaseEditContainer, 'ffm_mapping_special_case_update')} />
                                
                                <Route path={`${basePath}/package-splitting-rules`} exact component={withPermissions(PackageSplittingRulesContainer, 'ffm_view_split_package_rule')} />
                                
                                <Route path={`${basePath}/package-splitting-rules/create`} exact component={withPermissions(PackageSplittingRuleCreateContainer, 'ffm_create_split_package_rule')} />
                                <Route path={`${basePath}/package-splitting-rules/:ruleId`} exact component={withPermissions(PackageSplittingRuleCreateContainer, 'ffm_view_split_package_rule')} />

                                <Route
                                    path={`${basePath}/package-issues`} exact
                                    render={(props) =>
                                        <IssuePageWithPermission
                                            {...props}
                                            isOpenIssueConfirm={isOpenIssueConfirm}
                                            totalIssueConfirm={totalIssueConfirm}
                                            totalIssueChecking={totalIssueChecking}
                                            totalIssueReplace={totalIssueReplace}
                                            listIssueConfirm={listIssueConfirm}
                                            listIssueCheck={listIssueCheck}
                                            listIssueReplace={listIssueReplace}
                                            onCloseIssueConfirm={this._onIsCloseIssueConfirm}
                                            onChangeLoad={this._handleChangeLoad}
                                        />}
                                />

                                <Route path={`${basePath}/package-issues/report`} exact component={PackageIssueReportContainer}/>

                                <Route path={`${basePath}/package-issues/new`} exact component={withPermissions(CreatePackageIssueContainer, 'ffm_upsert_issue')} />

                                <Route path={`${basePath}/package-issues/:id/:category/:store`} exact component={withPermissions(CreatePackageIssueContainer, 'ffm_upsert_issue')} />


                                <Route path={`${basePath}/NotAuthorized`} exact component={NotAuthorizedContainer}>

                                </Route>

                                <Redirect to={`${basePath}`} />

                            </Switch>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

Dashboard.propTypes = {
    basePath: PropTypes.string.isRequired,
    store: PropTypes.object.isRequired,
}

export default Dashboard
