import React from 'react'
import RequestsPage from "./RequestsPage"
import DocTitleByStore from "../../dashboard/shared/DocTitleByStore"

function RequestsContainer() {
    return (
        <div className='RequestsContainer'>
            <DocTitleByStore title="Requests"/>
            <RequestsPage/>
        </div>
    )
}

export default RequestsContainer
