import React, {Component} from 'react'
import PropTypes from 'prop-types'
import {bulkOrderActions} from '../../../../../services/api/OrdersServices'
import {message, Modal, notification} from 'antd'
import {ObjectInspector} from 'react-inspector'

const _doAction = async (params) => {
    const {selectedOrders, slug, onSuccess} = params

    try {
        const {data, success, message: resMessage} = await bulkOrderActions(selectedOrders, slug)

        if (!success) {
            throw new Error(resMessage)
        }

        const {error: resError, data: resData} = data

        if (resError === 0 || data === true) {
            message.success('Success!')

            if (typeof onSuccess === 'function') {
                onSuccess()
            }
        } else {
            let action = '...'

            if ('complete-fulfill' === slug) {
                action = 'completed'
            } else if ('reject-fulfill' === slug) {
                action = 'rejected'
            }

            notification.warning({
                message: `Some orders couldn't be ${action}!`,
                description: <ObjectInspector data={resData}/>,
                duration: 0,
                style: {width: 600, marginLeft: -216},
            })
        }
    } catch (e) {
        notification.error({
            message: 'ERROR',
            description: e.message,
            duration: 0,
        })
    }
}

class OrderBulkAction extends Component {

    _handleClick = e => {
        e.preventDefault()

        const props = this.props

        Modal.confirm({
            title: `Please confirm "${props.name}"`,
            onOk() {
                return _doAction(props)
            },
        })
    }

    render() {
        return (
            <div className='OrderBulkAction' onClick={this._handleClick}>
                {this.props.name}
            </div>
        )
    }
}

OrderBulkAction.propTypes = {
    name: PropTypes.string.isRequired,
    slug: PropTypes.string.isRequired,
    selectedOrders: PropTypes.array.isRequired,
    onSuccess: PropTypes.func,
}

export default OrderBulkAction
