import React, {Component} from 'react'

class ShippingZoneMethodsHead extends Component {
    render() {
        return (
            <thead className="ShippingZoneNewMethodsHead thead-light">
            <tr>
                <th className="table-col-20">Plan</th>
                <th className="table-col-40">Product</th>
                <th className="table-col-15">First item</th>
                <th>Additional item</th>
                <th className="table-col-5">&nbsp;</th>
            </tr>
            </thead>
        )
    }
}

export default ShippingZoneMethodsHead
