import React, {useEffect, useState, useContext} from 'react'
import {Button, Modal, Input, Spin, message, Tooltip} from "antd"
import StatusStyle from "../../../../shared/StatusStyle"
import NumberFormat from 'react-number-format'
import {getOrderBasecost, reviewChargeUpdateDesign} from "../../../../../services/api/OrdersServices"
import formatCurrency from "../../../../../helpers/common/formatCurrency"
import {currency} from "../../../../../statics/currency"
import RequestContext from "../../../context/RequestContext"
import {ReactComponent as PriceInfo} from "../../../../../statics/assets/images/icons8-info.svg"

const ConfirmChargeModal = (props) => {
    const RequestsPageContext = useContext(RequestContext)
    const {fetchRequest} = RequestsPageContext
    const {isOpen, toggleModal, item} = props
    const [loading, setLoading] = useState(false)
    const [loadingCharge, setLoadingCharge] = useState(false)
    const [loadingInfo, setLoadingInfo] = useState(false)
    const [error, setError] = useState('')
    const [errorValue, setErrorValue] = useState('')
    const [chargeValue, setChargeValue] = useState(0)
    const [chargeInfo, setChargeInfo] = useState({})

    const symbolCurrency = (Object.keys(chargeInfo).length > 0 && chargeInfo.currency !== undefined) ? currency.find(item => item.value === chargeInfo.currency) : {symbol: '$'}

    const _fetchBasecost = async () => {
        setLoadingInfo(true)
        try {
            const params = {
                item_ids: [item.order_item]
            }
            const {success, data, message: errorBasecost} = await getOrderBasecost(item.order, params)
            if (!success) {
                setLoadingInfo(false)
                return setError(errorBasecost)
            }
            const {fulfillments} = data
            setChargeInfo(fulfillments[0])
            setChargeValue(parseFloat(fulfillments[0].variant_data.base_cost) * fulfillments[0].quantity)
        } catch (e) {
            setError(e.message)
        } finally {
            setLoadingInfo(false)
        }
    }

    useEffect(() => {
        _fetchBasecost()
        // eslint-disable-next-line
    }, [])
    // Simple check number
    const isNumber = (n) => !!`${n}`.match(/^-?[0-9]+(.[0-9]+)?$/)
    const _handleChangeValue = (values) => {
        const {value} = values
        const {variant_data} = chargeInfo
        const {variantPriceByStore, base_cost} = variant_data
        const {useVariantPriceByStore, value: byStoreValue} = {...variantPriceByStore}
        const vBaseCost = useVariantPriceByStore && isNumber(byStoreValue) ? byStoreValue : base_cost
        const maxValue = (parseFloat(vBaseCost) * chargeInfo.quantity).toFixed(3)
        setErrorValue('')
        setChargeValue(value)
        if (parseFloat(value) > maxValue) {
            setErrorValue(`This value must be less than ${formatCurrency(chargeInfo.currency, maxValue)}`)
        }
        if (parseFloat(value) <= 0) {
            setErrorValue(`This value must be larger 0`)
        }
    }

    const _onConfirmCharge = async () => {
        if (parseFloat(chargeValue) === 0) return setErrorValue(`This value must be larger 0`)
        setLoadingCharge(true)
        try {
            const payload = {
                charge_amount: parseFloat(chargeValue),
                currency: chargeInfo.currency,
                request_only: false
            }
            const {success, message: error} = await reviewChargeUpdateDesign(item.order, item._id, payload)
            if (!success) {
                setLoadingCharge(false)
                return setError(error)
            }
            message.success('Send request successfully!')
            fetchRequest()
        } catch (e) {
            setError(e.message)
        } finally {
            setLoadingCharge(false)
        }
    }

    const _onConfirmOnly = async () => {
        setLoading(true)
        try {
            const payload = {
                charge_amount: 0,
                currency: chargeInfo.currency,
                request_only: true
            }
            const {success, message: error} = await reviewChargeUpdateDesign(item.order, item._id, payload)
            if (!success) {
                setLoading(false)
                return setError(error)
            }
            message.success('Send request successfully!')
            fetchRequest()
        } catch (e) {
            setError(e.message)
        } finally {
            setLoading(false)
        }
    }

    return <Modal
        className="ModalConfirmChargeUpdateDesign"
        title="Review charge for design update"
        width={800}
        visible={isOpen}
        onCancel={() => toggleModal(!isOpen)}
        footer={[
            <Button key="RequestOnly" disabled={loadingCharge || loading || loadingInfo} loading={loading}
                    onClick={_onConfirmOnly}>
                Request Only
            </Button>,
            <Button key="RequestCharge" disabled={loadingCharge || loading || errorValue || loadingInfo} type="primary"
                    loading={loadingCharge} onClick={_onConfirmCharge}>
                Request with charge
            </Button>
        ]}
    >
        <Spin spinning={loadingInfo} tip="Loading data...">
            {Object.keys(chargeInfo).length > 0 && <div className="Content">
                {error && <div className="text-danger mb-3">{error}</div>}
                <div className="RequestInfo">
                    <strong>Request information</strong>
                    <div className="row">
                        <div className="col-6">
                            <label>OrderID:</label> {item.order_number}
                        </div>
                        <div className="col-6">
                            <label>Source:</label> <span className="text-uppercase">{item.source}</span>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-6">
                            <label>Store:</label> {item.store_id}
                        </div>
                        <div className="col-6">
                            <label>Production status:</label> <StatusStyle
                            status={item.production_status}>{item.production_status}</StatusStyle>
                        </div>
                    </div>
                </div>
                <div className="Note">
                    {item.content}
                </div>
                <div className="ChargeInfo">
                    <strong>Charge information</strong>
                    <div className="itemChargeInfo">

                        <label>Base cost:</label>
                        {
                            chargeInfo.variant_data.variantPriceByStore && chargeInfo.variant_data.variantPriceByStore.value ? formatCurrency(chargeInfo.currency, chargeInfo.variant_data.variantPriceByStore.value) : formatCurrency(chargeInfo.currency, chargeInfo.variant_data.base_cost)
                        }

                        {
                            chargeInfo.variant_data.variantPriceByStore && chargeInfo.variant_data.variantPriceByStore.useVariantPriceByStore === true ?
                                <Tooltip title="Price apply for seller">
                                    <span><PriceInfo width="20px" height="20px" style={{
                                        position: 'relative',
                                        top: '4px',
                                        marginLeft: '4px'
                                    }}/></span>
                                </Tooltip> : ''
                        }

                        {
                            chargeInfo.variant_data.currentVariantPriceByStore && chargeInfo.variant_data.currentVariantPriceByStore.value ? <>
                                <label style={{width: 230, marginLeft: 30}}>Current Variant Price By
                                    Store:</label> {formatCurrency(chargeInfo.currency, chargeInfo.variant_data.currentVariantPriceByStore.value)}</> : ''
                        }
                        {
                            chargeInfo.variant_data.currentVariantPriceByStore && chargeInfo.variant_data.currentVariantPriceByStore.useVariantPriceByStore === true ?
                                <Tooltip title="Price apply for seller">
                                    <span><PriceInfo width="20px" height="20px" style={{
                                        position: 'relative',
                                        top: '4px',
                                        marginLeft: '4px'
                                    }}/></span>
                                </Tooltip> : ''
                        }

                    </div>
                    <div className="itemChargeInfo">
                        <label>Quantity:</label> {chargeInfo.quantity}
                    </div>
                    <div className="itemChargeInfo ValueCharge d-flex align-items-center">
                        <label className="mb-0">Value:</label>
                        <span className={errorValue && 'error'}>
                        <Input.Group compact>
                        <NumberFormat
                            thousandSeparator={chargeInfo.currency === 'USD' ? true : '.'}
                            decimalSeparator={chargeInfo.currency === 'USD' ? '.' : ','}
                            decimalScale={chargeInfo.currency === 'USD' ? 2 : 0}
                            prefix=""
                            value={chargeValue}
                            onValueChange={_handleChangeValue}
                        />
                        <Button icon={symbolCurrency !== undefined ? symbolCurrency.symbol : '$'}/>
                    </Input.Group>
                    </span>
                        {errorValue && <div className="text-danger ml-3">{errorValue}</div>}
                    </div>
                </div>
            </div>}
        </Spin>
    </Modal>
}

export default ConfirmChargeModal