import React, {Component} from 'react'
import {
    getPlatformSettings,
    savePlatformSettings,
    generateSecret
} from '../../../../services/api/PlatformSettingsServices'
import Url from './webhook/Url'
import {Button} from "antd"

class Webhook extends Component {
    state = {
        platform: {
            _id: this.props.match.params.id
        },
        loading: {
            fetchSettings: false,
            saveSettings: false,
            generateSecret: false
        },
        err: {
            fetchSettings: null,
            saveSettings: null,
            generateSecret: null
        },
        settings: {},
        secretKey: {}
    }

    componentDidMount() {
        return this._fetchSettings()
    }

    _changeLoading = (loadingState, errState) => {
        const {loading, err} = this.state
        return {
            loading: {
                ...loading,
                ...loadingState
            },
            err: (errState) ? err : {
                ...err,
                ...errState
            }
        }
    }

    _saveSettings = async () => {
        const {platform, settings} = this.state
        this.setState({
            ...this._changeLoading({saveSettings: true}, {saveSettings: null})
        })

        try {
            const {success, data, message} = await savePlatformSettings(platform._id, 'webhook', settings)
            if (success) return this.setState({
                ...this._changeLoading({saveSettings: false}),
                settings: data,
            })

            this.setState({
                ...this._changeLoading({saveSettings: false}, {saveSettings: message}),
            })
        } catch (e) {
            this.setState({
                ...this._changeLoading({saveSettings: false}, {saveSettings: e.message || e})
            })
        }
    }

    _fetchSettings = async () => {
        const {platform} = this.state
        this.setState({
            ...this._changeLoading({fetchSettings: true}, {fetchSettings: null})
        })

        try {
            const {success, data, message} = await getPlatformSettings(platform._id, 'webhook')
            if (success) {
                return this.setState({
                    ...this._changeLoading({fetchSettings: false}),
                    settings: data,
                })
            }
            return this.setState({
                ...this._changeLoading({fetchSettings: false}, {fetchSettings: message})
            })
        } catch (e) {
            this.setState({
                ...this._changeLoading({fetchSettings: false}, {fetchSettings: e.message || e})
            })
        }
    }

    _fetchSecureSecret = async () => {
        this.setState({
            ...this._changeLoading({generateSecret: true}, {generateSecret: null})
        })

        try {
            const {success, message} = await generateSecret(this.props.match.params.id)

            if (success) {
                return this.setState({
                    ...this._changeLoading({generateSecret: false}),
                }, () => {
                    this._fetchSettings()
                })
            }

            return this.setState({
                ...this._changeLoading({generateSecret: false}, {generateSecret: message})
            })
        } catch (e) {
            this.setState({
                ...this._changeLoading({generateSecret: false}, {generateSecret: e.message || e})
            })
        }

    }

    _changeSetting = (key, value) => {
        this.setState(({settings}) => ({
            settings: {
                ...settings,
                [key]: value,
            }
        }))
    }

    _onSubmit = (e) => {
        e.preventDefault()

        return this._saveSettings()
    }

    render() {
        const {settings, loading, err, secretKey} = this.state

        return (
            <div className="Webhook">
                <div className='HeaderTop d-flex align-items-center justify-content-between'>
                    <div className="Title">Webhook</div>
                    <Button type='primary' onClick={this._fetchSecureSecret} loading={loading.generateSecret}>
                        Generate Secret Key
                    </Button>
                </div>

                <form onSubmit={this._onSubmit}>
                    <div className="SettingSection">
                        <div className="SectionDetails">
                            <p className="font-bold">URL</p>
                        </div>
                        <div className="SectionSettings">
                            <div className="SectionInner">
                                {
                                    (err.fetchSettings !== null || err.generateSecret !== null || err.saveSettings !== null) &&
                                    <div className='text-danger mb-3'>
                                        {err.fetchSettings || err.saveSettings || err.generateSecret}
                                    </div>
                                }
                                <Url onChangeSetting={this._changeSetting} settings={settings} secretKey={secretKey}
                                     loading={loading.fetchSettings || loading.saveSettings || loading.generateSecret}/>
                            </div>
                        </div>
                    </div>
                    <div className="SaveButtonGroup">
                        <button className="btn Button" type="submit"
                                disabled={loading.fetchSettings || loading.saveSettings || loading.generateSecret}>
                            Save
                        </button>
                    </div>
                </form>
            </div>
        )
    }
}

export default Webhook
