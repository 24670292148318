import React, {Component} from 'react'
import {Link} from "react-router-dom"
import formatCurrency from "../../../../helpers/common/formatCurrency"
import imageDefault from '../../../../helpers/common/imageDefault'
import {Button, message, Tooltip, Select, Popover} from "antd"
import {addMappingRule} from "../../../../services/api/ArtworksServices"
import broker from "../../OrderBroker"
import {ReactComponent as PriceInfo} from "../../../../statics/assets/images/icons8-info.svg"
import { getLocalData } from '../../../../services/StorageServices'
import {updateFulfillmentItem} from '../../../../services/api/OrdersServices'

class FulfilledPackageItemMap extends Component {
    state = {
        loading: false,
        preset: '',
        open: false
    }

    componentDidMount(){
        const {fulfillItem} = this.props
        const {meta} = Object.assign({}, fulfillItem)
        const {preset_id} = Object.assign({}, meta)
        this.setState({
            preset: !!preset_id ? preset_id : ''
        })

    }

    _onToggleMappingRule = async () => {
        this.setState({
            loading: true
        })

        try {
            const {mapping_rule, orderItemId} = this.props
            const payload = {
                order_item_id: orderItemId
            }

            if (mapping_rule) {
                Object.assign(payload, {mapping_rule: mapping_rule._id})
            }

            const response = await addMappingRule(payload)

            const {success, message: error} = response

            if (!success) {
                this.setState({
                    loading: false
                })

                return message.error(error)
            }

            this.setState({
                loading: false
            }, () => {
                message.success('Created rule success')
                broker.publish('ORDER_RESTORE')
                broker.publish("ORDER_REFRESH_ORDER")
            })
        } catch (e) {
            this.setState({
                loading: false
            })

            message.error(e.message)
        }
    }

    _updateFulfillmentItem = async (fulfilledPackageId, payload) => {
        const {fetchHistory} = this.props

        try {
            const response = await updateFulfillmentItem(fulfilledPackageId, payload)

            const {success, message: error} = response

            if (!success) {
                this.setState({
                    loading: false
                })

                return message.error(error)
            }

            message.success("Update preset successfully!")
            fetchHistory()
        } catch (e) {
            message.error(e.message)
        }
    }

    hanldChangePreset = (preset) => {
        const {orderId, fulfillItem, fulfilledPackageId} = this.props
        const payload = {
            orderId,
            items: [
                {
                    _id: fulfillItem._id,
                    meta: {
                        preset_id : preset
                    }
                }
            ] 
        }
        
        this.setState({
            preset 
        }, () => this._updateFulfillmentItem(fulfilledPackageId, payload))
        
    }

    _togglePriceDetail = () => {
        this.setState({
            open: !this.state.open
        })
    };

    _renderPriceDetail = () => {
        const {price, first_rate, second_rate} = this.props.fulfillItem
        const {currency} = this.props.productLineVariant

        return(
            <>
            <div className='d-flex mb-1 priceDetailItem'>
                <span className="Label font-weight-500">Base:</span>
                <span className="Value">{formatCurrency(currency, price || 0)}</span>
            </div>

            <div className='d-flex mb-1 priceDetailItem'>
                <span className="Label font-weight-500">First rate:</span>
                <span className="Value">{formatCurrency(currency, first_rate || 0)}</span>
            </div>

            <div className='d-flex mb-1 priceDetailItem'>
                <span className="Label font-weight-500">Second rate:</span>
                <span className="Value">{formatCurrency(currency, second_rate || 0)}</span>
            </div>
            </>
        )
    };

    render() {
        const {loading, preset, open} = this.state
        const {productLineVariant, orderItem, artworkType, orderType, quantity, isCheckVersionOrderV2, supplier, slug, fulfillItem} = this.props
        const {sku_prefix} = Object.assign({}, supplier)
        const {alert_message = '', price, first_rate, second_rate} = Object.assign({}, fulfillItem)

        if (typeof productLineVariant !== 'object' || !Object.keys(productLineVariant).length) return (
            <td className="FulfilledPackageItemMap"/>
        )

        const {
            product,
            base_cost,
            options,
            image,
            additional_price,
            sku,
            currency,
            discount_amount,
            discount_id,
            custom_base_cost,
            variantPriceByStore,
            mapping_rule,
        } = productLineVariant
        
        // const {mapping_rule} = orderItem
        const imageObject = Object.assign({}, image)
        const productObject = Object.assign({}, product)
        const vOptions = Array.isArray(options) ? options : []

        const permissionsData = getLocalData('permissions') ? getLocalData('permissions') : {}
        const changePresetPermission = !!permissionsData['ffm_package_update']

        return (
            <td className="FulfilledPackageItemMap">
                <div className="row">
                    <div className="col-4">
                        {
                            imageObject.hasOwnProperty("url") &&
                            <img
                                src={imageObject.url}
                                alt="Product line thumbnail"
                                onError={(e) => {
                                    e.target.onerror = null;
                                    e.target.src = imageDefault
                                }}
                            />
                        }
                    </div>

                    <div className="col-8">
                        <Link to={`/a/products/${productObject._id}`}
                              className="d-block mb-2">{productObject.title}</Link>
                        <div className="d-flex mb-1">
                            <span className="Label font-weight-500">Price:</span>
                            <span className="Value">
                                {
                                    (!!variantPriceByStore && variantPriceByStore.hasOwnProperty('useVariantPriceByStore') && variantPriceByStore.useVariantPriceByStore) ? 
                                    <>
                                    {formatCurrency(currency, variantPriceByStore.value || 0)}
                                    <Tooltip title="Price apply for seller">
                                            <span><PriceInfo width="20px" height="20px" style={{position: 'relative', top: '4px', marginLeft: '4px'}}/></span>
                                        </Tooltip>
                                    </> : 
                                    formatCurrency(currency, !!custom_base_cost? custom_base_cost: base_cost || 0)
                                }
                            </span>
                        </div>

                        {(discount_amount !== undefined && discount_amount > 0 && (orderType!=="FBA" && slug !== "express")) && (
                            <div className="d-flex mb-1">
                                <span className="Label font-weight-500">Discount:</span>
                                <span className="Value">
                                    {discount_id !== undefined ? (
                                        <Link to={`/a/discounts/${discount_id}`}
                                              className="d-block mb-2">-{formatCurrency(currency, discount_amount || 0)}</Link>
                                    ) : (
                                        <span>-{formatCurrency(currency, discount_amount || 0)}</span>
                                    )}
                                </span>
                            </div>
                        )}

                        {
                            (additional_price !== null && additional_price > 0 && (orderType!=="FBA" && slug !== "express")) &&
                            <div className="d-flex mb-1">
                                <span className="Label font-weight-500">Additional price:</span>
                                <span className="Value">{formatCurrency(currency, additional_price || 0)}</span>
                            </div>
                        }

                        {
                            vOptions.map(option => {
                                const {attribute, name: optionName} = option
                                const {name: attributeName} = attribute

                                return (
                                    <div className="d-flex mb-1" key={option._id}>
                                        <span className="Label font-weight-500">{attributeName}:</span>
                                        <span className="Value">{optionName}</span>
                                    </div>
                                )
                            })
                        }

                        <div className="d-flex mb-1">
                            <span className="Label font-weight-500">SKU:</span>
                            <span className="Value">{sku}</span>
                        </div>

                        {isCheckVersionOrderV2 && <div className="d-flex mb-1">
                            <span className="Label font-weight-500">Quantity:</span>
                            <span className="Value">{quantity}</span>
                        </div>}
                        
                        {
                            mapping_rule &&
                            <div className="d-flex mb-1">
                                <span className="Label font-weight-500">Rule ID:</span>
                                <span className="Value"><strong>{mapping_rule.id}</strong></span>
                            </div>
                        }

                        {sku_prefix === "CC" && <div className="d-flex mb-1">
                            <span className="Label font-weight-500">Preset:</span>
                            <Select
                                placeholder={'Enter preset...'}
                                value={preset || []}
                                onChange={this.hanldChangePreset}
                                style={{width: 200}}
                                disabled={!changePresetPermission}
                            >
                                <Select.Option value={'EXACT_ARTWORK_PLACEMENT'}>Exact artwork placement</Select.Option>
                                <Select.Option value={'LEFT_CHEST'}>1 - Left chest</Select.Option>
                                <Select.Option value={'FULL_FRONT'}>2 - Full front</Select.Option>
                                <Select.Option value={'DYE_SUB_DEFAULT'}>4 - Dye Sub Default</Select.Option>
                            </Select>
                        </div>}

                        <div className='price-detail'>
                        <Popover
                            content={this._renderPriceDetail}
                            title="Price detail"
                            trigger="click"
                            open={open}
                            onOpenChange={this._togglePriceDetail}
                            >
                            <Button className='price-detail-button mt-2'>Price detail</Button>
                        </Popover>
                        </div>
                    </div>

                    {
                        permissionsData.hasOwnProperty('ffm_order_detail_create_rule') && artworkType !== 'productvariant' && !mapping_rule &&
                        <div className="col-12 mt-0">
                            <Button
                                onClick={this._onToggleMappingRule}
                                className='CustomButton AddNewButton'
                                disabled={loading}
                                loading={loading}
                            >
                                Create rule
                            </Button>
                        </div>
                    }
                    {
                        !!alert_message && <div className="text-danger col-12 mt-0"><i>{alert_message}</i></div>
                    }
                </div>
            </td>
        )
    }
}

export default FulfilledPackageItemMap
