import React, { useState, useEffect } from 'react'
import PackageSplittingRulesTable from './PackageSplittingRulesTable'
import { Link } from 'react-router-dom'
import { Button, Input, Select, message, Spin, Pagination } from 'antd'
import { SearchOutlined } from '@ant-design/icons'
import {
    getListPackageSplittingRules,
    exportPackageSplittingRules,
} from '../../../services/api/PackageSplittingRulesServices'
import { getListSuppliersV2 } from '../../../services/api/SuppliersServices'
import { getLocalData } from '../../../services/StorageServices'
import UseDebounce from '../../shared/UseDebounce'
import downloadFileFromData from '../../../helpers/downloadFileFromData'
import moment from 'moment'

function PackageSplittingRulesPage() {
    const { Option } = Select
    const permissionsData = !!getLocalData('permissions') ? Object.keys(getLocalData('permissions')) : []
    const [loading, setLoading] = useState(false)
    const [listRule, setListRule] = useState([])
    const [page, setPage] = useState(1)
    const [limit, setLimit] = useState(10)
    const [total, setTotal] = useState(0)
    const [productType, setProductType] = useState('')
    const [shippingZone, setShippingZone] = useState('')
    const [supplier, setSupplier] = useState('')
    const [allSupplier, setAllSupplier] = useState([])
    const [splitType, setSplitType] = useState('')
    const [status, setStatus] = useState('active')
    const [error, setError] = useState('')

    const debounceProductType = UseDebounce(productType, 1000)
    const unknownError = 'Unknown error'

    const canCreate = permissionsData.includes('ffm_create_split_package_rule')

    const fetchListRule = async () => {
        setLoading(true)
        setError('')
        const payload = {
            page: page,
            limit: limit
        }
        if (productType) payload.product_type = productType
        if (shippingZone) payload.shipping_zone = shippingZone
        if (supplier) payload.supplier = supplier
        if (splitType) payload.split_type = splitType
        if (status) payload.status = status

        try {
            const { success, data, message: mess } = await getListPackageSplittingRules(payload)
            if (!success) {
                throw new Error(mess)
            }
            setListRule(data.rules || [])
            setTotal(data.total)
        } catch (error) {
            if (error.response.status !== 200) {
                const { data = {} } = { ...error.response }
                message.error(data.message || unknownError)
                setError(data.message || unknownError)
            } else {
                message.error(error.message || unknownError)
                setError(error.message || unknownError)
            }
        } finally {
            setLoading(false)
        }
    }

    const exportListRule = async () => {
        setLoading(true)
        const payload = {
            page: page,
            limit: limit
        }
        if (productType) payload.product_type = productType
        if (shippingZone) payload.shipping_zone = shippingZone
        if (supplier) payload.supplier = supplier
        if (splitType) payload.split_type = splitType
        if (status) payload.status = status

        try {
            const data = await exportPackageSplittingRules(payload)
            if (data) {
                downloadFileFromData(data, { filename: `Package-Splitting-Rules-${moment().format('YYYY-MM-DD-HH-mm-ss')}.csv` })
            }
        } catch (error) {
			if (error.response.status !== 200) {
				const { data = {} } = { ...error.response }
				message.error(data.message || unknownError)
				setError(data.message || unknownError)
			} else {
				message.error(error.message || unknownError)
				setError(error.message || unknownError)
			}
        } finally {
            setLoading(false)
        }
    }

    const getSuppliers = async () => {
        try {
            const { success, data, message: mess } = await getListSuppliersV2()
            if (!success) {
                throw new Error(mess)
            }
            const { suppliers = [] } = { ...data }
            const listSupplier = suppliers.filter(i => i && i.sku_prefix && !i.is_deleted)
            setAllSupplier(listSupplier)
        } catch (error) {
            if (error.response.status !== 200) {
				const { data = {} } = { ...error.response }
				message.error(data.message || unknownError)
			} else {
				message.error(error.message || unknownError)
			}
        }
    }

    const onChangeInput = (field, value) => {
        if (field === 'product_type') {
            setProductType(value)
        }
        setPage(1)
    }

    const handleChangeSelect = (field, value) => {
        if (field === 'ShippingZone') setShippingZone(value)
        if (field === 'Supplier') setSupplier(value)
        if (field === 'SplitType') setSplitType(value)
        if (field === 'Status') setStatus(value)
        setPage(1)
    }

    const handPagePagination = (value) => {
        setPage(value)
    }

    const handleShowSizeChange = (current, size) => {
        setLimit(size)
        setPage(1)
    }

    useEffect(() => {
        fetchListRule()
    }, [debounceProductType, page, limit, shippingZone, supplier, splitType, status])

    useEffect(() => {
        getSuppliers()
    }, [])

    const Suppliers = allSupplier.map(i => ({
        name: i.name,
        value: i._id,
        label: i.name
    })).sort((a, b) => a.label.toLowerCase().localeCompare(b.label.toLowerCase()))

    const ShippingZones = [
        { name: 'US', value: 'US', label: 'US' },
        { name: 'EU', value: 'EU', label: 'EU' },
        { name: 'ROW', value: 'ROW', label: 'ROW' },
    ].sort((a, b) => a.name.localeCompare(b.name))

    const SplitTypes = [
        { name: 'quantity', value: 'quantity', label: 'Quantity' },
        { name: 'weight', value: 'weight', label: 'Weight' },
    ].sort((a, b) => a.name.localeCompare(b.name))

    const Status = [
        { name: 'active', value: 'active', label: 'Active' },
        { name: 'inactive', value: 'inactive', label: 'Inactive' },
    ].sort((a, b) => a.name.localeCompare(b.name))

    return (
        <div className='PackageSplittingRulesPage'>
            <div className="container-fluid">
                <div className="MenuTitle pt-3 d-flex justify-content-between align-items-center">
                    Package Splitting Rules
                    <div className="Actions d-flex align-items-center justify-content-end">
                        <Button className='btn btn-outline-primary btn-sm px-4 rounded ml-3' disabled={loading} onClick={exportListRule}>Export</Button>
                        {(canCreate) &&
                            <Link className="ant-btn ant-btn-primary px-4 rounded ml-3" to='/a/package-splitting-rules/create'>Create Rule</Link>
                        }
                    </div>
                </div>
                <div className="SectionInner mt-3">
                    <div className="p-3">
                        <div className="filter">
                            <div className="d-flex align-items-center justify-content-between">
                                <div className="d-flex GroupFilter">
                                    <div className="itemFilter ProductType mb-3 mr-3">
                                        <Input
                                            placeholder='Search for Product Type'
                                            value={productType || ''}
                                            allowClear
                                            onChange={e => onChangeInput('product_type', e.target.value)}
                                            suffix={<SearchOutlined />}
                                            style={{ minWidth: "400px", color: '#c6c6c6' }}
                                            disabled={loading}
                                        />
                                    </div>
                                    <div className="itemFilter mb-3 mr-3">
                                        <Select
                                            placeholder='Shipping Zone'
                                            value={shippingZone || []}
                                            style={{ width: "200px" }}
                                            showArrow
                                            showSearch
                                            options={ShippingZones}
                                            onChange={(value) => handleChangeSelect('ShippingZone', value)}
                                            allowClear
                                            disabled={loading}
                                            filterOption={(inputValue, option) =>
                                                !!option && option.label.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
                                            }
                                        />
                                    </div>
                                    <div className="itemFilter mb-3 mr-3">
                                        <Select
                                            placeholder='Supplier'
                                            value={supplier || []}
                                            style={{ width: "200px" }}
                                            showArrow
                                            showSearch
                                            options={Suppliers}
                                            onChange={(value) => handleChangeSelect('Supplier', value)}
                                            allowClear
                                            disabled={loading}
                                            filterOption={(inputValue, option) =>
                                                !!option && option.label.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
                                            }
                                        />
                                    </div>
                                    <div className="itemFilter mb-3 mr-3">
                                        <Select
                                            placeholder='Split Type'
                                            value={splitType || []}
                                            style={{ width: "200px" }}
                                            showArrow
                                            showSearch
                                            options={SplitTypes}
                                            onChange={(value) => handleChangeSelect('SplitType', value)}
                                            allowClear
                                            disabled={loading}
                                            filterOption={(inputValue, option) =>
                                                !!option && option.label.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
                                            }
                                        />
                                    </div>
                                    <div className="itemFilter mb-3 mr-3">
                                        <Select
                                            placeholder='Status'
                                            value={status || []}
                                            style={{ width: "200px" }}
                                            showArrow
                                            showSearch
                                            options={Status}
                                            onChange={(value) => handleChangeSelect('Status', value)}
                                            allowClear
                                            disabled={loading}
                                            filterOption={(inputValue, option) =>
                                                !!option && option.label.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
                                            }
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        {error && <label className='text-danger mb-0'>{error}</label>}
                        <Spin spinning={loading} tip='Loading...'>
                            <PackageSplittingRulesTable
                                listRule={listRule}
                                refresh={fetchListRule}
                                permissionsData={permissionsData}
                                setLoading={setLoading}
                            />
                        </Spin>
                        <Pagination
                            current={page}
                            total={total}
                            pageSize={limit}
                            showTotal={(sum, range) => sum > 0 &&
                                <div>
                                    {`Hiển thị ${range[0]} đến ${range[1]} của `}<strong>{`${total}`}</strong> rules
                                </div>}
                            onChange={handPagePagination}
                            className='text-right' showSizeChanger
                            pageSizeOptions={['10', '20', '50', '100']}
                            onShowSizeChange={handleShowSizeChange}
                        />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default PackageSplittingRulesPage