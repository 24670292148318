import React, {Component} from 'react'
import WebhookDate from './WebhookTableData/WebhookDate'
import WebhookStatus from './WebhookTableData/WebhookStatus'
import WebhookType from './WebhookTableData/WebhookType'
import WebhookTotalSent from './WebhookTableData/WebhookTotalSent'
import WebhookSendTime from './WebhookTableData/WebhookSendTime'
import WebhookActions from './WebhookTableData/WebhookActions'
import WebhookMessage from './WebhookTableData/WebhookMessage'
import OrderNumber from './WebhookTableData/OrderNumber'
import PropTypes from 'prop-types'
import WebhookSelection from './WebhookTableData/WebhookSelection'

class WebhooksTableRow extends Component {
    render() {
        const {webhook, onFetchWebhook} = this.props
        const {send_time} = webhook

        return (
            <tr className="WebhooksTableRow">
                <WebhookSelection {...this.props}/>
                <OrderNumber webhook={webhook}/>
                <WebhookDate webhook={webhook}/>
                <WebhookStatus webhook={webhook}/>
                <WebhookType webhook={webhook}/>
                <WebhookTotalSent webhook={webhook}/>
                <WebhookSendTime sendTime={send_time}/>
                <WebhookMessage webhook={webhook}/>
                <WebhookActions webhook={webhook} onFetchWebhook={onFetchWebhook} {...this.props}/>
            </tr>
        )
    }
}

WebhooksTableRow.propTypes = {
    webhook: PropTypes.object.isRequired,
    onFetchWebhook: PropTypes.func.isRequired,
}

export default WebhooksTableRow
