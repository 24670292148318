import {Component} from 'react'
import PropTypes from 'prop-types'
import moment from "moment"

class ColumnDate extends Component {
    render() {
        const {dateTime} = this.props
        if (!dateTime) {
            return null
        }

        return moment(dateTime).format('lll')
    }
}

ColumnDate.propTypes = {
    dateTime: PropTypes.string,
}

export default ColumnDate
