import React from 'react'
import DocTitleByStore from "../../dashboard/shared/DocTitleByStore"
import MappingVariantsPage from './MappingVariantsPage'

const MappingVariantsPageContainer = () => {
    return (
        <div className="MappingVariantsPageContainer">
            <DocTitleByStore title="Mapping Catalog - Product Line" />
            <MappingVariantsPage/>
        </div>
    )
}

export default MappingVariantsPageContainer