import React, {Component} from 'react'
import SettingAutoPage from "./SettingAutoPage"

class SettingAutoContainer extends Component {
    render() {
        return (
            <div className='SettingAutoContainer'>
                <SettingAutoPage/>
            </div>
        )
    }
}

export default SettingAutoContainer
