import React from 'react'
import FastProductionRulesHeader from './FastProductionRulesTable/FastProductionRulesHeader'
import FastProductionRulesRow from './FastProductionRulesTable/FastProductionRulesRow'

function FastProductionRulesTable(props) {
    const { listRule = [], refresh, permissionsData } = { ...props }

    return (
        <div className="FastProductionRulesTable">
            <table className='table'>
                <FastProductionRulesHeader 
                    permissionsData={permissionsData}
                />
                <tbody className='FastProductionRulesRow'>
                    {!!listRule && listRule.length > 0 ? listRule.map((item, i) => {
                        return <FastProductionRulesRow
                            key={item._id}
                            index={i}
                            item={item}
                            refresh={refresh}
                            permissionsData={permissionsData}
                        />
                    }) : <tr>
                        <td colSpan={8}>No data</td>
                    </tr>
                    }
                </tbody>
            </table>
        </div>
    )
}

export default FastProductionRulesTable