import React from 'react'
import DocTitleByStore from "../../dashboard/shared/DocTitleByStore"
import ListPrintFilePage from './ListPrintFilePage'

const ListPrintFileContainer = () => {
    return (
        <div className="ListPrintFileContainer">
            <DocTitleByStore title="Print files" />
            <ListPrintFilePage />
        </div>
    )
}

export default ListPrintFileContainer