import React, {useEffect, useState} from 'react'
import {Spin} from "antd"
import FilterDate from "../../../shared/FilterDate"
import {getShipmentStatusV2} from "../../../../services/api/ShippingServices"
import {Link} from "react-router-dom"
import {
    getFilterTrackingStatus,
    getPermalink,
    dataColumns
} from './Helper'
import ReactHTMLTableToExcel from "react-html-table-to-excel"
import {DeleteFilled, CloseOutlined} from '@ant-design/icons'
import changeQuerySearch from '../../../../helpers/routing/changeQuerySearch'

function ReportShipStatusV2(props) {
    const {Config, filters: queryFilters} = props
    const localFilters = {
        ...Config.get('filters', {
            packaged_at: queryFilters && queryFilters.packaged_at || {},
        }),
        ...queryFilters
    }
    let {
        from,
        to,
    } = {...localFilters.packaged_at}
    const today = new Date()
    const priorDate = new Date(new Date().setDate(new Date().getDate() - 30))
    const date = today.getDate() + '/' + (today.getMonth() + 1) + '/' + today.getFullYear()
    const prevDate = priorDate.getDate() + '/' + (priorDate.getMonth() + 1) + '/' + priorDate.getFullYear()
    const packageDate = {'packaged_at': from && to ? {from, to} : {from: prevDate, to: date}}
    const [rowTotal, setRowTotal] = useState({})
    const [statics, setStaticsShipStatus] = useState({})
    const [error, setError] = useState('')
    const [loading, setLoading] = useState(false)
    const [filters, setFilters] = useState(packageDate)

    const _handleChangeDate = (paramFilter) => {
        const _filters = {
            ...filters,
            ...paramFilter,
        }
        changeQuerySearch(Object.keys(paramFilter.packaged_at).reduce((a, b) => {
            return {
                ...a,
                [`packaged_at.${b}`]: paramFilter.packaged_at[b]
            }
        }, {}), true)
        setFilters(_filters)
    }

    async function _fetchReportShipStatus() {
        try {
            setLoading(true)
            setError('')
            const {store, ...restFilters} = {...filters}
            const response = await getShipmentStatusV2({
                ...restFilters,
                ...(props.store && {
                    store: props.store
                })
            })

            const {success, data, message} = response

            if (!success) {
                setLoading(false)

                return setError(message)
            }

            setLoading(false)
            setRowTotal(data.summary)

            const dataSuppliers = Object.keys(data.suppliers).reduce((m, supName) => {
                return {
                    ...m,
                    ...(supName !== 'undefined' && {
                        [supName]: data.suppliers[supName],
                    }),
                }
            }, {})

            setStaticsShipStatus(dataSuppliers)

        } catch (e) {
            setLoading(false)
            setError(e.message)
        }
    }

    const clearStore = () => props.setStore('')
    const getPermalinkWrap = (args) => {
        return getPermalink({
            ...args,
            ...(props.store && {store: props.store})
        }, filters)
    }

    useEffect(() => {
        _fetchReportShipStatus()
    }, [filters.packaged_at, props.store])

    const conSpan = Object.values(statics).some(items => {
        return items.some(item => item.items && item.items.length)
    }) ? 2 : 1

    const tabsStaticsHeihght = !!document.getElementById("tabsStatics") && document.getElementById("tabsStatics").offsetHeight
    const FilterHeight = !!document.getElementById("Filter") && document.getElementById("Filter").offsetHeight
    const ErrorHeight = !!document.getElementById("Error") && document.getElementById("Error").offsetHeight
    const setTab = () => props.onChangeTab('byStore')

    return (
        <div className="ReportShipStatus">
            <div className="d-flex justify-content-between pt-4 Filter" id='Filter'
                 style={{top: `${tabsStaticsHeihght}px`}}>
                <h3>Shipment Status Report</h3>
                <div className="FilterAndAction d-flex justify-content-center align-items-center">
                    <div className="Filter d-flex justify-content-center align-items-center">
                        <FilterDate
                            heading="Completed at:"
                            field="packaged_at"
                            value={filters.packaged_at || {}}
                            onDatesChange={_handleChangeDate}
                            className='mt-0 mr-3'
                        />
                    </div>
                    <div className="Action d-flex justify-content-center align-items-center">
                        <ReactHTMLTableToExcel
                            id="Export-table-xls-button"
                            className="Export-table-xls-button btn btn-primary mr-2"
                            table="ShipmentStatusReport"
                            filename="Shipment_Status_Report.csv"
                            sheet="Shipment_Status_Report"
                            buttonText="Export"
                        />
                        <Link to={'/a/analytics/daily-report/by-store'}>
                            <button className="SwitchButton btn">By
                                Store
                            </button>
                        </Link>

                        {/*<button className="SwitchButton btn" onClick={setTab}>By Store</button>*/}
                    </div>
                </div>
            </div>
            {
                props.store && <p align="right">
                    <button onClick={clearStore} className="btn btn-outline-secondary btn-sm BtnClearStore">
                        <Link
                            to={'/a/analytics/daily-report/by-carrier'}>{props.store}
                            <DeleteFilled/>
                            <CloseOutlined/>
                        </Link></button>
                </p>
            }
            <div
                className="text-danger Error pb-4" id='Error'
                style={{top: `${tabsStaticsHeihght + FilterHeight}px`}}
            >
                {error}
            </div>
            <Spin spinning={loading} tip="Getting statics...">
                <table className="table table-borderless ShipmentStatusTable" id='ShipmentStatusReport'>
                    <thead style={{top: `${tabsStaticsHeihght + FilterHeight + ErrorHeight}px`}}>
                    <tr>
                        <th colSpan={conSpan} style={{background: 'rgb(249 250 251)'}}/>
                        <th className="text-right" style={{background: 'rgb(249 250 251)'}}>Unknown</th>
                        <th className="text-right" style={{background: 'rgb(249 250 251)'}}>Exception</th>
                        <th className="text-right">Out_for_delivery</th>
                        <th className="text-right">Pre_transit</th>
                        <th className="text-right">In_transit</th>
                        <th className="text-right">Delivered</th>
                        <th className="text-right">Total</th>
                        <th className="text-right">Ship delay</th>
                        <th className="text-right">> 13BDs</th>
                        <th className="text-right">> 16BDs</th>
                        <th className="text-right">> 21BDs</th>
                        <th className="text-right">Avg time</th>
                    </tr>
                    </thead>
                    <tbody>
                    {
                        !Object.keys(statics).length && <tr>
                            <td/>
                            <td colSpan={Object.keys(dataColumns).length + 1} className="text-center">No data</td>
                        </tr>
                    }
                    {!!Object.keys(statics).length && rowTotal.hasOwnProperty('pre_transit_count') && (
                        <tr className="">
                            <td colSpan={conSpan}/>
                            {
                                Object.keys(dataColumns).map(colName => {
                                    const colValue = rowTotal.total_count ? Math.max(Math.round(rowTotal[colName] / rowTotal.total_count * 100, 1), 0) : 0

                                    return !['total_count'].includes(colName)
                                        ? <td className="text-right font-weight-bold"
                                              key={`col-total-${colName}`}>
                                            {rowTotal[colName]}
                                            {'overtime_ship_count' !== colName && <span>({colValue}%)</span>}
                                        </td>
                                        : <td key={`col-total-${colName}`}
                                              className="text-right font-weight-bold">{rowTotal[colName]}</td>
                                })
                            }

                            <td className="text-right font-weight-bold">{Math.round(rowTotal.avg_time * 100, 1) / 100}</td>
                        </tr>
                    )}
                    {
                        Object.entries(statics).map((t, i) => {
                            const sub = t[0]

                            return t[1].length > 0 && t[1].map((item, index) => {
                                const {items: rowItems} = item

                                if (index === 0) {
                                    return (
                                        <tr key={`subRow-${index}`} className="Total rowSub">
                                            <td colSpan={conSpan} style={{background: '#dedede'}}>{sub}</td>

                                            {
                                                Object.keys(dataColumns).map((colName, colIndex) => {
                                                    const key = `col-total-${colName}_${index}`
                                                    return !['total_count'].includes(colName)
                                                        ? <td key={key}
                                                              className="text-right font-weight-bold"
                                                              style={{background: '#dedede'}}
                                                        >
                                                            <Link target={'_blank'}
                                                                  to={getPermalinkWrap({
                                                                      suppliers: `${item.supplier_id}`,
                                                                      ...getFilterTrackingStatus(colName),
                                                                  })}>
                                                                {item[colName]}
                                                                {
                                                                    'overtime_ship_count' !== colName &&
                                                                    <span>({item.total_count ? Math.round(item[colName] / item.total_count * 100, 1) : '0'}%)</span>
                                                                }
                                                            </Link>
                                                        </td>
                                                        : <td key={key} className="text-right font-weight-bold"
                                                              style={{background: '#dedede'}}>
                                                            {typeof item[colName] === 'undefined' ? colName : item[colName]}
                                                        </td>
                                                })
                                            }
                                            <td className="text-right font-weight-bold" style={{background: '#dedede'}}>
                                                {Math.round(item.avg_time * 100) / 100}
                                            </td>
                                        </tr>
                                    )
                                } else {
                                    return rowItems && rowItems.length
                                        ? (
                                            rowItems.map((rowItem, rowItemIndex) => {
                                                return <tr key={[index, rowItemIndex].join('-')}>
                                                    {
                                                        !rowItemIndex &&
                                                        <td rowSpan={rowItems.length}>{item.shipping_carrier}</td>
                                                    }
                                                    <td className={rowItems.length > 1 ? 'ax' : ''}>{rowItem[item.zone_type]}</td>
                                                    {
                                                        Object.keys(dataColumns).map(colName => {
                                                            const key = `col-${index}-${item.shipping_carrier}-${colName}`

                                                            return !['total_count'].includes(colName)
                                                                ? <td key={key}
                                                                      className="text-right">
                                                                    {
                                                                        item.shipping_carrier ? (
                                                                            <Link target={'_blank'}
                                                                                  to={getPermalinkWrap({
                                                                                      suppliers: `${item.supplier_id}`,
                                                                                      shipping_carrier: item.shipping_carrier,
                                                                                      ...(
                                                                                          item.zone_type && rowItem[item.zone_type] && {
                                                                                              [item.zone_type]: rowItem[item.zone_type],
                                                                                          }
                                                                                      ),
                                                                                      ...getFilterTrackingStatus(colName),
                                                                                  })}>
                                                                                {rowItem[colName]}
                                                                                {
                                                                                    'overtime_ship_count' !== colName &&
                                                                                    <span>({rowItem.total_count ? Math.round(rowItem[colName] / rowItem.total_count * 100, 1) : '0'}%)</span>
                                                                                }
                                                                            </Link>
                                                                        ) : (
                                                                            <span>{rowItem[colName]}</span>
                                                                        )
                                                                    }
                                                                </td>
                                                                : <td key={key}
                                                                      className="text-right">{rowItem[colName]}</td>

                                                        })
                                                    }
                                                    <td className="text-right">
                                                        {Math.round(rowItem.avg_time * 100) / 100}
                                                    </td>
                                                </tr>
                                            })
                                        )
                                        : (
                                            <tr key={index}>
                                                <td colSpan={conSpan}>{item.shipping_carrier}</td>
                                                {
                                                    Object.keys(dataColumns).map(colName => {
                                                        const key = `col-${index}-${item.shipping_carrier}-${colName}`
                                                        return !['total_count'].includes(colName)
                                                            ? <td key={key}
                                                                  className="text-right">
                                                                {
                                                                    item.shipping_carrier ? (
                                                                        <Link target={'_blank'}
                                                                              to={getPermalinkWrap({
                                                                                  suppliers: `${item.supplier_id}`,
                                                                                  shipping_carrier: item.shipping_carrier,
                                                                                  ...getFilterTrackingStatus(colName),
                                                                              })}>
                                                                            {item[colName]}{'overtime_ship_count' !== colName &&
                                                                        <span>({item.total_count ? Math.round(item[colName] / item.total_count * 100, 1) : '0'}%)</span>}
                                                                        </Link>
                                                                    ) : (
                                                                        <span>{item[colName]}</span>
                                                                    )
                                                                }
                                                            </td>
                                                            : <td key={key} className="text-right">{item[colName]}</td>

                                                    })
                                                }
                                                <td className="text-right">{Math.round(item.avg_time * 100) / 100}</td>
                                            </tr>
                                        )
                                }
                            })
                        })
                    }
                    </tbody>
                </table>
            </Spin>
        </div>
    )
}

export default ReportShipStatusV2
