import React, {Component} from 'react'
import ListingUsersTableHead from "../components/ListingUsersTable/ListingUsersTableHead"
import ListingUsersTableRow from "../components/ListingUsersTable/ListingUsersTableRow"
import PropTypes from 'prop-types'

class ListingUsersTable extends Component {
    render() {
        const {users, refresh, openModalEdit, permissionsData, departments} = this.props

        return (
            <div className="ProductsTable">
                <table className='table'>
                    <ListingUsersTableHead permissionsData={permissionsData}/>
                    <tbody>
                    {
                        users.map((user, i) => {
                            return <ListingUsersTableRow 
                                key={user._id} 
                                index={i} user={user} 
                                refresh={refresh} 
                                openModalEdit={openModalEdit} 
                                permissionsData={permissionsData}
                                departments={departments}
                            />
                        })
                    }
                    </tbody>
                </table>
            </div>
        )
    }
}

ListingUsersTable.propTypes = {
    users: PropTypes.array.isRequired,
}

export default ListingUsersTable
