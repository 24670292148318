import React, {Component} from 'react'
import PropTypes from 'prop-types'
import {Link} from 'react-router-dom'
import {
    getPlatformDetails,
    editPlatform,
    activatePlatform,
    deactivatePlatform
} from '../../../services/api/PlatformsServices'
import GeneralDetails from './GeneralDetails'
import PlatformApiKeys from './PlatformApiKeys'
import PlatformStatus from './status-bar/PlatformStatus'
import { getLocalData } from '../../../services/StorageServices'

class PlatformPage extends Component {
    state = {
        platform: {
            _id: this.props.platformId
        },
        editedPlatform: {
            _id: this.props.platformId
        },
        loading: {
            fetchPlatform: false,
            editPlatform: false,
        },
        err: {
            fetchPlatform: null,
            editPlatform: null,
        }
    }

    componentDidMount() {
        return this._fetchPlatformDetails()
    }

    _onClickSave = () => {
        return this._savePlatform()
    }

    _changePlatform = (key, value) => {
        return this.setState(({editedPlatform}) => ({
            editedPlatform: {
                ...editedPlatform,
                [key]: value,
            }
        }))
    }

    _changeLoading = (loadingState, errState) => {
        const {loading, err} = this.state
        return {
            loading: {
                ...loading,
                ...loadingState
            },
            err: (errState) ? err : {
                ...err,
                ...errState
            }
        }
    }

    _changePlatformStatus = async (status) => {
        const {platform} = this.state

        try {
            const request = (status === 'active') ? activatePlatform(platform._id) : deactivatePlatform(platform._id)
            const {success, data} = await request
            if (success && data) this.setState(({_platform, editedPlatform}) => ({
                platform: {..._platform, status},
                editedPlatform: {...editedPlatform, status},
            }))
        } catch (e) {
            alert(e)
        }
    }

    _savePlatform = async () => {
        this.setState({...this._changeLoading({editPlatform: true}, {editPlatform: true})})

        try {
            const {editedPlatform} = this.state
            const {success, data, message} = await editPlatform(editedPlatform._id, editedPlatform)

            if (success) return this.setState({
                ...this._changeLoading({editPlatform: false}),
                platform: {...data},
                editedPlatform: {...data},
            })

            this.setState({
                ...this._changeLoading({editPlatform: false}, {editPlatform: message})
            })
        } catch (e) {
            this.setState({...this._changeLoading({editPlatform: false}, {editPlatform: e.message || e})})
        }
    }

    _fetchPlatformDetails = async () => {
        this.setState({...this._changeLoading({fetchPlatform: true}, {fetchPlatform: null})})

        try {
            const {platform} = this.state

            const {success, data, message} = await getPlatformDetails(platform._id)
            if (success) return this.setState({
                ...this._changeLoading({fetchPlatform: false}),
                platform: {
                    ...platform,
                    ...data,
                },
                editedPlatform: {
                    ...platform,
                    ...data,
                }
            })

            return this.setState({
                ...this._changeLoading({fetchPlatform: false}, {fetchPlatform: message})
            })
        } catch (e) {
            this.setState({...this._changeLoading({fetchPlatform: false}, {fetchPlatform: e.message || e})})
        }
    }

    render() {
        const {platform, loading, editedPlatform} = this.state
        const permissionsData = !!getLocalData('permissions') ? Object.keys(getLocalData('permissions')) : []
        const disableAll = !permissionsData.includes('ffm_platform_update')

        return (
            <div className="PlatformPage">
                <h3>
                    <Link to={`/a/platforms`}><i className="fas fa-chevron-left mr-3"/></Link>{platform.name}
                </h3>
                <div className="StatusBar">
                    <PlatformStatus
                        platform={platform}
                        onChangeStatus={this._changePlatformStatus}
                        permissionsData={permissionsData}
                    />
                    {!disableAll &&
                        <Link to={`/a/platforms/${platform._id}/settings`}>
                            <button className="btn Button">
                                Setting
                            </button>
                        </Link>
                    }
                </div>

                <div className="DetailsWrapper">
                    <div className="P-5">
                        <div className="SectionInner">
                            <GeneralDetails
                                platform={editedPlatform}
                                onChangePlatform={this._changePlatform}
                                loading={loading.fetchPlatform}
                                disableAll={disableAll}
                            />
                        </div>
                    </div>
                    <div className="P-5">
                        <div className="SectionInner">
                            <PlatformApiKeys
                                platform={platform}
                                disableAll={disableAll}
                            />
                        </div>
                    </div>
                </div>
                {!disableAll &&
                    <div className="SaveButtonGroup">
                        <button
                            className="btn Button"
                            disabled={loading.editPlatform}
                            onClick={this._onClickSave}
                        >
                            Save
                        </button>
                    </div>
                }
            </div>
        )
    }
}

PlatformPage.propTypes = {
    platformId: PropTypes.string.isRequired,
}

export default PlatformPage
