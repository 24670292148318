import React, { useState, useEffect } from 'react'
import { Button, Input, Select, Spin, Switch } from "antd"
import { editUserAuth, getUser, getRoleList, getDepartmentList, getUserAth } from '../../../../services/api/UserService'
import PropTypes from 'prop-types'
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap'
import { userChangePassword } from "../../../../services/api/UserService"

const { Option } = Select

function UserEditModal(props) {
    const { isOpen, toggle, refresh } = props
    const { _id: userId, reloadUsers } = props
    const [error, setError] = useState('')
    const [loading, setLoading] = useState(false)
    const [validateError, setValidateError] = useState({ passwordError: '', confirmError: '' })
    const [state, setState] = useState(null)
    const [roles, setRoles] = useState([])
    const [department, setDepartment] = useState([])
    const [listRoles, setListRoles] = useState([])
    const [listDepartment, setListDepartment] = useState([])
    const [getData, setGetData] = useState(false)

    const getUserDetail = async () => {
        setGetData(true)
        setError('')

        try {
            const { success, data, message } = await getUserAth(userId)

            if (!success) {
                setGetData(false)

                return setError(message)
            }

            setGetData(false)

            const { roles, department } = data

            setState({
                ...state,
                username: data.username,
                is_active: data.is_active
            })

            const listRoleId = !!roles && roles.length > 0 && roles.map(item => item._id)

            setRoles(listRoleId)
            setDepartment(department)
        } catch (e) {
            setGetData(false)
            setError(e.message)
        }
    }

    const getUserRoles = async () => {
        try {
            const { success, data, message } = await getRoleList()

            if (!success) return setError(message)

            const { roles } = data

            setListRoles(roles)
        } catch (e) {
            setError(e.message)
        }
    }

    const _handleChangeInput = (e) => {
        const { name, value } = e.target
        setValidateError({ passwordError: '', confirmError: '' })

        setState({
            ...state,
            [name]: value.trim()
        })
    }

    const _handleToggleActive = (value) => {
        setValidateError({ passwordError: '', confirmError: '' })

        setState({
            ...state,
            is_active: value
        })
    }

    const _userValidator = () => {
        if (!!state.password && !!state.re_password && (state.password.trim() !== state.re_password.trim())) {
            setValidateError({ ...validateError, confirmError: 'Password does not match!' })
            return false
        }
        if (!!state.password && !!state.re_password && state.password.trim().length <= 7) {
            setValidateError({ ...validateError, passwordError: 'Password must be at least 8 characters!' })
            return false
        }

        return true
    }

    const _createUser = (e) => {
        e.preventDefault()

        const isValid = _userValidator()

        if (!isValid) return

        _reqEditNew()
    }

    const _reqEditNew = async () => {
        setLoading(true)
        setError('')

        try {
            let payload = {}
            if (state.password !== '') {
                payload = {
                    password: state.password,
                    is_active: state.is_active,
                    roles: roles || [],
                }
            } else {
                payload = {
                    is_active: state.is_active,
                    roles: roles || []
                }
            }
            // if(department) {payload.department = department}

            const { success, message } = await editUserAuth(userId, payload)

            if (!success) {
                setLoading(false)
                return setError(message)
            }

            setLoading(false)
            toggle()
            refresh()
        } catch (e) {
            setLoading(false)
            if (e.response.status !== 200) {
                setError(e.response.data.message)
            } else {
                setError(e.message)
            }
        }
    }

    const _closeModal = () => {
        setError('')
        setState({ username: '', password: '', re_password: '', is_active: false })
        setValidateError({ passwordError: '', confirmError: '' })
        toggle()
    }

    const _handleChangeRole = (value) => {
        setRoles(value)
    }

    const _handleChangeDepartment = (value) => {
        setDepartment(value)
    }

    const getUserDepartment = async () => {
        try {
            const {success, data, message} = await getDepartmentList()

            if (!success) return setError(message)

            const {departments} = data

            setListDepartment(departments)
        } catch (e) {
            setError(e.message)
        }
    }

    useEffect(() => {
        getUserRoles()
        getUserDepartment()
        getUserDetail()
        // eslint-disable-next-line
    }, [])

    return (
        <div>
            <Modal isOpen={isOpen} toggle={_closeModal} className={props.className}>
                <ModalHeader toggle={toggle}>Update User</ModalHeader>
                <ModalBody>
                    <Spin className="text-center w-100" spinning={getData} tip="Đang lấy thông tin...">
                        {state !== null ? (
                            <div>
                                <div className="mb-3">
                                    <label>Username</label>
                                    <Input disabled name="username" value={state.username || ''} />
                                </div>
                                <div className="mb-3">
                                    <label>Password</label>
                                    <Input type="password" name="password" value={state.password || ''}
                                        onChange={_handleChangeInput} />
                                    {validateError.passwordError &&
                                        <div className="mt-1 text-danger">{validateError.passwordError}</div>}
                                </div>
                                <div className="mb-3">
                                    <label>Confirm password</label>
                                    <Input type="password" name="re_password" value={state.re_password || ''}
                                        onChange={_handleChangeInput} />
                                    {validateError.confirmError &&
                                        <div className="mt-1 text-danger">{validateError.confirmError}</div>}
                                </div>
                                <div className="mb-3">
                                    <label className="mr-3">Status</label>
                                    <Switch checkedChildren="Active" unCheckedChildren="Deactivate" className=""
                                        defaultChecked={state.is_active} onClick={_handleToggleActive} />
                                </div>
                                <div className="mb-3">
                                    <label className="mr-3">Roles</label>
                                    <Select
                                        mode="tags"
                                        placeholder="Please select"
                                        value={roles || []}
                                        onChange={_handleChangeRole}
                                        style={{ width: '100%' }}
                                    >
                                        {
                                            !!listRoles && listRoles.length > 0 && listRoles.map(item => (
                                                <Option key={item._id} value={item._id}>{item.name}</Option>
                                            ))
                                        }
                                    </Select>
                                </div>
                                <div className="form-group">
                                    <label className="mr-3">Department</label>
                                    <Select
                                        // mode="multiple"
                                        placeholder="Please select"
                                        value={department || []}
                                        onChange={_handleChangeDepartment}
                                        disabled
                                        style={{ width: '100%' }}
                                    >
                                        {
                                            !!listDepartment && listDepartment.length > 0 && listDepartment.map(item => (
                                                <Option key={item._id} value={item._id}>{item.name}</Option>
                                            ))
                                        }
                                    </Select>
                                </div>
                                {error && <div className="mt-3 text-danger">{error}</div>}
                            </div>
                        ) :
                            <div>{error && <div className="text-danger">{error}</div>}</div>
                        }
                    </Spin>
                </ModalBody>
                <ModalFooter>
                    <button className='btn btn-secondary mr-2' onClick={toggle}>Cancel</button>
                    <button className='btn btn-primary' onClick={_createUser} disabled={loading || error}>
                        Save
                    </button>
                </ModalFooter>
            </Modal>
        </div>
    )
}

export default UserEditModal