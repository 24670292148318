import React from 'react'
import NotificationContext from './../context/NotificationContext'
import PropTypes from 'prop-types'

function NotificationProvider(props) {
    const {loadNotications, setState, filters} = props

    return (
        <NotificationContext.Provider
            value={{
                reLoadNotications: loadNotications,
                filters,
                setState: setState
            }}
        >
            {props.children}
        </NotificationContext.Provider>
    )
}

NotificationProvider.propTypes = {
    loadNotications: PropTypes.func.isRequired
}

export default NotificationProvider
