import React, {Component} from 'react'
import {Button} from 'antd'
import {ReloadOutlined} from '@ant-design/icons'
import PropTypes from 'prop-types'

class OrdersResetFilters extends Component {
    _handleClick = e => {
        e.preventDefault()

        this.props.onClick()
    }

    render() {
        return (
            <div className='OrdersResetFilters'>
                <Button onClick={this._handleClick} icon={<ReloadOutlined />}>Reset filters</Button>
            </div>
        )
    }
}

OrdersResetFilters.propTypes = {
    onClick: PropTypes.func.isRequired,
}

export default OrdersResetFilters
