import React, {Fragment, useState} from 'react'
import {Spin} from 'antd'
import TableHead from './data/TableHead'
import TableRow from './data/TableRow'
import emptyData from '../../../statics/assets/images/empty-img-gray.png'
import ScanDetailPagination from "./ScanDetailPagination"

const ScanDetailTable = (props) => {
    const {loading = false, scanDetail = [], statusCarrier} = props
    const [selectedItems, _setSelected] = useState([])
    const [loadingSend, _setLoadingSend] = useState(false)
    const [isSelectedAll, _toggleSelectedAll] = useState(false)

    const _setSendLoading = (value) => {
        _setLoadingSend(value)
    }

    const _toggleSelectAll = (isChecked) => {
        if (isChecked) {
            const listAllId = []

            scanDetail.map(noti => {
                if (noti.switch_status !== true) {
                    listAllId.push(noti._id)
                    return noti._id
                } else {
                    return null
                }
            })

            _setSelected(listAllId)
            _toggleSelectedAll(true)
        } else {
            _setSelected([])
            _toggleSelectedAll(false)
        }
    }

    const _updateSelectedOrders = (id, value) => {
        if (value && !selectedItems.includes(id)) {
            _setSelected([...selectedItems, id])
            _toggleSelectedAll(selectedItems.length + 1 === props.scanDetail.filter(itemF => itemF.upload_status === 'done').length)
        } else {
            const filtered = selectedItems.filter(notiId => notiId !== id)
            _setSelected(filtered)
            _toggleSelectedAll(false)
        }
    }

    return (
        <div className="ScanDetailTable">
            <Spin spinning={loading} tip='Loading...'>
                {scanDetail.length > 0 ? (
                    <div>
                        <table className='table'>
                            <TableHead
                                statusCarrier={statusCarrier}
                                carrier={scanDetail[0].carrier}
                                loadingSend={loadingSend}
                                handleSendLoading={_setSendLoading}
                                isSelectedAll={isSelectedAll}
                                selectedItems={selectedItems}
                                scanDetail={scanDetail}
                                toggleSelectAll={_toggleSelectAll}
                            />
                            <tbody>
                            {
                                scanDetail.map(scanDetailItem => {
                                    return (
                                        <Fragment key={scanDetailItem.fulfillment._id}>
                                            <TableRow
                                                loadingSend={loadingSend}
                                                handleSendLoading={_setLoadingSend}
                                                selectedItems={selectedItems}
                                                scanDetail={scanDetailItem}
                                                updateSelectedOrders={_updateSelectedOrders}
                                                statusCarrier={statusCarrier}
                                                currentPage={1}
                                            />
                                        </Fragment>
                                    )
                                })
                            }
                            </tbody>
                        </table>
                        <ScanDetailPagination {...props}/>
                    </div>
                ) : !loading && (
                    <div className="noData d-flex flex-column align-items-center justify-content-center">
                        <img src={emptyData} alt="No data"/>
                        Không có bản ghi!
                    </div>
                )}
            </Spin>
        </div>
    )
}

export default ScanDetailTable
