import React, { Component } from 'react'
import { getListSuppliersV2 } from "../../../services/api/SuppliersServices"
import SuppliersPagination from "./SuppliersPagination"
import SuppliersTable from "./SuppliersTable"
import SuppliersModalNew from "./SuppliersModalNew"
import SuppliersModalEdit from "./SuppliersModalEdit"
import SuppliersFilters from "./SuppliersFilters"
import { getLocalData } from '../../../services/StorageServices'
import { Link } from 'react-router-dom'
import { Button } from 'antd'

class SuppliersPageV2 extends Component {
    state = {
        suppliers: [],
        page: 1,
        limit: 20,
        total: 0,
        pages: 1,
        filters: {
            name: this.props.name ? this.props.name : '',
            sku_prefix: '',
            active: null
        },
        error: '',
        loading: false,
        isOpenModalNew: false,
        supplierModalEdit: null
    }

    componentDidMount() {
        this._getSuppliers()
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.state.page !== prevState.page || this.state.limit !== prevState.limit) {
            this._getSuppliers()
        }
    }

    _getSuppliers = async () => {
        const { page, limit, filters } = this.state
        const {
            name, sku_prefix, active, need_gen_mockup,
            available_tiktok, shipbytiktok,
            'need_charge_have_sleeves[$gte]': gte,
            'need_charge_have_sleeves[$lte]': lte,
        } = filters
        this.setState({ loading: true })

        try {
            const params = { page, limit, name, sku_prefix, active }

            if (need_gen_mockup) params.need_gen_mockup = need_gen_mockup
            if (available_tiktok) params.available_tiktok = available_tiktok
            if (available_tiktok === 'true' && shipbytiktok) params.shipbytiktok = shipbytiktok
            if (gte) params['need_charge_have_sleeves[$gte]'] = gte
            if (lte) params['need_charge_have_sleeves[$lte]'] = lte


            const { success, data, message } = await getListSuppliersV2(params)

            if (!success) throw new Error(message)

            this.setState({
                suppliers: data.suppliers,
                pages: data.pages,
                total: data.total,
                error: null,
                loading: false
            })
        } catch (e) {
            this.setState({
                error: e.message,
                loading: false
            })
        }
    }

    _renderSuppliersContent = () => {
        const { suppliers, error, loading, ...restState } = this.state

        if (loading) return (
            <p>Loading...</p>
        )

        if (error) return (
            <p className='text-danger'>{error}</p>
        )

        if (suppliers.length === 0) return (
            <p className='text-danger'>No result</p>
        )

        return (
            <SuppliersTable {...restState} refresh={this._getSuppliers} openModalEdit={this._openModalEdit}
                suppliers={suppliers} />
        )
    }

    _handleChangeState = (field, data) => {
        this.setState({ [field]: data })
    }

    _toggleModalNew = () => {
        this.setState({ isOpenModalNew: !this.state.isOpenModalNew })
    }

    _openModalEdit = (supplier) => {
        this.setState({ supplierModalEdit: supplier })
    }

    _closeModalEdit = () => {
        this.setState({ supplierModalEdit: null })
    }

    _renderModalNew = () => {
        const { isOpenModalNew } = this.state
        if (isOpenModalNew)
            return (
                <SuppliersModalNew isOpen={isOpenModalNew} toggle={this._toggleModalNew} refresh={this._getSuppliers} />
            )
    }

    _renderModalEdit = () => {
        const { supplierModalEdit } = this.state
        if (supplierModalEdit)
            return (
                <SuppliersModalEdit isOpen={!!supplierModalEdit} supplier={supplierModalEdit}
                    toggle={this._closeModalEdit} refresh={this._getSuppliers} />
            )
    }

    _handleChangeFilter = (paramFilter) => {
        const url = window.location.href.split('?')[0]
        window.history.pushState({}, '', url)

        this.setState(({ filters }) => {
            return {
                filters: {
                    ...filters,
                    ...paramFilter,
                },
                page: 1,
            }
        }, () => {
            if (this._timeOut) clearTimeout(this._timeOut)
            this._timeOut = setTimeout(async () => {
                await this._getSuppliers()
            }, 300)
        })
    }

    render() {
        const { page, pages, total, filters } = this.state
        const permissionsData = !!getLocalData('permissions') ? Object.keys(getLocalData('permissions')) : []

        return (
            <div className='SuppliersPage'>
                <div className="d-sm-flex align-items-center justify-content-between mb-2 pt-3">
                    <h1 className="PageTitle mb-0">Suppliers</h1>
                    <div className="d-flex">
                        <Link className='btn btn-outline-primary mr-3' to='/a/special-case'>Mapping Special Case</Link>
                        <Link className='btn btn-outline-primary mr-3' to='/a/setting-holiday'>Setting Holiday</Link>
                        {permissionsData.includes('ffm_supplier_create') &&
                            <button className='btn btn-primary mr-lg-0 mr-3 ml-sm-0 ml-3' onClick={this._toggleModalNew}>New Supplier</button>
                        }
                    </div>
                </div>
                <div className="SectionInner mx-lg-0 mx-3">
                    <div className="d-lg-flex align-items-center justify-content-between">
                        <div className='mb-3'>
                            <SuppliersFilters filters={filters} onChangeFilter={this._handleChangeFilter} />
                        </div>
                    </div>
                    <div className="mb-3 float-right">Total <strong>{total}</strong> {total === 1 ? 'item' : 'items'}</div>
                    {this._renderSuppliersContent()}
                    <SuppliersPagination page={page} pages={pages} onChangeState={this._handleChangeState} />

                    {this._renderModalNew()}
                    {this._renderModalEdit()}
                </div>
            </div>
        )
    }
}

export default SuppliersPageV2
