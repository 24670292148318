import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Button, Modal, notification, Select } from "antd"
import { cancelOrderDTUS } from "../../../../services/api/ExportOrderServices"
import broker from "../../OrderBroker"
import queryString from 'query-string'

class CancelOrderDTUS extends Component {
    state = {
        loading: false,
        error: '',
        reason: ''
    }

    _onCloseModal = () => {
        const { onClose } = this.props

        if (typeof onClose === 'function') {
            this.setState({ reason: '' })
            onClose()
        }
    }

    _onCancelOrder = async () => {
        this.setState({
            loading: true,
            error: '',
        })

        try {
            const { fulfillment } = this.props
            const { reason } = this.state
            const payload = { reason: reason }
            const query = queryString.stringify(payload)
            const response = await cancelOrderDTUS(fulfillment._id, query)

            const { success, message } = response

            if (!success) {
                return this.setState({
                    loading: false,
                    error: message
                })
            }

            this.setState({
                loading: false
            }, () => {
                const { onClose } = this.props

                if (typeof onClose === 'function') {
                    onClose()
                }

                broker.publish("TRACKING_UPDATED")
                broker.publish('ORDER_REFRESHED_ORDER')
                broker.publish('ORDER_REFRESH_BRANDING')
                broker.publish('ORDER_RESTORE')

                notification.success({
                    message: 'Cancel order success',
                    description: 'Cancel order DTUS success.',
                })
            })
        } catch (e) {
            this.setState({
                loading: false,
                error: e.message
            })
        }
    }

    _handleChangeReson = (value) => {
        this.setState({ reason: value })
    }

    render() {
        const { cancelOrderDTUS, fulfillment } = this.props
        const { Option } = Select
        const { loading, error, reason } = this.state
        const listReasonCode = [
            'Copyright', 'TestOrder', 'StandardsViolation', 'InvalidShippingAddress',
            'InvalidShippingMethod', 'CorruptedImageFile', 'UnacceptableImageQuality',
            'UnacceptableCropping', 'NonCompliantImageFile', 'LayoutMismatch', 'GreetingCardTextError',
            'DuplicateOrder', 'CustomerCancellation', 'FraudulentOrder', 'RetrievalFailed', 'Over30Days',
            'InvalidProduct', 'ProductonBackorder', 'ProductoutOfOstock', 'CustomerCancellationPrinted', 'CancelandBillCustomer'
        ]

        return (
            <Modal
                className='cancelOrderDTUS'
                visible={cancelOrderDTUS}
                title="Cancel order DTUS"
                onOk={this._onCancelOrder}
                onCancel={this._onCloseModal}
                footer={[
                    <div key="content" className='d-flex align-items-center justify-content-between'>
                        <Button key="back" onClick={this._onCloseModal}>
                            Cancel
                        </Button>
                        <Button key="submit" type="danger" disabled={!reason} loading={loading} onClick={this._onCancelOrder}>
                            Submit
                        </Button>
                    </div>
                ]}
            >
                <p>Do you want to cancel <strong>{fulfillment.name}</strong>?</p>
                <Select
                    showSearch
                    className='W-100'
                    showArrow
                    allowClear
                    placeholder="Select cancel reason code"
                    mode='default'
                    value={reason || []}
                    onChange={this._handleChangeReson}
                    style={{ width: '100%' }}
                >
                    {!!listReasonCode.length && listReasonCode.map((i, index) => (
                        <Option key={index} value={i}>{i}</Option>
                    ))
                    }
                </Select>
                {
                    !!error && error.length > 0 && <div className="text-danger">{error}</div>
                }
            </Modal>
        )
    }
}

CancelOrderDTUS.propTypes = {
    cancelOrderDTUS: PropTypes.bool,
    fulfillment: PropTypes.object
}

export default CancelOrderDTUS
