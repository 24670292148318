import React from 'react'
import moment from "moment"


const MappingConditionTableRow = (props) => {
    const {item, handleEdit, rowIndex, listSelecteds, handleSelected, permissionsData} = props
    const {_id, store, user, mapping_context, updated_at, created_at} = Object.assign({}, item)
    const formatTime = (time) => {
        if (!time) {
            return '-'
        }
        return moment(time).format('HH:mm DD/MM/YYYY')
    }

    const _detectChecked = () => {
        return listSelecteds.includes(item._id)
    }

    const _handleChange = () => {
        const isChecked = _detectChecked()

        if (typeof handleSelected === 'function') {
            handleSelected(item._id, !isChecked)
        }
    }

    return (
        <tr>
            {permissionsData.includes('ffm_mapping_condition_update') &&
                <td className="RowIndex">
                    <input type="checkbox" checked={_detectChecked()} onChange={_handleChange}/>
                </td>
            }
            <td className="Store">{!!store ? store : ''}</td>
            <td className="User">{!!user ? user : ''}</td>
            <td className="MappingContext">
                <div
                    dangerouslySetInnerHTML={{__html: !!mapping_context ? mapping_context.replace(/\n/g, '<br />') : ''}}/>
            </td>
            <td className="UpdatedAt">{formatTime(!!updated_at ? updated_at : !!created_at ? created_at : '')}</td>
            {permissionsData.includes('ffm_mapping_condition_update') && 
                <td className="Action">
                    <a onClick={handleEdit(item)} className="text-primary px-1 cursor-pointer">Edit</a>
                </td>
            }
        </tr>
    )
}

export default MappingConditionTableRow