import {Button, Modal, Select} from "antd"
import React, {useState} from "react"
import {addShippingCarriers} from '../../../services/api/ShippingServices'

export default (props) => {
    const [isLoading, setIsLoading] = useState(false)
    const [selectedCarrier, setSelectedCarrier] = useState(false)
    const {onOk, onCancel, availableShippingCarriers, shippingCarriers, supplier} = props

    const _handleOk = () => {
        setIsLoading(true)

        try {
            addShippingCarriers(supplier, selectedCarrier).then((res) => {
                const {data} = {...res}
                const {
                    shippingCostCarriers,
                    availableShippingCarriers
                } = {...data}

                onOk({
                    shippingCostCarriers,
                    availableShippingCarriers,
                    selectedCarrier
                })
            })
        } catch (e) {
            setIsLoading(false)
        }
        // setTimeout(() => {
        //     setIsLoading(false)
        //     onOk && onOk(selectedCarrier)
        // }, 3000)
    }

    const _handleCancel = () => {
        onCancel && onCancel()
    }

    const _handleSelectedCarrier = (value) => {
        const carrier = availableShippingCarriers.find(sc => sc.carrier === value)

        setSelectedCarrier(carrier)
    }


    return <Modal className="AddNewCarrierModal"
                  title="Add new carrier"
                  visible={true}
                  onOk={_handleOk}
                  onCancel={_handleCancel}
                  footer={[
                      <Button key="back" onClick={_handleCancel}>
                          Cancel
                      </Button>,
                      <Button key="submit" type="primary" disabled={!selectedCarrier} loading={isLoading}
                              onClick={_handleOk}>
                          Add
                      </Button>
                  ]}>
        <label className="Label">Select new carrier</label>
        <Select style={{width: '100%'}}
                onChange={_handleSelectedCarrier}>
            {
                !!availableShippingCarriers && availableShippingCarriers.length > 0
                && availableShippingCarriers.map((item, index) => {
                    const isExisted = shippingCarriers && shippingCarriers.find(sc => sc.carrier === item.carrier)
                    return <Select.Option key={index} value={item.carrier}
                                          disabled={isExisted}>{item.name}</Select.Option>
                })
            }
        </Select>
    </Modal>
}