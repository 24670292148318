import React, {useRef}from 'react'

const MappingStateTag = (props) => {
	const {showAllState, allowStates, showallonclick} = props

	const stateTags = useRef()
  return (
	<>
	<div className={ showAllState === false ? "HiddenCountry" : "" }>
	<div ref={ stateTags }>
	{
		!!allowStates && allowStates.length > 0 ? allowStates.map((state,index) => (
	  	<label key={index} className="badge badge-primary mr-2 mb-2"> {state} </label>
		)) : ""
	}
	</div>
  	</div>
	{
		!!stateTags.current ? (
			( stateTags.current.offsetHeight > 90 && showAllState === false ) ?
			<a className="float-right mr-3 text-primary">
				<small className="float-right mr-3" onClick={showallonclick}>More...</small>
			</a> :
			( stateTags.current.offsetHeight > 90 && showAllState === true ) ?
			<a className="float-right mr-3 text-primary">
				<small className="float-right mr-3" onClick={showallonclick}>Less...</small> 
			</a> : ""
		) : "ssss"
	}
	</>
  )
}

export default MappingStateTag