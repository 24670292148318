import createApiServices from './createApiServices'
import {getAccessToken} from '../AuthServices'

const prefixURL = process.env.NODE_ENV === 'production' ? '/api/export-order' : 'https://fulfillment-staging.merchize.com/api/export-order'
const api = createApiServices({baseUrl: prefixURL})
const devURL = window['API_EXPORT_ORDER_SERVICE_URL'] || ''
export const setupExport = ({link, name, supplier = ''}) => {
    return api.makeAuthRequest({
        url: `/exports/setup?supplier=${supplier}`,
        data: {link, name},
        method: 'POST',
    })
}

export const exportOrdersTemplate = (url_api, data = {}) => {
    return api.makeAuthRequest({
        url: url_api,
        method: 'POST',
        data
    })
}

export const exportOrdersToSheet = (payload, supplier) => {
    return api.makeAuthRequest({
        url: `/exports/push/listOrders?supplier=${supplier}`,
        method: 'POST',
        data: payload
    })
}

export const getExportSetup = () => {
    return api.makeAuthRequest({
        url: '/exports/setup',
        method: 'GET',
    })
}

export const pushExportOrder = (orderId, {name, link, supplier = ''}) => {
    return api.makeAuthRequest({
        url: `/exports/${orderId}/push?supplier=${supplier}`,
        method: 'POST',
        data: {name, link}
    })
}

export const getItemsToPush1C = (orderId, fulfillmentId) => {
    return api.makeAuthRequest({
        url: `/exports/${orderId}/${fulfillmentId}/push-factory`,
        method: 'GET',
    })
}

export const pushOrderPackages = (orderId, payload) => {
    return api.makeAuthRequest({
        url: `/orders/${orderId}/push-fulfillments`,
        method: 'POST',
        data: payload,
    })
}

export const pushOrderPackage = (fulfillmentId, supplierId, payload) => {
    return api.makeAuthRequest({
        url: `/push-fulfillments/${fulfillmentId}/${supplierId}`,
        method: 'POST',
        data: payload,
    })
}

export const getItemsPreviews = (fulfillmentId) => {
    return api.makeAuthRequest({
        url: `/preview-fulfillments/${fulfillmentId}`,
        method: 'GET',
    })
}

export const pushItemsTo1C = (orderId, {items, force = false}) => {
    return api.makeAuthRequest({
        url: `/exports/${orderId}/push-factory`,
        method: 'POST',
        data: {
            items,
            force
        }
    })
}

export const pushItemToSuplier = (fulfillmentId, paload) => {
    return api.makeAuthRequest({
        url: `/push-fulfillments/${fulfillmentId}`,
        method: 'POST',
        data: paload
    })
}

export const createFactoryOrder = (orderIds) => {
    return api.makeAuthRequest({
        url: '/bulk/create-factory-order',
        method: 'POST',
        data: {
            orders: orderIds,
        },
    })
}

export const exportsOrderCheckingDetails = (query) => {
    const token = getAccessToken()
    const baseURL = window['API_EXPORT_ORDER_SERVICE_URL'] || api.getBaseURL()

    return `${baseURL}/csv/exports/orders/tracking?token=${token}&${query}`
}

export const exportOrdersBilling = (query) => {
    const token = getAccessToken()
    const baseURL = window['API_EXPORT_ORDER_SERVICE_URL'] || api.getBaseURL()

    return `${baseURL}/csv/exports/orders/billing/v2?token=${token}&${query}`
}

export const exportProductionStatus = (query) => {
    const token = getAccessToken()
    const baseURL = window['API_EXPORT_ORDER_SERVICE_URL'] || api.getBaseURL()

    return `${baseURL}/csv/exports/production/status?token=${token}&${query}`
}

export const exportOrdersSellerRequest = (query) => {
    const token = getAccessToken()
    const baseURL = window['API_EXPORT_ORDER_SERVICE_URL'] || api.getBaseURL()

    return `${baseURL}/csv/exports/orders/seller/request?token=${token}&${query}`
}

export const exportOrdersCailianFactory = (query) => {
    const token = getAccessToken()
    const baseURL = window['API_EXPORT_ORDER_SERVICE_URL'] || api.getBaseURL()

    return `${baseURL}/csv/exports/orders/cailian-factory?token=${token}&${query}`
}


export const exportCailianGoogle = (query) => {
    return api.makeAuthRequest({
        url: `/csv/orders/cailian-factory-gg-sheet?${query}`,
        method: 'GET'
    })
}

export const exportTeezilyGoogle = (query) => {
    return api.makeAuthRequest({
        url: `/orders/teezily/export-gg-sheet?${query}`,
        method: 'GET'
    })
}

export const exportZimoGoogle = (query) => {
    return api.makeAuthRequest({
        url: `/csv/orders/zimo-factory-gg-sheet?${query}`,
        method: 'GET'
    })
}

export const exportBaseGiffGoogle = (query) => {
    return api.makeAuthRequest({
        url: `/gg-sheet/orders/bg-fac?${query}`,
        method: 'GET'
    })
}

export const exportOrderDPCNGoogle = (query) => {
    return api.makeAuthRequest({
        url: `/csv/orders/dpcn-factory-gg-sheet?${query}`,
        method: 'GET'
    })
}

export const exportOrderDBUSGoogle = (query) => {
    return api.makeAuthRequest({
        url: `/csv/orders/dbus-factory-gg-sheet?${query}`,
        method: 'GET'
    })
}

export const exportOrderDTUSGoogle = (query) => {
    return api.makeAuthRequest({
        url: `/csv/orders/dtus-factory-gg-sheet?${query}`,
        method: 'GET'
    })
}

export const exportOrderSPUSGoogle = (query) => {
    return api.makeAuthRequest({
        url: `/csv/orders/spus-factory-gg-sheet?${query}`,
        method: 'GET'
    })
}

export const exportOrderWLUSGoogle = (query) => {
    return api.makeAuthRequest({
        url: `/csv/orders/wlus-factory-gg-sheet?${query}`,
        method: 'GET'
    })
}

export const exportOrderFSUSGoogle = (query) => {
    return api.makeAuthRequest({
        url: `/csv/orders/fsus-factory-gg-sheet?${query}`,
        method: 'GET'
    })
}

export const exportOrderUCUSGoogle = (query) => {
    return api.makeAuthRequest({
        url: `/csv/orders/ucus-factory-gg-sheet?${query}`,
        method: 'GET'
    })
}

export const exportOrderEFUSGoogle = (query) => {
    return api.makeAuthRequest({
        url: `/csv/orders/efus-factory-gg-sheet?${query}`,
        method: 'GET'
    })
}

export const exportOrderDFUSGoogle = (query) => {
    return api.makeAuthRequest({
        url: `/csv/orders/dfus-factory-gg-sheet?${query}`,
        method: 'GET'
    })
}

export const exportOrderGLUSGoogle = (query) => {
    return api.makeAuthRequest({
        url: `/csv/orders/glus-factory-gg-sheet?${query}`,
        method: 'GET'
    })
}
export const exportOrderBFUSGoogle = (query) => {
    return api.makeAuthRequest({
        url: `/csv/orders/bfus-factory-gg-sheet?${query}`,
        method: 'GET'
    })
}

export const exportOrderMCUSGoogle = (query) => {
    return api.makeAuthRequest({
        url: `/csv/orders/mcus-factory-gg-sheet?${query}`,
        method: 'GET'
    })
}

export const exportOrderDFWUSGoogle = (query) => {
    return api.makeAuthRequest({
        url: `/csv/orders/dfwus-factory-gg-sheet?${query}`,
        method: 'GET'
    })
}

export const getProcess = (query) => {
    return api.makeAuthRequest({
        url: '/dashboard/tracking-progress',
        method: 'GET'
    })
}

export const getTrackingOver = (query) => {
    return api.makeAuthRequest({
        url: '/dashboard/tracking-overtime',
        method: 'GET'
    })
}

export const getStaticsPackage = () => {
    return api.makeAuthRequest({
        url: '/dashboard/package-status-statistic',
        method: 'GET'
    })
}

export const getShipmentStatus = (query) => {
    return api.makeAuthRequest({
        url: `gg-sheet/fulfillment/shipment-status?${query}`,
        method: 'GET'
    })
}

export const exportPackageManagement = (data) => {
    return api.makeAuthRequest({
        url: `/exports/google-sheet/package-management`,
        method: 'POST',
        data: data
    })
}

export const exportPackageManagementV2 = (data) => {
    return api.makeAuthRequest({
        url: `/exports/google-sheet/package-management-v2`,
        method: 'POST',
        data: data
    })
}

export const exportPackageManagementV3 = (data) => {
    return api.makeAuthRequest({
        url: `/exports/google-sheet/package-management-v3`,
        method: 'POST',
        data: data
    })
}

export const exportPackageManagementV4 = (data) => {
    return api.makeAuthRequest({
        url: `${devURL}/exports/google-sheet/package-items`,
        method: 'POST',
        data: data
    })
}

export const exportPackageManagementNew = (data) => {
    return api.makeAuthRequest({
        url: `exports/google-sheet/packages`,
        method: 'POST',
        data: data
    })
}

export const cancelOrderPF = (fulfillmentId) => {
    return api.makeAuthRequest({
        url: `/printforia/cancel-order/${fulfillmentId}`,
        method: 'PATCH'
    })
}

export const cancelOrderPLEU = (fulfillmentId) => {
    return api.makeAuthRequest({
        url: `/pleu/${fulfillmentId}/cancel`,
        method: 'PATCH'
    })
}

export const cancelOrderCW = (fulfillmentId) => {
    return api.makeAuthRequest({
        url: `/cw/${fulfillmentId}/cancel`,
        method: 'PATCH'
    })
}

export const cancelOrderMD = (fulfillmentId) => {
    return api.makeAuthRequest({
        url: `/mdus/${fulfillmentId}/cancel`,
        method: 'PATCH'
    })
}
export const cancelOrderSPUS = (fulfillmentId) => {
    return api.makeAuthRequest({
        url: `/spus/${fulfillmentId}/cancel`,
        method: 'PATCH'
    })
}

export const cancelOrderPBAU = (fulfillmentId) => {
    return api.makeAuthRequest({
        url: `/pbau/${fulfillmentId}/cancel`,
        method: 'PATCH'
    })
}

export const cancelOrderDPCN = (fulfillmentId) => {
    return api.makeAuthRequest({
        url: `/dpcn/${fulfillmentId}/cancel`,
        method: 'PATCH'
    })
}

export const cancelOrderDTUS = (fulfillmentId, query) => {
    return api.makeAuthRequest({
        url: `/dtus/${fulfillmentId}/cancel?${query}`,
        method: 'PATCH'
    })
}

export const cancelOrderHFT71 = (fulfillmentId) => {
    return api.makeAuthRequest({
        url: `/hft71/${fulfillmentId}/cancel`,
        method: 'PATCH'
    })
}

export const cancelOrderMOUS = (fulfillmentId) => {
    return api.makeAuthRequest({
        url: `/mous/${fulfillmentId}/cancel`,
        method: 'PATCH'
    })
}

export const cancelOrderGTUS = (fulfillmentId) => {
    return api.makeAuthRequest({
        url: `/gtus/${fulfillmentId}/cancel`,
        method: 'PATCH'
    })
}

export const cancelOrderMPUS = (fulfillmentId) => {
  return api.makeAuthRequest({
      url: `/mpus/${fulfillmentId}/cancel`,
      method: 'PATCH'
  })
  
}

export const cancelOrderFSUS = (fulfillmentId) => {
    return api.makeAuthRequest({
        url: `/fsus/${fulfillmentId}/cancel`,
        method: 'PATCH'
    })
}

export const cancelOrderPYUS = (fulfillmentId) => {
    return api.makeAuthRequest({
        url: `/pyus/${fulfillmentId}/cancel`,
        method: 'PATCH'
    })
}

export const cancelOrderTEEU = (fulfillmentId) => {
    return api.makeAuthRequest({
        url: `/teeu/${fulfillmentId}/cancel`,
        method: 'PATCH'
    })
}

export const cancelOrderDODUS = (fulfillmentId) => {
    return api.makeAuthRequest({
        url: `/dodus/${fulfillmentId}/cancel`,
        method: 'PATCH'
    })
}

export const cancelOrderSupplier = (supplier, fulfillmentId) => {
    return api.makeAuthRequest({
        url: `/${supplier}/${fulfillmentId}/cancel`,
        method: 'PATCH'
    })
}

export const exportRequestUpdate = (payload) => {
    return api.makeAuthRequest({
        url: `/request-update/ggsheet`,
        method: 'POST',
        data: payload
    })
}

export const exportPLEU = (query) => {
    return api.makeAuthRequest({
        url: `/csv/orders/pleu-factory-gg-sheet?${query}`,
        method: 'GET'
    })
}

export const exportCW = (query) => {
    return api.makeAuthRequest({
        url: `/csv/orders/cw-factory-gg-sheet?${query}`,
        method: 'GET'
    })
}

export const exportListVariants = (data) => {
    return api.makeAuthRequest({
        url: 'exports/variants/printforia',
        method: 'POST',
        data: data
    })
}

export const exportVariants = (data) => {
    return api.makeAuthRequest({
        url: '/variants/ggsheet',
        method: 'POST',
        data: data
    })
}

export const exportOrdersLocations = (payload) => {
    return api.makeAuthRequest({
        url: `/gg-sheet/orders/information`,
        method: 'POST',
        data: payload
    })
}

export const checkProductionStatus = (fulfillmentId) => {
    return api.makeAuthRequest({
        url: `${fulfillmentId}/check-production-status`,
        method: 'GET'
    })
}

export const exportOrderChargeRefund = (data) => {
    return api.makeAuthRequest({
        url: `/gg-sheet/charge-refund`,
        method: 'POST',
        data
    })
}

export const getFBAOrdersAlerts = (data) => {
    return api.makeAuthRequest({
        url: `/orders/fba-alerts`,
        method: 'POST',
        data
    })
}

export const getCheckDetail = (fulfillmentId) => {
    return api.makeAuthRequest({
        url: '/fulfillment-supplier-info',
        method: 'POST',
        data: {
            fulfillmentId
        },
    })
}

export const getCheckDetailHistory = (orderHistoryId) => {
    return api.makeAuthRequest({
        url: '/fulfillment-supplier-info',
        method: 'POST',
        data: {
            orderHistoryId
        },
    })
}

export const exportPrintFileItems = (data) => {
    return api.makeAuthRequest({
        url: `/exports/google-sheet/printing-files`,
        method: 'POST',
        data: data
    })
}

export const getManiFestWarning = () => {
    return api.makeAuthRequest({
        url: `/orders/manifest-warnings`,
        method: 'GET',
    })
}

export const confirmPushSupplier = (payload) => {
    return api.makeAuthRequest({
        url: `/push-fulfillments-confirm-push-sup`,
        method: 'POST',
        data: payload,
    })
}