import React, {Fragment} from 'react'
import PropTypes from 'prop-types'
import noImage from "../../../../statics/assets/images/empty-img-gray.png"
import Variants from "./Variants"

ProductLineRow.propTypes = {
    item: PropTypes.object,
    active: PropTypes.string,
    listSelectedVariant: PropTypes.array.isRequired,
    onChangeActive: PropTypes.func.isRequired,
    onUpdateSelectedProduct: PropTypes.func.isRequired,
    onSetVariants: PropTypes.func.isRequired,
    onUpdateSelectedVariants: PropTypes.func.isRequired
}

function ProductLineRow(props) {
    const {item, active, listSelectedVariant, onChangeActive, onSetVariants, onUpdateSelectedVariants} = props

    function _handleChange() {
        const {item, onUpdateSelectedProduct} = props
        const isChecked = _detectChecked()

        if (typeof onUpdateSelectedProduct === 'function') {
            onUpdateSelectedProduct(item._id, item.title, !isChecked)
        }
    }

    function _detectChecked() {
        const {item, listSelected} = props
        const checked = !!listSelected && listSelected.length > 0 && listSelected.filter(product => product.id === item._id)

        return checked.length > 0
    }

    return (
        <Fragment>
            <tr>
                <td className="SelectProductLine">
                    <input type="checkbox" checked={_detectChecked()} onChange={_handleChange}/>
                </td>
                <td className="Title">
                    <img
                        src={!!item.image && !!item.image.url ? item.image.url : noImage}
                        alt=""
                    />
                    {item.title}
                </td>
                <td className="ProductLine">
                    {item.type}
                </td>
                <td className="ProductSku">
                    {item.sku_prefix}
                </td>
                <td className="Actions">
                    <span onClick={() => onChangeActive(item._id)}>
                        Variants
                        <i className={`${active === item._id ? 'ti-angle-down' : 'ti-angle-up'}`}/>
                    </span>
                </td>
            </tr>
            <tr className={`${active === item._id ? 'ShowVariant' : 'NotShow'}`}>
                <td colSpan={5}>
                    {
                        (active === item._id) &&
                        <Variants
                            productLineId={item._id}
                            productLineTitle={item.title}
                            listSelectedVariant={listSelectedVariant}
                            onSetVariants={onSetVariants}
                            onUpdateSelectedVariants={onUpdateSelectedVariants}
                        />
                    }
                </td>
            </tr>
        </Fragment>
    )
}

export default ProductLineRow