import React, {useEffect, useState} from 'react'
import PropTypes from 'prop-types'
import {message} from "antd"
import {Modal, ModalHeader, ModalBody} from "reactstrap"
import {createCategory, deleteCategory, getListCategories} from "../../../../services/api/ProductsServices"
import UseDebounce from "../../../fulfillment-discounts/helpers/UseDebounce"
import CategoryItem from "./CategoryItem"
import CategoriesSearch from "./CategoriesSearch"
import CategoriesPagination from "./CategoriesPagination"
import CreateCategory from "./CreateCategory"

CategoriesModal.propTypes = {
    productId: PropTypes.string,
    onUpdateState: PropTypes.func.isRequired,
    onToggleModal: PropTypes.func.isRequired
}

function CategoriesModal(props) {
    const {productId, onUpdateState, onToggleModal} = props
    const [listCategory, setListCategory] = useState([])
    const [error, setError] = useState('')
    const [search, setSearch] = useState('')
    const [page, setPage] = useState(1)
    const [pages, setPages] = useState(1)

    const _handleChangeSearch = (value) => {
        setPage(1)
        setSearch(value)
    }

    const _handleChangePage = (value) => {
        setPage(value)
    }

    const _onDeleteCategory = async (categoryId) => {
        try {
            const response = await deleteCategory(categoryId)

            const {success, message} = response

            if (!success) {
                return setError(message)
            }

            _fetchListCategories()
        } catch (e) {
            setError(e.message)
        }
    }

    const _onCreateCategory = async (title) => {
        try {
            const response = await createCategory({title})

            const {success, data} = response

            if (!success) {
                return false
            }

            const newListCategory = [...listCategory, data]

            setListCategory(newListCategory)

            return true
        } catch (e) {
            return false
        }
    }

    const _fetchListCategories = async () => {
        try {
            const response = await getListCategories({
                page,
                limit: 10,
                term: search
            })

            const {success, message: error, data} = response

            if (!success) {
                return setError(error)
            }

            const {categories, pages} = data

            setListCategory(categories)
            setPages(pages)
        } catch (e) {
            message.error('Can\'t not get list categories.')
        }
    }

    const debouncedSearch = UseDebounce(search, 500)

    useEffect(() => {
        _fetchListCategories()
    }, [page, debouncedSearch])

    return (
        <div className='CategoriesModal'>
            <Modal isOpen={true} toggle={onToggleModal} className="CategoriesModalDialog">
                <ModalHeader>Select Category</ModalHeader>

                <ModalBody>
                    {
                        error && <div className="text-danger">{error}</div>
                    }

                    <CategoriesSearch search={search} handleChangeState={_handleChangeSearch}/>

                    {
                        !!listCategory && listCategory.length > 0 ?
                            <ul className="ListCategories mt-3">
                                {
                                    listCategory.map(item => {
                                        return <CategoryItem
                                            key={item._id}
                                            titleCategory={item.title}
                                            categoryId={item._id}
                                            productId={productId}
                                            onUpdateState={onUpdateState}
                                            onToggleModal={onToggleModal}
                                            onDeleteCategory={_onDeleteCategory}
                                        />
                                    })
                                }
                            </ul> :
                            <div className="font-italic mt-2">No results</div>
                    }

                    <CategoriesPagination page={page} pages={pages} onChangePage={_handleChangePage}/>
                    <CreateCategory onCreateCategory={_onCreateCategory}/>
                </ModalBody>
            </Modal>
        </div>
    )
}

export default CategoriesModal
