import Axios from 'axios'
import { getAccessToken, getRefreshToken, setAccessToken, setRefreshToken } from '../AuthServices'
import { parseJwt } from '../../helpers/parseToken'
import { getLocalData } from '../StorageServices'
import { logoutUser } from '../AuthServices'

const _store = {
    errorSubscribers: [],
}

const _makeRequest = createRequest => async args => {
    const _headers = args.headers ? args.headers : {}

    const defaultHeaders = {}

    args = {
        ...args,
        headers: {
            ...defaultHeaders,
            ..._headers,
        },
    }

    try {
        const {data} = await createRequest(args)

        return data
    } catch (e) {
        throw e
    }
}

const _makeBasicRequest = createRequest => async (args) => {
    const requestHeaders = args.headers ? args.headers : {}
    const basicAuth = process.env.REACT_APP_BASIC_AUTH_PLF || 'c2VydmljZTpzcjJBbUJaZXUyOGpZOGJ6SjJ4NnE0OHJVVkczZ0VuYw=='

    let headers = {
        'Authorization': `Basic ${basicAuth}`,
        'cache-control': 'no-cache',
    }

    args = {
        ...args,
        headers: {
            ...headers,
            ...requestHeaders,
        },
    }

    try {
        return await _makeRequest(createRequest)(args)
    } catch (error) {
        const {response} = error

        if (!response || typeof response !== 'object') {
            throw error
        }

        const {status, data} = response
        if (status) {
            _store.errorSubscribers.forEach(subscriber => {
                subscriber({status, data})
            })
        }

        throw error
    }
}

const _makeAuthRequest = createRequest => async (args) => {
    const requestHeaders = args.headers ? args.headers : {}
    const accessToken = getAccessToken()
    const refreshToken = getRefreshToken()

    let headers = {
        'Authorization': `Bearer ${accessToken}`,
        'cache-control': 'no-cache',
    }

    if (refreshToken) {
        headers['X-Refresh-Token'] = refreshToken
    }

    args = {
        ...args,
        headers: {
            ...headers,
            ...requestHeaders,
        },
    }

    try {
        return await _makeRequest(createRequest)(args)
    } catch (error) {
        const {response} = error

        if (!response || typeof response !== 'object') {
            throw error
        }

        const {status, data} = response
        if (status) {
            _store.errorSubscribers.forEach(subscriber => {
                subscriber({status, data})
            })
        }

        throw error
    }
}

const _subscribeErrorRequest = instance => (subscriber = null) => {
    if (!subscriber || typeof subscriber !== 'function') return false
    if (_store.errorSubscribers.indexOf(subscriber) !== -1) return false

    _store.errorSubscribers = [].concat(_store.errorSubscribers, [subscriber])
}

/**
 * @param options
 * @return {{
 * getBaseURL: function,
 * makeAuthRequest: function,
 * makeRequest: function,
 * changeBaseURL: function
 * }}
 */
export default (options = {}) => {
    const baseUrlValidated = options.baseUrl || '/api'
    const headers = Object.assign({}, options.headers)

    const instance = Axios.create({
        baseURL: baseUrlValidated,
        timeout: 120000,
        headers,
    })
    
    const _changeBaseURL = (baseURL) => {
        instance.defaults.baseURL = baseURL
    }

    const _getBaseURL = () => {
        return instance.defaults.baseURL
    }

    const _setDefault = (property, value = null) => {
        instance.defaults[property] = value
    }

    return {
        setDefault: _setDefault,
        getBaseURL: _getBaseURL,
        changeBaseURL: _changeBaseURL,
        makeRequest: _makeRequest(instance),
        makeAuthRequest: _makeAuthRequest(instance),
        makeBasicAuthRequest: _makeBasicRequest(instance),
        subscribeErrorRequest: _subscribeErrorRequest(instance),
    }
}
