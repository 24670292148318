import React, {Component} from 'react'
import PropTypes from 'prop-types'
import {getLocalData} from '../../../../../services/StorageServices'
import {pushExportOrder} from '../../../../../services/api/ExportOrderServices'

class PushOrderExport extends Component {
    state = {
        loading: false,
    }

    _onClickPushOrder = () => {
        const {supplier} = this.props
        const name = getLocalData(`exportGgDrv.name.${supplier}`, '')
        const link = getLocalData(`exportGgDrv.link.${supplier}`, '')

        if (!name || !link) {
            if (window.confirm('You need to setup Google Sheet first. Click ok to setup the link')) {
                window.open(`/a/orders?google_drv_sheet=true&supplier=${supplier}`)
            }
            return
        }

        return this._pushOrder({name, link, supplier})
    }

    _pushOrder = async ({name, link, supplier = ''}) => {
        const {orderId, onUpdateTotalPushToProduction} = this.props
        const {loading} = this.state

        if (loading) return

        try {
            this.setState({loading: true})
            const {data, success, message} = await pushExportOrder(orderId, {name, link, supplier})

            this.setState({loading: false})
            if (!success) return alert(message)

            const {count_push_to_production} = data
            onUpdateTotalPushToProduction(count_push_to_production)
        } catch (e) {
            this.setState({loading: true})
            alert(e.message)
        }
    }

    render() {
        const {order, supplier, paymentStatus, textSupplier} = this.props
        const {count_push_to_production = 0} = order
        const disabled = paymentStatus === 'partially_refunded' || paymentStatus === 'refunded'

        return (
            <div className={`PushOrderExport ${disabled && 'disabled'}`} onClick={this._onClickPushOrder}>
                <span><strong>[{textSupplier || supplier}]</strong> Push order to Google Sheets ({count_push_to_production})</span>
            </div>
        )
    }
}

PushOrderExport.propTypes = {
    supplier: PropTypes.string.isRequired,
    orderId: PropTypes.string.isRequired,
    onUpdateTotalPushToProduction: PropTypes.func.isRequired,
    paymentStatus: PropTypes.string
}

export default PushOrderExport
