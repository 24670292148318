import React from 'react'
import PropTypes from 'prop-types'

Name.propTypes = {
    name: PropTypes.string,
    validate: PropTypes.string,
    onChangeName: PropTypes.func.isRequired
}

function Name(props) {
    const {name, validate, onChangeName, permissionsData} = props

    return (
        <div className="FormItem">
            <label htmlFor="name" className="LabelDiscount">Discount name</label>
            <input
                id="name" className={`NameDiscount ${!!validate && validate.length > 0 ? 'Error' : ''}`} value={name}
                type="text"
                placeholder="e.g Giảm giá tháng 9"
                onChange={onChangeName}
                disabled={!permissionsData.includes('ffm_fulfillment_discounts_update')}
            />
            {
                !!validate && validate.length > 0 &&
                <span className='SmallText text-danger'>{validate}</span>
            }
            <span className="SmallText">Use only for management</span>
        </div>
    )
}

export default Name