import React, {Component} from 'react'
import PropTypes from 'prop-types'
import {Table} from "reactstrap"
import HeadAddress from "./HeadAddress"
import InputTemplate from "./InputTemplate"

class StandardAddress extends Component {
    render() {
        const {previews, onChangePreview} = this.props
        const {
            Marketplace,
            orderNumber,
            name,
            address1,
            address2,
            city,
            state,
            country,
            phone,
            postalCode,
            qship
        } = previews

        return (
            <Table responsive>
                <HeadAddress addressType="Normal"/>
                <tbody>
                <tr>
                    <InputTemplate
                        type='default'
                        value={Marketplace}
                        placeholder="Marketplace"
                        field="Marketplace"
                        previews={previews}
                        onChangePreview={onChangePreview}
                    />
                    <InputTemplate
                        type='default'
                        value={orderNumber}
                        placeholder="Order Number"
                        field="orderNumber"
                        previews={previews}
                        onChangePreview={onChangePreview}
                    />
                    <InputTemplate
                        type='default'
                        value={name}
                        placeholder="Name"
                        field="name"
                        previews={previews}
                        onChangePreview={onChangePreview}
                    />
                    <InputTemplate
                        type='default'
                        value={address1}
                        placeholder="Address 1"
                        field="address1"
                        previews={previews}
                        onChangePreview={onChangePreview}
                    />
                    <InputTemplate
                        type='default'
                        value={address2}
                        placeholder="Address 2"
                        field="address2"
                        previews={previews}
                        onChangePreview={onChangePreview}
                    />
                    <InputTemplate
                        type='default'
                        value={city}
                        placeholder="City"
                        field="city"
                        previews={previews}
                        onChangePreview={onChangePreview}
                    />
                    <InputTemplate
                        type='default'
                        value={state}
                        placeholder="State"
                        field="state"
                        previews={previews}
                        onChangePreview={onChangePreview}
                    />
                    <InputTemplate
                        type='default'
                        value={country}
                        placeholder="Country"
                        field="country"
                        previews={previews}
                        onChangePreview={onChangePreview}
                    />
                    <InputTemplate
                        type='default'
                        value={phone}
                        placeholder="Phone"
                        field="phone"
                        previews={previews}
                        onChangePreview={onChangePreview}
                    />
                    <InputTemplate
                        type='default'
                        value={postalCode}
                        placeholder="Postal Code"
                        field="postalCode"
                        previews={previews}
                        onChangePreview={onChangePreview}
                    />
                    <InputTemplate
                        type='default'
                        value={qship}
                        placeholder="QShip"
                        field="qship"
                        previews={previews}
                        onChangePreview={onChangePreview}
                    />
                </tr>
                </tbody>
            </Table>
        )
    }
}

StandardAddress.propTypes = {
    previews: PropTypes.object
}

export default StandardAddress
