import React, { useState, useEffect } from 'react'
import { Modal, message } from 'antd'
import { gettListOrderProfitDetails } from '../../../services/api/SuppliersServices'

function PackageDetail(props) {
    const {
        showPackageDetailModal,
        togglePackageDetailModal,
        packageId,
    } = props

    const [listDetail, setListDetail] = useState([])

    const _fetchDetail = async () => {
        if (packageId) {
            try {
                const { success, data, message: mess } = await gettListOrderProfitDetails(packageId)
                if (success) {
                    setListDetail(data.fulfillments)
                } else {
                    mess.error(mess)
                }
            } catch (error) {
                message.error(error)
            }
        }
    }

    const checkProfit = (data) => {
        const {
            invoice_record,
        } = data
        const approval_status = !!invoice_record? invoice_record.approval_status : null
        const process_status = !!invoice_record? invoice_record.process_status : null

        const checkApprovalStatus = !!approval_status && approval_status === 'approved'
        const checkProcessStatus = !!process_status && process_status === 'done'

        return !!invoice_record && checkApprovalStatus && checkProcessStatus ? true : false
    }

    const checkValue = (data, value) => {
        const {
            invoice_record,
        } = data
        const approval_status = !!invoice_record? invoice_record.approval_status : null
        const process_status = !!invoice_record? invoice_record.process_status : null

        const checkApprovalStatus = !!approval_status && approval_status === 'approved'
        const checkProcessStatus = !!process_status && process_status === 'done'

        return !!invoice_record && checkApprovalStatus && checkProcessStatus ? value : '-'
    }

    useEffect(() => {
        _fetchDetail()
    }, [packageId,])

    return (
        <Modal
            className='PackageDetail'
            visible={showPackageDetailModal}
            onCancel={togglePackageDetailModal}
            title='Package Details'
            footer={''}
            style={{ minWidth: "1000px" }}
        >
            <div className='wrapTable'>
                <table className='table'>
                    <thead style={{ background: 'rgb(233, 236, 239)' }}>
                        <tr>
                            <th>Package name</th>
                            <th>Supplier</th>
                            <th>Revenue</th>
                            <th>Production fee</th>
                            <th>Shipping fee</th>
                            <th>Tax</th>
                            <th>Profit</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        {!!listDetail && listDetail.length > 0 &&
                            listDetail.map(i =>
                                <tr>
                                    <td>{i.name}</td>
                                    <td>{i.supplier.name}</td>
                                    <td>{checkValue(i,i.supplier_invoice.revenue)}</td>
                                    <td>{checkValue(i,i.supplier_invoice.production_fee)}</td>
                                    <td>{checkValue(i,i.supplier_invoice.shipping_fee)}</td>
                                    <td>{checkValue(i,i.supplier_invoice.tax)}</td>
                                    <td>{checkValue(i,i.supplier_invoice.profit)}</td>
                                    <td>
                                        {checkProfit(i) &&
                                            <label className='text-success text-capitalize'>
                                                {i.invoice_record.message}
                                            </label>
                                        }
                                        {!checkProfit(i) && !!i.invoice_record &&
                                            <label className='text-danger text-capitalize'>
                                                {i.invoice_record.message}
                                            </label>
                                        }
                                        {!i.invoice_record &&
                                            <label className='text-danger text-capitalize'>
                                                unchecked package
                                            </label>
                                        }
                                    </td>
                                </tr>
                            )
                        }
                        {!listDetail || listDetail.length === 0 &&
                            <tr>
                                <td colSpan={5}>
                                    No result
                                </td>
                            </tr>

                        }
                    </tbody>
                </table>
            </div>
        </Modal>
    )
}

export default PackageDetail