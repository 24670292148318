import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Input, Select } from "antd";
import FilterOptions from "../../shared/FilterOptions"

class SuppliersFilters extends Component {

    _handleChangeInput = (name, value) => {
        const { onChangeFilter } = this.props

        onChangeFilter({ [name]: value })
    }

    _handleChangeSelect = (name, value) => {
        const { onChangeFilter } = this.props

        if (name === 'available_tiktok') {
            const selectChange = { [name]: value }

            if (value !== 'true') selectChange.shipbytiktok = undefined

            onChangeFilter(selectChange)
        } else {
            onChangeFilter({ [name]: value })
        }
    }

    render() {
        const { filters, onChangeFilter } = this.props
        const {
            name, sku_prefix, active, need_gen_mockup, available_tiktok, shipbytiktok,
            'need_charge_have_sleeves[$gte]': gte,
            'need_charge_have_sleeves[$lte]': lte,
        } = filters

        return (
            <div className='FiltersSuppliers d-flex flex-wrap align-items-center'>
                <div className="Name mr-sm-3 mb-3">
                    <div className="font-weight-500 Name mb-1">Name:</div>

                    <Input.Search value={name} placeholder='Enter to search...'
                        onChange={(e) => this._handleChangeInput('name', e.target.value)}
                        style={{ maxWidth: 300 }} allowClear />
                </div>
                <div className="Sku mr-sm-3 mb-3">
                    <div className="font-weight-500 Name mb-1">Sku prefix:</div>
                    <Input.Search value={sku_prefix} placeholder='Enter to search...'
                        onChange={(e) => this._handleChangeInput('sku_prefix', e.target.value)}
                        onSearch={this._handleFormSubmit} style={{ maxWidth: 300 }} allowClear />
                </div>
                <div className="Name mr-sm-3 mb-3">
                    <FilterOptions onChangeOption={onChangeFilter} selectedKey={active !== null ? active : ''}
                        field='active'
                        placeHolder="Select Status"
                        options={[
                            { name: 'Active', value: 'true' },
                            { name: 'Inactive', value: 'false' },
                        ]}
                        layout='select'
                        heading='Status:'
                        defaultOption={''}
                        allowClear={true} />
                </div>
                <div className="Sku mr-sm-3 mb-3">
                    <div className="font-weight-500 Name mb-1">Need Gen Mockup:</div>
                    <Select
                        placeholder='Need Gen Mockup'
                        style={{ width: 200 }}
                        allowClear
                        onChange={(value) => this._handleChangeSelect('need_gen_mockup', value)}
                        value={need_gen_mockup || []}
                    >
                        <Select.Option value={'true'}>Yes</Select.Option>
                        <Select.Option value={'false'}>No</Select.Option>
                    </Select>
                </div>
                <div className="Sku mr-sm-3 mb-3">
                    <div className="font-weight-500 Name mb-1">Available TikTok:</div>
                    <Select
                        placeholder='Available TikTok'
                        style={{ width: 200 }}
                        allowClear
                        onChange={(value) => this._handleChangeSelect('available_tiktok', value)}
                        value={available_tiktok || []}
                    >
                        <Select.Option value={'true'}>Yes</Select.Option>
                        <Select.Option value={'false'}>No</Select.Option>
                    </Select>
                </div>
                {available_tiktok === 'true' &&
                    <div className="Sku mr-sm-3 mb-3">
                        <div className="font-weight-500 Name mb-1 Heading">Ship By TikTok:</div>
                        <Select
                            placeholder='Ship By TikTok'
                            style={{ width: 200 }}
                            allowClear
                            onChange={(value) => this._handleChangeSelect('shipbytiktok', value)}
                            value={shipbytiktok || []}
                        >
                            <Select.Option value={'true'}>Yes</Select.Option>
                            <Select.Option value={'false'}>No</Select.Option>
                        </Select>
                    </div>
                }
                <div className="Sku mr-sm-3 mb-3">
                    <div className="font-weight-500 Name mb-1 Heading">Need charge have sleeves:</div>
                    <Input.Group compact>
                        <Input
                            placeholder="Minimum"
                            style={{ width: 120, textAlign: 'center' }}
                            allowClear
                            onChange={(e) => this._handleChangeInput('need_charge_have_sleeves[$gte]', e.target.value)}
                            value={gte || ''}
                            type='number'
                        />
                        <Input
                            className="site-input-split"
                            style={{
                                width: 30,
                                borderLeft: 0,
                                borderRight: 0,
                                pointerEvents: 'none',
                            }}
                            placeholder="~"
                            disabled
                        />
                        <Input
                            className="site-input-right"
                            style={{
                                width: 120,
                                textAlign: 'center',
                            }}
                            placeholder="Maximum"
                            allowClear
                            onChange={(e) => this._handleChangeInput('need_charge_have_sleeves[$lte]', e.target.value)}
                            value={lte || ''}
                            type='number'
                        />
                    </Input.Group>
                </div>
            </div>
        )
    }
}

SuppliersFilters.propTypes = {
    onChangeFilter: PropTypes.func.isRequired,
    filters: PropTypes.object.isRequired
}

export default SuppliersFilters
