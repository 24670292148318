import createAPIServices from "./createApiServices"
import {getCurrentStore, getStoreSetting, getStoreToken, subscribeChangeStore} from "../CurrentStoreService"

const _getDefaultBaseURL = (suffix = '') => {
    const prefixURL = process.env.NODE_ENV === 'production' ? '/api' : 'https://fulfillment-staging.merchize.com/api'

    return `${prefixURL}/${suffix}`
}

const _create = (options = {}) => {
    const {suffix, getBaseURL} = Object.assign({}, options)

    const _getBaseURLByStore = () => {
        const currentStore = getCurrentStore()
        const isProduction = process.env.NODE_ENV === 'production'

        return typeof getBaseURL === 'function' ? getBaseURL(currentStore, isProduction, getStoreSetting) : _getDefaultBaseURL(suffix)
    }

    const _getBaseHeaders = () => {
        const currentStore = getCurrentStore()
        const token = getStoreToken()

        const _headers = {
            'X-Store-ID': currentStore,
        }

        if (token) {
            _headers['X-Store-Token'] = token
        }

        return _headers
    }

    const baseURL = _getBaseURLByStore()
    const headers = _getBaseHeaders()
    const _api = createAPIServices({
        baseUrl: baseURL,
        headers,
    })

    subscribeChangeStore(() => {
        const _baseURL = _getBaseURLByStore()
        const _headers = _getBaseHeaders()

        _api.changeBaseURL(_baseURL)
        _api.setDefault('headers', _headers)
    })

    return _api
}

const _validateOptions = (options) => {
    const {suffix, getBaseURL} = Object.assign({}, options)

    return {
        suffix: suffix || '',
        getBaseURL,
    }
}


export const createStoreAPI = (options = {}) => {
    const opts = _validateOptions(options)

    return _create(opts)
}

