import React from 'react'
import { message, Tooltip } from 'antd'
import { updatePrintFilesStatus } from '../../../../services/api/OrdersServices'

const MarkAsDone = (props) => {
    const { orderId, fulfillmentId, onSuccess } = props

    const _handleClick = e => {
        e.preventDefault()

        _rqMarkDone()
    }

    const _rqMarkDone = async () => {
        try {
            const { data, success, message } = await updatePrintFilesStatus(fulfillmentId, orderId, 'done')

            if (!success) {
                throw new Error(message)
            }

            onSuccess('done', orderId)
        } catch (e) {
            message.error(e.message, 3)
        }
    }

    return (
        <div className='MarkAsDone Icon'>
            <div className='d-inline-block' onClick={_handleClick}>
                <Tooltip title='Done' placement='bottom'>
                    <i className='fa fa-check-square' />
                </Tooltip>
            </div>
        </div>
    )
}

export default MarkAsDone