import React, {Component} from 'react'
import PropTypes from 'prop-types'

class WebhookTotalSent extends Component {
    render() {
        const {webhook} = this.props
        const {total_sent} = webhook

        return (
            <td className="WebhookTotalSent">
                {total_sent}
            </td>
        )
    }
}
WebhookTotalSent.propTypes = {
    webhook: PropTypes.object.isRequired,
}
export default WebhookTotalSent
