import React, {Component} from 'react'
import ShippingPlanEditorActions from "./ShippingPlanEditorActions"
import { getLocalData } from '../../../../services/StorageServices'

class ShippingPlanEditor extends Component {


    _handleChangeInput = e => {
        const {name, value} = e.target
        const {_handleUpdatePlanState} = this.props

        _handleUpdatePlanState(name, value)
    }

    render() {
        const {title, slug, _handleUpdatePlan, _hanleDeleteShippingPlan, loading} = this.props
        const permissionsData = !!getLocalData('permissions') ? Object.keys(getLocalData('permissions')) : []
        const updatePermission = permissionsData.includes('ffm_shipping_plan_update')
        const deletePermission = permissionsData.includes('ffm_shipping_plan_delete')

        return (
            <div className="ShippingPlanEditor">
                <div className="SectionInner">
                    <div className="form-group">
                        <label>Title</label>
                        <input type="text" name="title" value={title || ""} className="form-control"
                               disabled={!updatePermission}
                               onChange={this._handleChangeInput}/>
                    </div>

                    <div className="form-group">
                        <label>Slug</label>
                        <input type="text" name="slug" value={slug || ""} className="form-control"
                               disabled={!permissionsData.includes('ffm_shipping_plan_update')}
                               onChange={this._handleChangeInput}/>
                    </div>

                    <div className="mt-4">
                        <ShippingPlanEditorActions 
                            _handleUpdatePlan={_handleUpdatePlan}
                            _hanleDeleteShippingPlan={_hanleDeleteShippingPlan} loading={loading}
                            title={title}
                            updatePermission={updatePermission}
                            deletePermission={deletePermission}
                        />
                    </div>
                </div>
            </div>
        )
    }
}

export default ShippingPlanEditor
