import React, { useState, useEffect, useMemo } from 'react'
import { message } from 'antd'
import UseDebounce from '../helpers/UseDebounce'
import { Link } from 'react-router-dom'
import { getListSuppliersV2 } from '../../../services/api/SuppliersServices'
import { getListSpecialCase, changeStatusSpecialCase, deleteSpecialCase } from '../../../services/api/ProductsServices'
import SpecialCaseFilters from './SpecialCaseFilters'
import SpecialCaseTable from './SpecialCaseTable'
import { getLocalData } from '../../../services/StorageServices'

const SpecialCasePage = () => {
    const [listSpecialCases, setListSpecialCases] = useState([])
    const [loading, setLoading] = useState(false)
    const [page, setPage] = useState(1)
    const [limit, setLimit] = useState(10)
    const [total, setTotal] = useState(0)
    const [error, setError] = useState('')
    const [suppliers, setSuppliers] = useState([])
    const [filters, setFilters] = useState({})
    const [selectField, setSelectField] = useState('store')

    const permissionsData = useMemo(() => !!getLocalData('permissions') ? Object.keys(getLocalData('permissions')) : [], [])
    const { store = '', title = '', tag = '', supplier, updated_at } = { ...filters }
    const storeDebounce = UseDebounce(store, 500)
    const titleDebounce = UseDebounce(title, 500)
    const tagDebounce = UseDebounce(tag, 500)

    useEffect(() => {
        _fetchListSpecialCases()
    }, [page, limit, storeDebounce, supplier, titleDebounce, tagDebounce, updated_at])

    const _fetchListSpecialCases = async () => {
        setLoading(true)
        setError('')
        try {
            const payload = {
                page,
                limit,
                ...filters,
            }

            if (storeDebounce) payload.store = storeDebounce.trim()
            if (titleDebounce) payload.title = titleDebounce.trim()
            if (tagDebounce) payload.tag = tagDebounce.trim()

            const { data, success, message } = await getListSpecialCase(payload)
            if (!success) {
                setLoading(false)
                return setError(message)
            }

            const { mappingSpecials, total } = data
            setTotal(total)
            setListSpecialCases(mappingSpecials)
        } catch (e) {
            setError(e.message)
        } finally {
            setLoading(false)
        }
    }

    const handleDeleteSpecialCase = async (id) => {
        setLoading(true)
        setError('')
        try {
            const { success, message: mess } = await deleteSpecialCase(id)
            if (!success) return message.error(mess)

            message.success('Delete special case successfully!')
            _fetchListSpecialCases()
        } catch (e) {
            setError(e.message)
        } finally {
            setLoading(false)
        }
    }

    const handleChangeStatusSpecialCase = async (checked, id) => {
        setLoading(true)
        setError('')
        try {
            const newListSpecialCases = listSpecialCases.map(item => {
                if (item._id === id) {
                    return {
                        ...item,
                        status: checked ? 1 : 0
                    }
                }

                return item
            })

            setListSpecialCases(newListSpecialCases)
            const { success, message: mess } = await changeStatusSpecialCase(id, checked ? 'active' : 'inactive')
            if (!success) {
                message.error(mess)
                setListSpecialCases(listSpecialCases)
            } else {
                message.success('Change status special case successfully!')
                _fetchListSpecialCases()
            }
        } catch (e) {
            setError(e.message)
            setListSpecialCases(listSpecialCases)
        } finally {
            setLoading(false)
        }
    }

    const _handleChangeSize = (size) => {
        setLimit(size)
        setPage(1)
    }

    const _handleChangePage = (page) => {
        setPage(page)
    }

    const _handleChangeFilters = (field, value) => {
        if (field && field.updated_at) {
            setFilters({ ...filters, [Object.keys(field)[0]]: field.updated_at })
            setPage(1)
            return
        }
        setFilters({ ...filters, [field]: value })
        setPage(1)
    }

    const _handleChageSelectField = (value) => {
        delete filters[selectField]
        setSelectField(value)
        setPage(1)
    }

    const _fetchListSuppliers = async () => {
        try {
            const { data, success, message } = await getListSuppliersV2({ active: true })

            if (!success) {
                console.log(message)
                return
            }

            const _listSuppliers = data.suppliers.map(option => ({ label: option.name, value: option._id }))
            setSuppliers(_listSuppliers)
        } catch (e) {
            message.error(e.message)
        }
    }

    useEffect(() => {
        _fetchListSuppliers()
    }, [])

    return (
        <div className='SpecialCasePage'>
            <Link className="nav-link BackButton pl-0" to="/a/suppliers">
                <i className="fas fa-chevron-left mr-2" />
                <span>Supplier</span>
            </Link>
            <div className='d-flex flex-column flex-md-row align-items-md-center justify-content-between mb-3'>
                <h1 className="PageTitle">
                    Mapping Special Case
                </h1>
                {permissionsData.includes('ffm_mapping_special_case_create') &&
                    <div className="d-flex align-items-center justify-content-end mr-lg-0 mr-3">
                        <Link className='btn btn-primary mr-3' to='/a/special-case-create'>New Case</Link>
                    </div>
                }
            </div>
            <div className='SectionInner'>
                <div className='filter mb-3 align-items-center'>
                    <div className="d-flex align-items-center justify-content-between">
                        <SpecialCaseFilters
                            loading={loading}
                            suppliers={suppliers}
                            filters={filters}
                            handleChangeFilters={_handleChangeFilters}
                            selectField={selectField}
                            handleChageSelectField={_handleChageSelectField}
                            placeholders={{
                                store: "Search for store",
                                title: "Search for title",
                                tag: "Search for tag"
                            }}
                        />

                    </div>
                </div>
                <div className="table">
                    {error && <div className="text-danger">{error}</div>}
                    <SpecialCaseTable
                        page={page}
                        limit={limit}
                        total={total}
                        loading={loading}
                        data={listSpecialCases}
                        onChangeSize={_handleChangeSize}
                        onChangePage={_handleChangePage}
                        onDeleteSpecialCase={handleDeleteSpecialCase}
                        onChangeStatusSpecialCase={handleChangeStatusSpecialCase}
                        permissionsData={permissionsData}
                    />
                </div>
            </div>
        </div>
    )
}

export default SpecialCasePage