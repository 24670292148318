import React, {Component} from 'react'
import {render, unmountComponentAtNode} from 'react-dom'
import {Button, Popover, PopoverBody, Spinner} from "reactstrap"
import PropTypes from 'prop-types'

class ConfirmPopover extends Component {
    state = {
        loading: false,
        error: '',
    }

    _toggle = () => {
        this.props.onClose(false)
    }

    _getIcon = () => {
        const {type} = this.props
        if (!type) return null

        let icon = ""

        switch (type) {
            case 'success':
                icon = <i className="fas fa-check-circle text-success"/>
                break
            case 'info':
                icon = <i className="fas fa-info-circle text-info"/>
                break
            case 'warning':
                icon = <i className="fas fa-exclamation-circle text-warning"/>
                break
            case 'danger':
            case 'error':
                icon = <i className="fas fa-times-circle text-danger"/>
                break
            default:
                icon = null
        }

        return <span className="mr-1">{icon}</span>
    }

    _handleClickOk = () => {
        const {onOk, onClose} = this.props

        if (typeof onClose !== 'function') {
            onClose(false)
            return
        }

        const result = onOk()

        if (!result || !result.then) {
            onClose(false)
            return result
        }

        this.setState({loading: true})

        result.then(response => {
            onClose(false)
        }).catch(e => {
            this.setState({
                loading: false,
                error: e.message,
            })
        })
    }

    render() {
        const {loading} = this.state
        const {message, id, placement, offset} = this.props

        return (
            <Popover isOpen target={id} placement={placement} fade={false} trigger="legacy" toggle={this._toggle} offset={offset}>
                <PopoverBody>
                    <div className="mb-3 d-flex">
                        {this._getIcon()}{message}
                    </div>
                    <div className="text-right">
                        <Button size="sm" className="btn-default mr-2" onClick={this._toggle}>Cancel</Button>
                        <Button size="sm" color="primary" onClick={this._handleClickOk}>
                            {
                                loading && <Spinner color='white' size='sm' className='mr-2'/>
                            }
                            Yes
                        </Button>
                    </div>
                </PopoverBody>
            </Popover>
        )
    }
}

ConfirmPopover.defaultProps = {
    offset: "0, 0"
}

ConfirmPopover.propTypes = {
    id: PropTypes.string.isRequired,
    onClose: PropTypes.func.isRequired,
    onOk: PropTypes.func,
    message: PropTypes.string,
    placement: PropTypes.string,
    offset: PropTypes.string
}

export function ConfirmModalV2(props) {
    return new Promise(resolve => {
        let el = document.createElement('div')

        const handleResolve = result => {
            unmountComponentAtNode(el)
            el = null
            resolve(result)
        }

        render(<ConfirmPopover {...props} onClose={handleResolve} />, el)
    })
}
