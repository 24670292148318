import React, {Component} from 'react'
import OrderFulfillmentPage from "./OrderFulfillmentPage"

class OrderFulfillmentContainer extends Component {
    render() {
        const orderId = this.props.match.params.id

        return (
            <div className="OrderFulfillmentContainer">
                <OrderFulfillmentPage orderId={orderId}/>
            </div>
        )
    }
}

export default OrderFulfillmentContainer
