import React, {Component} from 'react'
import PropTypes from 'prop-types'

class ArtworkImages extends Component {
    render() {
        const {
            preview, side, background, resize, width, height
        } = this.props

        return (
            <div className='ArtworkImages'>
                <div className="mb-2">
                    <a href={preview} target="_blank" rel="noopener noreferrer">{side}</a>
                </div>

                <div className="ImageArt">
                    <div className="OriginImage" style={{background: background}}>
                        <img
                            style={{background: background}} src={resize}
                            alt="artwork preview"
                            className={`ArtworkItem`}
                        />
                    </div>

                    <div className="Hover">
                        <img
                            style={{background: background}} src={resize}
                            alt="artwork preview"
                        />
                    </div>
                </div>

                <div className="Meta">{width}x{height}</div>
            </div>
        )
    }
}

ArtworkImages.propTypes = {
    preview: PropTypes.string,
    side: PropTypes.string,
    background: PropTypes.string,
    resize: PropTypes.string,
    width: PropTypes.number,
    height: PropTypes.number
}

export default ArtworkImages
