import React from 'react'
import PropTypes from 'prop-types'
import RequestContext from "../context/RequestContext"

RequestProvider.propTypes = {
    fetchRequest: PropTypes.func.isRequired
}

function RequestProvider(props) {
    const {fetchRequest} = props

    return (
        <RequestContext.Provider
            value={{
                fetchRequest
            }}
        >
            {props.children}
        </RequestContext.Provider>
    )
}

export default RequestProvider
