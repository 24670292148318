import React from 'react'
import OrderPartnersContext from './../context/OrderPartnersContext'
import PropTypes from 'prop-types'

function OrderPartnersProvider(props) {
    const {loadOrders, setState, filters} = props

    return (
        <OrderPartnersContext.Provider
            value={{
                loadOrders: loadOrders,
                filters,
                setState: setState
            }}
        >
            {props.children}
        </OrderPartnersContext.Provider>
    )
}

OrderPartnersProvider.propTypes = {
    loadOrders: PropTypes.func.isRequired
}

export default OrderPartnersProvider
