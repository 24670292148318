import React, { useState, useEffect, useMemo } from 'react'
import { getReasonTags, getOrderRefund } from '../../../services/api/OrdersServices'
import { getIssues } from '../../../services/api/PackageIssuesServices'
import StatusStyle from "../../shared/StatusStyle"
import formatCurrency from "../../../helpers/common/formatCurrency"
import { currency as currencies } from "../../../statics/currency"
import { createChargeRefund } from '../../../services/api/OrdersServices'
import formatThousandNumber from "../../../helpers/common/formatThousandNumber"
import { getLocalData } from "../../../services/StorageServices"
import { Modal, message, Radio, AutoComplete, Checkbox, Tooltip, Popover } from "antd"
import classNames from 'classnames'
import NumberFormat from 'react-number-format'

const RefundModal = (props) => {
    const { isOpenRefund, closeModal, packageName, orderId, statusPackage } = props
    const [error, setError] = useState('')
    const [loading, setLoading] = useState(false)
    const [refund, setRefund] = useState(0)
    const [refundData, setRefundData] = useState({})
    const [refundValue, setRefundValue] = useState(0)
    const [type, setType] = useState('dollar')
    const [reasonTag, setReasonTag] = useState('')
    const [reasonTagText, setReasonTagText] = useState('')
    const [validated, setValidated] = useState({
        vType: '',
        vRefund: '',
        vRasonTag: '',
        vReason: '',
    })
    const [tags, setTags] = useState([])
    const [currency, setCurrency] = useState('USD')
    const [typeRefund, setTypeRefund] = useState('refund')
    const [reason, setReason] = useState('')
    const [requestIssues, setRequestIssues] = useState([])

    const { maxRefund = 0, totalRefund = 0 } = Object.assign({}, refundData)

    const reasonTagOptions = useMemo(() => !!tags && [
        ...tags.filter(item => item.type === typeRefund).map(item => ({
            value: item.description,
            label: item.value,
            key: item._id
        }))
    ], [tags])

    const symbolPrice = useMemo(() => currencies.find(item => item.value === currency), [currency])

    const _fetchReasonTags = async () => {
        try {
            const { success, data } = await getReasonTags()

            if (!success) return
            setTags(data)
        } catch (e) {
            message.error(e.message)
        }
    }

    const _fetchOrderRefund = async () => {
        try {
            const { data, success, message } = await getOrderRefund(orderId)

            if (!success) return setError(message)
            const { maxRefund = 0 } = Object.assign({}, data)
            setRefundData(data)
            setRefund(maxRefund)
            setRefundValue(formatThousandNumber(parseFloat(maxRefund).toFixed(2)))
            setError()
        } catch (e) {
            setError(e.message)
        }
    }

    useEffect(() => {
        _fetchReasonTags()
    }, [])

    useEffect(() => {
        !!orderId && _fetchOrderRefund(orderId)
    }, [orderId])

    const getlistIssues = async () => {
        const payload = {
            page: 1,
            limit: 20000,
            query: { package_name: packageName }
        }
        setLoading(true)
        setError('')
        try {
            const { success, data, message: mess } = await getIssues(payload)
            if (!success) {
                throw new Error(mess)
            }
            const { issues } = { ...data }
            const newIssues = issues.filter(i => i.solution === "refund" && (!i.resolution || (!!i.resolution && i.resolution !== "refunded"))).map(item => ({
                ...item,
                isCheck: true
            })) || []
            const issueSorts = newIssues.sort((a, b) => a.category === 'shipment' && b.category !== 'shipment' ? -1 : 1)
            setRequestIssues(issueSorts)
        } catch (error) {
            message.error(error.message || 'Unknown error')
            setError(error.message || 'Unknown error')
        } finally {
            setLoading(false)
        }
    }

    useEffect(() => {
        !!packageName && getlistIssues()
    }, [packageName])

    const _handleChangeRefund = (e) => {
        const { value } = e.target
        const total = parseFloat(maxRefund) + parseFloat(totalRefund)

        let errorRefund = ''

        if (value === '') {
            errorRefund = `This value is not valid`

            setRefund(value)
            setRefundValue("")
            setValidated({
                ...validated,
                vRefund: errorRefund
            })
        }

        if (typeRefund === 'refund') {
            const maxPercent = parseFloat(total) > 0 ? (parseFloat(total) - parseFloat(totalRefund)) / parseFloat(total) * 100 : 0
            const maxValue = parseFloat(total) - parseFloat(totalRefund)

            if (maxValue === 0) {
                errorRefund = `Order can not refund`
            } else {
                if (type === 'dollar') {
                    if (parseFloat(value.replace(/[,]/g, '')) > parseFloat(maxValue)) {
                        errorRefund = `This value must be less than ${formatCurrency(currency, maxValue)}`
                    }
                } else {
                    if (parseFloat(value.replace(/[,]/g, '')) > parseFloat(maxPercent)) {
                        errorRefund = `This value must be less than ${parseFloat(maxPercent).toFixed(2)}%`
                    }
                }
            }
        }

        if (parseFloat(value) === 0) errorRefund = 'This value must be larger 0'

        const valuePercent = parseFloat(total) / 100 * parseFloat(value.replace(/[,]/g, ''))

        setRefund(value.replace(/[,]/g, ''))
        setRefundValue(type === 'dollar' ? formatThousandNumber(parseFloat(value.replace(/[,]/g, '')).toFixed(2)) : formatThousandNumber(parseFloat(valuePercent).toFixed(2)))
        setValidated({
            ...validated,
            vRefund: errorRefund
        })
    }

    const _changeTag = (value, e) => {
        setReasonTag(e.key)
        setReasonTagText(value)
        setReason(e.value)
        setValidated({
            ...validated,
            vRasonTag: value.trim() === '' ? `This field can't be blank` : '',
            vReason: ''
        })
    }

    const _changeReason = (e) => {
        const { value } = e.target

        setReason(value)
        setValidated({
            ...validated,
            vReason: value.trim() === '' ? `This field can't be blank` : ''
        })
    }

    const _handleChangeType = (value) => {
        const total = parseFloat(maxRefund) + parseFloat(totalRefund)
        const maxPercent = parseFloat(total) > 0 ? (parseFloat(total) - parseFloat(totalRefund)) / parseFloat(total) * 100 : 0
        const maxValue = parseFloat(total) - parseFloat(totalRefund)


        if (type !== value) {
            setType(value)
            setRefund(value === 'dollar' ? formatThousandNumber(parseFloat(maxValue).toFixed(2)) : maxPercent)
            setRefundValue(formatThousandNumber(parseFloat(maxValue).toFixed(2)))
            setValidated({
                ...validated,
                vRefund: ''
            })
        }
    }

    const _handleValidate = () => {
        const { vRefund } = validated

        let errorRefund = vRefund
        if (parseFloat(refundValue) === 0) {
            errorRefund = 'This value must be larger 0'
        }

        let errorType = ''
        if (typeRefund === '') {
            errorType = `This field can't be blank`
        }

        let errorTag = ''
        if (reasonTag === '') {
            errorTag = `This field can't be blank`
        }

        let errorReason = ''
        if (reason === '') {
            errorReason = `This field can't be blank`
        }

        setValidated({
            vType: errorType,
            vRefund: errorRefund,
            vRasonTag: errorTag,
            vReason: errorReason,
        })

        if (errorType !== '' || vRefund !== '' || errorTag !== '' || errorReason !== '') return false
        return true
    }

    const _handleRefund = async () => {
        if (_handleValidate()) {
            setLoading(true)
            setError('')

            try {
                const user = getLocalData('user')

                const payload = {
                    user_id: user.id,
                    refund_amount: typeRefund === 'refund' ? parseFloat(refundValue) : 0,
                    charge_amount: typeRefund === 'charge' ? parseFloat(refundValue) : 0,
                    reason_tag: reasonTag,
                    supporter: "",
                    charge_percent: typeRefund === 'charge' ? (type === 'dollar' ? 0 : parseFloat(refund)) : 0,
                    refund_percent: typeRefund === 'refund' ? (type === 'dollar' ? 0 : parseFloat(refund)) : 0,
                    reason_for_seller: reason,
                    currency,
                    issues: requestIssues.filter(i => i.isCheck).map(o => o._id) || []
                }

                const { success, message: messError } = await createChargeRefund('refund', orderId, payload)

                if (!success) {
                    setLoading(false)
                    return setError(messError)
                }

                setLoading(false)
                closeModal()
                message.success('Refund request create success!')
                if (typeRefund === 'refund') {
                } else {
                    message.success('Charge request create success!')
                }

            } catch (e) {
                setError(e.message)
            }
        } else {
            setError('')
        }
    }

    const _renderTitlePopup = () => {
        const order = !!packageName ? packageName.replace(/-F1$/, '') : ''
        return <div className="CustomHeaderPopup">
            <label>Refund order</label>
            <div className="infoOrder d-flex align-items-center">#{order} <StatusStyle status={statusPackage} /></div>
        </div>
    }

    const handleChangeItemRequest = (id, checked) => {
        const newListRequestIssues = requestIssues.map(item => {
            if (item._id === id) {
                return {
                    ...item,
                    isCheck: !checked
                }
            }

            return item
        })
        setRequestIssues(newListRequestIssues)
    }

    const renderContent = (items) => {
        return (
            <div style={{
                display: 'grid',
                gridTemplateColumns: 'repeat(2, 1fr)',
                gridColumnGap: 20
            }}>
                {
                    items.map((item, i) => (
                        <div key={`${item}_${i}`} className="barcode-item">
                            {item}
                        </div>
                    ))
                }
            </div>
        )
    }

    return (
        <Modal
            visible={isOpenRefund}
            width={837}
            className='PopupRefundContent'
            title={_renderTitlePopup()}
            onOk={_handleRefund}
            onCancel={closeModal}
            footer={[
                <div key="submit" className="d-flex justify-content-end align-items-center">
                    <button className="btn btn-primary" disabled={loading || refund === 0 || !requestIssues.some(i => i.isCheck)}
                        key="submit" type="primary"
                        onClick={_handleRefund}>
                        Request Refund {refund !== '' && formatCurrency(currency, refundValue)}
                    </button>
                </div>,
            ]}
        >
            <div className="PopupRefundOptions">
                <div className="d-flex align-items-center justify-content-between">
                    <div className="rowItem d-flex align-items-start mb-3">
                        <label className="mb-0">Type</label>
                        <div className="position-relative">
                            <Radio.Group
                                className={classNames("", validated.vType !== '' && 'error')}
                                defaultValue={typeRefund}
                                value={typeRefund}
                            >
                                <Radio value="refund">Refund</Radio>
                                <Radio value="charge" disabled>Charge</Radio>
                            </Radio.Group>

                            {validated.vType !== '' && <div className="text-danger mt-2">{validated.vType}</div>}
                        </div>
                    </div>
                    <div className="rowItem mb-3 d-flex align-items-start">
                        <label className="mb-0 mt-2">Value</label>
                        <div className="d-flex align-items-center">
                            <div className={classNames("valueRefund", validated.vRefund !== '' && 'error')}>
                                <NumberFormat
                                    thousandSeparator={currency === 'USD' ? true : '.'}
                                    decimalSeparator={currency === 'USD' ? '.' : ','}
                                    decimalScale={currency === 'USD' ? 2 : 0}
                                    prefix=""
                                    value={refund}
                                    onChange={_handleChangeRefund}
                                />
                                <span onClick={() => _handleChangeType('dollar')}
                                    className={classNames("", type === 'dollar' && 'active')}>{symbolPrice.symbol}</span>
                                <span onClick={() => _handleChangeType('percent')}
                                    className={classNames("", type === 'percent' && 'active')}>%</span>
                            </div>
                            {validated.vRefund !== '' && <div className="text-danger ml-3">{validated.vRefund}</div>}
                        </div>
                    </div>
                </div>
                <div className="d-flex align-items-start justify-content-between mb-3">
                    <div className="rowItem d-flex align-items-start w-100">
                        <label className="mb-0 mt-2">Reason tag</label>
                        <div className="position-relative w-100 ">
                            <AutoComplete style={{ width: "100%" }}
                                className={classNames("", validated.vRasonTag !== '' && 'error')}
                                onChange={(value, e) => _changeTag(value, e)}
                                defaultValue={reasonTagText}
                                value={reasonTagText}
                                options={reasonTagOptions}
                                placeholder='Select reason tag'
                                filterOption={(inputValue, option) => option.value.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1}
                            >
                            </AutoComplete>
                            {validated.vRasonTag !== '' && <div className="text-danger mt-2">{validated.vRasonTag}</div>}
                        </div>
                    </div>
                </div>
                <div className="rowItem mb-3 pt-1">
                    <label className="d-block">Reason for seller</label>
                    <div className="position-relative">
                        <textarea onChange={_changeReason}
                            className={classNames("form-control", validated.vReason !== '' && 'error')}
                            placeholder="Send to seller..."
                            value={reason || ""} />
                        {validated.vReason !== '' && <div className="text-danger mt-2">{validated.vReason}</div>}
                    </div>
                </div>
                <div className="mt-3 mb-4 border-top pt-2">
                    <label style={{ fontWeight: 500 }}>Request refund for issue items:</label>
                    <div className="issue-request-refund">
                        {
                            requestIssues.map(issue => {
                                const { category, package_name, variant_data, quantity, reference_number, tracking_number = '' } = Object.assign({}, issue)
                                const { product_type, size, color } = Object.assign({}, variant_data)
                                return category !== "shipment" ?
                                    (
                                        <div key={issue._id} style={{
                                            display: 'grid',
                                            gridTemplateColumns: '60px repeat(4, 1fr)',
                                            borderBottom: '1px solid #dedede',
                                            paddingBottom: '2px',
                                        }}>
                                            <span style={{ maxWidth: 60 }}><Checkbox checked={issue.isCheck} onChange={() => handleChangeItemRequest(issue._id, issue.isCheck)} /></span>
                                            {reference_number && Array.isArray(reference_number) && reference_number.length > 0 ?
                                                <div className='reference_number'>
                                                    <span>{reference_number[0]}</span>
                                                    {reference_number && reference_number.length > 1 &&
                                                        <Popover
                                                            title="Items"
                                                            trigger="click"
                                                            content={() => renderContent(reference_number)}
                                                            className='cursor-pointer'
                                                            placement={'bottom'}
                                                        >
                                                            <div>
                                                                <span className="cursor-pointer" style={{ color: 'rgb(33, 150, 243)' }}>
                                                                    {`Show all (${reference_number.length})`}
                                                                </span>
                                                            </div>
                                                        </Popover>
                                                    }
                                                </div> : <span>{reference_number || ''}</span>
                                            }
                                            {category === "production" && product_type && <span>{product_type}</span>}
                                            {category === "production" && <span className='text-center'>{(!!size || !!color) ? (size || color) : (size && color ? `${size}/${color}` : '')}</span>}
                                            {category === "production" && quantity && <span className='text-center'>{`Qty: ${quantity}`}</span>}
                                        </div>
                                    ) : (
                                        <div key={issue._id} style={{
                                            display: 'grid',
                                            gridTemplateColumns: '60px repeat(4, 1fr)',
                                            borderBottom: '1px solid #dedede',
                                            paddingBottom: '2px',
                                            background: "#f1f1f1",
                                            cursor: 'pointer'
                                        }}>
                                            <span style={{ maxWidth: 60 }}><Checkbox checked={issue.isCheck} onChange={() => handleChangeItemRequest(issue._id, issue.isCheck)} /></span>
                                            <Tooltip title='Shipment issue.'>
                                                {package_name && <span>{package_name}</span>}
                                            </Tooltip>
                                            {tracking_number && <span>{tracking_number}</span>}
                                        </div>
                                    )
                            })
                        }
                    </div>
                </div>
            </div>
            {error && <div className="mt-3 text-danger">{error}</div>}
        </Modal>
    )
}

export default RefundModal