import React, {useEffect, useState} from 'react'
import {Pagination, Spin, Tooltip, Select} from "antd"
import FilterDate from "../../../shared/FilterDate"
import {getShipmentStatusByStore} from "../../../../services/api/ShippingServices"
import {Link} from "react-router-dom"
import {
    ArrowUpOutlined,
    ArrowDownOutlined,
    ReloadOutlined,
} from '@ant-design/icons'
import downloadCsv from '../../../../helpers/common/downloadCsv'
import {
    shipByDays,
    dataColumns,
    getFilterTrackingStatus,
    getPermalink,
} from './Helper'
import moment from 'moment'
import changeQuerySearch from "../../../../helpers/routing/changeQuerySearch"
import {DropdownMenu, DropdownToggle, UncontrolledDropdown, DropdownItem} from "reactstrap";


const momentFormat = 'DD/MM/YYYY'
const getDefaultDate = () => {
    return {
        to: moment().format(momentFormat),
        from: moment().subtract(30, 'days').format(momentFormat),
    }
}

const {
    ship_greater_than_13_days_count,
    ship_greater_than_16_days_count,
    ship_greater_than_21_days_count,
    ...vDataColumns
} = dataColumns

function ReportShipStatusByStore(props) {
    const {Config, filters: queryFilters} = props
    const localFilters = Config.get('filters', {
        packaged_at: queryFilters && queryFilters.packaged_at || {},
    })
    const localPagination = Config.get('pagination', {
        limit: 1000,
        sortBy: '-ship_13_days_count',
    })
    const {
        packaged_at:localPackagedAt,
        ...restLocalFilters
    } = localFilters
    let {
        from,
        to,
    } = {...localPackagedAt}

    if (!from || !to) {
        const defDate = getDefaultDate()
        from = defDate.from
        to = defDate.to
    }

    const [results, setResults] = useState([])
    const [totalResults, setTotalResults] = useState({})
    const [page, setPage] = useState(1)
    const [total, setTotal] = useState(0)
    const [error, setError] = useState('')
    //const [loading, setLoading] = useState(false)
    const [filters, setFilters] = useState({'packaged_at': {from, to}, shipByDay: 13, ...restLocalFilters, ...queryFilters})
    const [sortBy, setSortBy] = useState(`-ship_${filters.shipByDay}_days_count`)
    const [limit, setLimit] = useState(localPagination.limit || 1000)
    const [sortItem, setSortItem] = useState(`ship_${filters.shipByDay}_days_count`)
    const [sortType, setSortType] = useState('quantity_down')
    const {loading, setLoading} = props
    vDataColumns[`ship_${filters.shipByDay}_days_count`] = ''
    localPagination.sortBy = [`ship_${filters.shipByDay}_days_count`]
    let numPages = 0
    const _handleChangeDate = (paramFilter) => {
        const _filters = {
            ...filters,
            ...paramFilter,
        }
        setFilters(_filters)
        Config.set('filters', _filters)
        changeQuerySearch(Object.keys(paramFilter.packaged_at).reduce((a, b) => {
            return {
                ...a,
                [`packaged_at.${b}`]: paramFilter.packaged_at[b],
            }
        }, {}), true)
    }

    const getFilters = (def) => {
        const {shipByDay, compare, ...restFilters} = {...filters}

        return {
            query: {
                ...restFilters,
                ...(def && def.filters),
            },
            page,
            limit,
            sort: sortBy,
            // ship_by_days: `${maxShipDay}` === `${shipByDay}` ? [shipByDay, 9999] : [+shipByDay],
            // compare: compare ? compare : `${maxShipDay}` === `${shipByDay}` ? '$in' : '$eq',

            ship_by_days: [shipByDay, 9999],
            compare: compare ? compare : '$in',
            ...def,
        }
    }

    async function _fetchReportShipStatus() {
        const filters = getFilters()
        const {query} = filters

        if (!query.packaged_at || !query.packaged_at.from || !query.packaged_at.to) {
            return setError(`Please select from and to of date.`)
        }

        if (moment(query.packaged_at.to, momentFormat).diff(moment(query.packaged_at.from, momentFormat), 'days') + 1 > 60) {
            const isStartOfMonth = moment(query.packaged_at.from, momentFormat).startOf('month').format('DD') === '01'
            const isEndOfMonth = moment(query.packaged_at.to, momentFormat).format('DD') === moment(query.packaged_at.to, momentFormat).endOf('month').format('DD')

            if (!isStartOfMonth || !isEndOfMonth) {
                return setError(`Date range must be less or equals to 60 days.`)
            }
        }

        try {
            setLoading(true)
            setError('')

            const response = await getShipmentStatusByStore(filters)

            const {success, data, message} = response

            if (!success) {
                setLoading(false)

                return setError(message)
            }

            setLoading(false)
            setResults(data.results)
            setTotalResults(data.totalResults)
            setTotal(data.total)
            numPages = data.pages
        } catch (e) {
            setLoading(false)
            setError(e.message)
        }
    }

    const clearFilters = () => {
        if (loading) {
            return
        }
        const defDate = getDefaultDate()

        const newFilters = {
            packaged_at: defDate,
            shipByDay: 13,
            sortBy: `-ship_13_days_count`,
        }
        const newPagination = {
            limit: 1000,
            sortBy: `-ship_13_days_count`,
        }
        setFilters(newFilters)
        setSortBy(newPagination.sortBy)
        setLimit(newPagination.limit)

        Config.set('pagination', newPagination)
        Config.set('filters', newFilters)
    }

    useEffect(() => {
        _fetchReportShipStatus()
    }, [filters.packaged_at, page, sortBy, sortType, limit, filters.shipByDay])

    const WrapContent = (props) => {
        return <div>{!!props.children && props.children}</div>
    }

    const sortingIcon = (colName) => {
        const m = sortBy ? `${sortBy}`.match(/^(-)?(.*)$/) : false
        const isSortUp = m ? m[1] === '-' : false
        const sortField = m ? m[2] === colName : false

        return <div className={['SortColumnHandle', sortField && (isSortUp ? 'Up' : 'Down')].join(' ')}>
            <Tooltip title={`Sort up!`}>
                <ArrowUpOutlined className="SortUp" onClick={doSort(colName, 'up')}/>
            </Tooltip>
            <Tooltip title={`Sort down!`}>
                <ArrowDownOutlined className="SortDown" onClick={doSort(colName, 'down')}/>
            </Tooltip>
        </div>
    }

    const isSortingBy = (column) => {
        return `${column}` === sortBy || `-${column}` === sortBy
    }

    const doSort = (col, upOrDown) => () => {
        const sortBy = [upOrDown === 'down' && '-', `${col}`].filter(Boolean).join('')
        setSortBy(sortBy)
        Config.merge('pagination', {
            sortBy,
        })
    }

    const setTab = () => props.onChangeTab('')

    const doExport = async () => {
        setLoading(true)
        const response = await getShipmentStatusByStore(getFilters({limit: 1000}))
        const {success, data, message} = response
        const mapCols = {
            store: 'Store',
            unknown_count: 'Unknown',
            exception_count: 'Exception',
            out_for_delivery_count: 'Out for delivery',
            pre_transit_count: 'Pre-transit',
            in_transit_count: 'In-transit',
            delivered_count: 'Delivered',
            total_count: 'Total',
            overtime_ship_count: 'Ship delay',
            //ship_greater_than_13_days_count: '> 13BDs',
            [`ship_${filters.shipByDay}_days_count`]: (filters.shipByDay > 1 ? `> ${filters.shipByDay}BDs` : `> ${filters.shipByDay}BD`),
            avg_time: 'Avg time',
        }
        await downloadCsv([data.totalResults, ...data.results].map(row => {
            Object.keys(mapCols).forEach(col => {
                if (['store'].includes(col)) {
                    return
                }

                if (col === 'avg_time') {
                    row.avg_time = Math.round(row.avg_time * 100) / 100
                } else if (col === 'total_count') {
                } else if (col !== 'overtime_ship_count') {
                    row[col] = [
                        row[col],
                        `(${row.total_count ? Math.round(row[col] / row.total_count * 100, 1) : 0}%)`,
                    ].join(' ')
                }
            })

            return row
        }), {mapCols})

        setLoading(false)
    }

    const handlePageChange = (page, pageSize) => {
        setPage(page)
        setLimit(pageSize)

        Config.merge('pagination', {
            limit: pageSize,
        })
    }

    const getPermalinkWrap = (args) => getPermalink(args, filters)

    const updateShipByDay = (e) => {
        const shipByDay = e.target ? e.target.value : e
        const _filters = {
            ...filters,
            shipByDay,
            sortBy: `-ship_${shipByDay}_days_count`,
        }
        shipByDays.forEach(d => {
            delete vDataColumns[`ship_${d}_days_count`]
        })
        vDataColumns[`ship_${shipByDay}_days_count`] = ''
        setFilters(_filters)
        setSortBy(`-ship_${shipByDay}_days_count`)
        Config.set('filters', _filters)
        changeQuerySearch({shipByDay}, true)
    }

    const selectStore = (store) => (e) => {
        e.preventDefault()

        props.setStore(store)
        props.onChangeTab('')
    }

    const conSpan = 1
    const tabsStaticsHeight = !!document.getElementById("tabsStatics") && document.getElementById("tabsStatics").offsetHeight
    const FilterHeight = !!document.getElementById("Filter") && document.getElementById("Filter").offsetHeight
    const ErrorHeight = !!document.getElementById("Error") && document.getElementById("Error").offsetHeight
    const maxShipDay = Math.max(...shipByDays)
    const {
        packaged_at,
    } = {...filters}
    const {
        from: packagedAtFrom,
        to: packagedAtTo,
    } = {...packaged_at}
    const getLinkToCarrier = (store) => {
        const base = `/a/analytics/daily-report/by-carrier/${store}`
        const query = [packagedAtFrom && `packaged_at.from=${packagedAtFrom}`, packagedAtTo && `packaged_at.to=${packagedAtTo}`].filter(Boolean).join('&')

        return [base, query].filter(Boolean).join('?')
    }

    shipByDays.forEach(d => {
        if (`${d}` === `${filters.shipByDay}`) {
            return
        }

        delete vDataColumns[`ship_${d}_days_count`]
    })

    const getSort = () => {
        const sorts = `${sortItem}`.match(/^(-)?(.*)$/)

        return {
            key: sorts[2],
            dir: sorts[1] ? -1 : 1,
        }
    }
    const handleChangeSortType = (value) =>{
        setSortType(value)
    }
    const getSortIcon = (field) => {
        const { key, dir } = getSort()
        const classNames = [`ti-filter cursor-pointer ${field} Sort`].filter(Boolean).join(' ')

        return <Tooltip>
            <UncontrolledDropdown
                className='MenuActions position-absolute p-0'
                style={{ fontSize: '10px', top: '5px', left: '0px' }}
            >
                <DropdownToggle tag='span' className='ToggleActions'>
                    <i className={classNames}/>
                </DropdownToggle>

                <DropdownMenu>
                    <div>
                        <DropdownItem className={sortType === 'quantity_up' ? 'Selected' : ''} onClick={() => handleChangeSortType('quantity_up')}><i className="fa fa-sort-numeric-asc" aria-hidden="true"></i> Quantity sort up</DropdownItem>
                        <DropdownItem className={sortType === 'quantity_down' ? 'Selected' : ''} onClick={() => handleChangeSortType('quantity_down')}><i className="fa fa-sort-numeric-desc" aria-hidden="true"></i> Quantity sort down</DropdownItem>
                        <DropdownItem className={sortType === 'percent_up' ? 'Selected' : ''} onClick={() => handleChangeSortType('percent_up')}><i className="fa fa-sort-numeric-asc" aria-hidden="true"></i> Percent sort up</DropdownItem>
                        <DropdownItem className={sortType === 'percent_down' ? 'Selected' : ''} onClick={() => handleChangeSortType('percent_down')}><i className="fa fa-sort-numeric-desc" aria-hidden="true"></i> Percent sort down</DropdownItem>
                        {/* <DropdownItem onClick={() => handleChangeSortType('')}><i className="fa fa-ban" aria-hidden="true"/> Clear</DropdownItem> */}
                    </div>
                </DropdownMenu>
            </UncontrolledDropdown>
        </Tooltip>
    }

    const renderItems = () => {
        const {key, dir} = getSort()
        
        switch (sortType) {
            case 'quantity_up':
                return [...results].sort((a, b) => {
                    return a[key] - b[key]

                })
            case 'quantity_down':
                return [...results].sort((a, b) => {
                    return b[key] - a[key]
                })
            case 'percent_up':
                return [...results].sort((a, b) => {
                    const percenA = a.total_count ? Math.round((a[key] || 0) / a.total_count * 100, 1) : '0'
                    const percenB = b.total_count ? Math.round((b[key] || 0) / b.total_count * 100, 1) : '0'
                    return percenA - percenB
                })
            case 'percent_down':
                return [...results].sort((a, b) => {
                    const percenA = a.total_count ? Math.round((a[key] || 0) / a.total_count * 100, 1) : '0'
                    const percenB = b.total_count ? Math.round((b[key] || 0) / b.total_count * 100, 1) : '0'
                    return percenB - percenA
                })
            default:
                return results
        }
    }
    const getHeaderClasses = (field) => {
        const { key } = getSort()

        return [
            'font-weight-bold position-relative SortField text-right', key === field && 'hasSorted',
        ].filter(Boolean).join(' ')
    }

    return (
        <div className={[`ReportShipStatusByStore`, loading && 'loading'].filter(Boolean).join(' ')}>
            <div className="ListTopBar pt-4 d-flex justify-content-between" id="Filter"
                 style={{top: `${tabsStaticsHeight}px`}}>
                <h3>
                    Shipment Status Report by Store
                </h3>
                <div className="Filters d-flex justify-content-center align-items-center">
                    <div className="Filter d-flex justify-content-center align-items-center">
                        <FilterDate
                            heading="Completed at:"
                            field="packaged_at"
                            value={filters.packaged_at || {}}
                            onDatesChange={_handleChangeDate}
                            className="mt-0 mr-1"
                        />
                        <ReloadOutlined className="Clear mr-3" onClick={clearFilters}/>
                    </div>
                    <div className="Action d-flex justify-content-center align-items-center">
                        <button className="SwitchButton btn btn-sm btn-export mr-2" onClick={doExport}>Export</button>
                        {/*<button className="SwitchButton btn btn-sm" onClick={setTab}>By Carrier</button>*/}
                        <Link to={'/a/analytics/daily-report/by-carrier'}><span
                            className="SwitchButton btn">By Carrier</span></Link>
                    </div>
                </div>
            </div>
            {
                error &&
                <div className="text-danger Error" style={{top: `${tabsStaticsHeight + FilterHeight}px`}}>{error}</div>
            }
            {
                !error &&
                <table className="table table-borderless ShipmentStatusByStoreTable">
                    <thead className="ListHeader" style={{top: `${tabsStaticsHeight + FilterHeight + ErrorHeight}px`}}>
                    <tr>
                        <th colSpan={conSpan} rowSpan={2} className="merge-total">
                            <WrapContent>Total</WrapContent>
                        </th>
                        <th className="text-right">
                            <WrapContent>
                                Unknown
                            </WrapContent>
                        </th>
                        <th className="text-right">
                            <WrapContent>
                                Exception</WrapContent>
                        </th>
                        <th className="text-right">
                            <WrapContent>
                                Out_for_delivery
                            </WrapContent>
                        </th>
                        <th className="text-right"><WrapContent>
                            Pre_transit</WrapContent></th>
                        <th className="text-right"><WrapContent>
                            In_transit</WrapContent></th>
                        <th className="text-right"><WrapContent>
                            Delivered</WrapContent></th>
                        <th className="text-right"><WrapContent>
                            Total</WrapContent></th>
                        <th className="text-right"><WrapContent>
                            Ship delay</WrapContent></th>
                        <th className="text-right">
                            <WrapContent>
                                <Select className="ListShipByDays" value={filters.shipByDay} onChange={updateShipByDay}>
                                    {
                                        shipByDays.map(v => {
                                            return <Select.Option key={v}
                                                value={v}>{maxShipDay === v ? `>${v}` : v}</Select.Option>
                                        })
                                    }
                                </Select>
                                &nbsp;
                                {filters.shipByDay > 1 ? 'BDs' : 'BD'}
                            </WrapContent>
                        </th>
                        <th className="text-right">
                            <WrapContent>
                                Avg time
                            </WrapContent>
                        </th>
                    </tr>
                    {!!results.length && (
                        <tr className="">
                            {
                                Object.keys(vDataColumns).map(colName => {
                                    const colValue = totalResults.total_count ? Math.max(Math.round((totalResults[colName] || 0) / totalResults.total_count * 100, 1), 0) : 0
                                    const isSorted = isSortingBy(colName)
                                    const clsName = getHeaderClasses(colName)

                                    return !['total_count'].includes(colName)
                                        ? <th className={clsName}
                                              key={`col-total-${colName}`}>
                                            <WrapContent>
                                                {totalResults[colName]}
                                                {'overtime_ship_count' !== colName && <span>({colValue}%)</span>}
                                            </WrapContent>
                                            {[`ship_${filters.shipByDay}_days_count`].includes(colName) &&                                      
                                                getSortIcon(colName)
                                            }
                                        </th>
                                        : <th key={`col-total-${colName}`}
                                              className={clsName}>
                                            <WrapContent>
                                                {totalResults[colName]}
                                            </WrapContent>
                                        </th>
                                })
                            }
                            {
                                ['avg_time'].map(colName => {
                                    const isSorted = isSortingBy(colName)
                                    const clsName = ['text-right font-weight-bold', isSorted && 'sorted'].filter(Boolean).join(' ')

                                    return <th className={clsName} key={colName}>
                                        <WrapContent>
                                            {Math.round(totalResults.avg_time * 100, 1) / 100}
                                        </WrapContent>
                                    </th>
                                })
                            }
                        </tr>
                    )}
                    </thead>
                    <tbody>
                    {
                        !results.length && <tr>
                            <td colSpan={Object.keys(vDataColumns).length + 2} className="text-center">No data</td>
                        </tr>
                    }
                    {
                        !!results.length && renderItems(results).map((item, itemIndex) => {
                            const {store} = item

                            return <tr key={[store, itemIndex].join('-')}>
                                <td className=""><WrapContent>
                                    {/*<Link target={'_blank'}*/}
                                    {/*      to={getPermalinkWrap({*/}
                                    {/*          store,*/}
                                    {/*      })}>*/}
                                    {/*    {store}*/}
                                    {/*</Link>*/}
                                    <Link to={getLinkToCarrier(store)}>{store}</Link>
                                </WrapContent></td>
                                {
                                    Object.keys(vDataColumns).map(colName => {
                                        const key = `col-${store}-${itemIndex}-${colName}`
                                        const isSorted = isSortingBy(colName)
                                        const clsName = ['text-right', isSorted && 'sorted'].filter(Boolean).join(' ')

                                        return !['total_count'].includes(colName)
                                            ? <td key={key}
                                                  className={clsName}>
                                                <Link target={'_blank'}
                                                      to={getPermalinkWrap({
                                                          ...getFilterTrackingStatus(colName),
                                                          store,
                                                      })}>
                                                    {item[colName]}
                                                    {
                                                        'overtime_ship_count' !== colName &&
                                                        <span>({item.total_count ? Math.round((item[colName] || 0) / item.total_count * 100, 1) : '0'}%)</span>
                                                    }
                                                </Link>
                                            </td>
                                            : <td key={key}
                                                  className={clsName}>{item[colName]}</td>

                                    })
                                }
                                {
                                    ['avg_time'].map(colName => {
                                        const isSorted = isSortingBy(colName)
                                        const clsName = ['text-right', isSorted && 'sorted'].filter(Boolean).join(' ')

                                        return <td className={clsName} key={colName}>
                                            {Math.round(item.avg_time * 100) / 100}
                                        </td>
                                    })
                                }
                            </tr>
                        })
                    }
                    </tbody>
                    <tfoot>
                    <tr>
                        <td colSpan={11}>

                            <Pagination current={page} total={total} pageSize={limit} onChange={handlePageChange}
                                        className="text-right" showSizeChanger
                                        pageSizeOptions={['10', '20', '50', '100', '1000']}
                            />
                        </td>
                    </tr>
                    </tfoot>
                </table>
            }
        </div>
    )
}

export default ReportShipStatusByStore
