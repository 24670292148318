import React, { useMemo, useState } from 'react'
import { DatePicker } from 'antd'
import moment from 'moment'

const { MonthPicker } = DatePicker

const TableStorePackage = (props) => {
    const { month1, month2, onChangeMonth2, storePackageReport } = props
    const [sortBy, setSortBy] = useState('prevPackageIssueTotal')
    const [time, setTime] = useState('previous')
    const [sortPrevPackageIssueTotal, setSortPrevPackageIssueTotal] = useState(true)
    const [sortPrevPackageTotal, setSortPrevPackageTotal] = useState(true)
    const [sortPrevPercent, setSortPrevPercent] = useState(true)
    const [sortTargetPackageIssueTotal, setSortTargetPackageIssueTotal] = useState(true)
    const [sortTargetPackageTotal, setSortTargetPackageTotal] = useState(true)
    const [sortTargetPercent, setSortTargetPercent] = useState(true)

    const { groupByStore = {}, prevPackageIssueTotal = 0, prevPackageTotal = 0, targetPackageIssueTotal = 0, targetPackageTotal = 0 } = Object.assign({}, storePackageReport)
    const data = useMemo(() => {
        return Object.entries(groupByStore).map(([key, value]) => ({
            store: key,
            ...value
        }))
    }, [storePackageReport])

    const dataSorted = React.useMemo(() => {
        if (sortBy === 'prevPackageIssueTotal') {
            if (sortPrevPackageIssueTotal) {
                return data.sort((a, b) => a[time].packageIssueTotal - b[time].packageIssueTotal)
            } else {
                return data.sort((a, b) => b[time].packageIssueTotal - a[time].packageIssueTotal)
            }
        }

        if (sortBy === 'prevPackageTotal') {
            if (sortPrevPackageTotal) {
                return data.sort((a, b) => a[time].packageTotal - b[time].packageTotal)
            } else {
                return data.sort((a, b) => b[time].packageTotal - a[time].packageTotal)
            }
        }

        if (sortBy === 'prevPercent') {
            if (sortPrevPercent) {
                return data.sort((a, b) => {
                    const a1 = Number(((a[time].packageIssueTotal / (a[time].packageTotal || 0)) * 100))
                    const b1 = Number(((b[time].packageIssueTotal / (b[time].packageTotal || 0)) * 100))
                    return a1 - b1
                })
            } else {
                return data.sort((a, b) => {
                    const a1 = Number(((a[time].packageIssueTotal / (a[time].packageTotal || 0)) * 100))
                    const b1 = Number(((b[time].packageIssueTotal / (b[time].packageTotal || 0)) * 100))
                    return b1 - a1
                })
            }
        }

        if (sortBy === "targetPackageIssueTotal") {
            if (sortTargetPackageIssueTotal) {
                return data.sort((a, b) => a[time].packageIssueTotal - b[time].packageIssueTotal)
            } else {
                return data.sort((a, b) => b[time].packageIssueTotal - a[time].packageIssueTotal)
            }
        }

        if (sortBy === 'targetPackageTotal') {
            if (sortTargetPackageTotal) {
                return data.sort((a, b) => a[time].packageTotal - b[time].packageTotal)
            } else {
                return data.sort((a, b) => b[time].packageTotal - a[time].packageTotal)
            }
        }

        if (sortBy === 'targetPercent') {
            if (sortTargetPercent) {
                return data.sort((a, b) => {
                    const a1 = Number(((a[time].packageIssueTotal / (a[time].packageTotal || 0)) * 100))
                    const b1 = Number(((b[time].packageIssueTotal / (b[time].packageTotal || 0)) * 100))
                    return a1 - b1
                })
            } else {
                return data.sort((a, b) => {
                    const a1 = Number(((a[time].packageIssueTotal / (a[time].packageTotal || 0)) * 100))
                    const b1 = Number(((b[time].packageIssueTotal / (b[time].packageTotal || 0)) * 100))
                    return b1 - a1
                })
            }
        }

    }, [sortPrevPackageIssueTotal, sortPrevPackageTotal, sortPrevPercent, sortTargetPackageIssueTotal, sortTargetPackageTotal, sortTargetPercent, sortBy, data])

    const handleSortPackage = (field, sortField, setSort, time) => {
        setTime(time)
        setSortBy(field)
        setSort(!sortField)
    }

    const showPercentIssue = (number, total) => {
        if (total === 0) return 0

        const percent = ((+number / +total) * 100)
        return !!percent ? Number(percent.toFixed(2)) : 0
    }

    return (
        <div className="table PackageIssueStoreReportTable d-flex">
            <table className="table-left">
                <thead>
                    <tr>
                        <th rowSpan={3} className='col-store'>Store</th>
                        <th colSpan="3" className="text-center">
                            <MonthPicker
                                allowClear={false}
                                value={month1}
                                format="MMMM YYYY"
                                style={{ pointerEvents: 'none' }}
                                className='custom-month-issue-report-store'
                            />
                        </th>
                    </tr>
                    <tr>
                        <th>Total package issue</th>
                        <th>Total package</th>
                        <th>Package issue rate</th>
                    </tr>
                    <tr>
                        <th className='text-right'>
                            {prevPackageIssueTotal}
                            {
                                sortPrevPackageIssueTotal ?
                                    <i
                                        className={`fa fa-long-arrow-up cursor-pointer p-1 ${sortBy === "prevPackageIssueTotal" && "text-primary"}`}
                                        onClick={() => handleSortPackage('prevPackageIssueTotal', sortPrevPackageIssueTotal, setSortPrevPackageIssueTotal, 'previous')}
                                    /> :
                                    <i
                                        className={`fa fa-long-arrow-down cursor-pointer p-1 ${sortBy === "prevPackageIssueTotal" && "text-primary"}`}
                                        onClick={() => handleSortPackage('prevPackageIssueTotal', sortPrevPackageIssueTotal, setSortPrevPackageIssueTotal, 'previous')}
                                    />
                            }
                        </th>
                        <th className='text-right'>
                            {prevPackageTotal}
                            {
                                sortPrevPackageTotal ?
                                    <i
                                        className={`fa fa-long-arrow-up cursor-pointer p-1 ${sortBy === "prevPackageTotal" && "text-primary"}`}
                                        onClick={() => handleSortPackage('prevPackageTotal', sortPrevPackageTotal, setSortPrevPackageTotal, 'previous')}
                                    /> :
                                    <i
                                        className={`fa fa-long-arrow-down cursor-pointer p-1 ${sortBy === "prevPackageTotal" && "text-primary"}`}
                                        onClick={() => handleSortPackage('prevPackageTotal', sortPrevPackageTotal, setSortPrevPackageTotal, 'previous')}
                                    />
                            }
                        </th>
                        <th className='text-right'>
                            {`${showPercentIssue(prevPackageIssueTotal, prevPackageTotal)}%`}
                            {
                                sortPrevPercent ?
                                    <i
                                        className={`fa fa-long-arrow-up cursor-pointer p-1 ${sortBy === "prevPercent" && "text-primary"}`}
                                        onClick={() => handleSortPackage('prevPercent', sortPrevPercent, setSortPrevPercent, 'previous')}
                                    /> :
                                    <i
                                        className={`fa fa-long-arrow-down cursor-pointer p-1 ${sortBy === "prevPercent" && "text-primary"}`}
                                        onClick={() => handleSortPackage('prevPercent', sortPrevPercent, setSortPrevPercent, 'previous')}
                                    />
                            }
                        </th>
                    </tr>
                </thead>
                <tbody>
                    {dataSorted.map((row, index) => {
                        const { previous = {}, store = '' } = Object.assign({}, row)
                        const { packageIssueTotal = 0, packageTotal = 0 } = Object.assign({}, previous)
                        const isNotification = showPercentIssue(packageIssueTotal, packageTotal) > 2
                        return (
                            <tr key={index}>
                                <td className='col-store'>{store}</td>
                                <td className='text-right' style={{ backgroundColor: isNotification ? "#f7d9da" : 'transparent' }}>{packageIssueTotal}</td>
                                <td className='text-right' style={{ backgroundColor: isNotification ? "#f7d9da" : 'transparent' }}>{packageTotal}</td>
                                <td className='text-right' style={{ backgroundColor: isNotification ? "#f7d9da" : 'transparent' }}>{`${showPercentIssue(packageIssueTotal, packageTotal)}%`}</td>
                            </tr>
                        )
                    })}
                </tbody>
            </table>
            <table className="table-right">
                <thead>
                    <tr>
                        <th colSpan="3" className="text-center">
                            <MonthPicker
                                allowClear={false}
                                value={month2}
                                onChange={onChangeMonth2}
                                format="MMMM YYYY"
                                className='custom-month-issue-report-store'
                            />
                        </th>
                    </tr>
                    <tr>
                        <th>Total package issue</th>
                        <th>Total package</th>
                        <th>Package issue rate</th>
                    </tr>
                    <tr>
                        <th className='text-right'>
                            {targetPackageIssueTotal}
                            {
                                sortTargetPackageIssueTotal ?
                                    <i
                                        className={`fa fa-long-arrow-up cursor-pointer p-1 ${sortBy === "targetPackageIssueTotal" && "text-primary"}`}
                                        onClick={() => handleSortPackage('targetPackageIssueTotal', sortTargetPackageIssueTotal, setSortTargetPackageIssueTotal, 'target')}
                                    /> :
                                    <i
                                        className={`fa fa-long-arrow-down cursor-pointer p-1 ${sortBy === "targetPackageIssueTotal" && "text-primary"}`}
                                        onClick={() => handleSortPackage('targetPackageIssueTotal', sortTargetPackageIssueTotal, setSortTargetPackageIssueTotal, 'target')}
                                    />
                            }
                        </th>
                        <th className='text-right'>
                            {targetPackageTotal}
                            {
                                sortTargetPackageTotal ?
                                    <i
                                        className={`fa fa-long-arrow-up cursor-pointer p-1 ${sortBy === "targetPackageTotal" && "text-primary"}`}
                                        onClick={() => handleSortPackage('targetPackageTotal', sortTargetPackageTotal, setSortTargetPackageTotal, 'target')}
                                    /> :
                                    <i
                                        className={`fa fa-long-arrow-down cursor-pointer p-1 ${sortBy === "targetPackageTotal" && "text-primary"}`}
                                        onClick={() => handleSortPackage('targetPackageTotal', sortTargetPackageTotal, setSortTargetPackageTotal, 'target')}
                                    />
                            }
                        </th>
                        <th className='text-right'>
                            {`${showPercentIssue(targetPackageIssueTotal, targetPackageTotal)}%`}
                            {
                                sortTargetPercent ?
                                    <i
                                        className={`fa fa-long-arrow-up cursor-pointer p-1 ${sortBy === "targetPercent" && "text-primary"}`}
                                        onClick={() => handleSortPackage('targetPercent', sortTargetPercent, setSortTargetPercent, 'target')}
                                    /> :
                                    <i
                                        className={`fa fa-long-arrow-down cursor-pointer p-1 ${sortBy === "targetPercent" && "text-primary"}`}
                                        onClick={() => handleSortPackage('targetPercent', sortTargetPercent, setSortTargetPercent, 'target')}
                                    />
                            }
                        </th>
                    </tr>
                </thead>
                <tbody>
                    {dataSorted.map((row, index) => {
                        const { target = {} } = Object.assign({}, row)
                        const { packageIssueTotal = 0, packageTotal = 0 } = Object.assign({}, target)
                        const isNotification = showPercentIssue(packageIssueTotal, packageTotal) > 2
                        return (
                            <tr key={index}>
                                <td className='text-right' style={{ backgroundColor: isNotification ? "#f7d9da" : 'transparent' }}>{packageIssueTotal}</td>
                                <td className='text-right' style={{ backgroundColor: isNotification ? "#f7d9da" : 'transparent' }}>{packageTotal}</td>
                                <td className='text-right' style={{ backgroundColor: isNotification ? "#f7d9da" : 'transparent' }}>{`${showPercentIssue(packageIssueTotal, packageTotal)}%`}</td>
                            </tr>
                        )
                    })}
                </tbody>
            </table>

            <table className="table" style={{ display: 'none' }} id="Issue_Store_Package_Report">
                <thead style={{ display: 'none' }}>
                    <tr>
                        <th rowSpan={3} className='col-store'>Store</th>
                        <th colSpan="3" className="text-center">
                            {moment(month1).format('MMMM YYYY')}
                        </th>
                        <th colSpan="3" className="text-center">
                            {moment(month2).format('MMMM YYYY')}
                        </th>
                    </tr>
                    <tr>
                        <th>Total package issue</th>
                        <th>Total package</th>
                        <th>Package issue rate</th>
                        <th>Total package issue</th>
                        <th>Total package</th>
                        <th>Package issue rate</th>
                    </tr>
                    <tr>
                        <th className='text-right'>
                            {prevPackageIssueTotal}
                            {
                                sortPrevPackageIssueTotal ?
                                    <i
                                        className={`fa fa-long-arrow-up cursor-pointer p-1 ${sortBy === "prevPackageIssueTotal" && "text-primary"}`}
                                        onClick={() => handleSortPackage('prevPackageIssueTotal', sortPrevPackageIssueTotal, setSortPrevPackageIssueTotal, 'previous')}
                                    /> :
                                    <i
                                        className={`fa fa-long-arrow-down cursor-pointer p-1 ${sortBy === "prevPackageIssueTotal" && "text-primary"}`}
                                        onClick={() => handleSortPackage('prevPackageIssueTotal', sortPrevPackageIssueTotal, setSortPrevPackageIssueTotal, 'previous')}
                                    />
                            }
                        </th>
                        <th className='text-right'>
                            {prevPackageTotal}
                            {
                                sortPrevPackageTotal ?
                                    <i
                                        className={`fa fa-long-arrow-up cursor-pointer p-1 ${sortBy === "prevPackageTotal" && "text-primary"}`}
                                        onClick={() => handleSortPackage('prevPackageTotal', sortPrevPackageTotal, setSortPrevPackageTotal, 'previous')}
                                    /> :
                                    <i
                                        className={`fa fa-long-arrow-down cursor-pointer p-1 ${sortBy === "prevPackageTotal" && "text-primary"}`}
                                        onClick={() => handleSortPackage('prevPackageTotal', sortPrevPackageTotal, setSortPrevPackageTotal, 'previous')}
                                    />
                            }
                        </th>
                        <th className='text-right'>
                            {`${showPercentIssue(prevPackageIssueTotal, prevPackageTotal)}%`}
                            {
                                sortPrevPercent ?
                                    <i
                                        className={`fa fa-long-arrow-up cursor-pointer p-1 ${sortBy === "prevPercent" && "text-primary"}`}
                                        onClick={() => handleSortPackage('prevPercent', sortPrevPercent, setSortPrevPercent, 'previous')}
                                    /> :
                                    <i
                                        className={`fa fa-long-arrow-down cursor-pointer p-1 ${sortBy === "prevPercent" && "text-primary"}`}
                                        onClick={() => handleSortPackage('prevPercent', sortPrevPercent, setSortPrevPercent, 'previous')}
                                    />
                            }
                        </th>
                        <th className='text-right'>
                            {targetPackageIssueTotal}
                            {
                                sortTargetPackageIssueTotal ?
                                    <i
                                        className={`fa fa-long-arrow-up cursor-pointer p-1 ${sortBy === "targetPackageIssueTotal" && "text-primary"}`}
                                        onClick={() => handleSortPackage('targetPackageIssueTotal', sortTargetPackageIssueTotal, setSortTargetPackageIssueTotal, 'target')}
                                    /> :
                                    <i
                                        className={`fa fa-long-arrow-down cursor-pointer p-1 ${sortBy === "targetPackageIssueTotal" && "text-primary"}`}
                                        onClick={() => handleSortPackage('targetPackageIssueTotal', sortTargetPackageIssueTotal, setSortTargetPackageIssueTotal, 'target')}
                                    />
                            }
                        </th>
                        <th className='text-right'>
                            {targetPackageTotal}
                            {
                                sortTargetPackageTotal ?
                                    <i
                                        className={`fa fa-long-arrow-up cursor-pointer p-1 ${sortBy === "targetPackageTotal" && "text-primary"}`}
                                        onClick={() => handleSortPackage('targetPackageTotal', sortTargetPackageTotal, setSortTargetPackageTotal, 'target')}
                                    /> :
                                    <i
                                        className={`fa fa-long-arrow-down cursor-pointer p-1 ${sortBy === "targetPackageTotal" && "text-primary"}`}
                                        onClick={() => handleSortPackage('targetPackageTotal', sortTargetPackageTotal, setSortTargetPackageTotal, 'target')}
                                    />
                            }
                        </th>
                        <th className='text-right'>
                            {`${showPercentIssue(targetPackageIssueTotal, targetPackageTotal)}%`}
                            {
                                sortTargetPercent ?
                                    <i
                                        className={`fa fa-long-arrow-up cursor-pointer p-1 ${sortBy === "targetPercent" && "text-primary"}`}
                                        onClick={() => handleSortPackage('targetPercent', sortTargetPercent, setSortTargetPercent, 'target')}
                                    /> :
                                    <i
                                        className={`fa fa-long-arrow-down cursor-pointer p-1 ${sortBy === "targetPercent" && "text-primary"}`}
                                        onClick={() => handleSortPackage('targetPercent', sortTargetPercent, setSortTargetPercent, 'target')}
                                    />
                            }
                        </th>
                    </tr>
                </thead>
                <tbody style={{ display: 'none' }}>
                    {dataSorted.map((row, index) => {
                        const { previous = {}, target = {}, store = '' } = Object.assign({}, row)
                        const isNotificationPrev = showPercentIssue(previous.packageIssueTotal, previous.packageTotal) > 2
                        const isNotificationTarget = showPercentIssue(target.packageIssueTotal, target.packageTotal) > 2

                        return (
                            <tr key={index}>
                                <td className='col-store'>{store}</td>
                                <td className='text-right' style={{ backgroundColor: isNotificationPrev ? "#f7d9da" : 'transparent' }}>{previous.packageIssueTotal}</td>
                                <td className='text-right' style={{ backgroundColor: isNotificationPrev ? "#f7d9da" : 'transparent' }}>{previous.packageTotal}</td>
                                <td className='text-right' style={{ backgroundColor: isNotificationPrev ? "#f7d9da" : 'transparent' }}>{`${showPercentIssue(previous.packageIssueTotal, previous.packageTotal)}%`}</td>
                                <td className='text-right' style={{ backgroundColor: isNotificationTarget ? "#f7d9da" : 'transparent' }}>{target.packageIssueTotal}</td>
                                <td className='text-right' style={{ backgroundColor: isNotificationTarget ? "#f7d9da" : 'transparent' }}>{target.packageTotal}</td>
                                <td className='text-right' style={{ backgroundColor: isNotificationTarget ? "#f7d9da" : 'transparent' }}>{`${showPercentIssue(target.packageIssueTotal, target.packageTotal)}%`}</td>
                            </tr>
                        )
                    })}
                </tbody>
            </table>
        </div>
    )
}

export default TableStorePackage