import React, {Component} from 'react'
import ReturnPage from "../../shared/ReturnPage"
import ShippingPlanEditor from "./ShippingPlanEditor/ShippingPlanEditor"
import {deleteShippingPlan, getShippingPlanDetail, updateShippingPlan} from "../../../services/api/ShippingServices"
import getHistory from "../../../store/getHistory"


class ShippingPlanPage extends Component {
    state = {
        plan: {},
        loading: false,
        error: "",
    }

    componentDidMount() {
        this._fetchShippingPlanDetail()
    }


    _fetchShippingPlanDetail = async () => {
        try {
            const {planId} = this.props
            const {data, success, message} = await getShippingPlanDetail(planId)

            if (!success) {
                return this.setState({
                    error: message,
                })
            }

            return this.setState({
                plan: data,
                error: "",
            })
        } catch (e) {
            return this.setState({
                error: e.message,
            })
        }
    }

    _handleUpdatePlanState = (field, data) => {
        this.setState(({plan}) => {
            return {
                plan: {
                    ...plan,
                    [field]: data,
                },
            }
        })
    }

    _handleUpdatePlan = async () => {
        try {
            this.setState({
                loading: true,
            })

            const {planId} = this.props
            const {plan} = this.state

            const {success, message} = await updateShippingPlan(planId, {
                title: plan.title,
                slug: plan.slug,
            })

            if (!success) {
                return this.setState({
                    loading: false,
                    error: message,
                })
            }

            return this.setState({
                loading: false,
                error: "",
            })
        } catch (e) {
            return this.setState({
                error: e.message,
            })
        }
    }

    _hanleDeleteShippingPlan = async () => {
        try {
            const {planId} = this.props
            const {success, message} = await deleteShippingPlan(planId)

            if (!success) {
                return this.setState({
                    error: message,
                })
            }

            const history = getHistory()
            history.push("/a/shipping")
        } catch (e) {
            return this.setState({
                error: e.message,
            })
        }
    }

    render() {
        const {plan, loading} = this.state
        const {title, slug} = plan

        return (
            <div className="ShippingPlanPage">
                <ReturnPage url="/a/shipping" title="Shipping Plans"/>

                <div className="row">
                    <div className="col-md-9">
                        <ShippingPlanEditor title={title} slug={slug}
                                            _handleUpdatePlanState={this._handleUpdatePlanState}
                                            _handleUpdatePlan={this._handleUpdatePlan} loading={loading}
                                            _hanleDeleteShippingPlan={this._hanleDeleteShippingPlan}/>
                    </div>
                </div>

            </div>
        )
    }
}

export default ShippingPlanPage
