import React, { useContext, useEffect, useState } from 'react'
import { Button, Modal, Select, message } from "antd"
import { ListShippingCarrier } from "../../helpers/ListShippingCarrier"
import { getListSuppliersV2 } from "../../../../services/api/SuppliersServices"
import { ReactComponent as AttachIcon } from "../../../../statics/assets/images/attach.svg"
import { ReactComponent as DeleteIcon } from "../../../../statics/assets/images/trash.svg"
import { PlusOutlined, CloudUploadOutlined } from '@ant-design/icons'
import { importShippingCost, importShippingCostOrther } from "../../../../services/api/OrdersServices"
import ShippingPriceContext from "../../context/ShippingPriceContext"
import { CSVLink } from "react-csv"

const { Option } = Select

const ImportShippingPrice = (props) => {
    const { shippingCarriers, selectedCarrier, setShippingCarrier, fetchListShippingSupplier } = props
    const { loadShippingPrice, filters } = useContext(ShippingPriceContext)
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState('')
    const [isModalVisible, setIsModalVisible] = useState(false)
    const [files, setFiles] = useState('')
    const [supplierIP, setSupplierIP] = useState("1C")
    const [listSupplier, setListSupplier] = useState([])
    const {_id} = Object.assign({},listSupplier.find(i=> i.sku_prefix === supplierIP))

    //const [shippingCarrier, setShippingCarrier] = useState(selectedCarrier)

    // useEffect(() => {
    //     setShippingCarrier(selectedCarrier)
    // }, [selectedCarrier])
    const _showModal = () => {
        setIsModalVisible(true)
    }

    const _handleCancel = () => {
        setIsModalVisible(false)
        setFiles('')
    }

    const _handleChangeShippingCarrier = (value) => {
        setShippingCarrier(value)
    }

    const _handleChangeFile = (e) => {
        const { files } = e.target

        if (files[0].size > 5242880) {
            setError('File size is lager than 5M')
        } else {
            setError('')
            setFiles(files)
        }
    }

    const _onChangeFile = (e) => {
        e.target.value = ''
    }

    const _handleChangeSupplierImport = value => {
        setSupplierIP(value)
    }

    const _onDeleteFile = (index) => {
        const newFilter = Object.assign({}, files)
        delete newFilter[index]

        setFiles(newFilter)
    }

    const _fetchListSupplier = async () => {
        setError('')
        setListSupplier([])
        setLoading(true)

        try {
            const {success, data, message} = await getListSuppliersV2()
            if (!success) {
                setLoading(false)

                return setError(message)
            }

            setLoading(false)
            setListSupplier(data.suppliers)

        } catch (e) {
            setLoading(false)
            setError(e.message)
        }
    }

    useEffect(() => {
        _fetchListSupplier()
    },[])

    useEffect(() => {
        if(filters !== supplierIP) setSupplierIP(filters)
    },[filters])

    const _handleOk = async () => {
        setLoading(true)
        setError('')

        try {
            const formData = new FormData()

            formData.append('file', files[0])

            const importApi = () => {
                switch (supplierIP){
                    case "1C":
                        return importShippingCost(formData, _id, selectedCarrier)
                    case "DBUS":
                        return importShippingCost(formData, _id, "ups")
                    default:
                        return importShippingCostOrther(formData, _id)
                }
            }

            const response = await importApi()

            const { success, message: error } = response

            if (!success) {
                setLoading(false)

                return setError(error)
            }

            message.success('Import shipping cost success!')
            setLoading(false)
            _handleCancel()
            if(supplierIP === "1C" || supplierIP === "DBUS"){
                loadShippingPrice(selectedCarrier)
            }else{
                fetchListShippingSupplier()
            }

        } catch (e) {
            setLoading(false)
            setError(e.message)
        }

    }

    const keysFile = Object.keys(files)
    const FileTemplateData = [
        ["Country code", "0-100", "100-200", "200-300", "300-400", "400-500", "500-600", "600-700", "700-800"],
        ["DE", 4.87, 5.95, 7.03, 8.41, 9.48, 10.56, 11.64, 12.72]
    ]

    const FilteTemplateData_PF = [
        ["SKU supplier", "Shipping method", "Country", "First item fee", "Second item fee"],
        ["PRINTFORIA.BASIC-HOODIE-UNISEX.BLACK.S", "express", "US", "20", "2.99"],
    ]

    const FilteTemplateData_YC = [
        ["Product Title","SKU Supplier","Size", "Shipping carrier", "Country", "First item fee", "Second item fee"],
        ["", "3MOTS-S", "", "DHL Express", "US", "20.2", "4.9"]
    ]

    const FilteTemplateData_PLEU = [
        ["SKU Supplier", "Country", "First item fee", "Second item fee"],
        ["CW_JDAY_010", "US", "02", "10"]
    ]

    const FilteTemplateData_CW = [
        ["Supplier", "Product Title", "Size", "Color","SKU",  "SKU Supplier","Shipping method", "Shipping carrier", "First item fee", "Second item fee"],
        ["CW", "Canvas 1.5 inch Ngang ship US CW", "36X24", "L","CW_JDAY_010", "N0903001-2436", "XX", "YY", "2", "10"]
    ]

    const FilteTemplateData_CC = [
        ["Supplier", "Product Title", "Size", "Color","SKU", "SKU Supplier", "Shipping method", "Shipping carrier", "Country", "First item fee", "Second item fee", "Variant ID", "Product ID"],
        ["CC-API", "AM15OZ 15oz. Accent Mug", "2XL", "Black", "CC_AM15OZ_001", "", "", "", "US", "4.99", "1.5", "6242be7a5f6d42721736d882", "6242be7a5f6d4284f636d877"]
    ]

    const template_file = supplier => {
        switch (supplier) {
            case "1C":
            case "DBUS":
                return FileTemplateData
            case "PF":
                return FilteTemplateData_PF
            case "YC":
                return FilteTemplateData_YC
            case "PL EU":
                return FilteTemplateData_PLEU
            case "CW":
                return FilteTemplateData_CW
            default:
                return FilteTemplateData_CC
        }
    }

    const form_import = supplier => {
        switch (supplier) {
            case "1C":
                return (
                    <>
                        <label className="Label">Shipping carrier:</label>
                        <Select value={selectedCarrier} style={{ width: '100%' }}
                            onChange={_handleChangeShippingCarrier}>
                            {
                                !!shippingCarriers && shippingCarriers.length > 0 && shippingCarriers.map((item, index) => (
                                    <Option key={index} value={item.carrier}>{item.name}</Option>
                                ))
                            }
                        </Select>
                    </>
                )
                case "DBUS":
                    return (
                        <>
                            <label className="Label">Shipping carrier:</label>
                            <Select value="ups" style={{ width: '100%' }}
                            >
                                <Option value="ups">UPS</Option>
                            </Select>
                        </>
                    )
            default:
                return
        }
    }

    return (
        <div className='ImportShippingPrice'>
            <Button type="primary" size="large" icon={<CloudUploadOutlined />} onClick={_showModal}>
                Import shipping cost
            </Button>
            <Modal
                className="ImportShippingModal"
                title="Import shipping cost"
                visible={isModalVisible}
                onOk={_handleOk}
                onCancel={_handleCancel}
                footer={[
                    <Button key="back" onClick={_handleCancel}>
                        Cancel
                    </Button>,
                    <Button key="submit" disabled={files === ''} type="primary" loading={loading} onClick={_handleOk}>
                        Import
                    </Button>
                ]}
            >
                <div className="SupplierImport mb-3">
                    <label className="Label">Supplier:</label>
                    <Select value={supplierIP} style={{ width: '100%' }}
                        onChange={_handleChangeSupplierImport}
                    >
                        <Option value="1C">1C</Option>
                        <Option value="PF">PF</Option>
                        <Option value="CC">CC-API</Option>
                        <Option value="YC">YC</Option>
                        <Option value="CW">CW</Option>
                        <Option value="DBUS">DBUS</Option>
                        <Option value="PL EU">PLEU</Option>
                    </Select>
                </div>
                <div className="FormItemImport">
                    {form_import(supplierIP)}
                </div>

                <div className={`FormItemImport ${supplierIP === "1C" || supplierIP === "DBUS" ? "mt-3" : ""}`}>
                    <div className="d-flex align-items-center justify-content-between">
                        <div className="Template">
                            <label className="Label">File import:</label>
                            <CSVLink
                                data={template_file(supplierIP)}
                                filename={`shipping-cost-template-${supplierIP}.csv`}
                                className="btn btn-link"
                            >
                                Download template
                            </CSVLink>
                        </div>
                    </div>

                    <input
                        id={`shipping_cost_select_file`}
                        className="Hidden"
                        type="file"
                        accept='text/csv'
                        multiple={false}
                        onChange={_handleChangeFile}
                        onClick={_onChangeFile}
                    />
                    <label htmlFor={`shipping_cost_select_file`}
                        className='d-block mb-0 cursor-pointer SelectFile'>
                        <PlusOutlined />
                        Select file csv
                    </label>
                </div>

                <div className="ListFileInput">
                    <ul>
                        {
                            keysFile && keysFile.length > 0 && keysFile.map((item, index) => (
                                <li key={index}>
                                    <div className="Left">
                                        <AttachIcon />
                                        <span className="FileName">{files[item].name}</span>
                                    </div>
                                    <DeleteIcon className="Delete" onClick={() => _onDeleteFile(item)} />
                                </li>
                            ))
                        }
                    </ul>
                </div>
                {
                    !!error && error.length > 0 && <div className="text-danger">{error}</div>
                }
            </Modal>
        </div>
    )
}

export default ImportShippingPrice
