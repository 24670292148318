export default [{
    name: 'name',
    title: 'Name',
}, {
    name: 'status',
    title: 'Status'
}, {
    name: 'action',
    title: 'Action'
}]
