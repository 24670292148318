import React, {Component} from 'react'
import PropTypes from 'prop-types'
import ReactCountryFlag from "react-country-flag"
import Papa from "papaparse"

class AutoCompletedText extends Component {
    state = {
        message: '',
        key: '',
        matchedTexts: [],
        textSuggestUpdateVi:[],
        textSuggestUpdateEn:[],
        textSuggestAddressVi:[],
        textSuggestAddressEn:[]
    }

    componentDidMount() {
        this._fetchTextSuggestUpdateVi();
        this._fetchTextSuggestUpdateEn();
        this._fetchTextSuggestAddressVi();
        this._fetchTextSuggestAddressEn();
    }

    _fetchTextSuggestUpdateVi = () => {
        Papa.parse("https://docs.google.com/spreadsheets/d/16lITC1YThEEKn9pzdqxK4pVHY-otTS6jLrAU9z2kv4s/pub?gid=0&single=true&output=csv", {
            download: true,
            header: true,
            complete: (results) => {
                const dataSuggestUpdateVi = results.data
                const templateRequestUpdateVi = dataSuggestUpdateVi.map((item) => {
                    return item['Text']
                });
                this.setState({
                    textSuggestUpdateVi: templateRequestUpdateVi
                })
            },
        });
    }

    _fetchTextSuggestUpdateEn = () => {
        Papa.parse("https://docs.google.com/spreadsheets/d/16lITC1YThEEKn9pzdqxK4pVHY-otTS6jLrAU9z2kv4s/pub?gid=502176401&single=true&output=csv", {
            download: true,
            header: true,
            complete: (results) => {
                const dataSuggestUpdateEn = results.data
                const templateRequestUpdateEn = dataSuggestUpdateEn.map((item) => {
                    return item['Text']
                });
                this.setState({
                    textSuggestUpdateEn: templateRequestUpdateEn
                })
            },
        });
    }

    _fetchTextSuggestAddressVi = () => {
        Papa.parse("https://docs.google.com/spreadsheets/d/16lITC1YThEEKn9pzdqxK4pVHY-otTS6jLrAU9z2kv4s/pub?gid=1305751371&single=true&output=csv", {
            download: true,
            header: true,
            complete: (results) => {
                const dataSuggestAddressVi = results.data
                const templateRequestAddressVi = dataSuggestAddressVi.map((item) => {
                    return item['Text']
                });
                this.setState({
                    textSuggestAddressVi: templateRequestAddressVi
                })
            },
        });
    }

    _fetchTextSuggestAddressEn = () => {
        Papa.parse("https://docs.google.com/spreadsheets/d/16lITC1YThEEKn9pzdqxK4pVHY-otTS6jLrAU9z2kv4s/pub?gid=750487329&single=true&output=csv", {
            download: true,
            header: true,
            complete: (results) => {
                const dataSuggestAddressEn = results.data
                const templateRequestAddressEn = dataSuggestAddressEn.map((item) => {
                    return item['Text']
                });
                this.setState({
                    textSuggestAddressEn: templateRequestAddressEn
                })
            },
        });
    }

    _handleChangeText = (e) => {
        const {value} = e.target

        this.setState({
            key: value,
            message: value
        }, () => {
            const {onChange} = this.props

            this._findTextCompleted(value)

            if (typeof onChange === 'function') {
                onChange(value)
            }
        })
    }

    _findTextCompleted = (key) => {
        const {language, value} = this.props
        const {textSuggestUpdateVi, textSuggestUpdateEn, textSuggestAddressVi, textSuggestAddressEn} = this.state

        const arrayTemplate = value !== 'address' ? (language === 'vi' ? textSuggestUpdateVi : textSuggestUpdateEn) : (language === 'vi' ? textSuggestAddressVi : textSuggestAddressEn)
        const regex = new RegExp(key, 'g')
        const matchedTexts = arrayTemplate.filter(text => text.match(regex))

        this.setState({
            matchedTexts: matchedTexts
        })
    }

    _onSelectText = (text) => {
        this.setState({
            key: text,
            message: text
        }, () => {
            const {onChange} = this.props

            this.setState({
                matchedTexts: []
            })

            if (typeof onChange === 'function') {
                onChange(text)
            }
        })
    }

    render() {
        const {message, matchedTexts} = this.state
        const {label, parentClass, namespace, language} = this.props

        return (
            <div className={`AutoCompletedText ${parentClass}`}>
                <label htmlFor={parentClass}>
                    {label}
                    <span className="Language">
                         <span className="NameSpace">{namespace}</span>
                             <span className="Flag">
                                <ReactCountryFlag code={language === 'vi' ? 'VN' : 'GB'} svg/>
                             </span>
                    </span>
                </label>
                <textarea
                    value={message}
                    id={parentClass}
                    rows="5"
                    placeholder='Input text short..'
                    onChange={this._handleChangeText}
                />

                {
                    matchedTexts && matchedTexts.length > 0 && <div className="Results">
                        <ul>
                            {
                                matchedTexts.map((item, index) => (
                                    <li key={index} onClick={() => this._onSelectText(item)}>{item}</li>
                                ))
                            }
                        </ul>
                    </div>
                }
            </div>
        )
    }
}

AutoCompletedText.propTypes = {
    namespace: PropTypes.string,
    language: PropTypes.string,
    label: PropTypes.string,
    parentClass: PropTypes.string,
    onChange: PropTypes.func.isRequired
}

export default AutoCompletedText
