import React, {Component} from 'react'
import humanizeTime from "../../../../../helpers/time/humanizeTime"
import PropTypes from 'prop-types'

class WebhookSendTIme extends Component {
    render() {
        const {sendTime} = this.props
        const textTime = sendTime ? humanizeTime(sendTime) : '--'

        return (
            <td className="WebhookSendTIme">
                {textTime}
            </td>
        )
    }
}

WebhookSendTIme.propTypes = {
    sendTime: PropTypes.string.isRequired,
}

export default WebhookSendTIme
