import React, {Component} from 'react'
import PropTypes from 'prop-types'
import SettingInput from '../../setting/shared/SettingInput'

class S3 extends Component {
    _onChangeSettingInput = (key) => (value) => {
        this.props.onChange(key)(value)
    }

    render() {
        const {settings, loading, disableAll} = this.props

        return (
            <div className="WebhookUrl">
                <SettingInput
                    itemId="s3_artwork_access_key" disabled={loading || disableAll} required
                    label="s3_artwork_access_key" value={settings.s3_artwork_access_key}
                    onChange={this._onChangeSettingInput('s3_artwork_access_key')}/>
                <SettingInput
                    itemId="s3_artwork_secret_key" label="s3_artwork_secret_key"
                    value={settings.s3_artwork_secret_key} disabled={loading || disableAll} required
                    onChange={this._onChangeSettingInput('s3_artwork_secret_key')}/>
                <SettingInput
                    itemId="s3_artwork_endpoint" label="s3_artwork_endpoint"
                    value={settings.s3_artwork_endpoint} disabled={loading || disableAll} required
                    onChange={this._onChangeSettingInput('s3_artwork_endpoint')}/>
                <SettingInput
                    itemId="s3_artwork_bucket" label="s3_artwork_bucket"
                    value={settings.s3_artwork_bucket} disabled={loading || disableAll} required
                    onChange={this._onChangeSettingInput('s3_artwork_bucket')}/>
            </div>
        )
    }
}

S3.propTypes = {
    settings: PropTypes.object.isRequired,
    onChange: PropTypes.func.isRequired,
    loading: PropTypes.bool.isRequired,
}

export default S3
