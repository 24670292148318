import React from 'react'
import OrderPartnersPage from './OrderPartnersPage'
import DocTitleByStore from "../../dashboard/shared/DocTitleByStore"
import queryString from 'query-string'

const OrderPartnersContainer = (props) => {
    const {history} = props
    const {location} = history
    let params = {}

    if (location.search !== '') {
        const _params = queryString.parse(location.search)
        if (_params.package_status !== undefined) {
            const valuePackageStatus = _params.package_status
            delete _params.package_status
            _params.package_status = valuePackageStatus.split(",")
        }

        if (_params['packaged_at.from'] !== undefined && _params['packaged_at.to'] !== undefined) {
            const valuePackageAtFrom = _params['packaged_at.from']
            const valuePackageAtTo = _params['packaged_at.to']
            delete _params.packaged_at
            _params.packaged_at = {from: valuePackageAtFrom, to: valuePackageAtTo}
        }

        if (_params['pushed_at.from'] !== undefined && _params['pushed_at.to'] !== undefined) {
            _params.pushed_at = {from: _params['pushed_at.from'], to: _params['pushed_at.to']}
        }

        if (_params.tracking_status !== undefined) {
            const valuePackageStatus = _params.tracking_status
            delete _params.tracking_status
            _params.tracking_status = valuePackageStatus.split(",")
        }

        if (_params.namespaces !== undefined) {
            _params.namespaces = `${_params.namespaces}`.split(",").map(s => s.trim()).filter(Boolean)

            if (!_params.namespaces.length) {
                delete _params.namespaces
            }
        }
        if (_params.should_noticed) {
            _params.did_actions = [_params.should_noticed]
            delete _params.should_noticed
        }

        if(_params.nin_did_actions !== undefined) {
            _params.nin_did_actions = [_params.nin_did_actions]
        }

        params = Object.assign(_params, _params.suppliers !== undefined ? {suppliers: [...`${_params.suppliers}`.split(/\s*,\s*/)]} : {})
    }

    return (
        <section className="NotificationContainer">
            <DocTitleByStore title="Package Management"/>
            <OrderPartnersPage params={params}/>
        </section>
    )
}

export default OrderPartnersContainer
