import React, {Component} from 'react'
import PropTypes from 'prop-types'
import ShipmentStatus from "../../../../shared/ShipmentStatus"

class OrderTracking extends Component {
    render() {
        const {trackingStatus, shipment_statuses} = this.props
        let outputArtwork = ""

        switch (trackingStatus) {
            case "completed":
                outputArtwork = <i className="fas fa-check text-success" title="Completed"/>
                break
            case "missing":
                outputArtwork = <i className="fas fa-times text-danger" title="Missing"/>
                break
            case "incomplete":
                outputArtwork = <i className="fas fa-exclamation-triangle text-warning" title="Incomplete"/>
                break
            default:
                outputArtwork = <i className="fas fa-times text-danger" title="Missing"/>
        }

        return (
            <td className="OrderArtwork text-center">
                <div>{outputArtwork}</div>
                {
                    shipment_statuses.length > 0 && <div>
                        <ShipmentStatus status={shipment_statuses}/>
                    </div>
                }
            </td>
        )
    }
}

OrderTracking.propTypes = {
    trackingStatus: PropTypes.string,
    shipment_statuses: PropTypes.array,
}

export default OrderTracking
