import React, { useRef, useState } from 'react'
import moment from 'moment'
import { Tag } from 'antd'
import { UncontrolledTooltip } from 'reactstrap'

function RolesRow(props) {
    const { item, openEditModal } = props
    const { permissions } = { ...item }
    const [showAllRole, setShowAllRole] = useState(false)
    const showallonclick = () => {
        setShowAllRole(!showAllRole)
    }
    const roleTags = useRef()
    return (
        <tr>
            <td>{item.name || '-'}</td>
            <td style={{ width: '50%' }}>
                <div className={showAllRole === false ? "HiddenCountry" : ""}>
                    <div ref={roleTags}>
                        {
                            !!permissions && Object.keys(permissions).length > 0 ? Object.keys(permissions).map((item, index) => (
                                <Tag key={`${item}_${index}`} className="mr-2 mb-2"> {item} </Tag>
                            )) : ""
                        }
                    </div>
                </div>
                {
                    !!roleTags.current ? (
                        (roleTags.current.offsetHeight > 90 && showAllRole === false) ?
                            <a className="float-right mr-3 text-primary">
                                <small className="float-right mr-3" onClick={showallonclick}>More...</small>
                            </a> :
                            (roleTags.current.offsetHeight > 90 && showAllRole === true) ?
                                <a className="float-right mr-3 text-primary">
                                    <small className="float-right mr-3" onClick={showallonclick}>Less...</small>
                                </a> : ""
                    ) : ""
                }
            </td>
            <td style={{ minWidth: 125 }}>{item.created_at ? moment(item.created_at).format('HH:MM DD/MM/YYYY') : '-'}</td>
            <td>
                <div className='btn-group'>
                    <div style={{ paddingRight: 10 }}>
                        <span className='text-primary cursor-pointer' href="#" id={`edit_role_${item._id}`}
                            onClick={() => openEditModal(item._id)}
                        >
                            <i className={'ti-pencil-alt'} />
                        </span>
                        <UncontrolledTooltip placement="right" target={`edit_role_${item._id}`}>
                            Edit role
                        </UncontrolledTooltip>
                    </div>
                </div>
            </td>
        </tr>
    )
}

export default RolesRow