import React, {Component} from 'react'
import OrderJobTable from "./OrderJobTable"

class OrderJobContainer extends Component {
    render() {
        return (
            <div className="OrderJobContainer">
                <div className="SectionInner">
                    <h3 className="SectionHeading mb-3">Webhook Jobs</h3>

                    <OrderJobTable {...this.props}/>
                </div>
            </div>
        )
    }
}

export default OrderJobContainer
