import React from 'react'
import {Spin,Modal, Typography} from 'antd';
import {DeleteOutlined} from '@ant-design/icons'
import {Droppable} from 'react-beautiful-dnd'
import {useOrderFulfillment} from '../store'
import classnames from 'classnames'

import FulfillmentPackageItem from './FulfillmentPackageItem'
import FulfillmentPackageSupplier from './FulfillmentPackageSupplier'
import FulfillmentPackageEmptyItems from './FulfillmentPackageEmptyItems'
import FulfillmentPackageStatus from './FulfillmentPackageStatus'

const {Title} = Typography

/**
 * @param {Object} props
 * @param {Object|FFMPackage} props.pack
 * @param {String} props.packageName
 * @returns {JSX.Element}
 */
export default (props) => {
    const {pack, packageName} = props
    const {state, dispatch} = useOrderFulfillment()

    const removePackage = (pack) => {
        if (pack.items.length) {
            return Modal.error({
                className: 'split-package__modal',
                title: 'Error!',
                content: 'You can only delete empty packages.',
            })
        }

        dispatch({type: 'REMOVE_PACKAGE', payload: pack})
    }

    const getListItems = () => {
        if (!pack.items.length) {
            return <FulfillmentPackageEmptyItems />
        }

        return pack.items.map((item,index) => (
            <FulfillmentPackageItem pack={pack} orderItem={item} index={index} key={item._id} />
        ))
    }

    const isShowLoading = () => {
        return pack.isProcessing() || pack.isError()
    }

    const loadingText = () => {
        if (pack.isError()) {

            return (
                <>
                    <div className="mb-2">{pack.message}</div>

                    <ul>
                        {pack.errors.map((msg, index2) => (<li key={index2}>{msg}</li>))}
                    </ul>
                </>
            )
        }

        return pack.allowFulfill()  ? 'Processing...' : 'Skip mark processing'
    }

    const bodyClassName = () => classnames({
        'split-package__body': true,
        'split-package__body-skip': !pack.allowFulfill(),
        [`split-package__body-${pack.status}`]: true,
    })

    return (
        <Droppable droppableId={pack._id} index={pack._id}>
            {(provided, snapshot) => (
                <div
                    {...provided.droppableProps}
                    ref={provided.innerRef}
                    className={classnames({
                        'is-dragging-over': snapshot.isDraggingOver,
                    })}
                >
                    <div className="split-package__item">
                        <div className="fulfill-pack__header">
                            <div className="split-package__header">
                                <Title className="split-package__header-name" level={3}>{packageName}</Title>

                                <FulfillmentPackageStatus pack={pack} />

                                <FulfillmentPackageSupplier pack={pack} />

                                <DeleteOutlined
                                    onClick={() => removePackage(pack)}
                                    disabled={pack.isProcessing() || pack.isFulfilled() || state.isProcessing || state.isProcessed}
                                    className={classnames({
                                        'split-package__btn-remove': true,
                                        'split-package__btn-remove--is-disabled': !pack.allowDelete(),
                                    })}
                                />
                            </div>

                        </div>
                        <div className={bodyClassName()}>
                            <Spin
                                spinning={isShowLoading()}
                                tip={loadingText()}
                                size="large"
                            >
                                {getListItems()}
                            </Spin>
                        </div>
                    </div>
                    {provided.placeholder}
                </div>
            )}
        </Droppable>
    )
}
