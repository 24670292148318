import React, {Component} from 'react'
import {
    getAllPlatform,
    deactivatePlatform,
    activatePlatform,
    editPlatform
} from '../../../services/api/PlatformsServices'
import PlatformsTable from './PlatformsTable'
import PlatformEditModal from './PlatformEditModal'
import NewPlatformModal from './NewPlatformModal'
import WebhookModal from "./settings/WebhookModal";
import { getLocalData } from '../../../services/StorageServices'

class PlatformsPage extends Component {
    state = {
        fetchQuery: {
            limit: 1000,
            page: 1,
        },
        platforms: {
            entity: [],
            total: 0,
        },
        loading: {
            fetchPlatforms: false,
            editPlatform: false,
            changeStatus: false,
        },
        err: {
            fetchPlatforms: null,
            changeStatus: null,
        },
        modal: {
            editPlatform: {
                open: false,
                platform: null,
            },
            addNewPlatform: {
                open: false,
            },
            editWebhook: {
                open: false,
                platformId: null
            }
        }
    }

    componentDidMount() {
        return this._fetchPlatforms()
    }

    _onClickAddNewPlatform = () => {
        this.setState(({modal}) => ({
            modal: {
                ...modal,
                addNewPlatform: {
                    open: true,
                }
            }
        }))
    }

    _onCloseNewPlatformModal = () => {
        this.setState(({modal}) => ({
            modal: {
                ...modal,
                addNewPlatform: {
                    open: false,
                }
            }
        }))
    }

    _onClickEditWebhook = (platformId) => {
        console.log(platformId)
        this.setState(({modal}) => ({
            modal: {
                ...modal,
                editWebhook: {
                    open: true,
                    platformId
                }
            }
        }))
    }

    _onCloseEditWebhook = () => {
        this.setState(({modal}) => ({
            modal: {
                ...modal,
                editWebhook: {
                    open: false,
                    platformId: null
                }
            }
        }))
    }

    _onOpenEditPlatform = (platform) => {
        this.setState(({modal}) => ({
            modal: {
                ...modal,
                editPlatform: {
                    open: true,
                    platform: {...platform},
                }
            }
        }))
    }

    _onClosePlatformEditModal = () => {
        this.setState(({modal}) => ({
            modal: {
                ...modal,
                editPlatform: {
                    open: false,
                    platform: null,
                }
            }
        }))
    }

    _onEditPlatform = (key, value) => {
        this.setState(({modal}) => ({
            modal: {
                ...modal,
                editPlatform: {
                    ...modal.editPlatform,
                    platform: {
                        ...modal.editPlatform.platform,
                        [key]: value,
                    },
                }
            }
        }))
    }

    _onSubmitEditPlatform = () => {
        this._submitEditForm()
    }

    _getEditedPlatform = (editedPlatform) => {
        const {entity} = this.state.platforms

        return entity.map((item) => {
            if (item._id === editedPlatform._id) return {
                ...item,
                ...editedPlatform
            }
            return item
        })
    }

    _addCreatedPlatform = (platform) => {
        this.setState(({platforms, modal}) => ({
            platforms: {
                ...platforms,
                entity: [...platforms.entity, platform],
                total: platforms.total + 1,
            },
            modal: {
                ...modal,
                addNewPlatform: {open: false}
            }
        }))
    }

    _submitEditForm = async () => {
        try {
            this.setState(({loading, err}) => ({
                loading: {
                    ...loading,
                    editPlatform: true,
                },
                err: {
                    ...err,
                    editPlatform: null,
                }
            }))
            const {modal} = this.state

            const {
                success,
                data
            } = await editPlatform(modal.editPlatform.platform._id, {...modal.editPlatform.platform})
            const editedPlatforms = this._getEditedPlatform(data)
            if (success) {
                this.setState(({loading, platforms, modal: _modal}) => ({
                    loading: {
                        ...loading,
                        editPlatform: false,
                    },
                    platforms: {
                        ...platforms,
                        entity: editedPlatforms
                    },
                    modal: {
                        ..._modal,
                        editPlatform: {
                            open: false,
                            platform: null,
                        },
                    }
                }))
            }
        } catch (e) {
            this.setState(({loading, err}) => ({
                loading: {
                    ...loading,
                    editPlatform: false,
                },
                err: {
                    ...err,
                    editPlatform: e.message || e,
                }
            }))
        }
    }

    _changePlatformStatus = async (platform, status) => {
        try {
            this.setState(({loading, err}) => ({
                loading: {
                    ...loading,
                    changeStatus: true,
                },
                err: {
                    ...err,
                    changeStatus: null,
                }
            }))

            const request = (status === 'active') ? activatePlatform(platform._id) : deactivatePlatform(platform._id)
            const {success, data} = await request

            const updatedPlatforms = this.state.platforms.entity.map((item) => {
                if (item._id === platform._id && success && data) return {
                    ...item,
                    status,
                }

                return item
            })

            this.setState(({loading, platforms}) => ({
                loading: {
                    ...loading,
                    changeStatus: false,
                },
                platforms: {
                    ...platforms,
                    entity: updatedPlatforms,
                }
            }))
        } catch (e) {
            this.setState(({loading, err}) => ({
                loading: {
                    ...loading,
                    changeStatus: false,
                },
                err: {
                    ...err,
                    changeStatus: e.message || e,
                }
            }))
        }
    }

    _fetchPlatforms = async () => {
        try {
            const {limit, page} = this.state.fetchQuery
            this.setState(({loading, err}) => ({
                loading: {
                    ...loading,
                    fetchPlatforms: true,
                },
                err: {
                    ...err,
                    fetchPlatforms: null,
                }
            }))

            const {success, data} = await getAllPlatform({limit, page})

            if (success) return this.setState(({loading, platforms}) => ({
                loading: {
                    ...loading,
                    fetchPlatforms: false,
                },
                platforms: {
                    ...platforms,
                    entity: data.platforms,
                    total: data.total,
                }
            }))

        } catch (e) {
            this.setState(({loading, err}) => ({
                loading: {
                    ...loading,
                    fetchPlatforms: false,
                },
                err: {
                    ...err,
                    fetchPlatforms: e.message || e
                }
            }))
        }
    }

    render() {
        const {platforms, loading} = this.state
        const {editPlatform: _editPlatform, addNewPlatform, editWebhook} = this.state.modal
        const permissionsData = !!getLocalData('permissions') ? Object.keys(getLocalData('permissions')) : []

        return (
            <div className="PlatformsPage">
                <h2>Platforms</h2>
                <div className="SectionInner">
                    {permissionsData.includes('ffm_platform_create') &&
                        <div className="TopTableButtonGroup">
                            <button
                                className="btn Button"
                                onClick={this._onClickAddNewPlatform}>
                                Add new platform
                            </button>
                        </div>
                    }

                    <div className="TableWrapper">
                        <PlatformsTable
                            changeStatus={this._changePlatformStatus}
                            loading={loading}
                            platforms={platforms.entity}
                            onOpenEditPlatform={this._onOpenEditPlatform}
                            onOpenEditWebhook={this._onClickEditWebhook}
                            permissionsData={permissionsData}
                            {...this.props}/>
                    </div>
                </div>

                <NewPlatformModal
                    open={addNewPlatform.open}
                    addNewPlatform={this._addCreatedPlatform}
                    onClose={this._onCloseNewPlatformModal}
                />

                <PlatformEditModal
                    open={_editPlatform.open}
                    platform={_editPlatform.platform}
                    loading={loading.editPlatform}
                    onClose={this._onClosePlatformEditModal}
                    onOk={this._onSubmitEditPlatform}
                    onEditPlatform={this._onEditPlatform}
                />

                <WebhookModal
                    platformId={editWebhook.platformId}
                    visible={editWebhook.open}
                    onClose={this._onCloseEditWebhook}
                />
            </div>
        )
    }
}

export default PlatformsPage
