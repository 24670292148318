import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { Button, Input, Select, message, Spin } from 'antd'
import { getListSuppliersV2 } from '../../../services/api/SuppliersServices'
import { searchProducts } from '../../../services/api/ProductsServices'
import { getListCountries } from '../../../services/api/ShippingServices'
import {
    getFastProductionRuleDetails,
    createFastProductionRule,
    updateFastProductionRule,
} from '../../../services/api/FastProductionRulesServices'
import getHistory from '../../../store/getHistory'
import { getLocalData } from '../../../services/StorageServices'

function CreateFastProductionRulePage(props) {
    const { id } = { ...props }
    const { Option } = Select
    const permissionsData = !!getLocalData('permissions') ? Object.keys(getLocalData('permissions')) : []
    const [loading, setLoading] = useState(false)
    const [priority, setPriority] = useState(null)
    const [store, setStore] = useState([])
    const [searchStore, setSearchStore] = useState('')
    const [productType, setProductType] = useState([])
    const [searchProductType, setSearchProductType] = useState('')
    const [country, setCountry] = useState([])
    const [searchCountry, setSearchCountry] = useState('')
    const [supplier, setSupplier] = useState('')
    const [listSupplier, setListSupplier] = useState([])
    const [listProducts, setListProducts] = useState([])
    const [listCountry, setListCountry] = useState([])
    const [error, setError] = useState('')
    const [errorValidate, setErrorValidate] = useState({})

    const getRuleDetails = async (id) => {
        setLoading(true)
        setError('')
        try {
            const { success, data, message: mess } = await getFastProductionRuleDetails(id)
            if (!success) {
                throw new Error(mess)
            }
            setPriority(data.priority)
            setStore(data.store && data.store.length === 0 ? ['All'] : (data.store || []))
            setProductType(data.product_types && data.product_types.length === 0 ? ['All'] : (data.product_types || []))
            setCountry(data.country)
            setSupplier(data.supplier)
        } catch (error) {
            message.error(error.message || 'Unknown error')
            setError(error.message || 'Unknown error')
        } finally {
            setLoading(false)
        }
    }

    const getListSupplier = async () => {
        try {
            const { data, success, message: mess } = await getListSuppliersV2()
            if (!success) {
                throw new Error(mess)
            }
            setListSupplier(data.suppliers || [])
        } catch (error) {
            message.error(error.message || 'Unknown error')
        }
    }

    const getListProduct = async () => {
        try {
            const { data, success, message: mess } = await searchProducts({ limit: 10000 })
            if (!success) {
                throw new Error(mess)
            }
            const newListProducts = data.products.map(option => ({ label: option.type, value: option._id })).filter(i => !!i && i.label)
            setListProducts(newListProducts)
        } catch (error) {
            message.error(error.message || 'Unknown error')
        }
    }

    const getListCountry = async () => {
        try {
            const { data, success, message: mess } = await getListCountries("")
            if (!success) {
                throw new Error(mess)
            }
            const newCountryList = data.length > 0 && data.map(item => {
                const { country } = item
                return {
                    id: country._id,
                    label: country.title,
                    value: country.code
                }
            })
            setListCountry(newCountryList)
        } catch (error) {
            message.error(error.message || 'Unknown error')
        }
    }
    const handleChangePriority = (e) => {
        delete errorValidate.priority
        const value = parseInt(e.target.value)
        if (value || value === 0) {
            if(typeof value !== "number") {
                setErrorValidate({...errorValidate, priority: 'Priority must be a number'})
                setPriority(null)
            }
            if (value <= 0) {
                setErrorValidate({ ...errorValidate, priority: 'Minimum value is 1' })
                setPriority(null)
            } else {
                setPriority(value)
            }
        } else {
            setPriority(null)
        }
    }

    const handleChangeTag = (field, value) => {
        if (field === 'Store') {
            setSearchStore('')
            setStore(value)
        }
        if (field === 'ProductType') {
            setSearchProductType('')
            setProductType(value)
        }
        if (field === 'Country') {
            setSearchCountry('')
            setCountry(value)
        }
    }

    const handleSearch = (field, value) => {
        if (value && (value.includes(',') || value.includes(' '))) {
            if (field === 'Store') {
                const newValue = [...new Set([...value.replace(/ /g, ',').split(',').map(i => i.trim()).filter(i => i), ...store])]
                setStore(newValue)
                setSearchStore('')
            }
            if (field === 'ProductType') {
                const newValue = [...new Set([...value.replace(/ /g, ',').split(',').map(i => i.trim()).filter(i => i), ...productType])]
                setProductType(newValue)
                setSearchProductType('')
            }
            if (field === 'Country') {
                const newValue = [...new Set([...value.replace(/ /g, ',').split(',').map(i => i.trim()).filter(i => i), ...country])]
                setCountry(newValue)
                setSearchCountry('')
            }
        } else {
            if (field === 'Store') {
                return setSearchStore(value)
            }
            if (field === 'ProductType') {
                return setSearchProductType(value)
            }
            if (field === 'Country') {
                return setSearchCountry(value)
            }
        }
    }

    const handleChangeSupplier = (value) => {
        setSupplier(value)
    }

    const validateData = () => {
        setErrorValidate({})
        const errValidate = {}
        if (!priority) errValidate.priority = 'Priority is require'
        if (!store || store.length === 0) errValidate.store = 'Store is require'
        if (!productType || productType.length === 0) errValidate.productType = 'Product Type is require'
        if (!supplier) errValidate.supplier = 'Supplier is require'

        if (Object.keys(errValidate).length) {
            setErrorValidate(errValidate)
            return false
        } else {
            return true
        }
    }

    const onCreateRule = async (payload = {}) => {
        setLoading(true)
        setError('')
        try {
            const { success, data, message: mess } = await createFastProductionRule(payload)
            if (!success) {
                throw new Error(mess)
            }
            message.success('Fast Production Rule created!')
            const history = getHistory()
            const location = {
                pathname: `/a/settings/fast-production/${data._id}`,
            }

            history.push(location)
        } catch (error) {
            message.error(error.message || 'Unknown error')
            setError(error.message || 'Unknown error')
        } finally {
            setLoading(false)
        }
    }

    const onUpdateRule = async (id, payload = {}) => {
        setLoading(true)
        setError('')
        try {
            const { success, data, message: mess } = await updateFastProductionRule(id, payload)
            if (!success) {
                throw new Error(mess)
            }
            message.success('Fast Production Rule updated!')
        } catch (error) {
            message.error(error.message || 'Unknown error')
            setError(error.message || 'Unknown error')
        } finally {
            setLoading(false)
        }
    }

    const handleSave = async () => {
        const payload = {
            priority: priority,
            store: store.length > 0 && store.find(i => i.toLowerCase() === 'all') ? '' : store,
            product_types: productType.length > 0 && productType.find(i => i.toLowerCase() === 'all') ? '' : productType,
            country: country,
            supplier: supplier,
        }

        if (!validateData()) return
        if (!id || id === 'create') onCreateRule(payload)
        if (!!id && id !== 'create') onUpdateRule(id, payload)
    }

    useEffect(() => {
        if (!!id && id !== 'create') {
            getRuleDetails(id)
        }
        // getListProduct()
        // getListCountry()
        getListSupplier()
    }, [id])

    const supplierOption = listSupplier.length > 0 && listSupplier.sort((a, b) => a.name.localeCompare(b.name)).map(item =>
        <Option key={item._id} value={item._id}>{item.name}</Option>
    )
    const productTypeOption = listProducts.length > 0 && listProducts.sort((a, b) => a.label.localeCompare(b.label)).map(option => (
        <Option key={option.value} value={option.label}>{option.label}</Option>
    ))
    const countryOption = listCountry.length > 0 && listCountry.sort((a, b) => a.label.localeCompare(b.label)).map((data, index) => (
        <Option key={index} value={data.value}> {data.label}</Option>
    ))

    const canCreate = permissionsData.includes('ffm_fast_production_rule_create')
    const canEdit = permissionsData.includes('ffm_fast_production_rule_update')

    return (
        <div className='CreateFastProductionRulePage'>
            <div className="container-fluid">
                <div className="BackWrapper">
                    <Link className="nav-link BackButton" to="/a/settings/fast-production">
                        <i className="fas fa-chevron-left" />
                        <span>List Fast Production Rule</span>
                    </Link>
                </div>
                <div className="MenuTitle d-flex justify-content-between align-items-center">
                    {!id || id === 'create' ? 'New Rule' : 'Update Rule'}
                    <div className="Actions d-flex align-items-center justify-content-end">
                        <Link className='ant-btn ant-btn-default px-4 rounded ml-3 btn-secondary' to='/a/settings/fast-production'>Cancel</Link>
                        {(canCreate || canEdit) &&
                            <Button className="ant-btn ant-btn-primary px-4 rounded ml-3" onClick={handleSave} loading={loading} type="primary">Save</Button>
                        }
                    </div>
                </div>
                <div className="SectionInner mt-3" style={{ maxWidth: '1000px' }}>
                    <div className="p-3">
                        <Spin spinning={loading} tip='Loading...'>
                            <div className="form">
                                <div className="form-item Priority mb-3">
                                    <label className='font-weight-bold'>Priority <span className="text-danger">*</span></label>
                                    <Input
                                        placeholder='Input Priority'
                                        className='w-100'
                                        value={!priority ? null : priority}
                                        onChange={handleChangePriority}
                                        type='number'
                                        allowClear
                                        min={1}
                                        disabled={!(canCreate || canEdit)}
                                    />
                                    {errorValidate.priority &&
                                        <small className='text-danger'>{errorValidate.priority}</small>
                                    }
                                </div>
                                <div className="form-item mb-3">
                                    <label className='font-weight-bold'>Store <span className="text-danger">*</span></label>
                                    <Select
                                        placeholder='Input Store'
                                        value={store || []}
                                        searchValue={searchStore}
                                        mode='tags'
                                        className='w-100'
                                        allowClear
                                        open={false}
                                        optionFilterProp="children"
                                        onChange={(e) => handleChangeTag('Store', e)}
                                        onSearch={(e) => handleSearch('Store', e)}
                                        disabled={!(canCreate || canEdit)}
                                    />
                                    {errorValidate.store &&
                                        <small className='text-danger'>{errorValidate.store}</small>
                                    }
                                </div>
                                <div className="form-item mb-3">
                                    <label className='font-weight-bold'>Product Type <span className="text-danger">*</span></label>
                                    <Select
                                        placeholder='Input Product Type'
                                        value={!id ? (productType || []) : (productType || ['All'])}
                                        searchValue={searchProductType}
                                        mode='tags'
                                        className='w-100'
                                        allowClear
                                        showSearch
                                        open={false}
                                        onChange={(e) => handleChangeTag('ProductType', e)}
                                        onSearch={(e) => handleSearch('ProductType', e)}
                                        disabled={!(canCreate || canEdit)}
                                    />
                                    {errorValidate.productType &&
                                        <small className='text-danger'>{errorValidate.productType}</small>
                                    }
                                </div>
                                <div className="form-item mb-3">
                                    <label className='font-weight-bold'>Country</label>
                                    <Select
                                        placeholder='Input Country'
                                        value={country || []}
                                        searchValue={searchCountry}
                                        mode='tags'
                                        className='w-100'
                                        allowClear
                                        optionFilterProp="children"
                                        showSearch
                                        open={false}
                                        onChange={(e) => handleChangeTag('Country', e)}
                                        onSearch={(e) => handleSearch('Country', e)}
                                        disabled={!(canCreate || canEdit)}
                                    />
                                    {errorValidate.country &&
                                        <small className='text-danger'>{errorValidate.country}</small>
                                    }
                                </div>
                                <div className="form-item mb-3">
                                    <label className='font-weight-bold'>Supplier <span className="text-danger">*</span></label>
                                    <Select
                                        placeholder='-- Choose Supplier --'
                                        value={supplier || []}
                                        className='w-100'
                                        allowClear
                                        filterOption={(input, option) =>
                                            option.children.toLocaleLowerCase().includes((input || "").toLocaleLowerCase())
                                        }
                                        showSearch
                                        onChange={handleChangeSupplier}
                                        disabled={!(canCreate || canEdit)}
                                    >
                                        {supplierOption}
                                    </Select>
                                    {errorValidate.supplier &&
                                        <small className='text-danger'>{errorValidate.supplier}</small>
                                    }
                                </div>
                            </div>
                        </Spin>
                        {error && <label className='text-danger'>{error}</label>}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default CreateFastProductionRulePage