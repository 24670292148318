import React from 'react'
import PropTypes from 'prop-types'
import {DatePicker} from 'antd'

const {RangePicker} = DatePicker

FilterDateRange.propTypes = {
    placeholder: PropTypes.array,
    dropdownClassName: PropTypes.string,
    onChangeDate: PropTypes.func.isRequired
}

function FilterDateRange(props) {
    const {onChangeDate, dropdownClassName, placeholder} = props

    function _handleChangeDate(value, dateString) {
        if (typeof onChangeDate == 'function') {
            onChangeDate(value)
        }
    }

    return (
        <div className='FilterDateRange PushAt'>
            <RangePicker
                dropdownClassName={dropdownClassName}
                id='pushAt'
                format="DD/MM/YYYY"
                placeholder={placeholder}
                onChange={_handleChangeDate}
            />
        </div>
    )
}

export default FilterDateRange
