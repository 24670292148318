import React, {useState} from 'react'
import QuickReportsLists from "./QuickReportsLists"

function QuickReportsPage() {
    const [isShow, toggleShow] = useState(false)

    const _handleToggleShow = () => {
        if(!isShow) {
            document.body.classList.add('quickFilters')
        } else {
            document.body.classList.remove('quickFilters')
        }

        toggleShow(!isShow)
    }

    return <div className="QuickReportsPage">
        <div className="IconFilters" onClick={_handleToggleShow}>
            <i className="ti-filter"/>
        </div>

        {isShow && <QuickReportsLists toggleShow={_handleToggleShow}/>}
    </div>
}

export default QuickReportsPage
