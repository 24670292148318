import React, {Component} from 'react'

class ShippingMethodsBaseCost extends Component {
    state = {
        baseCost: this.props.baseCost,
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.baseCost !== this.props.baseCost) {
            this.setState({
                baseCost: this.props.baseCost
            })
        }
    }

    _handleChangeInput = e => {
        const {value} = e.target
        const {index, _handleUpdatePlansState} = this.props

        this.setState({
            baseCost: value,
        })

        const baseCost = value > 0 ? parseFloat(value) : 0

        _handleUpdatePlansState(index, "first_rate", baseCost)
    }

    render() {
        const {baseCost} = this.state

        return (
            <td className="ShippingMethodsBaseCost">
                <input type="number" value={baseCost} className="form-control" step="any" min={0}
                       onChange={this._handleChangeInput} required/>
            </td>
        )
    }
}

export default ShippingMethodsBaseCost
