import React, {Component} from 'react'
import OrderItemsList from "./OrderItemsList"
import PropTypes from 'prop-types'

class OrderItemsContainer extends Component {
    render() {
        return (
            <tr className='OrderItemsContainer'>
                <td colSpan={13} className='pl-0 pr-0'><OrderItemsList {...this.props}/></td>
            </tr>
        )
    }
}

OrderItemsContainer.propTypes = {
    orderId: PropTypes.string.isRequired,
}

export default OrderItemsContainer
