import React, {Component} from 'react'
import {getListArtworks} from '../../../services/api/ArtworksServices'
import ArtworkTableInner from './ArtworkTableInner'
import {Pagination} from 'antd'

class ArtworksPage extends Component {
    state = {
        artworks: [],
        page: 1,
        limit: 10,
        total: 0,
        pages: 1,
        error: '',
        loading: false,
    }

    componentDidMount() {
        this._getArtworks()
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.state.page !== prevState.page || this.state.limit !== prevState.limit) {
            this._getArtworks()
        }
    }

    _getArtworks = async () => {
        const {page, limit} = this.state
        this.setState({loading: true})

        try {
            const {success, data, message} = await getListArtworks({page, limit})

            if (!success) throw new Error(message)

            this.setState({
                artworks: data.artworks,
                pages: data.pages,
                total: data.total,
                error: null,
                loading: false,
            })
        } catch (e) {
            this.setState({
                error: e.message,
                loading: false,
            })
        }
    }

    _renderArtworksTableContent = () => {
        const {artworks, error, loading, ...restState} = this.state

        if (loading) return (
            <p>Loading...</p>
        )

        if (error) return (
            <p className='text-warning'>{error}</p>
        )

        return (
            <ArtworkTableInner {...restState} artworks={artworks}/>
        )
    }

    _handleChangePage = current => {
        this.setState({
            page: current,
        }, this._getArtworks)
    }

    render() {
        const {page, total, limit} = this.state

        return (
            <div className='ArtworksPage'>
                <h1 className="PageTitle">Artworks</h1>
                <div className="SectionInner">
                    {this._renderArtworksTableContent()}

                    <Pagination current={page} total={total} pageSize={limit} onChange={this._handleChangePage}
                                className='text-right'/>
                </div>
            </div>
        )
    }
}

export default ArtworksPage
