import React, {Component} from 'react'
import PropTypes from 'prop-types'
import {Tooltip} from "antd"

class ToggleSafeZone extends Component {
    render() {
        const {offSafeZone, onToggleSafeZone} = this.props

        return (
            <div className='ToggleSafeZone Icon'>
                <div className='d-inline-block'>
                    <Tooltip title={`${offSafeZone ? 'Bật safeZone' : 'Tắt safeZone'}`} placement='bottom'>
                        <i className={`fa ${offSafeZone ? 'fa-toggle-off' : 'fa-toggle-on'}`}
                           onClick={onToggleSafeZone}/>
                    </Tooltip>
                </div>
            </div>
        )
    }
}

ToggleSafeZone.propTypes = {
    offSafeZone: PropTypes.bool,
    onToggleSafeZone: PropTypes.func.isRequired
}

export default ToggleSafeZone
