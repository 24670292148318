import React from 'react'
import ScanDetailPage from "./ScanDetailPage"
import {ScanDetailContextProvider} from "../context/ScanDetailContext"
import queryString from "query-string"

const ScanDetailContainer = (props) => {
    const {match, history} = props
    const {location} = history
    const {params} = match
    const {scanId} = params
    let statusCarrier = 'done'

    if (location.search !== '') {
        const _params = queryString.parse(location.search)

        if (_params.status !== undefined) {
            statusCarrier = _params.status
        }
    }

    return <div className="ScanDetailContainer">
        <ScanDetailContextProvider scanId={scanId}>
            <ScanDetailPage scanId={scanId} statusCarrier={statusCarrier}/>
        </ScanDetailContextProvider>
    </div>
}

export default ScanDetailContainer
