import createApiServices from "./createApiServices"

const prefixURL = process.env.NODE_ENV === 'production' ? '/api/product' : 'https://fulfillment-staging.merchize.com/api/product'
const api = createApiServices({baseUrl: prefixURL})
const devURL = window['API_PRODUCT_SERVICE_URL'] || ''

export const searchProducts = (data = {}) => {
    return api.makeAuthRequest({
        url: '/products/search',
        method: 'POST',
        data: data
    })
}

export const createProduct = (data = {}) => {
    return api.makeAuthRequest({
        url: '/products',
        method: 'POST',
        data: data
    })
}

export const dulicateProduct = (oldId, data = {}) => {
    return api.makeAuthRequest({
        url: `/products/${oldId}/duplicate`,
        method: 'POST',
        data: data
    })
}

export const getProductDetail = (id) => {
    return api.makeAuthRequest({
        url: `/products/${id}`,
        method: 'GET'
    })
}

export const updateProductCategory = (id, categoryId) => {
    return api.makeAuthRequest({
        url: `/products/${id}/category`,
        method: 'POST',
        data: {
            categoryId
        }
    })
}

export const updateProduct = (id, data) => {
    return api.makeAuthRequest({
        url: `/products/${id}`,
        method: 'POST',
        data: {
            ...data
        }
    })
}

export const deleteProduct = (id) => {
    return api.makeAuthRequest({
        url: `/products/${id}`,
        method: 'DELETE'
    })
}

export const getProductAttributes = (id) => {
    return api.makeAuthRequest({
        url: `/products/${id}/attributes`,
        method: 'GET'
    })
}

export const updateProductAttribute = (id, data) => {
    return api.makeAuthRequest({
        url: `/products/${id}/attributes`,
        method: 'POST',
        data: {
            attributes: data
        }
    })
}

export const updateProductAttributeV2 = (id, data) => {
    return api.makeAuthRequest({
        url: `/v2/products/${id}/attributes`,
        method: 'POST',
        data: data
    })
}

export const getProductVariants = (id, orderId) => {
    return api.makeAuthRequest({
        url: `${devURL}/products/${id}/variants` + (orderId ? `?order=${orderId}` : ''),
        method: "GET"
    })
}

export const updateProductVariants = (id, data) => {
    return api.makeAuthRequest({
        url: `${devURL}/products/${id}/variants`,
        method: "POST",
        data: {
            variants: data
        }
    })
}

export const updateProductVariantsSupplier = (id, supplier, data) => {
    return api.makeAuthRequest({
        url: `${devURL}/products/${id}/variants/${supplier}`,
        method: "POST",
        data: {
            variants: data
        }
    })
}

export const getListCategories = (data) => {
    return api.makeAuthRequest({
        url: `/categories/search`,
        method: "POST",
        data: data
    })
}

export const updateCategory = (id, data) => {
    return api.makeAuthRequest({
        url: `/categories/${id}`,
        method: "POST",
        data: data
    })
}

export const createCategory = (data) => {
    return api.makeAuthRequest({
        url: `/categories`,
        method: "POST",
        data: data
    })
}

export const deleteCategory = (id) => {
    return api.makeAuthRequest({
        url: `/categories/${id}`,
        method: "DELETE"
    })
}

export const detailCategory = (id) => {
    return api.makeAuthRequest({
        url: `/categories/${id}`,
        method: "GET"
    })
}

export const getProductImages = (id) => {
    return api.makeAuthRequest({
        url: `/products/${id}/images`,
        method: 'GET'
    })
}

export const createProductImage = (id, data) => {
    return api.makeAuthRequest({
        url: `/products/${id}/images`,
        method: 'POST',
        data
    })
}

export const deleteProductImage = (productId, imageId) => {
    return api.makeAuthRequest({
        url: `/products/${productId}/images/${imageId}`,
        method: 'DELETE'
    })
}

export const updateOrderItemMapping = (variantId, orderItemId, artworkdata, force_mapping = false) => {
    return api.makeAuthRequest({
        url: `/mapping/${orderItemId}`,
        method: "POST",
        data: {
            variant: variantId,
            artworks: artworkdata,
            force_mapping
        }
    })
}

export const removeOrderItemMapped = (orderItemId) => {
    return api.makeAuthRequest({
        url: `/mapping/${orderItemId}`,
        method: "DELETE"
    })
}

export const generateSKU = (productId) => {
    return api.makeAuthRequest({
        url: `/products/${productId}/variants/sku`,
        method: "POST",
    })
}

export const getListVariantForSupplier = (productId, supplierId) => {
    return api.makeAuthRequest({
        url: `/products/${productId}/variants/${supplierId}`,
        method: 'GET'
    })
}

export const cloneVariantForSupplier = (productId, data) => {
    return api.makeAuthRequest({
        url: `/products/${productId}/add-supplier`,
        method: 'POST',
        data: data
    })
}

export const changeStatus = (mappingId, data) => {
    return api.makeAuthRequest({
        url: `/mapping-rules/${mappingId}/update-status`,
        method: 'POST',
        data: data
    })
}

export const getListRule = (data) => {
    return api.makeAuthRequest({
        url: '/mapping-rules/search',
        method: 'POST',
        data: data
    })
}

export const getVariants = data => {
    return api.makeAuthRequest({
        url: '/products/list-variants',
        method: 'POST',
        data: data
    })
}

export const getSupplierType = (supplierId, data) => {
    return api.makeAuthRequest({
        url: `supplier-types/${supplierId}`,
        method: 'POST',
        data: data
    })
}

export const updateBaseCostMapping = (orderItemId, Payload) => {
    return api.makeAuthRequest({
        url: `mapping/${orderItemId}`,
        method: 'PUT',
        data: Payload
    })
}

export const changeConfirmTrusted = (mappingId, data) => {
    return api.makeAuthRequest({
        url: `/mapping-rules/${mappingId}/confirm-trusted`,
        method: 'POST',
        data: data
    })
}

export const productlineApplyBranding = (variantId, data) => {
    return api.makeAuthRequest({
        url: `products/${variantId}/productline-apply-branding`,
        method: 'PUT',
        data: data
    })
}

export const getStoreVariantForSupplier = (productId, supplierId) => {
    return api.makeAuthRequest({
        url: `/products/${productId}/store/${supplierId}`,
        method: 'GET'
    })
}

export const addStoreVariantForSupplier = (productId, supplierId, data) => {
    return api.makeAuthRequest({
        url: `/products/${productId}/variants-store/${supplierId}`,
        method: 'POST',
        data
    })
}

export const updateProductLineStore = (id, data) => {
    return api.makeAuthRequest({
        url: `/product-line-store/${id}`,
        method: 'PUT',
        data
    })
}

export const removeStoreVariantForSupplier = (productId, storeId) => {
    return api.makeAuthRequest({
        url: `/products/${productId}/variants-store/${storeId}`,
        method: 'DELETE',
    })
}

export const upsertEmbroiderySurcharge = (productId, data) => {
    return api.makeAuthRequest({
        url: `/products/${productId}/embroidery-surcharge`,
        method: 'POST',
        data
    })
}

export const deleteEmbroiderySurcharge = (productId, embroideryId) => {
    return api.makeAuthRequest({
        url: `/products/${productId}/embroidery-surcharge/${embroideryId}`,
        method: 'DELETE',
    })
}

export const getListSpecialCase = (data) => {
    return api.makeAuthRequest({
        url: `/mapping/special-case/search`,
        method: 'POST',
        data
    })
}

export const deleteSpecialCase = (id) => {
    return api.makeAuthRequest({
        url: `/mapping/special-case/${id}`,
        method: 'DELETE',
    })
}

export const changeStatusSpecialCase = (id, status) => {
    return api.makeAuthRequest({
        url: `/mapping/special-case/${id}/status/${status}`,
        method: 'PUT',
    })
}

export const createSpecialCase = (data) => {
    return api.makeAuthRequest({
        url: `/mapping/special-case`,
        method: 'POST',
        data
    })
}

export const updateSpecialCase = (data, id) => {
    return api.makeAuthRequest({
        url: `/mapping/special-case/${id}`,
        method: 'PUT',
        data
    })
}

export const getDetailSpecialCase = (id) => {
    return api.makeAuthRequest({
        url: `/mapping/special-case/${id}`,
        method: 'GET',
    })
}

export const addChildProducts = (productId, data) => {
    return api.makeAuthRequest({
        url: `/products/${productId}/add-child`,
        method: 'POST',
        data: data
    })
}

export const updateCustomCostItemMapping = (orderId, orderItemId, data) => {
    return api.makeAuthRequest({
        url: `mapping/${orderId}/${orderItemId}/base-cost`,
        method: 'PUT',
        data
    })
}