import createApiServices from "./createApiServices"

const prefixURL = process.env.NODE_ENV === 'production' ? '/api/product' : 'https://fulfillment-staging.merchize.com/api/product'
const devURL = window['API_SHIPPING_SERVICE_URL'] || ''
const api = createApiServices({baseUrl: prefixURL})

export const getCatalogVariantDetail = (catalogId) => {
    return api.makeAuthRequest({
        url: `/catalog-variants/${catalogId}`,
        method: 'GET'
    })
}

export const updateCatalogVariantDetail = (catalogId, data) => {
    return api.makeAuthRequest({
        url: `/catalog-variants/${catalogId}`,
        method: 'PUT',
        data: data
    })
}
