import React, {Component} from 'react'
import PropTypes from 'prop-types'
import ObjectInspector from 'react-object-inspector'
import humanizeTime from "../../../../helpers/time/humanizeTime"
import { Tooltip } from 'antd'
import PackageCheckDetail from './PackageCheckDetail'

class OrderHistoriesItem extends Component {
    state = {
        isOpenCheckDetail: false
    }

    _handleOpenCheckDetail = (e) => {
        e.preventDefault()

        this.setState({
            isOpenCheckDetail: true
        })
    }

    _handleCloseCheckDetail = () => {
        this.setState({
            isOpenCheckDetail: false
        })
    }

    render() {
        const {isOpenCheckDetail} = this.state
        const {event, description, created, value, _id} = this.props.history
        const eventNeedCheckDetail = /^order(_auto)?_push_to(.*)$/.test(event)
        
        return (
            <div className="OrderHistoriesItem">
                <div className="card">
                    <div className="d-flex align-items-center justify-content-start card-header">
                        <span className='card-header-time'>
                            {humanizeTime(created)}
                        </span>
                        {
                            eventNeedCheckDetail &&
                            <Tooltip title="get order detail from supplier by history">
                                <button onClick={this._handleOpenCheckDetail} className="btn btn-primary ml-auto">
                                    Check Detail
                                </button>
                            </Tooltip>
                        }
                    </div>

                    <div className="card-body">
                        <div className="row align-items-baseline mb-3">
                            <div className="col-4">
                                <code>{event}</code>
                            </div>

                            <div className="col-8 text-muted">{description}</div>
                        </div>

                        <div className="row align-items-baseline">
                            <div className="col-4">Value</div>
                            <div className="col-8">
                                <ObjectInspector depth={0} path="root.value" data={value}/>
                            </div>
                        </div>
                    </div>
                </div>

                {
                    eventNeedCheckDetail &&
                    <PackageCheckDetail
                        orderHistoryId={_id}
                        isOpen={isOpenCheckDetail}
                        onClose={this._handleCloseCheckDetail}
                    />
                }
            </div>
        )
    }
}

OrderHistoriesItem.propTypes = {
    history: PropTypes.object.isRequired
}

export default OrderHistoriesItem
