import React from 'react'
import { message, Tooltip } from 'antd'
import { updatePrintFilesStatus } from '../../../../services/api/OrdersServices'

const GenerateTemplate = ({ orderId, onChangeStatus, fulfillmentId }) => {

    const _handleClick = e => {
        e.preventDefault()

        _generateTemplate()
    }

    const _generateTemplate = async () => {
        try {
            const response = await updatePrintFilesStatus(fulfillmentId, orderId, 'retry')

            const { success, message, data } = response

            if (!success) {
                throw new Error(message)
            }

            onChangeStatus('retry', orderId)
        } catch (e) {
            message.error(e.message, 3)
        }
    }
    return (
        <div className='GenerateTemplate Icon' onClick={_handleClick}>
            <Tooltip title='Retry' placement='bottom'>
                <i className='fa fa-share-square-o' />
            </Tooltip>
        </div>
    )
}

export default GenerateTemplate