import React from 'react'
import {Pagination} from "antd"

const ScanDetailPagination = (props) => {
    const {limit, total, page, setPage, setLimit} = props

    const _handlePageChange = (page, pageSize) => {
        setLimit(pageSize)
        setPage(pageSize !== limit ? 1 : page)
    }

    return (
        <div className="OrdersPagination d-flex justify-content-end Pagination">
            <Pagination current={page} total={total} pageSize={limit} onChange={_handlePageChange}
                        className='text-right' showSizeChanger pageSizeOptions={['10', '20', '50', '100']}
                        />
        </div>
    )
}

export default ScanDetailPagination
