import React from 'react'
import PropTypes from 'prop-types'
import {Button} from "antd"
import getHistory from "../../../store/getHistory"

Actions.propTypes = {
    loading: PropTypes.bool,
    onUpdateDiscount: PropTypes.func.isRequired
}

function Actions(props) {
    const {loading, onUpdateDiscount} = props

    function _backLink() {
        const history = getHistory()
        history.push(`/a/fulfillment-discounts`)
    }

    return (
        <div className="Actions">
            <Button type="default" className="mr-2" onClick={_backLink}>Cancel</Button>
            <Button type="primary" loading={loading} onClick={onUpdateDiscount}>Save discount</Button>
        </div>
    )
}

export default Actions