export const listFilterText = [
    {name: 'Request ID', value: 'request_id'},
    {name: 'Store', value: 'store_id'},
    {name: 'Order ID', value: 'order_id'}
]

export const listFilterType = [
    {name: 'Design', value: 'update_design'},
    {name: 'Address', value: 'update_address'}
]

export const listStatus = [
    {name: 'Waiting', value: 'waiting'},
    {name: 'Processing', value: 'processing'},
    {name: 'Updated', value: 'updated'},
    {name: 'Hold', value: 'hold'}
]

export const listSource = [
    {name: 'Factory', value: 'fac'},
    {name: 'Fulfillment', value: 'ffm'},
    {name: 'Tool3D', value: 'tool3d'}
]

export const listNeedCharge = [
    {name: 'All', value: ''},
    {name: 'True', value: true},
    {name: 'False', value: false}
]

export const listProducts = [
    {name: 'Pending', value: 'pending'},
    {name: 'In Production', value: 'in_production'},
    {name: 'False Design', value: 'false_design'},
    {name: 'On-Hold', value: 'on-hold'},
    {name: 'Un-Printed', value: 'unprinted'},
    {name: 'Pushed', value: 'pushed'},
    {name: 'Printed', value: 'printed'},
    {name: 'Transferred', value: 'transferred'},
    {name: 'Confirmed', value: 'confirmed'},
    {name: 'Sewing', value: 'sewing'},
]

export const listStatusCharge = [
    {name: 'Pending', value: 'pending'},
    {name: 'Paid', value: 'paid'},
    {name: 'Cancelled', value: 'cancelled'}
]
