import { Modal, notification, message } from 'antd'
import { markItemNotNeedToPush } from '../../../../services/api/OrdersServices'

const bulkMarkItemNotNeedToPush = (selectedOrders, reload, onToggleSelectAll) => {

    const _generateTemplate = async (fulfillmentItem) => {
        try {
            const response = await markItemNotNeedToPush(fulfillmentItem)
            return response
        } catch (e) {
            message.error(e.message, 3)
        }
    }

    const handleMultipleRequests = async () => {
        try {
            const promises = selectedOrders.map((item) => _generateTemplate(item.fulfillmentItem))
            return await Promise.all(promises)
        } catch (error) {
            throw new Error(error)
        }
    }

    Modal.confirm({
        title: 'Be sure to mark item not need to push after update printing file selected orders ?',
        okText: 'Ok',
        cancelText: 'Cancel',
        async onOk() {
            const result = await handleMultipleRequests()
            const itemSuccess = result.every(i => i.success)

            if (itemSuccess) {
                notification.success({
                    message: 'Request successful',
                    description: 'Request mark item successfully',
                    duration: 3
                })
            }else{
                result.map(item => {
                    if (item.success) {
                        notification.success({
                            message: 'Request successful',
                            description: 'Request mark item successfully',
                            duration: 3
                        })
                    } else {
                        notification.error({
                            message: 'Request error',
                            description: item.message || 'Request mark item failed',
                            duration: 10
                        })
                    }
                })
            }

            reload()
            onToggleSelectAll(false)
        }
    })
}

export default bulkMarkItemNotNeedToPush