import React, {Component} from 'react'
import PropTypes from 'prop-types'
import humanizeTime from '../../../../helpers/time/humanizeTime'
import {deleteSupplier} from "../../../../services/api/SuppliersServices"
import { getLocalData } from '../../../../services/StorageServices'
import { Tooltip, Switch } from 'antd'

class SuppliersTableRow extends Component {
    _handleClickEdit = () => {
        const {supplier, openModalEdit} = this.props
        openModalEdit(supplier)
    }

    _handleClickDelete = async () => {
        const isSure = window.confirm('Are you sure?')
        if (!isSure) return

        try {
            const {supplier: {_id: supplierId}, refresh} = this.props
            const {success, message} = await deleteSupplier(supplierId)
            if (!success) throw new Error(message)

            refresh()
        } catch (e) {
            alert(e.message)
        }
    }

    render() {
        const {supplier} = this.props
        const {name, is_active, created, updated,sku_prefix, need_gen_mockup, max_mapping, available_tiktok, need_charge_have_sleeves, zone, mapping_priority, warehouse_name} = supplier
        const status = is_active ? 'active' : 'inactive'
        const updatedText = humanizeTime(updated)
        const createdText = humanizeTime(created)
        const permissionsData = !!getLocalData('permissions') ? Object.keys(getLocalData('permissions')) : []
        return (
            <tr className='SuppliersTableRow'>
                <td>{name}</td>
                <td>{status}</td>
                <td>{sku_prefix}</td>
                <td>
                    {
                        need_gen_mockup && need_gen_mockup === true ? 'Yes' : 'No'
                    }
                </td>
                <td>
                    {
                        mapping_priority ? mapping_priority : ''
                    }
                </td>
                <td>
                    {
                        max_mapping !== undefined && max_mapping !== null ? max_mapping : ''
                    }
                </td>
                <td>
                    {
                        zone ? zone : ''
                    }
                </td>
                <td>
                    {
                        available_tiktok && available_tiktok === true ? 'Yes' : 'No'
                    }
                </td>
                <td>
                    {
                        need_charge_have_sleeves ? need_charge_have_sleeves : ''
                    }
                </td>
                <td>
                    {
                        warehouse_name ? warehouse_name : ''
                    }
                </td>
                <td>{updatedText}</td>
                <td>{createdText}</td>
                {(permissionsData.includes('ffm_supplier_update') || permissionsData.includes('ffm_supplier_delete')) &&
                    <td>
                        <div className='btn-group'>
                            {permissionsData.includes('ffm_supplier_update')&&
                                <i className='ti-pencil-alt text-primary ml-2' onClick={this._handleClickEdit}/>
                            }
                            {permissionsData.includes('ffm_supplier_delete') &&
                                <i className='ti-close text-danger ml-2' onClick={this._handleClickDelete}/>
                            }
                        </div>
                    </td>
                }
            </tr>
        )
    }
}

SuppliersTableRow.propTypes = {
    supplier: PropTypes.object.isRequired,
    refresh: PropTypes.func.isRequired
}

export default SuppliersTableRow
