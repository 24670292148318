import React, {useState} from 'react'
import PropTypes from 'prop-types'
import {Select} from "antd"
import {ReactComponent as SearchIcon} from '../../../../statics/assets/icons/search.svg'

const {Option} = Select

MultiFilterInput.propTypes = {
    listFilters: PropTypes.array.isRequired,
    defaultValue: PropTypes.string,
    onChangeSearchMulti: PropTypes.func.isRequired
}

function MultiFilterInput(props) {
    const {listFilters, defaultValue, onChangeSearchMulti} = props

    const [name, setName] = useState(defaultValue)
    const [value, setValue] = useState('')

    const _handleChangeSelect = (value) => {
        setName(value)
        setValue('')

        if (typeof onChangeSearchMulti === 'function') {
            onChangeSearchMulti(name, '')
        }
    }

    const _handleChangeInput = (e) => {
        const {value} = e.target

        setValue(value)

        if (typeof onChangeSearchMulti === 'function') {
            onChangeSearchMulti(name, value)
        }
    }

    return (
        <div className='MultiFilterInput'>
            <div className="MultiFilter" id="multi">
                <Select value={name} onChange={_handleChangeSelect}>
                    {
                        !!listFilters && listFilters.length > 0 && listFilters.map((item, index) => (
                                <Option value={item.value} key={index}>{item.name}</Option>
                            )
                        )
                    }
                </Select>
                <div className="Right">
                    <SearchIcon/>
                    <input type="text" value={value} placeholder='Input text search...' onChange={_handleChangeInput}/>
                </div>
            </div>
        </div>
    )
}

export default MultiFilterInput
