import React, {Component} from 'react'
import StatusStyle from "../../../shared/StatusStyle"
import PropTypes from "prop-types"


class OrderPaidAt extends Component {
    render() {
        const {payment_status} = this.props.order

        return (
            <div className="OrderPaidAt">
                {
                    !!payment_status &&
                    <div className="ml-3 ml-sm-5">
                        <span className="">Payment status:</span>
                        <span className="ml-2">
                                <StatusStyle status={payment_status}/>
                            </span>
                    </div>
                }
            </div>
        )
    }
}


OrderPaidAt.propTypes = {
    order: PropTypes.object.isRequired,
}

export default OrderPaidAt
