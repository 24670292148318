import React, {Component} from 'react'
import PropTypes from 'prop-types'
import {checkDuplicateOrder} from "../../../../services/api/OrdersServices"
import {Link} from "react-router-dom"

class CheckDuplicateOrder extends Component {
    state = {
        isDuplicate: false,
        others: [],
    }

    componentDidMount() {
        this._checkDuplicate()
    }

    _checkDuplicate = async () => {
        const {orderId} = this.props

        try {
            const {success, data, message} = await checkDuplicateOrder(orderId)

            if (!success) {
                throw new Error(message)
            }

            const {isDuplicate, others} = data

            this.setState({
                isDuplicate,
                others
            })
        } catch (e) {
            console.log(e)
        }
    }

    _renderDuplicateOrders = () => {
        const {others} = this.state

        return (
            <div className="Others">
                {
                    others.map((order, index) => {
                        const {id: orderCode, _id, fulfillment_status} = order

                        return (
                            <Link target="_blank" to={`/a/orders/${_id}`} title={`Order: ${orderCode}`}>
                                <code>{orderCode}:{fulfillment_status}</code>
                                {
                                    index < others.length - 1 &&
                                    <span className="Comma">,</span>
                                }
                            </Link>
                        )
                    })
                }
            </div>
        )
    }

    render() {
        const {isDuplicate} = this.state
        if (!isDuplicate) return null

        return (
            <div className="CheckDuplicateOrder">
                <div className="d-flex align-items-center">
                    <span className="badge badge-danger">duplicated</span>

                    {this._renderDuplicateOrders()}
                </div>
            </div>
        )
    }
}

CheckDuplicateOrder.propTypes = {
    orderId: PropTypes.string.isRequired,
}

export default CheckDuplicateOrder
