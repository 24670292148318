import React, {Component} from 'react'
import ShippingPlanPage from "./ShippingPlanPage"
import DocTitleByStore from "../../dashboard/shared/DocTitleByStore"

class ShippingPlanPageContainer extends Component {
    render() {
        const id = this.props.match.params.id

        return (
            <div className="ShippingPlanPageContainer">
                <DocTitleByStore title="Create plan"/>
                <ShippingPlanPage planId={id}/>
            </div>
        )
    }
}

export default ShippingPlanPageContainer
