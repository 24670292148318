import React, { useState, useEffect } from 'react'

function ItemMockupModal(props) {
    const {visible, mockup} = props

    return (
        <div className={`imageMockup ${visible ? 'visible' : 'hide'}`}>
            <div className='imageMockupBox'>
                {
                    mockup ? <img src={mockup} alt='image mockup'/> : 'No Mockup'
                }
                
            </div>
        </div>
    )
}

export default ItemMockupModal