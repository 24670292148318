import React, { useState, useEffect } from 'react'
import moment from 'moment'
import TableStorePackage from './TableStorePackage'
import TableStorePackageItem from './TableStorePackageItem'

const StoreReport = (props) => {
    const { supplier, typeReport, fetchIssueStorePackage, fetchIssueStorePackageItem, storePackageReportItem, storePackageReport, storeBy } = props
    const [month1, setMonth1] = useState(moment().subtract(1, 'months'))
    const [month2, setMonth2] = useState(moment())

    useEffect(() => {
        storeBy === false && fetchIssueStorePackage(month1, month2)
        storeBy === true &&fetchIssueStorePackageItem(month1, month2)
    }, [supplier, typeReport, month1, month2, storeBy])

    const handleMonth2Change = (date) => {
        setMonth2(date)
        setMonth1(moment(date).subtract(1, 'months'))
    }

    return (
        !storeBy ?
            <TableStorePackage
                month1={month1}
                month2={month2}
                onChangeMonth2={handleMonth2Change}
                storePackageReport={storePackageReport}
            /> :
            <TableStorePackageItem
                month1={month1}
                month2={month2}
                onChangeMonth2={handleMonth2Change}
                storePackageReportItem={storePackageReportItem}
            />
    )
}

export default StoreReport
