import React, {Component} from 'react'
import PropTypes from 'prop-types'
import {updateIOSS} from '../../../../services/api/OrdersServices'

class OrderIOSS extends Component {
    constructor(props) {
        super(props);
        const {order} = props
        this.state = {
            ioss: order.ioss || '',
            error: '',
            loading: false,
            isEditing: false
        }
    }

    _handleChangeIoss = (e) => {
        const {value} = e.target

        this.setState({
            ioss: value
        })
    }

    _handleEditing = () => {
        this.setState({
            isEditing: !this.state.isEditing
        })
    }

    _handleSaveIOSS = async () => {
        try {
            const {order} = this.props
            const {ioss} = this.state

            this.setState({
                error: ''
            })

            const payload = {
                newIOSSNumber: ioss
            }
            const {success, message} = await updateIOSS(order._id, payload)

            if(!success) return this.setState({
                error: message
            })

            this.setState({
                isEditing: false
            })
        } catch (e) {
            this.setState({
                isEditing: false,
                error: e.message
            })
        }
    }

    render() {
        const {order} = this.props
        const {isEditing, error, ioss} = this.state
        const {fulfillments} = Object.assign({}, order)
        const pushedStatus = fulfillments.filter(itemF => itemF.push_status === "pushed")

        return (
            <div className="OrderPreferenceShipping">
                <div className="ShippingInner mt-3">
                    <div className="ShippingInner mt-3">
                        <div className="Title">IOSS number</div>
                        <div className="Content w-100">
                            {isEditing ? (
                                <span className="d-flex align-items-center justify-content-between">
                                    <input name="iossNumber" className="ant-input" value={ioss} onChange={this._handleChangeIoss}/>
                                    <span onClick={this._handleSaveIOSS} className="text-primary cursor-pointer ml-2" title="Save IOSS">
                                        <i className="ti-save"/>
                                    </span>
                                </span>
                            ) : (
                                <span className="d-flex align-items-center justify-content-between">
                                    <div className="Row">{ioss}</div>
                                    {(pushedStatus.length === 0 || fulfillments.length === 0) && (
                                        <span onClick={this._handleEditing} className="text-primary cursor-pointer ml-2" title="Edit tracking">
                                            <i className="ti-pencil"/>
                                        </span>
                                    )}
                                </span>
                            )}
                            {error && <div className="text-danger mt-2">{error}</div>}
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

OrderIOSS.propTypes = {
    preference: PropTypes.object.isRequired
}

export default OrderIOSS