import React from 'react'
import DocTitleByStore from "../../dashboard/shared/DocTitleByStore"
import CostControlPage from './CostControlPage'

function CostControlPageContainer(props) {
    const idCost = props.match.params.id

    return (
        <div className="CostControlPageContainer">
            <DocTitleByStore title="Cost Control" />
            <CostControlPage />
        </div>
    )
}

export default CostControlPageContainer