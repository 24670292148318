import React, {Component} from 'react'
import DocTitleByStore from "../../dashboard/shared/DocTitleByStore"
import ShippingPlansPage from "./ShippingPlansPage"

class ShippingPlansPageContainer extends Component {
    render() {
        return (
            <section className="ShippingPlansPageContainer">
                <h1 className="PageTitle">Shipping Plans</h1>
                <div className="SectionInner">
                    <DocTitleByStore title="Shipping"/>
                    <ShippingPlansPage/>
                </div>

            </section>
        )
    }
}

export default ShippingPlansPageContainer
