import React, {Component} from 'react'
import classNames from 'classnames'
import PropTypes from 'prop-types'
import StatusStyle from "../../../../shared/StatusStyle"

class OrderPaymentStatus extends Component {
    render() {
        const {payment_status} = this.props.order
        const status = payment_status || 'pending'

        return (
            <td className={classNames('OrderPaymentStatus', status)}>
                <StatusStyle status={payment_status}/>
            </td>
        )
    }
}

OrderPaymentStatus.propTypes = {
    order: PropTypes.object.isRequired,
}

export default OrderPaymentStatus
