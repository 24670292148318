import React, {useState, useEffect} from 'react'
import {getListSuppliersV2} from "../../../../services/api/SuppliersServices"
import {searchProducts} from "../../../../services/api/ProductsServices"
import FiltersProductLine from "./FiltersProductLine"
import ListProductLine from "./ListProductLine"
import {Button, Modal, Pagination} from "antd"
import UseDebounce from '../../helpers/UseDebounce'

function ProductLineModal(props) {
    const {isOpen, onSetVariants, listSelectedVariants, selectProductLines} = props
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState('')
    const [loadingSupplier, setLoadingSupplier] = useState(false)
    const [errorSupplier, setErrorSupplier] = useState('')
    const [suppliers, setSuppliers] = useState([])
    const [supplier, setSupplier] = useState('')
    const [term, setTerm] = useState('')
    const [type, setType] = useState('')
    const [skuPrefix, setSkuPrefix] = useState('')
    const [productLineList, setProductLineList] = useState([])
    const [page, setPage] = useState(1)
    const [limit, setLimit] = useState(10)
    const [total, setTotal] = useState(0)
    const [selectAll, setSelectAll] = useState(false)
    const [listSelectedProduct, setListSelectedProduct] = useState([])
    const [listSelectedVariant, setListSelectedVariant] = useState([])
    const [active, setActive] = useState('')

    function _onChangeActive(id) {
        setActive(id)
    }

    async function _fetchListSupplier() {
        setLoadingSupplier(true)
        setErrorSupplier('')

        try {
            const response = await getListSuppliersV2({limit: 100, active: true})

            const {data, success, message} = response

            if (!success) {
                setLoadingSupplier(false)

                return setErrorSupplier(message)
            }

            const {suppliers} = data

            setSuppliers(suppliers)
            setLoadingSupplier(false)
            setErrorSupplier('')
        } catch (e) {
            setLoadingSupplier(false)
            setErrorSupplier(e.message)
        }
    }

    async function _fetchListProductLine() {
        setLoading(true)
        setError('')

        try {
            let payload = {
                page,
                limit,
                supplier,
                term,
                type,
                sku_prefix: skuPrefix
            }

            const response = await searchProducts(payload)

            const {data, success, message} = response

            if (!success) {
                setLoading(false)

                return setError(message)
            }

            const {products, total} = data

            setProductLineList(products)
            setTotal(total)
            setLoading(false)
            setError('')

            if (!!products && products.length > 0) {
                let listAll = []
                const listProductLine = products.map(item => {
                    return {id: item._id, title: item.title}
                })

                listProductLine.map(item => {
                    const haveProduct = listSelectedProduct.filter(product => product.id === item.id)
                    const haveProductProp = selectProductLines.filter(product => product.id === item.id)

                    if (haveProduct.length > 0 || haveProductProp.length > 0) {
                        return listAll.push(item)
                    } else {
                        return []
                    }
                })

                if (listAll.length === products.length) {
                    setSelectAll(true)
                } else {
                    setSelectAll(false)
                }
            }
        } catch (e) {
            setLoading(false)
            setError(e.message)
        }
    }

    function _onChangeSupplier(value) {
        setSupplier(value)
    }

    function _onChangeTerm(value) {
        setTerm(value)
    }

    function _onChangeType(value) {
        setType(value)
    }

    function _onChangeSku(value) {
        setSkuPrefix(value)
    }

    function _handleChangePage(current) {
        setPage(current)
        setSelectAll(false)
    }

    function _handleChangeLimit(current, size) {
        setPage(1)
        setLimit(size)
    }

    function _updateSelectedProductLine(id, title, isAdded) {
        if (isAdded && !listSelectedProduct.includes(id)) {
            setSelectAll(listSelectedProduct.length + 1 === productLineList.length)
            setListSelectedProduct([...listSelectedProduct, {id: id, title: title}])
        } else {
            const filtered = listSelectedProduct.filter(product => product.id !== id)

            setListSelectedProduct(filtered)
            setSelectAll(false)
        }
    }

    function _toggleSelectAll(isChecked) {
        if (isChecked) {
            const listProductLine = productLineList.map(item => {
                return {id: item._id, title: item.title}
            })

            setSelectAll(true)
            setListSelectedProduct([...listSelectedProduct, ...listProductLine])
        } else {
            setSelectAll(false)
            setListSelectedProduct([])
        }
    }

    function _updateSelectedVariants(id, title, product_id, product_title, isAdded, selectAll) {
        if (selectAll && isAdded) {
            const filtered = listSelectedProduct.filter(product => product.id === product_id)

            if (!!filtered && filtered.length === 0) {
                setListSelectedProduct([...listSelectedProduct, {id: product_id, title: product_title}])
            }

            setListSelectedVariant([...listSelectedVariant, {
                id: id,
                title: title,
                product_title: product_title,
                product_id: product_id
            }])
        } else {
            if (isAdded) {
                setListSelectedVariant([...listSelectedVariant, {
                    id: id,
                    title: title,
                    product_title: product_title,
                    product_id: product_id
                }])
            } else {
                const filtered = listSelectedVariant.filter(variant => variant.id !== id)

                setListSelectedVariant(filtered)
            }
        }
    }

    function _addProductLine() {
        const {onSetProductLines, onClose} = props

        if (typeof onSetProductLines === 'function' && typeof onClose === 'function' && typeof onSetVariants === 'function') {
            onClose()
            onSetProductLines(listSelectedProduct)
            onSetVariants(listSelectedVariant)
        }

        setListSelectedProduct([])
        setListSelectedVariant([])
        setSelectAll(false)
    }

    function _onClose() {
        const {onSetProductLines, onClose} = props

        if (typeof onSetProductLines === 'function') {
            onClose()
            setActive('')
            setListSelectedProduct([])
            setListSelectedVariant([])
        }
    }

    const debounceTerm = UseDebounce(term, 500)
    const debounceType = UseDebounce(type, 500)
    const debounceSku = UseDebounce(skuPrefix, 500)

    useEffect(() => {
        _fetchListSupplier()
    }, [])

    useEffect(() => {
        _fetchListProductLine()
    }, [page, limit, supplier, debounceTerm, debounceType, debounceSku])

    useEffect(() => {
        setActive('')
        setListSelectedProduct(selectProductLines)
        setListSelectedVariant(listSelectedVariants)
    }, [isOpen])

    return (
        <div className="ProductLineModal">
            <Modal
                title="Select product line"
                className="AddProductLine"
                visible={isOpen}
                onCancel={_onClose}
                footer={[
                    <Button key="back" onClick={_onClose}>
                        Cancel
                    </Button>,
                    <Button key="submit" type="primary" onClick={_addProductLine}>
                        Add
                    </Button>
                ]}
            >
                <FiltersProductLine
                    loadingSupplier={loadingSupplier}
                    errorSupplier={errorSupplier}
                    suppliers={suppliers}
                    supplier={supplier}
                    term={term}
                    type={type}
                    skuPrefix={skuPrefix}
                    onChangeSupplier={_onChangeSupplier}
                    onChangeTitle={_onChangeTerm}
                    onChangeType={_onChangeType}
                    onChangeSku={_onChangeSku}
                />

                <ListProductLine
                    loading={loading}
                    selectAll={selectAll}
                    listSelected={listSelectedProduct}
                    listSelectedVariant={listSelectedVariant}
                    error={error}
                    active={active}
                    onChangeActive={_onChangeActive}
                    productLineList={productLineList}
                    onUpdateSelectedProduct={_updateSelectedProductLine}
                    onSetVariants={onSetVariants}
                    onSelectAll={_toggleSelectAll}
                    onUpdateSelectedVariants={_updateSelectedVariants}
                />

                <Pagination
                    current={page}
                    total={total}
                    pageSize={limit}
                    className='text-right'
                    showSizeChanger
                    pageSizeOptions={['10', '20', '50', '100']}
                    onChange={_handleChangePage}
                    onShowSizeChange={_handleChangeLimit}
                />
            </Modal>
        </div>
    )
}

export default ProductLineModal
