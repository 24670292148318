import React, {Component} from 'react'
import ReturnPage from "../shared/ReturnPage"
import DocTitleByStore from "../dashboard/shared/DocTitleByStore"
import ShippingPlanNewPage from "./ShippingPlanNewPage"

class ShippingPlanNewPageContainer extends Component {
    render() {
        return (
            <section className="ShippingPlanNewPageContainer">
                <ReturnPage url="/a/shipping" title="Shipping plans"/>

                <h1 className="PageTitle">Create plan</h1>
                <DocTitleByStore title="Create plan"/>
                <ShippingPlanNewPage/>
            </section>
        )
    }
}

export default ShippingPlanNewPageContainer
