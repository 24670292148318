import React, {Component} from 'react'
import PropTypes from 'prop-types'
import {getArtworksByOrderItemV3} from "../../../../services/api/ArtworksServices"
import OrderItemGroupArtworkItem from "../shared/OrderItemGroupArtworkItem"

class OrderItemDesignV2 extends Component {
    state = {
        artworks: [],
        artworksMore: [],
        error: "",
        showAll: false,
        piecesPerPack: 0,
    }

    componentDidMount() {
        this._fetchArtworks()
    }

    _onChangeShowAll = () => {
        this.setState({
            showAll: !this.state.showAll
        })
    }

    _fetchArtworks = async () => {
        const {orderItemId, orderId} = this.props

        try {
            const {data, success, message} = await getArtworksByOrderItemV3({orderId, orderItemId})

            if (!success) {
                return this.setState({
                    error: message,
                })
            }

            this.setState({
                artworks: data.length >= 2 ? data.slice(0, 1) : data,
                artworksMore: data.length >= 2 ? data.slice(1, data.length) : []
            })
        } catch (e) {
            return this.setState({
                error: e.message,
            })
        }
    }

    render() {
        const {artworks, artworksMore, error, showAll, piecesPerPack} = this.state

        return (
            <td className="OrderItemDesignV3">
                {
                    !!artworks.length && <div className="listArtwork">
                        {
                            artworks.map((item, index) => (
                                <OrderItemGroupArtworkItem
                                    key={`first_${index}`}
                                    artwork={item}
                                    typeDisplay="first"
                                    piecesPerPack={piecesPerPack}
                                />
                            ))
                        }
                    </div>
                }

                {
                    !showAll && !!artworksMore && artworksMore.length > 0 ?
                        <div className="ShowAll" onClick={this._onChangeShowAll}>
                            View more
                        </div> :
                        <>
                            <div className="listArtwork">
                                {
                                    showAll && !!artworksMore && artworksMore.length > 0 && artworksMore.map((item, index) => (
                                        <OrderItemGroupArtworkItem
                                            key={`first_${index}`}
                                            artwork={item}
                                            typeDisplay="more"
                                            piecesPerPack={piecesPerPack}
                                        />
                                    ))
                                }
                            </div>
                            {
                                !!artworksMore && artworksMore.length > 0 &&
                                <div className="ShowAll" onClick={this._onChangeShowAll}>
                                    Hide
                                </div>
                            }
                        </>
                }

                {
                    !!error &&
                    <div className="text-danger">{error}</div>
                }
            </td>
        )
    }
}

OrderItemDesignV2.propTypes = {
    orderItemId: PropTypes.string.isRequired,
    orderId: PropTypes.string.isRequired
}

export default OrderItemDesignV2
