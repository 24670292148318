import React, {Component} from 'react'
import {searchWebhookJobs} from "../../../../services/api/WebhookServices"
import OrderJobRow from "./OrderJobRow"
import PropTypes from 'prop-types'

class OrderJobTable extends Component {
    state = {
        jobs: [],
        error: "",
    }

    componentDidMount() {
        this._fetchOrderJobs()
    }

    _fetchOrderJobs = async () => {
        const queryParams = {
            order: this.props.orderId,
            limit: 100,
        }

        try {
            const {data, success, message} = await searchWebhookJobs(queryParams)

            if (!success) {
                return this.setState({
                    error: message,
                })
            }

            const {jobs} = data

            this.setState({
                jobs,
                error: "",
            })
        } catch (e) {
            this.setState({
                error: e.message,
            })
        }
    }


    render() {
        const {jobs, error} = this.state
        const {permissionsData} = this.props

        return (
            <div className="OrderJobTable">
                <table className="table">
                    <thead className="thead-light">
                    <tr>
                        <th></th>
                        <th>Date</th>
                        <th>Status</th>
                        <th>Type</th>
                        <th>Total Sent</th>
                        <th>Send Time</th>
                        <th>Error</th>
                        {
                            permissionsData.includes('ffm_webhook_job_run') && permissionsData.includes('ffm_webhook_job_cancel') &&
                            <th>Action</th>
                        }
                    </tr>
                    </thead>

                    <tbody>
                    {
                        jobs.length <= 0 ?
                            <tr>
                                <td><i>No results</i></td>
                            </tr>
                            :
                            jobs.map(job => {
                                return <OrderJobRow key={job._id} job={job} permissionsData={permissionsData} onFetchWebhook={this._fetchOrderJobs}/>
                            })
                    }
                    </tbody>
                </table>

                {
                    !!error && <div className="text-danger mt-3">{error}</div>
                }
            </div>
        )
    }
}

OrderJobTable.propTypes = {
    orderId: PropTypes.string.isRequired,
}

export default OrderJobTable
