export const templateRequestUpdateVi = [
    'Đơn này file in lệch file shape',
    'Đơn này file in 1 lệch file shape',
    'Đơn này file in 2 lệch file shape',
    'Đơn này design có họa tiết mảnh dễ gãy',
    'Đơn này design sai dimension',
    'Đơn này design thân lấn bo thân',
    'Đơn này design cánh tay lấn bo tay',
    'Đơn này design thân lấn bo thân, design cánh tay lấn bo tay',
    'Đơn này design bo thân và bo tay chưa để full màu như mock up',
    'Đơn này design bo thân chưa để full màu như mock up',
    'Đơn này design bo tay chưa để full màu như mock up',
    'Đơn này design khác mock up',
    'Đơn này design chưa tách nền',
    'Đơn này file cut phần chữ không có điểm nối,in ra bị gãy',
    'Đơn này design tràn safezone',
    'Đơn này design làm đúng theo template riêng size 18x24',
    'Đơn này design làm đúng theo template riêng size 35x42',
    'Đơn này phần họa tiết vân chìm cần tăng opacity lên 25%',
    'Đơn này phần họa tiết vân chìm của mũ cần tăng opacity lên 45%',
    'Đơn này design có họa tiết sát mép cổ khi in bị cắt',
    'Đơn này design có họa tiết sát mép tay áo khi in bị cắt',
    'Đơn này cần làm chữ file in 1 thuận chiều',
    'Đơn này cần làm chữ file in 2 thuận chiều',
    'Đơn này phần lỗ ở file cut sâu quá không móc được móc treo',
    'Đơn này phần lỗ mảnh dễ gãy',
    'Đơn này phần lỗ nhỏ quá',
    'Đơn này desing tay áo bị mất khi may vào',
    'Đơn này tay áo bị ngược so với mockup',
    'Đơn này màu mũ khác mock up',
    'Đơn này design chưa tắt safezone',
    'Đơn này design thân áo làm full họa tiết ra xung quanh',
    'Đơn này design bị lỗi định dạng file',
]

export const templateRequestUpdateEn = [
    "The print image is not inclined with the cut shape",
    "The print image no.1 is not inclined with the cut shape",
    "The print image no. 2 is not inclined with the cut shape",
    "The artwork has thin details easily broken",
    "The artwork has wrong dimension",
    "Pattern from the shirt's upper part spreads to the body hem",
    "Pattern from the shirt's sleeve spreads to the sleeve cuff",
    "Pattern/Color does not fully cover the body hem & sleeve cuff like in the mockup",
    "Pattern/Color does not fully cover the body hem like in the mockup",
    "Pattern/Color does not fully cover the sleeve cuff like in the mockup.",
    "The artwork is different from the mockup.",
    "The background needs to be changed into transparent.",
    "The cut shape part for the letters does not have connection(s), likely to be broken after printed",
    "The artwork spreads beyond the safe zone.",
    "The artwork needs to be designed following the template for size 18x24.",
    "The artwork needs to be designed following the template for size 35x42.",
    "The semiopaque pattern needs to have an opacity of 25%",
    "The semiopaque pattern for baseball cap needs to have an opacity of 45%",
    "The pattern close to the neck's safezone line might be lost when sewn on.",
    "The pattern close to the sleeve's safezone line might be lost when sewn on.",
    "Words on the print image no.1 need to be flipped",
    "Words on the print image no.2 need to be flipped",
    "The hole is too far from the edge, can hardly attach the ring of the chain.",
    "The hole is too thin and easily broken.",
    "The hole is too small.",
    "The sleeve's pattern might be lost when sewn on.",
    "The position of the two sleeves needs to be switched.",
    "The hood's color in the artwork is different from the mockup.",
    "The artwork still has safe zone layer.",
    "The pattern needs to be made full around front and back.",
    "The artwork has a file format error."
]
