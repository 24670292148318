import React, {Component} from 'react'
import PropTypes from "prop-types"
import StatusStyle from "../../../shared/StatusStyle"


class OrderFulfillmentStatus extends Component {
    render() {
        const {fulfillment_status} = this.props.order


        return (
            <div className="OrderFulfillmentStatus mx-3 mx-sm-0">
                <span className="">Fulfillment status:</span>
                <span className="ml-2">
                    <StatusStyle status={fulfillment_status}/>
                </span>
            </div>
        )
    }
}

OrderFulfillmentStatus.propTypes = {
    order: PropTypes.object.isRequired,
}

export default OrderFulfillmentStatus
