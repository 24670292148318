import React, {Component} from 'react'
import ShippingZoneNewTitle from "./basic-info/ShippingZoneNewTitle"
import ShippingZoneNewDescription from "./basic-info/ShippingZoneNewDescription"
import ShippingZoneNewCountries from "./countries/ShippingZoneNewCountries"
import ShippingZoneNewMethods from "./methods/ShippingZoneNewMethods"
import ShippingZoneCreate from "./actions/ShippingZoneCreate"
import {createShippingZone} from "../../../services/api/ShippingServices"
import getHistory from "../../../store/getHistory"

class ShippingZoneNewPage extends Component {
    state = {
        title: "",
        description: "",
        countries: [],
        plans: [],
        error: "",
    }

    _selectedCountries = []

    _handleUpdateSelectedCountries = (arrCountries) => {
        this._selectedCountries = arrCountries
    }

    _handleUpdateShippingZoneState = (field, data) => {
        this.setState({
            [field]: data,
        })
    }

    _handleCreatePlan = () => {
        this.setState(({plans}) => {
            return {
                plans: [
                    ...plans,
                    {},
                ],
            }
        })
    }

    _handleUpdatePlansState = (index, field, data) => {
        this.setState(({plans}) => {
            const newPlans = plans.map((plan, idx) => {
                if (index === idx) {
                    return {
                        ...plan,
                        [field]: data,
                    }
                }

                return plan
            })

            return {
                plans: newPlans,
            }
        })
    }

    _handleRemovePlan = (index) => {
        this.setState(({plans}) => {
            const newPlans = plans.filter((plan, idx) => {
                return idx !== index
            })

            return {
                plans: newPlans,
            }
        })
    }

    _handleCreateShippingZone = async () => {
        try {
            const {title, description, plans} = this.state
            const {data, success, message} = await createShippingZone({
                title: title,
                description: description,
                countries: this._selectedCountries,
                plans: plans,
            })

            if (!success) {
                this.setState({
                    error: message,
                })

                return false
            }

            const {shippingZone} = data

            const history = getHistory()
            const location = {
                pathname: `/a/shipping-zones/${shippingZone._id}`,
                state: shippingZone,
            }

            history.push(location)

        } catch (e) {
            this.setState({
                error: e.message,
            })
        }
    }

    _handleFormSubmit = e => {
        e.preventDefault()

        this._handleCreateShippingZone()
    }

    render() {
        const {plans, error} = this.state

        return (
            <div className="ShippingZoneNewPage">
                <form onSubmit={this._handleFormSubmit}>
                    <div className="SectionInner">
                        <ShippingZoneNewTitle _handleUpdateShippingZoneState={this._handleUpdateShippingZoneState}/>

                        <div className="mt-4">
                            <ShippingZoneNewDescription
                                _handleUpdateShippingZoneState={this._handleUpdateShippingZoneState}/>
                        </div>

                        <div className="mt-4">
                            <ShippingZoneNewCountries
                                _handleUpdateShippingZoneState={this._handleUpdateShippingZoneState}
                                onUpdateSelectedCountries={this._handleUpdateSelectedCountries}
                            />
                        </div>

                        <div className="mt-4">
                            <ShippingZoneNewMethods _handleUpdatePlansState={this._handleUpdatePlansState}
                                                    _handleCreatePlan={this._handleCreatePlan}
                                                    _handleRemovePlan={this._handleRemovePlan} plans={plans}/>
                        </div>
                    </div>

                    <div className="mt-4">
                        <ShippingZoneCreate _handleCreateShippingZone={this._handleCreateShippingZone}/>
                    </div>
                </form>

                {
                    !!error && <div className="text-danger text-right mt-2">{error}</div>
                }
            </div>
        )
    }
}

export default ShippingZoneNewPage
