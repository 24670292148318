import createApiServices from "./createApiServices"

const prefixURL = process.env.NODE_ENV === 'production' ? '/api/order' : 'https://fulfillment-staging.merchize.com/api/order'
const api = createApiServices({baseUrl: prefixURL})

export const getListChargeRefund = (data) => {
    return api.makeAuthRequest({
        url: `/orders/request/charge-refund`,
        method : 'POST',
        data
    })
}

export const approvedChargeRefund = (id)=> {
    return api.makeAuthRequest({
        url:`/charge-refund/${id}/approved`,
        method: 'POST',
    })
}

export const rejectedChargeRefund = (id, payload)=> {
    return api.makeAuthRequest({
        url:`/charge-refund/${id}/rejected`,
        method: 'POST',
        data: payload,
    })
}
