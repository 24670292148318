import React, {Component} from 'react'
import PropTypes from 'prop-types'
import {Select} from "antd"
import {PACKAGE_STATUS} from "../../helper/orderFilters"

const {Option} = Select

class PackageStatus extends Component {
    _handleChange = (value) => {
        const {onChangeFilterState} = this.props

        if (typeof onChangeFilterState === 'function') {
            onChangeFilterState({package_status: value})
        }
    }

    render() {
        const {packageStatus} = this.props

        return (
            <div className="PackageStatus">
                <label className='font-weight-500 mb-1'>Package status:</label>
                <Select
                    style={{width: '100%'}}
                    onChange={this._handleChange}
                    defaultValue={packageStatus}
                    value={packageStatus}
                >
                    {
                        PACKAGE_STATUS.map(item => {
                            return <Option value={item.value} key={item.value}>{item.name}</Option>
                        })
                    }
                </Select>
            </div>
        )
    }
}

PackageStatus.propTypes = {
    onChangeFilterState: PropTypes.func,
    packageStatus: PropTypes.string
}

export default PackageStatus