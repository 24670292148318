import React, {Component} from 'react'

class ShippingZoneCreate extends Component {
    render() {
        return (
            <div className="ShippingZoneCreate text-right">
                <button className="btn btn-primary" type="submit">Save changes</button>
            </div>
        )
    }
}

export default ShippingZoneCreate
