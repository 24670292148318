import React from 'react'
import DiscountNewPage from "./DiscountNewPage"

function DiscountNewContainer(props) {
    return (
        <div className="DiscountNewContainer">
            <DiscountNewPage/>
        </div>
    )
}

export default DiscountNewContainer