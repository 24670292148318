import React from 'react'
import { Pagination } from "antd"

const ShippingPricePageSupplier = ({ handlePageChange, error, listShippingSuplier, page, limit, total, loading }) => {

    if (error) return <div className="text-danger">{error}</div>

    return (
        <div className="ShippingPricePageSupplier">
            <div className="wrapTable">
                <table className="table">
                    <thead className="thead-light">
                        <tr>
                            <th scope='col' style={{minWidth : "292px"}}>Product title</th>
                            <th scope='col' style={{minWidth : "108px"}}>Size</th>
                            <th scope='col' style={{minWidth : "108px"}}>Color</th>
                            <th scope='col' style={{minWidth : "240px"}}>SKU supplier</th>
                            <th scope='col' style={{minWidth : "198px"}}>Shipping method</th>
                            <th scope='col' style={{minWidth : "198px"}}>Shipping carrier</th>
                            <th scope='col' style={{minWidth : "198px"}}>Country</th>
                            <th scope='col' style={{minWidth : "198px"}}>Frist item fee</th>
                            <th scope='col' style={{minWidth : "198px"}}>Second item fee</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            listShippingSuplier.length > 0 ? listShippingSuplier.map(item => {
                                const color = Object.assign({}, ...item.options.filter(i => i.attribute_type === 'color'))
                                const size = Object.assign({}, ...item.options.filter(i => i.attribute_type === 'label'))
                                const { shipping_cost, _id, product, partner_sku } = Object.assign({}, item)
                                const shipCost = !!shipping_cost ? shipping_cost.find(i => i) : {}
                                const { shipping_method: method, shipping_carrier: carrier, country_code: country, values: value } = Object.assign({}, shipCost)
                                return (
                                    <tr key={_id}>
                                        <td style={{minWidth : "250px"}}>{product.title}</td>
                                        <td style={{minWidth : "97px"}}>{size.value}</td>
                                        <td style={{minWidth : "98px"}}>{color.value}</td>
                                        <td style={{width : "293px"}}>{partner_sku}</td>
                                        {
                                            (!!shipping_cost && shipping_cost.length > 1) ? (<td colspan="5" classname="costMapp">
                                                {
                                                    !!shipping_cost && shipping_cost.map((cost, i) => {
                                                        const { shipping_method, shipping_carrier, country_code, values } = Object.assign({}, cost)
                                                        return (
                                                            <tr>
                                                                <td style={{minWidth : "200px"}} className={`${i > 0 ? "" : "border-0"}`}>{shipping_method || ""}</td>
                                                                <td style={{minWidth : "200px"}} className={`${i > 0 ? "" : "border-0"} `}>{shipping_carrier || ""}</td>
                                                                <td style={{minWidth : "200px"}} className={`${i > 0 ? "" : "border-0"} `}>
                                                                    {!!country_code ?
                                                                        (country_code.length ? country_code.map((i, x) => (
                                                                            <span>{x > 0 ? ", " : ""}{i}</span>
                                                                        )) : "All") : ""}
                                                                </td>
                                                                <td style={{minWidth : "200px"}} className={` ${i > 0 ? "" : "border-0"} `}>{!!values ? values[0].cost : ""}</td>
                                                                <td  className={` ${i > 0 ? "" : "border-0"} `}>{!!values ? values[1].cost : ""}</td>
                                                            </tr>
                                                        )
                                                    })
                                                }
                                            </td>) : ""
                                        }
                                        {
                                            (!!shipping_cost && shipping_cost.length < 2) ?
                                                <>
                                                    <td style={{minWidth : "212px"}}>
                                                        {!!method ? method : ""}
                                                    </td >
                                                    <td style={{minWidth : "200px"}}>
                                                        {!!carrier ? carrier : ""}
                                                    </td>
                                                    <td style={{minWidth : "200px"}}>{!!country ?
                                                        (country.length > 0 ? country.map((i, x) => (
                                                            <span>{x > 0 ? ", " : ""}{i}</span>
                                                        )) : "All") : ""
                                                    }</td>
                                                    <td style={{minWidth : "200px"}}>
                                                        {!!value ? value[0].cost : ""}
                                                    </td>
                                                    <td>
                                                        {!!value ? value[1].cost : ""}
                                                    </td>
                                                </>: ""
                                        }
                                        {
                                            !shipping_cost && <>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                            </>
                                        }
                                    </tr>
                                )
                            }) : !loading && (
                                <div className="d-flex pt-3 pb-4">
                                    <i className="text-muted">No results</i>
                                </div>
                            )
                        }
                    </tbody>
                </table>
            </div>

            <div className="OrdersPagination d-flex justify-content-end Pagination">
                <Pagination current={page} total={total} pageSize={limit} onChange={handlePageChange}
                    className='text-right' showSizeChanger pageSizeOptions={['10', '20', '50', '100']}
                />
            </div>
        </div>
    )
}

export default ShippingPricePageSupplier