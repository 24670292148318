import React, {useEffect, useState} from 'react'
import {Button, message, Popconfirm, Spin, AutoComplete, Tooltip, Input, Select} from "antd"
import ShippingPriceProvider from "./ShippingPriceProvider"
import ShippingPriceFilters from "./ShippingPriceFilters"
import ShippingPriceTabContent from "./tabs/ShippingPriceTabContent"
import ImportShippingPrice from "./import/ImportShippingPrice"
import ShippingPricePageSupplier from "./ShippingPricePageSupplier"
import {
    getShippingCost,
    updateShippingCost,
    getShippingCarriers,
    removeShippingCarriers,
    getListCountries, 
    getListShippingCostSupplier
} from "../../../services/api/ShippingServices"
import { getListSuppliersV2 } from "../../../services/api/SuppliersServices"
import AddNewCarrier from "./AddNewCarrier"
import queryString from 'query-string'
import UseDebounce from '../helpers/UseDebounce'
import { values } from 'lodash'

function ShippingPricePage() {
    const params = {}

    if (window.location.search !== '') {
        Object.assign(params, {...queryString.parse(window.location.search)})
    }

    const [loading, setLoading] = useState(false)
    const [error, setError] = useState('')
    const [supplier, setSupplier] = useState('1C')
    const [listShippingPrice, setListShippingPrice] = useState([])
    const [tabActive, _setTabActive] = useState(params.carrier || '')
    const [isUpdate, setIsUpdate] = useState(true)
    const [isShowModalNewTab, setIsShowModalNewTab] = useState(false)
    const [shippingCarriers, setShippingCarriers] = useState([])
    const [availableShippingCarriers, setAvailableShippingCarriers] = useState([])
    const [isFetchedPageData, setIsFetchedPageData] = useState(false)
    const [isDuplicateCountry, setDuplicateCountry] = useState(false)
    const [isInvalidRange, setInvalidRange] = useState(false)
    const [product_title, setProduct_title] = useState("")
    const [supplier_sku, setSupplier_sku] = useState("")
    const [shipping_carrier, setShipping_carrier] = useState("")
    const [country_code, setCountry_code] = useState("")
    const [shipping_method, setShipping_method] = useState("")
    const [listSupplier, setListSupplier] = useState([])
    const [listShippingSuplier, setListShippingSuplier] = useState([])
    const [page, setPage] = useState(1)
    const [limit, setLimit] = useState(20)
    const [total, setTotal] = useState(50)

    
    const {_id} = Object.assign({},listSupplier.find(i=> i.sku_prefix === supplier))
    const debouncedTitle = UseDebounce(product_title.trim(), 500)
    const debouncedSku = UseDebounce(supplier_sku.trim().toUpperCase(), 500)
    const debouncedCountryCode = UseDebounce(country_code.trim().toUpperCase(), 500)


    const setTabActive = (tab) => {
        _setTabActive(tab)
        window.history.pushState({}, null, tab ? `?carrier=${tab}` : window.location.href.split('?')[0])
    }

    const _handleChangeTabActive = (value) => (e) => {
        const action = e.target.getAttribute('data-action')

        if (action !== 'remove') {
            setTabActive(value)
        }
    }

    const _handleChangeSupplier = (value) => {
        setSupplier(value)
    }

    const _handlePageChange = (page, pageSize) => {
        setPage(page)
        setLimit(pageSize)
    }

    const _editValues = (indexParent, from, to, value) => {
        if (value !== '' && (typeof parseFloat(value) !== 'number' || isNaN(parseFloat(value)))) {
            return message.error('This is not number, please input number.')
        }

        const newList = listShippingPrice.map((parent, index) => {
            if (index === indexParent) {
                return {
                    ...parent,
                    values: parent.values.map((child) => {
                        if (from === child.from && to === child.to) {
                            return {
                                ...child,
                                cost: value,//parseFloat(value)
                            }
                        } else {
                            return child
                        }
                    }),
                }
            }

            return parent
        })

        setListShippingPrice(newList)
        setIsUpdate(false)
    }

    const _editCountryCode = (indexParent, value) => {
        const hasCountryCode = listShippingPrice.filter(item => item.country_code === value)

        // if (hasCountryCode.length > 0) {
        //     return message.error('Duplicate country code.')
        // }

        const newList = listShippingPrice.map((parent, index) => {
            if (index === indexParent) {
                return {
                    ...parent,
                    country_code: value ? `${value}`.toUpperCase() : '',
                }
            } else {
                return parent
            }
        })

        const groupByCountryCode = newList.reduce((m, row) => {
            return {
                ...m,
                [`${row.country_code}`.toUpperCase()]: m[row.country_code] ? [...m[row.country_code], row] : [row],
            }
        }, {})

        let isError = false
        Object.values(groupByCountryCode).map(items => {
            if (items.length === 1) {
                items[0].isDuplicate = false
            } else {
                items.map(item => item.isDuplicate = `${item.country_code}`.toUpperCase())
                isError = true
            }
        })
        setDuplicateCountry(isError)
        setListShippingPrice(newList)
        setIsUpdate(false)
    }

    const _editValueRange = (indexChild, field, value) => {
        let isError = false

        const newList = listShippingPrice.map((parent) => {
            parent.values[indexChild][field] = value

            return {
                ...parent,
                values: parent.values.map((child, indexItem) => {
                    const obj = {
                        ...child,
                        isError: false,
                    }

                    if (+obj.from > +obj.to || (indexItem && (+obj.from !== +parent.values[indexItem - 1].to))) {
                        obj.isError = true
                        isError = true
                    }

                    if (indexItem < parent.values.length - 1 && (+parent.values[indexItem].to !== +parent.values[indexItem + 1].from)) {
                        obj.isError = true
                        parent.values[indexItem].isError = true
                        parent.values[indexItem + 1].isError = true
                        isError = true
                    }

                    return {
                        ...child,
                        ...obj,
                    }
                }),
            }
        })

        setInvalidRange(isError)
        setListShippingPrice(newList)
        setIsUpdate(false)
    }

    const _addNewColumn = () => {
        const objectDefault = {
            country_code: null,
            supplier: supplier,
            shipping_carrier: tabActive,
            values: [{from: null, to: null, cost: null}],
        }

        if (listShippingPrice.length === 0) {
            return setListShippingPrice([objectDefault])
        }

        const newArr = listShippingPrice.map((item) => {
            const column = {from: null, to: null, cost: null}
            item.values.push(column)

            return item
        })

        setListShippingPrice(newArr)
        setIsUpdate(false)

        setTimeout(() => {
            document.getElementsByClassName('RangeValueFrom')[listShippingPrice[0].values.length - 1].focus()
            document.getElementsByClassName('TabContent')[0].scrollLeft = 99999999
        }, 300)
    }

    const _addNewRow = () => {
        const objectDefault = {
            country_code: null,
            supplier: supplier,
            shipping_carrier: tabActive,
            values: [],
        }

        if (listShippingPrice.length === 0) {
            return setListShippingPrice([objectDefault])
        }

        const firstRow = JSON.parse(JSON.stringify(listShippingPrice[0]))

        firstRow.country_code = null
        firstRow.values.map(item => {
            return item.cost = null
        })

        const newArr = [...listShippingPrice]
        newArr.push(firstRow)

        setListShippingPrice(newArr)
        setIsUpdate(false)
    }

    const _onDeleteColumn = (from, to, index) => {
        const newArr = listShippingPrice.map((item => {
                return {
                    ...item,
                    values: item.values.filter((item, idx) => (item.from !== from && item.to !== to && idx !== index)),
                }
            }
        ))

        setListShippingPrice(newArr)
        setIsUpdate(false)
    }

    const _onDeleteRow = (countryCode, index) => {
        const newArr = listShippingPrice.filter((item, idx) => (item.country_code !== countryCode && idx !== index))

        setListShippingPrice(newArr)
        setIsUpdate(false)
    }

    const _fetchListShippingPrice = async (shippingCarrier) => {
        setError('')
        setListShippingPrice([])

        if (!shippingCarrier) {
            shippingCarrier = tabActive
        }

        if (!shippingCarrier) {
            return
        }

        setLoading(true)

        try {
            const response = await getShippingCost(supplier, supplier === "1C" ? shippingCarrier : "ups")

            const {success, data, message} = response

            if (!success) {
                setLoading(false)

                return setError(message)
            }

            setLoading(false)
            setListShippingPrice(data)
        } catch (e) {
            setLoading(false)
            setError(e.message)
        }
    }

    const _fetchListSupplier = async () => {
        setError('')
        setListSupplier([])
        setLoading(true)

        try {
            const {success, data, message} = await getListSuppliersV2()
            if (!success) {
                setLoading(false)

                return setError(message)
            }

            setLoading(false)
            setListSupplier(data.suppliers)

        } catch (e) {
            setLoading(false)
            setError(e.message)
        }
    }

    const validateCosts = () => {
        let isError = false
        listShippingPrice.forEach(row => {
            const {values} = row
            values.forEach((value) => {
                const {from, to, cost} = {...value}

                if (isNaN(from) || isNaN(to) || isNaN(cost)) {
                    isError = true
                }
            })
        })

        if (isError) {
            setError('Invalid data. Please check again.')

            return false
        }

        return true
    }

    const _updateListShippingCost = async () => {

        setError('')

        if (!validateCosts()) {
            return
        }

        setLoading(true)

        try {
            const response = await updateShippingCost({shipping_carrier: tabActive, shipping_cost: listShippingPrice})

            const {success, message: error} = response

            if (!success) {
                setLoading(false)

                return setError(error)
            }

            setLoading(false)
            message.success('Update success')
            _fetchListShippingPrice()
        } catch (e) {
            setLoading(false)
            setError(e.message)
        }
    }

    const _confirmRefresh = (e) => {
        e.preventDefault()
        _fetchListShippingPrice()
    }

    const _confirmUpdate = (e) => {
        e.preventDefault()
        _updateListShippingCost()
    }

    const showModelNewTab = (isShow) => {
        setIsShowModalNewTab(isShow === undefined ? !isShowModalNewTab : !!isShow)
    }

    const onAddedTab = (data) => {
        setIsShowModalNewTab(false)
        const {
            shippingCostCarriers,
            selectedCarrier,
        } = {...data}
        setShippingCarriers(shippingCostCarriers)
        setTabActive(selectedCarrier.carrier)
    }

    const onCancelNewTab = () => {
        setIsShowModalNewTab(false)
    }

    const _handleRemoveTab = (carrier) => (e) => {

        removeShippingCarriers(supplier, carrier.carrier)
            .then((res) => {
                const {data} = {...res}
                const {shippingCostCarriers} = {...data}
                // const newShippingCarriers = shippingCarriers.filter(sc => sc.carrier !== carrier.carrier)
                // console.log({carrier, newShippingCarriers})

                setShippingCarriers(shippingCostCarriers)
                setTabActive(shippingCostCarriers[0] ? shippingCostCarriers[0].carrier : '')
            })
    }

    const _fetchPageData = async () => {
        setLoading(true)
        setError('')
        setIsFetchedPageData(false)

        try {
            const response = await getShippingCarriers(supplier)
            const {success, message: error, data} = response

            if (!success) {
                setLoading(false)

                return setError(error)
            }

            setLoading(false)

            const {shippingCostCarriers, availableShippingCarriers} = {...data}
            setShippingCarriers(shippingCostCarriers)
            setAvailableShippingCarriers(availableShippingCarriers)

            if (!tabActive && shippingCostCarriers) {
                setTabActive(shippingCostCarriers[0] && shippingCostCarriers[0].carrier)
            }
        } catch (e) {
            setLoading(false)
            setError(e.message)
        }

        setIsFetchedPageData(true)
    }

    const setShippingCarrier = (shippingCarrier) => {
        setTabActive(shippingCarrier)
    }

    const handleChangeSup_sku = e => {
        setSupplier_sku(e.target.value)
        setPage(1)
    }

    const handleChangeShippingMethod = value => {
        setShipping_method(value)
        setPage(1)
    }

    const handleChangeCountry = e => {
        setCountry_code(e.target.value)
        setPage(1)
    }
    
    const handleChangeTitle = e => {
        setProduct_title(e.target.value)
        setPage(1)
    }

    const handleChangeCarrier = value => {
        setShipping_carrier(value)
        setPage(1)
    }

    const _fetchListShippingSupplier = async () => {

        setLoading(true)
        setError("")
        setListShippingSuplier([])

        const payload = {
            page, 
            limit, 
            supplier_sku : debouncedSku, 
            product_title: debouncedTitle, 
            shipping_carrier: shipping_carrier ? shipping_carrier.trim() : '', 
            country_code : debouncedCountryCode, 
            shipping_method : shipping_method ? shipping_method.trim() : ''
        }

        try {
            const { data } = await getListShippingCostSupplier(payload, _id)
            const { message, total, variants } = data
            if (!!variants) {
                setTotal(total)
                setListShippingSuplier(variants)
            } else {
                setError(message)
            }

        } catch (e) {
            setError(e.message)
        } finally {
            setLoading(false)
        }
    }
    const clearShippingMethod = () => {
        setShipping_method('')
        setShipping_carrier('')
    }

    useEffect(() => {
        _fetchPageData()
        _fetchListShippingPrice()
        _fetchListSupplier()

        if(supplier === "DBUS") {
            window.history.pushState({}, null,`?carrier=ups`)
        }else{
            window.history.pushState({}, null,`?carrier=multrans_us`)
        }
    }, [supplier, tabActive])

    useEffect(() => {
        if(supplier === "1C" || supplier === "DBUS") return 

        if(!!_id) _fetchListShippingSupplier()
    },[page, limit, _id, debouncedTitle, debouncedSku, shipping_carrier, debouncedCountryCode, shipping_method])

    const tooltipUpdate = [
        isInvalidRange && 'Invalid weight ranges',
        isDuplicateCountry && 'Duplicate country code',
    ].filter(Boolean).join('.')

    const _getShippingPricePage = supplier => {
        switch(supplier){
            case "1C" :
            case "DBUS":
                return (
                    listShippingPrice && !!listShippingPrice.length && 
                    <ShippingPriceTabContent
                        listShippingPrice={listShippingPrice}
                        error={error}
                        onEditValues={_editValues}
                        onEditCountryCode={_editCountryCode}
                        onEditValueRange={_editValueRange}
                        onAddNewRow={_addNewRow}
                        onAddNewColumn={_addNewColumn}
                        onDeleteRow={_onDeleteRow}
                        onDeleteColumn={_onDeleteColumn}
                        supplier={supplier}
                    />
                )
                
            default :
                return (
                    <ShippingPricePageSupplier
                        error={error}
                        page={page}
                        limit={limit}
                        total={total}
                        loading={loading}
                        handlePageChange={_handlePageChange}
                        listShippingSuplier={listShippingSuplier}
                    />
                )
        }
    }

    return (
        <ShippingPriceProvider
            loadShippingPrice={_fetchListShippingPrice}
            filters={supplier}
            setState={_handleChangeSupplier}
        >
            <div className="ShippingPricePage">
                <div className="d-flex flex-column flex-md-row align-items-md-center justify-content-between pt-3 mb-3">
                    <h1 className="PageTitle">Shipping Cost</h1>
                    <div className="d-flex align-items-center justify-content-end mr-lg-0 mr-3">
                        <ImportShippingPrice shippingCarriers={shippingCarriers} selectedCarrier={tabActive}
                                             setShippingCarrier={setShippingCarrier} 
                                            fetchListShippingSupplier={_fetchListShippingSupplier}/>
                    </div>
                </div>

                <div className="SectionInner">
                    <div className="filter mb-3 align-items-center">
                        <div className="d-flex align-items-center justify-content-between">
                            <ul className="QuickSelect">
                                {supplier === "1C" && <>
                                    {
                                        shippingCarriers.map(shippingCarrier => {
                                            const {carrier, name} = shippingCarrier

                                            return <li
                                                className={tabActive === carrier ? 'Active' : ''}
                                                key={carrier}
                                            >
                                                <span onClick={_handleChangeTabActive(carrier)}>
                                                    {name}
                                                </span>
                                                <Popconfirm
                                                    title={`Are you sure to remove ${shippingCarrier.name}?`}
                                                    onConfirm={_handleRemoveTab(shippingCarrier)}
                                                    okText="Yes"
                                                    cancelText="No"
                                                    //placement="topCenter"
                                                >
                                                    <i className="ti-trash" data-action="remove"
                                                    title="Remove this tab and all shipping cost?"/>
                                                </Popconfirm>
                                            </li>
                                        })
                                    }
                                    <li onClick={showModelNewTab}>
                                        <span>
                                            <i className="btn-add-new ti-plus"/>
                                        </span>
                                    </li>
                                </>}

                                {
                                    (supplier !== "1C" && supplier !== "DBUS") && 
                                    <>
                                        {(supplier === "PF"||supplier === "YC"||supplier === "CW"||supplier === "PL EU")&&<div className="col-auto px-0 mr-3 mb-3" style={{width : "300px"}}>
                                            <div className="font-weight-500 LabelFilter mb-1">Sku supplier:</div>
                                            <Input
                                                style={{width: "100%"}}
                                                placeholder= "Search by sku supplier"
                                                value={supplier_sku}
                                                onChange={handleChangeSup_sku}
                                                allowClear
                                            />
                                        </div>}

                                        {(supplier === "CC"||supplier === "CW")&&<div className="col-auto px-0 mb-3 mr-3" style={{width : "300px"}}>
                                            <div className="font-weight-500 LabelFilter mb-1">Product title:</div>
                                            <Input
                                                style={{width: "100%"}}
                                                placeholder= "Search by product title"
                                                value={product_title}
                                                onChange={handleChangeTitle}
                                                allowClear
                                            />
                                        </div>}

                                        {supplier === "PF"&&<div className="col-auto px-0 mb-3 mr-3" style={{width : "300px"}}>
                                            <div className="font-weight-500 LabelFilter mb-1">Shipping method:</div>
                                            <AutoComplete
                                                options={(shipping_method === undefined || shipping_method === "" ) ? [ 
                                                    {value: "Standard"}, 
                                                    {value: "Express"}, 
                                                    {value: "Overnight"}, 
                                                ] : []}
                                                onChange={handleChangeShippingMethod}
                                                value={shipping_method}
                                                placeholder="Select by shipping method"
                                                style={{width: 300}}
                                                allowClear
                                                onClear={clearShippingMethod}
                                            />
                                        </div>}

                                        {supplier === "YC"&&<div className="col-auto px-0 mb-3 mr-3" style={{width : "300px"}}>
                                            <div className="font-weight-500 LabelFilter mb-1">Shipping carrier:</div>
                                            <AutoComplete
                                                options={(shipping_carrier === undefined || shipping_carrier === "" ) ? [ 
                                                    {value: "Yun Express"}, 
                                                    {value: "DHL eCommerce"}, 
                                                    {value: "USPS"},
                                                    {value: "DHL Express"}, 
                                                    {value: "JCEX"}, 
                                                    {value: "YHT"}, 
                                                ] : []}
                                                onChange={handleChangeCarrier}
                                                value={shipping_carrier || ""}
                                                placeholder="Select shipping carrier"
                                                style={{width: 300}}
                                                allowClear
                                                onClear={clearShippingMethod}
                                            />
                                        </div>}

                                        <div className="col-auto px-0 mb-3 mr-3" style={{width : "300px"}}>
                                            <div className="font-weight-500 LabelFilter mb-1">Country:</div>
                                            <Input
                                                style={{width: "100%"}}
                                                placeholder= "Search by country code"
                                                value={country_code}
                                                onChange={handleChangeCountry}
                                                allowClear
                                            />
                                        </div>
                                    </>
                                }
                            </ul>
                            <ShippingPriceFilters
                                setProduct_title={setProduct_title}
                                setSupplier_sku={setSupplier_sku}
                                setShipping_carrier={setShipping_carrier}
                                setCountry_code={setCountry_code}
                                setShipping_method={setShipping_method}
                                setPage={setPage}
                            />
                        </div>
                    </div>
                    <div className="BodyPage">
                        {
                            error && <div className="text-danger p-3 border-top">{error}</div>
                        }

                        <div className="Content">
                            {
                                <Spin spinning={loading} tip="Getting data...">
                                    {
                                        _getShippingPricePage(supplier)
                                    }
                                </Spin>
                            }
                        </div>

                        {(supplier === "1C") &&
                            <div className="Actions">
                                <Popconfirm
                                    title="Are you sure to refresh ?"
                                    onConfirm={_confirmRefresh}
                                    okText="Yes"
                                    cancelText="No"
                                    placement="topLeft"
                                >
                                    <Button type="default" size="large" disabled={isUpdate}>Refresh</Button>
                                </Popconfirm>

                                <Popconfirm
                                    title="Are you sure to update ?"
                                    onConfirm={_confirmUpdate}
                                    okText="Yes"
                                    cancelText="No"
                                    placement="topLeft"
                                    s
                                >
                                    <Tooltip title={tooltipUpdate}>
                                        <Button type="primary" size="large"
                                                disabled={isUpdate || isInvalidRange || isDuplicateCountry}>Update</Button>
                                    </Tooltip>
                                </Popconfirm>
                            </div>
                        }
                    </div>
                </div>
            </div>
            {
                isShowModalNewTab && 
                <AddNewCarrier onOk={onAddedTab} onCancel={onCancelNewTab} shippingCarriers={shippingCarriers}
                               availableShippingCarriers={availableShippingCarriers} supplier={supplier}/>
            }
        </ShippingPriceProvider>
    )
}

export default ShippingPricePage
