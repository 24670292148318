const shipByDays = Array.from({length: 22}).map((_, a) => a)
const dataColumns = {
    'unknown_count': '',
    'exception_count': ['exception_trackingmore', 'exception_easypost', 'exception_aftership', "exception"],
    'out_for_delivery_count': '',
    'pre_transit_count': '',
    'in_transit_count': '',
    'delivered_count': '',
    'total_count': '',
    'overtime_ship_count': [
        [
            "unknown",
            "pre_transit",
            "in_transit",
            "out_for_delivery",
            "exception_trackingmore",
            "exception_easypost",
            "exception_aftership",
            "exception",
        ],
    ],
    'ship_greater_than_13_days_count': '',
    'ship_greater_than_16_days_count': '',
    'ship_greater_than_21_days_count': '',
}

/**
 *
 * @param {Object} args
 * @param {String} args.suppliers
 * @param {String} args.shipping_carrier
 * @param {Number} args.delayed_days
 * @param {String} args.tracking_status
 * @param {String} args.tracking_company
 * @param {Object} filters
 * @return {string}
 */
const getPermalink = (args = {}, filters = {}) => {
    const mappingValues = {
        shipping_level: {
            Priority: 60,
            'First class': 50,
        },
    }
    const query = ['suppliers', 'shipping_carrier', 'tracking_status', 'delay', 'tracking_company', 'shipping_level', 'shipping_zone']
        .filter(p => args[p] !== undefined)
        .map(p => {
            let val = args[p]

            if (mappingValues[p] && mappingValues[p][val] !== undefined) {
                val = mappingValues[p][val]
            }

            return `${p}=${val}`
        }).join('&')
    let baseLink = `/a/orders-supplier?show_archive=show_all&package_status=in_transit,pre_transit,delivered`
    const filterByDays = args.delay && (
        `${args.delay}`.match(/ship_greater_than_([0-9]+)_days_count/) ||
        `${args.delay}`.match(/ship_delay_gt_days_([0-9]+)/)
    )

    if (!filterByDays) {
        baseLink += ',exception,reshipped-vn,reshipped,refund,lost'
    }

    if (filters.packaged_at) {
        baseLink = `${baseLink}&packaged_at.from=${filters.packaged_at.from}&packaged_at.to=${filters.packaged_at.to}`
    }

    if (args.store) {
        baseLink += `&namespaces=${args.store}`
    }

    return [baseLink, query].filter(Boolean).join('&')
}

const getFilterTrackingStatus = (name) => {
    const query = {}
    const isShipByDays = shipByDays.map(days => `ship_greater_than_${days}_days_count`).includes(name) || shipByDays.map(days => `ship_${days}_days_count`).includes(name)

    if (!dataColumns[name] && !isShipByDays) {
        //return name.replace(/_count/, '')
        Object.assign(query, {
            tracking_status: name.replace(/_count/, ''),
        })
    }

    if (Array.isArray(dataColumns[name])) {
        Object.assign(query, {tracking_status: dataColumns[name].join(',')})
    }

    if (dataColumns[name] && dataColumns[name].hasOwnProperty('status')) {
        Object.assign(query, {
            tracking_status: Array.isArray(dataColumns[name]['status']) ? dataColumns[name]['status'].join(',') : dataColumns[name]['status'],
        })
    }

    if (name === 'overtime_ship_count') {
        Object.assign(query, {delay: 'ship_delay_from_completed_at'})
    } else {
        const m = name.match(/ship_greater_than_([0-9]+)_days_count/) || name.match(/ship_([0-9]+)_days_count/)
        m && Object.assign(query, {
            delay: `ship_delay_gt_days_${m[1]}`,
        })
    }

    return query
}

export {
    dataColumns,
    getPermalink,
    getFilterTrackingStatus,
    shipByDays
}