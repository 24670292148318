import React from 'react'
import PropTypes from 'prop-types'

ProductVariantsAction.propTypes = {
    loading: PropTypes.bool,
    error: PropTypes.string,
    onUpdateVariants: PropTypes.func.isRequired,
    onDiscardChangeState: PropTypes.func.isRequired
}

function ProductVariantsAction(props) {
    const {loading, error, onUpdateVariants, onDiscardChangeState, disableAll} = props

    const _handleClickUpdate = () => {
        if (typeof onUpdateVariants === 'function') {
            onUpdateVariants()
        }
    }

    const _handleClickDiscard = () => {
        if (typeof onDiscardChangeState === 'function') {
            onDiscardChangeState()
        }
    }

    return (
        <div className='ProductVariantsAction'>
            {
                !!error && <div className="text-danger text-right mb-3">{error}</div>
            }
            {!disableAll &&
                <div className="d-flex justify-content-end">
                    <button className="btn btn-secondary mr-3" onClick={_handleClickDiscard}>
                        Discard
                    </button>
                    <button className="btn btn-primary" onClick={_handleClickUpdate}>
                        {loading ? "Saving..." : "Save variants"}
                    </button>
                </div>
            }
        </div>
    )
}

export default ProductVariantsAction
