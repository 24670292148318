import React, {Component} from 'react'
import WebhookTableHead from './WebhookTable/WebhookTableHead'
import WebhookTableRow from './WebhookTable/WebhookTableRow'
import PropTypes from 'prop-types'
import {Spin} from 'antd'

class WebhookTable extends Component {
    state = {
        allSelected: false,
        selectedItems: [],
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (!!this.props.webhookItems && !!prevProps.webhookItems) {
            const prevItems = prevProps.webhookItems.map(item => item._id)
            const currItems = this.props.webhookItems.map(item => item._id)

            if (JSON.stringify(prevItems) !== JSON.stringify(currItems)) {
                this.setState({
                    allSelected: false,
                    selectedItems: [],
                })
            }
        }
    }

    _toggleSelectAll = (isChecked) => {
        this.setState(() => {
            if (isChecked) {
                const {webhookItems} = this.props
                const ids = webhookItems.filter(item => !item.is_sent).map(item => item._id)

                return {
                    selectedItems: ids,
                    allSelected: true,
                }
            }

            return {
                selectedItems: [],
                allSelected: false,
            }
        })
    }

    _updateWebHookSelected = (id, isAdded) => {
        const {selectedItems} = this.state
        const originWebhook = this.props.webhookItems

        if (isAdded) {
            if (!selectedItems.includes(id)) {
                this.setState({
                    selectedItems: [...selectedItems, id],
                    allSelected: selectedItems.length + 1 === originWebhook.length,
                })
            }
        } else {
            const filtered = selectedItems.filter(orderId => orderId !== id)

            this.setState({
                selectedItems: filtered,
                allSelected: false,
            })
        }
    }

    render() {
        const {webhookItems, loading, ...rest} = this.props
        const {allSelected, selectedItems} = this.state

        return (
            <div className="WebhookTable">
                <Spin tip='Loading...' spinning={loading}>
                    <div className="wrapTable">
                        <table className='table'>
                            <WebhookTableHead
                                isChecked={allSelected}
                                selectedItems={selectedItems}
                                onToggleSelectAll={this._toggleSelectAll}
                                {...rest}
                            />

                            <tbody>
                            {
                                !!webhookItems && webhookItems.length > 0 ?
                                    webhookItems.map(webhook => {
                                        return <WebhookTableRow
                                            {...rest}
                                            webhook={webhook}
                                            key={webhook._id}
                                            selectedItems={selectedItems}
                                            onUpdateWebHookSelected={this._updateWebHookSelected}
                                        />
                                    })
                                    :
                                    <tr>
                                        <td colSpan={20}><i>No Webhook</i></td>
                                    </tr>
                            }
                            </tbody>
                        </table>
                    </div>
                </Spin>
            </div>
        )
    }
}

WebhookTable.propTypes = {
    webhookItems: PropTypes.array.isRequired,
    loading: PropTypes.bool.isRequired,
}

export default WebhookTable
