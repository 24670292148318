import React, {Component} from 'react'
import PropTypes from 'prop-types'
import {Input, Select} from 'antd'


class SearchGroupMultiple extends Component {
    constructor(props) {
        super(props);

        const selected = this._findSelectedValues()
        const {searchField, searchValue} = selected

        this.state = {
            searchField,
            searchValue,
        }

        this._timeOut = null
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const {options, defaultOption, search} = this.props
        if (prevProps.reset !== this.props.reset && this.props.reset) {
            this.setState({
                searchValue: '',
                searchField: options[0].key,
            })
        }
        if(defaultOption!== undefined && search!== undefined){
            if(prevProps.search[0] !== prevState.searchValue && prevProps.defaultOption === "tags"){
                this.setState({
                    searchValue: search[0] ? search[0] : '',
                    searchField: defaultOption !== undefined ? defaultOption :  options[1].key,
                })
            }
        }
    }

    _handleSelect = value => {
        if (this.state.searchField !== value) {
            this.setState({
                searchField: value,
                searchValue: '',
            }, () => {
                // if (this.state.searchValue.length > 0) {
                    this._reqSearchOrders()
                // }
            })
        }
    }

    _handleChange = (e) => {
        const {value} = e.target

        this.setState({
            searchValue: value,
        }, () => {
            if (this._timeOut) clearTimeout(this._timeOut)

            this._timeOut = setTimeout(() => {
                this._reqSearchOrders()
            }, 500)
        })
    }

    _findSelectedValues = () => {
        const {options} = this.props
        const selected = options.find(option => option.value.length > 0)

        if (selected === undefined) {
            return {
                searchField: options[0].key,
                searchValue: '',
            }
        }

        return {
            searchField: selected.key,
            searchValue: selected.value,
        }
    }

    _reqSearchOrders = () => {
        let {searchField, searchValue} = this.state
        const {onSearch, options} = this.props

        // if (searchField === 'tags') {
            searchValue = searchValue.split(',').map(tag => tag.trim()).filter(Boolean)
        // }

        const filter = options.reduce((acc, option) => {
            if (searchField !== option.key) {
                return {...acc, [option.key]: ''}
            }

            return {...acc, [searchField]: searchValue}
        }, {})

        onSearch(filter)
    }

    render() {
        const {options} = this.props
        const {searchField, searchValue} = this.state
        return (
            <div 
                className='SearchGroup mb-3 mb-md-0 mr-3'
                style={{
                    marginTop: '25px'
                }}
            >
                <Input.Group compact>
                    <Select value={ searchField} onSelect={this._handleSelect} style={{width: 130}}>
                        {
                            options.map(option => {
                                const {title, key} = option

                                return <Select.Option key={`search_${key}`} value={key}>{title}</Select.Option>
                            })
                        }
                    </Select>
                    <Input.Search style={{width: 300}} value={searchValue} placeholder='Type to search...'
                                  onChange={this._handleChange}/>
                </Input.Group>
            </div>
        )
    }
}

SearchGroupMultiple.defaultProps = {
    options: [],
}

SearchGroupMultiple.propTypes = {
    options: PropTypes.arrayOf(PropTypes.object).isRequired,
    onSearch: PropTypes.func.isRequired,
}

export default SearchGroupMultiple
