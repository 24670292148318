import React, {useEffect, useState} from 'react'
import ReportOverInTransitV2 from "./ReportOverInTransitV2"
import ReportOverShipV2 from "./ReportOverShipV2"
import {getShipmentReport} from "../../../../services/api/SuppliersServices"
import ReportPreTransit from "./ReportPreTransit"

function ShipmentReport(props) {
    const [allCarriersData, setAllCarriersData] = useState({})
    const [carriersData, setCarriersData] = useState({})
    const [error, setError] = useState('')
    const [loading, setLoading] = useState(false)

    async function _fetchReportShipment() {
        try {
            setLoading(true)
            setError('')

            const response = await getShipmentReport()

            const {success, data, message} = response

            if (!success) {
                setLoading(false)
                return setError(message)
            }

            const {carriersData, allCarriersData} = data

            setLoading(false)
            setAllCarriersData(allCarriersData)
            setCarriersData(carriersData)

        } catch (e) {
            setLoading(false)
            setError(e.message)
        }
    }

    useEffect(() => {
        _fetchReportShipment()
    }, [])

    return (
        <div className="ShipmentReports">
            <div className="mb-5">
                <ReportOverShipV2 error={error} loading={loading} allCarriersData={allCarriersData}
                                  carriersData={carriersData}/>
            </div>
            <div className="mb-5">
                <ReportOverInTransitV2 error={error} loading={loading} allCarriersData={allCarriersData}
                                       carriersData={carriersData}/>
            </div>
            <div className="mb-5">
                <ReportPreTransit/>
            </div>
        </div>
    )
}

export default ShipmentReport
