import React, { useState } from 'react'
import { Popover } from 'antd'

const Truncate = ({ string, length = 20, text, placement }) => {
    const [visible, setVisible] = useState(false)

    const _onToggleVisible = (visible) => {
        setVisible(visible)
    }

    if (!string) return null

    if (string.length > length) {
        return (
            <Popover
                placement={placement || 'topLeft'}
                className="CustomPopover"
                content={string}
                title={text}
                trigger="click"
                visible={visible}
                onVisibleChange={_onToggleVisible}
            >
                <span className="ShowMore">{length && length > 0 ? string.substring(0, length) + '...' : string}</span>
            </Popover>
        )
    }

    return <span>{string}</span>
}

export default Truncate