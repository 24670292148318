import React, { useState } from 'react'
import BulkChangeUser from "./BulkChangeUser"

const MappingConditionTableHead = ({ selectedAll, handleChangeSelectAll, listSelecteds, reload, listMappingCondition, onChangeMapper, permissionsData }) => {

    const [showModal, setShowModal] = useState(false)
    const [showAction, setShowAction] = useState(false)

    const _showModal = () => {
        setShowModal(true)
    }

    const _toggleAction = () => {
        setShowAction(!showAction)
    }

    const _hiddenModal = () => {
        setShowModal(false)
        handleChangeSelectAll(false)
        setShowAction(false)
    }

    const _handleChangeSelectAll = (e) => {
        const { checked } = e.target

        if (typeof handleChangeSelectAll === 'function') {
            handleChangeSelectAll(checked)
        }
    }

    return (
        <thead className="ShippingsTableHead">
            <tr>
                {permissionsData.includes('ffm_mapping_condition_update') &&
                    <th className="RowIndex">
                        <div className="BulkActions">
                            <input type="checkbox" checked={selectedAll} onChange={_handleChangeSelectAll} />
                            <div className="Actions">
                                <i className="ti-angle-down" onClick={_toggleAction} />
                                <ul className={`ListActions ${showAction ? 'd-block' : ''} ${listSelecteds.length > 0 ? '' : 'Disable'}`}>
                                    <li className="ActionItem" onClick={_showModal}>Change user mapping</li>
                                </ul>
                                {
                                    showAction && !showModal && <div className="Background" onClick={_toggleAction} />
                                }
                            </div>
                        </div>
                    </th>
                }
                <th className="Store">Namespace</th>
                <th className="User">Mapper</th>
                <th className="MappingContext">Mapping Context</th>
                <th className="UpdatedAt">Update at</th>
                {permissionsData.includes('ffm_mapping_condition_update') && 
                    <th className="Action">Action</th>
                }
            </tr>

            {
                showModal && <BulkChangeUser
                    visible={showModal}
                    onCancel={_hiddenModal}
                    listSelecteds={listSelecteds}
                    reload={reload}
                    listMappingCondition={listMappingCondition}
                    onChangeMapper={onChangeMapper}
                />
            }
        </thead>
    )
}

export default MappingConditionTableHead