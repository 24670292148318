import React from 'react'
import PropTypes from 'prop-types'
import {DatePicker, Select} from 'antd'

const {Option} = Select
const {RangePicker} = DatePicker

SwitchCarrierFilters.propTypes = {
    status: PropTypes.string,
    onChangeDateCreated: PropTypes.func.isRequired
}

function SwitchCarrierFilters(props) {
    function _handleChangeDate(value, dateString) {
        const {onChangeDateCreated} = props

        if (typeof onChangeDateCreated === 'function') {
            onChangeDateCreated(dateString)
        }
    }

    function _handleChangeStatus(value) {
        const {onChangeStatus} = props

        if (typeof onChangeStatus === 'function') {
            onChangeStatus(value)
        }
    }

    return (
        <div className='RequestFilters mb-3'>
            <div className="Filters">
                <div className='FilterDateRange'>
                    <label htmlFor="createdAt">Created at:</label>
                    <RangePicker
                        id='createdAt'
                        dropdownClassName='CreatedJobs'
                        format="DD/MM/YYYY"
                        placeholder={['Created from', 'Created to']}
                        onChange={_handleChangeDate}
                    />
                </div>
                <div className='FilterStatus'>
                    <label htmlFor="createdAt">Status:</label>
                    <Select defaultValue={props.status} style={{width: 120}} onChange={_handleChangeStatus}>
                        <Option value=''>All</Option>
                        <Option value="created">Created</Option>
                        <Option value="processing">Processing</Option>
                        <Option value="done">Done</Option>
                    </Select>
                </div>
            </div>
        </div>
    )
}

export default SwitchCarrierFilters
