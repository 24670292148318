export function _arrayUnique(array) {
    const a = array.concat()

    for (let i = 0; i < a.length; ++i) {
        for (let j = i + 1; j < a.length; ++j) {
            if (a[i] === a[j])
                a.splice(j--, 1)
        }
    }

    return a
}

export function _convertString(string) {
    const regex = /\s|,\s|,|\n/
    const parsed = string.split(regex)

    return !!parsed && parsed.length > 0 && parsed.filter(item => item !== "")
}

export function _checkString(string) {
    const regex = /\s|,\s|,|\n/gi

    return regex.test(string)
}