import React, {Component} from 'react'
import ChangePasswordPage from './ChangePasswordPage'

class ChangePasswordPageContainer extends Component {
    render() {
        return (
            <div className='ChangePasswordPageContainer bg-light vh-100'>
                <ChangePasswordPage/>
            </div>
        )
    }
}

export default ChangePasswordPageContainer
