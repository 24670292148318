import React, { useState, useEffect } from 'react'
import { Modal, Checkbox, Spin, Button } from 'antd'
import moment from 'moment'


function SelectItemsModal(props) {
    const { listItem, packageName, handleChangeSelectAll, handleSelected, handleAddNewItems, listAddeds, handleCloseModal, handleHover, handleLeave, ...rest } = { ...props }
    const [listData, setListData] = useState(listItem || [])
    const [loading, setLoading] = useState(false)
    const [selectedAll, setSelectedAll] = useState(false)
    const [listSelecteds, setListSelecteds] = useState(listAddeds || [])
    const [showAction, setShowAction] = useState(false)

    const _handleChangeSelectAll = (isActive = false) => {
        if (isActive) {
            const listSelectedId = listData.map((item, index) => !!item.showItem && index)
            setListSelecteds(listSelectedId)
            setSelectedAll(true)
        } else {
            setListSelecteds([])
            setSelectedAll(false)
        }
    }

    const _handleSelected = (index, isAdded) => {
        if (isAdded) {
            if (!listSelecteds.includes(index)) {
                setListSelecteds([...listSelecteds, index])
                setSelectedAll(listSelecteds.length + 1 === listData.length)
            }
        } else {
            const filtered = listSelecteds.filter((item) => item !== index)

            setListSelecteds(filtered)
            setSelectedAll(false)
        }
    }

    const handleSaveSelect = async () => {
        handleAddNewItems(listSelecteds, listData)
        setListSelecteds([])
        _handleCloseModal()
    }

    const _handleGroupIssue = async () => {
        handleAddNewItems(listSelecteds, listData, false, false, true)
        setListSelecteds([])
        _handleCloseModal()
    }

    const _handleCloseModal = () => {
        setSelectedAll(false)
        setListSelecteds([])
        handleCloseModal()
    }

    useEffect(() => {
        if (rest.visible && packageName) {
            setListData(listItem.map((i, index) => {
                return { ...i, showItem: !listAddeds.includes(index) }
            }))
            setListSelecteds([])
            setSelectedAll(false)
        }
    }, [listItem, rest.visible])

    const handleHoverBarcode = (image) => {
        handleHover(image)
    }

    const handleLeaveBarcode = () => {
        handleLeave()
    }

    const _toggleAction = () => {
        setShowAction(!showAction)
    }

    return (
        <Modal
            {...rest}
            onOk={handleSaveSelect}
            onCancel={_handleCloseModal}
            footer={[
                <Button key="back" onClick={_handleCloseModal}>
                    Cancel
                </Button>,
                <Button key="submitGroup" type="primary" onClick={_handleGroupIssue} disabled={!listSelecteds || listSelecteds.filter(i => !!i || i === 0).length <= 1}>
                    Group issue items
                </Button>,
                <Button key="submit" type="primary" onClick={handleSaveSelect} disabled={!listSelecteds || listSelecteds.length < 1}>
                    Save
                </Button>,
            ]}
        >
            <Spin spinning={loading} tip='Loading...'>
                <div className="modalContent">
                    <table className='IssueItemsTable table'>
                        <thead>
                            <tr>
                                <th>
                                    <div className="BulkActions">
                                        <Checkbox checked={selectedAll} onChange={(e) => _handleChangeSelectAll(e.target.checked)} />
                                        {/* <div className="Actions">
                                            <i className="ti-angle-down" onClick={_toggleAction} />
                                            <ul className={`ListActions ${showAction ? 'd-block' : ''} ${listSelecteds.length > 0 ? '' : 'Disable'}`}>
                                                <li className="ActionItem Disable" onClick={() => { }}>Group issue items</li>
                                            </ul>
                                            {
                                                showAction && <div className="Background" onClick={_toggleAction} />
                                            }
                                        </div> */}
                                    </div>
                                </th>
                                <th>Reference number</th>
                                <th>Supplier</th>
                                <th>Product type</th>
                                <th>Variant</th>
                                <th>Quantity</th>
                                <th>Tracking</th>
                            </tr>
                        </thead>
                        <tbody>
                            {!!listData && listData.length > 0 ? listData.map((i, index) => {
                                const { supplier, reference_number, mockup, variant_data, tracking_number, pushed_at, quantity, showItem } = Object.assign({}, i)
                                const { name } = Object.assign({}, supplier)
                                const { product_type, size, color } = Object.assign({}, variant_data)

                                return showItem && (
                                    <tr key={`packageItem_${index}`}>
                                        <td><Checkbox checked={listSelecteds.includes(index)} onChange={() => _handleSelected(index, !listSelecteds.includes(index))} /></td>
                                        <td>
                                            <div className="referenceNumber text-primary">
                                                <span className='referenceNumberText' onMouseOver={() => handleHoverBarcode(mockup)} onMouseLeave={handleLeaveBarcode}>
                                                    {reference_number}
                                                </span>

                                            </div>
                                            <div className="pushedAt">{pushed_at ? moment(pushed_at).format('HH:mm DD/MM/YYYY') : ''}</div>
                                        </td>
                                        <td>{name}</td>
                                        <td>{product_type}</td>
                                        <td>{(size || color) ?
                                            `${size || ''}${size && color ? '/' : ''}${color || ''}` : ''
                                        }
                                        </td>
                                        <td>{quantity}</td>
                                        <td className='text-primary'>{tracking_number}</td>
                                    </tr>
                                )
                            }) :
                                <tr>
                                    <td colSpan={7}>No Issue item</td>
                                </tr>
                            }
                        </tbody>
                    </table>
                </div>
            </Spin>
        </Modal>
    )
}

export default SelectItemsModal