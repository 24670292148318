import React, {Component} from 'react'

class ShippingZoneNewDescription extends Component {
    _handleChangeInput = e => {
        const {value} = e.target

        this.props._handleUpdateShippingZoneState("description", value)
    }

    render() {
        return (
            <div className="ShippingZoneNewDescription">
                <div className="row">
                    <div className="col-md-4">
                        <label htmlFor="shippingZoneDescription" className="font-weight-500">Description</label>
                    </div>

                    <div className="col-md-8">
                        <textarea name="" id="shippingZoneDescription" rows="5" className="form-control" onChange={this._handleChangeInput}/>
                    </div>
                </div>
            </div>
        )
    }
}

export default ShippingZoneNewDescription
