import React from 'react'

const ItemErrorMessage = ({ error }) => {
    return (
        <td className='ItemErrorMessage text-danger'>
            {!!error && error}
        </td>
    )
}

export default ItemErrorMessage