import React, {Component} from 'react'
import PropTypes from 'prop-types'
import { getListSuppliersV2 } from "../../../services/api/SuppliersServices"
import {Select} from "antd"

const {Option} = Select

class SuppliersSelector extends Component {
    state = {
        options: [],
        err: '',
        page: 1,
        limit: -1,
        input: '',
        message: ''
    }

    componentDidMount() {
        this._getCategories()
    }

    _getCategories = async () => {
        return new Promise(async resolve => {

            try {
                const {success, data, message} = await getListSuppliersV2()
                if (!success) {
                    return this.setState({
                        err: message
                    })
                }
                const {suppliers} = data
                this.setState(
                    () => ({
                        options: suppliers.map((supplier, index) => {
                            return {
                                id: supplier._id,
                                value: index,
                                label: supplier.name
                            }
                        })
                    }),
                    () => resolve(this.state.options)
                )
            } catch (e) {
                this.setState({
                    err: e.message || e
                })
            }
        })
    }


    _handleChange = option => {
        const _selectedSupplier = (option !== undefined) ? option : ''
        this.props.handleSelectSupplier(_selectedSupplier)
    }

    render() {
        const {options, message, err} = this.state
        const {supplier} = this.props
        return (
            <div className="SuppliersSelector w-auto">
                <Select
                    size={'large'}
                    showSearch
                    allowClear
                    showArrow
                    defaultValue={supplier}
                    value={supplier || []}
                    style={{width: 200}}
                    onChange={this._handleChange}
                    placeholder={'Select Supplier'}
                    filterOption={(input, option) => 
                        option.children.toLocaleLowerCase().includes((input || "").toLocaleLowerCase())
                    }
                >
                    {
                        options.map(item => {
                            return <Option value={item.id} key={item.id}>{item.label}</Option>
                        })
                    }
                </Select>
                {err && (
                    <p className='text-danger'>{err}</p>
                )}
                {message &&
                <p className='text-primary'>{message}</p>
                }
            </div>
        )
    }
}

SuppliersSelector.propTypes = {
    handleSelectSupplier: PropTypes.func.isRequired,
}

export default SuppliersSelector
