import React, {Component} from 'react'

class ShippingZoneSelectedCountries extends Component {
    _handleClickRemove = e => {
        const countryId = e.target.getAttribute("data-id")

        this.props.onUpdateCountriesState(countryId, false, true)
    }

    render() {
        const {selectedCountries} = this.props
        return (
            selectedCountries.length > 0 &&
            <div className="ShippingZoneSelectedCountries mb-2">
                <ul className="ListCountries">
                    {
                        selectedCountries.map((countryObj) => {
                            const {country} = countryObj

                            return (
                                <li key={country._id} className="d-inline-block mr-2">
                                    <span className="badge badge-info p-2">{country.title}<i
                                        className="fas fa-times cursor-pointer ml-2" data-id={country._id}
                                        onClick={this._handleClickRemove}/></span>
                                </li>
                            )
                        })
                    }
                </ul>
            </div>
        )
    }
}

export default ShippingZoneSelectedCountries
