import React from 'react'
import { Table } from "reactstrap"
import InputTemplate from "../../../order/components/fulfilled/previewAddress/InputTemplate"

const CodAddress = (props) => {
    const { previews, onChangePreview } = props
    const { Marketplace, orderNumber, name, address1, ghtk_info, phone } = previews
    const { province, district, ward } = ghtk_info

    return (
        <Table responsive>
            <thead className="HeadAddress">
                <th>Marketplace</th>
                <th>Order Number</th>
                <th>Name</th>
                <th>Address 1</th>
                <th>Address 2</th>
                <th>City</th>
                <th>State</th>
                <th>Country</th>
                <th>Phone</th>
                <th>Postal Code</th>
                <th>QShip</th>
            </thead>
            <tbody>
                <tr>
                    <InputTemplate
                        type='cod'
                        value={Marketplace}
                        placeholder="Marketplace"
                        field="Marketplace"
                        previews={previews}
                        onChangePreview={onChangePreview}
                    />
                    <InputTemplate
                        type='cod'
                        value={orderNumber}
                        placeholder="Order Number"
                        field="orderNumber"
                        previews={previews}
                        onChangePreview={onChangePreview}
                    />
                    <InputTemplate
                        type='cod'
                        value={name}
                        placeholder="name"
                        field="name"
                        previews={previews}
                        onChangePreview={onChangePreview}
                    />
                    <InputTemplate
                        type='cod'
                        value={address1}
                        placeholder="Address 1"
                        field="address1"
                        previews={previews}
                        onChangePreview={onChangePreview}
                    />
                    <InputTemplate
                        type='cod'
                        value={province}
                        placeholder="Province"
                        field="province"
                        previews={previews}
                        onChangePreview={onChangePreview}
                    />
                    <InputTemplate
                        type='cod'
                        value={district}
                        placeholder="District"
                        field="district"
                        previews={previews}
                        onChangePreview={onChangePreview}
                    />
                    <InputTemplate
                        type='cod'
                        value={ward}
                        placeholder="Ward"
                        field="ward"
                        previews={previews}
                        onChangePreview={onChangePreview}
                    />
                    <InputTemplate
                        type='cod'
                        value={phone}
                        placeholder="Phone"
                        field="phone"
                        previews={previews}
                        onChangePreview={onChangePreview}
                    />
                </tr>
            </tbody>
        </Table>
    )
}

export default CodAddress