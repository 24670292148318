import React from 'react'
import { Drawer, Pagination, Collapse, List } from 'antd'
import { Link } from "react-router-dom"


const OrdersMissFailed = ({
    onIsCloseOrderFailed, openOrderFailed, listOrderFailed, totalsFailed, handlePageChange, pageOrderMiss, onSearchTagMuniFest, newFBAOrders, 
    FBAUpdateLabel, FBAUpdateBoxInfo, totalFBAWarning, waitingPaidOrders, waitingMappingOrders, waitingFulfillOrders, validateWarnings,
}) => {

    const { Panel } = Collapse
    const listFBAWarning = [
        ...newFBAOrders.map(i => ({ _id: i._id, id: i.id, note: 'is new FBA order.' })),
        ...FBAUpdateLabel.map(i => ({ _id: i._id, id: i.id, note: 'have update label.' })),
        ...FBAUpdateBoxInfo.map(i => ({ _id: i._id, id: i.id, note: 'have update box infomation.' })),
    ]

    const handleSearchTagMuniFest = (manifest_id) => {
        onSearchTagMuniFest({tags : [`ManifestID: ${manifest_id}`]}, false, "tags")
        onIsCloseOrderFailed()
    }

    return (
        <div className='OrdersMissFailed'>
            <Drawer
                title={`Orders warning messages`}
                placement="left"
                onClose={onIsCloseOrderFailed}
                visible={openOrderFailed}
                className='p-0 OrdersWarnings'
                bodyStyle={{ padding: 0 }}
                width={'377px'}
            >
                <Collapse accordion>
                    {listOrderFailed.length > 0 && (
                        <Panel header={`Orders miss failed (${totalsFailed || ''})`} key="1" showArrow={false}>
                            <ul style={{ listStyle: 'auto' }}>
                                {
                                    listOrderFailed.map(item => (
                                        <li className="px-2 py-2" key={item._id}>
                                            <Link to={{ pathname: `/a/orders-supplier/${item._id}` }}>
                                                {item.name}
                                            </Link>
                                        </li>
                                    ))
                                }
                            </ul>

                            <Pagination
                                onChange={handlePageChange}
                                className='text-right mt-3'
                                size="small"
                                pageSize={5}
                                current={pageOrderMiss}
                                total={totalsFailed}
                            />
                        </Panel>
                    )
                    }

                    {totalFBAWarning > 0 &&
                        <Panel header={`FBA orders warning (${totalFBAWarning || ''})`} key="2" showArrow={false}>
                            <List
                                pagination={{
                                    className: 'text-right mt-3 p-0',
                                    size: "small",
                                    pageSize: 5,
                                }}
                                dataSource={listFBAWarning}
                                renderItem={(item, index) => (
                                    <li className="px-2 py-2" key={index}>
                                        <Link to={{ pathname: `/a/orders/${item._id}` }} className='mr-1'>
                                            {item.id}
                                        </Link>
                                        {item.note}
                                    </li>
                                )}
                            />
                        </Panel>
                    }

                    {validateWarnings.length > 0 &&
                        <Panel header={`Order Manifest quantity warning (${validateWarnings.length || ''})`} key="3" showArrow={false}>
                            <List
                                pagination={{
                                    className: 'text-right mt-3 p-0',
                                    size: "small",
                                    pageSize: 5,
                                }}
                                dataSource={validateWarnings}
                                renderItem={(item, index) => (
                                    <li className="px-2 py-2" key={index}>
                                        <Link 
                                            to="#" 
                                            className='mr-1'
                                            onClick={() => handleSearchTagMuniFest(item.manifest_id)} 
                                        >
                                            {item.manifest_id}
                                        </Link>
                                    </li>
                                )}
                            />
                        </Panel>
                    }

                    {waitingMappingOrders.length > 0 &&
                        <Panel header={`TikTok orders with manifest mapping warning (${waitingMappingOrders.length || ''})`} key="4" showArrow={false}>
                            <List
                                pagination={{
                                    className: 'text-right mt-3 p-0',
                                    size: "small",
                                    pageSize: 5,
                                }}
                                dataSource={waitingMappingOrders}
                                renderItem={(item, index) => (
                                    <li className="px-2 py-2" key={index}>
                                        <Link to={{ pathname: `/a/orders/${item._id}` }} className='mr-1'>
                                            {item.id}
                                        </Link>
                                    </li>
                                )}
                            />
                        </Panel>
                    }

                    {waitingPaidOrders.length > 0 &&
                        <Panel header={`TikTok orders with manifest paid warning (${waitingPaidOrders.length || ''})`} key="5" showArrow={false}>
                            <List
                                pagination={{
                                    className: 'text-right mt-3 p-0',
                                    size: "small",
                                    pageSize: 5,
                                }}
                                dataSource={waitingPaidOrders}
                                renderItem={(item, index) => (
                                    <li className="px-2 py-2" key={index}>
                                        <Link to={{ pathname: `/a/orders/${item._id}` }} className='mr-1'>
                                            {item.id}
                                        </Link>
                                    </li>
                                )}
                            />
                        </Panel>
                    }

                    {waitingFulfillOrders.length > 0 &&
                        <Panel header={`TikTok orders with manifest push warning (${waitingFulfillOrders.length || ''})`} key="6" showArrow={false}>
                            <List
                                pagination={{
                                    className: 'text-right mt-3 p-0',
                                    size: "small",
                                    pageSize: 5,
                                }}
                                dataSource={waitingFulfillOrders}
                                renderItem={(item, index) => (
                                    <li className="px-2 py-2" key={index}>
                                        <Link to={{ pathname: `/a/orders/${item._id}` }} className='mr-1'>
                                            {item.id}
                                        </Link>
                                    </li>
                                )}
                            />
                        </Panel>
                    }

                </Collapse>
            </Drawer>
        </div>
    )
}

export default OrdersMissFailed