import React, {useState, useEffect} from 'react'
import {message, Pagination, Spin, Button} from "antd"
import UseDebounce from '../helpers/UseDebounce'
import FileDetailTable from './FileDetailTable'
import FileDetailFilter from './Filter/FileDetailFilter'
import {fileApproveReject, getListFileDetails} from '../../../services/api/SuppliersServices'
import ExportFileDetails from './Action/ExportFileDetails'


function FileDetailPage(props) {
    //const {idFile} = props
    const [listFileDetail, setListFileDetail] = useState('')
    const [loading, setLoading] = useState(false)
    const [page, setPage] = useState(1)
    const [status, setStatus] = useState('')
    const [search, setSearch] = useState('')
    const [pages, setPages] = useState(1)
    const [limit, setLimit] = useState(20)
    const [total, setTotal] = useState(0)
    const [error, setError] = useState('')
    const [invoiceFile, setInvoiceFile] = useState({})
    const [showExportModal, setShowExportModal] = useState(false)
    const [idFile, setFileId] = useState(props.idFile)

    const fetchFileDetail = async (newFileId) => {

        if (newFileId && newFileId !== idFile) {
            return setFileId(newFileId)
        }

        setLoading(true)
        setError('')
        const payload = {
            page,
            limit,
        }
        if (status) payload.process_status = status
        if (search) payload.package_name = search

        try {
            const {data, success, message: mess} = await getListFileDetails(idFile, {...payload})
            if (success) {
                setListFileDetail(data.rows)
                setInvoiceFile(data.invoiceFile)
                setPages(data.pages)
                setTotal(data.total)
            } else {
                message.error(mess)
            }

            setLoading(false)
        } catch (error) {
            setError(error.message || 'Unknown error.')
        } finally {
            setLoading(false)
        }

    }

    const debounceSearch = UseDebounce(search, 500)

    const toggleExportModal = () => {
        setShowExportModal(!showExportModal)
    }


    const handlePageChange = (page, pageSize) => {
        setLimit(pageSize)
        setPage(pageSize !== limit ? 1 : page)
    }

    const handleChangeStatus = (value) => {
        setStatus(value)
        setPage(1)
    }

    const handleChangeSearch = (e) => {
        const value = e.target.value
        setSearch(value)
        setPage(1)
    }

    useEffect(() => {
        fetchFileDetail()
    }, [status, debounceSearch, limit, page, idFile])
    const handleApproveReject = async (Action) => {
        const payload = {
            action: Action
        }

        try {
            const {data, success, message: mess} = await fileApproveReject(idFile, payload)
            if (success) {
                message.success('File ' + (Action === 'approve' ? 'approved' : 'rejected'))
                fetchFileDetail()
            } else {
                message.error(mess)
            }
        } catch (error) {
            message.error(error)
        }
    }

    const ApproveReject = invoiceFile.approval_status === 'pending' && invoiceFile.process_status === 'processed'
    const RejectOnly = invoiceFile.approval_status === 'pending' && invoiceFile.process_status === 'error'

    return (
        <div className='FileDetailPage'>
            <div className='PageHeading d-flex align-items-center justify-content-between border-bottom'>
                <h1 className="PageTitle mt-2 mb-0">{invoiceFile.filename} <small
                    className="text-muted">({invoiceFile.id})</small></h1>
                <div className="RightTop">
                    {ApproveReject &&
                    <div className='Actions'>
                        <button
                            className='btn btn-primary mx-1'
                            onClick={() => handleApproveReject('approve')}
                            style={{background: '#477BF6'}}
                            disabled={loading}
                        >
                            Approve
                        </button>
                        <button
                            className='btn btn-danger mx-1'
                            onClick={() => handleApproveReject('reject')}
                            style={{background: '#DF1F1F'}}
                            disabled={loading}
                        >
                            Reject
                        </button>
                    </div>
                    }
                    {RejectOnly &&
                    <div className='Actions'>
                        <button
                            className='btn btn-danger mx-1'
                            onClick={() => handleApproveReject('reject')}
                            style={{background: '#DF1F1F'}}
                            disabled={loading}
                        >
                            Reject
                        </button>
                    </div>
                    }
                    <div className='Actions'>
                        <button
                            className='btn btn-light mx-1'
                            onClick={toggleExportModal}
                            style={{
                                background: '#fff',
                                border: "solid 1px #d8dbde",
                            }}
                            disabled={loading}
                        >
                            Export
                        </button>
                        <ExportFileDetails
                            showExportModal={showExportModal}
                            toggleExportModal={toggleExportModal}
                            idFile={idFile}
                            search={search}
                            status={status}
                        />
                    </div>
                </div>
            </div>
            <div className="SectionInner mt-4">
                <div className="filter align-items-center mt-2">
                    <FileDetailFilter
                        status={status}
                        search={search}
                        loading={loading}
                        handleChangeSearch={handleChangeSearch}
                        handleChangeStatus={handleChangeStatus}
                    />
                </div>
                {
                    <div>
                        <div className='Total mb-3 d-flex justify-content-end'>
                            {
                                total > 0 && <div>
                                    <span>{total}</span> items
                                </div>
                            }
                        </div>
                        <Spin spinning={loading} tip='Data Loading...'>
                            <FileDetailTable
                                listFileDetail={listFileDetail || []}
                                loading={loading}
                                reload={fetchFileDetail}
                            />
                            <Pagination
                                pageSizeOptions={['10', '20', '50', '100']}
                                onChange={handlePageChange}
                                className='text-right'
                                pageSize={limit}
                                showSizeChanger
                                current={page}
                                total={total}
                            />
                        </Spin>
                    </div>
                }
            </div>
        </div>
    )
}

export default FileDetailPage