import React from 'react'
import {DragDropContext} from 'react-beautiful-dnd'
import {Button, Modal, message} from 'antd'
import FulfillmentPackage from './FulfillmentPackage';
import {useOrderFulfillment} from '../store'
import {fulfillOrder, getOrder} from '../../../../services/api/OrdersServices'
import getHistory from '../../../../store/getHistory'

/**
 * @param {Object} props
 * @param {Object} props.order
 * @returns {JSX.Element}
 */
export default (props) => {
    const {state, dispatch} = useOrderFulfillment()
    const {isProcessing, isProcessed, order, isOrderHaveUpdate} = state

    const onDragEnd = (result) => {
        if (!result.destination) {
            return;
        }
        let {packages} = state
        const {draggableId, destination, source} = result
        
        if (source.droppableId === destination.droppableId) {
            return
        }
        
        const pack = packages.find(pack => pack._id === source.droppableId)
        const packOther = packages.find(pack => pack._id === destination.droppableId)
        const items = pack ? pack.items : []
        const movedItem = items.find((item) => draggableId.includes(item._id))
        const {sku} = Object.assign({}, movedItem.productLineVariant)
        const skuTo = packOther.items.length && packOther.items[0].productLineVariant.sku
        const isMixPack = order.order_type === "FBA" && (skuTo === sku || packOther.items.length < 1) ? true : false
        if (movedItem) {
            if(order.order_type !== "FBA" || isMixPack){
                dispatch({
                    type: 'ADD_PACKAGE_ITEM',
                    payload: {
                        packId: destination.droppableId,
                        fromPackId: source.droppableId,
                        orderItem: movedItem
                    }
                })
                dispatch({
                    type: 'REMOVE_PACKAGE_ITEM',
                    payload: {
                        packId: source.droppableId,
                        orderItem: movedItem
                    }
                })
            }else{
                message.error('Đơn FBA không gộp chung 2 item(variant) khác nhau vào 1 package.')
            }
        }
    }

    const getListPackages = () => {
        return state.packages.map((pack, index) => {
            const packageName = `${state.order.id}-F${index + 1}`
            return (
                <FulfillmentPackage pack={pack} packageName={packageName} key={pack._id}/>
            )
            })
    }

    const allowPackage = () =>  state.packages.filter(pack => pack.allowFulfill())

    const markProcessButton = () => {
        const totalPackages = state.packages.length
        const countAllowPackage = allowPackage().filter(pack => !pack.isError()).length

        return (
            <Button
                className="split-package__footer-btn"
                type="primary"
                onClick={markProcessAll}
                disabled={!countAllowPackage || isProcessing || isProcessed || isOrderHaveUpdate}
                loading={isProcessing}
            >
                Mark {countAllowPackage}/{totalPackages} packages to processing
            </Button>
        )
    }

    const addPackage = () => {
        dispatch({type: 'ADD_PACKAGE'})
    }

    const _fulfillOrder = async (orderId, pack) => {
        return  fulfillOrder(orderId, {
            items: pack.items.map(item => ({
                _id: item._id,
                quantity: item.quantity,
            })),
            supplier: pack.supplier,
            shipping_carrier: pack.shippingCarrier
        }).catch(e => {
            console.error(e)

            return Promise.resolve({
                message: e.message,
                success: false
            })
        })
    }

    const backToOrder = () => {
        const history = getHistory()
        history.push(`/a/orders/${props.order._id}`)
    }

    const markProcessAll = async () => {
        if (!allowPackage().length) {
            return
        }

        dispatch({type: 'CHANGE_PROCESSING', payload: true})
        const orderId = props.order._id

        let {packages} = state

        let pack

        try {
            const {data, success, message:mess} = await getOrder(orderId)
            if(!success){
                message.error(mess)
                dispatch({type: 'CHANGE_PROCESSING', payload: false})
                return
            }
            if(data.tags.includes('OrderHaveUpdate')){
                message.error('Order have been updated by PLF!')
                dispatch({type: 'IS_ORDER_HAVE_UPDATE', payload: true})
                dispatch({type: 'CHANGE_PROCESSING', payload: false})
                return
            }
        } catch (error) {
            console.log(error)
            dispatch({type: 'CHANGE_PROCESSING', payload: false})
            return
        }

        for (pack of packages) {
            dispatch({type: 'CHANGE_STATUS', payload: {pack, status: 'processing'}})
        }

        packages = packages.map((pack, index) => {
            pack.name = `${props.order.id}-F${index+1}`
            return pack
        })
            .filter(pack => pack.allowFulfill())
        const result = {}

        const errors = []
        for (pack of packages) {
            let {success, message, errors: details} = await _fulfillOrder(orderId, pack)
            details = Array.isArray(details) ? details: []
            result[pack.id] = success
            if (success) {
                dispatch({type: 'CHANGE_STATUS', payload: {pack, status: 'fulfilled'}})
            } else {
                errors.push({message, pack, details})
                dispatch({type: 'CHANGE_STATUS', payload: {pack, status: 'error', message, details}})
            }
        }

        dispatch({type: 'CHANGE_PROCESSING', payload: false})
        dispatch({type: 'CHANGE_PROCESSED', payload: true})

        if (Object.values(result).every(Boolean)) {
            return backToOrder()
        }

        Modal.error({
            title: 'Oops Something went wrong!',
            className: 'split-package__modal',
            content: (
                <div>
                    <p>
                        <strong className="text-danger">{errors.length} packages cannot mark processing!</strong><br/>
                        Please reload the browser to re-make package processing
                    </p>
                    <div>
                        {errors.map((item, index) => (
                            <div className="text-danger mb-3" key={index}>
                                <div>[{item.pack.name}] {item.message}</div>

                                <ul>
                                    {item.details.map((msg, index2) => (<li key={index2}>{msg}</li>))}
                                </ul>
                            </div>
                        ))}
                    </div>
                </div>
            ),
        })
    }

    return (
        <div>
            <DragDropContext onDragEnd={onDragEnd}>
                {getListPackages()}
            </DragDropContext>

            <div className="split-package__footer">
                <Button
                    className="split-package__footer-btn"
                    onClick={addPackage}
                    disabled={isProcessing || isProcessed || (state.order.order_type === 'TIKTOK' && !!state.packages && state.packages.length > 0 && !!state.packages[0].shippingPlan && state.packages[0].shippingPlan.slug === 'shipbytiktok')}
                >
                    Add new a package
                </Button>

                {markProcessButton()}
            </div>
        </div>
    )
}
