import React, {Component} from 'react'
import Modal from "reactstrap/es/Modal"
import ModalBody from "reactstrap/es/ModalBody"
import ModalFooter from "reactstrap/es/ModalFooter"
import {deleteShippingZone} from "../../../../services/api/ShippingServices"
import getHistory from "../../../../store/getHistory"

class ShippingZoneUpdate extends Component {
    state = {
        showModal: false,
        error: "",
    }

    _handleToggleModal = e => {
        e.preventDefault()

        this.setState({
            showModal: !this.state.showModal,
        })
    }

    _handleClickSave = e => {
        e.preventDefault()

        this.props._handleUpdateShippingZone()
    }

    _handleClickDelete = e => {
        e.preventDefault()

        this._handleDeleteShippingZone()
    }

    _handleDeleteShippingZone = async () => {
        try {
            const {shippingZoneId} = this.props
            const {success, message} = await deleteShippingZone(shippingZoneId)

            if (!success) {
                return this.setState({
                    error: message,
                })
            }

            const history = getHistory()
            history.push("/a/shipping-zones")
        } catch (e) {
            return this.setState({
                error: e.message,
            })
        }
    }

    render() {
        const {loading, error: saveError, title} = this.props
        const {showModal, error: deleteError} = this.state

        return (
            <div className="ShippingZoneUpdate">
                <div className="d-flex justify-content-between">
                    <button className="btn btn-outline-danger" onClick={this._handleToggleModal}>Delete</button>
                    <button className="btn btn-primary" type="submit">
                        {
                            loading ? "Saving..." : "Save changes"
                        }
                    </button>
                </div>

                {
                    !!saveError && <div className="text-danger mt-3">{saveError}</div>
                }

                <Modal isOpen={showModal} toggle={this._handleToggleModal}>
                    <ModalBody>
                        Are you sure want to delete <strong>{title}</strong>? This action cannot be reversed.
                    </ModalBody>

                    <ModalFooter>
                        <div className="d-flex justify-content-center">
                            <button className="btn border" onClick={this._handleToggleModal}>Cancel</button>
                            <button className="btn btn-danger ml-2" onClick={this._handleClickDelete}>Delete</button>
                        </div>

                        {
                            !!deleteError && <div className="text-danger mt-3">{deleteError}</div>
                        }
                    </ModalFooter>
                </Modal>
            </div>
        )
    }
}

export default ShippingZoneUpdate
