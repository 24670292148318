import React from 'react'

const TableHead = () => {
    return (
        <thead className="NotificationTableHead thead-light">
        <tr>
            <th className="Info">Info</th>
            <th className="Address">Country</th>
            <th className="Supplier">Supplier</th>
            <th className="ShippingMethod">Shipping Method</th>
            <th className="Status">Package Status</th>
            <th className="Status">Production Status</th>
            <th className="PushType">Push Type</th>
            <th className="Tracking">Tracking</th>
            <th className="Note">Note</th>
        </tr>
        </thead>
    )
}

export default TableHead
