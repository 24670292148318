import React from 'react'
import CreateFastProductionRulePage from './CreateFastProductionRulePage'
import DocTitleByStore from '../../dashboard/shared/DocTitleByStore'
import '../styles/_index.scss'

function CreateFastProductionRuleContainer(props) {
    const {ruleId: id} = props.match.params

    return (
        <div className='CreateFastProductionRuleContainer'>
            <DocTitleByStore title="Fast Production Rule Create" />
            <CreateFastProductionRulePage id={id} />
        </div>
    )
}

export default CreateFastProductionRuleContainer