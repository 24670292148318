import React, {Component} from 'react'
import {CloseCircleOutlined, CheckCircleOutlined} from '@ant-design/icons'
import classNames from "classnames"
import { Select, Input, InputNumber, message } from 'antd'
import { upsertEmbroiderySurcharge } from '../../../../services/api/ProductsServices'

class ProductSurcharge extends Component {
    constructor(props) {
        super(props)
        const {surcharges, dimensions} = props
        const newSurcharges = surcharges.length > 0 ? surcharges.map(item => {
            const surchargeItem = {
                type: 'Embroidery Fee',
                store: item.store.join(", "),
                simple: item.simple,
                complex: item.complex,
                secondLocationFee: item.second_rental_fee,
                status: 'exist',
                disableEdit: true,
                surchargeId: item._id
            }

            return surchargeItem
        }): []
        const newDimensions = dimensions.map(item => {
            const itemF = item.find(itemF => itemF.side === 'front')
            const itemB = item.find(itemB => itemB.side === 'back')
            const itemS = item.find(itemS => itemS.side === 'sleeve')
            const itemH = item.find(itemH => itemH.side === 'hood')
            return [
                {
                    width: itemF !== undefined ? itemF.width : '',
                    height: itemF !== undefined ? itemF.height : '',
                    side: "front"
                },
                {
                    width: itemB !== undefined ? itemB.width : '',
                    height: itemB !== undefined ? itemB.height : '',
                    side: "back"
                },
                {
                    width: itemS !== undefined ? itemS.width : '',
                    height: itemS !== undefined ? itemS.height : '',
                    side: "sleeve"
                },
                {
                    width: itemH !== undefined ? itemH.width : '',
                    height: itemH !== undefined ? itemH.height : '',
                    side: "hood"
                },
            ]
        })
        this.state = {
            dimensionsState: newDimensions,
            surchargesState: newSurcharges,
            disableAdd: false,
            loading: false,
            error: ''
        }
    }

    // componentWillReceiveProps(props) {
    //     const {surcharges} = props
    //     const newSurcharges = surcharges.length > 0 ? surcharges.map(item => {
    //         const surchargeItem = {
    //             type: 'Embroidery Fee',
    //             store: item.store,
    //             simple: item.simple,
    //             complex: item.complex,
    //             secondLocationFee: item.second_rental_fee,
    //             status: 'exist',
    //             disableEdit: false,
    //             surchargeId: item._id
    //         }

    //         return surchargeItem
    //     }): []
    //     this.state = {
    //         surchargesState: newSurcharges,
    //     }
    // }

    // static getDerivedStateFromProps(nextProps, prevState){
    //     if(JSON.stringify(nextProps.surcharges) !== JSON.stringify(prevState.surcharges)){
    //         const newSurcharges = nextProps.surcharges.length > 0 ? nextProps.surcharges.map(item => {
    //             const surchargeItem = {
    //                 type: 'Embroidery Fee',
    //                 store: item.store.join(", "),
    //                 simple: item.simple,
    //                 complex: item.complex,
    //                 secondLocationFee: item.second_rental_fee,
    //                 status: 'exist',
    //                 disableEdit: false,
    //                 surchargeId: item._id
    //             }
    
    //             return surchargeItem
    //         }): []

    //         return { surchargesState: newSurcharges};
    //    }
    //    else return null;
    // }

    // componentDidUpdate(prevProps, prevState) {
    //     if(JSON.stringify(prevProps.surcharges) !== JSON.stringify(this.props.surcharges)){
    //     // if(prevProps.someValue!==this.props.someValue){
    //       //Perform some operation here
    //         console.log('11111111111112222222222222233333333333')
    //         const newSurcharges = prevProps.surcharges.length > 0 ? prevProps.surcharges.map(item => {
    //             const surchargeItem = {
    //                 type: 'Embroidery Fee',
    //                 store: item.store.join(", "),
    //                 simple: 3333,
    //                 complex: item.complex,
    //                 secondLocationFee: item.second_rental_fee,
    //                 status: 'exist',
    //                 disableEdit: false,
    //                 surchargeId: item._id
    //             }
    
    //             return surchargeItem
    //         }): []
            
    //         this.setState({surchargesState: newSurcharges});
    //     }
    // }

    _handleAddSurcharge = () => {
        const {surchargesState} = this.state
        let newSurcharges = Object.assign([], surchargesState)

        newSurcharges.push(
            {
                type: 'Embroidery Fee',
                store: '',
                simple: '',
                complex: '',
                secondLocationFee: '',
                status: 'new',
                disabledEdit: false,
                surchargeId: ''
            }
        )
        
        this.setState({
            surchargesState: newSurcharges,
            disableAdd: true
        })
    }

    _handleChangeSurchargeItem = (index) => (e) => {
        console.log('itemmmmmm', index)
        const {surchargesState} = this.state
        const {name, value} = e.target
        let newSurcharges = [...surchargesState]
        newSurcharges[index][name] = value
        console.log('11111111111: ', newSurcharges)
        this.setState({
            surchargesState: newSurcharges
        })
    }

    _handleDeleteItem = (index) => {
        // const {onUpdateState} = this.props
        // const {surchargesState} = this.state

        // const newSurcharges = surchargesState.filter((item, i) => i !== index)

        // this.setState({
        //     surchargesState: newSurcharges
        // }, () => {
        //     onUpdateState('surcharges', newSurcharges)
        // })
    }

    // _handleSaveItem = (index) => {
    //     const {surchargesState} = this.state
    //     const newSurcharge = surchargesState.filter((item, i) => i === index)

    //     this.setState({
    //         disableAdd: false
    //     })
    // }

    _handleSaveItem = async (index) => {
        this.setState({
            loading: true,
            error: ''
        })

        try {
            const {productId} = this.props
            const {surchargesState} = this.state
            const newSurcharge = surchargesState.filter((item, i) => i === index)

            console.log('newSurcharge', newSurcharge)

            const payload = {}

            if(newSurcharge[0].surchargeId){
                payload._id = newSurcharge[0].surchargeId
            }

            if(newSurcharge[0].store){
                const storeArr = newSurcharge[0].store.split(",").map(item => {
                    return item.trim()
                })
                payload.store = storeArr
            }
            if(newSurcharge[0].simple){
                payload.simple = parseFloat(newSurcharge[0].simple)
            }

            if(newSurcharge[0].complex){
                payload.complex = parseFloat(newSurcharge[0].complex)
            }

            if(newSurcharge[0].secondLocationFee){
                payload.second_rental_fee = parseFloat(newSurcharge[0].secondLocationFee)
            }

            console.log('payload: ', payload)
            const response = await upsertEmbroiderySurcharge(productId, payload)

            const {success, message: error} = response

            if (!success) {
                return this.setState({
                    loading: false,
                }, () => {
                    message.error(error)
                })
            }

            this.setState({
                loading: false,
            }, () => {
                message.success('Update product information success!')
            })
            
        } catch (e) {
            this.setState({
                loading: false,
            }, () => {
                message.error(e.message)
            })
        }
    }

    render() {
        const {surchargesState, disableAdd, dimensionsState} = this.state
        const {disableAll} = this.props
// console.log('dimensionsState 333333333333333: ', dimensionsState)
        return (
            <div className='ProductDimension mt-3'>
                <div className="SectionInner">
                    <h3 className="SectionHeading mb-3">Surcharge</h3>
                    <div className='mt-4'>
                        {surchargesState.length > 0 && surchargesState.map((item, index) => {
                            return (
                                <div key={index} className="group bg-light p-3 mb-3">
                                    <div className="itemChild">
                                        <div className="row">
                                            <div className="itemSide col-12 mb-3 d-flex align-items-center">
                                                <Select
                                                style={{ width: '100%' }}
                                                disabled
                                                options={[{ value: 'Embroidery Fee', label: 'Embroidery Fee' }]}
                                                value={item.type}
                                                />
                                            </div>

                                            <div className="itemSide col-12 mb-3 d-flex align-items-center">
                                                {/* <input type="text" value={item.store} name="store" placeholder='*' onChange={this._handleChangeDimensionItem(index)}/> */}
                                                <Input placeholder="*" value={item.store} name="store" onChange={this._handleChangeSurchargeItem(index)}/>
                                            </div>
                                        </div>

                                        <div className="row">
                                            <div className="itemSide col-12 mb-3 d-flex align-items-center">
                                                <Input type="number" placeholder="Simple" value={item.simple} name="simple" onChange={this._handleChangeSurchargeItem(index)} style={{width: '100%'}}/>
                                                
                                            </div>

                                            <div className="itemSide col-12 mb-3 d-flex align-items-center">
                                                <Input type="number" placeholder="Complex" value={item.complex} name="complex" onChange={this._handleChangeSurchargeItem(index)}/>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="itemSide col-12 mb-3 d-flex align-items-center">
                                                <Input type="number" placeholder="Second location fee" value={item.secondLocationFee} name="secondLocationFee" onChange={this._handleChangeSurchargeItem(index)}/>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="surcharge-action d-flex">
                                        <div className="iconClose text-danger cursor-pointer"
                                            onClick={() => this._handleDeleteItem(index)}>
                                            <CloseCircleOutlined/>
                                        </div>

                                        <div className="iconSave text-success cursor-pointer"
                                         onClick={() => this._handleSaveItem(index)}>
                                            <CheckCircleOutlined />
                                        </div>
                                    </div>
                                </div>
                            )
                        })}
                        {!disableAll &&
                            <button className="btn btn-dark" onClick={this._handleAddSurcharge} disabled={disableAdd}>Add more</button>
                        }
                    </div>
                </div>
            </div>
        )
    }
}

export default ProductSurcharge
