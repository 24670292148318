import React,{useState, useContext} from 'react'
import {Button, Modal, Progress, Select, Switch} from "antd"
import ManageAutoMapContext from '../../context/ManageAutoMapContext'
import {ExclamationCircleOutlined, CheckCircleOutlined} from '@ant-design/icons'
import { changeConfirmTrusted } from '../../../../services/api/ProductsServices'


function ConfirmRule(props) {
  const {visible, onCancel} = props
  const {listSelected, listRules} = useContext(ManageAutoMapContext)
  const [loading, setLoading] = useState(false)
  const [confirmed, setConfirmed] = useState(true)
  const [listProcessing, setListProcessing] = useState(listRules.filter(item => listSelected.includes(item._id)))
  const [listSuccess, setListSuccess] = useState([])
  const [listError, setListError] = useState([])

  const _handleChangeConfirm = (checked) => {
        setConfirmed(checked)
        setListProcessing(listRules.filter(item => listSelected.includes(item._id)))
  }

  const _onChangeConfirmTrusted = async (ruleId) => {
        try {
            const payload = {
                is_trusted: confirmed
            }

            const response = await changeConfirmTrusted(ruleId, payload)

            const {success, message} = response

            if (!success) {
                return {success: success, message: message}
            }

            return {success: success}
        } catch (e) {
            return {success: false, message: e.message}
        }
  }

  const _onSubmitChange = async () => {
        setLoading(true)
        setListSuccess([])
        setListError([])

        let processed = []

        await Promise.all(listProcessing.map(async item => {
            const response = await _onChangeConfirmTrusted(item._id)

            const {success, message} = response

            if (!success) return processed.push({...item, error: message, processing: false})

            return processed.push({...item, success: true, processing: false})
        }))

        let newListSuccess = []
        let newListError = []

        processed.map(process => {
            if (process.success === true) {
                return newListSuccess.push(process)
            }

            if (process.error.length > 0) {
                return newListError.push(process)
            }
        })

        setListSuccess(newListSuccess)
        setListError(newListError)
        setLoading(false)
        return setListProcessing(processed)
  }

  return (
    <Modal
      visible={visible}
      className={"ChangeConfirmPackageModal"}
      title={"Confirm trusted rule"}
      onOk={""}
      onCancel={onCancel}
      footer={[
        <Button key="back" disabled={loading} onClick={onCancel}>
          Cancel
        </Button>,
        <Button
          key="submit"
          disabled={loading}
          type="primary"
          loading={loading}
          onClick={_onSubmitChange}
        >
          Confirm
        </Button>,
      ]}
    >
      <div className="FormItem">
        <label htmlFor="confirm" className="mr-3">
          Choose confirm:
        </label>
        <Switch
          checked={confirmed}
          onChange={_handleChangeConfirm}
          checkedChildren="Active"
          unCheckedChildren="Deactive"
        />
      </div>

      <h3>
        List processing <span>{listSelected.length}</span> items
      </h3>
      {(listSuccess.length > 0 || listError.length > 0) && (
        <div className="Results">
          <div className="ResultSuccess">
            Success: <span>{listSuccess.length}</span> items
          </div>
          <div className="ResultError">
            Error: <span>{listError.length}</span> items
          </div>
        </div>
      )}

      <div className="ListProcessing">
        <ul>
          {!!listProcessing &&
            listProcessing.length > 0 &&
            listProcessing.map((item) => (
              <li key={item._id}>
                <span>
                  {item.id} - {item.plf_product_title}
                </span>
                {!!item.error && (
                  <span className="Error">
                    <ExclamationCircleOutlined /> {item.error}
                  </span>
                )}
                {item.success && (
                  <span className="Success">
                    <CheckCircleOutlined /> Change success
                  </span>
                )}
                <Progress
                  percent={100}
                  size="small"
                  status={`${
                    item.processing === false && item.success === true
                      ? "success"
                      : item.processing === false && item.error.length > 0
                      ? "exception"
                      : "active"
                  }`}
                  showInfo={false}
                />
              </li>
            ))}
        </ul>
      </div>
    </Modal>
  )
}

export default ConfirmRule