import React from 'react'
import ItemSideDefault from './item-side/ItemSideDefault'

const OrderDesign = (props) => {
    const { designFront, designBack, designSleeves, designHood, rowSpan, ...rest } = props

    return (
        <td className='DesignFront' rowSpan={rowSpan}>
            <div className='row no-gutters'>
                <div className='col-12 px-1'>
                    <ItemSideDefault {...rest} type='update-design' side='front' designFront={designFront} />
                </div>

                <div className='col-12 px-1'>
                    <ItemSideDefault {...rest} type='update-design' side='back' designBack={designBack} />
                </div>

                <div className='col-12 px-1'>
                    <ItemSideDefault {...rest} type='update-design' side='sleeves' designSleeves={designSleeves} />
                </div>

                <div className='col-12 px-1'>
                    <ItemSideDefault {...rest} type='update-design' side='hood' designHood={designHood} />
                </div>
            </div>
        </td>
    )
}

export default OrderDesign