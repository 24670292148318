import React from 'react'
import Link from "react-router-dom/Link"

function FileDetailRow(props) {
    const {item, reload, index} = props
    const {
        row_index,
        invoice_records,
        message,
        approval_status,
        process_status,
        meta
    } = item
    const {duplicate_file_id} = {...meta}
    const {
        _id: duplicateFileId,
        id: duplicateFileName
    } = {...duplicate_file_id}

    const vMsg = (message || '').split(/([A-Z]{2}-[0-9]{5}-[0-9]{5}-F[0-9]+)/g)
    const theMsg = vMsg.reduce((arr, msg, i) => {
        if (i % 2) {
            arr.push(<strong className="PackageName" key={i}>{vMsg[i]}</strong>)
        } else {
            if (!duplicateFileName) {
                arr.push(vMsg[i])
            } else {

                const vv = vMsg[i].split(new RegExp(`#?${duplicateFileName}`))

                arr.push(...vv.map((v, j) => {
                    return j % 2 ?
                        <Link key={[i, j].join('_')} to={`/a/cost-control/${duplicateFileId}`} onClick={() => {
                            reload(duplicateFileId)
                        }}>#{duplicateFileName}</Link> : v
                }))
            }
        }

        return arr
    }, [])

    // const msg = duplicateFileName && vMsg ? <>
    //         {vMsg.replace(new RegExp(`#?${duplicateFileName}`), '')}
    //         <Link to={`/a/cost-control/${duplicateFileId}`} onClick={() => {
    //             reload(duplicateFileId)
    //         }}>#{duplicateFileName}</Link>
    //     </>
    //     : vMsg

    return (
        <tr>
            <td className='Line_CSV'>
                #{row_index}
            </td>
            <td className='Package_name'>
                <label>
                    {invoice_records[0]['Package name']}
                </label>
            </td>
            <td className='Reference_id'>
                <label>
                    {invoice_records[0]['Reference id']}
                </label>
            </td>
            <td className='Order_supplier_id'>
                <label>
                    {invoice_records[0]['Order supplier id']}
                </label>
            </td>
            <td className='Production_fee'>
                <label>
                    {invoice_records[0]['Production fee']}
                </label>
            </td>
            <td className='Shipping_fee'>
                <label>
                    {invoice_records[0]['Shipping fee']}
                </label>
            </td>
            <td className='Tax'>
                <label>
                    {invoice_records[0].Tax}
                </label>
            </td>
            <td className='Status'>
                {process_status === 'pending' &&
                <label className='badge badge-info pending-animation'>Pending</label>
                }
                {process_status === 'done' &&
                <label className='badge badge-success'>Done</label>
                }
                {process_status === 'error' &&
                <label className='badge badge-danger'>error</label>
                }
            </td>
            <td className='Message'>
                <label>
                    {theMsg}
                </label>
            </td>
        </tr>
    )
}

export default FileDetailRow