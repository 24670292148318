import createApiServices from './createApiServices'

const prefixURL = process.env.NODE_ENV === 'production' ? '/api/order' : 'https://fulfillment-staging.merchize.com/api/order'
const api = createApiServices({baseUrl: prefixURL})
const devURL = window['API_ORDER_SERVICE_URL'] || ''

const sanitizePersonalFilters = (data) => {
    const payload = Object.keys(data.payload).reduce((m, p) => {
        if (p.indexOf('.') !== -1) {
            return m
        }

        return {
            ...m,
            [p]: data.payload[p]
        }
    }, {})

    return {
        ...data,
        payload
    }
}

export const getOrderStatistics = (data = {}) => {
    return api.makeAuthRequest({
        url: '/orders/statistics',
        method: 'POST',
        data: data,
    })
}

export const searchOrders = (data = {}) => {
    return api.makeAuthRequest({
        url: `${devURL}/orders/search`,
        method: 'POST',
        data,
    })
}

export const getOrder = (orderId = '') => {
    return api.makeAuthRequest({
        url: `/orders/${orderId}`,
        method: 'GET',
    })
}

export const getOrderHistories = (orderId = '') => {
    return api.makeAuthRequest({
        url: `/orders/${orderId}/histories`,
        method: 'GET',
    })
}

export const searchOrderHistories = (payload) => {
    return api.makeAuthRequest({
        url: `/histories/search`,
        method: 'POST',
        data: payload,
    })
}

export const getOrderNote = (orderId = '') => {
    return api.makeAuthRequest({
        url: `/orders/${orderId}/notes`,
        method: 'GET',
    })
}

export const getOrderInvoice = (orderId = '') => {
    return api.makeAuthRequest({
        url: `/orders/${orderId}/invoices`,
        method: 'GET',
    })
}

export const getOrderRefund = (orderId = '') => {
    return api.makeAuthRequest({
        url: `/orders/${orderId}/refund`,
        method: 'GET'
    })
}

export const getInvoiceCharge = (orderId = '') => {
    return api.makeAuthRequest({
        url: `/orders/${orderId}/charge`,
        method: 'GET'
    })
}

export const getOrderSellerInvoice = (orderId = '') => {
    return api.makeAuthRequest({
        url: `/orders/${orderId}/seller-invoices`,
        method: 'GET',
    })
}

export const getReasonTags = () => {
    return api.makeAuthRequest({
        url: `/orders/refund/reasons`,
        method: 'GET',
    })
}

export const getOrderBuyerDetail = (orderId = '') => {
    return api.makeAuthRequest({
        url: `/orders/${orderId}/buyerdetails`,
        method: 'GET',
    })
}

export const submitRefundCharge = (type, orderID, payload) => {
    if (type === 'refund') {
        return api.makeAuthRequest({
            url: `/orders/${orderID}/refund`,
            method: 'POST',
            data: payload
        })
    }
    return api.makeAuthRequest({
        url: `/orders/${orderID}/charge`,
        method: 'POST',
        data: payload
    })
}

export const submitCharge = (orderId, payload) => {
    return api.makeAuthRequest(({
        url: `/orders/${orderId}/charge`,
        method: 'POST',
        data: payload
    }))
}

export const submitRefund = (orderID, payload) => {
    return api.makeAuthRequest({
        url: `/orders/${orderID}/refund`,
        method: 'POST',
        data: payload
    })
}

export const getItemsByOrderId = (orderId = '') => {
    return api.makeAuthRequest({
        url: `/orders/${orderId}/items`,
        method: 'GET',
    })
}

export const changeOrderStatus = ({orderId = '', status = ''}) => {
    return api.makeAuthRequest({
        url: `/orders/${orderId}/status`,
        data: {
            status,
        },
        method: 'POST',
    })
}

export const saveOrdersNotes = ({orderId = '', supportNote = '', fulfillmentNote = ''}) => {
    if (!orderId) return null
    return api.makeAuthRequest({
        method: 'POST',
        url: `/orders/${orderId}/notes`,
        data: {
            support_note: supportNote,
            fulfillment_note: fulfillmentNote,
        },
    })
}

export const getListUnfulfilledItems = (orderId = '') => {
    return api.makeAuthRequest({
        url: `/orders/${orderId}/unfulfilled`,
        method: 'GET',
    })
}

export const getListFulfillments = (orderId = '') => {
    return api.makeAuthRequest({
        url: `/orders/${orderId}/fulfillments`,
        method: 'GET',
    })
}

export const getListFulfilledItems = (orderId = '', fulfillId = '') => {
    return api.makeAuthRequest({
        url: `/orders/${orderId}/fulfillments/${fulfillId}/items`,
        method: 'GET',
    })
}

export const createFulfillment = ({
                                      orderId = '',
                                      tracking_number = '',
                                      tracking_url = '',
                                      tracking_company = '',
                                      notify_customer = true,
                                      items = []
                                  }) => {
    if (!orderId) return null
    return api.makeAuthRequest({
        method: 'POST',
        url: `/orders/${orderId}/fulfillments`,
        data: {
            tracking_number: tracking_number,
            tracking_company: tracking_company,
            tracking_url: tracking_url,
            notify_customer: notify_customer,
            items: items,
        },
    })
}

export const cancelFulfilled = (orderId = '', fulfillId = '') => {
    if (!orderId) return null
    return api.makeAuthRequest({
        method: 'POST',
        url: `/orders/${orderId}/fulfillments/${fulfillId}/cancel`,
    })
}

export const editTracking = (orderId, fulfillId, data) => {
    if (!orderId) return null
    return api.makeAuthRequest({
        method: 'POST',
        url: `/orders/${orderId}/fulfillments/${fulfillId}/tracking`,
        data: data,
    })
}

export const editAddress = (orderId, addressId, data) => {
    if (!orderId) return null
    return api.makeAuthRequest({
        method: 'POST',
        url: `/orders/${orderId}/address/${addressId}`,
        data: data,
    })
}

export const getListTags = (orderId = '') => {
    if (!orderId) return null
    return api.makeAuthRequest({
        method: 'GET',
        url: `/orders/${orderId}/tags`,
    })
}

export const createTag = (orderId = '', title = '') => {
    if (!orderId) return null
    return api.makeAuthRequest({
        method: 'POST',
        url: `/orders/${orderId}/tags`,
        data: {
            title: title,
        },
    })
}

export const removeOrderTag = async (orderId = '', tagId = '') => {
    if (!orderId || !tagId) return null

    return api.makeAuthRequest({
        method: 'DELETE',
        url: `/orders/${orderId}/tags/${tagId}`,
    })
}

export const updateOrderNote = (orderId, data) => {
    return api.makeAuthRequest({
        url: `/orders/${orderId}/note`,
        method: 'POST',
        data: {
            ...data,
        },
    })
}

export const addOrderTag = (orderId, data) => {
    return api.makeAuthRequest({
        url: `/orders/${orderId}/tag`,
        method: 'POST',
        data: {
            ...data,
        },
    })
}

export const updateOrderTags = (orderId, data) => {
    return api.makeAuthRequest({
        url: `/orders/${orderId}/tags`,
        method: 'POST',
        data: {
            ...data,
        },
    })
}

export const removeOrderTags = (orderId, data) => {
    return api.makeAuthRequest({
        url: `/orders/${orderId}/remove-tags`,
        method: 'POST',
        data: {
            ...data,
        },
    })
}

export const rejectFulfillOrder = (id) => {
    return api.makeAuthRequest({
        url: `/orders/${id}/reject`,
        method: 'POST',
    })
}

export const getOrderUnfufillItems = (id) => {
    return api.makeAuthRequest({
        url: `${devURL}/orders/${id}/unfulfill-items`,
        method: 'GET',
    })
}

export const fulfillOrder = (id, data) => {
    return api.makeAuthRequest({
        url: `/v2/orders/${id}/fulfill`,
        method: 'POST',
        data: data,
    })
}

export const getOrderFulfilledItems = (id) => {
    return api.makeAuthRequest({
        url: `${devURL}v2/orders/${id}/fulfillments`,
        method: 'GET',
    })
}

export const updateOrderFulfillmentTracking = (orderId, fulfillmentId, data) => {
    return api.makeAuthRequest({
        url: `/orders/${orderId}/fulfillments/${fulfillmentId}/tracking`,
        method: 'POST',
        data: {...data, isManual: true},
    })
}

export const updateOrderFulfillmentTrackingV2 = (orderId, fulfillmentId, data) => {
    return api.makeAuthRequest({
        url: `/v2/orders/${orderId}/fulfillments/${fulfillmentId}/tracking`,
        method: 'POST',
        data: data,
    })
}

export const rejectOrderItem = (orderId, orderItemIds) => {
    return api.makeAuthRequest({
        url: `/orders/${orderId}/reject-items`,
        method: 'POST',
        data: orderItemIds,
    })
}

export const getOrderRejectedItems = (orderId) => {
    return api.makeAuthRequest({
        url: `/orders/${orderId}/reject-items`,
        method: 'GET',
    })
}

export const getOrdersArtworks = (data) => {
    return api.makeAuthRequest({
        url: `/orders/artworks`,
        method: 'POST',
        data: data,
    })
}

export const updateOrderFulfillmentSupplier = (orderId, fulfillmentId, data) => {
    return api.makeAuthRequest({
        url: `/orders/${orderId}/fulfillments/${fulfillmentId}/supplier`,
        method: 'POST',
        data: data,
    })
}

export const getStatusPrintforia = (fulfillmentId) => {
    return api.makeAuthRequest({
        url: `/fulfillment/${fulfillmentId}/factories`,
        method: 'GET',
    })
}

export const undoRejectOrderItems = (orderId) => {
    return api.makeAuthRequest({
        url: `/orders/${orderId}/undo-reject`,
        method: 'POST',
    })
}

export const importTrackingCSV = (file) => {
    return api.makeAuthRequest({
        url: '/import/tracking-number/csv',
        method: 'POST',
        data: file,
    })
}

export const getOrderPopularTags = (orderID, limit) => {
    return api.makeAuthRequest({
        url: `/orders/${orderID}/popular-tags`,
        method: 'GET',
        data: {
            limit,
        },
    })
}

export const restoreFulfillOrder = (orderId) => {
    return api.makeAuthRequest({
        url: `/orders/${orderId}/restore-fulfill`,
        method: 'POST',
    })
}

export const undoRejectOrderItem = (orderId, orderItemIds) => {
    return api.makeAuthRequest({
        url: `/orders/${orderId}/undo-reject-items`,
        method: 'POST',
        data: {
            items: [orderItemIds],
        },
    })
}

export const checkDuplicateOrder = (orderId) => {
    return api.makeAuthRequest({
        url: `/orders/${orderId}/is-duplicate`,
        method: 'GET',
    })
}

export const markFulfilledOrder = (orderId) => {
    return api.makeAuthRequest({
        url: `/orders/${orderId}/fulfill-completed`,
        method: 'POST',
    })
}

export const getListHistories = (orderId) => {
    return api.makeAuthRequest({
        url: `/orders/${orderId}/histories`,
        method: 'GET',
    })
}

export const bulkOrderActions = (orderIds, slug) => {
    return api.makeAuthRequest({
        url: `/bulk/orders/${slug}`,
        method: 'POST',
        data: {
            orders: orderIds,
        },
    })
}

export const validateShippingAddress = (orderId) => {
    return api.makeAuthRequest({
        url: `/orders/${orderId}/validate-address`,
        method: 'POST',
    })
}

export const requestArchiveOrder = (id) => {
    return api.makeAuthRequest({
        url: `/orders/${id}/archive`,
        method: 'POST',
    })
}

export const requestUndoArchiveOrder = (id) => {
    return api.makeAuthRequest({
        url: `/orders/${id}/undo-archive`,
        method: 'POST',
    })
}

export const bulkMarkOrdersProcessing = (orderIds, supplierId) => {
    return api.makeAuthRequest({
        url: '/bulk/orders/mark-processing',
        method: 'POST',
        data: {
            orders: orderIds,
            supplier: supplierId,
        },
    })
}

export const bulkMarkFulFilledOrder = (orderIds) => {
    return api.makeAuthRequest({
        url: '/bulk/orders/complete-fulfill',
        method: 'POST',
        data: {
            orders: orderIds,
        },
    })
}

export const reqRunJobsByIds = ids => {
    return api.makeAuthRequest({
        url: '/bulk/webhook-jobs/run-by-ids',
        method: 'POST',
        data: {
            jobs: ids,
        },
    })
}

export const reqCancelJobsByIds = ids => {
    return api.makeAuthRequest({
        url: '/bulk/webhook-jobs/run-by-ids',
        method: 'POST',
        data: {
            jobs: ids,
        },
    })
}

export const markFulfilledPackage = async (orderId, fulfillmentId) => {
    return api.makeAuthRequest({
        url: `/orders/${orderId}/fulfillments/${fulfillmentId}/mark-fulfilled`,
        method: 'POST'
    })
}

export const pushAllPackage = async (orderId, payload) => {
    return api.makeAuthRequest({
        url: `/orders/${orderId}/push-fulfillments`,
        method: 'POST',
        data: payload
    })
}

export const getBrandingItems = async (orderId) => {
    return api.makeAuthRequest({
        url: `/orders/${orderId}/branding-items`,
        method: 'GET'
    })
}

export const detectChargeOrRefund = async (orderId, fulfillmentId, newSupplierId) => {
    return api.makeAuthRequest({
        url: `/orders/${orderId}/fulfillments/${fulfillmentId}/detect-process/${newSupplierId}`,
        method: 'GET'
    })
}

export const searchOrdersSupplier = (data = {}) => {
    return api.makeAuthRequest({
        url: '/fulfillments/search',
        method: 'POST',
        data,
    })
}
export const searchOrdersSupplierV2 = (data = {}) => {
    return api.makeAuthRequest({
        url: `${devURL}/fulfillments/search-v2`,
        method: 'POST',
        data,
    })
}

export const searchOrdersSupplierV2CountItems = (data = {}) => {
    return api.makeAuthRequest({
        url: `${devURL}/fulfillments/search-v2/count-items`,
        method: 'POST',
        data,
    })
}

export const getOrderSupplier = async (fufillmentId) => {
    return api.makeAuthRequest({
        url: `${devURL}/v2/fulfillments/${fufillmentId}`,
        method: 'GET'
    })
}

export const getHistory = async (fufillmentId) => {
    return api.makeAuthRequest({
        url: `/fulfillments/${fufillmentId}/histories`,
        method: 'GET'
    })
}

export const updateIOSS = async (orderId, payload) => {
    return api.makeAuthRequest({
        url: `/orders/${orderId}/ioss`,
        method: 'PUT',
        data: payload
    })
}

export const getDiscounts = (payload = {}) => {
    return api.makeAuthRequest({
        url: `/discounts/search`,
        method: 'POST',
        data: payload
    })
}

export const removeNoteOrders = (payload = {}) => {
    return api.makeAuthRequest({
        url: `bulk/orders/remove-note`,
        method: 'POST',
        data: payload
    })
}

export const getMultransUSReport = () => {
    return api.makeAuthRequest({
        url: `/reports/multrans-us-overtime`,
        method: 'GET'
    })
}

export const getListPersonalFilters = (type) => {
    return api.makeAuthRequest({
        url: `/personal-filters?type=${type}&is_hidden=false`,
        method: 'GET'
    })
}

export const getCountFilter = (payload) => {
    return api.makeAuthRequest({
        url: `/personal-filters/search`,
        method: 'POST',
        data: payload
    })
}

export const getListQuickReports = (query = {}) => {
    return api.makeAuthRequest({
        url: `personal-filters`,
        method: 'GET',
        params: query
    })
}

export const createQuickReports = (payload = {}) => {
    return api.makeAuthRequest({
        url: `personal-filters`,
        method: 'POST',
        data: sanitizePersonalFilters(payload)
    })
}

export const editQuickReports = (id, payload = {}) => {
    return api.makeAuthRequest({
        url: `personal-filters/${id}`,
        method: 'PUT',
        data: sanitizePersonalFilters(payload)
    })
}

export const hideQuickReport = (filterId) => {
    return api.makeAuthRequest({
        url: `personal-filters/${filterId}/hide`,
        method: 'PUT',
    })
}

export const getOrderBasecost = (orderId = '', params) => {
    return api.makeAuthRequest({
        url: `/orders/${orderId}/base-cost`,
        method: 'GET',
        params: params
    })
}

export const reviewChargeUpdateDesign = (orderId = '', requestId = '', payload) => {
    return api.makeAuthRequest({
        url: `/orders/${orderId}/${requestId}/charge`,
        method: 'POST',
        data: payload
    })
}

export const getDetailJobSwitchCarrier = (payload) => {
    return api.makeAuthRequest({
        url: `tracking-scan`,
        method: 'POST',
        data: payload
    })
}

export const updateNotePackage = (fulfillmentId, payload) => {
    return api.makeAuthRequest({
        url: `/fulfillments/${fulfillmentId}/package-note`,
        method: 'POST',
        data: payload
    })
}

export const updateStatusPackage = (fulfillmentId, payload) => {
    return api.makeAuthRequest({
        url: `/fulfillment/${fulfillmentId}/update-package-status`,
        method: 'POST',
        data: payload
    })
}

export const repairProgressArtworkStatus = (payload) => {
    return api.makeAuthRequest({
        url: `/repair/fulfillments/progress-artwork-status`,
        method: 'POST',
        data: payload
    })
}

export const getListSuggestNotes = (data) => {
    return api.makeAuthRequest({
        url: '/fulfillments/suggest-notes',
        method: 'POST',
        data: data
    })
}

export const getOrderItem = (orderId = '', itemid = '') => {
    return api.makeAuthRequest({
        url: `/orders/${orderId}/items/${itemid}`,
        method: 'GET',
    })
}

export const updateCustomizedArtworks = (itemid = '', data) => {
    return api.makeAuthRequest({
        url: `/repair/fulfillment-items/${itemid}/customized-artworks`,
        method: 'POST',
        data: data
    })
}

export const updateOrderShippingCost = (mongoId, shippingCost) => {
    return api.makeAuthRequest({
        url: `/orders/${mongoId}/custom-shipping-cost`,
        method: 'PUT',
        data: {
            "custom_shipping_cost": shippingCost
        }
    })
}

export const importShippingCost = (data, supplierId, shippingCarrier) => {
    return api.makeAuthRequest({
        url: `/shipping-costs/import/${supplierId}/${shippingCarrier}`,
        method: 'POST',
        data: data
    })
}

export const importShippingCostOrther = (data, supplierId) => {
    return api.makeAuthRequest({
        url: `/shipping-costs/import/${supplierId}`,
        method: 'POST',
        data: data
    })
}

export const getMappingConditions = (storeName) => {
    return api.makeAuthRequest({
        url: `/mapping-conditions/by-store/${storeName}`,
        method: 'GET',
    })
}

export const createChargeRefund = (type, orderID, payload) => {
    return api.makeAuthRequest({
        url: `/orders/${orderID}/request/${type}`,
        method: 'POST',
        data: payload
    })
}

export const getOrderFailed = (data) => {
    return api.makeAuthRequest({
        url: `/orders/fulfillments/pushed-to-supplier-not-found`,
        method: 'POST',
        data
    })
}

export const getListPrintFiles = (data) => {
    return api.makeAuthRequest({
        url: `/printing-files/search`,
        method: 'POST',
        data
    })
}

export const getCountPrintFiles = (data) => {
    return api.makeAuthRequest({
        url: `/printing-files/count`,
        method: 'POST',
        data
    })
}

export const checkFulfillmentNeedToPush = (data) => {
    return api.makeAuthRequest({
        url: `/printing-files/fulfillment-need-to-push`,
        method: 'POST',
        data
    })
}

export const getOrderNotePackage = (data) => {
    return api.makeAuthRequest({
        url: `/orders/note-search`,
        method: 'POST',
        data
    })
}

export const updatePrintFilesStatus = (fulfillId, fulfillItemId, status) => {
    return api.makeAuthRequest({
        url: `/printing-files/${fulfillId}/items/${fulfillItemId}/status/${status}`,
        method: 'PUT',
    })
}

export const updateLinkDesign = (data, fulfillItemId) => {
    return api.makeAuthRequest({
        url: `/fulfillment-items/printing-files/${fulfillItemId}/designs`,
        method: 'POST',
        data
    })
}

export const addFastProductionTag = (id) => {
    return api.makeAuthRequest({
        url: `/fulfillment/${id}/mark-fast-production`,
        method: 'POST',
    })
}

export const updateFulfillmentItem = (fulfillmentId, data) => {
    return api.makeAuthRequest({
        url: `/fulfillment/${fulfillmentId}/update-fulfillment-items`,
        method: 'POST',
        data
    })
}

export const getAllowedUpdateTags = () => {
    return api.makeAuthRequest({
        url: `/fulfillment/allowed-update-tags`,
        method: 'GET',
    })
}

export const updatePackageTags = (payload) => {
    return api.makeAuthRequest({
        url: `/fulfillment/update-package-tags`,
        method:'POST',
        data: payload,
    })
}

export const updateNoteOrder = (orderId, payload) => {
    return api.makeAuthRequest({
        url: `printing-files/${orderId}/note`,
        method: 'POST',
        data: payload,
    })
}

export const markItemNotNeedToPush = (fulfillId) => {
    return api.makeAuthRequest({
        url: `/fulfillment/${fulfillId}/no-need-to-push-after-update-printing-file`,
        method: 'PUT',
    })
}
