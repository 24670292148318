import React from 'react'
import { Spin, Pagination } from 'antd'
import emptyData from '../../../statics/assets/images/empty-img-gray.png'
import TableRow from './data/TableRow'
import TableHead from './data/TableHead'

const SpecialCaseTable = (props) => {
    const { loading, data, error, page, limit, total, onChangePage, onChangeSize, onChangeStatusSpecialCase, onDeleteSpecialCase, permissionsData } = props

    const _renderTableContent = () => {
        if (error) {
            return (
                <tr className='OrdersError'>
                    <td colSpan={10}>
                        <div className='text-danger'>
                            <span className='font-weight-bold'>ERROR:</span> {error}
                        </div>
                    </td>
                </tr>
            )
        }

        if (data.length === 0) {
            if (loading) return null

            return (
                <tr className='NoOrders'>
                    <td colSpan={10}><i>No items</i></td>
                </tr>
            )
        }

        return <TableRow
            data={data}
            onDeleteSpecialCase={onDeleteSpecialCase}
            onChangeStatusSpecialCase={onChangeStatusSpecialCase}
            permissionsData={permissionsData}
        />
    }

    const _handlePageChange = (page, pageSize) => {
        onChangeSize(pageSize)
        onChangePage(pageSize !== limit ? 1 : page)
    }

    return (
        <div className='SpecialCaseTable'>
            <Spin spinning={loading} tip='Loading...'>
                {data.length > 0 ? (
                    <div>
                        <div className="wrapTable">
                            <table className='table table-borderless'>
                                <TableHead />
                                <tbody>
                                    {_renderTableContent()}
                                </tbody>
                            </table>
                        </div>
                        <div className="OrdersPagination d-flex justify-content-end mt-4">
                            <Pagination current={page} total={total} pageSize={limit} onChange={_handlePageChange}
                                className='text-right' showSizeChanger pageSizeOptions={['10', '20', '50', '100']}
                            />
                        </div>
                    </div>
                ) : !loading && (
                    <div className="noData d-flex flex-column align-items-center justify-content-center">
                        <img src={emptyData} alt="No data" />
                        Không có bản ghi!
                    </div>
                )}
            </Spin>
        </div>
    )
}

export default SpecialCaseTable