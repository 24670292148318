import React,{useState} from 'react'
import { Modal, Input, message } from 'antd'
import { exportListFileDetails } from '../../../../services/api/SuppliersServices'
import moment from 'moment'



function ExportFileDetails(props) {
    const {
        showExportModal, 
        toggleExportModal,
        idFile, search,
        status,
    } = props

    const [fileName, setFileName] = useState('')
    const [error, setError] =useState('')

    const handleChangeFileName = (e) => {
        setFileName(e.target.value)
    }

    const toggleModal =() =>{
        toggleExportModal()
        setFileName('')
        setError('')
    }

    const handleExport = async() => {
        if(fileName!==''){
            const payload ={
                filename: fileName,
                is_archive: 'true',
                package_name: search,
                approval_status: status,
                process_status:'',
                reference_id:'',
                order_supplier_id:'',
            }
            try {
                const response = await exportListFileDetails(idFile,payload)
                if(response){
                    const {success, message: mess} = response
                    if(success==='false'){
                        message.error(mess)
                    }else{
                        let csvFile
                        let downloadLink
                        
                        const start = moment(Date.now()).format('YYYY/MM/DD')
                        csvFile = new Blob([response], { type: 'text/csv;encoding:utf-8' })
                        downloadLink = document.createElement('a')
                        downloadLink.download = `${fileName}_${start}`
                        downloadLink.href = window.URL.createObjectURL(csvFile)
                        downloadLink.style.display = 'none'
                        document.body.appendChild(downloadLink)
                        downloadLink.click()
                        document.body.removeChild(downloadLink)
                        message.success('Export Order Profit success!')
                        toggleModal()
                    }
                }else{
                    message.error('Export Order Profit fail!')
                }
            } catch (error) {
                message.error(error)
            }
        }else{
            setError("Please input file name!")
        }
    }

    return (
        <Modal 
            className='ExportFileDetails'
            visible={showExportModal}
            onOk={handleExport}
            onCancel={toggleModal}
            okText='Export'
    >
        <label>File name:</label>
        <Input
            placeholder='Input file name...'
            value={fileName}
            onChange={handleChangeFileName}
            className='FileName'
        >
        </Input>
        {!!error&&
            <small className='text-danger'>{error}</small> 
        }
    </Modal>

    )
}

export default ExportFileDetails