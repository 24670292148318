import React, {Component} from 'react'
import FulfilledPackageItems from "./FulfilledPackageItems"
import FulfilledPackageTracking from "./FulfilledPackageTracking"
import FulfilledPackageSupplier from "./FulfilledPackageSupplier"
import {Badge, message, Tooltip, Tag} from 'antd'
import PropTypes from 'prop-types'
import PackagePusherContainer from "./PackagePusherContainer"
import ShipmentStatus from "../../../shared/ShipmentStatus"
import {
    detectChargeOrRefund,
    submitRefund,
    submitCharge,
    updateOrderFulfillmentSupplier,
    getStatusPrintforia, repairProgressArtworkStatus,
    createChargeRefund,
} from "../../../../services/api/OrdersServices"
import broker from "../../OrderBroker"
import ChangeSupplierModal from "./changeSupplier/ChangeSupplierModal"
import {getListSuppliersV2} from "../../../../services/api/SuppliersServices"
import {getLocalData} from "../../../../services/StorageServices"
import {pushOrderPackage} from "../../../../services/api/ExportOrderServices"
import formatCurrency from "../../../../helpers/common/formatCurrency"
import StatusStyle from "../../../shared/StatusStyle"
import moment from "moment"
import {Link} from "react-router-dom"
import {ReactComponent as PriceInfo} from "../../../../statics/assets/images/icons8-info.svg"
import StatusColumn from '../../../order-partners-v2/components/data/StatusColumn'
import {parseJwt} from '../../../../helpers/parseToken'
import {getArtworksByOrderItemV3} from "../../../../services/api/ArtworksServices"
import {InfoCircleOutlined} from '@ant-design/icons'
import OrderCancelItems from './OrderCancelItems'

class FulfilledPackage extends Component {
    constructor(props) {
        super(props)

        const {fulfilledPackage} = props
        const {supplier = {}} = fulfilledPackage

        this.state = {
            supplierIdState: supplier._id,
            visible: false,
            loading: false,
            suppliers: [],
            error: '',
            pushPackage: false,
            pushSuccess: false,
            statusPF: '',
            chargeOrRefund: false,
            valuePercent: 0,
            valuePrice: 0,
            reasonTag: '',
            reasonSeller: '',
            supporter: '',
            artworks: {},
            shippingCarrier: '',
            urgentProduction: false,
        }
    }
    _checkPushAt = () => {
        const { fulfilledPackage } = { ...this.props }

        const { items } = { ...fulfilledPackage }
        let hasPushAt = false

        items.forEach(item => {
          if (item.fulfillmentItems) {
            item.fulfillmentItems.forEach(fulfillmentItem => {
              if (fulfillmentItem.push_at) {
                hasPushAt = true
                return
              }
            })
          }
          if (hasPushAt) return
        })
    
        if (hasPushAt) {
          this.setState({ urgentProduction: true })
        } else {
          this.setState({ urgentProduction: false })
        }
    }

    componentDidMount() {
        this._fetchListSupplier()
        this._getStatusPrintforia()
        this.maybeFetchArtworks()
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const {fetchOrderPackageCount: prevFetchOrderPackageCount} = prevProps
        const {fetchOrderPackageCount} = this.props

        if (prevFetchOrderPackageCount !== fetchOrderPackageCount) {
            this.maybeFetchArtworks()
        }
    }

    setValueShippingCarrier = (supplier, shippingPlan = '', orderType = '') => {
        if(!supplier) return 
        let shippingCarrier = ''

        if(supplier === "YC") shippingCarrier = 'Standard shipping'
        if(supplier === "DBUS") shippingCarrier = 'Standard shipping UPS'
        if(supplier === "DPCN") shippingCarrier = 'Standard Delivery'
        if(supplier === "SPUS"){
            shippingCarrier = shippingPlan === 'fast' ? 'priority' : shippingPlan === 'express' ? 'express' : 'standard'
        }
        if(supplier === "FSUS"){
            let shippingCarrier = 'FirstClass'

            if(orderType === "TIKTOK") shippingCarrier = 'Tiktok2Days'
            if(shippingPlan === "fast" && orderType !== "TIKTOK") shippingCarrier = 'Priority'
            // if((shippingPlan === 'standard' || shippingPlan === "shipbytiktok") && orderType === "TIKTOK") shippingCarrier = 'Rush Production Tiktok Standard'
            // if(orderType === "TIKTOK" && shippingPlan === "fast") shippingCarrier = "Rush Production Tiktok Priority" 
            // if(orderType === "TIKTOK" && shippingPlan === "shipbytiktok") shippingCarrier = 'Tiktok2Days'
            
            return shippingCarrier
        } 
        if(supplier === "PYUS") {
            shippingCarrier = shippingPlan === 'fast' ? 'priority' : shippingPlan === 'express' ? 'express' : 'standard'
        }
        if(supplier === "MDUS") {
            shippingCarrier = shippingPlan === "standard" ? "standard" : shippingPlan === "fast" ? "priority" : "express"
        }
        if(supplier === "CC") {
            shippingCarrier = shippingPlan === "standard" ? "standard" : shippingPlan === "fast" ? "priority" : "express"
        }
        if(supplier === "PBAU") {
            shippingCarrier = shippingPlan === "express" ? "express" : "standard"
        }
    
        return shippingCarrier
    }

    maybeFetchArtworks = async () => {
        const {fulfilledPackage} = this.props
        const {items} = fulfilledPackage
        const artworks = {}

        if (Array.isArray(items)) {
            for (let i = 0; i < items.length; i++) {
                const {fulfillmentItems, _id: orderItemId} = items[i]
                const isCheckVersionOrderV2 = false//fulfillmentItems.length > 0 ? fulfillmentItems.some(item => item.artworks.length > 0) : false
                artworks[orderItemId] = fulfillmentItems

                if (!isCheckVersionOrderV2) {
                    const {orderId} = this.props

                    try {
                        const {data, success, message} = await getArtworksByOrderItemV3({orderId, orderItemId})

                        if (success) {
                            artworks[orderItemId] = data[0]
                        }
                    } catch (e) {

                    }
                }
            }
        }

        this.setState({artworks}, this.calEmbroiderySurcharge)
    }
    _onChangeValueForm = (name, value) => {
        this.setState({
            [name]: value
        })
    }

    _setChargeOrRefund = (value) => {
        this.setState({
            chargeOrRefund: value
        })
    }

    _closeConfirm = () => {
        const {fulfilledPackage} = this.props
        const {supplier = {}} = fulfilledPackage

        this.setState({
            visible: false,
            supplierIdState: supplier._id,
            pushPackage: true
        })
        this._checkPushAt()
    }

    _fetchDetectChargeOrRefund = async () => {
        try {
            const {supplierIdState} = this.state
            const {orderId, fulfilledPackage} = this.props
            const {_id: fulfillmentID} = fulfilledPackage

            const {
                data,
                success,
                message
            } = await detectChargeOrRefund(orderId, fulfillmentID, supplierIdState)

            if (!success) {
                console.log(message)
            }

            return this.setState({
                chargeOrRefund: data,
            })
        } catch (e) {
            console.log(e.message)
        }
    }

    _fetchListSupplier = async () => {
        try {
            const {data, success, message: messageError} = await getListSuppliersV2({active: true})

            if (!success) {
                return this.setState({
                    error: messageError,
                })
            }
            const {suppliers} = data

            return this.setState({
                suppliers: suppliers,
                error: "",
            })
        } catch (e) {
            return this.setState({
                error: e.message,
            })
        }
    }

    _onChangeSupplier = (supplierId) => {
        this._checkPushAt()
        this.setState({
            error: '',
            errorChangeSupplier: '',
            supplierIdState: supplierId,
            visible: true
        }, () => {
            const {suppliers} = this.state
            this._fetchDetectChargeOrRefund()

            const {order : {shipping_plan = {}, order_type = ''}} = this.props
            const {slug = ''} = shipping_plan
            const supplierSelected = suppliers.find(item => item._id === supplierId) || {}
            const shippingCarrier = this.setValueShippingCarrier(supplierSelected.sku_prefix, slug, order_type)
            this.setState({shippingCarrier})
        })
    }

    _getStatusPrintforia = async () => {
        try {
            const {fulfilledPackage} = this.props
            const {_id: fulfillmentId} = fulfilledPackage

            const {success, data} = await getStatusPrintforia(fulfillmentId)

            if (!success) return

            this.setState({
                statusPF: data.status
            })
        } catch (e) {

        }
    }

    _pushOrderPackage = async () => {
        this.setState({
            loading: true,
            error: '',
            pushSuccess: false
        })

        try {
            const {supplierIdState: supplierId} = this.state
            const {orderId, fulfilledPackage} = this.props
            const {_id: fulfillmentId} = fulfilledPackage

            const supliers = [
                {
                    'sku_prefix': '1C',
                    'sheet_url': getLocalData(`exportGgDrv.link.1c`, ''),
                    'sheet_name': getLocalData(`exportGgDrv.name.1c`, '')
                },
                {
                    'sku_prefix': 'BG',
                    'sheet_url': getLocalData(`exportGgDrv.link.basegift`, ''),
                    'sheet_name': getLocalData(`exportGgDrv.name.basegift`, '')
                },
                {
                    'sheet_url': getLocalData(`exportGgDrv.link.other`, ''),
                    'sheet_name': getLocalData(`exportGgDrv.name.other`, '')
                },
            ]

            const payload = {
                orderId: orderId,
                supplier_sheets: supliers
            }

            const response = await pushOrderPackage(fulfillmentId, supplierId, payload)

            if (!response.success) {
                return this.setState({
                    error: response.message,
                    loading: false,
                })
            }

            const {push_results} = response.data

            broker.publish("TRACKING_UPDATED")
            broker.publish('ORDER_REFRESHED_ORDER')
            broker.publish('ORDER_REFRESH_BRANDING')
            broker.publish('ORDER_RESTORE')

            if (!push_results[0].success) {
                message.error('Push package error!')

                return this.setState({
                    error: push_results[0].error_message,
                    loading: false
                })
            }

            this.setState({
                pushSuccess: true,
                loading: false,
            }, () => {
                message.success('Push package and change supplier success!')
            })
        } catch (e) {
            this.setState({
                loading: false,
                error: e.message
            })
        }
    }

    _handleChangeSupplier = async (supplierId) => {
        const {orderId, fulfilledPackage} = this.props
        const {shippingCarrier, urgentProduction} = this.state
        const {_id: fulfillmentId} = fulfilledPackage

        this.setState({
            loading: true,
            errorChangeSupplier: ''
        })

        try {
            const payload = {
                supplier: supplierId,
            }
            if(!!shippingCarrier) payload.shipping_carrier = shippingCarrier
            if(urgentProduction) payload.priority = 'urgent'
            const {success, message: error} = await updateOrderFulfillmentSupplier(orderId, fulfillmentId, payload)

            if (!success) {
                return this.setState({
                    errorChangeSupplier: error,
                    loading: false
                })
            }

            this.setState({
                loading: false,
                supplierIdState: supplierId,
                visible: false
            }, () => {
                const {pushPackage} = this.state
                if (!pushPackage) {
                    message.success('Change supplier success!')
                    broker.publish("TRACKING_UPDATED")
                    broker.publish('ORDER_REFRESHED_ORDER')
                    broker.publish('ORDER_REFRESH_BRANDING')
                    broker.publish('ORDER_RESTORE')
                }
            })
        } catch (e) {
            return this.setState({
                errorChangeSupplier: e.message,
                loading: false
            })
        }
    }

    _handleChangeSupplierAndPush = async (supplierId) => {
        const {pushPackage} = this.state

        if (pushPackage) {
            await this._handleChangeSupplier(supplierId)

            const {error, errorChangeSupplier} = this.state

            if (error === '' && errorChangeSupplier === '') {
                this.setState({
                    visible: false
                })

                await this._pushOrderPackage()
            }
        } else {
            await this._handleChangeSupplier(supplierId)
        }
    }

    _handleChangePush = (value) => {
        this.setState({
            pushPackage: value
        })
    }

    _handleUrgentProduction = (value) => {
        this.setState({
            urgentProduction: value
        })
    }

    _handleChangeShippingCarrier = (value) => {
        this.setState({
            shippingCarrier: value
        })
    }

    _convertString = (string) => {
        const {fulfilledPackage} = this.props
        const {currency: rCurrency} = fulfilledPackage
        let result = string

        if (typeof string === 'string') {
            if (rCurrency === 'USD') {
                result = string.replaceAll(/[,]/g, '')
            } else {
                result = string.replaceAll(/[.]/g, '')
            }
        }

        return result
    }

    _submitRefund = async () => {
        try {
            this.setState({
                loading: true,
                error: ''
            })

            const user = getLocalData('user')
            const {orderId, fulfilledPackage} = this.props
            const {currency} = fulfilledPackage
            const {valuePercent, valuePrice, reasonTag, reasonSeller, supporter} = this.state
            const payload = {
                refund_amount: this._convertString(valuePrice),
                reason_tag: reasonTag,
                reason_for_seller: reasonSeller,
                user_id: user.id,
                currency: currency,
                refund_percent: valuePercent,
                supporter: supporter
            }

            const response = await createChargeRefund('refund', orderId, payload)

            const {success, message} = response

            if (!success) {
                this.setState({
                    loading: false,
                    error: message
                })

                return false
            }

            broker.publish('ORDER_REFRESHED_ORDER')

            return true
        } catch (e) {
            this.setState({
                loading: false,
                error: e.message
            })
        }
    }

    _submitCharge = async () => {
        try {
            this.setState({
                loading: true,
                error: ''
            })

            const user = getLocalData('user')
            const {valuePercent, valuePrice, reasonTag, reasonSeller, supporter} = this.state
            const {orderId, fulfilledPackage} = this.props
            const {currency} = fulfilledPackage
            const payload = {
                charge_percent: valuePercent || "0",
                charge_amount: this._convertString(valuePrice),
                currency: currency,
                reason_tag: reasonTag,
                reason_for_seller: reasonSeller,
                user_id: user.id,
                supporter: supporter
            }

            const response = await createChargeRefund('charge', orderId, payload)

            const {success, message} = response

            if (!success) {
                this.setState({
                    loading: false,
                    error: message
                })

                return false
            }

            broker.publish('ORDER_REFRESHED_ORDER')

            return true
        } catch (e) {
            this.setState({
                loading: false,
                error: e.message
            })
        }
    }

    _onChangeSupplierAndRefundCharge = async (supplierId) => {
        let success = false
        const {chargeOrRefund} = this.state

        if (chargeOrRefund === 'refund') {
            success = await this._submitRefund()
        } else if (chargeOrRefund === 'charge') {
            success = await this._submitCharge()
        } else {
            success = true
        }

        if (success) {
            await this._handleChangeSupplierAndPush(supplierId)
        }
    }

    _onRegenAgainFulfillment = async () => {
        try {
            const {fulfilledPackage} = this.props
            const {_id: fulfillmentId} = fulfilledPackage

            const payload = {
                fulfillments: [fulfillmentId],
                status: 'retry'
            }

            const response = await repairProgressArtworkStatus(payload)

            const {success, message: error} = response

            if (!success) {
                return message.error(error)
            }

            message.success('Retry success.')
            broker.publish('ORDER_RESTORE')
        } catch (e) {
            message.error(e.message)
        }
    }

    _checkDate = (string) => {
        if (!!string) {
            const arr = string.split('//')
            const newArr = arr[1].split('/')

            const day = moment().diff(moment(newArr[1], 'DD-MM-YYYY').add(90, 'days'), 'days')

            if (!!day && day > 0) {
                return day
            }

            return string
        }
    }

    isAllowEmbroiderySurcharge = (fulfillItem) => {
        return this.props.isAllowEmbroiderySurcharge(fulfillItem, this.props.fulfilledPackage)
    }

    selectComplexEmbroidery = (artwork, fulfillItem) => {
        return this.props.selectComplexEmbroidery(artwork, fulfillItem, this.props.fulfilledPackage)
    }

    calEmbroiderySurcharge = () => {
        return this.props.calEmbroiderySurcharge(this.props.fulfilledPackage)
    }

    handleEmbroiderySurcharge = () => {
        this.props.showEmbroiderySurcharge(true, {
            chargeValue: this.state.totalEmbroiderySurcharge,
            fulfillment: this.props.fulfilledPackage,
            productsSurcharge: this.getEmbroiderySurcharge(),
            selectedComplexEmbroidery: this.state.selectedComplexEmbroidery
        })
    }

    getButtonEmbroiderySurchargeText = (withCurrency = true) => {
        return this.props.getButtonEmbroiderySurchargeText(withCurrency, this.props.fulfilledPackage)
    }

    getEmbroiderySurcharge = () => {
        return this.props.getEmbroiderySurcharge(this.props.fulfilledPackage)
    }

    render() {
        const {
            fulfilledPackage,
            orderId,
            completedAt,
            canRestore,
            fulfillmentStatus,
            onUpdateTotalPushToProduction,
            preference,
            paymentStatus,
            invoiceRefund,
            orderType,
            setArtworks,
            fetchOrderFulfilledItems,
            selectedComplexEmbroidery,
            order,
        } = this.props

        const {
            name,
            shipping_cost,
            fulfillment_cost,
            items,
            tracking_company,
            tracking_number,
            tracking_url,
            _id: fulfillmentId,
            shipment_status,
            supplier,
            currency,
            ff_processed_artworks_pf,
            artwork_pf_status,
            artwork_pf_processing_at,
            artworks_pf_note,
            package_status,
        } = fulfilledPackage
        const {
            supplierIdState,
            suppliers,
            visible,
            loading,
            error,
            errorChangeSupplier,
            pushPackage,
            chargeOrRefund,
            statusPF,
            valuePercent,
            valuePrice,
            reasonTag,
            reasonSeller,
            supporter,
            shippingCarrier,
            urgentProduction,
        } = this.state
        const supplierSelected = suppliers.find(item => item._id === supplierIdState)
        const {fulfillmentInvoice} = fulfilledPackage
        const {shipping_plan = {}} = Object.assign({}, order)
        const {slug = ''} = shipping_plan
        const getListArt = (!!ff_processed_artworks_pf && ff_processed_artworks_pf.length > 0) ? Object.keys(ff_processed_artworks_pf[0].processed_artworks_pf) : ''
        const days = this._checkDate((!!getListArt && getListArt.length > 0) ? ff_processed_artworks_pf[0].processed_artworks_pf[getListArt[0]] : '')
        const artworkPFProcessingAt = artwork_pf_processing_at ? moment(artwork_pf_processing_at).format('MMMM Do YYYY, HH:mm:ss a') : ""
        const ItemBaseCostFromStore = items.filter(item => item.productLineVariant.price)
        const permissionsData = getLocalData('permissions') ? getLocalData('permissions') : {}
        const showPushButton = ((((fulfillmentStatus === 'processing' || fulfillmentStatus === 'fulfilled')
            && fulfillmentStatus !== 'onhold' && fulfillmentStatus !== 'cancelled')
            && supplierSelected !== undefined) && (orderType !== "FBA" && slug !== "express"))
        const hasEmbroiderySurcharge = fulfilledPackage.did_actions && fulfilledPackage.did_actions.includes('embroidery_surcharge')
        const showEmbroiderySurchargeButton = !(hasEmbroiderySurcharge)
            && permissionsData.hasOwnProperty('ffm_order_detail_embroidery_surcharge_create')
            && !!Object.keys(this.getEmbroiderySurcharge()).length

        

        return (
            <>
                <div className="FulfilledPackage mb-4">
                    <div className="card">
                        <div className="card-header d-flex justify-content-between">
                            <div className="font-weight-bold d-flex">
                                <Link to={{pathname: `/a/orders-supplier/${fulfillmentId}`}}>{name} </Link>
                                <span className="font-weight-normal">({fulfilledPackage.push_status})</span>
                                {!!package_status &&
                                <div className='ml-5 text-capitalize'>
                                    <StatusColumn status={package_status || ''}/>
                                </div>
                                }

                                {
                                    !!days && days > 0 &&
                                    <span
                                        className="ml-3 font-weight-normal text-danger CustomAlert"
                                        onClick={this._onRegenAgainFulfillment}
                                    >
                                        This package contains expired print files.
                                        <Tooltip title="Regenerate the print file before push factory again.">
                                            <i className="ti-loop cursor-pointer"/>
                                        </Tooltip>
                                    </span>
                                }
                            </div>

                            <ShipmentStatus status={shipment_status}/>

                            <FulfilledPackageTracking
                                key={fulfillmentId}
                                fulfillmentId={fulfillmentId}
                                orderId={orderId}
                                url={tracking_url}
                                company={tracking_company}
                                number={tracking_number}
                                fulfillment={fulfilledPackage}
                                fetchHistory={this.props.fetchHistory}
                            />

                        </div>

                        <div className="card-body bg-light">
                            <div className="d-flex mb-4 justify-content-between align-items-baseline">
                                <div className="d-flex align-items-center flex-wrap">
                                    <div className="itemCost mr-5 mb-1">
                                        <span className="font-weight-500 mr-2">Shipping cost:</span>
                                        {formatCurrency(currency, shipping_cost)}
                                    </div>

                                    {
                                        (fulfilledPackage.hasOwnProperty('branding_cost') && fulfilledPackage.branding_cost !== 0) && (
                                            <div className="itemCost mr-5 mb-1">
                                                <span className="font-weight-500 mr-2">Branding cost:</span>
                                                {formatCurrency(currency, fulfilledPackage.branding_cost)}
                                            </div>
                                        )
                                    }

                                    <div className="itemCost mr-5 mb-1">
                                        <span className="font-weight-500 mr-2">Base cost:</span>
                                        {formatCurrency(currency, fulfillment_cost)}
                                        {
                                            ItemBaseCostFromStore.length > 0 &&
                                            <Tooltip title="Price apply for seller">
                                                <span><PriceInfo width="20px" height="20px" style={{
                                                    position: 'relative',
                                                    top: '4px',
                                                    marginLeft: '4px'
                                                }}/></span>
                                            </Tooltip>
                                        }
                                    </div>

                                    {
                                        (!!fulfillmentInvoice && !!fulfillmentInvoice.discount_amount && fulfillmentInvoice.discount_amount > 0) && (
                                            <div className="itemCost mr-5 mb-1">
                                                <span className="font-weight-500 mr-2">Discount:</span>
                                                -{formatCurrency(currency, fulfillmentInvoice.discount_amount || 0)}
                                            </div>
                                        )
                                    }

                                </div>
                                <div className="d-flex align-items-center">
                                {
                                        artwork_pf_status &&
                                        <div className='itemCost mr-5 mb-1'>
                                            <Tooltip
                                                title={
                                                    artwork_pf_status === "error" ? artworks_pf_note :
                                                        artwork_pf_status === "done" ? "Printing files are ready!" :
                                                            artwork_pf_status === "processing" ? artworkPFProcessingAt :
                                                                "Waiting for generate printing files ..."
                                                }
                                                placement="bottom"
                                            >
                                                <Tag
                                                    className={
                                                        artwork_pf_status === "processing" ? "badge badge-primary processing-animation" :
                                                            artwork_pf_status === "error" ? "badge badge-danger" :
                                                                artwork_pf_status === "pending" ? "badge badge-info pending-animation" :
                                                                    artwork_pf_status === "retry" ? "badge badge-info" :
                                                                        "badge badge-success"
                                                    }
                                                >
                                                    {artwork_pf_status}
                                                </Tag>
                                            </Tooltip>
                                        </div>
                                    }
                                </div>
                                <div className="d-flex align-items-center">
                                    {
                                        statusPF && (
                                            <div className="mr-3">
                                                <label className="mb-0">Printforia: <StatusStyle status={statusPF}/></label>
                                            </div>
                                        )
                                    }

                                    {
                                        supplierSelected !== undefined && (
                                            <div>
                                                <FulfilledPackageSupplier
                                                    paymentStatus={paymentStatus}
                                                    orderId={orderId}
                                                    fulfillmentId={fulfillmentId}
                                                    suppliers={suppliers}
                                                    supplierCurrent={supplier._id}
                                                    supplierSelect={supplierSelected._id}
                                                    fulfilledPackage={fulfilledPackage}
                                                    handleChangeSupplier={this._onChangeSupplier}
                                                    permissionsData={permissionsData}
                                                />
                                                {
                                                    visible &&
                                                    <ChangeSupplierModal
                                                        invoiceRefund={invoiceRefund}
                                                        valuePercent={valuePercent}
                                                        valuePrice={valuePrice}
                                                        reasonTag={reasonTag}
                                                        reasonSeller={reasonSeller}
                                                        supporter={supporter}
                                                        visible={visible}
                                                        orderId={orderId}
                                                        preference={preference}
                                                        chargeOrRefund={chargeOrRefund}
                                                        error={error}
                                                        errorChangeSupplier={errorChangeSupplier}
                                                        fulfilledPackage={fulfilledPackage}
                                                        loading={loading}
                                                        supplierSelected={supplierSelected}
                                                        pushPackage={pushPackage}
                                                        urgentProduction={urgentProduction}
                                                        onChangeValueForm={this._onChangeValueForm}
                                                        onChangePush={this._handleChangePush}
                                                        onClose={this._closeConfirm}
                                                        onChangeAndPush={this._onChangeSupplierAndRefundCharge}
                                                        onChargeOrRefund={this._setChargeOrRefund}
                                                        order={order}
                                                        shippingCarrier={shippingCarrier}
                                                        onChangeShippingCarrier={this._handleChangeShippingCarrier}
                                                        onChangeUrgentProduction={this._handleUrgentProduction}
                                                    />
                                                }
                                            </div>
                                        )
                                    }
                                </div>
                            </div>

                            <FulfilledPackageItems
                                fulfilledPackageId={fulfilledPackage._id}
                                orderId={orderId}
                                items={items}
                                completedAt={completedAt}
                                canRestore={canRestore}
                                fulfillmentStatus={fulfillmentStatus}
                                ff_processed_artworks_pf={ff_processed_artworks_pf}
                                supplierCurrent={supplier._id}
                                supplierName={supplier.name}
                                orderType={orderType}
                                setArtworks={setArtworks}
                                fetchOrderFulfilledItems={fetchOrderFulfilledItems}
                                selectedComplexEmbroidery={selectedComplexEmbroidery}
                                selectComplexEmbroidery={this.selectComplexEmbroidery}
                                handleEmbroiderySurcharge={this.handleEmbroiderySurcharge}
                                getButtonEmbroiderySurchargeText={this.getButtonEmbroiderySurchargeText}
                                showEmbroiderySurchargeButton={showEmbroiderySurchargeButton}
                                hasEmbroiderySurcharge={hasEmbroiderySurcharge}
                                isAllowEmbroiderySurcharge={this.isAllowEmbroiderySurcharge}
                                supplier={supplier}
                                fetchHistory={this.props.fetchHistory}
                                slug={slug}
                                packageStatus={package_status}
                            />
                        </div>

                        {
                            (showPushButton || showEmbroiderySurchargeButton) &&
                            <div className="card-footer d-flex">
                                <PackagePusherContainer
                                    onUpdateTotalPushToProduction={onUpdateTotalPushToProduction}
                                    supplierSelected={supplierSelected}
                                    fulfillment={fulfilledPackage}
                                    orderId={orderId}
                                    paymentStatus={paymentStatus}
                                    preference={preference}
                                    selectedComplexEmbroidery={selectedComplexEmbroidery}
                                    selectComplexEmbroidery={this.selectComplexEmbroidery}
                                    handleEmbroiderySurcharge={this.handleEmbroiderySurcharge}
                                    getButtonEmbroiderySurchargeText={this.getButtonEmbroiderySurchargeText}
                                    showEmbroiderySurchargeButton={showEmbroiderySurchargeButton}
                                    showPushButton={showPushButton}
                                    hasEmbroiderySurcharge={hasEmbroiderySurcharge}
                                    isAllowEmbroiderySurcharge={this.isAllowEmbroiderySurcharge}
                                />
                            </div>
                        }
                    </div>
                </div>

                <OrderCancelItems packageStatus={package_status} items={items}/>
            </>
        )
    }
}


FulfilledPackage.propTypes = {
    preference: PropTypes.object,
    orderId: PropTypes.string.isRequired,
    fulfillmentStatus: PropTypes.string.isRequired,
    fulfilledPackage: PropTypes.object.isRequired,
    onUpdateTotalPushToProduction: PropTypes.func.isRequired,
    completedAt: PropTypes.string,
    canRestore: PropTypes.bool,
    paymentStatus: PropTypes.string,
    refunded: PropTypes.object
}

export default FulfilledPackage
