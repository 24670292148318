import React, {Component} from 'react'
import FulfilledPackage from "./FulfilledPackage"
import PropTypes from 'prop-types'
import PushAllPackages from './package-push/PushAllPackages'
import RefundButton from './refund/RefundButton'
import {getLocalData} from '../../../../services/StorageServices'
import {parseJwt} from '../../../../helpers/parseToken'
import RefundPopup from './refund/RefundPopup'
import {Tooltip} from "antd"
import {InfoCircleOutlined} from "@ant-design/icons"

class OrderFulfilled extends Component {
    state = {
        showPopupSurcharge: false,
        selectedComplexEmbroidery: {}
    }

    showEmbroiderySurcharge = (isShow) => {
        this.setState({showPopupSurcharge: isShow === undefined ? !this.state.showPopupSurcharge : isShow})
    }

    showEmbroiderySurchargePopup = () => {
        const {
            showPopupSurcharge,
            embroiderySurchargeByPackage,
            totalEmbroiderySurcharge
        } = this.state
        const {
            fulfillmentStatus,
            fulfilledPackages,
            orderId,
            code
        } = this.props
        const {currency} = {...fulfilledPackages[0]}
        const getAdditionData = () => {
            return {
                embroidery_surcharge: {
                    value: totalEmbroiderySurcharge,
                    embroiderySurchargeByPackage
                }
            }
        }
        const invoiceRefund = {}

        return showPopupSurcharge
            ? <RefundPopup isOpen={showPopupSurcharge}
                           isDisableOptions={true}
                           onOk={this.props.fetchOrderFulfilledItems}
                           onTogglePopup={() => this.showEmbroiderySurcharge()}
                           refundChargeValue={totalEmbroiderySurcharge}
                           getAdditionRequestData={getAdditionData}
                           buttonText="Request Embroidery Surcharge"
                           typeRefund="charge"
                           reasonTagText='Surcharge'
                           reason='Embroidery Fee'
                           orderId={orderId}
                           code={code}
                           fulfillmentStatus={fulfillmentStatus}
                           invoiceRefund={invoiceRefund}
                           currency={currency}/>
            : ''
    }

    isAllowEmbroiderySurcharge = (fulfillItem, fulfillment) => {
        const surchargeProducts = this.getEmbroiderySurcharge(fulfillment)
        const {productLineVariant} = {...fulfillItem}
        const {product} = {...productLineVariant}
        const pType = product && product.type || (productLineVariant && productLineVariant.product_type)

        // if (`${window.location.href}`.match(/^https?:\/\/localhost/)) {
        //     console.log({
        //         fulfillItem,
        //         pType,
        //         productLineVariant,
        //         surchargeProducts
        //     })
        // }

        return surchargeProducts && surchargeProducts[pType]
    }

    isAllowEmbroiderySurchargePackage = (fulfillment) => {
        const {items} = fulfillment
        const {fulfillmentItems} = {...items[0]}
        const isCheckVersionOrderV2 = Array.isArray(fulfillmentItems) && fulfillmentItems.length > 0 ? fulfillmentItems.some(item => item.artworks.length > 0) : false
        const vItems = isCheckVersionOrderV2
            ? (items || []).reduce((a, b) => {
                return a.concat(b.fulfillmentItems.map(it => {
                    it.productLineVariant = b.productLineVariant
                    return it
                }))
            }, [])
            : items
        return (vItems || []).some(item => {
            return this.isAllowEmbroiderySurcharge(item, fulfillment)
        })
    }

    selectComplexEmbroidery = (artwork, fulfillItem, fulfillment) => {
        if (!artwork) {
            return
        }

        const selectedComplexEmbroidery = this.getSelectedComplexEmbroidery(fulfillment)
        const {_id} = artwork
        const {_id: fulfillmentItemId} = fulfillItem
        const theKey = `${_id}_${fulfillmentItemId}`
        const {[theKey]: selected} = selectedComplexEmbroidery

        if (!this.isAllowEmbroiderySurcharge(fulfillItem, fulfillment)) {
            return
        }

        if (selected) {
            delete selectedComplexEmbroidery[theKey]
        } else {
            selectedComplexEmbroidery[theKey] = artwork
        }

        this.setSelectedComplexEmbroidery(fulfillment, selectedComplexEmbroidery, this.calEmbroiderySurcharge)
    }

    getSelectedComplexEmbroidery = (fulfillment) => {
        const {
            selectedComplexEmbroidery
        } = this.state

        return selectedComplexEmbroidery ? (selectedComplexEmbroidery[fulfillment._id] || {}) : {}
    }

    setSelectedComplexEmbroidery = (fulfillment, packageSelectedComplexEmbroidery, cb) => {
        const {
            selectedComplexEmbroidery
        } = this.state

        this.setState({
            selectedComplexEmbroidery: {
                ...selectedComplexEmbroidery,
                [fulfillment._id]: packageSelectedComplexEmbroidery
            }
        }, cb)
    }

    calEmbroiderySurcharge = () => {
        const {fulfilledPackages} = this.props
        const embroiderySurchargeByPackage = {}
        const totalEmbroiderySurcharge = fulfilledPackages && fulfilledPackages.length && fulfilledPackages.reduce((total, fulfillment) => {
            embroiderySurchargeByPackage[fulfillment._id] = this.calEmbroiderySurchargeEachPackage(fulfillment)

            return total + embroiderySurchargeByPackage[fulfillment._id].total
        }, 0) || 0

        this.setState({
            totalEmbroiderySurcharge,
            embroiderySurchargeByPackage
        })
    }

    calEmbroiderySurchargeEachPackage = (fulfillment) => {
        const {packageArtworks, items} = fulfillment
        const selectedComplexEmbroidery = this.getSelectedComplexEmbroidery(fulfillment)
        const isCheckVersionOrderV2 = items.some(item => {
            const {fulfillmentItems} = {...item}
            return fulfillmentItems.length > 0 ? fulfillmentItems.some(item => item.artworks.length > 0) : false
        })
        const vItems = isCheckVersionOrderV2
            ? items.reduce((a, b) => {
                return a.concat(b.fulfillmentItems.map(it => {
                    it.productLineVariant = b.productLineVariant
                    return it
                }))
            }, [])
            : items

        const getSidePrice = (surcharge, index, isComplex) => {
            const {
                complex,
                simple,
                second_rental_fee
            } = surcharge

            const price = !index ? (isComplex ? complex : simple) : second_rental_fee

            return isNaN(price) ? 0 : +price
        }
        const surchargeProducts = this.getEmbroiderySurcharge(fulfillment)

        return vItems.reduce((mem, item) => {
            const {_id: orderItemId, productLineVariant, quantity} = item
            const {product} = productLineVariant
            const vItem = isCheckVersionOrderV2 ? item : (packageArtworks ? packageArtworks[orderItemId] : [])
            const {artworks} = {...vItem}

            const pType = product && product.type || (productLineVariant && productLineVariant.product_type)

            if (!surchargeProducts || !surchargeProducts[pType]) {
                return mem
            }

            const totalOfItem = (artworks || []).reduce((totalOfItem, artwork, index) => {
                const {_id} = artwork

                return totalOfItem + getSidePrice(surchargeProducts[product.type], index, selectedComplexEmbroidery[`${_id}_${orderItemId}`])
            }, 0)

            return {
                ...mem,
                total: mem.total + totalOfItem * (quantity || 1)
            }
        }, {
            total: 0,
            name: fulfillment.name,
            surchargeProducts,
            selectedComplexEmbroidery
        })
    }

    handleEmbroiderySurcharge = () => {
        this.showEmbroiderySurcharge()
    }

    getButtonEmbroiderySurchargeText = (withCurrency = true, fulfillment) => {
        const {embroiderySurchargeByPackage, totalEmbroiderySurcharge} = this.state

        const value = fulfillment
            ? (embroiderySurchargeByPackage && embroiderySurchargeByPackage[fulfillment._id] && embroiderySurchargeByPackage[fulfillment._id].total)
            : totalEmbroiderySurcharge

        const titleEmbroiderySurcharge = fulfillment
            ? 'Package Surcharge'
            : 'Total Surcharge'

        return [<span key={0}>{titleEmbroiderySurcharge}</span>, ' ',
            <span key={1}>{withCurrency ? '$' : ''}{value || 0}</span>]
    }

    getEmbroiderySurcharge = (fulfillment) => {
        const {order} = this.props
        const {items} = fulfillment
        const {namespace} = {...order}

        return (items || []).reduce((mem, item) => {
            const {productLineVariant} = item
            const {product} = productLineVariant
            const {embroidery_surcharge, type} = product

            if (!embroidery_surcharge || (Array.isArray(embroidery_surcharge) && !embroidery_surcharge.length)) {
                return mem
            }

            const foundItemByStore = embroidery_surcharge.find(it => {
                return (it.store || []).includes(namespace)
            })

            const foundItemByAll = embroidery_surcharge.find(it => {
                return !it.store || (Array.isArray(it.store) && !it.store.length)
            })

            // Ưu tiên giá theo store của đơn
            if (foundItemByAll || foundItemByStore) {
                mem[type] = foundItemByStore || foundItemByAll
            }

            return mem
        }, {})
    }

    render() {
        const {fulfilledPackages, error, refunded, setArtworks, fetchOrderFulfilledItems} = this.props

        if (!fulfilledPackages || fulfilledPackages.length <= 0) {
            return null
        }

        const {
            orderId,
            code,
            completedAt,
            canRestore,
            fulfillmentStatus,
            paymentStatus,
            onUpdateTotalPushToProduction,
            preference,
            invoiceRefund,
            orderType,
            order,
        } = this.props
        const {shipping_plan = {}} = Object.assign({}, order)
        const {slug = ''} = shipping_plan
        const {storeMode} = preference
        const canRefund = ((paymentStatus === 'paid' || paymentStatus === 'partially_refunded' || paymentStatus === 'refunded')
            && (['cancelled', 'processing', 'fulfilled'].includes(fulfillmentStatus))
            && storeMode !== 'marketplace')
        const showEmbroiderySurchargeButton = fulfilledPackages.some(this.isAllowEmbroiderySurchargePackage)
            && fulfilledPackages.every(ffm => {
                return (!ffm.did_actions || !ffm.did_actions.includes('embroidery_surcharge'))
            })
        const {
            embroiderySurchargeByPackage
        } = this.state
        const confirmStatusArray = fulfilledPackages && fulfilledPackages.length > 0 && fulfilledPackages.map((i, index) => {
            const { factory_info, supplier } = { ...i }
            const { metadata } = { ...factory_info }
            const { confirmStatus } = { ...metadata }
            return {
                confirmStatus,
                supplier: supplier.name || ''
            }
        })
        const showConfirmError = confirmStatusArray && confirmStatusArray.length > 0 && confirmStatusArray.some(i=>i.confirmStatus==='error' && i.supplier==='PCUK')

        return (
            <div className="OrderFulfilled mb-3">
                <div className="SectionInner">
                    <div className="d-flex align-items-center justify-content-between mb-3">
                        <h3 className="SectionHeading mb-0">
                            {
                                ("processing" === fulfillmentStatus || "partial" === fulfillmentStatus) ?
                                    "Processing..."
                                    :
                                    "Fulfilled"
                            }
                        </h3>
                        <div className="d-flex align-items-center">
                            {showConfirmError &&
                                <i className='text-danger'>The package confirmation failed!</i>
                            }
                            {
                                canRefund && (
                                    <RefundButton
                                        refunded={refunded}
                                        orderId={orderId}
                                        code={code}
                                        fulfillmentStatus={fulfillmentStatus}
                                        invoiceRefund={invoiceRefund}
                                        currency={fulfilledPackages[0].currency}
                                    />
                                )
                            }
                        </div>
                    </div>
                    {
                        this.showEmbroiderySurchargePopup()
                    }
                    {
                        fulfilledPackages.map(fulfilledPackage => {
                            return <FulfilledPackage
                                showEmbroiderySurcharge={this.showEmbroiderySurcharge}
                                order={this.props.order}
                                key={fulfilledPackage._id}
                                invoiceRefund={invoiceRefund}
                                fulfillmentStatus={fulfillmentStatus}
                                preference={preference}
                                fulfilledPackage={fulfilledPackage}
                                orderId={orderId} completedAt={completedAt}
                                onUpdateTotalPushToProduction={onUpdateTotalPushToProduction}
                                canRestore={canRestore}
                                paymentStatus={paymentStatus}
                                orderType={orderType}
                                fetchHistory={this.props.fetchHistory}
                                fetchOrderFulfilledItems={fetchOrderFulfilledItems}
                                fetchOrderPackageCount={this.props.fetchOrderPackageCount}
                                getEmbroiderySurcharge={this.getEmbroiderySurcharge}
                                getButtonEmbroiderySurchargeText={this.getButtonEmbroiderySurchargeText}
                                calEmbroiderySurcharge={this.calEmbroiderySurcharge}
                                selectComplexEmbroidery={this.selectComplexEmbroidery}
                                isAllowEmbroiderySurcharge={this.isAllowEmbroiderySurcharge}
                                embroiderySurchargeByPackage={embroiderySurchargeByPackage}
                                selectedComplexEmbroidery={this.getSelectedComplexEmbroidery(fulfilledPackage)}
                                setArtworks={setArtworks}
                            />
                        })
                    }
                    <div className="d-flex justify-content-end mt-2">
                        {
                            showEmbroiderySurchargeButton &&
                            <button className="btn btn-primary"
                                    onClick={this.handleEmbroiderySurcharge}>{this.getButtonEmbroiderySurchargeText()}</button>
                        }
                        {
                            (fulfilledPackages.length > 1 || ((orderType === "FBA" || slug === "express") && fulfilledPackages.length > 0)) &&
                            <PushAllPackages
                                orderId={orderId}
                                fulfillment={fulfilledPackages}
                                code={code}
                                paymentStatus={paymentStatus}
                            />
                        }
                    </div>
                    {
                        error &&
                        <div className="text-danger mt-3">{error}</div>
                    }
                </div>
            </div>
        )
    }
}

OrderFulfilled.propTypes = {
    preference: PropTypes.object,
    orderId: PropTypes.string.isRequired,
    completedAt: PropTypes.string,
    canRestore: PropTypes.bool,
    fulfillmentStatus: PropTypes.string.isRequired,
    onUpdateTotalPushToProduction: PropTypes.func.isRequired,
    refunded: PropTypes.number.isRequired,
    paymentStatus: PropTypes.string
}

export default OrderFulfilled
