import React, {useState, useEffect} from 'react'
import NotificationFilters from './NotificationFilters'
import NotificationTable from './NotificationTable'
import NotificationProvider from "./NotificationProvider"
import {getNotifications} from "../../../services/api/NotificationServices"
import UploadFile from "./upload/UploadFile"
import UseDebounce from '../helpers/UseDebounce'
import { getLocalData } from '../../../services/StorageServices'

const NotificationPage = () => {
    const permissionsData = !!getLocalData('permissions') ? Object.keys(getLocalData('permissions')) : []
    const [page, _setPage] = useState(1)
    const [limit, _setLimit] = useState(50)
    const [loading, _setLoading] = useState(true)
    const [total, _setTotal] = useState(0)
    const [error, _setError] = useState('')
    const [notificationLists, _setNotificationLists] = useState([])
    const [filters, _setFilters] = useState({})

    const debouncedSearchStoreID = filters.store_id === '' ? UseDebounce(filters.store_id, 0) : UseDebounce(filters.store_id, 500)
    const debouncedSearchOrderNumber = filters.order_number === '' ? UseDebounce(filters.order_number, 0) : UseDebounce(filters.order_number, 500)

    const _hadleSetState = (filters) => {
        _setFilters(filters)
        _setPage(1)
    }

    const _fetchListnotification = async () => {
        try {
            _setLoading(true)
            _setError('')

            const response = await getNotifications(limit, page, filters)

            const {success, message, data} = response

            if (!success) {
                _setLoading(false)
                return _setError(message)
            }

            const {total, data: notificationLists} = data

            _setNotificationLists(notificationLists)

            _setLoading(false)
            _setError('')
            _setTotal(total)
        } catch (e) {
            _setLoading(false)
            _setError(e.message)
        }
    }

    const _handleSetPage = (page) => {
        _setPage(page)
    }

    const _handleSetSize = (size) => {
        _setLimit(size)
    }

    useEffect(() => {
        _fetchListnotification()
    }, [page, debouncedSearchStoreID, debouncedSearchOrderNumber, filters.upload_status, filters.send_status, limit])

    return (
        <NotificationProvider filters={filters} setState={_hadleSetState} loadNotications={_fetchListnotification}>
            <div className="NotificationPage pt-3">
                <div className='d-flex align-items-center justify-content-between mt-3 mb-3'>
                    <h1 className="PageTitle">Notification</h1>
                    {permissionsData.includes('ffm_notification_create') &&
                        <UploadFile reloadListNotification={_fetchListnotification}/>
                    }
                </div>

                <div className='SectionInner'>
                    <div className='filter mb-3 align-items-center'>
                        <NotificationFilters/>
                    </div>
                    <div className="table">
                        {error && <div className="text-danger">{error}</div> }
                        <NotificationTable loading={loading} limit={limit} page={page} total={total} onChangeSize={_handleSetSize} onChangePage={_handleSetPage} notifications={notificationLists}/>
                    </div>
                </div>
            </div>
        </NotificationProvider>
    )
}

export default NotificationPage
