import React, {useEffect, useState} from 'react'
import PropTypes from 'prop-types'
import {Button, Modal, Popconfirm, Switch} from "antd"
import OrderItemProduct from "./OrderItemProduct"
import OrderItemMapDetails from "./OrderItemMapDetails"
import OrderItemDesign from "./OrderItemDesign"
import moment from "moment"

MappingRuleDetail.propTypes = {
    item: PropTypes.object
}

function MappingRuleDetail(props) {
    const {item, onChangeStatus, onDeleteRule} = props
    const [visible, setVisible] = useState(false)
    const [status, setStatus] = useState(item.is_active)

    const showModal = () => {
        setVisible(true)
    }

    const handleCancel = () => {
        setVisible(false)
    }

    const _handleChangeStatus = (checked) => {
        setStatus(checked)

        if (typeof onChangeStatus === 'function') {
            onChangeStatus(checked)
        }
    }

    const _handleDelete = () => {
        if (typeof onDeleteRule === 'function') {
            onDeleteRule(true)
            setVisible(false)
        }
    }

    useEffect(() => {
        setStatus(item.is_active)
    }, [item.is_active])

    return (
        <div className="MappingRuleDetail">
            <span onClick={showModal}>
                {item.product.title}
            </span>
            <Modal
                className="MappingRuleModal"
                visible={visible}
                title={`Mapping rule ${item.id}`}
                onCancel={handleCancel}
                footer={[
                    <Switch key='change' checked={status} onChange={_handleChangeStatus}/>,
                    <Popconfirm
                        key='delete'
                        title={`Are you sure to Delete this rule?`}
                        onConfirm={() => _handleDelete(true)}
                        okText={'Delete'}
                        cancelText="Cancel"
                    >
                        <span className="text-danger cursor-pointer">{'Delete'}</span>
                    </Popconfirm>
                ]}
            >
                <div className="ContentDetail">
                    <div className="Information">
                        <div className="row">
                            <div className="col-4">
                                Store: {item.store}
                            </div>
                            <div className="col-8">
                                User: {!!item.meta && !!item.meta.histories && !!item.meta.histories[0].value && !!item.meta.histories[0].value.user && item.meta.histories[0].value.user.email}
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-4">
                                Zone: {item.zone}
                            </div>
                            <div className="col-8">
                                Created at: {moment(item.created_at).format('HH:mm DD/MM/YYYY')}
                            </div>
                        </div>
                    </div>
                    <div className="TableData">
                        <table className="table table-bordered">
                            <thead>
                            <tr>
                                <th>Product</th>
                                <th className="table-col-40">Map</th>
                                <th className="table-col-20">Design</th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr key={item._id}>
                                <OrderItemProduct
                                    product={item.relatedOrderItem.product}
                                    sku={item.relatedOrderItem.sku}
                                    currency={item.relatedOrderItem.currency}
                                    price={item.relatedOrderItem.price}
                                    quantity={item.relatedOrderItem.quantity}
                                    variant={item.relatedOrderItem.variant}
                                />
                                <OrderItemMapDetails product={item.product} variant={item.variant}/>
                                <OrderItemDesign orderId={item.meta.order} orderItemId={item.meta.order_item_id}/>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </Modal>
        </div>
    )
}

export default MappingRuleDetail
