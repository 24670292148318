import React, {Component} from 'react'
import { Modal, ModalBody, ModalFooter, ModalHeader} from "reactstrap"
import { Tooltip } from 'antd'
import PropTypes from 'prop-types'


class BulkEditPrice extends Component {
    state = {
        isOpen: false,
        price: ''
    }

    _handleClickEditPrice = e => {
        e.preventDefault()

        this.setState({
            isOpen: true,
        })
    }

    _handleToggle = () => {
        this.setState({
            isOpen: !this.state.isOpen
        })
    }

    _handleChangePrice = e => {
        const {value} = e.target

        this.setState({
            price: value
        })
    }

    _handleSubmit = e => {
        e.preventDefault()
        console.log('submit', e)

        const {price} = this.state
        this.setState({
            price: '',
            isOpen: false
        }, () => {
            this.props.onChangePrice(price, this.props.idStore)
        })
    }

    render() {
        const {isOpen, price} = this.state

        return (
            <div className="BulkEditPrice d-inline-flex">
                <Tooltip placement='top' title='Edit price'>
                    <i onClick={this._handleClickEditPrice} className="ti-pencil"/>
                </Tooltip>

                <Modal isOpen={isOpen} toggle={this._handleToggle}>
                    <form onSubmit={this._handleSubmit}>
                        <ModalHeader toggle={this._handleToggle}>Edit price</ModalHeader>
                        <ModalBody>
                            <input onChange={this._handleChangePrice} value={price}
                                   className="form-control" placeholder="Enter price" type="number"/>
                        </ModalBody>
                        <ModalFooter>
                            <button type="submit" className="btn btn-primary">Change price</button>
                        </ModalFooter>
                    </form>
                </Modal>
            </div>
        )
    }
}

BulkEditPrice.propTypes = {
    onChangePrice: PropTypes.func.isRequired,
}

export default BulkEditPrice
