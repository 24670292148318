import React, {createContext, useEffect, useState} from "react"
import {getDetailJobSwitchCarrier} from '../../../services/api/OrdersServices'
import UseDebounce from "../../fulfillment-discounts/helpers/UseDebounce"

export const Context = createContext({})

export const Provider = (props) => {
    const {scanId} = props
    const [page, setPage] = useState(1)
    const [limit, setLimit] = useState(50)
    const [total, setTotal] = useState(0)
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState('')
    const [listScanDetail, setScanDetail] = useState('')
    const [status, setStatus] = useState('')
    const [trackingNumber, setTrackingNumber] = useState('')

    const _handleChangeStatus = (value) => {
        setStatus(value)
    }

    const _handleChangeTrackingNumber = (value) => {
        setTrackingNumber(value)
    }

    const _fetchScanDetail = async () => {
        setLoading(true)
        setError('')

        try {
            const payload = {
                status,
                limit,
                page,
                tracking_number: trackingNumber,
                job_id: scanId
            }

            const {success, message, data} = await getDetailJobSwitchCarrier(payload)
            if (!success) {
                setLoading(false)
                return setError(message)
            }
            const {results, total} = data
            setTotal(total)
            setLoading(false)
            setScanDetail(results)
        } catch (e) {
            setLoading(false)
            setError(e.message)
        }
    }


    const debouncedTrackingNumber = UseDebounce(trackingNumber, 500)

    useEffect(() => {
        _fetchScanDetail()
        // eslint-disable-next-line
    }, [page, limit, status, debouncedTrackingNumber])

    return (
        <Context.Provider
            value={{
                listScanDetail,
                total,
                page,
                limit,
                loading,
                status,
                trackingNumber,
                error,
                setPage,
                setLimit,
                onChangeStatus: _handleChangeStatus,
                onChangeNumber: _handleChangeTrackingNumber,
                loadScanDetail: _fetchScanDetail
            }}
        >
            {props.children}
        </Context.Provider>
    )
}

export {
    Context as ScanDetailContext,
    Provider as ScanDetailContextProvider,
} from "./ScanDetailContext"
