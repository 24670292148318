import React, {Component} from 'react'
import humanizeTime from "../../../../../helpers/time/humanizeTime"
import PropTypes from 'prop-types'

class OrderJobDate extends Component {
    render() {
        const {created} = this.props
        const timeFormatted = humanizeTime(created)

        return (
            <td className="OrderJobDate">
                {timeFormatted}
            </td>
        )
    }
}

OrderJobDate.propTypes = {
    created: PropTypes.string.isRequired,
}

export default OrderJobDate
