import createApiServices from './createApiServices'

const prefixURL = process.env.NODE_ENV === 'production' ? '/api/express' : 'https://fulfillment-staging.merchize.com/api/express'
const api = createApiServices({baseUrl: prefixURL})

export const getOrderDetailByCode = (code) => {
    return api.makeAuthRequest({
        url: `/orders/details/by-code/${code}`,
        method: 'GET',
    })
}

export const generateLabelByOrderId = (id) => {
    return api.makeAuthRequest({
        url: `/shipping-label/generate/${id}`,
        method: 'POST',
    })
}

export const saveOrderDimension = (orderId, dimension = {}) => {
    return api.makeAuthRequest({
        url: `/orders/${orderId}/dimension`,
        method: 'POST',
        data: {
            dimension
        }
    })
}
