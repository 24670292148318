import React, {Component} from 'react'
import StatusStyle from "../../../../shared/StatusStyle"
import PropTypes from 'prop-types'

class OrderJobStatus extends Component {
    render() {
        const {isSent, status} = this.props

        return (
            <td className="OrderJobStatus">
                {
                    isSent ?
                        <StatusStyle status={status}/>
                        :
                        <span className="badge badge-secondary">{status}</span>
                }
            </td>
        )
    }
}

OrderJobStatus.propTypes = {
    isSent: PropTypes.bool.isRequired,
    status: PropTypes.string.isRequired,
}

export default OrderJobStatus
