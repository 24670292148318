import React from 'react'
import { Link } from 'react-router-dom'
import { Result, Button } from 'antd'

function NotAuthorizedContainer() {
    return (
        <div className='NotAuthorizedContainer'>
            <Result
                status="403"
                title="403"
                subTitle="Sorry, you are not authorized to access this page."
                // extra={
                //     <Link className='btn btn-primary' to='/login' >Sign in</Link>
                // }
            />
        </div>
    )
}

export default NotAuthorizedContainer