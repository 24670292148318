import React from 'react'
import SpecialCasePage from './SpecialCasePage'
import DocTitleByStore from '../../dashboard/shared/DocTitleByStore'

const SpecialCaseContainer = () => {
    return (
        <div className='SpecialCaseContainer'>
            <DocTitleByStore title="Mapping Special Case" />
            <SpecialCasePage />
        </div>
    )
}

export default SpecialCaseContainer