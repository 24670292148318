import React, {Component} from 'react'
import RefundPopup from './RefundPopup'

class RefundButton extends Component {
    state = {
        isOpen: false
    }

    _togglePopup = () => {
        this.setState({
            isOpen: !this.state.isOpen
        })
    }

    render() {
        const {isOpen} = this.state

        return <div className="RefundOrder ml-3">
            <button className="btn btn-primary" onClick={this._togglePopup}>Refund/Charge</button>
            {isOpen && (
                <RefundPopup isOpen={isOpen} onTogglePopup={this._togglePopup} {...this.props}/>
            )}
        </div>
    }
}

export default RefundButton