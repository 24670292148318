import React, {Component} from 'react'
import ShippingMethodsPlan from "./data/ShippingMethodsPlan"
import ShippingMethodsProduct from "./data/ShippingMethodsProduct"
import ShippingMethodsBaseCost from "./data/ShippingMethodsBaseCost"
import ShippingMethodsAdditionalCost from "./data/ShippingMethodsAdditionalCost"
import ShipingMethodsActions from "./data/ShipingMethodsActions"

class ShippingZoneNewMethodsRow extends Component {
    render() {
        const {index, _handleUpdatePlansState, _handleRemovePlan} = this.props

        return (
            <tr className="ShippingZoneNewMethodsRow">
                <ShippingMethodsPlan index={index} _handleUpdatePlansState={_handleUpdatePlansState}/>
                <ShippingMethodsProduct index={index} _handleUpdatePlansState={_handleUpdatePlansState}/>
                <ShippingMethodsBaseCost index={index} _handleUpdatePlansState={_handleUpdatePlansState}/>
                <ShippingMethodsAdditionalCost index={index} _handleUpdatePlansState={_handleUpdatePlansState}/>
                <ShipingMethodsActions _handleRemovePlan={_handleRemovePlan} index={index}/>
            </tr>
        )
    }
}

export default ShippingZoneNewMethodsRow
