import React, {useContext, useEffect, useState} from 'react'
import InputSearch from "./filters/InputSearch"
import SelectSearch from "./filters/SelectSearch"
import {
    PACKAGE_STATUS,
    DELAY_STATUS,
    PRODUCTION_STATUS_SUPPLIER,
    PUSH_TYPE,
    TRACKING_STATUS,
    DELAY_DAYS, SHIPPING_CARRIER
} from "../helpers/orderFilters"
import OrderPartnersContext from './../context/OrderPartnersContext'
import {getListSuppliersV2} from '../../../services/api/SuppliersServices'
import FilterOptions from "../../shared/FilterOptions"
import FilterDate from "../../shared/FilterDate"
import CustomMultiSelect from './filters/CustomMultiSelect'

const OrderPartnersFilters = () => {
    const {filters, setState} = useContext(OrderPartnersContext)
    const _packageStatusFilter = filters.package_status || []
    const packageStatusFilter = typeof _packageStatusFilter === 'string' ? [_packageStatusFilter] : _packageStatusFilter
    const [suppliers, _setSuppliers] = useState(1)

    const _fetchListSuppliers = async () => {
        try {
            const {data, success, message} = await getListSuppliersV2({active: true})

            if (!success) {
                console.log(message)
                return
            }

            const _listSuppliers = data.suppliers.map(option => ({name: option.name, value: option._id}))

            _setSuppliers(_listSuppliers)
        } catch (e) {
            console.log(e)
        }
    }

    const _handleChangeSuppliers = (paramFilter) => {
        const _filters = {
            ...filters,
            ...paramFilter
        }
        setState(_filters)
    }

    const _handleChangeInput = (name, value) => {
        const _filters = {
            ...filters,
            [name]: value
        }
        setState(_filters)
    }

    const _handleChangeSelect = (name, value) => {
        const _filters = {
            ...filters,
            [name]: value
        }
        if (_filters.delay !== 'in_transit_delay' && _filters.delay !== 'ship_delay_from_completed_at' && _filters.delay !== 'production_delay_from_in_production_at') delete _filters.delayed_days
        setState(_filters)
    }

    useEffect(() => {
        _fetchListSuppliers()
    }, [])

    return suppliers.length > 0 && (
        <div className="NotificationFilters OrdersSuplier">
            <div className="d-flex GroupFilter">
                <div className="itemFilter mb-3">
                    <div className="font-weight-500 LabelFilter mb-1">Suppliers:</div>
                    <FilterOptions selectedKey={filters.suppliers || []} onChangeOption={_handleChangeSuppliers}
                                   field='suppliers'
                                   options={suppliers} defaultOption={[]}
                                   layout='select' mode='multiple'/>
                </div>
                <div className="itemFilter mb-3">
                    <InputSearch name="custom_references" onChange={_handleChangeInput} label="Custom Reference:"
                                 value={filters.custom_references || []}/>
                </div>
                <div className="itemFilter mb-3">
                    <InputSearch name="namespaces" onChange={_handleChangeInput} label="Namespace:"
                                 value={filters.namespaces || []}/>
                </div>
                <div className="itemFilter mb-3">
                    <InputSearch name="names" onChange={_handleChangeInput} label="Package name:"
                                 value={filters.names || []}/>
                </div>
                <div className="itemFilter filterPushedAt mb-3">
                    <FilterDate
                        heading='Pushed at:'
                        field='pushed_at'
                        value={filters.pushed_at || {}}
                        onDatesChange={_handleChangeSuppliers}
                    />
                </div>
            </div>
            <div className="d-flex GroupFilter">
                <div className="itemFilter status mb-3">
                    <SelectSearch name="shipping_carrier" value={filters.shipping_carrier || ''}
                                  onChange={_handleChangeSelect} label="Shipping carrier:"
                                  listSelect={SHIPPING_CARRIER}/>
                </div>
                <div className="itemFilter status mb-3">
                    <CustomMultiSelect
                        name='package_status'
                        label={'Package status:'}
                        placeholder={'All'}
                        selected={packageStatusFilter}
                        listFilters={PACKAGE_STATUS}
                        onChangeFilters={_handleChangeSelect}
                    />
                </div>
                <div className="itemFilter status mb-3">
                    <SelectSearch name="production_status" value={filters.production_status || ''}
                                  onChange={_handleChangeSelect} label="Production status:"
                                  listSelect={PRODUCTION_STATUS_SUPPLIER}/>
                </div>
                <div className="itemFilter status mb-3">
                    <SelectSearch name="tracking_status" value={filters.tracking_status || ''}
                                  onChange={_handleChangeSelect} label="Tracking status:" listSelect={TRACKING_STATUS}/>
                </div>
                <div className="itemFilter end pushType mr-3 mb-3">
                    <SelectSearch name="pushed_type" value={filters.pushed_type || ''} onChange={_handleChangeSelect}
                                  label="Push type:" listSelect={PUSH_TYPE}/>
                </div>
            </div>
            <div className="d-flex GroupFilter">
                <div className="itemFilter end status mr-3 mb-3">
                    <SelectSearch name="delay" value={filters.delay || ''} onChange={_handleChangeSelect} label="Delay:"
                                  listSelect={DELAY_STATUS}/>
                </div>
                {(filters.delay === 'in_transit_delay' || filters.delay === 'ship_delay_from_completed_at' || filters.delay === 'production_delay_from_in_production_at') && (
                    <div className="itemFilter end status mr-3 mb-3">
                        <SelectSearch name="delayed_days" value={filters.delayed_days || ''}
                                      onChange={_handleChangeSelect} label="Delayed days:" listSelect={DELAY_DAYS}/>
                    </div>
                )}
            </div>
        </div>
    )
}

export default OrderPartnersFilters
