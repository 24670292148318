import React, { useContext, useState } from 'react'
import { Modal, Progress } from "antd"
import OrderPartnersContext from "../../context/OrderPartnersContext"
import { ExclamationCircleOutlined, CheckCircleOutlined } from '@ant-design/icons'
import { addFastProductionTag } from '../../../../services/api/OrdersServices'

function AddFastProductionTag(props) {
    const { visible, onCancel } = props
    const { listSelected, packageLists } = useContext(OrderPartnersContext)
    const [buttonLoading, setButtonLoading] = useState(false)
    const [listProcessing, setListProcessing] = useState(packageLists.filter(item => listSelected.includes(item._id)))
    const [listSuccess, setListSuccess] = useState([])
    const [listError, setListError] = useState([])

    const _onAddTag = async (id) => {
        try {
            const response = await addFastProductionTag(id)
            const { success, message } = response
            if (!success) {
                return { success: success, message: message }
            }
            return { success: success }
        } catch (e) {
            return { success: false, message: e.message }
        }
    }

    const _handleOk = async () => {
        setButtonLoading(true)
        let processed = []

        for (const item of listProcessing) {
            const response = await _onAddTag(item._id)
            const { success, message } = response

            if (success !== true) {
                processed = [...processed, { ...item, success: success, error: message }]
            }
            else {
                processed = [...processed, { ...item, success: success }]
            }
        }

        let newListSuccess = []
        let newListError = []

        processed.map(process => {
            if (process.success === true) {
                return newListSuccess.push(process)
            }

            if (process.error.length > 0) {
                return newListError.push(process)
            }
        })
        setListSuccess(newListSuccess)
        setListError(newListError)
        setListProcessing(processed)
        setButtonLoading(false)
    }

    return (
        <Modal
            className="AddFastProductionModalTag"
            visible={visible}
            title="Add Fast Production tag for packages"
            onOk={_handleOk}
            okText={"Add Tag"}
            onCancel={onCancel}
            confirmLoading={buttonLoading}
        >
            <h3>List processing <span>{listSelected.length}</span> items</h3>
            {
                (listSuccess.length > 0 || listError.length > 0) &&
                <div className="Results">
                    <div className="ResultSuccess">
                        Success: <span>{listSuccess.length}</span> items
                    </div>
                    <div className="ResultError">
                        Error: <span>{listError.length}</span> items
                    </div>
                </div>
            }
            <div className="ListProcessing">
                <ul>
                    {!!listProcessing && listProcessing.length > 0 &&
                        listProcessing.map(item => (
                            <li key={item._id}>
                                <span>{item.name}</span>
                                {
                                    item.success === false && !!item.error &&
                                    <span className="Error"><ExclamationCircleOutlined /> {item.error}</span>
                                }
                                {
                                    item.success === true &&
                                    <span className="Success"><CheckCircleOutlined /> Added Fast Production tag</span>
                                }
                                <Progress
                                    percent={100}
                                    size="small"
                                    status={`${(item.success === true) ? 'success' : ((item.success === false) ? 'exception' : 'active')}`}
                                    showInfo={false}
                                />
                            </li>
                        ))
                    }
                </ul>
            </div>
        </Modal>
    )
}

export default AddFastProductionTag