import React, {useState} from 'react'
import PropTypes from 'prop-types'
import {FormGroup, Input, Label} from "reactstrap"
import classNames from "classnames"
import {message, Select} from "antd"
import {listCurrencies, listStoreModes} from "../../../../statics/storeModes"
import {deleteProduct, updateProduct} from "../../../../services/api/ProductsServices"
import getHistory from "../../../../store/getHistory"
import ConfirmDeleteModal from "./ConfirmDeleteModal"
import NumberFormat from "react-number-format"
import { getLocalData } from '../../../../services/StorageServices'


const {Option} = Select

ProductDetailInfo.propTypes = {
    productId: PropTypes.string,
    productDetail: PropTypes.object,
    onChangeState: PropTypes.func.isRequired
}

function ProductDetailInfo(props) {
    const {productId, productDetail, onChangeState, disableAll} = props
    const {
        title, description, type, sku_prefix, suppliers,
        store_modes, product_label, currency, two_sided_cost,
        production_time_min, production_time_max, seller_production_time_max, fast_production_time,
    } = productDetail
    const productLabel = productDetail.hasOwnProperty('product_label')
    const listSupplier = !!suppliers && suppliers.length > 0 && suppliers.map(item => item.name)

    const [validated, setValidated] = useState('')
    const [saving, setSaving] = useState(false)
    const [modal, setModal] = useState(false)
    const [error, setError] = useState('')

    const _onValidate = () => {
        let flag = true

        setValidated(true)

        if (title.trim() === '' || sku_prefix.trim() === '') {
            flag = false
        }

        return flag
    }

    const _handleChange = (e) => {
        e.preventDefault()
    }

    const _handleChangeProductState = (field, value) => {
        if (typeof onChangeState === 'function') {
            onChangeState(field, value)
        }
    }

    const _onToggleModal = () => {
        setModal(!modal)
    }

    const _onUpdateProduct = async () => {
        const validate = _onValidate()

        if (!validate) return

        setSaving(true)
        setError('')

        try {
            const {dimension_conditions: oldDimensions, fast_production_time } = productDetail
            const newDimensions = oldDimensions.filter(item => item.length > 0)
            let productUpdate = Object.assign({}, productDetail)

            productUpdate = {
                ...productUpdate,
                dimension_conditions: newDimensions,
                fast_production_time: +fast_production_time > 0 ? fast_production_time : null
            }

            const {category, ...product} = productUpdate

            const response = await updateProduct(productId, product)

            const {success, message: error} = response

            if (!success) {
                setSaving(false)
                message.error(error)
                return setError(error)
            }

            setSaving(false)
            message.success('Update product information success!')
        } catch (e) {
            setSaving(false)
            setError(e.message)
        }
    }

    const _onDeleteProduct = async () => {
        try {
            const response = await deleteProduct(productId)

            const {success, message: error} = response

            if (!success) {
                return setError(error)
            }

            const history = getHistory()
            history.push('/a/products')
        } catch (e) {
            setError(e.message)
        }
    }

    const _handleChangeNumber = (value) => {
        if(value.includes('$')) {
            const newValues = value.replace('$', '')
             return _handleChangeProductState('two_sided_cost', newValues)
        }
        _handleChangeProductState('two_sided_cost', value)
    }

    const _handleChangeProductionTimeMin = (value) => {
        const newTime = parseInt(value)
        _handleChangeProductState('production_time_min', newTime)
    }

    const _handleChangeProductionTimeMax = (value) => {
        const newTime = parseInt(value)
        _handleChangeProductState('production_time_max', newTime)
    }

    const _handleChangeFastProductionTime = (value) => {
        const newTime = parseFloat(value)
        _handleChangeProductState('fast_production_time', newTime)
    }

    const _handleChangeProductionTimeMaxForSeller = (value) => {
        const newTime = parseInt(value)
        _handleChangeProductState('seller_production_time_max', newTime)
    }

    const limitInputValue = ({ floatValue, formattedValue }) => {
        if (floatValue == null) return formattedValue === ''
    
        return (floatValue >= 0 && floatValue < 1000)
    }
    const permissionsData = !!getLocalData('permissions') ? Object.keys(getLocalData('permissions')) : []

    return (
        <div className='ProductDetailInfo'>
            <div className='d-flex align-items-center justify-content-between'>
                <h1 className="PageTitle">{title}</h1>
            </div>

            {
                error && <div className="text-danger mb-1">{error}</div>
            }

            {
                title !== undefined &&
                <div className="SectionInner ProductInfo">
                    <div className="form-group">
                        <label htmlFor="productLine">Product Label <span
                            className="text-danger">*</span></label>
                        <div className='d-flex align-items-center'>
                            <FormGroup check className='mr-3'>
                                <Label check>
                                    <Input type="radio"
                                           checked={productLabel && productDetail.product_label === 'product'}
                                           disabled={productLabel && productDetail.product_label === 'branding' && disableAll}
                                           name="product" onChange={_handleChange}
                                    /> Product
                                </Label>
                            </FormGroup>
                            <FormGroup check>
                                <Label check>
                                    <Input type="radio"
                                           checked={productLabel && productDetail.product_label === 'branding'}
                                           disabled={productLabel && productDetail.product_label === 'product' && disableAll}
                                           name="branding"
                                           onChange={_handleChange}
                                    /> Branding
                                </Label>
                            </FormGroup>
                        </div>
                    </div>

                    <div className="row">
                        <div className="form-group col-md-6">
                            <label>Title <span className="text-danger">*</span></label>
                            <input
                                type="text" value={title || ''}
                                className={classNames('form-control', (title.trim() === '' && validated) && 'input-error')}
                                onChange={({target: {value}}) => _handleChangeProductState('title', value)}
                                disabled={disableAll}
                            />

                            {
                                (title.trim() === '' && validated) &&
                                <div className="text-danger mt-1 small">Title is require</div>
                            }
                        </div>
                        <div className="form-group col-md-6">
                            <label>Currency</label>
                            <Select size={'large'} disabled defaultValue={currency}>
                                {
                                    listCurrencies.map((item, index) => {
                                            return <Option key={index} value={item.value}>{item.name}</Option>
                                        }
                                    )
                                }
                            </Select>
                        </div>
                    </div>

                    <div className="row">
                        {
                            product_label === 'product' ?
                                <div className="form-group col-md-6">
                                    <label>Type product</label>
                                    <input
                                        type="text" className="form-control" value={type || ''}
                                        onChange={({target: {value}}) => _handleChangeProductState('type', value)}
                                        disabled={disableAll}
                                    />
                                </div> :
                                <div className="form-group col-md-6">
                                    <label>Type branding</label>
                                    <Input
                                        type="select" className="form-control" value={type}
                                        onChange={({target: {value}}) => _handleChangeProductState('type', value)}
                                        disabled={disableAll}
                                    >
                                        <option value='THANK_CARD'>THANK_CARD</option>
                                        <option value='BRAND_KIT'>BRAND_KIT</option>
                                        <option value='BOX_0700NO'>BOX_0700NO</option>
                                    </Input>
                                </div>
                        }

                        <div className="form-group col-md-6">
                            <label>Store mode</label>
                            <Select
                                mode="multiple" size={'large'} defaultValue={store_modes}
                                onChange={(value) => _handleChangeProductState('store_modes', value)}
                                disabled={disableAll}
                            >
                                {
                                    listStoreModes.map((item, index) => {
                                            return <Option key={index} value={item.value}>{item.name}</Option>
                                        }
                                    )
                                }
                            </Select>
                        </div>
                    </div>

                    <div className="row">
                        <div className="form-group col-md-6">
                            <label>SKU Prefix <span className="text-danger">*</span></label>
                            <input
                                type="text" value={sku_prefix || ''}
                                className={classNames('form-control', (!!sku_prefix && sku_prefix.trim() === '' && validated) && 'input-error')}
                                onChange={({target: {value}}) => _handleChangeProductState('sku_prefix', value)}
                                disabled={disableAll}
                            />
                            {
                                (!!sku_prefix && sku_prefix.trim() === '' && validated) &&
                                <div className="text-danger mt-1 small">SKU Prefix is require</div>
                            }
                        </div>

                        <div className="form-group col-md-6">
                            <label>Suppliers</label>
                            <Select mode="multiple" size={'large'} value={!!listSupplier ? listSupplier : []}
                                    disabled>
                                {
                                    !!suppliers && suppliers.length > 0 && suppliers.map((item, index) => {
                                            return <Option key={index} value={item._id}>{item.name}</Option>
                                        }
                                    )
                                }
                            </Select>
                        </div>
                    </div>

                    <div className="row">
                        <div className="form-group col-md-6">
                            <label>Production time min</label>
                            <NumberFormat
                                className='form-control InputPrice'
                                onChange={({target: {value}}) => _handleChangeProductionTimeMin(value)}
                                value={production_time_min || 0}
                                allowNegative={false}
                                suffix={production_time_min > 1 ? " days" : " day"}
                                // isAllowed={limitInputValue}
                                disabled={disableAll}
                            />
                        </div>
                        <div className="form-group col-md-6">
                        <label>Production time max</label>
                        <NumberFormat
                                className='form-control InputPrice'
                                onChange={({target: {value}}) => _handleChangeProductionTimeMax(value)}
                                value={production_time_max || 0}
                                allowNegative={false}
                                suffix={production_time_max > 1 ? " days" : " day"}
                                // isAllowed={limitInputValue}
                                disabled={disableAll}
                            />
                        </div>
                    </div>

                    <div className="row">
                        <div className="form-group col-md-6">
                            <label>Production time max for Seller</label>
                            <NumberFormat
                                className='form-control InputPrice'
                                onChange={({target: {value}}) => _handleChangeProductionTimeMaxForSeller(value)}
                                value={seller_production_time_max || 0}
                                allowNegative={false}
                                suffix={seller_production_time_max > 1 ? " days" : " day"}
                                disabled={disableAll}
                            />
                        </div>
                        <div className="form-group col-md-6">
                        <label>Fast production time</label>
                            <NumberFormat
                                className='form-control InputPrice'
                                onChange={({target: {value}}) => _handleChangeFastProductionTime(value)}
                                value={fast_production_time || 0}
                                allowNegative={false}
                                suffix={fast_production_time >= 2 ? " days" : " day"}
                                disabled={disableAll}
                            />
                        </div>
                    </div>

                    <div className="row">
                        <div className="form-group col-md-6">
                            <label>Second location print fee</label>
                            <NumberFormat
                                className='form-control InputPrice'
                                onChange={({target: {value}}) => _handleChangeNumber(value)}
                                value={two_sided_cost || 0}
                                thousandSeparator={currency === 'VND' ? '.' : ','}
                                decimalSeparator={currency === 'VND' ? ',' : '.'}
                                decimalScale={currency === 'VND' ? 0 : 2}
                                allowNegative={false}
                                prefix={currency === 'VND' ? '' : "$"}
                                isAllowed={limitInputValue}
                                disabled={disableAll}
                            />
                        </div>
                        <div className="form-group col-md-6">
                        </div>
                    </div>

                    <div className="form-group">
                        <label>Description</label>
                        <textarea
                            className="form-control" rows="5" value={description || ''}
                            onChange={({target: {value}}) => _handleChangeProductState('description', value)}
                            disabled={disableAll}
                        />
                    </div>

                    <div className="ProductAction d-flex justify-content-between mt-3">
                        {permissionsData.includes('ffm_product_line_delete') &&
                            <button type="button" className="btn btn-danger" onClick={_onToggleModal}
                                disabled={!permissionsData.includes('ffm_product_line_delete')}
                            >
                                Delete product
                            </button>
                        }
                        {!disableAll &&
                            <button type="button" className="btn btn-primary" onClick={_onUpdateProduct}
                                disabled={disableAll}
                            >
                                {
                                    saving ? 'Saving...' : 'Save product'
                                }
                            </button>
                        }
                    </div>
                </div>
            }

            {
                modal && <ConfirmDeleteModal
                    modal={modal}
                    title={title}
                    onToggleModal={_onToggleModal}
                    onDeleteProduct={_onDeleteProduct}
                />
            }
        </div>
    )
}

export default ProductDetailInfo
