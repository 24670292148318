export default (filter, variant) => {
    const keys = Object.keys(Object.assign({}, filter))
    if (!keys || !keys.length) return true

    const {options} = Object.assign({}, variant)
    const vOptions = Array.isArray(options) ? options : []

    let matches = 0

    for (let i = 0; i < keys.length; i++) {
        const key = keys[i]

        const option = vOptions.find(_option => _option && _option.attribute === key && _option.name === filter[key])
        if (option) {
            matches++
        }
    }

    return matches === keys.length
}
