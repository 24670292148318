import React, {Component} from 'react'
import {Link} from "react-router-dom"

class ShippingPlansCreate extends Component {
    render() {
        return (
            <div className="ShippingPlansCreate">
                <Link to="/a/shipping/new" className="btn btn-primary">Create plan</Link>
            </div>
        )
    }
}

export default ShippingPlansCreate
