import React, {useState} from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'

ProductAttributeNewValue.propTypes = {
    valueType: PropTypes.string,
    onChangeAtt: PropTypes.func.isRequired,
    values: PropTypes.array,
    attributeIndex: PropTypes.number
}

function ProductAttributeNewValue(props) {
    const {valueType, onChangeAtt, values, attributeIndex} = props
    const [newAttribute, setNewAttribute] = useState({
        name: '',
        value: ''
    })

    const _handleChangeAttributeState = (field, value) => {
        setNewAttribute({
            ...newAttribute,
            [field]: value
        })
    }

    const _handClickAdd = (e) => {
        e.preventDefault()

        const {name, value} = newAttribute

        if (valueType !== 'color') {
            const newName = (name.split(',')
                    .map((item) => {
                            return {
                                name: item.trim(),
                                value: item.trim()
                            }
                        }
                    )
                    .filter((item) => !values.includes(item))
            )
            const newValues = [...values, ...newName]
            const resultsValues = _.uniqBy(newValues, 'name')

            if (typeof onChangeAtt === 'function') {
                onChangeAtt(attributeIndex, 'values', resultsValues)
            }
        } else {
            const newValues = [...values, {name: name, value: valueType === 'color' ? value : name}]
            const resultsValues = _.uniqBy(newValues, 'value')

            if (typeof onChangeAtt === 'function') {
                onChangeAtt(attributeIndex, 'values', resultsValues)
            }
        }

        setNewAttribute({
            name: '',
            value: ''
        })
    }

    const {name, value: _value} = newAttribute

    return (
        <div className="ProductAttributeNewValue mt-3">
            <h6>Add attribute values</h6>
            <form onSubmit={_handClickAdd}>
                <div className="row">
                    <div className={`${valueType !== 'color' ? 'col-6' : 'col-3'}`}>
                        <input
                            type="text"
                            className="form-control"
                            placeholder={`${valueType !== 'color' ? 'Name 01, Name 02 ...' : 'Name'}`}
                            value={name || ""}
                            onChange={({target: {value}}) => {
                                _handleChangeAttributeState("name", value)
                            }}
                            autoFocus
                            required
                        />
                    </div>

                    {
                        valueType === 'color' &&
                        <div className="col-3">
                            <input
                                type="text"
                                className="form-control"
                                placeholder="Value" value={_value || ""}
                                onChange={({target: {value}}) => {
                                    _handleChangeAttributeState("value", value)
                                }}
                                required
                            />
                        </div>
                    }

                    <div className="col-6">
                        <button className="btn btn-success" type="submit">Add</button>
                    </div>
                </div>
            </form>
        </div>
    )
}

export default ProductAttributeNewValue
