import React, { useState } from 'react'
import { createRole } from '../../../services/api/UserService'
import { Modal, message, Input, Select } from 'antd'

function ModalCreateRole(props) {
    const { visible, onOpen, onClose, listPermission, reset } = props
    const [name, setName] = useState('')
    const [permisstionsSelected, setPermisstionsSelected] = useState([])
    const { Option } = Select

    const handleClose = () => {
        onClose()
        setName('')
        setPermisstionsSelected([])
    }

    const handleCreate = async () => {
        try {
            const payload = {
                name: name,
                permissions: permisstionsSelected
            }
            const { success, data, message: mess } = await createRole(payload)
            if (!success) throw new Error(mess)
            message.success('Role created!!!')
            onClose()
            reset()
        } catch (error) {
            if (error.response.status !== 200) {
                message.error(error.response.data.message)
            } else {
                message.error(error.message)
            }
        }
    }
    const onchangeInput = (e) => {
        setName(e.target.value)
    }
    const onChangeSelect = (value) => {
        setPermisstionsSelected(value)
    }

    return (
        <Modal
            visible={visible}
            title='Create Role'
            width={970}
            className='ModalCreateRole'
            onCancel={handleClose}
            okText='Create'
            onOk={handleCreate}
        >
            <div className='FormRole'>
                <div className="roleName mb-3">
                    <label>Role name</label>
                    <Input
                        placeholder='Role name'
                        value={name || ''}
                        onChange={onchangeInput}
                    >
                    </Input>

                </div>
                <div className="rolePermissions">
                    <label>Permissions</label>
                    <Select
                        placeholder='Select permissions'
                        className='W-100'
                        mode={'multiple'}
                        style={{ width: '100%' }}
                        allowClear
                        onChange={onChangeSelect}
                        value={permisstionsSelected || []}
                        optionFilterProp="children"
                    >
                        {listPermission && listPermission.length > 0 && listPermission.map((item, index) => (
                            <Option key={index} value={item._id}>{item.key}</Option>
                        ))}

                    </Select>
                </div>
            </div>
        </Modal>
    )
}

export default ModalCreateRole