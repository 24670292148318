import React, {Component, Fragment} from 'react'
import PropTypes from 'prop-types'

class StatusStyle extends Component {
    _mapStatus = () => {
        const {status} = this.props

        if (!status) {
            return null
        }

        let output = ""
        switch (status) {
            case "update-tracking":
                output = <span className="badge badge-info">{status}</span>
                break
            case "rejected":
            case "cancelled":
                output = <span className="badge badge-danger">{status}</span>
                break
            case "fulfilled":
                output = <span className="badge badge-success">{status}</span>
                break
            case "failed":
                output = <span className="badge badge-warning">{status}</span>
                break
            case "pending":
                output = <span className="badge badge-info pending-animation">{status}</span>
                break
            case "processing":
                output = <span className="badge badge-primary processing-animation">{status}</span>
                break
            case "processed":
                output = <span className="badge badge-secondary">{status}</span>
                break
            default:
                output = <span className="badget badge-secondary">{status}</span>
        }

        return output
    }

    render() {
        const {is_sent, status} = this.props
        const statusfail = this._mapStatus()
        let finalStatus
        if (is_sent) {
            finalStatus = <span className="badge badge-success">{status}</span>
        } else {
            finalStatus = statusfail
        }

        return (
            <Fragment>
                {finalStatus}
            </Fragment>
        )
    }
}

StatusStyle.propTypes = {
    status: PropTypes.string.isRequired,
}

export default StatusStyle
