import React, {Fragment, useState} from 'react'
import {Spin} from 'antd'
import TableHead from './data/TableHead'
import TableRow from './data/TableRow'
import emptyData from '../../../statics/assets/images/empty-img-gray.png'
import NotificationPagination from "./NotificationPagination"

const NotificationTable = (props) => {
    const {loading, notifications} = props
    const [selectedItems, _setSelected] = useState([])
    const [loadingSend, _setLoadingSend] = useState(false)
    const [isSelectedAll, _toggleSelectedAll] = useState(false)

    const _setSendLoading = (value) => {
        _setLoadingSend(value)
    }

    const _toggleSelectAll = (isChecked) => {
        if (isChecked) {
            const notiIds = notifications.filter(itemF => itemF.upload_status === 'done').map(noti => noti._id)
            _setSelected(notiIds)
            _toggleSelectedAll(true)
        } else {
            _setSelected([])
            _toggleSelectedAll(false)
        }
    }

    const _updateSelectedOrders = (id, value) => {
        if (value && !selectedItems.includes(id)) {
            _setSelected([...selectedItems, id])
            _toggleSelectedAll(selectedItems.length + 1 === props.notifications.filter(itemF => itemF.upload_status === 'done').length)
        } else {
            const filtered = selectedItems.filter(notiId => notiId !== id)
            _setSelected(filtered)
            _toggleSelectedAll(false)
        }
    }

    return (
        <div className="NotificationTable">
            <Spin spinning={loading} tip='Loading...'>
                {notifications.length > 0 ? (
                    <div className="wrapTable">
                        <table className='table'>
                            <TableHead loadingSend={loadingSend} handleSendLoading={_setSendLoading} isSelectedAll={isSelectedAll} selectedItems={selectedItems} notifications={notifications} toggleSelectAll={_toggleSelectAll}/>
                            <tbody>
                            {
                                notifications.map(notification => {
                                    return (
                                        <Fragment key={notification._id}>
                                            <TableRow loadingSend={loadingSend} handleSendLoading={_setLoadingSend} selectedItems={selectedItems} notification={notification} updateSelectedOrders={_updateSelectedOrders}
                                                      currentPage={1}/>
                                        </Fragment>
                                    )
                                })
                            }
                            </tbody>
                        </table>
                        <NotificationPagination {...props}/>
                    </div>
                ) : !loading && (
                    <div className="noData d-flex flex-column align-items-center justify-content-center">
                        <img src={emptyData} alt="No data"/>
                        Không có bản ghi!
                    </div>
                )}
            </Spin>
        </div>
    )
}

export default NotificationTable
