import React, { useState } from 'react'
import ChangeStatusPackage from "../popup/ChangeStatusPackage"
import UpdatePackageNote from '../popup/UpdatePackageNote'
import AddFastProductionTag from '../popup/AddFastProductionTag'
import OrderPartnersContext from '../../context/OrderPartnersContext'
import { getLocalData } from '../../../../services/StorageServices'
import BulkActionAddTags from '../popup/BulkActionAddTags'
import BulkActionRemoveTags from '../popup/BulkActionRemoveTags'

const TableHead = (props) => {
    const { selectAll, listSelected, onChangeSelectAll, orders } = props
    const [showAction, setShowAction] = useState(false)
    const [showModal, setShowModal] = useState(false)
    const [showNoteModal, setShowNoteModal] = useState(false)
    const [showAddFastProductionTagModal, setShowAddFastProductionTagModal] = useState(false)
    const [showAddTagsModal, setShowAddTagsModal] = useState(false)
    const [showRemoveTagsModal, setShowRemoveTagsModal] = useState(false)
    const [warning, setWarning] = useState("")

    const _showModal = () => {
        setShowModal(true)
    }

    const _showNoteModal = () => {
        const itemSelected = orders.filter(item => listSelected.includes(item._id))
        const success = itemSelected.every(item => item.note === itemSelected[0].note)
        if (!success) { setWarning("Notice: All chosen packages will have the same note content.") }
        setShowNoteModal(true)
    }

    const _showAddFastProductionTagModal = () => {
        setShowAddFastProductionTagModal(true)
    }

    const _showAddTagsModal = () => {
        setShowAddTagsModal(true)
    }
    
    const _showRemoveTagsModal = () => {
        setShowRemoveTagsModal(true)
    }

    const _hiddenModal = () => {
        setShowModal(false)

        if (typeof onChangeSelectAll === 'function') {
            onChangeSelectAll(false)
            setShowAction(false)
        }
    }

    const _hiddenNoteModal = () => {
        setWarning("")
        setShowNoteModal(false)

        if (typeof onChangeSelectAll === 'function') {
            onChangeSelectAll(false)
            setShowAction(false)
        }
    }

    const _hiddenAddTagModal = () => {
        setShowAddFastProductionTagModal(false)

        if (typeof onChangeSelectAll === 'function') {
            onChangeSelectAll(false)
            setShowAction(false)
        }
    }

    const _hiddenAddTagsModal = () => {
        setShowAddTagsModal(false)

        if (typeof onChangeSelectAll === 'function') {
            onChangeSelectAll(false)
            setShowAction(false)
        }
    }

    const _hiddenRemoveTagsModal = () => {
        setShowRemoveTagsModal(false)

        if (typeof onChangeSelectAll === 'function') {
            onChangeSelectAll(false)
            setShowAction(false)
        }
    }

    const _toggleAction = () => {
        setShowAction(!showAction)
    }

    const _handleChangeSelectAll = (e) => {
        const { checked } = e.target

        if (typeof onChangeSelectAll === 'function') {
            onChangeSelectAll(checked)
        }
    }
    const permissionsData = !!getLocalData('permissions') ? Object.keys(getLocalData('permissions')) : []
    const changeStatusPermission = permissionsData.includes('ffm_package_change_status')
    const updateNotePermission = permissionsData.includes('ffm_package_update_note')
    const addFastProductionTagPermission = permissionsData.includes('ffm_package_mark_fastproduction')
    const addTagsPermission = permissionsData.includes('ffm_package_update')
    return (
        <thead className="NotificationTableHead thead-light">
            <tr>
                {(!!changeStatusPermission || !!updateNotePermission) &&
                    <th>
                        <div className="PackageChangeStatus">
                            <input type="checkbox" checked={selectAll} onChange={_handleChangeSelectAll} />
                            <div className="Actions">
                                <i className="ti-angle-down" onClick={_toggleAction} />
                                <ul className={`ListActions ${showAction ? 'IsOpen' : ''} ${listSelected.length > 0 ? '' : 'Disable'}`}>
                                    {changeStatusPermission &&
                                        <li className={`ActionItem ${changeStatusPermission ? '' : 'Disable'}`} onClick={_showModal}>Change status package</li>
                                    }
                                    {updateNotePermission &&
                                        <li className={`ActionItem ${updateNotePermission ? '' : 'Disable'}`} onClick={_showNoteModal}>Update package note</li>
                                    }
                                    {addFastProductionTagPermission &&
                                        <li
                                            className={`ActionItem${addFastProductionTagPermission ? '' : 'Disable'}`}
                                            onClick={_showAddFastProductionTagModal}
                                        >
                                            Add Fast Production tag
                                        </li>
                                    }
                                    {addTagsPermission &&
                                        <li
                                            className={`ActionItem${addTagsPermission ? '' : 'Disable'}`}
                                            onClick={_showAddTagsModal}
                                        >
                                            Add tags
                                        </li>
                                    }
                                    {addTagsPermission &&
                                        <li
                                            className={`ActionItem${addTagsPermission ? '' : 'Disable'}`}
                                            onClick={_showRemoveTagsModal}
                                        >
                                            Remove tags
                                        </li>
                                    }
                                </ul>
                                {
                                    showAction && <div className="Background" onClick={_toggleAction} />
                                }
                            </div>
                        </div>
                    </th>
                }
                <th className="Info">Info</th>
                <th className="Address">Country</th>
                <th className="Supplier">Supplier</th>
                <th className="Status">Package Status</th>
                <th className="Status">Production Status</th>
                <th className="">Due</th>
                <th className="PushType">Push Type</th>
                <th className="Tracking">Tracking</th>
                <th className="ShippingMethod">Interior Shipment</th>
                <th className="Note">Note</th>
            </tr>

            {showModal &&
                <ChangeStatusPackage visible={showModal} onCancel={_hiddenModal} />
            }
            {showNoteModal &&
                <UpdatePackageNote visible={showNoteModal} onCancel={_hiddenNoteModal} showModal={_showModal} warning={warning} />
            }
            {showAddFastProductionTagModal &&
                <AddFastProductionTag
                    visible={showAddFastProductionTagModal}
                    onCancel={_hiddenAddTagModal}
                    showModal={_showAddFastProductionTagModal}
                />
            }
            {showAddTagsModal &&
                <BulkActionAddTags
                    open={showAddTagsModal}
                    onCancel={_hiddenAddTagsModal}
                    showModal={_showAddTagsModal}
                />
            }
            {showRemoveTagsModal &&
                <BulkActionRemoveTags
                    open={showRemoveTagsModal}
                    onCancel={_hiddenRemoveTagsModal}
                    showModal={_showRemoveTagsModal}
                />
            }
        </thead>
    )
}

export default TableHead
