import React, { useState, useEffect } from "react"
import { getListShippingZonesV2, getListCountries} from "../../services/api/ShippingServices"
import ShippingZonesTable from "./table/ShippingZonesTable"
import ShippingZoneFilter from "./filter/ShippingZoneFilter"
import { getListSuppliersV2 } from "../../services/api/SuppliersServices"
import { Pagination, Spin } from "antd"

const ShippingZonesPage = (props) => {
  const {permissionsData} = props
  const [shippingZones, setShippingZones] = useState([])
  const [error, setError] = useState("")
  const [title, setTitle] = useState("")
  const [supplier, setSupplier] = useState("")
  const [page, setPage] = useState(1)
  const [total, setTotal] = useState(0)
  const [limit, setLimit] = useState(50)
  const [loading, setLoading] = useState(false)
  const [listSupplier, setListSupplier] = useState([])
  const [listCountries, setListCountries] = useState([])

  const getListSupplierData = async () => {
    try {
      const response = await getListSuppliersV2("")
      const { success, data, message } = response
      if (success) {
        setListSupplier(data.suppliers)
      } else {
        console.log(message)
      }
    } catch (error) {
      console.log(error)
    }
  }

  const getListCountriesData = async () => {
    try {
      const response = await getListCountries("")
      const { success, data, message } = response
      if (success) {
        setListCountries(data)
      } else {
        console.log(message)
      }
    } catch (error) {
      console.log(error)
    }
  }

  const _handleChangePagination = (page, pageSize) => {
    setPage(pageSize !== limit ? 1 : page)
    setLimit(pageSize)
  }

  const _fetchListShippingZones = async () => {
    setLoading(true)
    const Payload = {
      page: page,
      limit: limit,
      supplier: supplier,
      title: title,
    }
    try {
      const { data, success, message } = await getListShippingZonesV2(Payload)

      if (success) {
        setLimit(data.limit)
        setTotal(data.total)
        setPage(data.page)
        setShippingZones(data.zones)
        !data.zones.length? setError("Not Found Data"): setError("")
      }
      if (!success) {
        setLoading(false)
        setError(message)
      }
      setLoading(false)
    } catch (e) {
      setLoading(false)
      setError(e.message)
    }
  }

  useEffect(() => {
    getListSupplierData()
    getListCountriesData()
    _fetchListShippingZones()
  }, [title, supplier, page, limit])

  return (
    <div className="ShippingZonesPage">
      <div className="SectionInner">
        <ShippingZoneFilter
          title={title}
          setTitle={setTitle}
          supplier={supplier}
          setSupplier={setSupplier}
          listSupplier={listSupplier}
        />
        <Spin spinning={loading} tip="Getting list ...">
          <ShippingZonesTable
            shippingZones={shippingZones}
            fetchListShippingZones={_fetchListShippingZones}
            listSupplier={listSupplier}
            listCountries={listCountries}
            total={total}
            limit={limit}
            error={error}
            permissionsData={permissionsData}
          />
          <Pagination
            current={page}
            total={total}
            pageSize={limit}
            onChange={_handleChangePagination}
            className="text-right"
            showSizeChanger
            pageSizeOptions={["10", "20", "50", "100"]}
          />
        </Spin>
      </div>
    </div>
  )
}

export default ShippingZonesPage
