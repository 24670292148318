import React, {Component} from 'react'
import PropTypes from 'prop-types'
import {Table} from "reactstrap"
import {countryList} from '../../../../../statics/country-list'

class PackageAddressEditor extends Component {
    _handleChangeField = field => e => {
        const {address = []} = this.props
        const {value} = e.target

        address[field] = value

        this.props.onChangeAddress(address)
    }

    render() {
        const {address = {}} = this.props
        const {name, address1, address2, city, state, country_code, phone, postal_code} = address

        return (
            <div className="PackageAddressEditor">
                <div>Shipping address</div>

                <Table responsive>
                    <thead>
                    <tr>
                        <th>Name</th>
                        <th>Address1</th>
                        <th>Address2</th>
                        <th>City</th>
                        <th>State</th>
                        <th>Country</th>
                        <th>Phone</th>
                        <th>Postal code</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr>
                        <td>
                            <input className="form-control" type="text" value={name} placeholder="name"
                                   onChange={this._handleChangeField('name')}/>
                        </td>
                        <td>
                            <input className="form-control" type="text" value={address1} placeholder="address1"
                                   onChange={this._handleChangeField('address1')}/>
                        </td>
                        <td>
                            <input className="form-control" type="text" value={address2} placeholder="address2"
                                   onChange={this._handleChangeField('address2')}/>
                        </td>
                        <td>
                            <input className="form-control" type="text" value={city} placeholder="city"
                                   onChange={this._handleChangeField('city')}/>
                        </td>
                        <td>
                            <input className="form-control" type="text" value={state} placeholder="state"
                                   onChange={this._handleChangeField('state')}/>
                        </td>
                        <td>
                            <select className="form-control" onChange={this._handleChangeField('country_code')} value={country_code}>
                                {
                                    countryList.map((country)=>{
                                        return <option value={country.code} key={country.code}>{country.name}</option>
                                    })
                                }
                            </select>
                        </td>
                        <td>
                            <input className="form-control" type="text" value={phone} placeholder="phone"
                                   onChange={this._handleChangeField('phone')}/>
                        </td>
                        <td>
                            <input className="form-control" type="text" value={postal_code} placeholder="postalCode"
                                   onChange={this._handleChangeField('postal_code')}/>
                        </td>
                    </tr>
                    </tbody>
                </Table>
            </div>
        )
    }
}

PackageAddressEditor.propTypes = {
    address: PropTypes.object.isRequired,
    onChangeAddress: PropTypes.func.isRequired,
}


export default PackageAddressEditor
