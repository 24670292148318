import React, {useState} from 'react'
import PropTypes from 'prop-types'
import classNames from "classnames"

ArtworkPreview.propTypes = {
    artwork: PropTypes.object.isRequired
}

function ArtworkPreview(props) {
    const [background, setBackground] = useState('black')
    const {artwork} = props
    const {preview, width, height, resize, side} = artwork
    const style = {
        backgroundColor: background
    }

    function _handleChangeColor(color) {
        setBackground(color)
    }

    function _renderColor(color) {
        return (
            <div onClick={() => _handleChangeColor(color)}
                 className={classNames("Color", {active: background === color})}>{color}</div>
        )
    }

    return (
        <div className="ArtworkPreview">
            <div className="mb-2">
                <a href={preview} target="_blank" rel="noopener noreferrer">{side}</a>
            </div>

            <img style={style} src={resize} alt="artwork preview" className="ArtworkItem"/>
            <div className="Meta">{width}x{height}</div>

            <div className="Colors d-flex">
                {_renderColor('black')}
                {_renderColor('white')}
            </div>
        </div>
    )
}

export default ArtworkPreview