import React from 'react'
import BulkSelect from "./BulkSelect"
import BulkActions from "./BulkActions"

const TableHead = (props) => {
    const {scanDetail, selectedItems, toggleSelectAll, loadingSend, handleSendLoading, carrier} = props

    return (
        <thead className="ScanDetailTableHead thead-light">
        {loadingSend ? (
            <tr>
                <BulkSelect {...props}/>
                <th colSpan={4} className="position-relative">
                    Sending...
                </th>
            </tr>
        ) : selectedItems.length > 0 ? (
            <tr>
                <BulkSelect {...props}/>
                <th colSpan={4} className="position-relative">
                    <BulkActions
                        scanDetail={scanDetail}
                        selectedItems={selectedItems}
                        toggleSelectAll={toggleSelectAll}
                        carrier={carrier}
                        handleSendLoading={handleSendLoading}
                    />
                </th>
            </tr>
        ) : (
            <tr>
                <BulkSelect {...props}/>
                <th className="storeId">Package name</th>
                <th className="title">Tracking number</th>
                <th className="orderNumber">Carrier current</th>
                <th className="confirm">Carrier new</th>
            </tr>
        )}
        </thead>
    )
}

export default TableHead
