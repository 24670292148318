import createAPIServices from './createApiServices'

const baseUrl = process.env.NODE_ENV === 'production' ? '/api/coolie-v1' : 'https://fulfillment-staging.merchize.com/api/coolie-v1'

const api = createAPIServices({baseUrl})

export const getCoolieSettings = () => {
    return api.makeAuthRequest({
        url: '/coolie/settings',
        method: 'GET'
    })
}

export const saveCoolieSettings = (payload) => {
    return api.makeAuthRequest({
        url: '/coolie/settings',
        data: payload,
        method: 'PUT',
    })
}

export const splitPackagePreview = (orderId) => {
    return api.makeAuthRequest({
        url: `/v2/split-package/preview`,
        method: 'POST',
        data: {order_id: orderId}
    })
}
