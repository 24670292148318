import React from 'react'
import PropTypes from 'prop-types'
import {Input} from "reactstrap"

ProductVariantQuantity.propTypes = {
    quantity: PropTypes.number,
    variantIndex: PropTypes.number.isRequired,
    onChangeVariant: PropTypes.func.isRequired,
}

function ProductVariantQuantity(props) {
    const {quantity, variantIndex, onChangeVariant, handlePaste, disableAll} = props

    const _handleChange = e => {
        const {value} = e.target
        const quantityEntered = Number(value)

        if (isNaN(quantityEntered)) return

        if (typeof onChangeVariant === 'function') {
            onChangeVariant(variantIndex, 'as_quantity', quantityEntered)
        }
    }

    const _handlePasteInput = e => {
        handlePaste(e, "as_quantity", variantIndex)
    }

    return (
        <td className='ProductVariantQuantity'>
            <Input value={quantity || ''} onChange={_handleChange} onPaste={_handlePasteInput} disabled={disableAll}/>
        </td>
    )
}

export default ProductVariantQuantity
