import React from 'react'
import PropTypes from 'prop-types'
import {Input, Select} from "antd"

const {Option} = Select

ScanDetailFilter.propTypes = {
    status: PropTypes.string,
    trackingNumber: PropTypes.string,
    onChangeStatus: PropTypes.func.isRequired,
    onChangeNumber: PropTypes.func.isRequired
}

const trackingStatus = [
    {name: "UNKNOWN", value: "unknown"},
    {name: "PRE_TRANSIT", value: "pre_transit"},
    {name: "IN_TRANSIT", value: "in_transit"},
    {name: "OUT_FOR_DELIVERY", value: "out_for_delivery"},
    {name: "DELIVERED", value: "delivered"},
    {name: "EXCEPTION", value: "exception"}
]

function ScanDetailFilter(props) {
    const {status, trackingNumber, onChangeStatus, onChangeNumber} = props

    const _handleChangeSelect = (value) => {
        if (typeof onChangeStatus === 'function') {
            onChangeStatus(value)
        }
    }

    const _handleChangeInput = (e) => {
        const {value} = e.target
        if (typeof onChangeNumber === 'function') {
            onChangeNumber(value)
        }
    }

    return (
        <div className='ScanDetailFilter'>
            <div className="FilterItem Input">
                <label>Tracking Number</label>
                <Input value={trackingNumber} onChange={_handleChangeInput}/>
            </div>

            <div className="FilterItem">
                <label>Status</label>
                <Select onChange={_handleChangeSelect} value={status}>
                    <Option value="">All</Option>
                    {!!trackingStatus && trackingStatus.length > 0 && trackingStatus.map((item, index) => (
                        <Option value={item.value} key={index}>{item.name}</Option>
                    ))}
                </Select>
            </div>
        </div>
    )
}

export default ScanDetailFilter
