import React, {Component} from 'react'
import ExportOrderModal from "./ExportOrdersModal"
import PropTypes from "prop-types"

class ExportOrders extends Component {
    constructor(props) {
        super(props)
        this.state = {
            modal: false
        }
    }

    _onToggle = () => {
        this.setState({
            modal: !this.state.modal
        })
    }

    render() {
        const {filters, code} = this.props
        return (
            <div className="ml-2">
                <button className="btn btn-primary btn-sm"
                        onClick={this._onToggle}
                >
                    Export Orders
                </button>
                {
                    this.state.modal && <ExportOrderModal
                        open={this.state.modal}
                        filters={filters}
                        code={code}
                        onToggle={this._onToggle}
                    />
                }
            </div>
        )
    }
}

ExportOrders.propTypes = {
    filters: PropTypes.object.isRequired,
    code: PropTypes.array
}

export default ExportOrders