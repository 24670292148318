import React, {useEffect, useState} from 'react'
import ManageAutoMapProvider from "./ManageAutoMapProvider"
import UseDebounce from "./share/UseDebounce"
import Filters from "./filters/Filters"
import {Pagination} from "antd";
import RuleTable from "./datas/RuleTable"
import {getListRule} from "../../../services/api/ProductsServices";
import moment from "moment";

function ManageAutoMapPage() {
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState('')
    const [store, setStore] = useState([])
    const [titlePlf, setTitlePlf] = useState('')
    const [titleFfm, setTitleFfm] = useState('')
    const [createdAt, setCreatedAt] = useState({from: null, to: null})
    const [status, setStatus] = useState('')
    const [listRules, setListRules] = useState([])
    const [total, setTotal] = useState(0)
    const [limit, setLimit] = useState(50)
    const [page, setPage] = useState(1)

    const [selectAll, setSelectAll] = useState(false)
    const [listSelected, setListSelected] = useState([])

    const _handleChangeSelectAll = (isActive = false) => {
        if (isActive) {
            const listSelectedId = listRules.map(item => item._id)
            setListSelected(listSelectedId)
            setSelectAll(true)
        } else {
            setListSelected([])
            setSelectAll(false)
        }
    }

    const _handleSelectedRule = (id, isAdded) => {
        if (isAdded) {
            if (!listSelected.includes(id)) {
                setListSelected([
                    ...listSelected, id
                ])
                setSelectAll(listSelected.length === listRules.length)
            }
        } else {
            const filtered = listSelected.filter(item => item !== id)

            setListSelected(filtered)
            setSelectAll(false)
        }
    }

    const _convertString = (string) => {
        const regex = /\s|,\s|,|\n/
        const parsed = string.split(regex)

        return !!parsed && parsed.length > 0 && parsed.filter(item => item !== "")
    }

    const _handlePageChange = (page, pageSize) => {
        setLimit(pageSize)
        setPage(pageSize !== limit ? 1 : page)
    }

    const _handleChangeStatus = (value) => {
        setStatus(value)
    }

    const _handleChangeSearchMulti = (name, value) => {
        setPage(1)

        const arrConvert = _convertString(value)

        if (name === 'titlePlf') {
            setStore([])
            setTitleFfm('')
            setTitlePlf(value)
        } else if (name === 'store') {
            setTitleFfm('')
            setTitlePlf('')
            setStore(arrConvert)
        } else {
            setTitlePlf('')
            setStore([])
            setTitleFfm(value)
        }
    }

    const _handleChangeDateCreated = (dates) => {
        if (!!dates && dates.length > 0) {
            setPage(1)
            setCreatedAt({
                from: dates[0],
                to: dates[1]
            })
        } else {
            setPage(1)
            setCreatedAt({
                from: null,
                to: null
            })
        }
    }

    const _fetchListMappingRules = async () => {
        setLoading(true)
        setError('')

        try {
            const payload = {
                store,
                plf_product_title: titlePlf,
                product_title: titleFfm,
                created_at: {
                    from: !!createdAt.from ? moment(createdAt.from).startOf('day').format('DD/MM/YYYY HH:mm') : null,
                    to: !!createdAt.to ? moment(createdAt.to).endOf('day').format('DD/MM/YYYY HH:mm') : null
                },
                page,
                limit
            }

            if (status !== '') {
                Object.assign(payload, {is_active: status})
            }

            const response = await getListRule(payload)

            const {success, data, message: error} = response

            if (!success) {
                setLoading(false)

                return setError(error)
            }

            const {mappingRules, total} = data

            setLoading(false)
            setListRules(mappingRules)
            setTotal(total)
        } catch (e) {
            setLoading(false)
            setError(e.message)
        }
    }

    const debounceStore = UseDebounce(store, 500)

    useEffect(() => {
        _fetchListMappingRules()
    }, [page, limit, debounceStore, status, createdAt])

    return (
        <ManageAutoMapProvider listSelected={listSelected} listRules={listRules} fetchMappingRules={_fetchListMappingRules}>
            <div className='ManageAutoMapPage'>
                <div className='d-flex align-items-center justify-content-between pt-3 mb-3'>
                    <h1 className="PageTitle">Auto mapping rules</h1>
                </div>

                <div className='SectionInner'>
                    <Filters
                        status={status}
                        onChangeStatus={_handleChangeStatus}
                        onChangeSearchMulti={_handleChangeSearchMulti}
                        onChangeDateCreated={_handleChangeDateCreated}
                    />

                    <div className="Total">
                        {
                            total > 0 && <div>
                                Showing <span>{(page - 1) * limit + 1} to {(page - 1) * limit + listRules.length}</span> of <span>{total}</span> items
                            </div>
                        }
                    </div>

                    <RuleTable
                        loading={loading}
                        error={error}
                        listRules={listRules}
                        selectAll={selectAll}
                        listSelected={listSelected}
                        onChangeSelectAll={_handleChangeSelectAll}
                        onChangeSelectedRule={_handleSelectedRule}
                    />

                    <Pagination
                        current={page}
                        total={total}
                        pageSize={limit}
                        onChange={_handlePageChange}
                        className='text-right'
                        showSizeChanger pageSizeOptions={['10', '20', '50', '100']}
                    />
                </div>
            </div>
        </ManageAutoMapProvider>
    )
}

export default ManageAutoMapPage
