import React from 'react'
import {Tooltip} from "antd"
import {
    CheckCircleOutlined,
    ExclamationCircleOutlined
} from '@ant-design/icons'


const UploadStatus = (props) => {
    const {notification} = props

    const _generateStatus = (status) => {
        switch (status) {
            case "done":
                return <div className="text-success"><CheckCircleOutlined /></div>
            case "failed":
                return <div className="text-danger"><Tooltip placement="top" title={notification.upload_note}><ExclamationCircleOutlined /></Tooltip></div>
            default:
                return <div className="status"/>
        }
    }

    return (
        <td className="text-center status">{_generateStatus(notification.upload_status)}</td>
    )
}

export default UploadStatus
