import React from 'react'
import SpecialCaseNewPage from './SpecialCaseNewPage'
import DocTitleByStore from '../../dashboard/shared/DocTitleByStore'


const SpecialCaseNewContainer = () => {
    return (
        <div className='SpecialCaseNewContainer'>
            <DocTitleByStore title="Mapping Special Case Create" />
            <SpecialCaseNewPage />
        </div>
    )
}

export default SpecialCaseNewContainer