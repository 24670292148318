import React, {useContext, useState} from 'react'
import PropTypes from 'prop-types'
import ChangeStatusRule from "../popup/ChangeStatusRule"
import ManageAutoMapContext from "../../context/ManageAutoMapContext"
import DeleteRuleMapping from "../popup/DeleteRuleMapping"
import ConfirmRule from "../popup/ConfirmRule"

RuleThead.propTypes = {
    selectAll: PropTypes.bool,
    listSelected: PropTypes.array,
    onChangeSelectAll: PropTypes.func.isRequired
}

function RuleThead(props) {
    const {selectAll, listSelected, onChangeSelectAll} = props
    const {fetchMappingRules} = useContext(ManageAutoMapContext)
    const [showAction, setShowAction] = useState(false)
    const [showModal, setShowModal] = useState(false)
    const [showModalDelete, setShowModalDelete] = useState(false)
    const [showModalConfirm, setShowModalConfirm] = useState(false)

    const _showModal = () => {
        setShowModal(true)
        setShowModalDelete(false)
        setShowModalConfirm(false)
    }

    const _hiddenModal = () => {
        setShowModal(false)

        if (typeof onChangeSelectAll === 'function') {
            onChangeSelectAll(false)
            setShowAction(false)
            fetchMappingRules()
        }
    }

    const _showModalDelete = () => {
        setShowModalDelete(true)
        setShowModal(false)
        setShowModalConfirm(false)
    }

    const _hiddenModalDelete = () => {
        setShowModalDelete(false)

        if (typeof onChangeSelectAll === 'function') {
            onChangeSelectAll(false)
            setShowAction(false)
            fetchMappingRules()
        }
    }

    const _showModalConfirm = () => {
        setShowModalConfirm(true)
        setShowModal(false)
        setShowModalDelete(false)
    }

    const _hiddenModalConfirm = () => {
        setShowModalConfirm(false)

        if(typeof onChangeSelectAll === 'function') {
            onChangeSelectAll(false)
            setShowAction(false)
            fetchMappingRules()
        }
    }

    const _toggleAction = () => {
        setShowAction(!showAction)
    }

    const _handleChangeSelectAll = (e) => {
        const {checked} = e.target

        if (typeof onChangeSelectAll === 'function') {
            onChangeSelectAll(checked)
        }
    }

    return (
        <thead className='RuleThead'>
        <tr>
            <th>
                <div className="PackageChangeStatus">
                    <input type="checkbox" checked={selectAll} onChange={_handleChangeSelectAll}/>
                    <div className="Actions">
                        <i className="ti-angle-down" onClick={_toggleAction}/>
                        <ul className={`ListActions ${showAction ? 'IsOpen' : ''} ${listSelected.length > 0 ? '' : 'Disable'}`}>
                            <li className="ActionItem" onClick={_showModal}>On/Off rules</li>
                            <li className="ActionItem" onClick={_showModalConfirm} >Confirm trusted</li>
                            <li className="ActionItem" onClick={_showModalDelete}>Delete rules</li>
                        </ul>
                        {
                            showAction && <div className="Background" onClick={_toggleAction}/>
                        }
                    </div>
                </div>
            </th>
            <th>ID</th>
            <th>Store</th>
            <th>Zone</th>
            <th className="ProductTitle">Product PLF</th>
            <th className="ProductTitle">Map</th>
            <th>Confirmed</th>
            <th>Created at</th>
            <th>Status</th>
            <th>Action</th>
        </tr>

        {
            showModal && <ChangeStatusRule visible={showModal} onCancel={_hiddenModal}/>
        }

        {
            showModalConfirm && <ConfirmRule visible={showModalConfirm} onCancel={_hiddenModalConfirm} />
        }

        {
            showModalDelete && <DeleteRuleMapping visible={showModalDelete} onCancel={_hiddenModalDelete}/>
        }
        </thead>
    )
}

export default RuleThead
