import React, {Component} from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import className from 'classnames'
import {restoreFulfillOrder} from '../../../../../services/api/OrdersServices'
import broker from '../../../OrderBroker'
import {message, Modal, notification} from 'antd'

class OrderRestore extends Component {
    state = {
        countdown: '',
    }

    timer = null

    componentDidMount() {
        const {canRestore, fulfillmentStatus} = this.props
        if (!canRestore || 'partial' === fulfillmentStatus) {
            return
        }

        const completedAt = moment(this.props.completedAt)
        const finishedAt = completedAt.add(15, 'm')

        this.timer = setInterval(() => {
            const diff = finishedAt.diff(moment())

            if (diff <= 0) {
                clearInterval(this.timer)
            } else {
                this.setState({
                    countdown: moment.utc(diff).format('mm:ss'),
                })
            }
        }, 1000)
    }

    componentWillUnmount() {
        clearInterval(this.timer)
    }

    _handleToggleModal = () => {
        const restoreOrder = this._handleRestoreOrderFulfilled

        Modal.confirm({
            title: 'Are you sure want to restore the order?',
            onOk() {
                return restoreOrder().then(result => {
                    if (result !== true) throw new Error('Something wrong')

                    message.success('Order restored')
                }).catch(err => {
                    notification.error({
                        message: 'ERROR',
                        description: err.message,
                        duration: 0,
                    })
                })
            },
        })
    }

    _handleRestoreOrderFulfilled = async () => {
        const {orderId} = this.props

        const {success, message: _message} = await restoreFulfillOrder(orderId)

        if (!success) {
            throw new Error(_message)
        }

        broker.publish('ORDER_RESTORE')
        broker.publish('ORDER_REFRESH_BRANDING')
        broker.publish("ORDER_ITEM_REJECTED")

        return true
    }

    render() {
        const {canRestore, fulfillmentStatus, fulfilledPackages} = this.props
        const {countdown} = this.state
        const packagesPushed = fulfilledPackages.filter(item => item.push_status === 'pushed')


        let disabled = false

        if (!canRestore) {
            disabled = true
        }

        if (fulfillmentStatus === 'rejected') {
            disabled = false
        }

        if (fulfilledPackages && fulfilledPackages.length > 0 && packagesPushed.length > 0) disabled = true

        return (
            <div
                className={className('OrderRestore', {disabled: disabled})}
                onClick={this._handleToggleModal}>
                <span>Restore order</span>
                {
                    (canRestore && fulfillmentStatus !== 'partial') &&
                    <span className="text-danger float-right">{countdown}</span>
                }
            </div>
        )
    }
}

OrderRestore.propTypes = {
    orderId: PropTypes.string.isRequired,
    fulfillmentStatus: PropTypes.string.isRequired,
    completedAt: PropTypes.string,
    canRestore: PropTypes.bool,
}

export default OrderRestore
