import React, {Component, Fragment} from 'react'
import PropTypes from 'prop-types'

class StatusStyle extends Component {
    _mapStatus = () => {
        const {status} = this.props

        if (!status) {
            return null
        }

        let output = ""
        switch (status) {
            case "partial":
                output = <span className="badge badge-info">{status}</span>
                break
            case "rejected":
            case "cancelled":
            case "failed":
                output = <span className="badge badge-danger">{status}</span>
                break
            case "fulfilled":
            case "created":
            case "success":
                output = <span className="badge badge-success">{status}</span>
                break
            case "unfulfilled":
                output = <span className="badge badge-warning">{status}</span>
                break
            case "pending":
                output = <span className="badge badge-info pending-animation">{status}</span>
                break
            case "processing":
            case "in_production":
                output = <span className="badge badge-primary processing-animation">{status}</span>
                break
            case "processed":
            case "shipped":
                output = <span className="badge badge-primary">{status}</span>
                break
            case "paid":
            case "archived":
                output = <span className="badge badge-secondary">{status}</span>
                break
            default:
                output = <span className="badge badge-secondary">{status}</span>
        }

        return output
    }

    render() {
        const status = this._mapStatus()

        return (
            <Fragment>
                {status}
            </Fragment>
        )
    }
}

StatusStyle.propTypes = {
    status: PropTypes.string,
}

export default StatusStyle
