import React from 'react'
import ItemSide from './item-side/ItemSide'

const OrderMockUp = (props) => {
    const { mockUpFront, mockUpBack, mockUpSleeves, mockUpHood, lazyload, rowSpan, ...rest } = props

    return (
        <td className='OrderMockUp' rowSpan={rowSpan}>
            <div className='d-flex no-gutters'>
                <div className='col-6 p-1'>
                    <ItemSide {...rest} type='update-mock-up' side='front' url={mockUpFront} layout='image' />
                </div>

                {/* <div className='col-6 p-1'>
                    <ItemSide {...rest} type='update-mock-up' side='back' url={mockUpBack} layout='image' />
                </div> */}
            </div>
        </td>
    )
}

export default OrderMockUp