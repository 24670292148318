import React, {Component} from 'react'
import PropTypes from 'prop-types'
import ReactCountryFlag from "react-country-flag"

class ShippingAddress extends Component {
    render() {
        const {
            address: addressDetail = "", address2: addressDetail2 = "", full_name = "",
            country_code = "", country = "", email = "", city = "",
            state = "", postal_code, phone = "",
        } = this.props.address

        return (
            <div className="ShippingAddress">
                {
                    !!full_name &&
                    <div className="font-weight-bold mb-2">{full_name}</div>
                }

                {
                    !!addressDetail &&
                    <div className="mb-2">{addressDetail}{!!addressDetail2 && `, ${addressDetail2}`}</div>
                }

                <div className="mb-2">{city}{!!state && ` - ${state}`}{!!postal_code && ` - ${postal_code}`}</div>

                {
                    !!country_code &&
                    <div className="d-flex align-items-baseline mb-2">
                        <span className="mr-2"><ReactCountryFlag code={country_code} svg/></span>
                        <span>{country}</span>
                    </div>
                }

                {
                    !!phone &&
                    <div className="mb-2">
                        <i className="fas fa-phone mr-2"/>{phone}
                    </div>
                }

                {
                    !!email &&
                    <div className="d-flex align-items-baseline"><i className="fa fa-envelope mr-2"/>{email}
                    </div>
                }
            </div>
        )
    }
}

ShippingAddress.propTypes = {
    address: PropTypes.object.isRequired,
}

export default ShippingAddress
