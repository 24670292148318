export const currency = [
    {
        "value": "USD",
        "title": "USD - US Dollar",
        "symbol": "$"
    },
    {
        "value": "CAD",
        "title": "CAD - Canadian Dollar",
        "symbol": "CA$"
    },
    {
        "value": "EUR",
        "title": "EUR - Euro",
        "symbol": "€"
    },
    {
        "value": "AED",
        "title": "AED - United Arab Emirates Dirham",
        "symbol": "AED"
    },
    {
        "value": "AFN",
        "title": "AFN - Afghan Afghani",
        "symbol": "Af"
    },
    {
        "value": "ALL",
        "title": "ALL - Albanian Lek",
        "symbol": "ALL"
    },
    {
        "value": "AMD",
        "title": "AMD - Armenian Dram",
        "symbol": "AMD"
    },
    {
        "value": "ARS",
        "title": "ARS - Argentine Peso",
        "symbol": "AR$"
    },
    {
        "value": "AUD",
        "title": "AUD - Australian Dollar",
        "symbol": "AU$"
    },
    {
        "value": "AZN",
        "title": "AZN - Azerbaijani Manat",
        "symbol": "man."
    },
    {
        "value": "BAM",
        "title": "BAM - Bosnia-Herzegovina Convertible Mark",
        "symbol": "KM"
    },
    {
        "value": "BDT",
        "title": "BDT - Bangladeshi Taka",
        "symbol": "Tk"
    },
    {
        "value": "BGN",
        "title": "BGN - Bulgarian Lev",
        "symbol": "BGN"
    },
    {
        "value": "BHD",
        "title": "BHD - Bahraini Dinar",
        "symbol": "BD"
    },
    {
        "value": "BIF",
        "title": "BIF - Burundian Franc",
        "symbol": "FBu"
    },
    {
        "value": "BND",
        "title": "BND - Brunei Dollar",
        "symbol": "BN$"
    },
    {
        "value": "BOB",
        "title": "BOB - Bolivian Boliviano",
        "symbol": "Bs"
    },
    {
        "value": "BRL",
        "title": "BRL - Brazilian Real",
        "symbol": "R$"
    },
    {
        "value": "BWP",
        "title": "BWP - Botswanan Pula",
        "symbol": "BWP"
    },
    {
        "value": "BYR",
        "title": "BYR - Belarusian Ruble",
        "symbol": "BYR"
    },
    {
        "value": "BZD",
        "title": "BZD - Belize Dollar",
        "symbol": "BZ$"
    },
    {
        "value": "CDF",
        "title": "CDF - Congolese Franc",
        "symbol": "CDF"
    },
    {
        "value": "CHF",
        "title": "CHF - Swiss Franc",
        "symbol": "CHF"
    },
    {
        "value": "CLP",
        "title": "CLP - Chilean Peso",
        "symbol": "CL$"
    },
    {
        "value": "CNY",
        "title": "CNY - Chinese Yuan",
        "symbol": "CN¥"
    },
    {
        "value": "COP",
        "title": "COP - Colombian Peso",
        "symbol": "CO$"
    },
    {
        "value": "CRC",
        "title": "CRC - Costa Rican Colón",
        "symbol": "₡"
    },
    {
        "value": "CVE",
        "title": "CVE - Cape Verdean Escudo",
        "symbol": "CV$"
    },
    {
        "value": "CZK",
        "title": "CZK - Czech Republic Koruna",
        "symbol": "Kč"
    },
    {
        "value": "DJF",
        "title": "DJF - Djiboutian Franc",
        "symbol": "Fdj"
    },
    {
        "value": "DKK",
        "title": "DKK - Danish Krone",
        "symbol": "Dkr"
    },
    {
        "value": "DOP",
        "title": "DOP - Dominican Peso",
        "symbol": "RD$"
    },
    {
        "value": "DZD",
        "title": "DZD - Algerian Dinar",
        "symbol": "DA"
    },
    {
        "value": "EEK",
        "title": "EEK - Estonian Kroon",
        "symbol": "Ekr"
    },
    {
        "value": "EGP",
        "title": "EGP - Egyptian Pound",
        "symbol": "EGP"
    },
    {
        "value": "ERN",
        "title": "ERN - Eritrean Nakfa",
        "symbol": "Nfk"
    },
    {
        "value": "ETB",
        "title": "ETB - Ethiopian Birr",
        "symbol": "Br"
    },
    {
        "value": "GBP",
        "title": "GBP - British Pound Sterling",
        "symbol": "£"
    },
    {
        "value": "GEL",
        "title": "GEL - Georgian Lari",
        "symbol": "GEL"
    },
    {
        "value": "GHS",
        "title": "GHS - Ghanaian Cedi",
        "symbol": "GH₵"
    },
    {
        "value": "GNF",
        "title": "GNF - Guinean Franc",
        "symbol": "FG"
    },
    {
        "value": "GTQ",
        "title": "GTQ - Guatemalan Quetzal",
        "symbol": "GTQ"
    },
    {
        "value": "HKD",
        "title": "HKD - Hong Kong Dollar",
        "symbol": "HK$"
    },
    {
        "value": "HNL",
        "title": "HNL - Honduran Lempira",
        "symbol": "HNL"
    },
    {
        "value": "HRK",
        "title": "HRK - Croatian Kuna",
        "symbol": "kn"
    },
    {
        "value": "HUF",
        "title": "HUF - Hungarian Forint",
        "symbol": "Ft"
    },
    {
        "value": "IDR",
        "title": "IDR - Indonesian Rupiah",
        "symbol": "Rp"
    },
    {
        "value": "ILS",
        "title": "ILS - Israeli New Sheqel",
        "symbol": "₪"
    },
    {
        "value": "INR",
        "title": "INR - Indian Rupee",
        "symbol": "Rs"
    },
    {
        "value": "IQD",
        "title": "IQD - Iraqi Dinar",
        "symbol": "IQD"
    },
    {
        "value": "IRR",
        "title": "IRR - Iranian Rial",
        "symbol": "IRR"
    },
    {
        "value": "ISK",
        "title": "ISK - Icelandic Króna",
        "symbol": "Ikr"
    },
    {
        "value": "JMD",
        "title": "JMD - Jamaican Dollar",
        "symbol": "J$"
    },
    {
        "value": "JOD",
        "title": "JOD - Jordanian Dinar",
        "symbol": "JD"
    },
    {
        "value": "JPY",
        "title": "JPY - Japanese Yen",
        "symbol": "¥"
    },
    {
        "value": "KES",
        "title": "KES - Kenyan Shilling",
        "symbol": "Ksh"
    },
    {
        "value": "KHR",
        "title": "KHR - Cambodian Riel",
        "symbol": "KHR"
    },
    {
        "value": "KMF",
        "title": "KMF - Comorian Franc",
        "symbol": "CF"
    },
    {
        "value": "KRW",
        "title": "KRW - South Korean Won",
        "symbol": "₩"
    },
    {
        "value": "KWD",
        "title": "KWD - Kuwaiti Dinar",
        "symbol": "KD"
    },
    {
        "value": "KZT",
        "title": "KZT - Kazakhstani Tenge",
        "symbol": "KZT"
    },
    {
        "value": "LBP",
        "title": "LBP - Lebanese Pound",
        "symbol": "LB£"
    },
    {
        "value": "LKR",
        "title": "LKR - Sri Lankan Rupee",
        "symbol": "SLRs"
    },
    {
        "value": "LTL",
        "title": "LTL - Lithuanian Litas",
        "symbol": "Lt"
    },
    {
        "value": "LVL",
        "title": "LVL - Latvian Lats",
        "symbol": "Ls"
    },
    {
        "value": "LYD",
        "title": "LYD - Libyan Dinar",
        "symbol": "LD"
    },
    {
        "value": "MAD",
        "title": "MAD - Moroccan Dirham",
        "symbol": "MAD"
    },
    {
        "value": "MDL",
        "title": "MDL - Moldovan Leu",
        "symbol": "MDL"
    },
    {
        "value": "MGA",
        "title": "MGA - Malagasy Ariary",
        "symbol": "MGA"
    },
    {
        "value": "MKD",
        "title": "MKD - Macedonian Denar",
        "symbol": "MKD"
    },
    {
        "value": "MMK",
        "title": "MMK - Myanma Kyat",
        "symbol": "MMK"
    },
    {
        "value": "MOP",
        "title": "MOP - Macanese Pataca",
        "symbol": "MOP$"
    },
    {
        "value": "MUR",
        "title": "MUR - Mauritian Rupee",
        "symbol": "MURs"
    },
    {
        "value": "MXN",
        "title": "MXN - Mexican Peso",
        "symbol": "MX$"
    },
    {
        "value": "MYR",
        "title": "MYR - Malaysian Ringgit",
        "symbol": "RM"
    },
    {
        "value": "MZN",
        "title": "MZN - Mozambican Metical",
        "symbol": "MTn"
    },
    {
        "value": "NAD",
        "title": "NAD - Namibian Dollar",
        "symbol": "N$"
    },
    {
        "value": "NGN",
        "title": "NGN - Nigerian Naira",
        "symbol": "₦"
    },
    {
        "value": "NIO",
        "title": "NIO - Nicaraguan Córdoba",
        "symbol": "C$"
    },
    {
        "value": "NOK",
        "title": "NOK - Norwegian Krone",
        "symbol": "Nkr"
    },
    {
        "value": "NPR",
        "title": "NPR - Nepalese Rupee",
        "symbol": "NPRs"
    },
    {
        "value": "NZD",
        "title": "NZD - New Zealand Dollar",
        "symbol": "NZ$"
    },
    {
        "value": "OMR",
        "title": "OMR - Omani Rial",
        "symbol": "OMR"
    },
    {
        "value": "PAB",
        "title": "PAB - Panamanian Balboa",
        "symbol": "B/."
    },
    {
        "value": "PEN",
        "title": "PEN - Peruvian Nuevo Sol",
        "symbol": "S/."
    },
    {
        "value": "PHP",
        "title": "PHP - Philippine Peso",
        "symbol": "₱"
    },
    {
        "value": "PKR",
        "title": "PKR - Pakistani Rupee",
        "symbol": "PKRs"
    },
    {
        "value": "PLN",
        "title": "PLN - Polish Zloty",
        "symbol": "zł"
    },
    {
        "value": "PYG",
        "title": "PYG - Paraguayan Guarani",
        "symbol": "₲"
    },
    {
        "value": "QAR",
        "title": "QAR - Qatari Rial",
        "symbol": "QR"
    },
    {
        "value": "RON",
        "title": "RON - Romanian Leu",
        "symbol": "RON"
    },
    {
        "value": "RSD",
        "title": "RSD - Serbian Dinar",
        "symbol": "din."
    },
    {
        "value": "RUB",
        "title": "RUB - Russian Ruble",
        "symbol": "RUB"
    },
    {
        "value": "RWF",
        "title": "RWF - Rwandan Franc",
        "symbol": "RWF"
    },
    {
        "value": "SAR",
        "title": "SAR - Saudi Riyal",
        "symbol": "SR"
    },
    {
        "value": "SDG",
        "title": "SDG - Sudanese Pound",
        "symbol": "SDG"
    },
    {
        "value": "SEK",
        "title": "SEK - Swedish Krona",
        "symbol": "Skr"
    },
    {
        "value": "SGD",
        "title": "SGD - Singapore Dollar",
        "symbol": "S$"
    },
    {
        "value": "SOS",
        "title": "SOS - Somali Shilling",
        "symbol": "Ssh"
    },
    {
        "value": "SYP",
        "title": "SYP - Syrian Pound",
        "symbol": "SY£"
    },
    {
        "value": "THB",
        "title": "THB - Thai Baht",
        "symbol": "฿"
    },
    {
        "value": "TND",
        "title": "TND - Tunisian Dinar",
        "symbol": "DT"
    },
    {
        "value": "TOP",
        "title": "TOP - Tongan Paʻanga",
        "symbol": "T$"
    },
    {
        "value": "TRY",
        "title": "TRY - Turkish Lira",
        "symbol": "TL"
    },
    {
        "value": "TTD",
        "title": "TTD - Trinidad and Tobago Dollar",
        "symbol": "TT$"
    },
    {
        "value": "TWD",
        "title": "TWD - New Taiwan Dollar",
        "symbol": "NT$"
    },
    {
        "value": "TZS",
        "title": "TZS - Tanzanian Shilling",
        "symbol": "TSh"
    },
    {
        "value": "UAH",
        "title": "UAH - Ukrainian Hryvnia",
        "symbol": "₴"
    },
    {
        "value": "UGX",
        "title": "UGX - Ugandan Shilling",
        "symbol": "USh"
    },
    {
        "value": "UYU",
        "title": "UYU - Uruguayan Peso",
        "symbol": "$U"
    },
    {
        "value": "UZS",
        "title": "UZS - Uzbekistan Som",
        "symbol": "UZS"
    },
    {
        "value": "VEF",
        "title": "VEF - Venezuelan Bolívar",
        "symbol": "Bs.F."
    },
    {
        "value": "VND",
        "title": "VND - Vietnamese Dong",
        "symbol": "₫"
    },
    {
        "value": "XAF",
        "title": "XAF - CFA Franc BEAC",
        "symbol": "FCFA"
    },
    {
        "value": "XOF",
        "title": "XOF - CFA Franc BCEAO",
        "symbol": "CFA"
    },
    {
        "value": "YER",
        "title": "YER - Yemeni Rial",
        "symbol": "YR"
    },
    {
        "value": "ZAR",
        "title": "ZAR - South African Rand",
        "symbol": "R"
    },
    {
        "value": "ZMK",
        "title": "ZMK - Zambian Kwacha",
        "symbol": "ZK"
    }
]
