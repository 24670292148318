import React from 'react'
import PropTypes from 'prop-types'
import OrderItemProduct from "./OrderItemProduct"
import OrderItemMap from "./OrderItemMap"
import OrderItemDesign from "./OrderItemDesign"

OrderItemRow.propTypes = {
    item: PropTypes.object,
    orderId: PropTypes.string
}

function OrderItemRow(props) {
    const { orderId, item, orderType } = props
    const { product, variant, currency, quantity, price, sku, _id: orderItemId, productLineVariant, mockup_url, mockup_message, fulfillmentItems = [] } = item
    const isCheckVersionOrderPartnerV2 = fulfillmentItems.some(item => item.artworks.length > 0)

    return (
        (isCheckVersionOrderPartnerV2 && fulfillmentItems.length > 1) ? fulfillmentItems.map((fulfillmentItem, index) => {
            const { quantity: quantityItem, artworks } = fulfillmentItem
            return (
                <tr className="OrderItemRow" key={index}>
                    {index === 0 && <OrderItemProduct
                        product={product}
                        variant={variant}
                        currency={currency}
                        quantity={quantity}
                        price={price}
                        sku={sku}
                        mockupUrl={mockup_url}
                        mockupMessage={mockup_message}
                        rowSpan={fulfillmentItems.length}
                    />}
                    <OrderItemMap
                        imageObject={productLineVariant.image}
                        product={productLineVariant.product}
                        currency={productLineVariant.currency}
                        base_cost={productLineVariant.base_cost}
                        additional_price={productLineVariant.additional_price}
                        options={productLineVariant.options}
                        sku={productLineVariant.sku}
                        orderType={orderType}
                        quantity={quantityItem}
                        isCheckVersionOrderPartnerV2={isCheckVersionOrderPartnerV2}
                    />
                    <OrderItemDesign
                        orderId={orderId}
                        orderItemId={orderItemId}
                        artworks={artworks}
                        isCheckVersionOrderPartnerV2={isCheckVersionOrderPartnerV2}
                    />
                </tr>
            )
        }) :
            (
                <tr className="OrderItemRow">
                    <OrderItemProduct
                        product={product}
                        variant={variant}
                        currency={currency}
                        quantity={quantity}
                        price={price}
                        sku={sku}
                        mockupUrl={mockup_url}
                        mockupMessage={mockup_message}
                    />
                    <OrderItemMap
                        imageObject={productLineVariant.image}
                        product={productLineVariant.product}
                        currency={productLineVariant.currency}
                        base_cost={productLineVariant.base_cost}
                        additional_price={productLineVariant.additional_price}
                        options={productLineVariant.options}
                        sku={productLineVariant.sku}
                        orderType={orderType}
                        quantity={fulfillmentItems[0] ? fulfillmentItems[0].quantity : ''}
                        isCheckVersionOrderPartnerV2={isCheckVersionOrderPartnerV2}
                    />
                    <OrderItemDesign
                        orderId={orderId}
                        orderItemId={orderItemId}
                        artworks={fulfillmentItems[0] ? fulfillmentItems[0].artworks : []}
                        isCheckVersionOrderPartnerV2={isCheckVersionOrderPartnerV2}
                    />
                </tr>
            )
    )
}

export default OrderItemRow