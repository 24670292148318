import React, {useState} from 'react'
import PropTypes from 'prop-types'
import classNames from "classnames"
import {updateCategory, updateProductCategory} from "../../../../services/api/ProductsServices"
import {message} from "antd"

CategoryItem.propTypes = {
    titleCategory: PropTypes.string,
    productId: PropTypes.string,
    categoryId: PropTypes.string,
    onUpdateState: PropTypes.func.isRequired,
    onToggleModal: PropTypes.func.isRequired,
    onDeleteCategory: PropTypes.func.isRequired
}

function CategoryItem(props) {
    const {titleCategory, productId, categoryId, onUpdateState, onToggleModal, onDeleteCategory} = props
    const [loading, setLoading] = useState(false)
    const [editing, setEditing] = useState(false)
    const [category, setCategory] = useState({
        title: titleCategory,
        is_active: true
    })

    const _handleOnChange = (e) => {
        e.preventDefault()

        const {value} = e.target

        setCategory({
            ...category,
            title: value
        })
    }

    const _handleClickCategoryTitle = async (e) => {
        e.preventDefault()

        if (loading) return

        setLoading(true)

        const {innerText: categoryTitle} = e.target

        const response = await _onUpdateProductCategory()

        if (response) {
            if (typeof onUpdateState === 'function' && typeof onToggleModal === 'function') {
                onToggleModal()
                onUpdateState(categoryTitle)
            }
        }

        setLoading(false)
    }

    const _handleClickDelete = (e) => {
        e.preventDefault()

        if (typeof onDeleteCategory === 'function') {
            onDeleteCategory(categoryId)
        }
    }

    const _handleClickEdit = (e) => {
        e.preventDefault()

        setEditing(true)
    }

    const _handleClickCancel = (e) => {
        e.preventDefault()

        setEditing(false)
    }

    const _handleClickSave = (e) => {
        e.preventDefault()

        _handleUpdateCategory()
    }

    const _handleUpdateCategory = async () => {
        try {
            const {title, is_active} = category

            const response = await updateCategory(categoryId, {title, is_active})

            const {success} = response

            setEditing(false)

            return success
        } catch (e) {
            return false
        }
    }

    const _onUpdateProductCategory = async () => {
        try {
            const response = await updateProductCategory(productId, categoryId)

            const {success, message: error} = response

            if (!success) {
                message.error(error)
            }

            message.success('Update product category success!')

            return success
        } catch (e) {
            return false
        }
    }

    const {title} = category
    const listActions = [
        {
            name: "Save",
            event: _handleClickSave,
            editing: true,
            class: "btn-sm text-primary",
        },
        {
            name: "Cancel",
            event: _handleClickCancel,
            editing: true,
            class: "btn-sm text-secondary",
        },
        {
            name: "Edit",
            event: _handleClickEdit,
            editing: false,
            class: "btn-sm text-secondary",
        },
        {
            name: "Delete",
            event: _handleClickDelete,
            editing: false,
            class: "btn-sm text-danger",
        },
    ]

    const listActionsAvailable = listActions.filter(action => action.editing === editing)

    return (
        <li className={classNames("CategoryItem d-flex p-2 align-items-baseline cursor-pointer", {editing})}>
            <div className="flex-grow-1 mr-3">
                {
                    editing ?
                        <input
                            type="text"
                            className="form-control"
                            value={title || ""}
                            autoFocus={true}
                            onChange={_handleOnChange}
                        />
                        :
                        <h6 className="mb-0 Title" onClick={_handleClickCategoryTitle} title="Select">{title}</h6>
                }
            </div>

            <ul>
                {
                    listActionsAvailable.map((action) => {
                        return (
                            <li key={action.name} onClick={action.event} className="d-inline-block">
                                <span className={action.class}>{action.name}</span>
                            </li>
                        )
                    })
                }
            </ul>
        </li>
    )
}

export default CategoryItem
