import React, {useState} from 'react'
import PropTypes from 'prop-types'

CreateCategory.propTypes = {
    onCreateCategory: PropTypes.func.isRequired
}

function CreateCategory(props) {
    const {onCreateCategory} = props
    const [toggleForm, setToggleForm] = useState(false)
    const [title, setTitle] = useState('')

    const _handleClick = (e) => {
        e.preventDefault()

        setToggleForm(!toggleForm)
    }

    const _handleChange = (e) => {
        const {value} = e.target

        setTitle(value)
    }

    const _onSubmit = async (e) => {
        e.preventDefault()

        const response = await onCreateCategory(title)

        if (response) {
            setTitle('')
        }
    }

    return (
        <div className="CreateCategory border-top mt-4">
            <div className="text-primary mt-3 mb-2 d-inline-block cursor-pointer" onClick={_handleClick}>
                + Add new category
            </div>

            {
                toggleForm &&
                <form className="d-flex" onSubmit={_onSubmit}>
                    <input
                        type="text" value={title || ""}
                        className="form-control flex-grow-1"
                        placeholder="Enter your title..."
                        autoFocus={toggleForm}
                        onChange={_handleChange}
                    />
                    <button type="submit" className="btn btn-success flex-shrink-0 ml-4">Add new</button>
                </form>
            }
        </div>
    )
}

export default CreateCategory
