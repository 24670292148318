import React from 'react'
import OrderPartnersContext from './../context/OrderPartnersContext'
import PropTypes from 'prop-types'

function OrderPartnersProvider(props) {
    const {loadOrders, setState, filters, listSelected, packageLists, onChangeListOrder, onChangePackageNotes, onChangePackageData, allowedUpdateTags} = props

    return (
        <OrderPartnersContext.Provider
            value={{
                filters,
                listSelected,
                packageLists,
                loadOrders: loadOrders,
                setState: setState,
                onChangeListOrder: onChangeListOrder,
                onChangePackageNotes: onChangePackageNotes,
                onChangePackageData: onChangePackageData,
                allowedUpdateTags: allowedUpdateTags,
            }}
        >
            {props.children}
        </OrderPartnersContext.Provider>
    )
}

OrderPartnersProvider.propTypes = {
    loadOrders: PropTypes.func.isRequired
}

export default OrderPartnersProvider
