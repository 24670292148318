import React from 'react'
import NotificationPage from './NotificationPage'
import DocTitleByStore from "../../dashboard/shared/DocTitleByStore"

const NotificationContainer = () => {
    return (
        <section className="NotificationContainer">
            <DocTitleByStore title="Notification"/>
            <NotificationPage/>
        </section>
    )
}

export default NotificationContainer
