import React, {Component} from 'react'
import PropTypes from 'prop-types'

class OrderShippingPlan extends Component {
    render() {
        const {order} = this.props
        const {shipping_plan} = order
        let textPlan = 'Unset'

        if (typeof shipping_plan === 'object') {
            const {title} = Object.assign({}, shipping_plan)
            textPlan = title
        }

        return (
            <td className="OrderShippingPlan">
                {textPlan}
            </td>
        )
    }
}

OrderShippingPlan.propTypes = {
    order: PropTypes.object.isRequired,
}

export default OrderShippingPlan
