export const listFilterText = [
    {name: 'Store', value: 'store'},
    {name: 'Title Platform', value: 'titlePlf'},
    {name: 'Title Fulfillment', value: 'titleFfm'}
]

export const listFilterStatus = [
    {name: 'All', value: ''},
    {name: 'Active', value: true},
    {name: 'Deactive', value: false}
]
