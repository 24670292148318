import React, {Component} from 'react'
import OrderItemHead from "./OrderItemTable/OrderItemHead"
import OrderItemRow from "./OrderItemTable/OrderItemRow"
import PropTypes from 'prop-types'

class OrderItemTable extends Component {
    render() {
        const {language, languageError, order} = this.props
        const {
            orderItems = {},
            _fetchOrderItems,
            orderId,
            storeMode,
            fulfillmentStatus,
            packageName,
            tags,
            namespace,
            orderType,
            setArtWorkLevel,
            setArtWorkPack,
            setArtworks,
            listSuppliers,
        } = this.props

        return (
            <div className="OrderItemTable">
                <table className="table table-bordered">
                    <OrderItemHead/>
                    <tbody>
                    {
                        orderItems.map(item => {
                            return <OrderItemRow
                                order={order}
                                language={language}
                                languageError={languageError}
                                tags={tags}
                                key={item._id}
                                fulfillmentStatus={fulfillmentStatus}
                                storeMode={storeMode}
                                orderId={orderId}
                                orderItem={item}
                                _fetchOrderItems={_fetchOrderItems}
                                packageName={packageName}
                                namespace={namespace}
                                orderType={orderType}
                                setArtWorkLevel={setArtWorkLevel}
                                setArtWorkPack={setArtWorkPack}
                                setArtworks={setArtworks}
                                listSuppliers={listSuppliers}
                            />
                        })
                    }
                    </tbody>
                </table>
            </div>
        )
    }
}

OrderItemTable.propTypes = {
    namespace: PropTypes.string,
    orderItems: PropTypes.array.isRequired,
    storeMode: PropTypes.string.isRequired,
    packageName: PropTypes.string
}

export default OrderItemTable
