import React from 'react'
import PropTypes from 'prop-types'
import formatCurrency from "../../../../helpers/common/formatCurrency"

ShippingCod.propTypes = {
    shipping: PropTypes.object,
    shipmentInfo: PropTypes.object,
    shippingPlan: PropTypes.object
}

function ShippingCod(props) {
    const {shipping, shipmentInfo, shippingPlan} = props

    if (!shipping || typeof shipping !== 'object' || Object.keys(shipping).length === 0) {
        return null
    }

    const transport = shipmentInfo !== undefined ? shipmentInfo.shipment_name.split('_') : []

    return (
        <div className="ShippingInner">
            <div className="Title">Shipping plan</div>
            <div className="Content">
                <div className="Row">
                    <div className="value">{shippingPlan.title}</div>
                </div>
                <div className="Row">
                    <div className="value">
                        {transport[1]}
                    </div>
                </div>
            </div>
            <div className="Title mt-1">
                COD: {formatCurrency(shipmentInfo !== undefined ? shipmentInfo.currency : 'USD', shipmentInfo !== undefined ? shipmentInfo.total : 0)}
            </div>
        </div>
    )
}

export default ShippingCod