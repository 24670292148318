import React, {Component} from 'react'
import {Alert, Divider, Input, Spin} from 'antd'
import ShippingLabelDetails from './ShippingLabelDetails'
import {getOrderDetailByCode} from '../../../services/api/ExpressServices'

class ShippingLabelPage extends Component {
    state = {
        order: {},
        loading: false,
        error: '',
    }

    _fetchOrderDetailByCode = async (orderCode) => {
        if (this.state.loading) return true

        try {
            this.setState({
                loading: true,
            })

            const {data, success, message} = await getOrderDetailByCode(orderCode)

            if (!success) throw new Error(message)

            this.setState({
                loading: false,
                order: data,
                error: '',
            })
        } catch (e) {
            this.setState({
                loading: false,
                order: {},
                error: e.message,
            })
        }
    }

    _handleSearch = (value) => {
        if (value === '') {
            this.setState({
                order: {},
                error: '',
            })

            return
        }

        const code = value.trim()

        if (!code) {
            return
        }

        this._fetchOrderDetailByCode(code)
    }

    _handleChangeDimension = (dimension = {}) => {
        const {order} = this.state

        this.setState({
            order: {
                ...order,
                dimension: {
                    ...Object.assign({}, dimension)
                }
            }
        })
    }

    render() {
        const {order, loading, error} = this.state

        return (
            <div className='ShippingLabelPage pt-4'>
                <div className='container-fluid'>
                    <div className='p-5 bg-white shadow-sm'>
                        <h1 className='PageTitle mt-n3'>Print QR</h1>
                        <Input.Search placeholder='Enter order code...' size='large' enterButton='Search'
                                      onSearch={this._handleSearch} autoFocus={true} allowClear/>
                    </div>

                    {
                        error && <Alert message='ERROR' description={error} type='error' showIcon className='mt-5'/>
                    }

                    <Spin spinning={loading} tip='Loading...'>
                        <div className='py-5'>
                            {
                                order.hasOwnProperty('_id') &&
                                <>
                                    <Divider orientation='left' className='mt-0 mb-3'>Order details</Divider>
                                    <ShippingLabelDetails onChangeDimension={this._handleChangeDimension}
                                                          order={order}/>
                                </>
                            }
                        </div>
                    </Spin>
                </div>
            </div>
        )
    }
}

export default ShippingLabelPage
