import React, {Component} from 'react'
import OrderHistoriesList from "./OrderHistoriesList"
import PropTypes from 'prop-types'
import {Input, AutoComplete} from 'antd'

class OrderHistoriesContainer extends Component {
    state = {
        keyHistory: '',
        historyOptions : [ 
            {value: "All"}, 
            {value: "Order push"}, 
            {value: "Order tracking"}, 
            {value: "Order invoice"}
        ],
    }


    _handleChangeKeyHistory = (value) => {
        this.setState({
            keyHistory: value
        })
    }


    _handleAutoChange = (value) => {
        this.setState(preState => ({
            ...preState,
            keyHistory:value,
            isClear : false
        }))
    }


    render() {
        const {keyHistory, historyOptions} = this.state
        return (
            <div className="OrderHistoriesContainer">
                <div className="SectionInner">

                    <div className="d-sm-flex mb-3 align-items-center justify-content-between">
                        <h3 className="SectionHeading mb-0">History</h3>
                        <div className="Select position-relative" >
                            <AutoComplete
                                options={(keyHistory === undefined || keyHistory === "" ) ? historyOptions : []}
                                name="order_status_note"
                                style={{width: 300}}
                                placeholder="Please select tag history"
                                onChange={this._handleAutoChange}
                                allowClear
                                value={keyHistory}
                            />
                        </div>
                    </div>

                    <OrderHistoriesList keyHistory={keyHistory} {...this.props}/>
                </div>
            </div>
        )
    }
}

OrderHistoriesContainer.propTypes = {
    orderId: PropTypes.string.isRequired,
}

export default OrderHistoriesContainer
