import React from 'react'
import PropTypes from 'prop-types'
import moment from "moment"
import {Link} from "react-router-dom"

SwitchCarrierItem.propTypes = {
    item: PropTypes.object
}

function SwitchCarrierItem(props) {
    const {item} = props

    return (
        <tr className='RequestItem'>
            <td className="RequestId">
                <Link
                    to={{
                        pathname: `switch-carrier/${item._id}`,
                        search: `?status=${item.status}`
                    }}
                >
                    {item._id}
                </Link>
            </td>
            <td className="Created">{item.created_at && moment(item.created_at).format('HH:mm DD/MM/YYYY')}</td>
            <td className="Package">{item.number_tracking || '-'}</td>
            <td className="Carrier">{item.carrier}</td>
            <td className="Status">
                <div className={item.status}>
                    {item.status === 'processing' ? `${item.status} ${item.number_processed_tracking}/${item.number_tracking}` : item.status}
                </div>
            </td>
            <td className="Carrier">
                {!!item.user && item.user.email}
            </td>
        </tr>
    )
}

export default SwitchCarrierItem
