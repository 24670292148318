import moment from 'moment'

export default async (rows, args) => {
    const {
        mapCols,
        fileName,
        prefix,
    } = {...args}
    const getHeader = () => {
        return mapCols ? Object.values(mapCols).join(',') + "\r\n" : ''
    }
    const getFileName = () => (fileName || ((prefix || 'download-') + moment().format('YYYY-MM-DD-HH:mm'))) + '.csv'

    const blob = new Blob([
        getHeader() +
        rows.reduce((lines, row) => {
            const rowData = Object.keys(mapCols || row).map(col => {
                return row[col]
            })
            lines.push('"' + rowData.join('","') + '"')

            return lines
        }, []).join("\r\n"),
    ], {type: "text/csv"})

    try {
        const fileHandle = await window.showSaveFilePicker({
            suggestedName: getFileName(),
            types: [{
                description: "CSV file",
                accept: {"text/csv": [".csv"]},
            }],
        })
        const fileStream = await fileHandle.createWritable()
        await fileStream.write(blob)
        await fileStream.close()

        return
    } catch (e) {
        if (e.message.match(/The user aborted a request/)) {
            return
        }
    }

    const url = window.URL.createObjectURL(blob)
    const anchor = document.createElement("a")
    anchor.href = url
    anchor.download = getFileName()

    anchor.click()
    window.URL.revokeObjectURL(url)
    anchor.remove()
}