import React, {Component} from 'react'
import PropTypes from 'prop-types'
import WithAuthentication from "../../shared/WithAuthentication"
import getHistory from "../../../store/getHistory"
import { getLocalData } from '../../../services/StorageServices'
import { logoutUser } from '../../../services/AuthServices'

class HomeContainer extends Component {
    componentDidMount() {
        this._setup()
    }

    _setup = () => {
        const permissionsData = !!getLocalData('permissions') ? Object.keys(getLocalData('permissions')) : []
        const history = getHistory()
        if(permissionsData.includes('ffm_order_view')) {
            history.push('/a/orders')
        } else{
            history.push('/a/analytics')
        }
    }

    render() {
        return (
            <div className="HomeContainer">
                <h1>Home</h1>
            </div>
        )
    }
}

HomeContainer.propTypes = {
    isAuthenticated: PropTypes.bool.isRequired,
    user: PropTypes.object.isRequired,
}

export default function (props) {
    return (
        <WithAuthentication>
            {({isAuthenticated, user}) => {
                return (
                    <HomeContainer isAuthenticated={isAuthenticated} user={user} {...props}/>
                )
            }}
        </WithAuthentication>
    )
}