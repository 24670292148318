import React, {Component, Fragment} from 'react'
import {ModalBody, ModalFooter, ModalHeader} from 'reactstrap'
import {getCheckDetail} from '../../../../services/api/ExportOrderServices'
import PropTypes from 'prop-types'
import {ObjectInspector} from 'react-inspector'

class PackageCheckDetailInner extends Component {
    state = {
        resultCheck: {},
        error: '',
        isLoading: false,
    }

    componentDidMount() {
        this._fetchCheckDetail()
    }

    _fetchCheckDetail = async () => {
        const {fulfillment} = this.props
        const {_id: fulfillmentId} = fulfillment
        
        this.setState({
            isLoading: true,
            error: '',
        })

        try {
            const {success, data, message: messageError} = await getCheckDetail(fulfillmentId)

            if (!success) {
                throw new Error(messageError)
            }

            this.setState({
                isLoading: false,
                resultCheck: data,
            })
        } catch (e) {
            this.setState({
                error: e.message || e,
                isLoading: false,
            })
        }
    }

    _handleClickCancel = e => {
        e.preventDefault()
        this.props.onClose()
    }

    render() {
        const {isLoading, resultCheck, error} = this.state
        
        return (
            <Fragment>
                <ModalHeader>
                    <div className="Name"><strong>Information</strong></div>
                </ModalHeader>
                <ModalBody>
                    {
                        !!isLoading && <div className="Loading">Loading...</div>
                    }
                    
                    {
                        !isLoading && JSON.stringify(resultCheck) !== "{}" &&
                        <ObjectInspector depth={0} path="root.value" data={resultCheck}/>
                    }

                    {
                        !!error && <div className="Error text-danger mt-3">{error}</div>
                    }
                </ModalBody>
                <ModalFooter>
                    <div className="w-100 d-flex align-items-center justify-content-between">
                        <button className="btn btn-secondary" onClick={this._handleClickCancel}>
                            Close
                        </button>
                    </div>
                </ModalFooter>
            </Fragment>
        )
    }
}

PackageCheckDetailInner.propTypes = {
    isOpen: PropTypes.bool.isRequired,
    fulfillment: PropTypes.object.isRequired,
    orderId: PropTypes.string.isRequired,
    onClose: PropTypes.func.isRequired,
    onUpdateTotalPushToProduction: PropTypes.func.isRequired,
}

export default PackageCheckDetailInner
