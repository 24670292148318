import React, {useContext} from 'react'
import {message} from 'antd'
import {DropdownItem} from "reactstrap"
import {ScanDetailContext} from './../../../context/ScanDetailContext'
import {sendConfirmSwitchCarrier} from "../../../../../services/api/ShippingServices"

const ActionSend = (props) => {
    const {selectedItems, handleSendLoading, carrier} = props
    const {loadScanDetail} = useContext(ScanDetailContext)

    const _actionSendBulk = async () => {
        handleSendLoading(true)
        try {
            const payload = {
                trackingScanIds: selectedItems,
                carrier
            }
            const {success, message: error} = await sendConfirmSwitchCarrier(payload)

            if (!success) {
                message.error(error)
                return handleSendLoading(false)
            }

            handleSendLoading(false)
            props.toggleSelectAll(false)
            loadScanDetail()

            message.success('Switch carrier success!')
        } catch (e) {
            message.error(e.message)
            return handleSendLoading(false)
        }
    }

    const _showModal = () => {
        _actionSendBulk()
    }

    return (
        <div>
            <DropdownItem onClick={_showModal}>Send</DropdownItem>
        </div>
    )
}

export default ActionSend
