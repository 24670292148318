import React, {useEffect, useState} from 'react'
import { getMappingConditions } from '../../../services/api/OrdersServices'
import MappingConditionForm from '../../mapping-condition/components/MappingConditionForm'
import { getLocalData } from '../../../services/StorageServices'
import { parseJwt } from '../../../helpers/parseToken'
import { Tooltip } from 'antd'

function OrderMappingConditions(prop) {
    const {namespace} = prop

    const [listMappingCondition, setlistMappingCondition] = useState("")
    const [conditionData, setConditionData] = useState("")
    const [error, setError] = useState("")
    const [mappingCondition, setMappingCondition] = useState(null)

    const _fetchMappingConditions = async() => {
        setConditionData("")
        setError("")
        try {
            const {data,success, message} = await getMappingConditions(namespace)
            if(!success){
                setConditionData("No Condition")
                setError(message)
                console.log(error)
            }else{
                setlistMappingCondition(data)
                setConditionData(data.mapping_context)
                updateCurrentItemIndex()
            }
        } catch (error) {
            console.log(error)
        }
    }

    const showForm = (mappingCondition) => (e) => {
        setMappingCondition(mappingCondition)
        updateCurrentItemIndex()
    }

    const onCloseForm = (reload) => {
        showForm(false)()
        setTimeout(() => {
            reload && _fetchMappingConditions()
        }, 100)
    }

    const updateCurrentItemIndex = () => {
        if (!mappingCondition) {
            return
        }

        // const index = listMappingCondition.findIndex(i => i._id === mappingCondition._id)
        // setItemIndex(index)
    }
    useEffect(() => {
        _fetchMappingConditions()
    }, [])

    const permissionsData = getLocalData('permissions') ? getLocalData('permissions') : {}

    return (
        <div className='OrderMappingConditions'>
            <div className='card'>
                <div className="card-header font-weight-bold d-flex justify-content-between">
                    <span>Mapping Conditions</span>
                    <div className="RightActions">
                    <span className="text-primary cursor-pointer float-right">
                        {permissionsData.hasOwnProperty('ffm_order_detail_mapping_conditions') && conditionData!=="No Condition" &&
                            <Tooltip overlayStyle={{ width: '110px'}} overlayInnerStyle={{textAlign: 'center'}} title={`Edit Mapping Conditions`}>
                                <i className="ti-pencil" style={{color:"#007bff"}} onClick={showForm(listMappingCondition)}></i>
                            </Tooltip>
                        }
                    </span>
                    </div>
                </div>
                <div className='card-body'>
                    {conditionData &&
                    <span style={{whiteSpace: "pre-line"}}>{conditionData}</span >
                    }
                </div>

            </div>
            {
                mappingCondition && <MappingConditionForm
                    onClose={onCloseForm}
                    fetchMappingCondition={_fetchMappingConditions}
                    setMappingCondition={setMappingCondition}
                    mappingCondition={mappingCondition}/>
            }
        </div>
    )
}

export default OrderMappingConditions