import React, { useState, useEffect } from 'react'
import { Select } from 'antd'
import VariantTable from './VariantTable'
import { searchProducts } from '../../../../services/api/ProductsServices'
import { getProductAttributes } from "../../../../services/api/ProductsServices"
import { getShippingFee } from '../../../../services/api/ShippingServices'

const ProductlinePage = (props) => {

    const [listProducts, setListProducts] = useState([])
    const [listShippingFee, setListListShippingFee] = useState([])
    const [listAtt, setListAtt] = useState([])

    const {
        first_item_price, handleChangeProductLine,
        additional_item_price, shipping_plan, zone, supplier, store,
        handleChangeVariants, fetchListVariant,
        product_line, newVariant, handlePaste,
        handleChangeFilter, filter, allStore,
    } = props

    const _fetchListProducts = async () => {
        try {
            const { data, success } = await searchProducts({ supplier, limit: 100000 })
            if (!success) return

            setListProducts(data.products)
        } catch (error) {
            console.log(error.message || 'Unknown error')
        }
    }

    const _fetchListShippingFee = async () => {
        const payload = {
            supplier,
            shipping_plan,
            shipping_zone: zone,
            store: allStore ? ['All'] : store,
            limit: 100000
        }
        try {
            const { data, success } = await getShippingFee(payload)
            if (!success) return

            setListListShippingFee(data.shipping_costs)
        } catch (error) {
            console.log(error.message || 'Unknown error')
        }
    }

    useEffect(() => {
        _fetchListProducts()
        _fetchListShippingFee()
    }, [])

    useEffect(() => {

        if (!product_line) return

        const _fetchListAttributes = async () => {

            try {
                const response = await getProductAttributes(product_line)

                const { success, data } = response
                if (!success) return

                setListAtt(data)
            } catch (error) {
                console.log(error.message || 'Unknown error')
            }
        }

        _fetchListAttributes()
    }, [product_line])

    const onChangeProductLine = (value) => {
        handleChangeProductLine(value)
        !!value && !!supplier && fetchListVariant(value, supplier)
    }

    const newProducts = listShippingFee.length ? listProducts.filter(pro => {
        const newShippingFee = listShippingFee.filter(i => i.product_line !== null)
        if (allStore) {
            return !newShippingFee.some(fee => ((!fee.store) && fee.product_line._id === pro._id))
        }
        return !newShippingFee.some(fee => ((fee.store && fee.store.length > 0) && fee.product_line._id === pro._id))
    }) : listProducts.filter(pro => (pro))

    const limitInputValue = ({ floatValue, formattedValue }) => {
        if (floatValue == null) return formattedValue === ''

        return (floatValue >= 0 && floatValue < 1000)
    }

    function _handleChangeFirstPrice(e) {
        const { value } = e.target
        const { handleChangeFirstPrice } = props

        if (typeof handleChangeFirstPrice === 'function') {
            handleChangeFirstPrice(value)
        }
    }

    function _handleChangeAdditional(e) {
        const { value } = e.target
        const { handleChangeAdditional } = props

        if (typeof handleChangeAdditional === 'function') {
            handleChangeAdditional(value)
        }
    }

    const productTitle = listProducts.length > 0 ? listProducts.find(item => item && Object.keys(item).length > 0 && item._id === product_line) : {}

    const { title: newProductTitle = '' } = Object.assign({}, productTitle)

    return (
        <div className="ProductlinePage px-4 py-3">
            <div className="ProductSearch d-flex flex-column">
                <span className="font-weight-bold py-2">Choose one productline</span>
                <Select
                    showSearch
                    placeholder="Search productline"
                    optionFilterProp="children"
                    allowClear
                    value={product_line || []}
                    style={{ maxWidth: 600 }}
                    onChange={onChangeProductLine}
                >
                    {
                        newProducts.length > 0 && newProducts.map((item, index) => (
                            <Select.Option key={index} value={item._id}>{item.title}</Select.Option>
                        ))
                    }
                </Select>
            </div>

            {!!product_line && <div className="ProductVariant">
                <div className="ProductTitle py-4">
                    <span>Productline:</span>
                    <span className="font-weight-bold ml-1">{newProductTitle || ''}</span>
                </div>
                <div className="ProductPrice d-flex align-items-center justify-content-between" style={{ maxWidth: 600 }}>
                    <div className="FirstItem d-flex align-items-center">
                        <span className="font-weight-bold">
                            First item
                        </span>
                        <input type="text" className="form-control" value={(first_item_price !== '' && first_item_price !== null && first_item_price !== undefined) ? first_item_price : ""}
                            onChange={_handleChangeFirstPrice} />
                    </div>
                    <div className="AdditionalItem d-flex align-items-center justify-content-end">
                        <span className="font-weight-bold">
                            Additional item
                        </span>
                        <input type="text" className="form-control" value={(additional_item_price !== '' && additional_item_price !== null && additional_item_price !== undefined) ? additional_item_price : ""}
                            onChange={_handleChangeAdditional} />
                    </div>
                </div>
                {
                    newVariant.length > 0 ?
                        <VariantTable
                            newVariant={newVariant}
                            handleChangeVariants={handleChangeVariants}
                            first_item_price={first_item_price}
                            additional_item_price={additional_item_price}
                            handlePaste={handlePaste}
                            listAtt={listAtt}
                            handleChangeFilter={handleChangeFilter}
                            filter={filter}
                        /> :
                        <div className="py-4">Can not find variant</div>
                }
            </div>}
        </div>
    )
}

export default ProductlinePage