import React, {Component} from 'react'
import ProductLineModalContainer from "../../ProductLineCatalog/ProductLineModalContainer"
import {removeOrderItemMapped} from "../../../../../services/api/ProductsServices"
import OrderItemMapDetails from "./OrderItemMap/OrderItemMapDetails"
import PropTypes from 'prop-types'
import broker from '../../../OrderBroker'

class OrderItemMap extends Component {
    state = {
        productLineVariant: this.props.productLineVariant,
        showModal: false,
        error: "",
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const {_id: prevId = ""} = prevProps.productLineVariant
        const {_id: currId = ""} = this.props.productLineVariant

        if (prevId !== currId) {
            this.setState({
                productLineVariant: this.props.productLineVariant,
            })
        }
    }

    _handleUpdateStateOrderItem = (field, value) => {
        this.setState((prevState) => {
            return {
                ...prevState,
                [field]: value,
            }
        })
    }

    _handleToggleModal = () => {
        this.setState({
            showModal: !this.state.showModal,
        })
    }

    _handleRemoveItemMapped = async () => {
        try {
            const {orderItemId} = this.props
            const {success, message} = await removeOrderItemMapped(orderItemId)

            if (!success) {
                return this.setState({
                    error: message,
                })
            }

            broker.publish("PRODUCT_MAP_UPDATED")
            setTimeout(() => {
                broker.publish("ARTWORK_GET")
            }, 1000)

            return this.setState({
                productLineVariant: {},
                error: "",
            })
        } catch (e) {
            return this.setState({
                error: e.message,
            })
        }
    }

    render() {
        const {showModal, productLineVariant} = this.state
        const {orderItemId, orderItem, storeMode, orderId, artworkType, fulfillmentStatus, orderType, order,
            _fetchOrderItems, artworks, alertItem, _handleAlert, artworksMore, categories, error, namespace, listSuppliers} = this.props
        const isMapped = productLineVariant.hasOwnProperty('product')

        return (
            <td className="OrderItemMap">
                <OrderItemMapDetails
                    order={order}
                    orderId={orderId}
                    productLineVariant={this.props.productLineVariant}
                    isMapped={isMapped}
                    outOfStock={orderItem.outOfStock}
                    orderItemId={orderItemId}
                    mapping_rule={orderItem.mapping_rule}
                    artworkType={artworkType}
                    _handleToggleModal={this._handleToggleModal}
                    _handleRemoveItemMapped={this._handleRemoveItemMapped}
                    fulfillmentStatus={fulfillmentStatus}
                    orderType={orderType}
                    _fetchOrderItems={_fetchOrderItems}
                    listSuppliers={listSuppliers}
                />
                {Object.keys(this.props.productLineVariant).length>0 && alertItem!=='' &&
                    <div className='Text alert alert-danger'> {alertItem}</div>
                }

                <ProductLineModalContainer
                    order={order}
                    orderId={orderId}
                    storeMode={storeMode}
                    showModal={showModal}
                    orderItem={orderItem}
                    orderItemId={orderItemId}
                    artworks={artworks}
                    artworksMore={artworksMore}
                    categories={categories}
                    error={error}
                    _handleToggleModal={this._handleToggleModal}
                    _handleUpdateStateOrderItem={this._handleUpdateStateOrderItem}
                    _handleAlert={_handleAlert}
                    namespace={namespace}
                    orderType={orderType}
                    listSuppliers={listSuppliers}
                />
            </td>
        )
    }
}

OrderItemMap.propTypes = {
    orderId: PropTypes.string,
    orderItemId: PropTypes.string.isRequired,
    productLineVariant: PropTypes.object,
    storeMode: PropTypes.string.isRequired
}

export default OrderItemMap

