import React, {Component} from 'react'
import PropType from 'prop-types'
import StatusStyle from "../../../../shared/StatusStyle"

class OrderFulfillmentStatus extends Component {
    render() {
        const {fulfillmentStatus, packages} = this.props

        return (
            <td className="OrderFulfillmentStatus">
                <StatusStyle status={fulfillmentStatus}/>
                {
                    (packages !== undefined && packages.total > 0 && packages.success > 0) &&
                    <div className="mt-1">
                        <span
                            className="badge badge-secondary">in production: {packages.success}/{packages.total}</span>
                    </div>
                }
            </td>
        )
    }
}

OrderFulfillmentStatus.propTypes = {
    fulfillmentStatus: PropType.string,
    packages: PropType.object
}

export default OrderFulfillmentStatus
