import React from 'react'
import { Tooltip } from 'antd'
import { message } from 'antd'
import { updatePrintFilesStatus } from '../../../../services/api/OrdersServices'

const ChangeOnHoldState = (props) => {
    const { status, onChangeStatus, orderId, fulfillmentId } = props

    const isOnHold = 'hold' === status
    const _handleClick = e => {
        e.preventDefault()

        _reqChangeState()
    }

    const _reqChangeState = async () => {
        try {
            const isOnHold = 'hold' === status
            const slug = isOnHold ? 'retry' : 'hold'

            const { data, success, message } = await updatePrintFilesStatus(fulfillmentId, orderId, slug)

            if (!success) {
                throw new Error(message)
            }
            const { item } = { ...data }
            const { status: newStatus } = { ...item }

            onChangeStatus(newStatus, orderId)
        } catch (e) {
            message.error(e.message, 3)
        }
    }
    return (
        <div className='ChangeOnHoldState Icon'>
            <div onClick={_handleClick}>
                <Tooltip title={isOnHold ? 'Resume' : 'Hold'} placement='bottom'>
                    {
                        isOnHold ? <i className='fa fa-play-circle' /> : <i className='fa fa-pause-circle' />
                    }
                </Tooltip>
            </div>
        </div>
    )
}

export default ChangeOnHoldState