import React, {Component} from 'react'
import {Link} from "react-router-dom"
import PropTypes from 'prop-types'

class ProductTitle extends Component {
    render() {
        const {title, id} = this.props

        return (
            <td className="ProductTitle">
                <Link to={`/a/products/${id}`}>{title}</Link>
            </td>
        )
    }
}

ProductTitle.propTypes = {
    id: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
}

export default ProductTitle
