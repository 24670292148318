import createApiServices from "./createApiServices"
import queryString from 'query-string'

const prefixURL = process.env.NODE_ENV === 'production' ? '/api/shipping' : 'https://fulfillment-staging.merchize.com/api/shipping'
const devURL = window['API_SHIPPING_SERVICE_URL'] || ''
const api = createApiServices({baseUrl: prefixURL})
export const getListShippingPlans = (data) => {
    return api.makeAuthRequest({
        url: "/shipping-plans/search",
        method: "POST",
        data: data,
    })
}

export const getListCarriers = () => {
    return api.makeAuthRequest({
        url: "/list-carriers",
        method: "GET",
    })
}

export const getShippingPlanDetail = (id) => {
    return api.makeAuthRequest({
        url: `/shipping-plans/${id}`,
        method: "GET",
    })
}

export const updateShippingPlan = (id, data) => {
    return api.makeAuthRequest({
        url: `/shipping-plans/${id}`,
        method: "POST",
        data: data,
    })
}

export const deleteShippingPlan = (id) => {
    return api.makeAuthRequest({
        url: `/shipping-plans/${id}`,
        method: "DELETE",
    })
}

export const createShippingPlan = (data) => {
    return api.makeAuthRequest({
        url: "/shipping-plans",
        method: "POST",
        data: data,
    })
}

export const getListShippingZones = () => {
    return api.makeAuthRequest({
        url: "/shipping-zones",
        method: "GET",
    })
}

export const getListCountries = (data) => {
    return api.makeAuthRequest({
        url: "shipping-zones/countries/search",
        method: "POST",
        data: data,
    })
}

export const createShippingZone = (data) => {
    return api.makeAuthRequest({
        url: "/shipping-zones",
        method: "POST",
        data: data,
    })
}

export const getShippingZoneDetail = (id) => {
    return api.makeAuthRequest({
        url: `/shipping-zones/${id}`,
        method: "GET",
    })
}

export const getShippingZoneRates = (id) => {
    return api.makeAuthRequest({
        url: `/shipping-zones/${id}/rates`,
        method: "GET",
    })
}

export const updateShippingZone = (id, data) => {
    return api.makeAuthRequest({
        url: `/shipping-zones/${id}`,
        method: "POST",
        data: data,
    })
}

export const deleteShippingZone = (id) => {
    return api.makeAuthRequest({
        url: `/shipping-zones/${id}`,
        method: "DELETE",
    })
}

export const getShipmentStatus = (filter) => {
    return api.makeAuthRequest({
        url: `/shipment/average`,
        method: "POST",
        data: {
            ...filter,
        },
    })
}

export const getShipmentStatusV2 = (filter) => {
    return api.makeAuthRequest({
        url: `${devURL}/v4/shipment/average`,
        method: "POST",
        data: {
            ...filter,
        },
    })
}

export const addTrackingScan = (data) => {
    return api.makeAuthRequest({
        url: `/switch-carrier/add`,
        method: 'POST',
        data: data
    })
}

export const getListTrackingScan = (filters) => {
    return api.makeAuthRequest({
        url: `/switch-carrier/search?${filters}`,
        method: 'GET'
    })
}

export const sendConfirmSwitchCarrier = (payload) => {
    return api.makeAuthRequest({
        url: `switch-carrier/bulk-confirm`,
        method: 'POST',
        data: payload
    })
}

export const importShippingCost = (data) => {
    return api.makeAuthRequest({
        url: '/shipping-cost/import',
        method: 'POST',
        data: data
    })
}

export const getShippingCost = (supplierId, shippingCarrier) => {
    return api.makeAuthRequest({
        url: `/shipping-cost/${supplierId}/${shippingCarrier}`,
        method: 'GET'
    })
}

export const updateShippingCost = (data) => {
    return api.makeAuthRequest({
        url: '/shipping-cost/update',
        method: 'POST',
        data: data
    })
}

export const getShippingCarriers = (skuPrefix) => {
    return api.makeAuthRequest({
        url: `/shipping-cost/${skuPrefix}/shipping-carriers`,
        method: 'GET'
    })
}

export const addShippingCarriers = (skuPrefix, carrier) => {
    return api.makeAuthRequest({
        url: `/shipping-cost/${skuPrefix}/shipping-carriers`,
        method: 'POST',
        data: carrier
    })
}

export const removeShippingCarriers = (skuPrefix, carrier) => {
    return api.makeAuthRequest({
        url: `/shipping-cost/${skuPrefix}/shipping-carriers/${carrier}`,
        method: 'DELETE'
    })
}

export const createShippingZoneV2 = (data) => {
    return api.makeAuthRequest({
        url: "/v2/shipping-zones",
        method: "POST",
        data: data,
    })
}

export const getShippingZoneDetailV2 = (id) => {
    return api.makeAuthRequest({
        url: `/v2/shipping-zones/${id}`,
        method: "GET",
    })
}

export const updateShippingZoneV2 = (id, data) => {
    return api.makeAuthRequest({
        url: `/v2/shipping-zones/${id}`,
        method: "PUT",
        data: data,
    })
}

export const deleteShippingZoneV2 = (id) => {
    return api.makeAuthRequest({
        url: `/shipping-zones/${id}`,
        method: "DELETE",
    })
}

export const getListShippingZonesV2 = (params) => {
    const query = queryString.stringify(params)
    return api.makeAuthRequest({
        url: `/v2/shipping-zones?${query}`,
        method: "GET",
    })
}

export const getShippingFee = (data) => {
    return api.makeAuthRequest({
        url: `/shipping-cost/search`,
        method: 'POST',
        data
    })
}

export const deleteShippingFee = data => {
    return api.makeAuthRequest({
        url: `/shipping-cost`,
        method: 'DELETE',
        data
    })
}

export const createShippingRate = data => {
    return api.makeAuthRequest({
        url: '/shipping-cost/create',
        method: 'POST',
        data
    })
}

export const getDetailShippingFee = id => {
    return api.makeAuthRequest({
        url: `/seller-shipping-cost/${id}`,
        method: 'GET'
    })
}

export const updateShippingFee = data => {
    return api.makeAuthRequest({
        url: `/shipping-cost`,
        method: 'PUT',
        data
    })
}

// export const createShippingZoneV2 = (data) => {
//     return api.makeAuthRequest({
//         url: "/v2/shipping-zones",
//         method: "POST",
//         data: data,
//     })
// }
//
// export const getShippingZoneDetailV2 = (id) => {
//     return api.makeAuthRequest({
//         url: `/v2/shipping-zones/${id}`,
//         method: "GET",
//     })
// }
//
// export const updateShippingZoneV2 = (id, data) => {
//     return api.makeAuthRequest({
//         url: `/v2/shipping-zones/${id}`,
//         method: "PUT",
//         data: data,
//     })
// }
//
// export const deleteShippingZoneV2 = (id) => {
//     return api.makeAuthRequest({
//         url: `/shipping-zones/${id}`,
//         method: "DELETE",
//     })
// }
//
// export const getListShippingZonesV2 = (params) => {
//     const query = queryString.stringify(params)
//     return api.makeAuthRequest({
//         url: `/v2/shipping-zones?${query}`,
//         method: "GET",
//     })
// }

export const getListShippingCostSupplier = (data, supplierId) => {
    return api.makeAuthRequest({
        url: `/shipping-cost/search/${supplierId}`,
        method: "POST",
        data
    })
}

export const getShipmentStatusByStore = (filter) => {
    return api.makeAuthRequest({
        url: `${devURL}/shipment/average-by-store`,
        method: "POST",
        data: {
            ...filter
        },
    })
}