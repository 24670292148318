import React from 'react'
import { Select } from 'antd'

const { Option } = Select

const optionsData = {
    YC: [
        { label: 'Standard shipping', value: 'Standard shipping' },
        { label: 'Fast shipping', value: 'Fast shipping' },
        { label: 'DHL Express', value: 'DHL Express' },
        { label: 'USPS', value: 'USPS' },
    ],
    DBUS: [
        { label: "Standard shipping UPS", value: "Standard shipping UPS" },
        { label: "Fast shipping USPS", value: "Fast shipping USPS" },
    ],
    DPCN: [
        { label: 'Standard Delivery', value: "Standard Delivery" },
        { label: 'Express Delivery', value: "Express Delivery" },
    ],
    SPUS: [
        { label: 'Standard', value: 'standard' },
        { label: 'Priority', value: 'priority' },
        { label: 'Express', value: 'express' },
    ],
    FSUS: [
        { label: 'First Class', value: 'FirstClass' },
        { label: 'Priority', value: 'Priority' },
        { label: 'Rush Production Tiktok Standard', value: 'Rush Production Tiktok Standard' },
        { label: 'Rush Production Tiktok Priority', value: 'Rush Production Tiktok Priority' },
        { label: 'Tiktok 2 Days', value: 'Tiktok2Days' },
    ],
    PYUS: [
        { label: 'Standard', value: 'standard' },
        { label: 'Priority', value: 'priority' },
        { label: 'Express', value: 'express' },
    ],
    MDUS: [
        { label: 'Standard', value: 'standard' },
        { label: 'USPS Priority Mail', value: 'priority' },
        { label: 'USPS Priority Mail Express', value: 'express' },
    ],
    CC: [
        { label: 'Economy', value: 'standard' },
        { label: '2-Day', value: 'priority' },
        { label: 'Standard Overnight', value: 'express' },
        { label: 'Ground', value: 'ground' },
    ],
    PBAU: [
        { label: 'Standard', value: 'standard' },
        { label: 'Express shipping', value: 'express' },
    ]
}

function SelectShippingMethod(props) {
    const { skuPrefix, ...rest } = { ...props }

    return (
        <Select {...rest}>
            {(!!skuPrefix && !!optionsData[skuPrefix]) &&
                optionsData[skuPrefix].map((i, index) => (
                    <Option value={i.value} key={`${skuPrefix}_${index}`}>{i.label}</Option>
                ))
            }
        </Select>
    )
}

export default SelectShippingMethod