import React, {Component} from 'react'
import {Checkbox} from 'antd'
import PropTypes from 'prop-types'

class WebhookSelection extends Component {
    _detectChecked = () => {
        const {webhook, selectedItems} = this.props

        return selectedItems.includes(webhook._id)
    }

    _handleChange = () => {
        const {onUpdateWebHookSelected, webhook} = this.props
        const isChecked = this._detectChecked()

        onUpdateWebHookSelected(webhook._id, !isChecked)
    }

    render() {
        const isChecked = this._detectChecked()
        const {webhook, permissionsData} = this.props

        return (permissionsData.includes('ffm_webhook_job_update') &&
            <td className='WebhookSelection'>
                <Checkbox checked={isChecked} onChange={this._handleChange} className='px-3' disabled={webhook.is_sent}/>
            </td>
        )
    }
}

WebhookSelection.propTypes = {
    webhook: PropTypes.object.isRequired,
    selectedItems: PropTypes.array.isRequired,
    onUpdateWebHookSelected: PropTypes.func.isRequired,
}

export default WebhookSelection
