import React from 'react'
import OrderSelection from './OrderSelection'
import Marketplace from './Marketplace'
import OrderMockUp from './OrderMockUp'
import OrderDesign from './OrderDesign'
import ItemImage from './ItemImage'
import ItemType from './ItemType'
import ItemStatus from './ItemStatus'
import ItemErrorMessage from './ItemErrorMessage'
import ItemCreated from './ItemCreated'
import ItemNote from './ItemNote'
import OrderActions from './OrderActions'
import ItemImages from './ItemImages'
import ItemImageSafeZone from './ItemImageSafeZone'

const TableRowNew = (props) => {
    const { data, selectedOrders, onUpdateOrdersSelected, reload, onChangeStatus, suppliers, permissionsData, packageNeedPush, listNotePackages, itemsRequest } = props

    const _onUpdateOrdersSelected = (orderId, fulfillmentId, isAdded) => {
        onUpdateOrdersSelected(orderId, fulfillmentId, isAdded)
    }

    return data.length && data.map(item => {
        const { _id, tags, status, fulfillment, order_item, variant_data, design_hood,
            item_number, total_item, brand_name, processed_artworks_pf = {},
            batch, design_front, quantity, note, design_back, design_sleeves, paid_at, mock_up, name, namespace, mapping_supplier, push_at, message: mess,
            barcode_numbers, image, preview_image, safezone_image, safezone_movable, safezone_warning, supplier: supplierId, processing_at, created, production_status
        } = item

        const { size, color, product_type: type, partner_sku } = variant_data
        const { front = '', back = '', sleeves = '', hood = '' } = { ...processed_artworks_pf }
        const previewImage = front || back || sleeves || hood
        const side = front ? 'front' : back ? 'back' : sleeves ? 'sleeves' : 'hood'
        const supplier = (!!supplierId && !!suppliers.find(sup => sup.value === supplierId) && suppliers.find(sup => sup.value === supplierId).label) || ''
        const isRequestUpdateDesign = Array.isArray(tags) && tags.includes('waiting-update-design')

        return (
            image ?
                <tr key={_id} className='OrderItem' style={{ background: isRequestUpdateDesign ? '#fbebd2' : '' }}>
                    {
                        permissionsData.includes('ffm_printing_file_update') &&
                        <OrderSelection
                            orderId={_id}
                            fulfillmentId={fulfillment}
                            selectedOrders={selectedOrders}
                            onUpdateOrdersSelected={_onUpdateOrdersSelected}
                        />
                    }

                    <Marketplace
                        fulfillmentId={fulfillment}
                        namespace={namespace}
                        itemNumber={item_number}
                        name={name}
                        totalItems={total_item}
                        batch={batch}
                        tags={tags}
                        barcodeNumbers={barcode_numbers}
                        fulfillmentItemId={_id}
                        supplier={supplier}
                        packageNeedPush={packageNeedPush}
                        listNotePackages={listNotePackages}
                        reload={reload}
                        paid_at={paid_at}
                    />
                    <OrderMockUp
                        mockUpFront={mock_up}
                        // mockUpBack={mock_up_back}
                        orderId={_id}
                    />
                    <OrderDesign
                        designFront={design_front}
                        designBack={design_back}
                        designSleeves={design_sleeves}
                        designHood={design_hood}
                        orderId={_id}
                        tagFalseDesign={!!tags && tags.includes('waiting-update-design')}
                        reload={reload}
                    />
                    <ItemImageSafeZone
                        type={type}
                        tags={tags}
                        side={side}
                        orderId={_id}
                        status={status}
                        reload={reload}
                        muckUpUrl={mock_up}
                        namespace={namespace}
                        // previewImage={previewImage}
                        fulfillmentId={fulfillment}
                        image={image}
                        previewImage={preview_image}
                        safezoneImage={safezone_image}
                        safezoneMovable={safezone_movable}
                    />
                    <ItemType
                        type={type}
                        supplier={supplier}
                        brandName={brand_name}
                    />
                    <td className='ItemVariant'>
                        {
                            size &&
                            <span className='d-block ItemSize'>
                                {size}
                            </span>
                        }

                        {
                            color &&
                            <span className='d-block ItemColor'>
                                {color}
                            </span>
                        }

                        {
                            quantity &&
                            <span className='d-block ItemQuantity' style={{ color: '#9e9e9e' }}>
                                Qty: {quantity}
                            </span>
                        }
                    </td>
                    {/* <td>{supplier}</td> */}
                    <ItemStatus
                        orderId={_id}
                        status={status}
                        tags={tags}
                        itemNumber={item_number}
                        fulfillmentId={fulfillment}
                        onChangeStatus={onChangeStatus}
                        processingAt={processing_at}
                        itemsRequest={itemsRequest}
                        orderItem={order_item}
                    />
                    <ItemErrorMessage error={mess || safezone_warning} />
                    <ItemCreated pushAt={push_at} paidAt={paid_at} created={created} />
                    <ItemNote orderId={_id} noteContent={note} permissionsData={permissionsData} />

                    {
                        permissionsData.includes('ffm_printing_file_update') &&
                        <td>
                            <OrderActions
                                index={0}
                                type={type}
                                size={size}
                                color={color}
                                partnerSku={partner_sku}
                                tags={tags}
                                name={name}
                                reload={reload}
                                orderItemId={order_item}
                                namespace={namespace}
                                itemNumber={item_number}
                                orderId={_id}
                                status={status}
                                onChangeStatus={onChangeStatus}
                                fulfillmentId={fulfillment}
                                isPushedAt={item.hasOwnProperty('push_at')}
                                designFront={design_front}
                                designBack={design_back}
                                designSleeves={design_sleeves}
                                designHood={design_hood}
                                suppFulfill={mapping_supplier}
                                productionStatus={production_status}
                            />
                        </td>
                    }

                </tr> :
                Object.keys(processed_artworks_pf).length > 1 ?
                    <tr key={_id} className='OrderItem' style={{ background: isRequestUpdateDesign ? '#fbebd2' : '' }}>
                        {
                            permissionsData.includes('ffm_printing_file_update') &&
                            <OrderSelection
                                orderId={_id}
                                fulfillmentId={fulfillment}
                                selectedOrders={selectedOrders}
                                onUpdateOrdersSelected={_onUpdateOrdersSelected}
                            />
                        }

                        <Marketplace
                            fulfillmentId={fulfillment}
                            namespace={namespace}
                            itemNumber={item_number}
                            name={name}
                            totalItems={total_item}
                            batch={batch}
                            tags={tags}
                            barcodeNumbers={barcode_numbers}
                            fulfillmentItemId={_id}
                            supplier={supplier}
                            packageNeedPush={packageNeedPush}
                            listNotePackages={listNotePackages}
                            reload={reload}
                            paid_at={paid_at}
                        />
                        <OrderMockUp
                            mockUpFront={mock_up}
                            // mockUpBack={mock_up_back}
                            orderId={_id}
                        />
                        <OrderDesign
                            designFront={design_front}
                            designBack={design_back}
                            designSleeves={design_sleeves}
                            designHood={design_hood}
                            orderId={_id}
                            tagFalseDesign={!!tags && tags.includes('waiting-update-design')}
                            reload={reload}
                        />
                        <ItemImages
                            type={type}
                            tags={tags}
                            side={side}
                            orderId={_id}
                            status={status}
                            reload={reload}
                            image={image}
                            mockUpUrl={mock_up}
                            namespace={namespace}
                            previewImage={previewImage}
                            fulfillmentId={fulfillment}
                            processed_artworks_pf={processed_artworks_pf}
                        />
                        <ItemType
                            type={type}
                            supplier={supplier}
                            brandName={brand_name}
                        />
                        <td className='ItemVariant'>
                            {
                                size &&
                                <span className='d-block ItemSize'>
                                    {size}
                                </span>
                            }

                            {
                                color &&
                                <span className='d-block ItemColor'>
                                    {color}
                                </span>
                            }

                            {
                                quantity &&
                                <span className='d-block ItemQuantity' style={{ color: '#9e9e9e' }}>
                                    Qty: {quantity}
                                </span>
                            }
                        </td>
                        {/* <td>{supplier}</td> */}
                        <ItemStatus
                            orderId={_id}
                            status={status}
                            tags={tags}
                            itemNumber={item_number}
                            fulfillmentId={fulfillment}
                            onChangeStatus={onChangeStatus}
                            processingAt={processing_at}
                            itemsRequest={itemsRequest}
                            orderItem={order_item}
                        />
                        <ItemErrorMessage error={mess || safezone_warning} />
                        <ItemCreated pushAt={push_at} paidAt={paid_at} created={created} />
                        <ItemNote orderId={_id} noteContent={note} permissionsData={permissionsData} />

                        {
                            permissionsData.includes('ffm_printing_file_update') &&
                            <td>
                                <OrderActions
                                    index={0}
                                    type={type}
                                    size={size}
                                    color={color}
                                    partnerSku={partner_sku}
                                    tags={tags}
                                    name={name}
                                    reload={reload}
                                    orderItemId={order_item}
                                    namespace={namespace}
                                    itemNumber={item_number}
                                    orderId={_id}
                                    status={status}
                                    onChangeStatus={onChangeStatus}
                                    fulfillmentId={fulfillment}
                                    isPushedAt={item.hasOwnProperty('push_at')}
                                    designFront={design_front}
                                    designBack={design_back}
                                    designSleeves={design_sleeves}
                                    designHood={design_hood}
                                    suppFulfill={mapping_supplier}
                                    productionStatus={production_status}
                                />
                            </td>
                        }
                    </tr> :
                    <tr key={_id} className='OrderItem' style={{ background: isRequestUpdateDesign ? '#fbebd2' : '' }}>
                        {
                            permissionsData.includes('ffm_printing_file_update') &&
                            <OrderSelection
                                orderId={_id}
                                fulfillmentId={fulfillment}
                                selectedOrders={selectedOrders}
                                onUpdateOrdersSelected={_onUpdateOrdersSelected}
                            />
                        }

                        <Marketplace
                            fulfillmentId={fulfillment}
                            namespace={namespace}
                            itemNumber={item_number}
                            name={name}
                            totalItems={total_item}
                            batch={batch}
                            tags={tags}
                            barcodeNumbers={barcode_numbers}
                            fulfillmentItemId={_id}
                            supplier={supplier}
                            packageNeedPush={packageNeedPush}
                            listNotePackages={listNotePackages}
                            reload={reload}
                            paid_at={paid_at}
                        />
                        <OrderMockUp
                            mockUpFront={mock_up}
                            // mockUpBack={mock_up_back}
                            orderId={_id}
                        />
                        <OrderDesign
                            designFront={design_front}
                            designBack={design_back}
                            designSleeves={design_sleeves}
                            designHood={design_hood}
                            orderId={_id}
                            tagFalseDesign={!!tags && tags.includes('waiting-update-design')}
                            reload={reload}
                        />
                        <ItemImage
                            type={type}
                            tags={tags}
                            side={side}
                            orderId={_id}
                            status={status}
                            reload={reload}
                            muckUpUrl={mock_up}
                            namespace={namespace}
                            previewImage={previewImage}
                            fulfillmentId={fulfillment}
                        />
                        <ItemType
                            type={type}
                            supplier={supplier}
                            brandName={brand_name}
                        />
                        {/* <ItemSize size={size} />
                        <ItemColor color={color} />
                        <ItemQuantity quantity={quantity} /> */}
                        <td className='ItemVariant'>
                            {
                                size &&
                                <span className='d-block ItemSize'>
                                    {size}
                                </span>
                            }

                            {
                                color &&
                                <span className='d-block ItemColor'>
                                    {color}
                                </span>
                            }

                            {
                                quantity &&
                                <span className='d-block ItemQuantity' style={{ color: '#9e9e9e' }}>
                                    Qty: {quantity}
                                </span>
                            }
                        </td>
                        {/* <td>{supplier}</td> */}
                        <ItemStatus
                            orderId={_id}
                            status={status}
                            tags={tags}
                            itemNumber={item_number}
                            fulfillmentId={fulfillment}
                            onChangeStatus={onChangeStatus}
                            processingAt={processing_at}
                            itemsRequest={itemsRequest}
                            orderItem={order_item}
                        />
                        <ItemErrorMessage error={mess || safezone_warning} />
                        <ItemCreated pushAt={push_at} paidAt={paid_at} created={created} />
                        <ItemNote orderId={_id} noteContent={note} permissionsData={permissionsData} />

                        {
                            permissionsData.includes('ffm_printing_file_update') &&
                            <td>
                                <OrderActions
                                    index={0}
                                    type={type}
                                    size={size}
                                    color={color}
                                    partnerSku={partner_sku}
                                    tags={tags}
                                    name={name}
                                    reload={reload}
                                    orderItemId={order_item}
                                    namespace={namespace}
                                    itemNumber={item_number}
                                    orderId={_id}
                                    status={status}
                                    onChangeStatus={onChangeStatus}
                                    fulfillmentId={fulfillment}
                                    isPushedAt={item.hasOwnProperty('push_at')}
                                    designFront={design_front}
                                    designBack={design_back}
                                    designSleeves={design_sleeves}
                                    designHood={design_hood}
                                    suppFulfill={mapping_supplier}
                                    productionStatus={production_status}
                                />
                            </td>
                        }

                    </tr>
        )
    })
}

export default TableRowNew