import React from 'react'

const TableHead = () => {
    return (
        <thead className="NotificationTableHead thead-light">
        <tr>
            <th className="DiscountName">Discount name</th>
            <th className="Type">Type</th>
            <th className="Value text-right">Value</th>
            <th className="Store">Store</th>
            <th className="DateApply">Apply date</th>
            <th className="Date">Create at</th>
            <th className="Date">Update at</th>
            <th className="Status">Status</th>
            <th className="Actions text-center">Actions</th>
        </tr>
        </thead>
    )
}

export default TableHead
