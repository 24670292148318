import React from 'react'

const TableHead = () => {
    return (
        <thead className="NotificationTableHead thead-light">
            <tr>
                <th className="Store">Store</th>
                <th className="Country">Country</th>
                <th className="OrderTag">Order Tag</th>
                <th className="Title">Title</th>
                <th className="ShippingPlan">Shipping Plan</th>
                <th className="ProductCatalogSku">Product Catalog SKU</th>
                <th className="SupplierApply">Supplier Apply</th>
                <th className="Date">Created - Updated</th>
                <th className="Status">Status</th>
                <th className="Action">Action</th>
            </tr>
        </thead>
    )
}

export default TableHead