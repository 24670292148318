import React from 'react'
import CodAddress from "./CodAddress"
import StandardAddress from "./StandardAddress"
import { Select } from 'antd'

const PackageAddressEditor = (props) => {
    const {previews, supplierSku, shippingCarrier} = props

    const _handleChangeShippingCarrier = (value) => {
        const {onChangeShippingCarrier} = props

        if (typeof onChangeShippingCarrier === 'function') {
            onChangeShippingCarrier(value)
        }
    }

    return (
        <div className="PackageAddressEditor">
            <div className="HeadingPackage">
                <div className="HeadingTitle">Package Information</div>
                <div className="ShippingCarrier">
                    {
                        supplierSku === 'YC' && <>
                            <label>Shipping carrier</label>
                            <Select value={shippingCarrier} onChange={_handleChangeShippingCarrier}>
                                <Select.Option value="Standard shipping">Standard shipping</Select.Option>
                                <Select.Option value="Fast shipping">Fast shipping</Select.Option>
                                <Select.Option value="DHL Express">DHL Express</Select.Option>
                                <Select.Option value="USPS">USPS</Select.Option>
                            </Select>
                        </>
                    }
                </div>
            </div>

            {
                previews.hasOwnProperty('ghtk_info') ?
                    <CodAddress previews={previews} {...props} /> :
                    <StandardAddress previews={previews} {...props} />
            }
        </div>
    )
}

export default PackageAddressEditor
