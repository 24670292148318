import React, {Component} from 'react'
import PropTypes from 'prop-types'
import moment from "moment"
import {Tooltip} from "antd"

class ArtworkImages extends Component {
    state = {
        selectedEmbroiderySurcharge: {}
    }
    _checkDimension = (artworkId) => {
        const {correctDimensions} = this.props

        if (!!correctDimensions && correctDimensions.length > 0) {
            return correctDimensions.filter(item => item.artworkId === artworkId)
        } else {
            return false
        }
    }

    _checkDate = (string) => {
        if (!!string) {
            const arr = string.split('//')
            const newArr = arr[1].split('/')

            const day = moment().diff(moment(newArr[1], 'DD-MM-YYYY').add(2, 'months'), 'days')
            if (!!day && day > 0) {
                return day
            }

            return string
        }
    }

    selectComplexEmbroidery = (artwork, fulfillItem) => (e) => {
        this.props.showEmbroiderySurchargeButton && this.props.selectComplexEmbroidery(artwork, fulfillItem)
    }

    render() {
        const {
            preview,
            side,
            background,
            resize,
            width,
            height,
            artworkID,
            processed_artworks_pf = {},
            embroiderySurcharge,
            selectedComplexEmbroidery,
            fulfillment_item_id,
            fulfillItem,
            originFulfillItem,
            isAllowEmbroiderySurcharge
        } = this.props
        const dimensionValidate = this._checkDimension(artworkID)
        const printFile = (Object.keys(processed_artworks_pf).length && processed_artworks_pf[side === 'sleeve' ? 'sleeves' : side]) || ''
        const days = this._checkDate(printFile)
        const fulfillmentItemId = (originFulfillItem || fulfillItem || {})._id
        const selected = embroiderySurcharge
            && selectedComplexEmbroidery
            && selectedComplexEmbroidery[`${embroiderySurcharge._id}_${fulfillmentItemId}`]
        const imageClasses = ['ImageArt', selected && 'selected']

        return (
            <div className={['ArtworkImages', embroiderySurcharge && 'EmbroiderySurcharge'].filter(Boolean).join(' ')}>
                <div className="mb-2">
                    <a href={preview} target="_blank" rel="noopener noreferrer">{side}</a>
                    {
                        !!days ? (
                            !printFile ? '' :
                                <Tooltip title='View print file'>
                                    <a href={printFile || ''} rel="noopener noreferrer" target='_blank'
                                       className="ml-1">
                                        <i className='ti-printer'/>
                                    </a>
                                </Tooltip>
                        ) : ''
                    }
                </div>

                <div className={imageClasses.join(' ')}>
                    <div className="OriginImage" style={{background: background}}
                         onClick={this.selectComplexEmbroidery(embroiderySurcharge, originFulfillItem || fulfillItem)}>
                        <img
                            style={{background: background}} src={resize}
                            alt="artwork preview"
                            className={`ArtworkItem ${dimensionValidate.length ? 'HasWrong' : ''}`}
                        />
                        {
                            isAllowEmbroiderySurcharge && isAllowEmbroiderySurcharge(originFulfillItem || fulfillItem) &&
                            <span className="CheckComplex"/>
                        }

                    </div>
                    <div className="Hover">
                        <img
                            style={{background: background}} src={resize}
                            alt="artwork preview"
                        />
                    </div>
                </div>

                <div className="Meta">{width}x{height}</div>

                {
                    dimensionValidate !== false && dimensionValidate.length > 0 && <div className="ErrorDimension">
                        {dimensionValidate[0].conditionWidth}x{dimensionValidate[0].conditionHeight}
                    </div>
                }
            </div>
        )
    }
}

ArtworkImages.propTypes = {
    preview: PropTypes.string,
    side: PropTypes.string,
    background: PropTypes.string,
    resize: PropTypes.string,
    correctDimensions: PropTypes.array,
    width: PropTypes.number,
    height: PropTypes.number,
    artworkID: PropTypes.string,
    fulfillment_item_id: PropTypes.string
}

export default ArtworkImages
