import React, {Component} from 'react'
import PropTypes from 'prop-types'
import Dashboard from "./Dashboard"
import WithStoreSettings from "../../shared/WithStoreSettings"
import DashboardHead from "./DashboardHead"
import EnsureLoggedIn from '../../shared/EnsureLoggedIn'

class DashboardContainer extends Component {
    render() {
        return (
            <EnsureLoggedIn>
                <DashboardHead {...this.props}/>
                <Dashboard {...this.props} basePath="/a"/>
            </EnsureLoggedIn>
        )
    }
}

DashboardContainer.propTypes = {
    store: PropTypes.object.isRequired,
}

export default function (props) {
    return (
        <WithStoreSettings>
            {(store) => {
                return (
                    <DashboardContainer {...props} store={store}/>
                )
            }}
        </WithStoreSettings>
    )
}
