import React, { useState } from 'react'
import ChargeRefundHead from './ChargeRefundTable/ChargeRefundHead'
import ChargeRefundRow from './ChargeRefundTable/ChargeRefundRow'

function ChargeRefundTable(props) {
    const { listChargeRefund, loading, reload, onChangeStatus, permissionsData } = props
    const [selectedAll, setSelectedAll] = useState(false)
    const [listSelecteds, setListSelecteds] = useState([])

    const _handleChangeSelectAll = (isActive = false) => {
        if (isActive) {
            const listSelectedId = listChargeRefund.map(item => item._id)
            setListSelecteds(listSelectedId)
            setSelectedAll(true)
        } else {
            setListSelecteds([])
            setSelectedAll(false)
        }
    }

    const _handleSelected = (id, isAdded) => {
        if (isAdded) {
            if (!listSelecteds.includes(id)) {
                setListSelecteds([
                    ...listSelecteds, id,
                ])
                setSelectedAll(listSelecteds.length === listChargeRefund.length)
            }
        } else {
            const filtered = listSelecteds.filter(item => item !== id)

            setListSelecteds(filtered)
            setSelectedAll(false)
        }
    }

    const parseUserData = (value) => {
        const data = value.replace(/\\"/g, '"').trim().replace('"', '').slice(0, -1)
        return !!data ? JSON.parse(data) : {}
    }

    const UserData = parseUserData(localStorage.getItem('com.pdf126.user'))

    return (
        <div className="ChargeRefundTable">
            <div className="wrapTable">
                <table className='table'>
                    <ChargeRefundHead
                        handleChangeSelectAll={_handleChangeSelectAll}
                        selectedAll={selectedAll}
                        listSelecteds={listSelecteds}
                        UserData={UserData}
                        reload={reload}
                        listChargeRefund={listChargeRefund}
                        onChangeStatus={onChangeStatus}
                        permissionsData={permissionsData}
                    />
                    <tbody>
                        {
                            !!listChargeRefund && listChargeRefund.map((item, i) => {
                                return <ChargeRefundRow
                                    key={item._id}
                                    index={i}
                                    item={item}
                                    reload={reload}
                                    UserData={UserData}
                                    listSelecteds={listSelecteds}
                                    handleSelected={_handleSelected}
                                    permissionsData={permissionsData}
                                />
                            })
                        }
                        {
                            !loading && listChargeRefund.length === 0 &&
                            <tr>
                                <td colSpan={9}>
                                    No result
                                </td>
                            </tr>
                        }
                    </tbody>
                </table>
            </div>
        </div>
    )
}

export default ChargeRefundTable