import React, {Component} from 'react'
import PropTypes from 'prop-types'
import Clipboard from "react-clipboard.js"
import {listStoreModes} from "../../../../statics/storeModes"
import formatCurrency from "../../../../helpers/common/formatCurrency"
import imageDefault from "../../../../helpers/common/imageDefault"
import GroupArtworkItem from "./GroupArtworkItem"

class ProductSource extends Component {
    state = {
        copyText: "Copy image url",
        showAll: false,
    }

    _renderStoreMode = (storeModes) => {
        return listStoreModes.find(mode => mode.value === storeModes)
    }

    _handleCopyAddressSuccess = () => {
        this.setState({
            copyText: "Copied!",
        })

        setTimeout(() => {
            this.setState({
                copyText: "Copy image url",
            })
        }, 2000)
    }

    _onChangeShowAll = () => {
        this.setState({
            showAll: !this.state.showAll
        })
    }

    render() {
        const {copyText, showAll} = this.state
        const {orderItem, storeMode, artworksMore, artworks, error} = this.props
        const {product, variant, price, quantity, currency} = orderItem
        const renderStoreMode = storeMode !== undefined && this._renderStoreMode(storeMode)

        return (
            <div className="ProductSource">
                <div className="row">
                    <div className="col-12 mb-2">
                        <div className="position-relative ThumbnailWrapper">
                            <img
                                src={product.preview}
                                onError={(e) => {
                                    e.target.onerror = null;
                                    e.target.src = imageDefault
                                }}
                                alt="Product thumbnail"
                            />
                        </div>
                    </div>

                    <div className="col-12">
                        {/* <Clipboard
                            component="div"
                            data-clipboard-text={product.preview}
                            className="CopyTitleMap cursor-pointer d-inline-block"
                            onSuccess={this._handleCopyAddressSuccess}
                        >
                            <div className="Tooltip3 Right">
                                <i className="far fa-copy"/>

                                <div className="TooltipContent Clipboard">
                                    <div className="ContentInner text-center">
                                        <small className="text-monospace">{copyText}</small>
                                    </div>
                                </div>
                            </div>
                        </Clipboard> */}
                        <a href={product.url} target="_blank" rel="noopener noreferrer"
                           className="mb-2 d-block text-break">{product.title}</a>

                        <div className="d-flex mb-1">
                            <span className="Label font-weight-500">Price:</span>
                            <span className='text-break'>{formatCurrency(currency, price)}</span>
                        </div>

                        <div className="d-flex mb-1">
                            <span className="Label font-weight-500">Variant:</span>
                            <span>{variant.title}</span>
                        </div>

                        <div className="Quantity d-flex align-items-center">
                            <span className="Label font-weight-500">Quantity:</span>
                            <span className="Number">{quantity}</span>
                        </div>

                        <div className="storeMode d-flex align-items-center">
                            <span className="Label font-weight-500">Store mode:</span>
                            <span className="Number">{renderStoreMode.name}</span>
                        </div>
                    </div>

                    <div className="col-12">
                        {
                            !!artworks.length && <div className="listArtwork">
                                {
                                    artworks.map((item, index) => (
                                        <GroupArtworkItem
                                            key={`first_${index}`}
                                            artwork={item}
                                        />
                                    ))
                                }
                            </div>
                        }

                        {
                            !showAll && !!artworksMore && artworksMore.length > 0 ?
                                <div className="ShowAll" onClick={this._onChangeShowAll}>
                                    View more
                                </div> :
                                <>
                                    <div className="listArtwork">
                                        {
                                            showAll && !!artworksMore && artworksMore.length > 0 && artworksMore.map((item, index) => (
                                                <GroupArtworkItem
                                                    key={`first_${index}`}
                                                    artwork={item}
                                                />
                                            ))
                                        }
                                    </div>
                                    {
                                        !!artworksMore && artworksMore.length > 0 &&
                                        <div className="ShowAll" onClick={this._onChangeShowAll}>
                                            Hide
                                        </div>
                                    }
                                </>
                        }

                        {
                            !!error &&
                            <div className="text-danger">{error}</div>
                        }
                    </div>
                </div>
            </div>
        )
    }
}

ProductSource.propTypes = {
    orderItem: PropTypes.object.isRequired,
    orderItemId: PropTypes.string,
    storeMode: PropTypes.string,
    orderId: PropTypes.string
}

export default ProductSource
