import React from 'react'
import DocTitleByStore from "../../dashboard/shared/DocTitleByStore"
import StaticsPage from "./StaticsPage"

function StaticsContainer(props) {
    return (
        <section className="StaticsContainer">
            <DocTitleByStore title="Statics"/>
            <StaticsPage page={props.match}/>
        </section>
    )
}

export default StaticsContainer