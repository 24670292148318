import React, { useState } from 'react'
import { Modal, Input, Progress } from 'antd'
import { addOrderTag } from "../../../../../services/api/OrdersServices"
import { ExclamationCircleOutlined, CheckCircleOutlined } from '@ant-design/icons'

const { TextArea } = Input

const BulkAddTags = ({ open, unShowModal, selectedOrders, orders, onFetchOrders }) => {
    const [orderTags, setOrderTags] = useState('')
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState('')
    const [listProcessing, setListProcessing] = useState(orders.filter(item => selectedOrders.includes(item._id)))
    const [listSuccess, setListSuccess] = useState([])
    const [listError, setListError] = useState([])


    const _onAddTag = async (id, payload) => {
        setLoading(true)
        try {

            const { success, message } = await addOrderTag(id, payload)

            if (!success) {
                return { success: success, message: message }
            }
            return { success: success }

        } catch (e) {
            return { success: false, message: e.message }
        } finally {
            setLoading(false)
        }

    }

    const _handleAddTags = async () => {
        setListSuccess([])
        setListError([])

        let processed = []


        try {
            if (!orderTags || !orderTags.trim()) return setError('Order Tags is required')
            await Promise.all(listProcessing.map(async item => {
                const payload = {
                    title: orderTags
                }
                
                const response = await _onAddTag(item._id, payload)

                const { success, message } = response

                if (!success) return processed.push({ ...item, error: message, processing: false })
                return processed.push({ ...item, success: true, processing: false })

            }))


            let newListSuccess = []
            let newListError = []

            processed.map(process => {
                if (process.success === true) {
                    return newListSuccess.push(process)
                }

                if (process.error.length > 0) {
                    return newListError.push(process)
                }
            })

            setListSuccess(newListSuccess)
            setListError(newListError)
            return setListProcessing(processed)

        } catch (e) {
            return { success: false, message: e.message }
        } finally {
            onFetchOrders()
            setLoading(false)
        }
    }

    const handleChangeTags = e => {
        setError('')
        setLoading(false)
        setOrderTags(e.target.value)
    }

    return (
        <Modal title="Add tags" visible={open} onOk={_handleAddTags} onCancel={unShowModal} okText="Save" confirmLoading={loading} className='addTagsModal'>
            <div className='font-weight-bold mb-2'>Tags:</div>
            <TextArea rows={3} placeholder="Enter tags..." onChange={handleChangeTags} value={orderTags} allowClear />
            {error && <span className='text-danger'>{error}</span>}
            <h6>List processing <span>{selectedOrders.length}</span> items</h6>
            {
                (listSuccess.length > 0 || listError.length > 0) &&
                <div className="Results d-flex align-items-center">
                    <div className="ResultSuccess mr-2">
                        Success: <span className='text-success font-weight-bold'>{listSuccess.length}</span> items
                    </div>
                    <div className="ResultError">
                        Error: <span className='text-danger font-weight-bold'>{listError.length}</span> items
                    </div>
                </div>
            }

            <div className="ListProcessing ListProcessingAddTags">
                <ul>
                    {
                        !!listProcessing && listProcessing.length > 0 && listProcessing.map(item => (
                            <li key={item._id}>
                                <span className='mr-2 font-weight-bold'>{item.id}</span>
                                {
                                    !!item.error &&
                                    <span className="Error text-danger"><ExclamationCircleOutlined /> {item.error}</span>
                                }
                                {
                                    item.success &&
                                    <span className="Success text-success"><CheckCircleOutlined /> Change success</span>
                                }
                                <Progress
                                    percent={100}
                                    size="small"
                                    status={`${(item.processing === false && item.success === true) ? 'success' : ((item.processing === false && item.error.length > 0) ? 'exception' : 'active')}`}
                                    showInfo={false}
                                />
                            </li>
                        )
                        )
                    }
                </ul>
            </div>
        </Modal>
    )
}

export default BulkAddTags