import React, {Component} from 'react'
import PropTypes from 'prop-types'

class OrderCheckbox extends Component {

    _handleChange = () => {
        const {order, onUpdateSelectedOrders} = this.props
        const isChecked = this._detectChecked()

        onUpdateSelectedOrders(order._id, !isChecked, order.id)
    }

    _detectChecked = () => {
        const {order, selectedOrders} = this.props

        return selectedOrders.includes(order._id)
    }

    render() {
        const {order} = this.props
        const isChecked = this._detectChecked()

        return (
            <td className='OrderCheckbox'>
                <div className='custom-control custom-checkbox'>
                    <input type='checkbox' className='custom-control-input' id={`orderCheckbox_${order._id}`}
                           checked={isChecked} onChange={this._handleChange}/>
                    <label htmlFor={`orderCheckbox_${order._id}`} className='custom-control-label'/>
                </div>
            </td>
        )
    }
}

OrderCheckbox.propTypes = {
    order: PropTypes.object.isRequired,
    selectedOrders: PropTypes.array.isRequired,
    onUpdateSelectedOrders: PropTypes.func.isRequired,

}

export default OrderCheckbox
