import React, { useState } from 'react'
import BulkEditPrice from './BulkEditPrice'
import { Popconfirm, Modal, Spin, Select, Tooltip, message, Popover } from 'antd'
import ProductVariantsHistory from './ProductVariantsHistory'
import { updateProductLineStore } from '../../../../services/api/ProductsServices'

function GroupHead(props) {
    const { isShowMoreTable, item, disableAll, onChangePriceStore, confirm, allStore, index, fetchListVariants, fetchStoreVariants } = { ...props }
    const { group } = { ...item }
    const groupedStore = allStore.length > 0 ? allStore.filter(i => i.group === group) : []
    const arrStoreCurent = groupedStore.map(i => i._id)
    const [arrStore, setArrStore] = useState(arrStoreCurent)
    const [loading, setLoading] = useState(false)
    const [visibleModal, setVisibleModal] = useState(false)
    const [isModalOpen, setIsModalOpen] = useState(false)
    const [changes, setChanges] = useState({
        added: [],
        removed: [],
    })
    const [listSuccess, setListSuccess] = useState([])
    const [listError, setListError] = useState([])

    const toggleShowModal = () => {
        setIsModalOpen(!isModalOpen)
    }

    const handleCancel = () => {
        setVisibleModal(false)
        setArrStore(arrStoreCurent)
    }

    const handleShowModal = () => {
        setArrStore(arrStoreCurent)
        setVisibleModal(true)
    }

    const handleChangeSelect = (value) => {
        const added = value.filter((i) => !arrStoreCurent.includes(i));
        const removed = arrStoreCurent.filter((i) => !value.includes(i));
        setArrStore(value)
        setChanges({
            added,
            removed,
        })
    }

    const handleSave = async () => {
        setListSuccess([])
        setListError([])
        let processed = []

        try {
            if (changes.removed.length > 0) {
                await Promise.all(changes.removed.map(async i => {
                    const response = await updateProductLineStore(i, { group: '' })
                    const { success, message } = response
                    if (!success) return processed.push({ id: i, error: message, processing: false })
                    return processed.push({ id: i, success: true, processing: false })
                }))
            }
            if (changes.added.length > 0) {
                await Promise.all(changes.added.map(async i => {
                    const response = await updateProductLineStore(i, { group: group })
                    const { success, message } = response
                    if (!success) return processed.push({ _id: i, error: message, processing: false })
                    return processed.push({ _id: i, success: true, processing: false })
                }))
            }
            let newListSuccess = []
            let newListError = []

            processed.map(process => {
                if (process.success === true) {
                    return newListSuccess.push(process)
                }

                if (process.error.length > 0) {
                    return newListError.push(process)
                }
            })
            if (newListSuccess.length !== (changes.removed.length + changes.added.length)) {
                throw new Error(`Group update error!`)
            }
            message.success(`Group update success!`)
            setListSuccess(newListSuccess)
            setListError(newListError)
            handleCancel()
            fetchListVariants()
            fetchStoreVariants()
        } catch (e) {
            message.error(e.message)
        }
    }

    const storeOptions = allStore.length > 0 ? allStore.filter(i => !i.group || i.group === group).map(i => ({ label: `${i.store}${i.name ? ` (${i.name})` : ''}`, value: i._id })) : []
    const historyStoreOptions = allStore.length > 0 ? allStore.filter(i => i.group === group).map(i => ({ label: `${i.store}${i.name ? ` (${i.name})` : ''}`, value: i._id })) : []

    return (
        <th className={`${isShowMoreTable ? 'table-col-5' : 'table-col-auto'} GroupHead`} key={`${index}_group`}>
            {item.group && <div className='cursor-pointer text-success' onClick={() => handleShowModal()}>
                <Popover content={item.store.join(', ')} title={`Group - ${item.group}`} trigger="hover">
                    {item.group}
                </Popover>
            </div>}
            {!disableAll &&
                <div className="d-flex align-items-center">
                    <BulkEditPrice onChangePrice={onChangePriceStore} idStore={item._id} />
                    <Tooltip placement='top' title='History'>
                        <i className="fa fa-history History cursor-pointer ml-3" onClick={() => toggleShowModal()}></i>
                    </Tooltip>
                    <Popconfirm
                        title={`Are you sure to delete all store of ${item.group}(${item.store.join(', ')})?`}
                        okText="Yes"
                        cancelText="No"
                        onConfirm={() => confirm(item._id)}
                    >
                        <Tooltip placement='top' title='Delete group'>
                            <i className="ti-close mx-3 cursor-pointer" />
                        </Tooltip>
                    </Popconfirm>
                </div>
            }
            <ProductVariantsHistory objectType="store" objectId={item._id[0]} store={item} isOpen={isModalOpen} toggleShowModal={() => toggleShowModal()} group={group} storeOptions={historyStoreOptions} />

            <Modal
                title={`Group - ${group}`}
                visible={visibleModal}
                onCancel={handleCancel}
                onOk={handleSave}
                okText='Save'
                className='StoreGroupModal'
                width={800}
            >
                <Spin spinning={loading} tip="Đang lấy dữ liệu...">
                    <Select
                        mode='multiple'
                        placeholder='Store'
                        options={storeOptions}
                        style={{ width: '100%' }}
                        value={arrStore}
                        onChange={handleChangeSelect}
                    />
                </Spin>
            </Modal>
        </th>
    )
}

export default GroupHead