import React, {Component} from 'react'
import PropTypes from 'prop-types'
import ModalImageRequest from "./request/ModalImageRequest"

class RequestList extends Component {
    state = {
        isOpen: true
    }

    _onToggleContent = () => {
        this.setState({
            isOpen: !this.state.isOpen
        })
    }

    _convertTypeAndSource = (type, source) => {
        const types = [
            {name: 'Design', value: 'update_design'},
            {name: 'Address', value: 'update_address'}
        ]

        const getType = types.find(item => item.value === type)

        return getType.name + ' ' + (source ? source : '')
    }

    render() {
        const {requests} = this.props
        const {isOpen} = this.state

        return (
            <>
                {
                    !!requests && requests.length > 0 && <div className='RequestList'>
                        <div className="HeadingRequest" onClick={this._onToggleContent}>
                            <div className="Title">Request update order</div>
                            {isOpen ? <i className="fas fa-chevron-up"/> : <i className="fas fa-chevron-down"/>}
                        </div>

                        <div className={`ContentRequest ${isOpen ? 'isOpen' : ''}`}>
                            {
                                requests.map((item) => (
                                    <div
                                        className={`ItemRequest ${item.progress_status}`}
                                        key={item._id}>
                                        <div>
                                            <span>
                                                {item.progress_status === 'waiting' ? item.progress_status + ' Update' : item.progress_status}
                                            </span>{' - ' + this._convertTypeAndSource(item.request_type, item.source)} (Request
                                            ID: {item._id})
                                        </div>
                                        <div>{item.content}</div>
                                        <div>Attach file: <ModalImageRequest image={item.image}/></div>
                                    </div>
                                ))
                            }
                        </div>
                    </div>
                }
            </>
        )
    }
}

RequestList.propTypes = {
    requests: PropTypes.array
}

export default RequestList
