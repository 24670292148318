import React, {useContext, useEffect, useState} from 'react'
import PropTypes from 'prop-types'
import moment from "moment"
import {message, Switch, Popconfirm} from "antd"
import {changeStatus, changeConfirmTrusted} from "../../../../services/api/ProductsServices"
import ManageAutoMapContext from "../../context/ManageAutoMapContext"
import MappingRuleDetail from "../popup/MappingRuleDetail"

RuleItem.propTypes = {
    item: PropTypes.object,
    index: PropTypes.number,
    listSelected: PropTypes.array,
    onChangeSelectedRule: PropTypes.func.isRequired
}

function RuleItem(props) {
    const {item, listSelected, onChangeSelectedRule} = props
    const {fetchMappingRules} = useContext(ManageAutoMapContext)
    const [status, setStatus] = useState(item.is_active)
    const [confirmed, setConfirmed] = useState(item.is_trusted)

    const _detectChecked = () => {
        return listSelected.includes(item._id)
    }

    const _handleChange = () => {
        const isChecked = _detectChecked()

        if (typeof onChangeSelectedRule === 'function') {
            onChangeSelectedRule(item._id, !isChecked)
        }
    }

    const _handleChangeStatus = async (checked) => {
        try {
            setStatus(checked)
            const payload = {
                is_active: checked
            }

            const response = await changeStatus(item._id, payload)

            const {success, message: error} = response

            if (!success) {
                return message.error(error)
            }

            message.success('Change status success.')
            fetchMappingRules()
        } catch (e) {
            message.error(e.message)
        }
    }

    const _handleChangeConfirm = async (checked) => {
        try {
            const payload = {
                is_trusted: checked
            }

            const response = await changeConfirmTrusted(item._id, payload)

            const {success, message: error} = response

            if (!success) {
                return message.error(error)
            }else{
                message.success('Confirm trust success.')
                setConfirmed(checked)
            }
            fetchMappingRules()
        } catch (e) {
            message.error(e.message)
        }
    }

    const _onDeleteRule = async (isDelete) => {
        try {
            const payload = {
                is_delete: isDelete
            }

            const response = await changeStatus(item._id, payload)

            const {success, message: error} = response

            if (!success) {
                return message.error(error)
            }

            message.success(`${isDelete ? 'Delete rule success.' : 'Un-delete rule success.'}`)
            fetchMappingRules()
        } catch (e) {
            message.error(e.message)
        }
    }

    useEffect(() => {
        setStatus(item.is_active)
        setConfirmed(item.is_trusted)
    }, [item.is_active,item.is_trusted])

    return (
        <tr className='RuleItem'>
            <td>
                <input type="checkbox" checked={_detectChecked()} onChange={_handleChange}/>
            </td>
            <td>{item.id}</td>
            <td>{item.store}</td>
            <td>{item.zone}</td>
            <td>{item.plf_product_title}</td>
            <td><MappingRuleDetail item={item} onChangeStatus={_handleChangeStatus} onDeleteRule={_onDeleteRule}/></td>
            <td><Switch checked={confirmed} onChange={_handleChangeConfirm}></Switch></td>
            <td>{moment(item.created_at).format('HH:mm DD/MM/YYYY')}</td>
            <td>
                <Switch checked={status} onChange={_handleChangeStatus}/>
            </td>
            <td>
                <Popconfirm
                    title={`Are you sure to ${item.is_deleted ? 'Un-delete' : 'Delete'} this rule?`}
                    onConfirm={() => _onDeleteRule(!item.is_deleted)}
                    okText={item.is_deleted ? 'Un-delete' : 'Delete'}
                    cancelText="Cancel"
                >
                    <span className="text-danger cursor-pointer">{item.is_deleted ? 'Un-delete' : 'Delete'}</span>
                </Popconfirm>
            </td>
        </tr>
    )
}

export default RuleItem
