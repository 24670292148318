import React from 'react'
import SettingHolidayPage from './SettingHolidayPage'
import DocTitleByStore from '../../dashboard/shared/DocTitleByStore'
import '../styles/_index.scss'

function SettingHolidayContainer() {
    return (
        <div className="SettingHolidayContainer">
            <DocTitleByStore title="Setting Holiday" />
            <SettingHolidayPage />
        </div>
    )
}

export default SettingHolidayContainer