import React, {useState, useRef} from 'react'
import {Input} from 'antd'

const MappingConditionFilter = (props) => {
    const {placeholders, placeholder, onchangeFilter, search: searchProps} = props

    const [searchField, setSearchField] = useState(props.searchField.length ? props.searchField[0] : null)
    const [search, setSearch] = useState(searchProps && searchProps.store || '')
    const inputRef = useRef()

    const _onChangeSelectSearchField = e => {
        const {value} = e.target
        inputRef.current.focus()

        setSearch('')
        if (searchProps) delete {...searchProps}
        setSearchField(value)
    }

    const _onChangeInputFilter = value => {
        setSearch(value)
        if (searchField === 'Namespace') return onchangeFilter('store', value)
        if (searchField === 'Mapper') return onchangeFilter('user', value)

        onchangeFilter('mapping_context', value)
    }
    return (
        <div className="TableFilter d-xl-flex justify-content-between align-items-end mb-3">
            <div className="Filter">
                <div className="d-flex flex-wrap align-items-baseline justify-content-start">
                    <div className="FilterWrapper FilterOptions mb-3 mr-3">
                        <div className="d-flex align-items-stretch justify-content-end">
                            <div className="input-group-prepend SelectWrapper">
                                {searchField.length > 0 ? <select
                                        value={searchField}
                                        className="form-control"
                                        onChange={_onChangeSelectSearchField}
                                    >
                                        {props.searchField.map((item, i) => <option key={i} value={item}>{item}</option>)}
                                    </select> :
                                    <span className="input-group-text">
                                        <i className="ti ti-search"/>
                                    </span>}
                            </div>

                            <div className="InputWrapper position-relative">
                                <i className="ti-search position-absolute d-none"/>
                                <Input
                                    value={search || ''}
                                    onChange={(e) => _onChangeInputFilter(e.target.value)}
                                    className="form-control InputSearch"
                                    placeholder={searchField.length ? placeholders[searchField] : placeholder}
                                    ref={inputRef}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default MappingConditionFilter