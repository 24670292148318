import React, { useState } from 'react'
import { Modal, Input, message as Message, Button } from "antd"
import { exportOrderChargeRefund } from "../../../services/api/ExportOrderServices"


const ExportFilter = (props) => {

    const [isModalVisible, setIsModalVisible] = useState(false)
    const [errors, setErrors] = useState({})
    const [linkGG, setLinkGG] = useState("")
    const [sheetName, setSheetName] = useState("")
    const [loading, setLoading] = useState(false)

    const showModal = () => {
        setIsModalVisible(true)
    }

    const handleExport = async () => {
        setLoading(true)
        try {
            const { userSearch, reasonId, createdEnd, createdStart, status, type, orderId } = props
            let err = {}
            const payload = {
                query: { ...userSearch }
            }
            if (type) payload.query.type = type
            if (status) payload.query.status = status
            if (orderId) payload.query.order_name = orderId
            if (createdStart && createdEnd) payload.query.created_at = {
                from: createdStart,
                to: createdEnd,
            }
            if (reasonId) payload.query.reason = reasonId
            if(linkGG) payload.linkGG = linkGG
            if(sheetName) payload.sheetName = sheetName

            const { success, message } = await exportOrderChargeRefund(payload)
            if (!success) {
                err.message = message
            } else {
                setIsModalVisible(false)
                setLinkGG("")
                setSheetName("")
                Message.success('Export successfully!!!')
            }
            setErrors(err)

        } catch (e) {
            Message.error(e.message)
        } finally {
            setLoading(false)
        }
    }

    const handleCancel = () => {
        setLinkGG("")
        setSheetName("")
        setErrors({})
        setIsModalVisible(false)
    }

    return (
        <div className='ExportFilter'>
            <Button type="primary" onClick={showModal}>Export</Button>
            <Modal
                title={"Export Order Charge/Refund"}
                visible={isModalVisible}
                okText={'Export'}
                onOk={handleExport}
                confirmLoading={loading}
                onCancel={handleCancel}
            >
                {errors && <small style={{ color: "#ed4122", display: "block" }}>{errors.message}</small>}
                <div>
                    <label>Link</label>
                    <Input value={linkGG} onChange={e => { setLinkGG(e.target.value) }} allowClear />
                    {errors && <small style={{ color: "#ed4122", display: "block" }}>{errors.linkGG}</small>}
                    <label>Name</label>
                    <Input value={sheetName} onChange={e => { setSheetName(e.target.value) }} allowClear />
                    {errors && <small style={{ color: "#ed4122", display: "block" }}>{errors.sheetName}</small>}
                    <p>
                        Share your Google Sheet to this account:
                    </p>
                    <b>exporter@platform126.iam.gserviceaccount.com</b>
                </div>
            </Modal>
        </div>
    )
}

export default ExportFilter