import React, { useState } from 'react'
import { Button, Dropdown, Menu } from 'antd'
import bulkRetry from '../actions/bulkRetry'
import bulkMarkDone from '../actions/bulkMarkDone'
import bulkMarkItemNotNeedToPush from '../actions/bulkMarkItemNotNeedToPush'
import { DownOutlined } from '@ant-design/icons'

const TableHead = (props) => {
    const { isChecked, reload, onToggleSelectAll, selectedOrders, permissionsData } = props
    const [action, setAction] = useState('')

    const resetAction = () => {
        setAction('')
    }

    const handleMenuClick = ({ key }) => {
        if ('bulkIdsAction1' === key) {
            bulkRetry(selectedOrders, reload, onToggleSelectAll)
        } else if ('bulkIdsAction2' === key) {
            bulkMarkDone(selectedOrders, reload, onToggleSelectAll)
        }else if('bulkIdsAction3' === key){
            bulkMarkItemNotNeedToPush(selectedOrders, reload, onToggleSelectAll)
        }

        setAction(key)
    }

    const handleChange = e => {
        const { checked } = e.target

        onToggleSelectAll(checked)
    }

    const _menu = (
        <Menu onClick={handleMenuClick}>
            <Menu.Item key='bulkIdsAction1'>Retry</Menu.Item>
            <Menu.Item key='bulkIdsAction2'>Mark done</Menu.Item>
            <Menu.Item key='bulkIdsAction3'>Mark item not need to push</Menu.Item>
            {/* <Menu.Divider />
            <Menu.Item key='bulkIdsAction2'>Đánh dấu tạm dừng</Menu.Item>
            <Menu.Item key='bulkIdsAction3'>Đánh dấu tiếp tục</Menu.Item>
            <Menu.Item key='bulkIdsAction7'>Đánh dấu đã xong</Menu.Item>
            <Menu.Divider />
            <Menu.Item key='bulkIdsAction5'><span className='text-danger'>Hủy đơn</span></Menu.Item> */}
        </Menu>
    )

    return (
        <thead className="NotificationTableHead thead-light">
            <tr>
                {
                    permissionsData.includes('ffm_printing_file_update') &&
                    <th>
                        <div className='custom-control custom-checkbox'>
                            <input type='checkbox' id='orderAllSelection' className='custom-control-input' checked={isChecked}
                                onChange={handleChange} />
                            <label htmlFor='orderAllSelection' className='custom-control-label' />
                        </div>
                    </th>
                }
                
                <th className="Template">ID</th>
                <th className="MockUp">Mock up</th>
                <th className="Design">Design</th>
                <th className="Image">Print file</th>
                <th className="Type">Product Type</th>
                {/* <th className="Size">Size</th>
                <th className="Color">Color</th>
                <th className="Quantity">Quantity</th> */}
                <th className="variant">Variant</th>
                {/* <th className="Supplier">Supplier</th> */}
                <th className="Status">Status</th>
                <th className="Error">Error</th>
                <th className="Time">Created at<br/>Paid at<br/>Pushed at</th>
                <th className="Note">Note</th>
                {
                    permissionsData.includes('ffm_printing_file_update') &&
                    <th className="Actions">Action</th>
                }
            </tr>

            {
                selectedOrders.length > 0 &&
                <div className='MenuActions position-absolute'>
                    <Dropdown overlay={_menu} trigger={['click']}>
                        <Button>Actions <DownOutlined /></Button>
                    </Dropdown>
                    <div className='ml-3 font-weight-300'>
                        <span>Bạn đã chọn <strong>{selectedOrders.length}</strong> items.</span>
                    </div>
                </div>
            }
        </thead>
    )
}

export default TableHead