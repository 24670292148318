import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Popover } from 'antd'

const REASON_REJECT = {
    ip_violation: 'Reject by Trademark',
    invalid_artwork: 'Invalid Artwork',
    invalid_address: 'Invalid Address',
    out_of_stock: 'Out of stock',
    customer_changed_mind: 'Customer Changed Mind'
}

class StatusColumn extends Component {
    state = {
        visible: false,
    }

    handleVisibleChange = visible => {
        this.setState({ visible })
    };

    _mapStatus = () => {
        const { status } = this.props
        if (!status) {
            return null
        }

        let output = ""
        switch (status) {
            case "on_hold":
                output = <span className="badge badge-info">{status}</span>
                break
            case "cancelled":
            case "error":
                output = <span className="badge badge-danger">{status}</span>
                break
            case "completed":
            case "pushed":
                output = <span className="badge badge-success">{status}</span>
                break
            case "picked_up":
                output = <span className="badge badge-warning">{status}</span>
                break
            case "pending":
            case "shipping":
                output = <span className="badge badge-info pending-animation">{status}</span>
                break
            case "in_production":
            case "in_transit":
                output = <span className="badge badge-primary processing-animation">{status}</span>
                break
            case "packaged":
            case "delivered":
                output = <span className="badge badge-primary">{status}</span>
                break
            default:
                output = <span className="badge badge-secondary">{status}</span>
        }

        return output
    }

    _mapReasonReject = (reason) => {
        if (!reason) return ''

        return <div style={{ fontSize: 14, fontWeight: 400 }}>{REASON_REJECT[reason] || reason}</div>
    }

    render() {
        const statusHtml = this._mapStatus()
        const { status, orderNote, metaData, isShowReject } = Object.assign({}, this.props)
        const { supplier_reject_reason = '' } = Object.assign({}, metaData)
        let messageError = ''
        if (status === 'error' && metaData && typeof metaData === 'string') {
            messageError = metaData
        } else if (status === 'error' && metaData && typeof metaData === 'object') {
            const { stack, error } = metaData
            if (stack) {
                try {
                    const stackObj = JSON.parse(stack)
                    const { response } = stackObj
                    if (response) {
                        const { message, detail, errors } = response

                        // if(errors.length > 0){
                        if (typeof errors === 'object') {
                            if (errors.length > 0) {
                                const valuesError = Object.values(errors[0])
                                if (typeof valuesError[0] === 'string') {
                                    messageError = valuesError[0]
                                } else if (typeof valuesError[0] === 'object') {
                                    messageError = valuesError[0].errorMessage
                                }
                            } else {
                                messageError = JSON.stringify(errors)
                            }
                        } else if (message) {
                            messageError = message
                        } else if (detail) {
                            messageError = message
                        }
                    }
                } catch {
                    messageError = stack
                }
            }

            if (error) {
                if (typeof error === 'string') {
                    messageError = error
                }
            }
        }

        return (
            <div className="d-flex flex-column">
                <div>
                    <span className="error-box">
                        {statusHtml}
                        {isShowReject && this._mapReasonReject(supplier_reject_reason)}
                        {
                            messageError ? <span className="error-message">{messageError}</span> : ''
                        }
                    </span>
                </div>
                {(orderNote && orderNote.length > 20) ?
                    <Popover
                        content={orderNote}
                        title="Order status note"
                        overlayStyle={{
                            width: "20vw",
                        }}
                        placement="top"
                        trigger="click"
                        visible={this.state.visible}
                        onVisibleChange={this.handleVisibleChange}
                    >
                        <span className="cursor-pointer OrderNote">
                            {`${orderNote.substring(0, 20)}...`}
                        </span>
                    </Popover> :
                    <span className="cursor-pointer OrderNote">
                        {orderNote}
                    </span>
                }
            </div>
        )
    }
}

StatusColumn.propTypes = {
    status: PropTypes.string.isRequired,
}

export default StatusColumn
