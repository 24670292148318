import React, {Component} from 'react'

class ShippingMethodsAdditionalCost extends Component {
    _handleChangeInput = e => {
        const {value} = e.target
        const {index, _handleUpdatePlansState} = this.props

        _handleUpdatePlansState(index, "second_rate", parseFloat(value))
    }

    render() {
        return (
            <td className="ShippingMethodsAdditionalCost">
                <input type="number" step="any" className="form-control" min={0} onChange={this._handleChangeInput} required/>
            </td>
        )
    }
}

export default ShippingMethodsAdditionalCost
