import {getLocalData, setLocalData} from '../../services/StorageServices'

const _prefix = 'FiltersState'

/**
 *
 * @param {string} page
 * @returns {string}
 */
const getFiltersStorageKey = (page) => {
    return `${page}.${_prefix}`
}

/**
 *
 * @param {string} page
 * @param {array=} values
 * @return {Object}
 */
export const getFiltersStorage = (page, values) => {
    const storageKey = getFiltersStorageKey(page)
    const filtersValue = getLocalData(storageKey, {}, false, 'sessionStorage')

    if (!values) {
        return filtersValue
    }

    let result = {}
    for (let i = 0; i < values.length; i++) {
        const valueName = values[i]

        if (filtersValue.hasOwnProperty(valueName)) {
            result[valueName] = filtersValue[valueName]
        }
    }

    return result
}

/**
 * @param {string} page
 * @param {Object} value
 * */
export const setFilterStorage = (page, value) => {
    const storageKey = getFiltersStorageKey(page)
    const filtersValue = getLocalData(storageKey, {}, false, 'sessionStorage')

    setLocalData(storageKey, {...filtersValue, ...value}, false, 'sessionStorage')
}

export const resetFiltersStorage = (page) => {
    const storageKey = getFiltersStorageKey(page)

    setLocalData(storageKey, {}, false, 'sessionStorage')
}
