import React from 'react'
import ApprovedChargeRefund from '../Actions/ApprovedChargeRefund'
import RejectChargeRefund from '../Actions/RejectChargeRefund'
import moment from 'moment'
import { Link } from 'react-router-dom'
import { currency as currencies } from '../../../../statics/currency'
import { Tooltip, Popover } from 'antd'

function ChargeRefundRow(props) {
    const { item, reload, UserData, listSelecteds, handleSelected, permissionsData } = props
    const { currency } = item
    const symbolPrice = currencies.find(item => item.value === currency)
    const adminCheck = UserData.role === 'admin'
    const approveCheck = adminCheck || permissionsData.includes('ffm_charge_and_refund_approve')
    const rejectCheck = adminCheck || permissionsData.includes('ffm_charge_and_refund_reject')

    const _detectChecked = () => {
        return listSelecteds.includes(item._id)
    }

    const _handleChange = () => {
        const isChecked = _detectChecked()

        if (typeof handleSelected === 'function') {
            handleSelected(item._id, !isChecked)
        }
    }

    const formatNote = string => {

        if (!string) return

        if (string.length > 20) {
            return (
                <Popover
                    placement="topLeft"
                    content={string}
                    title={`Order note`}
                    trigger="click"
                >
                    <span className="ShowMore">{string.substring(0, 20) + '...'}</span>
                </Popover>
            )
        } else {
            return <span>{string}</span>
        }
    }

    return (
        <tr>
            {permissionsData.includes('ffm_charge_and_refund_approve') &&
                <td className="CheckBox">
                    <input type="checkbox" checked={_detectChecked()} onChange={_handleChange} />
                </td>
            }
            <td className='Order'>
                <Link to={`/a/orders/${item.order._id}`}>
                    {item.order_number}
                </Link>
                <div>
                    <code className='h6'>{item.store_id || ''}</code>
                </div>
            </td>
            <td className='Type'>
                {item.request_type === 'charge' &&
                    <label className="charge text-capitalize">
                        {item.request_type}
                    </label>
                }
                {item.request_type === 'refund' &&
                    <label className="refund text-capitalize">
                        {item.request_type}
                    </label>
                }
            </td>
            <td className='Value text-right'>
                {item.amount}{symbolPrice.symbol}
            </td>
            <td className='ReasonTag'>
                {
                    !!item.reason.description &&
                    <label className="badge badge-primary">
                        {item.reason.description}
                    </label>
                }
            </td>
            <td className='Reason'>
                {item.reason_for_seller}
            </td>
            <td className='CreatedAt'>
                {moment(item.created_at).format('HH:MM DD/MM/YYYY')}
            </td>
            <td className='User'>
                {item.user_created ? item.user_created.username : '-'}
            </td>
            <td className="Approve_at">
                {item.hasOwnProperty('approved_at') && moment(item.approved_at).format('HH:MM DD/MM/YYYY')}
            </td>
            <td className="Reject_at">
                {item.hasOwnProperty('rejected_at') && moment(item.rejected_at).format('HH:MM D/MM/YYYY')}
            </td>
            <td className='OrderNote'>
                {formatNote(item.order.note)}
            </td>
            <td className='Status'>
                {
                    item.status === 'pending' &&
                    <label className="badge badge-info pending-animation text-capitalize">
                        {item.status}
                    </label>
                }
                {
                    item.status === 'approved' &&
                    <label className="badge badge-success text-capitalize">
                        {item.status}
                    </label>
                }
                {
                    item.status === 'rejected' &&
                    <Tooltip title={'Reason: ' + item.reason_rejected}>
                        <label className="badge badge-danger text-capitalize cursor-pointer">
                            {item.status}
                        </label>
                    </Tooltip>
                }
            </td>
            {
            (permissionsData.includes('ffm_charge_and_refund_approve') || permissionsData.includes('ffm_charge_and_refund_reject')) &&
                <td className='Action'>
                    {
                        item.status === 'pending' &&
                        <div className='d-flex justify-content-start'>
                            {permissionsData.includes('ffm_charge_and_refund_approve') &&
                                <ApprovedChargeRefund id={item._id} reload={reload} status={item.status} adminCheck={approveCheck} />
                            }
                            {permissionsData.includes('ffm_charge_and_refund_reject') &&
                                <RejectChargeRefund id={item._id} reload={reload} status={item.status} adminCheck={rejectCheck} />
                            }
                        </div>
                    }
                </td>
            }
        </tr>
    )
}

export default ChargeRefundRow