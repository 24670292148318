import React from 'react'
import BulkSelectNotification from "./BulkSelectNotification"
import BulkActions from "./BulkActions"

const TableHead = (props) => {
    const {notifications, selectedItems, toggleSelectAll, loadingSend, handleSendLoading} = props

    return (
        <thead className="NotificationTableHead thead-light">
        {loadingSend ? (
            <tr>
                <BulkSelectNotification {...props}/>
                <th colSpan={7} className="position-relative">
                    Sending...
                </th>
            </tr>
        ) : selectedItems.length > 0 ? (
                <tr>
                    <BulkSelectNotification {...props}/>
                    <th colSpan={7} className="position-relative">
                        <BulkActions notifications={notifications} selectedItems={selectedItems} toggleSelectAll={toggleSelectAll} handleSendLoading={handleSendLoading}/>
                    </th>
                </tr>
            ) : (
                <tr>
                    <BulkSelectNotification {...props}/>
                    <th className="storeId">Store_id</th>
                    <th className="title">Title</th>
                    <th className="orderNumber">Order number</th>
                    <th className="confirm">Confirm</th>
                    <th className="image">Image</th>
                    <th className="text-center status">Upload status</th>
                    <th className="status">Send status</th>
                </tr>
            )}
        </thead>
    )
}

export default TableHead
