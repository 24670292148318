import React, {useState} from "react"
import ShippingZoneDelete from "../actions/ShippingZoneDelete"
import ShippingZonesEdit from "../actions/ShippingZonesEdit"
import MappingCountryTag from "./MappingCountryTag"
import MappingStateTag from "./MappingStateTag"

const ShippingZonesRow = (props) => {
    const {zone, fetchListShippingZones, listSupplier, listCountries, permissionsData} = props
    const {title, supplier, country_code, _id, allow_states} = zone
    const [showAllCountry, setShowAllCountry] = useState(false)
    const [showAllState, setShowAllState] = useState(false)
    const newCountry_code = country_code.map((code) => code ? code.title : '')

    const showallonclick = () => {
        setShowAllCountry(!showAllCountry)
    }

    const showAllStateOnClick = () => {
        setShowAllState(!showAllState)
    }

    return (
        <>
            <tr className="ShippingZonesRow">
                <td className="ShippingZoneSupplier">
                    <span>{supplier ? supplier.name : ""}</span>
                </td>
                <td className="ShippingZoneTitle">
                    <span>{title}</span>
                </td>
                <td className="ShippingZoneCountries">
                    <MappingCountryTag showAllCountry={showAllCountry} newCountrycode={newCountry_code}
                                       showallonclick={showallonclick}/>
                </td>
                <td>
                    <MappingStateTag showAllState={showAllState} allowStates={allow_states}
                                     showallonclick={showAllStateOnClick}/>
                </td>
                {permissionsData.includes('ffm_shipping_zone_update')&&
                    <td className="action">
                        <ShippingZonesEdit
                            listSupplier={listSupplier}
                            listCountries={listCountries}
                            id={_id}
                            fetchListShippingZones={fetchListShippingZones}
                        />
                        {/* <ShippingZoneDelete
                ShippingZoneId={_id}
                title={title}
                fetchListShippingZones={fetchListShippingZones}
            /> */}
                    </td>
                }
            </tr>
        </>
    )
}

export default ShippingZonesRow