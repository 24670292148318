import React, {Component} from 'react'
import PropTypes from 'prop-types'

class OrderTagsCreate extends Component {
    state = {
        tag: "",
    }

    _handleChangeInput = e => {
        const {value} = e.target

        this.setState({
            tag: value,
        })
    }

    _handleSubmitForm = async e => {
        e.preventDefault()

        const {_handleCreateOrderTags} = this.props
        const {tag} = this.state


        const isCreated = await _handleCreateOrderTags(tag)

        if (isCreated) {
            this.setState({
                tag: "",
            })
        }
    }

    render() {
        const {tag} = this.state

        return (
            <div className="OrderTagsCreate">
                <form onSubmit={this._handleSubmitForm}>
                    <input type="text" value={tag} className="form-control" placeholder="e.g. tag1, tag2"
                           onChange={this._handleChangeInput}/>
                    {/*<small className="form-text text-muted">Press enter to add new tag</small>*/}
                </form>
            </div>
        )
    }
}

OrderTagsCreate.propTypes = {
    _handleCreateOrderTags: PropTypes.func.isRequired,
}

export default OrderTagsCreate
