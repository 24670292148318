import React from 'react'
import PropTypes from 'prop-types'

ArtworkType.propTypes = {
    artworks: PropTypes.array
}

function ArtworkType(props) {
    const {artworks} = props
    const {level} = artworks[0]
    const mapTypes = [
        {
            value: 'product',
            label: 'Product artworks'
        },
        {
            value: 'order',
            label: 'Order artworks'
        },
        {
            value: 'attribute',
            label: 'Attribute artworks'
        },
        {
            value: 'productvariant',
            label: 'Product variant artworks'
        }
    ]
    const type = mapTypes.find(item => item.value === level)


    return <div className="ArtworkType mb-1 font-weight-500">{type.label}</div>
}

export default ArtworkType
