export const PRODUCTION_STATUS_SUPPLIER = [
    {
        name: 'All',
        value: ''
    },
    {
        name: 'Pending',
        value: 'pending'
    },
    {
        name: 'In production',
        value: 'in_production'
    },
    {
        name: 'Packaged',
        value: 'packaged'
    },
    {
        name: 'Picked up',
        value: 'picked_up'
    },
    {
        name: 'Shipping',
        value: 'shipping'
    },
    {
        name: 'Delivered',
        value: 'delivered'
    },
    {
        name: 'On hold',
        value: 'on_hold'
    },
    {
        name: 'Waiting push',
        value: 'waiting_push'
    },
    {
        name: 'Cancelled',
        value: 'cancelled'
    },
    {
        name: 'Error',
        value: 'error'
    },
]

export const PACKAGE_STATUS = [
    {
        name: 'Waiting push',
        value: 'waiting_push'
    },
    {
        name: 'Pushed',
        value: 'pushed'
    },
    {
        name: 'In production',
        value: 'in_production'
    },
    {
        name: 'Completed',
        value: 'completed'
    },
    {
        name: 'Pre transit',
        value: 'pre_transit'
    },
    {
        name: 'In transit',
        value: 'in_transit'
    },
    {
        name: 'Delivered',
        value: 'delivered'
    },
    {
        name: 'Cancelled',
        value: 'cancelled'
    },
]

export const PUSH_TYPE = [
    {
        name: 'All',
        value: ''
    },
    {
        name: 'API',
        value: 'api'
    },
    {
        name: 'Export',
        value: 'export'
    }
]

export const DELAY_STATUS = [
    {
        name: 'All',
        value: ''
    },
    {
        name: 'Push Delay (From Paid at)',
        value: 'push_delay'
    },
    {
        name: 'Tracking Delay (From Pushed at)',
        value: 'tracking_delay'
    },
    {
        name: 'Production Delay (From Pushed at)',
        value: 'production_delay'
    },
    {
        name: 'Ship Delay (From In_transit at)',
        value: 'ship_delay'
    },
    {
        name: 'In_transit Delay (From Completed at)',
        value: 'in_transit_delay'
    },
    {
        name: 'Ship Delay (From Completed at)',
        value: 'ship_delay_from_completed_at'
    },
    {
        name: 'Production Delay (From Production at)',
        value: 'production_delay_from_in_production_at'
    },
]

export const DELAY_DAYS = [
    {
        name: 'All',
        value: ''
    },
    {
        name: '-6',
        value: '-6'
    },
    {
        name: '-5',
        value: '-5'
    },
    {
        name: '-4',
        value: '-4'
    },
    {
        name: '-3',
        value: '-3'
    },
    {
        name: '-2',
        value: '-2'
    },
    {
        name: '-1',
        value: '-1'
    },
    {
        name: '1',
        value: '1'
    },
    {
        name: '2',
        value: '2'
    },
    {
        name: '3',
        value: '3'
    },
    {
        name: '4',
        value: '4'
    },
    {
        name: '5',
        value: '5'
    },
    {
        name: '6',
        value: '6'
    },
]

export const TRACKING_STATUS = [
    {
        name: 'All',
        value: ''
    },
    {
        name: 'Unknown',
        value: 'unknown'
    },
    {
        name: 'Pre_transit',
        value: 'pre_transit'
    },
    {
        name: 'In_transit',
        value: 'in_transit'
    },
    {
        name: 'Out for delivery',
        value: 'out_for_delivery'
    },
    {
        name: 'Delivered',
        value: 'delivered'
    },
    {
        name: 'Exception trackingmore',
        value: 'exception_trackingmore'
    },
    {
        name: 'Exception easypost',
        value: 'exception_easypost'
    },
    {
        name: 'Exception aftership',
        value: 'exception_aftership'
    },
]

export const SHIPPING_CARRIER = [
    {
        name: 'All',
        value: ''
    },
    {
        name: 'Multrans US',
        value: 'Multrans US'
    },
    {
        name: 'Multrans EU',
        value: 'Multrans EU'
    },
    {
        name: 'Royal mail',
        value: 'Royal Mail'
    },
    {
        name: 'Shippo US',
        value: 'Shippo US'
    },
    {
        name: 'Ib blue',
        value: 'IB Blue'
    },
    {
        name: 'UPS',
        value: 'UPS'
    },
    {
        name: 'UPS UK',
        value: 'UPS UK'
    },
    {
        name: 'DHL eCommerce',
        value: 'DHL eCommerce'
    },
    {
        name: 'DHL Express',
        value: 'DHL Express'
    },
    {
        name: 'USPS',
        value: 'USPS'
    },
    {
        name: 'Tim US',
        value: 'Tim US'
    },
    {
        name: 'Yun Express',
        value: 'Yun Express'
    },
    {
        name: 'Deutsche Post',
        value: 'Deutsche Post'
    },
    {
        name: 'DPDUK',
        value: 'DPDUK'
    },
    {
        name: 'Canada post',
        value: 'Canada post'
    },
    {
        name: 'Singapore Post',
        value: 'Singapore Post'
    },
    {
        name: 'Shopee',
        value: 'Shopee'
    },
    {
        name: 'Giao hàng tiết kiệm',
        value: 'Giao hàng tiết kiệm'
    },
    {
        name: 'Other',
        value: 'Other'
    },
]