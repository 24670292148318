import React from 'react'
import PropTypes from 'prop-types'
import ProductImagesItem from "./ProductImagesItem"

ProductImagesList.propTypes = {
    images: PropTypes.array
}

function ProductImagesList(props) {
    const {images, ...rest} = props

    return (
        <div className='ProductImagesList d-flex flex-wrap'>
            {
                !!images && images.length > 0 && images.map(image => {
                    return <ProductImagesItem {...rest} key={image._id} image={image}/>
                })
            }
        </div>
    )
}

export default ProductImagesList
