import React from 'react'
import {Checkbox} from 'antd'

const BulkSelect = (props) => {
    const {isSelectedAll, selectedItems, loadingSend, statusCarrier, scanDetail} = props

    const _checkDisables = () => {
        const hasSwitchArr = []

        scanDetail.map((item) => {
            if (item.switch_status === true) {
                return hasSwitchArr.push(item)
            } else {
                return null
            }
        })

        if (hasSwitchArr.length === scanDetail.length) {
            return true
        }

        return false
    }

    const _handleChange = () => {
        props.toggleSelectAll(!isSelectedAll)
    }

    const hasDisables = _checkDisables()

    return (
        <th className='BulkSelect position-relative'>
            <div className='d-flex'>
                <div className='custom-control custom-checkbox'>
                    <Checkbox
                        disabled={loadingSend || statusCarrier !== 'done' || hasDisables}
                        indeterminate={!isSelectedAll && selectedItems.length > 0}
                        onChange={_handleChange}
                        checked={props.isSelectedAll}
                    />
                </div>
            </div>
        </th>
    )
}

export default BulkSelect
