import React, {Component} from 'react'
import {getListShippingPlans} from "../../../../../../services/api/ShippingServices"

class ShippingMethodsPlan extends Component {
    state = {
        plans: [],
        error: "",
    }

    componentDidMount() {
        this._fetchListPlan()
    }


    _fetchListPlan = async () => {
        try {
            const {data, success, message} = await getListShippingPlans({term: ""})

            if (!success) {
                this.setState({
                    error: message,
                })

                return false
            }

            this.setState({
                plans: data,
                error: "",
            })
        } catch (e) {
            return this.setState({
                error: e.message,
            })
        }
    }

    _handleChangeSelect = e => {
        const {value} = e.target
        const {index, _handleUpdatePlansState} = this.props

        if ("" === value) {
            return
        }

        _handleUpdatePlansState(index, "plan", value)
    }

    render() {
        const {plans} = this.state

        return (
            <td className="ShippingMethodsPlan">
                <select name="plans" className="form-control" required onChange={this._handleChangeSelect}>
                    <option value="">-- Select plan --</option>
                    {
                        plans.map(plan => {
                            return <option value={plan._id} key={plan._id}>{plan.title}</option>
                        })
                    }
                </select>
            </td>
        )
    }
}

export default ShippingMethodsPlan
