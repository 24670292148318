const _ = require('lodash')

const jsLocalStorage = (rootKey, _def) => {
    let json = _def || {}

    const load = () => {
        const data = localStorage.getItem(rootKey)

        try {
            json = JSON.parse(data || '{}')
        } catch (e) {
            json = {}
        }

        return json
    }

    const get = (key, _def) => {
        return _.get(json, key, _def)
    }
    const set = (key, value) => {
        _.set(json, key, value)
        localStorage.setItem(rootKey, JSON.stringify(json))

        return json
    }
    const merge = (key, value) => {
        const oldValue = get(key, {})
        _.merge(oldValue, value)

        set(key, oldValue)
    }

    load()

    return {
        get,
        set,
        merge
    }
}

export default (key, def) => {
    return new jsLocalStorage(key, def)
}