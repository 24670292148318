import React, { useState, useEffect, useMemo } from 'react'
import { Input, Modal, message, Button, Switch, Select } from 'antd'
import { getVariants, searchProducts } from '../../../../services/api/ProductsServices'

const ModalUpdateCustomizedArtworks = (props) => {
    const {
        visible = false,
        urlDesign,
        variant,
        onSaveEdit,
        onCancel,
        checked,
        onCheck,
        onChangeVariant,
        onChangeSide,
        isEditDesign,
        supplierId,
    } = props

    const { type = '', size = '', color = '', partner_sku = '' } = Object.assign({}, variant)

    const [optionsProductType, setOptionsProductType] = useState([])
    const [dataDroplist, setDataDroplist] = useState([])
    const [visibleDroplist, setVisibleDroplist] = useState(false)
    const [isSave, setIsSave] = useState(false)

    const dataDroplistFilter = useMemo(() => {
        return (size || color || partner_sku) ? dataDroplist.filter(item => {
            const { options = [], partner_sku: sku } = Object.assign({}, item)

            const hasSize = options.some(option => {
                const { attribute, name: value } = Object.assign({}, option)
                const { name = '' } = Object.assign({}, attribute)
                return name.toLowerCase() === "size" && (!!value && value.toLowerCase().includes(size.toLowerCase()))
            })

            const hasColor = options.some(option => {
                const { attribute, name: value } = Object.assign({}, option)
                const { name = '' } = Object.assign({}, attribute)
                return name.toLowerCase() === "color" && (!!value && value.toLowerCase().includes(color.toLowerCase()))
            })

            const hasSku = !!sku && sku.includes(partner_sku)

            return (!size || hasSize) && (!color || hasColor) && (!partner_sku || hasSku)
        }) : dataDroplist
    }, [size, color, dataDroplist, partner_sku])

    const _fetchDataDroplist = async () => {
        try {
            const payload = {
                limit: 1000,
                page: 1,
                query: {
                    supplier: supplierId,
                    variant_status: "1",
                    store_modes: ["normal"]
                }
            }

            if (type) {
                payload.query.exact_product_type = type
            }

            const { data, success } = await getVariants(payload)

            if (!success) return

            const dataDroplist = data.variants.filter(variant => variant.product && variant.product.is_deleted === false)
            setDataDroplist(dataDroplist)
        } catch (error) {
            console.log(error.message || 'Unknown error')
        }
    }

    const _fetchListOptionsProductType = async () => {
        try {
            const { data, success } = await searchProducts({ limit: 10000, supplier: supplierId })
            if (!success) return

            const newListProducts = data.products.map(item => item.type).filter(item => !!item)
            const listProductsFinal = []
            newListProducts.map(item => {
                if (!listProductsFinal.includes(item)) {
                    listProductsFinal.push(item)
                }
            })

            const listOptionsProductType = listProductsFinal.map(item => {
                return {
                    label: item,
                    value: item
                }
            })
            setOptionsProductType(listOptionsProductType)
        } catch (error) {
            console.log(error.message || 'Unknown error')
        }
    }

    const _onClickInput = (e) => {
        setVisibleDroplist(true)
    }

    const _onSelectOption = (item) => {
        if(dataDroplistFilter.length > 0) setIsSave(true)
        const { options = [], partner_sku } = Object.assign({}, item)
        const colorSelect = options.find(i => {
            const { attribute } = Object.assign({}, i)
            const { name } = Object.assign({}, attribute)
            return name && name.toLowerCase() === 'color'
        }) || {}
        const sizeSelect = options.find(i => {
            const { attribute } = Object.assign({}, i)
            const { name } = Object.assign({}, attribute)
            return name && name.toLowerCase() === 'size'
        }) || {}
        const { name: valueColor } = Object.assign({}, colorSelect)
        const { name: valueSize } = Object.assign({}, sizeSelect)

        onChangeVariant({
            size: valueSize,
            color: valueColor,
            partner_sku
        })
    }

    const handleBlurInput = (e) => {
        e.stopPropagation()
        if(dataDroplistFilter.length === 0) return
        setTimeout(() => setVisibleDroplist(false), 300)
    }

    const _renderVariantSupplier = (options) => {
        const nameVariants = options.map(option => option.name)
        return nameVariants.join('/')
    }

    const handleChangeType = (value) => {
        setIsSave(false)
        onChangeVariant({
            type: value,
        })
    }

    const handleChangeInput = (field, value) => {
        setIsSave(false)
        setVisibleDroplist(true)
        onChangeVariant({ [field]: value })
    }

    useEffect(() => {
        checked && _fetchDataDroplist()
    }, [type, checked])

    useEffect(() => {
        checked && _fetchListOptionsProductType()
    }, [checked])

    return (
        <Modal
            visible={visible}
            title="Update customized artworks"
            onCancel={onCancel}
            footer={[
                <Button key="cancel" onClick={onCancel}>
                    Cancel
                </Button>,
                <Button key="ok" type="primary" onClick={onSaveEdit} disabled={!isEditDesign || (checked && !isSave)}>
                    OK
                </Button>,
            ]}
            width={1200}
        >
            <div className='UpdateDesign'>
                <div className="url-design-customized-artworks">
                    <div className="itemSide">
                        <div>Front</div>
                        <Input onChange={e => onChangeSide('front', e.target.value)} value={urlDesign.front} />
                    </div>
                    <div className="itemSide">
                        <div>Back</div>
                        <Input onChange={e => onChangeSide('back', e.target.value)} value={urlDesign.back} />
                    </div>
                    <div className="itemSide">
                        <div>Sleeves</div>
                        <Input onChange={e => onChangeSide('sleeves', e.target.value)} value={urlDesign.sleeves} />
                    </div>
                    <div className="itemSide">
                        <div>Hood</div>
                        <Input onChange={e => onChangeSide('hood', e.target.value)} value={urlDesign.hood} />
                    </div>
                </div>
                <label>Select product line</label>
                <Switch checked={checked} style={{ margin: "15px" }} onChange={onCheck}></Switch>
                <br />
                {
                    checked && (
                        <div className="table-design-customized-artworks">
                            <table className='position-relative'>
                                <thead>
                                    <tr>
                                        <th className="productType" style={{ width: '300px' }}>Type</th>
                                        <th className="sku" style={{ width: '300px' }}>Size</th>
                                        <th className="color" style={{ width: '300px' }}>Color</th>
                                        <th className="sku_partner">Supplier SKU</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td style={{ width: '300px' }}>
                                            <Select
                                                showSearch
                                                optionFilterProp="children"
                                                filterOption={(input, option) => option.label.toLowerCase().includes(input.toLowerCase())}
                                                style={{ width: '100%' }}
                                                onChange={handleChangeType}
                                                options={optionsProductType}
                                                value={type}
                                            />
                                        </td>

                                        <td style={{ width: '300px' }}>
                                            <div className='searchAdvance'>
                                                <div className='searchInput'>
                                                    <input
                                                        name="size"
                                                        className="ant-input"
                                                        // onChange={e => onChangeVariant({ size: e.target.value })}
                                                        onChange={e => handleChangeInput('size', e.target.value)}
                                                        onClick={_onClickInput}
                                                        onBlur={handleBlurInput}
                                                        value={size}
                                                        style={{ width: '100%' }}
                                                    />
                                                </div>
                                            </div>
                                        </td>
                                        <td style={{ width: '300px' }}>
                                            <input
                                                name="color"
                                                className="ant-input"
                                                onChange={e => handleChangeInput('color', e.target.value)}
                                                onClick={_onClickInput}
                                                onBlur={handleBlurInput}
                                                value={color}
                                                style={{ width: '100%' }}
                                            />
                                        </td>
                                        <td style={{ width: '300px' }}>
                                            <input
                                                name="partner_sku"
                                                className="ant-input"
                                                onChange={e => handleChangeInput('partner_sku', e.target.value)}
                                                onClick={_onClickInput}
                                                onBlur={handleBlurInput}
                                                value={partner_sku}
                                                style={{ width: '100%' }}
                                            />
                                        </td>
                                    </tr>
                                </tbody>
                                <div className={`searchDroplist ${visibleDroplist ? 'show' : 'hide'}`}>
                                    <table>
                                        <thead>
                                            <tr>
                                                <th style={{ width: "40%" }}>SKU</th>
                                                <th style={{ width: "20%" }} className='text-center'>Supplier SKU</th>
                                                <th style={{ width: "20%" }} className='text-center'>Variant Supplier</th>
                                                <th style={{ width: "20%" }} className='text-center'>Supplier</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                dataDroplistFilter.length > 0 ?
                                                    dataDroplistFilter.map((item, dropListIndex) => (
                                                        <tr
                                                            key={dropListIndex}
                                                            onClick={() => _onSelectOption(item)}
                                                        >
                                                            <td style={{ width: "40%" }}>{item.sku || ''}</td>
                                                            <td style={{ width: "20%" }} className='text-center'>{item.partner_sku || ''}</td>
                                                            <td style={{ width: "20%" }} className='text-center'>{_renderVariantSupplier(item.options)}</td>
                                                            <td style={{ width: "20%" }} className='text-center'>{item.supplier ? item.supplier.name : ''}</td>
                                                        </tr>
                                                    )) : <tr style={{ textAlign: 'center', fontSize: 16 }}>Not found variants!</tr>
                                            }
                                        </tbody>
                                    </table>
                                </div>
                            </table>
                        </div>
                    )
                }
            </div>
        </Modal>
    )
}

export default ModalUpdateCustomizedArtworks