import React, {Component} from 'react'

class ShippingZoneCountryItem extends Component {

    render() {
        const {countryObj} = this.props
        const {available, country} = countryObj
        const {_id, code, title} = country

        return (
            <option value={_id} data-code={code} disabled={!available}>
                {title}
            </option>
        )
    }
}

export default ShippingZoneCountryItem
