import React, {Component} from 'react'
import {CSVLink} from "react-csv"

class FileTemplate extends Component {
    render() {
        const FileTemplateData = [
            ["store_id", "title", "first_line", "order_number", "confirm", "image", "last_line"],
            ["abcdef", "CONFIRM ORDER", "text", "AB-12345-12345", "text", "link image", "text"],
        ]

        return (
            <div className='FileTemplate'>
                <CSVLink
                    data={FileTemplateData}
                    filename={"notification-template.csv"}
                    className="btn btn-link"
                >
                    Download template
                </CSVLink>
            </div>
        )
    }
}

export default FileTemplate
