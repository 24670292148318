import React, {useState} from 'react'
import PropTypes from 'prop-types'
import {createProductImage} from "../../../../services/api/ProductsServices"

ProductImagesAdd.propTypes = {
    productId: PropTypes.string,
    reloadImages: PropTypes.func.isRequired
}

function ProductImagesAdd(props) {
    const [isUploading, setIsUploading] = useState(false)
    const {productId, reloadImages} = props
    const fileUploader = React.createRef()

    const _handleChangeFile = (e) => {
        const {files} = e.target
        if (files && files.length > 0) {
            return _uploadImage(files[0])
        }
    }

    const _uploadImage = async (file) => {
        try {
            setIsUploading(true)

            const formData = new FormData()
            formData.append('file', file, file.name)

            const {success, message} = await createProductImage(productId, formData)

            if (!success) throw new Error(message)

            await reloadImages()

            setIsUploading(false)
        } catch (e) {
            setIsUploading(false)
            console.log(e)
        }

        fileUploader.current.value = null
    }

    const _handleClickAddImage = () => {
        fileUploader.current.click()
    }
    return (
        <div className='ProductImagesAdder mb-3'>
            <input className='d-none' type='file' ref={fileUploader} onChange={_handleChangeFile}/>
            {
                isUploading ? <span className='text-primary cursor-pointer'>Uploading</span> :
                    <span className='text-primary cursor-pointer' onClick={_handleClickAddImage}>Add image</span>
            }
        </div>
    )
}

export default ProductImagesAdd
