import React, {useEffect, useState} from 'react'
import QuickReportsItem from './QuickReportsItem'
import {Spin} from 'antd'
import {
    CloseOutlined,
} from '@ant-design/icons'
import {getCountFilter, getListQuickReports} from "../../../../services/api/OrdersServices"

function QuickReportsLists(props) {
    const {toggleShow} = props
    const [loading, _setLoading] = useState(false)
    const [error, _setError] = useState('')
    const [commonFilters, _setCommonFilters] = useState([])
    const [totalRows, setTotalRows] = useState([])
    const [personalFilters, _setPersonalFilters] = useState([])

    async function getQuickReports() {
        try {
            _setError('')
            _setLoading(true)
            const params = {
                type: 'package',
                is_hidden: false
            }
            const {data, success, message} = await getListQuickReports(params)
            if(!success) {
                _setLoading(false)
                return _setError(message)
            }
            const {commonFilters, personalFilters} = data
            _setCommonFilters(commonFilters)
            _setPersonalFilters(personalFilters)
            const totalFilters = commonFilters.concat(personalFilters)
            await getTotal(totalFilters)
            _setLoading(false)
        } catch (e) {
            _setLoading(false)
            _setError(e.message)
        }
    }

    async function getTotal(filters) {
        const arrId = filters.map(itemC => itemC._id)
        try {
            const {success, data, message: error} = await getCountFilter({
                listId: arrId,
                type: 'package'
            })

            if (!success) {
                _setLoading(false)
                return _setError(error)
            }

            setTotalRows(data)
        } catch (e) {
            _setLoading(false)
            _setError(e.message)
        }
    }

    useEffect(() => {
        getQuickReports()
    }, [])

    return <div className="QuickReportsLists">
        <div className="QuickReportOverlay" onClick={toggleShow}/>
        <div className="QuickReportsListsWrap">
            <div className="ReportHead d-flex align-items-center justify-content-between">
                <h3>Quick report</h3>
                <div className="iconClose">
                    <CloseOutlined onClick={toggleShow}/>
                </div>
            </div>
            <div className="ReportContent">
                {error && <div className="text-danger p-3">{error}</div> }
                <Spin spinning={loading} tip='Loading...'>
                    <>
                        {commonFilters.length > 0 && commonFilters.map(itemCommon => {
                            return <QuickReportsItem totalRows={totalRows} key={itemCommon._id} data={itemCommon} index={itemCommon._id} canDelete={false} handleGetQuickReports={getQuickReports} toggleShow={toggleShow}/>
                        })}
                        {personalFilters.length > 0 && personalFilters.map(itemPersonal => {
                            return <QuickReportsItem totalRows={totalRows} key={itemPersonal._id} data={itemPersonal} index={itemPersonal._id} canDelete={true} handleGetQuickReports={getQuickReports} toggleShow={toggleShow}/>
                        })}
                    </>
                </Spin>
            </div>
        </div>
    </div>
}

export default QuickReportsLists