import React, {Component} from 'react'
import PropTypes from 'prop-types'
import { getLocalData } from '../../../../services/StorageServices'
import { parseJwt } from '../../../../helpers/parseToken'
import { Tooltip, Popconfirm} from 'antd'

class OrderTagItem extends Component {
    _handleClickRemove = e => {
        const {tag, _handleRemoveOrderTag} = this.props
        _handleRemoveOrderTag(tag)
    }

    render() {
        const {tag} = this.props

        const permissionsData = getLocalData('permissions') ? getLocalData('permissions') : {}

        return (
            <li className="OrderTagItem d-inline-block mr-2 mb-2">
                <span className="btn btn-secondary px-2 py-1">{tag} 
                {
                    permissionsData.hasOwnProperty('ffm_order_detail_update_order_tags') &&
                    <Tooltip title="Remove Tag">
                        <Popconfirm
                            title="Are you sure to remove this tag?"
                            description="Are you sure to remove this tag?"
                            onConfirm={this._handleClickRemove}
                            // onCancel={cancel}
                            okText="Yes"
                            cancelText="No"
                        >
                            <i className="ml-2 fas fa-times cursor-pointer"/>
                        </Popconfirm>
                    </Tooltip>
                }
                </span>
            </li>
        )
    }
}

OrderTagItem.propTypes = {
    tag: PropTypes.string,
    _handleRemoveOrderTag: PropTypes.func.isRequired,
}

export default OrderTagItem
