import React, {Component} from 'react'

class ShippingPlansPagination extends Component {
    render() {
        return (
            <div className="ShippingPlansPagination">

            </div>
        )
    }
}

export default ShippingPlansPagination
