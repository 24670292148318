import React, {Component} from 'react'
import {getListCountries} from "../../../../services/api/ShippingServices"
import ShippingZoneListCountries from "./ShippingZoneListCountries"
import ShippingZoneSelectedCountries from "./ShippingZoneSelectedCountries"

class ShippingZoneNewCountries extends Component {
    state = {
        countries: [],
        selected: [],
        error: "",
    }

    componentDidMount() {
        this._fetchListCountries()
    }

    _fetchListCountries = async () => {
        try {
            const {data, success, message} = await getListCountries({term: ""})

            if (!success) {
                this.setState({
                    error: message,
                })

                return false
            }

            this.setState({
                countries: data,
                error: "",
            })
        } catch (e) {
            this.setState({
                error: e.message,
            })

            return false
        }
    }

    _handleUpdateCountriesState = (countryId, isAdded, isAvailable) => {
        this.setState(({countries}) => {
            const updatedCountries = countries.map(countryObj => {
                const {country} = countryObj

                if (country._id === countryId) {
                    return {
                        ...countryObj,
                        added: isAdded,
                        available: isAvailable,
                    }
                }

                return countryObj
            })

            return {
                countries: updatedCountries,
            }
        }, () => {
            // Update root selected countries
            const {countries} = this.state

            const selectedCountries = countries.filter(countryObj => countryObj.added)
            const arrCountries = selectedCountries.map(countryObj => countryObj.country._id)

            this.props.onUpdateSelectedCountries(arrCountries)
        })
    }

    render() {
        const {countries} = this.state

        const selectedCountries = countries.filter(countryObj => countryObj.added)

        return (
            <div className="ShippingZoneNewCountries">
                <div className="row">
                    <div className="col-md-4">
                        <label className="font-weight-500" htmlFor="shippingZoneCoutries">Countries</label>
                    </div>

                    <div className="col-md-8">
                        <ShippingZoneSelectedCountries selectedCountries={selectedCountries}
                                                       onUpdateCountriesState={this._handleUpdateCountriesState}/>

                        <div className="row">
                            <div className="col-md-6">
                                <ShippingZoneListCountries countries={countries}
                                                           onUpdateCountriesState={this._handleUpdateCountriesState}/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default ShippingZoneNewCountries
