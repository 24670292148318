import React, {Component} from 'react'
import {createShippingPlan} from "../../services/api/ShippingServices"
import getHistory from "../../store/getHistory"

class ShippingPlanNewPage extends Component {
    state = {
        title: "",
        slug: "",
        error: "",
    }

    _handleChangeInput = e => {
        const {name, value} = e.target

        this.setState({
            [name]: value,
        })
    }

    _handleSubmitForm = e => {
        e.preventDefault()

        this._handleCreateShippingPlan()
    }

    _handleCreateShippingPlan = async () => {
        try {
            const {success, message, data} = await createShippingPlan({
                title: this.state.title,
                slug: this.state.slug,
            })

            if (!success) {
                return this.setState({
                    error: message,
                })
            }

            const newPlan = {...data}

            const history = getHistory()
            const location = {
                pathname: `/a/shipping/${newPlan._id}`,
                state: newPlan
            }

            history.push(location)

        } catch (e) {
            return this.setState({
                error: e.message,
            })
        }
    }

    render() {
        const {title, slug} = this.state

        return (
            <div className="ShippingPlanNewPage">
                <div className="SectionInner">
                    <form onSubmit={this._handleSubmitForm}>
                        <div className="form-group">
                            <label>Title</label>
                            <input type="text" name="title" value={title || ""} className="form-control"
                                   onChange={this._handleChangeInput}/>
                        </div>

                        <div className="form-group">
                            <label>Slug</label>
                            <input type="text" name="slug" value={slug || ""} className="form-control"
                                   onChange={this._handleChangeInput}/>
                        </div>

                        <button className="btn btn-primary">Submit</button>
                    </form>
                </div>
            </div>
        )
    }
}

export default ShippingPlanNewPage
