import React, {Component} from 'react'
import ShippingZoneMethodsHead from "./ShippingZoneMethodsHead"
import ShippingZoneMethodsRow from "./ShippingZoneMethodsRow"

class ShippingZoneMethodsTable extends Component {
    render() {
        const {
            listPlans,
            shippingMethods,
            _handleUpdatePlansState,
            _handleRemovePlan,
            onGetSelectedProduct,
            onUpdateSelectedProduct,
            onRemoveSelectedProduct
        } = this.props

        return (
            <div className="tableWrapResponsive">
                <table className="ShippingZoneNewMethodTable table">
                    <ShippingZoneMethodsHead/>

                    <tbody>
                    {
                        shippingMethods.map((method, index) => {
                            return <ShippingZoneMethodsRow
                                key={`shippingMethod_${index}`}
                                listPlans={listPlans}
                                method={method}
                                index={index}
                                inputValue={method.hasOwnProperty('inputValue') && method.inputValue}
                                _handleUpdatePlansState={_handleUpdatePlansState}
                                _handleRemovePlan={_handleRemovePlan}
                                onGetSelectedProduct={onGetSelectedProduct}
                                onUpdateSelectedProduct={onUpdateSelectedProduct}
                                onRemoveSelectedProduct={onRemoveSelectedProduct}
                            />
                        })
                    }
                    </tbody>
                </table>
            </div>
        )
    }
}

export default ShippingZoneMethodsTable