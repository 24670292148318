import React from 'react'
import { Table } from "reactstrap"
import InputTemplate from "../../../order/components/fulfilled/previewAddress/InputTemplate"

const StandardAddress = (props) => {
    const { previews, onChangePreview } = props
    const {
        Marketplace,
        orderNumber,
        name,
        address1,
        address2,
        city,
        state,
        country,
        phone,
        postalCode,
        qship
    } = previews

    return (
        <Table responsive>
            <tr>
                <th>Marketplace</th>
                <th>Order Number</th>
                <th>Name</th>
                <th>Address 1</th>
                <th>Address 2</th>
                <th>City</th>
                <th>State</th>
                <th>Country</th>
                <th>Phone</th>
                <th>Postal Code</th>
                <th>QShip</th>
            </tr>
            <tbody>
                <tr>
                    <InputTemplate
                        type='default'
                        value={Marketplace}
                        placeholder="Marketplace"
                        field="Marketplace"
                        previews={previews}
                        onChangePreview={onChangePreview}
                    />
                    <InputTemplate
                        type='default'
                        value={orderNumber}
                        placeholder="Order Number"
                        field="orderNumber"
                        previews={previews}
                        onChangePreview={onChangePreview}
                    />
                    <InputTemplate
                        type='default'
                        value={name}
                        placeholder="Name"
                        field="name"
                        previews={previews}
                        onChangePreview={onChangePreview}
                    />
                    <InputTemplate
                        type='default'
                        value={address1}
                        placeholder="Address 1"
                        field="address1"
                        previews={previews}
                        onChangePreview={onChangePreview}
                    />
                    <InputTemplate
                        type='default'
                        value={address2}
                        placeholder="Address 2"
                        field="address2"
                        previews={previews}
                        onChangePreview={onChangePreview}
                    />
                    <InputTemplate
                        type='default'
                        value={city}
                        placeholder="City"
                        field="city"
                        previews={previews}
                        onChangePreview={onChangePreview}
                    />
                    <InputTemplate
                        type='default'
                        value={state}
                        placeholder="State"
                        field="state"
                        previews={previews}
                        onChangePreview={onChangePreview}
                    />
                    <InputTemplate
                        type='default'
                        value={country}
                        placeholder="Country"
                        field="country"
                        previews={previews}
                        onChangePreview={onChangePreview}
                    />
                    <InputTemplate
                        type='default'
                        value={phone}
                        placeholder="Phone"
                        field="phone"
                        previews={previews}
                        onChangePreview={onChangePreview}
                    />
                    <InputTemplate
                        type='default'
                        value={postalCode}
                        placeholder="Postal Code"
                        field="postalCode"
                        previews={previews}
                        onChangePreview={onChangePreview}
                    />
                    <InputTemplate
                        type='default'
                        value={qship}
                        placeholder="QShip"
                        field="qship"
                        previews={previews}
                        onChangePreview={onChangePreview}
                    />
                </tr>
            </tbody>
        </Table>
    )
}

export default StandardAddress