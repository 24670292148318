import React, { useEffect, useState } from 'react'
import { Modal, Input, Progress, Radio, AutoComplete, Select } from 'antd'
import { addOrderTag, createChargeRefund, getReasonTags } from "../../../../../services/api/OrdersServices"
import { ExclamationCircleOutlined, CheckCircleOutlined } from '@ant-design/icons'
import RefundChargeValueTableRow from './RefundChargeValueTableRow'
import { getLocalData } from '../../../../../services/StorageServices'

const { TextArea } = Input

const BulkRefundCharge = ({ open, unShowModal, selectedOrders, orders, onFetchOrders }) => {
    const [orderTags, setOrderTags] = useState('')
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState('')
    const [listProcessing, setListProcessing] = useState(orders.filter(item => selectedOrders.includes(item._id)))
    const [listSuccess, setListSuccess] = useState([])
    const [listError, setListError] = useState([])
    const [typeRefund, setTypeRefund] = useState('refund')
    const [tags, setTags] = useState([])
    const [reasonTag, setReasonTag] = useState('')
    const [supporter, setSupporter] = useState('')
    const [reasonForSeller, setReasonForSeller] = useState('')
    const [values, setValues] = useState(orders.filter(item => selectedOrders.includes(item._id)).map(item => { return {id: item._id, orderCode: item.id, value: ''}}))
    const [validate, setValidate] = useState({})

    const _fetchReasonTags = async () => {
        try {
            const {success, data} = await getReasonTags()

            if (!success) return

            setTags(data)
        } catch (e) {
            console.log(e.message)
        }
    }


    const _onRefundChargeItem = async (orderId, payload) => {
        setLoading(true)
        try {

            const { success, message } = await createChargeRefund(typeRefund, orderId, payload)

            if (!success) {
                return { success: success, message: message }
            }
            return { success: success }

        } catch (e) {
            return { success: false, message: e.message }
        } finally {
            setLoading(false)
        }

    }

    const _handleRefundChargeAll = async () => {
        setListSuccess([])
        setListError([])

        let processed = []


        try {
            if (handleValidate()) {
                return
            }
            await Promise.all(listProcessing.map(async item => {
                const user = getLocalData('user')
                const itemValue = values.filter(v => v.id === item._id)
                const payload = {
                    user_id: user.id,
                    refund_amount: typeRefund === 'refund' ? parseFloat(itemValue[0].value) : 0,
                    charge_amount: typeRefund === 'charge' ? parseFloat(itemValue[0].value) : 0,
                    reason_tag: reasonTag,
                    supporter: supporter,
                    charge_percent: 0,
                    refund_percent: 0,
                    reason_for_seller: reasonForSeller,
                    currency: 'USD',
                    title: orderTags
                }
                
                const response = await _onRefundChargeItem(item._id, payload)

                const { success, message } = response

                if (!success) return processed.push({ ...item, error: message, processing: false })
                return processed.push({ ...item, success: true, processing: false })

            }))


            let newListSuccess = []
            let newListError = []

            processed.map(process => {
                if (process.success === true) {
                    return newListSuccess.push(process)
                }

                if (process.error.length > 0) {
                    return newListError.push(process)
                }
            })

            setListSuccess(newListSuccess)
            setListError(newListError)
            return setListProcessing(processed)

        } catch (e) {
            return { success: false, message: e.message }
        } finally {
            onFetchOrders()
            setLoading(false)
        }
    }

    const handleChangeTags = e => {
        setError('')
        setLoading(false)
        setOrderTags(e.target.value)
    }

    const handleChangeType = e => {
        setTypeRefund(e.target.value)
        setReasonTag('')
    }

    const handleChangeTag = (value) => {
        setReasonTag(value)
        setSupporter('')
        const reasonForSeller = tags.filter(tag => tag._id === value)
        setReasonForSeller(reasonForSeller && reasonForSeller.length > 0 ? reasonForSeller[0].description : '')
    }

    const handleChangeReason = (e) => {
        setReasonForSeller(e.target.value)
    }

    const handleChangeSupporter = (e) => {
        setSupporter(e.target.value)
    }

    const handleChangeValues = (item, index) => {
        const newValues = values.map((_item, _index) => {
            if (_index === index) return item
            return _item
        })

        setValues(newValues)
    }

    const handleValidate = () => {
        if(!reasonTag){
            setValidate({vReasonTag: "Reason tag can't be blank"})
            return true
        }

        const idFFM = tags.find(itemF => itemF.name === 'ffm')
        if(idFFM !== undefined && reasonTag === idFFM._id && !supporter.trim()){
            setValidate({vSupporter: "Supporter can't be blank"})

            return true
        }

        if(!reasonForSeller){
            setValidate({vReasonForSeller: "Reason for seller can't be blank"})
            return true
        }

        const errorValues = values.filter(item => item.value === '')
        if(errorValues.length > 0){
            setValidate({vValues: "Values can't be blank"})
            return true
        }
    }

    useEffect(() => {
        _fetchReasonTags()
    }, [])

    const idFFM = tags.find(itemF => itemF.name === 'ffm')
    const reasonTagOptions = !!tags && [
        ...tags.filter(item => item.type === typeRefund).map(item => ({
        // ...tags.map(item => ({
            value: item._id,
            label: item.description,
        }))
    ]

    return (
        <Modal title="Refund/Charge orders" visible={open} onOk={_handleRefundChargeAll} onCancel={unShowModal} okText="Submit" confirmLoading={loading} className='refundChargeModal'>
            <div className="PopupRefundOptions">
                <div className='container'>
                    <div className='row'>
                        <div className='col-md-6'>
                            <div className="d-flex align-items-center justify-content-between">
                                <div className="rowItem d-flex align-items-start mb-3">
                                    <label className="mb-0">Type</label>
                                    <div className="position-relative">
                                        <Radio.Group
                                            // className={classNames("", vType !== '' && 'error')}
                                            onChange={handleChangeType}
                                            defaultValue={typeRefund}
                                            value={typeRefund}
                                        >
                                            <Radio value="refund">Refund</Radio>
                                            <Radio value="charge">Charge</Radio>
                                        </Radio.Group>

                                        {validate.vTypeRefund && validate.vTypeRefund !== '' && <div className="text-danger mt-2">{validate.vTypeRefund}</div>}
                                    </div>
                                </div>
                            </div>
                            <div className="d-flex align-items-start justify-content-between mb-3">
                                <div className="rowItem d-flex align-items-start w-100">
                                    <label className="mb-0 mt-2">Reason tag</label>
                                    <div className="position-relative w-100 ">
                                        {/* <AutoComplete style={{width: "100%"}}
                                                    //   className={classNames("", vRasonTag !== '' && 'error')}
                                                    onChange={(value, e) => handleChangeTag(value, e)}
                                                    defaultValue={reasonTag}
                                                    value={reasonTag}
                                                    options={reasonTagOptions}
                                                    placeholder='Select reason tag'
                                                    //   filterOption={(inputValue, option) => this.handleFilter(inputValue, option)}
                                                    //   onSelect={this.handleSelect}
                                        >
                                        </AutoComplete> */}

                                        <Select
                                            showSearch
                                            allowClear
                                            placeholder="Select reason tag"
                                            optionFilterProp="children"
                                            filterOption={(input, option) => option.label.toLowerCase().includes(input.toLowerCase())}
                                            style={{width: '100%'}}
                                            onChange={value => handleChangeTag(value)}
                                            options={reasonTagOptions}
                                            value={reasonTag || undefined}
                                        />
                                        {validate.vReasonTag && validate.vReasonTag !== '' && <div className="text-danger mt-2">{validate.vReasonTag}</div>}
                                    </div>
                                </div>
                            </div>
                            {
                                (idFFM !== undefined && reasonTag === idFFM._id) &&
                                <div className="d-flex align-items-start justify-content-between mb-3">
                                    <div className="rowItem d-flex align-items-start w-100">
                                        <label className="mb-0 mt-2">Supporter</label>
                                        <div className="position-relative w-100">
                                            <Input 
                                            // className={classNames("form-control", vSupporter !== '' && 'error')}
                                            onChange={handleChangeSupporter}
                                            name="creator" 
                                            value={supporter}/>
                                            {validate.vSupporter && validate.vSupporter !== '' && <div className="text-danger mt-2">{validate.vSupporter}</div>}
                                        </div>
                                    </div>
                                </div>
                            }
                            <div className="rowItem mb-3 pt-1">
                                <label className="d-block">Reason for seller</label>
                                <div className="position-relative">
                                    <TextArea onChange={handleChangeReason}
                                            //   className={classNames("form-control", vReason !== '' && 'error')}
                                            placeholder="Send to seller..."
                                            value={reasonForSeller || ""}/>
                                    {validate.vReasonForSeller && validate.vReasonForSeller !== '' && <div className="text-danger mt-2">{validate.vReasonForSeller}</div>}
                                </div>
                            </div>
                        </div>
                        <div className='col-md-6'>
                            <div className='refundChargeValueTableWrap'>
                                <table className='refundChargeValueTable'>
                                    <thead>
                                        <tr>
                                            <th className='orderCode'>Order</th>
                                            <th>Value</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            !!values && values.map((item, i) => {
                                                return <RefundChargeValueTableRow
                                                    key={i}
                                                    index={i}
                                                    item={item}
                                                    onChangeValues={handleChangeValues}
                                                />
                                            })
                                        }
                                    </tbody>
                                </table>
                            </div>
                            {validate.vValues && validate.vValues !== '' && <div className="text-danger mt-2">{validate.vValues}</div>}
                        </div>
                    </div>
                </div>
                {
                    // typeRefund === "refund" && 
                    <div className='notiModal'>
                        <p className="text-warning">
                            <i className="fas fa-exclamation-triangle mr-2"></i>
                            Using this feature, you will need to go to the Issues management page to mark the related issues as 'refunded'!</p>
                    </div>
                }
            </div>
            {error && <div className="mt-3 text-danger">{error}</div>}

            <h6 className='mt-3'>List processing <span>{selectedOrders.length}</span> items</h6>
            {
                (listSuccess.length > 0 || listError.length > 0) &&
                <div className="Results d-flex align-items-center">
                    <div className="ResultSuccess mr-2">
                        Success: <span className='text-success font-weight-bold'>{listSuccess.length}</span> items
                    </div>
                    <div className="ResultError">
                        Error: <span className='text-danger font-weight-bold'>{listError.length}</span> items
                    </div>
                </div>
            }

            <div className="ListProcessing ListProcessingRefundCharge">
                <ul>
                    {
                        !!listProcessing && listProcessing.length > 0 && listProcessing.map(item => (
                            <li key={item._id}>
                                <span className='mr-2 font-weight-bold'>{item.id}</span>
                                {
                                    !!item.error &&
                                    <span className="Error text-danger"><ExclamationCircleOutlined /> {item.error}</span>
                                }
                                {
                                    item.success &&
                                    <span className="Success text-success"><CheckCircleOutlined /> Change success</span>
                                }
                                <Progress
                                    percent={100}
                                    size="small"
                                    status={`${(item.processing === false && item.success === true) ? 'success' : ((item.processing === false && item.error.length > 0) ? 'exception' : 'active')}`}
                                    showInfo={false}
                                />
                            </li>
                        )
                        )
                    }
                </ul>
            </div>
        </Modal>
    )
}

export default BulkRefundCharge