import React, {Component} from 'react'
import ShippingZoneNewMethodsHead from "./ShippingZoneNewMethodsHead"
import ShippingZoneNewMethodsRow from "./ShippingZoneNewMethodsRow"

class ShippingZoneNewMethodsTable extends Component {
    render() {
        const {shippingMethods, _handleUpdatePlansState, _handleRemovePlan} = this.props
        return (
            <div className="tableWrapResponsive">
                <table className="ShippingZoneNewMethodTable table">
                    <ShippingZoneNewMethodsHead/>

                    <tbody>
                    {
                        shippingMethods.map((method, index) => {
                            return <ShippingZoneNewMethodsRow key={index} method={method} index={index}
                                                              _handleUpdatePlansState={_handleUpdatePlansState}
                                                              _handleRemovePlan={_handleRemovePlan}/>
                        })
                    }
                    </tbody>
                </table>
            </div>
        )
    }
}

export default ShippingZoneNewMethodsTable
