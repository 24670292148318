import React, {Component} from 'react'
import PropTypes from 'prop-types'
import {getAllPlatform} from '../../../../services/api/PlatformsServices'
import FilterOptions from '../../../shared/FilterOptions'

class Platform extends Component {
    state = {
        options: [],
        loading: true,
        error: '',
    }

    componentDidMount() {
        this._fetchListPlatform()
    }

    _fetchListPlatform = async () => {
        try {
            const {data, success, message} = await getAllPlatform({limit: 100})
            if (!success) {
                throw new Error(message)
            }

            const {platforms} = data
            const formatPlatforms = platforms.map(platform => {
                return {
                    value: platform._id,
                    name: platform.name,
                }
            })

            return this.setState({
                options: formatPlatforms,
                loading: false,
                error: '',
            })
        } catch (e) {
            return this.setState({
                error: e.message,
                loading: false,
            })
        }
    }

    render() {
        const {options, loading} = this.state
        const {platform, onChangeFilterState} = this.props
        const selected = loading ? '' : platform

        return (
            <div className="Platform">
                <FilterOptions onChangeOption={onChangeFilterState} selectedKey={selected} field='platform'
                               placeHolder="Select Platform"
                               options={options} layout='select' heading='Platform:' defaultOption={''}
                               allowClear={true}/>
            </div>
        )
    }
}

Platform.propTypes = {
    onChangeFilterState: PropTypes.func.isRequired,
    platform: PropTypes.string.isRequired,
}

export default Platform
