import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { templateRequestUpdateVi, templateRequestUpdateEn } from "../helpers/AutoCompletedText"
import ReactCountryFlag from "react-country-flag"

class AutoCompletedText extends Component {
    state = {
        message: '',
        key: '',
        matchedTexts: []
    }

    componentDidUpdate(prevProps) {
        if (prevProps.content !== this.props.content) {
            this.setState({ message: this.props.content })
        }
    }

    _handleChangeText = (e) => {
        const { value } = e.target

        this.setState({
            key: value,
            message: value
        }, () => {
            const { onChange } = this.props

            this._findTextCompleted(value)

            if (typeof onChange === 'function') {
                onChange(value)
            }
        })
    }

    _findTextCompleted = (key) => {
        const { language } = this.props
        const arrayTemplate = language === 'vi' ? templateRequestUpdateVi : templateRequestUpdateEn
        const regex = new RegExp(key, 'g')
        const matchedTexts = arrayTemplate.filter(text => text.match(regex))

        this.setState({
            matchedTexts: matchedTexts
        })
    }

    _onSelectText = (text) => {
        this.setState({
            key: text,
            message: text
        }, () => {
            const { onChange } = this.props

            this.setState({
                matchedTexts: []
            })

            if (typeof onChange === 'function') {
                onChange(text)
            }
        })
    }

    render() {
        const { message, matchedTexts } = this.state
        const { label, parentClass, namespace, language } = this.props

        return (
            <div className={`AutoCompletedText ${parentClass}`}>
                <label htmlFor={parentClass}>
                    {label}
                    <span className="Language">
                        <span className="NameSpace">{namespace}</span>
                        <span className="Flag">
                            <ReactCountryFlag code={language === 'vi' ? 'VN' : 'GB'} svg />
                        </span>
                    </span>
                </label>
                <textarea
                    value={message}
                    id={parentClass}
                    rows="3"
                    placeholder='Nhập vào 1 đoạn ngắn text liền nhau để tìm nhanh hoặc nhập từ bạn muốn'
                    onChange={this._handleChangeText}
                />

                {
                    matchedTexts && matchedTexts.length > 0 && <div className="Results">
                        <ul>
                            {
                                matchedTexts.map((item, index) => (
                                    <li key={index} onClick={() => this._onSelectText(item)}>{item}</li>
                                ))
                            }
                        </ul>
                    </div>
                }
            </div>
        )
    }
}

AutoCompletedText.propTypes = {
    namespace: PropTypes.string,
    language: PropTypes.string,
    label: PropTypes.string,
    parentClass: PropTypes.string,
    onChange: PropTypes.func.isRequired
}

export default AutoCompletedText
