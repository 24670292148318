import createAPIServices from "./createApiServices"

const baseUrl = process.env.REACT_APP_URL_PLF || 'https://admin-staging.merchize.com'
const api = createAPIServices({baseUrl})

export const getLanguageStore = (storeName) => {
    return api.makeBasicAuthRequest({
        url: `/api/manager/private/stores/${storeName}/preferred-language`,
        method: 'GET'
    })
}
