import React from 'react'
import {DropdownMenu, DropdownToggle, UncontrolledDropdown} from "reactstrap";
import ActionSend from "./bulk-actions/ActionSend"

const BulkActions = (props) => {
    return (
        <UncontrolledDropdown className='MenuActions'>
            <DropdownToggle tag='span' className='ToggleActions'>
                                <span
                                    className='border rounded d-inline-flex px-2 py-1 bg-light font-weight-normal align-items-baseline'>
                                    Actions <i className="fas fa-caret-down ml-2"/>
                                </span>
            </DropdownToggle>

            <DropdownMenu>
                <ActionSend {...props}/>
            </DropdownMenu>
        </UncontrolledDropdown>
    )
}

export default BulkActions
