import React, {Component} from 'react'
import {getListCountries} from "../../../../services/api/ShippingServices"
import ShippingZoneListCountries from "./ShippingZoneListCountries"
import ShippingZoneSelectedCountries from "./ShippingZoneSelectedCountries"

class ShippingZoneCountries extends Component {
    state = {
        countries: null,
        error: "",
    }

    componentDidMount() {
        this._fetchListCountries()

    }

    _fetchListCountries = async () => {
        try {
            const {zoneId, _handleUpdateSelectedCountries} = this.props
            const {data, success, message} = await getListCountries({
                zone: zoneId,
            })

            if (!success) {
                this.setState({
                    error: message,
                })

                return false
            }

            // Init root selected countries
            const selectedCountries = data.filter(countryObj => countryObj.added).map(countryObj => countryObj.country._id)
            _handleUpdateSelectedCountries(selectedCountries)

            this.setState({
                countries: data,
                error: "",
            })
        } catch (e) {
            this.setState({
                error: e.message,
            })

            return false
        }
    }

    _handleUpdateCountriesState = (countryId, isAdded, isAvailable) => {
        this.setState(({countries}) => {
            const updatedCountries = countries.map(countryObj => {
                const {country} = countryObj

                if (country._id === countryId) {
                    return {
                        ...countryObj,
                        added: isAdded,
                        available: isAvailable,
                    }
                }

                return countryObj
            })

            return {
                countries: updatedCountries,
            }
        }, () => {
            // Update root selected countries
            const {countries} = this.state

            const selectedCountries = countries.filter(countryObj => countryObj.added)

            const arrCountries = selectedCountries.map(countryObj => countryObj.country._id)

            this.props._handleUpdateSelectedCountries(arrCountries)
        })
    }

    _handleSelectRemainCountries = () => {
        const {countries} = this.state
        const countriesUpdated = countries.map(countryObj => {
            if (countryObj.available) {
                return {
                    ...countryObj,
                    added: true,
                    available: false,
                }
            }

            return countryObj
        })

        this.setState({
            countries: countriesUpdated,
        }, () => {
            const {countries: _countries} = this.state

            const selectedCountries = _countries.filter(countryObj => countryObj.added)

            const arrCountries = selectedCountries.map(countryObj => countryObj.country._id)

            this.props._handleUpdateSelectedCountries(arrCountries)
        })
    }

    render() {
        const {countries} = this.state

        if (!countries) {
            return null
        }

        const selectedCountries = countries.filter(countryObj => {
            return countryObj.added
        })

        return (
            <div className="ShippingZoneCountries">
                <div className="row">
                    <div className="col-md-4">
                        <label className="font-weight-500" htmlFor="shippingZoneCoutries">Countries</label>
                    </div>

                    <div className="col-md-8">
                        <ShippingZoneSelectedCountries
                            selectedCountries={selectedCountries}
                            onUpdateCountriesState={this._handleUpdateCountriesState}
                        />

                        <div className="row">
                            <div className="col-12">
                                <ShippingZoneListCountries
                                    countries={countries}
                                    onUpdateCountriesState={this._handleUpdateCountriesState}
                                    onSelectRemainCountries={this._handleSelectRemainCountries}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default ShippingZoneCountries