import React from 'react'
import PackageIssueReportPage from './PackageIssueReportPage'
import DocTitleByStore from '../../dashboard/shared/DocTitleByStore'
import '../styles/_index.scss'

const PackageIssueReportContainer = () => {
    return (
        <div className='PackageIssueReportContainer'>
            <DocTitleByStore title="Package Issue Report" />
            <PackageIssueReportPage />
        </div>
    )
}

export default PackageIssueReportContainer