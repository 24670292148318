import React, { useState, useEffect, useRef } from 'react'
import moment from 'moment'

function IssueReferenceNumber(props) {
    const { reference_number, index, pushed_at, details } = { ...props }
    const refReferenceNumber = useRef()
    const newOffsetHeight = refReferenceNumber && refReferenceNumber.current ? refReferenceNumber.current.offsetHeight : undefined
    const [showAllReferenceNumber, setShowAllReferenceNumber] = useState(false)
    const [offsetHeight, setOffsetHeight] = useState(newOffsetHeight)

    const showallonclick = () => {
        setShowAllReferenceNumber(!showAllReferenceNumber)
    }
    useEffect(() => {
        if (refReferenceNumber.current) {
            console.log(`Row ${index} height:`, refReferenceNumber.current.offsetHeight)
            setOffsetHeight(refReferenceNumber.current.offsetHeight)
        }
    }, [details])

    return (
        <div className='IssueReferenceNumber'>
            <div className={showAllReferenceNumber === false ? "HiddenReferenceNumber" : ""}>
                <div className="referenceNumber text-primary" ref={refReferenceNumber}>
                    {typeof reference_number === 'object' && reference_number && reference_number.length > 0 ?
                        reference_number.map(i => (
                            <div key={`${i}`} className='referenceNumberText'>{i}</div>
                        )) :
                        <div className='referenceNumberText'>{reference_number}</div>
                    }
                </div>
            </div>
            <div className="pushedAt">{pushed_at ? moment(pushed_at).format('HH:mm DD/MM/YYYY') : ''}</div>
            {
                !!refReferenceNumber.current ? (
                    (refReferenceNumber.current.offsetHeight > 48 && showAllReferenceNumber === false) ?
                        <a className="float-right mr-3 text-primary">
                            <small className="float-right mr-3" onClick={showallonclick}>Show more</small>
                        </a> :
                        (refReferenceNumber.current.offsetHeight > 48 && showAllReferenceNumber === true) ?
                            <a className="float-right mr-3 text-primary">
                                <small className="float-right mr-3" onClick={showallonclick}>Show less</small>
                            </a> : ""
                ) : ""
            }
        </div>
    )
}

export default IssueReferenceNumber