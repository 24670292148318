import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { Pagination, message } from "antd"
import ShippingFeeFtilter from './ShippingFeeFtilter'
import ShippingFeeTable from './ShippingFeeTable'
import { getShippingFee, deleteShippingFee } from '../../../services/api/ShippingServices'
import UseDebounce from '../helpers/UseDebounce'
import { getLocalData } from '../../../services/StorageServices'


const ShippingFeePage = () => {
    const permissionsData = !!getLocalData('permissions') ? Object.keys(getLocalData('permissions')) : []
    const [listShippingFee, setlistShippingFee] = useState([])
    const [loading, setLoading] = useState(false)
    const [supplier, setSupplier] = useState('')
    const [shippingPlan, setShippingPlan] = useState('')
    const [zone, setZone] = useState('')
    const [page, setPage] = useState(1)
    const [pages, setPages] = useState(1)
    const [limit, setLimit] = useState(20)
    const [total, setTotal] = useState(0)
    const [error, setError] = useState('')
    const [search, setSearch] = useState({})
    const [store, setStore] = useState([])

    const _onchangeFilter = (field, value) => {
        setSearch({
            [field]: value
        })
    }

    const _handleChangeSupplier = (supplier) => {

        setSupplier(supplier)
        setPage(1)
    }

    const _handleChangeShippingPlan = (shipPlan) => {

        setShippingPlan(shipPlan)
        setPage(1)
    }

    const _handleChangeZone = (zone) => {

        setZone(zone)
        setPage(1)
    }

    const _handleChangeStore = (store) => {

        setStore(store)
        setPage(1)
    }

    const _handlePageChange = (page, pageSize) => {
        setLimit(pageSize)
        setPage(pageSize !== limit ? 1 : page)
    }

    const _fetchShippingFee = async () => {

        setLoading(true)
        setError('')
        const payload = {
            supplier,
            page,
            limit,
            store: "",
            shipping_plan: shippingPlan,
            shipping_zone: zone,
            hide_variants: false,
        }
        if (store && store.length > 0) payload.store = store

        try {
            const res = await getShippingFee({ ...payload, ...search })
            const { data, success } = res
            const { pages, total, shipping_costs } = data
            if (success) {
                setlistShippingFee(shipping_costs)
                setPages(pages)
                setTotal(total)
            }

            setLoading(false)
        } catch (error) {
            setError(error.message || 'Unknown error.')
        } finally {
            setLoading(false)
        }

    }

    const _handleDelete = async (supplier, shipping_plan, shipping_zone, store, product_line) => {
        setLoading(true)

        try {
            const payload = {
                supplier, shipping_plan, shipping_zone, store, product_line
            }

            const res = await deleteShippingFee(payload)

            const { message: mess, success } = res
            if (!success) return message.error(`${mess}!`)
            message.success('Deleted successfully!')
            _fetchShippingFee()
        } catch (e) {
            message.error(e.message || 'Unknown error.')
        } finally {
            setLoading(false)
        }
    }

    const debounceSearch = UseDebounce(search, 500)

    useEffect(() => {
        _fetchShippingFee()
    }, [page, debounceSearch, supplier, shippingPlan, zone, limit, store])

    if (error) return <i className='text-danger fs-14'>{error}</i>

    return (
        <div className="ShippingFeePage">
            <div className="d-flex HeaderPage justify-content-between">
                <h1 className="PageTitle mt-2 mb-2">Shipping Fee</h1>
                {permissionsData.includes('ffm_shipping_fee_create') &&
                    <div className='Create-shippingFee d-flex align-items-center'>
                        <div className='row justify-content-end'>
                            <div className='col-auto ml-3 ml-sm-0 mr-3 mr-lg-0'>
                                <Link to="/a/shipping-fee/new" className="btn btn-primary">Add shipping rate</Link>
                            </div>
                        </div>
                    </div>
                }
            </div>

            <div className="SectionInner">
                <div className="filter align-items-center mt-3">
                    <ShippingFeeFtilter
                        searchField={['Title', 'Type', 'SKU Prefix']}
                        placeholders={{
                            'Title': 'Search for product title',
                            'Type': 'Search for product type',
                            'SKU Prefix': 'Search for sku prefix',

                        }}
                        onchangeFilter={_onchangeFilter}
                        zone={zone}
                        supplier={supplier}
                        shippingPlan={shippingPlan}
                        handleChangeZone={_handleChangeZone}
                        handleChangeSupplier={_handleChangeSupplier}
                        handleChangeShippingPlan={_handleChangeShippingPlan}
                        search={search}
                        store={store}
                        handleChangeStore={_handleChangeStore}
                    />
                </div>

                {
                    loading && <div>Loading...</div>
                }

                {
                    !loading && listShippingFee.length > 0 &&
                    <div className="mt-3">
                        <ShippingFeeTable
                            listShippingFee={listShippingFee}
                            handleDelete={_handleDelete}
                            permissionsData={permissionsData}
                        />
                        <Pagination current={page} total={total} pageSize={limit} onChange={_handlePageChange}
                            className='text-right' showSizeChanger pageSizeOptions={['10', '20', '50', '100']}
                        />
                    </div>
                }

                {
                    !loading && listShippingFee.length === 0 && <div>No result</div>
                }

            </div>
        </div>
    )
}

export default ShippingFeePage