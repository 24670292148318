import React, {Component} from 'react'
import humanizeTime from "../../../../../helpers/time/humanizeTime"
import PropTypes from 'prop-types'

class OrderJobSendTime extends Component {
    render() {
        const {sendTime} = this.props
        const timeFormatted = humanizeTime(sendTime)

        return (
            <td className="OrderJobSendTime">
                {timeFormatted}
            </td>
        )
    }
}

OrderJobSendTime.propTypes = {
    sendTime: PropTypes.string.isRequired,
}

export default OrderJobSendTime
