import React, {useState, useEffect} from 'react'
import {Pagination} from "antd"
import RequestProvider from "./RequestProvider"
import {getListRequest} from "../../../services/api/PlatformApiKeysServices"
import RequestFilters from "./filters/RequestFilters"
import RequestTable from "./datas/RequestTable"
import UseDebounce from "./share/UseDebounce"
import ExportRequestGoogleSheet from "./exports/ExportRequestGoogleSheet"
import {listFilterText} from "./contants/contanstFilter"
import MultiFilterInput from "./share/MultiFilterInput"

function RequestsPage() {
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState('')
    const [page, setPage] = useState(1)
    const [limit, setLimit] = useState(50)
    const [total, setTotal] = useState(0)
    const [listRequest, setListRequest] = useState([])
    const [requestID, setRequestID] = useState([])
    const [storeID, setStoreID] = useState([])
    const [orderID, setOrderID] = useState([])
    const [created, setCreated] = useState({from: null, to: null})
    const [updated, setUpdated] = useState({from: null, to: null})
    const [type, setType] = useState([])
    const [status, setStatus] = useState([])
    const [sources, setSources] = useState([])
    const [needCharge, setNeedCharge] = useState('')
    const [productionStatus, setProductionStatus] = useState([])
    const [chargeStatus,setChargeStatus]= useState([])

    const [showFilter, setShowFilter] = useState(false)

    const _onShowFilter = () => {
        setShowFilter(!showFilter)
    }

    const _convertString = (string) => {
        const regex = /\s|,\s|,|\n/
        const parsed = string.split(regex)

        return !!parsed && parsed.length > 0 && parsed.filter(item => item !== "")
    }

    const _handleChangeStatus = (value) => {
        setPage(1)
        setStatus(value)
    }

    const _handleChangeChargeStatus = (value) => {
        setPage(1)
        setChargeStatus(value)
    }

    const _handleChangeTye = (value) => {
        setPage(1)
        setType(value)
    }

    const _handleChangeSource = (value) => {
        setPage(1)
        setSources(value)
    }

    const _handleChangeProductStatus = (value) => {
        setPage(1)
        setProductionStatus(value)
    }

    const _handleChangeNeedCharge = (value) => {
        setPage(1)
        setNeedCharge(value)
    }

    const _handleChangeSearchMulti = (name, value) => {
        console.log(name, value)
        setPage(1)

        const arrConvert = _convertString(value)

        if (name === 'request_id') {
            setStoreID([])
            setOrderID([])
            setRequestID(arrConvert)
        } else if (name === 'store_id') {
            setOrderID([])
            setRequestID([])
            setStoreID(arrConvert)
        } else {
            setRequestID([])
            setStoreID([])
            setOrderID(arrConvert)
        }
    }

    const _handleChangeDateCreated = (dates) => {
        if (!!dates && dates.length > 0) {
            setPage(1)
            setCreated({
                from: dates[0],
                to: dates[1]
            })
        } else {
            setPage(1)
            setCreated({
                from: null,
                to: null
            })
        }
    }

    const _handleChangeDateUpdated = (dates) => {
        if (!!dates && dates.length > 0) {
            setPage(1)
            setUpdated({
                from: dates[0],
                to: dates[1]
            })
        } else {
            setPage(1)
            setUpdated({
                from: null,
                to: null
            })
        }
    }

    const _fetchListRequest = async () => {
        setLoading(true)
        setError('')
        setListRequest([])

        try {
            const payload = {
                order_numbers: orderID,
                _ids: requestID,
                store_ids: storeID,
                request_types: type,
                progress_statuses: status,
                created_at: created,
                updated_at: updated,
                sources: sources,
                need_charge: needCharge,
                production_status: productionStatus,
                charge_status:chargeStatus,
                page,
                limit
            }

            const response = await getListRequest(payload)

            const {success, data, message: error} = response

            if (!success) {
                setLoading(false)

                return setError(error)
            }

            setLoading(false)
            setListRequest(data.requests)
            setTotal(data.total)
        } catch (e) {
            setLoading(false)
            setError(e.message)
        }
    }

    const _handlePageChange = (page, pageSize) => {
        setLimit(pageSize)
        setPage(pageSize !== limit ? 1 : page)
    }

    const debouncedRequestID = UseDebounce(requestID, 500)

    useEffect(() => {
        _fetchListRequest()
        // eslint-disable-next-line
    }, [debouncedRequestID, type, status, sources, created, needCharge, updated, page, limit, productionStatus,chargeStatus])

    return (
        <RequestProvider fetchRequest={_fetchListRequest}>
            <div className='RequestsPage'>
                <div className='PageHeading d-flex align-items-center justify-content-between'>
                    <h1 className="PageTitle">Request</h1>

                    <div className="RightTop">
                        <MultiFilterInput
                            listFilters={listFilterText}
                            defaultValue='order_id'
                            onChangeSearchMulti={_handleChangeSearchMulti}
                        />

                        <span className="MoreFilter" onClick={_onShowFilter}>
                            More filters <i className="ti-angle-double-down"/>
                        </span>

                        <ExportRequestGoogleSheet
                            needCharge={needCharge}
                            sources={sources}
                            requestID={requestID}
                            orderID={orderID}
                            storeID={storeID}
                            created={created}
                            updated={updated}
                            status={status}
                            type={type}
                        />
                    </div>
                </div>

                <div className='SectionInner'>
                    {
                        showFilter && <RequestFilters
                            needCharge={needCharge}
                            sources={sources}
                            requestID={requestID}
                            orderID={orderID}
                            storeID={storeID}
                            created={created}
                            updated={updated}
                            status={status}
                            type={type}
                            production_status={productionStatus}
                            charge_status={chargeStatus}
                            onChangeStatus={_handleChangeStatus}
                            onChangeType={_handleChangeTye}
                            onChangeDateCreated={_handleChangeDateCreated}
                            onChangeDateUpdated={_handleChangeDateUpdated}
                            onChangeMultiInput={_handleChangeSearchMulti}
                            onChangeSource={_handleChangeSource}
                            onChangeNeedCharge={_handleChangeNeedCharge}
                            onChangeProductStatus={_handleChangeProductStatus}
                            onChangeChargeStatus={_handleChangeChargeStatus}
                        />
                    }

                    <div className="Total">
                        {
                            total > 0 && <div>
                                Showing <span>{(page - 1) * limit + 1} to {(page - 1) * limit + listRequest.length}</span> of <span>{total}</span> items
                            </div>
                        }
                    </div>

                    <RequestTable loading={loading} error={error} listRequest={listRequest}/>

                    <Pagination
                        current={page}
                        total={total}
                        pageSize={limit}
                        onChange={_handlePageChange}
                        className='text-right'
                        showSizeChanger pageSizeOptions={['10', '20', '50', '100']}
                    />
                </div>
            </div>
        </RequestProvider>
    )
}

export default RequestsPage
