import React, {Component} from 'react'
import PropTypes from 'prop-types'
import {DatePicker} from "antd"
import moment from "moment"

class FilterDateSingle extends Component {
    _onChange = (date, dateString) => {
        const {onChangeDateSingle} = this.props

        if (typeof onChangeDateSingle === 'function') {
            onChangeDateSingle('apply_date', dateString)
        }
    }

    render() {
        const {heading, value} = this.props

        return (
            <div className='FilterDateSingle'>
                {
                    heading &&
                    <div className='font-weight-500 LabelFilter mb-1'>{heading}</div>
                }
                <DatePicker showTime={'HH:mm'} format={'DD/MM/YYYY HH:mm'}
                            value={!!value ? moment(value, 'DD/MM/YYYY HH:mm') : ''}
                            onChange={this._onChange}/>
            </div>
        )
    }
}

FilterDateSingle.propTypes = {
    heading: PropTypes.string,
}

export default FilterDateSingle
