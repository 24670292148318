import React, {Component} from 'react'
import PropTypes from 'prop-types'

class OrderJobType extends Component {
    render() {
        return (
            <td className="OrderJobType">
                {this.props.type}
            </td>
        )
    }
}

OrderJobType.propTypes = {
    type: PropTypes.string.isRequired,
}

export default OrderJobType
