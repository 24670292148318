import React from 'react'
import PropTypes from 'prop-types'
import CatalogVariantsTableHead from './CatalogVariantsTable/CatalogVariantsTableHead'
import CatalogVariantsTableRow from './CatalogVariantsTable/CatalogVariantsTableRow'


const CatalogProductVariantsTable = (props) => {

    const { listCatalogVariants, permissionsData } = props

    return (
        <div className="MappingVariantsTable">
            <div className="wrapTable">
                <table className='table'>
                    <CatalogVariantsTableHead permissionsData={permissionsData} />
                    <tbody>
                        {
                            listCatalogVariants.map((item, i) => {
                                return <CatalogVariantsTableRow key={item._id} index={i} item={item} permissionsData={permissionsData}/>
                            })
                        }
                    </tbody>
                </table>
            </div>
        </div>
    )
}

CatalogProductVariantsTable.propTypes = {
    listCatalogVariants: PropTypes.array.isRequired,
    handleDelete : PropTypes.func.isRequired,
}

export default CatalogProductVariantsTable