import React, { Component } from 'react'
import { getOrderInvoice, updateOrderShippingCost } from "../../../../services/api/OrdersServices"
import broker from '../../OrderBroker'
import PropTypes from 'prop-types'
import formatCurrency from "../../../../helpers/common/formatCurrency"
import EmbroiderySurchargeIcon from '../../components/meta/EmbroiderySurchargeIcon'

class OrderInvoice extends Component {
    state = {
        invoice: null,
        error: ""
    }

    _subscription1 = null

    componentDidMount() {
        this._fetchOrderInvoice()
        this._subscription1 = broker.subscribe('ORDER_RESTORE', this._fetchOrderInvoice)
    }

    componentWillUnmount() {
        broker.unsubscribe(this._subscription1)
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const { fulfillmentStatus } = this.props

        if (prevProps.fulfillmentStatus !== fulfillmentStatus && fulfillmentStatus === "fulfilled") {
            this._fetchOrderInvoice()
        }
    }

    _fetchOrderInvoice = async () => {
        try {
            const { orderId } = this.props
            const { data, success, message } = await getOrderInvoice(orderId)
            const { shipping_cost } = data

            if (!success) {
                return this.setState({
                    error: message,
                })
            }

            return this.setState({
                invoice: data,
                error: "",
                formShippingCost: shipping_cost
            })
        } catch (e) {
            return this.setState({
                error: e.message,
            })
        }
    }

    render() {
        const { invoice } = this.state
        const { refunded, charge, fulfillmentStatus, order } = this.props
        let chargePending = 0
        let chargePaid = 0

        if (!!charge && charge.length > 0 && charge.filter(item1 => item1.status === 'pending').length > 0) {
            chargePending = parseFloat(charge.filter(item => item.status === 'pending').map(itemR => itemR.amount).reduce(function (a, b) {
                return a + b
            }))
        }
        if (!!charge && charge.length > 0 && charge.filter(item1 => item1.status === 'paid').length > 0) {
            chargePaid = parseFloat(charge.filter(item1 => item1.status === 'paid').map(itemP => itemP.amount).reduce(function (a, b) {
                return a + b
            }))
        }

        if (!invoice || Object.entries(invoice).length <= 0) {
            return null
        }

        if (fulfillmentStatus === 'unfulfilled') {
            return null
        }

        const { fulfillment_cost, branding_cost, shipping_cost, total, currency, discount_amount, label_handling_fee, additional_fee, usps_peak_surcharge } = invoice
        const { embroiderySurcharge } = order

        return (
            <div className="OrderInvoice">
                <div className="card">
                    <div className="card-header font-weight-bold">
                        <span>Order Invoice</span>
                        {
                            embroiderySurcharge && <EmbroiderySurchargeIcon embroiderySurcharge={embroiderySurcharge} />
                        }
                    </div>

                    <div className="card-body">
                        <div className="d-flex justify-content-between align-items-center mb-2 ShippingCost">
                            <div>Shipping cost</div>
                            <div className="d-flex align-items-center font-weight-500 shippingCostValue">{formatCurrency(currency, shipping_cost)}</div>
                        </div>

                        {(invoice.hasOwnProperty('branding_cost') && invoice.branding_cost !== 0) && (
                            <div className="d-flex justify-content-between mb-3 ShippingCost">
                                <div>Branding cost</div>
                                <div className="font-weight-500">{formatCurrency(currency, branding_cost)}</div>
                            </div>
                        )}

                        <div className="d-flex justify-content-between mb-3 FulfillmentCost">
                            <div>Base cost</div>
                            <div className="font-weight-500">{formatCurrency(currency, fulfillment_cost)}</div>
                        </div>

                        {(invoice.hasOwnProperty('usps_peak_surcharge') && invoice.usps_peak_surcharge !== 0) &&
                            <div className="d-flex justify-content-between mb-3 FulfillmentCost">
                                <div>USPS peak surcharge</div>
                                <div className="font-weight-500">{formatCurrency(currency, usps_peak_surcharge)}</div>
                            </div>
                        }

                        {(invoice.hasOwnProperty('label_handling_fee') && invoice.label_handling_fee !== 0) && (
                            <div className="d-flex justify-content-between mb-3 ShippingCost">
                                <div>Label handling fee</div>
                                <div className="font-weight-500">{formatCurrency(currency, label_handling_fee)}</div>
                            </div>
                        )}

                        {(invoice.hasOwnProperty('additional_fee') && invoice.additional_fee !== 0) && (
                            <div className="d-flex justify-content-between mb-3 ShippingCost">
                                <div>Additional fee</div>
                                <div className="font-weight-500">{formatCurrency(currency, additional_fee)}</div>
                            </div>
                        )}

                        {(discount_amount !== undefined && discount_amount > 0) && (
                            <div className="d-flex justify-content-between mb-3 FulfillmentCost">
                                <div>Discount</div>
                                <div className="font-weight-500">-{formatCurrency(currency, discount_amount || 0)}</div>
                            </div>
                        )}

                        <div className="d-flex justify-content-end border-top pt-3 align-items-baseline Total">
                            <div className="mr-3">Total ({currency})</div>
                            <div className="font-weight-500"
                                style={{ fontSize: "20px" }}>{formatCurrency(currency, total)}</div>
                        </div>

                        {!!refunded && (
                            <div className="d-flex justify-content-between ShippingCost">
                                <div>Refunded</div>
                                <div className="font-weight-500">{formatCurrency(currency, refunded)}</div>
                            </div>
                        )}

                        {!!chargePending &&
                            <div className='ChargeInvoice d-flex align-items-center justify-content-between'>
                                <span className='Title'>Charge</span>
                                <div className='Value text-right'>
                                    <span className="Status pending pending-animation">
                                        pending
                                    </span>
                                    <span className='Amount'>{formatCurrency(currency, chargePending)}</span>
                                </div>
                            </div>}

                        {!!chargePaid &&
                            <div className='ChargeInvoice d-flex align-items-center justify-content-between'>
                                <span className='Title'>Charge</span>
                                <div className='Value text-right'>
                                    <span className="Status paid">
                                        paid
                                    </span>
                                    <span className='Amount'>{formatCurrency(currency, chargePaid)}</span>
                                </div>
                            </div>}
                    </div>
                </div>
            </div>
        )
    }
}

OrderInvoice.propTypes = {
    refunded: PropTypes.number.isRequired,
    charge: PropTypes.array
}

export default OrderInvoice
