import React, {Component} from 'react'
import ShippingPlanTitle from "./ShippingPlanTitle"
import ShippingPlanSlug from "./ShippingPlanSlug"
import ShippingPlanIndex from "./ShippingPlanIndex"
import ShippingPlanDefault from "./ShippingPlanDefault"

class ShippingPlansRow extends Component {
    render() {
        const {plan, index} = this.props
        const {title, slug, _id} = plan

        return (
            <tr className="ShippingPlansRow">
                <ShippingPlanIndex index={index}/>
                <ShippingPlanTitle title={title} _id={_id}/>
                <ShippingPlanSlug slug={slug}/>
                <ShippingPlanDefault plan={plan}/>
            </tr>
        )
    }
}

export default ShippingPlansRow
