import React, {Component} from 'react'

class ShippingMethodsAdditionalCost extends Component {
    state = {
        additionalCost: this.props.additionalCost,
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.additionalCost !== this.props.additionalCost) {
            this.setState({
                additionalCost: this.props.additionalCost
            })
        }
    }

    _handleChangeInput = e => {
        const {value} = e.target
        const {index, _handleUpdatePlansState} = this.props

        this.setState({
            additionalCost: value,
        })

        _handleUpdatePlansState(index, "second_rate", parseFloat(value))
    }

    render() {
        const {additionalCost} = this.state

        return (
            <td className="ShippingMethodsAdditionalCost">
                <input type="number" value={additionalCost} step="any" min={0} className="form-control"
                       onChange={this._handleChangeInput} required/>
            </td>
        )
    }
}

export default ShippingMethodsAdditionalCost
