import React from 'react'
import PropTypes from 'prop-types'

ProductVariantPrice.propTypes = {
    price: PropTypes.any,
    variantIndex: PropTypes.number,
    totalVariants: PropTypes.number,
    onChangeVariant: PropTypes.func.isRequired
}

function ProductVariantPrice(props) {
    const {price, onChangeVariant, variantIndex, handlePaste, disableAll} = props

    const _handleChangeInput = e => {
        const {value} = e.target
        const vValue = Number(value)

        if (isNaN(vValue)) {
            return
        }

        if (typeof onChangeVariant === 'function') {
            onChangeVariant(variantIndex, 'base_cost', value)
        }
    }

    const _handlePasteInput = e => {
        handlePaste(e, 'base_cost', variantIndex)
    }

    return (
        <td className="ProductVariantPrice">
            <input
                type="text"
                className="form-control"
                value={price || ""}
                onChange={_handleChangeInput}
                onPaste={_handlePasteInput}
                disabled={disableAll}
            />
        </td>
    )
}

export default ProductVariantPrice
