import React from 'react'
import PropTypes from 'prop-types'
import {Radio} from "antd"

Amount.propTypes = {
    type: PropTypes.string,
    validatePrice: PropTypes.any,
    validatePercent: PropTypes.any,
    priceDiscount: PropTypes.string,
    percentDiscount: PropTypes.string,
    onChangeType: PropTypes.func.isRequired,
    onChangeValueDiscount: PropTypes.func.isRequired
}

function Amount(props) {
    const {
        type,
        validatePrice,
        validatePercent,
        priceDiscount,
        percentDiscount,
        onChangeType,
        onChangeValueDiscount
    } = props

    return (
        <div className="FormItem">
            <label htmlFor="type" className="LabelDiscount">Type</label>
            <Radio.Group id="type" onChange={onChangeType} value={type}>
                <div className="TypeContent">
                    <Radio value='percentage'>Percentage</Radio>
                    <input
                        className={`TypeInput ${!!validatePercent && validatePercent.length > 0 ? 'Error' : ''}`}
                        type="number"
                        value={percentDiscount}
                        disabled={type !== 'percentage'}
                        placeholder='0.00'
                        onChange={onChangeValueDiscount}
                    />
                    <span
                        className={`TypeCharacter ${!!validatePercent && validatePercent.length > 0 ? 'Error' : ''}`}>%</span>
                </div>

                <div className="TypeContent">
                    <Radio value='fixed'>Fixed value</Radio>
                    <input
                        className={`TypeInput ${!!validatePrice && validatePrice.length > 0 ? 'Error' : ''}`}
                        type="number"
                        value={priceDiscount}
                        disabled={type !== 'fixed'}
                        placeholder='0.00'
                        onChange={onChangeValueDiscount}
                    />
                    <span
                        className={`TypeCharacter ${!!validatePrice && validatePrice.length > 0 ? 'Error' : ''}`}>$</span>
                </div>
            </Radio.Group>
        </div>
    )
}

export default Amount