import React, {Component} from 'react'
import {Radio, Input, Checkbox, Switch, Button, Spin} from "antd"
import {CloseOutlined} from '@ant-design/icons'
import {getCoolieSettings, saveCoolieSettings} from "../../../../services/api/CoolieServices"
import searchIcon from '../../../../statics/assets/images/Search.png'
import className from "classnames"

const {Search} = Input

class AutoMapping extends Component {
    state = {
        loading: false,
        loadingGetSetting: false,
        errorGetSetting: '',
        error: '',
        errorStores: '',
        autoMapping: true,
        config: {
            modeNormal: false,
            modeBuiltPayment: true,
            modeMarketplace: false,
            printHolo: false,
            listSpecific: false,
            delay: 3600
        },
        string: '',
        listStores: [],
        stores: [],
        stores2: [],
        search: ''
    }

    _timeOut = null

    componentDidMount() {
        this._handleGetSetting()
    }

    _handleGetSetting = async () => {
        this.setState({
            loadingGetSetting: true,
            errorGetSetting: ''
        })

        try {
            const {success, message, data} = await getCoolieSettings()

            if (!success) return this.setState({
                errorGetSetting: message,
                loadingGetSetting: false
            })

            const {auto_mapping_platforms, stores} = data
            const {normal, bp, mp, printholo, specific_stores, stores: storesApply} = auto_mapping_platforms

            this.setState({
                autoMapping: data.hasOwnProperty('auto_mapping') ? data.auto_mapping === 'yes' : true,
                errorStores: '',
                loadingGetSetting: false,
                stores: storesApply,
                stores2: storesApply,
                listStores: stores,
                config: {
                    ...this.state.config,
                    modeNormal: normal === 'yes',
                    modeBuiltPayment: bp === 'yes',
                    modeMarketplace: mp === 'yes',
                    printHolo: printholo === 'yes',
                    listSpecific: specific_stores === 'yes',
                    delay: data.hasOwnProperty('auto_mapping_schedule') ? data.auto_mapping_schedule : 3600,
                }
            })
        } catch (e) {
            this.setState({
                errorGetSetting: e.message,
                loadingGetSetting: false
            })
        }
    }

    _handleTogglePush = (checked) => {
        const {stores} = this.state
        this.setState({
            autoMapping: checked,
            search: '',
            stores2: stores
        })
    }

    _handleChangeCheckbox = (e) => {
        const {name, checked} = e.target
        const {stores} = this.state

        this.setState({
            search: '',
            stores2: stores,
            config: {
                ...this.state.config,
                [name]: checked,
            },
        })
    }

    _handleChangeRadio = (e) => {
        const {name, value} = e.target
        const {stores} = this.state

        this.setState({
            search: '',
            stores2: stores,
            config: {
                ...this.state.config,
                [name]: value,
            },
        })
    }

    _handleAddStore = (value) => {
        const {stores} = this.state
        const arrValue = value.split(",").filter(item => item !== '')
        const _newStores = arrValue.concat(stores)
        const newStores = _newStores.filter((item, index) => _newStores.indexOf(item) === index)

        this.setState({
            string: '',
            search: '',
            stores: newStores,
            stores2: newStores,
            config: {
                ...this.state.config,
                listSpecific: newStores.length > 0,
            },
        }, () => {
            this._handleCheckStoresError(newStores)
        })
    }

    _handleChangeInput = (e) => {
        this.setState({
            string: e.target.value.trim(),
        })
    }

    _handleDeleteItem = (value) => {
        const {stores, autoMapping, config} = this.state
        const {listSpecific} = config

        if (autoMapping && listSpecific) {
            const newStores = stores.filter(item => item !== value)

            this.setState({
                stores: newStores,
                stores2: newStores,
                search: '',
                config: {
                    ...this.state.config,
                    listSpecific: newStores.length > 0,
                },
            }, () => {
                this._handleCheckStoresError(newStores)
            })
        }
    }

    _handleSaveSetting = async () => {
        const {autoMapping, config, stores} = this.state
        const {modeNormal, modeBuiltPayment, modeMarketplace, printHolo, delay, listSpecific} = config

        this.setState({
            loading: true,
            error: '',
            search: '',
            stores2: stores
        })

        try {
            const payload = {
                auto_mapping: autoMapping ? 'yes' : 'no',
                auto_mapping_platforms: {
                    normal: modeNormal ? 'yes' : 'no',
                    bp: modeBuiltPayment ? 'yes' : 'no',
                    mp: modeMarketplace ? 'yes' : 'no',
                    printholo: printHolo ? 'yes' : 'no',
                    specific_stores: listSpecific ? 'yes' : 'no',
                    stores: stores
                },
                auto_mapping_schedule: delay
            }

            const {success, message} = await saveCoolieSettings(payload)

            if (!success) return this.setState({
                loading: false,
                error: message
            })

            this.setState({
                loading: false
            }, () => {
                this._handleGetSetting()
            })
        } catch (e) {
            this.setState({
                loading: false,
                error: e.message
            })
        }
    }

    _handleCheckStoresError = (stores) => {
        const {listStores} = this.state

        const errorStores = stores.filter(item => !listStores.includes(item))

        this.setState({
            errorStores: errorStores.length > 0 ? 'Store không có trong cấu hình Auto mapping. Liên hệ Dev để kiểm tra.' : '',
        })
    }

    _handleChangeSearch = (e) => {
        const {value} = e.target
        const {stores2, stores} = this.state

        this.setState({
            search: value.trim(),
            stores2: value.trim() ? stores2 : stores
        }, () => {
            if (this.timeOut) clearTimeout(this.timeOut)
            this.timeOut = setTimeout(this._handleSearchStores, 500)
        })
    }

    _handleSearchStores = () => {
        const {stores, search} = this.state

        const newStores = stores.filter(item => item.indexOf(search) >= 0)
        this.setState({
            stores2: newStores
        }, () => {
            this._handleCheckStoresError(newStores)
        })
    }

    render() {
        const {autoMapping, config, stores2, search, string, listStores, loading, error, errorStores, loadingGetSetting, errorGetSetting} = this.state
        const {modeNormal, modeBuiltPayment, modeMarketplace, printHolo, listSpecific, delay} = config
        const radioStyle = {
            display: 'block',
            height: '30px',
            lineHeight: '30px',
        }
        const {disableAll} = this.props

        if(errorGetSetting) return <div className="AutoMapping AutoPushContent">{errorGetSetting}</div>

        return <Spin spinning={loadingGetSetting}><div className="AutoMapping AutoPushContent">
            <div className="TogglePush">
                <label htmlFor="AutoPush">Auto Mapping</label>
                <Switch id='AutoPush' checked={autoMapping} onChange={this._handleTogglePush} disabled={disableAll}/>
            </div>

            <div className="row">
                <div className="col-sm-6">
                    <div className='title'>
                        Chọn Platform hoặc mode áp dụng Auto mapping sản phẩm khi có đơn hàng
                    </div>

                    <div className="MappingCheck disabled">
                        <Checkbox name="modeNormal" disabled checked={modeNormal} onChange={this._handleChangeCheckbox}>
                            PLF mode Normal
                        </Checkbox>
                    </div>

                    <div className="MappingCheck">
                        <Checkbox name="modeBuiltPayment" disabled={!autoMapping || disableAll} checked={modeBuiltPayment}
                                  onChange={this._handleChangeCheckbox}>
                            PLF mode Built-payment
                        </Checkbox>
                    </div>

                    <div className="MappingCheck disabled">
                        <Checkbox name="modeMarketplace" disabled checked={modeMarketplace}
                                  onChange={this._handleChangeCheckbox}>
                            PLF mode Marketplace
                        </Checkbox>
                    </div>

                    <div className="MappingCheck disabled">
                        <Checkbox name="printHolo" disabled checked={printHolo} onChange={this._handleChangeCheckbox}>
                            Printholo
                        </Checkbox>
                    </div>

                </div>
                <div className="col-sm-6">
                    <div className='title'>
                        Chọn thời gian delay auto mapping kể từ thời điểm đơn hàng được đẩy hệ thống Platform sang hệ
                        thống FFM
                    </div>
                    <Radio.Group disabled={!autoMapping || disableAll} name="delay" onChange={this._handleChangeRadio} value={delay}>
                        <Radio style={radioStyle} value={0}>
                            Không delay
                        </Radio>
                        <Radio style={radioStyle} value={1800}>
                            30 phút
                        </Radio>
                        <Radio style={radioStyle} value={3600}>
                            1 giờ
                        </Radio>
                    </Radio.Group>
                </div>
            </div>
            <div className="d-flex align-items-center justify-content-between">
                <div className="MappingCheck d-flex align-items-center">
                    <Checkbox name="listSpecific" disabled={!autoMapping || disableAll} checked={listSpecific}
                              onChange={this._handleChangeCheckbox}>
                        List specific store
                    </Checkbox>
                    {!disableAll &&
                        <Search
                            className="ml-3"
                            value={string}
                            disabled={!autoMapping || !listSpecific || disableAll}
                            onChange={this._handleChangeInput}
                            style={{width: '600px'}}
                            placeholder="Nhập store ID..."
                            enterButton="Add"
                            size="large"
                            onSearch={this._handleAddStore}
                        />
                    }
                </div>
                <div className="searchStores">
                    <Input
                        placeholder="Search store ID"
                        size="large"
                        value={search}
                        onChange={this._handleChangeSearch}
                        prefix={<img src={searchIcon} alt="iconSearch"/>}
                    />
                </div>
            </div>
            {stores2.length > 0 ? <div className="listStore">
                {stores2.map((itemStore, index) => {
                    return (
                        <div key={index}
                            className={className('itemStore', !listStores.includes(itemStore) && 'error')}
                        >{index + 1}.
                            <span>{itemStore}
                                {!disableAll &&
                                    <CloseOutlined onClick={() => this._handleDeleteItem(itemStore)} />
                                }
                            </span>
                        </div>
                    )
                })}
            </div> : <div className="listStore pb-3">Không có store nào.</div>}
            <div className="actions d-flex justify-content-end align-items-center">
                {errorStores && <div className="errorStore text-danger text-center">{errorStores}</div>}
                {!disableAll &&
                    <Button disabled={loading || disableAll} onClick={this._handleSaveSetting} type="primary" size="large">
                        {loading ? 'Saving...' : 'Save'}
                    </Button>
                }
            </div>
            {error && <div className="mt-3 text-danger">{error}</div>}
        </div></Spin>
    }
}

export default AutoMapping
