import React, {Component} from 'react'
import PropTypes from 'prop-types'

class ContentResults extends Component{
    render() {
        const {errors, success, errorOrders} = this.props

        return (
            <div>
                <div className="info mb-3">
                    <span className="mr-2"><span className="badge badge-success">Success</span> {success} items</span>
                    <span className="mr-2"><span className="badge badge-danger">Errors</span> {errors} items</span>
                </div>
                {errorOrders.length > 0 && (
                    <div className="mt-2 contentResults">
                        <label className="mb-2 font-weight-bold d-block">Items error:</label>
                        {errorOrders.map(item => {
                            if(item.packages.length > 0) {
                                return <span key={item.orderId} className="d-block mb-2"><strong className='d-block'>{item.orderName}: </strong>
                                    {
                                        item.packages.map(itemPackage => {
                                            if(!itemPackage.success) {
                                                return <span key={itemPackage._id} className='text-danger mb-1 d-block'>{itemPackage.packageName}: {itemPackage.message}</span>
                                            } else {
                                                return <span key={itemPackage._id} className='text-success mb-1 d-block'>{itemPackage.packageName}: Push package success</span>
                                            }
                                        })
                                    }
                                </span>
                            } else {
                                return <span key={item.orderId} className="d-block mb-2"><strong className='d-block'>{item.orderName}:</strong> <span className='text-danger '> {item.error}</span></span>
                            }
                        })}
                    </div>
                )}
            </div>
        )
    }
}

ContentResults.propTypes = {
    errors: PropTypes.number.isRequired,
    success: PropTypes.number.isRequired,
    errorOrders: PropTypes.array.isRequired
}

export default ContentResults