import React from 'react'
import DocTitleByStore from "../../dashboard/shared/DocTitleByStore"
import ReturnPage from '../../shared/ReturnPage'
import SettingHolidayDetailPage from './SettingHolidayDetailPage'
import '../styles/_index.scss'

function SettingHolidayDetailContainer(props) {
    const idSetting = props.match.params.id
    return (
        <div className='SettingHolidayDetailContainer'>
            <ReturnPage url="/a/setting-holiday" title="Setting holiday" />
            <DocTitleByStore title="Setting Holiday Edit" />
            <SettingHolidayDetailPage idSetting={idSetting} />
        </div>
    )
}

export default SettingHolidayDetailContainer