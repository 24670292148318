import React, {Component, Fragment} from 'react'
import PropTypes from 'prop-types'
import {ReactComponent as IconDown} from "../../statics/assets/images/icon_down.svg"

class CustomSelectMultiLevel extends Component {
    constructor(props) {
        super(props)

        this.state = {
            openPopup: false,
            selected: [],
            subSelected: []
        }
    }

    componentDidMount() {
        const {selected, listSub} = this.props

        if (!!selected && selected.length > 0) {
            this.setState({
                selected: [...selected],
                subSelected: listSub.filter(item => selected.includes(item))
            })
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const {selected, listSub} = this.props

        if (prevProps.selected !== selected) {
            this.setState({
                selected: [...selected],
                subSelected: listSub.filter(item => selected.includes(item))
            })
        }
    }

    _openPopup = () => {
        this.setState({
            openPopup: true
        })
    }

    _closePopup = () => {
        const {selected} = this.props

        this.setState({
            selected: !!selected ? [...selected] : [],
            openPopup: false
        })
    }

    _onSelectAll = () => {
        const {listFilters} = this.props
        const listSelected = []

        listFilters.map((item => {
            if (!!item.subLevel && item.subLevel.length > 0) {
                listSelected.push(item.value)

                item.subLevel.map((sub) => {
                    return listSelected.push(sub.value)
                })
            } else {
                listSelected.push(item.value)
            }
        }))

        this.setState({
            selected: listSelected
        })
    }

    _onClearSelect = () => {
        this.setState({
            selected: [],
            subSelected: []
        })
    }

    _onCheckBoxForDiv = (name) => {
        document.getElementById(name).click()
    }

    _handleChangeCheckbox = (e, listSub) => {
        //ở đây cần kiểm tra xem có sub hay không => có thì thêm all sub hoặc clear all sub
        const {checked, name} = e.target
        const {selected} = this.state

        if (checked) {
            if (!!listSub && listSub.length > 0) {
                this.setState({
                    selected: [...selected, name, ...listSub.map(sub => sub.value)],
                    subSelected: listSub.map(sub => sub.value)
                })
            } else {
                this.setState({
                    selected: [...selected, name]
                })
            }
        } else {
            if (!!listSub && listSub.length > 0) {
                const listSubName = listSub.map(sub => sub.value)

                this.setState({
                    selected: selected.filter(item => (item !== name && !listSubName.includes(item))),
                    subSelected: []
                })
            } else {
                this.setState({
                    selected: selected.filter(item => item !== name)
                })
            }
        }
    }

    _handleChangeSubCheckbox = (e, length, parent) => {
        const {checked, name} = e.target
        const {selected, subSelected} = this.state

        if (checked) {
            this.setState({
                subSelected: [...subSelected, name],
                selected: [...selected, name]
            }, () => {
                const {subSelected, selected} = this.state
                if (subSelected.length >= length) {
                    this.setState({
                        selected: [...selected, parent]
                    })
                }
            })
        } else {
            this.setState({
                selected: selected.filter(item => (item !== name && item !== parent)),
                subSelected: subSelected.filter(item => item !== name),
            })
        }
    }

    _onFilter = () => {
        const {onChangeFilters, name} = this.props

        if (typeof onChangeFilters === 'function') {
            onChangeFilters(name, this.state.selected)

            this.setState({
                openPopup: false
            })
        }
    }

    _mappingSelected = (selected) => {
        const {listFilters} = this.props

        if (selected.length > 0) {
            const listSelected = []

            listFilters.map((item => {
                if (!!item.subLevel && item.subLevel.length > 0) {
                    item.subLevel.map((sub) => {
                        if (selected.includes(sub.value)) {
                            return listSelected.push(sub.name)
                        }
                    })
                } else {
                    if (selected.includes(item.value)) {
                        listSelected.push(item.name)
                    }
                }
            }))

            return listSelected
        }
    }

    render() {
        const {label, listFilters, placeholder, loading} = this.props
        const {openPopup, selected} = this.state

        return (
            <div className='CustomMultiSelect CustomSelectMultiLevel'>
                <div className="font-weight-500 LabelFilter mb-1">{label}</div>
                <div className='InputSelect' 
                     onClick={loading===false?this._openPopup:null}
                     style={{cursor:(loading!==false? "not-allowed": "pointer")}}
                >
                    <span style={loading===true?{color:"rgba(0,0,0,.25)"}:null}>
                        {selected.length === 0 ? placeholder : this._mappingSelected(selected).join(', ')}
                    </span>
                    <IconDown width={12} height={12}/>
                </div>
                <div className={`PopupSelect ${openPopup}`}>
                    <div className="ListItemsSelect">
                        {
                            listFilters.length > 0 && listFilters.map((item, index) =>
                                <Fragment key={index}>
                                    <div className='Item'>
                                        <input
                                            id={item.value}
                                            type="checkbox"
                                            name={item.value}
                                            checked={selected.includes(item.value)}
                                            onChange={(e) => this._handleChangeCheckbox(e, item.subLevel)}
                                        />
                                        <label onClick={() => this._onCheckBoxForDiv(item.value)}>{item.name}</label>
                                        <span className="bg"/>
                                    </div>
                                    {
                                        !!item.subLevel && item.subLevel.length > 0 && <div className="SubItemList">
                                            {
                                                item.subLevel.map((sub, idx) =>
                                                    <div key={`sub_${idx}`} className='Item'>
                                                        <input
                                                            id={sub.value}
                                                            type="checkbox"
                                                            name={sub.value}
                                                            checked={selected.includes(sub.value)}
                                                            onChange={(e) => this._handleChangeSubCheckbox(e, item.subLevel.length, item.value)}
                                                        />
                                                        <label onClick={() => this._onCheckBoxForDiv(sub.value)}>
                                                            {sub.name}
                                                        </label>
                                                        <span className="bg"/>
                                                    </div>
                                                )
                                            }
                                        </div>
                                    }
                                </Fragment>
                            )
                        }
                    </div>
                    <div className="Actions">
                        <button onClick={this._onSelectAll} className='LinkAction'>Select All</button>
                        <button
                            disabled={selected.length === 0}
                            className='LinkAction'
                            onClick={this._onClearSelect}
                        >
                            Clear
                        </button>
                        <button onClick={this._onFilter}>Filter</button>
                    </div>
                </div>
                {
                    openPopup && <div className="Background" onClick={this._closePopup}/>
                }
            </div>
        )
    }
}

CustomSelectMultiLevel.propTypes = {
    label: PropTypes.string.isRequired,
    listFilters: PropTypes.array.isRequired,
    placeholder: PropTypes.string.isRequired,
    onChangeFilters: PropTypes.func.isRequired,
    selected: PropTypes.array,
    name: PropTypes.string,
    listSub: PropTypes.array
}

export default CustomSelectMultiLevel
