import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { UncontrolledTooltip } from 'reactstrap'

class CopyToClipboard extends Component {
    state = {
        copied: false,
        tooltipOpen: false,
    }

    _input = React.createRef()

    _handleClick = e => {
        // e.preventDefault()
        e.stopPropagation()

        const input = this._input.current
        input.select()
        input.setSelectionRange(0, 99999)
        document.execCommand('copy')

        this.setState({
            copied: true,
        })
    }

    _handleMouseOut = e => {
        this.setState({
            copied: false,
        })
    }

    render() {
        const { children, text, target } = this.props
        const { copied } = this.state

        return (
            <>
                <div className='CopyToClipboard cursor-pointer d-inline-flex' onClick={this._handleClick}
                    onMouseOut={this._handleMouseOut} id={target}>
                    <input type='text' className='sr-only' defaultValue={text} ref={this._input} />

                    {children}
                </div>

                <UncontrolledTooltip placement='bottom' target={target} trigger='hover'>
                    <span className='fs-12'>
                        {
                            copied ? 'Copied' : `Copy to clipboard`
                        }
                    </span>
                </UncontrolledTooltip>
            </>
        )
    }
}

CopyToClipboard.propTypes = {
    text: PropTypes.string.isRequired,
    target: PropTypes.string.isRequired,
}

export default CopyToClipboard
