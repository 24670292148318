import React from 'react'
import CostControlHead from './CostControlTable/CostControlHead'
import CostControlRow from './CostControlTable/CostControlRow'

function CostControlTable(props) {
    const { listCostControl, loading, reload, permissionsData } = props
    return (
        <div className="CostControlTable">
            <div className="wrapTable">
                <table className='table'>
                    <CostControlHead />
                    <tbody>
                        {
                            !!listCostControl && listCostControl.map((item, i) => {
                                return <CostControlRow key={item._id} index={i} item={item} reload={reload} permissionsData={permissionsData}/>
                            })
                        }
                        {
                            !loading && listCostControl.length === 0 && 
                            <tr>
                                <td colSpan={6}>
                                    No result
                                </td>
                            </tr>
                        }
                    </tbody>
                </table>
            </div>
        </div>
    )
}

export default CostControlTable