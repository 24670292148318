import React from 'react'

function RolesHead() {
    return (
        <thead className="RolesHead">
            <tr>
                <th>Roles</th>
                <th>Permissions</th>
                <th>Created Date</th>
                <th>Actions</th>
            </tr>
        </thead>
    )
}

export default RolesHead