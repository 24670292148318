import React, {Component} from 'react'
import { Popover } from 'antd'

class OrderAddress extends Component {
    render() {
        const {country = '', email = '', full_name = '', zone = ''} = this.props

        return (
            <td className="OrderAddress">
                {
                    !!full_name &&
                    <div>{full_name}</div>
                }
                {
                    !!email &&
                    <div>
                        {
                            email.length > 14 ? (
                                <Popover
                                    placement="topLeft"
                                    content={email}
                                    overlayClassName='emailPopover'
                                >
                                    <span className='cursor-pointer'>{`${email.substring(0, 15)}...`}</span>
                                </Popover>
                            ) : email
                        }
                    </div>
                }
                {
                    !!country &&
                    <div className="Country">
                        <span>{country}</span>
                        {!!zone && <span> ({zone})</span>}
                    </div>
                }
            </td>
        )
    }
}

export default OrderAddress
