import React, {useContext, useState} from 'react'
import PropTypes from 'prop-types'
import {Button, Modal, Progress, Select} from "antd"
import OrderPartnersContext from "../../context/OrderPartnersContext"
import {ExclamationCircleOutlined, CheckCircleOutlined} from '@ant-design/icons'
import {updateStatusPackage} from "../../../../services/api/OrdersServices"

ChangeStatusPackage.propTypes = {
    visible: PropTypes.bool,
    onCancel: PropTypes.func.isRequired
}

function ChangeStatusPackage(props) {
    const {visible, onCancel} = props
    const {listSelected, packageLists} = useContext(OrderPartnersContext)
    const [loading] = useState(false)
    const [status, setStatus] = useState('exception')
    const [reason, setReason] = useState(status === 'exception' ? 'reshipped-vn' : 'cancel-ship')
    const [listProcessing, setListProcessing] = useState(packageLists.filter(item => listSelected.includes(item._id)))
    const [listSuccess, setListSuccess] = useState([])
    const [listError, setListError] = useState([])

    const _handleChangeStatus = (value) => {
        setStatus(value)

        if (value === 'exception') {
            setReason('reshipped-vn')
        } else {
            setReason('cancel-ship')
        }
    }

    const _handleChangeReason = (value) => {
        setReason(value)
    }

    const _onChangeStatusPackage = async (fulfillmentId) => {
        try {
            const payload = {
                fulfillmentId: fulfillmentId,
                newStatus: status,
                reason: reason
            }

            const response = await updateStatusPackage(fulfillmentId, payload)

            const {success, message} = response

            if (!success) {
                return {success: success, message: message}
            }

            return {success: success}
        } catch (e) {
            return {success: false, message: e.message}
        }
    }

    const _onSubmitChange = async () => {
        setListSuccess([])
        setListError([])

        let processed = []

        await Promise.all(listProcessing.map(async item => {
            const response = await _onChangeStatusPackage(item._id)

            const {success, message} = response

            if (!success) return processed.push({...item, error: message, processing: false})

            return processed.push({...item, success: true, processing: false})
        }))

        let newListSuccess = []
        let newListError = []

        processed.map(process => {
            if (process.success === true) {
                return newListSuccess.push(process)
            }

            if (process.error.length > 0) {
                return newListError.push(process)
            }
        })

        setListSuccess(newListSuccess)
        setListError(newListError)
        return setListProcessing(processed)
    }

    return (
        <Modal
            className="ChangeStatusPackageModal"
            visible={visible}
            title="Change status package"
            onOk={_onSubmitChange}
            onCancel={onCancel}
            footer={[
                <Button key="back" disabled={loading} onClick={onCancel}>
                    Cancel
                </Button>,
                <Button key="submit" disabled={loading} type="primary" loading={loading} onClick={_onSubmitChange}>
                    Change status
                </Button>
            ]}
        >
            <div className="FormItem">
                <label htmlFor="status">Choose status:</label>
                <Select onChange={_handleChangeStatus} value={status} disabled={loading}>
                    <Select.Option value='exception'>Exception</Select.Option>
                    <Select.Option value='cancelled'>Cancel</Select.Option>
                </Select>
            </div>

            <div className="FormItem">
                <label htmlFor="reason">Reason:</label>

                {
                    status === 'exception' ? <Select onChange={_handleChangeReason} value={reason} disabled={loading}>
                            <Select.Option value='reshipped-vn'>Reshipped VN</Select.Option>
                            <Select.Option value='reshipped'>Reshipped</Select.Option>
                            <Select.Option value='refund'>Refund</Select.Option>
                            <Select.Option value='lost'>Lost</Select.Option>
                        </Select> :
                        <Select onChange={_handleChangeReason} value={reason} disabled={loading}>
                            <Select.Option value='cancel-ship'>Cancel Ship</Select.Option>
                            {/*<Select.Option value='cancel-production'>Cancel Production</Select.Option>*/}
                        </Select>
                }
            </div>

            <h3>List processing <span>{listSelected.length}</span> items</h3>
            {
                (listSuccess.length > 0 || listError.length > 0) &&
                <div className="Results">
                    <div className="ResultSuccess">
                        Success: <span>{listSuccess.length}</span> items
                    </div>
                    <div className="ResultError">
                        Error: <span>{listError.length}</span> items
                    </div>
                </div>
            }

            <div className="ListProcessing">
                <ul>
                    {
                        !!listProcessing && listProcessing.length > 0 && listProcessing.map(item => (
                                <li key={item._id}>
                                    <span>{item.name}</span>
                                    {
                                        !!item.error &&
                                        <span className="Error"><ExclamationCircleOutlined/> {item.error}</span>
                                    }
                                    {
                                        item.success &&
                                        <span className="Success"><CheckCircleOutlined/> Change success</span>
                                    }
                                    <Progress
                                        percent={100}
                                        size="small"
                                        status={`${(item.processing === false && item.success === true) ? 'success' : ((item.processing === false && item.error.length > 0) ? 'exception' : 'active')}`}
                                        showInfo={false}
                                    />
                                </li>
                            )
                        )
                    }
                </ul>
            </div>
        </Modal>
    )
}

export default ChangeStatusPackage
