import React, {Component} from 'react'
import {getLocalData, setLocalData} from '../../services/StorageServices'
import getHistory from '../../store/getHistory'
import {Link} from "react-router-dom"
import {extendUrl} from "../../statics/extenent-url"

class UserInfo extends Component {
    _handleLogout = () => {
        setLocalData('user', '')
        setLocalData('accessToken', '')
        setLocalData('refreshToken', '')
        setLocalData('permissions', {})

        const history = getHistory()
        history.push('/login')
    }

    render() {
        const user = getLocalData('user')
        const {username} = Object.assign({}, user)
        const token = JSON.parse(localStorage.getItem('com.pdf126.accessToken'))
        const permissionsData = !!getLocalData('permissions')? Object.keys(getLocalData('permissions')) : []

        return (
            <div className='UserInfo'>
                <div className='mb-2'>Username: {username}</div>
                {/* {permissionsData.includes('ffm_fconnector_all') &&
                    <div className='mb-2'>
                        <Link
                            to={{
                                pathname: `${extendUrl.url}`,
                                search: `?token=${token}`
                            }}
                            target='_blank'
                        >
                            Sync Connector
                        </Link>
                    </div>
                } */}
                <div><u className='text-primary cursor-pointer' onClick={this._handleLogout}>Logout</u></div>
            </div>
        )
    }
}

export default UserInfo
