import React, {useState, createRef, useEffect} from 'react'
import {Modal, Input, message} from 'antd'
import {exportListOrderProfit, exportListPackagesProfit} from '../../../../services/api/SuppliersServices'
import moment from 'moment'

function ExportOrderProfit(props) {
    const {
        showExportModal,
        toggleExportModal,
        supplier, orderId,
        createdStart,
        createdEnd, isArchive,
        namespace, profitStart, profitEnd
    } = props
    const [fileName, setFileName] = useState('')
    const [error, setError] = useState('')
    const [loading, setLoading] = useState(false)
    const inputFileName = createRef()
    const handleChangeFileName = (e) => {
        setFileName(e.target.value)
    }

    const toggleModal = () => {
        toggleExportModal('')()
        setFileName('')
        setError('')
    }

    const focusInput = () => inputFileName && inputFileName.current && inputFileName.current.focus()

    useEffect((a, b) => {
        if (showExportModal) {
            setTimeout(focusInput, 100)
        }
    }, [showExportModal])
    const handleExport = async () => {
        if (fileName !== '') {
            const payload = {
                filename: fileName,
                supplier: supplier,
                order_id: orderId,
                is_archive: isArchive,
            }
            setLoading(true)
            if (!!createdStart && !!createdEnd) payload.fulfilled_at = {
                from: createdStart,
                to: createdEnd,
            }
            if (namespace) payload.namespace = namespace
            if (profitStart && profitEnd) payload.profit = {
                from: + profitStart,
                to: + profitEnd,
            }
            try {
                console.log({showExportModal})
                const response = showExportModal === 'packages'
                    ? await exportListPackagesProfit(payload, fileName)
                    : await exportListOrderProfit(payload, fileName)
                if (response) {
                    const {success, message: mess} = response
                    if (success === 'false') {
                        message.error(mess)
                    } else {
                        let csvFile
                        let downloadLink

                        const start = moment(Date.now()).format('YYYY/MM/DD')
                        csvFile = new Blob([response], {type: 'text/csv;encoding:utf-8'})
                        downloadLink = document.createElement('a')
                        downloadLink.download = `${fileName}${start}`
                        downloadLink.href = window.URL.createObjectURL(csvFile)
                        downloadLink.style.display = 'none'
                        document.body.appendChild(downloadLink)
                        downloadLink.click()
                        document.body.removeChild(downloadLink)
                        message.success('Export Order Profit success!')
                        toggleModal()
                    }
                } else {
                    message.error('Export Order Profit fail!')
                }
                setLoading(false)
            } catch (error) {
                message.error(error)
            }
        } else {
            setError("Please input file name!")
            focusInput()
        }
    }

    return (
        <Modal
            className="ExportOrderProfitModal"
            visible={showExportModal}
            onOk={handleExport}
            onCancel={toggleModal}
            okText="Export"
            confirmLoading={loading}
        >
            <label>File name:</label>
            <Input
                ref={inputFileName}
                placeholder="Input file name..."
                value={fileName}
                onChange={handleChangeFileName}
                className="FileName"
                autoFocus={true}
            >
            </Input>
            {!!error &&
            <small className="text-danger">{error}</small>
            }
        </Modal>
    )
}

export default ExportOrderProfit