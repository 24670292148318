import React from 'react'
import PropTypes from 'prop-types'

ProductVariantsFilter.propTypes = {
    attributes: PropTypes.array,
    filter: PropTypes.object,
    onChangeFilter: PropTypes.func.isRequired
}

function ProductVariantsFilter(props) {
    const {attributes, filter, onChangeFilter, listVariants} = props

    const _handleChangeAttributeFilter = (attributeId) => e => {
        const {value} = e.target

        if (typeof onChangeFilter === 'function') {
            onChangeFilter({
                ...filter,
                [attributeId]: value
            })
        }
    }

    return (
        <div className='ProductVariantsFilter'>
            {
                !!listVariants && listVariants.length > 0 && attributes.map(attribute => {
                    const {name, values, _id} = attribute
                    const currentValue = filter[_id] || ''

                    return (
                        <div key={`variant_${_id}`} className="FilterItem">
                            <select value={currentValue}
                                    onChange={_handleChangeAttributeFilter(_id)}
                                    className="form-control">
                                <option value="">Select {name}</option>
                                {
                                    values.map(value => {
                                        const {name: _name} = value

                                        return (
                                            <option value={_name} key={_name}>{_name}</option>
                                        )
                                    })
                                }
                            </select>
                        </div>
                    )
                })
            }
        </div>
    )
}

export default ProductVariantsFilter
