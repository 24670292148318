import React from 'react'
import Setting from './Setting'

const SettingContainer = function (props) {

    return (
        <div className="SettingContainer">
            <Setting {...props}/>
        </div>
    )
}

export default SettingContainer

