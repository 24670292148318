import React from 'react'
import {Input} from "antd"
import {
    SearchOutlined,
} from '@ant-design/icons'

const InputSearch = (props) => {
    const {name, value, label} = props

    const _handleChangeInput = (e) => {
        const {name, value} = e.target
        props.onChange(name, value)
    }

    return (
        <div className="InputSearch">
            <div className="font-weight-500 LabelFilter mb-1">{label}</div>

            <Input value={value} name={name} placeholder='Enter to search...' onChange={_handleChangeInput} style={{width: "100%"}} allowClear/>
            {!value && (
                <span className="icon">
                <SearchOutlined />
            </span>
            )}
        </div>
    )
}

export default InputSearch
