import React, {useState, useEffect} from 'react'
import SwitchCarrierFilters from "./SwitchCarrierFilters"
import SwitchCarrierTable from "./SwitchCarrierTable"
import {getListTrackingScan} from "../../../services/api/ShippingServices"
import {Pagination} from "antd"
import queryString from "qs"

function SwitchCarrierPage(props) {
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState('')
    const [listRequest, setListRequest] = useState([])
    const [page, setPage] = useState(1)
    const [total, setTotal] = useState(0)
    const [limit, setLimit] = useState(50)
    const [created, setCreated] = useState({from: null, to: null})
    const [status, setStatus] = useState('')

    const _handleChangeStatus = (value) => {
        setPage(1)
        setStatus(value)
    }

    const _handleChangeDateCreated = (dates) => {
        if (!!dates && dates.length > 0) {
            setPage(1)
            setCreated({
                from: dates[0],
                to: dates[1]
            })
        } else {
            setPage(1)
            setCreated({
                from: null,
                to: null
            })
        }
    }

    const _fetchListTrackingScan = async () => {
        setLoading(true)
        setError('')

        try {
            const query = {
                page,
                limit,
                status,
                created_at: created
            }
            const response = await getListTrackingScan(queryString.stringify(query))

            const {success, data, message} = response

            if (!success) {
                setLoading(false)

                return setError(message)
            }

            setLoading(false)
            setTotal(data.total)
            setListRequest(data.jobs)
        } catch (e) {
            setLoading(false)
            setError(e.message)
        }
    }

    const _handlePageChange = (page, pageSize) => {
        setLimit(pageSize)
        setPage(pageSize !== limit ? 1 : page)
    }

    useEffect(() => {
        _fetchListTrackingScan()
    }, [page, limit, created, status])

    return (
        <div className="SwitchCarrierPage">
            <div className='PageHeading d-flex align-items-center justify-content-between'>
                <h1 className="PageTitle">Request</h1>
            </div>

            <div className='SectionInner'>
                <SwitchCarrierFilters
                    status={status}
                    onChangeDateCreated={_handleChangeDateCreated}
                    onChangeStatus={_handleChangeStatus}
                />
                <div className="Total">
                    {
                        total > 0 && <div>
                            Showing <span>{(page - 1) * limit + 1} to {(page - 1) * limit + listRequest.length}</span> of <span>{total}</span> items
                        </div>
                    }
                </div>
                <SwitchCarrierTable listRequest={listRequest} loading={loading} error={error}/>
                <Pagination
                    current={page}
                    total={total}
                    pageSize={limit}
                    onChange={_handlePageChange}
                    className='text-right'
                    showSizeChanger pageSizeOptions={['10', '20', '50', '100']}
                />
            </div>
        </div>
    )
}

export default SwitchCarrierPage
