import React, { useState, useEffect } from 'react'
import { Button, message, notification, Spin } from 'antd'
import OrderPartnersFilters from './OrderPartnersFilters'
import OrderPartnersTable from './OrderPartnersTable'
import OrderPartnersProvider from "./OrderPartnersProvider"
import UseDebounce from '../helpers/UseDebounce'
import { searchOrdersSupplierV2, searchOrdersSupplierV2CountItems, getAllowedUpdateTags } from "../../../services/api/OrdersServices"
import { exportPackageManagementNew, exportPackageManagementV4 } from "../../../services/api/ExportOrderServices"
import { UndoOutlined, CloudDownloadOutlined } from '@ant-design/icons'
import { getFiltersStorage } from "../../shared/ManageFiltersState"
import ExportPackage from "./popup/ExportPackage"
import { setLocalData, getLocalData } from "../../../services/StorageServices"
import QuickReportsPage from "./quick-reports/QuickReportsPage"
import SaveFilter from "./quick-reports/SaveFilter"
import ScanTracking from "./tracking/ScanTracking"
import { LIST_SUB, REASON_REJECT } from "../helpers/orderFilters"
import { shipByDays } from '../../statics/components/daily-report/Helper'

const OrderPartnersPage = (props) => {
    const filtersStorage = getFiltersStorage('ordersSupplierV2')
    const { params } = props
    const [page, _setPage] = useState(1)
    const [limit, _setLimit] = useState(50)
    const [loading, _setLoading] = useState(true)
    const [total, _setTotal] = useState(0)
    const [totalItem, _setTotalItem] = useState(0)
    const [error, _setError] = useState('')
    const [ordersLists, _setOrdersLists] = useState([])
    const [link, setLink] = useState('')
    const [sheetName, setSheetName] = useState('')
    const [errorExport, setErrorExport] = useState('')
    const [loadingExport, setLoadingExport] = useState(false)
    const [isVisible, setIsVisible] = useState(false)
    const [typeExport, setTypeExport] = useState('items')
    const [loadingTotalItem, setLoadingTotalItem] = useState(false)
    const [allowedUpdateTags, setAllowedUpdateTags] = useState({})

    const [selectAll, setSelectAll] = useState(false)
    const [listSelected, setListSelected] = useState([])
    const [isCount, setIsCount] = useState(false)

    if (Object.keys(params).length !== 0) setLocalData('ordersSupplierV2.QuickReportItem', {}, false, 'sessionStorage')

    const [filters, _setFilters] = useState(Object.keys(params).length === 0 ? filtersStorage : { ...params })
    const debouncedSearchSupplier = UseDebounce(filters.suppliers, 500)
    const debouncedSearchCustomReference = UseDebounce(filters.custom_references, 500)
    const debouncedSearchNamespace = UseDebounce(filters.namespaces, 500)
    const debouncedSearchPackageName = UseDebounce(filters.names, 500)
    const debouncedSearchTrackingLastNotes = UseDebounce(filters.tracking_last_notes, 500)
    const debouncedSearchTrakingUpdateDelayDays = UseDebounce(filters.tracking_update_delayed_days, 500)
    const debouncedSearchOrderStatusNote = UseDebounce(filters.order_status_note, 1000)

    const _handleChangeSelectAll = (isActive = false) => {
        if (isActive) {
            const listSelectedId = ordersLists.map(item => item._id)
            setListSelected(listSelectedId)
            setSelectAll(true)
        } else {
            setListSelected([])
            setSelectAll(false)
        }
    }

    const _handleSelectedPackage = (id, isAdded) => {
        if (isAdded) {
            if (!listSelected.includes(id)) {
                setListSelected([
                    ...listSelected, id,
                ])
                setSelectAll(listSelected.length === ordersLists.length)
            }
        } else {
            const filtered = listSelected.filter(item => item !== id)

            setListSelected(filtered)
            setSelectAll(false)
        }
    }

    const _onChangeStateOrder = (index, field, value) => {
        const newListOrders = ordersLists.map((item, idx) => {
            if (idx === index) {
                return {
                    ...item,
                    [field]: value,
                }
            }

            return item
        })

        return _setOrdersLists(newListOrders)
    }

    const _onChangePackageNotes = (index, value) => {
        const newListOrders = ordersLists.map((item, idx) => {
            if (idx === index) {
                item.note = value
                return item
            }
            return item
        })
        return _setOrdersLists(newListOrders)
    }

    const _onChangePackageData = (index, field, value) => {
        const newListOrders = ordersLists.map((item, idx) => {
            if (idx === index) {
                item[field] = value
                return item
            }
            return item
        })
        return _setOrdersLists(newListOrders)
    }

    const _handleSetState = (filters, isQuickReport = false) => {
        if (!isQuickReport) setLocalData('ordersSupplierV2.QuickReportItem', {}, false, 'sessionStorage')

        setLocalData('ordersSupplierV2.FiltersState', filters, false, 'sessionStorage')
        const url = window.location.href.split('?')[0]
        window.history.pushState({}, '', url)

        _setFilters(filters)
        _setPage(1)
    }

    const _clearFilters = () => {
        _handleSetState({})
        setIsCount(false)
    }

    const getFilters = () => {
        const subStatus = []
        const anotherPackageStatus = []
        const subReasonReject = []

        if (filters.hasOwnProperty('package_status')) {
            const subList = filters.package_status.filter(item => LIST_SUB.includes(item))
            const packageStatus = filters.package_status.filter(item => !LIST_SUB.includes(item))
            const reasonReject = filters.package_status.filter(item => REASON_REJECT.includes(item))

            subList.map(sub => {
                if (sub === 'cancelled-by-supplier') {
                    subStatus.push('supplier-cancelled')
                } else {
                    subStatus.push(sub)
                }
            })
            // subStatus.push(...subList)

            if (subList.includes('cancel-ship') || subList.includes('cancelled-by-supplier')) {
                anotherPackageStatus.push('cancelled')
            }

            const listException = subList.filter(item => item !== 'cancel-ship' && item !== 'cancelled-by-supplier')

            if (!!listException && listException.length > 0 && listException.filter(item => listException.includes(item))) {
                anotherPackageStatus.push('exception')
            }

            anotherPackageStatus.push(...packageStatus)
            subReasonReject.push(...reasonReject)
        }

        const filtersPayload = Object.assign({}, filters)

        if (filters.hasOwnProperty('delay')) {
            shipByDays.forEach(numDays => {
                if (filters.delay === `ship_delay_gt_days_${numDays}`) {
                    delete filtersPayload.delay
                    filtersPayload.ship_delay_gt_days = numDays
                }
            })
        }


        const payload = {
            ...filtersPayload,
            package_status: [...new Set(anotherPackageStatus)],
            extra_status_reason: subReasonReject.length > 0 ? ["supplier-cancelled"] : [...new Set(subStatus)],
            page,
            limit,
        }

        if (subReasonReject.length > 0) {
            payload.did_actions = [...new Set(subReasonReject)]
            payload.package_status = payload.package_status.filter(item => !REASON_REJECT.includes(item))
        }

        if (payload.hasOwnProperty('did_actions') && payload.did_actions.length === 5) {
            payload.did_actions = filtersPayload.did_actions
            payload.package_status = ["cancelled"]
        }

        const { params } = props
        const { product_type } = Object.assign({}, params)

        if (product_type) {
            payload.product_type = product_type
        }

        return payload
    }

    const _fetchListOrders = async () => {
        try {
            _setLoading(true)
            _setOrdersLists([])
            _setError('')
            _setTotalItem(0)

            const payload = getFilters()
            // const response = await searchOrdersSupplierV2(payload)
            const response = await searchOrdersSupplierV2(payload)

            const { success, message, data } = response

            if (!success) {
                _setLoading(false)
                return _setError(message)
            }

            const { total, fulfillments: fulfillmentsLists } = data

            _setOrdersLists(fulfillmentsLists)

            _setLoading(false)
            _setError('')
            _setTotal(total)

            if(total === 0) return setIsCount(false)
            
            const {limit, page, ...restPayload} = {...payload}
            const isActiveCount = Object.entries(restPayload).some(([key, value]) => {
                return Array.isArray(value) ? !!value.length : !!value
            })

            if(isActiveCount) {
                setIsCount(true)
            }else {
                setIsCount(false)
            }
    
        } catch (e) {
            _setLoading(false)
            _setError(e.message)
        }
    }

    const _fetchCountTotalItems = async () => {
        setLoadingTotalItem(true)
        try {
            const payload = getFilters()
            const responseCountItems = await searchOrdersSupplierV2CountItems(payload)

            if (responseCountItems.success) _setTotalItem(responseCountItems.data)
                
        } catch (e) {
            _setError(e.message)
        }finally{
            setLoadingTotalItem(false)
        }
    }

    const _handleSetPage = (page) => {
        _setPage(page)
        setSelectAll(false)
        setListSelected([])
    }

    const _handleSetSize = (size) => {
        _setLimit(size)
        setSelectAll(false)
        setListSelected([])
    }

    function _openModal() {
        setIsVisible(true)
    }

    function _closeModal() {
        setIsVisible(false)
    }

    function _handleChangeLink(value) {
        setLink(value)
        setLocalData('export.package.link', value)
    }

    function _handleChangeName(value) {
        setSheetName(value)
        setLocalData('export.package.sheetName', value)
    }

    function _handleChangeTypeExport(value) {
        setTypeExport(value)
    }

    async function exportPackageNew() {
        setLoadingExport(true)
        setErrorExport('')

        try {
            const payload = {
                ...getFilters(),
                page,
                limit,
                link,
                sheetName,
            }

            const response = await exportPackageManagementNew(payload)

            const { success, message: error } = response

            if (!success) {
                setLoadingExport(false)
                setErrorExport(error)

                return
            }

            setLoadingExport(false)
            _closeModal()

            notification.success({
                message: 'Export success',
                description: `Please check sheet ${sheetName} in link ${link}.`,
            })


        } catch (e) {
            setLoadingExport(false)
            setErrorExport(e.message)
        }
    }

    async function exportPackage() {
        setLoadingExport(true)
        setErrorExport('')

        try {
            const payload = {
                ...getFilters(),
                page,
                limit,
                link,
                sheetName,
            }

            const response = await exportPackageManagementV4(payload)

            const { success, message: error } = response

            if (!success) {
                setLoadingExport(false)
                setErrorExport(error)

                return
            }

            setLoadingExport(false)
            _closeModal()

            notification.success({
                message: 'Export success',
                description: `Please check sheet ${sheetName} in link ${link}.`,
            })


        } catch (e) {
            setLoadingExport(false)
            setErrorExport(e.message)
        }
    }

    const _fetchAllowedUpdateTags = async () => {
        try {
            const res = await getAllowedUpdateTags()
            const {success, data, message:mess} = {...res}
            if(!success) {
                throw new Error(mess)
            }
            setAllowedUpdateTags(data)
        } catch (error) {
            message.error(error.message)
            console.log(error.message)
        }
    }

    useEffect(() => {
        _fetchListOrders()
    }, [
        page, limit, debouncedSearchSupplier, debouncedSearchPackageName, debouncedSearchNamespace, debouncedSearchTrackingLastNotes,
        debouncedSearchCustomReference, debouncedSearchTrakingUpdateDelayDays, filters.production_status, filters.shipping_carrier, filters.package_status,
        filters.tracking_status, filters.delay, filters.delayed_days, filters.pushed_type, filters.pushed_at, filters.packaged_at, debouncedSearchOrderStatusNote,
        filters.shipping_zone, filters.order_type, filters.store_mode, filters.shipping_level, filters.tracking_company, filters.tracking_modified,
        filters.llc_delay_us_hub, filters.did_actions
    ],
    )

    useEffect(() => {
        const link = getLocalData('export.package.link')
        const name = getLocalData('export.package.sheetName')

        setLink(link)
        setSheetName(name)
        _fetchAllowedUpdateTags()
    }, [])

    const permissionsData = !!getLocalData('permissions') ? Object.keys(getLocalData('permissions')) : []

    return (
        <OrderPartnersProvider
            filters={filters}
            listSelected={listSelected}
            packageLists={ordersLists}
            onChangeListOrder={_onChangeStateOrder}
            onChangePackageNotes={_onChangePackageNotes}
            onChangePackageData={_onChangePackageData}
            setState={_handleSetState}
            loadOrders={_fetchListOrders}
            allowedUpdateTags={allowedUpdateTags}
        >
            <div className="QuickReportContainer">
                <QuickReportsPage />
            </div>

            <div className="NotificationPage pt-3">
                <div className="d-flex flex-column flex-md-row align-items-md-center justify-content-between mt-3 mb-3">
                    <h1 className="PageTitle">Package Management</h1>
                    <div className="d-flex align-items-center justify-content-end mr-lg-0 mr-3">
                        <div className="total mr-3">
                            {
                                !totalItem ?
                                    <React.Fragment>
                                        {
                                            loadingTotalItem ? <>
                                                <Spin /> / <strong>{`${total} packages`}</strong>
                                            </> : <>
                                                <strong 
                                                    className='cursor-pointer text-primary' 
                                                    onClick={() => _fetchCountTotalItems()}
                                                    style={{pointerEvents: isCount ? 'unset' : 'none', opacity: isCount ? 1 : 0.5}}
                                                >Count</strong> / <strong>{`${total} packages`}</strong>
                                            </>
                                        }
                                    </React.Fragment> :
                                    <strong>{`${totalItem} items/${total} packages`} </strong>
                            }
                        </div>
                        <Button onClick={_clearFilters}
                            disabled={loading}
                        >
                            <UndoOutlined /> Clear filter
                        </Button>
                        <div className="ml-3">
                            <SaveFilter filters={filters} disabled={loading} />
                        </div>
                        {permissionsData.includes('ffm_package_scan_tracking') &&
                            <div className="ml-3">
                                {
                                    !!ordersLists && ordersLists.length <= 10000 &&
                                    <ScanTracking filters={filters} disabled={loading} />
                                }
                            </div>
                        }
                        {permissionsData.includes('ffm_package_export') &&
                            <Button type="primary"
                                className="ml-3"
                                onClick={_openModal}
                                disabled={loading}
                            >
                                <CloudDownloadOutlined />
                                Export package
                            </Button>
                        }
                    </div>
                </div>

                <div className="SectionInner">
                    <div className="filter mb-3 align-items-center">
                        <div className="d-flex align-items-center justify-content-between">
                            <OrderPartnersFilters loading={loading} />
                        </div>
                    </div>
                    <div className="table">
                        {error && <div className="text-danger">{error}</div>}

                        <OrderPartnersTable
                            loading={loading}
                            limit={limit}
                            selectAll={selectAll}
                            listSelected={listSelected}
                            page={page}
                            total={total}
                            orders={ordersLists}
                            onChangeSize={_handleSetSize}
                            onChangePage={_handleSetPage}
                            onChangeSelectAll={_handleChangeSelectAll}
                            onChangeSelectedPackage={_handleSelectedPackage}
                            allowedUpdateTags={allowedUpdateTags}
                        />
                    </div>
                </div>
                <ExportPackage
                    link={link}
                    sheetName={`${sheetName}`}
                    error={errorExport}
                    loading={loadingExport}
                    isVisible={isVisible}
                    typeExport={typeExport}
                    onCancel={_closeModal}
                    onChangeLink={_handleChangeLink}
                    onChangeName={_handleChangeName}
                    onChangeTypeExport={_handleChangeTypeExport}
                    onUpdate={typeExport === 'items' ? exportPackage : exportPackageNew}
                />
            </div>
        </OrderPartnersProvider>
    )
}

export default OrderPartnersPage
