import React from 'react'
import PropTypes from 'prop-types'
import moment from "moment";
import StatusStyle from "../../../shared/StatusStyle";
import ConfirmCharge from "./confirm-charge/ConfirmCharge";
import {Link} from "react-router-dom";

RequestItem.propTypes = {
    item: PropTypes.object
}

function RequestItem(props) {
    const {item} = props

    return (
        <tr className='RequestItem'>
            <td className="RequestId">{item._id}</td>
            <td className="Type">
                <span>{item.request_type === 'update_design' ? 'Design' : 'Address'}</span>
                <div className="MoreInfo">
                    {item.need_charge === true && <span className="Charge">charge</span>}
                    {
                        (item.need_charge === true && item.progress_status !== 'hold') &&
                        <span className={item.charge_status || 'NoCharge'}>
                                {item.charge_status || 'no charge'}
                            </span>
                    }
                </div>
            </td>
            <td className="Source">{item.source}</td>
            <td className="Source StatusProduction">
                <StatusStyle status={item.production_status}>{item.production_status}</StatusStyle>
            </td>
            <td className="Info">
                <span>{item.store_id}</span>
                <span>
                    <Link to={{
                        pathname: `/a/orders/${item.order}`
                    }}>{item.order_number}</Link>
                </span>
            </td>
            <td className="Content">{item.content}</td>
            <td className="Created">{moment(item.created_at).format('HH:mm DD/MM/YYYY')}</td>
            <td className="Updated">{moment(item.updated_at).format('HH:mm DD/MM/YYYY')}</td>
            <td className="Status">
                <div className="d-flex align-items-center">
                    <div className={`itemStatus ${item.progress_status}`}>
                        {item.progress_status}
                    </div>
                    {(item.progress_status === 'hold') && <ConfirmCharge {...props}/>}
                </div>
            </td>
        </tr>
    )
}

export default RequestItem
