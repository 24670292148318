import React, {Component} from "react"
import {settings} from '../../../statics/settings'
import {getLocalData} from '../../../services/StorageServices'

const menus = settings

class SettingsPage extends Component {
    _pushPage = (index) => e => {
        e.preventDefault()
        if (menus[index]) return this.props.history.push(menus[index].location)
    }

    render() {
        const user = getLocalData('user')
        const permissionsData = !!getLocalData('permissions') ? Object.keys(getLocalData('permissions')) : []

        return (
            <section className="SettingsContainer">
                <h1 className="PageTitle">Settings</h1>

                <div className="SectionInner">
                    <div className="card-body Card">
                        <div className="SettingsWrapper row">
                            {
                                settings.map((setting, index) => {
                                    if (setting.permission && !permissionsData.includes(setting.permission)) {
                                        return <div/>
                                    }
                                    return <div className="SettingWrapper col-lg-4 col-12" key={index}
                                                onClick={this._pushPage(index)}>
                                        <div className="SettingInner d-flex">
                                            <div className="IconWrapper">
                                                <i className={setting.icon}/>
                                            </div>
                                            <div className="Info">
                                                <h6 className="Title">{setting.title}</h6>
                                                <div className="Description">{setting.description}</div>
                                            </div>
                                        </div>
                                    </div>
                                })
                            }
                        </div>
                    </div>
                </div>
            </section>
        )
    }
}

export default SettingsPage
