import React, {useContext} from 'react'
import InputSearch from "./filters/InputSearch"
import SelectSearch from "./filters/SelectSearch"
import {SEND_STATUS, UPLOAD_STATUS} from "../helpers/notificationFilters"
import NotificationContext from './../context/NotificationContext'

const NotificationFilters = () => {
    const {filters, setState} = useContext(NotificationContext)

    const _handleChangeInput = (name, value) => {
        const _filters = {
            ...filters,
            [name]: value
        }
        setState(_filters)
    }

    const _handleChangeSelect = (name, value) => {
        const _filters = {
            ...filters,
            [name]: value
        }
        setState(_filters)
    }

    return (
        <div className="NotificationFilters d-flex">
            <div className="itemFilter mb-3 mr-3">
                <InputSearch name="store_id" onChange={_handleChangeInput} label="Store ID:" value={filters.store_id}/>
            </div>
            <div className="itemFilter mr-3 mb-3">
                <InputSearch name="order_number" onChange={_handleChangeInput} label="Order number:" value={filters.order_number}/>
            </div>
            <div className="itemFilter mr-3 mb-3">
                <SelectSearch name="upload_status" onChange={_handleChangeSelect} label="Status upload:" listSelect={UPLOAD_STATUS}/>
            </div>
            <div className="itemFilter mr-3 mb-3">
                <SelectSearch name="send_status" onChange={_handleChangeSelect} label="Status send:" listSelect={SEND_STATUS}/>
            </div>
        </div>
    )
}

export default NotificationFilters
