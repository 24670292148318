import React, {Component} from 'react'
import {Modal, notification} from "antd"
import {removeNoteOrders} from "../../../../../services/api/OrdersServices"

const _doAction = async (params) => {
    try {
        const {selectedOrders, onSuccess, resetSelect} = params
        const {success, message: error} = await removeNoteOrders({orders: selectedOrders})

        if (!success) {
            throw new Error(error)
        }

        Modal.success({
            title: 'Remove note orders successfully!',
            width: 460
        })

        if (typeof resetSelect === 'function') {
            resetSelect()
        }

        if (typeof onSuccess === 'function') {
            onSuccess()
        }

    } catch (e) {
        notification.error({
            message: 'ERROR',
            description: e.message,
            duration: 0,
        })
    }
}

class BulkRemoveNote extends Component {
    _handleClick = e => {
        e.preventDefault()

        const props = this.props

        Modal.confirm({
            title: `Please confirm remove note orders`,
            onOk() {
                return _doAction(props)
            },
        })
    }

    render() {
        return (
            <div className='BulkRemoveNote' onClick={this._handleClick}>
                Remove note
            </div>
        )
    }
}

export default BulkRemoveNote
