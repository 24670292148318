import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { getRoleList, getPermissionList } from '../../../services/api/UserService'
import { message, Pagination, Spin } from 'antd'
import ModalCreateRole from './ModalCreateRole'
import ModalEditRole from './ModalEditRole'
import RolesTable from './RolesTable'

function RolesPage() {
    const [page, setPage] = useState(1)
    const [limit, setLimit] = useState(50)
    const [total, setTotal] = useState(0)
    const [listRole, setListRole] = useState([])
    const [listPermission, setListPermission] = useState([])
    const [loading, setLoading] = useState(true)
    const [showCreateModal, setShowCreateModal] = useState(false)
    const [showEditModal, setShowEditModal] = useState(false)
    const [editId, setEditId] = useState('')

    const fetchListRole = async () => {
        setLoading(true)
        try {
            const payload = {
                page: page,
                limit: limit,
            }
            const { data, success, message: mess } = await getRoleList(payload)
            if (!success) {
                return message.error(mess)
            }
            setListRole(data.roles || [])
            setLimit(data.limit)
            setPage(data.page)
            setTotal(data.total)
            setLoading(false)
        } catch (error) {
            if (error.response.status !== 200) {
                message.error(error.response.data.message)
            } else {
                message.error(error.message)
            }
        } finally {
            setLoading(false)
        }
    }

    const getListPermission = async () => {
        try {
            const payload = {
                page: 1,
                limit: 10000,
            }
            const { data, success, message: mess } = await getPermissionList(payload)
            if (!success) return message.error(mess)
            setListPermission(data.permissions)
        } catch (error) {
            if (error.response.status !== 200) {
                message.error(error.response.data.message)
            } else {
                message.error(error.message)
            }
        }
    }

    const handPagePagination = (value) => {
        setPage(value)
    }

    const handleShowSizeChange = (current, size) => {
        setLimit(size)
        setPage(1)
    }

    useEffect(() => {
        getListPermission()

    }, [])

    useEffect(() => {
        fetchListRole()
    }, [page, limit, total])

    const openCreateModal = () => {
        setShowCreateModal(true)
    }
    const closeCreateModal = () => {
        setShowCreateModal(false)
    }
    const openEditModal = (id) => {
        setEditId(id)
        setShowEditModal(true)
    }
    const closeEditModal = () => {
        setShowEditModal(false)
        setEditId('')
    }

    return (
        <div className="Setting">
            <div className="container-fluid">
                <div className="BackWrapper">
                    <Link className="nav-link BackButton" to="/a/settings/users">
                        <i className="fas fa-chevron-left" />
                        <span>Users Settings</span>
                    </Link>
                </div>
                <div className="MenuTitle">
                    Roles Setting
                </div>
                <div className="ListingUserPage pt-3">
                    <div className='row justify-content-end mb-3 pr-3'>
                        <div className="UserCreate text-right">
                            <button
                                className='btn btn-primary'
                                onClick={openCreateModal}
                            >
                                New Roles
                            </button>
                        </div>
                    </div>

                    <div className='SectionInner'>
                        <div className='filter align-items-center'>
                        </div>
                        <div className="mt-3">
                            <Spin spinning={loading} tip='Data Loading...'>
                                <RolesTable
                                    listRole={listRole}
                                    refresh={fetchListRole}
                                    openEditModal={openEditModal}
                                />
                                <Pagination
                                    current={page}
                                    total={total}
                                    pageSize={limit}
                                    showTotal={(sum, range) => sum > 0 &&
                                        <div>
                                            {`Hiển thị ${range[0]} đến ${range[1]} của `}<strong>{`${total}`}</strong> roles
                                        </div>}
                                    onChange={handPagePagination}
                                    className='text-right' showSizeChanger
                                    pageSizeOptions={['10', '20', '50', '100']}
                                    onShowSizeChange={handleShowSizeChange}
                                />
                            </Spin>
                        </div>
                    </div>
                    <ModalCreateRole
                        visible={showCreateModal}
                        onOpen={openCreateModal}
                        onClose={closeCreateModal}
                        listPermission={listPermission}
                        reset={fetchListRole}
                    />
                    <ModalEditRole
                        visible={showEditModal}
                        onClose={closeEditModal}
                        listPermission={listPermission}
                        editId={editId}
                        reset={fetchListRole}
                    />
                </div>
            </div>
        </div>
    )
}

export default RolesPage