import React, {Component} from 'react'
import OrderPage from "./OrderPage"

class OrderPageContainer extends Component {

    render() {
        const orderId = this.props.match.params.id
        const historyState = this.props.location.state
        const vHistoryState = Object.assign({}, historyState)
        const {fromOrdersPage = 1} = vHistoryState

        return (
            <div className="OrderPageContainer">
                <OrderPage orderId={orderId} fromOrdersPage={fromOrdersPage}/>
            </div>
        )
    }
}

export default OrderPageContainer
