import React from 'react'
import PropTypes from 'prop-types'
import humanizeTime from "../../../../helpers/time/humanizeTime"
import ObjectInspector from "react-object-inspector"
import { useState } from 'react'
import { Tooltip } from 'antd'
import PackageCheckDetail from './PackageCheckDetail'

OrderHistoriesItem.propTypes = {
    history: PropTypes.object,
    key: PropTypes.string
}

function OrderHistoriesItem(props) {
    const [isOpenCheckDetail, setIsOpenCheckDetail] = useState(false)
    
    const _handleOpenCheckDetail = (e) => {
        e.preventDefault()

        setIsOpenCheckDetail(true)
    }

    const _handleCloseCheckDetail = () => {
        setIsOpenCheckDetail(false)
    }
    const {event, description, created, value} = props.history
    const eventNeedCheckDetail = /^order(auto)?_push_to(.*)$/.test(event)
    return (
        <div className="OrderHistoriesItem">
            <div className="card">
                <div className="d-flex align-items-center justify-content-start card-header">
                    <span className='card-header-time'>
                        {humanizeTime(created)}
                    </span>
                    {
                        eventNeedCheckDetail &&
                        <Tooltip title="get order detail from supplier by history">
                            <button onClick={_handleOpenCheckDetail} className="btn btn-primary ml-auto">
                                Check Detail
                            </button>
                        </Tooltip>
                    }
                </div>

                <div className="card-body">
                    <div className="row align-items-baseline mb-3">
                        <div className="col-4">
                            <code>{event}</code>
                        </div>

                        <div className="col-8 text-muted">{description}</div>
                    </div>

                    <div className="row align-items-baseline">
                        <div className="col-4">Value</div>
                        <div className="col-8">
                            <ObjectInspector depth={0} path="root.value" data={value}/>
                        </div>
                    </div>
                </div>
            </div>

            {
                eventNeedCheckDetail &&
                <PackageCheckDetail
                    orderHistoryId={props.key}
                    isOpen={isOpenCheckDetail}
                    onClose={_handleCloseCheckDetail}
                />
            }
        </div>
    )
}

export default OrderHistoriesItem