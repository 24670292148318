import React, {useState} from 'react'
import PropTypes from 'prop-types'
import isMatchVariant from "../../../product/helpers/isMatchVariant"

ProductVariantPfVariant.propTypes = {
    pfSku: PropTypes.string,
    variantIndex: PropTypes.number.isRequired,
    onChangeVariant: PropTypes.func.isRequired,
    totalVariants: PropTypes.number.isRequired,
}

function ProductVariantPfVariant(props) {
    const {pfSku, variantIndex, totalVariants, onChangeVariant, onChangeAllVariant, variant, variants, filter, disableAll} = props
    const [isPaste, setIsPaste] = useState(false)

    const _handleChange = e => {
        const {value} = e.target

        if (isPaste) return

        if (typeof onChangeVariant === 'function') {
            onChangeVariant(variantIndex, 'partner_sku', value)
        }
    }

    const _handlePasteInput = e => {
        setIsPaste(true)

        const copyText = e.clipboardData.getData('Text')
        const parseText = copyText.split(/[\n\t\r\s]+/)
        let count = 0
        const newVariants = variants.map((v, i) => {
            if (i < variantIndex || count >= parseText.length) {
                return v
            }
            if(isMatchVariant(filter, v )){
                return {
                    ...v,
                    partner_sku: parseText[count++]
                }
            }
            return {...v}
            
        })

        onChangeAllVariant(newVariants)

        setTimeout(() => {
            setIsPaste(false)
        }, 50)
    }

    return (
        <td className='ProductVariantQuantity'>
            <input
                id={`product-variant-${variant._id}`}
                className="form-control"
                value={pfSku || ''}
                onChange={_handleChange}
                onPaste={_handlePasteInput}
                disabled={disableAll}
            />
        </td>
    )
}

export default ProductVariantPfVariant
