import React, {Component} from 'react'
import PropTypes from 'prop-types'
import NumberFormat from "react-number-format"
import {Checkbox, Select, AutoComplete} from "antd"
import formatThousandNumber from "../../../../../helpers/common/formatThousandNumber"

const {Option} = Select

class FormCharge extends Component {
    constructor(props) {
        super(props)

        this.state = {
            loading: false,
            error: '',
            typePrice: 'price',
            valuePrice: 0,
            valuePercent: 0,
            total: 0,
            validateTag: '',
            validateSupport: '',
            validateReason: '',
            reasonTag: '',
            reasonTagText: '',
            reasonSeller: '',
            supporter: '',
            showForm: true
        }
    }

    _convertString = (string) => {
        const {currency} = this.props
        let result = string

        if (typeof string === 'string') {
            if (currency === 'USD') {
                result = string.replaceAll(/[,]/g, '')
            } else {
                result = string.replaceAll(/[.]/g, '')
            }
        }

        return result
    }

    _convertPercent = (resultPrice, total) => {
        const newResult = this._convertString(resultPrice)
        const percent = (parseFloat(newResult) / parseFloat(total)) * 100

        return parseFloat(formatThousandNumber(percent.toFixed(2)))
    }

    _convertPrice = (percent, total) => {
        const newPercent = this._convertString(percent)
        const price = (parseFloat(newPercent) / 100) * total

        return parseFloat(formatThousandNumber(price.toFixed(2)))
    }

    componentDidMount() {
        const {invoiceRefund, showForm} = this.props
        const {maxRefund} = invoiceRefund
        const resultRefund = parseFloat(maxRefund)

        this.setState({
            total: maxRefund || 0,
            valuePrice: resultRefund,
            valuePercent: this._convertPercent(resultRefund, maxRefund),
            showForm: showForm
        }, () => {
            const {valuePrice, valuePercent} = this.state
            const {onChangeValueForm} = this.props

            if (typeof onChangeValueForm === 'function') {
                onChangeValueForm('valuePercent', valuePercent)
                onChangeValueForm('valuePrice', valuePrice)
            }
        })
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const {showForm} = this.props
        if (prevProps.showForm !== showForm) {
            this.setState({
                showForm: showForm
            })
        }
    }

    _handleChangeCheckBox = (e) => {
        const {checked} = e.target
        const {onChangeShowForm} = this.props

        if (typeof onChangeShowForm === 'function') {
            onChangeShowForm(checked)
        }
    }

    _handleChangeSelect = (value,e) => {
        this.setState({
            reasonTag: e.key,
            reasonTagText: value,
            reasonSeller: e.value
        }, () => {
            const {onChangeValueForm, resetValidate} = this.props

            if (typeof onChangeValueForm === 'function') {
                onChangeValueForm('reasonTag', e.key)
                onChangeValueForm('reasonSeller', e.value)
                resetValidate('reasonTag', e.key)
                resetValidate('reasonSeller', e.value)
            }
        })
    }

    _handleChangeInput = (e) => {
        const {name, value} = e.target
        this.setState({
            [name]: value
        }, () => {
            const {onChangeValueForm, resetValidate} = this.props

            if (typeof onChangeValueForm === 'function') {
                onChangeValueForm(name, value)
                resetValidate(name, value)
            }
        })
    }

    _handleChangeType = (type) => {
        const {valuePercent, valuePrice} = this.state

        this.setState({
            typePrice: type
        }, () => {
            this.setState({
                valuePrice: valuePrice,
                valuePercent: valuePercent
            }, () => {
                const {valuePrice, valuePercent} = this.state
                const {onChangeValueForm} = this.props

                onChangeValueForm('valuePercent', valuePercent)
                onChangeValueForm('valuePrice', valuePrice)
            })
        })
    }

    _handleChangeValue = (e) => {
        const {value} = e.target
        const {typePrice} = this.state

        if (typePrice === 'price') {
            this.setState({
                valuePrice: value
            }, () => {
                const {valuePrice, total} = this.state

                this.setState({
                    valuePercent: this._convertPercent(valuePrice, total)
                })

                const {onChangeValueForm} = this.props

                if (typeof onChangeValueForm === 'function') {
                    onChangeValueForm('valuePrice', value)
                }
            })
        } else {
            this.setState({
                valuePercent: value
            }, () => {
                const {valuePercent, total} = this.state

                this.setState({
                    valuePrice: this._convertPrice(valuePercent, total)
                })

                const {onChangeValueForm} = this.props

                if (typeof onChangeValueForm === 'function') {
                    onChangeValueForm('valuePercent', value)
                    onChangeValueForm('valuePrice', this._convertPrice(valuePercent, total))
                }
            })
        }

    }

    handleFilter = (inputValue, option) => {
        return option.value.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
    } 

    render() {
        const {currency, validatePrice, validateReasonTag, validateReasonSeller, tags, validateSupporter} = this.props
        const {valuePrice, valuePercent, typePrice, reasonTag, reasonTagText, showForm, reasonSeller} = this.state
        const reasonFFM = tags.length > 0 && tags.find(item => item._id === reasonTag)

        const reasonTagOptions = !!tags && [
            ...tags.map(item=> ({
                value: item.description,
                label: item.value,
                key: item._id
            }))
        ]

        return (
            <div className='FormRefund'>
                <div className="TopForm">
                    <h3 className='Title'>Charge information</h3>
                    <div className="ToggleRefund">
                        <Checkbox checked={showForm} onChange={this._handleChangeCheckBox}>Charge order</Checkbox>
                    </div>
                </div>

                <div className="ContentForm">
                    <div className="d-flex align-items-center">
                        <div className={`Left ${!!validatePrice && validatePrice !== '' ? 'Error' : ''}`}>
                            <label htmlFor="Value">Value</label>
                            <NumberFormat
                                id='Value'
                                value={typePrice === 'price' ? valuePrice : valuePercent}
                                thousandSeparator={currency === 'USD' ? true : '.'}
                                decimalSeparator={currency === 'USD' ? '.' : ','}
                                decimalScale={currency === 'USD' ? 2 : 0}
                                onChange={this._handleChangeValue}
                            />
                            <span
                                className={`ChangeType ${typePrice === 'price' ? 'active' : ''}`}
                                onClick={() => this._handleChangeType('price')}
                            >
                                $
                            </span>
                            <span
                                className={`ChangeType Last ${typePrice === 'percent' ? 'active' : ''}`}
                                onClick={() => this._handleChangeType('percent')}
                            >
                                %
                            </span>

                            {
                                (!!validatePrice && validatePrice !== '') && <p className='text-danger'>
                                    {validatePrice}
                                </p>
                            }
                        </div>
                    </div>

                    <div className="d-flex align-items-center">
                        <div className={`Left ${!!validateReasonTag && validateReasonTag !== '' ? 'Error' : ''}`}>
                            <label htmlFor="ReasonTag">Reason tag</label>
                            <AutoComplete
                                id='ReasonTag'
                                value={reasonTagText}
                                defaultValue={reasonTagText}
                                options={reasonTagOptions}
                                style={{width: '64%'}}
                                placeholder='Select reason tag'
                                onChange={this._handleChangeSelect}
                                filterOption={(inputValue, option)=> this.handleFilter(inputValue, option)}
                            >
                            </AutoComplete>

                            {
                                (!!validateReasonTag && validateReasonTag !== '') && <p className='text-danger'>
                                    {validateReasonTag}
                                </p>
                            }
                        </div>

                        {
                            !!reasonFFM && reasonFFM.name === 'ffm' &&
                            <div className={`Right ${!!validateSupporter && validateSupporter !== '' ? 'Error' : ''}`}>
                                <label htmlFor="Supporter">Supporter</label>
                                <input type="text" name='supporter' placeholder='Add supporter...'
                                       onChange={this._handleChangeInput}/>

                                {
                                    (!!validateSupporter && validateSupporter !== '') && <p className='text-danger'>
                                        {validateSupporter}
                                    </p>
                                }
                            </div>
                        }
                    </div>

                    <div className={`Reason ${!!validateReasonSeller && validateReasonSeller !== '' ? 'Error' : ''}`}>
                        <label htmlFor="Reason">Reason for seller</label>
                        <textarea id='Reason' name='reasonSeller' placeholder='Send for seller...' value={reasonSeller || ''}
                                  onChange={this._handleChangeInput}/>

                        {
                            (!!validateReasonSeller && validateReasonSeller !== '') && <p className='text-danger'>
                                {validateReasonSeller}
                            </p>
                        }
                    </div>
                </div>
            </div>
        )
    }
}

FormCharge.propTypes = {
    orderId: PropTypes.string,
    invoiceRefund: PropTypes.object,
    currency: PropTypes.string,
    onChangeShowForm: PropTypes.func
}

export default FormCharge
