import React from 'react'
import PropTypes from 'prop-types'
import Link from "react-router-dom/Link"
import formatCurrency from "../../../../helpers/common/formatCurrency"
import imageDefault from "../../../../helpers/common/imageDefault"

OrderItemMap.propTypes = {
    imageObject: PropTypes.object,
    product: PropTypes.object,
    currency: PropTypes.string,
    base_cost: PropTypes.number,
    additional_price: PropTypes.number,
    options: PropTypes.array,
    sku: PropTypes.string
}

function OrderItemMap(props) {
    const { imageObject, product, currency, base_cost, additional_price, options, sku, orderType, quantity, isCheckVersionOrderPartnerV2 } = props

    return (
        <td className="OrderItemMap">
            <div className="row">
                <div className="col-4">
                    <div className="ThumbnailWrapper position-relative">
                        {
                            !!imageObject && imageObject.hasOwnProperty("url") &&
                            <img
                                src={imageObject.url}
                                alt="Product line thumbnail"
                                onError={(e) => { e.target.onerror = null; e.target.src = imageDefault }}
                            />
                        }
                    </div>
                </div>

                <div className="col-8">
                    <Link to={`/a/products/${product._id}`} className="mb-2 d-block">{product.title}</Link>

                    <div className="d-flex mb-1">
                        <span className="Label font-weight-500">Price:</span>
                        <span className="Value">{formatCurrency(currency, base_cost)}</span>
                    </div>

                    {
                        (additional_price !== undefined && additional_price > 0 && orderType !== "FBA") &&
                        <div className="d-flex mb-1">
                            <span className="Label font-weight-500">Additional Price:</span>
                            <span className="Value">{formatCurrency(currency, additional_price)}</span>
                        </div>
                    }

                    {
                        !!options && options.length > 0 && options.map(option => {
                            const { attribute, name: optionName } = option
                            const { name: attributeName } = attribute

                            return (
                                <div className="d-flex mb-1" key={option._id}>
                                    <span className="Label font-weight-500">{attributeName}:</span>
                                    <span className="Value">{optionName}</span>
                                </div>
                            )
                        })
                    }

                    <div className="d-flex mb-1">
                        <span className="Label font-weight-500">SKU:</span>
                        <span className="Value">{sku}</span>
                    </div>

                    {(quantity && isCheckVersionOrderPartnerV2) && <div className="d-flex mb-1">
                        <span className="Label font-weight-500">Quantity:</span>
                        <span className="Value">{quantity}</span>
                    </div>}
                </div>
            </div>
        </td>
    )
}

export default OrderItemMap