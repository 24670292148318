import React from 'react'
import { Link } from 'react-router-dom'
import { Tooltip } from 'antd'
import moment from 'moment'


function SettingHolidayRow(props) {
    const { item } = { ...props }
    const { _id, name, holidays = [] } = { ...item }

    const convertUTC = (so) => {
        const gio = Math.floor(so)
        const phut = (so - gio) * 60

        const chuoi = `${so >= 0 ? 'UTC +' : 'UTC -'}${Math.abs(gio).toString().padStart(2, '0')}:${phut < 10 ? '0' : ''}${phut}`
        return chuoi
    }

    return (
        holidays.length > 0 ? holidays.map((i, index) => (
            <tr key={`splitBox_${index}`} className={`rowSplitBox ${index > 0 ? 'topDashed' : ''}`}>
                {index === 0 &&
                    <>
                        <td rowSpan={holidays.length}>
                            {name}
                        </td>
                    </>
                }
                <td>{i.name || '-'}</td>
                <td>
                    {i.start_time ? moment(i.start_time).utcOffset(0).format('DD/MM/YYYY') : '-'}
                    {i.start_time && i.end_time ? ` - ${moment(i.end_time).utcOffset(0).format('DD/MM/YYYY')}` : ''}
                </td>
                <td>{i.cutoff_time ? moment(i.cutoff_time).utcOffset(0).format('DD/MM/YYYY') : '-'}</td>
                {index === 0 &&
                    <>
                        <td rowSpan={holidays.length}>{holidays.length > 0 && holidays[0].pre_cutoff_days ? holidays[0].pre_cutoff_days : '-'}</td>
                        <td rowSpan={holidays.length}>{holidays.length > 0 && holidays[0].time_zone ? convertUTC(holidays[0].time_zone) : '-'}</td>
                        <td>
                            <Link to={`/a/setting-holiday/${_id}`}>
                                <Tooltip title={`Edit`} placement="right">
                                    <i className="fa fa-pencil fa-lg text-primary mr-2" />
                                </Tooltip>
                            </Link>
                        </td>
                    </>
                }
            </tr>
        )) : <tr>
            <td>{name || '-'}</td>
            <td>-</td>
            <td>-</td>
            <td>-</td>
            <td>-</td>
            {/* <td>-</td> */}
            <td>-</td>
            <td>
                <Link to={`/a/setting-holiday/${_id}`}>
                    <Tooltip title={`Edit`} placement="right">
                        <i className="fa fa-pencil fa-lg text-primary mr-2" />
                    </Tooltip>
                </Link>
            </td>
        </tr>
    )
}

export default SettingHolidayRow