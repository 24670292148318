import React, {Component} from 'react'
import humanizeTime from "../../../../../helpers/time/humanizeTime"
import PropTypes from 'prop-types'
import {deleteUser, deleteUserAuth} from '../../../../../services/api/UserService'
import getHistory from '../../../../../store/getHistory'
import {UncontrolledTooltip} from 'reactstrap';
import { Switch, Tag } from 'antd'

class ListingUsersTableRow extends Component {
    state = {
        username: '',
        role: ''
    }

    componentDidMount() {
        this._fetchDetailUser()
    }

    _fetchDetailUser = async () => {
        const {user} = this.props
        const {username, role} = user

        this.setState({
            username,
            role
        })

    }

    _handleClickChangePassword = () => {
        const {user, openModalEdit} = this.props
        openModalEdit(user.email, user._id)
    }

    _handleClickDeleteUser = async () => {
        const isSure = window.confirm('Are you sure delete user?')
        if (!isSure) return

        try {
            const {user: {_id: userId}, refresh} = this.props
            const {success, message} = await deleteUserAuth(userId)
            if (!success) throw new Error(message)

            refresh()
        } catch (e) {
            alert(e.message)
        }
    }

    _handleChangePassword = () => {
        const history = getHistory()
        history.push('/a/change-password')
    }

    render() {
        const {user, index, departments, permissionsData} = this.props
        const { username, created_at, is_active} = user
        const timeText = humanizeTime(created_at)

        return (
            <tr className="ListingUsersTableRow">
                <td>{index + 1}</td>
                <td>{username}</td>
                <td>{timeText}</td>
                <td className="StatusNew">
                    {
                        // users.hasOwnProperty('user_management') &&
                        <Switch
                            checkedChildren="Activate"
                            unCheckedChildren="Deactivate"
                            checked={is_active}
                        // onClick={_onChangeActive}
                        />
                    }
                </td>
                {/* <td>{role==='admin'? `Admin` : 'User'}</td> */}
                <td className="Roles">
                    {
                        user.hasOwnProperty('roles') && user.roles.length > 0 && user.roles.map((item) => (
                            <Tag key={item._id}>{item.name}</Tag>
                        ))
                    }
                </td>
                <td>
                    {!!user.department && !!departments && departments.length > 0 && departments.filter(i=>i._id===user.department).map((i,index)=>(
                        <Tag key={i._id}>{i.name}</Tag>
                    ))}
                </td>
                {permissionsData.includes('ffm_setting_users_update') &&
                // (permissionsData.includes('ffm_setting_users_update') || permissionsData.includes('ffm_setting_users_delete')) &&
                    <td>
                        <div className='btn-group'>
                            {permissionsData.includes('ffm_setting_users_update') &&
                                <div style={{ paddingRight: 10 }}>
                                    <span className='text-primary cursor-pointer' href="#" id="change_password" onClick={this._handleClickChangePassword}>
                                        <i className={'ti-pencil-alt'} />
                                    </span>
                                    <UncontrolledTooltip placement="right" target="change_password">
                                        Edit User
                                    </UncontrolledTooltip>
                                </div>
                            }
                            {/* {(permissionsData.includes('ffm_setting_users_update') && permissionsData.includes('ffm_setting_users_delete')) && '|'}
                            {permissionsData.includes('ffm_setting_users_delete') &&
                                <div style={{ paddingLeft: 10 }}>
                                    <span onClick={this._handleClickDeleteUser} href="#" id="delete_user" className="Icon IconHolder">
                                        <i className={'ti-trash'} />
                                    </span>
                                    <UncontrolledTooltip placement="right" target="delete_user">
                                        Delete User
                                    </UncontrolledTooltip>
                                </div>
                            } */}
                        </div>
                    </td>
                }
            </tr>
        )
    }
}

ListingUsersTableRow.propTypes = {
    index: PropTypes.number.isRequired,
    user: PropTypes.object.isRequired,
}

export default ListingUsersTableRow
