import React, {Component} from 'react'
import PropTypes from 'prop-types'
import {Button, Modal} from "antd"

class ModalImageRequest extends Component {
    state = {
        isModalVisible: false
    }

    _showModal = () => {
        this.setState({
            isModalVisible: true
        })
    }

    _hiddenModal = () => {
        this.setState({
            isModalVisible: false
        })
    }

    render() {
        const {image} = this.props
        const {isModalVisible} = this.state

        return (
            <>
                <span className="Link" onClick={this._showModal}>
                    Image
                </span>
                <Modal
                    title="Image request"
                    className="ImageRequest"
                    visible={isModalVisible}
                    onCancel={this._hiddenModal}
                    footer={[
                        <Button key="back" onClick={this._hiddenModal}>
                            Close
                        </Button>
                    ]}
                >
                    <img src={image} alt={image}/>
                </Modal>
            </>
        )
    }
}

ModalImageRequest.propTypes = {
    image: PropTypes.string
}

export default ModalImageRequest
