import React, {Component} from 'react'
import DocTitleByStore from "../../../dashboard/shared/DocTitleByStore"
import ListingUsersPage from "./ListingUsersPage"

class ListingUsersPageContainer extends Component {
    render() {
        return (
            <section className="ListingUsersPageContainer">
                <DocTitleByStore title="Users"/>
                <ListingUsersPage/>
            </section>
        )
    }
}

export default ListingUsersPageContainer
