import React, {Component} from 'react'
import PropTypes from 'prop-types'

class ProductsSupplier extends Component {
    _handleChangeSupplier = (value) => {
        const {onChangeSupplier} = this.props
        if (typeof onChangeSupplier === 'function') {
            onChangeSupplier(value)
        }
    }
    _renderSupplier = (supplier) => {
        if(supplier !== null){
            return supplier.map(item => {
                return <li className="cursor-pointer" onClick={() => this._handleChangeSupplier([item._id])} key={item._id} value={item._id}>{item.name}</li>
            })
        }
    }

    render() {
        const {suppliers} = this.props 
        
        const renderSupplier = suppliers !== undefined && this._renderSupplier(suppliers)

        return (
            <td className="ProductsSupplier border-0">
                <ul className='listSupplier'>
                    {suppliers !== null ? renderSupplier : '' }
                </ul>
            </td>
        )
    }
}

ProductsSupplier.propTypes = {
    onChangeSupplier: PropTypes.func.isRequired,
}

export default ProductsSupplier