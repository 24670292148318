import React, {Component} from 'react'
import PropTypes from 'prop-types'

class Url extends Component {
    _onChangeInput = (key) => (e) => { console.log(key)
        const {value} = e.target

        const {onChangeSetting} = this.props

        if (typeof onChangeSetting === 'function') {
            onChangeSetting(key, value)
        }
    }

    render() {
        const {settings, loading} = this.props

        return (
            <div className="Url">
                <div className="InputWrapper">
                    <label htmlFor="secretKey">Secret Key</label>
                    <input disabled className="Input " id="secretKey"
                           value={settings.webhook_secure_secret || ''}
                           onChange={this._onChangeInput('webhook_secure_secret')}/>
                </div>
                <div className="InputWrapper">
                    <label htmlFor="webhook_url_order_tracking">Webhook URL order tracking</label>
                    <input disabled={loading} className="Input " id="webhook_url_order_tracking"
                           value={settings.webhook_url_order_tracking || ''}
                           onChange={this._onChangeInput('webhook_url_order_tracking')}/>
                </div>
                <div className="InputWrapper">
                    <label htmlFor="webhook_url_order_fufilled">Webhook URL order fulfilled</label>
                    <input disabled={loading} className="Input " id="webhook_url_order_fufilled"
                           value={settings.webhook_url_order_fufilled || ''}
                           onChange={this._onChangeInput('webhook_url_order_fufilled')}/>
                </div>
                <div className="InputWrapper">
                    <label htmlFor="webhook_url_order_rejected">Webhook URL order rejected</label>
                    <input disabled={loading} className="Input " id="webhook_url_order_rejected"
                           value={settings.webhook_url_order_rejected || ''}
                           onChange={this._onChangeInput('webhook_url_order_rejected')}/>
                </div>
                <div className="InputWrapper">
                    <label htmlFor="webhook_url_order_item_processed">Webhook URL mark processing</label>
                    <input disabled={loading} className="Input " id="webhook_url_order_item_processed"
                           value={settings.webhook_url_order_item_processed || ''}
                           onChange={this._onChangeInput('webhook_url_order_item_processed')}/>
                </div>
                <div className="InputWrapper">
                    <label htmlFor="webhook_url_order_update_shipment_status">Webhook URL order update shipment
                        status</label>
                    <input disabled={loading} className="Input " id="webhook_url_order_update_shipment_status"
                           value={settings.webhook_url_order_update_shipment_status || ''}
                           onChange={this._onChangeInput('webhook_url_order_update_shipment_status')}/>
                </div>
                <div className="InputWrapper">
                    <label htmlFor="webhook_url_request_update">Webhook URL request update</label>
                    <input disabled={loading} className="Input " id="webhook_url_request_update"
                           value={settings.webhook_url_request_update || ''}
                           onChange={this._onChangeInput('webhook_url_request_update')}/>
                </div>
                <div className="InputWrapper">
                    <label htmlFor="webhook_url_delete_request_update">Webhook URL delete request update</label>
                    <input disabled={loading} className="Input " id="webhook_url_delete_request_update"
                           value={settings.webhook_url_delete_request_update || ''}
                           onChange={this._onChangeInput('webhook_url_delete_request_update')}/>
                </div>
                <div className="InputWrapper">
                    <label htmlFor="webhook_url_order_refunded">Webhook URL refunded</label>
                    <input disabled={loading} className="Input " id="webhook_url_order_refunded"
                           value={settings.webhook_url_order_refunded || ''}
                           onChange={this._onChangeInput('webhook_url_order_refunded')}/>
                </div>
                <div className="InputWrapper">
                    <label htmlFor="webhook_url_order_refunded">Webhook URL charged</label>
                    <input disabled={loading} className="Input " id="webhook_url_order_charged"
                           value={settings.webhook_url_order_charged || ''}
                           onChange={this._onChangeInput('webhook_url_order_charged')}/>
                </div>
                <div className="InputWrapper">
                    <label htmlFor="webhook_url_update_profit">Webhook URL update profit</label>
                    <input disabled={loading} className="Input " id="webhook_url_update_profit"
                           value={settings.webhook_url_update_profit || ''}
                           onChange={this._onChangeInput('webhook_url_update_profit')}/>
                </div>
                <div className="InputWrapper">
                    <label htmlFor="webhook_url_box_information">Webhook URL box information</label>
                    <input disabled={loading} className="Input " id="webhook_url_box_information"
                           value={settings.webhook_url_box_information || ''}
                           onChange={this._onChangeInput('webhook_url_box_information')}/>
                </div>
                <div className="InputWrapper">
                    <label htmlFor="webhook_url_cancel_package">Webhook URL cancel package</label>
                    <input disabled={loading} className="Input " id="webhook_url_cancel_package"
                           value={settings.webhook_url_cancel_package || ''}
                           onChange={this._onChangeInput('webhook_url_cancel_package')}/>
                </div>
            </div>
        )
    }
}

Url.propTypes = {
    settings: PropTypes.object.isRequired,
    loading: PropTypes.bool.isRequired,
    onChangeSetting: PropTypes.func.isRequired
}

export default Url
