export const ZONE = [
    {
        label : 'VN',
        value: 'VN'
    },
    {
        label : 'US',
        value: 'US'
    },
    {
        label : 'EU',
        value: 'EU'
    },
    {
        label : 'CN',
        value: 'CN'
    },
    {
        label : 'AU',
        value: 'AU'
    },
]