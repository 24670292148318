import React, {useEffect, useState} from 'react'
import DocTitleByStore from "../../dashboard/shared/DocTitleByStore"
import ReturnPage from "../../shared/ReturnPage"
import {_convertString, _arrayUnique, _checkString} from '../helpers/convertString'
import ProductLineModal from "./product-line/ProductLineModal"
import {updateDiscount, getDetailDiscount} from "../../../services/api/DiscountServices"
import moment from "moment"
import Actions from "./Actions"
import Heading from "./Heading"
import Name from "./form-discount/Name"
import Amount from "./form-discount/Amount"
import Store from "./form-discount/Store"
import Date from "./form-discount/Date"
import ProductLine from "./form-discount/ProductLine"
import getHistory from "../../../store/getHistory"
import Histories from "./Histories"
import {message} from "antd"
import { getLocalData } from '../../../services/StorageServices'

function DiscountEditPage(props) {
    const permissionsData = !!getLocalData('permissions') ? Object.keys(getLocalData('permissions')) : []
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState('')
    const [name, setName] = useState('')
    const [type, setType] = useState('fixed')
    const [priceDiscount, setPriceDiscount] = useState('')
    const [percentDiscount, setPercentDiscount] = useState('')
    const [storeString, setStoreString] = useState('')
    const [storeList, setStoreList] = useState([])
    const [storeType, setStoreType] = useState('all')
    const [productLineType, setProductLineType] = useState('all')
    const [startDate, setStartDate] = useState()
    const [startTime, setStartTime] = useState()
    const [activeEndDate, setActiveEndDate] = useState(false)
    const [endDate, setEndDate] = useState()
    const [endTime, setEndTime] = useState()
    const [isOpen, setOpen] = useState(false)
    const [validate, setValidate] = useState({})
    const [storeMode, setStoreMode] = useState([])

    const [listSelectedProductLine, setListSelectedProductLine] = useState([])
    const [listSelectedVariants, setListSelectedVariants] = useState([])

    function _onChangeListSelectedProductLine(productLines) {
        setListSelectedProductLine([...productLines])
    }

    function _onChangeListSelectedVariants(variants) {
        setListSelectedVariants([...variants])
    }

    function _handleChangeName(e) {
        const {value} = e.target

        setName(value)
    }

    function _handleChangeType(e) {
        const {value} = e.target

        setType(value)
    }

    function _handleChangeValueDiscount(e) {
        const {value} = e.target

        if (type === 'percentage') {
            if (value > 100 || value < 0) {
                //value only in 0 - 100
            } else {
                setPriceDiscount('')
                setPercentDiscount(value)
            }
        } else {
            if (value > 99999999999 || value < 0) {
                //value only in 0 - 100
            } else {
                setPercentDiscount('')
                setPriceDiscount(value)
            }
        }
    }

    function _handleChangeProductLineType(e) {
        const {value} = e.target

        if (value === 'all') {
            setListSelectedProductLine([])
            setListSelectedVariants([])
        }

        setProductLineType(value)
    }

    function _handleChangeStoreType(e) {
        const {value} = e.target

        if (value === 'all' || value === 'store_mode') {
            setStoreList([])
            setStoreString('')
        }

        if (value !== 'store_mode') {
            setStoreMode([])
        }

        setStoreType(value)
    }

    function _handleChangeStoreMode(value) {
        setStoreMode(value)
    }

    function _handleChangeStoreList(e) {
        const {value} = e.target
        const input = _convertString(value)
        const check = _checkString(value)

        if (check || e.key === 'Enter') {
            const newValue = (!!storeList && storeList.length > 0) ? _arrayUnique(storeList.concat(input)) : _arrayUnique(input)

            setStoreString('')
            setStoreList(newValue)
        } else {
            setStoreString(value)
        }
    }

    function _onClickAddStoreList() {
        const input = _convertString(storeString)
        const newValue = (!!storeList && storeList.length > 0) ? _arrayUnique(storeList.concat(input)) : _arrayUnique(input)

        setStoreString('')
        setStoreList(newValue)
    }

    function _onRemoveValueFirst(value) {
        const newArray = storeList.filter(item => item !== value)

        setStoreList(newArray)
    }

    function _handleChangeActiveEndDate(e) {
        const {checked} = e.target

        if (!checked) {
            setEndDate(null)
            setEndTime(null)
        }

        setActiveEndDate(checked)
    }

    function _handleChangeStartDate(date) {
        setStartDate(date)
    }

    function _handleChangeEndDate(date) {
        setEndDate(date)
    }

    function _handleChangeStartTime(time) {
        setStartTime(time)
    }

    function _handleChangeEndTime(time) {
        setEndTime(time)
    }

    function _onOpenModal() {
        setOpen(true)
    }

    function _onCloseModal() {
        setOpen(false)
    }

    function _validate() {
        if (!name) {
            setValidate({...validate, name: 'name is required'})

            return true
        }

        if (name.trim().length > 255) {
            setValidate({name: 'name length'})

            return true
        }

        if (storeType === 'store_mode' && storeMode.length === 0) {
            setValidate({storeMode: 'Please select store mode'})

            return true
        }

        if (storeType === 'custom' && storeList.length === 0) {
            setValidate({storeCustom: 'Please add store name'})

            return true
        }

        if (type === 'fixed' && (isNaN(parseFloat(priceDiscount)) || parseFloat(priceDiscount) <= 0 || parseFloat(priceDiscount) > 99999999999)) {
            setValidate({price: 'Price not right'})

            return true
        }

        if (type === 'percentage' && (isNaN(parseFloat(percentDiscount)) || parseFloat(percentDiscount) <= 0 || parseFloat(percentDiscount) > 100)) {
            setValidate({percent: 'Price not right'})

            return true
        }

        if (!startDate && !startTime) {
            setValidate({dateStart: 'Date start not right'})

            return true
        }

        if (activeEndDate && !endDate && !endTime) {
            setValidate({dateEnd: 'Date end not right'})

            return true
        }
    }

    async function _addNewDiscount() {
        setLoading(true)
        setError('')

        try {
            if (_validate()) {
                return setLoading(false)
            } else {
                const {discountId} = props

                let vStartDate = startDate
                let vStartTime = startTime
                let vEndDate = endDate
                let vEndTime = endTime

                if (typeof startDate !== 'string') {
                    vStartDate = moment(startDate).format('DD-MM-YYYY')
                }

                if (typeof startTime !== 'string') {
                    vStartTime = moment(startTime).format('HH:mm')
                }

                if (typeof endDate !== 'string') {
                    vEndDate = moment(endDate).format('DD-MM-YYYY')
                }

                if (typeof endTime !== 'string') {
                    vEndTime = moment(endTime).format('HH:mm')
                }

                const payload = {
                    name,
                    type,
                    store_modes: storeMode,
                    amount: type === 'fixed' ? parseFloat(priceDiscount) : parseFloat(percentDiscount),
                    namespace: storeList,
                    product_lines: (!!listSelectedProductLine && listSelectedProductLine.length > 0) ? listSelectedProductLine.map(item => item.id) : [],
                    variants: (!!listSelectedVariants && listSelectedVariants.length > 0) ? listSelectedVariants.map(item => item.id) : [],
                    started_at: vStartDate + ' ' + vStartTime,
                    finished_at: activeEndDate ? vEndDate + ' ' + vEndTime : ''
                }

                const response = await updateDiscount(discountId, payload)

                const {success, message: errorMessage} = response

                if (!success) {
                    setLoading(false)

                    message.error(errorMessage)

                    return setError(errorMessage)
                }

                setLoading(false)
                setError('')
                const history = getHistory()
                history.push(`/a/fulfillment-discounts`)
            }
        } catch (e) {
            setLoading(false)
            setError(e.message)
        }
    }

    async function _fetchDiscount() {
        try {
            const {discountId} = props
            const response = await getDetailDiscount(discountId)

            const {data, success, message} = response

            if (!success) {
                return setError(message)
            }

            const {name, type, amount, namespace, product_lines, variants, started_at, finished_at, store_modes} = data

            setError('')
            setName(name)
            setType(type)
            setStartDate(moment(started_at).format('DD-MM-YYYY'))
            setStartTime(moment(started_at).format('HH:mm'))

            if (type === 'fixed') {
                setPriceDiscount(amount)
            } else {
                setPercentDiscount(amount)
            }

            if (product_lines.length > 0 || variants.length > 0) {
                setProductLineType('custom')
                setListSelectedProductLine(product_lines)
                setListSelectedVariants(variants)
            }

            if (namespace.length > 0) {
                setStoreType('custom')
                setStoreList(namespace)
            }

            if (finished_at) {
                setActiveEndDate(true)
                setEndDate(moment(finished_at).format('DD-MM-YYYY'))
                setEndTime(moment(finished_at).format('HH:mm'))
            }

            if (!!store_modes && store_modes.length > 0) {
                setStoreType('store_mode')
                setStoreMode(store_modes)
            }

        } catch (e) {
            setError(e.message)
        }
    }

    useEffect(() => {
        _fetchDiscount()
    }, [])

    const {discountId} = props

    return (
        <div className="DiscountNewPage">
            <DocTitleByStore title={'Edit - Discount'}/>
            <ReturnPage url={'/a/fulfillment-discounts'} title="Fulfillment discount"/>

            <div className="DiscountHeader">
                <Heading/>
                <Actions loading={loading} onUpdateDiscount={_addNewDiscount} permissionsData={permissionsData}/>
            </div>

            <div className="DiscountContent">
                <div className="row">
                    <div className="col-md-8">
                        <Name name={name} validate={validate.name} onChangeName={_handleChangeName} permissionsData={permissionsData}/>
                        <Amount
                            type={type}
                            percentDiscount={percentDiscount}
                            priceDiscount={priceDiscount}
                            validatePrice={type === 'fixed' && validate.price}
                            validatePercent={type === 'percentage' && validate.percent}
                            onChangeType={_handleChangeType}
                            onChangeValueDiscount={_handleChangeValueDiscount}
                            permissionsData={permissionsData}
                        />
                        <Store
                            storeString={storeString}
                            storeList={storeList}
                            storeType={storeType}
                            storeMode={storeMode}
                            validateStoreMode={validate.storeMode}
                            validateStoreList={validate.storeCustom}
                            onChangeStoreList={_handleChangeStoreList}
                            onChangeStoreType={_handleChangeStoreType}
                            onRemoveValueFirst={_onRemoveValueFirst}
                            onClickAddStoreList={_onClickAddStoreList}
                            onChangeStoreMode={_handleChangeStoreMode}
                            permissionsData={permissionsData}
                        />
                        <ProductLine
                            productLineType={productLineType}
                            selectProductLines={listSelectedProductLine}
                            listSelectedVariants={listSelectedVariants}
                            onChangeListSelectedProductLine={_onChangeListSelectedProductLine}
                            onChangeListSelectedVariants={_onChangeListSelectedVariants}
                            onOpenModal={_onOpenModal}
                            onChangeProductLineType={_handleChangeProductLineType}
                            permissionsData={permissionsData}
                        />
                        <Date
                            startDate={startDate}
                            endDate={endDate}
                            startTime={startTime}
                            endTime={endTime}
                            activeEndDate={activeEndDate}
                            validateStart={validate.dateStart}
                            validateEnd={validate.dateEnd}
                            onChangeStartDate={_handleChangeStartDate}
                            onChangeStartTime={_handleChangeStartTime}
                            onChangeActiveEndDate={_handleChangeActiveEndDate}
                            onChangeEndDate={_handleChangeEndDate}
                            onChangeEndTime={_handleChangeEndTime}
                            permissionsData={permissionsData}
                        />
                    </div>

                    <div className="col-md-4">
                        <Histories id={discountId}/>
                    </div>
                </div>
            </div>

            <div className="DiscountFooter mt-3">
                {
                    !!error && error.length > 0 && <div className="text-danger">
                        {error}
                    </div>
                }

                <Actions loading={loading} onUpdateDiscount={_addNewDiscount} permissionsData={permissionsData}/>
            </div>

            {
                isOpen && <ProductLineModal
                    isOpen={isOpen}
                    storeMode={storeMode}
                    selectProductLines={listSelectedProductLine}
                    listSelectedVariants={listSelectedVariants}
                    onSetProductLines={_onChangeListSelectedProductLine}
                    onSetVariants={_onChangeListSelectedVariants}
                    onClose={_onCloseModal}
                />
            }
        </div>
    )
}

export default DiscountEditPage
