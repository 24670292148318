import React, { useState, useEffect } from 'react'
import SettingHolidayTable from './SettingHolidayTable'
import UseDebounce from '../../shared/UseDebounce'
import { Spin, Pagination, Input, Select, DatePicker, AutoComplete, message } from 'antd'
import { SearchOutlined } from '@ant-design/icons'
import { Link } from 'react-router-dom'
import { getListSuppliersV2, getListHolidaySetting } from '../../../services/api/SuppliersServices'
import moment from 'moment'

function SettingHolidayPage() {
    const [loading, setLoading] = useState(false)
    const [listSetting, setListSetting] = useState([])
    const [error, setError] = useState('')
    const [name, setName] = useState('')
    const [supplier, setSupplier] = useState([])
    const [page, setPage] = useState(1)
    const [limit, setLimit] = useState(50)
    const [total, setTotal] = useState(0)
    const [allSupplier, setAllSupplier] = useState([])
    const [holidayDate, setHolidayDate] = useState(['', ''])
    const [cutoffDate, setCutoffDate] = useState(['', ''])
    const [holidayNames, setHolidayNames] = useState([])

    const { RangePicker } = DatePicker
    const dateFormat = "DD/MM/YYYY"

    const debounceName = UseDebounce(name, 1000)

    const fetchListSetting = async () => {
        setLoading(true)
        setError('')
        const payload = {
            page: page,
            limit: limit,
            supplier_status: "active"
        }
        if (name) payload.name = name
        if (supplier && supplier.length > 0) payload.suppliers = supplier
        if (holidayDate && holidayDate[0] && holidayDate[1]) {
            payload.holiday_time = {
                from: holidayDate[0].format(dateFormat),
                to: holidayDate[1].format(dateFormat),
            }
        }
        if (cutoffDate && cutoffDate[0] && cutoffDate[1]) {
            payload.cutoff_time = {
                from: cutoffDate[0].format(dateFormat),
                to: cutoffDate[1].format(dateFormat),
            }
        }
        try {
            const { success, data, message: mess } = await getListHolidaySetting(payload)
            const { suppliers = [], holidayNames = [] } = { ...data }

            if (!success) {
                throw new Error(mess)
            }
            setListSetting(suppliers)
            setHolidayNames(holidayNames)
            setTotal(data.total)
        } catch (error) {
            setError(error.message || 'Unknown error.')
            message.error(error.message || 'Unknown error.')
        } finally {
            setLoading(false)
        }
    }

    const getSuppliers = async () => {
        const { data } = await getListSuppliersV2()
        const { suppliers = [] } = { ...data }
        const listSupplier = suppliers.filter(i => i && i.sku_prefix && !i.is_deleted && i.is_active)
        setAllSupplier(listSupplier)
    }

    useEffect(() => {
        fetchListSetting()
    }, [page, limit, debounceName, supplier, holidayDate, cutoffDate])

    useEffect(() => {
        getSuppliers()
    }, [])

    const handleChangeName = (value) => {
        setName(value)
        setPage(1)
    }

    const handleChangeSupplier = (value) => {
        setSupplier(value)
        setPage(1)
    }

    const handleChangeDate = (field, value, dateString) => {
        if (field === 'Holiday') {
            setHolidayDate(value)
        }
        if (field === 'Cutoff') {
            setCutoffDate(value)
        }
        setPage(1)
    }

    const handPagePagination = (value) => {
        setPage(value)
    }

    const handleShowSizeChange = (current, size) => {
        setLimit(size)
        setPage(1)
    }

    const nameOptions = holidayNames.length > 0 && holidayNames.map((i, index) => ({
        value: i,
        label: i,
        key: index
    }))

    const Suppliers = allSupplier.map(i => ({
        name: i.name,
        value: i._id,
        label: i.name
    })).sort((a, b) => {
        const nameA = a.label.toLowerCase();
        const nameB = b.label.toLowerCase();

        if (nameA < nameB) {
            return -1
        }
        if (nameA > nameB) {
            return 1
        }
        return 0
    })

    return (
        <div className='SettingHolidayPage'>
            <div className="container-fluid p-0">
                <div className="BackWrapper">
                    <Link className="nav-link BackButton" to="/a/suppliers">
                        <i className="fas fa-chevron-left" />
                        <span>Supplier</span>
                    </Link>
                </div>
                <div className="MenuTitle">
                    Setting Holiday
                </div>
                <div className="SettingHolidayPage pt-3">
                    <div className='row justify-content-end mb-3 pr-3'>
                    </div>

                    <div className='SectionInner'>
                        <div className='filter d-flex align-items-start'>
                            <div className="filter-item mr-3 mb-3">
                                <AutoComplete
                                    value={name || ''}
                                    onChange={(value) => handleChangeName(value)}
                                    options={nameOptions}
                                    filterOption={(inputValue, option) =>
                                        !!option && option.value.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
                                    }
                                    allowClear
                                    disabled={loading}
                                >
                                    <Input
                                        suffix={<SearchOutlined />}
                                        style={{ minWidth: "400px", color: '#c6c6c6' }}
                                        placeholder={'Search for Holiday Name'}
                                    />
                                </AutoComplete>
                            </div>
                            <div className="filter-item mr-3">
                                <Select
                                    placeholder='Supplier'
                                    mode={'multiple'}
                                    value={supplier || []}
                                    style={{ width: "225px" }}
                                    showArrow
                                    showSearch
                                    options={Suppliers}
                                    onChange={handleChangeSupplier}
                                    allowClear
                                    disabled={loading}
                                    filterOption={(inputValue, option) =>
                                        !!option && option.label.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
                                    }
                                >
                                </Select>
                            </div>
                            <div className="filter-item mr-3">
                                <RangePicker
                                    value={holidayDate || []}
                                    placeholder={['Holiday Start', 'Holiday End']}
                                    format={dateFormat}
                                    ranges={{
                                        'Today': [moment().startOf('day'), moment().endOf('day')],
                                        'Last 7 Days': [moment().subtract(6, 'day').startOf('day'), moment().endOf('day')],
                                        'This Month': [moment().startOf('month').startOf('day'), moment().endOf('month').endOf('day')],
                                    }}
                                    onChange={(value, dateString) => handleChangeDate('Holiday', value, dateString)}
                                    disabled={loading}
                                />
                            </div>
                            <div className="filter-item">
                                <RangePicker
                                    value={cutoffDate || []}
                                    placeholder={['Cutoff Start', 'Cutoff End']}
                                    format={dateFormat}
                                    ranges={{
                                        'Today': [moment().startOf('day'), moment().endOf('day')],
                                        'Last 7 Days': [moment().subtract(6, 'day').startOf('day'), moment().endOf('day')],
                                        'This Month': [moment().startOf('month').startOf('day'), moment().endOf('month').endOf('day')],
                                    }}
                                    onChange={(value, dateString) => handleChangeDate('Cutoff', value, dateString)}
                                    disabled={loading}
                                />
                            </div>
                        </div>
                        <div className="mb-3 float-right">Total <strong>{total}</strong> {total === 1 ? 'item' : 'items'}</div>
                        <div className="mt-3">
                            <Spin spinning={loading} tip='Data Loading...'>
                                <SettingHolidayTable
                                    listSetting={listSetting}
                                    refresh={fetchListSetting}
                                />
                                <Pagination
                                    current={page}
                                    total={total}
                                    pageSize={limit}
                                    showTotal={(sum, range) => sum > 0 &&
                                        <div>
                                            {`Hiển thị ${range[0]} đến ${range[1]} của `}<strong>{`${total}`}</strong> suppliers
                                        </div>}
                                    onChange={handPagePagination}
                                    className='text-right' showSizeChanger
                                    pageSizeOptions={['10', '20', '50', '100']}
                                    onShowSizeChange={handleShowSizeChange}
                                />
                            </Spin>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default SettingHolidayPage