import React from 'react'
import {Tag, Switch} from 'antd'
import {
    CheckCircleOutlined,
    CloseCircleOutlined,
    ExclamationCircleOutlined,
    SyncOutlined,
} from '@ant-design/icons'
import {useOrderFulfillment} from "../store"

/**
 * @param {Object} props
 * @param {Object} props.pack
 *
 * @returns {JSX.Element}
 */
export default (props) => {
    const {pack} = props
    const {dispatch} = useOrderFulfillment()

    const handleAllowFulfill = (status) => {
        dispatch({type: 'SET_ALLOW_FULFILL', payload: {pack, status}})
    }

    const isProcessing = () => (
        <Tag icon={<SyncOutlined spin/>} color="processing">
            Processing
        </Tag>
    )

    const isFulfilled = () => (
        <Tag icon={<CheckCircleOutlined/>} color="success">
            Fulfilled
        </Tag>
    )

    const isSkipped = () => (
        <Tag icon={<ExclamationCircleOutlined />} color="warning">
            Skip mark processing
        </Tag>
    )

    const isError = () => (
        <Tag icon={<CloseCircleOutlined />} color="error">
            Error
        </Tag>
    )

    if (pack.isError()) {
        return isError()
    }

    if (pack.isFulfilled()) {
        return isFulfilled()
    }

    if (pack.isProcessing()) {
        if (!pack.allowFulfill()) {
            return isSkipped()
        }

        return isProcessing()
    }

    return (
        <Switch
            className="split-package__header-input-skip"
            checkedChildren="Allow processing"
            unCheckedChildren="Skip processing"
            defaultChecked={pack.isAllowFulfill}
            onClick={handleAllowFulfill}
        />
    )
}