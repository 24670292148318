import React from 'react'
import ReportOrderTime from './ReportOrderTime'

function TiktokOrderReport(props) {
    const {suppliers} = props
    return (
        <div className="ShipmentReports">
            <div className="mb-5">
                <ReportOrderTime suppliers={suppliers}/>
            </div>
        </div>
    )
}

export default TiktokOrderReport
