import React from 'react'
import {Checkbox} from "antd"
import moment from "moment";

const TableRow = (props) => {
    const {scanDetail, selectedItems, updateSelectedOrders, loadingSend, statusCarrier} = props
    const {fulfillment, carrier, status, updated_at, old_carrier} = scanDetail

    const _detectChecked = () => {
        return selectedItems.includes(scanDetail._id)
    }

    const _handleChange = () => {
        const isChecked = _detectChecked()

        updateSelectedOrders(scanDetail._id, !isChecked)
    }

    const isChecked = _detectChecked()

    const _convertTime = (string) => {
        if (!!string) {
            return moment(string).format('HH:mm DD/MM/YYYY')
        } else {
            return ''
        }
    }

    return (
        <tr className={`${scanDetail.switch_status === true ? 'HasSwitch' : ''}`}>
            <td className='OrderCheckbox'>
                <div className='custom-control custom-checkbox'>
                    <Checkbox
                        disabled={loadingSend || statusCarrier !== 'done' || scanDetail.switch_status === true}
                        onChange={_handleChange}
                        checked={isChecked}
                    />
                </div>
            </td>
            <td>
                <div><strong>{fulfillment.name}</strong></div>
                <div>{fulfillment.namespace || ''}</div>
            </td>
            <td>
                {fulfillment.tracking_number !== undefined && (
                    (fulfillment.tracking_url !== undefined && fulfillment.tracking_url !== null) ?
                        <a rel="noopener noreferrer" target="_blank"
                           href={fulfillment.tracking_url}>{fulfillment.tracking_number}</a> : fulfillment.tracking_number
                )}
            </td>
            <td>
                <div>{old_carrier || ''}</div>
                <div>{fulfillment.tracking_status || ''}</div>
                <div>{fulfillment.tracking_updated_at ? _convertTime(fulfillment.tracking_updated_at) : ''}</div>
            </td>
            <td>
                <div>{carrier || ''}</div>
                <div>{status || ''}</div>
                <div>{updated_at ? _convertTime(updated_at) : ''}</div>
            </td>
        </tr>
    )
}

export default TableRow
