import React, { useState } from 'react'
import { Modal, Tag, Popover, message, Typography } from 'antd'
import { DownloadOutlined, EyeOutlined } from "@ant-design/icons"
import ChangeOnHoldState from '../actions/ChangeOnHoldState'
import MarkAsDone from '../actions/MarkAsDone'
import LazyLoad from 'react-lazyload'
import { allowStore, allowType } from '../constants'
import ToggleSafeZone from './ToggleSafeZone'
import Draggable from 'react-draggable'

const { Paragraph } = Typography

const ItemImageSafeZone = (props) => {
    const { image, previewImage, status, orderId, type, tags, namespace, muckUpUrl, fulfillmentId, reload, side, safezoneImage, safezoneMovable } = props
    const [visible, setVisible] = useState(false)
    const [offSafeZone, setOffSafeZone] = useState(false)
    const [orderStatus, setOrderStatus] = useState(status)
    const [background, setBackground] = useState('gray')

    const isSpecialTag = Array.isArray(tags) && tags.includes('priority-store')
    const isShowMockUp = !!namespace && allowStore.includes(namespace) && !!type && allowType.includes(type)

    const _showModal = () => {
        setVisible(true)
    }

    const _hideModal = () => {
        setVisible(false)
    }

    const _handleChangeColor = color => {
        setBackground(color)
    }

    const _afterDoAction = (status) => {
        setOrderStatus(status)
        message.success('Trạng thái đã thay đổi')
        _hideModal()
        reload()
    }

    const _toggleSafeZone = () => {
        setOffSafeZone(!offSafeZone)
    }

    const showSafeZone = () => {
        if (!!safezoneImage) {
            const imagePreview = new Image()
            imagePreview.src = previewImage
            const imageSafezone = new Image()
            imageSafezone.src = safezoneImage
            const safezoneWidth = Math.round((1000 / imagePreview.width) * imageSafezone.width)
            const safezoneHeight = Math.round((1000 / imagePreview.width) * imageSafezone.height)

            return (
                safezoneMovable ?
                    <Draggable
                        bounds="parent"
                    >
                        <div className="box" style={{ width: safezoneWidth, height: safezoneHeight }}>
                            <img src={safezoneImage} alt="zoneZone" className="Safezone" style={{ pointerEvents: 'none' }} />
                        </div>
                    </Draggable> :
                    <img src={safezoneImage} alt="zoneZone" className="Safezone" />
            )
        }
    }

    const show_file_in = (preview, mockUp, isShowMockUp) => {
        if (!!previewImage) {
            const newImage = new Image()
            newImage.src = previewImage
            const newWidth = newImage.width > newImage.height ? "100%" : "auto"
            const newHeight = newImage.width > newImage.height ? "auto" : "100%"
            return (
                <div className='position-relative'>
                    {isShowMockUp && <div className="MockupViewer position-absolute">
                        <img src={mockUp} alt='preview' className='d-block m-auto' width={250} />
                    </div>}
                    <div className={`Preview position-relative PriviewFileIn ${isShowMockUp ? 'ml-3' : ''}`}>
                        <img src={preview} className='position-absolute' alt='preview' style={{ width: newWidth, height: newHeight, left: isShowMockUp ? 'unset' : 0, background: background }} />
                    </div>
                    <div className="ActionBackgroundPrintFile">
                        <button
                            className={background === 'gray' ? 'Active' : ''}
                            onClick={() => _handleChangeColor('gray')}
                        >
                            Gray
                        </button>
                        <button
                            className={background === 'black' ? 'Active' : ''}
                            onClick={() => _handleChangeColor('black')}
                        >
                            Black
                        </button>
                        <button
                            className={background === 'white' ? 'Active' : ''}
                            onClick={() => _handleChangeColor('white')}
                        >
                            White
                        </button>
                    </div>
                </div>
            )
        }
    }


    return (
        <LazyLoad>
            <td className='ItemImage'>
                <Popover
                    placement="right"
                    content={show_file_in(previewImage, muckUpUrl, false)}
                    overlayClassName={`previewFixed ${visible ? 'd-none' : ''}`}
                >
                    {
                        previewImage ?
                            <div className={`Preview position-relative ${type}`}>
                                <img src={previewImage} className='position-absolute' alt='preview' style={{ background: background }} />
                                {
                                    !offSafeZone && showSafeZone()
                                }
                                <div className='Actions d-flex justify-content-center align-items-center position-absolute'>
                                    <span className='Icon cursor-pointer mr-2' onClick={_showModal}>
                                        <EyeOutlined />
                                    </span>
                                    <a href={image} download className='Icon Download' target='_blank' rel="noopener noreferrer">
                                        <DownloadOutlined />
                                    </a>
                                    <span className='Icon ml-2'><Paragraph copyable={{ text: image }} className='mb-0' /></span>
                                </div>

                                <Modal
                                    className='PrintedFileModal'
                                    style={{ "textAlign": "center" }}
                                    visible={visible}
                                    centered={true}
                                    footer={null}
                                    onCancel={_hideModal}
                                >
                                    <div className='Previewer' style={{ marginLeft: isShowMockUp ? 370 : 'auto' }}>
                                        <img src={previewImage} alt='preview' className='d-block m-auto' width={1000} style={{ pointerEvents: 'none', background: background }} />
                                        {
                                            !offSafeZone && showSafeZone()
                                        }
                                    </div>
                                    <div className="ActionBackgroundPrintFile" style={{ position: 'absolute', bottom: 140, zIndex: 11 }}>
                                        <button
                                            className={background === 'gray' ? 'Active' : ''}
                                            onClick={() => _handleChangeColor('gray')}
                                        >
                                            Gray
                                        </button>
                                        <button
                                            className={background === 'black' ? 'Active' : ''}
                                            onClick={() => _handleChangeColor('black')}
                                        >
                                            Black
                                        </button>
                                        <button
                                            className={background === 'white' ? 'Active' : ''}
                                            onClick={() => _handleChangeColor('white')}
                                        >
                                            White
                                        </button>
                                    </div>
                                    <div className='bg-light d-flex align-items-center justify-content-between'>
                                        <div className="d-flex align-items-center">
                                            <div className={`OrderActions`}>
                                                <div className='Actions'>
                                                    <MarkAsDone orderId={orderId} fulfillmentId={fulfillmentId} onSuccess={_afterDoAction} />
                                                    <ChangeOnHoldState
                                                        status={orderStatus}
                                                        orderId={orderId}
                                                        fulfillmentId={fulfillmentId}
                                                        onChangeStatus={_afterDoAction}
                                                    />
                                                </div>
                                            </div>

                                            {
                                                !!showSafeZone() && <div className='OrderActions'>
                                                    <div className='Actions'>
                                                        <ToggleSafeZone
                                                            offSafeZone={offSafeZone}
                                                            onToggleSafeZone={_toggleSafeZone}
                                                        />
                                                    </div>
                                                </div>
                                            }
                                        </div>
                                        {
                                            isSpecialTag && <Tag color="#ff0000">Đặc biệt</Tag>
                                        }
                                    </div>
                                </Modal>
                            </div>
                            : image &&
                            <a href={image} target='_blank' rel='noreferrer noopener' className='btn btn-sm text-primary'>
                                <u>Download</u>
                            </a>
                    }
                </Popover >
            </td>
        </LazyLoad>
    )
}

export default ItemImageSafeZone