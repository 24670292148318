import React from 'react'
import DocTitleByStore from "../../dashboard/shared/DocTitleByStore"
import ManageAutoMapPage from "./ManageAutoMapPage"

function ManageAutoMapContainer() {
    return (
        <section className='ManageAutoMapContainer'>
            <DocTitleByStore title="Auto mapping rules"/>
            <ManageAutoMapPage/>
        </section>
    )
}

export default ManageAutoMapContainer
