import React, {Component} from 'react'
import PropTypes from 'prop-types'
import formatCurrency from "../../../../helpers/common/formatCurrency"

class OrderItem extends Component {
    render() {
        const {item} = this.props
        const {price, quantity, product, variant, currency} = item

        return (
            <div className='OrderItem col-3 mb-3'>
                <div className="d-flex align-items-center">
                    <div className="Image mr-3 flex-shrink-0"
                         style={{backgroundImage: `url("${product.preview}"), url("/assets/images/placeholder-image.png")`}}/>
                    <div className="Title">
                        <a href={product.url} target="_blank" rel="noreferrer noopener">{product.title}</a>

                        <ul>
                            <li>{variant.title}</li>
                            <li>{formatCurrency(currency, price)} x {quantity}</li>
                            <li>{variant.sku && `SKU: ${variant.sku}`}</li>
                        </ul>
                    </div>
                </div>
            </div>
        )
    }
}

OrderItem.propTypes = {
    item: PropTypes.object.isRequired,
}

export default OrderItem
