import React, {Component} from 'react'

class OrderPlatform extends Component {
    render() {
        const {name = ''} = this.props

        return (
            <td className="OrderPlatform">
                {name}
            </td>
        )
    }
}

export default OrderPlatform
