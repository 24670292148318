import React, {Component} from 'react'
import {
    getListShippingPlans,
    getShippingZoneDetail,
    getShippingZoneRates,
    updateShippingZone,
} from "../../../services/api/ShippingServices"
import ShippingZoneTitle from "./basic-info/ShippingZoneTitle"
import ShippingZoneDescription from "./basic-info/ShippingZoneDescription"
import ShippingZoneCountries from "./countries/ShippingZoneCountries"
import ShippingZoneMethods from "./methods/ShippingZoneMethods"
import ShippingZoneUpdate from "./actions/ShippingZoneUpdate"

class ShippingZonePage extends Component {
    state = {
        title: "",
        description: "",
        plans: [],
        loading: false,
        error: "",
        listPlans: [],
        errorPlans: ''
    }

    _selectedCountry = []

    _selectedProduct = []

    componentDidMount() {
        Promise.all([this._fetchShippingZone(), this._fetchShippingZonePlans(), this._fetchListPlans()])
    }

    _fetchShippingZone = async () => {
        try {
            const {id} = this.props
            const {data, success, message} = await getShippingZoneDetail(id)

            if (!success) {
                this.setState({
                    error: message,
                })

                return false
            }

            const {title, description} = data

            this.setState({
                title: title,
                description: description,
                error: "",
            })

            return true
        } catch (e) {
            this.setState({
                error: e.message,
            })

            return false
        }
    }

    _fetchShippingZonePlans = async () => {
        try {
            const {id} = this.props
            const {data, success, message} = await getShippingZoneRates(id)

            if (!success) {
                this.setState({
                    error: message,
                })

                return false
            }

            let plansFormatted = []

            data.forEach(method => {
                const {rates, plans} = method
                const {_id: planId, title: planTitle} = plans
                const selectedProducts = rates.map(rate => rate.product._id)

                this._selectedProduct.push({plan: planId, products: selectedProducts})

                rates.forEach(rate => {
                    plansFormatted.push({
                        plan: planId,
                        first_rate: rate.first_rate,
                        second_rate: rate.second_rate,
                        products: [rate.product._id],
                        product_title: rate.product.title,
                        plan_title: planTitle,
                    })


                })
            })

            this.setState({
                plans: plansFormatted,
                error: "",
            })

        } catch (e) {
            this.setState({
                error: e.message,
            })

            return false
        }
    }

    _handleUpdateShippingZoneState = (field, data) => {
        this.setState({
            [field]: data,
        })
    }

    _handleUpdateSelectedCountries = (arrCountries) => {
        this._selectedCountry = arrCountries
    }

    _handleUpdatePlansState = (index, field, data) => {
        this.setState(({plans}) => {
            const newPlans = plans.map((plan, idx) => {
                if (index === idx) {
                    return {
                        ...plan,
                        [field]: data,
                    }
                }

                return plan
            })

            return {
                plans: newPlans,
            }
        })
    }

    _handleCreatePlan = () => {
        this.setState(({plans}) => {
            return {
                plans: [
                    ...plans,
                    {
                        plan: "",
                        first_rate: "",
                        second_rate: "",
                        products: [""],
                    },
                ],
            }
        })
    }

    _handleRemovePlan = (index) => {
        this.setState(({plans}) => {
            const newPlans = plans.filter((plan, idx) => {
                return idx !== index
            })

            return {
                plans: newPlans,
            }
        })
    }

    _handleUpdateShippingZone = async () => {
        try {
            this.setState({
                error: "",
                loading: true,
            })

            const {id} = this.props
            const {title, description, plans} = this.state
            const {success, message} = await updateShippingZone(id, {
                title: title,
                description: description,
                countries: this._selectedCountry,
                plans: plans,
            })

            if (!success) {
                return this.setState({
                    loading: false,
                    error: message,
                })
            }

            return this.setState({
                loading: false,
            }, () => {
                this._fetchShippingZonePlans()
            })
        } catch (e) {
            return this.setState({
                loading: false,
                error: e.message,
            })
        }
    }

    _handleSubmit = e => {
        e.preventDefault()

        this._handleUpdateShippingZone()
    }

    _handleGetSelectedProduct = () => {
        return this._selectedProduct
    }

    _handleUpdateSelectedProduct = (planId, productId) => {
        const planFounded = this._selectedProduct.find(selected => selected.plan === planId)

        if (planFounded === undefined) {
            return this._selectedProduct.push({plan: planId, products: [productId]})
        }

        this._selectedProduct = this._selectedProduct.map(selected => {
            if (selected.plan !== planId) return selected

            const {products} = selected

            return {
                ...selected,
                products: [...products, productId],
            }
        })
    }

    _handleRemoveSelectedProduct = (planId, productId) => {
        const planFounded = this._selectedProduct.find(selected => selected.plan === planId)

        if (planFounded === undefined) {
            return
        }

        this._selectedProduct = this._selectedProduct.map(selected => {
            if (selected.plan !== planId) return selected

            const {products} = selected

            return {
                ...selected,
                products: products.filter(product => product !== productId),
            }
        })
    }

    _fetchListPlans = async () => {
        this.setState({
            errorPlan: ''
        })

        try {
            const response = await getListShippingPlans({term: ""})

            const {success, data, message} = response

            if (!success) {
                this.setState({
                    errorPlan: message,
                })

                return false
            }

            this.setState({
                listPlans: data,
                error: ""
            })
        } catch (e) {
            this.setState({
                errorPlan: e.message
            })
        }
    }

    render() {
        const {id} = this.props
        const {title, description, plans, loading, error, listPlans, errorPlans} = this.state

        if (title === "") {
            return null
        }

        return (
            <div className="ShippingZonePage">
                <h1 className="PageTitle">{title}</h1>

                {
                    !!errorPlans && errorPlans.length > 0 && <div className="text-danger mt-3 mb-3">{errorPlans}</div>
                }

                <form onSubmit={this._handleSubmit}>
                    <div className="SectionInner">
                        <ShippingZoneTitle
                            title={title}
                            _handleUpdateShippingZoneState={this._handleUpdateShippingZoneState}
                        />

                        <div className="mt-4">
                            <ShippingZoneDescription
                                description={description}
                                _handleUpdateShippingZoneState={this._handleUpdateShippingZoneState}
                            />
                        </div>

                        <div className="mt-4">
                            <ShippingZoneCountries
                                zoneId={id}
                                _handleUpdateSelectedCountries={this._handleUpdateSelectedCountries}
                            />
                        </div>

                        <div className="mt-4">
                            <ShippingZoneMethods
                                listPlans={listPlans}
                                plans={plans}
                                _handleUpdatePlansState={this._handleUpdatePlansState}
                                _handleRemovePlan={this._handleRemovePlan}
                                _handleCreatePlan={this._handleCreatePlan}
                                onGetSelectedProduct={this._handleGetSelectedProduct}
                                onUpdateSelectedProduct={this._handleUpdateSelectedProduct}
                                onRemoveSelectedProduct={this._handleRemoveSelectedProduct}
                            />
                        </div>
                    </div>

                    <div className="mt-4">
                        <ShippingZoneUpdate
                            loading={loading}
                            error={error}
                            title={title}
                            shippingZoneId={id}
                            _handleUpdateShippingZone={this._handleUpdateShippingZone}
                        />
                    </div>
                </form>
            </div>
        )
    }
}

export default ShippingZonePage
