import React, {Component} from 'react'
import PropTypes from 'prop-types'


class ProductType extends Component {
    render() {
        const {type} = this.props

        return (
            <td className="ProductType">
                <span>{type}</span>
            </td>
        )
    }
}

ProductType.propTypes = {
    type: PropTypes.string.isRequired,
}

export default ProductType
