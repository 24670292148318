import React, {Component} from 'react'
import ShippingZonesHead from "./ShippingZonesHead"
import ShippingZonesRow from "./ShippingZonesRow"

class ShippingZonesTable extends Component {
    render() {
        const {shippingZones, fetchListShippingZones, listSupplier, listCountries, total, error, permissionsData} = this.props

        return (
            <div className="tableWrapResponsive" style={{margin:"20px 0 0 0"}}>
                <></>
                <label className='float-right'><b>Total: </b>{total} items</label>
                <table className="ShippingZonesTable table">
                    <ShippingZonesHead permissionsData={permissionsData}/>

                    <tbody>
                    {error!==""? <span style={{color: "red"}}>{error}</span>:
                        shippingZones.map((zone,index) => {
                            return (
                              <ShippingZonesRow
                                key={zone._id}
                                zone={zone}
                                index={index}
                                fetchListShippingZones={fetchListShippingZones}
                                listSupplier={listSupplier}
                                listCountries={listCountries}
                                permissionsData={permissionsData}
                              />
                            )
                        })
                    }
                    </tbody>
                </table>
            </div>
        )
    }
}

export default ShippingZonesTable
