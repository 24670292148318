import React, {Component} from 'react'
import {getListShippingPlans} from "../../../services/api/ShippingServices"
import ShippingPlansTable from "./ShippingPlansTable/ShippingPlansTable"
import ShippingPlansCreate from "./ShippingPlansActions/ShippingPlansCreate"
import ShippingPlansPagination from "./ShippingPlansActions/ShippingPlansPagination"
import { getLocalData } from '../../../services/StorageServices'

class ShippingPlansPage extends Component {
    state = {
        plans: [],
        error: "",
    }

    componentDidMount() {
        this._fetchListShippingPlans()
    }

    _fetchListShippingPlans = async () => {
        try {
            const {data, success, message} = await getListShippingPlans({
                term: "",
            })

            if (!success) {
                return this.setState({
                    error: message,
                })
            }

            return this.setState({
                plans: data,
                error: "",
            })

        } catch (e) {
            return this.setState({
                error: e.message,
            })
        }
    }

    render() {
        const {plans} = this.state
        const permissionsData = !!getLocalData('permissions') ? Object.keys(getLocalData('permissions')) : []

        return (
            <div className="ShippingPlansPage">
                {permissionsData.includes('ffm_shipping_plan_create') &&
                    <div className="mb-3">
                        <ShippingPlansCreate/>
                    </div>
                }
                <ShippingPlansTable plans={plans}/>
                <ShippingPlansPagination/>
            </div>
        )
    }
}

export default ShippingPlansPage
