import React from 'react'
import PropTypes from 'prop-types'
import ProductAttributeItem from "./ProductAttributeItem"

ProductAttributesList.propTypes = {
    attributes: PropTypes.array,
    onChangeAtt: PropTypes.func.isRequired,
    onDeleteAtt: PropTypes.func.isRequired
}

function ProductAttributesList(props) {
    const {attributes, onChangeAtt, onDeleteAtt, disableAll} = props

    return (
        <div className='ProductAttributesList'>
            <ul>
                {
                    !!attributes && attributes.length > 0 && attributes.map((item, index) => {
                        return <ProductAttributeItem
                            key={index}
                            attributes={attributes}
                            attributeIndex={index}
                            attribute={item}
                            onChangeAtt={onChangeAtt}
                            onDeleteAtt={onDeleteAtt}
                            disableAll={disableAll}
                        />
                    })
                }
            </ul>
        </div>
    )
}

export default ProductAttributesList
