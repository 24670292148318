import React, { useState, useRef } from 'react'
import { Input, Select } from 'antd'
import SuppliersSelector from './SuppliersSelector'
import FilterDate from '../../shared/FilterDate'


const MappingVariantsFilter = (props) => {
    const { listProductTypeFulfill, listOptionsSearchField, handleChangeFilters, filters
    } = props

    // const [searchField, setSearchField] = useState(props.searchField.length ? props.searchField[0] : null)
    // const [search, setSearch] = useState('')
    const inputRef = useRef()

    const _onChangeSelectSearchField = e => {
        const { value } = e.target
        // console.log('input refff: ', inputRef)
        // inputRef.current.focus()

        // onChangeSearchField(value)
        handleChangeFilters('searchField', value)
    }

    const _onChangeInputFilter = (value) => {
        // onchangeFilter(value)
        handleChangeFilters('search', value)
    }

    const onChangeProductTypeFulfill = (value) => {
        // onchangeFilter(value)
        handleChangeFilters('search', value)
    };

    const onChangeMode = (value) => {
        // handleChangeMode(value)
        handleChangeFilters('mode', value)
    };

    const onChangeSupplier = (value) => {
        handleChangeFilters('supplier', value)
    }

    const onChangeStatus = (value) => {
        // handleChangeStatus(value)
        handleChangeFilters('status', value)
    };

    const onChangeCatalogStatus = (value) => {
        // handleChangeCatalogStatus(value)
        handleChangeFilters('catalogStatus', value)
    };

    return (
        <div className="TableFilter d-xl-flex justify-content-between align-items-end mb-3">
            <div className="Filter">
                <div className="d-flex flex-wrap align-items-center justify-content-start">
                    <div className="FilterWrapper FilterOptions mb-3 mr-3">
                        <div className="d-flex align-items-stretch justify-content-end">
                            <div className="input-group-prepend SelectWrapper">
                                <select
                                    value={filters.searchField}
                                    className="form-control"
                                    onChange={_onChangeSelectSearchField}
                                >
                                    {listOptionsSearchField.map((item, i) => <option key={i} value={item}>{item}</option>)}
                                </select>
                            </div>

                            <div className="InputWrapper position-relative">
                                <i className="ti-search position-absolute d-none" />
                                {
                                    filters.searchField === 'Product Type Fulfill' ? <Select
                                    showSearch
                                    allowClear
                                    placeholder="Search"
                                    optionFilterProp="children"
                                    onChange={onChangeProductTypeFulfill}
                                    // onSearch={onSearch}
                                    filterOption={(input, option) => option.label.toLowerCase().includes(input.toLowerCase())}
                                    options={listProductTypeFulfill}
                                    value={filters.search || undefined}
                                    style={{width: '100%'}}
                                    ref={inputRef}
                                /> : <Input
                                value={filters.search || ''}
                                onChange={(e) => _onChangeInputFilter(e.target.value)}
                                className="form-control InputSearch"
                                placeholder="Search"
                                ref={inputRef}
                            />
                                }
                            </div>
                        </div>
                    </div>

                    <div className='CustomSelect FilterOptions mr-3 mb-3'>
                        <form>
                            <Select
                                allowClear
                                placeholder="Mode"
                                onChange={onChangeMode}
                                // onSearch={onSearch}
                                options={[
                                    {
                                      value: 'normal',
                                      label: 'Normal',
                                    },
                                    {
                                      value: 'built-in-payment',
                                      label: 'Built in payment',
                                    },
                                    {
                                      value: 'marketplace',
                                      label: 'Marketplace',
                                    },
                                  ]}
                                value={filters.mode || undefined}
                                style={{width: 180}}
                            />
                        </form>
                    </div>

                    <div className='CustomSelect FilterOptions mr-3 mb-3'>
                        <form>
                            <SuppliersSelector
                                supplier={filters.supplier}
                                handleSelectSupplier={onChangeSupplier}
                                width={'150px'}
                                placeholder="Supplier"
                            />
                        </form>
                    </div>

                    <div className='CustomSelect FilterOptions mr-3 mb-3'>
                        <form>
                            <Select
                                allowClear
                                placeholder="Status"
                                onChange={onChangeStatus}
                                // onSearch={onSearch}
                                options={[
                                    {
                                      value: 'mapped',
                                      label: 'Mapped',
                                    },
                                    {
                                      value: 'unmapped',
                                      label: 'Unmapped',
                                    }
                                  ]}
                                value={filters.status || undefined}
                                style={{width: 150}}
                            />
                        </form>
                    </div>

                    <div className='CustomSelect FilterOptions mr-3 mb-3'>
                        <form>
                            <Select
                                allowClear
                                placeholder="Catalog Status"
                                onChange={onChangeCatalogStatus}
                                // onSearch={onSearch}
                                options={[
                                    {
                                      value: 'inactive',
                                      label: 'Inactive',
                                    },
                                    {
                                      value: 'active',
                                      label: 'Active',
                                    }
                                  ]}
                                value={filters.catalogStatus || undefined}
                                style={{width: 150}}
                            />
                        </form>
                    </div>

                    <div className='CustomFilterDate FilterOptions mr-3 mb-3'>
                        <form>
                            <label className='mr-3'>Updated at:</label>
                            <FilterDate
                                field='updated_at'
                                value={filters.updated_at || {}}
                                onDatesChange={handleChangeFilters}
                            />
                        </form>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default MappingVariantsFilter