export const ListArtworkType = [
    {
        value: 'product',
        label: 'Product artworks'
    },
    {
        value: 'order',
        label: 'Order artworks'
    },
    {
        value: 'attribute',
        label: 'Attribute artworks'
    },
    {
        value: 'productvariant',
        label: 'Product variant'
    }
]
