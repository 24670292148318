import React from 'react'
import SpecialCaseEditPage from './SpecialCaseEditPage'
import DocTitleByStore from '../../dashboard/shared/DocTitleByStore'

const SpecialCaseEditContainer = (props) => {
    const idSpecialCase = props.match.params.id

    return (
        <div className='SpecialCaseEditContainer'>
            <DocTitleByStore title="Mapping Special Case Edit" />
            <SpecialCaseEditPage id={idSpecialCase}/>
        </div>
    )
}

export default SpecialCaseEditContainer