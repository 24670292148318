import React from 'react'
import DocTitleByStore from "../../dashboard/shared/DocTitleByStore"
import OrderProfitPage from './OrderProfitPage'

function OrderProfitPageContainer() {
    return (
        <div className="OrderProfitPageContainer">
        <DocTitleByStore title="Order Profit" />
        <OrderProfitPage/>
    </div>
    )
}

export default OrderProfitPageContainer