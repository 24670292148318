import React from 'react'
import DocTitleByStore from "../../dashboard/shared/DocTitleByStore"
import ReturnPage from '../../shared/ReturnPage'
import FileDetailPage from './FileDetailPage'

function FileDetailPageContainer(props) {
    const idFile = props.match.params.id
    return (
        <div className="FileDetailPageContainer">
            <ReturnPage url="/a/cost-control" title="Cost control" />
            <DocTitleByStore title="File Detail" />
            <FileDetailPage idFile={idFile}/>
        </div>
  )
}

export default FileDetailPageContainer