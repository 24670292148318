import React from 'react'
import ShippingZonesPage from "./ShippingZonesPage"
import DocTitleByStore from "../dashboard/shared/DocTitleByStore"
import {Link} from "react-router-dom"
import { Button } from 'antd'
import { getLocalData } from '../../services/StorageServices'

const ShippingZonesPageContainer = () => {
  const permissionsData = !!getLocalData('permissions') ? Object.keys(getLocalData('permissions')) : []
  
  return (
    <div className="ShippingZonesPageContainer">
      <DocTitleByStore title="Shipping Zones" />
      <div className='d-flex ShippingZonesHeader'>
          <h1 className="PageTitle mb-3">Shipping Zones</h1>
          {permissionsData.includes('ffm_shipping_zone_create') &&
            <Link to="shipping-zones/create">
              <Button type="primary" className="BtnAddZone"  size="large" >Add zone</Button>
            </Link>
          }
      </div>
      <ShippingZonesPage permissionsData={permissionsData} />
    </div>
  )
}

export default ShippingZonesPageContainer
