import React, {Component} from 'react'
import {CloseCircleOutlined, CheckCircleOutlined} from '@ant-design/icons'
import classNames from "classnames"
import { Select, Input, InputNumber, message, Popconfirm, Tooltip } from 'antd'
import { upsertEmbroiderySurcharge, deleteEmbroiderySurcharge, getProductDetail } from '../../../../services/api/ProductsServices'
import { indexOf } from 'lodash'

class ProductDimension extends Component {
    constructor(props) {
        super(props)
        const {dimensions, surcharges} = props
        
        const newDimensions = dimensions.map(item => {
            const itemF = item.find(itemF => itemF.side === 'front')
            const itemB = item.find(itemB => itemB.side === 'back')
            const itemS = item.find(itemS => itemS.side === 'sleeve')
            const itemH = item.find(itemH => itemH.side === 'hood')
            return [
                {
                    width: itemF !== undefined ? itemF.width : '',
                    height: itemF !== undefined ? itemF.height : '',
                    side: "front"
                },
                {
                    width: itemB !== undefined ? itemB.width : '',
                    height: itemB !== undefined ? itemB.height : '',
                    side: "back"
                },
                {
                    width: itemS !== undefined ? itemS.width : '',
                    height: itemS !== undefined ? itemS.height : '',
                    side: "sleeve"
                },
                {
                    width: itemH !== undefined ? itemH.width : '',
                    height: itemH !== undefined ? itemH.height : '',
                    side: "hood"
                },
            ]
        })

        const newSurcharges = surcharges.length > 0 ? surcharges.map(item => {
            const surchargeItem = {
                type: 'Embroidery Fee',
                store: item.store.join(", "),
                simple: item.simple.toString(),
                complex: item.complex.toString(),
                secondLocationFee: item.second_rental_fee.toString(),
                status: 'exist',
                disableEdit: true,
                surchargeId: item._id
            }

            return surchargeItem
        }): []
        
        this.state = {
            dimensionsState: newDimensions,
            surchargesState: newSurcharges,
            surchargesStateOld: JSON.parse(JSON.stringify(newSurcharges)),
            disableAdd: false,
            loading: false,
            error: ''
        }
    }

    _handleAddDimension = () => {
        const {dimensionsState} = this.state
        let newDimensions = Object.assign([], dimensionsState)
        newDimensions.push([
            {
                width: '',
                height: '',
                side: "front"
            },
            {
                width: '',
                height: '',
                side: "back"
            },
            {
                width: '',
                height: '',
                side: "sleeve"
            },
            {
                width: '',
                height: '',
                side: "hood"
            },
        ])

        this.setState({
            dimensionsState: newDimensions
        })
    }

    _handleChangeDimensionItem = (index, side) => (e) => {
        const {dimensionsState} = this.state
        const {onUpdateState} = this.props
        const {name, value} = e.target
        const newDimensions = dimensionsState.map((item, indexI) => {
            if (indexI === index) {
                const mapChild = item.map(itemC => {
                    if (itemC.side === side) {
                        if ((/^[0-9]+$/.test(value)) || value === '') {
                            return {
                                ...itemC,
                                [name]: value
                            }
                        } else {
                            return itemC
                        }
                    } else {
                        return itemC
                    }
                })
                const itemF = mapChild.find(itemF => itemF.side === 'front')
                const itemB = mapChild.find(itemB => itemB.side === 'back')
                const itemS = mapChild.find(itemS => itemS.side === 'sleeve')
                const itemH = mapChild.find(itemH => itemH.side === 'hood')
                if (itemF.width === '' && itemF.height === '' && itemB.width === '' && itemB.height === '' && itemS.width === '' && itemS.height === '' && itemH.width === '' && itemH.height === '') {
                    return []
                }
                return mapChild
            } else {
                const itemF = item.find(itemF => itemF.side === 'front')
                const itemB = item.find(itemB => itemB.side === 'back')
                const itemS = item.find(itemS => itemS.side === 'sleeve')
                const itemH = item.find(itemH => itemH.side === 'hood')
                if (itemF.width === '' && itemF.height === '' && itemB.width === '' && itemB.height === '' && itemS.width === '' && itemS.height === '' && itemH.width === '' && itemH.height === '') {
                    return []
                }
                return item
            }
        })


        this.setState({
            dimensionsState: newDimensions.filter(itemN => itemN.length > 0)
        }, () => {
            onUpdateState('dimension_conditions', newDimensions)
        })
    }

    _handleDeleteItem = (index) => {
        const {onUpdateState} = this.props
        const {dimensionsState} = this.state

        const newDimensions = dimensionsState.filter((item, i) => i !== index)

        this.setState({
            dimensionsState: newDimensions
        }, () => {
            onUpdateState('dimension_conditions', newDimensions)
        })
    }

    _handleChangeSurchargeItem = (index) => (e) => {
        const {surchargesState, surchargesStateOld} = this.state
        const {name, value} = e.target
        if(name === 'simple' || name === 'secondLocationFee' || name === 'complex'){
            const vValue = Number(value)

            if (isNaN(vValue)) {
                return
            }
        }
        let newSurcharges = [...surchargesState]
        newSurcharges[index][name] = value

        if(surchargesStateOld.length === newSurcharges.length){
            if((surchargesStateOld[index]['store'] && surchargesStateOld[index]['store']) !== newSurcharges[index]['store'] || (surchargesStateOld[index]['simple'] && surchargesStateOld[index]['simple']) !== newSurcharges[index]['simple'] || (surchargesStateOld[index]['complex'] && surchargesStateOld[index]['complex']) !== newSurcharges[index]['complex'] || (surchargesStateOld[index]['secondLocationFee'] && surchargesStateOld[index]['secondLocationFee']) !== newSurcharges[index]['secondLocationFee']){
                newSurcharges[index]['disableEdit'] = false
            }else{
                newSurcharges[index]['disableEdit'] = true
            }
        }

        this.setState({
            surchargesState: newSurcharges
        })
    }

    _handleChangeSurchargeInputNumber = (index, name, value) => {
        const {surchargesState, surchargesStateOld} = this.state

        const vValue = Number(value)

        if (isNaN(vValue)) {
            return
        }

        let newSurcharges = [...surchargesState]
        newSurcharges[index][name] = value

        if(surchargesStateOld.length === newSurcharges.length){
            if((surchargesStateOld[index]['store'] && surchargesStateOld[index]['store']) !== newSurcharges[index]['store'] || (surchargesStateOld[index]['simple'] && surchargesStateOld[index]['simple']) !== newSurcharges[index]['simple'] || (surchargesStateOld[index]['complex'] && surchargesStateOld[index]['complex']) !== newSurcharges[index]['complex'] || (surchargesStateOld[index]['secondLocationFee'] && surchargesStateOld[index]['secondLocationFee']) !== newSurcharges[index]['secondLocationFee']){
                newSurcharges[index]['disableEdit'] = false
            }else{
                newSurcharges[index]['disableEdit'] = true
            }
        }

        this.setState({
            surchargesState: newSurcharges
        })
    }

    _handleDeleteSurchargeItemNotSave = (index) => {
        const {surchargesState} = this.state

        const newSurcharges = surchargesState.filter((item, i) => i !== index)

        this.setState({
            surchargesState: newSurcharges,
            disableAdd: false
        })
    }

    _handleSaveSurchargeItem = async (index) => {
        this.setState({
            loading: true,
            error: ''
        })

        try {
            const {productId} = this.props
            const {surchargesState} = this.state
            const newSurcharge = surchargesState.filter((item, i) => i === index)

            const payload = {}

            if(newSurcharge[0].surchargeId){
                payload._id = newSurcharge[0].surchargeId
            }

            if(newSurcharge[0].store){
                const storeArr = newSurcharge[0].store.split(",").map(item => {
                    return item.trim()
                })
                payload.store = storeArr
            }
            if(newSurcharge[0].simple){
                payload.simple = parseFloat(newSurcharge[0].simple)
            }

            if(newSurcharge[0].complex){
                payload.complex = parseFloat(newSurcharge[0].complex)
            }

            if(newSurcharge[0].secondLocationFee){
                payload.second_rental_fee = parseFloat(newSurcharge[0].secondLocationFee)
            }

            const response = await upsertEmbroiderySurcharge(productId, payload)

            const {success, message: error} = response

            if (!success) {
                return this.setState({
                    loading: false,
                }, () => {
                    message.error(error)
                })
            }

            this.setState({
                disableAdd: false,
                loading: false
            }, () => {
                this._refetchEmbroiderySurcharge()
                message.success('Update embroidery surcharge success!')
            })
            
        } catch (e) {
            this.setState({
                loading: false,
            }, () => {
                message.error(e.message)
            })
        }
    }

    _handleAddSurcharge = () => {
        const {surchargesState} = this.state
        let newSurcharges = Object.assign([], surchargesState)

        newSurcharges.push(
            {
                type: 'Embroidery Fee',
                store: '',
                simple: '',
                complex: '',
                secondLocationFee: '',
                status: 'new',
                disabledEdit: false,
                surchargeId: ''
            }
        )
        
        this.setState({
            surchargesState: newSurcharges,
            disableAdd: true
        })
    }

    _handleDeleteSurchargeItemSaved = async (index) => {
        this.setState({
            loading: true,
            error: ''
        })

        try {
            const {productId} = this.props
            const {surchargesState} = this.state
            const response = await deleteEmbroiderySurcharge(productId, surchargesState[index]['surchargeId'])

            const {success, message: error} = response

            if (!success) {
                return this.setState({
                    loading: false,
                }, () => {
                    message.error(error)
                })
            }

            this.setState({
                disableAdd: false,
                loading: false
            }, () => {
                this._refetchEmbroiderySurcharge()
                message.success('Delete embroidery surcharge success!')
            })
            
        } catch (e) {
            this.setState({
                loading: false,
            }, () => {
                message.error(e.message)
            })
        }
    }

    _refetchEmbroiderySurcharge = async () => {
        this.setState({
            loading: true,
        })
        try {
            const {productId} = this.props
            const response = await getProductDetail(productId)

            const {data, success, message} = response

            const {embroidery_surcharge} = data

            const newSurcharges = embroidery_surcharge.length > 0 ? embroidery_surcharge.map(item => {
                const surchargeItem = {
                    type: 'Embroidery Fee',
                    store: item.store.join(", "),
                    simple: item.simple,
                    complex: item.complex,
                    secondLocationFee: item.second_rental_fee,
                    status: 'exist',
                    disableEdit: true,
                    surchargeId: item._id
                }
    
                return surchargeItem
            }): []
            
            this.setState({
                surchargesState: newSurcharges,
                surchargesStateOld: JSON.parse(JSON.stringify(newSurcharges))
            })
        } catch (e) {
            this.setState({
                loading: false,
            }, () => {
                message.error(e.message)
            })
        }
    }

    render() {
        const {dimensionsState, surchargesState, disableAdd} = this.state
        const {disableAll} = this.props

        return (
            <>
                <div className='ProductDimension mt-3'>
                    <div className="SectionInner">
                        <h3 className="SectionHeading mb-3">Dimensions</h3>
                        <div className='mt-4'>
                            {dimensionsState.length > 0 && dimensionsState.map((item, index) => {
                                return (
                                    <div key={index} className="group bg-light p-3 mb-3">
                                        <div className="itemChild">
                                            <div className="row">
                                                {item.length > 0 && item.map((itemC, indexC) => {
                                                    return (
                                                        <div key={indexC}
                                                            className="itemSide col-12 mb-3 d-flex align-items-center">
                                                            <span
                                                                className="pr-2 font-weight-bold">{itemC.side.slice(0, 1).toUpperCase()}</span>
                                                            <input type="text" value={itemC.width} name="width"
                                                                className={classNames('form-control', '')}
                                                                onChange={this._handleChangeDimensionItem(index, itemC.side)}
                                                            />
                                                            <span className="p-1">x</span>
                                                            <input type="text" value={itemC.height} name="height"
                                                                className={classNames('form-control', '')}
                                                                onChange={this._handleChangeDimensionItem(index, itemC.side)}
                                                            />
                                                        </div>
                                                    )
                                                })}
                                            </div>
                                        </div>
                                        <div className="iconClose text-danger cursor-pointer"
                                            onClick={() => this._handleDeleteItem(index)}>
                                            <CloseCircleOutlined/>
                                        </div>
                                    </div>
                                )
                            })}
                            {!disableAll &&
                                <button className="btn btn-dark" onClick={this._handleAddDimension}>Add more</button>
                            }
                        </div>
                    </div>
                </div>

                <div className='ProductDimension mt-3'>
                    <div className="SectionInner">
                        <h3 className="SectionHeading mb-3">Surcharge</h3>
                        <div className='mt-4'>
                            {surchargesState.length > 0 && surchargesState.map((item, index) => {
                                return (
                                    <div key={index} className="group bg-light p-3 mb-3">
                                        <div className="itemChild">
                                            <div className="row">
                                                <div className="itemSide col-12 mb-3 d-flex align-items-center">
                                                    <Select
                                                    style={{ width: '100%' }}
                                                    disabled
                                                    options={[{ value: 'Embroidery Fee', label: 'Embroidery Fee' }]}
                                                    value={item.type}
                                                    />
                                                </div>

                                                <div className="itemSide col-12 mb-3 d-flex align-items-center">
                                                    {/* <input type="text" value={item.store} name="store" placeholder='*' onChange={this._handleChangeDimensionItem(index)}/> */}
                                                    <Tooltip title="Store">
                                                        <Input placeholder="*" value={item.store} name="store" onChange={this._handleChangeSurchargeItem(index)}/>
                                                    </Tooltip>
                                                </div>
                                            </div>

                                            <div className="row">
                                                <div className="itemSide col-12 mb-3 d-flex align-items-center">
                                                    {/* <InputNumber type="number" placeholder="Simple" value={item.simple} name="simple" onChange={value => this._handleChangeSurchargeInputNumber(index, 'simple', value)} style={{width: '100%'}}/> */}
                                                    <Tooltip title="Simple">
                                                        <Input placeholder="Simple" value={item.simple} name="simple" onChange={this._handleChangeSurchargeItem(index)} style={{width: '100%'}}/>
                                                    </Tooltip>
                                                </div>

                                                <div className="itemSide col-12 mb-3 d-flex align-items-center">
                                                    {/* <InputNumber type="number" placeholder="Complex" value={item.complex} name="complex" onChange={value => this._handleChangeSurchargeInputNumber(index, 'complex', value)} style={{width: '100%'}}/> */}
                                                    <Tooltip title="Complex">
                                                        <Input placeholder="Complex" value={item.complex} name="complex" onChange={this._handleChangeSurchargeItem(index)} style={{width: '100%'}}/>
                                                    </Tooltip>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="itemSide col-12 mb-3 d-flex align-items-center">
                                                    {/* <InputNumber type="number" placeholder="Second location fee" value={item.secondLocationFee} name="secondLocationFee" onChange={value => this._handleChangeSurchargeInputNumber(index, 'secondLocationFee', value)} style={{width: '100%'}}/> */}
                                                    <Tooltip title="Second location fee">
                                                        <Input placeholder="Second location fee" value={item.secondLocationFee} name="secondLocationFee" onChange={this._handleChangeSurchargeItem(index)} style={{width: '100%'}}/>
                                                    </Tooltip>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="surcharge-action d-flex">
                                            {
                                                item.status === 'new' ? <Popconfirm
                                                title="Embroidery surcharge has not been saved! Do you still want to delete?"
                                                onConfirm={() => this._handleDeleteSurchargeItemNotSave(index)}
                                                // onCancel={cancel}
                                                okText="Yes"
                                                cancelText="No"
                                            >
                                                <div className="iconClose text-danger cursor-pointer">
                                                    <CloseCircleOutlined/>
                                                </div>
                                            </Popconfirm> : <Popconfirm
                                            title="Are you sure?"
                                            onConfirm={() => this._handleDeleteSurchargeItemSaved(index)}
                                            // onCancel={cancel}
                                            okText="Yes"
                                            cancelText="No"
                                        >
                                            <div className="iconClose text-danger cursor-pointer">
                                                <CloseCircleOutlined/>
                                            </div>
                                        </Popconfirm>
                                            }

                                            {
                                                item.disableEdit ? '' : <div className="iconSave text-success cursor-pointer"
                                                    onClick={() => this._handleSaveSurchargeItem(index)}>
                                                        <CheckCircleOutlined />
                                                    </div>
                                            }
                                            {/* <div className="iconSave text-success cursor-pointer"
                                            onClick={() => this._handleSaveSurchargeItem(index)}>
                                                <CheckCircleOutlined />
                                            </div> */}
                                        </div>
                                    </div>
                                )
                            })}
                            {!disableAll &&
                                <button className="btn btn-dark" onClick={this._handleAddSurcharge} disabled={disableAdd} style={{cursor: disableAdd ? 'not-allowed' : 'pointer'}}>Add more</button>
                            }
                        </div>
                    </div>
                </div>
            </>
        )
    }
}

export default ProductDimension
