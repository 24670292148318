import React, {Component} from 'react'
import PropTypes from 'prop-types'
import {Button, Modal} from 'antd'
import requestCancelJob from '../../actions/requestCancelJob'
import requestRunJob from "../../actions/requestRunJob"

class WebhookActions extends Component {

    _handleRunClick = async () => {
        const {webhook, onFetchWebhook} = this.props
        const {_id, order, object_id} = webhook

        if(order.id){
            Modal.confirm({
                title: 'Are you sure want to run this job?',
                content: <div>Order code: <strong>{order.id}</strong></div>,
                onOk() {
                    return requestRunJob(_id, onFetchWebhook)
                },
            })
        }

        if(object_id.name){
            Modal.confirm({
                title: 'Are you sure want to run this job?',
                content: <div>Order code: <strong>{object_id.name}</strong></div>,
                onOk() {
                    return requestRunJob(_id, onFetchWebhook)
                },
            })
        }        
    }

    _handleCancelClick = () => {
        const {webhook, onFetchWebhook} = this.props
        const {_id, order, object_id} = webhook

        if(order.id){
            Modal.confirm({
                title: 'Are you sure want to cancel this job?',
                content: <div>Order code: <strong>{order.id}</strong></div>,
                onOk() {
                    return requestCancelJob(_id, order.id, onFetchWebhook)
                },
            })
        }

        if(object_id.name){
            Modal.confirm({
                title: 'Are you sure want to cancel this job?',
                content: <div>Order code: <strong>{object_id.name}</strong></div>,
                onOk() {
                    return requestCancelJob(_id, object_id.name, onFetchWebhook)
                },
            })
        }
    }

    render() {
        const {webhook, permissionsData} = this.props
        const {is_sent, status} = webhook

        return (permissionsData.includes('ffm_webhook_job_update') &&
            <td className="WebhookActions">
                <div className="d-flex flex-row">
                    {
                        !is_sent &&
                        <Button onClick={this._handleRunClick} type='primary'>Run</Button>
                    }

                    {
                        !is_sent && 'cancelled' !== status &&
                        <Button onClick={this._handleCancelClick} type='danger' className='ml-3'>Cancel</Button>
                    }
                </div>
            </td>
        )
    }
}

WebhookActions.propTypes = {
    webhook: PropTypes.object.isRequired,
    onFetchWebhook: PropTypes.func.isRequired,
}

export default WebhookActions
