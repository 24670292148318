import React, {Component} from 'react'
import {Modal, notification} from 'antd'
import {createFactoryOrder} from '../../../../../services/api/ExportOrderServices'
import ContentResults from './ContentResults'

const _doAction = async (params) => {
    try {
        const {selectedOrders, onSuccess, resetSelect} = params
        const {data, success, message: resMessage} = await createFactoryOrder(selectedOrders)

        if (!success) {
            throw new Error(resMessage)
        }

        const {success: numberSuccess, errors, errorOrders} = data

        if (errors === 0) {
            Modal.success({
                title: 'Create orders successfully!',
                width: 460
            })
        } else {
            Modal.info({
                title: 'Create factory orders results:',
                content: <ContentResults errors={errors} success={numberSuccess} errorOrders={errorOrders}/>,
                width: 460
            })
        }

        if (typeof resetSelect === 'function') {
            resetSelect()
        }

        if (typeof onSuccess === 'function') {
            onSuccess()
        }

    } catch (e) {
        notification.error({
            message: 'ERROR',
            description: e.message,
            duration: 0,
        })
    }
}

class BulkCreateFactoryOrders extends Component {
    _handleClick = e => {
        e.preventDefault()

        const props = this.props

        Modal.confirm({
            title: `Please confirm create factory orders`,
            onOk() {
                return _doAction(props)
            },
        })
    }

    render() {
        return (
            <div className='BulkCreateFactoryOrders' onClick={this._handleClick}>
                Create factory orders
            </div>
        )
    }
}

export default BulkCreateFactoryOrders
