import React, { Component } from 'react'
import { getOrderRejectedItems } from "../../../../services/api/OrdersServices"
import broker from '../../OrderBroker'
import OrderRejectItem from "./OrderRejectItem"
import RefundButton from '../fulfilled/refund/RefundButton'

class OrderRejectedItems extends Component {
    state = {
        orderItems: null,
        error: "",
    }

    _subscription = null
    _subscription2 = null
    _subscription3 = null

    componentDidMount() {
        this._fetchOrderRejectedItems()
        this._subscription = broker.subscribe("ORDER_ITEM_REJECTED", this._handleUpdateOrderItemRejected)
        this._subscription2 = broker.subscribe("ORDER_RESTORE", this._handleUpdateOrderItemRejected)
        this._subscription3 = broker.subscribe("ORDER_REJECTED", this._handleUpdateOrderItemRejected)
    }

    componentWillUnmount() {
        broker.unsubscribe(this._subscription)
        broker.unsubscribe(this._subscription2)
        broker.unsubscribe(this._subscription3)
    }

    _fetchOrderRejectedItems = async () => {
        try {
            const { orderId } = this.props
            const { data, success, message } = await getOrderRejectedItems(orderId)

            if (!success) {
                return this.setState({
                    error: message,
                })
            }

            return this.setState({
                orderItems: data,
                error: "",
            })
        } catch (e) {
            return this.setState({
                error: e.message,
            })
        }
    }

    _handleUpdateOrderItemRejected = () => {
        this._fetchOrderRejectedItems()
    }

    render() {
        const { orderItems } = this.state
        const { fulfillmentStatus, orderId, refunded, code, invoiceRefund, paymentStatus } = this.props

        if (!orderItems || orderItems.length <= 0) {
            return null
        }

        return (
            <div className="OrderRejectedItems">
                <div className="SectionInner">
                    <div className="d-flex align-items-center justify-content-between mb-3">
                        <h3 className="SectionHeading mb-3">Rejected</h3>
                        <div className="d-flex align-items-center">
                            {(['paid', 'partially_refunded', 'refunded'].includes(paymentStatus) && ['cancelled'].includes(fulfillmentStatus)) &&
                                <RefundButton
                                    refunded={refunded}
                                    orderId={orderId}
                                    code={code}
                                    fulfillmentStatus={fulfillmentStatus}
                                    invoiceRefund={invoiceRefund}
                                    currency={orderItems[0].currency}
                                />
                            }
                        </div>
                    </div>

                    {
                        orderItems.map(item => {
                            return (
                                <OrderRejectItem key={item._id} item={item} fulfillmentStatus={fulfillmentStatus}
                                    orderId={orderId} onFetchOrderItems={this._fetchOrderRejectedItems} />
                            )
                        })
                    }

                    {/*<div className="border-top pt-4 text-right">*/}
                    {/*    <UndoRejectedItems orderId={orderId} onUpdateOrderItemRejected={this._handleUpdateOrderItemRejected}/>*/}
                    {/*</div>*/}
                </div>
            </div>
        )
    }
}

export default OrderRejectedItems
