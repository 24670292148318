import React, {useState} from 'react'
import PropTypes from 'prop-types'
import CategoriesModal from "./CategoriesModal"

ProductCategories.propTypes = {
    category: PropTypes.string,
    productId: PropTypes.string,
    onUpdateState: PropTypes.func.isRequired
}

function ProductCategories(props) {
    const {category, productId, onUpdateState, disableAll} = props
    const [modal, setModal] = useState(false)

    const _onToggleModal = () => {
        setModal(!modal)
    }

    return (
        <div className='ProductCategories'>
            <div className="SectionInner">
                <div className="SectionHeading">Category</div>

                <div className="mt-4 d-flex justify-content-between align-items-center">
                    {category} 
                    {!disableAll &&
                        <span 
                            className="text-primary cursor-pointer float-right"
                            onClick={_onToggleModal}
                        >
                            Change
                        </span>
                    }
                </div>
            </div>

            {
                modal && <CategoriesModal
                    productId={productId}
                    onUpdateState={onUpdateState}
                    onToggleModal={_onToggleModal}
                />
            }
        </div>
    )
}

export default ProductCategories
