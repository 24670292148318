import React, {Component} from 'react'
import PropTypes from 'prop-types'
import moment from "moment"


class OrderTime extends Component {
    render() {
        const {updated, order_paid_at} = this.props.order
        const textTime = moment(order_paid_at || updated).format('HH:mm DD/MM/YYYY')

        return (
            <td className="OrderTime">
                {textTime}
            </td>
        )
    }
}

OrderTime.propTypes = {
    order: PropTypes.object.isRequired,
}

export default OrderTime
