import React from 'react'
import { Select, Input} from 'antd'
import {ReactComponent as SearchIcon} from '../../../../statics/assets/icons/search.svg'

function FileDetailFilter(props) {
    const { Option } = Select
    const {
        status,
        loading, search,
        handleChangeStatus,
        handleChangeSearch,
    } = props

    return (
        <div className='FileDetailFilter TableFilter d-xl-flex justify-content-between align-items-end'>
            <div className="Filter">
                <div className="d-flex flex-wrap justify-content-start">
                    <div className="FilterWrapper FilterOptions mb-3 mr-3">
                        <div className="d-flex align-items-stretch justify-content-end">
                            <Input
                                style={{ minWidth: "400px" }}
                                placeholder='Search package name...'
                                value={search || ''}
                                onChange={handleChangeSearch}
                                allowClear
                                disabled={loading}
                                className='search'
                                prefix={<SearchIcon className='mr-2'/>}
                            />
                        </div>
                    </div>

                    <div className='CustomSelect FilterOptions mr-3 mb-3'>
                        <Select
                            style={{ minWidth: "200px"}}
                            placeholder="-- Status --"
                            allowClear
                            value={status || []}
                            onChange={handleChangeStatus}
                            disabled={loading}
                        >
                            <Option value="done">Done</Option>
                            <Option value="error">Error</Option>
                            {/* <Option value="pending">Pending</Option> */}
                        </Select>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default FileDetailFilter