import React from 'react'
import { Select } from 'antd'

const FilterSelect = (props) => {
    const { style, mode, placeholder, options, allowClear, showSearch, value, handleChangeFilters, field, type } = { ...props }
    return (
        <Select
            value={value || []}
            mode={mode}
            style={style}
            placeholder={placeholder}
            onChange={(value) => handleChangeFilters(field, value)}
            allowClear={allowClear}
            showSearch={showSearch}
            filterOption={(input, option) => option.children.toLocaleLowerCase().includes((input || "").toLocaleLowerCase())}
            showArrow
        >
            {type === "product" ?
                options.map(option => (
                    <Select.Option key={option.value} value={option.label}>{option.label}</Select.Option>
                )) :
                options.map(option => (
                    <Select.Option key={option.value} value={option.value}>{option.label}</Select.Option>
                ))
            }
        </Select>
    )
}

export default FilterSelect