import React, {Component} from 'react'
import PropTypes from "prop-types"


class OrderZone extends Component {
    render() {
        const {order} = this.props
        const {zone} = Object.assign({}, order)

        if (!zone) {
            return null
        }

        return (
            <div className="OrderZone mb-sm-0 mb-1">
                <div className="card mr-3">
                    <div className="card-body">
                        <span className="Label">Zone: </span>
                        <strong className="Title">{zone}</strong>
                    </div>
                </div>

            </div>
        )
    }
}

OrderZone.propTypes = {
    order: PropTypes.object.isRequired,
}

export default OrderZone
