import React from 'react'
import moment from "moment"
import {Link} from "react-router-dom"
import StatusColumn from "./StatusColumn"

const TableRow = (props) => {
    const {notification} = props
    const {order, supplier, factory_info, package_status, pushed_type} = notification
    const {address} = order
    const textTime = notification.pushed_at !== undefined ? moment(notification.pushed_at).format('HH:mm DD/MM/YYYY') : ''

    return (
        <tr>
            <td>
                <div>
                    <strong>
                        <Link to={`/a/orders-supplier/${notification._id}`}>{notification.name}</Link>
                    </strong>
                </div>
                <div>{order.namespace}</div>
                <div>{factory_info !== undefined && factory_info.order_reference_id}</div>
                <div>{textTime}</div>
            </td>
            <td>{address.country_code || ''}</td>
            <td>{supplier.name}</td>
            <td>{order.shipping_plan !== null && order.shipping_plan.slug}</td>
            <td>{package_status !== undefined && <StatusColumn status={package_status || ''}/>}</td>
            <td>{(factory_info !== undefined && factory_info.status !== null) && <StatusColumn status={factory_info.status || ''}/>}</td>
            <td>{pushed_type || ''}</td>
            <td>
                {(notification.hasOwnProperty('tracking_number') && notification.tracking_number !== '') && (
                    <a rel="noopener noreferrer" target="_blank" href={notification.tracking_url}>{notification.tracking_number || ''}</a>
                )}
                <div>{notification.tracking_company || ''}</div>
                <div>{notification.tracking_status || ''}</div>
            </td>
            <td>{notification.note}</td>
        </tr>
    )
}

export default TableRow
