import React, {Component} from 'react'
import {Button, message, Modal} from 'antd'
import {UploadOutlined, PaperClipOutlined, DeleteOutlined, ExclamationCircleOutlined} from '@ant-design/icons'
import {UploadFileNotification} from "../../../../services/api/PlatformApiKeysServices"
import FileTemplate from "./FileTemplate"

class UploadFile extends Component {

    state = {
        modal: false,
        file: '',
        error: '',
        loading: false
    }

    _handleChangeFile = (e) => {
        const file = e.target.files[0]

        if (!!file) {
            const {name, size} = file
            const fileName = name.split('.').pop()
            const format = /^[a-zA-Z0-9\s/.\-_]+$/

            this.setState({
                error: '',
                file: file
            })

            if (fileName !== 'csv') {
                return this.setState({
                    error: 'Import by CSV file format.'
                })
            }

            if (size > (5 * 1024 * 1024)) {
                return this.setState({
                    error: 'Import file size limit 5MB.'
                })
            }

            if (!format.test(name)) {
                return this.setState({
                    error: 'File name have not contain special characters.'
                })
            }
        }
    }

    _onClickIcon = () => {
        document.getElementById('CustomUpload').click()
    }

    _onRemoveFile = () => {
        this.setState({
            file: '',
            error: ''
        })
    }

    _closeModal = () => {
        this.setState({
            modal: false,
            file: '',
            error: ''
        })
    }

    _openModal = () => {
        this.setState({
            modal: true
        })
    }

    _onUploadFile = async () => {
        this.setState({
            loading: true
        })

        try {
            const {file} = this.state
            const {name} = file
            const formData = new FormData()
            formData.append('file', file)

            const response = await UploadFileNotification(formData)

            const {message: error, success} = response

            if (!success) {
                return this.setState({
                    loading: false
                }, () => {
                    this._closeModal()
                    message.error(error)
                })
            }

            this.setState({
                loading: false
            }, () => {
                const {reloadListNotification} = this.props

                this._closeModal()
                reloadListNotification()
                message.success(`"${name}" upload successfully!`)
            })
        } catch (e) {
            this.setState({
                loading: false
            }, () => {
                message.error(e.message)
            })
        }
    }

    render() {
        const {modal, loading, error, file} = this.state

        return (
            <div className="UploadFilePage">
                <Button type="primary" onClick={this._openModal}>
                    Upload file
                </Button>

                <Modal
                    title="Upload file notification"
                    className="ModalUploadFile"
                    visible={modal}
                    centered
                    width={640}
                    onOk={this._onUploadFile}
                    onCancel={this._closeModal}
                    footer={[
                        <Button key="back" onClick={this._closeModal}>
                            Cancel
                        </Button>,
                        <Button
                            key="submit"
                            disabled={(!!error && error.length > 0) || !file}
                            type="primary"
                            loading={loading}
                            onClick={this._onUploadFile}
                        >
                            Upload file
                        </Button>
                    ]}
                >
                    <div className='Upload'>
                        <div className='d-flex align-items-center'>
                            <h3 className='Title'>Upload file CSV</h3>
                            <FileTemplate/>
                        </div>

                        <p>File name have not contain special characters and limit 5MB</p>

                        <div className={`UploadFile ${!!error && error.length > 0 ? 'Error' : ''}`}>
                            <input
                                id='CustomUpload'
                                type='file'
                                accept='.csv'
                                title={''}
                                onChange={this._handleChangeFile}
                            />
                            <div onClick={this._onClickIcon} className='ClickUpload'>
                                <UploadOutlined/><span>Choose File</span>
                            </div>
                            <div className="File">
                                {
                                    file ? <div className='FileName'>
                                            <PaperClipOutlined/>
                                            <div className="FloatRight">
                                                <span>{file.name}</span><DeleteOutlined onClick={this._onRemoveFile}/>
                                            </div>
                                        </div> :
                                        <span>No file chosen</span>
                                }
                            </div>
                        </div>

                        {
                            !!error && error.length > 0 &&
                            <div className="ErrorText"><ExclamationCircleOutlined/>{error}</div>
                        }
                    </div>
                </Modal>
            </div>
        )
    }
}

export default UploadFile
