import React, {useContext, useState} from 'react'
import PropTypes from 'prop-types'
import {Button, Modal, Input, Tooltip} from "antd"
import OrderPartnersContext from "../../context/OrderPartnersContext"
import {updateNotePackage} from "../../../../services/api/OrdersServices"
import Truncate from "../../../../helpers/common/formatNote"

const {TextArea} = Input

NotePackage.propTypes = {
    indexRow: PropTypes.number,
    note: PropTypes.string
}

function NotePackage(props) {
    const {note, indexRow, fulfillmentId} = props
    const {onChangeListOrder} = useContext(OrderPartnersContext)
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState('')
    const [visible, setVisible] = useState(false)
    const [notePackage, setNotePackage] = useState(note)

    const _onShowModal = () => {
        setVisible(true)
        setNotePackage(note)
    }

    const _onCloseModal = () => {
        setVisible(false)
        setNotePackage(note)
        setError('')
    }

    const _onUpdateNotePackage = async () => {
        setLoading(true)
        setError('')

        try {
            const payload = {
                note: notePackage
            }
            const response = await updateNotePackage(fulfillmentId, payload)

            const {success, message: error} = response

            if (!success) {
                setLoading(false)

                return setError(error)
            }

            setLoading(false)
            _onChangeNote(notePackage)
            _onCloseModal()
        } catch (e) {
            setLoading(false)
            setError(e.message)
        }
    }

    const _handleChangeNotePackage = (e) => {
        const {value} = e.target

        setNotePackage(value)
    }

    const _onChangeNote = (value) => {
        if (typeof onChangeListOrder === 'function') {
            onChangeListOrder(indexRow, 'note', value)
        }
    }

    return (
        <div className='NotePackage'>
            <Truncate
                string={note}
                length={20}
                text={'Note package'}
            />

            <Tooltip title='Edit/Add package note'>
                <i className='ti-pencil' onClick={_onShowModal}/>
            </Tooltip>

            <Modal
                className="PackageNoteModal"
                visible={visible}
                title="Package Note"
                onOk={_onUpdateNotePackage}
                onCancel={_onCloseModal}
                footer={[
                    <Button key="submit" type="primary" loading={loading} onClick={_onUpdateNotePackage}>
                        Save note
                    </Button>
                ]}
            >
                {
                    !!error && error.length > 0 && <div className="text-danger mb-3">{error}</div>
                }

                <TextArea
                    rows={5}
                    value={notePackage}
                    onChange={_handleChangeNotePackage}
                    placeholder="Enter your note package..."
                />
            </Modal>
        </div>
    )
}

export default NotePackage
