import React, {Component} from 'react'
import ReactPaginate from 'react-paginate'

class ProductLinePagination extends Component {
    _handlePageChange = (data) => {
        const {selected} = data
        const {filter, _handUpdateState} = this.props

        _handUpdateState("filter", {
            ...filter,
            page: selected + 1,
        })
    }

    render() {
        const {pages, filter} = this.props
        const {page} = filter

        return (
            pages > 1 &&
            <div className="ProductLinePagination Pagination">
                <ReactPaginate
                    previousLabel="Previous"
                    nextLabel="Next"
                    pageCount={pages}
                    forcePage={page - 1}
                    marginPagesDisplayed={2}
                    pageRangeDisplayed={5}
                    onPageChange={this._handlePageChange}
                    containerClassName="pagination"
                    pageClassName='page-item'
                    previousClassName='Previous page-item'
                    nextClassName='Next page-item'
                    pageLinkClassName='page-link'
                    previousLinkClassName='page-link'
                    nextLinkClassName='page-link'
                    subContainerClassName="pages pagination"
                    activeClassName='active'
                />
            </div>
        )
    }
}

export default ProductLinePagination
