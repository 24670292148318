import React, {Component} from 'react'
import PlatformSettingsPage from './PlatformSettingsPage'

class PlatformSettingsContainer extends Component {
    render() {
        const {id} = this.props.match.params

        return (
            <div className="PlatformSettingsContainer">
                <PlatformSettingsPage
                    platformId={id || ''}
                    {...this.props}/>
            </div>
        )
    }
}

export default PlatformSettingsContainer
