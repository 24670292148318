import React from 'react'
import ItemImage from './ItemImage'

const ItemImages = (props) => {
    const { status, orderId, type, tags, namespace, mockUpUrl, fulfillmentId, reload, processed_artworks_pf, image } = props
    const { preview = {}, ...restProcessedArtworksPf } = Object.assign({}, processed_artworks_pf)

    return (
        <td className='ItemImage'>
            {
                Object.keys(restProcessedArtworksPf).filter(i => !!restProcessedArtworksPf[i]).map((key, index) => {
                    return (
                        <ItemImage
                            key={index}
                            type={type}
                            tags={tags}
                            side={key}
                            image={image}
                            orderId={orderId}
                            status={status}
                            reload={reload}
                            muckUpUrl={mockUpUrl}
                            namespace={namespace}
                            fulfillmentId={fulfillmentId}
                            previewImage={!!preview[key] ? preview[key] : restProcessedArtworksPf[key]}
                            previewUrl={restProcessedArtworksPf[key]}
                        />
                    )
                })
            }
        </td>
    )
}

export default ItemImages