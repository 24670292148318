import React, { useState } from 'react'
import { Modal, Select, Upload, Button, Input, message } from 'antd'
import { uploadFileCSV } from '../../../../services/api/SuppliersServices'


function UploadFile(props) {
    const [fileList, setFileList] = useState([])
    const { showUpLoadFile, setShowUpLoadFile, toggleModal, listSupplier, reload } = props
    const [supplier, setSupplier] = useState('')
    const [fileName, setFileName] = useState('')
    const [fileError, setFileError] = useState('')
    const [error, setError] = useState({})


    const handleChangeFileName = (e) => {
        setError({ ...error, errorFileName: '' })
        const format = /^[a-zA-Z0-9\s/.\-_]+$/
        if (!format.test(e.target.value)) {
            setError({ ...error, errorFileName: 'File name does not contain Vietnamese, special characters. Please check again.' })
        }
        setFileName(e.target.value)
    }

    const handleChangeSupplier = (value) => {
        setSupplier(value)
        // setError({ ...error, errorSupplier: '' })
        // setFileError('')
    }

    const handleChangeFile = (e) => {
        const file = e.file
        const fileListLength = e.fileList.length
        if (!!file) {
            const { name, size, type } = file
            const format = /^[a-zA-Z0-9\s/.\-_]+$/
            const Filename = !!name && name.split('.csv')[0]
            setFileError('')
            fileListLength ? setFileList([file]) : setFileList([])
            if(!!name){
                setFileName(Filename)
            }

            if (fileListLength && !format.test(Filename)) {
                return setError({ ...error, errorFileName: 'File name does not contain Vietnamese, special characters. Please check again.' })
            }
            
            if (fileListLength && format.test(Filename)) {
                setFileError('')
                setError({})
            }
        }
    }

    const handleUpload = async () => {

        if (!!supplier && !!fileList && !!fileName) {
            try {
                const formData = new FormData()
                formData.append('file', fileList[0])
                formData.append('file_name', fileName)
                formData.append('supplier_id', supplier)

                const { data, message:mess, success } = await uploadFileCSV(formData)
                if (success) {
                    handleToggleModal()
                    message.success('File uploaded!')
                    reload()
                } else {
                    setFileError(mess)
                }
            } catch (error) {

            }
        }
        return false
    }

    const handleToggleModal = () => {
        toggleModal()
        setFileList('')
        setError('')
        setFileError('')
        setSupplier('')
        setFileName('')
    }

    const { Option } = Select
    const supplierOption = !!listSupplier && listSupplier.filter(i=>i.name!=="1C").map(item =>
        <Option key={item._id} value={item._id}>{item.name}</Option>
    )
    const {errorFileName}= {...error}
    const enableUpload = !!supplier && !!fileList && !!fileName && !errorFileName && !fileError

    return (
        <Modal
            visible={showUpLoadFile}
            title='Upload Bill'
            onCancel={handleToggleModal}
            className='UploadModal'
            okText='Upload'
            onOk={handleUpload}
            okButtonProps={{disabled:!enableUpload}}
        >
            <div className='d-flex justify-content-between mb-3'>
                <label >Choose supplier:</label>
                <div className='w-75'>
                    <Select
                        className='w-100'
                        placeholder="-- Supplier --"
                        allowClear
                        value={supplier || []}
                        showSearch
                        onChange={handleChangeSupplier}
                        filterOption={(input, option) => 
                            option.children.toLocaleLowerCase().includes((input || "").toLocaleLowerCase())
                        }
                    >
                        {supplierOption}
                    </Select>
                    {error.errorSupplier && (<small className='text-danger'>{error.errorSupplier}</small>)}
                </div>
            </div>
            <div className='d-flex justify-content-between mb-3'>
                <label>Choose file:</label>
                <div className='w-75'>
                    <Upload
                        accept='text/csv'
                        multiple={false}
                        beforeUpload={() => {
                            return false
                        }}
                        onRemove={
                            file => {
                                const index = fileList.indexOf(file)
                                console.log(index)
                                const newFileList = fileList.slice()
                                newFileList.splice(index, 1)
                                setFileList(newFileList)
                                setFileName('')
                            }
                        }
                        fileList={fileList || []}
                        onChange={handleChangeFile}
                        listType='picture'
                    >
                        <Button icon={''}>Choose file upload</Button>
                    </Upload>
                    {fileError && <small className='text-danger justify-content-end'>{fileError}</small>}
                </div>
            </div>
            <div className='d-flex justify-content-between mb-3'>
                <label >File name:</label>
                <div className='w-75'>
                    <Input
                        className='file_name'
                        value={fileName || ''}
                        onChange={handleChangeFileName}
                        allowClear
                    ></Input>
                    {error.errorFileName && (<small className='text-danger'>{error.errorFileName}</small>)}
                </div>
            </div>
        </Modal>
    )
}

export default UploadFile