import React, {Component} from 'react'
import PropTypes from 'prop-types'
import {undoRejectOrderItem} from "../../../../services/api/OrdersServices"
import broker from '../../OrderBroker'
import checkFulfillStatus from "../../../../helpers/common/checkFulfillStatus"
import formatCurrency from "../../../../helpers/common/formatCurrency"

class OrderCancelItem extends Component {
    
    render() {
        const {item} = this.props
        const {
            product,
            variant,
            price,
            quantity,
            currency,
            fulfillmentItems = [],
            merchizeCatalogSku
        } = item

        const fulfillmentItemCancel = fulfillmentItems.filter(item => item.production_status === 'cancelled' || item.production_status === 'cancel-ship')

        if(fulfillmentItemCancel.length === 0) return null

        const {sku: skuVariant} = Object.assign({}, variant)
        const vSku = skuVariant || ''
        const options = merchizeCatalogSku ? merchizeCatalogSku.options : []
        const product_title = merchizeCatalogSku ? merchizeCatalogSku.product_title : ''

        return (
            <div className="OrderRejectItem OrderItem mb-4">
                <div className="row">
                    <div className="col-2">
                        <img src={product.preview} alt="Product thumbnail"/>
                    </div>

                    <div className="col-6">
                        <a href={product.url} target="_blank" rel="noopener noreferrer"
                           className="d-block mb-2">{product.title}</a>

                        <div className="d-flex mb-1">
                            <span className="Label font-weight-500">Price:</span>
                            <span>{formatCurrency(currency, price)}</span>
                        </div>

                        {
                            !!vSku &&
                            <div className="d-flex mb-1">
                                <span className="Label font-weight-500">SKU:</span>
                                <span className='text-break'>{vSku}</span>
                            </div>
                        }

                        <div className="d-flex mb-1">
                            <span className="Label font-weight-500">Quantity:</span>
                            <span>{quantity}</span>
                        </div>

                        {
                            fulfillmentItems.length > 0 && fulfillmentItems[0].production_status &&
                            <div className="d-flex mb-1">
                                <span className="badge badge-danger badge-pill">{fulfillmentItems[0].production_status}</span>
                            </div>
                        }
                        
                    </div>
                    <div className={`${merchizeCatalogSku ? 'col-6 mt-3 border-right' : 'col-12 mt-3'}`}>
                        <div className='CustomVariant'>
                            <span className="Label mr-1">Variant:</span>
                            <span className='text-break'>{variant.title}</span>
                        </div>
                        <div className='CustomVariable'>
                            <ul>
                                {
                                    variant.options.length > 0 && variant.options.map(opt =>
                                        <li className="d-flex mb-1" key={opt.key}>
                                            <span className="Label mr-1">{opt.key}:</span>
                                            <span className={`text-break ${opt.key}`}>{opt.value}</span>
                                        </li>
                                    )
                                }
                            </ul>
                        </div>
                    </div>

                    {
                        merchizeCatalogSku &&
                        <div className="col-6 mt-3">
                            <div className='CustomVariant'>
                                <span className="Label">Catalog variant</span>
                            </div>
                            <div className='CustomVariable'>
                                <ul>
                                    {
                                        product_title &&
                                        <li className="mb-1">
                                            <span className="Label mr-1">Product Title:</span>
                                            <span className={`text-break`}>{product_title}</span>
                                        </li>
                                    }
                                    
                                    {
                                        options && options.length > 0 &&
                                        <li className="mb-1">
                                            <span className="Label mr-1">Variant:</span>
                                            <span className={`text-break`}>{options.join(" / ")}</span>
                                        </li>
                                    }
                                    
                                </ul>
                            </div>
                        </div>
                    }
                </div>
            </div>
        )
    }
}

OrderCancelItem.propTypes = {
    item: PropTypes.object.isRequired,
    fulfillmentStatus: PropTypes.string.isRequired,
    orderId: PropTypes.string.isRequired,
    onFetchOrderItems: PropTypes.func.isRequired,
}

export default OrderCancelItem
