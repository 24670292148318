import React, {useState} from 'react'
import {Tooltip} from 'antd'
import ConfirmChargeModal from './ConfirmChargeModal'
import { DollarCircleOutlined } from '@ant-design/icons'

const ConfirmCharge = (props) => {
    const [isOpen, toggleModal] = useState(false)

    return <div className="ConfirmCharge">
        <span className="cursor-pointer" onClick={() => toggleModal(true)}>
            <Tooltip title="Review charge for design update">
                <DollarCircleOutlined />
            </Tooltip>
        </span>
        {isOpen && <ConfirmChargeModal isOpen={isOpen} toggleModal={toggleModal} {...props}/>}
    </div>
}

export default ConfirmCharge