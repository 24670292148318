import React, {Component} from 'react'

class ListingUsersTableHead extends Component {
    render() {
        const {permissionsData} = this.props

        return (
            <thead className="ListingUsersTableHead">
            <tr>
                <th>#</th>
                <th>User Name</th>
                <th>Register Date</th>
                <th>Status</th>
                <th>Roles</th>
                <th>Department</th>
                {permissionsData.includes('ffm_setting_users_update') &&
                // (permissionsData.includes('ffm_setting_users_update') || permissionsData.includes('ffm_setting_users_delete')) &&
                    <th>Actions</th>
                }
            </tr>
            </thead>
        )
    }
}

export default ListingUsersTableHead
