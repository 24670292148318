import { useState, useEffect } from 'react'

export default function UseDebounce(value, delay, isClearing) {
    const [debouncedValue, setDebouncedValue] = useState(value)

    useEffect(
        () => {

            if (isClearing) {
                setDebouncedValue(value)
                return
            }

            const handler = setTimeout(() => {
                setDebouncedValue(value)
            }, delay)


            return () => {
                clearTimeout(handler)
            }
        },

        [value, isClearing]
    )

    return debouncedValue
}