import React, {Component} from 'react'
import classNames from 'classnames'

class ProductLineCategory extends Component {
    _handleClickCategory = (e) => {
        e.preventDefault()

        const {category, _handUpdateState, filter} = this.props
        const {_id: categoryId} = category

        _handUpdateState("filter", {
            ...filter,
            category: categoryId,
            page: 1,
        })
    }

    render() {
        const {categoryId, category} = this.props
        const {title, _id} = category

        return (
            <li className="ProductLineCategory nav-item cursor-pointer" onClick={this._handleClickCategory}>
                <span className={classNames("nav-link", {active: categoryId === _id})}>{title}</span>
            </li>
        )
    }
}

export default ProductLineCategory
