import React from 'react'
import ProductPage from "./ProductPage"

function ProductPageContainer(props) {
    const {productId: id} = props.match.params

    return (
        <div className='ProductPageContainer'>
            <ProductPage id={id}/>
        </div>
    )
}

export default ProductPageContainer
