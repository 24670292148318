import React, {Component} from 'react'

class HeadItems extends Component {
    render() {
        const {preference} = this.props
        const {storeMode} = preference
        return (
            <thead className='HeadItems'>
            <tr>
                <th className='Actions'>&nbsp;</th>
                <th>MockUp Front</th>
                <th>MockUp Back</th>
                <th>Design Front</th>
                <th>Design Back</th>
                <th>Design Sleeves</th>
                <th>Design Hood</th>
                <th className='Size'>Size</th>
                <th>Type</th>
                <th>Sku Partner</th>
                <th>Color</th>
                <th>Item</th>
                <th className='Quantity'>Quantity</th>
                {storeMode === "marketplace" && <th className='Actions'>&nbsp;</th>}
            </tr>
            </thead>
        )
    }
}

export default HeadItems
