import React from 'react'
import PropTypes from 'prop-types'
import ShippingAddress from "./ShippingAddress"

OrderBuyerDetails.propTypes = {
    address: PropTypes.any
}

function OrderBuyerDetails(props) {
    return (
        <div className="OrderBuyerDetails">
            <div className="card">
                <div className="card-header font-weight-bold d-flex justify-content-between">
                    <span>Buyer Details</span>
                </div>

                <div className="card-body">
                    {!!props.address && <ShippingAddress address={props.address}/>}
                </div>
            </div>
        </div>
    )
}

export default OrderBuyerDetails