import React, {Component} from 'react'
import WebhookPage from "./WebhookPage"
import DocTitleByStore from "../../dashboard/shared/DocTitleByStore"

class WebhookPageContainer extends Component {
    render() {
        return (
            <div className="WebhookPageContainer">
                <DocTitleByStore title="Webhook Jobs"/>
                <WebhookPage/>
            </div>
        )
    }
}

export default WebhookPageContainer
