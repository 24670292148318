import React, { useState, useEffect } from 'react'
import FastProductionRulesTable from './FastProductionRulesTable'
import UseDebounce from '../../shared/UseDebounce'
import { Input, DatePicker, Spin, Pagination, Select, message } from 'antd'
import { getListFastProductionRules } from '../../../services/api/FastProductionRulesServices'
import moment from 'moment'
import { getLocalData } from '../../../services/StorageServices'

function FastProductionRulesPage() {
    const permissionsData = !!getLocalData('permissions') ? Object.keys(getLocalData('permissions')) : []
    const { RangePicker } = DatePicker
    const dateFormat = "DD/MM/YYYY"
    const [loading, setLoading] = useState(false)
    const [listRule, setListRule] = useState([])
    const [page, setPage] = useState(1)
    const [limit, setLimit] = useState(10)
    const [total, setTotal] = useState(0)
    const [error, setError] = useState('')
    const [selectField, setSelectField] = useState('store')
    const [store, setStore] = useState('')
    const [productType, setProductType] = useState('')
    const [updatedDate, setUpdatedDate] = useState(['', ''])
    const [status, setStatus] = useState('')

    const debounceStore = UseDebounce(store, 1000)
    const debounceProductType = UseDebounce(productType, 1000)

    const fetchFastProductionRules = async () => {
        setLoading(true)
        setError('')

        const payload = { page, limit }
        if (store) payload.store = store
        if (productType) payload.product_types = productType
        if (updatedDate && updatedDate[0] && updatedDate[1]) {
            payload.updated_at = {
                from: updatedDate[0].format(dateFormat),
                to: updatedDate[1].format(dateFormat),
            }
        }
        if (status) payload.status = status

        try {
            const { success, data, message: mess } = await getListFastProductionRules(payload)
            if (!success) {
                throw new Error(mess)
            }
            setListRule(data.rules || [])
            setTotal(data.total)
        } catch (error) {
            setError(error.message)
            message.error(error.message)
        } finally {
            setLoading(false)
        }
    }

    const _handleChageSelectField = (value) => {
        setStore('')
        setProductType('')
        setSelectField(value)
    }

    const onChangeInput = (field, value) => {
        if (field === 'store') {
            setStore(value)
            setProductType('')
        }
        if (field === 'product_type') {
            setProductType(value)
            setStore('')
        }
        setPage(1)
    }

    const onChangeSelect = (field, value) => {
        if (field === 'status') {
            setStatus(value)
        }
        setPage(1)
    }

    const handleChangeDate = (field, value, dateString) => {
        setUpdatedDate(value)
        setPage(1)
    }

    const handPagePagination = (value) => {
        setPage(value)
    }

    const handleShowSizeChange = (current, size) => {
        setLimit(size)
        setPage(1)
    }

    useEffect(() => {
        fetchFastProductionRules()
    }, [page, limit, debounceStore, debounceProductType, updatedDate, status])

    const groupValue = {
        store: store,
        product_type: productType,
    }
    const groupPlaceHolder = {
        store: 'Search for Store',
        product_type: 'Search for Product Type',
    }

    const Status = [
        { name: 'active', value: 'active', label: 'Active' },
        { name: 'inactive', value: 'inactive', label: 'Inactive' },
    ]


    return (
        <div className='FastProductionRulesPage'>
            <div className="SectionInner mt-3">
                <div className="p-3">
                    <div className="filter mb-3">
                        <div className="d-flex align-items-center justify-content-between">
                            <div className="d-flex GroupFilter">
                                <div className="itemFilter mb-3">
                                    <Input.Group compact>
                                        <Select
                                            value={selectField}
                                            style={{ width: 150 }}
                                            onChange={_handleChageSelectField}
                                            disabled={loading}
                                            className='Group'
                                        >
                                            <Select.Option value="store">Store</Select.Option>
                                            <Select.Option value="product_type">Product Type</Select.Option>
                                        </Select>
                                        <Input
                                            style={{ width: 375 }}
                                            placeholder={groupPlaceHolder[selectField] || ''}
                                            onChange={e => onChangeInput(selectField, e.target.value)}
                                            value={groupValue[selectField] || ''}
                                            disabled={loading}
                                            allowClear
                                        />
                                    </Input.Group>
                                </div>
                                <div className="itemFilter ml-3 mb-3">
                                    <RangePicker
                                        value={updatedDate || []}
                                        placeholder={['Updated start', 'Updated end']}
                                        format={dateFormat}
                                        ranges={{
                                            'Today': [moment().startOf('day'), moment().endOf('day')],
                                            'Last 7 Days': [moment().subtract(6, 'day').startOf('day'), moment().endOf('day')],
                                            'This Month': [moment().startOf('month').startOf('day'), moment().endOf('month').endOf('day')],
                                        }}
                                        onChange={(value, dateString) => handleChangeDate('updated_date', value, dateString)}
                                        disabled={loading}
                                    />
                                </div>
                                <div className="itemFilter ml-3 mb-3">
                                    <Select
                                        placeholder='Status'
                                        value={status || []}
                                        disabled={loading}
                                        style={{ width: '150px' }}
                                        options={Status}
                                        onChange={(value) => onChangeSelect('status', value)}
                                        allowClear
                                        showSearch
                                        showArrow
                                        filterOption={(inputValue, option) =>
                                            !!option && option.label.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
                                        }
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="table">
                        <Spin spinning={loading} tip='Data Loading...'>
                            <FastProductionRulesTable
                                listRule={listRule}
                                refresh={fetchFastProductionRules}
                                permissionsData={permissionsData}
                            />
                            <Pagination
                                current={page}
                                total={total}
                                pageSize={limit}
                                showTotal={(sum, range) => sum > 0 &&
                                    <div>
                                        {`Hiển thị ${range[0]} đến ${range[1]} của `}<strong>{`${total}`}</strong> rules
                                    </div>}
                                onChange={handPagePagination}
                                className='text-right' showSizeChanger
                                pageSizeOptions={['10', '20', '50', '100']}
                                onShowSizeChange={handleShowSizeChange}
                            />
                        </Spin>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default FastProductionRulesPage