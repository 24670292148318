import React, {Component} from 'react'
import humanizeTime from "../../../../../helpers/time/humanizeTime"
import PropTypes from 'prop-types'

class WebhookDate extends Component {
    render() {
        const {webhook} = this.props
        const {created} = webhook
        const textTime = humanizeTime(created)
        return (
            <td className="WebhookAddress">
                {textTime}
            </td>
        )
    }
}

WebhookDate.propTypes = {
    webhook: PropTypes.object.isRequired,
}

export default WebhookDate
