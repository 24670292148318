import React, {Component} from 'react'
import PropTypes from 'prop-types'
import {Table} from "reactstrap"
import HeadAddress from "./HeadAddress"
import InputTemplate from "./InputTemplate"

class CodAddress extends Component {
    render() {
        const {previews, onChangePreview} = this.props
        const {Marketplace, orderNumber, name, address1, ghtk_info, phone} = previews
        const {province, district, ward} = ghtk_info

        return (
            <Table responsive>
                <HeadAddress addressType="CodAddress"/>
                <tbody>
                <tr>
                    <InputTemplate
                        type='cod'
                        value={Marketplace}
                        placeholder="Marketplace"
                        field="Marketplace"
                        previews={previews}
                        onChangePreview={onChangePreview}
                    />
                    <InputTemplate
                        type='cod'
                        value={orderNumber}
                        placeholder="Order Number"
                        field="orderNumber"
                        previews={previews}
                        onChangePreview={onChangePreview}
                    />
                    <InputTemplate
                        type='cod'
                        value={name}
                        placeholder="name"
                        field="name"
                        previews={previews}
                        onChangePreview={onChangePreview}
                    />
                    <InputTemplate
                        type='cod'
                        value={address1}
                        placeholder="Address 1"
                        field="address1"
                        previews={previews}
                        onChangePreview={onChangePreview}
                    />
                    <InputTemplate
                        type='cod'
                        value={province}
                        placeholder="Province"
                        field="province"
                        previews={previews}
                        onChangePreview={onChangePreview}
                    />
                    <InputTemplate
                        type='cod'
                        value={district}
                        placeholder="District"
                        field="district"
                        previews={previews}
                        onChangePreview={onChangePreview}
                    />
                    <InputTemplate
                        type='cod'
                        value={ward}
                        placeholder="Ward"
                        field="ward"
                        previews={previews}
                        onChangePreview={onChangePreview}
                    />
                    <InputTemplate
                        type='cod'
                        value={phone}
                        placeholder="Phone"
                        field="phone"
                        previews={previews}
                        onChangePreview={onChangePreview}
                    />
                </tr>
                </tbody>
            </Table>
        )
    }
}

CodAddress.propTypes = {
    previews: PropTypes.object
}

export default CodAddress
