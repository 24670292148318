import React from 'react'
import { Input, DatePicker, Tooltip } from 'antd'
import moment from 'moment'

function SettingHolidayDetailRow(props) {
    const { RangePicker } = DatePicker
    const { item, removeSetting, index, handleChangeSetting, errorValidate, preCutoff, currentData = {} } = { ...props }
    const { start_time, end_time, name, cutoff_time } = { ...item }
    const cutoffDate = cutoff_time ? moment(cutoff_time).utcOffset(0) : null
    const currentCutoffDate = currentData.cutoff_time ? moment(currentData.cutoff_time).utcOffset(0) : null
    const holidayDate = start_time && end_time ? [moment(start_time).utcOffset(0).startOf('day'), moment(end_time).utcOffset(0).endOf('day')] : null

    const CUTOFF_DATE_FORMAT = 'DD/MM/YYYY HH:mm'
    const HOLIDAY_DATE_FORMAT = 'DD/MM/YYYY'

    const disabledDate = current => {
        let today = moment().utc()
        let disableSameOrBeforeToday = moment(current).isSameOrBefore(today.startOf('day'), 'day')
        let disableSameOrBeforeCutoff = cutoffDate && moment(current).isSameOrBefore(moment(cutoffDate).startOf('day'), 'day')
        return current && cutoffDate ? disableSameOrBeforeCutoff : disableSameOrBeforeToday
    }

    const disabledCutoffDate = current => {
        let today = moment().utc()
        let disableBeforeToday = moment(current).isBefore(today.startOf('day'))
        let disableSameOrAfterStart = (!!start_time && !!end_time) && moment(current).endOf('day').isSameOrAfter(moment(holidayDate[0]))
        return current && !!start_time && !!end_time ? (disableBeforeToday || disableSameOrAfterStart) : disableBeforeToday
    }

    const today = moment().utc().toISOString()
    const isPastOrHappenning = !!start_time && !!end_time && (moment(today).isAfter(holidayDate[1]) || (moment(today).isBetween(holidayDate[0], holidayDate[1])))

    const todayIsCutoff = cutoffDate ? moment(today).startOf('day').isSame(moment(cutoffDate).startOf('day'), 'day') : false
    const currentTodayIsCutoff = currentCutoffDate ? moment(today).startOf('day').isSame(moment(currentCutoffDate).startOf('day'), 'day') : false

    const preCutoffDate = !!cutoffDate && !!preCutoff ? moment(cutoffDate).clone().subtract(preCutoff, 'days').startOf('day') : null
    const isPreCutoffTime = !isPastOrHappenning && !!preCutoffDate && !!cutoffDate ? (moment(today).isBetween(moment(preCutoffDate), moment(cutoffDate))) : false
    const currentIsPreCutoffTime = !isPastOrHappenning && !!preCutoffDate && !!currentCutoffDate ? (moment(today).isBetween(moment(preCutoffDate), moment(currentCutoffDate))) : false

    const cutoffTimeIsPast = cutoffDate ? moment(today).isAfter(moment(cutoffDate)) : false

    const error = errorValidate && errorValidate.length > 0 && errorValidate.filter(i => i.row === index)
    const nameError = error && error.length > 0 && error[0].name
    const holidayDateError = error && error.length > 0 && error[0].holiday_date
    const cutoffDateError = error && error.length > 0 && error[0].cutoff_time

    const disableHolidayDate = isPastOrHappenning
    const disableCutoffDate = isPastOrHappenning || cutoffTimeIsPast || (isPreCutoffTime && currentIsPreCutoffTime) || (todayIsCutoff && currentTodayIsCutoff)

    return (
        <tr>
            <td>
                <div>
                    <Input
                        allowClear
                        onChange={(e) => handleChangeSetting(index, 'name', e.target.value)}
                        value={name}
                        placeholder='Input Holiday Name'
                        disabled={isPastOrHappenning}
                        status="error"
                    />
                </div>
                {nameError &&
                    <small className='text-danger'>{nameError}</small>
                }
            </td>
            <td>
                <div>
                    <RangePicker
                        allowClear
                        onChange={(value, dateString) => handleChangeSetting(index, 'holidayDate', value)}
                        value={holidayDate}
                        placeholder={['Holiday start', 'Holiday end']}
                        // ranges={{
                        //     'Today': [moment().startOf('day'), moment().endOf('day')],
                        //     'Last 7 Days': [moment().subtract(6, 'day').startOf('day'), moment().endOf('day')],
                        //     'This Month': [moment().startOf('month').startOf('day'), moment().endOf('month').endOf('day')],
                        // }}
                        format={HOLIDAY_DATE_FORMAT}
                        // showTime={{
                        //     hideDisabledOptions: true,
                        //     format: 'HH:mm',
                        //     defaultValue: [moment('00:00', 'HH:mm'), moment('23:59', 'HH:mm')],
                        // }}
                        disabledDate={disabledDate}
                        disabled={disableHolidayDate}
                    />

                </div>
                {holidayDateError &&
                    <small className='text-danger'>{holidayDateError}</small>
                }
            </td>
            <td>
                <div>
                    <DatePicker
                        allowClear
                        onChange={(value, dateString) => handleChangeSetting(index, 'cutoff_time', value)}
                        value={cutoffDate}
                        placeholder='Cutoff date'
                        format={CUTOFF_DATE_FORMAT}
                        showTime={{
                            hideDisabledOptions: true,
                            format: 'HH:mm',
                            defaultValue: moment('23:59', 'HH:mm'),
                        }}
                        disabled={disableCutoffDate}
                        disabledDate={disabledCutoffDate}
                    />
                </div>
                {cutoffDateError &&
                    <small className='text-danger'>{cutoffDateError}</small>
                }
            </td>
            <td>
                {!disableHolidayDate && !disableCutoffDate &&
                    <Tooltip title='Delete' className="ti-close text-danger ml-2 cursor-pointer" onClick={() => removeSetting(index)} />
                }
            </td>
        </tr>
    )
}

export default SettingHolidayDetailRow