import React, {Component} from 'react'
import PropTypes from 'prop-types'
import {Helmet} from "react-helmet"

class DashboardHead extends Component {
    render() {
        const {store} = this.props
        const storeName = store.getSetting('name', '')

        return (
            <Helmet>
                {
                    !!storeName &&
                    <title>{storeName}</title>
                }
            </Helmet>
        )
    }
}

DashboardHead.propTypes = {
    store: PropTypes.object.isRequired,
}

export default DashboardHead
