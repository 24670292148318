import React, { useState } from 'react'
import { Checkbox, Popover } from 'antd'


const ItemReplaced = (props) => {
    const { id, item, onChange } = props
    const { _id, reference_number, isCheck = false, solution_note } = Object.assign({}, item)

    const [isOpen, setIsOpen] = useState(false)

    const handleOpenChange = () => {
        setIsOpen(!isOpen)
    }

    return (
        <div className="item-replaced">
            <Checkbox
                className="mr-1"
                checked={isCheck}
                onChange={e => onChange(id, _id, e.target.checked)}
            />
            <span>{typeof reference_number === 'string' ? reference_number : reference_number.join(', ')}</span>

            {
                solution_note &&
                <Popover
                    content={solution_note}
                    title="Solution Note"
                    trigger="click"
                    open={isOpen}
                    onOpenChange={handleOpenChange}
                >
                    <i className="icon-quill4 text-danger ml-3 cursor-pointer" />
                </Popover>
            }
        </div>
    )
}

export default ItemReplaced
