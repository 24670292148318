import React from 'react'
import CopyToClipboard from '../../../../shared/CopyToClipboard'
import { Popover } from 'antd'

const ItemSideDefault = (props) => {
    const { side, designHood, designSleeves, designBack, designFront, orderId, type } = props

    const url = (side === "front" && designFront) || (side === "back" && designBack) || (side === "sleeves" && designSleeves) || (side === "hood" && designHood)

    const show_file_in = (preview) => {
        const newImage = new Image()
        if (!!preview) {
            newImage.src = preview
            const newWidth = newImage.width > newImage.height ? "100%" : "auto"
            const newHeight = newImage.width > newImage.height ? "auto" : "100%"
            return (
                <div className={`previewArtwork`}>
                    <img src={preview} alt='preview' style={{ width: newWidth, height: newHeight }} />
                </div>
            )
        }
    }

    return (
        <div className='ItemSideDefault border bg-white'>
            <div className='px-2 py-1'>{side}</div>
            <div>
                {
                    url &&
                    <CopyToClipboard text={url} target={`copy_${type}_${side}_${orderId}`}>
                        <span className='Icon no-pointer-event p-2'>
                            <i className='fa fa-clone' />
                        </span>
                    </CopyToClipboard>
                }

                {
                    url &&
                    <Popover
                        placement="right"
                        trigger="hover"
                        content={show_file_in(url)}
                        overlayClassName={`popoverArtwork`}
                    >
                        <a href={url} target='_blank' rel='noreferrer noopener'>
                            <span className='Icon View p-2' title='View'><i className='fa fa-eye' /></span>
                        </a>
                    </Popover>
                }

            </div>
        </div>
    )
}

export default ItemSideDefault
