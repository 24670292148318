import React, {Component} from 'react'
import PropTypes from 'prop-types'
import {Modal} from "reactstrap"
import PackagePusherInner from "./PackagePusherInner"

class PackagePusherEditor extends Component {
    render() {
        const {isOpen} = this.props

        return (
            <div className="PackagePusherEditor">
                <Modal className="PackagePusherEditorModal Factory" size="lg" backdrop="static" isOpen={isOpen}>
                    <PackagePusherInner {...this.props}/>
                </Modal>
            </div>
        )
    }
}

PackagePusherEditor.propTypes = {
    isOpen: PropTypes.bool.isRequired,
    fulfillment: PropTypes.object.isRequired,
    orderId: PropTypes.string.isRequired,
    onClose: PropTypes.func.isRequired
}


export default PackagePusherEditor
