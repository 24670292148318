import React, {Component} from 'react'
import PropTypes from "prop-types"


class OrderShippingPlan extends Component {
    render() {
        const {order} = this.props
        const shippingPlan = Object.assign({}, order.shipping_plan)
        const {title = "", _id, slug} = shippingPlan

        if (!title) {
            return null
        }

        return (
            <div className="OrderShippingPlan mb-sm-0 mb-1" id={`Shipping-${_id}`}>
                <div className="card" style={{border : slug === "shipbytiktok" ? '2px solid red' : ''}}>
                    <div className="card-body">
                        <span className="Label">Shipping: </span>
                        <strong data-slug={slug}>{title}</strong>
                    </div>
                </div>

            </div>
        )
    }
}

OrderShippingPlan.propTypes = {
    order: PropTypes.object.isRequired,
}

export default OrderShippingPlan
