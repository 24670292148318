import React, {useEffect, useState} from 'react'
import PropTypes from 'prop-types'
import ProductImagesList from "./ProductImagesList"
import {getProductImages} from "../../../../services/api/ProductsServices"
import ProductImagesAdd from "./ProductImagesAdd";

ProductImages.propTypes = {
    productId: PropTypes.string
}

function ProductImages(props) {
    const {productId, disableAll} = props
    const [images, setImages] = useState([])

    const _fetchProductImage = async () => {
        try {
            const {success, data, message} = await getProductImages(productId)

            if (!success) throw new Error(message)

            setImages(data)
        } catch (e) {
            console.log(e)
        }
    }

    useEffect(() => {
        _fetchProductImage()
    }, [])
    return (
        <div className='ProductImages mt-3'>
            <div className="SectionInner">
                <h3 className="SectionHeading mb-3">Images</h3>

                <div className="mt-4">
                    {!disableAll &&
                        <ProductImagesAdd productId={productId} reloadImages={_fetchProductImage}/>
                    }
                    <ProductImagesList productId={productId} images={images} reloadImages={_fetchProductImage} disableAll={disableAll}/>
                </div>
            </div>
        </div>
    )
}

export default ProductImages
