import moment from 'moment'
import { isPlainObject } from 'lodash'

export default (data, options) => {
    const {
        filename,
        type = 'text/csv;encoding:utf-8',
    } = {...options}
    const getData = () => {
        if (typeof data === 'string') {
            return data
        }

        if (isPlainObject(data)) {
            return JSON.stringify(data)
        }

        return `${data}`
    }

    const date = moment(Date.now()).format('YYYY/MM/DD')
    const csvFile = new Blob(['\uFEFF'+ getData()], {type})
    const downloadLink = document.createElement('a')
    downloadLink.download = filename || `download_${date}`
    downloadLink.href = window.URL.createObjectURL(csvFile)
    downloadLink.style.display = 'none'
    document.body.appendChild(downloadLink)
    downloadLink.click()
    document.body.removeChild(downloadLink)
}