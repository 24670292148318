import React, {Component, createRef} from 'react'
import PropTypes from 'prop-types'
import {Modal, ModalHeader, ModalBody, ModalFooter} from 'reactstrap'
import {createNewPlatform} from '../../../services/api/PlatformsServices'

class NewPlatformModal extends Component {
    state = {
        platform: {
            name: '',
        },
        loading: {
            addNewPlatform: false,
        }
    }

    nameInput = createRef()

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (!prevProps.open && this.props.open) {
            this.setState({
                platform: {name: ''},
            })
            this.nameInput.focus()
        }
    }

    _onChangeInput = (key) => (e) => {
        const {value} = e.target
        this.setState(({platform}) => ({
            platform: {
                ...platform,
                [key]: value,
            }
        }))
    }

    _onClose = () => {
        this.props.onClose()
    }

    _onClickSave = () => {
        const {platform} = this.state
        if (!platform.name) return null
        return this._savePlatform(platform)
    }

    _savePlatform = async (platform) => {
        this.setState({loading: {addNewPlatform: true}})

        try {
            const {success, data, message} = await createNewPlatform(platform)
            const newPlatformState = success ? {name: ''} : platform

            this.setState({
                platform: newPlatformState,
                loading: {addNewPlatform: false}
            })
            if (message) return alert(message)
            this.props.addNewPlatform(data)
        } catch (e) {
            this.setState({
                loading: {addNewPlatform: false}
            })
            alert(e.message || e)
        }
    }

    render() {
        const {open} = this.props
        const {platform, loading} = this.state

        return (
            <Modal
                isOpen={open}
                toggle={this._onClose}
                className="PlatformEditModal"
            >
                <ModalHeader toggle={this._onClose}>Add new platform</ModalHeader>
                <ModalBody>
                    <label htmlFor="new-platform-name">Platform Name</label>
                    <input className="Input" id="new-platform-name" value={platform.name}
                           onChange={this._onChangeInput('name')}
                           ref={input => this.nameInput = input}/>
                </ModalBody>
                <ModalFooter>
                    <button className="btn CloseButton" onClick={this._onClose}>Cancel</button>
                    <button disabled={loading.addNewPlatform} className="btn SaveButton" onClick={this._onClickSave}>
                        Add new platform
                    </button>
                </ModalFooter>
            </Modal>
        )
    }
}

NewPlatformModal.propTypes = {
    open: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    addNewPlatform: PropTypes.func.isRequired,
}

export default NewPlatformModal
