import React, { useState, useEffect } from 'react'
import {Select, Tooltip} from 'antd'
import VariantTable from './VariantTable'
import { getProductAttributes } from "../../../../services/api/ProductsServices"

const ProductlinePage = (props) => {

    const [listAtt, setListAtt] = useState([])
    const [loading, setLoading] = useState(false)

    const {
        first_item_price, handleChangeProductLine,
        additional_item_price, handlePaste, product_line, newVariant,
        handleChangeVariants, filter,
        handleChangeFilter, handleHistoryClick, defaultShippingCost,
        permissionsData,
    } = props

    useEffect(() => {

        if (!product_line._id) return

        const _fetchListAttributes = async () => {
            setLoading(true)
            try {
                const response = await getProductAttributes(product_line._id)

                const { success, data } = response
                if (!success) return

                setListAtt(data)
            } catch (error) {
                console.log(error.message || 'Unknown error')
            } finally {
                setLoading(false)
            }
        }

        _fetchListAttributes()
    }, [product_line])


    const onChangeProductLine = (value) => {
        handleChangeProductLine(value)
    }

    const limitInputValue = ({ floatValue, formattedValue }) => {
        if (floatValue == null) return formattedValue === ''

        return (floatValue >= 0 && floatValue < 1000)
    }

    function _handleChangeFirstPrice(e) {
        const {value} = e.target
        const {handleChangeFirstPrice} = props

        if (typeof handleChangeFirstPrice === 'function') {
            handleChangeFirstPrice(value)
        }
    }

    function _handleChangeAdditional(e) {
        const {value} = e.target
        const {handleChangeAdditional} = props

        if (typeof handleChangeAdditional === 'function') {
            handleChangeAdditional(value)
        }
    }
    const disableAll = !permissionsData.includes('ffm_shipping_fee_update')
    return (
        <div className="ProductlinePage px-4 py-3">
            <div className="ProductSearch d-flex flex-column">
                <span className="font-weight-bold py-2">Choose one productline</span>
                <Select
                    showSearch
                    placeholder="Search productline"
                    optionFilterProp="children"
                    allowClear
                    value={product_line.title || ''}
                    style={{ maxWidth: 600 }}
                    onChange={onChangeProductLine}
                    disabled
                >
                </Select>
            </div>

            {!!product_line && <div className="ProductVariant">
                <div className="ProductTitle py-4">
                    <span>Productline:</span>
                    <span className="font-weight-bold ml-1">{product_line.title ? product_line.title : ''}</span>
                </div>
                <div className="ProductPrice d-flex align-items-center justify-content-between" style={{ maxWidth: 638 }}>
                    <div className="FirstItem d-flex align-items-center">
                        <span className="font-weight-bold">
                            First item
                        </span>
                        <input type="text" className="form-control" value={(first_item_price !== '' && first_item_price !== null && first_item_price !== undefined) ? first_item_price : ""}
                               onChange={_handleChangeFirstPrice} disabled={disableAll}/>
                    </div>
                    <div className="AdditionalItem d-flex align-items-center justify-content-end">
                        <span className="font-weight-bold">
                            Additional item
                        </span>
                        <input type="text" className="form-control" value={(additional_item_price !== '' && additional_item_price !== null && additional_item_price !== undefined) ? additional_item_price : ""}
                               onChange={_handleChangeAdditional} disabled={disableAll}/>
                        <Tooltip title="History">
                            <i className="fa fa-history History cursor-pointer ml-4" aria-hidden="true" onClick={()=>handleHistoryClick(defaultShippingCost._id)}></i>
                        </Tooltip>
                    </div>
                </div>
                {
                    newVariant.length > 0 ?
                        <VariantTable
                            newVariant={newVariant}
                            handleChangeVariants={handleChangeVariants}
                            first_item_price={first_item_price}
                            additional_item_price={additional_item_price}
                            handlePaste={handlePaste}
                            listAtt={listAtt}
                            handleChangeFilter={handleChangeFilter}
                            filter={filter}
                            loading={loading}
                            handleHistoryClick={handleHistoryClick}
                            defaultCostId={defaultShippingCost._id}
                            disableAll={disableAll}
                        /> :
                        <div className="py-4">Can not find variant</div>
                }
            </div>}
        </div>
    )
}

export default ProductlinePage