import React from 'react'

function CostControlHead() {
    return (
        <thead 
            className="CostControlTableHead" 
            style={{ background :'#e9ecef'}}
        >
            <tr>
                <th className="File_name">File name</th>
                <th className="Supplier">Supplier</th>
                <th className="Create_at">Create at</th>
                <th className="Finish_at">Finish at</th>
                <th className="Record">Record</th>
                <th className="Approval_status">Approval status</th>
            </tr>
        </thead>
    )
}

export default CostControlHead