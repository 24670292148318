import React from 'react'
import DocTitleByStore from "../../dashboard/shared/DocTitleByStore"
import MappingConditionPage from "./MappingConditionPage"

const MappingConditionPageContainer = (props) => {
    return (
        <div className="MappingConditionPageContainer">
            <DocTitleByStore title="Mapping Condition"/>
            <MappingConditionPage params={props.match.params}/>
        </div>
    )
}

export default MappingConditionPageContainer