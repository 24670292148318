import React, {Component} from 'react'
import PropTypes from 'prop-types'
import {ListArtworkType} from "../../../../statics/artworkType"
import ArtworkImages from "./ArtworkImages"

class OrderItemGroupArtworkItem extends Component {
    state = {
        background: 'black'
    }

    _getTypeArtwork = (artwork) => {
        if (!!artwork.artworks) {
            const {level} = artwork.artworks[0]

            return ListArtworkType.find(item => item.value === level)
        }
    }

    _handleChangeColor = color => {
        this.setState({
            background: color
        })
    }

    render() {
        const {artwork, typeDisplay, piecesPerPack, isCheckVersionOrderV2} = this.props
        const {background} = this.state
        const type = this._getTypeArtwork(artwork)

        return (
            <div className={`OrderItemGroupArtworkFirst ${!isCheckVersionOrderV2 ? 'border-bottom' : ''} ${typeDisplay === 'more' ? 'More' : ''}`}>
                {
                    typeDisplay !== 'more' && <div className="TypeArtwork">
                        {type.label || ''}
                        {
                            artwork.hasOwnProperty('pack') && artwork.pack > 0 &&
                            <span>{piecesPerPack}{'/' + artwork.pack}</span>
                        }
                    </div>
                }

                <div className="Actions">
                    <div className="QuantityArtwork">
                        Qty: <span>{artwork.quantity}{artwork.hasOwnProperty('pack') ? '/' + artwork.pack : ''}</span>
                    </div>

                    <div className="ActionBackground">
                        <button
                            className={background === 'black' ? 'Active' : ''}
                            onClick={() => this._handleChangeColor('black')}
                        >
                            Black
                        </button>
                        <button
                            className={background === 'white' ? 'Active' : ''}
                            onClick={() => this._handleChangeColor('white')}
                        >
                            White
                        </button>
                    </div>
                </div>

                <div className="ArtworkListFirst">
                    <ul className="ArtworkListNew row">
                        {
                            !!artwork.artworks && artwork.artworks.length > 0 && artwork.artworks.map((item, index) => (
                                <li className="col-6" key={index}>
                                    <ArtworkImages
                                        background={background}
                                        preview={item.preview}
                                        resize={item.resize}
                                        side={item.side}
                                        width={item.width}
                                        height={item.height}
                                    />
                                </li>
                            ))
                        }
                    </ul>
                </div>
            </div>
        )
    }
}

OrderItemGroupArtworkItem.propTypes = {
    artwork: PropTypes.object,
    typeDisplay: PropTypes.string,
    piecesPerPack: PropTypes.number
}

export default OrderItemGroupArtworkItem
