import React, {Component} from 'react'
import PropTypes from 'prop-types'
import {DateRangePicker} from "react-dates"
import 'react-dates/lib/css/_datepicker.css'
import 'react-dates/initialize'
import moment from 'moment'

class DatePaid extends Component {
    state = {
        focusedInput: null,
        from: null,
        to: null,
    }

    _handleToggle = (e) => {
        e.preventDefault()

        this.setState(({focusedInput}) => ({
            focusedInput: focusedInput ? null : 'startDate',
        }))
    }

    _onFocusChange = (focusedInput) => {
        this.setState({
            focusedInput,
        })
    }

    _handleDatesChange = ({startDate, endDate}) => {
        this.setState({
            from: startDate,
            to: endDate,
        }, this._handleAfterDatesChange)
    }

    _handleAfterDatesChange = () => {
        const {from, to} = this.state

        const result = {}

        if (from && from.format) {
            result.from = from.format('DD/MM/YYYY')
        }

        if (to && to.format) {
            result.to = to.format('DD/MM/YYYY')
        }

        this.props.onChangeFiltersState("paid_at", result)
    }

    _isOutsideRangeDate = (date) => {
        const tomorrow = moment().add(1, 'day')
        const startDate = moment('2018-11-05', 'YYYY-MM-DD')

        if (startDate.isAfter(date)) return true

        return !date.isBefore(tomorrow) || date.isSame(tomorrow, 'day')
    }

    render() {
        const {focusedInput, from, to} = this.state

        return (
            <div className="DatePaid d-inline-flex align-items-baseline" onClick={this._handleToggle}>
                <div className="Label cursor-pointer mr-2">Paid at</div>

                <div className="DatePickerWrapper rounded">
                    <DateRangePicker
                        small
                        noBorder
                        showClearDates
                        enableOutsideDays
                        reopenPickerOnClearDates
                        minimumNights={0}
                        numberOfMonths={1}
                        startDateId="_statistic_filter_from"
                        endDateId="_statistic_filter_to"
                        startDate={from}
                        endDate={to}
                        focusedInput={focusedInput}
                        isOutsideRange={this._isOutsideRangeDate}
                        onDatesChange={this._handleDatesChange}
                        onFocusChange={this._onFocusChange}
                        displayFormat="DD/MM/YYYY"
                    />
                </div>
            </div>
        )
    }
}

DatePaid.propTypes = {
    onChangeFiltersState: PropTypes.func.isRequired,
}

export default DatePaid
