import React, {useState, useContext} from 'react'
import {DeleteOutlined, SyncOutlined} from '@ant-design/icons'
import {ConfirmModalV2} from "../../../shared/ConfirmModalV2"
import {hideQuickReport} from "../../../../services/api/OrdersServices"
import {message} from "antd"
import OrderPartnersContext from "../../context/OrderPartnersContext"
import {getLocalData, setLocalData} from "../../../../services/StorageServices"

function QuickReportsItem(props) {
    const {setState} = useContext(OrderPartnersContext)
    const {data, index, canDelete, handleGetQuickReports, toggleShow, totalRows} = props
    const [loading, setLoading] = useState(false)
    const itemQuickCurrent = getLocalData('ordersSupplierV2.QuickReportItem', {}, false, 'sessionStorage')
    const currentItemId = (itemQuickCurrent && typeof itemQuickCurrent === 'object') ? itemQuickCurrent._id : ''

    const _handleSetFilter = () => {
        setLocalData('ordersSupplierV2.QuickReportItem', canDelete ? data : {}, false, 'sessionStorage')
        toggleShow()
        setState(data.payload, true)
    }

    const _handleDeleteFilter = async () => {
        setLoading(true)
        const id = data._id
        try {
            const {message: error, success} = await hideQuickReport(id)
            if (!success) {
                setLoading(false)
                return message.error(error)
            }

            if(currentItemId === id) {
                setLocalData('ordersSupplierV2.QuickReportItem', {}, false, 'sessionStorage')
            }
            setLoading(false)
            handleGetQuickReports()
            message.success('Delete filter successfully!')
        } catch (e) {
            setLoading(false)
            message.error(e.message)
        }
    }

    const _renderTotal = (id) => {
        const total = totalRows.find(item => item._id === id)
        return total !== undefined ? `(${total.total})` : ''
    }

    return <div className={`QuickReportsItem ${currentItemId === data._id && 'active'} ${canDelete && 'canDelete'}`}>
        <div className={`Title cursor-pointer`} onClick={_handleSetFilter}>{data.name} {_renderTotal(data._id)}</div>
        {canDelete && (
            loading ? <div className="IconDelete"
            >
                <SyncOutlined spin/>
            </div> : <div className="IconDelete"
                          id={`btnDeleteFilter-${index}`}
                          onClick={() => {
                              ConfirmModalV2({
                                  message: "Are you sure you want to delete?",
                                  type: "warning",
                                  id: `btnDeleteFilter-${index}`,
                                  placement: "bottom-end",
                                  onOk: () => {
                                      _handleDeleteFilter()
                                  }
                              })
                          }}
            >
                <DeleteOutlined/>
            </div>
        )}
    </div>
}

export default QuickReportsItem