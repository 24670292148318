export const settings = [
    {
        icon: 'ti-harddrives',
        title: 'Storage settings',
        description: 'View and update your storage settings',
        location: '/a/settings/storage',
        permission: 'ffm_setting_storage_read',
    },
    {
        icon: 'ti-panel',
        title: 'Auto settings',
        description: 'Enable auto mapping and auto push',
        location: '/a/settings/auto',
        role: 'admin',
        permission: 'ffm_setting_auto_read',
    },
    {
        icon: 'ti-user',
        title: 'Users settings',
        description: 'Change password and delete users',
        location: '/a/settings/users',
        role: 'admin',
        permission: 'ffm_setting_users_read',
    },
    {
        icon: 'ti-panel',
        title: 'Fast Production Rule',
        description: 'Manage fast production rules',
        location: '/a/settings/fast-production',
        role: 'admin',
        permission: 'ffm_fast_production_rule_list',
    }
]
