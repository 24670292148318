import React, {Component} from 'react'
import { Select, Tooltip } from 'antd'

class ProductLineTitleSearch extends Component {
    timeOut = null

    _handChangeSearchInput = (e) => {
        const {value} = e.target
        const {_handUpdateState, filter} = this.props

        clearTimeout(this.timeOut)
        this.timeOut = setTimeout(() => {
            _handUpdateState("filter", {
                ...filter,
                term: value,
                page: 1,
            })
        }, 500)
    }

    _handleSelectSupplier = e => {
        const {name, value} = e.target
        const {_handUpdateState, filter} = this.props
        const Value = value && value.length > 0 && !value.includes('') ? value : ""

        clearTimeout(this.timeOut)
        this.timeOut = setTimeout(() => {
            _handUpdateState("filter", {
                ...filter,
                supplier: Value,
                page: 1,
            })
        }, 500)
    }
    _handleSelectAvailableTiktok = e => {
        const {name, value} = e.target
        const {_handUpdateState, filter} = this.props

        clearTimeout(this.timeOut)
        this.timeOut = setTimeout(() => {
            _handUpdateState("filter", {
                ...filter,
                available_tiktok: value,
                page: 1,
            })
        }, 500)
    }
    componentDidMount() {
        const {orderType, listSuppliers, showModal, filteredSupplierTiktok, _handUpdateState, order = {}, filter, filteredAvaikabeTiktok} = this.props
        const {shipping_plan = {}} = order
        const {slug = ''} = shipping_plan
        const suppliersShipByTikTok = listSuppliers && listSuppliers.length > 0 ? listSuppliers.filter(i=>i.available_tiktok && i.shipbytiktok).map(i=>i._id) : []
        const allSupplierTiktok =  listSuppliers && listSuppliers.length > 0 ? listSuppliers.filter(i=>i.available_tiktok).map(i=>i._id) : []
        const supplierValues = slug === "shipbytiktok" ? suppliersShipByTikTok : allSupplierTiktok
        if(orderType==='TIKTOK' && showModal && !filteredSupplierTiktok){
            clearTimeout(this.timeOut)
            this.timeOut = setTimeout(() => {
                _handUpdateState("filter", {
                    ...filter,
                    supplier:supplierValues,
                    available_tiktok: 'yes',
                    page: 1,
                })
                _handUpdateState('filteredSupplierTiktok', true)
                _handUpdateState('filteredAvaikabeTiktok', true)
            }, 500)
        }
    }

    render() {
        const {filter, listSuppliers} = this.props
        const supplierOptions = listSuppliers && listSuppliers.length > 0 ? listSuppliers.sort((a, b) => a.name.localeCompare(b.name)) : []
        const{supplier, available_tiktok} = filter
        const Option = Select.Option

        return (
            <div className="ProductLineTitleSearch position-relative mb-3 d-flex">
                <i className="ti-search position-absolute"/>
                <input type="text" className="form-control" placeholder="Search for title..."
                       onChange={this._handChangeSearchInput}/>
                {/* <select className='form-control ml-3 w-10' onChange={this._handleSelectSupplier} value={supplier}>
                    <option value={''}>All {'supplier'}</option>
                    {
                        listSuppliers.map(_value => {
                            return <option key={_value._id} value={_value._id}>{_value.name}</option>
                        })
                    }
                </select> */}
                <Tooltip title='Supplier'>
                    <Select
                        className='supplierFilter ml-3'
                        onChange={(value) => this._handleSelectSupplier({ target: { name: '', value } })}
                        value={supplier || []}
                        allowClear
                        mode={'multiple'}
                        showSearch
                        placeholder='All supplier'
                        showArrow
                        optionFilterProp="children"
                    >
                        {
                            supplierOptions.map(_value => {
                                return <Option key={_value._id} value={_value._id}>{_value.name}</Option>
                            })
                        }
                    </Select>
                </Tooltip>
                <Tooltip title='Available Tiktok'>
                    <div className='FilterTikTok'>
                        <Select
                            className='supplierFilter ml-3'
                            onChange={(value) => this._handleSelectAvailableTiktok({ target: { name: '', value } })}
                            value={available_tiktok || []}
                            placeholder='Available Tiktok'
                            showArrow
                            allowClear
                            showSearch
                            optionFilterProp="children"
                        >
                            <Option value='all'>All</Option>
                            <Option value='yes'>Yes</Option>
                            <Option value='no'>No</Option>
                        </Select>
                    </div>
                </Tooltip>
            </div>
        )
    }
}

export default ProductLineTitleSearch
