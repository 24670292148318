import React, { useState } from 'react'
import { Input, message } from 'antd'
// import { updateDesignOrder } from "../../../../../services/api/3dApi"

const { Search } = Input

const sleep = (ms) => {
    return new Promise(resole => setTimeout(resole, ms))
}

const UpdateDesign = ({ side, orderId, type, onSuccess, url }) => {
    const [loading, setLoading] = useState(false)

    const _handleSave = (value, e) => {
        e.preventDefault()

        // _updateDesign(value)
    }

    // const _updateDesign = async (value) => {
    //     setLoading(true)
    //     try {
    //         await sleep(500)

    //         const params = {
    //             [side]: value,
    //         }

    //         const { data: response } = await updateDesignOrder(orderId, type, params)

    //         const { success, error } = response

    //         if (!success) {
    //             throw new Error(error)
    //         }

    //         onSuccess()

    //         message.success(`${side} url updated.`, 1)
    //     } catch (e) {
    //         message.error(e.message, 3)
    //     } finally {
    //         setLoading(false)
    //     }
    // }

    return (
        <div className='UpdateDesign'>
            <Search type='url' enterButton='Save' onSearch={_handleSave} defaultValue={url}
                loading={loading} />
        </div>
    )
}

export default UpdateDesign