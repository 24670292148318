import React, {Component} from 'react'
import PropTypes from 'prop-types'
import ListPopularTags from "./ListPopularTags"
import {getOrderPopularTags} from "../../../../../services/api/OrdersServices"

class PopularTagsContainer extends Component {
    state = {
        popularTags: [],
        error: "",
    }

    componentDidMount() {
        this._fetchListPopularTags()
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const preTags = prevProps.tags
        const currTags = this.props.tags

        if (preTags.length !== currTags.length) {
            this._handleUpdateSelectedPopularTags()
        }
    }

    _fetchListPopularTags = async () => {
        const {orderID} = this.props
        try {
            const {data, success, message} = await getOrderPopularTags(orderID, 5)
            if (!success) {
                return this.setState({
                    error: message,
                })
            }

            if (!data || !Array.isArray(data)) return

            const currentTags  = this.props.tags

            const popularTags = data.map(tag => {
                const selected = currentTags.indexOf(tag) !== -1

                return {
                    title: tag,
                    selected,
                }
            })

            this.setState({
                popularTags
            })
        } catch (e) {
            console.log( e.message )
        }
    }

    _handleUpdateSelectedPopularTags = () => {
        const {popularTags} = this.state
        const currTags = this.props.tags

        const popularTagsUpdated = popularTags.map(tag => {
            return {
                ...tag,
                selected: currTags.includes(tag.title),
            }
        })

        this.setState({
            popularTags: popularTagsUpdated,
        })
    }

    render() {
        const {popularTags} = this.state

        return (
            <div className="PopularTagsContainer">
                <ListPopularTags popularTags={popularTags} onCreateOrderTags={this.props.onCreateOrderTags}/>
            </div>
        )
    }
}

PopularTagsContainer.propTypes = {
    onCreateOrderTags: PropTypes.func.isRequired,
    orderID: PropTypes.string.isRequired,
    tags: PropTypes.array.isRequired,
}

export default PopularTagsContainer
