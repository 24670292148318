import {currency} from "../../statics/currency"
import formatPrice from "./formatPrice"

export default (currencyPLF, price) => {
    const symbolPrice = currencyPLF !== undefined ? currency.find(item => item.value === currencyPLF) : {symbol: '$'}

    if (symbolPrice === undefined) {
        return '$' + formatPrice(price, 2)
    }

    if (symbolPrice.symbol === '$') {
        return symbolPrice.symbol + formatPrice(price, 2)
    }

    return formatPrice(price, 0, 'vi-VI') + symbolPrice.symbol
}