import React, {Component} from 'react'
import ShippingZonePage from "./ShippingZonePage"

class ShippingZonePageContainer extends Component {
    render() {
        const id = this.props.match.params.id

        return (
            <div className="ShippingZonePageContainer">
                <div className="col-12 m-auto">
                    <ShippingZonePage id={id}/>
                </div>
            </div>
        )
    }
}

export default ShippingZonePageContainer
