import React, { useState } from 'react'
import { Modal, Input, Progress, Checkbox } from 'antd'
import { removeOrderTags } from "../../../../../services/api/OrdersServices"
import { ExclamationCircleOutlined, CheckCircleOutlined } from '@ant-design/icons'

const CheckboxGroup = Checkbox.Group;

const BulkRemoveTags = ({ open, unShowModal, selectedOrders, orders, onFetchOrders}) => {
    
    const [checkedList, setCheckedList] = useState([])
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState('')
    const [listProcessing, setListProcessing] = useState(orders.filter(item => selectedOrders.includes(item._id)))
    const [listSuccess, setListSuccess] = useState([])
    const [listError, setListError] = useState([])


    const _onRemoveTags = async (id, payload) => {
        setLoading(true)
        try {

            const { success, message } = await removeOrderTags(id, payload)

            if (!success) {
                return { success: success, message: message }
            }
            return { success: success }

        } catch (e) {
            return { success: false, message: e.message }
        } finally {
            setLoading(false)
        }

    }

    const _handleRemoveTags = async () => {
        setListSuccess([])
        setListError([])

        let processed = []

        try {
            if (checkedList.length === 0) return setError('Select tag is required')

            await Promise.all(listProcessing.map(async item => {
                const payload = {
                    tags: checkedList
                }
                
                const response = await _onRemoveTags(item._id, payload)

                const { success, message } = response

                delete item.success;
                delete item.error;
                delete item.processing;

                if (!success){
                    return processed.push({ ...item, error: message, processing: false })
                }
                
                return processed.push({ ...item, success: true, processing: false })

            }))


            let newListSuccess = []
            let newListError = []

            processed.map(process => {
                if (process.success === true) {
                    return newListSuccess.push(process)
                }

                if (process.error.length > 0) {
                    return newListError.push(process)
                }
            })

            setCheckedList([]);
            setListSuccess(newListSuccess)
            setListError(newListError)
            return setListProcessing(processed)

        } catch (e) {
            return { success: false, message: e.message }
        } finally {
            onFetchOrders()
            setLoading(false)
        }
    }

    const listSelectedOrders = orders.filter(item => selectedOrders.includes(item._id))
    
    let tagOptions = []
    listSelectedOrders.map(item => {
        const itemTags = item.tags ? item.tags : []
        if(itemTags && itemTags.length > 0){
            itemTags.map(itemTag => {
                if(itemTag !== 'thank_card' && itemTag !== 'thank_you_card' && !tagOptions.includes(itemTag)){
                    tagOptions.push(itemTag)
                }
            })
        }
    })

    const checkAll = tagOptions.length === checkedList.length;
    const indeterminate = checkedList.length > 0 && checkedList.length < tagOptions.length;

    const onChange = (list) => {
        setError('')
        setCheckedList(list);
    };

    const onCheckAllChange = (e) => {
        setCheckedList(e.target.checked ? tagOptions : []);
    };

    const disabledButton = checkedList.length === 0 ? true : false

    return (
        <Modal title="Remove tags" visible={open} onOk={_handleRemoveTags} onCancel={unShowModal} okText="Save" confirmLoading={loading} okButtonProps={{ disabled: disabledButton }}>
            <div className='font-weight-bold mb-2 remove-tags-title'>
                <label>Select tags:</label>
                {
                    tagOptions.length > 1 && 
                    <span className='check-all'>
                        <Checkbox onChange={onCheckAllChange} checked={checkAll}>
                            Check all
                        </Checkbox>
                    </span>
                }
                
            </div>
            {/* <TextArea rows={3} placeholder="Enter your note order..." onChange={handleChangeNote} value={orderNote} allowClear /> */}
            <CheckboxGroup options={tagOptions} value={checkedList} onChange={onChange} />

            {error && <div className='text-danger'>{error}</div>}
            <h6 className='mt-3'>List processing <span>{selectedOrders.length}</span> items</h6>
            {
                (listSuccess.length > 0 || listError.length > 0) &&
                <div className="Results d-flex align-items-center">
                    <div className="ResultSuccess mr-2">
                        Success: <span className='text-success font-weight-bold'>{listSuccess.length}</span> items
                    </div>
                    <div className="ResultError">
                        Error: <span className='text-danger font-weight-bold'>{listError.length}</span> items
                    </div>
                </div>
            }

            <div className="ListProcessing">
                <ul>
                    {
                        !!listProcessing && listProcessing.length > 0 && listProcessing.map(item => (
                            <li key={item._id}>
                                <span className='mr-2 font-weight-bold'>{item.id}</span>
                                {
                                    !!item.error &&
                                    <span className="Error text-danger"><ExclamationCircleOutlined /> {item.error}</span>
                                }
                                {
                                    item.success &&
                                    <span className="Success text-success"><CheckCircleOutlined /> Remove success</span>
                                }
                                <Progress
                                    percent={100}
                                    size="small"
                                    status={`${(item.processing === false && item.success === true) ? 'success' : ((item.processing === false && item.error.length > 0) ? 'exception' : 'active')}`}
                                    showInfo={false}
                                />
                            </li>
                        )
                        )
                    }
                </ul>
            </div>
        </Modal>
    )
}

export default BulkRemoveTags