import React, {Component} from 'react'

class ShippingZonesHead extends Component {
    render() {
        const {permissionsData} = this.props
        return (
            <thead className="ShippingZonesHead">
            <tr>
                <th>Supplier</th>
                <th>Zone name</th>
                <th>Country</th>
                <th>States</th>
                {permissionsData.includes('ffm_shipping_zone_update') && 
                    <th>Action</th>
                }
            </tr>
            </thead>
        )
    }
}

export default ShippingZonesHead
