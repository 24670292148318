import React from 'react'
import {Popconfirm, Tooltip, Table} from "antd"
import PropTypes from "prop-types"

ListTable.propTypes = {
    onEditValueRange: PropTypes.func.isRequired,
    onConfirmDelete: PropTypes.func.isRequired,
    onAddNewColumn: PropTypes.func.isRequired,
    onChangeCountryCode: PropTypes.func.isRequired,
    onConfirm: PropTypes.func.isRequired,
    onChangeValue: PropTypes.func.isRequired
}

export default function ListTable(props) {
    const {
        shippingCosts,
        headers,
        onEditValueRange,
        onConfirmDelete,
        onAddNewColumn,
        onChangeCountryCode,
        onConfirm,
        onChangeValue,
        supplier,
        highlightRow = (row) => {
        }
    } = props
    const _onEditValueRange = (index) => (e) => {
        onEditValueRange(index)(e)
    }
    const _confirmDelete = (from, to, index) => {
        onConfirmDelete(from, to, index)
    }
    const _onAddNewColumn = () => {
        onAddNewColumn()
    }
    const _onChangeCountryCode = (e, index) => {
        onChangeCountryCode(e, index)
    }
    const _confirm = (code, index) => {
        onConfirm(code, index)
    }
    const _onChangeValue = (e, index, from, to) => {
        onChangeValue(e, index, from, to)
    }

    const columns = [{
        title: 'Country code',
        className: "CountryCode",
        key: 'country-code',
        fixed: 'left',
        render: (item, record, rowIndex) => {
            return <>
                <Popconfirm
                    title="Are you sure to delete ?"
                    onConfirm={() => _confirm(item.country_code, rowIndex)}
                    okText="Yes"
                    cancelText="No"
                    placement="topRight"
                >
                    <i className="RemoveRow ti-trash"/>
                </Popconfirm>

                <Tooltip title={`Type "ALL" or "*" for default`}>
                    <input
                        type="text"
                        value={item.country_code || ''}
                        className="InputCountryCode"
                        onChange={(e) => _onChangeCountryCode(e, rowIndex)}
                    />
                </Tooltip>

                {
                    item.isDuplicate && <Tooltip title={`Duplicate country code`}>
                        <i className="ErrorDuplicateCountry ti-info-alt text-danger"/>
                    </Tooltip>
                }
            </>
        }
    }]

    headers.forEach((item, index) => {
        const {isError} = item
        const input = (item) => {
            return <div>
                <div>
                    <input
                        className="RangeValueFrom"
                        name='from'
                        type="text"
                        value={(item.from !== '' && item.from !== null) ? item.from : ''}
                        onChange={_onEditValueRange(index)}
                        disabled={supplier === "1C" ? false : true}
                    />
                    -
                    <input
                        name='to'
                        type="text"
                        value={(item.to !== '' && item.to !== null) ? item.to : ''}
                        onChange={_onEditValueRange(index)}
                        disabled={supplier === "1C" ? false : true}
                    />
                </div>
            </div>
        }
        columns.push({
            title: <div className={['RangeValue', isError ? 'Error' : ''].filter(Boolean).join(' ')}>
                {
                    isError && <Tooltip title={`Invalid range`}>
                        {input(item)}
                    </Tooltip>
                }
                {
                    !isError && input(item)
                }
                {supplier === "1C" && <Popconfirm
                    title="Are you sure to delete ?"
                    onConfirm={() => _confirmDelete(item.from, item.to, index)}
                    okText="Yes"
                    cancelText="No"
                >
                    <i className="ti-trash"/>
                </Popconfirm>}
            </div>,
            key: `col-${index}`,
            render: (item, record, rowIndex) => {
                const itemValue = item.values && item.values[index] || {}

                return <input
                    type="text"
                    value={itemValue.cost || ''}
                    onChange={(e) => _onChangeValue(e, rowIndex, itemValue.from, itemValue.to)}
                />
            }
        })
        // return <th key={index} className="ShippingRange">
        //     <div className="RangeValue">
        //         <input
        //             name='from'
        //             type="text"
        //             value={(item.from !== '' && item.from !== null) ? item.from : ''}
        //             onChange={_onEditValueRange(index)}
        //         />
        //         -
        //         <input
        //             name='to'
        //             type="text"
        //             value={(item.to !== '' && item.to !== null) ? item.to : ''}
        //             onChange={_onEditValueRange(index)}
        //         />
        //
        //         <Popconfirm
        //             title="Are you sure to delete ?"
        //             onConfirm={() => _confirmDelete(item.from, item.to, index)}
        //             okText="Yes"
        //             cancelText="No"
        //         >
        //             <i className="ti-trash"/>
        //         </Popconfirm>
        //     </div>
        // </th>
    })
    columns.push({
        className: `AddColumnShippingCost ${supplier === "1C" ? "" : "d-none"}`,
        key: 'new-column',
        title: <Tooltip title='Add new column'>
            <i className="ti-plus" onClick={_onAddNewColumn}/>
        </Tooltip>
    })

    const dataSource = shippingCosts.map((row, index) => {
        return {...row, key: index}
    })

    const onRenderRow = (record, index) => {
        return {
            className: record.isDuplicate ? `DuplicateCountryCode` : '',
            onMouseOver: highlightRow(record),
            onMouseLeave: highlightRow(),
            'data-group': record.isDuplicate
        }
    }

    return <>
        <Table columns={columns} className="table" dataSource={dataSource} pagination={false} onRow={onRenderRow}
               id="ShippingCosts"/>
    </>
}