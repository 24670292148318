import React, {Component} from 'react'
import PropTypes from 'prop-types'
import PackageSingleItemEditor from "./PackageSingleItemEditor"
import {Table} from "reactstrap"


class PackageListItemsEditor extends Component {

    render() {
        const {items, onChangeItem, onRemoveItem} = this.props

        return (
            <div className="PackageListItemsEditor">
                <div>Items</div>
                <Table responsive striped>
                    <thead>
                    <tr>
                        <th className='Actions'>&nbsp;</th>
                        <th>Mockup</th>
                        <th>Design</th>
                        <th>Attributes</th>
                        <th className='Quantity'>Quantity</th>
                    </tr>
                    </thead>
                    <tbody>
                    {
                        items.map((item, index) => {
                            return (
                                <PackageSingleItemEditor
                                    key={item.item_index}
                                    onChangeItem={onChangeItem}
                                    onRemoveItem={items.length > 1 ? onRemoveItem : false}
                                    item={item}
                                />
                            )
                        })
                    }
                    </tbody>
                </Table>
            </div>
        )
    }
}

PackageListItemsEditor.propTypes = {
    items: PropTypes.array.isRequired,
    onChangeItem: PropTypes.func.isRequired,
    onRemoveItem: PropTypes.func.isRequired
}

export default PackageListItemsEditor
