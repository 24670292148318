import React, {Component} from 'react'
import WithAuthentication from "./WithAuthentication"
import {Redirect, withRouter} from "react-router-dom"
import createApiServices from '../../services/api/createApiServices'
import {getRefreshToken, logoutUser} from '../../services/AuthServices'
import {refreshToken} from "../../services/api/UserService"
import {parseJwt} from "../../helpers/parseToken"
import * as AuthServices from "../../services/AuthServices"
import {getLocalData} from "../../services/StorageServices"

class EnsureLoggedIn extends Component {
    state = {
        load: false
    }
    _api = createApiServices()

    async componentDidMount() {
        this.setState({ load: false })
        const getTimeExp = getLocalData('timeExp')
        if (new Date().getTime() > getTimeExp && getRefreshToken()) {
            try {
                const { data, success, message } = await refreshToken({ refreshToken: getRefreshToken() })
                if (!success) {
                    logoutUser(message || '')
                }
                // const { accessToken, roles, refreshToken: refreshTok } = {...data}
                const { accessToken, permissions, refreshToken: refreshTok, roles, facPermissions, ...rest } = {...data}
                const timeExp = parseJwt(accessToken).exp - parseJwt(accessToken).iat

                AuthServices.setAccessToken(accessToken)
                AuthServices.setUserData(rest)
                AuthServices.setRoles(roles)
                AuthServices.setRefreshToken(refreshTok)
                AuthServices.setTimeExp((timeExp - 300) * 1000 + new Date().getTime())
                this.setState({ load: true })
            } catch (error) {
                logoutUser(error.message || '')
                this.setState({ load: true })
            }
        } else {
            this.setState({ load: true })
        }

        this._api.subscribeErrorRequest(error => {
            const {status, data} = error
            if (status === 403) {
                const {message: mess, user } = Object.assign({}, data)
                // logoutUser(mess || '')
                throw new Error(mess)
            }
            if (status === 401) {
                const {message} = Object.assign({}, data)
                logoutUser(message || '')
            }
        })
    }

    render() {
        const {location} = this.props

        return (
            <WithAuthentication>
                {
                    ({isAuthenticated}) => {
                        return this.state.load ? isAuthenticated ? this.props.children : <Redirect to={{
                            pathname: "/login",
                            state: {referrer: location.pathname + (location.search || '')}
                        }}/> : <div className="text-center bg-white">Đang tải...</div>
                    }
                }
            </WithAuthentication>
        )
    }
}

export default withRouter(EnsureLoggedIn)
