import React, {Component} from 'react'
import ShippingZoneMethodsTable from "./table/ShippingZoneMethodsTable"
import ShippingZoneNewMethodsActions from "./actions/ShippingZoneNewMethodsActions"

class ShippingZoneMethods extends Component {
    render() {
        const {
            listPlans,
            plans,
            _handleCreatePlan,
            _handleUpdatePlansState,
            _handleRemovePlan,
            onGetSelectedProduct,
            onUpdateSelectedProduct,
            onRemoveSelectedProduct
        } = this.props

        return (
            <div className="ShippingZoneNewMethods">
                <div className="row">
                    <div className="col-md-4">
                        <label htmlFor="" className="font-weight-500">Shipping rate</label>
                    </div>

                    <div className="col-md-8">
                        <ShippingZoneMethodsTable
                            listPlans={listPlans}
                            shippingMethods={plans}
                            _handleUpdatePlansState={_handleUpdatePlansState}
                            _handleRemovePlan={_handleRemovePlan}
                            onGetSelectedProduct={onGetSelectedProduct}
                            onUpdateSelectedProduct={onUpdateSelectedProduct}
                            onRemoveSelectedProduct={onRemoveSelectedProduct}
                        />

                        <ShippingZoneNewMethodsActions _handleCreatePlan={_handleCreatePlan}/>
                    </div>
                </div>
            </div>
        )
    }
}

export default ShippingZoneMethods
