export const allowStore = ['m825fdn', 'e6tfim4', '2u8dh9j', 'xlvod42', 'yt6tepe']
export const allowType = [
    'ACRYLIC_PLAQUE',
    'GRADUATION_STOLE',
    'MICA_KEYCHAIN_V2_3D',
    'CUSTOM_MICA_PLAQUE',
    'CUSTOM_ACRYLIC_PLAQUE',
    'CUSTOM_WOOD_SIGN',
    'HAWAII_SHIRT_UID',
    'PASSPORT_WALLET',
    'STEEL_KEYCHAIN',
    'PHONECASE_FC',
    'SOCKS_3D',
    'T-SHIRT',
    '1_CERAMIC_ORNAMENT',
    '2_CERAMIC_ORNAMENT',
    '1_ALUMINIUM_ORNAMENT',
    '2_ALUMINIUM_ORNAMENT',
    '1L_WOOD_ORNAMENT_UID',
    'MICA_ORNAMENT_UID',
    'CUSTOM_PLAQUE_0115MT',
    'GRADUA_STOLE_03',
    '1L_KEY_0103MT',
    'MICA_PLAQUE_0103MTM',
    'CUSTOM_SIGN_0205TM',
    'HAWAII_33',
    'PASSPORT_0804TM',
    'STEEL_KEY_0502IK',
    'CUSTOM_PLAQUE_0115MT',
    '1S_CER_ORN_0303TM',
    '2S_CER_ORN_0303TM',
    '1S_ALU_ORN_0500D7TM',
    '2S_ALU_ORN_0500D7TM',
    '1L_ORN_0203TM',
    'CUSTOM_ORN_0103MTM',
]