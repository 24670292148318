import React from 'react'
import SettingHolidayDetailHeader from './SettingHolidayDetailTable/SettingHolidayDetailHeader'
import SettingHolidayDetailRow from './SettingHolidayDetailTable/SettingHolidayDetailRow'

function SettingHolidayDetailTable(props) {
    const { listSettingDetail = [], currentData = [], addSetting, removeSetting, handleChangeSetting, error, handleSort, sortType, errorValidate, preCutoff } = { ...props }

    return (
        <div className='SettingHolidayDetailTable pt-3'>
            <table className='table'>
                <SettingHolidayDetailHeader handleSort={handleSort} sortType={sortType} listSettingDetail={listSettingDetail} />
                <tbody className='SettingHolidayDetailRow'>
                    {!!listSettingDetail && listSettingDetail.length > 0 && listSettingDetail.map((item, i) => {
                        return (
                            <SettingHolidayDetailRow
                                key={i}
                                index={i}
                                item={item}
                                removeSetting={removeSetting}
                                handleChangeSetting={handleChangeSetting}
                                errorValidate={errorValidate}
                                preCutoff={preCutoff}
                                currentData={currentData[i]}
                            />
                        )
                    })}
                </tbody>
            </table>
            {error.message &&
                <div>
                    <label className='text-danger'>{error.message}</label>
                </div>
            }
            <button className='btn btn-link' onClick={() => addSetting()}>+ Add Holiday</button>
        </div>
    )
}

export default SettingHolidayDetailTable