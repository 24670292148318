import React, { useState, useEffect } from 'react'
import { message, Spin, Select, Input } from "antd"
import SettingHolidayDetailTable from './SettingHolidayDetailTable'
import { Link } from 'react-router-dom'
import { getHolidaySetting, updateHolidaySetting } from '../../../services/api/SuppliersServices'
import moment from 'moment'

function SettingHolidayDetailPage(props) {
    const { idSetting } = { ...props }
    const [UTCTime, setUTCTime] = useState(7)
    const [preCutoff, setPreCutoff] = useState(null)
    const [currentPreCutoff, setCurrentPreCutoff] = useState(null)
    const [listSettingDetail, setListSettingDetail] = useState([])
    const [currentData, setCurrentData] = useState([])
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState({})
    const [supplierDetail, setSupplierDetail] = useState({})
    const [sortType, setSortType] = useState('down')
    const [errorValidate, setErrorValidate] = useState([])

    const fetchSettingDetail = async () => {
        setLoading(true)
        delete error.message
        setSortType('')
        try {
            const { data, success, message: mess } = await getHolidaySetting(idSetting)
            if (success) {
                const { holidays = [], supplier = {} } = { ...data }
                const sortHolidays = holidays.length > 0 ? holidays.sort((a, b) => {
                    if (!a.start_time) return 1
                    if (!b.start_time) return -1
                    return sortType === 'up' ? new Date(a.start_time) - new Date(b.start_time) : new Date(b.start_time) - new Date(a.start_time)
                }) : []
                const holidaysHavePreCutoff = holidays.length > 0 && holidays[0]
                const PreCutoffDays = holidaysHavePreCutoff ? holidaysHavePreCutoff.pre_cutoff_days : null
                setListSettingDetail(sortHolidays)
                setCurrentData(sortHolidays)
                setSupplierDetail(supplier)
                setUTCTime(holidays.length > 0 ? holidays[0].time_zone : 7)
                setPreCutoff(PreCutoffDays)
                setCurrentPreCutoff(PreCutoffDays)
            } else {
                message.error(mess)
            }
            setLoading(false)
        } catch (error) {
            setError({ ...error, message: error.message || 'Unknown error.' })
        } finally {
            setLoading(false)
        }
    }

    const handleChangeUTC = (value) => {
        setUTCTime(value)
    }

    const handleChangePreCutoff = (e) => {
        delete error.preCutoff
        const value = e.target.value
        if (value || value === 0) {
            if (value <= 0) {
                setError({ ...error, preCutoff: 'Minimum value is 1' })
                setPreCutoff(null)
            } else {
                setPreCutoff(value)
            }
        } else {
            setPreCutoff(null)
        }
    }

    const handleAddSetting = () => {
        setListSettingDetail([
            ...listSettingDetail,
            {
                name: "",
                start_time: null,
                end_time: null,
                cutoff_time: null,
                pre_cutoff_days: null,
            }
        ])
    }

    const handleRemoveSetting = (index) => {
        const newList = listSettingDetail.filter((i, idx) => idx !== index)
        setListSettingDetail(newList)

    }

    const handleChangeSetting = (index, field, value) => {
        delete error.message
        setErrorValidate([])
        let listUpdated = []

        listSettingDetail.map((i, idx) => {
            if (idx === index) {
                if (field === 'holidayDate') {

                    return listUpdated.push({
                        ...i,
                        start_time: value && value.length > 0 ? value[0].utcOffset(0, true).toISOString() : null,
                        end_time: value && value.length > 0 ? value[1].utcOffset(0, true).toISOString() : null,
                    })
                }
                if (field === 'cutoff_time') {
                    return listUpdated.push({ ...i, [field]: value ? value.utcOffset(0, true).toISOString() : null })
                }
                return listUpdated.push({ ...i, [field]: value })
            }

            return listUpdated.push(i)
        })

        setListSettingDetail(listUpdated)
    }

    const getOptionUTCTime = (start, end, another) => {
        const Options = []
        for (let i = start; i <= end; i++) {
            const Option = {
                name: (i >= 0 ? `UTC +` : `UTC -`) + Math.abs(i).toString().padStart(2, '0') + ":00",
                value: i,
            }
            Options.push(Option)
        }
        const anotherTimeZone = [
            { name: 'UTC -09:30', value: -9.5 },
            { name: 'UTC -03:30', value: -3.5 },
            { name: 'UTC +03:30', value: -3.5 },
            { name: 'UTC +04:30', value: 4.5 },
            { name: 'UTC +05:30', value: 5.5 },
            { name: 'UTC +05:45', value: 5.75 },
            { name: 'UTC +06:30', value: 6.5 },
            { name: 'UTC +08:45', value: 8.75 },
            { name: 'UTC +09:30', value: 9.5 },
            { name: 'UTC +10:30', value: 10.5 },
            { name: 'UTC +12:45', value: 12.75 },
        ]
        return another ? [...Options, ...anotherTimeZone].sort((a, b) => a.value - b.value) : Options
    }

    const validate = () => {
        setErrorValidate([])
        let isValid = true

        if (listSettingDetail && listSettingDetail.length > 0) {
            listSettingDetail.forEach((i, index) => {
                let today = moment().utc()
                let errors = {}
                let isPast = moment(today).isSameOrAfter(moment(i.end_time))
                let isCutoff = i.cutoff_time ? today.isSame(i.cutoff_time, 'day') : false
                let isBeforeStartTime = moment(i.cutoff_time).isBefore(moment(i.start_time))

                if (!i.name) {
                    errors.name = 'Please input Holiday name!!!'
                } else if (i.name.length > 100) {
                    errors.name = 'Maximum 100 characters.'
                }
                if (!i.start_time) {
                    errors.holiday_date = 'Please select Holiday date!!!'
                }
                if (!!i.cutoff_time && !isBeforeStartTime && !isPast) {
                    errors.cutoff_time = 'Cutoff time cannot be after the holiday start time.'
                }
                if (Object.keys(errors).length > 0) {
                    errors.row = index
                    setErrorValidate(prevErrors => [...prevErrors, errors])
                    isValid = false
                }
                if (!!i.cutoff_time && !preCutoff && !isPast) {
                    setError({ ...error, preCutoff: 'Please input Pre-Cutoff days!' })
                    isValid = false
                }
            })

            return isValid
        }

        return isValid
    }

    const handleSave = async () => {
        const payload = {
            time_zone: UTCTime,
            pre_cutoff_days: preCutoff
        }
        if (!validate()) return

        if (isChangeData(listSettingDetail, currentData)) {
            const configs = listSettingDetail.length > 0 ? listSettingDetail.map(i => {
                const newObj = Object.fromEntries(Object.entries({
                    ...i,
                    start_time: i.start_time ? moment(i.start_time).utcOffset(0).format('DD/MM/YYYY') : null,
                    end_time: i.end_time ? moment(i.end_time).utcOffset(0).format('DD/MM/YYYY') : null,
                    cutoff_time: i.cutoff_time ? moment(i.cutoff_time).utcOffset(0).format('DD/MM/YYYY HH:mm') : null,
                }).filter(([_, value]) => !!value))
                return newObj
            }).filter(obj => Object.keys(obj).length !== 0) : []
            payload.configs = configs
        }
        try {
            const { data, success, message: mess } = await updateHolidaySetting(idSetting, payload)
            if (!success) {
                throw new Error(mess)
            }
            message.success(`Setting Holiday ${currentData.length ? 'updated' : 'created'}!!!`)
            fetchSettingDetail()
        } catch (error) {
            setError({ ...error, message: error.message })
            message.error(error.message)
        }
    }

    const handleSort = (type) => {
        if (listSettingDetail && listSettingDetail.length > 0) {
            setSortType(type)
            const sortData = listSettingDetail.slice().sort((a, b) => {
                if (!a.start_time) return 1
                if (!b.start_time) return -1
                return type === 'up' ? new Date(a.start_time) - new Date(b.start_time) : new Date(b.start_time) - new Date(a.start_time)
            })

            const sortCurrentData = currentData.slice().sort((a, b) => {
                if (!a.start_time) return 1
                if (!b.start_time) return -1
                return type === 'up' ? new Date(a.start_time) - new Date(b.start_time) : new Date(b.start_time) - new Date(a.start_time)
            })
            setCurrentData(sortCurrentData)
            setListSettingDetail(sortData)
        }
    }

    useEffect(() => {
        fetchSettingDetail()
    }, [idSetting])

    const isChangeData = (data, currentdata) => {
        if (data.length !== currentdata.length) {
            return true
        }
        if (preCutoff !== currentPreCutoff) {
            return true
        }
        for (let i = 0; i < data.length; i++) {
            if (JSON.stringify(data[i]) !== JSON.stringify(currentdata[i])) {
                return true
            }
        }
        return false
    }

    const UTCOptions = getOptionUTCTime(-12, 14, true)
    const { Option } = Select
    const isEdit = listSettingDetail && listSettingDetail.length > 0 && listSettingDetail.some(obj => obj.hasOwnProperty('_id'))
    const today = moment().utc().startOf('day').toISOString()

    const allIsPastOrHappening = listSettingDetail.length > 0 ? listSettingDetail.filter(i => i.start_time && i.end_time &&
        (moment(today).isSameOrAfter(moment(i.end_time)) || (moment(today).isBetween(moment(i.start_time), moment(i.end_time))))
    ).length === listSettingDetail.length : false

    const todayIsCutoff = listSettingDetail.length > 0 ? !!currentPreCutoff && !!listSettingDetail.filter(i => !!i.cutoff_time &&
        moment(today).startOf('day').isSame(moment(i.cutoff_time).utc().startOf('day'), 'day')
    ).length : false

    const currentTodayIsCutoff = currentData.length > 0 ? !!currentPreCutoff && !!currentData.filter(i => !!i.cutoff_time &&
        moment(today).startOf('day').isSame(moment(i.cutoff_time).utc().startOf('day'), 'day')
    ).length : false
    const disablePreCutoffDays = loading || allIsPastOrHappening || (todayIsCutoff && currentTodayIsCutoff)

    return (
        <div className='SettingHolidayDetailPage'>
            <div className='PageHeading d-flex align-items-center justify-content-between border-bottom'>
                <h1 className="PageTitle mt-2 mb-0">Setting Holiday - Supplier {supplierDetail.name || ''}</h1>
                <div className="RightTop">
                    <div className='Actions'>
                        <Link to='/a/setting-holiday' className='ant-btn ant-btn-default px-4 rounded mr-3 btn-secondary'>Cancel</Link>
                        <button
                            className='ant-btn ant-btn-primary px-4 rounded mr-3'
                            disabled={loading || !isChangeData(listSettingDetail, currentData)}
                            onClick={handleSave}
                        >
                            Save
                        </button>
                    </div>
                </div>
            </div>
            <div className="SectionInner mt-4" style={{ minHeight: '775px' }}>
                <h5 className='mb-4'>Configure Holiday Date and Cutoff Date: </h5>
                <div className="filter align-items-center mt-2 mb-3">
                    <div className="filter-item mb-3 d-flex align-items-center">
                        <label className='font-weight-bold mb-0' style={{ minWidth: '127px' }}>UTC Time:</label>
                        <Select
                            placeholder='Select UTC Time'
                            className='ml-2'
                            value={UTCTime}
                            showSearch
                            style={{ minWidth: '300px' }}
                            disabled={loading || isEdit}
                            onChange={handleChangeUTC}
                        >
                            {
                                UTCOptions.map((item, index) =>
                                    <Option key={index} value={item.value}>
                                        {item.name}
                                    </Option>
                                )
                            }
                        </Select>
                    </div>
                    <div className="filter-item d-flex align-items-center">
                        <label className='font-weight-bold mb-0' style={{ minWidth: '127px' }}>Pre-Cutoff Days: </label>
                        <Input
                            placeholder='Pre-Cutoff days'
                            className='ml-2'
                            value={!preCutoff ? null : preCutoff}
                            style={{ maxWidth: '150px' }}
                            disabled={disablePreCutoffDays}
                            onChange={handleChangePreCutoff}
                            type='number'
                            allowClear
                            min={1}
                        >
                        </Input>
                        <label className='ml-2 mb-0'>Calendar days</label>
                    </div>
                    <div className="filter-item d-flex align-items-center">
                        <label className='font-weight-bold mb-0' style={{ minWidth: '127px' }}></label>
                        {error.preCutoff && <small className='text-danger ml-2 mt-2'>{error.preCutoff}</small>}
                    </div>
                </div>
                <div>
                    <Spin spinning={loading} tip='Data Loading...'>
                        <SettingHolidayDetailTable
                            listSettingDetail={listSettingDetail || []}
                            UTCTime={UTCTime}
                            currentData={currentData}
                            loading={loading}
                            reload={fetchSettingDetail}
                            addSetting={handleAddSetting}
                            removeSetting={handleRemoveSetting}
                            handleChangeSetting={handleChangeSetting}
                            error={error}
                            handleSort={handleSort}
                            sortType={sortType}
                            errorValidate={errorValidate}
                            preCutoff={preCutoff}
                        />
                    </Spin>
                </div>
            </div>
        </div>
    )
}

export default SettingHolidayDetailPage