import React from 'react'
import PropTypes from 'prop-types'
import ShippingPriceContext from "../context/ShippingPriceContext"

ShippingPriceProvider.propTypes = {
    loadShippingPrice: PropTypes.func.isRequired,
    setState: PropTypes.func.isRequired,
    filters: PropTypes.string.isRequired
}

function ShippingPriceProvider(props) {
    const {loadShippingPrice, setState, filters} = props

    return (
        <ShippingPriceContext.Provider
            value={{
                loadShippingPrice,
                filters,
                setState
            }}
        >
            {props.children}
        </ShippingPriceContext.Provider>
    )
}

export default ShippingPriceProvider
