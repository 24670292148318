import React, {Component} from 'react'
import ShippingMethodsPlan from "./data/ShippingMethodsPlan"
import ShippingMethodsProduct from "./data/ShippingMethodsProduct"
import ShippingMethodsBaseCost from "./data/ShippingMethodsBaseCost"
import ShippingMethodsAdditionalCost from "./data/ShippingMethodsAdditionalCost"
import ShipingMethodsActions from "./data/ShipingMethodsActions"

class ShippingZoneMethodsRow extends Component {
    render() {
        const {
            index,
            _handleUpdatePlansState,
            _handleRemovePlan,
            method,
            onGetSelectedProduct,
            onUpdateSelectedProduct,
            onRemoveSelectedProduct,
            inputValue,
            listPlans
        } = this.props

        const {plan: selectedPlan, first_rate, second_rate, products, product_title = "", plan_title = ""} = method

        return (
            <tr className="ShippingZoneNewMethodsRow">
                <ShippingMethodsPlan
                    listPlans={listPlans}
                    index={index}
                    planTitle={plan_title}
                    _handleUpdatePlansState={_handleUpdatePlansState}
                    selectedPlan={selectedPlan}
                />

                <ShippingMethodsProduct
                    inputValue={inputValue}
                    index={index}
                    product={products[0]}
                    productTitle={product_title}
                    selectedPlan={selectedPlan}
                    _handleUpdatePlansState={_handleUpdatePlansState}
                    onGetSelectedProduct={onGetSelectedProduct}
                    onUpdateSelectedProduct={onUpdateSelectedProduct}
                    onRemoveSelectedProduct={onRemoveSelectedProduct}
                />

                <ShippingMethodsBaseCost
                    index={index}
                    _handleUpdatePlansState={_handleUpdatePlansState}
                    baseCost={first_rate}
                />

                <ShippingMethodsAdditionalCost
                    index={index}
                    _handleUpdatePlansState={_handleUpdatePlansState}
                    additionalCost={second_rate}
                />

                <ShipingMethodsActions
                    _handleRemovePlan={_handleRemovePlan}
                    onRemoveSelectedProduct={onRemoveSelectedProduct}
                    index={index}
                    product={products[0]}
                    selectedPlan={selectedPlan}
                />
            </tr>
        )
    }
}

export default ShippingZoneMethodsRow
