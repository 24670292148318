import React, {Component} from 'react'
import PropTypes from 'prop-types'
import {listStoreModes} from "../../../../../statics/storeModes"

class OrderStoreMode extends Component {
    _renderStoreMode = (storeModes) => {
        return listStoreModes.find(mode => mode.value === storeModes)
    }

    render() {
        const {storeMode} = this.props
        const renderStoreMode = storeMode !== undefined && this._renderStoreMode(storeMode)

        return (
            <td>
                {renderStoreMode !== undefined && renderStoreMode.name}
            </td>
        )
    }
}

OrderStoreMode.propTypes = {
    storeMode: PropTypes.string
}

export default OrderStoreMode