import React, { Component, Fragment } from 'react'
class OrderNote extends Component {

    state = {
        isShow: false
    }

    _splitNote = (string) => {
        const listStrings = string.split(" ")

        if (listStrings.length > 25) {
            let new_arr = []
            for (let i = 0; i < 25; i++) {
                new_arr.push(listStrings[i])
            }
            return new_arr.join(' ') + '...'
        } else {
            return string
        }

    }

    _handleChangeShow = () => {
        this.setState({
            isShow: !this.state.isShow
        })
    }

    _handleShowMore = (text, limit) => {
        if (!text) return

        const { isShow } = this.state
        const textNote = text
        const splitNote = text.split(',') || []

        if (!isShow && textNote.length > limit) {
            return (
                <Fragment>
                    <span>
                        {
                            splitNote.length > 0 && (() => {
                                let charCount = 0

                                return splitNote.map((item, index) => {
                                    if (charCount >= limit) return null

                                    const remainingChars = limit - charCount
                                    const truncatedItem = item.length > remainingChars ? item.substring(0, remainingChars) + "..." : item

                                    charCount += truncatedItem.length

                                    return (
                                        <div key={index}>-{truncatedItem}</div>
                                    )
                                })
                            })()
                        }
                    </span>
                    <small className="float-right text-primary cursor-pointer" style={{ lineHeight: '22px' }} onClick={this._handleChangeShow}>show more</small>
                </Fragment>
            )
        }

        if (isShow && textNote.length > limit) {
            return (
                <Fragment>
                    <span>
                        {
                            splitNote.length > 0 && splitNote.map((item, index) => {
                                return (
                                    <div key={index}>-{item}</div>
                                )
                            })
                        }
                    </span>
                    <small
                        className="float-right text-primary cursor-pointer position-absolute"
                        style={{
                            right: 0,
                            bottom: 0
                        }}
                        onClick={this._handleChangeShow}>show less</small>
                </Fragment>
            )
        }
    }

    render() {
        const { note } = this.props
        const newNote = !!note ? note.split(",") : []
        const LIMIT_NOTE = 200

        return (
            <td className="OrderNote position-relative">
                {
                    note.length <= LIMIT_NOTE ? (
                        newNote.length > 0 && newNote.map((item, index) => (
                            <div key={index}>{(note !== '') && `-${this._splitNote(item)}`}</div>
                        ))
                    ) : (
                        this._handleShowMore(note, LIMIT_NOTE)
                    )
                }
            </td>
        )
    }
}

export default OrderNote