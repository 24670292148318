import React, {Component} from 'react'
import {getItemsByOrderId} from "../../../../services/api/OrdersServices"
import OrderItem from "./OrderItem"
import PropTypes from 'prop-types'

class OrderItemsList extends Component {
    state = {
        items: [],
        error: '',
    }

    componentDidMount() {
        this._fetchOrderItems()
    }

    _fetchOrderItems = async () => {
        try {
            const {orderId} = this.props
            const {data, success, message} = await getItemsByOrderId(orderId)

            if (!success) {
                this.setState({
                    error: message,
                })

                return
            }

            this.setState({
                items: data,
                error: '',
            })
        } catch (e) {
            this.setState({
                error: e.message,
            })
        }
    }

    render() {
        const {items} = this.state

        return (
            <div className='OrderItemsList'>
                <div className="btn-light p-3">
                    <div className="row fs-14 text-muted align-items-center">
                        {
                            items.map(item => {
                                return <OrderItem key={item._id} item={item}/>
                            })
                        }
                    </div>
                </div>
            </div>
        )
    }
}

OrderItemsList.propTypes = {
    orderId: PropTypes.string.isRequired,
}

export default OrderItemsList
