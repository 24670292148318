import React, {Component} from 'react'
import PropTypes from 'prop-types'
import FilterOptions from '../../../shared/FilterOptions'
import {listStoreModes} from "../../../../statics/storeModes"

class StoreMode extends Component {
    render() {
        const {store_mode, onChangeFilterState} = this.props

        return (
            <div className="Platform">
                <FilterOptions onChangeOption={onChangeFilterState} selectedKey={store_mode} field='store_mode'
                               placeHolder="Select Store mode"
                               options={listStoreModes} layout='select' heading='Store Mode:' defaultOption={''}
                               allowClear={true}/>
            </div>
        )
    }
}

StoreMode.propTypes = {
    onChangeFilterState: PropTypes.func.isRequired,
    store_mode: PropTypes.string.isRequired,
}

export default StoreMode
