import React, {useContext} from 'react'
import {Select} from 'antd'
import ShippingPriceContext from "../context/ShippingPriceContext"

const {Option} = Select

function ShippingPriceFilters(props) {
    const {setState, filters} = useContext(ShippingPriceContext)
    const {setProduct_title, setSupplier_sku, setShipping_carrier, setCountry_code, setShipping_method, setPage} = props
    const _handleChange = (value) => {
        setState(value)
        setProduct_title("")
        setSupplier_sku("")
        setShipping_carrier("")
        setCountry_code("")
        setShipping_method("")
        setPage(1)
    }

    return (
        <div className='ShippingPriceFilters'>
            {/* <label htmlFor="supplierID">Supplier</label> */}
            <div className="col-auto px-0 mr-3 mb-3" style={{width : "250px"}}>
                <div className="font-weight-bold LabelFilter mb-1">Supplier:</div>
                <Select
                    id="supplierID"
                    defaultValue={filters}
                    style={{width: 250}}
                    onChange={_handleChange}
                >
                    <Option value="1C">1C</Option>
                    <Option value="PF">PF</Option>
                    <Option value="CC">CC-API</Option>
                    <Option value="YC">YC</Option>
                    <Option value="CW">CW</Option>
                    <Option value="DBUS">DBUS</Option>
                    <Option value="PL EU">PL EU</Option>
                </Select>
            </div>
        </div>
    )
}

export default ShippingPriceFilters
