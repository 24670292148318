import React from 'react'
import FilterSelect from './filters/FilterSelect'
import FilterDate from "../../shared/FilterDate"
import { Input, Select } from 'antd'

const SpecialCaseFilters = (props) => {
    const { loading, suppliers, filters, handleChangeFilters, placeholders, selectField, handleChageSelectField } = props

    return (
        <div className='SpecialCaseFilters'>
            <div className='d-flex GroupFilter'>
                <div className="itemFilter mb-3 mr-4">
                    <Input.Group compact>
                        <Select
                            value={selectField}
                            style={{ width: 100 }}
                            onChange={handleChageSelectField}
                        >
                            <Select.Option value="store">Store</Select.Option>
                            <Select.Option value="title">Title</Select.Option>
                            <Select.Option value="tag">Tag</Select.Option>
                        </Select>
                        <Input
                            style={{ width: 300 }}
                            placeholder={placeholders[selectField]}
                            onChange={e => handleChangeFilters(selectField, e.target.value)}
                            value={filters[selectField]}
                            allowClear
                        />
                    </Input.Group>
                </div>
                <div className="itemFilter mb-3 mr-4">
                    <FilterSelect
                        placeholder="Choose supplier"
                        options={suppliers}
                        style={{ width: 200 }}
                        allowClear={true}
                        showSearch={true}
                        value={filters.supplier}
                        handleChangeFilters={handleChangeFilters}
                        field="supplier"
                    />
                </div>
                <div className="d-flex flex-nowrap align-items-center itemFilter filterPushedAt mb-3 font-weight-bold">
                    <FilterDate
                        field='updated_at'
                        value={filters.updated_at || {}}
                        onDatesChange={handleChangeFilters}
                    />
                </div>

            </div>
        </div>
    )
}

export default SpecialCaseFilters