import React from 'react'
import DocTitleByStore from "../../dashboard/shared/DocTitleByStore"
import ChargeRefundPage from './ChargeRefundPage'

function ChargeRefundPageContainer() {
    return (
        <div className="ChargeRefundPageContainer">
            <DocTitleByStore title="Charge/Refund" />
            <ChargeRefundPage />
        </div>
    )
}

export default ChargeRefundPageContainer