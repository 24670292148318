import React,{useState} from 'react'
import { Button, Modal, message, Input } from 'antd'
import { rejectedChargeRefund } from '../../../../services/api/ChargeRefundServices'

function RejectChargeRefund(props) {
    const {id, reload, status, adminCheck}= props
    const [showModal, setShowModal] = useState(false)
    const [btnLoading, setBtnLoading] = useState(false)
    const [reason, setReason] = useState('')

    const { TextArea } = Input

    const handleClick = () => {
        setShowModal(true)
    }

    const handleClose = () => {
        setShowModal(false)
        setReason('')
    }

    const handleReject = async(id) => {
        setBtnLoading(true)
        const payload={
            reject_reason: reason
        }
        try {
            const {success, message:mess} = await rejectedChargeRefund(id,payload)
            if(success){
                setBtnLoading(false)
                message.success('Request rejected!')
                setShowModal(false)
                reload()
            }else{
                message.error(mess)
            }
        } catch (error) {
            message.error(error)
        }finally{
            setBtnLoading(false)
        }
    }

    const handleChangeReason = (e) => {
        setReason(e.target.value) 
    }

    return (
        <div>
            <Button 
                className='btn btn-danger mx-1 btn-sm'
                onClick={()=>handleClick(id)}
                disabled={!adminCheck || status!=='pending'}
            >
                Reject
            </Button>
            <Modal
                visible={showModal}
                okText='Confirm'
                onOk={()=>handleReject(id)}
                onCancel={handleClose}
                confirmLoading={btnLoading}
                title='Reason for rejection'
                cancelButtonProps={{disabled: btnLoading}}
                okButtonProps={{disabled: reason === ''}}
                className='ReasonModal'
            >
                <TextArea 
                    rows={4} 
                    placeholder='Reason for rejection...'
                    onChange={handleChangeReason}
                    value={reason || ''}
                />
            </Modal>
        </div>
    )
}

export default RejectChargeRefund