import React, {Component} from 'react'
import PropTypes from 'prop-types'
import SpecificPopularTag from "./SpecificPopularTag"

class ListPopularTags extends Component {
    render() {
        const {popularTags, onCreateOrderTags} = this.props

        return (
            <ul className="ListPopularTags" style={{lineHeight: 0}}>
                {
                    popularTags.map((tag, index) => {
                        if (tag.title !== 'thank_card' && tag.title !== 'thank_you_card') {
                            return <SpecificPopularTag key={`${tag}_${index}`} tag={tag}
                                                       onCreateOrderTags={onCreateOrderTags}/>
                        }

                        return null
                    })
                }
            </ul>
        )
    }
}

ListPopularTags.propTypes = {
    popularTags: PropTypes.array.isRequired,
    onCreateOrderTags: PropTypes.func.isRequired,
}

export default ListPopularTags
