import React from 'react'

const ProductTypeReport = (props) => {
    const { returnPageHeihgt, FilterHeight, sortItemQuantity, sortBy, handleSortItemQuantity, totalErrorQuantity,
        sortCaseQuantity, showPercentIssue, baseLink, linkTo, issueProductType,
    } = props

    const issueProductTypeBySort = React.useMemo(() => {
        if (sortItemQuantity) {
            return issueProductType.sort((a, b) => a.quantity - b.quantity)
        } else {
            return issueProductType.sort((a, b) => b.quantity - a.quantity)
        }
    }, [sortItemQuantity, sortCaseQuantity, issueProductType, sortBy])

    return (
        <>
            <table className="table PackageIssueProductTypeTable">
                <thead style={{ top: `${returnPageHeihgt + FilterHeight}px` }}>
                    <tr>
                        <th className="col-product-type" rowSpan={2}>Product Type</th>
                        <th className="col-error-quantity" >Error quantity</th>
                    </tr>
                    <tr>
                        <th className="col-item-quantity text-right">
                            {totalErrorQuantity} {
                                sortItemQuantity ? <i className={`fa fa-long-arrow-up cursor-pointer p-1 ${sortBy === 'item' && 'text-primary'}`} onClick={handleSortItemQuantity} /> :
                                    <i className={`fa fa-long-arrow-down cursor-pointer p-1 ${sortBy === 'item' && 'text-primary'}`} onClick={handleSortItemQuantity} />
                            }
                        </th>
                    </tr>
                </thead>
                <tbody>
                    {
                        !!issueProductTypeBySort && issueProductTypeBySort.map((item, index) => (
                            <tr key={index}>
                                <td className="col-product-type">{item.type || 0}</td>
                                <td className="col-error-quantity text-right">
                                    {linkTo(baseLink, showPercentIssue(item.quantity, totalErrorQuantity), {
                                        product_type: item.type,
                                    })}
                                </td>
                            </tr>
                        ))
                    }
                </tbody>
            </table>
            <table className="table PackageIssueProductTypeTable" style={{ display: 'none' }} id="Issue_Product_Type_Report">
                <thead style={{ top: `${returnPageHeihgt + FilterHeight}px`, display: 'none' }}>
                    <tr>
                        <th className="col-product-type" rowSpan={2}>Product Type</th>
                        <th className="col-error-quantity" >Error quantity</th>
                    </tr>
                    <tr>
                        <th className="col-item-quantity text-right">
                            {totalErrorQuantity} {
                                sortItemQuantity ? <i className={`fa fa-long-arrow-up cursor-pointer p-1 ${sortBy === 'item' && 'text-primary'}`} onClick={handleSortItemQuantity} /> :
                                    <i className={`fa fa-long-arrow-down cursor-pointer p-1 ${sortBy === 'item' && 'text-primary'}`} onClick={handleSortItemQuantity} />
                            }
                        </th>
                    </tr>
                </thead>
                <tbody style={{ display: 'none' }}>
                    {
                        !!issueProductTypeBySort && issueProductTypeBySort.map((item, index) => (
                            <tr key={index}>
                                <td className="col-product-type">{item.type || 0}</td>
                                <td className="col-error-quantity text-right">
                                    {showPercentIssue(item.quantity, totalErrorQuantity)}
                                </td>
                            </tr>
                        ))
                    }
                </tbody>
            </table>
        </>
    )
}

export default ProductTypeReport