import React, {Component} from 'react'
import ShippingPlansHead from "./ShippingPlansHead"
import ShippingPlansRow from "./ShippingPlansRow"

class ShippingPlansTable extends Component {
    render() {
        const {plans} = this.props

        return (
            <table className="ShippingPlansTable table">
                <ShippingPlansHead/>

                {
                    plans.length > 0 &&
                        <tbody>
                        {
                            plans.map((plan, index) => {
                                return <ShippingPlansRow key={plan._id} plan={plan} index={index + 1}/>
                            })
                        }
                        </tbody>
                }
            </table>
        )
    }
}

export default ShippingPlansTable
