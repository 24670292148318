import React from 'react'
import PackageSplittingRulesHeader from './PackageSplittingRulesTable/PackageSplittingRulesHeader'
import PackageSplittingRulesRow from './PackageSplittingRulesTable/PackageSplittingRulesRow'

function PackageSplittingRulesTable(props) {
    const { listRule = [], refresh, permissionsData, setLoading } = { ...props }

    return (
        <div className='PackageSplittingRulesTable mt-3'>
            <table className='table'>
                <PackageSplittingRulesHeader
                    permissionsData={permissionsData}
                />
                <tbody className='PackageSplittingRulesRow'>
                    {!!listRule && listRule.length > 0 ? listRule.map((item, i) => {
                        return <PackageSplittingRulesRow
                            key={item._id}
                            index={i}
                            item={item}
                            refresh={refresh}
                            setLoading={setLoading}
                            permissionsData={permissionsData}
                        />
                    }) : <tr>
                        <td colSpan={10}>No data</td>
                    </tr>
                    }
                </tbody>
            </table>
        </div>
    )
}

export default PackageSplittingRulesTable