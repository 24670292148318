import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Modal, notification } from 'antd'
import SuppliersContext from '../../../context/SuppliersContext'
import { bulkMarkFulFilledOrder } from '../../../../../services/api/OrdersServices'
import { ObjectInspector } from 'react-inspector'

class BulkMarkFulFilledOrders extends Component {

    _openModal = () => {
        this._renderModalContent()
    }

    _renderModalContent = () => {
        const _handleOk = this._reqBulkMarkFulFilledOrders

        Modal.confirm({
            title: 'Please confirm "Fulfilled Orders"',
            onOk() {
                return _handleOk()
            },
        })
    }

    _reqBulkMarkFulFilledOrders = async () => {
        try {
            const { selectedOrders: orderIds } = this.props

            const { data, success, message } = await bulkMarkFulFilledOrder(orderIds)

            if (!success) throw new Error(message)

            const { success: totalSuccess, error: totalError } = data

            if (totalError === 0) {
                notification.success({
                    message: 'SUCCESS',
                    description: <ObjectInspector data={data} />,
                    duration: 0,
                })
            } else if (totalSuccess === 0) {
                notification.error({
                    message: 'ERROR',
                    description: <ObjectInspector data={data} />,
                    duration: 0,
                })
            } else {
                notification.info({
                    message: 'RESULT',
                    description: <ObjectInspector data={data} />,
                    duration: 0,
                })
            }

        } catch (e) {
            notification.error({
                message: 'ERROR',
                description: e.message,
                duration: 0,
            })
        }
    }

    render() {
        return (
            <div className='BulkMarkFulFilledOrders' onClick={this._openModal}>
                Mark fulfilled orders
            </div>
        )
    }
}

BulkMarkFulFilledOrders.propTypes = {
    selectedOrders: PropTypes.array.isRequired,
    suppliers: PropTypes.array.isRequired,
}

export default function (props) {
    return (
        <SuppliersContext.Consumer>
            {
                suppliers => {
                    return <BulkMarkFulFilledOrders suppliers={suppliers} {...props} />
                }
            }
        </SuppliersContext.Consumer>
    )
}
