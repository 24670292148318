import React from 'react'
import Link from 'react-router-dom/Link'

function OrderProfitRow(props) {
    const { item, reload, togglePackageDetailModal } = props
    const supplier = !!item.suppliers ? item.suppliers.map(i => (
        <label key={i.name} className="suppliers text-capitalize badge badge-primary mr-2 mb-2">
            {i.name}
        </label>
    )) : ''

    const checkValue = (value) => {
        return value !== undefined && value !== '' && !hasProfit? value : '-'
    }

    const checkProfit = (value) => {        
        if(hasProfit){
            return (
                <a onClick={()=>togglePackageDetailModal(item._id)}>
                    Details
                </a>
            )
        }else{
            return (
                <label>{value.profit}</label>
            )
        }
    }

    const {supplier_invoice} = item
    const {
        shipping_fee, 
        production_fee,
        profit, 
        revenue,
        // tax,
    } = {...supplier_invoice}
    const Profit = (!!profit && profit == 0) || !profit ? true : false
    const Revenue = (!!revenue && revenue == 0) || !revenue ? true : false
    const Production_fee = (!!production_fee && production_fee == 0) || !production_fee ? true : false
    const Shipping_fee = (!!shipping_fee && shipping_fee == 0) || !shipping_fee ? true : false
    // const Tax = (!!tax && tax == 0) || !value.tax ? true : false
    const hasProfit = Profit && Revenue && Production_fee && Shipping_fee

    return (
        <tr className='OrderProfitRow'>
            <td className='Order'>
                <Link to={`/a/orders/${item._id}`}>
                    {item.order_id}
                </Link>
            </td>
            <td className='supplier'>
                {supplier}
            </td>
            <td className='Value revenue'>
                <label>
                    {checkValue(item.supplier_invoice.revenue)}
                </label>
            </td>
            <td className='charge'>
                <label>
                    {checkValue(item.total_charge_amount)}
                </label>
            </td>
            <td className='refund'>
                <label>
                    {checkValue(item.total_refund_amount)}
                </label>
            </td>
            <td className='production_fee'>
                <label>
                    {checkValue(item.supplier_invoice.production_fee)}
                </label>
            </td>
            <td className='shipping_fee'>
                <label>
                    {checkValue(item.supplier_invoice.shipping_fee)}
                </label>
            </td>
            <td className='tax'>
                <label>
                    {checkValue(item.supplier_invoice.tax)}
                </label>
            </td>
            <td className='profit'>
                    {checkProfit(item.supplier_invoice)}
            </td>
        </tr>
    )
}

export default OrderProfitRow
