import React, {Component} from 'react'
import PropTypes from 'prop-types'
import {getListPersonalFilters, getCountFilter, hideQuickReport} from '../../../services/api/OrdersServices'
import {message, Spin} from "antd"
import {setFilterStorage} from "../../shared/ManageFiltersState"
import {getLocalData, setLocalData} from "../../../services/StorageServices"
import {DeleteOutlined, SyncOutlined} from "@ant-design/icons"
import {ConfirmModalV2} from "../../shared/ConfirmModalV2"

class OrderFilterPersonalized extends Component {
    constructor(props) {
        super(props)

        this.state = {
            loading: false,
            error: '',
            commonFilters: [],
            personalFilters: [],
            listTotal: []
        }
    }

    _fetchListFilter = async () => {
        this.setState({
            loading: true,
            error: ''
        })

        try {
            const response = await getListPersonalFilters('order')

            const {success, data, message: error} = response

            if (!success) {
                return this.setState({
                    loading: false,
                    error: error
                })
            }

            this.setState({
                loading: false,
                commonFilters: data.commonFilters,
                personalFilters: data.personalFilters
            })
        } catch (e) {
            this.setState({
                loading: false,
                error: e.message
            })
        }
    }

    _fetchDataTotalFilter = async () => {
        this.setState({
            loading: true,
            error: ''
        })

        try {
            const {commonFilters, personalFilters} = this.state
            let listFilter = []
            commonFilters.map(item => {
                return listFilter.push(item._id)
            })
            personalFilters.map(itemP => {
                return listFilter.push(itemP._id)
            })
            const response = await getCountFilter({
                listId: listFilter,
                type: 'order'
            })

            const {success, data, message: error} = response

            if (!success) {
                return this.setState({
                    loading: false,
                    error: error
                })
            }

            this.setState({
                loading: false,
                listTotal: data
            })
        } catch (e) {
            this.setState({
                loading: false,
                error: e.message
            })
        }
    }

    _changeFilterOrder = (item, canDelete) => {
        const {onChangeState, INIT_FILTERS} = this.props

        setLocalData('ordersPage.QuickReportItem', canDelete ? item : {}, false, 'sessionStorage')

        const keepInit = Object.assign({}, INIT_FILTERS)
        const newFilter = Object.assign(keepInit, item.payload)

        setFilterStorage('orders', newFilter)

        if (typeof onChangeState === 'function') {
            onChangeState(newFilter, true)
        }
    }

    _findTotal = (id) => {
        const {listTotal} = this.state
        const totalItem = listTotal.find(item => item._id === id)

        return totalItem !== undefined ? totalItem.total : ''
    }

    _handleDeleteFilter = async (item) => {
        const itemQuickCurrent = getLocalData('ordersPage.QuickReportItem', {}, false, 'sessionStorage')
        const currentItemId = (itemQuickCurrent && typeof itemQuickCurrent === 'object') ? itemQuickCurrent._id : ''
        this.setState({
            loadingDelete: item._id
        })
        const id = item._id
        try {
            const {message: error, success} = await hideQuickReport(id)
            if (!success) {
                this.setState({
                    loadingDelete: ''
                })
                return message.error(error)
            }

            if (currentItemId === id) {
                setLocalData('ordersPage.QuickReportItem', {}, false, 'sessionStorage')
            }
            this.setState({
                loadingDelete: ''
            })
            this._fetchListFilter()
            message.success('Delete filter successfully!')
        } catch (e) {
            this.setState({
                loadingDelete: ''
            })
            message.error(e.message)
        }
    }

    componentDidUpdate = async (prevProps, prevState, snapshot) => {
        if (prevProps.openQuickFilter !== this.props.openQuickFilter && this.props.openQuickFilter === true) {
            await this._fetchListFilter()
            await this._fetchDataTotalFilter()
        }
    }

    render() {
        const {loading, error, commonFilters, listTotal, personalFilters, loadingDelete} = this.state
        const {openQuickFilter, onToggleFilter} = this.props
        const itemQuickCurrent = getLocalData('ordersPage.QuickReportItem', {}, false, 'sessionStorage')
        const currentItemId = (itemQuickCurrent && typeof itemQuickCurrent === 'object') ? itemQuickCurrent._id : ''

        return (
            <div className={`OrderFilterPersonalized ${openQuickFilter ? 'Open' : 'Close'}`}>
                <div className="HeadingFilter">
                    <h3 className="Heading">
                        QUICK REPORT
                    </h3>
                    <div className="Close">
                        <i className="ti-close" onClick={onToggleFilter}/>
                    </div>
                </div>
                <div className="ContentFilter">
                    <Spin spinning={loading} tip="Get list filters...">
                        <ul>
                            {
                                commonFilters && commonFilters.length > 0 && commonFilters.map((item) => (
                                    <li key={item._id} onClick={() => this._changeFilterOrder(item, false)}
                                        className={`${currentItemId === item._id && 'active'}`}>
                                        {item.name} ({!!listTotal && listTotal.length > 0 && this._findTotal(item._id)})
                                    </li>
                                ))
                            }
                            {
                                personalFilters && personalFilters.length > 0 && personalFilters.map((itemP) => (
                                    <li key={itemP._id}
                                        className={`d-flex align-items-center justify-content-between ${currentItemId === itemP._id && 'active'}`}>
                                        <span onClick={() => this._changeFilterOrder(itemP, true)}>
                                            {itemP.name} ({!!listTotal && listTotal.length > 0 && this._findTotal(itemP._id)})
                                        </span>
                                        {
                                            loadingDelete === itemP._id ? <div className="IconDelete"
                                            >
                                                <SyncOutlined spin/>
                                            </div> : <div className="IconDelete"
                                                          id={`btnDeleteFilter-${itemP._id}`}
                                                          onClick={() => {
                                                              ConfirmModalV2({
                                                                  message: "Are you sure you want to delete?",
                                                                  type: "warning",
                                                                  id: `btnDeleteFilter-${itemP._id}`,
                                                                  placement: "bottom-end",
                                                                  onOk: () => {
                                                                      this._handleDeleteFilter(itemP)
                                                                  }
                                                              })
                                                          }}
                                            >
                                                <DeleteOutlined/>
                                            </div>
                                        }
                                    </li>
                                ))
                            }
                        </ul>

                        {!!error && error.length > 0 && <div className="text-danger">{error}</div>}
                    </Spin>
                </div>
            </div>
        )
    }
}

OrderFilterPersonalized.propTypes = {
    openQuickFilter: PropTypes.bool,
    onToggleFilter: PropTypes.func.isRequired
}

export default OrderFilterPersonalized
