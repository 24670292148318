import React, {Component} from 'react'
import PropTypes from 'prop-types'

class InputTemplate extends Component {
    _handleChangeField = field => e => {
        const {previews, onChangePreview} = this.props
        const {value} = e.target

        const updateAddress = Object.assign({}, previews, {
            [field]: value
        })

        if (typeof onChangePreview === 'function') {
            onChangePreview(updateAddress)
        }
    }

    _handleChangeFieldCod = field => e => {
        const {previews, onChangePreview} = this.props
        const {value} = e.target
        let updateAddress = Object.assign({}, previews)

        if (field !== 'province' && field !== 'district' && field !== 'ward') {
            updateAddress = {
                ...updateAddress,
                [field]: value,
                ghtk_info: {
                    ...updateAddress.ghtk_info,
                    [field]: value
                }
            }
        } else {
            updateAddress = {
                ...updateAddress,
                ghtk_info: {
                    ...updateAddress.ghtk_info,
                    [field]: value
                }
            }
        }

        if (typeof onChangePreview === 'function') {
            onChangePreview(updateAddress)
        }
    }

    render() {
        const {value, placeholder, field, type} = this.props

        return (
            <td>
                <input
                    className="form-control"
                    type="text"
                    value={value}
                    placeholder={placeholder}
                    onChange={type === 'cod' ? this._handleChangeFieldCod(field) : this._handleChangeField(field)}
                />
            </td>
        )
    }
}

InputTemplate.propTypes = {
    type: PropTypes.string.isRequired,
    previews: PropTypes.object,
    onChangePreview: PropTypes.func.isRequired,
    value: PropTypes.string,
    placeholder: PropTypes.string.isRequired,
    field: PropTypes.string.isRequired
}

export default InputTemplate
