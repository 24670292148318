import React, {Component} from 'react'
import PropTypes from 'prop-types'
import SuppliersTableRow from "./SuppliersTable/SuppliersTableRow"
import { getLocalData } from '../../../services/StorageServices'
import { Tooltip } from 'antd'

class SuppliersTable extends Component {
    _renderTableBody = () => {
        const {suppliers, refresh, ...rest} = this.props

        return suppliers.map(supplier => {
            return <SuppliersTableRow {...rest} key={supplier._id} supplier={supplier} refresh={refresh}/>
        })
    }

    render() {
        const permissionsData = !!getLocalData('permissions') ? Object.keys(getLocalData('permissions')) : []
        return (
            <div className='table-responsive'>
                <table className='table'>
                    <thead>
                    <tr>
                        <th>Name</th>
                        <th>Status</th>
                        <th>SKU</th>
                        <th><Tooltip title="Must have mockup when pushing orders?">Need Gen Mockup</Tooltip></th>
                        <th>Mapping Priority</th>
                        <th><Tooltip title="Max capacity per day">Max Mapping</Tooltip></th>
                        <th>Zone</th>
                        <th><Tooltip title="Available for receiving Tiktok orders?">Available Tiktok</Tooltip></th>
                        <th>Need charge have sleeves</th>
                        <th>Warehouse Name</th>
                        <th>Updated</th>
                        <th>Created</th>
                        {(permissionsData.includes('ffm_supplier_update') || permissionsData.includes('ffm_supplier_delete')) &&
                            <th>Actions</th>
                        }
                    </tr>
                    </thead>
                    <tbody>
                    {this._renderTableBody()}
                    </tbody>
                </table>
            </div>
        )
    }
}

SuppliersTable.propTypes = {
    suppliers: PropTypes.array.isRequired,
    refresh: PropTypes.func.isRequired
}

export default SuppliersTable
