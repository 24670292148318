import React, {useContext} from 'react'
import InputSearch from "./filters/InputSearch"
import InputArraySearch from "./filters/InputArraySearch"
import SelectSearch from "./filters/SelectSearch"
import {DISCOUNT_TYPE, DISCOUNT_STATUS} from "../helpers/DiscountFilters"
import OrderDiscountsContext from './../context/FulfillmentDiscountsContext'
import FilterDate from "../../shared/FilterDate"
import FilterDateSingle from "../../shared/FilterDateSingle"

const FulfillmentDiscountsFilters = () => {
    const {filters, setState} = useContext(OrderDiscountsContext)

    const _handleChangeDate = (paramFilter) => {
        const _filters = {
            ...filters,
            ...paramFilter
        }
        setState(_filters)
    }

    const _handleChangeInput = (name, value) => {
        const _filters = {
            ...filters,
            [name]: value
        }
        setState(_filters)
    }

    const _handleChangeSelect = (name, value) => {
        const _filters = {
            ...filters,
            [name]: value
        }
        setState(_filters)
    }

    const _handleChangeDateSingle = (name, value) => {
        const _filters = {
            ...filters,
            [name]: value
        }
        setState(_filters)
    }

    return (
        <div className="NotificationFilters OrdersSuplier listDiscounts">
            <div className="d-flex GroupFilter">
                <div className="itemFilter type mb-3">
                    <SelectSearch name="type" value={filters.type || ''} onChange={_handleChangeSelect} label="Type:"
                                  listSelect={DISCOUNT_TYPE}/>
                </div>
                <div className="itemFilter status mb-3">
                    <SelectSearch name="status" value={filters.status || ''} onChange={_handleChangeSelect}
                                  label="Status:" listSelect={DISCOUNT_STATUS}/>
                </div>
                <div className="itemFilter mb-3">
                    <InputSearch name="name" onChange={_handleChangeInput} label="Discount name:"
                                 value={filters.name || ''}/>
                </div>
                <div className="itemFilter mb-3">
                    <InputArraySearch name="namespaces" onChange={_handleChangeInput} label="Namespace:"
                                      value={filters.namespaces || []}/>
                </div>
                <div className="itemFilter filterPushedAt mb-3">
                    <FilterDateSingle
                        heading='Apply date:'
                        value={filters.apply_date}
                        onChangeDateSingle={_handleChangeDateSingle}
                    />
                </div>
                <div className="itemFilter filterPushedAt mb-3">
                    <FilterDate
                        heading='Updated at:'
                        field='updated_at'
                        value={filters.updated_at || {}}
                        onDatesChange={_handleChangeDate}
                    />
                </div>
            </div>
        </div>
    )
}

export default FulfillmentDiscountsFilters
