import React from 'react'
import ReportOvertimeProduction from './ReportOvertimeProduction'
import ReportProductionTime from './ReportProductionTime'

function ProductionReport(props) {
    const {suppliers} = props
    return (
        <div className="ShipmentReports">
            <div className="mb-5">
                <ReportProductionTime suppliers={suppliers}/>
            </div>
        </div>
    )
}

export default ProductionReport
