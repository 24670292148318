import React, {Component} from 'react'
import PropTypes from 'prop-types'
import {getListShippingPlans} from "../../../services/api/ShippingServices"

import {Select} from "antd"

const {Option} = Select

class ShippingPlansSelector extends Component {
    state = {
        options: [],
        err: '',
        page: 1,
        limit: -1,
        input: '',
        message: ''
    }

    componentDidMount() {
        this._getCategories()
    }

    _getCategories = async () => {
        return new Promise(async resolve => {

            try {
                const {success, data, message} = await getListShippingPlans()
                if (!success) {
                    return this.setState({
                        err: message
                    })
                }
                this.setState(
                    () => ({
                        options: data.map((item, index) => {
                            return {
                                id: item._id,
                                value: index,
                                label: item.title
                            }
                        })
                    }),
                    () => resolve(this.state.options)
                )
            } catch (e) {
                this.setState({
                    err: e.message || e
                })
            }
        })
    }


    _handleChange = option => {
        const _selectedShipPlan = (option !== undefined) ? option : ''
        this.props.handleChangeShippingPlan(_selectedShipPlan)
    }

    render() {
        const {options, message, err} = this.state
        const {shippingPlan, width, placeholder} = this.props
        return (
            <div className="ShippingPlansSelector w-auto">
                <Select
                    size={'large'}
                    allowClear
                    showArrow
                    defaultValue={shippingPlan}
                    value={shippingPlan || []}
                    style={{width}}
                    onChange={this._handleChange}
                    placeholder={placeholder? placeholder : 'Select'}
                >
                    {
                        options.map(item => {
                            return <Option value={item.id} key={item.id}>{item.label}</Option>
                        })
                    }
                </Select>
                {err && (
                    <p className='text-danger'>{err}</p>
                )}
                {message &&
                <p className='text-primary'>{message}</p>
                }
            </div>
        )
    }
}

ShippingPlansSelector.propTypes = {
    handleChangeShippingPlan: PropTypes.func.isRequired,
}

export default ShippingPlansSelector
