import React, {Component} from 'react'
import ProductLineCategory from "./ProductLineCategories/ProductLineCategory"
import classNames from 'classnames'

class ProductLineCategories extends Component {

    _handleClickAllCategory = () => {
        const {_handUpdateState, filter} = this.props
        _handUpdateState("filter", {
            ...filter,
            term: "",
            category: "",
        })
    }

    render() {
        const {_handUpdateState, categoryId, filter, categories} = this.props

        return (
            categories.length > 0 &&
            <div className="ProductLineCategories mb-3">
                <ul className="ListCategories nav nav-tabs">
                    <li className="ProductLineCategory nav-item cursor-pointer" onClick={this._handleClickAllCategory}>
                        <span className={classNames("nav-link", {active: categoryId === ""})}>All</span>
                    </li>
                    {
                        categories.map(category => {
                            return <ProductLineCategory key={category._id} category={category}
                                                        _handUpdateState={_handUpdateState} categoryId={categoryId} filter={filter}/>
                        })
                    }
                </ul>
            </div>
        )
    }
}

export default ProductLineCategories
