import React, {useState, useEffect} from 'react'
import PropTypes from 'prop-types'
import {Button, PopoverBody, PopoverHeader, UncontrolledPopover} from "reactstrap"
import ObjectInspector from "react-inspector"
import {Pagination, Spin} from "antd"
import { searchOrderHistories } from '../../../services/api/OrdersServices'
import moment from 'moment'

MappingVariantHistory.propTypes = {
    catalogId: PropTypes.string
}

function MappingVariantHistory(props) {
    const {catalogId} = props
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState('')
    const [listHistories, setListHistories] = useState([])
    const [page, setPage] = useState(1)
    const [limit, setLimit] = useState(100)
    const [total, setTotal] = useState(0)

    const _handlePageChange = (page, pageSize) => {
        setLimit(pageSize)
        setPage(pageSize !== limit ? 1 : page)
    }

    const _fetchListShippingHistory = async () => {
        setLoading(true)
        setError('')
        setListHistories([])

        try {
            const payload = {
                object_type: 'CatalogProductVariant',
                object_id: catalogId,
                page: page,
                limit: limit
            }
            const {data, success, message: error} = await searchOrderHistories(payload)

            if (!success) {
                setLoading(false)

                return setError(error)
            }

            const {histories, total} = data
            setLoading(false)
            setListHistories(histories)
            setTotal(total)
        } catch (e) {
            setLoading(false)
            setError(e.message)
        }
    }

    useEffect(() => {
        _fetchListShippingHistory()
    }, [page, limit])

    return (
        <div className='ShippingHistory'>
            <h3>History</h3>

            <Spin spinning={loading} tip="Đang lấy dữ liệu...">
                <table className="table">
                    <thead>
                    <tr>
                        <th className="colID">#</th>
                        <th className="colTime">Time</th>
                        <th className="colDescription">Description</th>
                        <th className="colUser">Người tạo</th>
                        <th className="colIP">IP</th>
                        <th className="colMeta">Key</th>
                    </tr>
                    </thead>
                    <tbody>
                    {
                        (listHistories && listHistories.length > 0) ? listHistories.map((history, index) => {
                                const {_id, meta, created_at, description, user, event} = history
                                const {IP} = meta

                                return (
                                    <tr key={_id}>
                                        <td className="colID">{index + 1}</td>
                                        <td className="colTime">{created_at ? moment(created_at).format('HH:mm DD/MM/YYYY') : '-'}</td>
                                        <td className="colDescription">{description || ''}</td>
                                        <td className="colUser">{user || ''}</td>
                                        <td className="colIP">{IP || '--'}</td>
                                        <td className="colMeta">
                                            <Button id={`mypopover_${event}_${_id}`} color="link">
                                                <code>{event}</code>
                                            </Button>
                                            <UncontrolledPopover target={`mypopover_${event}_${_id}`} trigger="legacy">
                                                <PopoverHeader><code>{event}</code></PopoverHeader>
                                                <PopoverBody>
                                                    <ObjectInspector depth={0} path="root.value"
                                                                     data={meta !== undefined ? (Object.keys(meta).length === 0 ? '--' : meta) : '--'}/>
                                                </PopoverBody>
                                            </UncontrolledPopover>
                                        </td>
                                    </tr>
                                )
                            }) :
                            <tr>
                                <td colSpan={6}>Không có lịch sử</td>
                            </tr>
                    }

                    {
                        error && error.length > 0 && <tr>
                            <td colSpan={6} className="text-danger">{error}</td>
                        </tr>
                    }
                    </tbody>
                </table>

                {
                    (listHistories && listHistories.length > 0) &&
                    <div className="OrdersPagination d-flex justify-content-end Pagination">
                        <Pagination
                            current={page}
                            total={total}
                            pageSize={limit}
                            onChange={_handlePageChange}
                            className='text-right'
                            showSizeChanger
                            pageSizeOptions={['10', '20', '50', '100']}
                        />
                    </div>
                }
            </Spin>
        </div>
    )
}

export default MappingVariantHistory
