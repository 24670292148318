import React, {Component} from 'react'
import PropTypes from 'prop-types'
import {Modal, ModalHeader, ModalBody} from 'reactstrap'

class ApiKeyResultModal extends Component {
    state = {copied: false}

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (!prevProps.open && this.props.open) this.setState({
            copied: false,
        })
    }

    _copyApiKey = () => {
        const textField = document.createElement('textarea')
        textField.innerText = this.props.apiKey
        document.body.appendChild(textField)
        textField.select()
        document.execCommand('copy')
        textField.remove()
        this.setState({copied: true}, () => {
            setTimeout(() => this.setState({copied: false}), 1000)
        })
    }

    render() {
        const {apiKey, onClose, open} = this.props
        const {copied} = this.state
        const buttonText = copied ? 'COPIED!' : 'COPY'

        return (
            <Modal
                isOpen={open}
                toggle={onClose}
                className="ApiKeyResultModal">
                <ModalHeader toggle={onClose}>
                    API Key created
                </ModalHeader>
                <ModalBody>
                    <div className="ContentWrapper">
                        <div className="Text">
                            Please copy this key and save it somewhere safe.
                        </div>
                        <div className="Text text-danger">
                            For security reasons, we cannot show it you again.
                        </div>
                        <input className="ApiKeyWrapper" value={apiKey} readOnly/>
                        <button className="btn Button" onClick={this._copyApiKey}>{buttonText}</button>
                    </div>
                </ModalBody>
            </Modal>
        )
    }
}

ApiKeyResultModal.propTypes = {
    apiKey: PropTypes.string.isRequired,
    onClose: PropTypes.func.isRequired,
    open: PropTypes.bool.isRequired,
}

export default ApiKeyResultModal
