import React, { useState, useRef } from 'react'
import { Switch, message, Tooltip, Modal } from 'antd'
import { updateFastProductionRulesStatus, deleteFastProductionRule } from '../../../../services/api/FastProductionRulesServices'
import moment from 'moment'
import { Link } from 'react-router-dom'

function FastProductionRulesRow(props) {
    const { item = {}, refresh, permissionsData } = { ...props }
    const {
        _id, store = [], product_types = [], priority,
        supplier = {}, created_at, updated_at, country = []
    } = { ...item }
    const [status, setStatus] = useState(item.status)
    const [showAllProductType, setShowAllProductType] = useState(false)
    const showallonclick = () => {
        setShowAllProductType(!showAllProductType)
    }

    const _handleChangeStatus = async (checked) => {
        setStatus(checked)
        try {
            const STATUS = checked ? 'active' : 'inactive'
            const response = await updateFastProductionRulesStatus(_id, STATUS)

            const { success, message: error } = response

            if (!success) {
                throw new Error(error)
            } else {
                message.success(`Fast Production Rule is ${STATUS}.`)
                setStatus(checked)
            }
            refresh()
        } catch (e) {
            setStatus(item.status)
            message.error(e.message || 'Unknown error.')
        }
    }

    const _handleDelete = async (id) => {
        try {
            const res = await deleteFastProductionRule(id)

            const { message: mess, success } = res
            if (!success) {
                throw new Error(mess)
            }
            message.success('Deleted successfully!')
            refresh()
        } catch (e) {
            message.error(e.message || 'Unknown error.')
        }
    }

    const handleClickDelete = (e, id) => {
        e.preventDefault()
        Modal.confirm({
            title: <span className='text-danger fs-14'>Confirm Delete</span>,
            icon: '',
            content: 'Are you sure you want to delete?',
            okText: 'Ok',
            cancelText: 'Cancel',
            onOk: () => _handleDelete(id)
        })

    }

    const canEdit = permissionsData.includes('ffm_fast_production_rule_update')
    const canDelete = permissionsData.includes('ffm_fast_production_rule_delete')
    const canViewDetails = permissionsData.includes('ffm_fast_production_rule_detail')
    const borderRight = (canEdit || canViewDetails) && canDelete ? 'border-right' : ''
    const productTypesRef = useRef()

    return (
        <tr>
            <td>{priority || '-'}</td>
            <td>
                {store.length > 0 ? store.join(', ') : 'All'}
            </td>
            <td>
                <div className={showAllProductType === false ? "HiddenProductTypes" : ""}>
                    <div ref={productTypesRef} className='text-break'>
                        {product_types.length > 0 ? product_types.join(', ') : 'All'}
                    </div>
                </div>
                {
                    !!productTypesRef.current ? (
                        (productTypesRef.current.offsetHeight > 98 && showAllProductType === false) ?
                            <a className="float-right mt-2 text-primary">
                                <small className="float-right" onClick={showallonclick}>More...</small>
                            </a> :
                            (productTypesRef.current.offsetHeight > 98 && showAllProductType === true) ?
                                <a className="float-right mt-2 text-primary">
                                    <small className="float-right" onClick={showallonclick}>Less...</small>
                                </a> : ""
                    ) : ""
                }
            </td>
            <td>
                {country.length > 0 ? country.join(', ') : '-'}
            </td>
            <td>{supplier.name || '-'}</td>
            <td>
                <p className='mb-0'>
                    {created_at ? moment(created_at).format('HH:mm DD/MM/YYYY') : '-'}
                </p>
                <p className='mb-0'>
                    {updated_at ? moment(updated_at).format('HH:mm DD/MM/YYYY') : '-'}
                </p>
            </td>
            <td>
                <Tooltip title='Change status'>
                    <Switch
                        checked={status}
                        onChange={_handleChangeStatus}
                        disabled={!canEdit}
                    />
                </Tooltip>
            </td>
            {(canEdit || canViewDetails || canDelete) &&
                <td>
                    {(canEdit || canViewDetails) &&
                        <Tooltip title={canEdit ? 'Edit rule' : (canViewDetails ? 'Rule details' : '')}>
                            <Link to={`/a/settings/fast-production/${_id}`} className={`text-primary ${borderRight} px-1 cursor-pointer`}>
                                {canEdit ? 'Edit' : (canViewDetails ? 'Detail' : '')}
                            </Link>
                        </Tooltip>
                    }
                    {canDelete &&
                        <Tooltip title='Delete rule'>
                            <span className="text-danger mx-1 cursor-pointer"
                                onClick={(e) => handleClickDelete(e, _id)}
                            >Delete</span>
                        </Tooltip>
                    }
                </td>
            }
        </tr>
    )
}

export default FastProductionRulesRow