export default [
    'fba',
    'FBA',
    'gift',
    'cutoff',
    'fastship',
    'priority-store',
    'auto-approved',
    'updated-design',
    'force-updated-design',
    'waiting-update-design',
    'deleted-update-design',
]