import React, {Component} from 'react'
import OrderTagItem from "./OrderTagItem"
import PropTypes from 'prop-types'

class OrderTagsList extends Component {
    render() {
        const {tags, _handleRemoveOrderTag} = this.props

        return (
            tags.length > 0 &&
            <div className="OrderTagsList">
                <ul className="ListTags">
                    {
                        tags.map((tag, index) => {
                            if (tag !== 'thank_card' && tag !== 'thank_you_card') {
                                return <OrderTagItem key={index} tag={tag}
                                                     _handleRemoveOrderTag={_handleRemoveOrderTag}/>
                            }

                            return null
                        })
                    }
                </ul>
            </div>
        )
    }
}

OrderTagsList.propTypes = {
    tags: PropTypes.array,
    _handleRemoveOrderTag: PropTypes.func.isRequired,
}

export default OrderTagsList
