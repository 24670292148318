import createApiServices from "./createApiServices"
import { getRefreshToken } from "../AuthServices"

const prefixURL = process.env.NODE_ENV === 'production' ? '/api/user' : 'https://fulfillment-staging.merchize.com/api/user'
const authPrefixURL = process.env.NODE_ENV === 'production' ? '/api/auth' : 'https://fulfillment-staging.merchize.com/api/auth'
const api = createApiServices({baseUrl: prefixURL})
const apiAuth = createApiServices({baseUrl: authPrefixURL})
const devUrl = window['API_USER_SERVICE_URL'] || ''
export const userSignIn = (data) => {
    return api.makeRequest({
        url: `${devUrl}/users/login`,
        method: 'POST',
        data,
    })
}
export const userSignInAuth = (data) => {
    return apiAuth.makeRequest({
        url: '/auth/login',
        method: 'POST',
        data,
    })
}
export const createUser = (data) => {
    return api.makeAuthRequest({
        url: `/users`,
        method: 'POST',
        data,
    })
}
export const createUserAuth = (data) => {
    return apiAuth.makeAuthRequest({
        url: `/v2/users/create`,
        method: 'POST',
        data,
    })
}
export const userChangePassword = (userId, data) => {
    return api.makeAuthRequest({
        url: `/users/${userId}/change-password`,
        method: 'POST',
        data,
    })
}
export const searchUser = (data) => {
    return api.makeAuthRequest({
        url: '/users/search',
        method: 'POST',
        data,
    })
}

export const searchUserAuth = (query,data) => {
    return apiAuth.makeAuthRequest({
        url: `v2/users/list?${query}`,
        method: 'POST',
        data,
    })
}

export const editUserAuth = (userId, data) => {
    return apiAuth.makeAuthRequest({
        url: `/v2/users/${userId}`,
        method: 'PUT',
        data,
    })
}

export const getUserAth = (userId) => {
    return apiAuth.makeAuthRequest({
        url: `/users/${userId}`,
        method: 'GET',
    })
}

export const getUser = (userId) => {
    return api.makeAuthRequest({
        url: `/users/${userId}`,
        method: 'GET',
    })
}

export const deleteUser = (userId) => {
    return api.makeAuthRequest({
        url: `/users/${userId}`,
        method: 'DELETE',
    })
}

export const deleteUserAuth = (userId) => {
    return apiAuth.makeAuthRequest({
        url: `v2/users/${userId}`,
        method: 'DELETE',
    })
}
export const getRoleList = (payload) => {
    return apiAuth.makeAuthRequest({
        url: 'v2/roles',
        method: 'GET',
        params: payload
    })
}

export const createRole = (payload) => {
    return apiAuth.makeAuthRequest({
        url: 'v2/roles',
        method: 'POST',
        data: payload
    })
}

export const roleDetails = (id) => {
    return apiAuth.makeAuthRequest({
        url: `v2/roles/${id}`,
        method: 'GET',
    })
}

export const editRole = (payload, id) => {
    return apiAuth.makeAuthRequest({
        url: `v2/roles/${id}`,
        method: 'PUT',
        data: payload,
    })
}

export const getPermissionList = (payload) => {
    return apiAuth.makeAuthRequest({
        url: 'v2/permissions',
        method: 'GET',
        params: payload
    })
}

export const getDepartmentList = (payload) => {
    return apiAuth.makeAuthRequest({
        url: `/departments`,
        method: 'GET',
        params: payload,
    })
}

export const refreshToken = (data) => {
    return apiAuth.makeAuthRequest({
        url: '/auth/refresh-token',
        method: 'POST',
        data: {
            refreshToken: getRefreshToken(),
        },
    })
}