import React from 'react'
import PropTypes from 'prop-types'
import {Spin} from "antd"
import ProductLineHead from "./ProductLineHead"
import ProductLineRow from "./ProductLineRow"

ListProductLine.propTypes = {
    loading: PropTypes.bool,
    error: PropTypes.string,
    productLineList: PropTypes.array,
    selectAll: PropTypes.bool,
    active: PropTypes.string,
    listSelected: PropTypes.array,
    listSelectedVariant: PropTypes.array,
    onSelectAll: PropTypes.func.isRequired,
    onUpdateSelectedProduct: PropTypes.func.isRequired,
    onSetVariants: PropTypes.func.isRequired,
    onUpdateSelectedVariants: PropTypes.func.isRequired,
    onChangeActive: PropTypes.func.isRequired
}

function ListProductLine(props) {
    const {
        loading,
        error,
        productLineList,
        selectAll,
        active,
        listSelected,
        listSelectedVariant,
        onSelectAll,
        onUpdateSelectedProduct,
        onSetVariants,
        onUpdateSelectedVariants,
        onChangeActive
    } = props

    return (
        <div className="ListProductLine">
            <Spin spinning={loading}>
                <table>
                    <ProductLineHead selectAll={selectAll} onSelectAll={onSelectAll}/>

                    <tbody>
                    {
                        !!error && error.length > 0 && <tr>
                            <td colSpan={5} className="text-danger">{error}</td>
                        </tr>
                    }
                    {
                        !!productLineList && productLineList.length > 0 && productLineList.map(item => (
                            <ProductLineRow
                                key={item._id}
                                active={active}
                                item={item}
                                listSelected={listSelected}
                                listSelectedVariant={listSelectedVariant}
                                onUpdateSelectedProduct={onUpdateSelectedProduct}
                                onSetVariants={onSetVariants}
                                onChangeActive={onChangeActive}
                                onUpdateSelectedVariants={onUpdateSelectedVariants}
                            />
                        ))
                    }
                    </tbody>
                </table>
            </Spin>
        </div>
    )
}

export default ListProductLine