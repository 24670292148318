import React, {useContext, useState} from 'react'
import {message} from "antd"
import {
    RedoOutlined,
    SyncOutlined
} from '@ant-design/icons'
import {sendNotification} from "../../../../../services/api/NotificationServices"
import NotificationContext from './../../../context/NotificationContext'

const SendStatus = (props) => {
    const {notification} = props
    const {reLoadNotications} = useContext(NotificationContext)
    const [loading, _setLoading] = useState(false)

    const _generateStatus = (status) => {
        switch (status) {
            case "sent":
                return <div className="text-success">Sent {notification.sent_time > 1 && `(${notification.sent_time})`}</div>
            case "failed":
                return <div>
                    <span className="text-danger mr-1">Failed</span>
                    {loading ? (
                        <span><SyncOutlined spin /></span>
                    ) : (
                        <span onClick={_actionSend} className="text-success cursor-pointer"><RedoOutlined /></span>
                    )}
                </div>
            default:
                return <div className="status"/>
        }
    }

    const _actionSend = async () => {
        try {
            _setLoading(true)

            const {data, success: _success} = await sendNotification(notification._id)

            if(!_success) {
                _setLoading(false)
                return message.error('Send error')
            }

            const {send_status, send_note} = data

            if(send_status === 'failed') {
                _setLoading(false)
                return message.error(send_note)
            }

            message.success('Send success')

            _setLoading(false)
            reLoadNotications()
        } catch (e) {
            _setLoading(false)
            message.error(e.message)
        }
    }

    return (
        <td className="status">{_generateStatus(notification.send_status)}</td>
    )
}

export default SendStatus
