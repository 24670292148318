import React from 'react'
import {Checkbox} from "antd"
import UploadStatus from "./column/UploadStatus"
import SendStatus from "./column/SendStatus"

const TableRow = (props) => {
    const {notification, selectedItems, updateSelectedOrders, loadingSend} = props

    const _detectChecked = () => {
        return selectedItems.includes(notification._id)
    }

    const _handleChange = () => {
        const isChecked = _detectChecked()

        updateSelectedOrders(notification._id, !isChecked)
    }

    const isChecked = _detectChecked()

    return (
        <tr>
            <td className='OrderCheckbox'>
                <div className='custom-control custom-checkbox'>
                    <Checkbox disabled={notification.upload_status !== 'done' || loadingSend} onChange={_handleChange} checked={isChecked}/>
                </div>
            </td>
            <td className="storeId">{notification.store_id}</td>
            <td className="title">{notification.title}</td>
            <td className="orderNumber">{notification.order_number}</td>
            <td className="confirm">{notification.confirm}</td>
            <td className="image">{notification.image ? <a rel="noopener noreferrer" target="_blank" href={notification.image}>Image Link</a> : ''}</td>
            <UploadStatus notification={notification}/>
            <SendStatus notification={notification}/>
        </tr>
    )
}

export default TableRow
