import React, {Component} from 'react'

class ShippingPlanIndex extends Component {
    render() {
        const {index} = this.props
        return (
            <td className="ShippingPlanIndex">
                {index}
            </td>
        )
    }
}

export default ShippingPlanIndex
