import React, {Component} from 'react'
import PropTypes from 'prop-types'
import {Button, Modal} from 'antd'
import requestCancelJob from '../../actions/requestCancelJob'
import requestRunJob from "../../actions/requestRunJob"

class WebhookActions extends Component {

    _handleRunClick = async () => {
        const {job, onFetchWebhook} = this.props
        const {_id, order} = job

        Modal.confirm({
            title: 'Are you sure want to run this job?',
            content: <div>Order code: <strong>{order.id}</strong></div>,
            onOk() {
                return requestRunJob(_id, onFetchWebhook)
            },
        })
    }

    _handleCancelClick = () => {
        const {job, onFetchWebhook} = this.props
        const {_id, order} = job

        Modal.confirm({
            title: 'Are you sure want to cancel this job?',
            content: <div>Order code: <strong>{order.id}</strong></div>,
            onOk() {
                return requestCancelJob(_id, order.id, onFetchWebhook)
            },
        })
    }

    render() {
        const {job, permissionsData} = this.props
        const {is_sent, status} = job
        
        return (
            permissionsData.includes('ffm_webhook_job_run') && permissionsData.includes('ffm_webhook_job_cancel') &&
            <td className="WebhookActions">
                <div className="d-flex flex-row">
                    {
                        !is_sent &&
                        <Button onClick={this._handleRunClick} type='primary'>Run</Button>
                    }

                    {
                        !is_sent && 'cancelled' !== status &&
                        <Button onClick={this._handleCancelClick} type='danger' className='ml-3'>Cancel</Button>
                    }
                </div>
            </td>
        )
    }
}

WebhookActions.propTypes = {
    webhook: PropTypes.object.isRequired,
    onFetchWebhook: PropTypes.func.isRequired,
}

export default WebhookActions
