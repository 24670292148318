import React, { useState } from 'react'
import { Button, Modal, Input, message as Message, Progress } from "antd"
import { updateMappingCondition } from "../../../../services/api/MappingConditionServices"
import { ExclamationCircleOutlined, CheckCircleOutlined } from '@ant-design/icons'


const BulkChangeUser = ({ visible, onCancel, listSelecteds, reload, listMappingCondition, onChangeMapper }) => {

    const [loading, setLoading] = useState(false)
    const [error, setError] = useState('')
    const [user, setUser] = useState('')
    const [listSuccess, setListSuccess] = useState([])
    const [listError, setListError] = useState([])
    const [listProcessing, setListProcessing] = useState(listMappingCondition.filter(item => listSelecteds.includes(item._id)))

    const _onChangeUser = async (id) => {
        setLoading(true)
        try {
            const { success, message } = await updateMappingCondition(id, { user })

            if (!success) {
                return { success: success, message: message }
            }
            return { success: success }

        } catch (e) {
            return { success: false, message: e.message }
        } finally {
            setLoading(false)
        }
    }
    const _onSubmitChange = async () => {

        setListSuccess([])
        setListError([])
        setListProcessing(listProcessing.map(item => {
            if (item.success) delete item.success
            if (item.error) delete item.error
            return item
        }))

        let processed = []

        if (!user || !user.trim()) return setError('User is required!')
        await Promise.all(listProcessing.map(async item => {
            const response = await _onChangeUser(item._id)

            const { success, message } = response

            if (!success) return processed.push({ ...item, error: message, processing: false })
            onChangeMapper(item._id, user)
            return processed.push({ ...item, success: true, processing: false })

        }))

        let newListSuccess = []
        let newListError = []

        processed.map(process => {
            if (process.success === true) {
                return newListSuccess.push(process)
            }

            if (process.error.length > 0) {
                return newListError.push(process)
            }
        })

        setListSuccess(newListSuccess)
        setListError(newListError)
        return setListProcessing(processed)
    }

    const _hanldeChangeUser = e => {
        setError('')
        const { value } = e.target
        setUser(value)
    }
    
    return (
        <Modal
            className="BulkChangeUser"
            visible={visible}
            title="Change user"
            onOk={_onSubmitChange}
            onCancel={onCancel}
            footer={[
                <Button key="back" disabled={loading} onClick={onCancel}>
                    Cancel
                </Button>,
                <Button key="submit" disabled={loading} type="primary" loading={loading} onClick={_onSubmitChange}>
                    Save
                </Button>
            ]}
        >
            <div className="FormItem">
                <label className='font-weight-bold' htmlFor="user">User</label>
                <Input placeholder="Enter your user" id="user" onChange={_hanldeChangeUser} value={user} />
                {error && <span className='text-danger'>{error}</span>}
                <h6>List processing <span>{listSelecteds.length}</span> items</h6>
                {
                    (listSuccess.length > 0 || listError.length > 0) &&
                    <div className="Results d-flex align-items-center">
                        <div className="ResultSuccess mr-2">
                            Success: <span className='text-success font-weight-bold'>{listSuccess.length}</span> items
                        </div>
                        <div className="ResultError">
                            Error: <span className='text-danger font-weight-bold'>{listError.length}</span> items
                        </div>
                    </div>
                }
                <div className="ListProcessing">
                    <ul>
                        {
                            !!listProcessing && listProcessing.length > 0 && listProcessing.map(item => (
                                <li key={item._id}>
                                    <span className='mr-2 font-weight-bold'>{item.store}</span>
                                    {
                                        !!item.error &&
                                        <span className="Error text-danger"><ExclamationCircleOutlined /> {item.error}</span>
                                    }
                                    {
                                        item.success &&
                                        <span className="Success text-success"><CheckCircleOutlined /> Change success</span>
                                    }
                                    <Progress
                                        percent={100}
                                        size="small"
                                        status={`${(item.processing === false && item.success === true) ? 'success' : ((item.processing === false && !!item.error) ? 'exception' : 'active')}`}
                                        showInfo={false}
                                    />
                                </li>
                            )
                            )
                        }
                    </ul>
                </div>
            </div>

        </Modal>
    )
}

export default BulkChangeUser