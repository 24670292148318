import React from 'react'
import PropTypes from 'prop-types'
import {Menu} from 'antd'
import {bulkCancelJobs, bulkRunJobs} from '../actions/bulkJobActions'

function MenuBulkJobActions(props) {
    const _runJobs = () => {
        const {selectedItems, onFetchWebhook} = props

        return bulkRunJobs(selectedItems, onFetchWebhook)
    }

    const _cancelJobs = () => {
        const {selectedItems, onFetchWebhook} = props

        return bulkCancelJobs(selectedItems, onFetchWebhook)
    }

    return (
        <Menu className='BulkJobsMenu'>
            <Menu.Item key='run_webhook_jobs' onClick={_runJobs}>Run webhook jobs</Menu.Item>
            <Menu.Item key='cancel_webhook_jobs' disabled={props.filterStatus === 'cancelled'} onClick={_cancelJobs}>Cancel
                webhook jobs</Menu.Item>
        </Menu>
    )
}

MenuBulkJobActions.propTypes = {
    selectedItems: PropTypes.array.isRequired,
    filterStatus: PropTypes.string.isRequired,
    onFetchWebhook: PropTypes.func.isRequired,
}

export default MenuBulkJobActions
