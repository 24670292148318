import React from 'react'
import { Select } from 'antd'

const VariantTable = (props) => {

    const {
        newVariant, handleChangeVariants, handlePaste, filter,
        first_item_price, additional_item_price, listAtt, handleChangeFilter,
    } = props

    const handleChangeFirstItemPrice = (id) => (e) => {
        const {value} = e.target
        if (value === null || value === '' || value === undefined) return handleChangeVariants(id, 'first_item_price', "0")
        handleChangeVariants(id, 'first_item_price', value)
    }

    const handleChangeAddItemPrice = (id) => (e) => {
        const {value} = e.target
        if (value == null || value === '' || value === undefined) return handleChangeVariants(id, 'additional_item_price', "0")
        handleChangeVariants(id, 'additional_item_price', value)
    }

    const _handlePaste = (field, index) => (e) => {
        handlePaste(e, field, index)
    }

    const onChangeAttFilter = attributeId => value =>{
        if (typeof handleChangeFilter === 'function') {
            handleChangeFilter({
                ...filter,
                [attributeId]: value
            })
        }
    }   

    const variant_render = newVariant.filter(v=>{
        const {options} = v

        for( let option of options){
            const {attribute, name} = option
            if(filter[attribute] && filter[attribute] !== name) return false

        }
        return true
    })

    return (
        <div className="VariantTable py-5">
            <div className="wrapTable">
                <table>
                    <thead className="VariantHead">
                        <tr>
                            {listAtt.length > 0 && listAtt.map((item, index) => (
                                <th key={index}>
                                    <Select
                                        value={filter[item._id] || ''}
                                        className="itemAtt"
                                        onChange={onChangeAttFilter(item._id)}
                                    >
                                        <Select.Option value="">{!!item.name ? item.name : ""}</Select.Option>
                                        {
                                            item.values.map(value => {
                                                const {name: _name} = value
                                                return (
                                                <Select.Option value={_name} key={_name}>{_name}</Select.Option>
                                                )
                                            })
                                        }
                                    </Select>
                                </th>
                            ))}
                            <th className="FirstItem">First item</th>
                            <th className="AdditionalItem">Additional item</th>
                        </tr>
                    </thead>
                    <tbody className="py-3">
                        {
                            variant_render.length > 0 && variant_render.map((item, index) => (
                                <tr key={index} className="border-top">
                                    {
                                        listAtt.map((att, idx) => {
                                            const { options = [] } = item
                                            const attValue = options.find(option => option.attribute === att._id) || ""
                                            return <td key={idx}>{attValue.name}</td>
                                        })
                                    }
                                    <td className="py-2">
                                        <input type="text" className="form-control" value={(item.first_item_price !== '' && item.first_item_price !== null && item.first_item_price !== undefined) ? item.first_item_price : first_item_price}
                                               onChange={handleChangeFirstItemPrice(item._id)} onPaste={_handlePaste('first_item_price', index)}/>
                                    </td>
                                    <td>
                                        <input type="text" className="form-control" value={(item.additional_item_price !== '' && item.additional_item_price !== null && item.additional_item_price !== undefined) ? item.additional_item_price : additional_item_price}
                                               onChange={handleChangeAddItemPrice(item._id)} onPaste={_handlePaste('additional_item_price', index)}/>
                                    </td>
                                </tr>
                            ))
                        }
                    </tbody>
                </table>
            </div>
        </div>
    )
}

export default VariantTable