import React from 'react'
import ShippingFeePage from "./ShippingFeePage"
import DocTitleByStore from "../../dashboard/shared/DocTitleByStore"

const ShippingFeePageContainer = () => {
    return (
        <div className="ShippingFeePageContainer">
            <DocTitleByStore title="Shipping Fee" />
            <ShippingFeePage />
        </div>
    )
}

export default ShippingFeePageContainer