import React, {Component} from 'react'
import ReactPaginate from 'react-paginate'
import PropTypes from 'prop-types'

class SuppliersPagination extends Component {
    _handlePageChange = (data) => {
        const {selected} = data

        this.props.onChangeState('page', selected + 1)
    }

    render() {
        const {pages, page} = this.props

        return (
            <div className="ArtworksPagination">
                <ReactPaginate
                    previousLabel="Previous"
                    nextLabel="Next"
                    pageCount={pages}
                    forcePage={page - 1}
                    marginPagesDisplayed={2}
                    pageRangeDisplayed={5}
                    onPageChange={this._handlePageChange}
                    containerClassName="pagination"
                    pageClassName='page-item'
                    previousClassName='Previous page-item'
                    nextClassName='Next page-item'
                    pageLinkClassName='page-link'
                    previousLinkClassName='page-link'
                    nextLinkClassName='page-link'
                    subContainerClassName="pages pagination"
                    activeClassName='active'
                />
            </div>
        )
    }
}

SuppliersPagination.propTypes = {
    page: PropTypes.number.isRequired,
    pages: PropTypes.number.isRequired,
    onChangeState: PropTypes.func.isRequired
}

export default SuppliersPagination
