import React, {Component} from 'react'
import PropTypes from 'prop-types'
import FilterOptions from '../../../shared/FilterOptions'
import SuppliersContext from '../../context/SuppliersContext'

class Supplier extends Component {
    render() {
        const {suppliers, onChangeFilterState} = this.props
        const _listSuppliers = this.props.options.map(option => ({name: option.name, value: option._id}))

        return (
            <div className="Supplier">
                <label htmlFor="" className="font-weight-500 mb-1">Suppliers:</label>
                <FilterOptions 
                    selectedKey={suppliers} 
                    onChangeOption={onChangeFilterState} 
                    field='suppliers'
                    options={_listSuppliers} 
                    defaultOption={[]}
                    layout='select' 
                    mode='multiple'
                    showSearch={true}
                />
            </div>
        )
    }
}

Supplier.propTypes = {
    onChangeFilterState: PropTypes.func.isRequired,
    suppliers: PropTypes.array.isRequired,
    options: PropTypes.array.isRequired,
}

export default function (props) {
    return (
        <SuppliersContext.Consumer>
            {
                suppliers => {
                    return <Supplier options={suppliers} {...props}/>
                }
            }
        </SuppliersContext.Consumer>
    )
}
