import React, {Component} from 'react'
import {notification} from "antd"
import {exportOrdersToSheet} from "../../../../services/api/ExportOrderServices"
import {getLocalData} from "../../../../services/StorageServices"

class ExportOrdersSheet extends Component {
    state = {
        loading: false,
    }

    _renderResults = (orderSuccess, orderError, arrErrors) => {
        return (
            <div>
                {orderSuccess.length > 0 && (
                    <span className="d-block mb-3 text-primary"><strong>{orderSuccess.length}</strong> orders success</span>
                )}
                {orderError.length > 0 && (
                    <div className="d-block">
                        <span className="d-block text-danger mb-1"><strong>{orderError.length} orders error:</strong></span>
                        <div className="listErrorsExportSheet">
                            <ul>
                                {
                                    arrErrors.map(itemErr => {
                                        return <li className="mb-1"><strong>{itemErr.id}</strong>: {itemErr.error}</li>
                                    })
                                }
                            </ul>
                        </div>

                    </div>
                )}
            </div>
        )
    }

    _onClickPushOrder = () => {
        const {supplier} = this.props
        const name = getLocalData(`exportGgDrv.name.${supplier}`, '')
        const link = getLocalData(`exportGgDrv.link.${supplier}`, '')

        if (!name || !link) {
            if (window.confirm('You need to setup Google Sheet first. Click ok to setup the link')) {
                window.open(`/a/orders?google_drv_sheet=true&supplier=${supplier}`)
            }
            return
        }

        return this._pushOrder({name, link})
    }

    _pushOrder = async ({name, link}) => {
        const {selectedOrders, supplier} = this.props
        const {loading} = this.state

        if (loading) return

        try {
            this.setState({loading: true})

            const {data, success, message} = await exportOrdersToSheet({
                link: link,
                name: name,
                orderIdArray: selectedOrders,
            }, supplier)

            this.setState({loading: false})
            if (!success) return alert(message)

            const {order_id_not_fulfill, order_id_ready_to_push, order_not_fulfill} = data

            notification.info({
                message: `Export Orders to Google sheet`,
                description: this._renderResults(order_id_ready_to_push, order_id_not_fulfill, order_not_fulfill)
            })
        } catch (e) {
            this.setState({loading: true})
            alert(e.message)
        }
    }

    render() {
        const {supplier, textSupplier} = this.props

        return (
            <div
                onClick={this._onClickPushOrder}
            >
                <strong>[{textSupplier || supplier}]</strong> Export Orders to Google sheet
            </div>
        )
    }
}

export default ExportOrdersSheet
