import React, {Component} from 'react'
import PropTypes from 'prop-types'

class GeneralDetails extends Component {
    _onChangeInput = (key) => (e) => {
        const {value} = e.target
        return this.props.onChangePlatform(key, value)
    }

    render() {
        const {platform, loading, disableAll} = this.props

        return (
            <div className="GeneralDetails">
                <div className="InputWrapper">
                    <label
                        htmlFor="platform-name">
                        Name
                    </label>
                    <input
                        value={platform.name || ''}
                        className="Input"
                        id="platform-name"
                        onChange={this._onChangeInput('name')}
                        disabled={loading || disableAll}
                        autoComplete="off"/>
                </div>
            </div>
        )
    }
}

GeneralDetails.propTypes = {
    onChangePlatform: PropTypes.func.isRequired,
    platform: PropTypes.object.isRequired,
    loading: PropTypes.bool.isRequired,
}

export default GeneralDetails
