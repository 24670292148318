import React, {Component} from 'react'
import AutoPushContent from "./AutoPushContent"
import AutoMapping from './auto-mapping/AutoMapping'
import { getLocalData } from '../../../services/StorageServices'

class SettingAutoPage extends Component {
    state = {
        tabActive: 'autoMap'
    }

    _onChangeTab = (tab) => {
        this.setState({
            tabActive: tab
        })
    }

    render() {
        const {tabActive} = this.state
        const permissionsData = !!getLocalData('permissions') ? Object.keys(getLocalData('permissions')) : []
        const disableAll = !permissionsData.includes('ffm_setting_auto_update')

        return (
            <div className='SettingAutoPage'>
                <div className="SectionInner mt-3">
                    <div className="p-3">
                        <div className="TabList">
                            <div className="FilterTab">
                                <ul className='FilterList'>
                                    <li
                                        className={`Filter ${tabActive === 'autoMap' ? 'active' : ''}`}
                                        onClick={() => this._onChangeTab('autoMap')}
                                    >
                                        Auto Mapping
                                    </li>
                                    <li
                                        className={`Filter ${tabActive === 'autoPush' ? 'active' : ''}`}
                                        onClick={() => this._onChangeTab('autoPush')}
                                    >
                                        Auto Push
                                    </li>
                                </ul>
                            </div>

                            <div className="ContentTab">
                                {
                                    tabActive === 'autoMap' ? <div className="ContentTabPush"><AutoMapping disableAll={disableAll}/></div> : <AutoPushContent disableAll={disableAll}/>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default SettingAutoPage
