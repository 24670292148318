import React, {Component} from 'react'
import PropTypes from 'prop-types'
import Clipboard from 'react-clipboard.js'

class OrderButtonCopy extends Component {
    state = {
        copyText: "Copy address",
    }

    _createText = () => {
        const {order} = this.props
        const {id} = order
        const address = Object.assign({}, order.address)

        const {full_name, address: add, city, country_code, postal_code, state} = Object.assign({}, address)

        const _ = (text) => {
            return text ? (text + '').trim() : ''
        }

        return `${_(id)}\t\t\t${_(full_name)}\t${_(add)}\t\t${_(city)}\t${_(state)}\t${country_code}\t\t${postal_code}\n`
    }

    _handleCopyAddressSuccess = () => {
        this.setState({
            copyText: "Copied!",
        })

        setTimeout(() => {
            this.setState({
                copyText: "Copy address",
            })
        }, 2000)
    }

    render() {
        const {copyText} = this.state
        const text = this._createText()

        return (
            <div className="OrderButtonCopy cursor-pointer d-inline-block">
                <Clipboard component="div" data-clipboard-text={text} className="CopyAddress"
                           onSuccess={this._handleCopyAddressSuccess}>
                    <div className="Tooltip3 Top">
                        <i className="far fa-copy"/>

                        <div className="TooltipContent Clipboard">
                            <div className="ContentInner text-center">
                                <small className="text-monospace">{copyText}</small>
                            </div>
                        </div>
                    </div>
                </Clipboard>
            </div>
        )
    }
}

OrderButtonCopy.propTypes = {
    order: PropTypes.object.isRequired,
}

export default OrderButtonCopy

