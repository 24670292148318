import React from 'react'
import { Tooltip } from 'antd'

const TagRequestUpdate = (props) => {
    const { orderId, index, tag, itemsRequest = [], orderItem } = props
    const requestContents = {}
    for (let index = 0; index < itemsRequest.length; index++) {
        const { order_item, content } = { ...itemsRequest[index] }
        if (!!order_item && !!content) {
            requestContents[order_item] = content
        }
    }
    const isRequestUpdateTag = ['waiting-update-design', 'force-updated-design', 'updated-design', 'deleted-update-design'].includes(tag)

    return (
        <div key={index} id={"Tooltip-Tag-" + orderItem}>
            {isRequestUpdateTag ?
                (Object.keys(requestContents).length > 0 && !!requestContents[orderItem] ?
                    <Tooltip title={requestContents[orderItem]}>
                        <span className={`badge badge-pill badge-primary ${tag}`}>
                            {tag === 'waiting-update-design' ? 'request-design' : tag === 'waiting-update-address' ? 'request-address' : tag === 'force-updated-design' ? 'force-updated' : tag}
                        </span>
                    </Tooltip> :
                    <span className={`badge badge-pill badge-primary ${tag}`}>
                        {tag === 'waiting-update-design' ? 'request-design' : tag === 'waiting-update-address' ? 'request-address' : tag === 'force-updated-design' ? 'force-updated' : tag}
                    </span>
                ) :
                <span className={`badge badge-pill badge-primary ${tag}`}>
                    {tag === 'waiting-update-design' ? 'request-design' : tag === 'waiting-update-address' ? 'request-address' : tag === 'force-updated-design' ? 'force-updated' : tag}
                </span>
            }
        </div>
    )
}

export default TagRequestUpdate