import createAPIServices from './createApiServices'

const baseUrl = process.env.NODE_ENV === 'production' ? '/api/platform' : 'https://fulfillment-staging.merchize.com/api/platform'
const api = createAPIServices({baseUrl})

export const getAllPlatformApiKeys = (platform) => {
    return api.makeAuthRequest({
        method: 'GET',
        url: `/platforms/${platform}/api-keys`
    })
}

export const revokeApiKey = (platform, apiKey) => {
    return api.makeAuthRequest({
        method: 'POST',
        url: `/platforms/${platform}/api-keys/${apiKey}/revoke`
    })
}

export const createNewApiKey = (platform, apiKey) => {
    return api.makeAuthRequest({
        method: 'POST',
        url: `/platforms/${platform}/api-keys`,
        data: apiKey
    })
}

export const UploadFileNotification = (file) => {
    return api.makeAuthRequest({
        url: `/notifications/upload`,
        method: 'POST',
        data: file
    })
}

export const createUpdateDesignRequest = (orderItemID, formData) => {
    return api.makeAuthRequest({
        url: `/request-update-design/${orderItemID}`,
        method: 'POST',
        data: formData
    })
}

export const createUpdateAddressRequest = (orderID, formData) => {
    return api.makeAuthRequest({
        url: `/request-update-address/${orderID}`,
        method: 'POST',
        data: formData
    })
}

export const getListRequest = (payload = {}) => {
    return api.makeAuthRequest({
        url: `/update-requests/search`,
        method: 'POST',
        data: payload
    })
}
