import React, { useState, useEffect } from 'react'
import { message, Pagination, Spin, Input } from "antd"
import ChargeRefundTable from './ChargeRefundTable'
import ChargeRefundFilter from './Filter/ChargeRefundFilter'
import { getListChargeRefund } from '../../../services/api/ChargeRefundServices'
import { ReactComponent as SearchIcon } from '../../../statics/assets/icons/search.svg'
import UseDebounce from '../helpers/UseDebounce'
import ExportFilter from "./ExportFilter"
import { getLocalData } from '../../../services/StorageServices'

function ChargeRefundPage() {
    const permissionsData = !!getLocalData('permissions') ? Object.keys(getLocalData('permissions')) : []
    const [listChargeRefund, setListChargeRefund] = useState('')
    const [loading, setLoading] = useState(false)
    const [page, setPage] = useState(1)
    const [type, setType] = useState('')
    const [status, setStatus] = useState('')
    const [orderId, setOrderId] = useState('')
    const [createdStart, setCreatedStart] = useState('')
    const [createdEnd, setCreatedEnd] = useState('')
    const [pages, setPages] = useState(1)
    const [limit, setLimit] = useState(20)
    const [total, setTotal] = useState(0)
    const [error, setError] = useState('')
    const [userSearch, setUserSearch] = useState({})
    const [showFilter, setShowFilter] = useState(false)
    const [reason, setReason] = useState('')
    const [reasonId, setReasonId] = useState('')


    const fetchChargeRefund = async () => {
        setLoading(true)
        setError('')
        const payload = {
            query: {
                ...userSearch
            },
            sort: {
                created_at: -1
            },
            page,
            limit,
        }
        if (type) payload.query.type = type
        if (status) payload.query.status = status
        if (orderId) payload.query.order_name = orderId
        if (createdStart && createdEnd) payload.query.created_at = {
            from: createdStart,
            to: createdEnd,
        }
        if (reasonId) payload.query.reason = reasonId

        try {
            const { data, success, message: mess } = await getListChargeRefund({ ...payload })
            if (success) {
                const { pages, total, requests } = data
                setListChargeRefund(requests)
                setPages(pages)
                setTotal(total)
            } else {
                message.error(mess)
            }

            setLoading(false)
        } catch (error) {
            setError(error.message || 'Unknown error.')
        } finally {
            setLoading(false)
        }

    }

    const debounceSearch = UseDebounce(orderId, 500)
    const debounceUserSearch = UseDebounce(userSearch, 1000)
    const debounceReasonTags = UseDebounce(reason, 1000)

    const handlePageChange = (page, pageSize) => {
        setLimit(pageSize)
        setPage(pageSize !== limit ? 1 : page)
    }

    const handleChangeType = (value) => {
        setType(value)
        setPage(1)
    }

    const handleChangeStatus = (value) => {
        setStatus(value)
        setPage(1)
    }

    const handleChangeOrderId = (e) => {
        const value = e.target.value
        setOrderId(value)
        setPage(1)
    }

    const handleChangeCreated = (momentArray, dateStringArray) => {
        setCreatedStart(dateStringArray[0])
        setCreatedEnd(dateStringArray[1])
        setPage(1)
    }

    const handerToggleFilter = () => {
        setShowFilter(!showFilter)
    }

    const _onchangeFilter = (field, value) => {
        setUserSearch({
            [field]: value
        })
        setPage(1)
    }

    const handleChangeReasonTags = (value, e) => {
        if (value) {
            setReason(value)
            if (e.key) {
                setReasonId(e.key)
            } else {
                setReasonId(value)
            }
        } else {
            setReason('')
            setReasonId('')
        }
        setPage(1)
    }

    const _onChangeStatus = (id, value) => {
        const newListOrders = listChargeRefund.length > 0 && listChargeRefund.map(item => {
            if (id === item._id) {
                item.status = value
                return item
            }
            return item
        })
        return setListChargeRefund(newListOrders)
    }

    useEffect(() => {
        fetchChargeRefund()
    }, [
        type, status, debounceSearch,
        debounceUserSearch, createdStart,
        createdEnd, limit, page,
        debounceReasonTags,
    ])

    return (
        <div className="ChargeRefundPage">
            <div className='PageHeading d-flex align-items-center justify-content-between'>
                <h1 className="PageTitle mt-2 mb-0">Charge/Refund</h1>
                <div className="d-flex align-items-center">
                    <div className="RightTop mr-3">
                        <Input
                            style={{ minWidth: "400px" }}
                            placeholder='Search order'
                            value={orderId || ''}
                            onChange={handleChangeOrderId}
                            allowClear
                            disabled={loading}
                            className='searchOrder'
                            prefix={<SearchIcon className='mr-2' />}
                        />
                        <span className="MoreFilter" onClick={handerToggleFilter}>
                            More filters <i className="ti-angle-double-down" />
                        </span>
                    </div>
                    <ExportFilter
                        type={type}
                        status={status}
                        createdStart={createdStart}
                        createdEnd={createdEnd}
                        reasonId={reasonId}
                        userSearch={userSearch}
                        orderId={orderId}
                    />
                </div>
            </div>
            <div className="SectionInner">
                <div className={`filter align-items-center mt-2 ${!showFilter && 'd-none'}`}>
                    <ChargeRefundFilter
                        type={type}
                        status={status}
                        orderId={orderId}
                        createdStart={createdStart}
                        createdEnd={createdEnd}
                        loading={loading}
                        handleChangeType={handleChangeType}
                        handleChangeStatus={handleChangeStatus}
                        handleChangeOrderId={handleChangeOrderId}
                        handleChangeCreated={handleChangeCreated}
                        onchangeFilter={_onchangeFilter}
                        userSearch={userSearch}
                        reason={reason}
                        handleChangeReasonTags={handleChangeReasonTags}
                        permissionsData={permissionsData}
                    />
                </div>
                {
                    <div>
                        <div className='Total mb-3 d-flex justify-content-end'>
                            {
                                total > 0 && <div>
                                    Showing <span>{(page - 1) * limit + 1} to {(page - 1) * limit + listChargeRefund.length}</span> of <span>{total}</span> items
                                </div>
                            }
                        </div>
                        <Spin spinning={loading} tip='Data Loading...'>
                            <ChargeRefundTable
                                listChargeRefund={listChargeRefund}
                                loading={loading}
                                reload={fetchChargeRefund}
                                onChangeStatus={_onChangeStatus}
                                permissionsData={permissionsData}
                            />
                            <Pagination
                                pageSizeOptions={['10', '20', '50', '100']}
                                onChange={handlePageChange}
                                className='text-right'
                                pageSize={limit}
                                showSizeChanger
                                current={page}
                                total={total}
                            />
                        </Spin>
                    </div>
                }
            </div>
        </div>
    )
}

export default ChargeRefundPage