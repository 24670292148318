import React, { useState, useEffect } from "react"
import { Button, Select, Input, message } from "antd"
import { Link } from "react-router-dom"
import DocTitleByStore from "../../dashboard/shared/DocTitleByStore"
import { createShippingZoneV2, getListCountries, getListShippingZonesV2 } from "../../../services/api/ShippingServices"
import { getListSuppliersV2 } from "../../../services/api/SuppliersServices"
import getHistory from "../../../store/getHistory"

const { Option } = Select
const { TextArea } = Input

const ShippingZonesCreate = () => {
    const [title, setTitle] = useState("")
    const [supplier, setSupplier] = useState("")
    const [countryCode, setCountryCode] = useState([])
    const [description, setDescription] = useState("")
    const [listCountry, setListCountry] = useState([])
    const [listSupplier, setListSupplier] = useState([])
    const [supplierCountryId, setSupplierCountryId] = useState([])
    const [error, setError] = useState({})

    const getListCountry = async () => {
        try {
            const ListCountry = await getListCountries("")
            setListCountry(ListCountry.data)
        } catch (error) {
            console.log(error)
        }
    }

    const getListSupplier = async () => {
        try {
            const ListSupplier = await getListSuppliersV2("")
            setListSupplier(ListSupplier.data.suppliers)
        } catch (error) {
            console.log(error)
        }
    }

    const onchangeInput = (e) => {
        const { value, name } = e.target
        if (name == "title") {
            setTitle(value)
        }
        if (name == "description") {
            setDescription(value)
        }
    }

    const onchangeSupplier = async(value) => {
        try {
            const Payload={
                supplier:value
            }
            const response = await getListShippingZonesV2(Payload)
            const {data, success, message:err}= response

            if(success){
                const supplierCountries = data.zones.reduce((mem, zone)=>{
                    return [...mem, ...zone.country_code.map(code=>code._id)]
                    }, [])
                setSupplierCountryId(supplierCountries)
            }else{
                message.error(err)
            }
            
        } catch (error) {
            message.error(error)
        }
        setSupplier(value)
    }

    const newCountry = listCountry.length ? listCountry.map(item => {
        const { country } = item
        return {
            id:country._id,
            title: country.title,
            value: country.code
        }
    }) : []

    const onchangeCountry = (value) => {
        if (value.some(i => i === 'all')){

            const newListCountry = newCountry.filter(i=>{
                for (let country of supplierCountryId){
                    if(country === i.id) return false
                }
                return true
            })
            return setCountryCode(newListCountry.map(item=>item.value))
            
        } 
        setCountryCode([...value])
    }

    const handleSave = async () => {
        const validate = {}

        if (title == "") {
            validate.errorTitle = "Shipping zone title is required!"
        }
        if (supplier == "") {
            validate.errorSupplier = "Please select Supplier!"
        }
        if (!(countryCode.length > 0)) {
            validate.errorCountry = "Please select Country!"
        }

        setError(validate)

        if (
            validate.errorTitle == undefined &&
            validate.errorSupplier == undefined &&
            validate.errorSupplier == undefined
        ) {
            const Payload = {
                title: title,
                supplier: supplier,
                country_code: countryCode,
                description: description,
            }

            try {
                const response = await createShippingZoneV2(Payload)
                const { data, success, message: err } = response
                const history = getHistory()
                if (success) {
                    history.push('/a/shipping-zones')
                    message.success("Create Shipping Zone is Success!")
                } else {
                    message.error(err)
                }
            } catch (error) {
                message.error(error)
            }
        }

    }
    useEffect(() => {
        getListSupplier()
        getListCountry()
    }, [])

    return (
        <>
            <DocTitleByStore title="Add shipping zone" />
            <div className="ShippingZoneCreateForm">
                <div className="Header">
                    <div className="d-flex">
                        <Link to="/a/shipping-zones">
                        <i className="fas fa-chevron-left"></i>
                            <span className="mx-3">Shipping zone</span>
                        </Link>
                    </div>
                    <div
                        className="d-flex "
                        style={{ borderBottom: "1px #E0E3E7 solid" }}
                    >
                        <h1
                            className="PageTitle"
                            style={{ fontSize: "28px", width: "100%" }}
                        >
                            Add new shipping zone
                        </h1>
                        <Link to="/a/shipping-zones">
                            <Button
                                type="primary"
                                className="BtnCancel"
                                size="large"
                            >
                                Cancel
                            </Button>
                        </Link>
                        <Button
                            type="primary"
                            className="BtnSave"
                            onClick={handleSave}
                            size="large"
                        >
                            Save
                        </Button>
                    </div>
                </div>
                <div className="FormCreate">
                    <label>Title</label>
                    <Input
                        placeholder="Input Title"
                        className="formFilter"
                        value={title}
                        allowClear
                        name="title"
                        onChange={onchangeInput}
                    ></Input>
                    {error.errorTitle && (
                        <small style={{ color: "red" }}>{error.errorTitle}</small>
                    )}
                    <label>Supplier</label>
                    <Select
                        className="formFilter"
                        placeholder="Choose Supplier"
                        value={supplier || undefined}
                        onChange={onchangeSupplier}
                    >
                        {!!listSupplier &&
                            listSupplier.map((supplier, index) => (
                                <Option value={supplier._id} key={index}>
                                    {supplier.name}
                                </Option>
                            ))}
                    </Select>
                    {error.errorSupplier && (
                        <small style={{ color: "red" }}>{error.errorSupplier}</small>
                    )}
                    <label>Choose country</label>
                    <Select
                        allowClear
                        placeholder={"Select Country" || []}
                        onChange={onchangeCountry}
                        mode="multiple"
                        className="formFilter"
                        value={countryCode || []}
                        showArrow
                    >
                        <Option key="all" value="all">---SELECT ALL---</Option>
                        {
                            newCountry.map((data, index) => (
                                <Option disabled={(supplierCountryId.find(i=>i===data.id))?true:false} key={index} value={data.value}>
                                    {data.title}
                                </Option>
                            ))}
                    </Select>
                    {error.errorCountry && (
                        <small style={{ color: "red" }}>{error.errorCountry}</small>
                    )}
                    <label>Description</label>
                    <TextArea
                        placeholder="Input Description"
                        className="formFilter"
                        value={description}
                        name="description"
                        onChange={onchangeInput}
                        rows="6"
                        allowClear
                    ></TextArea>
                </div>
            </div>
        </>
    );
}

export default ShippingZonesCreate
