export const UPLOAD_STATUS = [
    {
        name: 'All status upload',
        value: '',
    },
    {
        name: 'Done',
        value: 'done',
    },
    {
        name: 'Failed',
        value: 'failed',
    },
]

export const SEND_STATUS = [
    {
        value: '',
        name: 'All status send',
    },
    {
        value: 'sent',
        name: 'Sent',
    },
    {
        value: 'failed',
        name: 'Failed',
    },
    {
        value: 'pending',
        name: 'Pending',
    },
]