import React from 'react'
import {Spin} from "antd"
import {Link} from "react-router-dom"

function ReportOverShipV2(props) {
    const {loading, error, carriersData = {}, allCarriersData} = props
    const {ship: totalData} = {...allCarriersData}
    const allDays = ['-6', '-5', '-4', '-3', '-2', '-1', 'countRemaining', 1, 2, 3, 4, 5, 6, 'countOvertime']
    const isColumnTotal = (day) => ['countAll', 'countRemaining', 'countOvertime'].includes(day)
    /**
     *
     * @param {Object} args
     * @param {String} args.suppliers
     * @param {String} args.shipping_carrier
     * @param {Number} args.delayed_days
     * @return {string}
     */
    const getPermalink = (args = {}) => {
        const mappingValues = {
            shipping_level: {
                Priority: 60,
                'First class': 50
            }
        }
        const query = ['suppliers', 'shipping_carrier', 'shipping_zone', 'delayed_days', 'tracking_company', 'shipping_level']
            .filter(p => args[p] !== undefined)
            .map(p => {
                let val = args[p]

                if (mappingValues[p] && mappingValues[p][val] !== undefined) {
                    val = mappingValues[p][val]
                }

                return `${p}=${val}`
            }).join('&')
        const baseLink = '/a/orders-supplier?package_status=completed,pre_transit,in_transit&delay=ship_delay_from_completed_at'

        return [baseLink, query].filter(Boolean).join('&')
    }
    return (
        <div className="ReportOverShip">
            <h3>Delivery Delay Report</h3>
            <div className="text-danger">{error}</div>
            <Spin spinning={loading} tip='Getting statics...'>
                <table className="table table-bordered">
                    <thead>
                    <tr>
                        <th rowSpan={2} colSpan={2}>Carrier</th>
                        <th className="text-center bg_1" colSpan={7}>Remaining days</th>
                        <th className="text-center bg_2" colSpan={7}>Delivery delay days</th>
                    </tr>
                    <tr>
                        <th className="text-right">>=6 days</th>
                        <th className="text-right">5 days</th>
                        <th className="text-right">4 days</th>
                        <th className="text-right">3 days</th>
                        <th className="text-right">2 days</th>
                        <th className="text-right">1 day</th>
                        <th className="text-right">Total</th>
                        <th className="text-right">1 day</th>
                        <th className="text-right">2 days</th>
                        <th className="text-right">3 days</th>
                        <th className="text-right">4 days</th>
                        <th className="text-right">5 days</th>
                        <th className="text-right">>=6 days</th>
                        <th className="text-right">Total</th>
                    </tr>
                    </thead>
                    <tbody>
                    {
                        totalData !== undefined &&
                        <tr>
                            <td colSpan={2}/>
                            {
                                allDays.map(day => {
                                    const isTotal = isColumnTotal(day)

                                    return <td key={`total_${day}`} className="text-right font-weight-bold">
                                        {
                                            isTotal && totalData[day]
                                        }
                                        {
                                            !isTotal &&
                                            <Link target={'_blank'}
                                                  to={getPermalink({...(!isNaN(day) && {delayed_days: day})})}>{totalData[day]}</Link>
                                        }
                                    </td>
                                })
                            }

                        </tr>
                    }
                    {
                        Object.entries(carriersData).map(([supplierName, supplierAllData], supplierIndex) => {
                            const {supplier, ship: supplierData, ship_by_carriers: carriersData} = supplierAllData

                            return <React.Fragment key={`${supplierName}_${supplierIndex}`}>
                                <tr key={`${supplierName}`} className="rowSub">
                                    <td key={`${supplierName}_${supplierIndex}`} colSpan={2}>{supplierName}</td>
                                    {
                                        allDays.map(day => {
                                            const isTotal = isColumnTotal(day)

                                            return <td key={`${supplierName}_${supplierIndex}_${day}`}
                                                       className="text-right font-weight-bold">
                                                {
                                                    isTotal && supplierData[day]
                                                }
                                                {
                                                    !isTotal &&
                                                    <Link target={'_blank'}
                                                          to={getPermalink({
                                                              suppliers: supplier.id,
                                                              ...(!isNaN(day) && {delayed_days: day})
                                                          })}>{supplierData[day]}</Link>
                                                }
                                            </td>
                                        })
                                    }
                                </tr>
                                {
                                    Object.keys(carriersData).map((carrierName, carrierIndex) => {
                                        const carrierData = carriersData[carrierName]

                                        return carrierData.map((zoneData, zoneIndex) => {
                                            const {
                                                zoneName,
                                                zoneType
                                            } = zoneData

                                            return <tr key={`${supplierName}_${carrierName}_${zoneName}`}>
                                                {
                                                    zoneIndex < 1 &&
                                                    <td key={`${supplierName}_${carrierName}_${zoneName}_carrier`}
                                                        rowSpan={carrierData.length}
                                                        colSpan={carrierData.length <= 1 ? 2 : 1}>{carrierName}
                                                    </td>
                                                }
                                                {
                                                    carrierData.length > 1 &&
                                                    <td key={`${supplierName}_${carrierName}_${zoneName}_zone`}
                                                        className={carrierData.length > 1 ? 'ax' : ''}>{zoneName}</td>
                                                }
                                                {
                                                    allDays.map(day => {
                                                        const isTotal = isColumnTotal(day)

                                                        return <td
                                                            key={`${supplierName}_${carrierName}_${zoneName}_${day}`}
                                                            className="text-right">
                                                            {
                                                                isTotal && zoneData[day]
                                                            }
                                                            {
                                                                !isTotal &&
                                                                <Link target={'_blank'}
                                                                      to={getPermalink({
                                                                          suppliers: supplier.id,
                                                                          shipping_carrier: carrierName,
                                                                          ...(
                                                                              zoneName && zoneType && {
                                                                                  [zoneType]: zoneName
                                                                              }
                                                                          ),
                                                                          ...(!isNaN(day) && {delayed_days: day})
                                                                      })}>{zoneData[day]}</Link>
                                                            }
                                                        </td>
                                                    })
                                                }
                                            </tr>
                                        })
                                    })
                                }
                            </React.Fragment>
                        })
                    }
                    </tbody>
                </table>
            </Spin>
        </div>
    )
}

export default ReportOverShipV2
