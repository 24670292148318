import React from 'react'
import PropTypes from 'prop-types'
import ShippingTableHeader from './ShippingTable/ShippingsTableHead'
import ShippingTableRow from './ShippingTable/ShippingsTableRow'


const ShippingFeeTable = (props) => {

    const { listShippingFee, handleDelete, permissionsData } = props

    return (
        <div className="ShippingFeeTable">
            <div className="wrapTable">
                <table className='table'>
                    <ShippingTableHeader permissionsData={permissionsData} />
                    <tbody>
                        {
                            listShippingFee.map((item, i) => {
                                return <ShippingTableRow key={item._id} index={i} item={item} handleDelete={handleDelete} permissionsData={permissionsData}/>
                            })
                        }
                    </tbody>
                </table>
            </div>
        </div>
    )
}

ShippingFeeTable.propTypes = {
    listShippingFee: PropTypes.array.isRequired,
    handleDelete : PropTypes.func.isRequired,
}

export default ShippingFeeTable