import React, {Component} from 'react'
import PropTypes from "prop-types"
import classNames from 'classnames'
import {listStoreModes} from "../../../../statics/storeModes"


class OrderStoreMode extends Component {
    _renderStoreMode = (storeModes) => {
        return listStoreModes.find(mode => mode.value === storeModes)
    }

    render() {
        const {preference} = this.props.order
        const {storeMode} = Object.assign({}, preference)
        const renderStoreMode = storeMode !== undefined && this._renderStoreMode(storeMode)

        return (
            <div className={classNames("OrderStoreMode mb-sm-0 mb-1", storeMode)}>
                <div className="card">
                    <div className="card-body">
                        <div className="Text">
                            <span className="Label">Mode: </span>
                            <strong className="Mode">{renderStoreMode.name}</strong>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

OrderStoreMode.propTypes = {
    order: PropTypes.object.isRequired,
}

export default OrderStoreMode
