import React from 'react'
import PropTypes from 'prop-types'
import OrderHistoriesList from "./OrderHistoriesList"

OrderHistoriesContainer.propTypes = {
    fufillmentId: PropTypes.string.isRequired
}

function OrderHistoriesContainer(props) {
    return (
        <div className="OrderHistoriesContainer">
            <div className="SectionInner">
                <h3 className="SectionHeading mb-3">History</h3>

                <OrderHistoriesList {...props}/>
            </div>
        </div>
    )
}

export default OrderHistoriesContainer
