import React, {Component} from 'react'
import {Switch, Route, Link} from 'react-router-dom'
import PropTypes from 'prop-types'
import Webhook from './categories/Webhook'

class PlatformSettingPage extends Component {
    render() {
        const {platformId} = this.props

        return (
            <div className="PlatformSettingPage">
                <Link to={`/a/platforms/${platformId}/settings`}>
                    <i className="fas fa-chevron-left"/>
                    <span className="BackTitle">Settings</span>
                </Link>
                <div className="SettingWrapper">
                    <Switch>
                        <Route
                            component={Webhook}
                            exact
                            path={`/a/platforms/:id/settings/webhook`}
                        />
                    </Switch>
                </div>
            </div>
        )
    }
}

PlatformSettingPage.propTypes = {
    platformId: PropTypes.string.isRequired,
}

export default PlatformSettingPage
