import React, { useState } from 'react'
import { approvedChargeRefund } from '../../../../services/api/ChargeRefundServices'
import { Modal, Progress } from "antd"
import { ExclamationCircleOutlined, CheckCircleOutlined } from '@ant-design/icons'

const BulkChangeApprove = ({ handleUnShowModal, listSelecteds, isOpen, listChargeRefund, onChangeStatus }) => {

    const [loading, setLoading] = useState(false)
    const [listProcessing, setListProcessing] = useState(listChargeRefund.filter(item => listSelecteds.includes(item._id)))
    const [listSuccess, setListSuccess] = useState([])
    const [listError, setListError] = useState([])

    const _hanldeFetchApprove = async (id) => {
        setLoading(true)
        try {

            const { success, message } = await approvedChargeRefund(id)

            if (!success) {
                return { success: success, message: message }
            }
            return { success: success }

        } catch (e) {
            return { success: false, message: e.message }
        } finally {
            setLoading(false)
        }

    }


    const _handleChangeApprove = async () => {

        setListSuccess([])
        setListError([])
        setListProcessing(listProcessing.map(item => {
            if (item.success) delete item.success
            if (item.error) delete item.error
            return item
        }))

        let processed = []


        try {
            await Promise.all(listProcessing.map(async item => {
                const response = await _hanldeFetchApprove(item._id)

                const { success, message } = response

                if (!success) return processed.push({ ...item, error: message, processing: false })
                onChangeStatus(item._id, "approved")
                return processed.push({ ...item, success: true, processing: false })

            }))

            let newListSuccess = []
            let newListError = []

            processed.map(process => {
                if (process.success === true) {
                    return newListSuccess.push(process)
                }

                if (process.error.length > 0) {
                    return newListError.push(process)
                }
            })

            setListSuccess(newListSuccess)
            setListError(newListError)
            return setListProcessing(processed)

        } catch (e) {
            return { success: false, message: e.message }
        } finally {
            setLoading(false)
        }
    }

    const _handleClick = e => {
        e.preventDefault()

        Modal.confirm({
            title: ` Do you want to approve for ${listSelecteds.length} requests?`,
            onOk() {
                _handleChangeApprove()
            },
        })
    }

    return (
        <div className='BulkChangeApprove'>
            <Modal title="Approve Charge/Refund" visible={isOpen} onOk={_handleClick} onCancel={handleUnShowModal} okText="Approve" confirmLoading={loading}>
                <h6>List processing <span>{listSelecteds.length}</span> items</h6>
                {
                    (listSuccess.length > 0 || listError.length > 0) &&
                    <div className="Results d-flex align-items-center">
                        <div className="ResultSuccess mr-2">
                            Success: <span className='text-success font-weight-bold'>{listSuccess.length}</span> items
                        </div>
                        <div className="ResultError">
                            Error: <span className='text-danger font-weight-bold'>{listError.length}</span> items
                        </div>
                    </div>
                }

                <div className="ListProcessing">
                    <ul>
                        {
                            !!listProcessing && listProcessing.length > 0 && listProcessing.map(item => (
                                <li key={item._id}>
                                    <span className='mr-2 font-weight-bold'>{item.order_number}</span>
                                    {
                                        !!item.error &&
                                        <span className="Error text-danger"><ExclamationCircleOutlined /> {item.error}</span>
                                    }
                                    {
                                        item.success &&
                                        <span className="Success text-success"><CheckCircleOutlined /> Change success</span>
                                    }
                                    <Progress
                                        percent={100}
                                        size="small"
                                        status={`${(item.processing === false && item.success === true) ? 'success' : ((item.processing === false && !!item.error) ? 'exception' : 'active')}`}
                                        showInfo={false}
                                    />
                                </li>
                            )
                            )
                        }
                    </ul>
                </div>
            </Modal>
        </div>
    )
}

export default BulkChangeApprove