import React, {useState, useEffect} from 'react'
import {Pagination} from "antd"
import {getMappingCondition} from "../../../services/api/MappingConditionServices"
import MappingConditionTable from "./MappingConditionTable"
import MappingConditionFilter from "./MappingConditionFilter"
import UseDebounce from "../helpers/UseDebounce"
import MappingConditionForm from "./MappingConditionForm"
import getHistory from "../../../store/getHistory"
import { getLocalData } from '../../../services/StorageServices'

const MappingConditionPage = (props) => {
    const permissionsData = !!getLocalData('permissions') ? Object.keys(getLocalData('permissions')) : []
    const {id: mappingConditionId} = {...props.params}
    const [listMappingCondition, setlistMappingCondition] = useState([])
    const [loading, setLoading] = useState(false)
    const [itemIndex, setItemIndex] = useState(0)
    const [page, setPage] = useState(1)
    const [pages, setPages] = useState(1)
    const [limit, setLimit] = useState(100)
    const [total, setTotal] = useState(0)
    const [error, setError] = useState('')
    const [search, setSearch] = useState({
        ...(mappingConditionId && {
            store: mappingConditionId
        })
    })
    const [mappingCondition, setMappingCondition] = useState(null)
    const _onchangeFilter = (field, value) => {
        if (field === 'store' && !value) {
            getHistory().push('/a/mapping-condition')
        }
        setSearch({
            [field]: value
        })
    }

    const _handlePageChange = (page, pageSize) => {
        setLimit(pageSize)
        setPage(pageSize !== limit ? 1 : page)
    }

    const _fetchMappingCondition = async (cb) => {

        setLoading(true)
        setError('')
        const payload = {
            page,
            limit
        }

        try {
            const res = await getMappingCondition({...payload, ...search})
            const {data = {}, success, message} = res
            const {pages, total, conditions} = data
            if (success) {
                setlistMappingCondition(conditions)
                setPages(pages)
                setTotal(total)

                if (mappingConditionId) {
                    const editingMappingCondition = conditions.find(cd => cd.store === mappingConditionId)

                    if (editingMappingCondition) {
                        showForm(editingMappingCondition)()
                    }
                }

                updateCurrentItemIndex()
            } else {
                setError(message)
            }

            setLoading(false)
        } catch (error) {
            setError(error.message || 'Unknown error.')
        } finally {
            setLoading(false)
        }

        cb && cb()
    }

    const debounceSearch = UseDebounce(search, 500)

    useEffect(() => {
        if (!mappingConditionId) {
            return
        }

    }, [mappingConditionId])

    useEffect(() => {
        _fetchMappingCondition()
    }, [page, limit, debounceSearch])

    const showForm = (mappingCondition) => (e) => {
        setMappingCondition(mappingCondition)
        updateCurrentItemIndex()
    }

    const onCloseForm = (reload) => {
        showForm(false)()
        setPage(1)
        setTimeout(() => {
            reload && _fetchMappingCondition()
        }, 100)
    }

    const nextItem = (inc) => () => {
        if (!mappingCondition) {
            return
        }

        const index = listMappingCondition.findIndex(i => i._id === mappingCondition._id)

        setMappingCondition(listMappingCondition[index + inc])
        setItemIndex(index + inc)
    }

    const updateCurrentItemIndex = () => {
        if (!mappingCondition) {
            return
        }

        const index = listMappingCondition.findIndex(i => i._id === mappingCondition._id)
        setItemIndex(index)
    }

    const _onChangeMapper = (id, value) => {
        const newListOrders = listMappingCondition.map(item => {
            if (id === item._id) {
                item.user = value
                return item
            }
            return item
        })
        return setlistMappingCondition(newListOrders)
    }

    const startIndex = (page - 1) * limit
    const endIndex = Math.min(startIndex + limit, total)

    return (
        <div className="MappingConditionPage">
            <div className="d-flex justify-content-between align-items-center">
                <h1 className="PageTitle my-3">Mapping Condition</h1>
                {permissionsData.includes('ffm_mapping_condition_create') &&
                    <div className='Create-shippingFee d-flex align-items-center'>
                        <div className='row justify-content-end'>
                            <div className='col-auto ml-3 ml-sm-0 mr-3 mr-lg-0'>
                                <button className="btn btn-primary" onClick={showForm({})}>Add Mapping Condition</button>
                            </div>
                        </div>
                    </div>
                }
            </div>

            <div className="SectionInner">
                <div className="filter align-items-center mt-3">
                    <MappingConditionFilter
                        searchField={['Namespace', 'Mapper', 'Mapping context']}
                        placeholders={{
                            'Namespace': 'Search for namespace',
                            'Mapper': 'Search for mapper',
                            'Mapping context': 'Search for mapping context',

                        }}
                        onchangeFilter={_onchangeFilter}
                        search={search}
                    />
                </div>

                {
                    loading && <div>Loading...</div>
                }

                {error && <i className='text-danger fs-14'>{error}</i>}

                {
                    !error && <>
                        {
                            !loading && listMappingCondition.length > 0 &&
                            <div className="mt-3">
                                <MappingConditionTable
                                    listMappingCondition={listMappingCondition}
                                    handleEdit={showForm}
                                    rowIndex={startIndex}
                                    reload={_fetchMappingCondition}
                                    onChangeMapper={_onChangeMapper}
                                    permissionsData={permissionsData}
                                />
                                <div className="TableFooter">
                                    {
                                        limit >= total &&
                                        <p className="page-counter">Showing <span>{total}</span> items</p>
                                    }
                                    {
                                        limit < total && <p className="page-counter">
                                            Showing
                                            from <span>{startIndex + 1}</span> to <span>{endIndex}</span> of <span>{total}</span> items
                                        </p>
                                    }
                                    <Pagination current={page} total={total} pageSize={limit}
                                                onChange={_handlePageChange}
                                                className='text-right' showSizeChanger
                                                pageSizeOptions={['10', '20', '50', '100']}
                                    />
                                </div>
                            </div>
                        }

                        {
                            !loading && listMappingCondition.length === 0 && <div>No result</div>
                        }
                    </>
                }
            </div>

            {
                mappingCondition && <MappingConditionForm
                    onClose={onCloseForm}
                    fetchMappingCondition={_fetchMappingCondition}
                    setMappingCondition={setMappingCondition}
                    nextItem={nextItem}
                    itemIndex={itemIndex}
                    itemsLength={listMappingCondition.length}
                    mappingCondition={mappingCondition}/>
            }
        </div>
    )
}

export default MappingConditionPage