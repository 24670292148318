import React from 'react'

function Heading() {
    return (
        <div className="Heading">
            <h1>
                Edit discount
            </h1>
        </div>
    )
}

export default Heading