import React, { useState, useMemo } from 'react'
import { approveIssue } from '../../../../services/api/PackageIssuesServices'
import { Modal, Progress, Select, Button, Popover } from "antd"
import { ExclamationCircleOutlined, CheckCircleOutlined } from '@ant-design/icons'
import { IssueCategorys, Accepts } from '../../helps/ListOptionSelect'

const checkSameTypeId = (items) => {
    if (items.length === 0) return false

    const firstTypeId = (!!items[0] && items[0].hasOwnProperty('type') && items[0].type.hasOwnProperty('_id')) ? items[0].type._id : ''

    if (!firstTypeId) return false

    return items.every(item => (!!item && item.hasOwnProperty('type') && item.type.hasOwnProperty('_id')) && item.type._id === firstTypeId)
}

function BulkMarkIssueApprove(props) {
    const { handleUnShowModal, listSelecteds, isOpen, listData, refresh, onChangeLoad, ProductionIssueTypes, ShipmentIssueTypes, issueCategory } = { ...props }
    const [loading, setLoading] = useState(false)
    const [listProcessing, setListProcessing] = useState(listData.filter(item => listSelecteds.includes(item._id)) || [])
    const [listSuccess, setListSuccess] = useState([])
    const [listError, setListError] = useState([])
    const [issueType, setIssueType] = useState(checkSameTypeId(listProcessing) ? listProcessing[0].type._id : '')
    const [newAccepted, setNewAccepted] = useState('')

    const isAllowIssueType = useMemo(() => {
        return listProcessing.some(item => (["1C", "TCVN"].includes(item.supplier.name) && item.status !== 'done') || (!["1C", "TCVN"].includes(item.supplier.name) && item.status === 'pending') || item.status === 'done')
    }, [listProcessing])

    const _hanldeFetchMark = async (id, payload) => {
        setLoading(true)
        try {
            const { success, message } = await approveIssue(payload, id)

            if (!success) {
                return { success: success, message: message }
            }
            return { success: success }

        } catch (e) {
            return { success: false, message: e.message }
        } finally {
            setLoading(false)
        }
    }

    const _handleMark = async () => {

        setListSuccess([])
        setListError([])
        setListProcessing(listProcessing.map(item => {
            if (item.success) delete item.success
            if (item.error) delete item.error
            return item
        }))

        let processed = []

        try {
            await Promise.all(listProcessing.map(async item => {
                const { type = {} } = Object.assign({}, item)
                const isAllowType = (["1C", "TCVN"].includes(item.supplier.name) && item.status !== 'done') || (!["1C", "TCVN"].includes(item.supplier.name) && item.status === 'pending') || item.status === 'done'
                const payload = {
                    accepted: !newAccepted ? null : (newAccepted === 'yes' ? true : false),
                    issue_type: (!!issueType && isAllowType) ? issueType : (type.hasOwnProperty('_id') ? type._id : ''),
                    partner_refunded: (item.hasOwnProperty('partner_refunded') && item.partner_refunded) ? item.partner_refunded : 0
                }
                const response = await _hanldeFetchMark(item._id, payload)

                const { success, message } = response

                if (!success) return processed.push({ ...item, error: message, processing: false })
                return processed.push({ ...item, success: true, processing: false })

            }))

            let newListSuccess = []
            let newListError = []

            processed.map(process => {
                if (process.success === true) {
                    return newListSuccess.push(process)
                }

                if (process.error.length > 0) {
                    return newListError.push(process)
                }
            })

            setListSuccess(newListSuccess)
            setListError(newListError)
            refresh()
            onChangeLoad()
            return setListProcessing(processed)

        } catch (e) {
            return { success: false, message: e.message }
        } finally {
            setLoading(false)
        }
    }

    const _handleClick = e => {
        e.preventDefault()
        _handleMark()
    }

    const handleChangeSelect = (field, value) => {
        if (field === 'issueCategory') {
            setIssueType('')
        }
        if (field === 'issueType') {
            if (!issueCategory) {
                const isProduction = ProductionIssueTypes.find(i => i.value === value)
                const isShipment = ShipmentIssueTypes.find(i => i.value === value)
                if (isProduction && isShipment) {
                    setIssueType(value)
                    return
                }
            }
            setIssueType(value)
        }
        if (field === 'newAccepted') setNewAccepted(value)
    }

    let IssueType = []

    switch (issueCategory) {
        case 'production':
            IssueType = ProductionIssueTypes
            break
        case 'shipment':
            IssueType = ShipmentIssueTypes
            break
        default:
            IssueType = [...new Set([...ProductionIssueTypes, ...ShipmentIssueTypes])].reduce((acc, current) => {
                const x = acc.find(item => item.name === current.name)
                if (!x) {
                    return acc.concat([current])
                } else {
                    return acc
                }
            }, []).sort((a, b) => a.name.localeCompare(b.name))
            break
    }

    const renderContent = (items) => {
        return (
            <div style={{
                display: 'grid',
                gridTemplateColumns: 'repeat(2, 1fr)',
                gridColumnGap: 20
            }}>
                {
                    items.map((item, i) => (
                        <div key={`${item}_${i}`} className="barcode-item">
                            {item}
                        </div>
                    ))
                }
            </div>
        )
    }

    return (
        <div className='BulkMarkIssueApprove'>
            <Modal title="Mark issues as approve"
                visible={isOpen}
                onCancel={() => handleUnShowModal('approve')}
                confirmLoading={loading}
                width={800}
                footer={[
                    <Button onClick={() => handleUnShowModal('approve')}>Cancel</Button>,
                    <Button type='primary' onClick={_handleClick} disabled={listProcessing.some(item => (["1C", "TCVN"].includes(item.supplier.name) && item.status !== 'done')) && !newAccepted}>Mark</Button>,
                ]}
            >
                <div className="form-approve border-bottom mb-2">
                    <div className="mb-3 mr-3 d-flex">
                        <span style={{ minWidth: '92px' }} className='mr-3'>Issue category</span>
                        <Select
                            placeholder='Issue category'
                            value={issueCategory || []}
                            style={{ width: "100%" }}
                            showArrow
                            showSearch
                            options={IssueCategorys}
                            onChange={(value) => handleChangeSelect('issueCategory', value)}
                            allowClear
                            filterOption={(inputValue, option) =>
                                !!option && option.label.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
                            }
                            disabled
                        />
                    </div>
                    <div className="mb-3 mr-3 d-flex">
                        <span style={{ minWidth: '92px' }} className='mr-3'>Issue type</span>
                        <Select
                            placeholder='Issue type'
                            value={issueType || []}
                            style={{ width: "100%" }}
                            showArrow
                            showSearch
                            options={IssueType}
                            onChange={(value) => handleChangeSelect('issueType', value)}
                            allowClear
                            filterOption={(inputValue, option) =>
                                !!option && option.label.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
                            }
                            disabled={!isAllowIssueType}
                        />
                    </div>
                    <div className="mb-3 mr-3 d-flex">
                        <span style={{ minWidth: '92px' }} className='mr-3'>Acceptable</span>
                        <Select
                            placeholder='Acceptable'
                            value={newAccepted || []}
                            style={{ width: "100%" }}
                            showArrow
                            showSearch
                            allowClear
                            options={Accepts}
                            onChange={(value) => handleChangeSelect('newAccepted', value)}
                            filterOption={(inputValue, option) =>
                                !!option && option.label.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
                            }
                        />
                    </div>
                </div>
                <h6>List processing <span>{listSelecteds.length}</span> issues</h6>
                {
                    (listSuccess.length > 0 || listError.length > 0) &&
                    <div className="Results d-flex align-items-center mb-3">
                        <div className="ResultSuccess mr-2">
                            Success: <span className='text-success font-weight-bold'>{listSuccess.length}</span> issues
                        </div>
                        <div className="ResultError">
                            Error: <span className='text-danger font-weight-bold'>{listError.length}</span> issues
                        </div>
                    </div>
                }

                <div className="ListProcessing" style={{ maxHeight: 500, overflow: 'auto' }}>
                    <ul>
                        {
                            !!listProcessing && listProcessing.length > 0 && listProcessing.map(item => (
                                <li key={item._id} className='mb-3'>
                                    <div className="d-flex justify-content-between">
                                        {item.reference_number && Array.isArray(item.reference_number) && item.reference_number.length > 0 ?
                                            <div className='reference_number d-flex'>
                                                <span>{item.reference_number[0]}</span>
                                                {item.reference_number && item.reference_number.length > 1 &&
                                                    <Popover
                                                        title="Items"
                                                        trigger="click"
                                                        content={() => renderContent(item.reference_number)}
                                                        className='cursor-pointer'
                                                        placement={'bottom'}
                                                    >
                                                        <div className='ml-1'>
                                                            <span className="cursor-pointer" style={{ color: 'rgb(33, 150, 243)' }}>
                                                                {`Show all (${item.reference_number.length})`}
                                                            </span>
                                                        </div>
                                                    </Popover>
                                                }
                                            </div> : <span className='mr-2 font-weight-bold'>{item.reference_number || ''}</span>
                                        }
                                        {
                                            item.success &&
                                            <span className="Success text-success mr-3"><CheckCircleOutlined /> Success</span>
                                        }
                                    </div>
                                    {
                                        !!item.error &&
                                        <div className="d-flex justify-content-between">
                                            <div className="Error text-danger"><ExclamationCircleOutlined /> {item.error}</div>
                                            <span className="Error text-danger mr-3"><ExclamationCircleOutlined /> Error</span>
                                        </div>
                                    }
                                    <Progress
                                        percent={100}
                                        size="small"
                                        status={`${(item.processing === false && item.success === true) ? 'success' : ((item.processing === false && !!item.error) ? 'exception' : 'active')}`}
                                        showInfo={false}
                                    />
                                </li>
                            )
                            )
                        }
                    </ul>
                </div>
            </Modal>
        </div>
    )
}

export default BulkMarkIssueApprove