import React, {Component} from 'react'
import PropTypes from 'prop-types'

class WebhookMessage extends Component {
    render() {
        const {webhook} = this.props
        const {error_message} = webhook
        return (
            <td className="WebhookMessage">
                {error_message !== null ? error_message : '' }
            </td>
        )
    }
}

WebhookMessage.propTypes = {
    webhook: PropTypes.object.isRequired,
}

export default WebhookMessage
