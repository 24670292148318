import React, {Component} from 'react'
import classnames from 'classnames'
import SettingSectionDetails from '../../setting/shared/SettingSectionDetails'
import S3 from './S3'
import {getSettings, saveSettings} from '../../../services/api/SettingServices'
import { getLocalData } from '../../../services/StorageServices'

class SettingStorage extends Component {
    state = {
        settings: {
            s3_artwork_access_key: '',
            s3_artwork_secret_key: '',
            s3_artwork_endpoint: '',
            s3_artwork_bucket: ''
        },
        loading: false,
    }

    componentDidMount() {
        this._fetchSettings()
    }

    _fetchSettings = async () => {
        const {settings} = this.state
        this.setState({loading: true})

        const {success, message, data} = await getSettings('storage')
        const settingData = success ? data : {}
        this.setState({
            settings: {...settings, ...settingData},
            loading: false,
        })

        if (!success) alert(message)
    }

    _onChangeSetting = (key) => (value) => this.setState(({settings}) => ({settings: {...settings, [key]: value}}))

    _onSubmit = (e) => {
        e.preventDefault()
        this._submitSettings()
    }

    _submitSettings = async () => {
        const {settings} = this.state

        this.setState({loading: true})
        const {success, message} = await saveSettings(settings)
        this.setState({loading: false})

        if (!success) alert(message)

    }

    render() {
        const {settings, loading} = this.state
        const permissionsData = !!getLocalData('permissions') ? Object.keys(getLocalData('permissions')) : []
        const disableAll = !permissionsData.includes('ffm_setting_storage_update')

        return (
            <div className="SettingWebhook">
                <form onSubmit={this._onSubmit}>
                    <div className="Section">
                        <SettingSectionDetails title="S3"/>
                        <div className="SectionSetting">
                            <div className="card">
                                <div className="card-body shadow-sm">
                                    <S3 settings={settings} onChange={this._onChangeSetting} loading={loading} disableAll={disableAll}/>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="ButtonSave">
                        {!disableAll &&
                            <button className="btn btn-primary" type="submit" disabled={loading || disableAll}>
                                <span className="Label">Save</span>
                                <span className={classnames('Spin', {'spin': loading})}/>
                            </button>
                        }
                    </div>
                </form>
            </div>
        )
    }
}

export default SettingStorage
