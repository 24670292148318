import React from 'react'
import SwitchCarrierPage from "./SwitchCarrierPage"

function SwitchCarrierContainer(props) {
    return (
        <div className="SwitchCarrierContainer">
            <SwitchCarrierPage/>
        </div>
    )
}

export default SwitchCarrierContainer
