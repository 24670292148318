import React, { useState } from 'react'
import BulkChangeApprove from './BulkChangeApprove'

function ChargeRefundHead(props) {
    const { UserData, selectedAll, handleChangeSelectAll, listSelecteds, listChargeRefund, onChangeStatus, permissionsData } = props
    const adminCheck = UserData.role === 'admin' || permissionsData.includes('ffm_charge_and_refund_approve')

    const [showAction, setShowAction] = useState(false)
    const [isOpen, setIsOpen] = useState(false)

    const _toggleAction = () => {
        setShowAction(!showAction)
    }

    const _handleShowModal = () => {
       setIsOpen(true)
    }

    const _handleUnShowModal = () => {
        setIsOpen(false)
        handleChangeSelectAll(false)
        setShowAction(false)
    }

    const _handleChangeSelectAll = (e) => {
        const { checked } = e.target

        if (typeof handleChangeSelectAll === 'function') {
            handleChangeSelectAll(checked)
        }
    }
    return (
        <thead
            className="ChargeRefundTableHead"
            style={{ background: '#e9ecef' }}
        >
            <tr>
                {permissionsData.includes('ffm_charge_and_refund_approve') &&
                    <th className="CheckBox">
                        <div className="BulkActions">
                            <input type="checkbox" checked={selectedAll} onChange={_handleChangeSelectAll} />
                            <div className="Actions">
                                <i className="ti-angle-down" onClick={_toggleAction} />
                                <ul className={`ListActions ${showAction ? 'd-block' : ''} ${listSelecteds.length > 0 && adminCheck ? '' : 'Disable'}`}>
                                    <li className="ActionItem" onClick={_handleShowModal}>Approve multiple requests</li>
                                </ul>
                                {
                                    showAction && <div className="Background" onClick={_toggleAction} />
                                }
                            </div>
                        </div>
                    </th>
                }
                <th className="Order_id" style={{ minWidth: '81px' }}>Order</th>
                <th className="Type">Type</th>
                <th className="Value">Value</th>
                <th className="Reason_tag">Reason tag</th>
                <th className="Reason_for_seller">Reason for seller</th>
                <th className="Created_at">Created at</th>
                <th className="User">User</th>
                <th className="Approve_at">Approved at</th>
                <th className="Reject_at">Rejected at</th>
                <th className="OrderNote">Order note</th>
                <th className="Status">Status</th>
                {(permissionsData.includes('ffm_charge_and_refund_approve') || permissionsData.includes('ffm_charge_and_refund_reject')) &&
                    <th className="Action">Action</th>
                }
            </tr>
            {
                isOpen && <BulkChangeApprove
                    handleUnShowModal={_handleUnShowModal}
                    listSelecteds={listSelecteds}
                    isOpen={isOpen}
                    listChargeRefund={listChargeRefund}
                    onChangeStatus={onChangeStatus}
                />
            }
        </thead>
    )
}

export default ChargeRefundHead