import React, {Component} from 'react'
import {InfoCircleOutlined} from '@ant-design/icons'
import {Tooltip} from "antd"

class EmbroiderySurchargeIcon extends Component {
    render() {
        const {embroiderySurcharge} = this.props
        const {amount} = {...embroiderySurcharge}

        return embroiderySurcharge ? <Tooltip overlayInnerStyle={{width: '260px'}} title={`Already charged $${amount} for embroidery`}>
            <InfoCircleOutlined className="EmbroiderySurchargeTooltip" style={{fontSize: '20px'}}/>
        </Tooltip> : ''
    }
}

export default EmbroiderySurchargeIcon