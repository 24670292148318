import React, {Component} from 'react'
import PropTypes from 'prop-types'

class ShippingPlanDefault extends Component {
    render() {
        const {plan} = this.props
        const {is_default} = Object.assign({}, plan)

        return (
            <td className="ShippingPlanDefault">
                {is_default && 'x'}
            </td>
        )
    }
}

ShippingPlanDefault.propTypes = {
    plan: PropTypes.object.isRequired,
}

export default ShippingPlanDefault
