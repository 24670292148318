import React from 'react'
import Link from 'react-router-dom/Link'
import moment from 'moment'
import {message, Button} from 'antd'
import {fileApproveReject} from '../../../../services/api/SuppliersServices'

function CostControlRow(props) {
    const {item, reload, permissionsData} = props
    const supplier = !!item.supplier ? item.supplier.name : ''
    const results = {...item.results}

    const handleApproveReject = async (Action, fileId) => {
        const payload = {
            action: Action
        }

        try {
            const {data, success, message: mess} = await fileApproveReject(fileId, payload)
            if (success) {
                message.success('File ' + (Action === 'approve' ? 'approved' : 'rejected'))
                reload()
            } else {
                message.error(mess)
            }
        } catch (error) {
            message.error(error)
        }
    }

    return (
        <tr>
            <td className='File_name'>
                <Link to={`/a/cost-control/${item._id}`}>
                    {item.filename}
                    <small className="text-muted">{item.id}</small>
                </Link>
            </td>
            <td className='Supplier'>
                {supplier}
            </td>
            <td className='Create_at'>
                {moment(item.created_at).format('HH:MM DD/MM/YYYY')}
            </td>
            <td className='Finish_at'>
                {moment(item.updated_at).format('HH:MM DD/MM/YYYY')}
            </td>
            <td className='Record'>
                {
                    results.records &&
                    <label
                        title={['done', 'error'].filter(p => results[p] !== undefined).map(p => `${p}: ${results[p]}`).join(', ')}>
                        {results.records}
                    </label>
                }
            </td>
            <td className='Approval_status'>

                {item.approval_status === 'approved' &&
                <label className='badge badge-success text-capitalize'>{item.approval_status}</label>
                }
                {item.approval_status === 'rejected' &&
                <label className='badge badge-danger text-capitalize'>{item.approval_status}</label>
                }
                {item.approval_status === 'pending' && item.process_status === 'processed' &&
                    (permissionsData.includes('ffm_cost_control_update') ?
                        <div className='Actions'>
                            <Button
                                className='btn btn-success btn-sm'
                                onClick={() => handleApproveReject('approve', item._id)}
                            >
                                Approve
                            </Button>
                            <Button
                                className='btn btn-danger mx-1 btn-sm'
                                onClick={() => handleApproveReject('reject', item._id)}
                            >
                                Reject
                            </Button>
                        </div> :
                        <label className='badge badge-info pending-animation text-capitalize'>{item.approval_status}</label>
                    )
                }
                {item.approval_status === 'pending' && item.process_status === 'processing' &&
                <label className='badge badge-secondary text-capitalize' text-capitalize>{item.process_status}</label>
                }
                {item.approval_status === 'pending' && item.process_status === 'error' &&
                <Button
                    className='btn btn-danger btn-sm'
                    onClick={() => handleApproveReject('reject', item._id)}
                >
                    Reject
                </Button>
                }
            </td>
        </tr>
    )
}

export default CostControlRow