import React, {Component} from 'react'

class ShipingMethodsActions extends Component {
    _handleClickRemove = e => {
        e.preventDefault()

        const {index, _handleRemovePlan, onRemoveSelectedProduct, product, selectedPlan} = this.props

        if (typeof _handleRemovePlan === 'function' && typeof onRemoveSelectedProduct === 'function') {
            onRemoveSelectedProduct(selectedPlan, product)
            _handleRemovePlan(index)
        }
    }

    render() {
        return (
            <td className="ShipingMethodsActions">
                <i className="fas fa-times text-danger" onClick={this._handleClickRemove}/>
            </td>
        )
    }
}

export default ShipingMethodsActions