import React, {Component} from 'react'
import ImportTrackingModal from "./ImportTrackingModal"

class ImportTrackingContainer extends Component {
    state = {
        showModal: false,
    }

    _handleToggleModal = () => {
        this.setState({
            showModal: !this.state.showModal,
        })
    }

    render() {
        const {showModal} = this.state

        return (
            <div className="ImportTrackingContainer ml-2">
                <button className="btn btn-outline-primary btn-sm" onClick={this._handleToggleModal}>
                    <i className="ti-import mr-2"/> Import tracking
                </button>

                <ImportTrackingModal onToggleModal={this._handleToggleModal} showModal={showModal}/>
            </div>
        )
    }
}

export default ImportTrackingContainer
