import React, {Component} from 'react'
import {Popover, Tooltip} from 'antd'
import moment from 'moment'
import {Link} from "react-router-dom"
import {getLocalData, setLocalData} from '../../../../../services/StorageServices'

class MappingCondition extends Component {
    state = {
        triggerPopup: getLocalData('OrderMappingConditionDetailContext') || 'hover'
    }

    setPopoverContext = (e) => {
        this.setState({
            triggerPopup: e.target.checked ? 'click' : 'hover'
        })

        setLocalData('OrderMappingConditionDetailContext', e.target.checked ? 'click' : 'hover')
    }

    getContent = (content) => {
        if (!content || content && !`${content}`.length) {
            return 'No mapping content'
        }

        const lines = content.split("\n").filter(Boolean)

        return <>
            <div className="OrderRowMappingContent">
                <div className="OrderRowMappingContentInner">
                    {
                        lines.map((line, index) => {
                            return <div className="MappingContentLine" key={index}>{line}</div>
                        })
                    }
                </div>
            </div>
            {/*<label className="XXX">*/}
            {/*    <input type="checkbox" checked={this.state.triggerPopup === 'click'}*/}
            {/*           onClick={this.setPopoverContext}/>*/}
            {/*    <span>Use mouse click to show mapping condition instead of hover</span>*/}
            {/*</label>*/}
        </>
    }

    getTitle = () => {
        const {order} = this.props
        const {
            store,
            user,
            created_at,
            lastEdited
        } = {...order.mapping_condition}

        return [
            <span key="created">{user} <small
                className="CreatedAt">at {moment(created_at).format('H:mm DD/MM/YYYY')}</small></span>,
            lastEdited && <Tooltip key="updated" className="MappingConditionAction Info"
                                   title={`Last edited at ${moment(lastEdited.created_at).format('H:mm DD/MM/YYYY')} by ${lastEdited.user || '-'}`}>
                <i className="ti-help-alt MappingConditionTipLastEdited"/>
            </Tooltip>,
            <Link key="edit" className="MappingConditionAction Edit" to={`/a/mapping-condition/${store}`} exac={true}
                  target={"_blank"}>
                <Tooltip title={`Click to edit this mapping condition`}>
                    <i className="ti-pencil-alt"/>
                </Tooltip>
            </Link>
        ].filter(Boolean)
    }

    render() {
        const {order} = this.props
        const {
            _id: mappingId,
            mapping_context,
            user
        } = {...order.mapping_condition}

        return (
            <td className="MappingCondition">
                {
                    !mappingId && <i className="fas fa-times text-danger" title="No mapping condition"/>
                }
                {
                    mappingId && <div>
                        <Popover overlayClassName="MappingConditionPopover" placement="left"
                                 title={this.getTitle(order)}
                                 content={this.getContent(mapping_context)}
                                 trigger={this.state.triggerPopup}>
                            <i className="fas fa-check text-success"
                               title={this.state.triggerPopup === 'click' ? `View detail (mapper: ${user}).` : ''}/>
                        </Popover>
                    </div>
                }
            </td>
        )
    }
}

export default MappingCondition