import React, {useEffect, useState} from 'react'
import ReportShipStatusV2 from "./ReportShipStatusV2"
import ReportShipStatusByStore from "./ReportShipStatusByStore"
import jsLocalStorage from '../../../../helpers/common/localStorage'
import {Spin, Tooltip} from "antd"
import {ArrowUpOutlined} from "@ant-design/icons"
import {createUpdateAddressRequest} from "../../../../services/api/PlatformApiKeysServices"
import queryString from "query-string"

const Config = new jsLocalStorage('dailyReport', {
    currentTab: ''
})
const getQueryParams = (props) => {
    const {location = {}} = window
    const vParams = {}

    if (location.search !== '') {
        const params = queryString.parse(location.search)

        if (params['packaged_at.from'] !== undefined && params['packaged_at.to'] !== undefined) {
            const valuePackageAtFrom = params['packaged_at.from']
            const valuePackageAtTo = params['packaged_at.to']
            vParams.packaged_at = {from: valuePackageAtFrom, to: valuePackageAtTo}
        }

        if (params.shipByDay) {
            vParams.shipByDay = params.shipByDay
        }
    }

    return vParams
}

function DailyReport(props) {
    const vParams = getQueryParams(props)
    const getTabFromUrl = () => {
        return {
            'by-store': 'byStore',
            'by-carrier': 'byCarrier'
        }[props.page.params.tab] || 'byStore'
    }
    const [currentTab, setCurrentTab] = useState(getTabFromUrl() || Config.get('currentTab'))
    const [loading, setLoading] = useState(false)
    const [toTop, setToTop] = useState(false)
    const [store, setStore] = useState(props.page.params.store)
    const setTab = (tab) => {
        setCurrentTab(tab)
        Config.set('currentTab', tab)
    }

    useEffect(() => {
        setTab(getTabFromUrl())
        setStore(props.page.params.store)
    }, [props.page.params.tab, props.page.params.store])
    let timer
    window.addEventListener('scroll', () => {
        timer && clearTimeout(timer)
        setTimeout(() => {
            if (window.scrollY > 100) {
                setToTop(true)
            } else {
                setToTop(false)
            }
        }, 100)
    })
    return (
        <div className={['DailyReports', loading && 'Loading'].filter(Boolean).join(' ')}>
            {currentTab === 'byStore' &&
            <ReportShipStatusByStore onChangeTab={setTab}
                                     Config={Config}
                                     loading={loading}
                                     setLoading={setLoading}
                                     setStore={setStore}
                                     filters={vParams}
                                     toTop={toTop}/>
            }
            {!currentTab || currentTab === 'byCarrier' &&
            <ReportShipStatusV2 onChangeTab={setTab}
                                Config={Config}
                                store={store}
                                setStore={setStore}
                                filters={vParams}/>
            }

            <Spin spinning={loading} tip="Getting data..."/>
            {
                toTop &&
                <div className="ToTop" onClick={() => {
                    window.scrollTo(0, 0)
                }}>
                    <ArrowUpOutlined/>
                </div>
            }
        </div>
    )
}

export default DailyReport
