import React, {Component} from 'react'
import PropTypes from 'prop-types'

class ArtworksTableThumbnail extends Component {
    render() {
        const {thumbnail} = this.props

        return (
            <td className='ArtworksTableThumbnail'>
                <div className='ThumbnailWrapper'>
                    <div className='ThumbnailInner'>
                        <img className='ThumbnailImage' src={thumbnail} alt='artwork'/>
                        <img className='OriginImage' src={thumbnail} alt='artwork'/>
                    </div>
                </div>
            </td>
        )
    }
}

ArtworksTableThumbnail.propTypes = {
    thumbnail: PropTypes.string.isRequired
}

export default ArtworksTableThumbnail