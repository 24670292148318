import React, {Component} from 'react'
import {updateOrderNote} from "../../../services/api/OrdersServices"
import PropTypes from 'prop-types'

class OrderNote extends Component {
    state = {
        note: this.props.note,
        saving: false,
        error: "",
    }

    _timeOut = null

    _handleUpdateOrderNote = async () => {
        try {
            const {note} = this.state
            const {orderId} = this.props

            this.setState({
                saving: true,
            })

            const {success, message} = await updateOrderNote(orderId, {note: note})

            if (!success) {
                return this.setState({
                    error: message,
                    saving: false,
                })
            }

            return this.setState({
                error: "",
                saving: false,
            })
        } catch (e) {
            return this.setState({
                error: e.message,
                saving: false,
            })
        }
    }

    _handleChangeTextarea = e => {
        const {value} = e.target

        this.setState({
            note: value,
        }, this._delaySaveNote)
    }

    _delaySaveNote = () => {
        if (this._timeOut) clearTimeout(this._timeOut)

        this._timeOut = setTimeout(async () => {
            await this._handleUpdateOrderNote()
        }, 1000)
    }

    _handleSubmit = e => {
        e.preventDefault()

        this._handleUpdateOrderNote()
    }

    render() {
        const {saving, note} = this.state

        return (
            <div className="OrderNote mt-3">
                <div className="card">
                    <div className="card-header font-weight-bold">Order Note</div>

                    <div className="card-body">
                        <form onSubmit={this._handleSubmit}>
                            <div className="form-group mb-0">
                                <textarea value={note} className="form-control" placeholder="Enter your note..."
                                          rows="4"
                                          onChange={this._handleChangeTextarea}/>

                                <div className="text-right mt-3">
                                    {
                                        saving ?
                                            <button className="btn btn-primary btn-sm disabled">Saving...</button>
                                            :
                                            <button className="btn btn-primary btn-sm">Save note</button>
                                    }
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        )
    }
}

OrderNote.propTypes = {
    orderId: PropTypes.string.isRequired,
    note: PropTypes.string,
}

export default OrderNote
