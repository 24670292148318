import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Link } from "react-router-dom"
import ModalInformation from "../../detail-infomation/ModalInformation"

class OrderNumber extends Component {
    render() {
        const { webhook } = this.props
        const { order, object_type = '', object_id = {} } = webhook
        const { name } = { ...object_id }
        let newId = '-', nameSpace = '-', idObject = '#'

        if (order !== null) {
            const { id, namespace, _id } = { ...order }
            newId = id
            nameSpace = namespace
            idObject = _id
        }

        return (
            <td className="OrderNumber">
                {
                    object_type === "Supplier" ? (
                        <>
                            <div>
                                <Link to={`orders/${idObject}`}>{name}</Link>
                                <ModalInformation webhook={webhook} />
                            </div>

                        </>
                    ) : (
                        <>
                            <div>
                                <Link to={`orders/${idObject}`}>{newId}</Link>
                                <ModalInformation webhook={webhook} />
                            </div>
                            <div>
                                <code>{nameSpace}</code>
                            </div>
                        </>
                    )

                }
            </td>
        )
    }
}

OrderNumber.propTypes = {
    webhook: PropTypes.object.isRequired,
}
export default OrderNumber
