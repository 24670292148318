import React from 'react'
import { Checkbox } from 'antd'

const BulkSelectNotification = (props) => {
    const {isSelectedAll, selectedItems, loadingSend} = props

    const _handleChange = () => {
        props.toggleSelectAll(!isSelectedAll)
    }

    return (
        <th className='BulkSelectNotification position-relative'>
            <div className='d-flex'>
                <div className='custom-control custom-checkbox'>
                    <Checkbox disabled={loadingSend} indeterminate={!isSelectedAll && selectedItems.length > 0} onChange={_handleChange} checked={props.isSelectedAll}/>
                </div>
            </div>
        </th>
    )
}

export default BulkSelectNotification
