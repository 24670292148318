import React from 'react'
import PropTypes from 'prop-types'
import RequestThead from "./RequestThead"
import RequestBody from "./RequestBody"
import {Spin} from "antd"

RequestTable.propTypes = {
    listRequest: PropTypes.array,
    loading: PropTypes.bool,
    error: PropTypes.string
}

function RequestTable(props) {
    const {listRequest, loading, error} = props

    return (
        <div className='RequestTable'>
            <Spin spinning={loading} tip="Get data...">
                <table>
                    <RequestThead/>
                    <RequestBody listRequest={listRequest} error={error}/>
                </table>
            </Spin>
        </div>
    )
}

export default RequestTable
