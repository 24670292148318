import React from 'react'
import RolesHead from './RolesTable/RolesHead'
import RolesRow from './RolesTable/RolesRow'

function RolesTable(props) {
    const { listRole, openEditModal } = props
    return (
        <div className="RolesTable">
            <table className='table'>
                <RolesHead />
                <tbody>
                    {!!listRole && listRole.length > 0 ? listRole.map((item, i) => {
                        return <RolesRow
                            key={item._id}
                            index={i}
                            item={item}
                            openEditModal={openEditModal}
                        />
                    }) : <tr>
                        <td colSpan={3}>No data</td>
                    </tr>
                    }
                </tbody>
            </table>
        </div>
    )
}

export default RolesTable