import React, {Component} from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import {validateShippingAddress} from "../../../../services/api/OrdersServices"

class OrderRevalidateAddress extends Component {
    state = {
        loading: false,
    }

    _handleClickRevalidate = e => {
        e.preventDefault()

        if (this.state.loading) return false

        return this._revalidate()
    }

    _revalidate = async () => {
        this.setState({
            loading: true
        })

        try {
            const {success, data, message} = await validateShippingAddress(this.props.order._id)

            if (!success) {
                throw new Error(message)
            }

            this.setState({
                loading: false
            }, () => {
                this.props.onChangeAddress(data)
            })
        } catch (e) {
            this.setState({
                loading: false
            }, () => {
                window.alert(e.message || e)
            })
        }
    }

    render() {
        const {loading} = this.state

        return (
            <div onClick={this._handleClickRevalidate}
                 className={classNames("OrderRevalidateAddress cursor-pointer d-inline-block mr-3", {loading})}>
                <div className="Tooltip3 Top">
                    <i className={classNames("fas fa-sync-alt", {"fa-spin": loading})}/>

                    <div className="TooltipContent Clipboard">
                        <div className="ContentInner text-center">
                            <small className="text-monospace">Validate Address</small>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

OrderRevalidateAddress.propTypes = {
    order: PropTypes.object.isRequired,
    onChangeAddress: PropTypes.func.isRequired,
}

export default OrderRevalidateAddress
