import React, {Component} from 'react'

class ShippingPlanSlug extends Component {
    render() {
        const {slug} = this.props
        return (
            <td className="ShippingPlanSlug">
                {slug}
            </td>
        )
    }
}

export default ShippingPlanSlug
