import React from 'react'
import PropTypes from 'prop-types'
import ShippingAddress from "./ShippingAddress"
import ReturnAddress from './ReturnAddress'

ReturnAddressContainer.propTypes = {
    returnAddress: PropTypes.any
}

function ReturnAddressContainer(props) {
    return (
        <div className="card">
            <div className="card-header font-weight-bold d-flex justify-content-between">
                <span>Return Address</span>
            </div>

            <div className="card-body">
                {!!props.returnAddress && <ReturnAddress returnAddress={props.returnAddress}/>}
            </div>
        </div>
    )
}

export default ReturnAddressContainer