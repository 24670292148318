import React, {Component} from 'react'
import ProductLineVariantsHead from './ProductLineVariantsHead'
import ProductLineVariantsRow from './ProductLineVariantsRow'
import ProductLineVariantsSelect from './ProductLineVariantsSelect'
import { getListSuppliersV2 } from "../../../../../services/api/SuppliersServices"

class ProductLineVariantsTable extends Component {
    filter = {...this.props.filter}
    supplier = this.filter.supplier
    state = {
        attributesSelected: {},
        supplierSelected : this.supplier && this.supplier.length === 1 ? {id: this.supplier[0]} : {},
        // listSuppliers: []
    }

    _handleSelectAttribute = (e) => {
        const {name, value} = e.target

        const {attributesSelected} = this.state
        const newAttributesSelected = {
            ...attributesSelected,
            [name]: value
        }

        for (let key of Object.keys(newAttributesSelected)) {
            if (!newAttributesSelected[key]) delete newAttributesSelected[key]
        }

        this.setState({attributesSelected: newAttributesSelected})
    }

    _handleSelectSupplier = e => {
        const {name, value} = e.target

        const {supplierSelected} = this.state
        const newSupplierSelected = {
            ...supplierSelected,
            [name]: value
        }

        for (let key of Object.keys(newSupplierSelected)) {
            if (!newSupplierSelected[key]) delete newSupplierSelected[key]
        }

        this.setState({
            supplierSelected: Object.keys(newSupplierSelected).length ? newSupplierSelected : {id: 'all'}
        })
    }


    _filterVariants = (variants, attributesSelected, supplierSelect, filterSupTiktok) => {
        if(Object.keys(supplierSelect).length){
            return variants.filter(variant => {
                if(supplierSelect.id === 'all') return true

                const {options} = variant

                for (let option of options) {
                    const {attribute, _id: id} = option
                    if ((attributesSelected[attribute] && attributesSelected[attribute] !== id) || 
                    (supplierSelect.id && variant.supplier !== supplierSelect.id)) return false
                }
                return true
            })
        }else{
            return variants.filter(variant => {
                const {options} = variant
    
                for (let option of options) {
                    const {attribute, _id: id} = option
                    if ((attributesSelected[attribute] && attributesSelected[attribute] !== id) || 
                    (filterSupTiktok && variant.supplier !== filterSupTiktok)) return false
                }
                return true
            })
        }
    }

    render() {
        const {variants, attributes, orderItemId, _handleUpdateStateOrderItem, selectedProduct, filter, listSuppliers, artworks, _handleAlert,order_type} = this.props
        const {attributesSelected, supplierSelected} = this.state
        const {supplier} = {...filter}
        const optionSupliers =  listSuppliers.filter(sup => {
            return variants.some(option => {
                return sup._id === option.supplier
            })
        }) 
        const filterSupTiktok = optionSupliers.find(sup => supplier.includes(sup._id)) || {}
        const supplierTiktok = !!filterSupTiktok && !!filterSupTiktok._id && filterSupTiktok._id
        const filteredVariants = this._filterVariants(variants, attributesSelected, supplierSelected, supplierTiktok)

        return (
            <table className="ProductLineVariantsTable table table-hover">
                <ProductLineVariantsHead attributes={attributes}/>

                <tbody>
                <tr>
                    {
                        attributes.map(attribute => {
                            return <ProductLineVariantsSelect onChangeAttribute={this._handleSelectAttribute}
                                                              key={attribute._id}
                                                              value={attributesSelected[attribute._id] || ''}
                                                              attribute={attribute}
                                    />
                        })
                    }
                    <td className='ProductLineVariantsSelect'>
                        <select className='form-control' name='id' value={supplierSelected.id || supplierTiktok || 'all'} onChange={this._handleSelectSupplier}>
                            <option value={'all'}>All supplier</option>
                            {
                                optionSupliers.map(_value => {                                  
                                        return <option key={_value._id} value={_value._id}>{_value.name}</option>
                                    }                                  
                                )
                            }
                        </select>
                    </td>
                </tr>
                {
                    filteredVariants.map(variant => {
                        return <ProductLineVariantsRow key={variant._id} variant={variant} attributes={attributes}
                                                       orderItemId={orderItemId}
                                                       _handleUpdateStateOrderItem={_handleUpdateStateOrderItem}
                                                       selectedProduct={selectedProduct} artworks={artworks}
                                                       _handleAlert={_handleAlert} order_type={order_type}/>
                    })
                }
                </tbody>
            </table>
        )
    }
}

export default ProductLineVariantsTable
