export const listStoreModes = [
    {name: 'Normal', value: 'normal'},
    {name: 'Built-in payment', value: 'merchize-payment'},
    {name: 'Marketplace', value: 'marketplace'}
]

export const productLabelOptions = [
    {name: 'Product', value: 'product'},
    {name: 'Branding', value: 'branding'},
]

export const listCurrencies = [
    {name: 'USD', value: 'USD'},
    {name: 'VND', value: 'VND'}
]

export const availableTiktokOptions = [
    {name: 'All', value: 'all'},
    {name: 'Yes', value: 'yes'},
    {name: 'No', value: 'no'},
]