import React from 'react'
import PropTypes from 'prop-types'
import {Checkbox, DatePicker} from "antd"

Date.propTypes = {
    startDate: PropTypes.any,
    startTime: PropTypes.any,
    activeEndDate: PropTypes.bool,
    endDate: PropTypes.any,
    endTime: PropTypes.any,
    validateStart: PropTypes.string,
    validateEnd: PropTypes.string,
    onChangeStartDate: PropTypes.func.isRequired,
    onChangeStartTime: PropTypes.func.isRequired,
    onChangeActiveEndDate: PropTypes.func.isRequired,
    onChangeEndDate: PropTypes.func.isRequired,
    onChangeEndTime: PropTypes.func.isRequired
}

function Date(props) {
    const {
        startDate,
        startTime,
        activeEndDate,
        endDate,
        endTime,
        validateStart,
        validateEnd,
        onChangeStartDate,
        onChangeStartTime,
        onChangeActiveEndDate,
        onChangeEndDate,
        onChangeEndTime
    } = props

    return (
        <div className="FormItem">
            <label htmlFor="activeDate" className="LabelDiscount">Actives date</label>
            <div className="SelectDate">
                <div className="Date">
                    <label htmlFor="StartDate">Start date</label>
                    <DatePicker
                        className={`${!!validateStart && validateStart.length > 0 && 'Error'}`}
                        value={startDate}
                        id="StartDate"
                        format="DD/MM/YYYY"
                        onChange={onChangeStartDate}
                    />
                </div>

                <div className="Time">
                    <label htmlFor="StartTime">Start time (+7)</label>
                    <DatePicker
                        className={`${!!validateStart && validateStart.length > 0 && 'Error'}`}
                        value={startTime}
                        id="StartTime"
                        format="HH:mm"
                        picker="time"
                        onChange={onChangeStartTime}
                    />
                </div>
            </div>

            <Checkbox checked={activeEndDate} onChange={onChangeActiveEndDate}>
                Set end date
            </Checkbox>

            <div className="SelectDate">
                <div className="Date">
                    <label htmlFor="EndDate">End date</label>
                    <DatePicker
                        className={`${!!validateEnd && validateEnd.length > 0 && 'Error'}`}
                        value={endDate}
                        disabled={!activeEndDate}
                        format="DD/MM/YYYY"
                        id="EndDate"
                        onChange={onChangeEndDate}
                    />
                </div>

                <div className="Time">
                    <label htmlFor="EndTime">End time (+7)</label>
                    <DatePicker
                        className={`${!!validateEnd && validateEnd.length > 0 && 'Error'}`}
                        value={endTime}
                        disabled={!activeEndDate}
                        format="HH:mm"
                        id="EndTime"
                        picker="time"
                        onChange={onChangeEndTime}
                    />
                </div>
            </div>
        </div>
    )
}

export default Date