import React from 'react'
import {reqCancelJobsByIds, reqRunJobsByIds} from '../../../../services/api/OrdersServices'
import {notification} from 'antd'
import {ObjectInspector} from 'react-inspector'

export const bulkRunJobs = async (ids, onSuccess) => {
    try {
        if (!Array.isArray(ids) || ids.length === 0) {
            throw new Error('Job ids invalid')
        }

        const {success, message, data} = await reqRunJobsByIds(ids)

        if (!success) throw new Error(message)

        if (data.error === 0) {
            notification.success({
                message: 'The jobs has been run',
                description: <ObjectInspector data={data}/>,
                duration: 0,
            })
        } else if (data.success === 0) {
            notification.error({
                message: `The jobs can't run`,
                description: <ObjectInspector data={data}/>,
                duration: 0,
            })
        } else {
            notification.info({
                message: `Some jobs can't run`,
                description: <ObjectInspector data={data}/>,
                duration: 0,
            })
        }

        onSuccess()
    } catch (e) {
        notification.error({
            message: 'ERROR',
            description: e.message,
            duration: 0,
        })
    }
}

export const bulkCancelJobs = async (ids, onSuccess) => {
    try {
        if (!Array.isArray(ids) || ids.length === 0) {
            throw new Error('Job ids invalid')
        }

        const {success, message, data} = await reqCancelJobsByIds(ids)

        if (!success) throw new Error(message)

        if (data.error === 0) {
            notification.success({
                message: 'The jobs has been cancel',
                description: <ObjectInspector data={data}/>,
                duration: 0,
            })
        } else if (data.success === 0) {
            notification.error({
                message: `The jobs can't cancel`,
                description: <ObjectInspector data={data}/>,
                duration: 0,
            })
        } else {
            notification.info({
                message: `Some jobs can't cancel`,
                description: <ObjectInspector data={data}/>,
                duration: 0,
            })
        }

        onSuccess()
    } catch (e) {
        notification.error({
            message: 'ERROR',
            description: e.message,
            duration: 0,
        })
    }
}
