import createApiServices from "./createApiServices"

//const prefixURL = 'http://localhost:6100/exports-factory/'
const prefixURL = process.env.NODE_ENV === 'production' ? '/api/export-order' : 'https://fulfillment-staging.merchize.com/api/export-order'
const api = createApiServices({baseUrl: prefixURL})

export const pushFulfillment = async (orderId, fulfillmentId, items, forcePush = false) => {
    return api.makeAuthRequest({
        url: `/exports-factory/${orderId}/${fulfillmentId}/push-custom`,
        method: forcePush ? 'PUT' : 'POST',
        data: items
    })
}

export const getExportFulfillment = async (orderId, fulfillmentId) => {
    return api.makeAuthRequest({
        url: `/exports-factory/${orderId}/${fulfillmentId}`,
        method: 'GET'
    })
}

export const pushOrder = async (orderId, force = false) => {
    return api.makeAuthRequest({
        url: `/exports-factory/${orderId}/push`,
        method: force ? 'PUT' : 'POST'
    })
}
