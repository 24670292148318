import React from 'react'
import PropTypes from 'prop-types'
import {
    listStatus,
    listFilterType,
    listSource,
    listProducts,
    listStatusCharge
} from "../contants/contanstFilter"
import FilterDateRange from "../share/FilterDateRange"
import FilterSelect from "../share/FilterSelect"

RequestFilters.propTypes = {
    needCharge: PropTypes.any,
    sources: PropTypes.array,
    requestID: PropTypes.array,
    orderID: PropTypes.array,
    storeID: PropTypes.array,
    created: PropTypes.any,
    updated: PropTypes.any,
    status: PropTypes.array,
    type: PropTypes.array,
    charge_status: PropTypes.array,
    onChangeStatus: PropTypes.func.isRequired,
    onChangeType: PropTypes.func.isRequired,
    onChangeDateCreated: PropTypes.func.isRequired,
    onChangeDateUpdated: PropTypes.func.isRequired,
    onChangeMultiInput: PropTypes.func.isRequired,
    onChangeSource: PropTypes.func.isRequired,
    onChangeNeedCharge: PropTypes.func.isRequired,
    onChangeProductStatus: PropTypes.func.isRequired
}

function RequestFilters(props) {
    const {
        status,
        type,
        sources,
        production_status,
        charge_status,
        onChangeStatus,
        onChangeType,
        onChangeDateCreated,
        onChangeDateUpdated,
        onChangeSource,
        onChangeProductStatus,
        onChangeChargeStatus
    } = props

    const _onResetFilter = (type) => {
        if (type === 'status') {
            if (typeof onChangeStatus === 'function') {
                onChangeStatus([])
            }
        } else if (type === 'sources') {
            if (typeof onChangeSource === 'function') {
                onChangeSource([])
            }
        } else if (type === 'production_status') {
            if (typeof onChangeProductStatus === 'function') {
                onChangeProductStatus([])
            }
        } else if (type === 'charge_status') {
            if (typeof onChangeChargeStatus === 'function') {
                onChangeChargeStatus([])
            }
        } else {
            if (typeof onChangeType === 'function') {
                onChangeType([])
            }
        }
    }

    return (
        <div className='RequestFilters'>
            <div className="Filters">
                <FilterDateRange
                    dropdownClassName="Created"
                    placeholder={['Created start', 'Created end']}
                    onChangeDate={onChangeDateCreated}
                />

                <FilterDateRange
                    dropdownClassName="Updated"
                    placeholder={['Updated start', 'Updated end']}
                    onChangeDate={onChangeDateUpdated}
                />

                <FilterSelect
                    placeholder='-- Status --'
                    listFilters={listStatus}
                    listSelected={type}
                    name='status'
                    onChangeFilters={onChangeStatus}
                />

                <FilterSelect
                    placeholder='-- Type --'
                    listFilters={listFilterType}
                    listSelected={status}
                    name='type'
                    onChangeFilters={onChangeType}
                />

                <FilterSelect
                    placeholder='-- Source --'
                    listFilters={listSource}
                    listSelected={sources}
                    name='sources'
                    onChangeFilters={onChangeSource}
                />

                <FilterSelect
                    placeholder='-- Production Status --'
                    listFilters={listProducts}
                    listSelected={production_status}
                    name='production_status'
                    onChangeFilters={onChangeProductStatus}
                />

                <FilterSelect
                    placeholder='-- Charge Status --'
                    listFilters={listStatusCharge}
                    listSelected={charge_status}
                    name='charge_status'
                    onChangeFilters={onChangeChargeStatus}
                />
            </div>

            {
                (!!type || !!status) && <div className="DisplayFilter">
                    {
                        !!type && type.length > 0 && <div className="ProductStatus">
                            <span className="Title">Type request: </span>
                            {
                                type.map((item, index) => (
                                    <span key={index}>{item}</span>
                                ))
                            }
                            <i className='ti-close' onClick={() => _onResetFilter('type')}/>
                        </div>
                    }


                    {
                        !!status && status.length > 0 &&
                        <div className="ProductStatus">
                            <span className="Title">Status request: </span>
                            {
                                status.map((item, index) => (
                                    <span key={index}>{item}</span>
                                ))
                            }
                            <i className='ti-close' onClick={() => _onResetFilter('status')}/>
                        </div>
                    }

                    {
                        !!sources && sources.length > 0 &&
                        <div className="ProductStatus">
                            <span className="Title">Sources: </span>
                            {
                                sources.map((item, index) => (
                                    <span key={index}>{item}</span>
                                ))
                            }
                            <i className='ti-close' onClick={() => _onResetFilter('sources')}/>
                        </div>
                    }

                    {
                        !!charge_status && charge_status.length > 0 &&
                        <div className="ProductStatus">
                            <span className="Title">Charge status: </span>
                            {
                                charge_status.map((item, index) => (
                                    <span key={index}>{item}</span>
                                ))
                            }
                            <i className='ti-close' onClick={() => _onResetFilter('charge_status')}/>
                        </div>
                    }

                    {
                        !!production_status && production_status.length > 0 &&
                        <div className="Variants">
                            <span className="Title">Product status: </span>
                            {
                                production_status.map((item, index) => (
                                    <span key={index}>{item}</span>
                                ))
                            }
                            <i className='ti-close' onClick={() => _onResetFilter('production_status')}/>
                        </div>
                    }
                </div>
            }
        </div>
    )
}

export default RequestFilters
