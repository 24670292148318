import React, {Component} from 'react'

class ProductLineVariantAttribute extends Component {
    render() {
        const {variant, attribute} = this.props
        const {options = []} = variant
        const attValue = options.find(option => option.attribute === attribute._id) || ""

        return (
            <td className="ProductLineVariantAttribute">
                {!!attValue && attValue.name}
            </td>
        )
    }
}

export default ProductLineVariantAttribute
