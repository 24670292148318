import React, {Component} from 'react'
import LoginPage from './LoginPage'

class LoginPageContainer extends Component {
    render() {
        return (
            <div className='LoginPageContainer bg-light vh-100'>
                <LoginPage/>
            </div>
        )
    }
}

export default LoginPageContainer
