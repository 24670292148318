import React, {Component} from 'react'
import PropTypes from 'prop-types'
import Clipboard from "react-clipboard.js"
import formatCurrency from "../../../../helpers/common/formatCurrency"
import imageDefault from "../../../../helpers/common/imageDefault"

class OrderItemProduct extends Component {
    state = {
        copyImage: "Copy image url",
        copyProduct: "Copy product url"
    }

    _handleCopyAddressSuccess = () => {
        this.setState({
            copyImage: "Copied!",
        })

        setTimeout(() => {
            this.setState({
                copyImage: "Copy image url",
            })
        }, 2000)
    }

    _handleCopyProductUrl = () => {
        this.setState({
            copyProduct: "Copied!",
        })

        setTimeout(() => {
            this.setState({
                copyProduct: "Copy product title",
            })
        }, 2000)
    }

    render() {
        const {product, currency, price, quantity, variant, sku} = this.props
        const {copyImage, copyProduct} = this.state
        const {sku: skuVariant} = Object.assign({}, variant)
        const vSku = skuVariant || sku || ''

        return (
            <td className="OrderItemProduct">
                <div className="row">
                    <div className="col-4">
                        <div className="position-relative ThumbnailWrapper">
                            <img
                                src={product.preview}
                                alt="Product thumbnail"
                                onError={(e) => {
                                    e.target.onerror = null;
                                    e.target.src = imageDefault
                                }}
                            />
                        </div>

                        <Clipboard component="div" data-clipboard-text={product.preview}
                                   className="cursor-pointer d-inline-block CopyImageUrl mt-2"
                                   onSuccess={this._handleCopyAddressSuccess}>
                            <div className="Tooltip3 Bottom">
                                <i className="far fa-copy"/>

                                <div className="TooltipContent Clipboard">
                                    <div className="ContentInner text-center">
                                        <small className="text-monospace">{copyImage}</small>
                                    </div>
                                </div>
                            </div>
                        </Clipboard>
                    </div>

                    <div className="col-8">
                        <div className="mb-2">
                            <a href={product.url} target="_blank" rel="noopener noreferrer"
                               className="text-break mr-3">{product.title}</a>
                            <Clipboard data-clipboard-text={product.title} component="span"
                                       className="d-inline-block cursor-pointer" onSuccess={this._handleCopyProductUrl}>
                                <div className="Tooltip3 Bottom">
                                    <i className="far fa-copy"/>

                                    <div className="TooltipContent Clipboard">
                                        <div className="ContentInner text-center">
                                            <small className="text-monospace">{copyProduct}</small>
                                        </div>
                                    </div>
                                </div>
                            </Clipboard>
                        </div>

                        <div className="d-flex mb-1">
                            <span className="Label">Price:</span>
                            <span>{formatCurrency(currency, price)}</span>
                        </div>

                        {
                            !!vSku &&
                            <div className="d-flex mb-1">
                                <span className="Label">SKU:</span>
                                <span className='text-break'>{vSku}</span>
                            </div>
                        }

                        <div className="Quantity d-flex">
                            <span className="Label">Quantity:</span>
                            <span className="Number">{quantity}</span>
                        </div>
                    </div>

                    <div className="col-12 mt-3">
                        <div className='CustomVariant'>
                            <span className="Label">Variant:</span>
                            <span className='text-break'>{variant.title}</span>
                        </div>
                        <div className='CustomVariable'>
                            <ul>
                                {
                                    variant.options.length > 0 && variant.options.map(item =>
                                        <li className="d-flex mb-1" key={item.key}>
                                            <span className="Label mr-1">{item.key}:</span>
                                            <span className={`text-break ${item.key}`}>{item.value}</span>
                                        </li>
                                    )
                                }
                            </ul>
                        </div>
                    </div>
                </div>
            </td>
        )
    }
}

OrderItemProduct.propTypes = {
    product: PropTypes.object,
    currency: PropTypes.string,
    price: PropTypes.number,
    quantity: PropTypes.number,
    variant: PropTypes.object,
    sku: PropTypes.string
}

export default OrderItemProduct
