import React from 'react'
import { Input,Select } from 'antd'
import { SearchOutlined } from '@ant-design/icons'

const {Option}=Select

const ShippingZoneFilter = (props) => {
  const { title, setTitle, supplier, setSupplier,listSupplier } = props
  const handleTitleChange = (e) => {
    const {value}= e.target
    setTitle(value)
  }

  const handleSupplierChange = (value) => {
    setSupplier(value)
  }
  return (
    <div className="d-flex" style={{ margin: "29px 0 0 0" }}>
    <Input
      style={{ width: "546px" }}
      prefix={<SearchOutlined />}
      placeholder="Search zone name"
      onChange={handleTitleChange}
      value={title}
      name="title"
      allowClear
    ></Input>
    <Select
      placeholder="Choose Supplier"
      className='filterSelect'
      onChange={handleSupplierChange}
      value={supplier|| undefined}
      allowClear
      filterOption={(input, option) => 
        option.children.toLocaleLowerCase().includes((input || "").toLocaleLowerCase())
      }
      showSearch
    >
      {
        listSupplier && listSupplier.map((supplier,index)=> (
          <Option value={supplier._id} key={index}>{supplier.name}</Option>
        ))
      }

    </Select>
  </div>
  )
}

export default ShippingZoneFilter