import React, {Component} from 'react'
import PropTypes from 'prop-types'

class OrderArtwork extends Component {
    render() {
        const {artwork} = this.props

        let outputArtwork = ""
        switch (artwork) {
            case "completed":
                outputArtwork = <i className="fas fa-check text-success" title="Completed"/>
                break
            case "missing":
                outputArtwork = <i className="fas fa-times text-danger" title="Missing"/>
                break
            case "incomplete":
                outputArtwork = <i className="fas fa-exclamation-triangle text-warning" title="Incomplete"/>
                break
            default:
                console.log('Please pick artwork')
        }

        return (
            <td className="OrderArtwork text-center">
                {outputArtwork}
            </td>
        )
    }
}

OrderArtwork.propTypes = {
    artwork: PropTypes.string.isRequired,
}

export default OrderArtwork
