import React, { Component } from 'react'
import OrderJobDate from "./table-data/OrderJobDate"
import OrderJobStatus from "./table-data/OrderJobStatus"
import OrderJobType from "./table-data/OrderJobType"
import OrderJobTotalSent from "./table-data/OrderJobTotalSent"
import OrderJobSendTime from "./table-data/OrderJobSendTime"
import OrderJobError from "./table-data/OrderJobError"
import PropTypes from 'prop-types'
import { Button, Modal, Tooltip } from 'antd'
import { ObjectInspector } from 'react-inspector'
import WebhookActions from './table-data/WebhookActions'

class OrderJobRow extends Component {
    state = {
        visible: false,
    }

    showModal = () => {
        this.setState({
            visible: true,
        })
    }

    handleCancel = () => {
        this.setState({ visible: false })
    }

    render() {
        const { visible } = this.state
        const { job, permissionsData, onFetchWebhook } = this.props
        const { created, status, total_sent, type, send_time, is_sent, error_message = "" } = job
        
        return (
            <tr className="OrderJobRow">
                <td className='Information'>
                    <Tooltip title='Information'>
                        <span className="ti-info-alt" onClick={this.showModal} />
                    </Tooltip>
                    <Modal
                        visible={visible}
                        title="Information"
                        onCancel={this.handleCancel}
                        footer={[
                            <Button key="back" onClick={this.handleCancel}>
                                Close
                            </Button>
                        ]}
                    >
                        <ObjectInspector depth={0} path="root.value" data={job} />
                    </Modal>
                </td>
                <OrderJobDate created={created} />
                <OrderJobStatus isSent={is_sent} status={status} />
                <OrderJobType type={type} />
                <OrderJobTotalSent totalSent={total_sent} />
                <OrderJobSendTime sendTime={send_time} />
                <OrderJobError errorMessage={error_message} />
                <WebhookActions job={job} permissionsData={permissionsData} onFetchWebhook={onFetchWebhook}/>
            </tr>
        )
    }
}

OrderJobRow.propTypes = {
    job: PropTypes.object.isRequired,
}

export default OrderJobRow
