import React, {Component} from 'react'
import PropTypes from 'prop-types'
import {getArtworksByOrderItemV3, getValidateArtwork} from "../../../../../services/api/ArtworksServices"
import broker from "../../../OrderBroker"
import OrderItemGroupArtworkItem from "../../shared/OrderItemGroupArtworkItem"
import {getLanguageStore} from "../../../../../services/api/PlatformIntergationServices"

class OrderItemDesignV2 extends Component {
    state = {
        error: "",
        validates: [],
        showAll: false,
    }

    _subscription1 = null

    componentDidMount() {
        this._subscription1 = broker.subscribe('ARTWORK_GET', this.props._fetchArtworks)
    }

    componentWillUnmount() {
        broker.unsubscribe(this._subscription1)
    }

    _onChangeShowAll = () => {
        this.setState({
            showAll: !this.state.showAll
        })
    }


    _fetchLanguageStore = async () => {
        this.setState({
            languageError: ''
        })

        try {
            const {namespace} = this.props
            const response = await getLanguageStore(namespace)

            const {success, data, message: error} = response

            if (!success) {
                return this.setState({
                    languageError: error
                })
            }

            this.setState({
                language: (data.hasOwnProperty('preferred_language') ? data.preferred_language : 'vi')
            })
        } catch (e) {
            this.setState({
                languageError: e.message
            })
        }
    }


    render() {
        const {showAll} = this.state
        const {artworks, artworksMore, error, piecesPerPack, validates} = this.props
        const {packageName, orderItemId, fulfillmentStatus, tags, namespace, language, languageError} = this.props

        return (
            <td className="OrderItemDesignV3">
                {
                    !!artworks.length && <div className="listArtwork">
                        {
                            artworks.map((item, index) => (
                                <OrderItemGroupArtworkItem
                                    namespace={namespace}
                                    language={language}
                                    languageError={languageError}
                                    key={`first_${index}`}
                                    tags={tags}
                                    artwork={item}
                                    validates={validates}
                                    typeDisplay="first"
                                    piecesPerPack={piecesPerPack}
                                    packageName={packageName}
                                    orderItemId={orderItemId}
                                    fulfillmentStatus='unfulfilled'
                                />
                            ))
                        }
                    </div>
                }

                {
                    !showAll && !!artworksMore && artworksMore.length > 0 ?
                        <div className="ShowAll" onClick={this._onChangeShowAll}>
                            View more
                        </div> :
                        <>
                            <div className="listArtwork">
                                {
                                    showAll && !!artworksMore && artworksMore.length > 0 && artworksMore.map((item, index) => (
                                        <OrderItemGroupArtworkItem
                                            namespace={namespace}
                                            language={language}
                                            languageError={languageError}
                                            key={`first_${index}`}
                                            tags={tags}
                                            artwork={item}
                                            validates={validates}
                                            typeDisplay="more"
                                            piecesPerPack={piecesPerPack}
                                            packageName={packageName}
                                            orderItemId={orderItemId}
                                            fulfillmentStatus={fulfillmentStatus}
                                        />
                                    ))
                                }
                            </div>
                            {
                                !!artworksMore && artworksMore.length > 0 &&
                                <div className="ShowAll" onClick={this._onChangeShowAll}>
                                    Hide
                                </div>
                            }
                        </>
                }

                {
                    !!error || !!this.state.error &&
                    <div className="text-danger">{error || this.state.error}</div>
                }
            </td>
        )
    }
}

OrderItemDesignV2.propTypes = {
    language: PropTypes.string,
    languageError: PropTypes.string,
    namespace: PropTypes.string,
    packageName: PropTypes.string,
    orderItemId: PropTypes.string.isRequired,
    product: PropTypes.object.isRequired,
    orderId: PropTypes.string.isRequired,
    productMapId: PropTypes.string,
    fulfillmentStatus: PropTypes.string
}

export default OrderItemDesignV2
