import React from 'react'

function FastProductionRulesHeader(props) {
    const { permissionsData = [] } = { ...props }
    const canEdit = permissionsData.includes('ffm_fast_production_rule_update')
    const canDelete = permissionsData.includes('ffm_fast_production_rule_delete')
    const canViewDetails = permissionsData.includes('ffm_fast_production_rule_detail')

    return (
        <thead className='FastProductionRulesHeader thead-light'>
            <tr>
                <th>Priority</th>
                <th>Store</th>
                <th>Product Type</th>
                <th>Country</th>
                <th>Supplier</th>
                <th
                    style={{ minWidth: '170px' }}
                >
                    Created - Updated
                </th>
                <th>Status</th>
                {
                    (canEdit || canViewDetails || canDelete) &&
                    <th>Action</th>
                }
            </tr>
        </thead>
    )
}

export default FastProductionRulesHeader