import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap'
import { Button, Input, Select } from "antd"
import { createUser, createUserAuth } from "../../../../services/api/UserService"
import { getRoleList, getDepartmentList } from '../../../../services/api/UserService'

const { Option } = Select

function UserCreateModal(props) {
    const { isOpen, toggle, refresh } = props
    const [error, setError] = useState('')
    const [loading, setLoading] = useState(false)
    const [validateError, setValidateError] = useState({ usernameError: '', passwordError: '', confirmError: '' })
    const [state, setState] = useState({ username: '', password: '', re_password: '' })
    const [listRoles, setListRoles] = useState([])
    const [listDepartment, setListDepartment] = useState([])
    const [roles, setRoles] = useState([])
    const [department, setDepartment] = useState('')

    const _handleChangeInput = (e) => {
        const { name, value } = e.target
        setValidateError({ usernameError: '', passwordError: '', confirmError: '' })

        setState({
            ...state,
            [name]: value.trim()
        })
    }

    const _handleChangeRole = (value) => {
        setRoles(value)
    }

    const _handleChangeDepartment = (value) => {
        setDepartment(value)
    }

    const _userValidator = () => {
        if (state.username.trim() === '') {
            setValidateError({
                ...validateError,
                usernameError: 'Username is null or invalid!'
            })
            return false
        }
        if (state.password.trim() === '') {
            setValidateError({ ...validateError, passwordError: 'Password is null or invalid!' })
            return false
        }

        if (state.password.trim() !== state.re_password.trim()) {
            setValidateError({ ...validateError, confirmError: 'Password does not match!' })
            return false
        }
        if (state.password.trim().length <= 7) {
            setValidateError({ ...validateError, passwordError: 'Password must be at least 8 characters!' })
            return false
        }

        return true
    }

    const _createUser = (e) => {
        e.preventDefault()

        const isValid = _userValidator()

        if (!isValid) return

        _reqAddNew()
    }

    const _reqAddNew = async () => {
        setLoading(true)
        setError('')

        try {
            const payload = {
                username: state.username,
                password: state.password,
                roles: roles
            }
            if(department) payload.department = department
            const { success, message } = await createUserAuth(payload)

            if (!success) {

                setLoading(false)
                return setError(message)
            }

            setLoading(false)
            toggle()
            await refresh()
            // reloadUsers()
        } catch (e) {
            setLoading(false)
            if (e.response.status !== 200) {
                setError(e.response.data.message)
            } else {
                setError(e.message)
            }
        }
    }

    const _closeModal = () => {
        setError('')
        setState({ username: '', password: '', re_password: '' })
        setValidateError({ usernameError: '', passwordError: '', confirmError: '' })
        setLoading(false)
        toggle()
    }

    const getUserRoles = async () => {
        try {
            const { success, data, message } = await getRoleList()

            if (!success) return setError(message)

            const { roles } = data

            setListRoles(roles)
        } catch (e) {
            setError(e.message)
        }
    }

    const getUserDepartment = async () => {
        try {
            const { success, data, message } = await getDepartmentList()

            if (!success) return setError(message)

            const { departments } = data

            setListDepartment(departments)
        } catch (e) {
            setError(e.message)
        }
    }

    useEffect(() => {
        getUserRoles()
        getUserDepartment()
    }, [])

    return (
        <div>
            <Modal isOpen={isOpen} toggle={_closeModal} className={props.className}>
                <ModalHeader toggle={toggle}>Create New User</ModalHeader>
                <ModalBody>
                    <div className="form-group">
                        <label>Username</label>
                        <Input name="username" value={state.username || ''} onChange={_handleChangeInput} />
                        {validateError.usernameError && <div className="mt-1 text-danger">{validateError.usernameError}</div>}
                    </div>
                    <div className="form-group">
                        <label>Password</label>
                        <Input type="password" name="password" value={state.password || ''} onChange={_handleChangeInput} />
                        {validateError.passwordError && <div className="mt-1 text-danger">{validateError.passwordError}</div>}
                    </div>
                    <div className="form-group">
                        <label>Confirm password</label>
                        <Input type="password" name="re_password" value={state.re_password || ''}
                            onChange={_handleChangeInput} />
                        {validateError.confirmError && <div className="mt-1 text-danger">{validateError.confirmError}</div>}
                    </div>
                    <div className="form-group">
                        <label className="mr-3">Roles</label>
                        <Select
                            mode="multiple"
                            placeholder="Please select"
                            value={roles || []}
                            onChange={_handleChangeRole}
                            style={{ width: '100%' }}
                        >
                            {
                                !!listRoles && listRoles.length > 0 && listRoles.map(item => (
                                    <Option key={item._id} value={item._id}>{item.name}</Option>
                                ))
                            }
                        </Select>
                    </div>
                    <div className="form-group">
                        <label className="mr-3">Department</label>
                        <Select
                            // mode="multiple"
                            placeholder="Please select"
                            value={department || []}
                            onChange={_handleChangeDepartment}
                            style={{ width: '100%' }}
                        >
                            {
                                !!listDepartment && listDepartment.length > 0 && listDepartment.map(item => (
                                    <Option key={item._id} value={item._id}>{item.name}</Option>
                                ))
                            }
                        </Select>
                    </div>
                    {error && <div className="mt-3 text-danger">{error}</div>}

                </ModalBody>
                <ModalFooter>
                    <button className='btn btn-secondary mr-2' onClick={_closeModal}>Cancel</button>
                    <button className='btn btn-primary' onClick={_createUser} disabled={loading}>
                        Save
                    </button>
                </ModalFooter>
            </Modal>
        </div>
    )
}

export default UserCreateModal

