import React, { useState } from 'react'
import { Select } from 'antd'
import {useOrderFulfillment} from "../store";
import {Modal, ModalHeader, ModalBody, ModalFooter} from 'reactstrap'
import SelectShippingMethod from './SelectShippingMethod';

const { Option } = Select

/**
 * @param {Object} props
 * @param {Object} props.pack
 * @returns {JSX.Element}
 */
export default (props) => {
    const {pack} = props
    const {state, dispatch} = useOrderFulfillment()
    const [openModal, setOpenModal] = useState(false)

    const _handleChangeSupplier = (supplier) => {
        if(state.order.order_type === 'TIKTOK' && pack.shippingPlan && pack.shippingPlan.slug === 'shipbytiktok'){
            const supplierSelected = state.suppliers.filter(sup => sup._id === supplier)
            if(supplierSelected[0].available_tiktok === true && supplierSelected[0].shipbytiktok === true){
                const {sku_prefix}= {...state.suppliers.find(item => item._id === supplier)}
                dispatch({type: 'CHANGE_SUPPLIER', payload: {pack: pack, supplier, sku_prefix: sku_prefix}})
            }else{
                setOpenModal(true)
            }
        }else{
            const {sku_prefix}= {...state.suppliers.find(item => item._id === supplier)}
            dispatch({type: 'CHANGE_SUPPLIER', payload: {pack: pack, supplier, sku_prefix: sku_prefix}})
        }
    }

    const _handleChangeShippingCarrier = (shippingCarrier) => {
        dispatch({type: 'CHANGE_SHIPPING_CARRIER', payload: {pack: pack, shippingCarrier}})
    }

    const _isDisabled = () => pack.isProcessing() || pack.isFulfilled() || state.isProcessing || state.isProcessed

    const _formSupplier = () => {
        const options = state.suppliers && state.suppliers.length > 0 && state.suppliers.sort((a, b) => a.name.localeCompare(b.name)).map(item => (
            <Option key={item._id} value={item._id} label={item.name}>{item.name}</Option>
        ))

        return (
            <Select
                defaultValue={pack.supplier}
                value={pack.supplier}
                onChange={_handleChangeSupplier}
                placeholder="Select supplier"
                className="split-package__supplier-select mr-3"
                disabled={_isDisabled()}
                showSearch
                filterOption={(input, option) => option.label.toLowerCase().includes(input.toLowerCase())}
            >
                {options}
            </Select>
        )
    }

    const _formShippingCarrier = () => {
        const {suppliers = [], order = {}} = state
        const supplier = suppliers.find(item => item._id === pack.supplier)
        const {sku_prefix}= {...supplier}
        const {order_type} = order

        if (!supplier || !state.suppliersIdsRequireShippingCarrier.includes(supplier._id) || (!!sku_prefix && sku_prefix==='MDUS' && order_type !== "TIKTOK")) {
            return null
        }

        return (
            <SelectShippingMethod
                defaultValue={pack.shippingCarrier}
                value={pack.shippingCarrier}
                onChange={_handleChangeShippingCarrier}
                placeholder="Select shipping carrier"
                className="split-package__supplier-select split-package__supplier-is-carrier"
                disabled={_isDisabled()}
                style={{ width: 255 }}
                skuPrefix={sku_prefix}
            />
        )
    }

    const closeModalEdit = () => {
        setOpenModal(false)
    }

    return (
        <div className="split-package__supplier">
            {_formSupplier()}
            {_formShippingCarrier()}

            <Modal isOpen={openModal} toggle={closeModalEdit} className='Popup noti'>
                    <ModalBody>
                        <div>
                            Supplier doesn't support ship by tiktok
                        </div>
                    </ModalBody>
                    <ModalFooter>
                        <div className="d-flex align-items-center justify-content-between w-100">
                            <button className='btn btn-secondary' onClick={closeModalEdit}>Close</button>
                        </div>
                    </ModalFooter>
                </Modal>
        </div>
    )
}
