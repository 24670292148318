import React, {Component} from 'react'
import PropTypes from 'prop-types'

class OrderTags extends Component {

    _handleFilterTags= (tag) => {
        const {handleChangeFilterState} = this.props
        handleChangeFilterState(tag, '', 'tags')
    }

    _renderTags = () => {
        const {tags, _id: orderId} = this.props.order
        if (!Array.isArray(tags) || tags.length <= 0) {
            return null
        }

        return tags.map((tag, index) => {
            if (tag !== 'thank_card' && tag !== 'thank_you_card') {
                return (
                    <span onClick={() => this._handleFilterTags({tags: [tag]})} key={`${tag}_${index}_${orderId}`} className="badge badge-secondary mr-2 mb-2 cursor-pointer">
                    {tag}
                </span>
                )
            }

            return null
        })
    }

    render() {
        const renderTags = this._renderTags()

        return (
            <td className="OrderTags">
                {renderTags}
            </td>
        )
    }
}

OrderTags.propTypes = {
    order: PropTypes.object.isRequired,
    handleChangeFilterState : PropTypes.func.isRequired
}

export default OrderTags
