import React, {Component} from 'react'
import DocTitleByStore from '../../dashboard/shared/DocTitleByStore'
import OrdersPage from './OrdersPage'
import parseSearchQuery from '../../../helpers/routing/parseSearchQuery'
import {getListSuppliersV2} from '../../../services/api/SuppliersServices'
import SuppliersContext from '../context/SuppliersContext'

class OrdersPageContainer extends Component {
    state = {
        suppliers: [],
    }

    componentDidMount() {
        this._fetchListSuppliers()
    }

    _fetchListSuppliers = async () => {
        try {
            const {data, success, message} = await getListSuppliersV2({active: true})

            if (!success) throw new Error(message)

            this.setState({
                suppliers: data.suppliers,
            })
        } catch (e) {
            console.log(e)
        }
    }

    render() {
        const urlParams = parseSearchQuery()
        const {page = 1} = urlParams
        const currentPageParsed = Number(page)

        return (
            <section className="OrderContainers">
                <DocTitleByStore title="Orders"/>

                <SuppliersContext.Provider value={this.state.suppliers}>
                    <OrdersPage {...this.props} currentPage={currentPageParsed}/>
                </SuppliersContext.Provider>
            </section>
        )
    }
}

export default OrdersPageContainer
