import React, {Component} from 'react'
import PropTypes from 'prop-types'
import { CloseOutlined, CopyOutlined } from '@ant-design/icons'
import { Button, Tooltip, Checkbox, Popover } from 'antd'


class ItemReplaced extends Component {
    state = {
        isOpen: false
    }

    handleOpenChange = () => {
        this.setState({
            isOpen: !this.state.isOpen,
        })
    }
    
    render() {
        const { isOpen } = this.state
        const { id, item, onChange} = this.props
        const { _id, reference_number, isCheck = false, solution_note } = Object.assign({}, item)
        
        return (
            <div className="item-replaced">
                <Checkbox
                    className="mr-1"
                    checked={isCheck}
                    onChange={e => onChange(id, _id, e.target.checked)}
                />
                <span>{typeof reference_number === 'string' ? reference_number : reference_number.join(', ')}</span>

                {
                    solution_note &&
                    <Popover
                        content={solution_note}
                        title="Solution Note"
                        trigger="click"
                        open={isOpen}
                        onOpenChange={this.handleOpenChange}
                    >
                        <i className="icon-quill4 text-danger ml-3 cursor-pointer"/>
                    </Popover>
                }
            </div>
        )
    }
}

ItemReplaced.propTypes = {
    item: PropTypes.object.isRequired,
    onChange: PropTypes.func.isRequired
}

export default ItemReplaced
