import React from 'react'
import OrderPartnersPage from './OrderPartnersPage'
import DocTitleByStore from "../../dashboard/shared/DocTitleByStore"
import queryString from 'query-string'

const OrderPartnersContainer = (props) => {
    const {history} = props
    const {location} = history
    let params = {}
    if (location.search !== '') {
        const _params = queryString.parse(location.search)
        if(_params.package_status !== undefined) {
            const valuePackageStatus = _params.package_status
            delete _params.package_status
            _params.package_status = valuePackageStatus.split(",")
        }
        params = Object.assign(_params, _params.suppliers !== undefined ? {suppliers: [_params.suppliers]} : {})
    }

    return (
        <section className="NotificationContainer">
            <DocTitleByStore title="Package Management"/>
            <OrderPartnersPage params={params}/>
        </section>
    )
}

export default OrderPartnersContainer
