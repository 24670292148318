import React from "react"
import Variants from "./Variants"

const VariantsContainer = () => {
  return (
    <div className="VariantsContainer">
      <Variants />
    </div>
  )
}

export default VariantsContainer