import React, {useState} from 'react'
import PropTypes from 'prop-types'
import formatCurrency from "../../../../helpers/common/formatCurrency"
import classNames from 'classnames'
import Modal from "reactstrap/es/Modal"
import ModalBody from "reactstrap/es/ModalBody"
import ModalFooter from "reactstrap/es/ModalFooter"
import { Tooltip } from 'antd'

OrderItemProduct.propTypes = {
    variant: PropTypes.object,
    product: PropTypes.object,
    currency: PropTypes.string,
    quantity: PropTypes.number,
    price: PropTypes.number,
    sku: PropTypes.string
}

function OrderItemProduct(props) {
    const [showModalMockup, setShowModalMockup] = useState(false)
    const {product, currency, price, quantity, variant, sku, mockupUrl, mockupMessage, rowSpan} = props
    const {sku: skuVariant} = Object.assign({}, variant)
    const vSku = skuVariant || sku || ''

    const _handleToggleModalMockup = () => {
        setShowModalMockup(!showModalMockup)
    }

    return (
        <td className="OrderItemProduct" rowSpan={rowSpan}>
            <div className="row">
                <div className="col-4">
                    <div className="position-relative ThumbnailWrapper">
                        <img src={product.preview} alt="Product thumbnail"/>

                        <div className='thumbnail-bottom'>
                            <Tooltip title="View Mockup">
                                <div className={classNames('cursor-pointer', 'mt-2', 'mockup-open', {'disable' : mockupMessage || !mockupUrl})} onClick={_handleToggleModalMockup}>
                                    <i className="far fa-image"></i>
                                </div>
                            </Tooltip>
                        </div>
                    </div>
                </div>

                <div className="col-8">
                    <div className="mb-2">
                        <a href={product.url} target="_blank" rel="noopener noreferrer"
                           className="text-break mr-3">{product.title}</a>
                    </div>

                    <div className="d-flex mb-1">
                        <span className="Label">Price:</span>
                        <span>{formatCurrency(currency, price)}</span>
                    </div>

                    {
                        !!vSku &&
                        <div className="d-flex mb-1">
                            <span className="Label">SKU:</span>
                            <span className='text-break'>{vSku}</span>
                        </div>
                    }

                    <div className="Quantity d-flex">
                        <span className="Label">Quantity:</span>
                        <span className="Number">{quantity}</span>
                    </div>
                </div>

                <div className="col-12 mt-3">
                    {
                        !!variant && !!variant.options && variant.options.length > 0 && <div className='CustomVariant'>
                            <span className="Label">Variant:</span>
                            <span className='text-break'>{variant.title}</span>
                        </div>
                    }

                    <div className='CustomVariable'>
                        <ul>
                            {
                                !!variant && !!variant.options && variant.options.length > 0 && variant.options.map(item =>
                                    <li className="d-flex mb-1" key={item.key}>
                                        <span className="Label mr-1">{item.key}:</span>
                                        <span className={`text-break ${item.key}`}>{item.value}</span>
                                    </li>
                                )
                            }
                        </ul>
                    </div>
                </div>

                {
                    mockupMessage &&
                    <div className='col-12 mt-3 mockup-message'>
                        <i className="fas fa-exclamation-triangle"></i> {mockupMessage}
                    </div>
                }
            </div>

            {
                    mockupUrl && !mockupMessage &&
                    <Modal className='modal-mockup' isOpen={showModalMockup} toggle={_handleToggleModalMockup}>
                        <ModalBody>
                            <div className='mockup-image'>
                                <img src={mockupUrl} alt={product.title}/>
                            </div>
                        </ModalBody>
                        <ModalFooter>
                            <div className="d-flex justify-content-end">
                                <button className="btn border" onClick={_handleToggleModalMockup}>Cancel</button>
                            </div>
                        </ModalFooter>
                    </Modal>
                }
        </td>
    )
}

export default OrderItemProduct