import React, {Component} from 'react'
import PropTypes from 'prop-types'
import humanizeTime from '../../../helpers/time/humanizeTime'
import ArtworksTableThumbnail from "./ArtworksTableThumbnail"

class ArtworksTableRow extends Component {
    render() {
        const {artwork} = this.props
        const {thumbnail, namespace, product_id, side, platform, mime_type, size, created, order_item_id, level, attributes} = artwork
        const sizeKb = Math.round(size / 100) / 10
        const textTime = humanizeTime(created)

        return (
            <tr className='ArtworksTableRow'>
                <ArtworksTableThumbnail thumbnail={thumbnail}/>
                <td>{product_id}</td>
                <td>{order_item_id}</td>
                <td>{level}</td>
                <td className="OrderTags">
                    {
                        (attributes !== null && attributes !== undefined && typeof attributes !== 'string') && attributes.map((item, index) => {
                            return <span key={index} className="badge badge-secondary mr-2 mb-2">{item}</span>
                        })
                    }
                </td>
                <td>{side}</td>
                <td>
                    {(platform !== null && platform !== undefined) && (
                        <div>
                            <div className="Platform">{platform.name || ''}</div>
                            <div className="Namespace">
                                <code>{namespace || ''}</code>
                            </div>
                        </div>
                    )}
                </td>
                <td>{mime_type}</td>
                <td>{sizeKb} kB</td>
                <td>{textTime}</td>
            </tr>
        )
    }
}

ArtworksTableRow.propTypes = {
    artwork: PropTypes.shape({
        item_id: PropTypes.string,
        platform: PropTypes.shape({
            _id: PropTypes.string.isRequired,
            status: PropTypes.string.isRequired,
            name: PropTypes.string.isRequired,
            created: PropTypes.string.isRequired
        }),
        mime_type: PropTypes.string,
        size: PropTypes.number,
        order_id: PropTypes.string,
        created: PropTypes.string
    }).isRequired
}

export default ArtworksTableRow