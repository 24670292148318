import React from 'react'
import FastProductionRulesPage from './FastProductionRulesPage'
import DocTitleByStore from '../../dashboard/shared/DocTitleByStore'
import '../styles/_index.scss'

function FastProductionRulesContainer() {
    return (
        <div className='FastProductionRulesContainer'>
            <DocTitleByStore title="Fast Production Rule"/>
            <FastProductionRulesPage/>
        </div>
    )
}

export default FastProductionRulesContainer