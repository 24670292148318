import React, { useState } from 'react'
import GeneralPage from './components/GeneralPage'
import ProductlinePage from './components/ProductlinePage'
import classNames from "classnames"
import { Button, message } from 'antd'
import { getListVariantForSupplier } from '../../../services/api/ProductsServices'
import { createShippingRate } from '../../../services/api/ShippingServices'
import getHistory from '../../../store/getHistory'
import { Spinner } from 'reactstrap'

const ShippingFeeNewPage = () => {

    const [tab, _setTab] = useState('general')
    const [supplier, setSupplier] = useState('')
    const [shipping_plan, setShippingPlan] = useState('')
    const [zone, setZone] = useState('')
    const [store, setStore] = useState([])
    const [allStore, setAllStore] = useState(false)
    const [first_item_price, setFirst_item_price] = useState('')
    const [additional_item_price, setAdditional_item_price] = useState('')
    const [product_line, setProduct_line] = useState('')
    const [listVariant, setListVariant] = useState([])
    const [loading, setLoading] = useState(false)
    const [filter, setFilter] = useState({})

    const newVariants = listVariant.filter(item => {

        if (!!item.change && (item.first_item_price === first_item_price || item.additional_item_price === additional_item_price)) delete item.change

        if (!!item.change) {
            return item.change
        }
    }).map(i => {
        return {
            _id: i._id,
            first_item_price: (i.first_item_price !== '' && i.first_item_price !== null && i.first_item_price !== undefined) ? i.first_item_price : first_item_price,
            additional_item_price: (i.additional_item_price !== '' && i.additional_item_price !== null && i.additional_item_price !== undefined) ? i.additional_item_price : additional_item_price
        }
    })

    const _handleChangeFilter = (data) => {
        const updatedFilter = {
            ...filter,
            ...data
        }

        for (let key in updatedFilter) {
            if (!updatedFilter[key]) delete updatedFilter[key]
        }

        setFilter(updatedFilter)
    }

    const _handleChangeSupplier = (supplier) => {
        setSupplier(supplier)
        setZone('')
    }

    const _handleChangeVariants = (id, field, value) => {
        if (isNaN(value) && value !== '-') {
            return
        }

        if (!isNaN(value) && (value < 0 || value >= 1000)) {
            return
        }

        if (!isNaN(value) && value.length >= 2 && value.indexOf('-') === 0) {
            return
        }

        if (value.indexOf('.') !== -1) {
            const afterDot = value.substring(value.indexOf('.') + 1);

            if (afterDot.length > 2) {
                return
            }
        }

        const newVariants = listVariant.map(item => {
            if (item._id !== id) return item

            return {
                ...item,
                [field]: value,
                change: (value === first_item_price || value === additional_item_price) ? false : true
            }
        })

        return setListVariant(newVariants)
    }

    const _handleChangeProductLine = product => {
        setProduct_line(product)
    }

    const _handleChangeShippingPlan = (shipPlan) => {
        setShippingPlan(shipPlan)
    }

    const _handleChangeZone = (zone) => {
        setZone(zone)
    }

    const _handleChangeFirstPrice = price => {
        if (isNaN(price) && price !== '-') {
            return
        }

        if (!isNaN(price) && (price < 0 || price >= 1000)) {
            return
        }

        if (!isNaN(price) && price.length >= 2 && price.indexOf('-') === 0) {
            return
        }

        if (price.indexOf('.') !== -1) {
            const afterDot = price.substring(price.indexOf('.') + 1);

            if (afterDot.length > 2) {
                return
            }
        }

        setFirst_item_price(price)
    }

    const _handleChangeAdditional = price => {

        if (isNaN(price) && price !== '-') {
            return
        }

        if (!isNaN(price) && (price < 0 || price >= 1000)) {
            return
        }

        if (!isNaN(price) && price.length >= 2 && price.indexOf('-') === 0) {
            return
        }

        if (price.indexOf('.') !== -1) {
            const afterDot = price.substring(price.indexOf('.') + 1);

            if (afterDot.length > 2) {
                return
            }
        }

        setAdditional_item_price(price)
    }


    const _handleChangeAddStore = (store) => {
        setStore(store)
    }

    const _handleCheckAllStore = () => {
        setAllStore(!allStore)
    }

    const _handlePaste = (e, field, variantIndex) => {
        const copyText = e.clipboardData.getData("Text")
        const parseText = copyText.split(/[\r?\n]+/)
        let count = 0
        const newVariants = listVariant.map((variant, index) => {
            if (index < variantIndex || count >= parseText.length) return variant

            return {
                ...variant,
                [field]: parseText[count++],
                change: true
            }
        })
        setListVariant(newVariants)
    }

    const _handleCancelClick = () => {
        _setTab('general')
        setProduct_line('')
    }

    const _handleCreateShippingRate = async () => {

        setLoading(true)

        try {
            const payload = {
                supplier, shipping_plan,
                shipping_zone: zone,
                product_line,
                // store: allStore ? "" : store,
                first_item_price: first_item_price,
                additional_item_price: additional_item_price
            }
            if (!allStore && store && store.length > 0) payload.store = store

            if (newVariants.length > 0) payload.variants = newVariants

            const history = getHistory()
            const res = await createShippingRate(payload)
            const { success, message: mess } = res

            if (!success) return message.error(mess)
            message.success('Create successfully!')
            history.push('/a/shipping-fee')

        } catch (e) {
            message.error(e.message || 'Unknown error.')
        } finally {
            setLoading(false)
        }

    }

    const _fetchListVariant = async (product_line, supplier) => {
        try {
            const res = await getListVariantForSupplier(product_line, supplier)
            const { data, success } = res
            if (!success) return

            setListVariant(data)
        } catch (error) {
            console.log(error.message || 'Unknown error')
        }
    }

    const checkNext = supplier !== '' && shipping_plan !== '' && zone !== '' && ((store && store.length > 0) || allStore)
    const checkSave = !!product_line && !!first_item_price && !!additional_item_price && !!newVariants

    const _generateContentTab = (key) => {
        switch (key) {
            case 'general':
                return <GeneralPage
                    supplier={supplier}
                    shipping_plan={shipping_plan}
                    zone={zone}
                    store={store}
                    allStore={allStore}
                    handleChangeSupplier={_handleChangeSupplier}
                    handleChangeShippingPlan={_handleChangeShippingPlan}
                    handleChangeZone={_handleChangeZone}
                    handleCheckAllStore={_handleCheckAllStore}
                    handleChangeAddStore={_handleChangeAddStore}
                />
            default:
                return <ProductlinePage
                    first_item_price={first_item_price}
                    additional_item_price={additional_item_price}
                    handleChangeFirstPrice={_handleChangeFirstPrice}
                    handleChangeAdditional={_handleChangeAdditional}
                    supplier={supplier}
                    shipping_plan={shipping_plan}
                    zone={zone}
                    store={store}
                    handleChangeProductLine={_handleChangeProductLine}
                    product_line={product_line}
                    handleChangeVariants={_handleChangeVariants}
                    newVariant={listVariant}
                    handlePaste={_handlePaste}
                    fetchListVariant={_fetchListVariant}
                    handleChangeFilter={_handleChangeFilter}
                    filter={filter}
                    allStore={allStore}
                />
        }
    }

    return (
        <>
            <div className="d-flex justify-content-between border-bottom">
                <h1 className="PageTitle">Add new shipping rate</h1>

                {tab === 'general' && <Button disabled={!checkNext} onClick={() => _setTab('productline')} className="px-4 rounded mr-3" type="primary">Next</Button>}

                {tab === 'productline' &&
                    <div className="d-flex justify-content-between">
                        <Button className="px-4 rounded mr-3 btn-secondary" onClick={_handleCancelClick} type="default">Back</Button>
                        <Button className="px-4 rounded mr-3" type="primary" onClick={_handleCreateShippingRate} disabled={!checkSave}>
                            {
                                loading && <Spinner color='white' size='sm' className='mr-2' />
                            }

                            Save
                        </Button>
                    </div>
                }

            </div>
            <div className="TabNewShipping">
                <ul>
                    <li className={classNames('itemTab', tab === 'general' && 'active')}>General</li>
                    <li className={classNames('itemTab', tab === 'productline' && 'active')}>Productline</li>
                </ul>
            </div>
            <div className="ShippingFeeNewPages">
                <div className="SectionInner">
                    {_generateContentTab(tab)}
                </div>
            </div>
        </>
    )
}

export default ShippingFeeNewPage