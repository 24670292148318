import React, {Component} from 'react'

class ShippingZoneNewMethodsActions extends Component {
    _handleClickCreate = e => {
        e.preventDefault()

        this.props._handleCreatePlan()
    }

    render() {
        return (
            <div className="ShippingZoneNewMethodsActions">
                <div className="text-right">
                    <button className="btn btn-sm btn-outline-primary" onClick={this._handleClickCreate}>Add shipping rate</button>
                </div>
            </div>
        )
    }
}

export default ShippingZoneNewMethodsActions
