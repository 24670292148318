import React, {Component} from 'react'

class ShippingMethodsBaseCost extends Component {
    _handleChangeInput = e => {
        const {value} = e.target
        const {index, _handleUpdatePlansState} = this.props

        _handleUpdatePlansState(index, "first_rate", parseFloat(value))
    }

    render() {
        return (
            <td className="ShippingMethodsBaseCost">
                <input type="number" className="form-control" step="any" min={0} onChange={this._handleChangeInput} required/>
            </td>
        )
    }
}

export default ShippingMethodsBaseCost
