import React from 'react'
import PropTypes from 'prop-types'
import ReactCountryFlag from "react-country-flag"

ShippingAddress.propTypes = {
    address: PropTypes.any
}

function ShippingAddress(props) {
    const {full_name, city, postal_code, country_code, country, phone, email, address, address2, state} = props.address

    return (
        <div className="ShippingAddress">
            {
                !!full_name &&
                <div className="font-weight-bold mb-2">{full_name}</div>
            }

            {
                !!address &&
                <div className="mb-2">{address}{!!address2 && `, ${address2}`}</div>
            }

            <div className="mb-2">{city}{!!state && ` - ${state}`}{!!postal_code && ` - ${postal_code}`}</div>

            {
                !!country_code &&
                <div className="d-flex align-items-baseline mb-2">
                    <span className="mr-2"><ReactCountryFlag code={country_code} svg/></span>
                    <span>{country}</span>
                </div>
            }

            {
                !!phone &&
                <div className="mb-2">
                    <i className="fas fa-phone mr-2"/>{phone}
                </div>
            }

            {
                !!email &&
                <div className="d-flex align-items-baseline"><i className="fa fa-envelope mr-2"/>{email}
                </div>
            }
        </div>
    )
}

export default ShippingAddress