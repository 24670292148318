import React from 'react'
import PropTypes from 'prop-types'
import {Button, Radio, Tag} from "antd"

ProductLine.propTypes = {
    productLineType: PropTypes.string,
    selectProductLines: PropTypes.array,
    listSelectedVariants: PropTypes.array,
    onOpenModal: PropTypes.func.isRequired,
    onChangeProductLineType: PropTypes.func.isRequired,
    onChangeListSelectedProductLine: PropTypes.func.isRequired,
    onChangeListSelectedVariants: PropTypes.func.isRequired
}

function ProductLine(props) {
    const {
        productLineType,
        listSelectedVariants,
        selectProductLines,
        onOpenModal,
        onChangeProductLineType,
        onChangeListSelectedProductLine,
        onChangeListSelectedVariants,
        permissionsData,
    } = props

    function _convertProduct() {
        const products = selectProductLines.map(product => {
            if ((listSelectedVariants.filter(variant => variant.product_id !== product.id)).length > 0 || listSelectedVariants.length === 0) {
                return product
            }

            return {}
        })

        return products.filter(item => item !== undefined && Object.keys(item).length > 0)
    }

    function _convertVariant() {
        if (!!listSelectedVariants && listSelectedVariants.length > 0) {
            const group = listSelectedVariants.reduce((m, i) => {
                const product = m.find(p => p.id === i.product_id)

                if (product) {
                    product.variants.push({
                        id: i.id,
                        title: i.title
                    })
                } else {
                    m.push({
                        id: i.product_id,
                        title: i.product_title,
                        variants: [{
                            id: i.id,
                            title: i.title
                        }]
                    })
                }

                return m

            }, [])

            return group
        }

        return []
    }

    function _removeProductLine(id) {
        const filters = selectProductLines.filter(item => item.id !== id)

        if (typeof onChangeListSelectedProductLine === 'function') {
            onChangeListSelectedProductLine(filters)
        }
    }

    function _removeVariants(id) {
        const filters = listSelectedVariants.filter(item => item.id !== id)

        if (typeof onChangeListSelectedVariants === 'function') {
            onChangeListSelectedVariants(filters)
        }
    }

    const products = !!selectProductLines && selectProductLines.length > 0 && _convertProduct()
    const disableAll = !permissionsData.includes('ffm_fulfillment_discounts_update')

    return (
        <div className="FormItem">
            <label htmlFor="productLine" className="LabelDiscount">Product Line</label>
            <Radio.Group id="productLine" onChange={onChangeProductLineType}
                         value={productLineType}>
                <Radio value='all' disabled={disableAll}>All</Radio>

                <div className="LineList">
                    <Radio value='custom' disabled={disableAll}>Specific product line</Radio>
                    <div className="AddProductline">
                        {!disableAll &&
                            <Button
                                type="primary"
                                disabled={productLineType !== 'custom'}
                                className={`${productLineType !== 'custom' ? 'Disable' : ''}`}
                                onClick={onOpenModal}
                            >
                                Browser
                            </Button>
                        }
                    </div>
                </div>
            </Radio.Group>

            <ul className="ListProductLineSelect">
                {
                    !!products && products.length > 0 && products.map(product => (
                        <li key={product.id}>
                            <div className="Title">
                                {product.title}
                                {!disableAll &&
                                    <span
                                        className="Remove ti-close"
                                        onClick={() => _removeProductLine(product.id)}
                                    />
                                }
                            </div>
                        </li>
                    ))
                }
                {
                    _convertVariant().length > 0 && _convertVariant().map((item, index) => (
                        <li key={index}>
                            <div className="Title">
                                {item.title}
                            </div>
                            <div className="VariantsList">
                                {
                                    !!item.variants && item.variants.length > 0 && item.variants.map(variant => (
                                        <Tag 
                                            className="ItemVariant"  
                                            key={variant.id} 
                                            closable={!disableAll}
                                            onClose={() => _removeVariants(variant.id)}
                                            closeIcon={<span className="Remove ti-close"></span>}
                                        >
                                            {variant.title}
                                        </Tag>
                                    ))
                                }
                            </div>
                        </li>
                    ))
                }
            </ul>
        </div>
    )
}

export default ProductLine