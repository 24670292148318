import React, {Component, Fragment} from 'react'
import {ModalBody, ModalFooter, ModalHeader} from 'reactstrap'
import PackageAddressEditor from './PackageAddressEditor'
import PackageListItemsEditor from './PackageListItemsEditor'
import {pushFulfillment, getExportFulfillment} from '../../../../../services/api/ExportOrderFactoryServices'
import PropTypes from 'prop-types'

class PackagePusherInner extends Component {
    state = {
        items: [],
        error: '',
        isLoading: false,
        isPushing: false,
        exportFulfillment: {},
        forcePush: false,
        pushedCount: 0
    }

    componentDidMount() {
        this._fetchItems().then(() => {
        })
    }

    _fetchItems = async () => {
        const {orderId, fulfillment} = this.props
        const {_id: fulfillmentId} = fulfillment

        this.setState({
            isLoading: true,
            error: ''
        })

        try {
            const {data, success, message} = await getExportFulfillment(orderId, fulfillmentId)

            if (!success) {
                throw new Error(message)
            }

            const {items = []} = data

            if (!items.length) {
                throw new Error('No items to push.')
            }

            const disabledSplit = data.items.filter(item => !!item.outOfStock)

            this.setState({
                error: !!disabledSplit && disabledSplit.length ? disabledSplit[0].outOfStock : '',
                isLoading: false,
                exportFulfillment: data
            })
        } catch (e) {
            this.setState({
                error: e.toString(),
                isLoading: false
            })
        }
    }

    _handleClickCancel = e => {
        e.preventDefault()
        this.props.onClose()
    }

    _handleChangeItem = (item) => {
        const {exportFulfillment, exportFulfillment: {items}} = this.state

        this.setState({
            exportFulfillment: {
                ...exportFulfillment,
                items: [...items.map((it) => {
                    return item.item_index === it.item_index ? {...it, item} : it
                })]
            }
        })
    }

    _handleClickPushFactory = async (e) => {
        this.setState({
            isPushing: true,
            error: '',
            pushResponse: false,
            pushedCount: this.state.pushedCount + 1
        })
        const {exportFulfillment, forcePush} = this.state
        const {orderId} = this.props
        const {fulfillment: {_id: fulfillmentId}} = this.props
        let response

        try {
            response = await pushFulfillment(orderId, fulfillmentId, exportFulfillment, forcePush)
            const {success} = response

            if (success) {
                response.message = 'Package pushed successful!'
            }
        } catch (err) {
            response = {
                success: false,
                message: err.toString()
            }
        }

        this.setState({
            pushResponse: response,
            isPushing: false
        })
    }

    _handleRemoveItem = item => {
        this.setState(({exportFulfillment}) => {
            const {items} = exportFulfillment

            return {
                exportFulfillment: {
                    ...exportFulfillment,
                    items: items.filter((it) => it.item_index !== item.item_index)
                }
            }
        })
    }

    _onChangeAddress = (newAddress) => {
        this.setState(({exportFulfillment}) => {
            return {
                exportFulfillment: {
                    ...exportFulfillment,
                    address: {...newAddress}
                }
            }
        })
    }

    _handleMarkForcePushing = (e) => {
        const {checked} = e.target

        if (checked) {
            if (!window.confirm('Ensure to overwrite pushed order?')) {
                return
            }
        }

        this.setState({
            forcePush: checked
        })
    }

    render() {
        const {fulfillment} = this.props
        const {name, items} = fulfillment
        const totalItems = Array.isArray(items) ? items.length : 0
        const {error, isLoading, isPushing, pushResponse} = this.state

        const {exportFulfillment = {}, forcePush, pushedCount} = this.state
        const {address = {}, items: pushItems = []} = exportFulfillment
        const canPush = pushItems.length && !isPushing

        return (
            <Fragment>
                <ModalHeader>
                    <div className="Name">Package {name} - {totalItems} items</div>
                </ModalHeader>
                <ModalBody className={isPushing ? "Pushing" : ''}>
                    {
                        !!error &&
                        <div className="Error text-danger">{error}</div>
                    }
                    {
                        !!isLoading &&
                        <div className="Loading">Loading...</div>
                    }

                    {
                        !isLoading &&
                        <div className="Editor ExportFactory">
                            <PackageAddressEditor onChangeAddress={this._onChangeAddress} address={address}/>
                            <hr/>
                            <PackageListItemsEditor onChangeItem={this._handleChangeItem} items={pushItems}
                                                    onRemoveItem={this._handleRemoveItem}/>
                        </div>
                    }

                    {
                        !!pushResponse && (({success, message}) => {
                            return message ? <div
                                className={['PushMessage', success ? "text-info" : "text-danger"].join(' ')}>{message}</div> : ''
                        })(pushResponse)
                    }
                </ModalBody>
                <ModalFooter className="d-flex">
                    <div className="ml-auto">
                        <div className="custom-control custom-checkbox">
                            <input type="checkbox" className="custom-control-input" disabled={!canPush}
                                   checked={forcePush}
                                   onChange={this._handleMarkForcePushing} id="InputForceOrdersPushed"/>
                            <label className="custom-control-label" htmlFor="InputForceOrdersPushed"
                                   style={forcePush ? {color: '#FF0000'} : {}}>Overwrite if order has
                                already pushed!</label>
                        </div>
                        <button className="btn btn-secondary" disabled={isPushing}
                                onClick={this._handleClickCancel}>
                            Close
                        </button>

                        <button onClick={this._handleClickPushFactory}
                                className="btn btn-success ml-3" disabled={!canPush}>
                            {isPushing ? 'Pushing...' : (pushedCount ? 'Push again' : 'Push')}
                        </button>
                    </div>
                </ModalFooter>
            </Fragment>
        )
    }
}

PackagePusherInner.propTypes = {
    isOpen: PropTypes.bool.isRequired,
    fulfillment: PropTypes.object.isRequired,
    orderId: PropTypes.string.isRequired,
    onClose: PropTypes.func.isRequired,
    onUpdateTotalPushToProduction: PropTypes.func.isRequired
}

export default PackagePusherInner
