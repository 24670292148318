import React, {Component} from 'react'
import ShippingZoneCountryItem from "./ShippingZoneCountryItem"

class ShippingZoneListCountries extends Component {

    _handleChangeSelect = e => {
        const {value: countryId} = e.target

        if (countryId === "") {
            return
        }

        this.props.onUpdateCountriesState(countryId, true, false)
    }

    _handleClickRemainCountries = e => {
        e.preventDefault()

        this.props.onSelectRemainCountries()
    }

    render() {
        const {countries} = this.props

        return (
            countries.length > 0 &&
            <div className="ShippingZoneListCountries">
                <select name="countries" className="custom-select" value="" onChange={this._handleChangeSelect}>
                    <option value="">-- Select country --</option>
                    {
                        countries.map(countryObj => {
                            return <ShippingZoneCountryItem key={countryObj.country._id} countryObj={countryObj}/>
                        })
                    }
                </select>

                <button className="btn btn-outline-primary" onClick={this._handleClickRemainCountries}>Select remain countries</button>
            </div>
        )
    }
}

export default ShippingZoneListCountries
