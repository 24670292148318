import {getLocalData, setLocalData} from "./StorageServices"
import PubSub from 'pubsub.js'

const contextArgument = ['currentStore']
const instance = PubSub.newInstance({
    context: contextArgument,
    async: false,
})

const _getCurrentStoreFormStorage = () => {
    const storeId = getLocalData('currentStore', '')

    return (storeId + '').trim()
}

const _setCurrentStore = storeId => {
    const storeValidated = (storeId + '').trim()

    _store.storeId = storeValidated
    setLocalData('currentStore', storeValidated)

    return _store.storeId
}

const _getStoreSettingsFormStorage = () => {
    const settings = getLocalData('currentStoreSettings', [])

    return Array.isArray(settings) ? settings : []
}

const _setStoreSettings = (settings = []) => {
    const validatedSettings = Array.isArray(settings) ? settings : []

    _store.settings = validatedSettings
    setLocalData('currentStoreSettings', validatedSettings)

    return _store.settings
}

const _broadcast = () => {
    const {storeId} = _store
    instance.publish('STORE_CHANGED', [{storeId}])

    return true
}

const _getStoreTokenFromLocalStorage = () => {
    const token = getLocalData('storeToken', '')

    return (token + '').trim()
}

const _setStoreToken = token => {
    setLocalData('storeToken', token)
    _store.storeToken = token

    return token
}

const _getScopesFromLocalStorage = () => {
    const scopes = getLocalData('storeScopes', [])

    return Array.isArray(scopes) ? scopes : []
}

const _saveStopreScopes = (scopes) => {
    setLocalData('storeScopes', scopes)
    _store.scopes = scopes

    return scopes
}

const _store = {
    storeToken: _getStoreTokenFromLocalStorage(),
    storeId: _getCurrentStoreFormStorage(),
    settings: _getStoreSettingsFormStorage(),
    scopes: _getScopesFromLocalStorage(),
}

export const subscribeChangeStore = (subscriber) => {
    return instance.subscribe('STORE_CHANGED', (data) => {
        subscriber(data)
    })
}

export const unsubscribeChangeStore = token => {
    instance.unsubscribe(token)
}

export const getCurrentStore = () => {
    return _store.storeId
}

export const getCurrentStoreSettings = () => {
    return _store.settings || []
}

export const getStoreSetting = (key, defaultValue = null) => {
    const {settings} = _store
    const settingsValidated = Array.isArray(settings) ? settings : []
    const setting = settingsValidated.find(_setting => _setting.key === key)

    if (!setting) return defaultValue

    const {value} = Object.assign({}, setting)

    return value || defaultValue
}

export const refreshStoreSettings = (data) => {
    const {storeId} = _store

    if (!storeId) return false

    _setStoreSettings(data)
    _broadcast()

    return true
}

export const setCurrentStore = (storeId, settings = []) => {
    const storeIdValidated = storeId ? (storeId + '').trim() : ''

    _setCurrentStore(storeIdValidated)
    _setStoreSettings(settings)
    _broadcast()

    return _store.storeId
}

export const setStoreToken = (token) => {
    return _setStoreToken(token)
}

export const getStoreToken = () => {
    return _store.storeToken || ''
}

export const setStoreScopes = scopes => {
    _saveStopreScopes(scopes)

    return true
}

export const getStoreScopes = () => {
    return Array.isArray(_store.scopes) ? _store.scopes : []
}
