import React, { useState } from 'react'
import { Popover, Input, message, notification, Button } from 'antd'
// import { updateNoteOrder, deleteNoteOrder } from "../../../../../services/api/3dApi"
import { EditTwoTone } from '@ant-design/icons'
import { updateNoteOrder } from '../../../../services/api/OrdersServices'

const ItemNote = ({ noteContent, orderId, permissionsData }) => {
    const [visible, setVisible] = useState(false)
    const [note, setNote] = useState(noteContent)
    
    let _tempNote = note

    const _handleChangeInput = e => {
        const { value } = e.target

        _tempNote = value
    }

    const _hidePopover = () => {
        setVisible(false)
    }

    const _rqDeleteNote = async () => {
        try {
            // const { data: response } = await deleteNoteOrder(orderId)

            // const { success, error } = response

            // if (!success) {
            //     throw new Error(error)
            // }

            setNote('')

            _tempNote = ''

            message.success('Đã xóa note')
        } catch (e) {
            notification.error({
                message: 'ERROR',
                description: e.message,
                duration: 0,
            })
        } finally {
            _hidePopover()
        }
    }

    const _rqAddNote = async () => {
        try {
            const payload = {
                note: _tempNote,
            }

            const response= await updateNoteOrder(orderId, payload)

            const {success} = response

            if (!success) {
                const {message} = response
                throw new Error(message)
            }

            setNote(_tempNote)

            message.success('Đã lưu note')
        } catch (e) {
            notification.error({
                message: 'ERROR',
                description: e.message,
                duration: 0,
            })
        } finally {
            _hidePopover()
        }
    }

    const _rqCancelEditNote = () => {
        setNote(noteContent)
        setVisible(false)
    }

    const _handleVisibleChange = visible => {
        setVisible(visible)
    }

    const splitNote = (str) => {
        if (!str) return
        if (str.length >= 7) {
            return str.slice(0, 7) + '...'
        } else {
            return str
        }
    }

    const content = (
        <div>
            {
                visible && <Input.TextArea defaultValue={note} autoSize={{ minRows: 3, maxRows: 5 }}
                    onChange={_handleChangeInput} />
            }
            <div className='d-flex justify-content-end mt-1'>
                <Button type='link' size='small' onClick={_rqCancelEditNote} className='text-danger'>Cancel</Button>
                <Button type='link' size='small' onClick={_rqAddNote}>Save</Button>
            </div>
        </div>
    )

    return (
        <td className='ItemNote'>
            <div className='Content d-inline-block mr-1'>
                {splitNote(note)}
            </div>

            {
                permissionsData.includes('ffm_printing_file_update_note') &&
                <Popover visible={visible} onVisibleChange={_handleVisibleChange} title='Note' content={content}
                    placement='bottom'
                    trigger='click'>
                    <EditTwoTone />
                </Popover>
            }
        </td>
    )
}

export default ItemNote