import React from 'react'
import PropTypes from 'prop-types'
import OrderItemHead from "./OrderItemTable/OrderItemHead"
import OrderItemRow from "./OrderItemTable/OrderItemRow"

OrderItemTable.propTypes = {
    items: PropTypes.array,
    orderId: PropTypes.string
}

function OrderItemTable(props) {
    const {items, orderId, orderType} = props

    return (
        <div className="OrderItemTable">
            <table className="table table-bordered">
                <OrderItemHead/>
                <tbody>
                {
                    !!items && items.length > 0 && items.map(item => {
                        return <OrderItemRow key={item._id} item={item} orderId={orderId} orderType={orderType} />
                    })
                }
                </tbody>
            </table>
        </div>
    )
}

export default OrderItemTable