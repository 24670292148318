import React, {Component} from 'react'

class ProductLineModalNavigation extends Component {
    _handleClickBack = (e) => {
        e.preventDefault()

        const {_handUpdateState} = this.props
        _handUpdateState("selectedProduct", "")
    }

    _handleClickClose = e => {
        e.preventDefault()

        this.props._handleToggleModal()
    }

    render() {
        const {selectedProduct} = this.props

        return (
            <div className="ProductLineModalNavigation border-bottom mb-3">
                <div className="d-flex">
                    {
                        !!selectedProduct &&
                        <span className="Back text-primary cursor-pointer" onClick={this._handleClickBack}><i className="ti-angle-left"/> Back</span>
                    }
                    <span className="Close ml-auto cursor-pointer text-danger" onClick={this._handleClickClose}><i className="ti-close"/></span>
                </div>
            </div>
        )
    }
}

export default ProductLineModalNavigation
