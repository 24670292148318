import React, {Component} from 'react'
import PropTypes from 'prop-types'
import {getArtworksByOrderItemV3, getValidateArtwork} from "../../../../services/api/ArtworksServices"
import OrderItemGroupArtworkItem from "../shared/OrderItemGroupArtworkItem"
import ModalUpdateCustomizedArtworks from "../../../print-files/components/data/ModalUpdateCustomizedArtworks"
import {Modal, Input, Switch, Select, message, Tooltip} from 'antd'
import {
    getOrderItem,
    updateCustomizedArtworks,
    updateLinkDesign,
    updatePrintFilesStatus
} from '../../../../services/api/OrdersServices'
import {getSupplierType} from '../../../../services/api/ProductsServices'
import {getLocalData} from '../../../../services/StorageServices'

class FulfilledPackageItemDesignV3 extends Component {
    state = {
        artworks: [],
        artworksMore: [],
        error: "",
        validates: [],
        showAll: false,
        piecesPerPack: 0,
        isModalVisible: false,
        checked: false,
        customizedArtworks: {},
        variant: {
            type : '',
            size : '',
            color : '',
            partner_sku : ''
        },
    }

    timer = null

    componentDidMount() {
        const {fulfillmentItems: data, setArtworks, isCheckVersionOrderV2} = this.props

        if (isCheckVersionOrderV2) {
            if (data.length > 0 && !!data[0].artworks && data[0].artworks.length > 0) {
                const {level, pack} = data[0].artworks[0]
                if ((level === 'order' || level === 'productvariant') && pack > 1) {
                    setArtworks(level)
                }
            }
            const {onChangeArtworkType} = this.props

            if (typeof onChangeArtworkType === 'function') {
                if (data.length > 0 && !!data[0].artworks && data[0].artworks.length > 0) {
                    onChangeArtworkType(data[0].artworks[0].level)
                }
            }

            this.setState({
                piecesPerPack: data.reduce((accumulator, currentValue) => accumulator + currentValue.artworkQuantity, 0)
            })
            data.map(async (item) => {
                const listIdArtwork = item.artworks.map((item) => {
                    return item._id
                })
                const {productMapId} = this.props

                if (!!productMapId && !!listIdArtwork && listIdArtwork.length > 0) {
                    await this._fetchValidateDimension(productMapId, listIdArtwork, item.side_group || null)
                }
            })
        } else {
            this._fetchArtworks()
        }
    }

    _onChangeShowAll = () => {
        this.setState({
            showAll: !this.state.showAll
        })
    }

    _fetchArtworks = async () => {
        const {orderItemId, orderId, setArtworks,} = this.props

        this.setState({validates: []})

        try {
            const {data, success, message} = await getArtworksByOrderItemV3({orderId, orderItemId})

            if (!success) {
                return this.setState({
                    error: message,
                })
            }

            this.setState({
                artworks: data.length >= 2 ? data.slice(0, 1) : data,
                artworksMore: data.length >= 2 ? data.slice(1, data.length) : []
            }, () => {
                if (data.length > 0 && !!data[0].artworks && data[0].artworks.length > 0) {
                    const {level, pack} = data[0].artworks[0]
                    if ((level === 'order' || level === 'productvariant') && pack > 1) {
                        setArtworks(level)
                    }
                }
                const {onChangeArtworkType} = this.props

                if (typeof onChangeArtworkType === 'function') {
                    if (data.length > 0 && !!data[0].artworks && data[0].artworks.length > 0) {
                        onChangeArtworkType(data[0].artworks[0].level)
                    }
                }

                this.setState({
                    piecesPerPack: data.reduce((accumulator, currentValue) => accumulator + currentValue.quantity, 0)
                })
                data.map(async (item) => {
                    const listIdArtwork = item.artworks.map((item) => {
                        return item._id
                    })
                    const {productMapId} = this.props

                    if (!!productMapId && !!listIdArtwork && listIdArtwork.length > 0) {
                        await this._fetchValidateDimension(productMapId, listIdArtwork, item.side_group || null)
                    }
                })
            })
        } catch (e) {
            return this.setState({
                error: e.message,
            })
        }
    }

    _onChangeShowAll = () => {
        this.setState({
            showAll: !this.state.showAll
        })
    }

    handleShowModal = async () => {
        // await this.props.fetchOrderFulfilledItems()
        try {
            await this.handleGetOrderItem()
                .then(response => {
                    const {customized_artworks: data, type = '', size = '', color = '', partner_sku = ''} = response
                    if (!!data) {
                        this.setState({
                            customizedArtworks: data,
                            variant: {
                                ...this.state.variant,
                                type,
                                size,
                                color,
                                partner_sku
                            }
                        })
                    }
                })

        } catch (error) {
            message.error(error.message)
        }
        this.setState({isModalVisible: true})
    }

    handleCancel = () => {
        this.setState({
            isModalVisible: false,
            checked: false,
            variant: {},
            customizedArtworks: {}
        })
    }

    handleCheck = () => {
        this.setState({checked: !this.state.checked})
    }

    handleGetOrderItem = async () => {
        const {orderId, orderItemId, fulfillItem, productLineVariant, fetchOrderFulfilledItems} = this.props
        // const response = await getOrderItem(orderId, orderItemId)
        // const { data } = response 
        const {design_back, design_front, design_hood, design_sleeves, ...rest} = {...fulfillItem}
        const {product_type = '', size = '', partner_sku = '', color = ''} = {...productLineVariant}
        const customizedArtworks = {
            front: design_front || '',
            back: design_back || '',
            sleeves: design_sleeves || '',
            hood: design_hood || '',
        }
        return {
            customized_artworks: customizedArtworks,
            type: product_type,
            size: size,
            color,
            partner_sku,
        }
    }

    handleChangeSide = (field, value) => {
        this.setState({
            ...this.state,
            customizedArtworks: {
                ...this.state.customizedArtworks,
                [field]: value
            }
        })
    }

    handleChangeVariant = (item) => {
        this.setState({
            ...this.state,
            variant: {
                ...this.state.variant,
                ...item
            }
        })
    }

    onChangeSize = (e) => {
        const {value, name} = e.target
        this.setState({...this.state.size, [name]: value})
    }

    handleOkClick = async () => {
        const {fulfillment_item_id, fetchOrderFulfilledItems} = this.props
        const {customizedArtworks, checked, variant} = this.state
        const { type, size, color, partner_sku } = Object.assign({}, variant)
        const data = {
            front: customizedArtworks.front,
            back: customizedArtworks.back,
            sleeves: customizedArtworks.sleeves,
            hood: customizedArtworks.hood,
        }
        if (checked) {
            data.type = type || ''
            data.size = size || ''
            data.color = color || ''
            data.partner_sku = partner_sku || ''
        }

        try {
            const response = await updateLinkDesign(data, fulfillment_item_id)
            const {success, message: mess} = response
            if (!success) {
                throw new Error(mess)
            }
            message.success("Update Customize Artwork Success!")
            this.handleCancel()
            Modal.confirm({
                title: 'Do you want to retry generating print-file of this item?',
                okText: 'Yes',
                cancelText: 'No',
                onOk: () => this._handleRetryPrintFile(),
                onCancel: () => fetchOrderFulfilledItems(),
            })
        } catch (error) {
            message.error(error.message)
        }
    }

    _handleRetryPrintFile = async (action) => {
        const {fulfillment_item_id, fulfilledPackageId, fetchOrderFulfilledItems} = this.props
        try {
            const {
                success,
                data,
                message: mess
            } = await updatePrintFilesStatus(fulfilledPackageId, fulfillment_item_id, action ? action : 'retry')
            if (!success) {
                throw new Error(mess)
            }
            message.success('Print file retry success!!!')
        } catch (error) {
            Modal.error({
                title: 'Print file retry error',
                content: error.message
            })
        } finally {
            fetchOrderFulfilledItems()
        }
    }

    _fetchValidateDimension = async (productId, artworkIds, side_group) => {
        try {
            const response = await getValidateArtwork({productId: productId, artworkIds: artworkIds})

            const {success, data, message: error} = response

            if (!success) {
                return this.setState({
                    validates: [...this.state.validates, {side_group: side_group, errorValidate: error}]
                })
            }

            const {validDimension, correctDimensions, message} = data

            this.setState({
                validates: [
                    ...this.state.validates,
                    {
                        side_group: side_group,
                        errorValidate: message,
                        correctDimensions: correctDimensions,
                        validDimension: validDimension
                    }
                ]
            })

        } catch (e) {
            this.setState({
                error: e.message
            })
        }
    }

    render() {
        const {
            fulfillmentStatus,
            ff_processed_artworks_pf,
            orderItemId,
            fulfillment_item_id,
            orderId,
            supplierCurrent,
            supplierName,
            productLineVariant,
            fulfillItem,
            originFulfillItem,
            isCheckVersionOrderV2
        } = this.props
        const {artworks, artworksMore, error, validates, showAll, piecesPerPack} = this.state
        const {customizedArtworks, variant} = this.state
        const isEditDesign = Object.keys(customizedArtworks).some(item => !!customizedArtworks[item].trim())
        const {isModalVisible, checked} = this.state
        // const genfilesupplier= ["PF", "PL EU", "CW"]

        const permissionsData = getLocalData('permissions') ? getLocalData('permissions') : {}

        return (
            <td className="OrderItemDesignV3">
                {
                    permissionsData.hasOwnProperty('ffm_order_detail_update_design') && productLineVariant && fulfillmentStatus !== "unfulfilled" && !!fulfillItem &&
                    // genfilesupplier.includes(supplierName) &&
                    <Tooltip title="Update Customize Artwork">
                        <span className={`text-primary cursor-pointer float-right`}>
                            <i className="ti-pencil" style={{color: "#007bff"}} onClick={this.handleShowModal}></i>
                        </span>
                    </Tooltip>
                    
                }

                {
                    isCheckVersionOrderV2 ?
                        <OrderItemGroupArtworkItem
                            artwork={fulfillItem}
                            validates={validates}
                            typeDisplay="first"
                            piecesPerPack={piecesPerPack}
                            fulfillmentStatus={fulfillmentStatus}
                            ff_processed_artworks_pf={ff_processed_artworks_pf}
                            orderItemId={orderItemId}
                            fulfillment_item_id={fulfillment_item_id}
                            orderId={orderId}
                            supplierCurrent={supplierCurrent}
                            productLineVariant={productLineVariant}
                            selectedComplexEmbroidery={this.props.selectedComplexEmbroidery}
                            selectComplexEmbroidery={this.props.selectComplexEmbroidery}
                            showEmbroiderySurchargeButton={this.props.showEmbroiderySurchargeButton}
                            isAllowEmbroiderySurcharge={this.props.isAllowEmbroiderySurcharge}
                            fulfillItem={fulfillItem}
                            originFulfillItem={originFulfillItem}
                            onRetryPrintFile={this._handleRetryPrintFile}
                        /> : (
                            !!artworks.length && <div className="listArtwork">
                                {
                                    artworks.map((item, index) => (
                                        <OrderItemGroupArtworkItem
                                            key={`first_${index}`}
                                            artwork={item}
                                            validates={validates}
                                            typeDisplay="first"
                                            piecesPerPack={piecesPerPack}
                                            fulfillmentStatus={fulfillmentStatus}
                                            ff_processed_artworks_pf={ff_processed_artworks_pf}
                                            orderItemId={orderItemId}
                                            fulfillment_item_id={fulfillment_item_id}
                                            orderId={orderId}
                                            supplierCurrent={supplierCurrent}
                                            productLineVariant={productLineVariant}
                                            selectedComplexEmbroidery={this.props.selectedComplexEmbroidery}
                                            selectComplexEmbroidery={this.props.selectComplexEmbroidery}
                                            showEmbroiderySurchargeButton={this.props.showEmbroiderySurchargeButton}
                                            isAllowEmbroiderySurcharge={this.props.isAllowEmbroiderySurcharge}
                                            fulfillItem={fulfillItem}
                                            originFulfillItem={originFulfillItem}
                                            onRetryPrintFile={this._handleRetryPrintFile}
                                        />
                                    ))
                                }
                            </div>
                        )
                }

                {/* <Modal
                    title={'Update customized artworks'}
                    visible={isModalVisible}
                    onCancel={this.handleCancel}
                    onOk={this.handleOkClick}
                    okButtonProps={{disabled: !isEditDesign}}
                >
                    <label>Design Front</label>
                    <Input
                        value={
                            !!customizedArtworks ? customizedArtworks.front : ''
                        }
                        name='front'
                        onChange={this.onchangeCustomizedArtworks}
                    ></Input>
                    <label>Design Back</label>
                    <Input
                        value={!!customizedArtworks ? customizedArtworks.back : ''}
                        name='back'
                        onChange={this.onchangeCustomizedArtworks}
                    ></Input>
                    <label>Design Sleeve</label>
                    <Input
                        value={!!customizedArtworks ? customizedArtworks.sleeves : ''}
                        name='sleeves'
                        onChange={this.onchangeCustomizedArtworks}
                    ></Input>
                    <label>Design Hood</label>
                    <Input
                        value={!!customizedArtworks ? customizedArtworks.hood : ''}
                        name='hood'
                        onChange={this.onchangeCustomizedArtworks}
                    ></Input>
                    <label>Type & Size</label>
                    <Switch checked={checked} style={{margin: "15px"}} onChange={this.handleCheck}></Switch>
                    <br/>
                    {checked === true && (
                        <div className='d-flex justify-content-center'>
                            <label>Type</label>
                            <Select
                                value={Type || []}
                                onChange={this.handleChangeType}
                                onSearch={this.handleSearchType}
                                showSearch
                                style={{
                                    width: "100%",
                                    margin: "0 10px 0 10px"
                                }}
                                placeholder='Search to Select'
                                defaultActiveFirstOption={false}
                                filterOption={false}
                                notFoundContent={null}
                                allowClear
                            >
                                {options}
                            </Select>
                            <label>Size</label>
                            <Input
                                value={size || ""}
                                name='size'
                                onChange={this.onChangeSize}
                                style={{
                                    width: "30%",
                                    margin: "0 0 0 10px"
                                }}
                            >
                            </Input>
                        </div>
                    )}
                </Modal> */}

                {isModalVisible && <ModalUpdateCustomizedArtworks
                    visible={isModalVisible}
                    urlDesign={{...customizedArtworks}}
                    onSaveEdit={this.handleOkClick}
                    onCancel={this.handleCancel}
                    checked={checked}
                    onCheck={this.handleCheck}
                    onChangeVariant={this.handleChangeVariant}
                    variant={variant}
                    onChangeSide={this.handleChangeSide}
                    isEditDesign={isEditDesign}
                    supplierId={supplierCurrent}
                />}

                {
                    !isCheckVersionOrderV2 && !showAll && !!artworksMore && artworksMore.length > 0 ?
                        <div className="ShowAll" onClick={this._onChangeShowAll}>
                            View more
                        </div> :
                        <>
                            <div className="listArtwork">
                                {
                                    showAll && !!artworksMore && artworksMore.length > 0 && artworksMore.map((item, index) => (
                                        <OrderItemGroupArtworkItem
                                            key={`first_${index}`}
                                            artwork={item}
                                            validates={validates}
                                            typeDisplay="more"
                                            piecesPerPack={piecesPerPack}
                                            fulfillmentStatus={fulfillmentStatus}
                                            selectedComplexEmbroidery={this.props.selectedComplexEmbroidery}
                                            selectComplexEmbroidery={this.props.selectComplexEmbroidery}
                                            showEmbroiderySurchargeButton={this.props.showEmbroiderySurchargeButton}
                                            fulfillItem={fulfillItem}
                                            originFulfillItem={originFulfillItem}
                                        />
                                    ))
                                }
                            </div>
                            {
                                !!artworksMore && artworksMore.length > 0 &&
                                <div className="ShowAll" onClick={this._onChangeShowAll}>
                                    Hide
                                </div>
                            }
                        </>
                }

                {
                    !!error &&
                    <div className="text-danger">{error}</div>
                }
            </td>
        )
    }
}

FulfilledPackageItemDesignV3.propTypes = {
    orderItemId: PropTypes.string.isRequired,
    product: PropTypes.object.isRequired,
    orderId: PropTypes.string.isRequired,
    productMapId: PropTypes.string,
    fulfillmentStatus: PropTypes.string,
    fulfillment_item_id: PropTypes.string
}

export default FulfilledPackageItemDesignV3
