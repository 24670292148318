import React from 'react'
import OrderProfitHead from './OrderProfitTable/OrderProfitHead'
import OrderProfitRow from './OrderProfitTable/OrderProfitRow'

function OrderProfitTable(props) {
    const { 
        listOrderProfit, loading, reload,
        togglePackageDetailModal, 
    } = props
    return (
        <div className="OrderProfitTable">
        <div className="wrapTable">
            <table className='table'>
                <OrderProfitHead/>
                <tbody>
                    {
                        !!listOrderProfit && listOrderProfit.map((item, i) => {
                            return <OrderProfitRow 
                                        key={item._id} 
                                        index={i} 
                                        item={item} 
                                        reload={reload}
                                        togglePackageDetailModal={togglePackageDetailModal}
                                    />
                        })
                    }
                    {
                        !loading && listOrderProfit.length === 0 && 
                        <tr>
                            <td colSpan={9}>
                                No result
                            </td>
                        </tr>
                    }
                </tbody>
            </table>
        </div>
    </div>
    )
}

export default OrderProfitTable