import React, {useReducer, useEffect} from 'react'
import {splitPackagePreview} from "../../../services/api/CoolieServices"
import {getListSuppliersV2} from "../../../services/api/SuppliersServices"
import OrderFulfillmentBasicInfo from "./basic-info/OrderFulfillmentBasicInfo"
import ReturnPage from "../../shared/ReturnPage"
import {Modal, Spin, Collapse} from 'antd'
import FulfillmentPackageContainer from './packages/FulfillmentPackageContainer'
import {OrderFulfillmentContext, initialState, reducer} from './store';
import { getOrder } from '../../../services/api/OrdersServices'

const {Panel} = Collapse;

const _splitPackagePreview = async (orderId) => {
    return splitPackagePreview(orderId).then(({data: response, success}) => {
        const packages = !Array.isArray(response.packages) ? [] : response.packages

        if (!packages.length) {
            packages.push({
                packName: `${response.order.id}-1`,
                supplier: null,
                items: response.orderItems,
            })
        }

        return {
            message: response.message,
            errors: response.errors,
            order: response.order,
            isLoading: false,
            packages: packages.map(item => ({
                _id: item.packName,
                ...item
            }))
        }
    })
        .catch(e => {
            return Promise.resolve({
                order: null,
                packages: [],
                isLoading: false,
                message: e.message,
                errors: e.errors,
            })
        })
}

const _getOderShippingPlan = async (orderId) => {
    try {
        const {data, success, message} = await getOrder(orderId)
        if(!success) return console.log(message)
        return data.shipping_plan
    } catch (error) {
        console.log(error.message)
    }
}

/**
 * @param {Object} props
 * @param {String} props.orderId
 * @returns {JSX.Element}
 */
export default (props) => {
    const {orderId} = props
    const [state, dispatch] = useReducer(reducer, initialState)

    useEffect(() => {
        if (state.order && state.order._id === orderId) {
            return
        }

        async function fetchData() {
            const payload = await _splitPackagePreview(orderId)
            const shippingPlan = await _getOderShippingPlan(orderId)
            payload.shippingPlan = shippingPlan

            dispatch({type: 'UPDATE_FORM', payload})
            getListSuppliersV2({limit: 1000, active: true}, true).then(({data, success, message}) => {
                if (!success) {
                    return Modal.error({
                        className: 'split-package__modal',
                        title: 'Oops Something went wrong!',
                        content: message || 'Can not fetch list suppliers',
                        onOk: () => {
                            window.location.reload()
                        }
                    })
                }

                dispatch({type: 'UPDATE_LIST_SUPPLIERS', payload: data.suppliers})
            })
        }

        fetchData()
    }, [])

    const _orderFulfillment = () => {
        const items = []
        const {order, packages, message, errors} = state

        if (order) {
            items.push(<OrderFulfillmentBasicInfo order={order} key={1}/>)
        }

        if (message || errors) {
            items.push(
                <Collapse defaultActiveKey={['1']} key={2}>
                    <Panel header={<strong className="text-danger">{message}</strong>} key="1">
                        <pre className="text-danger"><code>{JSON.stringify(errors, null, 2)}</code></pre>
                    </Panel>
                </Collapse>
            )
        }

        if (order && packages && packages.length > 0) {
            items.push(<FulfillmentPackageContainer order={order} packages={packages} key={3}/>)
        }

        return items
    }

    const providerState = {
        state,
        dispatch,
    }

    return (
        <OrderFulfillmentContext.Provider value={providerState}>
            <div className="OrderFulfillmentPage col-lg-10 col-md-12 m-auto">
                <ReturnPage url={`/a/orders/${orderId}`} title={`Order #${orderId}`}/>

                <Spin
                    className="OrderFulfillmentPage__loading"
                    spinning={state.isLoading}
                    tip="Load packages..."
                    size="large"
                >
                    {_orderFulfillment()}
                </Spin>
            </div>
        </OrderFulfillmentContext.Provider>
    )

}
