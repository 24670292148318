import React from 'react'
import PropTypes from 'prop-types'
import {deleteProductImage} from "../../../../services/api/ProductsServices"

ProductImagesItem.propTypes = {
    image: PropTypes.object
}

function ProductImagesItem(props) {
    const {image, productId, reloadImages, disableAll} = props

    const _handleClickDelete = async () => {
        try {
            const {success, message} = await deleteProductImage(productId, image._id)

            if (!success) throw new Error(message)

            if (typeof reloadImages === 'function') {
                await reloadImages()
            }
        } catch (e) {
            console.log(e)
        }
    }

    return (
        <div className='ProductImagesItem'>
            <img className='ProductImageView' src={image.url} alt=''/>
            {!disableAll && 
                <i className='RemoveImage ti-close text-danger' onClick={_handleClickDelete}/>
            }
        </div>
    )
}

export default ProductImagesItem
