import React, {useEffect, useState} from 'react'
import {Spin} from "antd"
import {getReportProductionTime} from "../../../../services/api/SuppliersServices"
import { Link } from "react-router-dom"

function ReportOvertimeProduction(props) {
    const {suppliers} = props
    const [statics, setStaticsShipmentReport] = useState({})
    const [summary, setSummary] = useState({})
    const [error, setError] = useState('')
    const [loading, setLoading] = useState(false)
    
    async function _fetchOvertimeProductionReport() {
        try {
            setLoading(true)
            setError('')

            const supplierPF = suppliers.find(item => item.sku_prefix === 'PF')

            if (supplierPF === undefined) return

            const payloadStatic = {
                supplier: supplierPF._id,
            }

            const response = await getReportProductionTime(payloadStatic)

            const {success, data, message} = response

            if (!success) {
                setLoading(false)
                return setError(message)
            }

            const {product_types, summary} = data

            setLoading(false)
            if(summary !== null) {
                const {production_time_data: dataSummary} = summary
                setSummary(dataSummary || {})
            }
            setStaticsShipmentReport(product_types)

        } catch (e) {
            setLoading(false)
            setError(e.message)
        }
    }

    useEffect(() => {
        _fetchOvertimeProductionReport()
    }, [suppliers])

    return (
        <div className="ReportOverIntransit">
            <h3>Production Delay Report</h3>
            <div className="text-danger">{error}</div>
            <Spin spinning={loading} tip='Getting statics...'>
                <table className="table table-bordered">
                    <thead>
                    <tr>
                        <th rowSpan={2}>Product type</th>
                        <th className="text-center bg_1" colSpan={4}>Remaining days</th>
                        <th className="text-center bg_2" colSpan={6}>Production delay days</th>
                    </tr>
                    <tr>
                        <th className="text-right">3 days</th>
                        <th className="text-right">2 days</th>
                        <th className="text-right">1 day</th>
                        <th className="text-right">Total</th>
                        <th className="text-right">1 day</th>
                        <th className="text-right">2 days</th>
                        <th className="text-right">3 days</th>
                        <th className="text-right">4 days</th>
                        <th className="text-right">{`>=5 days`}</th>
                        <th className="text-right">Total</th>
                    </tr>
                    </thead>
                    <tbody>
                    {summary !== undefined && (
                        <tr>
                            <td/>
                            <td className="text-right font-weight-bold">{summary['-3'] || 0}</td>
                            <td className="text-right font-weight-bold">{summary['-2'] || 0}</td>
                            <td className="text-right font-weight-bold">{summary['-1'] || 0}</td>
                            <td className="text-right font-weight-bold">
                                {(summary['-1'] || 0) + (summary['-2'] || 0) + (summary['-3'] || 0)}
                            </td>
                            <td className="text-right font-weight-bold">{summary['1'] || 0}</td>
                            <td className="text-right font-weight-bold">{summary['2'] || 0}</td>
                            <td className="text-right font-weight-bold">{summary['3'] || 0}</td>
                            <td className="text-right font-weight-bold">{summary['4'] || 0}</td>
                            <td className="text-right font-weight-bold">{summary['5'] || 0}</td>
                            <td className="text-right font-weight-bold">
                                {(summary['1'] || 0) + (summary['2'] || 0) + (summary['3'] || 0) + (summary['4'] || 0) + (summary['5'] || 0)}
                            </td>
                        </tr>
                    )}
                    {statics.length > 0 && statics.map(item => {
                        const {production_time_data} = item
                        return (
                            <tr key={item._id}>
                                <td>{item.product_type}</td>
                                <td className="text-right">
                                    <Link to={`/a/orders-supplier?delay=production_delay_from_in_production_at&suppliers=${item.supplier.id}&product_type=${item.product_type}&delayed_days=-3`}>
                                        {production_time_data['-3'] || 0}
                                    </Link>
                                </td>
                                <td className="text-right">
                                    <Link to={`/a/orders-supplier?delay=production_delay_from_in_production_at&suppliers=${item.supplier.id}&product_type=${item.product_type}&delayed_days=-2`}>
                                        {production_time_data['-2'] || 0}
                                    </Link>
                                </td>
                                <td className="text-right">
                                    <Link to={`/a/orders-supplier?delay=production_delay_from_in_production_at&suppliers=${item.supplier.id}&product_type=${item.product_type}&delayed_days=-1`}>
                                        {production_time_data['-1'] || 0}
                                    </Link>
                                </td>
                                <td className="text-right">
                                    <Link to={`/a/orders-supplier?delay=production_delay_from_in_production_at&suppliers=${item.supplier.id}&product_type=${item.product_type}&delayed_days=-3&delayed_days=-2&delayed_days=-1`}>
                                        {(production_time_data['-1'] || 0) + (production_time_data['-2'] || 0) + (production_time_data['-3'] || 0)}
                                    </Link>
                                </td>
                                <td className="text-right">
                                    <Link to={`/a/orders-supplier?delay=production_delay_from_in_production_at&suppliers=${item.supplier.id}&product_type=${item.product_type}&delayed_days=1`}>
                                        {production_time_data['1'] || 0}
                                    </Link>
                                </td>
                                <td className="text-right">
                                    <Link to={`/a/orders-supplier?delay=production_delay_from_in_production_at&suppliers=${item.supplier.id}&product_type=${item.product_type}&delayed_days=2`}>
                                        {production_time_data['2'] || 0}
                                    </Link>
                                </td>
                                <td className="text-right">
                                    <Link to={`/a/orders-supplier?delay=production_delay_from_in_production_at&suppliers=${item.supplier.id}&product_type=${item.product_type}&delayed_days=3`}>
                                        {production_time_data['3'] || 0}
                                    </Link>
                                </td>
                                <td className="text-right">
                                    <Link to={`/a/orders-supplier?delay=production_delay_from_in_production_at&suppliers=${item.supplier.id}&product_type=${item.product_type}&delayed_days=4`}>
                                        {production_time_data['4'] || 0}
                                    </Link>
                                </td>
                                <td className="text-right">
                                    <Link to={`/a/orders-supplier?delay=production_delay_from_in_production_at&suppliers=${item.supplier.id}&product_type=${item.product_type}&delayed_days=5&delayed_days=6`}>
                                        {production_time_data['5'] || 0}
                                    </Link>
                                </td>
                                <td className="text-right">
                                    <Link to={`/a/orders-supplier?delay=production_delay_from_in_production_at&suppliers=${item.supplier.id}&product_type=${item.product_type}&delayed_days=1&delayed_days=2&delayed_days=3&delayed_days=4&delayed_days=5&delayed_days=6`}>
                                        {(production_time_data['1'] || 0) + (production_time_data['2'] || 0) + (production_time_data['3'] || 0) + (production_time_data['4'] || 0) + (production_time_data['5'] || 0)}
                                    </Link>
                                </td>
                            </tr>
                        )
                    })}

                    </tbody>
                </table>
            </Spin>
        </div>
    )
}

export default ReportOvertimeProduction