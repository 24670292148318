import React, {Component} from 'react'
import {userSignIn, userSignInAuth} from '../../../services/api/UserService'
import * as AuthServices from '../../../services/AuthServices'
import {withRouter} from 'react-router-dom'
import {extendUrl} from '../../../statics/extenent-url'
import { parseJwt } from '../../../helpers/parseToken'

class LoginPage extends Component {
    state = {
        loading: false,
        error: '',
    }

    _formData = {}

    _handleSubmit = e => {
        e.preventDefault()

        const isValid = this._signInValidator()

        if (!isValid) return

        this._reqSignIn()
    }

    _handleChange = e => {
        const {name, value} = e.target

        this._formData[name] = value.trim()
    }

    _signInValidator = () => {
        const {username, password} = this._formData

        if (!username || !password) {
            this.setState({
                error: 'Invalid username or password!',
            })

            return false
        }

        return true
    }

    _reqSignIn = async () => {
        try {
            this.setState({
                loading: true,
                error: '',
            })

            const authPayload = {
                username : this._formData.username,
                password : this._formData.password
            }
            const {data, success, message} = await userSignInAuth(authPayload)

            if (!success) throw new Error(message)

            const {accessToken, permissions, refreshToken, roles, facPermissions, ...rest} = data
            if(!Object.keys(permissions).length) {
                return this.setState({
                    error: `Your account don't have permissions!!!`,
                })
            }
            const timeExp = parseJwt(accessToken).exp - parseJwt(accessToken).iat
            AuthServices.setAccessToken(accessToken)
            AuthServices.setRefreshToken(refreshToken)
            AuthServices.setUserData(rest)
            AuthServices.setPermissionsData(permissions)
            AuthServices.setTimeExp((timeExp - 300) * 1000 + new Date().getTime())
            AuthServices.setRoles(roles)


            const {location, history} = this.props
            const {search, state} = location
            const regexReferrer = `^\\?referrer=${extendUrl.url.replace(/\//gi, '\\/')}\\/?(.*)$`
            const parsed = (new RegExp(regexReferrer, 'gi')).exec(search)

            // if (parsed) {
            //     const referrerUrl = parsed[1]

            //     window.location.href = `${extendUrl.url}/${referrerUrl || ''}?token=${accessToken}`

            //     return
            // }

            if (state && state.referrer) {
                history.push(state.referrer)

                return
            }

            history.push('/')
        } catch (e) {
            if (e.response.status !== 200) {
                return this.setState({
                    error: e.response.data.message,
                })
            } else {
                return this.setState({
                    error: e.message,
                })
            }
        } finally {
            this.setState({
                loading: false,
            })
        }
    }

    render() {
        const {error, loading} = this.state

        return (
            <div className='LoginPage pt-5'>
                <div className='row'>
                    <div className='col-12 col-xl-4 m-auto'>
                        <div className='bg-white p-4 shadow-sm rounded'>
                            <h1 className='PageTitle'>Account Login</h1>

                            <form onSubmit={this._handleSubmit}>
                                <div className='form-group'>
                                    <label htmlFor=''>Username</label>
                                    <input type='username' className='form-control' autoFocus required name='username'
                                           onChange={this._handleChange}/>
                                </div>

                                <div className='form-group'>
                                    <label htmlFor=''>Password</label>
                                    <input type='password' className='form-control' required name='password'
                                           onChange={this._handleChange}/>
                                </div>

                                <button type='submit' className='btn btn-primary px-4' disabled={loading}>Sign in
                                </button>
                            </form>

                            {
                                error && <div className='text-danger mt-3'>{error}</div>
                            }
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default withRouter(LoginPage)
