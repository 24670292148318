import React, {useContext} from 'react'
import DocTitleByStore from "../../dashboard/shared/DocTitleByStore"
import ReturnPage from "../../shared/ReturnPage"
import ScanDetailTable from "./ScanDetailTable"
import {ScanDetailContext} from "../context/ScanDetailContext"
import ScanDetailFilter from "./ScanDetailFilter"

const ScanDetailPage = (props) => {
    const {scanId, statusCarrier} = props
    const returnUrl = '/a/switch-carriers'
    const ScanDetailContextPage = useContext(ScanDetailContext)
    const {
        listScanDetail,
        loading,
        error,
        total,
        page,
        limit,
        setPage,
        setLimit,
        status,
        trackingNumber,
        onChangeStatus,
        onChangeNumber
    } = ScanDetailContextPage

    return <div className="ScanDetailPage">
        <DocTitleByStore title={`#${scanId} - Switch carrier detail`}/>
        <ReturnPage url={returnUrl} title="Requests"/>
        <h1 className="PageTitle mb-3">Switch carriers</h1>

        <div className='SectionInner'>
            <ScanDetailFilter
                status={status}
                trackingNumber={trackingNumber}
                onChangeStatus={onChangeStatus}
                onChangeNumber={onChangeNumber}
            />

            {error && <div className="text-danger mb-3">{error}</div>}

            <ScanDetailTable
                total={total}
                page={page}
                limit={limit}
                loading={loading}
                setLimit={setLimit}
                statusCarrier={statusCarrier}
                setPage={setPage}
                scanDetail={listScanDetail}
            />
        </div>
    </div>
}

export default ScanDetailPage
