import React, {Component} from 'react'
import PropTypes from 'prop-types'
import {Radio, Select} from 'antd'
import classNames from 'classnames'

const {Option, OptGroup} = Select

class FilterOptions extends Component {
    _handleChange = e => {
        const {value} = e.target

        this._handleUpdateState(value)
    }

    _handleUpdateState = value => {
        const {onChangeOption, field, defaultOption} = this.props

        onChangeOption({[field]: value === undefined ? defaultOption : value})
    }

    _renderLayoutSelect = () => {
        const {options, field, selectedKey, mode, allowClear, defaultOption, placeHolder, loading, showSearch = false} = this.props

        return (
            <>
                <Select onChange={this._handleUpdateState} defaultValue={defaultOption}
                        style={{width: '100%'}} value={selectedKey} mode={mode}
                        allowClear={allowClear && selectedKey !== ''} disabled={loading}
                        filterOption={(input, option) => 
                            option.children.toLocaleLowerCase().includes((input || "").toLocaleLowerCase())
                        }
                        showSearch={showSearch}
                        >
                    {
                        placeHolder && <Option value=''>{placeHolder}</Option>
                    }
                    {
                        options.map((option, index) => {
                            if (option.hasOwnProperty('label')) {
                                const {label, data} = option

                                return (
                                    <OptGroup label={label} key={`${field}_group_${index + 1}`}>
                                        {
                                            data.map((item, idx) => {
                                                const {name: _name, value: _value} = item

                                                return <Option key={`${field}_${_value}_${idx + 1}`}
                                                               value={_value}>{_name}</Option>
                                            })
                                        }
                                    </OptGroup>
                                )
                            }

                            const {name, value} = option

                            return <Option key={`${field}_${value}_${index + 1}`} value={value}>{name}</Option>
                        })
                    }
                </Select>
            </>
        )
    }

    _renderLayoutLabel = () => {
        const {options, field, selectedKey, defaultOption} = this.props

        return (
            <div className='btn-group btn-group-toggle'>
                <Radio.Group defaultValue={defaultOption} value={selectedKey} onChange={this._handleChange}
                             buttonStyle='solid'>
                    {
                        options.map((option, index) => {
                            const {name, value} = option

                            return <Radio.Button key={`${field}_option_${index}`} value={value}>{name}</Radio.Button>
                        })
                    }
                </Radio.Group>
            </div>
        )
    }

    _renderContent = () => {
        const {layout} = this.props

        switch (layout) {
            case 'label':
                return this._renderLayoutLabel()
            case 'select':
                return this._renderLayoutSelect()
            default:
                return ''
        }
    }

    render() {
        const {heading, className} = this.props
        const content = this._renderContent()

        return (
            <div className={classNames('FilterOptions', className)}>
                {
                    heading &&
                    <div className='mb-1 Heading'>{this.props.heading}</div>
                }

                {content}
            </div>
        )
    }
}

FilterOptions.defaultProps = {
    layout: 'label',
    heading: '',
    className: '',
    mode: 'default',
    placeHolder: ''
}

FilterOptions.propTypes = {
    field: PropTypes.string.isRequired,
    options: PropTypes.array.isRequired,
    onChangeOption: PropTypes.func.isRequired,
    layout: PropTypes.oneOf(['select', 'label']).isRequired,
    heading: PropTypes.string,
    className: PropTypes.string,
    mode: PropTypes.oneOf(['default', 'multiple', 'tags']),
    selectedKey: PropTypes.any.isRequired,
    allowClear: PropTypes.bool,
    defaultOption: PropTypes.any.isRequired,
    placeHolder: PropTypes.string
}

export default FilterOptions
