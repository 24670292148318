import React from 'react'
import moment from 'moment'

const ItemCreated = ({ pushAt, paidAt, created }) => {
    return (
        <td className='ItemCreated'>
            <span className='d-block created' style={{whiteSpace: 'nowrap'}}>
                {created ? moment(created).format("DD/MM/YYYY HH:mm:ss") : '-'}
            </span>
            <span className='d-block paidAt' style={{whiteSpace: 'nowrap'}}>
                {paidAt ? moment(paidAt).format("DD/MM/YYYY HH:mm:ss") : '-'}
            </span>
            <span className='d-block pushAt' style={{whiteSpace: 'nowrap'}}>
                {pushAt ? moment(pushAt).format("DD/MM/YYYY HH:mm:ss") : '-'}
            </span>
        </td>
    )
}

export default ItemCreated