import React, {Component} from 'react'
import {searchProducts} from "../../../../../../services/api/ProductsServices"
import AsyncSelect from 'react-select/async'

class ShippingMethodsProduct extends Component {
    state = {
        inputValue: {},
    }

    _timer = null

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.selectedPlan !== this.props.selectedPlan && prevProps.selectedPlan !== "") {
            const {onRemoveSelectedProduct} = this.props
            const {inputValue} = this.state


            if (inputValue.hasOwnProperty("value")) {
                onRemoveSelectedProduct(prevProps.selectedPlan, inputValue.value)

                this.setState({
                    inputValue: {},
                })
            }
        }

        if (this.props.product !== '' && this.props.inputValue !== false && this.state.inputValue !== this.props.inputValue) {
            this.setState({
                inputValue: this.props.inputValue
            })
        }
    }

    _fetchListProducts = async (term = "") => {
        try {
            const {data, success, message} = await searchProducts({
                limit: -1,
                term: term,
            })

            if (!success) {
                console.log(message)

                return
            }

            const {products} = data

            return products.map(product => {
                return {
                    value: product._id,
                    label: product.title,
                }
            })
        } catch (e) {
            console.log(e.message)
        }
    }

    _handleLoadOptions = (inputValue) => {
        if (this._timer) clearTimeout(this._timer)

        return new Promise(resolve => {
            this._timer = setTimeout(() => {
                resolve(this._fetchListProducts(inputValue))
            }, 500)
        })
    }

    _handleInputChange = (selected) => {
        const {index, _handleUpdatePlansState, onUpdateSelectedProduct, selectedPlan} = this.props

        this.setState({
            inputValue: selected,
        })

        _handleUpdatePlansState(index, "products", [selected.value])
        _handleUpdatePlansState(index, "inputValue", selected)
        onUpdateSelectedProduct(selectedPlan, selected.value)
    }

    _handleDisableOption = (option) => {
        const {onGetSelectedProduct, selectedPlan} = this.props
        const selectedProducts = onGetSelectedProduct()

        if (!selectedPlan) {
            return true
        }

        const findPlan = selectedProducts.find(selected => selected.plan === selectedPlan)

        if (findPlan === undefined) {
            return false
        }

        const products = findPlan.products

        return products.includes(option.value)
    }

    render() {
        const {productTitle} = this.props
        const {inputValue} = this.state

        return (
            <td className="ShippingMethodsProduct">
                {
                    !!productTitle
                        ? productTitle
                        : <AsyncSelect
                            cacheOptions
                            defaultOptions
                            loadOptions={this._handleLoadOptions}
                            onChange={this._handleInputChange}
                            isOptionDisabled={this._handleDisableOption}
                            value={inputValue}
                        />
                }
            </td>
        )
    }
}

export default ShippingMethodsProduct