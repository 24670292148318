import React from 'react'
import DocTitleByStore from "../../dashboard/shared/DocTitleByStore"
import OrderPartnerPage from "./OrderPartnerPage"

function OrderPartnerContainer(props) {
    const {fufillmentId} = props.match.params


    return (
        <section className="OrderPartnerContainer">
            <DocTitleByStore title="Package Management"/>
            <OrderPartnerPage fufillmentId={fufillmentId}/>
        </section>
    )
}

export default OrderPartnerContainer