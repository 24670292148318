import React, {useState, useEffect} from 'react'
import PropTypes from 'prop-types'
import {getHistoryDiscount} from "../../../services/api/DiscountServices"
import humanizeTime from "../../../helpers/time/humanizeTime"
import ObjectInspector from "react-object-inspector"
import {Spin} from "antd"

Histories.propTypes = {
    id: PropTypes.string.isRequired
}

function Histories(props) {
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState('')
    const [data, setData] = useState([])

    async function _fetchHistory() {
        try {
            const {id} = props

            const response = await getHistoryDiscount(id, 'page=1&limit=1000')

            const {data, success, message} = response

            if (!success) {
                setLoading(false)

                return setError(message)
            }

            setLoading(false)
            setError('')
            setData(data.histories)
        } catch (e) {
            setLoading(false)
            setError(e.message)
        }
    }

    useEffect(() => {
        _fetchHistory()
    }, [])

    return (
        <div className="Histories">
            <div className="TitleHeading">
                History logs
            </div>
            <div className="Content">
                <Spin spinning={loading} tip='Loading...'>
                    {
                        !!data && data.length > 0 && data.map(item => (
                            <div className="HistoriesItem" key={item._id}>
                                <div className="card">
                                    <div className="card-header">{humanizeTime(item.created)}</div>

                                    <div className="card-body">
                                        <div className="row align-items-baseline mb-3">
                                            <div className="col-4">
                                                <code>{item.event}</code>
                                            </div>

                                            <div className="col-8 text-muted">{item.description}</div>
                                        </div>

                                        <div className="row align-items-baseline">
                                            <div className="col-4">Value</div>
                                            <div className="col-8">
                                                <ObjectInspector depth={0} path="root.value" data={item.value}/>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))
                    }
                </Spin>
                {
                    !!error && error.length > 0 && <div className="text-danger">{error}</div>
                }
            </div>
        </div>
    )
}

export default Histories