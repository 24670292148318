import React, {Component} from 'react'
import PropTypes from 'prop-types'

class ToggleOrderItems extends Component {
    _handleChangeInput = e => {
        const {checked} = e.target

        this.props.onChangeState('displayOrderItem', checked)
    }

    render() {
        return (
            <div className='ToggleOrderItems FilterOptions mr-3'>
                <div className='custom-control custom-checkbox'>
                    <input
                        type='checkbox'
                        className='custom-control-input'
                        id='toggleOrderItems'
                        onChange={this._handleChangeInput}
                    />
                    <label htmlFor='toggleOrderItems' className='Heading custom-control-label'>
                        Display order items
                    </label>
                </div>
            </div>
        )
    }
}

ToggleOrderItems.propTypes = {
    onChangeState: PropTypes.func.isRequired,
}

export default ToggleOrderItems
