import createAPIServices from './createApiServices'

const baseUrl = process.env.NODE_ENV === 'production' ? '/api/platform' : 'https://fulfillment-staging.merchize.com/api/platform'
const api = createAPIServices({baseUrl})

export const getPlatformSettings = (platform, category) => {
    return api.makeAuthRequest({
        url: `/platforms/${platform}/settings/${category}`,
        method: 'GET',
    })
}

export const savePlatformSettings = (platform, category, settings) => {
    return api.makeAuthRequest({
        url: `/platforms/${platform}/settings/${category}`,
        method: 'POST',
        data: settings,
    })
}

export const generateSecret = (flatform) =>{
    return api.makeAuthRequest({
        url:`/platforms/${flatform}/settings/webhook/generate-secret`,
        method:'POST'
    })
}

