import React from 'react'
import DocTitleByStore from '../../dashboard/shared/DocTitleByStore'
import PackageSplittingRuleCreatePage from './PackageSplittingRuleCreatePage'
import '../styles/_index.scss'

function PackageSplittingRuleCreateContainer(props) {
  const {ruleId: id} = props.match.params

  return (
    <div className='PackageSplittingRuleCreateContainer'>
      <DocTitleByStore title="Package Splitting Rule Create" />
      <PackageSplittingRuleCreatePage id={id}/>
    </div>
  )
}

export default PackageSplittingRuleCreateContainer