import React, {Component} from 'react'
import PropTypes from 'prop-types'
import humanizeTime from "../../../../helpers/time/humanizeTime"
import StatusStyle from "../../../shared/StatusStyle"

class OrderArchived extends Component {
    render() {
        const {order} = this.props
        const {is_archive, archived_at} = order

        if (!is_archive) return null

        const archivedAt = humanizeTime(archived_at)

        return (
            <div className="OrderArchived ml-3" title={`Archived at ${archivedAt}`}>
                <StatusStyle status="archived"/>
            </div>
        )
    }
}

OrderArchived.propTypes = {
    order: PropTypes.object.isRequired,
}

export default OrderArchived
