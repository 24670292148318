import React, {Component} from 'react'
import PropTypes from 'prop-types'
import {Button, message, Modal, Spin} from "antd"
import {
    generateSecret,
    getPlatformSettings,
    savePlatformSettings
} from "../../../../services/api/PlatformSettingsServices"
import Url from "./Url"

class WebhookModal extends Component {
    state = {
        platform: {
            _id: this.props.platformId
        },
        loading: {
            fetchSettings: false,
            saveSettings: false,
            generateSecret: false
        },
        err: {
            fetchSettings: null,
            saveSettings: null,
            generateSecret: null
        },
        settings: {},
        secretKey: {}
    }

    _handleOk = () => {
        this._saveSettings()
    }

    _changeLoading = (loadingState, errState) => {
        const {loading, err} = this.state
        return {
            loading: {
                ...loading,
                ...loadingState
            },
            err: (errState) ? err : {
                ...err,
                ...errState
            }
        }
    }

    _saveSettings = async () => {
        const {platform, settings} = this.state
        this.setState({
            ...this._changeLoading({
                saveSettings: true
            }, {
                saveSettings: null
            })
        })

        try {
            const {success, message: error} = await savePlatformSettings(platform._id, 'webhook', settings)

            if (success) return this.setState({
                ...this._changeLoading({
                    saveSettings: false
                })
            }, () => {
                const {onClose} = this.props

                onClose()
                message.success('Update success!')
            })

            this.setState({
                ...this._changeLoading({
                    saveSettings: false
                }, {
                    saveSettings: error
                }),
            })
        } catch (e) {
            this.setState({
                ...this._changeLoading({
                    saveSettings: false
                }, {
                    saveSettings: e.message || e
                })
            })
        }
    }

    _fetchSettings = async () => {
        const {platform} = this.state
        this.setState({
            ...this._changeLoading({
                fetchSettings: true
            }, {
                fetchSettings: null
            })
        })

        try {
            const {success, data, message} = await getPlatformSettings(platform._id, 'webhook')

            if (success) {
                return this.setState({
                    ...this._changeLoading({
                        fetchSettings: false
                    }),
                    settings: data,
                })
            }
            return this.setState({
                ...this._changeLoading({
                    fetchSettings: false
                }, {
                    fetchSettings: message
                })
            })
        } catch (e) {
            this.setState({
                ...this._changeLoading({
                    fetchSettings: false
                }, {
                    fetchSettings: e.message || e
                })
            })
        }
    }

    _fetchSecureSecret = async () => {
        this.setState({
            ...this._changeLoading({
                generateSecret: true
            }, {
                generateSecret: null
            })
        })

        try {
            const {success, message} = await generateSecret(this.props.platformId)

            if (success) {
                return this.setState({
                    ...this._changeLoading({
                        generateSecret: false
                    }),
                }, () => {
                    this._fetchSettings()
                })
            }

            return this.setState({
                ...this._changeLoading({
                    generateSecret: false
                }, {
                    generateSecret: message
                })
            })
        } catch (e) {
            this.setState({
                ...this._changeLoading({
                    generateSecret: false
                }, {
                    generateSecret: e.message || e
                })
            })
        }

    }

    _changeSetting = (key, value) => {
        this.setState(({settings}) => ({
            settings: {
                ...settings,
                [key]: value,
            }
        }))
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.platformId !== prevProps.platformId) {
            this.setState({
                platform: {
                    _id: this.props.platformId
                }
            }, () => {
                this._fetchSettings()
            })
        }
    }

    render() {
        const {visible, onClose} = this.props
        const {settings, loading, err, secretKey} = this.state

        return (
            <div className='WebhookModal'>
                <Modal
                    className="SettingModal"
                    visible={visible}
                    title="Setting webhooks"
                    onOk={this._handleOk}
                    onCancel={onClose}
                    width={'50%'}
                    footer={[
                        <Button key="back" size='large' onClick={onClose}>
                            Cancel
                        </Button>,
                        <Button key="submit" size='large' type="primary"
                                disabled={loading.fetchSettings || loading.saveSettings || loading.generateSecret}
                                loading={loading.fetchSettings || loading.saveSettings || loading.generateSecret}
                                onClick={this._handleOk}>
                            Save
                        </Button>
                    ]}
                >
                    <div className="Content">
                        <Spin spinning={loading.fetchSettings || loading.saveSettings || loading.generateSecret}>
                            <div className="Webhook">
                                <div className='HeaderTop'>
                                    <Button
                                        type='primary'
                                        size='large'
                                        loading={loading.generateSecret}
                                        onClick={this._fetchSecureSecret}
                                    >
                                        Generate Secret Key
                                    </Button>
                                </div>

                                <div className="SettingSection">
                                    <div className="SectionSettings">
                                        <div className="SectionInner">
                                            {
                                                (err.fetchSettings !== null || err.generateSecret !== null || err.saveSettings !== null) &&
                                                <div className='text-danger mb-3'>
                                                    {err.fetchSettings || err.saveSettings || err.generateSecret}
                                                </div>
                                            }
                                            <Url
                                                loading={loading.fetchSettings || loading.saveSettings || loading.generateSecret}
                                                settings={settings}
                                                secretKey={secretKey}
                                                onChangeSetting={this._changeSetting}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Spin>
                    </div>
                </Modal>
            </div>
        )
    }
}

WebhookModal.propTypes = {
    platformId: PropTypes.string
}

export default WebhookModal
