import React from 'react'
import ManageAutoMapContext from "../context/ManageAutoMapContext"
import PropTypes from "prop-types"

ManageAutoMapProvider.propTypes = {
    listSelected: PropTypes.array,
    listRules: PropTypes.array,
    fetchMappingRules: PropTypes.func.isRequired
}

function ManageAutoMapProvider(props) {
    const {fetchMappingRules, listSelected, listRules} = props

    return (
        <ManageAutoMapContext.Provider
            value={{
                listRules,
                listSelected,
                fetchMappingRules
            }}
        >
            {props.children}
        </ManageAutoMapContext.Provider>
    )
}

export default ManageAutoMapProvider
