import React, {Component, createRef} from 'react'
import PropTypes from 'prop-types'
import {Modal, ModalHeader, ModalBody, ModalFooter} from 'reactstrap'

class CreateNewApiKeyModal extends Component {
    state = {
        apiKey: {
            name: '',
        }
    }

    nameInput = createRef()

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.open) this.nameInput.focus()
    }

    _onClose = () => {
        this.setState({
            apiKey: {name: ''},
        })
        this.props.onClose()
    }

    _onSave = () => {
        const {apiKey} = this.state
        if (!apiKey.name) return

        const newApiKey = {...apiKey}
        this.props.onOk(newApiKey)
    }

    _changeInput = (key) => (e) => {
        const {value} = e.target
        this.setState(({apiKey}) => ({
            apiKey: {
                ...apiKey,
                [key]: value,
            }
        }))
    }

    render() {
        const {open} = this.props
        const {apiKey} = this.state

        return (
            <Modal
                isOpen={open}
                toggle={this._onClose}
                className="CreateNewApiKeyModal"
            >
                <ModalHeader toggle={this._onClose}>
                    Create new API Key
                </ModalHeader>
                <ModalBody>
                    <div className="InputWrapper">
                        <label htmlFor="api-key-name">
                            Name
                        </label>
                        <input
                            ref={(input) => this.nameInput = input}
                            id="api-key-name"
                            className="Input"
                            value={apiKey.name || ''}
                            onChange={this._changeInput('name')}
                            autoComplete="off"/>
                    </div>
                </ModalBody>
                <ModalFooter>
                    <button className="btn OutlineButton" onClick={this._onClose}>
                        Close
                    </button>
                    <button className="btn Button" onClick={this._onSave}>
                        Save
                    </button>
                </ModalFooter>
            </Modal>
        )
    }
}

CreateNewApiKeyModal.propTypes = {
    open: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    onOk: PropTypes.func.isRequired,
}

export default CreateNewApiKeyModal
