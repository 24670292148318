import React, { useState } from 'react'
import BulkEditPrice from './BulkEditPrice'
import { Popconfirm, Modal, Tooltip, Input, AutoComplete, message } from 'antd'
import ProductVariantsHistory from './ProductVariantsHistory'
import { updateProductLineStore } from '../../../../services/api/ProductsServices'

function StoreHead(props) {
    const { isShowMoreTable, item = {}, disableAll, onChangePriceStore, confirm, index, storeName = [], fetchListVariants, fetchStoreVariants } = { ...props }
    const [isModalOpen, setIsModalOpen] = useState(false)
    const [showEditModal, setShowEditModal] = useState(false)
    const [namespace, setNamespace] = useState(item.store || '')
    const [storename, setStorename] = useState(item.name || '')
    const [group, setGroup] = useState(item.group || '')

    const toggleShowModal = () => {
        setIsModalOpen(!isModalOpen)
    }

    const toggleEditModal = () => {
        setShowEditModal(!showEditModal)
        setNamespace(item.store || '')
        setStorename(item.name || '')
        setGroup(item.group || '')
    }

    const _handleChangeNamespace = (e) => {
        const { value } = e.target
        setNamespace(value.trim())
    }

    const _handleChangeStore = (e) => {
        const { value } = e.target
        setStorename(value.trim())
    }

    const _handleChangeGroup = (value) => {
        setGroup(value)
    }

    const handleEdit = async () => {
        try {
            const payload = {}
            if (storeName) payload.storename = storename
            if (group) payload.group = group
            const res = await updateProductLineStore(item._id[0], payload)
            const { success, data, message: mess } = { ...res }
            if (!success) {
                throw new Error(mess)
            }
            message.success(`Store ${namespace} updated!`)
            toggleEditModal()
            fetchListVariants()
            fetchStoreVariants()
        } catch (error) {
            message.error(error.message)
        }

    }

    const groupOptions = storeName.length > 0 ? [...new Set(storeName.map(i => i.group).filter(Boolean))].map(i => ({ value: i })) : []

    return (
        item.store &&
        <th className={isShowMoreTable ? 'table-col-5' : 'table-col-auto'} key={`${index}_store`}>
            {item.store && <div className='cursor-pointer' onClick={() => toggleEditModal()}>
                <Tooltip placement='top' title='Edit store'>
                    {item.store}
                </Tooltip>
            </div>}
            {item.name && <div className='cursor-pointer' onClick={() => toggleEditModal()}>{`(${item.name})`}</div>}
            {!disableAll &&
                <div className="d-flex align-items-center">
                    <BulkEditPrice onChangePrice={onChangePriceStore} idStore={item._id} />
                    <Tooltip placement='top' title='History'>
                        <i className="fa fa-history History cursor-pointer ml-3" onClick={() => toggleShowModal()}></i>
                    </Tooltip>
                    <Popconfirm
                        title={`Are you sure to delete this store (${item.store})?`}
                        okText="Yes"
                        cancelText="No"
                        onConfirm={() => confirm(item._id)}
                    >
                        <Tooltip placement='top' title='Delete store'>
                            <i className="ti-close cursor-pointer ml-3" />
                        </Tooltip>
                    </Popconfirm>
                </div>
            }

            <ProductVariantsHistory objectType="store" objectId={item._id} store={item} isOpen={isModalOpen} toggleShowModal={() => toggleShowModal()} />

            <Modal
                title="Edit Store"
                visible={showEditModal}
                onOk={handleEdit}
                onCancel={toggleEditModal}
                okButtonProps={{ disabled: !namespace }}
                okText='Save'
            >
                <div className="item-store mb-2">
                    <div className='mb-1'>Namespace <span className='text-danger'>*</span></div>
                    <Input placeholder="Enter namespace" value={namespace} disabled onChange={_handleChangeNamespace} />
                </div>
                <div className="item-store mb-2">
                    <div className='mb-1'>Storename</div>
                    <Input placeholder="Enter storename" value={storename} disabled onChange={_handleChangeStore} />
                </div>
                <div className="item-group mb-2">
                    <div className='mb-1'>Group</div>
                    <AutoComplete style={{ width: '100%' }} placeholder="Enter group" value={group} onChange={_handleChangeGroup} options={groupOptions} />
                </div>
            </Modal>
        </th>
    )
}

export default StoreHead