import React from 'react'
import PropTypes from 'prop-types'
import {Select, Input, Spin} from "antd"

const {Option} = Select

FiltersProductLine.propTypes = {
    loadingSupplier: PropTypes.bool,
    errorSupplier: PropTypes.string,
    suppliers: PropTypes.array,
    supplier: PropTypes.string,
    term: PropTypes.string,
    type: PropTypes.string,
    skuPrefix: PropTypes.string,
    onChangeSupplier: PropTypes.func.isRequired,
    onChangeTitle: PropTypes.func.isRequired,
    onChangeType: PropTypes.func.isRequired,
    onChangeSku: PropTypes.func.isRequired
}

function FiltersProductLine(props) {

    const {loadingSupplier, errorSupplier, supplier, suppliers, term, type, skuPrefix} = props

    function _handleChangeSupplier(value) {
        const {onChangeSupplier} = props

        if (typeof onChangeSupplier === 'function') {
            onChangeSupplier(value)
        }
    }

    function _handleChangeTitle(e) {
        const {value} = e.target
        const {onChangeTitle} = props

        if (typeof onChangeTitle === 'function') {
            onChangeTitle(value)
        }
    }

    function _handleChangeType(e) {
        const {value} = e.target
        const {onChangeType} = props

        if (typeof onChangeType === 'function') {
            onChangeType(value)
        }
    }

    function _handleChangeSku(e) {
        const {value} = e.target
        const {onChangeSku} = props

        if (typeof onChangeSku === 'function') {
            onChangeSku(value)
        }
    }


    return (
        <div className="FiltersProductLine">
            <div className="FilterSupplier">
                <Spin spinning={loadingSupplier} tip="Get list supplier...">
                    <Select
                        value={supplier}
                        onChange={_handleChangeSupplier}
                        className={!!errorSupplier && errorSupplier.length > 0 ? 'Error' : ''}
                    >
                        <Option value="">All</Option>
                        {
                            !!suppliers && suppliers.length > 0 && suppliers.map(item => (
                                <Option key={item._id} value={item._id}>{item.name}</Option>
                            ))
                        }
                    </Select>
                </Spin>
            </div>

            <div className="FilterInput">
                <Input placeholder="Search title" value={term} onChange={_handleChangeTitle}/>
            </div>

            <div className="FilterInput">
                <Input placeholder="Search type" value={type} onChange={_handleChangeType}/>
            </div>

            <div className="FilterInput">
                <Input placeholder="Search SKU" value={skuPrefix} onChange={_handleChangeSku}/>
            </div>
        </div>
    )
}

export default FiltersProductLine