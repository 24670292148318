import {runWebhookJobs} from '../../../../services/api/WebhookServices'
import {message, notification} from 'antd'

export default async function (id, afterSuccessAction) {
    try {
        const {success, message: resMessage} = await runWebhookJobs(id)

        if (!success) {
            throw new Error(resMessage)
        }

        message.info('Action in the background...')

        afterSuccessAction()
    } catch (e) {
        notification.error({
            message: 'ERROR',
            description: e.message,
            duration: 0,
        })
    }
}