import React, {Component} from 'react'
import PropTypes from 'prop-types'
import imageDefault from "../../../../../helpers/common/imageDefault"

class ProductLineItem extends Component {
    _handleClickProduct = (e) => {
        e.preventDefault()

        const {_handUpdateState} = this.props
        const productId = this.props.product._id

        _handUpdateState("selectedProduct", productId)
    }

    render() {
        const {product} = this.props
        const {title, image} = product

        return (
            <div className="ProductLineItem cursor-pointer mb-3 py-3" onClick={this._handleClickProduct}>
                {
                    <div className="AspectRatioBox">
                        <img
                            src={!!image ? image.url : ''}
                            alt="Product line thumbnail"
                            onError={(e) => {
                                e.target.onerror = null;
                                e.target.src = imageDefault
                            }}
                        />
                    </div>
                }
                <h6 className="mb-0 mt-3">
                    {title}
                </h6>
            </div>
        )
    }
}

ProductLineItem.propTypes = {
    product: PropTypes.object.isRequired,
}

export default ProductLineItem
