import React, {Component} from 'react'
import PropTypes from 'prop-types'
import Modal from "reactstrap/es/Modal"
import ModalBody from "reactstrap/es/ModalBody"
import {importTrackingCSV} from "../../../../services/api/OrdersServices"
import className from 'classnames'
import ModalHeader from "reactstrap/es/ModalHeader"

class ImportTrackingModal extends Component {
    state = {
        loading: false,
        error: "",
        result: null,
    }

    _handleChangeFile = e => {
        const selectedFile = e.target.files[0]

        this._handleImportTracking(selectedFile)
    }

    _handleImportTracking = async (file) => {
        this.setState({
            loading: true,
        })

        const postData = new FormData()
        postData.append("file", file)

        try {
            const {data, success, message} = await importTrackingCSV(postData)

            if (!success) {
                return this.setState({
                    loading: false,
                    error: message,
                })
            }

            return this.setState({
                loading: false,
                error: "",
                result: data,
            })
        } catch (e) {
            return this.setState({
                error: e.message,
            })
        }
    }

    render() {
        const {showModal, onToggleModal} = this.props
        const {loading, error, result} = this.state

        return (
            <Modal isOpen={showModal} className="UploadInput ImportTrackingModal" toggle={onToggleModal}>
                <ModalHeader toggle={onToggleModal}>Import tracking number via CSV file</ModalHeader>
                <ModalBody>
                    <ul>
                        <li>
                            <div className="d-flex justify-content-between align-items-baseline">
                                <span>- From 1C</span>
                                <label htmlFor="importTrackingCSV"
                                       className={className("btn btn-sm btn-primary cursor-pointer mb-0", {"processing-animation": loading})}>Upload
                                    CSV</label>
                            </div>
                            <input type="file" accept=".csv" id="importTrackingCSV" onChange={this._handleChangeFile}/>

                            {
                                result &&
                                <div className="ImportResult p-3 mt-3">
                                    <div className="Success row mb-1">
                                        <div className="col-3">Success:</div>
                                        <div className="col-9 font-weight-500">{result.success.length}</div>
                                    </div>

                                    <div className="Fail row">
                                        <div className="col-3">Failed:</div>
                                        <div className="col-9 font-weight-500">{result.failed.length}</div>
                                    </div>
                                </div>
                            }
                        </li>
                    </ul>

                    {
                        !!error && <div className="text-danger mt-3">{error}</div>
                    }
                </ModalBody>
            </Modal>
        )
    }
}

ImportTrackingModal.propTypes = {
    onToggleModal: PropTypes.func.isRequired,
    showModal: PropTypes.bool.isRequired,
}

export default ImportTrackingModal
