import React from 'react'
import DocTitleByStore from '../../dashboard/shared/DocTitleByStore'
import PackageIssuesPage from './PackageIssuesPage'
import '../styles/_index.scss'
import queryString from 'query-string'

function PackageIssuesContainer(props) {
    const { history } = props
    const { location = {} } = history
    let params = {}

    if (location.search !== '') {
        const _params = queryString.parse(location.search)
        const supplier_confirm = []
        const status = []
        if (!!_params.issue_type) {
            params.issue_type = _params.issue_type
        }

        if (!!_params['created_at.from'] && !!_params['created_at.to']) {
            params.created_at = { from: _params['created_at.from'], to: _params['created_at.to'] }
        }

        if (!!_params.supplier) {
            params.supplier = _params.supplier
        }

        if (!!_params["supplier_confirm.0"]) supplier_confirm.push(_params["supplier_confirm.0"])

        if (!!_params["supplier_confirm.1"]) supplier_confirm.push(_params["supplier_confirm.1"])

        if (!!_params["supplier_confirm.2"]) supplier_confirm.push(_params["supplier_confirm.2"])

        if(!!supplier_confirm.length) params.supplier_confirm = supplier_confirm

        if (!!_params["status.0"]) status.push(_params["status.0"])

        if (!!_params["status.1"]) status.push(_params["status.1"])

        if(!!status.length) params.statusParams = status

        if (!!_params.product_type) {
            params.product_type = _params.product_type
        }
    }

    return (
        <div className='PackageIssuesContainer'>
            <DocTitleByStore title="Package Issues Management" />
            <PackageIssuesPage {...props} params={params} />
        </div>
    )
}

export default PackageIssuesContainer