import React, {Component} from 'react'
import PropTypes from 'prop-types'
import ReturnAddress from './ReturnAddress'


class ReturnAddressContainer extends Component {
    render() {
        const {order} = this.props
        const {return_address} = Object.assign({}, order)

        return (
            <div className="card">
                <div className="card-header font-weight-bold d-flex justify-content-between">
                    <span>Return Address</span>
                </div>

                <div className="card-body">
                    {!!return_address && <ReturnAddress returnAddress={return_address}/>}
                </div>
            </div>
        )
    }
}

ReturnAddressContainer.propTypes = {
    order: PropTypes.object.isRequired
}

export default ReturnAddressContainer
