import React, {Component} from 'react'
import Modal from "reactstrap/es/Modal"
import ModalBody from "reactstrap/es/ModalBody"
import ModalFooter from "reactstrap/es/ModalFooter"

class ShippingPlanEditorActions extends Component {
    state = {
        confirmModal: false,
    }

    _handleClickUpdate = e => {
        e.preventDefault()

        this.props._handleUpdatePlan()
    }

    _handleClickDelete = e => {
        e.preventDefault()

        this.props._hanleDeleteShippingPlan()
    }

    _handleToggleModal = () => {
        return this.setState({
            confirmModal: !this.state.confirmModal,
        })
    }

    render() {
        const {loading, title, updatePermission, deletePermission} = this.props
        const {confirmModal} = this.state

        return (
            <div className="ShippingPlanEditorActions">
                <div className="d-flex justify-content-between">
                    {deletePermission &&
                        <button className="btn btn-outline-danger" onClick={this._handleToggleModal}>Delete plan</button>
                    }
                    {updatePermission &&
                        <button className="btn btn-primary" onClick={this._handleClickUpdate}>
                            {
                                loading ? "Updating..." : "Update plan"
                            }
                        </button>
                    }
                </div>

                <Modal isOpen={confirmModal} toggle={this._handleToggleModal}>
                    <ModalBody>
                        Are you sure want to delete <strong>{title}</strong>? This action cannot be
                        reversed.
                    </ModalBody>
                    <ModalFooter>
                        <button className="btn border" onClick={this._handleToggleModal}>Cancel</button>
                        <button className="btn btn-danger" onClick={this._handleClickDelete}>Delete plan</button>
                    </ModalFooter>
                </Modal>
            </div>
        )
    }
}

export default ShippingPlanEditorActions
