import React from 'react'
import PropTypes from 'prop-types'

ProductVariantRowDimension.propTypes = {
    value: PropTypes.any,
    field: PropTypes.string,
    variantIndex: PropTypes.number,
    onChangeVariantDimension: PropTypes.func.isRequired
}

function ProductVariantRowDimension(props) {
    const {value, field, variantIndex, onChangeVariantDimension, handlePaste, disableAll} = props

    const _handleChange = e => {
        const {value} = e.target
        const valueEntered = Number(value)

        if (isNaN(valueEntered)) {
            return
        }

        if (typeof onChangeVariantDimension === 'function') {
            onChangeVariantDimension(variantIndex, field, value)
        }
    }

    const _handlePasteInput = e => {
        handlePaste(e, field, variantIndex)
    }

    return (
        <td className='ProductVariantRowDimension'>
            <input type='text' className="form-control" value={value || ''} onChange={_handleChange} onPaste={_handlePasteInput} disabled={disableAll}/>
        </td>
    )
}

export default ProductVariantRowDimension
