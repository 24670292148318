import React from 'react'
import PropTypes from 'prop-types'
import ReactPaginate from "react-paginate"

CategoriesPagination.propTypes = {
    pages: PropTypes.number,
    page: PropTypes.number,
    onChangePage: PropTypes.func.isRequired
}

function CategoriesPagination(props) {
    const {page, pages, onChangePage} = props

    const _handlePageChange = (data) => {
        const {selected} = data

        if (typeof onChangePage === 'function') {
            onChangePage(selected + 1)
        }
    }

    return (
        pages > 1 &&
        <div className="CategoriesPagination Pagination">
            <ReactPaginate
                previousLabel="Previous"
                nextLabel="Next"
                pageCount={pages}
                forcePage={page - 1}
                marginPagesDisplayed={2}
                pageRangeDisplayed={5}
                onPageChange={_handlePageChange}
                containerClassName="pagination"
                pageClassName='page-item'
                previousClassName='Previous page-item'
                nextClassName='Next page-item'
                pageLinkClassName='page-link'
                previousLinkClassName='page-link'
                nextLinkClassName='page-link'
                subContainerClassName="pages pagination"
                activeClassName='active'
            />
        </div>
    )
}

export default CategoriesPagination
