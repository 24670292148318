import React, { Component } from 'react'
import OrderCancelItem from './OrderCancelItem'

class OrderCancelItems extends Component {

    render() {
        const { packageStatus, items } = this.props

        const fulfillmentItemCancel = items.filter(item => {
            const {fulfillmentItems = []} = item

            const itemCancel = fulfillmentItems.filter(item => item.production_status === 'cancelled' || item.production_status === 'cancel-ship')
            if(itemCancel.length > 0) return item
        })

        if (packageStatus === 'cancelled' || packageStatus === 'cancel-ship' || (packageStatus !== 'cancelled' && packageStatus !== 'cancel-ship' && fulfillmentItemCancel.length <= 0)) {
            return null
        }

        return (
            <div className="OrderCancelledItems" style={{borderBottom: '1px solid rgba(0, 0, 0, 0.125)', marginBottom: '40px', paddingBottom: '40px'}}>
                <div className="card">
                    <div className="card-header d-flex justify-content-between">
                        <span className="CancelHeading font-weight-bold">Cancel</span>
                    </div>

                    <div className="card-body bg-light">
                        {
                            fulfillmentItemCancel.map(item => {
                                return (
                                    <OrderCancelItem key={item._id} item={item}/>
                                )
                            })
                        }
                    </div>
                </div>
            </div>
        )
    }
}

export default OrderCancelItems
