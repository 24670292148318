import React, {Component} from 'react'
import DocTitleByStore from "../../dashboard/shared/DocTitleByStore"
import SuppliersPageV2 from "./SuppliersPageV2"
import queryString from 'query-string'

class SuppliersPageContainer extends Component {

    render() {
        const {history} = this.props
        const {location} = history
        let params = {}
        if (location.search !== '') {
            params = queryString.parse(location.search)
        }

        return (
            <section className="SuppliersPageContainer">
                <DocTitleByStore title="Suppliers"/>
                <SuppliersPageV2 name={params.supplier}/>
            </section>
        )
    }
}

export default SuppliersPageContainer