import React from 'react'
import ReactDOM from 'react-dom'
import './env'
import 'antd/dist/antd.min.css'
import './statics/assets/css/libs/themify-icons.css'
import './statics/assets/css/libs/icomoon-icons.css'
import './scss/app.scss'
import App from './app/App'
import Router from 'react-router-dom/Router'
import getHistory from './store/getHistory'
import * as Sentry from "@sentry/react"
import {Integrations} from "@sentry/tracing"

if(window.location.hostname === "fulfillment.merchize.com"){
    Sentry.setTag('webapp', 'Fulfillment Webapp')
    Sentry.init({
        dsn: "https://ab08172c4ff499dfdfd456e9a3008442@sentry-fac.mymerchize.com/9",
        integrations: [new Integrations.BrowserTracing(),],
        tracesSampleRate: 1.0,
        environment: "production",
    })
}

ReactDOM.render(
    (
        <Router history={getHistory()}>
            <App/>
        </Router>
    ),
    document.getElementById('root'),
)
