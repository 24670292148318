import React, {Component} from 'react'
import PropTypes from 'prop-types'
import {listStoreModes} from "../../../../../statics/storeModes"

class ProductStoreMode extends Component {
    _handleChangeStoreMode = (value) => {
        const {onChangeStoreMode} = this.props
        if (typeof onChangeStoreMode === 'function') {
            onChangeStoreMode(value)
        }
    }
    _renderStoreMode = (storeModes) => {
        return storeModes.map(item => {
            const storeMode = listStoreModes.find(mode => mode.value === item)
            return <li onClick={() => this._handleChangeStoreMode([item])} key={item} value={item}>{storeMode.name}</li>
        })
    }

    render() {
        const {storeModes} = this.props
        const renderStoreMode = storeModes !== undefined && this._renderStoreMode(storeModes)

        return (
            <td className="ProductStoreMode">
                <ul className='listStoreMode'>
                    {storeModes !== undefined ? renderStoreMode :
                        <li value='normal' onClick={() => this._handleChangeStoreMode(['normal'])}>Normal</li>}
                </ul>
            </td>
        )
    }
}

ProductStoreMode.propTypes = {
    storeModes: PropTypes.array
}

export default ProductStoreMode