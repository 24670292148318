import React from 'react'

const OrderSelection = (props) => {
    const { orderId, selectedOrders, onUpdateOrdersSelected, fulfillmentId, rowSpan } = props

    const _detectChecked = () => {
        return selectedOrders.some(item => item.orderItem === orderId)
    }

    const _handleChange = () => {
        const isChecked = _detectChecked()

        onUpdateOrdersSelected(orderId, fulfillmentId, !isChecked)
    }
    return (
        <td className='OrderSelection' rowSpan={rowSpan}>
            <div className='custom-control custom-checkbox'>
                <input type='checkbox' checked={_detectChecked()}
                    id={`checkbox_${orderId}`}
                    className='custom-control-input' onChange={_handleChange} />
                <label htmlFor={`checkbox_${orderId}`} className='custom-control-label' />
            </div>
        </td>
    )
}

export default OrderSelection