import createApiServices from "./createApiServices"

const prefixURL = process.env.NODE_ENV === 'production' ? '/api/order' : 'https://fulfillment-staging.merchize.com/api/order'
const api = createApiServices({baseUrl: prefixURL})

export const syncOrderTrackingFromShopify = (orderId) => {
    return api.makeAuthRequest({
        url: `/orders/${orderId}/tracking/sync-shopify`,
        method: 'POST',
    })
}
