import createAPIServices from './createApiServices'

const baseUrl = process.env.NODE_ENV === 'production' ? '/api/platform' : 'https://fulfillment-staging.merchize.com/api/platform'
const api = createAPIServices({baseUrl})

export const createNewPlatform = (platform) => {
    return api.makeAuthRequest({
        url: '/platforms',
        method: 'POST',
        data: platform,
    })
}

export const getAllPlatform = ({page, limit}) => {
    return api.makeAuthRequest({
        url: '/platforms',
        method: 'GET',
        params: {page, limit}
    })
}

export const getPlatformDetails = (platform) => {
    return api.makeAuthRequest({
        url: `/platforms/${platform}`,
        method: 'GET',
    })
}

export const editPlatform = (platform, {name}) => {
    return api.makeAuthRequest({
        url: `/platforms/${platform}`,
        method: 'POST',
        data: {
            name,
        }
    })
}

export const activatePlatform = (platform) => {
    return api.makeAuthRequest({
        url: `/platforms/${platform}/activate`,
        method: 'POST',
    })
}

export const deactivatePlatform = (platform) => {
    return api.makeAuthRequest({
        url: `/platforms/${platform}/deactivate`,
        method: 'POST',
    })
}

export const getListRequests = (data) => {
    return api.makeAuthRequest({
        url: '/list-request/search',
        method: 'POST',
        data,
    })
}

export const requestUpdateDesign = (data, orderItemId) => {
    return api.makeAuthRequest({
        url: `/request-update-design/${orderItemId}`,
        method: 'POST',
        data,
    })
}
