import React from 'react'
import ProductContext from "../context/ProductContext"
import PropTypes from 'prop-types'

function ProductProvider(props) {
    const {onUpdateListAttributes} = props

    return (
        <ProductContext.Provider
            value={{
                onUpdateListAttributes
            }}
        >
            {props.children}
        </ProductContext.Provider>
    )
}

ProductProvider.propTypes = {
    onUpdateListAttributes: PropTypes.func.isRequired
}

export default ProductProvider
