import React, {useState} from 'react'
import PropTypes from 'prop-types'
import ProductAttributeNewValue from "./ProductAttributeNewValue"

ProductAttributeItem.propTypes = {
    attributeIndex: PropTypes.number,
    attribute: PropTypes.object,
    attributes: PropTypes.array,
    onChangeAtt: PropTypes.func.isRequired,
    onDeleteAtt: PropTypes.func.isRequired
}

function ProductAttributeItem(props) {
    const {attributeIndex, attribute, attributes, onChangeAtt, onDeleteAtt, disableAll} = props
    const {name, value_type, values} = attribute
    const [toggleNewValue, setToggleNewValue] = useState(false)
    const [showContent, setShowContent] = useState(true)

    const _handleClickListValues = (e) => {
        setToggleNewValue(!toggleNewValue)
    }

    const _handleRemoveAttribute = () => {
        if (typeof onDeleteAtt === 'function') {
            onDeleteAtt(attributeIndex)
        }
    }

    const _handleRemoveAttributeValue = (e) => {
        const removeValue = e.target.getAttribute('data-value')
        const newValues = values.filter(value => value.value !== removeValue)

        if (typeof onChangeAtt === 'function') {
            onChangeAtt(attributeIndex, 'values', newValues)
        }
    }

    const _handleShowContent = () => {
        setShowContent(!showContent)
    }

    const disableSide = attributes.filter(item => item.value_type === 'side_print')

    return (
        <li className='ProductAttributeItem mb-3'>
            <div className="card">
                <div className="card-header d-flex">
                    <h6 className="mb-0 font-weight-bold">{name || ''}</h6>

                    <div className="ml-auto">
                        <i className="fas fa-caret-down cursor-pointer" onClick={_handleShowContent}/>
                        {!disableAll && 
                            <span
                                className="text-danger ml-3 cursor-pointer"
                                onClick={_handleRemoveAttribute}
                            >
                                Remove
                            </span>
                        }
                    </div>
                </div>

                {
                    showContent &&
                    <div className="card-body">
                        <div className="row">
                            <div className="col-3">
                                <label>Name</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Name"
                                    value={name || ''}
                                    onChange={({target: {value}}) => onChangeAtt(attributeIndex, 'name', value)}
                                    disabled={disableAll}
                                />
                            </div>

                            <div className="col-3">
                                <label>Type</label>
                                <select
                                    className="form-control"
                                    value={value_type || 'select'}
                                    onChange={({target: {value}}) => onChangeAtt(attributeIndex, 'value_type', value)}
                                    disabled={disableAll}
                                >
                                    <option value="" disabled>-- Choose type --</option>
                                    <option value="select">Dropdown</option>
                                    <option value="color">Color</option>
                                    <option value="label">Label</option>
                                    <option value="slide">Slide</option>
                                    <option value="side_print" className={disableSide.length ? 'NotSelect' : ''}
                                            disabled={disableSide.length}>Side Print
                                    </option>
                                </select>
                            </div>

                            <div className="col-6">
                                <label>Values</label>
                                <div className="AttributeValues">
                                    <div className="border rounded p-2">
                                        <ul className="ListAttributes">
                                            {
                                                values.map((value, index) => {
                                                    return (
                                                        <li className="Item d-inline-block mr-2 mb-2"
                                                            key={index}>
                                                            <span className="Label badge badge-secondary p-2">
                                                                <span
                                                                    className={`Name ${value_type === 'color' && 'pr-2'}`}
                                                                >
                                                                    {value.name}
                                                                </span>

                                                                {
                                                                    value_type === 'color' &&
                                                                    <span className="Value border-left pl-2">
                                                                        {value.value}
                                                                    </span>
                                                                }
                                                                {!disableAll &&
                                                                    <span className=" Value pl-2 px-1 cursor-pointer">
                                                                        <i
                                                                            className="fas fa-times"
                                                                            data-value={value.value}
                                                                            onClick={_handleRemoveAttributeValue}
                                                                        />
                                                                    </span>
                                                                }
                                                            </span>
                                                        </li>
                                                    )
                                                })
                                            }
                                        </ul>
                                        {!disableAll &&
                                            <small
                                                className="form-text text-muted cursor-pointer"
                                                onClick={_handleClickListValues}
                                            >
                                                Add more +
                                            </small>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>

                        {
                            toggleNewValue &&
                            <ProductAttributeNewValue
                                valueType={value_type}
                                onChangeAtt={onChangeAtt}
                                values={values}
                                attributeIndex={attributeIndex}
                            />
                        }
                    </div>
                }
            </div>
        </li>
    )
}

export default ProductAttributeItem
