import React, {Component} from 'react'

class ShippingMethodsPlan extends Component {
    _handleChangeSelect = e => {
        const {value: planId} = e.target
        const {index, _handleUpdatePlansState} = this.props

        if ("" === planId) {
            return
        }

        _handleUpdatePlansState(index, "plan", planId)
    }

    render() {
        const {selectedPlan, planTitle, listPlans} = this.props

        return (
            <td className="ShippingMethodsPlan">
                {
                    !planTitle ?
                        <select name="plans" className="form-control" required value={selectedPlan}
                                onChange={this._handleChangeSelect}>
                            <option value="" disabled defaultChecked>-- Select plan --</option>
                            {
                                listPlans.map(plan => {
                                    return <option value={plan._id} key={plan._id}>{plan.title}</option>
                                })
                            }
                        </select>
                        :
                        planTitle
                }
            </td>
        )
    }
}

export default ShippingMethodsPlan
