import React, {Component, createRef} from 'react'
import PropTypes from 'prop-types'
import {Modal, ModalHeader, ModalBody, ModalFooter} from 'reactstrap'

class PlatformEditModal extends Component {
    nameInput = createRef()

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.open) this.nameInput.focus()
    }

    _onChangeInput = (key) => (e) => {
        const {value} = e.target

        this.props.onEditPlatform(key, value)
    }

    render() {
        const {open, onClose, onOk, platform, loading} = this.props

        return (
            <Modal
                isOpen={open}
                toggle={onClose}
                className="PlatformEditModal"
            >
                <ModalHeader
                    toggle={onClose}>
                    Edit platform
                </ModalHeader>
                <ModalBody>
                    <label htmlFor="platform-name">
                        Platform name
                    </label>
                    <input
                        id="platform-name"
                        disabled={loading}
                        value={platform ? platform.name : ''}
                        onChange={this._onChangeInput('name')}
                        className="Input"
                        placeholder="Platform name"
                        ref={(input) => this.nameInput = input}
                        autoComplete="off"/>
                </ModalBody>
                <ModalFooter>
                    <button className="btn CloseButton" onClick={onClose}>Close</button>
                    <button className="btn SaveButton" onClick={onOk}>Save platform</button>
                </ModalFooter>
            </Modal>
        )
    }
}

PlatformEditModal.defaultProps = {
    platform: {},
}

PlatformEditModal.propTypes = {
    open: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    onOk: PropTypes.func.isRequired,
    platform: PropTypes.object,
    onEditPlatform: PropTypes.func.isRequired,
    loading: PropTypes.bool.isRequired,
}

export default PlatformEditModal
