import React, { useState, useEffect, useRef } from 'react'
import { Select, Input, DatePicker, AutoComplete } from 'antd'
import moment from 'moment'
import { searchUser, searchUserAuth } from '../../../../services/api/UserService'
import { getReasonTags } from '../../../../services/api/OrdersServices'

function ChargeRefundFilter(props) {
    const { RangePicker } = DatePicker
    const { Option } = Select

    const {
        type, status,
        loading, orderId,
        handleChangeType,
        handleChangeStatus,
        handleChangeOrderId,
        handleChangeCreated,
        createdStart, createdEnd,
        onchangeFilter, userSearch: searchProps,
        reason, handleChangeReasonTags,
        permissionsData,
    } = props
    const dateFormat = "DD/MM/YYYY"
    const dateFilterValue = createdStart && createdEnd ? [moment(createdStart, dateFormat), moment(createdEnd, dateFormat)] : []
    const searchFieldValue = ['User created', 'User process', 'Namespace']
    const placeholders = {
        'User created': 'Search for user created',
        'User process': 'Search for user process',
        'Namespace': "Search for namespace"
    }
    const [searchField, setSearchField] = useState(searchFieldValue.length ? searchFieldValue[0] : null)
    const [userSearch, setUserSearch] = useState('')
    const [optionsUser, setOptionsUser] = useState([])
    const [reasonTags, setReasonTags] = useState([])
    const inputRef = useRef()

    const _onChangeSelectSearchField = e => {
        const { value } = e.target
        inputRef.current.focus()

        setUserSearch('')
        if (searchProps) delete { ...searchProps }
        setSearchField(value)
    }

    const _onChangeInputFilter = value => {
        setUserSearch(value)
        if (searchField === 'User created') return onchangeFilter('user_created', value)
        if (searchField === 'User process') return onchangeFilter('user_processed', value)
        if (searchField === 'Namespace') return onchangeFilter('store', value)

        onchangeFilter('mapping_context', value)
    }

    const getUserList = async () => {
        if(permissionsData.includes('ffm_setting_users_read')){
            try {
                const { data, success, message: mess } = await searchUserAuth(`page=${1}&limit=${10000}`,{})
                if (success) {
                    const { users } = data
                    setOptionsUser(users.map(i => ({ value: i.username })))
                } else {
                    console.log(mess)
                }
    
            } catch (error) {
                console.log(error)
            }
        } else {setOptionsUser([])}
    }
    const fetchReasonTags = async () => {
        try {
            const { success, data } = await getReasonTags()

            if (!success) return

            setReasonTags(data)
        } catch (e) {
            console.log(e.message)
        }
    }

    useEffect(() => {
        getUserList()
        fetchReasonTags()
    }, [])

    const reasonTagOptions = !!reasonTags && [
        ...reasonTags.filter(item => item).map(item => ({
            value: item.description,
            label: item.value,
            key: item._id
        }))
    ]

    return (
        <div className="TableFilter d-xl-flex justify-content-between align-items-end">
            <div className="Filter">
                <div className="d-flex flex-wrap align-items-center justify-content-start">
                    {/* <div className="FilterWrapper FilterOptions mb-3 mr-3">
                        <div className="d-flex align-items-stretch justify-content-end">
                            <Input
                                style={{ minWidth: "400px" }}
                                placeholder='Search order'
                                value={orderId || ''}
                                onChange={handleChangeOrderId}
                                allowClear
                                disabled={loading}
                            />
                        </div>
                    </div> */}

                    <div className='CustomSelect FilterOptions mr-3 mb-3'>
                        <Select
                            style={{ minWidth: "200px" }}
                            placeholder="-- Type --"
                            allowClear
                            value={type || []}
                            onChange={handleChangeType}
                            disabled={loading}
                        >
                            <Option value="refund">Refund</Option>
                            <Option value="charge">Charge</Option>
                        </Select>
                    </div>

                    <div className='CustomSelect FilterOptions mr-3 mb-3'>
                        <Select
                            style={{ minWidth: "200px" }}
                            placeholder="-- Status --"
                            value={status || []}
                            onChange={handleChangeStatus}
                            allowClear
                            disabled={loading}
                        >
                            <Option value="pending">Pending</Option>
                            <Option value="approved">Approved</Option>
                            <Option value="rejected">Rejected</Option>
                        </Select>
                    </div>

                    <div className='CustomSelect FilterOptions mr-3 mb-3'>
                        <RangePicker
                            id='createdAt'
                            placeholder={['Created start', 'Created end']}
                            ranges={{
                                'Today': [moment().startOf('day'), moment().endOf('day')],
                                'Last 7 Days': [moment().subtract(6, 'day').startOf('day'), moment().endOf('day')],
                                'This Month': [moment().startOf('month').startOf('day'), moment().endOf('month').endOf('day')],
                            }}
                            format={dateFormat}
                            onChange={handleChangeCreated}
                            popupStyle={{ maxWidth: '600px' }}
                            disabled={loading}
                            value={dateFilterValue}
                        />
                    </div>
                    <div className='CustomSelectSearch FilterOptions mr-3 mb-3'>
                        <div className="d-flex  align-items-center justify-content-end">
                            <div className="FilterOptions input-group-prepend SelectWrapper">
                                {searchField.length > 0 ? <select
                                    value={searchField}
                                    className="form-control"
                                    onChange={_onChangeSelectSearchField}
                                    style={{ height: '40px' }}
                                >
                                    {searchFieldValue.map((item, i) => <option key={i} value={item}>{item}</option>)}
                                </select> :
                                    <span className="input-group-text">
                                        <i className="ti ti-search" />
                                    </span>}
                            </div>

                            <div className="FilterOptions">
                                <i className="ti-search position-absolute d-none" />
                                <AutoComplete
                                    value={userSearch || ''}
                                    onChange={(e) => _onChangeInputFilter(e)}
                                    style={{ minWidth: "200px" }}
                                    placeholder={searchField.length ? placeholders[searchField] : ''}
                                    // options={searchField === "Namespace" ? [] : optionsUser}
                                    options={[]}
                                    ref={inputRef}
                                    filterOption={(inputValue, option) =>
                                        !!option && option.value.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
                                    }
                                    allowClear
                                />
                            </div>
                        </div>
                    </div>
                    <div className='CustomSelect FilterOptions mr-3 mb-3'>
                        <AutoComplete
                            value={reason || ''}
                            onChange={(value, e) => handleChangeReasonTags(value, e)}
                            style={{ minWidth: "300px" }}
                            placeholder={'Reason tags'}
                            options={reasonTagOptions}
                            filterOption={(inputValue, option) =>
                                !!option && option.value.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
                            }
                            allowClear
                        />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ChargeRefundFilter