import React, {Component} from 'react'
import PropTypes from 'prop-types'
import {Modal, notification} from 'antd'
import SuppliersContext from '../../../context/SuppliersContext'
import {bulkMarkOrdersProcessing} from '../../../../../services/api/OrdersServices'
import {ObjectInspector} from 'react-inspector'

class BulkMarkProcessing extends Component {
    _supplier = ''

    _handleChange = e => {
        const {value} = e.target

        this._supplier = value
    }

    _modalContent = (
        <div>
            <select className='form-control' defaultValue='' onChange={this._handleChange}>
                <option value='' disabled>-- Choose a supplier --</option>
                {
                    this.props.suppliers.map(supplier => {
                        return <option key={supplier._id}
                                       value={supplier._id}>{supplier.name}</option>
                    })
                }
            </select>
        </div>
    )

    _openModal = () => {
        this._renderModalContent()
    }

    _renderModalContent = () => {
        const _handleOk = this._reqBulkMarkProcessing

        Modal.confirm({
            title: 'Select supplier',
            content: this._modalContent,
            onOk() {
                return _handleOk()
            },
        })
    }

    _reqBulkMarkProcessing = async () => {
        try {
            const {selectedOrders: orderIds} = this.props
            const supplier = this._supplier

            const {data, success, message} = await bulkMarkOrdersProcessing(orderIds, supplier)

            if (!success) throw new Error(message)

            const {success: totalSuccess, error: totalError} = data

            if (totalError === 0) {
                notification.success({
                    message: 'SUCCESS',
                    description: <ObjectInspector data={data}/>,
                    duration: 0,
                })
            } else if (totalSuccess === 0) {
                notification.error({
                    message: 'ERROR',
                    description: <ObjectInspector data={data}/>,
                    duration: 0,
                })
            } else {
                notification.info({
                    message: 'RESULT',
                    description: <ObjectInspector data={data}/>,
                    duration: 0,
                })
            }

        } catch (e) {
            notification.error({
                message: 'ERROR',
                description: e.message,
                duration: 0,
            })
        }
    }

    render() {
        return (
            <div className='BulkMarkProcessing' onClick={this._openModal}>
                Mark processing
            </div>
        )
    }
}

BulkMarkProcessing.propTypes = {
    selectedOrders: PropTypes.array.isRequired,
    suppliers: PropTypes.array.isRequired,
}

export default function (props) {
    return (
        <SuppliersContext.Consumer>
            {
                suppliers => {
                    return <BulkMarkProcessing suppliers={suppliers} {...props}/>
                }
            }
        </SuppliersContext.Consumer>
    )
}
