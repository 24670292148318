import React, {Component} from 'react'
import PropTypes from 'prop-types'
import {ReactComponent as IconDown} from '../../../../statics/assets/images/icon_down.svg'

class CustomMultiSelect extends Component {
    constructor(props) {
        super(props)

        this.state = {
            openPopup: false,
            selected: []
        }
    }

    componentDidMount() {
        const {selected} = this.props

        if (!!selected && selected.length > 0) {
            this.setState({
                selected: [...selected]
            })
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const {selected} = this.props

        if (prevProps.selected !== selected) {
            if (!!selected && selected.length > 0) {
                this.setState({
                    selected: [...selected]
                })
            }
        }
    }

    _openPopup = () => {
        this.setState({
            openPopup: true
        })
    }

    _closePopup = () => {
        const {selected} = this.props

        this.setState({
            selected: !!selected ? [...selected] : [],
            openPopup: false
        })
    }

    _onSelectAll = () => {
        const {listFilters} = this.props

        this.setState({
            selected: listFilters.map(item => item.value)
        })
    }

    _onClearSelect = () => {
        this.setState({
            selected: []
        })
    }

    _onCheckBoxForDiv = (name) => {
        document.getElementById(name).click()
    }

    _handleChangeCheckbox = (e) => {
        const {checked, name} = e.target
        const {selected} = this.state

        if (checked) {
            this.setState({
                selected: [...selected, name]
            })
        } else {
            this.setState({
                selected: selected.filter(item => item !== name)
            })
        }
    }

    _onFilter = () => {
        const {onChangeFilters, name} = this.props

        if (typeof onChangeFilters === 'function') {
            onChangeFilters(name, this.state.selected)

            this.setState({
                openPopup: false
            })
        }
    }

    _mappingSelected = (selected) => {
        const {listFilters} = this.props

        if (selected.length > 0) {
            return selected.map(item => {
                const result = listFilters.filter(status => status.value === item)

                return result[0].name
            })
        }
    }

    render() {
        const {label, listFilters, placeholder} = this.props
        const {openPopup, selected} = this.state

        return (
            <div className='CustomMultiSelect'>
                <div className="font-weight-500 LabelFilter mb-1">{label}</div>
                <div className='InputSelect' onClick={this._openPopup}>
                    <span>
                        {selected.length === 0 ? placeholder : this._mappingSelected(selected).join(', ')}
                    </span>
                    <IconDown width={12} height={12}/>
                </div>
                <div className={`PopupSelect ${openPopup}`}>
                    <div className="ListItemsSelect">
                        {
                            listFilters.length > 0 && listFilters.map((item, index) =>
                                <div key={index} className='Item'>
                                    <input
                                        id={item.value}
                                        type="checkbox"
                                        name={item.value}
                                        checked={selected.includes(item.value)}
                                        onChange={this._handleChangeCheckbox}
                                    />
                                    <label onClick={() => this._onCheckBoxForDiv(item.value)}>{item.name}</label>
                                </div>)
                        }
                    </div>
                    <div className="Actions">
                        <button onClick={this._onSelectAll} className='LinkAction'>Select All</button>
                        <button
                            disabled={selected.length === 0}
                            className='LinkAction'
                            onClick={this._onClearSelect}
                        >
                            Clear
                        </button>
                        <button onClick={this._onFilter}>Filter</button>
                    </div>
                </div>
                {
                    openPopup && <div className="Background" onClick={this._closePopup}/>
                }
            </div>
        )
    }
}

CustomMultiSelect.propTypes = {
    label: PropTypes.string.isRequired,
    listFilters: PropTypes.array.isRequired,
    placeholder: PropTypes.string.isRequired,
    onChangeFilters: PropTypes.func.isRequired,
    selected: PropTypes.array,
    name: PropTypes.string
}

export default CustomMultiSelect
