import React, {Component} from 'react'
import PropTypes from "prop-types"
import OrderPreferenceShipping from "./OrderPreferenceShipping"
import OrderDelayGenLabel from "./OrderDelayGenLabel"
import OrderIOSS from "./OrderIOSS"


class OrderPreference extends Component {
    render() {
        const {order} = this.props
        const {zone} = Object.assign({}, order)
        const preference = Object.assign({}, order.preference)

        return (
            <div className="OrderPreference">
                <div className="card">
                    <div className="card-header font-weight-bold">Preference</div>

                    <div className="card-body">
                        <OrderPreferenceShipping preference={preference} order={order}/>
                        <OrderDelayGenLabel preference={preference}/>
                        {(zone !== undefined && zone === 'EU') && (
                            <OrderIOSS preference={preference} order={order}/>
                        )}
                    </div>
                </div>
            </div>
        )
    }
}

OrderPreference.propTypes = {
    order: PropTypes.object.isRequired,
}

export default OrderPreference
