import React from 'react'
import { Modal } from "antd"
import { Link } from 'react-router-dom'
import moment from 'moment'
import { map } from 'store/src/util'


const CatalogVariantsTableRow = (props) => {
    const { item, permissionsData } = props
    const { catalog_status, product_title,
        updated_at, mapping_items, mapping_status, product_status,
        sku, store_mode, suppliers, variant_status, _id, options
    } = Object.assign({}, item)

    return (
        <>
        {
            mapping_status === 'unmapped' &&
            <tr>
                <td className="SkuVariantMerchize">
                    <Link to={`/a/mapping-variants/${_id}`} className={`px-1 cursor-pointer`}>
                        {sku}
                    </Link>
                    <span className='productTitle'>{product_title}</span>
                    <span className={`catalogStatus ${catalog_status}`}>{catalog_status}</span>
                </td>
                <td className="VariantMerchize">{options.join('/')}</td>
                <td className="SkuVariantSupplier"></td>
                <td className="ProductTypeFulfill"></td>
                <td className="VariantSupplier"></td>
                <td className="Mode"></td>
                <td className="Supplier"></td>
                <td className={`Status ${mapping_status}`}>
                    <span>{mapping_status}</span>    
                </td>
                <td className="UpdateAt">
                    {updated_at ? moment(updated_at).format('HH:mm DD/MM/YYYY') : '-'}
                </td>
                <td className="Action">
                    <Link to={`/a/mapping-variants/${_id}`} className={`px-1 cursor-pointer`}>
                        <i className="fa fa-pencil fa-lg text-primary mr-2" />
                    </Link>
                </td>
            </tr>
        }
        {
            mapping_status === 'mapped' &&
            mapping_items.map((item, index) => (
                <tr key={`mappedItem_${index}`} className={`rowMapped ${index > 0 ? 'topDashed' : ''}`}>
                    {index === 0 &&
                        <>
                            <td className="SkuVariantMerchize" rowSpan={mapping_items.length}>
                                <Link to={`/a/mapping-variants/${_id}`} className={`px-1 cursor-pointer`}>
                                    {sku}
                                </Link>
                                <span className='productTitle'>{product_title}</span>
                                <span className='catalogStatus'>{catalog_status}</span>
                            </td>
                            
                            <td className="VariantMerchize" rowSpan={mapping_items.length}>{options.join('/')}</td>
                        </>
                    }

                    <td className="SkuVariantSupplier">
                        <Link to={`/a/products/${item.ffm_variant && item.ffm_variant.product ? item.ffm_variant.product : ''}`} className={`px-1 cursor-pointer`}>
                            {
                                item.supplier && item.supplier.name === '1C' ? item.ffm_sku : item.ffm_partner_sku ? item.ffm_partner_sku : item.ffm_sku
                            }
                        </Link>
                    </td>
                    <td className="ProductTypeFulfill">{item.ffm_product_type ? item.ffm_product_type : ''}</td>
                    <td className="VariantSupplier">{item.ffm_variant && item.ffm_variant.title ? item.ffm_variant.title : ''}</td>
                    <td className="Mode">{item.store_mode ? item.store_mode.join('/') : ''}</td>
                    <td className="Supplier">{item.supplier && item.supplier.name ? item.supplier.name : ''}</td>

                    {index === 0 &&
                        <>
                        <td className="Status" rowSpan={mapping_items.length}>
                            <span>{mapping_status}</span>    
                        </td>
                        <td className="UpdateAt" rowSpan={mapping_items.length}>
                            {updated_at ? moment(updated_at).format('HH:mm DD/MM/YYYY') : '-'}
                        </td>
                        <td className="Action" rowSpan={mapping_items.length}>
                            <Link to={`/a/mapping-variants/${_id}`} className={`px-1 cursor-pointer`}>
                                <i className="fa fa-pencil fa-lg text-primary mr-2" />
                            </Link>
                        </td>
                        </>
                    }
                    
                </tr>
            ))
        }
        </>
    )
}

export default CatalogVariantsTableRow