import React from 'react'
import { Button,message } from 'antd'
import { approvedChargeRefund } from '../../../../services/api/ChargeRefundServices'

function ApprovedChargeRefund(props) {
    const {id, reload, status, adminCheck}= props

    const handleClick = async(id) => {
        try {
            const { success, message:mess } = await approvedChargeRefund(id)
            if(success){
                message.success('Request Approved!')
                reload()
            }else{
                message.error(mess)
            }
            
        } catch (error) {
            message.error(error)
        }

    }
    return (
        <div>
            <Button 
                className='btn btn-success btn-sm'
                onClick={()=>handleClick(id)}
                disabled={!adminCheck || status!=='pending'}
            >
                Approve
            </Button>
        </div>
    )
}

export default ApprovedChargeRefund