import React, {Component} from 'react'

class ProductLineVariantsHead extends Component {
    render() {
        const {attributes} = this.props

        return (
            <thead className="ProductLineVariantsHead thead-light">
            <tr>
                {
                    attributes.map(att => {
                        return (
                            <th key={att._id}>{att.name}</th>
                        )
                    })
                }
                <th className="table-col-15">Price</th>
            </tr>
            </thead>
        )
    }
}

export default ProductLineVariantsHead
