import React from 'react'
import PropTypes from 'prop-types'
import {Button, Form, Input, Modal, Radio} from "antd"

ExportPackage.propTypes = {
    loading: PropTypes.bool,
    error: PropTypes.string,
    link: PropTypes.string,
    sheetName: PropTypes.string,
    isVisible: PropTypes.bool,
    typeExport: PropTypes.string,
    onCancel: PropTypes.func.isRequired,
    onChangeTypeExport: PropTypes.func.isRequired
}

function ExportPackage(props) {
    const {
        loading,
        error,
        link,
        sheetName,
        isVisible,
        onCancel,
        onUpdate,
        typeExport,
        onChangeTypeExport
    } = props

    function _handleChangeLink(e) {
        const {value} = e.target
        const {onChangeLink} = props

        if (typeof onChangeLink === 'function') {
            onChangeLink(value)
        }
    }

    function _handleChangeName(e) {
        const {value} = e.target
        const {onChangeName} = props

        if (typeof onChangeName === 'function') {
            onChangeName(value)
        }
    }

    function _handleChangeType(e) {
        const {value} = e.target

        if (typeof onChangeTypeExport === 'function') {
            onChangeTypeExport(value)
        }
    }

    return (
        <div className="ExportPackage">
            <Modal title="Export package"
                   visible={isVisible}
                   onCancel={onCancel}
                   footer={[
                       <Button key="back" onClick={onCancel}>
                           Cancel
                       </Button>,
                       <Button key="submit" type="primary" loading={loading} onClick={onUpdate}>
                           Export
                       </Button>
                   ]}
            >
                <Form>
                    {
                        !!error && error.length > 0 && <div className="text-danger mb-3">{error}</div>
                    }
                    <label htmlFor="link">
                        Link
                    </label>
                    <Input
                        id="link"
                        onFocus
                        defaultValue={link}
                        className="form-control mb-2"
                        onChange={_handleChangeLink}
                    />

                    <label htmlFor="tracking_name">
                        Name
                    </label>
                    <Input
                        id="tracking_name"
                        defaultValue={sheetName}
                        className="form-control mb-2"
                        onChange={_handleChangeName}
                    />

                    <Radio.Group onChange={_handleChangeType} value={typeExport} className="mt-2 mb-2">
                        <Radio value="items">Export Items</Radio>
                        <Radio value="packages">Export packages</Radio>
                    </Radio.Group>

                    <div>
                        Share your Google Sheet to this account:
                        <br/>
                        <strong>exporter@platform126.iam.gserviceaccount.com</strong>
                    </div>
                </Form>
            </Modal>
        </div>
    )
}

export default ExportPackage
