export const INTERNATIONAL_STATUS = [
    {
        name: 'All',
        value: '',
    },
    {
        name: 'US',
        value: 'US',
    },
    {
        name: 'EU',
        value: 'EU',
    },
    {
        name: 'ROW',
        value: 'ROW',
    },
]

export const PAYMENT_STATUS = [
    {
        value: '',
        name: 'All',
    },
    {
        value: 'paid',
        name: 'Paid',
    },
    {
        value: 'pending',
        name: 'Pending',
    },
    {
        value: "partially_refunded",
        name: "Partially refunded",
    },
    {
        value: "refunded",
        name: "Refunded",
    }
]

export const FULFILLMENT_STATUS = [
    {
        value: '',
        name: 'All',
    },
    {
        value: 'unfulfilled',
        name: 'Unfulfilled',
    },
    {
        value: 'processing',
        name: 'Processing',
    },
    {
        value: 'fulfilled',
        name: 'Fulfilled',
    },
    {
        value: 'partial',
        name: 'Partial',
    },
    {
        value: 'onhold',
        name: 'Onhold',
    },
    {
        value: 'rejected',
        name: 'Rejected',
    },
    {
        value: 'cancelled',
        name: 'Cancelled',
    },
]

export const ARTWORK_STATUS = [
    {
        value: '',
        name: 'All',
    },
    {
        value: 'completed',
        name: 'Completed',
    },
    {
        value: 'incomplete',
        name: 'Incomplete',
    },
    {
        value: 'missing',
        name: 'Missing',
    },
]

export const TRACKING_STATUS = [
    {
        value: '',
        name: 'All',
    },
    {
        value: 'completed',
        name: 'Completed',
    },
    {
        value: 'incomplete',
        name: 'Incomplete',
    },
    {
        value: 'missing',
        name: 'Missing',
    },
]

export const ORDERS_ARCHIVED = [
    {
        name: 'Hide archived orders',
        value: 'hide_archive',
    },
    {
        name: 'Only archived orders',
        value: 'only_archive',
    },
    {
        name: 'All orders',
        value: 'show_all',
    },
]

export const HAS_NOTE = [
    {
        name: 'All',
        value: '',
    },
    {
        name: 'Yes',
        value: 'yes',
    },
    {
        name: 'No',
        value: 'no',
    },
]

export const ORDER_TYPE = [
    {
        name: 'All',
        value: '',
    },
    {
        name: 'FBA',
        value: 'FBA',
    },
    {
        name: 'TIKTOK',
        value: 'TIKTOK'
    },
]

export const SHIPMENT_STATUS = [
    {
        name: 'All',
        value: '',
    },
    {
        name: 'Created',
        value: 'created',
    },
    {
        name: 'Unknown',
        value: 'unknown',
    },
    {
        name: 'Pre transit',
        value: 'pre_transit',
    },
    {
        name: 'In transit',
        value: 'in_transit',
    },
    {
        name: 'Out for delivery',
        value: 'out_for_delivery',
    },
    {
        name: 'Delivered',
        value: 'delivered',
    },
    {
        name: 'Return to sender',
        value: 'return_to_sender',
    },
    {
        name: 'Failure',
        value: 'failure',
    },
    {
        name: 'Cancelled',
        value: 'cancelled',
    },
    {
        name: 'Expired',
        value: 'expired',
    },
    {
        name: 'Exception',
        value: 'exception',
    },
]

export const PRODUCTION_STATUS = [
    {
        name: 'All',
        value: 'all',
    },
    {
        name: 'In production',
        value: 'in_production',
    },
    {
        name: 'No production',
        value: 'no_production',
    },
]

export const PACKAGE_STATUS = [
    {
        name: 'All',
        value: ''
    },
    {
        name: 'Pending',
        value: 'pending'
    },
    {
        name: 'Pushed',
        value: 'pushed'
    }
]

export const DATE_TRACKING = [
    {
        name: 'Select day',
        value: ''
    },
    {
        name: '1 day',
        value: 1
    },
    {
        name: '2 days',
        value: 2
    },
    {
        name: '3 days',
        value: 3
    },
    {
        name: '4 days',
        value: 4
    },
    {
        name: '5 days',
        value: 5
    },
    {
        name: '6 days',
        value: 6
    },
    {
        name: '7 days',
        value: 7
    },
]

export const MAPPING_CONDITION = [
    {
        name: 'All',
        value: '',
    },
    {
        name: 'Yes',
        value: 'yes',
    },
    {
        name: 'No',
        value: 'no',
    }
]

export const MOCKUP_STATUS = [
    {
        value: '',
        name: 'All',
    },
    {
        value: 'completed',
        name: 'Completed',
    },
    {
        value: 'incomplete',
        name: 'Incomplete',
    },
    {
        value: 'missing',
        name: 'Missing',
    },
]

export const REQUEST_UPDATE = [
    {
        label: "Waiting update",
        value: "waiting-update-design"
    },
    {
        label: "Updated",
        value: "updated-design"
    },
    {
        label: "No request",
        value: "no-request"
    },
    {
        label: "Force updated",
        value: "force-updated-design"
    },
] 
