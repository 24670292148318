export const supplierConfirms = [
    { name: 'Approve', value: 'approve', label: 'Approve' },
    { name: 'half', value: 'half', label: '50/50' },
    { name: 'Reject', value: 'reject', label: 'Reject' },
    { name: 'None', value: 'none', label: 'None' },
].sort((a, b) => a.name.localeCompare(b.name))

export const IssueCategorys = [
    { name: 'production', value: 'production', label: 'Production' },
    { name: 'shipment', value: 'shipment', label: 'Shipment' },
].sort((a, b) => a.name.localeCompare(b.name))

export const ProductionIssueTypes = [
    { name: 'Lỗi may/Gia công', value: 'Lỗi may/Gia công', label: 'Lỗi may/Gia công' },
    { name: 'Sai hàng', value: 'Sai hàng', label: 'Sai hàng' },
    { name: 'Size chart', value: 'Size chart', label: 'Size chart' },
    { name: 'Thiếu hàng', value: 'Thiếu hàng', label: 'Thiếu hàng' },
    { name: 'Sản phẩm lỗi', value: 'Sản phẩm lỗi', label: 'Sản phẩm lỗi' },
    { name: 'Vùng in to bé - lệch vùng in', value: 'Vùng in to bé - lệch vùng in', label: 'Vùng in to bé - lệch vùng in' },
    { name: 'Hình in mờ', value: 'Hình in mờ', label: 'Hình in mờ' },
    { name: 'Lệch màu in', value: 'Lệch màu in', label: 'Lệch màu in' },
    { name: 'Lỗi design ', value: 'Lỗi design ', label: 'Lỗi design ' },
    { name: 'Lỗi vải/Phôi/NVL', value: 'Lỗi vải/Phôi/NVL', label: 'Lỗi vải/Phôi/NVL' },
    { name: 'Khác', value: 'Khác', label: 'Khác' },
].sort((a, b) => a.name.localeCompare(b.name))

export const ShipmentIssueTypes = [
    { name: 'Damage (gãy, vỡ, hỏng)', value: 'Damage (gãy, vỡ, hỏng)', label: 'Damage (gãy, vỡ, hỏng)' },
    { name: 'Delay', value: 'Delay', label: 'Delay' },
    { name: 'Lost', value: 'Lost', label: 'Lost' },
    { name: 'Khác', value: 'Khác', label: 'Khác' },
].sort((a, b) => a.name.localeCompare(b.name))

export const Solutions = [
    { name: 'refund', value: 'refund', label: 'Refund' },
    { name: 'replace', value: 'replace', label: 'Replace' },
    { name: 'none', value: 'none', label: 'None' },
].sort((a, b) => a.name.localeCompare(b.name))

export const Status = [
    { name: 'Pending', value: 'pending', label: 'Pending' },
    { name: 'Approved', value: 'approved', label: 'Approved' },
    { name: 'Checking', value: 'checking', label: 'Checking' },
    { name: 'Confirmed', value: 'confirmed', label: 'Confirmed' },
    // { name: 'Done', value: 'done', label: 'Done' },
    // { name: 'Closed', value: 'closed', label: 'Closed' },
    { name: 'ConfirmationOverdue', value: 'confirmation_overdue', label: 'Confirmation overdue' },
]

export const Accepts = [
    { name: 'yes', value: 'yes', label: 'Yes' },
    { name: 'no', value: 'no', label: 'No' },
]

export const Notifications = [
    { name: 'confirm', value: 'confirm', label: 'Confirm' },
    { name: 'checking', value: 'checking', label: 'Checking' },
    { name: 'replace', value: 'replace', label: 'Replace' },
]

export const IssueResolutionStatus = [
    { name: 'Refunded', value: 'refunded', label: 'Refunded' },
    { name: 'Not yet refunded', value: 'not-yet-refunded', label: 'Not yet refunded' },
    { name: 'Replaced', value: 'replaced', label: 'Replaced' },
    { name: 'Not yet replaced', value: 'not-yet-replaced', label: 'Not yet replaced' },
]

export const partnerRefunds = [
    { name: 'Refunded', value: '1', label: 'Refunded' },
    { name: 'Not yet', value: '0', label: 'Not yet' },
]