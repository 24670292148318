import React, {Component} from 'react'
import PropTypes from 'prop-types'
import BrandingPage from "./BrandingPage"

class BrandingContainer extends Component {
    render() {
        const {orderId} = this.props

        return (
            <div className='BrandingContainer'>
                    <BrandingPage orderId={orderId}/>
            </div>
        )
    }
}

BrandingContainer.propTypes = {
    orderId: PropTypes.string.isRequired
}

export default BrandingContainer