import React, {Component} from 'react'
import ShippingZoneNewMethodsTable from "./table/ShippingZoneNewMethodsTable"
import ShippingZoneNewMethodsActions from "./actions/ShippingZoneNewMethodsActions"

class ShippingZoneNewMethods extends Component {
    render() {
        const {plans, _handleCreatePlan, _handleUpdatePlansState, _handleRemovePlan} = this.props

        return (
            <div className="ShippingZoneNewMethods">
                <div className="row">
                    <div className="col-md-4">
                        <label htmlFor="" className="font-weight-500">Shipping rate</label>
                    </div>

                    <div className="col-md-8">
                        <ShippingZoneNewMethodsTable shippingMethods={plans}
                                                     _handleUpdatePlansState={_handleUpdatePlansState}
                                                     _handleRemovePlan={_handleRemovePlan}/>
                        <ShippingZoneNewMethodsActions _handleCreatePlan={_handleCreatePlan}/>
                    </div>
                </div>
            </div>
        )
    }
}

export default ShippingZoneNewMethods
