import createApiServices from "./createApiServices"

const prefixURL = process.env.NODE_ENV === 'production' ? '/api/order' : 'https://fulfillment-staging.merchize.com/api/order'
const api = createApiServices({baseUrl: prefixURL})

export const searchWebhookJobs = (data = {}) => {
    return api.makeAuthRequest({
        url: '/webhook-jobs/search',
        method: 'POST',
        data: data
    })
}

export const searchWebhookJobsV2 = (data = {}) => {
    return api.makeAuthRequest({
        url: '/webhook-jobs/search/v2',
        method: 'POST',
        data: data
    })
}

export const runWebhookJobs = (jobId = '') => {
    return api.makeAuthRequest({
        url: `/webhook-jobs/${jobId}/run`,
        method: 'POST',
    })
}

export const cancelWebhookJobs = (jobId = '') => {
    return api.makeAuthRequest({
        url: `/webhook-jobs/${jobId}/cancel`,
        method: 'POST',
    })
}

