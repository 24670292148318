import React from 'react'
import PropTypes from 'prop-types'
import {Spin} from "antd"
import SwitchCarrierThead from "./SwitchCarrierThead"
import SwitchCarrierBody from "./SwitchCarrierBody"

SwitchCarrierTable.propTypes = {
    listRequest: PropTypes.array,
    loading: PropTypes.bool,
    error: PropTypes.string
}

function SwitchCarrierTable(props) {
    const {listRequest, loading, error} = props

    return (
        <div className="SwitchCarrierTable">
            <Spin spinning={loading} tip="Get data...">
                <table>
                    <SwitchCarrierThead/>
                    <SwitchCarrierBody listRequest={listRequest} error={error}/>
                </table>
            </Spin>
        </div>
    )
}

export default SwitchCarrierTable
