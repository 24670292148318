import React from 'react'
import PropTypes from 'prop-types'

VariantItem.propTypes = {
    item: PropTypes.object,
    productLineId: PropTypes.string,
    productLineTitle: PropTypes.string,
    listSelectedVariant: PropTypes.array,
    length: PropTypes.number,
    onUpdateSelectedVariants: PropTypes.func.isRequired
}

function VariantItem(props) {
    const {item} = props

    function _handleChange() {
        const {item, listSelectedVariant, length, productLineId, productLineTitle, onUpdateSelectedVariants} = props
        const listSelectVariant = listSelectedVariant.filter(item => item.product_id === productLineId)
        const isChecked = _detectChecked()

        if (typeof onUpdateSelectedVariants === 'function') {
            onUpdateSelectedVariants(item._id, item.title, productLineId, productLineTitle, !isChecked, (listSelectVariant.length + 1) === length)
        }
    }

    function _detectChecked() {
        const {item, listSelectedVariant} = props
        const checked = listSelectedVariant.filter(variant => variant.id === item._id)

        return checked.length > 0
    }

    return (
        <div key={item._id} className="VariantItem">
            <div className="Left">
                <div className="SelectVariant">
                    <input type="checkbox" checked={_detectChecked()} onChange={_handleChange}/>
                </div>
                <div className="TitleVariant">{item.title}</div>
            </div>

            <div className="SkuVariant">{item.sku}</div>
        </div>
    )
}

export default VariantItem
