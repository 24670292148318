import React, {Component} from 'react'
import PropTypes from 'prop-types'
import {getListShippingPlans} from '../../../../services/api/ShippingServices'
import {Select} from 'antd'

class ShippingPlan extends Component {
    state = {
        options: [],
        error: '',
    }

    componentDidMount() {
        this._fetchListShipping()
    }

    _handleChange = value => {
        this.props.onChangeFilterState({'shipping_plan': value})
    }

    _fetchListShipping = async () => {
        try {
            const params = {
                limit: 69,
            }

            const {data, success, message} = await getListShippingPlans(params)

            if (!success) {
                throw new Error(message)
            }

            this.setState({
                options: data,
            })

        } catch (e) {
            this.setState({
                error: e.message,
            })
        }
    }

    render() {
        const {options, error} = this.state
        const {value} = this.props

        return (
            <div className='ShippingPlan FilterOptions ml-sm-3'>
                <div className='mb-1 Heading'>Shipping plan:</div>
                <Select value={value} onChange={this._handleChange} style={{width: 230}}>
                    <Select.Option value=''>-- Choose a shipping plan --</Select.Option>
                    {
                        error ?
                            <Select.Option value={error}>{error}</Select.Option>
                            :
                            options.map(option => {
                                const {title, _id} = option
                                return <Select.Option key={_id} value={_id}>{title}</Select.Option>
                            })
                    }
                </Select>
            </div>
        )
    }
}

ShippingPlan.propTypes = {
    onChangeFilterState: PropTypes.func.isRequired,
}

export default ShippingPlan
