import React, { useState } from 'react'
import PropTypes from 'prop-types'
import MappingConditionTableHead from "./MappingConditionTable/MappingConditionTableHead"
import MappingConditionTableRow from "./MappingConditionTable/MappingConditionTableRow"


const MappingConditionTable = (props) => {

    const [selectedAll, setSelectedAll] = useState(false)
    const [listSelecteds, setListSelecteds] = useState([])
    const { listMappingCondition, handleEdit, rowIndex, reload, onChangeMapper, permissionsData } = props

    const _handleChangeSelectAll = (isActive = false) => {
        if (isActive) {
            const listSelectedId = listMappingCondition.map(item => item._id)
            setListSelecteds(listSelectedId)
            setSelectedAll(true)
        } else {
            setListSelecteds([])
            setSelectedAll(false)
        }
    }

    const _handleSelected = (id, isAdded) => {
        if (isAdded) {
            if (!listSelecteds.includes(id)) {
                setListSelecteds([
                    ...listSelecteds, id,
                ])
                setSelectedAll(listSelecteds.length === listMappingCondition.length)
            }
        } else {
            const filtered = listSelecteds.filter(item => item !== id)

            setListSelecteds(filtered)
            setSelectedAll(false)
        }
    }

    return (
        <div className="MappingConditionTable mb-4">
            <div className="wrapTable">
                <table className='table'>
                    <MappingConditionTableHead
                        handleChangeSelectAll={_handleChangeSelectAll}
                        selectedAll={selectedAll}
                        listSelecteds={listSelecteds}
                        reload={reload}
                        listMappingCondition={listMappingCondition}
                        onChangeMapper={onChangeMapper}
                        permissionsData={permissionsData}
                    />
                    <tbody>
                        {
                            listMappingCondition.map((item, i) => {
                                return <MappingConditionTableRow key={item._id} index={i} item={item}
                                    rowIndex={rowIndex + i + 1}
                                    handleEdit={handleEdit} listSelecteds={listSelecteds}
                                    permissionsData={permissionsData}
                                    handleSelected={_handleSelected} />
                            })
                        }
                    </tbody>
                </table>
            </div>
        </div>
    )
}

MappingConditionTable.propTypes = {
    listMappingCondition: PropTypes.array.isRequired,
}

export default MappingConditionTable