import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Tooltip } from 'antd'
import ProductVariantsHistory from './ProductVariantsHistory'

ProductVariantPrice.propTypes = {
    price: PropTypes.any,
    variantIndex: PropTypes.number,
    onChangeVariant: PropTypes.func.isRequired,
    id: PropTypes.string,
    storeId: PropTypes.string,
}

function ProductVariantPrice(props) {
    const {price, onChangeVariant, variantIndex, handlePaste, priceStore, store, id, storeId, group, disableAll} = props
    const [isModalOpen, setIsModalOpen] = useState(false)

    const _handleChangeInput = (e) => {
        const {value} = e.target
        const vValue = Number(value)

        if (isNaN(vValue)) {
            return
        }

        const newValue = store.map(item => {
            if(item._id === id || (group && item.store.group === group)){
                return {
                    ...item,
                    price: value
                }
            }
            return item
        })

        if (typeof onChangeVariant === 'function') {
            onChangeVariant(variantIndex, 'store', newValue)
        }
    }

    const _handlePasteInput = e => {
        handlePaste(e, 'store', variantIndex, storeId)
    }

    const toggleShowModal = () => {
        setIsModalOpen(!isModalOpen)
    }

    const storeOptions = store.length > 0 ? store.filter(i=>i.store.group === group).map(i=>({
        label: `${i.store.store}${i.store.name ? ` (${i.store.name})` : ''}`, value: i._id,
    })): [] 

    return (
        <td className="ProductVariantPrice">
            <div className='hasHistory'>
                <Tooltip title="Histories update price" className='mr-2'>
                    <i className='ti-info-alt cursor-pointer' onClick={toggleShowModal}/>
                </Tooltip>
                <input
                    type="text"
                    className="form-control"
                    value={priceStore !== undefined ? priceStore : (price || "")}
                    onChange={_handleChangeInput}
                    onPaste={_handlePasteInput}
                    disabled={disableAll}
                />
            </div>

            <ProductVariantsHistory objectType="ProductLineVariantPrice" objectId={id} isOpen={isModalOpen} toggleShowModal={toggleShowModal} group={group} storeOptions={storeOptions}/>
        </td>
    )
}

export default ProductVariantPrice
