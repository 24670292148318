import React, {Component} from 'react'
import PropTypes from 'prop-types'
import OrderButtonCopy from "./OrderButtonCopy"
import classNames from 'classnames'
import ShippingAddress from "./ShippingAddress"
import OrderRevalidateAddress from "./OrderRevalidateAddress"
import ShippingAddressCod from "./ShippingAddressCOD"
import RequestUpdateModal from "../request-update/RequestUpdateModal"
import { getLocalData } from '../../../../services/StorageServices'
import { parseJwt } from '../../../../helpers/parseToken'


class OrderBuyerDetails extends Component {
    render() {
        const {order, tags, language, languageError} = this.props
        const {address} = Object.assign({}, order)
        const {error_message = ''} = address

        const isChecked = address.hasOwnProperty('is_checked') && address.is_checked
        const isValid = address.hasOwnProperty('is_valid') && address.is_valid && !['BH', 'CY', 'EG', 'IR', 'IQ', 'IL', 'JO', 'KW', 'LB', 'OM', 'PS', 'QA', 'SA', 'SY', 'TR', 'AE', 'YE'].includes(address.country_code)

        const permissionsData = getLocalData('permissions') ? getLocalData('permissions') : {}

        return (
            <div className={classNames("OrderBuyerDetails", {isChecked, valid: isValid, invalid: !isValid})}>
                <div className="card">
                    <div className="card-header font-weight-bold d-flex justify-content-between">
                        <span>Buyer Details</span>
                        <div className="RightActions">
                            {
                                permissionsData.hasOwnProperty('ffm_request_update_address') && (!tags || !tags.includes('waiting-update-address')) && <RequestUpdateModal
                                    loading={false}
                                    title="Request update address"
                                    value="address"
                                    namespace={order.namespace}
                                    packageName={order.id}
                                    orderItemId={order._id}
                                    language={language}
                                    languageError={languageError}
                                />
                            }

                            {
                                permissionsData.hasOwnProperty('ffm_order_detail_update_buyer_detail') &&
                                <OrderRevalidateAddress {...this.props}/>
                            }
                            
                            <OrderButtonCopy order={order} address={address}/>
                        </div>
                    </div>

                    <div className="card-body">
                        {
                            (!order.hasOwnProperty('shipment_infos') || order.shipment_infos.length === 0) ?
                                <ShippingAddress address={address}/> :
                                <ShippingAddressCod address={address} shipmentInfo={order.shipment_infos[0]}/>
                        }

                        <div className="Error">
                            {!!isChecked &&
                            <div className="Text alert alert-danger">{error_message || ''}</div>
                            }
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

OrderBuyerDetails.propTypes = {
    order: PropTypes.object.isRequired,
    onChangeAddress: PropTypes.func.isRequired,
}

export default OrderBuyerDetails
