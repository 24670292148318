import React, {Component} from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'

import {createNewApiKey, getAllPlatformApiKeys, revokeApiKey} from '../../../services/api/PlatformApiKeysServices'
import CreateNewApiKeyModal from './api-keys/CreateNewApiKeyModal'
import ApiKeyResultModal from './api-keys/ApiKeyResultModal'

class PlatformApiKeys extends Component {
    state = {
        apiKeys: {
            entity: [],
        },
        loading: {
            fetchApiKeys: false,
            revokeApiKey: false,
        },
        err: {
            fetchApiKeys: null,
            revokeApiKey: null,
        },
        createNewApiKeyModal: false,
        apiKeyResultModal: false,
    }

    componentDidMount() {
        return this._fetchApiKeys()
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.platform._id !== this.props.platform._id) return this._fetchApiKeys()
    }

    _onClickRevoke = (keyId) => () => {
        return this._revokeApiKey(keyId)
    }

    _onToggleCreateNewApiModal = () => {
        this.setState({
            createNewApiKeyModal: !this.state.createNewApiKeyModal,
        })
    }

    _closeApiKeyResultModal = () => {
        this.setState({
            apiKeyResultModal: false,
        })
    }

    _createNewApiKey = async (apiKey) => {
        const {platform} = this.props
        if (platform.status !== 'active') {
            this.setState({
                createNewApiKeyModal: false,
            })
            return alert('You need to activate platform first')
        }

        try {
            const {success, data, message} = await createNewApiKey(platform._id, apiKey)
            if (success) return this.setState(({apiKeys}) => ({
                apiKeys: {
                    ...apiKeys,
                    entity: [data, ...apiKeys.entity]
                },
                createNewApiKeyModal: false,
                apiKeyResultModal: data.apiKey,
            }))

            alert(message)
        } catch (e) {
            alert(e.message || e)
        }
    }

    _changeLoading = (loadingState, errState) => {
        const {loading, err} = this.state
        return {
            loading: {
                ...loading,
                ...loadingState
            },
            err: (errState) ? err : {
                ...err,
                ...errState
            }
        }
    }

    _revokeApiKey = async (keyId) => {
        this.setState({
            ...this._changeLoading({revokeApiKey: true}, {revokeApiKey: null})
        })

        try {
            const {platform} = this.props
            const {success, data, message} = await revokeApiKey(platform._id, keyId)
            if (success && data) return this.setState(({apiKeys}) => ({
                ...this._changeLoading({revokeApiKey: true}),
                apiKeys: {
                    ...apiKeys,
                    entity: apiKeys.entity.map((key) => {
                        if (key._id === keyId) return {
                            ...key,
                            status: data,
                        }
                        return key
                    })
                }
            }))

            this.setState({
                ...this._changeLoading({revokeApiKey: false}, {revokeApiKey: message})
            })
        } catch (e) {
            this.setState({
                ...this._changeLoading({revokeApiKey: false}, {revokeApiKey: e.message || e})
            })
        }
    }

    _fetchApiKeys = async () => {
        this.setState({
            ...this._changeLoading({fetchApiKeys: true}, {fetchApiKeys: null})
        })

        try {
            const {platform} = this.props
            if (!platform._id) return

            const {success, data, message} = await getAllPlatformApiKeys(platform._id)
            if (success) return this.setState(({apiKeys}) => ({
                ...this._changeLoading({fetchApiKeys: false}),
                apiKeys: {
                    ...apiKeys,
                    entity: data,
                }
            }))
            this.setState({
                ...this._changeLoading({fetchApiKeys: false}, {fetchApiKeys: message})
            })
        } catch (e) {
            this.setState({
                ...this._changeLoading({fetchApiKeys: false}, {fetchApiKeys: e.message || e})
            })
        }
    }

    _renderKeyStatus = (status) => {
        return status.charAt(0).toUpperCase() + status.slice(1)
    }

    _parseDate = (date) => {
        return moment(date).format('MMMM Do YYYY')
    }

    render() {
        const {apiKeys, createNewApiKeyModal, apiKeyResultModal} = this.state
        const {disableAll} = this.props

        return (
            <div className="PlatformApiKeys">
                <CreateNewApiKeyModal
                    onClose={this._onToggleCreateNewApiModal}
                    onOk={this._createNewApiKey}
                    open={createNewApiKeyModal}/>
                <ApiKeyResultModal
                    open={!!apiKeyResultModal}
                    apiKey={apiKeyResultModal || ''}
                    onClose={this._closeApiKeyResultModal}/>
                <div className="Title">
                    <label>API Keys</label>
                    {!disableAll &&
                        <button
                            className="btn Button"
                            onClick={this._onToggleCreateNewApiModal}>
                            Add new API key
                        </button>
                    }
                </div>

                <div className="table-responsive ApiKeysTable">
                    <table className="table table-striped">
                        <thead className="thead-light">
                        <tr>
                            <th>Name</th>
                            <th>Prefix</th>
                            <th>Status</th>
                            {!disableAll &&
                                <th>Action</th>
                            }
                            <th>Created date</th>
                        </tr>
                        </thead>
                        <tbody>
                        {apiKeys.entity.map((api) => <tr key={api._id}>
                            <td>{api.name}</td>
                            <td>{api.prefix}</td>
                            <td>{this._renderKeyStatus(api.status)}</td>
                            {!disableAll &&
                                <td>{!!api.status && api.status !== 'revoked' && <button
                                    className="btn Button"
                                    onClick={this._onClickRevoke(api._id)}>
                                    Revoke
                                </button>}</td>
                            }
                            <td>{this._parseDate(api.created)}</td>
                        </tr>)}
                        </tbody>
                    </table>
                </div>
            </div>
        )
    }
}

PlatformApiKeys.propTypes = {
    platform: PropTypes.object.isRequired,
}

export default PlatformApiKeys
