import createApiServices from "./createApiServices";

const factoryUrl = process.env.NODE_ENV === 'production' ? 'https://factory.merchize.com' : 'https://f1-staging.merchize.com'
const api = createApiServices({ baseUrl: factoryUrl})

export const getListBrand = (payload) => {
    return api.makeAuthRequest({
        url: `/api/order/get-list-brand`,
        method: 'POST',
        data: payload,
    })
}