import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Button, Modal, Tooltip } from "antd"
import { ObjectInspector } from "react-inspector"

class ModalInformation extends Component {
    state = {
        visible: false,
    }

    showModal = () => {
        this.setState({
            visible: true,
        })
    }

    handleCancel = () => {
        this.setState({ visible: false })
    }

    render() {
        const { visible } = this.state
        const { webhook } = this.props

        return (
            <>
                <Tooltip title='Information'>
                    <span className="ti-info-alt" onClick={this.showModal} />
                </Tooltip>
                <Modal
                    visible={visible}
                    title="Information"
                    onCancel={this.handleCancel}
                    footer={[
                        <Button key="back" onClick={this.handleCancel}>
                            Close
                        </Button>
                    ]}
                >
                    <ObjectInspector depth={0} path="root.value" data={webhook} />
                </Modal>
            </>
        )
    }
}

ModalInformation.propTypes = {
    webhook: PropTypes.any
}

export default ModalInformation
