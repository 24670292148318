import React, { useState, useEffect } from 'react';
import { Button, PopoverBody, UncontrolledPopover } from 'reactstrap';
import { Tooltip, Popover, message } from 'antd';
import classNames from 'classnames';

function ListItemsNote(props) {
    const { id, title, itemIndex, onChangeInput } = props;
    const [note, setNote] = useState('')
    const [currentNote, setCurrentNote] = useState('')
    const [isLoadingNote, setIsLoadingNote] = useState(false)

    useEffect(() => {
        loadStateFromProps()
    }, [])

    const loadStateFromProps = () => {
        const { note: newNote } = props;
        setNote(newNote)
        setCurrentNote(newNote || '')
    };

    const _handleChangeInput = (e) => {
        const { target: { name, value } } = e;
        if (name === 'note') setNote(value)
    };

    const _renderIconNote = () => {
        if (isLoadingNote)
            return <i className='icon-spinner2 spinner ml-2' />;
        return <i className='icon-quill4 ml-2' />;
    };

    const _handleSaveNote = async () => {
        setIsLoadingNote(true)
        onChangeInput('solution_note', note ? note.trim() : '', itemIndex)
        setCurrentNote(note ? note.trim() : '')
        setNote(note ? note.trim() : '')
        message.success(`${title} updated!`)
        setIsLoadingNote(false)
    };

    const classNameIcon = classNames({
        'icon-quill4': true,
        'cursor-pointer': true,
        'text-danger': !!note,
        'text-success': !note
    });

    return (
        <div className='ListItemsNote'>
            <Tooltip title={title}>
                <i className={classNameIcon} id={id} />
            </Tooltip>
            <UncontrolledPopover placement='bottom' trigger='legacy' target={id}>
                <PopoverBody>
                    <textarea className='form-control' name='note' value={note} onChange={_handleChangeInput} />
                    <div className='text-right mt-2'>
                        <Button color='primary' onClick={_handleSaveNote} disabled={note === currentNote}>
                            Save Note
                            {_renderIconNote()}
                        </Button>
                    </div>
                </PopoverBody>
            </UncontrolledPopover>
        </div>
    );
}

export default ListItemsNote;