import React from 'react'
import PropTypes from 'prop-types'

ShippingStandard.propTypes = {
    title: PropTypes.string,
    fulfillmentPlan: PropTypes.string,
    subtitle: PropTypes.string,
    cost: PropTypes.number
}

function ShippingStandard(props) {
    const {title, fulfillmentPlan, subtitle, cost} = props

    return (
        <div className="ShippingInner">
            <div className="Title">Shipping plan</div>
            <div className="Content">
                {
                    !!title &&
                    <div className="Row">
                        <div className="Label">Title:</div>
                        <div className="Value">{title}</div>
                    </div>
                }

                {
                    !!fulfillmentPlan &&
                    <div className="Row">
                        <div className="Label">Slug:</div>
                        <div className="Value">{fulfillmentPlan}</div>
                    </div>
                }

                {
                    !!subtitle &&
                    <div className="Row">
                        <div className="Label">Subtitle:</div>
                        <div className="Value">{subtitle}</div>
                    </div>
                }

                {
                    !!cost &&
                    <div className="Row">
                        <div className="Label">Cost:</div>
                        <div className="Value">${cost}</div>
                    </div>
                }
            </div>
        </div>
    )
}

export default ShippingStandard