import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { Button, Input, Select, message, Spin, Modal, Tooltip } from 'antd'
import getHistory from '../../../store/getHistory'
import { searchProducts } from "../../../services/api/ProductsServices"
import {
	getPackageSplittingRuleDetails,
	createPackageSplittingRule,
	updatePackageSplittingRule,
} from '../../../services/api/PackageSplittingRulesServices'
import { getListSuppliersV2 } from '../../../services/api/SuppliersServices'
import { getLocalData } from '../../../services/StorageServices'
import { searchOrderHistories } from '../../../services/api/OrdersServices'
import moment from 'moment'
import humanizeTime from '../../../helpers/time/humanizeTime'
import ObjectInspector from "react-object-inspector"

function PackageSplittingRuleCreatePage(props) {
	const { id } = { ...props }
	const { Option } = Select
	const { TextArea } = Input
	const permissionsData = !!getLocalData('permissions') ? Object.keys(getLocalData('permissions')) : []
	const [loading, setLoading] = useState(false)
	const [loadingSupplier, setLoadingSupplier] = useState(false)
	const [loadingProduct, setLoadingProduct] = useState(false)
	const [priority, setPriority] = useState(null)
	const [supplier, setSupplier] = useState('')
	const [productType, setProductType] = useState([])
	const [shippingZone, setShippingZone] = useState([])
	const [splitType, setSplitType] = useState('')
	const [maxValue, setMaxValue] = useState(null)
	const [note, setNote] = useState('')
	const [searchShippingZone, setSearchShippingZone] = useState('')
	const [searchProductType, setSearchProductType] = useState('')
	const [listSupplier, setListSupplier] = useState([])
	const [listProducts, setListProducts] = useState([])
	const [curentListProducts, setCurentListProducts] = useState([])
	const [error, setError] = useState('')
	const [errorValidate, setErrorValidate] = useState({})
	const [showHisory, setShowHistory] = useState(false)
	const [history, setHistory] = useState([])

	const getRuleDetails = async (id) => {
		setLoading(true)
		setError('')
		try {
			const { success, data, message: mess } = await getPackageSplittingRuleDetails(id)
			if (!success) {
				throw new Error(mess)
			}
			setPriority(data.priority)
			setSupplier(data.supplier[0])
			setProductType((data.product_type || []))
			setShippingZone((data.shipping_zone || []))
			setSplitType(data.split_type)
			setMaxValue(data.max_value)
			setNote(data.note)
			await getListProduct(data.supplier[0])
		} catch (error) {
			if (error.response.status !== 200) {
				const { data = {} } = { ...error.response }
				message.error(data.message || 'Unknown error')
				setError(data.message || 'Unknown error')
			} else {
				message.error(error.message || 'Unknown error')
				setError(error.message || 'Unknown error')
			}
		} finally {
			setLoading(false)
		}
	}

	const getListSupplier = async () => {
		setLoadingSupplier(true)
		try {
			const { data, success, message: mess } = await getListSuppliersV2()
			if (!success) {
				throw new Error(mess)
			}
			setListSupplier(data.suppliers || [])
		} catch (error) {
			if (error.response.status !== 200) {
				const { data = {} } = { ...error.response }
				message.error(data.message || 'Unknown error')
			} else {
				message.error(error.message || 'Unknown error')
			}
		} finally {
			setLoadingSupplier(false)
		}
	}

	const getListProduct = async (supplierValue) => {
		// setLoadingSupplier(true)
		setLoadingProduct(true)
		const payload = {
			limit: 10000,
			supplier: supplierValue,
		}
		try {
			const { data, success, message: mess } = await searchProducts(payload)
			if (!success) {
				throw new Error(mess)
			}
			const { products = [] } = { ...data }
			const newListProducts = products.length > 0 ?
				[...new Set(products.map(option => ({
					label: option.type,
					value: option.type,
					suppliers: option.suppliers && option.suppliers.length > 0 ? option.suppliers.map(i => i._id) : []
				})).filter(i => !!i && i.label))]
				: []

			const labelMap = {}

			newListProducts.forEach(item => {
				const { label, suppliers } = item;
				if (labelMap[label]) {
					labelMap[label].suppliers = Array.from(new Set([...labelMap[label].suppliers, ...suppliers]));
				} else {
					labelMap[label] = { ...item };
				}
			})
			const uniqueArray = Object.values(labelMap)
			setListProducts(uniqueArray)
			setCurentListProducts(uniqueArray)
		} catch (error) {
			if (error.response.status !== 200) {
				const { data = {} } = { ...error.response }
				message.error(data.message || 'Unknown error')
			} else {
				message.error(error.message || 'Unknown error')
			}
		} finally {
			// setLoadingSupplier(false)
			setLoadingProduct(false)
		}
	}

	const handleChangePriority = (e) => {
		delete errorValidate.priority
		const value = parseInt(e.target.value)
		if (value || value === 0) {
			if (typeof value !== "number") {
				setErrorValidate({ ...errorValidate, priority: 'Priority must be a number' })
				setPriority(null)
			}
			if (value <= 0) {
				setErrorValidate({ ...errorValidate, priority: 'Minimum value is 1' })
				setPriority(null)
			} else {
				setPriority(value)
			}
		} else {
			setPriority(null)
		}
	}

	const handleChangeMaxValue = (e) => {
		delete errorValidate.maxValue
		const value = parseInt(e.target.value)
		if (value || value === 0) {
			if (typeof value !== "number") {
				setErrorValidate({ ...errorValidate, maxValue: 'Max value must be a number' })
				setMaxValue(null)
			}
			if (value <= 0) {
				setErrorValidate({ ...errorValidate, maxValue: 'Minimum value is 1' })
				setMaxValue(null)
			} else {
				setMaxValue(value)
			}
		} else {
			setMaxValue(null)
		}
	}

	const handleChangeTag = (field, value) => {
		if (field === 'ProductType') {
			delete errorValidate.productType
			setSearchProductType('')
			setProductType(value)
		}
		if (field === 'ShippingZone') {
			setSearchShippingZone('')
			setShippingZone(value)
		}
		if (field === 'SplitType') {
			delete errorValidate.splitType
			setSplitType(value)
		}
	}

	const handleSearch = (field, value) => {
		if (value && (value.includes(',') || value.includes(' '))) {
			if (field === 'ProductType') {
				if (value.includes(',')) {
					const newValue = [...new Set([...value.split(',').map(i => i.trim()).filter(i => i), ...productType])]
					setProductType(newValue)
					setSearchProductType('')
				} else {
					return setSearchProductType(value)
				}
			}
			if (field === 'ShippingZone') {
				const newValue = [...new Set([...value.replace(/ /g, ',').split(',').map(i => i.trim()).filter(i => i), ...shippingZone])]
				setShippingZone(newValue)
				setSearchShippingZone('')
			}
		} else {
			if (field === 'ProductType') {
				return setSearchProductType(value)
			}
			if (field === 'ShippingZone') {
				return setSearchShippingZone(value)
			}
		}
	}

	const handleChangeSupplier = async(value) => {
		delete errorValidate.supplier
		setSupplier(value)
		if (value) {
			await getListProduct(value)
			// const newListProduct = curentListProducts.length > 0 ? curentListProducts.filter(i => i.suppliers.includes(value)) : []
			// setListProducts(newListProduct)
		}
		setProductType([])
		setSearchProductType('')
	}

	const handleChangeNote = (e) => {
		setNote(e.target.value)
	}

	const validateData = () => {
		setErrorValidate({})
		const errValidate = {}
		if (!priority) errValidate.priority = 'Priority is require'
		if (!supplier) errValidate.supplier = 'Supplier is require'
		if (!productType || productType.length === 0) errValidate.productType = 'Product Type is require'
		if (!splitType) errValidate.splitType = 'Split Type is require'
		if (!maxValue) errValidate.maxValue = 'Max value is require'

		if (Object.keys(errValidate).length) {
			setErrorValidate(errValidate)
			return false
		} else {
			return true
		}
	}

	const onCreateRule = async (payload = {}) => {
		setLoading(true)
		setError('')
		try {
			const { success, data, message: mess } = await createPackageSplittingRule(payload)
			if (!success) {
				throw new Error(mess)
			}
			message.success('Package Splitting Rule created!')
			const history = getHistory()
			const location = {
				pathname: `/a/package-splitting-rules/${data._id}`,
			}

			history.push(location)
		} catch (error) {
			if (error.response.status !== 200) {
				const { data = {} } = { ...error.response }
				message.error(data.message || 'Unknown error')
				setError(data.message || 'Unknown error')
			} else {
				message.error(error.message || 'Unknown error')
				setError(error.message || 'Unknown error')
			}
		} finally {
			setLoading(false)
		}
	}

	const onUpdateRule = async (id, payload = {}) => {
		setLoading(true)
		setError('')
		try {
			const { success, data, message: mess } = await updatePackageSplittingRule(id, payload)
			if (!success) {
				throw new Error(mess)
			}
			message.success('Package Splitting Rule updated!')
		} catch (error) {
			if (error.response.status !== 200) {
				const { data = {} } = { ...error.response }
				message.error(data.message || 'Unknown error')
				setError(data.message || 'Unknown error')
			} else {
				message.error(error.message || 'Unknown error')
				setError(error.message || 'Unknown error')
			}
		} finally {
			setLoading(false)
		}
	}

	const handleSave = async () => {
		const payload = {
			priority: priority,
			supplier: supplier,
			product_type: productType,
			shipping_zone: shippingZone,
			split_type: splitType,
			max_value: maxValue,
			note: note,
		}

		if (!validateData()) return
		if (!id || id === 'create') onCreateRule(payload)
		if (!!id && id !== 'create') onUpdateRule(id, payload)
	}

	const _handleHistoryClick = async (object_id) => {
		setHistory([])
		try {
			const payload = {
				object_type: "SplitPackageRule",
				object_id: object_id,
				page: 1,
				limit: 10000
			}
			const { data, success, message } = await searchOrderHistories(payload)
			if (!success) throw new Error(message)
			setShowHistory(true)
			setHistory(data.histories || [])
		} catch (error) {
			if (error.response.status !== 200) {
				const { data = {} } = { ...error.response }
				message.error(data.message || 'Unknown error')
			} else {
				message.error(error.message || 'Unknown error')
			}
		}
	}

	const _onCloseHistory = () => {
		setShowHistory(false)
		setHistory([])
	}

	const fetchData = async () => {
		if (!!id && id !== 'create') {
			setLoading(true)
			getListSupplier()
			await getRuleDetails(id)
			// await getListProduct()
			setLoading(false)
		} else {
			await getListSupplier()
			// await getListProduct()
		}
	}

	useEffect(() => {
		fetchData()
	}, [id])

	useEffect(() => {
		if (supplier) {
			const newListProduct = curentListProducts.length > 0 ? curentListProducts.filter(i => i.suppliers.includes(supplier)) : []
			setListProducts(newListProduct)
		} else {
			setListProducts(curentListProducts)
		}
	}, [supplier])

	const supplierOption = listSupplier.length > 0 && listSupplier.sort((a, b) => a.name.localeCompare(b.name)).map(item =>
		<Option key={item._id} value={item._id}>{item.name}</Option>
	)
	const productTypeOption = listProducts.length > 0 && listProducts.sort((a, b) => a.label.localeCompare(b.label)).map(option => (
		<Option key={option.value} value={option.label}>{option.label}</Option>
	))

	const ShippingZones = [
		{ name: 'US', value: 'US', label: 'US' },
		{ name: 'EU', value: 'EU', label: 'EU' },
		{ name: 'ROW', value: 'ROW', label: 'ROW' },
	].sort((a, b) => a.name.localeCompare(b.name))

	const SplitTypes = [
		{ name: 'quantity', value: 'quantity', label: 'Quantity' },
		{ name: 'weight', value: 'weight', label: 'Weight' },
	].sort((a, b) => a.name.localeCompare(b.name))

	const canCreate = permissionsData.includes('ffm_fast_production_rule_create')
	const canEdit = permissionsData.includes('ffm_fast_production_rule_update')

	return (
		<div className='PackageSplittingRuleCreatePage'>
			<div className="container-fluid">
				<div className="BackWrapper">
					<Link className="nav-link BackButton" to="/a/package-splitting-rules">
						<i className="fas fa-chevron-left" />
						<span>Package Splitting Rules</span>
					</Link>
				</div>
				<div className="MenuTitle d-flex justify-content-between align-items-center">
					{!id || id === 'create' ? 'Create Splitting Rule' : 'Update Splitting Rule'}
					<div className="Actions d-flex align-items-center justify-content-end">
						{!!id && id !== 'create' &&
							<Tooltip title="History">
								<i className="fa fa-history History cursor-pointer ml-3" aria-hidden="true" onClick={() => _handleHistoryClick(id)}></i>
							</Tooltip>
						}
						<Link className="ant-btn ant-btn-default px-4 rounded ml-3 btn-secondary" to='/a/package-splitting-rules' >Cancel</Link>
						{(canCreate || canEdit) &&
							<Button
								className='ant-btn ant-btn-primary px-4 rounded ml-3'
								disabled={loading || loadingSupplier || loadingProduct}
								onClick={handleSave}
							>
								Save
							</Button>
						}
					</div>
				</div>
				<div className="SectionInner mt-3">
					<div className="p-3" style={{ maxWidth: '1000px' }}>
						<Spin spinning={loading} tip='Loading...'>
							<div className="form">
								<div className="form-item Priority mb-3">
									<label className='font-weight-bold'>Priority <span className="text-danger">*</span></label>
									<Input
										placeholder='Input Priority'
										className='w-100'
										value={!priority ? null : priority}
										onChange={handleChangePriority}
										type='number'
										allowClear
										min={1}
										disabled={!(canCreate || canEdit)}
									/>
									{errorValidate.priority &&
										<small className='text-danger'>{errorValidate.priority}</small>
									}
								</div>
								<div className="form-item mb-3">
									<label className='font-weight-bold'>Supplier <span className="text-danger">*</span></label>
									<Spin spinning={loadingSupplier}>
										<Select
											placeholder='Choose Supplier'
											value={supplier || []}
											className='w-100'
											allowClear
											filterOption={(input, option) =>
												option.children.toLocaleLowerCase().includes((input || "").toLocaleLowerCase())
											}
											showSearch
											onChange={handleChangeSupplier}
											disabled={!(canCreate || canEdit) || loadingSupplier || loadingProduct || loading}
										>
											{supplierOption}
										</Select>
										{errorValidate.supplier &&
											<small className='text-danger'>{errorValidate.supplier}</small>
										}
									</Spin>
								</div>
								<div className="form-item mb-3">
									<label className='font-weight-bold'>Product Type <span className="text-danger">*</span></label>
									<Spin spinning={loadingProduct}>
										<Select
											placeholder='Choose Product Type'
											value={!id ? (productType || []) : (productType || ['All'])}
											searchValue={searchProductType}
											mode='multiple'
											className='w-100'
											allowClear
											showSearch
											onChange={(e) => handleChangeTag('ProductType', e)}
											onSearch={(e) => handleSearch('ProductType', e)}
											disabled={!(canCreate || canEdit) || loadingProduct || loading || !supplier}
											showArrow
										>
											{productTypeOption}
										</Select>
										{errorValidate.productType &&
											<small className='text-danger'>{errorValidate.productType}</small>
										}
									</Spin>
								</div>
								<div className="form-item mb-3">
									<label className='font-weight-bold'>Shipping Zone</label>
									<Select
										placeholder='Choose Shipping Zone'
										value={shippingZone || []}
										searchValue={searchShippingZone}
										mode='multiple'
										className='w-100'
										allowClear
										options={ShippingZones}
										optionFilterProp="children"
										onChange={(e) => handleChangeTag('ShippingZone', e)}
										onSearch={(e) => handleSearch('ShippingZone', e)}
										disabled={!(canCreate || canEdit)}
										filterOption={(inputValue, option) =>
											!!option && option.label.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
										}
										showArrow
										showSearch
									/>
								</div>

								<div className="form-item mb-3">
									<label className='font-weight-bold'>Split Type <span className="text-danger">*</span></label>
									<Select
										placeholder='Choose Split Type'
										value={splitType || []}
										className='w-100'
										allowClear
										optionFilterProp="children"
										showSearch
										options={SplitTypes}
										onChange={(e) => handleChangeTag('SplitType', e)}
										onSearch={(e) => handleSearch('Country', e)}
										disabled={!(canCreate || canEdit)}
										filterOption={(inputValue, option) =>
											!!option && option.label.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
										}
									/>
									{errorValidate.splitType &&
										<small className='text-danger'>{errorValidate.splitType}</small>
									}
								</div>
								<div className="form-item Priority mb-3">
									<label className='font-weight-bold'>Max Value <span className="text-danger">*</span></label>
									<Input
										placeholder='Input Max Value'
										className='w-100'
										value={!maxValue ? null : maxValue}
										onChange={handleChangeMaxValue}
										type='number'
										allowClear
										min={1}
										disabled={!(canCreate || canEdit)}
									/>
									{errorValidate.maxValue &&
										<small className='text-danger'>{errorValidate.maxValue}</small>
									}
								</div>
								<div className="form-item Priority mb-3">
									<label className='font-weight-bold'>Note</label>
									<TextArea
										placeholder='Note'
										className='w-100'
										value={note || ''}
										onChange={handleChangeNote}
										allowClear
										disabled={!(canCreate || canEdit)}
										rows={6}
									/>
								</div>
							</div>
						</Spin>
						{error && <label className='text-danger mb-0'>{error}</label>}
						<Modal
							visible={showHisory}
							width={800}
							title='Splitting Rule History'
							onCancel={_onCloseHistory}
							className='shippingFeeHistoryModal'
							footer={[
								error && <p key='error' className="text-danger">{error}</p>,
								<Button className="cancel" key="cancel" onClick={_onCloseHistory} disabled={loading}>Đóng</Button>,
							]}
						>
							{!!history && history.length > 0 ? (<ul>
								{history.map(h => {
									const {
										_id: historyId,
										event,
										created_at: createdAt,
										description,
										meta, user,
									} = h
									const hdata = { ...meta, user, date_time: moment(createdAt).format('DD/MM/YYYY hh:mm:ss') }

									return <li className="card" key={historyId}>
										<div className="card-header">
											<time title={createdAt}>{humanizeTime(createdAt)}</time>
										</div>
										<div className="card-body">
											<div className="row align-items-baseline mb-3">
												<div className="col-4"><code>{event}</code>
												</div>
												<div className="col-8 text-muted">{description}</div>
											</div>
											<div className="row align-items-baseline">
												<div className="col-4">Value</div>
												<div className="col-8">
													<ObjectInspector depth={0} patxh="root.value" data={hdata} />
												</div>
											</div>
										</div>
									</li>
								})
								}
							</ul>) : (<h6 className='text-danger'>No history data has been saved!!!</h6>)
							}
						</Modal>
					</div>
				</div>
			</div>
		</div>
	)
}

export default PackageSplittingRuleCreatePage