import React from 'react'
import PropTypes from 'prop-types'
import RuleItem from "./RuleItem"

RuleBody.propTypes = {
    error: PropTypes.string,
    listRules: PropTypes.array,
    listSelected: PropTypes.array,
    onChangeSelectedRule: PropTypes.func.isRequired
}

function RuleBody(props) {
    const {error, listRules, listSelected, onChangeSelectedRule} = props

    return (
        <tbody className='RuleBody'>
        {
            !!listRules && listRules.length > 0 && listRules.map((item, index) => {
                return <RuleItem
                    key={item._id}
                    item={item}
                    index={index}
                    listSelected={listSelected}
                    onChangeSelectedRule={onChangeSelectedRule}
                />
            })
        }
        {
            !!error && error.length > 0 && <tr>
                <td colSpan={9} className="text-danger">{error}</td>
            </tr>
        }
        </tbody>
    )
}

export default RuleBody
