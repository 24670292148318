import React, {Component} from 'react'
import SettingStorage from './SettingStorage'

class SettingStorageContainer extends Component {
    render() {

        return (
            <div className="SettingStorageContainer">
                <SettingStorage {...this.props}/>
            </div>
        )
    }
}

export default SettingStorageContainer
