import React, {Component} from 'react'
import DocTitleByStore from "../../dashboard/shared/DocTitleByStore"
import ShippingZoneNewPage from "./ShippingZoneNewPage"

class ShippingZoneNewPageContainer extends Component {
    render() {
        return (
            <div className="ShippingZoneNewPageContainer">
                <DocTitleByStore title="Add shipping zone"/>

                <div className="col-10 m-auto">
                    <h1 className="PageTitle">Add shipping zone</h1>
                    <ShippingZoneNewPage/>
                </div>
            </div>
        )
    }
}

export default ShippingZoneNewPageContainer
