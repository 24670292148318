import React from 'react'

function PackageSplittingRulesHeader(props) {
    const { permissionsData = [] } = { ...props }
    const canEdit = permissionsData.includes('ffm_update_split_package_rule')
    const canDelete = permissionsData.includes('ffm_delete_split_package_rule')
    const canViewDetails = permissionsData.includes('ffm_view_split_package_rule')
    return (
        <thead className='PackageSplittingRulesHeader thead-light'>
            <tr>
                <th>Priority</th>
                <th>Supplier</th>
                <th style={{minWidth:'127px'}}>Product Type</th>
                <th style={{minWidth:'136px'}}>Shipping Zone</th>
                <th style={{minWidth:'101px'}}>Split Type</th>
                <th style={{minWidth:'104px'}}>Max Value</th>
                <th style={{minWidth:'186px'}}>Update at - Create at</th>
                <th style={{minWidth:'129px'}} >Note</th>
                <th>Status</th>
                {
                    (canEdit || canViewDetails || canDelete) &&
                    <th style={{minWidth:'92px'}}>Action</th>
                }
            </tr>
        </thead>
    )
}

export default PackageSplittingRulesHeader