import React from 'react'

const NoOrders = (props) => {
    const { loading } = props

    if (loading) return null

    return (
        <tr className='NoOrders'>
            <td colSpan={14}><i>No items</i></td>
        </tr>
    )
}

export default NoOrders