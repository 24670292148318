import React, {Component} from 'react'

class OrderItemHead extends Component {
    render() {
        return (
            <thead className="OrderItemHead bg-light">
                <tr>
                    <th>Product</th>
                    <th className="table-col-40">Map</th>
                    <th className="table-col-20">Design</th>
                </tr>
            </thead>
        )
    }
}

export default OrderItemHead
