import createApiServices from './createApiServices'

const prefixURL = process.env.NODE_ENV === 'production' ? '/api/order' : 'https://fulfillment-staging.merchize.com/api/order'
const api = createApiServices({ baseUrl: prefixURL })

export const getListFastProductionRules = (data = {}) => {
    return api.makeAuthRequest({
        url: `/fast-production-rules/search`,
        method: 'POST',
        data,
    })
}

export const updateFastProductionRulesStatus = (id, status) => {
    return api.makeAuthRequest({
        url: `/fast-production-rules/${id}/status/${status}`,
        method: 'PUT',
    })
}

export const deleteFastProductionRule = (id) => {
    return api.makeAuthRequest({
        url: `/fast-production-rules/${id}`,
        method: 'DELETE',
    })
}

export const getFastProductionRuleDetails = (id) => {
    return api.makeAuthRequest({
        url: `/fast-production-rules/${id}`,
        method: 'GET',
    })
}

export const createFastProductionRule = (data) => {
    return api.makeAuthRequest({
        url: `/fast-production-rules`,
        method: 'POST',
        data: data,
    })
}

export const updateFastProductionRule = (id, data) => {
    return api.makeAuthRequest({
        url: `/fast-production-rules/${id}`,
        method: 'PUT',
        data: data,
    })
}