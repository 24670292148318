import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { Button, Select, message, Spin } from 'antd'
import { getLocalData } from '../../../services/StorageServices'
import { getListSuppliersV2 } from '../../../services/api/SuppliersServices'
import { getListShippingPlans } from '../../../services/api/ShippingServices'
import { updateSpecialCase, getDetailSpecialCase } from '../../../services/api/ProductsServices'

const SpecialCaseEditPage = ({ id }) => {
    const [loading, setLoading] = useState(false)
    const [listSuppliers, setListSuppliers] = useState([])
    const [listShippingPlans, setListShippingPlans] = useState([])
    const [supplier, setSupplier] = useState("")
    const [store, setStore] = useState([])
    const [countryAllow, setCountryAllow] = useState([])
    const [countryDisallow, setCountryDisallow] = useState([])
    const [tagAllow, setTagAllow] = useState([])
    const [tagDisallow, setTagDisallow] = useState([])
    const [title, setTitle] = useState([])
    const [productSku, setProductSku] = useState([])
    const [shippingPlan, setShippingPlan] = useState("all")
    const [error, setError] = useState('')
    const [errorValidate, setErrorValidate] = useState({})
    const [status, setStatus] = useState(0)

    const permissionsData = !!getLocalData('permissions') ? Object.keys(getLocalData('permissions')) : []
    const canEdit = permissionsData.includes('ffm_mapping_special_case_update')

    const handleSave = async () => {
        if (!validateForm()) return

        setLoading(true)
        setError('')
        setErrorValidate({})

        try {
            const payload = {
                store: store,
                country: {
                    allow: countryAllow,
                    disallow: countryDisallow
                },
                tags: {
                    allow: tagAllow,
                    disallow: tagDisallow
                },
                product_title: title,
                shipping_plan: shippingPlan === "all" ? "" : shippingPlan,
                supplier,
                product_catalog_sku: productSku,
                status
            }

            const { data, success, message: mess } = await updateSpecialCase(payload, id)
            if (!success) {
                throw new Error(mess)
            }
            message.success('Mapping  Special Case updated successfully!')

        } catch (error) {
            message.error(error.message || 'Unknown error')
            setError(error.message || 'Unknown error')
        } finally {
            setLoading(false)
        }
    }

    const validateForm = () => {
        setErrorValidate({})
        const errValidate = {}
        if (!supplier) errValidate.supplier = 'Supplier is require'
        if (!shippingPlan) errValidate.shippingPlan = 'Shipping Plan is require'
        if (countryAllow.some(i => i.length !== 2)) errValidate.countryAllow = 'The accept country code value consists of 2 characters'
        if (countryDisallow.some(i => i.length !== 2)) errValidate.countryDisallow = 'The except country code value consists of 2 characters'

        if (Object.keys(errValidate).length) {
            setErrorValidate(errValidate)
            return false
        } else {
            return true
        }
    }

    const handleChangeMultiple = (field, value, stateField, setValues) => {
        delete errorValidate[field]
        let valueSplit = []
        const _ = value.map(o => {
            if (o.replace(/ /g, ',').includes(',')) {
                return valueSplit = [...new Set([...stateField, ...o.replace(/ /g, ',').split(',').map(i => i.trim()).filter(i => i)])]
            }

            return valueSplit.push(o)
        })

        field === 'store' ? setValues(value.some(i => i.toLowerCase() === 'all') ? ["All"] : valueSplit) : setValues(valueSplit)
    }

    const handleChangeSelect = (value, setValue, field) => {
        delete errorValidate[field]
        setValue(value)
    }

    const fetchDetailSpecialCase = async () => {
        setLoading(true)
        try {
            const { data, success, message: mess } = await getDetailSpecialCase(id)
            if (!success) return setError(mess)

            const { country = {}, product_catalog_sku = [], product_title = {}, shipping_plan = {}, store = {}, supplier, tags = {}, status } = { ...data }
            const new_shipping_plan = (shipping_plan.allow && shipping_plan.allow[0] && Object.keys(shipping_plan.allow[0]).length > 0) ? shipping_plan.allow[0]._id : "all"
            setCountryAllow(country.allow)
            setCountryDisallow(country.disallow)
            setProductSku(product_catalog_sku)
            setTitle(product_title.allow)
            setStore(store.allow)
            setTagAllow(tags.allow)
            setTagDisallow(tags.disallow)
            setSupplier(!!supplier[0] ? supplier[0]._id : '')
            setShippingPlan(new_shipping_plan)
            setStatus(status)

        } catch (error) {
            message.error(error.message || 'Unknown error')
        } finally {
            setLoading(false)
        }
    }

    const getListShippingPlan = async () => {
        try {
            const { data, success, message: mess } = await getListShippingPlans()
            if (!success) {
                throw new Error(mess)
            }
            setListShippingPlans(data)
        } catch (error) {
            message.error(error.message || 'Unknown error')
        }
    }

    const getListSupplier = async () => {
        try {
            const { data, success, message: mess } = await getListSuppliersV2()
            if (!success) {
                throw new Error(mess)
            }

            const listOptions = data.suppliers.filter(i => i.is_active).map(item => ({
                label: item.name,
                value: item._id
            }))
            setListSuppliers(listOptions)
        } catch (error) {
            message.error(error.message || 'Unknown error')
        }
    }

    useEffect(() => {
        getListSupplier()
        getListShippingPlan()
        fetchDetailSpecialCase()
    }, [])

    return (
        <div className='SpecialCaseEditPage'>
            <div className="container-fluid">
                <div className="BackWrapper">
                    <Link className="nav-link BackButton pl-0" to="/a/special-case">
                        <i className="fas fa-chevron-left mr-2" />
                        <span>List Mapping Special Case</span>
                    </Link>
                </div>
                <div className="MenuTitle d-flex justify-content-between align-items-center border-bottom">
                    <h1 className="PageTitle">Update Case</h1>
                    <div className="Actions d-flex align-items-center justify-content-end">
                        <Link className='ant-btn ant-btn-default px-3 rounded ml-3 btn-secondary' to='/a/special-case'>Cancel</Link>
                        {
                            canEdit &&
                            <Button className="ant-btn ant-btn-primary px-4 rounded ml-3" onClick={handleSave} loading={loading} type="primary">Save</Button>
                        }
                    </div>
                </div>
                <div className="SectionInner mt-3" style={{ maxWidth: '1200px' }}>
                    <div className="p-3">
                        <Spin spinning={loading} tip='Loading...'>
                            <div className="form">
                                <div className="form-item Priority mb-3">
                                    <label className='font-weight-bold'>Store</label>
                                    <Select
                                        placeholder='Input Store'
                                        className='w-100'
                                        mode='tags'
                                        value={store}
                                        open={false}
                                        onChange={value => handleChangeMultiple('store', value, store, setStore)}
                                        type='number'
                                        allowClear
                                        disabled={!canEdit}
                                    />
                                </div>
                                <div className="form-item mb-3">
                                    <label className='font-weight-bold'>Country</label>
                                    <div className="form-item-accept mb-1">
                                        <label style={{ fontWeight: 500 }}>Accept country</label>
                                        <Select
                                            placeholder='Input accept country'
                                            value={countryAllow}
                                            mode='tags'
                                            className='w-100'
                                            allowClear
                                            showSearch
                                            open={false}
                                            onChange={value => handleChangeMultiple('countryAllow', value, countryAllow, setCountryAllow)}
                                            disabled={!canEdit}
                                        />
                                        {errorValidate.countryAllow &&
                                            <small className='text-danger font-weight-bold'>{errorValidate.countryAllow}</small>
                                        }
                                    </div>
                                    <div className="form-item-except">
                                        <label style={{ fontWeight: 500 }}>Except country</label>
                                        <Select
                                            placeholder='Input except country'
                                            value={countryDisallow}
                                            mode='tags'
                                            className='w-100'
                                            allowClear
                                            showSearch
                                            open={false}
                                            onChange={value => handleChangeMultiple('countryDisallow', value, countryDisallow, setCountryDisallow)}
                                            disabled={!canEdit}
                                        />
                                        {errorValidate.countryDisallow &&
                                            <small className='text-danger font-weight-bold'>{errorValidate.countryDisallow}</small>
                                        }
                                    </div>
                                </div>
                                <div className="form-item mb-3">
                                    <label className='font-weight-bold'>Order tag</label>
                                    <div className="form-item-accept mb-1">
                                        <label style={{ fontWeight: 500 }}>Accept tag</label>
                                        <Select
                                            placeholder='Input accept tag'
                                            value={tagAllow}
                                            mode='tags'
                                            className='w-100'
                                            allowClear
                                            showSearch
                                            open={false}
                                            onChange={value => handleChangeMultiple('tagAllow', value, tagAllow, setTagAllow)}
                                            disabled={!canEdit}
                                        />
                                    </div>
                                    <div className="form-item-except">
                                        <label style={{ fontWeight: 500 }}>Except tag</label>
                                        <Select
                                            placeholder='Input except tag'
                                            value={tagDisallow}
                                            mode='tags'
                                            className='w-100'
                                            allowClear
                                            showSearch
                                            open={false}
                                            onChange={value => handleChangeMultiple('tagDisallow', value, tagDisallow, setTagDisallow)}
                                            disabled={!canEdit}
                                        />
                                    </div>
                                </div>
                                <div className="form-item mb-3">
                                    <label className='font-weight-bold'>Title</label>
                                    <Select
                                        placeholder='Input Title'
                                        value={title}
                                        mode='tags'
                                        className='w-100'
                                        allowClear
                                        optionFilterProp="children"
                                        showSearch
                                        open={false}
                                        onChange={value => handleChangeMultiple('title', value, title, setTitle)}
                                        disabled={!canEdit}
                                    />
                                </div>
                                <div className="form-item mb-3">
                                    <label className='font-weight-bold'>Shipping Plan <span className="text-danger">*</span></label>
                                    <Select
                                        placeholder='-- Choose Shipping Plan --'
                                        value={shippingPlan || []}
                                        className='w-100'
                                        optionFilterProp="children"
                                        showSearch
                                        filterOption={(input, option) => option.children.toLowerCase().includes((input || "").toLowerCase())}
                                        disabled={!canEdit}
                                        onChange={value => handleChangeSelect(value, setShippingPlan)}
                                    >
                                        <Select.Option value={"all"}>All</Select.Option>
                                        {listShippingPlans.length > 0 && listShippingPlans.map(item => (
                                            <Select.Option key={item._id} value={item._id}>{item.title}</Select.Option>
                                        ))}
                                    </Select>
                                    {errorValidate.shippingPlan &&
                                        <small className='text-danger font-weight-bold'>{errorValidate.shippingPlan}</small>
                                    }
                                </div>
                                <div className="form-item mb-3">
                                    <label className='font-weight-bold'>Product Catalog SKU</label>
                                    <Select
                                        placeholder='Input Product Catalog SKU'
                                        value={productSku || []}
                                        mode='tags'
                                        className='w-100'
                                        allowClear
                                        optionFilterProp="children"
                                        showSearch
                                        open={false}
                                        onChange={value => handleChangeMultiple('productSku', value, productSku, setProductSku)}
                                        disabled={!canEdit}
                                    />
                                </div>
                                <div className="form-item mb-3">
                                    <label className='font-weight-bold'>Supplier <span className="text-danger">*</span></label>
                                    <Select
                                        placeholder='-- Choose Supplier --'
                                        value={supplier || []}
                                        className='w-100'
                                        allowClear
                                        filterOption={(input, option) => option.label.toLowerCase().includes((input || "").toLowerCase())}
                                        showSearch
                                        onChange={value => handleChangeSelect(value, setSupplier, 'supplier')}
                                        options={listSuppliers}
                                        disabled={!canEdit}
                                    />
                                    {errorValidate.supplier &&
                                        <small className='text-danger font-weight-bold'>{errorValidate.supplier}</small>
                                    }
                                </div>
                            </div>
                        </Spin>
                        {error && <label className='text-danger'>{error}</label>}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default SpecialCaseEditPage