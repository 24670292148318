import React from 'react'
import PropTypes from 'prop-types'
import {listFilterText, listFilterStatus} from "../contants/contanstFilter"
import MultiFilterInput from "../share/MultiFilterInput"
import FilterDateRange from "../share/FilterDateRange"
import {Select} from "antd"

Filters.propTypes = {
    status: PropTypes.any,
    onChangeStatus: PropTypes.func.isRequired
}

function Filters(props) {
    const {status, onChangeStatus, onChangeSearchMulti, onChangeDateCreated} = props

    const _onResetFilter = () => {
        onChangeStatus([])
    }

    return (
        <div className='RequestFilters'>
            <div className='Filters'>
                <MultiFilterInput
                    listFilters={listFilterText}
                    defaultValue='store'
                    onChangeSearchMulti={onChangeSearchMulti}
                />
                <div className='CustomSelect'>
                    <Select value={status} onChange={onChangeStatus}>
                        {
                            !!listFilterStatus && listFilterStatus.length > 0 && listFilterStatus.map((item, index) => {
                                return <Select.Option value={item.value} key={index}>{item.name}</Select.Option>
                            })
                        }
                    </Select>
                </div>
                <FilterDateRange
                    dropdownClassName="CreatedMapping"
                    placeholder={['Start date', 'End date']}
                    onChangeDate={onChangeDateCreated}
                />
            </div>

            {
                (!!status) && <div className="DisplayFilter">
                    {
                        !!status && status.length > 0 &&
                        <div className="ProductStatus">
                            <span className="Title">Status: </span>
                            {
                                status.map((item, index) => (
                                    <span key={index}>{item}</span>
                                ))
                            }
                            <i className='ti-close' onClick={() => _onResetFilter('status')}/>
                        </div>
                    }
                </div>
            }
        </div>
    )
}

export default Filters
