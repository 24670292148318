import React from 'react'
import ShippingFeeNewPage from './ShippingFeeNewPage'
import DocTitleByStore from "../../dashboard/shared/DocTitleByStore"
import ReturnPage from '../../shared/ReturnPage'


const ShippingFeeNewPageContainer = () => {

    return (
        <div className="ShippingFeeNewPageContainer">
            <ReturnPage url="/a/shipping-fee" title="Shipping fee" />
            <DocTitleByStore title="New shipping fee" />
            <ShippingFeeNewPage />
        </div>
    )
}

export default ShippingFeeNewPageContainer