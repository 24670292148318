import React, {Component} from 'react'
import DocTitleByStore from "../../dashboard/shared/DocTitleByStore"
import ArtworksPage from "./ArtworksPage"

class ArtworksPageContainer extends Component {
    render() {
        return (
            <section className="ArtworksPageContainer">
                <DocTitleByStore title="Artworks"/>
                <ArtworksPage/>
            </section>
        )
    }
}

export default ArtworksPageContainer