import React, {Component} from 'react'
import PropTypes from 'prop-types'
import {syncOrderTrackingFromShopify} from "../../../../../services/api/OrderTrackingServices"
import broker from '../../../OrderBroker'


class SyncShopifyTracking extends Component {
    _canSyncFromShopify = () => {
        const {tags} = this.props.order

        if (!tags || !Array.isArray(tags) || !tags.length) return false

        return tags.includes('tracking')
    }

    _sync = async () => {
        const {_id: orderId} = this.props.order

        try {
            const {success, data, message} = await syncOrderTrackingFromShopify(orderId)

            if (!success) {
                throw new Error(message)
            }

            broker.publish('ORDER_REFRESH_ORDER')
        } catch (e) {
            window.alert(e.message)
        }
    }

    _handleClick = e => {
        e.preventDefault()

        this._sync()
    }

    render() {
        const ableToSync = this._canSyncFromShopify()
        if (!ableToSync) return null

        return (
            <div onClick={this._handleClick} className="SyncShopifyTracking">
                <span className="Text">Sync tracking from Shopify</span>
            </div>
        )
    }
}


SyncShopifyTracking.propTypes = {
    order: PropTypes.object.isRequired,
}

export default SyncShopifyTracking
