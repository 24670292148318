import React, {Component} from 'react'
import StatusStyle from "../../../shared/StatusStyle"

class OrderFulfillmentBasicInfo extends Component {
    render() {
        const {order} = this.props
        const {id, fulfillment_status, updated} = order

        return (
            <div className="OrderFulfillmentBasicInfo d-flex align-items-baseline">
                <h1 className="PageTitle">{`#${id}`}</h1>
                <div className="UpdateTime mx-3">{updated}</div>
                <StatusStyle status={fulfillment_status}/>
            </div>
        )
    }
}

export default OrderFulfillmentBasicInfo
