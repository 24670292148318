import React, {Component} from 'react'
import PropTypes from "prop-types"
import ShippingStandard from "./ShippingStandard"
import ShippingCod from "./ShippingCOD"

class OrderPreferenceShipping extends Component {
    render() {
        const {preference, order} = this.props
        const {shipping} = preference

        if (!shipping || typeof shipping !== 'object' || Object.keys(shipping).length === 0) {
            return null
        }

        const {title, subtitle, cost, fulfillment_plan} = shipping

        return (
            <div className="OrderPreferenceShipping">
                {
                    (order.hasOwnProperty('shipment_infos') && order.shipment_infos.length > 0)
                        ? <ShippingCod shipping={shipping} shipmentInfo={order.shipment_infos[0]}
                                       shippingPlan={order.shipping_plan}/>
                        : <ShippingStandard
                            title={title}
                            cost={cost}
                            fulfillmentPlan={fulfillment_plan}
                            subtitle={subtitle}
                        />
                }
            </div>
        )
    }
}

OrderPreferenceShipping.propTypes = {
    preference: PropTypes.object.isRequired,
    order: PropTypes.object
}

export default OrderPreferenceShipping
