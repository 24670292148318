import React from 'react'
import PropTypes from 'prop-types'

class ProductLineVariantsSelect extends React.Component {
    render() {
        const {attribute, value, onChangeAttribute} = this.props
        const {_id: id, name, values} = attribute

        return (
            <td className='ProductLineVariantsSelect'>
                <select className='form-control' name={id} onChange={onChangeAttribute} value={value}>
                    <option value={''}>All {name}</option>
                    {
                        values.map(_value => {
                            return <option key={_value._id} value={_value._id}>{_value.name}</option>
                        })
                    }
                </select>
            </td>
        )
    }
}

ProductLineVariantsSelect.propTypes = {
    attribute        : PropTypes.object.isRequired,
    onChangeAttribute: PropTypes.func.isRequired,
    value            : PropTypes.string.isRequired
}

export default ProductLineVariantsSelect
