import React from 'react'
import PropTypes from 'prop-types'
import formatCurrency from "../../../helpers/common/formatCurrency"

OrderPreference.propTypes = {
    order: PropTypes.object
}

function OrderPreference(props) {
    const {order} = props
    return (
        <div className="OrderPreference">
            <div className="card">
                <div className="card-header font-weight-bold">Fulfillment Information</div>

                <div className="card-body">
                    <div className="OrderPreferenceShipping">
                        <div className="ShippingInner mt-3">
                            <div className="Title">Fulfillment</div>
                            <div className="Content">
                                <div className="Row">
                                    <div className="Label">BILL:</div>
                                    <div className="Value">{}</div>
                                </div>
                                <div className="Row">
                                    <div className="Label">COST:</div>
                                    <div className="Value">{}</div>
                                </div>
                            </div>
                        </div>

                        <div className="ShippingInner mt-3">
                            <div className="Title">Shipping Plan</div>
                            <div className="Content">
                                <div className="Row">
                                    <div className="Label">SHIPPING CARRIER:</div>
                                    <div className="Value">{order.tracking_company}</div>
                                </div>
                                <div className="Row">
                                    <div className="Label">SHIPPING COST:</div>
                                    <div
                                        className="Value">{formatCurrency(order.currency, order.shipping_cost || 0)}</div>
                                </div>
                            </div>
                        </div>

                        <div className="Tracking">
                            <div className="Value"><a href={order.tracking_url}>{order.tracking_number}</a></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default OrderPreference