import React, {Component} from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'


class ShipmentStatus extends Component {
    _parseStatuses = () => {
        const {status} = this.props

        if (Array.isArray(status)) {
            return status.map(st => st ? st.trim() : '')
                .filter(Boolean)
        }

        const vStatus = status ? status.trim() : ''

        if (!vStatus) return []

        return vStatus
            .split(',')
            .map(st => st ? st.trim() : '')
            .filter(Boolean)
    }


    render() {
        const statuses = this._parseStatuses()

        return (
            <div title="Shipment status" className="ShipmentStatus d-inline-flex ml-auto">
                <span className="Statuses">
                {
                    statuses.map((status, index) => {
                        return (
                            <span key={index} className={classNames("badge", status)}>{status}</span>
                        )
                    })
                }
                </span>
            </div>
        )
    }
}

ShipmentStatus.propTypes = {
    status: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.array,
    ]),
}

export default ShipmentStatus
