import React, {Component} from 'react'
import PropTypes from 'prop-types'
import {getBrandingItems} from "../../../../services/api/OrdersServices"
import BrandingItem from "./BrandingItem"
import broker from "../../OrderBroker";

class BrandingPage extends Component {
    constructor(props) {
        super(props)

        this.state = {
            loading: false,
            error: '',
            listBranding: []
        }
    }

    _subscription = null

    componentDidMount() {
        this._fetchBrandingItems()

        this._subscription = broker.subscribe('ORDER_REFRESH_BRANDING', this._fetchBrandingItems)
    }

    componentWillUnmount() {
        broker.unsubscribe(this._subscription)
    }

    _fetchBrandingItems = async () => {
        this.setState({
            loading: true,
            error: ''
        })

        try {
            const {orderId} = this.props

            const response = await getBrandingItems(orderId)

            const {success, message, data} = response

            if (!success) {
                return this.setState({
                    loading: false,
                    error: message
                })
            }

            this.setState({
                loading: false,
                error: '',
                listBranding: data
            })
        } catch (e) {
            this.setState({
                loading: false,
                error: ''
            })
        }
    }

    render() {
        const {listBranding, error, loading} = this.state

        if (loading || !listBranding || listBranding.length <= 0) {
            return null
        }

        return (
            <div className='BrandingPage OrderRejectedItems'>
                <div className="SectionInner">
                    <h3 className="SectionHeading mb-3 border-bottom pb-1">Branding</h3>
                    {
                        listBranding.map(item => {
                            return (
                                <BrandingItem key={item._id} item={item}/>
                            )
                        })
                    }

                    {
                        error && <div className='text-danger'>{error}</div>
                    }
                </div>
            </div>
        )
    }
}

BrandingPage.propTypes = {
    orderId: PropTypes.string.isRequired
}

export default BrandingPage