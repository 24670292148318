import React, {Component} from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

const mapStatus = {
    'active': 'Active', 'inactive': 'Inactive',
}

const mapAction = {
    'active': 'Activate this platform', 'inactive': 'Deactivate this platform',
}

class PlatformStatus extends Component {
    state = {
        statusToggle: false,
    }

    _onClickStatusButton = () => {
        this._toggleStatusSelect()
    }

    _onClickStatus = (status) => () => {
        this._toggleStatusSelect()
        this.props.onChangeStatus(status)
    }

    _toggleStatusSelect = () => {
        this.setState({
            statusToggle: !this.state.statusToggle,
        })
    }

    _mapStatus = () => {
        const {platform} = this.props
        if (!platform.status) return ''

        return (mapStatus[platform.status]) || ''
    }

    _getTargetStatuses = () => {
        const {platform} = this.props
        if (!platform.status || !mapAction[platform.status]) return mapAction

        return Object.keys(mapAction).filter(key => key !== platform.status).reduce((result, key) => ({
            ...result,
            [key]: mapAction[key]
        }), {})
    }

    render() {
        const {statusToggle} = this.state
        const {permissionsData} = this.props
        const status = this._mapStatus()
        const targetStatuses = this._getTargetStatuses()

        return (
            <div className="PlatformStatus">
                <div className="StatusWrapper">
                    <button
                        className="StatusButton"
                        disabled={!permissionsData.includes('ffm_platform_update')}
                        onClick={this._onClickStatusButton}>
                        <span>Status: {status}</span>
                        <span className="text-muted ml-2"><i className="fas fa-caret-down"/></span>
                    </button>
                    <ul className={classnames('StatusList', {'Show': statusToggle})}>
                        {Object.keys(targetStatuses).map((key) => <li
                            onClick={this._onClickStatus(key)}
                            key={key}>
                            {targetStatuses[key]}
                        </li>)}
                    </ul>
                </div>
                {statusToggle && <div className="Background" onClick={this._onClickStatusButton}/>}
            </div>
        )
    }
}

PlatformStatus.propTypes = {
    platform: PropTypes.object.isRequired,
    onChangeStatus: PropTypes.func.isRequired,
}

export default PlatformStatus
