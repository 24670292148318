import React, {useState, useEffect} from 'react'
import {getProductVariants} from "../../../../services/api/ProductsServices"
import {Spin} from "antd"
import PropTypes from 'prop-types'
import VariantItem from "./VariantItem"

Variants.propTypes = {
    productLineId: PropTypes.string,
    productLineTitle: PropTypes.string,
    listSelectedVariant: PropTypes.array,
    onUpdateSelectedVariants: PropTypes.func.isRequired
}

function Variants(props) {
    const [loadingVariant, setLoadingVariant] = useState(false)
    const [errorVariant, setErrorVariant] = useState('')
    const [variants, setVariants] = useState([])

    async function _fetchVariant(id) {
        setLoadingVariant(true)
        setErrorVariant('')

        try {
            const response = await getProductVariants(id)

            const {data, success, message} = response

            if (!success) {
                setLoadingVariant(false)

                return setErrorVariant(message)
            }

            setVariants(data)
            setLoadingVariant(false)
            setErrorVariant('')
        } catch (e) {
            setLoadingVariant(false)
            setErrorVariant(e.message)
        }
    }

    useEffect(() => {
        _fetchVariant(props.productLineId)
    }, [])

    const {productLineId, productLineTitle, listSelectedVariant, onUpdateSelectedVariants} = props

    return (
        <div className="Variants">
            <Spin spinning={loadingVariant}>
                {
                    !!variants && variants.length > 0 && variants.map(item => (
                        <VariantItem
                            key={item._id}
                            productLineId={productLineId}
                            productLineTitle={productLineTitle}
                            item={item}
                            length={variants.length}
                            listSelectedVariant={listSelectedVariant}
                            onUpdateSelectedVariants={onUpdateSelectedVariants}
                        />
                    ))
                }
            </Spin>
            {
                !!errorVariant && errorVariant.length > 0 && <div className="text-danger">{errorVariant}</div>
            }
        </div>
    )
}

export default Variants