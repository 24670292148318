import React, {Component} from 'react'
import PropTypes from 'prop-types'
import {undoRejectOrderItem} from "../../../../services/api/OrdersServices"
import broker from '../../OrderBroker'
import checkFulfillStatus from "../../../../helpers/common/checkFulfillStatus"
import formatCurrency from "../../../../helpers/common/formatCurrency"

class OrderRejectItem extends Component {
    state = {
        error: "",
    }

    _handleClickUndoReject = e => {
        e.preventDefault()

        this._handleRejectOrderItem()
    }

    _handleRejectOrderItem = async () => {
        const {orderId, item, onFetchOrderItems} = this.props
        const {_id: orderItemId} = item

        try {
            const {success, message} = await undoRejectOrderItem(orderId, orderItemId)

            if (!success) {
                return this.setState({
                    error: message,
                })
            }

            broker.publish("UNDO_REJECTED_ORDER_ITEM")

            onFetchOrderItems()
        } catch (e) {
            return this.setState({
                error: e.message,
            })
        }
    }

    render() {
        const {item, fulfillmentStatus} = this.props
        const {product: {title: productTitle, preview, url}, quantity, price, currency, sku, variant: {title: titleVar, sku: skuVariant, options: option}, merchizeCatalogSku} = item
        const vSku = skuVariant || sku || ''

        const options = merchizeCatalogSku ? merchizeCatalogSku.options : []
        const product_title = merchizeCatalogSku ? merchizeCatalogSku.product_title : ''

        return (
            <div className="OrderRejectItem OrderItem p-4 border-top">
                <div className="row">
                    <div className="col-2">
                        <img src={preview} alt="Product thumbnail"/>
                    </div>

                    <div className="col-6">
                        <a href={url} target="_blank" rel="noopener noreferrer"
                           className="d-block mb-2">{productTitle}</a>

                        <div className="d-flex mb-1">
                            <span className="Label font-weight-500">Price:</span>
                            <span>{formatCurrency(currency, price)}</span>
                        </div>

                        {
                            !!vSku &&
                            <div className="d-flex mb-1">
                                <span className="Label font-weight-500">SKU:</span>
                                <span className='text-break'>{vSku}</span>
                            </div>
                        }

                        <div className="d-flex">
                            <span className="Label font-weight-500">Quantity:</span>
                            <span>{quantity}</span>
                        </div>
                    </div>
                    <div className={`${merchizeCatalogSku ? 'col-6 mt-3 border-right' : 'col-12 mt-3'}`}>
                        <div className='CustomVariant'>
                            <span className="Label mr-1">Variant:</span>
                            <span className='text-break'>{titleVar}</span>
                        </div>
                        <div className='CustomVariable'>
                            <ul>
                                {
                                    option.length > 0 && option.map(opt =>
                                        <li className="d-flex mb-1" key={opt.key}>
                                            <span className="Label mr-1">{opt.key}:</span>
                                            <span className={`text-break ${opt.key}`}>{opt.value}</span>
                                        </li>
                                    )
                                }
                            </ul>
                        </div>
                    </div>

                    {
                        merchizeCatalogSku &&
                        <div className="col-6 mt-3">
                            <div className='CustomVariant'>
                                <span className="Label">Catalog variant</span>
                            </div>
                            <div className='CustomVariable'>
                                <ul>
                                    {
                                        product_title &&
                                        <li className="mb-1">
                                            <span className="Label mr-1">Product Title:</span>
                                            <span className={`text-break`}>{product_title}</span>
                                        </li>
                                    }
                                    
                                    {
                                        options && options.length > 0 &&
                                        <li className="mb-1">
                                            <span className="Label mr-1">Variant:</span>
                                            <span className={`text-break`}>{options.join(" / ")}</span>
                                        </li>
                                    }
                                    
                                </ul>
                            </div>
                        </div>
                    }

                    {
                        !checkFulfillStatus(fulfillmentStatus) &&
                        <div className="col-4">
                            <button className="btn btn-sm btn-primary px-3" onClick={this._handleClickUndoReject}>Undo
                                reject
                            </button>
                        </div>
                    }
                </div>
            </div>
        )
    }
}

OrderRejectItem.propTypes = {
    item: PropTypes.object.isRequired,
    fulfillmentStatus: PropTypes.string.isRequired,
    orderId: PropTypes.string.isRequired,
    onFetchOrderItems: PropTypes.func.isRequired,
}

export default OrderRejectItem
