import React, {Component} from 'react'

class ShippingZoneNewTitle extends Component {
    _handleChangeInput = e => {
        const {value} = e.target

        this.props._handleUpdateShippingZoneState("title", value)
    }

    render() {
        return (
            <div className="ShippingZoneNewTitle">
                <div className="row">
                    <div className="col-md-4">
                        <label htmlFor="shippingZoneTitle" className="font-weight-500">Title</label>
                    </div>

                    <div className="col-md-8">
                        <input type="text" className="form-control" id="shippingZoneTitle" onChange={this._handleChangeInput} required/>
                    </div>
                </div>
            </div>
        )
    }
}

export default ShippingZoneNewTitle
