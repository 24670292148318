import React, { useEffect, useState } from 'react'
import BulkEditPrice from "./BulkEditPrice"
import { Switch, Popconfirm, Tooltip } from "antd"
import isMatchVariant from '../../../product/helpers/isMatchVariant'
import { name } from 'store/storages/cookieStorage'
import ProductVariantsHistory from './ProductVariantsHistory'
import GroupHead from './GroupHead'
import StoreHead from './StoreHead'

function ProductVariantsHead(props) {
    const {
        variants, attributes, onChangePrice, onChangeAllVariant, toggle,
        filter, storeName = [], onChangePriceStore, deleteStoreVariant, isShowMoreTable, disableAll,
        fetchListVariants, fetchStoreVariants,
    } = props
    const [checkedAll, setCheckedAll] = useState(false)
    const [isModalOpen, setIsModalOpen] = useState(false)
    const [showGroupModal, setShowGroupModal] = useState(false)
    const [modalClick, setModalClick] = useState('')
    const [availableTiktokcheckedAll, setAvailableTiktokCheckedAll] = useState(false)

    const _onChange = (checked) => {
        setCheckedAll(checked)

        const newVariants = variants.map((item) => {
            if (isMatchVariant(filter, item) || item.is_disabled !== checked) {
                return { ...item, is_disabled: !checked }
            }
            return { ...item, is_disabled: checked }
        })

        if (typeof onChangeAllVariant === 'function') {
            onChangeAllVariant(newVariants)
        }
    }

    const _onChangeAvailableTiktok = (checked) => {
        setAvailableTiktokCheckedAll(checked)

        const newVariants = variants.map((item) => {
            if (isMatchVariant(filter, item) || item.available_tiktok !== checked) {
                return { ...item, available_tiktok: checked }
            }
            return { ...item, available_tiktok: checked }
        })

        if (typeof onChangeAllVariant === 'function') {
            onChangeAllVariant(newVariants)
        }
    }

    const _allEqual = arr => {
        return arr.every(val => val === arr[0])
    }

    const _checkVariantActive = () => {
        const pushTrue = []
        const filterselect = []

        !!variants && variants.length > 0 && variants.map((variant) => {
            if (isMatchVariant(filter, variant)) {
                return filterselect.push(variant.hasOwnProperty('is_disabled') ? variant.is_disabled : false)
            }
            return pushTrue.push(variant.hasOwnProperty('is_disabled') ? variant.is_disabled : false)
        })

        if ((Object.keys(filter).length > 0 || filterselect.length === variants.length) && filterselect[0] === false && _allEqual(filterselect)) {
            return setCheckedAll(true)
        } else {
            return setCheckedAll(false)
        }
    }

    const confirm = (storeId) => {
        deleteStoreVariant(storeId)
    }

    const toggleShowModal = (value) => {
        setModalClick(value)
        setIsModalOpen(!isModalOpen)
    }

    const toggleShowGroupModal = (value) => {
        setModalClick(value)
        setShowGroupModal(!showGroupModal)
    }

    const _checkVariantAvailableTiktokActive = () => {
        const pushTrue = []
        const filterselect = []

        !!variants && variants.length > 0 && variants.map((variant) => {
            if (isMatchVariant(filter, variant)) {
                return filterselect.push(variant.hasOwnProperty('available_tiktok') ? variant.available_tiktok : false)
            }
            return pushTrue.push(variant.hasOwnProperty('available_tiktok') ? variant.available_tiktok : false)
        })

        if ((Object.keys(filter).length > 0 || filterselect.length === variants.length) && filterselect[0] === true && _allEqual(filterselect)) {
            return setAvailableTiktokCheckedAll(true)
        } else {
            return setAvailableTiktokCheckedAll(false)
        }
    }

    useEffect(() => {
        _checkVariantActive()
        _checkVariantAvailableTiktokActive()
    }, [variants, filter])
    const groupStore = (store) => {
        const groupedArray = [];

        store.forEach(item => {
            const existingGroup = groupedArray.find(group => group.group === item.group);
            if (item.group && existingGroup) {
                if (item.store) {
                    existingGroup.store = existingGroup.store || []
                    existingGroup.store.push(item.store);
                }
                if (item._id) {
                    existingGroup._id = existingGroup._id || []
                    existingGroup._id.push(item._id);
                }
            } else {
                const newItem = {};
                if (item.name) newItem.name = item.name;
                if (item.store) newItem.store = [item.store];
                if (item.group) newItem.group = item.group;
                if (item._id) newItem._id = [item._id];
                groupedArray.push(newItem);
            }
        });

        return groupedArray;
    }
    const groupedStore = storeName.length > 0 ? groupStore(storeName) : []

    return (
        <thead className="ProductVariantsHead thead-light">
            <tr>
                {
                    !!variants && variants.length > 0 && attributes.map(att => {
                        return (
                            <th className={att.name} key={`att_${att._id}`}>{att.name}</th>
                        )
                    })
                }
                <th className={isShowMoreTable ? 'table-col-5' : 'table-col-auto'}>Price {!disableAll && <BulkEditPrice onChangePrice={onChangePrice} />}
                </th>
                {
                    groupedStore.length > 0 && groupedStore.map((item, index) => (
                        item.group ?
                            <GroupHead
                                isShowMoreTable={isShowMoreTable}
                                index={index}
                                key={`${index}_group`}
                                item={item}
                                disableAll={disableAll}
                                onChangePriceStore={onChangePriceStore}
                                confirm={confirm}
                                allStore={storeName}
                                fetchListVariants={fetchListVariants}
                                fetchStoreVariants={fetchStoreVariants}
                            />
                            :
                            <StoreHead
                                isShowMoreTable={isShowMoreTable}
                                index={index}
                                key={`${index}_store`}
                                item={item}
                                disableAll={disableAll}
                                onChangePriceStore={onChangePriceStore}
                                confirm={confirm}
                                storeName={storeName}
                                fetchListVariants={fetchListVariants}
                                fetchStoreVariants={fetchStoreVariants}
                            />
                    ))
                }
                <th className='Quantity'>Quantity</th>
                <th className={isShowMoreTable ? 'table-col-15' : 'table-col-auto'}>SKU</th>
                <th className={isShowMoreTable ? 'table-col-10' : 'table-col-auto'}>Supplier SKU</th>
                <th className={isShowMoreTable ? 'table-col-5' : 'table-col-auto'}>
                    <Tooltip title={`${checkedAll ? 'Enable variants' : 'Disable variants'}`}>
                        <Switch
                            checked={checkedAll}
                            onChange={_onChange}
                            disabled={disableAll}
                        />
                    </Tooltip>
                </th>
                <th className={isShowMoreTable ? 'table-col-5' : 'table-col-auto'}>
                    {/* <Switch
                        checked={checkedAll}
                        onChange={_onChange}
                        disabled={disableAll}
                    /> */}
                    {/* <Switch 
                        checkedChildren="Available Tiktok" 
                        unCheckedChildren="Not Available Tiktok"
                        checked={availableTiktokcheckedAll}
                        onChange={_onChangeAvailableTiktok}
                        style={{width: '140px'}}
                    /> */}
                    <Tooltip title={`${availableTiktokcheckedAll ? 'Available Tiktok' : 'Not Available Tiktok'}`}>
                        <Switch
                            checked={availableTiktokcheckedAll}
                            onChange={_onChangeAvailableTiktok}
                            disabled={disableAll}
                        />
                    </Tooltip>
                </th>
                {isShowMoreTable &&
                    <>
                        <th className="ProductVariantRowDimension">Height</th>
                        <th className="ProductVariantRowDimension">Length</th>
                        <th className="ProductVariantRowDimension">Width</th>
                        <th className="ProductVariantRowDimension">Weight</th>
                        <th className="ProductVariantRowDimension">Weight+</th>
                        <th className="ProductVariantRowDimension">Volume/5</th>
                    </>
                }
                <th className="table-col-5">
                    {!isShowMoreTable ?
                        <i className="ti-angle-double-left cursor-pointer" onClick={toggle} /> :
                        <i className="ti-angle-double-right cursor-pointer" onClick={toggle} />
                    }
                </th>
            </tr>
        </thead>
    )
}

export default ProductVariantsHead
