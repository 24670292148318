import React, {Component} from 'react'
import PropTypes from 'prop-types'
import {requestArchiveOrder} from '../../../../../services/api/OrdersServices'
import {message, notification} from 'antd'

class ArchiveOrder extends Component {
    _handleClick = e => {
        e.preventDefault()

        this._requestArchiveOrder()
    }

    _requestArchiveOrder = async () => {
        const {orderId, onSuccess} = this.props

        try {
            const {success, message: error} = await requestArchiveOrder(orderId)

            if (!success) throw new Error(error)

            message.success('Order archived')

            onSuccess()
        } catch (e) {
            notification.error({
                message: 'ERROR',
                description: e.message,
                duration: 0,
            })
        }
    }

    render() {
        return (
            <div className='ArchiveOrder' onClick={this._handleClick}>
                Archive order
            </div>
        )
    }
}

ArchiveOrder.propTypes = {
    orderId: PropTypes.string.isRequired,
    onSuccess: PropTypes.func.isRequired,
}

export default ArchiveOrder
