import React, {Component} from 'react'
import PropTypes from 'prop-types'
import {Select} from "antd"
import {listStoreModes} from '../../../statics/storeModes'

const {Option} = Select

class SelectStoreMode extends Component {
    _handleChange = (value) => {
        const {onChange} = this.props

        if (typeof onChange === 'function') {
            onChange('store_modes', value)
        }
    }

    render() {
        const {storeMode} = this.props

        return (
            <div className="SelectStoreMode mb-3 form-group col-md-6">
                <label>Store Mode</label>
                <Select
                    mode="multiple"
                    size={'large'}
                    allowClear
                    style={{width: '100%'}}
                    defaultValue={storeMode}
                    onChange={this._handleChange}
                >
                    {
                        listStoreModes.map(item => {
                            return <Option value={item.value} key={item.value}>{item.name}</Option>
                        })
                    }
                </Select>
            </div>
        )
    }
}

SelectStoreMode.propTypes = {
    onChange: PropTypes.func.isRequired,
    storeMode: PropTypes.array
}

export default SelectStoreMode
