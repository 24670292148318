import React from 'react'
import SettingHolidayHeader from './SettingHolidayTable/SettingHolidayHeader'
import SettingHolidayRow from './SettingHolidayTable/SettingHolidayRow'

function SettingHolidayTable(props) {
    const { listSetting } = { ...props }
    return (
        <div className="SettingHolidayTable">
            <table className='table'>
                <SettingHolidayHeader />
                <tbody className='SettingHolidayRow'>
                    {!!listSetting && listSetting.length > 0 ? listSetting.map((item, i) => {
                        return <SettingHolidayRow
                            key={item._id}
                            index={i}
                            item={item}
                        />
                    }) : <tr>
                        <td colSpan={6}>No data</td>
                    </tr>
                    }
                </tbody>
            </table>
        </div>
    )
}

export default SettingHolidayTable