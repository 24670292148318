import React from 'react'
import PropTypes from 'prop-types'
import {ModalHeader} from "reactstrap"
import ModalBody from "reactstrap/es/ModalBody"
import ModalFooter from "reactstrap/es/ModalFooter"
import Modal from "reactstrap/es/Modal"

ConfirmDeleteModal.propTypes = {
    modal: PropTypes.bool,
    title: PropTypes.string,
    onToggleModal: PropTypes.func.isRequired,
    onDeleteProduct: PropTypes.func.isRequired
}

function ConfirmDeleteModal(props) {
    const {modal, title, onToggleModal, onDeleteProduct} = props

    return (
        <Modal isOpen={modal} toggle={onToggleModal}>
            <ModalHeader>Confirm delete?</ModalHeader>
            <ModalBody>
                Are you sure want to delete <strong>{title}</strong>?<br/>
                This action cannot be reversed.
            </ModalBody>
            <ModalFooter>
                <button className="btn border" onClick={onToggleModal}>Cancel</button>
                <button className="btn btn-danger" onClick={onDeleteProduct}>Delete product
                </button>
            </ModalFooter>
        </Modal>
    )
}

export default ConfirmDeleteModal
