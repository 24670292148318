import React, {Component} from 'react'
import {searchProducts} from "../../../../../../services/api/ProductsServices"

class ShippingMethodsProduct extends Component {
    state = {
        products: [],
        error: "",
    }

    componentDidMount() {
        this._fetchListProducts()
    }


    _fetchListProducts = async () => {
        try {
            const {data, success, message} = await searchProducts({
                limit: -1,
            })

            if (!success) {
                this.setState({
                    error: message,
                })

                return false
            }

            const {products} = data

            this.setState({
                products: products,
                error: "",
            })

            return true
        } catch (e) {
            return this.setState({
                error: e.message,
            })
        }
    }

    _handleChangeSelect = e => {
        const {value} = e.target
        const {index, _handleUpdatePlansState} = this.props

        _handleUpdatePlansState(index, "products", [value])
    }

    render() {
        const {products} = this.state

        return (
            <td className="ShippingMethodsProduct">
                <select name="products" className="form-control" required onChange={this._handleChangeSelect}>
                    <option value="">-- Select product --</option>
                    {
                        products.map(product => {
                            return <option key={product._id} value={product._id}>{product.title}</option>
                        })
                    }
                </select>
            </td>
        )
    }
}

export default ShippingMethodsProduct
