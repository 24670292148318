import React from 'react'
import RolesPage from './RolesPage'
import DocTitleByStore from "../../dashboard/shared/DocTitleByStore"

function RolesPageContainer() {
    return (
        <div className="RolesPageContainer">
            <DocTitleByStore title="Roles" />
            <RolesPage />
        </div>
    )
}

export default RolesPageContainer