import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { getArtworksByOrderItemV3 } from "../../../../services/api/ArtworksServices"
import ArtworkType from "./ArtworkType"
import ArtworkPreview from "./ArtworkPreview"

OrderItemDesign.propTypes = {
    artworks: PropTypes.array
}

function OrderItemDesign(props) {
    const [error, setError] = useState('')
    const [artworks, setArtworks] = useState({})

    const _fetchOrder = async () => {
        setError('')

        try {
            const { orderId, orderItemId } = props
            const response = await getArtworksByOrderItemV3({ orderId, orderItemId })

            const { data, success, message } = response

            if (!success) {
                return setError(message)
            }

            setArtworks(data.length > 0 ? data[0].artworks : data)
        } catch (e) {
            setError(e.message)
        }
    }

    useEffect(() => {
        const { isCheckVersionOrderPartnerV2, artworks: listArtworks } = props
        if (isCheckVersionOrderPartnerV2) return setArtworks(listArtworks)
        _fetchOrder()
    }, [])

    return (
        <td className="OrderItemDesign">
            {
                !!artworks.length &&
                <div className="listArtwork">
                    <ArtworkType artworks={artworks} />
                    <ul className="ListArtworks position-relative row align-items-baseline">
                        {
                            artworks.map((artwork) => {
                                return (
                                    <li key={artwork._id} className="col-6">
                                        <ArtworkPreview artwork={artwork} />
                                    </li>
                                )
                            })
                        }
                    </ul>
                </div>
            }

            {
                !!error &&
                <div className="text-danger">{error}</div>
            }
        </td>
    )
}

export default OrderItemDesign
