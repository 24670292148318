import createApiServices from "./createApiServices"

const prefixURL = process.env.NODE_ENV === 'production' ? '/api/order' : 'https://fulfillment-staging.merchize.com/api/order'
//const prefixURL = 'http://localhost:5700'
const api = createApiServices({baseUrl: prefixURL})

export const getMappingCondition = (data) => {
    return api.makeAuthRequest({
        url: `/mapping-conditions/search`,
        method : 'POST',
        data
    })
}


export const updateMappingCondition = (mappingConditionId, mappingCondition) => {
    return api.makeAuthRequest({
        url: `/mapping-conditions/${mappingConditionId}`,
        method: 'PUT',
        data: mappingCondition
    })
}

export const addMappingCondition = (mappingCondition) => {
    return api.makeAuthRequest({
        url: `/mapping-conditions`,
        method: 'POST',
        data: mappingCondition
    })
}

export const getMappingConditionHistory = (mappingConditionId, query) => {
    return api.makeAuthRequest({
        url: `/mapping-conditions/${mappingConditionId}/history`,
        method: 'GET',
        params:query,
    })
}