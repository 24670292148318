import React, {useEffect, useState} from 'react'
import {Input} from "antd"
import {
    SearchOutlined,
} from '@ant-design/icons'

const InputSearch = (props) => {
    const {name, value, label} = props
    const [valueInput, _setValue] = useState(value)

    const _handleSetValue = () => {
        _setValue('')
    }

    const _handleChangeInput = (e) => {
        const {name, value} = e.target
        _setValue(value)
        props.onChange(name, value)
    }

    useEffect(() => {
        props.value.length === 0 && _handleSetValue()
    }, [props.value])

    return (
        <div className="InputSearch">
            <div className="font-weight-500 LabelFilter mb-1">{label}</div>

            <Input name={name} value={valueInput} placeholder='Enter to search...' onChange={_handleChangeInput} style={{width: "100%"}}/>
            {value === '' && (
                <span className="icon">
                <SearchOutlined />
            </span>
            )}
        </div>
    )
}

export default InputSearch
