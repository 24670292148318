import React, {Component, Fragment} from 'react'
import OrdersTableHead from './OrdersTable/OrdersTableHead'
import OrdersTableRow from './OrdersTable/OrdersTableRow'
import PropTypes from 'prop-types'
import OrderItemsContainer from './order-items/OrderItemsContainer'
import {Spin} from 'antd'

class OrdersTable extends Component {
    state = {
        selectedAll: false,
        selectedOrders: [],
        selectedOrdersID: []
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const prevOrders = prevProps.orders.map(order => order._id)
        const currOrders = this.props.orders.map(order => order._id)

        if (JSON.stringify(prevOrders) !== JSON.stringify(currOrders)) {
            this.setState({
                selectedAll: false,
                selectedOrders: [],
                selectedOrdersID: []
            })
        }
    }

    _updateSelectedOrders = (id, isAdded, orderNumber) => {
        this.setState(({selectedOrders, selectedOrdersID}) => {
            if (isAdded && !selectedOrders.includes(id)) {
                return {
                    selectedOrders: [...selectedOrders, id],
                    selectedAll: selectedOrders.length + 1 === this.props.orders.length,
                    selectedOrdersID: [...selectedOrdersID, orderNumber]
                }
            } else {
                const filtered = selectedOrders.filter(orderId => orderId !== id)
                const filteredID = selectedOrdersID.filter(orderNumberId => orderNumberId !== orderNumber)

                return {
                    selectedOrders: filtered,
                    selectedAll: false,
                    selectedOrdersID: filteredID
                }
            }
        }, () => {
            const {onChangeCodes} = this.props
            const {selectedOrdersID} = this.state

            if (typeof onChangeCodes === 'function') {
                onChangeCodes(selectedOrdersID)
            }
        })
    }

    _toggleSelectAll = (isChecked) => {
        this.setState(() => {
            if (isChecked) {
                const {orders} = this.props
                const orderIds = orders.map(order => order._id)
                const orderNumbers = orders.map(order => order.id)

                return {
                    selectedOrders: orderIds,
                    selectedAll: true,
                    selectedOrdersID: orderNumbers
                }
            }

            return {
                selectedOrders: [],
                selectedAll: false,
                selectedOrdersID: []
            }
        }, () => {
            const {onChangeCodes} = this.props
            const {selectedOrdersID} = this.state

            if (typeof onChangeCodes === 'function') {
                onChangeCodes(selectedOrdersID)
            }
        })
    }

    _resetSelect = () => {
        this.setState({
            selectedOrders: [],
            selectedAll: false,
            selectedOrdersID: []
        }, () => {
            const {onChangeCodes} = this.props
            const {selectedOrdersID} = this.state

            if (typeof onChangeCodes === 'function') {
                onChangeCodes(selectedOrdersID)
            }
        })
    }

    render() {
        const {orders, displayOrderItem, loading, currentPage, onFetchOrders, handleChangeFilterState, onChangeOrderNote, filters} = this.props
        const {selectedOrders, selectedAll} = this.state

        return (
            <div className='OrdersTable'>
                <Spin spinning={loading} tip='Loading...'>
                    <table className='table'>
                        <OrdersTableHead
                            selectedOrders={selectedOrders}
                            isSelectedAll={selectedAll}
                            onFetchOrders={onFetchOrders}
                            orders={orders}
                            onToggleSelectAll={this._toggleSelectAll}
                            resetSelect={this._resetSelect}
                            onChangeOrderNote={onChangeOrderNote}
                            filters={filters}
                        />
                        <tbody>
                        {
                            orders.length > 0 ?
                                orders.map(order => {
                                    return (
                                        <Fragment key={order._id}>
                                            <OrdersTableRow order={order} selectedOrders={selectedOrders}
                                                            currentPage={currentPage}
                                                            onUpdateSelectedOrders={this._updateSelectedOrders}
                                                            handleChangeFilterState={handleChangeFilterState}
                                            />
                                            {
                                                displayOrderItem && <OrderItemsContainer orderId={order._id}/>
                                            }
                                        </Fragment>
                                    )
                                })
                                :
                                <tr>
                                    <td><i>No orders</i></td>
                                </tr>
                        }
                        </tbody>
                    </table>
                </Spin>
            </div>
        )
    }
}

OrdersTable.propTypes = {
    orders: PropTypes.array.isRequired,
    loading: PropTypes.bool.isRequired,
    displayOrderItem: PropTypes.bool.isRequired,
    currentPage: PropTypes.number.isRequired,
}

export default OrdersTable
