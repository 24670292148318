import React, { Component } from 'react'
import { DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown } from 'reactstrap'
import PropTypes from 'prop-types'
import OrderBulkAction from './OrderBulkAction'
import BulkCreateFactoryOrders from './BulkCreateFactoryOrders'
import BulkMarkProcessing from './BulkMarkProcessing'
import ExportOrdersSheet from "./../../export-orders-sheet/ExportOrdersSheet"
import BulkRemoveNote from "./BulkRemoveNote"
import BulkAddNote from "./BulkAddNote"
import BulkRemoveTags from './BulkRemoveTags'
import BulkMarkFulFilledOrders from './BulkMarkFulFilledOrders'
import { getLocalData } from '../../../../../services/StorageServices'
import { parseJwt } from '../../../../../helpers/parseToken'
import BulkAddTags from './BulkAddTags'
import BulkRefundCharge from './BulkRefundCharge'

class BulkSelectOrder extends Component {
    state = {
        message: '',
        openAddNote: false,
        openRemoveTags: false,
        openAddTags: false,
        openRefundCharge: false
    }

    _handleChange = () => {
        const { isSelectedAll } = this.props

        this.props.onToggleSelectAll(!isSelectedAll)
    }

    _handleShowAddNote = () => {
        this.setState({ openAddNote: true })
    }

    _handleUnShowAddNote = () => {
        this.setState({ openAddNote: false })
        this.props.resetSelect()
    }

    _handleShowRemoveTags = () => {
        this.setState({ openRemoveTags: true })
    }

    _handleUnShowRemoveTags = () => {
        this.setState({ openRemoveTags: false })
        this.props.resetSelect()
    }

    _handleShowAddTags = () => {
        this.setState({ openAddTags: true })
    }

    _handleUnShowAddTags = () => {
        this.setState({ openAddTags: false })
        this.props.resetSelect()
    }

    _handleShowRefundCharge = () => {
        this.setState({ openRefundCharge: true })
    }

    _handleUnRefundCharge = () => {
        this.setState({ openRefundCharge: false })
        this.props.resetSelect()
    }

    render() {
        const { selectedOrders, onFetchOrders, orders, resetSelect, onChangeOrderNote, filters } = this.props
        const {fulfillment_status, payment_status, code} = filters
        const permissionsData = getLocalData('permissions') ? getLocalData('permissions') : {}
        const userRoles = !!getLocalData('roles') && getLocalData('roles').length > 0 ? getLocalData('roles').map(i => i.name) : []
        const hasPermissionRefundCharge = !!userRoles.length && userRoles.includes('ffm_admin') && (payment_status === 'paid' || payment_status === 'partially_refunded') && fulfillment_status === 'fulfilled' && code && code !== '' ? true : false

        return (
            <th className='BulkSelectOrder position-relative'>
                <div className='d-flex'>
                    <div className='custom-control custom-checkbox'>
                        <input
                            type='checkbox'
                            className='custom-control-input'
                            id='bulkSelectOrder'
                            checked={this.props.isSelectedAll}
                            onChange={this._handleChange}
                        />
                        <label htmlFor='bulkSelectOrder' className='custom-control-label' />
                    </div>

                    {
                        selectedOrders.length > 0 &&
                        <UncontrolledDropdown className='MenuActions position-absolute'>
                            <DropdownToggle tag='span' className='ToggleActions'>
                                <span
                                    className='border rounded d-inline-flex px-2 py-1 bg-light font-weight-normal align-items-baseline'>
                                    Actions <i className="fas fa-caret-down ml-2" />
                                </span>
                            </DropdownToggle>

                            <DropdownMenu>
                                {
                                    permissionsData.hasOwnProperty('ffm_order_create_factory_orders') &&
                                    <DropdownItem>
                                        <BulkCreateFactoryOrders
                                            selectedOrders={selectedOrders}
                                            onSuccess={onFetchOrders}
                                            resetSelect={resetSelect}
                                        />
                                    </DropdownItem>
                                }

                                {
                                    permissionsData.hasOwnProperty('ffm_order_mark_processing') &&
                                    <DropdownItem>
                                        <BulkMarkProcessing selectedOrders={selectedOrders} />
                                    </DropdownItem>
                                }

                                {
                                    permissionsData.hasOwnProperty('ffm_order_detail_fulfill_order') &&
                                    <DropdownItem>
                                        <BulkMarkFulFilledOrders selectedOrders={selectedOrders} />
                                    </DropdownItem>
                                }

                                {
                                    (permissionsData.hasOwnProperty('ffm_order_create_factory_orders') || permissionsData.hasOwnProperty('ffm_order_mark_processing')) ?
                                        <DropdownItem divider /> : ''
                                }

                                {
                                    permissionsData.hasOwnProperty('ffm_order_archive_orders') &&
                                    <DropdownItem>
                                        <OrderBulkAction
                                            name='Archive orders'
                                            slug='archive-by-ids'
                                            selectedOrders={selectedOrders}
                                            onSuccess={onFetchOrders}
                                        />
                                    </DropdownItem>
                                }

                                {
                                    permissionsData.hasOwnProperty('ffm_order_undo_archive_orders') &&
                                    <DropdownItem>
                                        <OrderBulkAction
                                            name='Undo archive orders'
                                            slug='undo-archive-by-ids'
                                            selectedOrders={selectedOrders}
                                            onSuccess={onFetchOrders}
                                        />
                                    </DropdownItem>
                                }

                                {
                                    (permissionsData.hasOwnProperty('ffm_order_archive_orders') || permissionsData.hasOwnProperty('ffm_order_undo_archive_orders')) ?
                                        <DropdownItem divider /> : ''
                                }

                                {
                                    permissionsData.hasOwnProperty('ffm_order_reject_orders') &&
                                    <>
                                        <DropdownItem>
                                            <OrderBulkAction
                                                name='Reject orders'
                                                slug='reject-fulfill'
                                                selectedOrders={selectedOrders}
                                            />
                                        </DropdownItem>
                                        <DropdownItem divider />
                                    </>
                                }

                                {
                                    permissionsData.hasOwnProperty('ffm_order_export_orders_ggsheet_1c') &&
                                    <DropdownItem>
                                        <ExportOrdersSheet selectedOrders={selectedOrders} orders={orders} supplier="1c" />
                                    </DropdownItem>
                                }

                                {
                                    permissionsData.hasOwnProperty('ffm_order_export_orders_ggsheet_basegift') &&
                                    <DropdownItem>
                                        <ExportOrdersSheet
                                            selectedOrders={selectedOrders}
                                            orders={orders}
                                            supplier="basegift"
                                        />
                                    </DropdownItem>
                                }

                                {
                                    permissionsData.hasOwnProperty('ffm_order_detail_push_ggsheet_tcvn') &&
                                    <DropdownItem>
                                        <ExportOrdersSheet
                                            selectedOrders={selectedOrders}
                                            orders={orders}
                                            supplier="tcvn"
                                            textSupplier="TCVN"
                                        />
                                    </DropdownItem>
                                }

                                {
                                    (permissionsData.hasOwnProperty('ffm_order_export_orders_ggsheet_1c') || permissionsData.hasOwnProperty('ffm_order_export_orders_ggsheet_basegift')) ?
                                        <DropdownItem divider /> : ''
                                }

                                <DropdownItem>
                                    <div className='BulkAddNote' onClick={this._handleShowAddNote}>
                                        Add note
                                    </div>
                                </DropdownItem>

                                {
                                    permissionsData.hasOwnProperty('ffm_order_remove_note') &&
                                    <DropdownItem>
                                        <BulkRemoveNote
                                            selectedOrders={selectedOrders}
                                            onSuccess={onFetchOrders}
                                            resetSelect={resetSelect}
                                        />
                                    </DropdownItem>
                                }

                                <DropdownItem>
                                    <div className='BulkRemoveTags' onClick={this._handleShowRemoveTags}>
                                        Remove tags
                                    </div>
                                </DropdownItem>

                                <DropdownItem>
                                    <div className='BulkAddTags' onClick={this._handleShowAddTags}>
                                        Add Tags
                                    </div>
                                </DropdownItem>

                                {
                                    hasPermissionRefundCharge &&
                                    <DropdownItem>
                                        <div className='BulkRefundCharge' onClick={this._handleShowRefundCharge}>
                                            Refund/Change
                                        </div>
                                    </DropdownItem>

                                }
                            </DropdownMenu>
                        </UncontrolledDropdown>
                    }
                    {this.state.openAddNote && <BulkAddNote
                        selectedOrders={selectedOrders}
                        resetSelect={resetSelect}
                        unShowModal={this._handleUnShowAddNote}
                        open={this.state.openAddNote}
                        orders={orders}
                        onChangeOrderNote={onChangeOrderNote}
                    />}

                    {this.state.openRemoveTags && <BulkRemoveTags
                        selectedOrders={selectedOrders}
                        unShowModal={this._handleUnShowRemoveTags}
                        open={this.state.openRemoveTags}
                        orders={orders}
                        onFetchOrders={onFetchOrders}
                    />}

                    {this.state.openAddTags && <BulkAddTags
                        selectedOrders={selectedOrders}
                        resetSelect={resetSelect}
                        unShowModal={this._handleUnShowAddTags}
                        open={this.state.openAddTags}
                        orders={orders}
                        onFetchOrders={onFetchOrders}
                    />}

                    {this.state.openRefundCharge && <BulkRefundCharge
                        selectedOrders={selectedOrders}
                        resetSelect={resetSelect}
                        unShowModal={this._handleUnRefundCharge}
                        open={this.state.openRefundCharge}
                        orders={orders}
                        onFetchOrders={onFetchOrders}
                    />}
                </div>
            </th>
        )
    }
}

BulkSelectOrder.propTypes = {
    selectedOrders: PropTypes.array.isRequired,
    isSelectedAll: PropTypes.bool.isRequired,
    onToggleSelectAll: PropTypes.func.isRequired,
    onFetchOrders: PropTypes.func.isRequired
}

export default BulkSelectOrder
