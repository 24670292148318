import React, {Component} from 'react'
import PlatformsPage from './PlatformsPage'
import DocTitleByStore from '../../dashboard/shared/DocTitleByStore'

class PlatformsContainer extends Component {
    render() {

        return (
            <div className="PlatformsContainer">
                <DocTitleByStore title='Platforms'/>

                <PlatformsPage
                    {...this.props}
                />
            </div>
        )
    }
}

export default PlatformsContainer
