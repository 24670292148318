import React, {Component} from 'react'
import {getProductAttributes, getProductVariants} from "../../../../services/api/ProductsServices"
import ProductLineVariantsTable from "./ProductLineVariants/ProductLineVariantsTable"

class ProductLineVariants extends Component {
    state = {
        variants: [],
        attributes: [],
        error: "",
    }

    componentDidMount() {
        this._fetchProductVariant()
        this._fetchProductAttributes()
    }

    _fetchProductVariant = async () => {
        try {
            const {selectedProduct, orderId} = this.props
            const {data, success, message} = await getProductVariants(selectedProduct, orderId)

            if (!success) {
                return this.setState({
                    error: message,
                })
            }

            return this.setState({
                variants: [...data],
                error: "",
            })
        } catch (e) {
            return this.setState({
                error: e.message,
            })
        }
    }

    _fetchProductAttributes = async () => {
        try {
            const {selectedProduct} = this.props
            const {data, success, message} = await getProductAttributes(selectedProduct)

            if (!success) {
                return this.setState({
                    error: message,
                })
            }

            return this.setState({
                attributes: [...data],
                error: "",
            })
        } catch (e) {
            return this.setState({
                error: e.message,
            })
        }
    }

    render() {
        const {variants, attributes} = this.state
        const {orderItemId, _handleUpdateStateOrderItem, selectedProduct, filter, listSuppliers, artworks, _handleAlert, namespace, order_type} = this.props

        return (
            variants.length > 0 &&
            <div className="ProductLineVariants">
                <ProductLineVariantsTable variants={variants} attributes={attributes} orderItemId={orderItemId}
                                          _handleUpdateStateOrderItem={_handleUpdateStateOrderItem}
                                          selectedProduct={selectedProduct} filter={filter}
                                          listSuppliers={listSuppliers}
                                          artworks={artworks} _handleAlert={_handleAlert} order_type={order_type}/>
            </div>
        )
    }
}

export default ProductLineVariants
