import createApiServices from "./createApiServices"
import queryString from 'query-string'

const prefixURL = process.env.NODE_ENV === 'production' ? '/api/product' : 'https://fulfillment-staging.merchize.com/api/product'
const devURL = window['API_SHIPPING_SERVICE_URL'] || ''
const api = createApiServices({baseUrl: prefixURL})

export const getCatalogVariants = (data) => {
    return api.makeAuthRequest({
        url: `/catalog-variants/search`,
        method: 'POST',
        data
    })
}