import React, {useState} from 'react'
import SetupExportModal from './SetupExportModal'
import parseSearchQuery from '../../../../helpers/routing/parseSearchQuery'

function SetupExport() {
    const {google_drv_sheet, supplier} = parseSearchQuery()

    const [_modalOpen, _setModalOpen] = useState(!!google_drv_sheet)

    const _toggleTracking = () => {
        _setModalOpen(!_modalOpen)
    }

    return (
        <div className="Tracking">
            <button className="btn btn-sm btn-outline-secondary" onClick={_toggleTracking}>Setup export</button>
            <SetupExportModal
                open={_modalOpen} supplier={supplier} onToggle={_toggleTracking}
            />
        </div>
    )
}

SetupExport.propTypes = {}

export default SetupExport
