import React, {Component} from 'react'
import PropTypes from 'prop-types'
import StatusStyle from "../../shared/StatusStyle"
import OrderAdvanceActions from "./meta/OrderAdvanceActions"
import humanizeTime from "../../../helpers/time/humanizeTime"
import CheckDuplicateOrder from "./meta/CheckDuplicateOrder"
import OrderArchived from "./meta/OrderArchived"
import OrderPaidAt from "./meta/OrderPaidAt"
import OrderFulfillmentStatus from "./meta/OrderFulfillmentStatus"
import {Tooltip} from "antd"
import { getLocalData } from '../../../services/StorageServices'
class OrderMeta extends Component {
    render() {
        const {code, namespace, isLegacy, canRestore, orderId, order, onUpdateTotalPushToProduction, onFetchOrder, paymentStatus, fulfilledPackages} = this.props

        const {
            updated, completed_at, created,
            fulfillment_status: fulfillmentStatus,
            tracking_status, cancelled_at, fulfilled_at, packages
        } = order
        const updatedText = (updated ? 'Updated' : 'Created') + ' at'

        const permissionsData = getLocalData('permissions') ? getLocalData('permissions') : {}

        return (
            <div className="OrderMeta">
                <div className="d-sm-flex align-items-baseline">
                    <h1 className="PageTitle OrderNumber font-weight-bold mb-0">{`#${code}`}</h1>
                    <div className="UpdateTime mx-3">{updatedText}: {humanizeTime(updated || created)}</div>
                    {
                        !!completed_at &&
                        <div className="UpdateTime mx-3">Completed at: {humanizeTime(completed_at)}</div>
                    }
                    {
                        !!fulfilled_at &&
                        <div className="UpdateTime mx-3">Fulfilled at: {humanizeTime(fulfilled_at)}</div>
                    }
                    {
                        !!cancelled_at &&
                        <div className="UpdateTime mx-3">Cancelled at: {humanizeTime(cancelled_at)}</div>
                    }
                    <code className="ml-3 ml-sm-0" title="Namespace">{namespace}</code>

                    {
                        (packages !== undefined && packages.total > 0 && packages.success > 0) &&
                        <div className="ml-3 mr-3">Push to production:
                            <Tooltip title='Package success'><strong
                                className='ml-1'>{packages.success}</strong></Tooltip>/<Tooltip
                                title='Package total'><strong>{packages.total}</strong></Tooltip>
                        </div>
                    }

                    {
                        tracking_status === 'completed' && <StatusStyle status="shipped"/>
                    }

                </div>

                <div className="d-sm-flex mt-2 align-items-baseline">
                    <OrderFulfillmentStatus order={order}/>

                    <OrderPaidAt order={order}/>

                    <OrderArchived order={order}/>

                    {
                        isLegacy && <span className="text-info ml-3 ml-sm-5">legacy</span>
                    }

                    <CheckDuplicateOrder orderId={orderId}/>

                    {
                        (permissionsData.hasOwnProperty('ffm_order_detail_restore_order') || permissionsData.hasOwnProperty('ffm_order_detail_push_ggsheet_1c') || permissionsData.hasOwnProperty('ffm_order_detail_push_ggsheet_basegift') || permissionsData.hasOwnProperty('ffm_order_detail_archive')) ? <div className="ml-0 ml-sm-5">
                        <OrderAdvanceActions
                            paymentStatus={paymentStatus} order={order}
                            onUpdateTotalPushToProduction={onUpdateTotalPushToProduction}
                            completedAt={completed_at} canRestore={canRestore}
                            orderId={orderId} fulfillmentStatus={fulfillmentStatus} onFetchOrder={onFetchOrder}
                            fulfilledPackages={fulfilledPackages}
                        />
                    </div> : ''
                    }
                
                </div>
            </div>
        )
    }
}

OrderMeta.propTypes = {
    order: PropTypes.object.isRequired,
    code: PropTypes.string.isRequired,
    time: PropTypes.string.isRequired,
    fulfillmentStatus: PropTypes.string.isRequired,
    orderId: PropTypes.string.isRequired,
    completedAt: PropTypes.string,
    canRestore: PropTypes.bool,
    onUpdateTotalPushToProduction: PropTypes.func.isRequired,
}

export default OrderMeta
