import {Component} from 'react'
import PropTypes from 'prop-types'
import {
    getCurrentStore,
    getCurrentStoreSettings, getStoreScopes, refreshStoreSettings,
    subscribeChangeStore,
    unsubscribeChangeStore
} from "../../services/CurrentStoreService"
import {fetchStoreSettingsBySlug} from "../../services/api/SellerServices"

class WithStoreSettings extends Component {
    state = {
        storeId: getCurrentStore(),
        settings: getCurrentStoreSettings(),
        scopes: getStoreScopes()
    }

    _token = null

    componentDidMount() {
        this._token = subscribeChangeStore(this._handleAuthChange)
    }

    componentWillUnmount() {
        unsubscribeChangeStore(this._token)
    }

    _handleAuthChange = () => {
        this.setState({
            storeId: getCurrentStore(),
            settings: getCurrentStoreSettings(),
            scopes: getStoreScopes(),
        })
    }

    _getSetting = (key, defaultValue = null) => {
        const {settings} = this.state

        if (!settings || !Array.isArray(settings)) return defaultValue

        const setting = settings.find(_setting => _setting.key === key)
        if (!setting) return defaultValue

        const {value} = Object.assign({}, setting)

        return value || defaultValue
    }

    _getLinkAssets = (key, defaultValue = null) => {
        const value = this._getSetting(key, defaultValue)
        const domain = this._getSetting('domain', '')

        if (!domain) return value
        if (!value) return defaultValue

        if (value.indexOf('http') !== -1) return value

        return `https://${domain}${value}`
    }

    _refreshSettings = async () => {
        const {storeId} = this.state

        if (!storeId) return false

        try {
            const {success, data, message} = await fetchStoreSettingsBySlug(storeId)

            if (!success) {
                return console.error(message)
            }

            refreshStoreSettings(data)
        } catch (e) {
            console.error(e)
        }

        return true
    }

    _getTimezone = () => {
        const timezone = this._getSetting('timezone', '(UTC+00:00)')
        const text = (timezone + '').trim()
        const regex = /\(UTC(.\d+:\d+)\)/gi
        const parse = regex.exec(text)

        if (!parse || parse.length < 2) return '+00:00'

        return (parse[1] + '').trim()
    }

    _currentUserCan = (scope, alternative = '') => {
        if (!scope) return false

        const {scopes} = this.state
        const arr = Array.isArray(scopes) ? scopes : []

        if (alternative && arr.indexOf(alternative) !== -1) return true

        return arr.indexOf(scope) !== -1
    }

    render() {
        return this.props.children({
            ...this.state,
            getTimezone: this._getTimezone,
            getSetting: this._getSetting,
            getLinkAssets: this._getLinkAssets,
            refresh: this._refreshSettings,
            currentUserCan: this._currentUserCan,
        })
    }
}

WithStoreSettings.propTypes = {
    children: PropTypes.func.isRequired,
}

export default WithStoreSettings
