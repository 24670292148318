import React, {Component} from 'react'
import PropTypes from 'prop-types'

class OrderJobTotalSent extends Component {
    render() {
        return (
            <td className="OrderJobTotalSent">
                {this.props.totalSent}
            </td>
        )
    }
}

OrderJobTotalSent.propTypes = {
    totalSent: PropTypes.number.isRequired,
}

export default OrderJobTotalSent
