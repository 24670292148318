import React, {useState} from 'react'
import OrderItemTable from "./OrderItemTable"
import { Tooltip } from 'antd'
import PackageCheckDetail from './PackageCheckDetail'

function OrderItems(props) {
    const {items, orderId, orderType, packageStatus, fufillmentId} = props
    const [isOpenCheckDetail, setIsOpenCheckDetail] = useState(false)
    
    const _handleOpenCheckDetail = (e) => {
        e.preventDefault()

        setIsOpenCheckDetail(true)
    }

    const _handleCloseCheckDetail = () => {
        setIsOpenCheckDetail(false)
    }

    return (
        <div className="OrderItems">
            <div className="SectionInner">
                <OrderItemTable items={items} orderId={orderId} orderType={orderType} />
                {
                        packageStatus === "pushed" &&
                        <Tooltip title="get order detail from SupplierName">
                            <button onClick={_handleOpenCheckDetail} className="btn btn-primary mr-auto">
                                Check Detail
                            </button>
                        </Tooltip>
                    }
            </div>

            {
                packageStatus === "pushed" &&
                <PackageCheckDetail
                    fufillmentId={fufillmentId}
                    isOpen={isOpenCheckDetail}
                    onClose={_handleCloseCheckDetail}
                />
            }
        </div>
    )
}

export default OrderItems