import React from 'react'

function FileDetailHead() {
    return (
        <thead 
            className="FileDetailTableHead" 
            style={{ background :'#e9ecef'}}
        >
            <tr>
                <th className="Line_csv" style={{minWidth:'81px'}}>Line CSV</th>
                <th className="Package_name">Package name</th>
                <th className="Reference_id">Reference id</th>
                <th className="Order_supplier_id">Order supplier id</th>
                <th className="Production_fee">Production fee</th>
                <th className="Shipping_fee">Shipping fee</th>
                <th className="Tax">Tax</th>
                <th className="Status">Status</th>
                <th className="Message">Message</th>
            </tr>
        </thead>
    )
}

export default FileDetailHead