import React from 'react'

function RequestThead(props) {
    return (
        <thead className='RequestThead'>
        <tr>
            <th className="RequestId">Request ID</th>
            <th className="Type">Type</th>
            <th className="Source">Source</th>
            <th className="Source">Production status</th>
            <th className="Info">Info</th>
            <th className="Content">Content</th>
            <th className="Created">Created</th>
            <th className="Updated">Updated</th>
            <th className="Status">Status</th>
        </tr>
        </thead>
    )
}

export default RequestThead
