import { getLocalData, setLocalData } from '../../services/StorageServices'
import NotAuthorizedContainer from '../not-authorized/components/NotAuthorizedContainer'
import { message } from 'antd'
import getHistory from '../../store/getHistory'

const withPermissions = (WrappedComponent, requiredPermission) => {
    const storedPermissions = getLocalData('permissions')
    const hasPermission = storedPermissions && requiredPermission ? storedPermissions.hasOwnProperty(requiredPermission) : true

    const _handleLogout = () => {
        setLocalData('user', '')
        setLocalData('accessToken', '')
        setLocalData('refreshToken', '')
        // setLocalData('permissions', {})

        const history = getHistory()
        history.push('/login')
    }

    if (!storedPermissions) {
        // message.error(`Your account don't have permissions. Please sign in again!!!`)
        _handleLogout()
    }

    return hasPermission ? WrappedComponent : NotAuthorizedContainer

}

export default withPermissions