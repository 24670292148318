import React from 'react'
import { CloseOutlined, CopyOutlined } from '@ant-design/icons'
import { Button, Tooltip } from 'antd'
import ItemReplaced from './ItemReplaced'

const PackageSingleItemEditor = (props) => {
    const { item, index, totalItem, preference, handleDuplicateItem, onChangeCheckBoxIssueReplace, supplierSku } = props
    const { storeMode } = { ...preference }
    const {
        mockUpFront,
        mockUpBack,
        designFront,
        designBack,
        designSleeves,
        designHood,
        size,
        type,
        color,
        item: itemName,
        quantity,
        productLabel,
        partner_sku,
        captured_partner_sku,
        level,
        outOfStock,
        issueProductions,
        _id,
    } = item

    const hightLight = !!item.itemHasBeenUpdated ? 'ItemHightLight' : null
    const { reference_number = '' } = Object.assign({}, issueProductions)
    const isInfoIssueReplace = !!reference_number && !["1C", "TCVN"].includes(supplierSku)

    const _handleChangeInput = field => e => {
        const { value } = e.target
        const { index, item, onChangeArtworkData, supplierSku } = props
        let newValue = value

        if (!!value && field === 'quantity') {
            newValue = parseInt(value)
        }

        const itemUpdated = Object.assign({}, item, { [field]: newValue })

        if (supplierSku === 'PF') {
            if (field === 'designFront' || field === 'designBack' || field === 'designSleeves' || field === 'designHood') {
                onChangeArtworkData({
                    ...item,
                    [field]: newValue
                })
            }
        }

        props.onChange(itemUpdated, index)
    }

    const _handleClickRemove = () => {
        const { index, onRemoveItem } = props

        onRemoveItem(index)
    }

    return (
        <>
            <tr data-index={index} className={`PackageSingleItemEditor ${index % 2 === 0 ? 'dark' : 'light'} ${hightLight}`}>
                <td rowSpan={issueProductions.length > 0 ? 2 : 1} style={{ verticalAlign: isInfoIssueReplace ? 'top' : 'middle' }}>
                    <Button
                        icon={<CloseOutlined />}
                        type='link'
                        className='text-danger'
                        disabled={totalItem === 1}
                        onClick={_handleClickRemove}
                    />
                </td>
                <td style={{ verticalAlign: isInfoIssueReplace ? 'top' : 'middle' }}>
                    <div className='position-relative'>
                        <input
                            value={mockUpFront}
                            onChange={_handleChangeInput('mockUpFront')}
                            placeholder="mockUpFront"
                            className="form-control"
                        />
                    </div>
                </td>
                <td style={{ verticalAlign: isInfoIssueReplace ? 'top' : 'middle' }}>
                    <input
                        value={mockUpBack}
                        onChange={_handleChangeInput('mockUpBack')}
                        placeholder="mockUpBack"
                        className="form-control"
                    />
                </td>
                <td style={{ verticalAlign: isInfoIssueReplace ? 'top' : 'middle' }}>
                    <input
                        value={designFront}
                        onChange={_handleChangeInput('designFront')}
                        placeholder="designFront"
                        className="form-control"
                        disabled
                    />
                </td>
                <td style={{ verticalAlign: isInfoIssueReplace ? 'top' : 'middle' }}>
                    <input
                        value={designBack}
                        onChange={_handleChangeInput('designBack')}
                        placeholder="designBack"
                        className="form-control"
                        disabled
                    />
                </td>
                <td style={{ verticalAlign: isInfoIssueReplace ? 'top' : 'middle' }}>
                    <input
                        value={designSleeves}
                        onChange={_handleChangeInput('designSleeves')}
                        placeholder="designSleeves"
                        className="form-control"
                        disabled
                    />
                </td>
                <td style={{ verticalAlign: isInfoIssueReplace ? 'top' : 'middle' }}>
                    <input
                        value={designHood}
                        onChange={_handleChangeInput('designHood')}
                        placeholder="designHood"
                        className="form-control"
                        disabled
                    />
                </td>
                <td style={{ verticalAlign: isInfoIssueReplace ? 'top' : 'middle' }}>
                    <input
                        value={size}
                        onChange={_handleChangeInput('size')}
                        placeholder="size"
                        className="form-control"
                        disabled
                    />
                </td>
                <td style={{ verticalAlign: isInfoIssueReplace ? 'top' : 'middle' }}>
                    <input
                        value={type}
                        onChange={_handleChangeInput('type')}
                        placeholder="type"
                        className="form-control"
                        disabled
                    />
                </td>
                <td style={{ verticalAlign: isInfoIssueReplace ? 'top' : 'middle' }}>
                    <input
                        value={partner_sku}
                        onChange={_handleChangeInput('partner_sku')}
                        placeholder="sku partner"
                        className={`${!!outOfStock ? 'Warning' : ''} form-control`}
                    />
                </td>
                <td style={{ verticalAlign: isInfoIssueReplace ? 'top' : 'middle' }}>
                    <input
                        value={color}
                        onChange={_handleChangeInput('color')}
                        placeholder="color"
                        className="form-control"
                    />
                </td>
                <td style={{ verticalAlign: isInfoIssueReplace ? 'top' : 'middle' }}>
                    <input
                        value={itemName}
                        onChange={_handleChangeInput('item')
                        }
                        placeholder="item"
                        className="form-control"
                    />
                </td>
                <td style={{ verticalAlign: isInfoIssueReplace ? 'top' : 'middle' }}>
                    <input
                        value={quantity}
                        disabled={productLabel === 'branding'}
                        onChange={_handleChangeInput('quantity')}
                        placeholder="quantity"
                        className={`${(!!level && level === 'productvariant') || (item.hasOwnProperty('pack') && item.pack > 1) ? 'Warning' : ''} form-control`}
                    />
                </td>
                {storeMode === "marketplace" && <td>
                    <Tooltip title="Duplicate line item">
                        <Button
                            icon={<CopyOutlined />}
                            type='link'
                            className='text-primary'
                            onClick={() => handleDuplicateItem(item)}
                        />
                    </Tooltip>
                </td>}
            </tr>
            {
                issueProductions.length > 0 &&
                <tr className={`PackageSingleItemEditor ${index % 2 === 0 ? 'dark' : 'light'} ${hightLight} PackageSingleItemEditorIssues`}>
                    <td colSpan={storeMode === "marketplace" ? 13 : 12}>
                        <div className='position-relative'>
                            <div>
                                <span className='text-danger d-block' style={{ fontSize: 13 }}>{issueProductions.length} {issueProductions.length === 1 ? 'Item' : 'Items'} needs replacing.</span>
                                <span style={{ fontSize: 15 }} className='d-block'>Mark these issue items as replaced:</span>
                                <div className='item-replaced-list'>
                                    {
                                        issueProductions.map((ffmIssueItem, index) => {
                                            return (
                                                <ItemReplaced key={index} id={_id} item={ffmIssueItem} onChange={onChangeCheckBoxIssueReplace} />
                                            )
                                        })
                                    }
                                </div>
                            </div>
                        </div>
                    </td>
                </tr>
            }
        </>
    )
}

export default PackageSingleItemEditor