import React, {Component} from 'react'
import DocTitleByStore from "../../dashboard/shared/DocTitleByStore"
import CreateProductPage from "./CreateProductPage"
import ReturnPage from "../../shared/ReturnPage"

class CreateProductPageContainer extends Component {
    render() {
        const dataClone = this.props.location.state

        return (
            <section className="CreateProductPageContainer">
                <ReturnPage url="/a/products" title="Products"/>
                <h1 className="PageTitle">Create Product</h1>
                <DocTitleByStore title="Create Product"/>
                <CreateProductPage dataClone={dataClone}/>
            </section>
        )
    }
}

export default CreateProductPageContainer
