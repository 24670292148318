import React, {useState, useEffect} from 'react'
import {Button} from 'antd'
import FulfillmentDiscountsFilters from './FulfillmentDiscountsFilters'
import FulfillmentDiscountsTable from './FulfillmentDiscountsTable'
import FulfillmentDiscountsProvider from "./FulfillmentDiscountsProvider"
import UseDebounce from '../helpers/UseDebounce'
import {getDiscounts} from "../../../services/api/OrdersServices"
import {getFiltersStorage, setFilterStorage} from "../../shared/ManageFiltersState"
import {Link} from "react-router-dom"
import moment from "moment"
import { getLocalData } from '../../../services/StorageServices'

const FulfillmentDiscountsPage = (props) => {
    const permissionsData = !!getLocalData('permissions') ? Object.keys(getLocalData('permissions')) : []
    const {params} = props
    const filtersStorage = getFiltersStorage('fulfillmentDiscounts')
    const [page, _setPage] = useState(1)
    const [limit, _setLimit] = useState(50)
    const [loading, _setLoading] = useState(true)
    const [total, _setTotal] = useState(0)
    const [error, _setError] = useState('')
    const [discountsLists, _setDiscountsLists] = useState([])
    const [filters, _setFilters] = useState(Object.keys(params).length === 0 ? filtersStorage : params)

    const debouncedSearchNamespaces = filters.namespaces === [] ? UseDebounce(filters.namespaces, 0) : UseDebounce(filters.namespaces, 500)
    const debouncedSearchName = filters.name === '' ? UseDebounce(filters.name, 0) : UseDebounce(filters.name, 500)

    const _handleSetState = (filters) => {
        setFilterStorage('fulfillmentDiscounts', filters)
        _setFilters(filters)
        _setPage(1)
    }

    const _fetchFulfillmentDiscounts = async () => {
        try {
            _setLoading(true)
            _setDiscountsLists([])
            _setError('')

            const payload = {
                ...filters,
                apply_date: moment(filters.apply_date, 'DD/MM/YYYY HH:mm'),
                page,
                limit
            }

            const response = await getDiscounts(payload)

            const {success, message, data} = response

            if (!success) {
                _setLoading(false)
                return _setError(message)
            }

            const {total, discounts: fulfillmentsLists} = data

            _setDiscountsLists(fulfillmentsLists)

            _setLoading(false)
            _setError('')
            _setTotal(total)
        } catch (e) {
            _setLoading(false)
            _setError(e.message)
        }
    }

    const _handleSetPage = (page) => {
        _setPage(page)
    }

    const _handleSetSize = (size) => {
        _setLimit(size)
    }

    useEffect(() => {
        _fetchFulfillmentDiscounts()
    }, [page, limit, debouncedSearchNamespaces, debouncedSearchName, filters.type, filters.status, filters.updated_at, filters.apply_date])

    return (
        <FulfillmentDiscountsProvider filters={filters} setState={_handleSetState}
                                      loadOrders={_fetchFulfillmentDiscounts}>
            <div className="NotificationPage pt-3">
                <div className='d-flex flex-column flex-md-row align-items-md-center justify-content-between mt-3 mb-3'>
                    <h1 className="PageTitle">Fulfillment Discounts</h1>
                    {permissionsData.includes('ffm_fulfillment_discounts_create') && 
                        <div className="d-flex align-items-center justify-content-end mr-lg-0 mr-3">
                            <Link to={`/a/discounts/new`}><Button type="primary" className="ml-3">Create
                                discount</Button></Link>
                        </div>
                    }
                </div>

                <div className='SectionInner'>
                    <div className='filter mb-3 align-items-center'>
                        <div className="d-flex align-items-center justify-content-between">
                            <FulfillmentDiscountsFilters/>
                        </div>
                    </div>
                    <div className="table">
                        {error && <div className="text-danger">{error}</div>}
                        <FulfillmentDiscountsTable
                            loading={loading}
                            limit={limit}
                            page={page}
                            total={total}
                            discounts={discountsLists}
                            onChangeSize={_handleSetSize}
                            onChangePage={_handleSetPage}
                            permissionsData={permissionsData}
                        />
                    </div>
                </div>
            </div>
        </FulfillmentDiscountsProvider>
    )
}

export default FulfillmentDiscountsPage
