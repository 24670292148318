import createApiServices from "./createApiServices"

const prefixURL = process.env.NODE_ENV === 'production' ? '/api/artwork' : 'https://fulfillment-staging.merchize.com/api/artwork'

const api = createApiServices({baseUrl: prefixURL})

export const getListArtworks = (query) => {
    return api.makeAuthRequest({
        url: `/artworks`,
        method: 'GET',
        params: query
    })
}

export const getArtworksByProduct = ({orderId, productId}) => {
    return api.makeAuthRequest({
        url: `/orders/${orderId}/${productId}/artworks`,
        method: 'GET'
    })
}

export const getArtworksByOrderItem = ({orderId, orderItemId}) => {
    return api.makeAuthRequest({
        url: `/v2/orders/${orderId}/${orderItemId}/artworks`,
        method: 'GET'
    })
}

export const getArtworksByOrderItemV3 = ({orderId, orderItemId}) => {
    return api.makeAuthRequest({
        url: `/v3/orders/${orderId}/${orderItemId}/artworks`,
        method: 'GET'
    })
}

export const getValidateArtwork = (payload = {}) => {
    return api.makeAuthRequest({
        url: `artworks/dimension/validate`,
        method: 'POST',
        data: payload
    })
}

export const addMappingRule = (payload) => {
    return api.makeAuthRequest({
        url: '/mapping-rules',
        method: 'POST',
        data: payload
    })
}
