import React from 'react'
import FulfillmentDiscountsPage from './FulfillmentDiscountsPage'
import DocTitleByStore from "../../dashboard/shared/DocTitleByStore"
import queryString from "query-string"

const FulfillmentDiscountsContainer = (props) => {
    const {history} = props
    const {location} = history
    let params = {}
    if (location.search !== '') {
        params = queryString.parse(location.search)
    }

    return (
        <section className="FulfillmentDiscountsContainer">
            <DocTitleByStore title="Fulfillment Discounts"/>
            <FulfillmentDiscountsPage params={params}/>
        </section>
    )
}

export default FulfillmentDiscountsContainer
