import React from "react";

export default () => {
    return (
        <div className="split-package__empty">
            <p>
                Drag order item to this area
            </p>
        </div>
    )
}