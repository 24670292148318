import React, {Component} from 'react'
import {searchUserAuth, getDepartmentList} from '../../../../services/api/UserService'
import ListingUsersTable from './ListingUsersTable'
import ListingUsersPagination from './ListingUsersPagination'
import TableFilter from '../../../shared/TableFilter'
import UserCreatedModal from "./UserCreateModal"
import UserEditModal from './UserEditModal'
import { getLocalData } from '../../../../services/StorageServices'
import { Pagination } from 'antd'
import { Link } from 'react-router-dom'


class ListingUsersPage extends Component {
    state = {
        page: 1,
        limit: 10,
        users: [],
        pages: 1,
        total: 0,
        error: '',
        username: '',
        isOpenModalNew: false,
        departments: []
    }

    componentDidMount() {
        this._fetchUsers()
        this.getUserDepartment()
    }

    _fetchUsers = async () => {
        const {page, limit, username} = this.state

        try {
            const payload = {
                username: username
            }
            const {data : data, success: success, message: message} = await searchUserAuth(`page=${page}&limit=${limit}`,payload)

            if (!success) {
                return this.setState({
                    error: message,
                })
            }

            const {users, pages, total} = data

            return this.setState({
                users: users,
                pages: pages,
                total: total,
                error: '',
            })
        } catch (e) {
            if (e.response.status !== 200) {
                return this.setState({
                    error: e.response.data.message,
                })
            } else {
                return this.setState({
                    error: e.message,
                })
            }
        }
    }

    getUserDepartment = async () => {
        try {
            const { success, data, message } = await getDepartmentList()

            if (!success) return this.setState({
                error: message,
            })

            const { departments } = data

            return this.setState({
                departments: departments
            })
        } catch (e) {
            return this.setState({
                error: e.message,
            })
        }
    }

    _toggleModalNew = () => {
        this.setState({isOpenModalNew: !this.state.isOpenModalNew})
    }

    _openModalEdit = (username, _id) => {
        this.setState({changePasswordModal: {username, _id}})
    }

    _closeModalEdit = () => {
        this.setState({changePasswordModal: null})
    }

    _handPagePagination = (selectedPage) => {
        this.setState({
            page: selectedPage,
        }, () => {
            this._fetchUsers()
        })
    }

    _handleShowSizeChange = (current, size) => {
        this.setState({
            page: 1,
            limit: size,
        }, () => {
            this._fetchUsers()
        })
    }

    _onSubmitFilter = ({limit, value, field}) => {
        const fieldMap = {
            'Username': 'username'
        }

        const limitNumber = Number(limit)

        const parsedField = fieldMap[field]
        const parsedValue = value.trim()

        this.setState({
            limit: limitNumber,
            page: 1,
            [parsedField]: parsedValue,
        }, () => this._fetchUsers())
    }

    render() {
        const {users, total, page, pages, limit, isOpenModalNew, changePasswordModal, error, departments} = this.state
        const permissionsData = !!getLocalData('permissions')? Object.keys(getLocalData('permissions')) : []
        const disableCreate = !permissionsData.includes('ffm_setting_users_create')
        const disableRoleManagement = !permissionsData.includes('ffm_role_management')
        return (
            <div className="ListingUserPage pt-3">
                <div className='row justify-content-end mb-3 pr-3'>
                    <div className="UserCreate text-right">
                        {!disableCreate &&
                            <button
                                className='btn btn-primary'
                                // disabled={disableCreate}
                                onClick={this._toggleModalNew}
                            >
                                New User
                            </button>
                        }
                        {!disableRoleManagement &&
                            <Link
                                className='btn btn-primary ml-3'
                                to='/a/roles'
                            >
                                Roles settings
                            </Link>
                        }
                    </div>
                </div>

                <div className='SectionInner'>
                    <div className='filter align-items-center'>
                        <TableFilter
                            defaultLimit={limit}
                            total={total}
                            isDateOn={false}
                            onSubmitForm={this._onSubmitFilter}
                            searchField={['Username']}
                            placeholders={{
                                'Username': 'Search user by username',
                                'Role': 'Search user by role',
                            }}
                            selectorStatus={false}
                        />

                        {
                            error && <div className='text-danger'>{error}</div>
                        }
                    </div>

                    {
                        users.length > 0 &&
                        <div className="mt-3">
                            <ListingUsersTable users={users} refresh={this._fetchUsers}
                                               openModalEdit={this._openModalEdit} permissionsData={permissionsData}
                                               departments={departments}
                            />
                            <ListingUsersPagination pages={pages} page={page}
                                                    onPagePagination={this._handPagePagination}/>
                            <Pagination
                            current={page}
                            total={total}
                            pageSize={limit}
                            showTotal={(sum, range) => sum > 0 &&
                                <div>
                                    {`Hiển thị ${range[0]} đến ${range[1]} của `}<strong>{`${total}`}</strong> users
                                </div>}
                            onChange={this._handPagePagination}
                            className='text-right' showSizeChanger
                            pageSizeOptions={['10', '20', '50', '100']}
                            onShowSizeChange={this._handleShowSizeChange}
                        />
                        </div>
                    }</div>

                {changePasswordModal &&
                // <UserChangePasswordModal isOpen={!!changePasswordModal} email={changePasswordModal.email}
                //                          _id={changePasswordModal._id}
                //                          toggle={this._closeModalEdit} refresh={this._fetchUsers}
                //                          className='ChangePasswordUser'/>
                    <UserEditModal
                        isOpen={!!changePasswordModal} 
                        username={changePasswordModal.username}
                        _id={changePasswordModal._id}
                        toggle={this._closeModalEdit} 
                        refresh={this._fetchUsers}
                        className='ChangePasswordUser'
                    />
                }

                {isOpenModalNew &&
                <UserCreatedModal
                    isOpen={isOpenModalNew}
                    toggle={this._toggleModalNew}
                    refresh={this._fetchUsers}
                    className='UserCreateModal'
                />
                }
            </div>
        )
    }
}

export default ListingUsersPage
