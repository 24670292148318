import React, {Component} from 'react'
import PropTypes from 'prop-types'
import {rejectOrderItem} from "../../../../../services/api/OrdersServices"
import Modal from "reactstrap/es/Modal"
import ModalBody from "reactstrap/es/ModalBody"
import ModalFooter from "reactstrap/es/ModalFooter"
import broker from '../../../OrderBroker'
import Clipboard from "react-clipboard.js"
import checkFulfillStatus from "../../../../../helpers/common/checkFulfillStatus"
import formatCurrency from "../../../../../helpers/common/formatCurrency"
import imageDefault from "../../../../../helpers/common/imageDefault"
import { Tag, Button, Modal as AntdModal } from 'antd'
import ObjectInspector from 'react-object-inspector'
import classNames from 'classnames'
import { getLocalData } from '../../../../../services/StorageServices'
import { parseJwt } from '../../../../../helpers/parseToken'

class OrderItemProduct extends Component {
    state = {
        copyImage: "Copy image url",
        copyProduct: "Copy product url",
        showModal: false,
        showModalUpdated: false,
        error: "",
        showModalMockup: false,
    }

    _handleToggleModal = () => {
        this.setState({
            showModal: !this.state.showModal,
        })
    }

    _handleClickReject = e => {
        e.preventDefault()

        this._handleRejectOrderItems()
    }

    _handleRejectOrderItems = async () => {
        try {
            const {orderId, orderItemId, _fetchOrderItems} = this.props
            const {success, message} = await rejectOrderItem(orderId, {
                items: [orderItemId],
            })

            if (!success) {
                return this.setState({
                    error: message,
                })
            }

            _fetchOrderItems()

            broker.publish("ORDER_ITEM_REJECTED")
            broker.publish("ORDER_REFRESH_BRANDING")
            broker.publish("ORDER_RESTORE")

            return this.setState({
                showModal: false,
                error: "",
            })
        } catch (e) {
            return this.setState({
                error: e.message,
            })
        }
    }

    _handleCopyAddressSuccess = () => {
        this.setState({
            copyImage: "Copied!",
        })

        setTimeout(() => {
            this.setState({
                copyImage: "Copy image url",
            })
        }, 2000)
    }

    _handleCopyProductUrl = () => {
        this.setState({
            copyProduct: "Copied!",
        })

        setTimeout(() => {
            this.setState({
                copyProduct: "Copy product title",
            })
        }, 2000)
    }

    handleOpenModal = () => {
        this.setState({
            showModalUpdated: true,
        })
    }

    handleCancel = () => {
        this.setState({
            showModalUpdated: false,
        })
    }

    _handleToggleModalMockup = () => {
        this.setState({
            showModalMockup: !this.state.showModalMockup,
        })
    }

    render() {
        const {orderItem, fulfillmentStatus} = this.props
        const {product, variant, price, quantity, sku, currency, variant_histories, mockup_url, mockup_message, merchizeCatalogSku} = orderItem
        const {sku: skuVariant} = Object.assign({}, variant)
        const vSku = skuVariant || sku || ''

        const {error, showModal, copyImage, copyProduct, showModalUpdated, showModalMockup} = this.state

        const permissionsData = getLocalData('permissions') ? getLocalData('permissions') : {}
        const options = merchizeCatalogSku ? merchizeCatalogSku.options : []
        const product_title = merchizeCatalogSku ? merchizeCatalogSku.product_title : ''

        return (
            <td className="OrderItemProduct">
                <div className="row">
                    <div className="col-4">
                        <div className="position-relative ThumbnailWrapper">
                            <img
                                src={product.preview}
                                alt="Product thumbnail"
                                onError={(e)=>{e.target.onerror = null; e.target.src=imageDefault}}
                            />
                            {
                                permissionsData.hasOwnProperty('ffm_order_detail_reject_product') && !checkFulfillStatus(fulfillmentStatus) &&
                                <div className="Actions position-absolute">
                                    <button className="btn btn-sm btn-danger" onClick={this._handleToggleModal}>Reject
                                        product
                                    </button>
                                </div>
                            }
                        </div>

                        <div className='thumbnail-bottom'>
                            <Clipboard component="div" data-clipboard-text={product.preview}
                                    className="cursor-pointer d-inline-block CopyImageUrl mt-2"
                                    onSuccess={this._handleCopyAddressSuccess}>
                                <div className="Tooltip3 Bottom">
                                    <i className="far fa-copy"/>

                                    <div className="TooltipContent Clipboard">
                                        <div className="ContentInner text-center">
                                            <small className="text-monospace">{copyImage}</small>
                                        </div>
                                    </div>
                                </div>
                            </Clipboard>

                            <div className={classNames('cursor-pointer', 'mt-2', 'ml-3', 'mockup-open', {'disable' : mockup_message || !mockup_url})} onClick={this._handleToggleModalMockup}>
                                <i className="far fa-image"></i>
                            </div>
                        </div>
                    </div>

                    <div className="col-8">
                        <div className="mb-2 d-flex">
                            <a href={product.url} target="_blank" rel="noopener noreferrer"
                               className="text-break mr-3">{product.title}</a>
                            <Clipboard data-clipboard-text={product.title} component="span"
                                       className="d-inline-block cursor-pointer" onSuccess={this._handleCopyProductUrl}>
                                <div className="Tooltip3 Bottom">
                                    <i className="far fa-copy"/>

                                    <div className="TooltipContent Clipboard">
                                        <div className="ContentInner text-center">
                                            <small className="text-monospace">{copyProduct}</small>
                                        </div>
                                    </div>
                                </div>
                            </Clipboard>
                            {!!variant_histories && !!variant_histories.length &&
                                <Tag 
                                    color="#87d068" 
                                    className='position-absolute' 
                                    style={{right:'0', cursor: 'pointer'}} 
                                    onClick={this.handleOpenModal} 
                                >Updated</Tag>
                            }
                            <AntdModal
                                visible={showModalUpdated}
                                title="Updating histories"
                                onCancel={this.handleCancel}
                                footer={[
                                    <Button key="back" onClick={this.handleCancel}>
                                        Close
                                    </Button>
                                ]}
                            >
                                <table className="table table-striped">
                                    {!!variant_histories && !!variant_histories.length && variant_histories.map(i => (
                                        <tbody><tr><td><ObjectInspector depth={0} path="root.value" data={i} /></td></tr></tbody>
                                    ))}
                                </table>
                            </AntdModal>
                        </div>

                        <div className="d-flex mb-1">
                            <span className="Label">Price:</span>
                            <span>{formatCurrency(currency, price)}</span>
                        </div>

                        {
                            !!vSku &&
                            <div className="d-flex mb-1">
                                <span className="Label">SKU:</span>
                                <span className='text-break'>{vSku}</span>
                            </div>
                        }

                        <div className="Quantity d-flex">
                            <span className="Label">Quantity:</span>
                            <span className="Number">{quantity}</span>
                        </div>
                    </div>

                    <div className={`${merchizeCatalogSku ? 'col-6 mt-3 border-right' : 'col-12 mt-3 ssss'}`}>
                        <div className='CustomVariant'>
                            <span className="Label">Variant:</span>
                            <span className='text-break'>{variant.title}</span>
                        </div>
                        <div className='CustomVariable'>
                            <ul>
                                {
                                    variant.options.length > 0 && variant.options.map(item =>
                                        <li className="d-flex mb-1" key={item.key}>
                                            <span className="Label mr-1">{item.key}:</span>
                                            <span className={`text-break ${item.key}`}>{item.value}</span>
                                        </li>
                                    )
                                }
                            </ul>
                        </div>
                    </div>

                    {
                        merchizeCatalogSku &&
                        <div className="col-6 mt-3">
                            <div className='CustomVariant'>
                                <span className="Label">Catalog variant</span>
                            </div>
                            <div className='CustomVariable'>
                                <ul>
                                    {
                                        product_title &&
                                        <li className="mb-1">
                                            <span className="Label mr-1">Product Title:</span>
                                            <span className={`text-break`}>{product_title}</span>
                                        </li>
                                    }
                                    
                                    {
                                        options && options.length > 0 &&
                                        <li className="mb-1">
                                            <span className="Label mr-1">Variant:</span>
                                            <span className={`text-break`}>{options.join(" / ")}</span>
                                        </li>
                                    }
                                    
                                </ul>
                            </div>
                        </div>
                    }

                    {
                        mockup_message &&
                        <div className='col-12 mt-3 mockup-message'>
                            <i className="fas fa-exclamation-triangle"></i> {mockup_message}
                        </div>
                    }
                    
                </div>

                <Modal isOpen={showModal} toggle={this._handleToggleModal}>
                    <ModalBody>
                        Are you sure want to reject <strong>{product.title}</strong>? This action cannot be reversed.
                    </ModalBody>
                    <ModalFooter>
                        <div className="d-flex justify-content-end">
                            <button className="btn border" onClick={this._handleToggleModal}>Cancel</button>
                            <button className="btn btn-danger ml-2" onClick={this._handleClickReject}>Reject product
                            </button>
                        </div>

                        {
                            !!error && <div className="mt-3 text-danger">{error}</div>
                        }
                    </ModalFooter>
                </Modal>
                {
                    mockup_url && !mockup_message &&
                    <Modal className='modal-mockup' isOpen={showModalMockup} toggle={this._handleToggleModalMockup}>
                        <ModalBody>
                            <div className='mockup-image'>
                                <img src={mockup_url} alt={product.title}/>
                            </div>
                        </ModalBody>
                        <ModalFooter>
                            <div className="d-flex justify-content-end">
                                <button className="btn border" onClick={this._handleToggleModalMockup}>Cancel</button>
                            </div>
                        </ModalFooter>
                    </Modal>
                }
                
            </td>
        )
    }
}

OrderItemProduct.propTypes = {
    orderItem: PropTypes.object.isRequired,
}

export default OrderItemProduct
