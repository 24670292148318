import React from 'react'
import DiscountEditPage from "./DiscountEditPage"

function DiscountEditContainer(props) {
    const {discountId} = props.match.params

    return (
        <div className="DiscountEditContainer">
            <DiscountEditPage discountId={discountId}/>
        </div>
    )
}

export default DiscountEditContainer