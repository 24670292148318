import React, {useEffect, useState} from 'react'
import PropTypes from 'prop-types'

FilterSelect.propTypes = {
    listFilters: PropTypes.array.isRequired,
    placeholder: PropTypes.string.isRequired,
    onChangeFilters: PropTypes.func.isRequired,
    listSelected: PropTypes.array,
    name: PropTypes.string
}

function FilterSelect(props) {
    const {listSelected, listFilters, placeholder, onChangeFilters} = props
    const [openPopup, setOpenPopup] = useState(false)
    const [selected, setSelected] = useState([])

    const _openPopup = () => {
        setOpenPopup(true)
    }

    const _closePopup = () => {
        setSelected(!!listSelected ? [...listSelected] : [])
        setOpenPopup(false)
    }

    const _onSelectAll = () => {
        setSelected(listFilters.map(item => item.value))
    }

    const _onClearSelect = () => {
        setSelected([])
    }

    const _onCheckBoxForDiv = (name) => {
        document.getElementById(name).click()
    }

    const _handleChangeCheckbox = (e) => {
        const {checked, name} = e.target

        if (checked) {
            setSelected([...selected, name])
        } else {
            setSelected(selected.filter((item => item !== name)))
        }
    }

    const _onFilter = () => {
        if (typeof onChangeFilters === 'function') {
            onChangeFilters(selected)
            setOpenPopup(false)
        }
    }

    useEffect(() => {
        setSelected(listSelected)
        // eslint-disable-next-line
    }, [listSelected.length])

    return (
        <div className='CustomMultiSelect form-group'>
            <div className='InputSelect' onClick={_openPopup}>
                    <span>
                        {placeholder}
                    </span>
            </div>
            <div className={`PopupSelect ${openPopup}`}>
                <div className="ListItemsSelect">
                    {
                        listFilters.length > 0 && listFilters.map((item, index) =>
                            <div key={index} className='Item'>
                                <input
                                    id={item.value}
                                    type="checkbox"
                                    name={item.value}
                                    checked={selected.includes(item.value)}
                                    onChange={_handleChangeCheckbox}
                                />
                                <label onClick={() => _onCheckBoxForDiv(item.value)}>{item.name}</label>
                            </div>)
                    }
                </div>
                <div className="Actions">
                    <button onClick={_onSelectAll} className='LinkAction'>Select All</button>
                    <button
                        disabled={selected.length === 0}
                        className='LinkAction'
                        onClick={_onClearSelect}
                    >
                        Clear
                    </button>
                    <button onClick={_onFilter}>Filter</button>
                </div>
            </div>
            {
                openPopup && <div className="Background" onClick={_closePopup}/>
            }
        </div>
    )
}

export default FilterSelect
