import React, {Component} from 'react'

class ShippingZoneListCountries extends Component {

    _handleChangeSelect = e => {
        const {value: countryId} = e.target

        if (countryId === "") {
            return
        }

        this.props.onUpdateCountriesState(countryId, true, false)
    }

    render() {
        const {countries} = this.props

        return (
            countries.length > 0 &&
            <div className="ShippingZoneListCountries">
                <select name="countries" className="form-control" value="" onChange={this._handleChangeSelect}>
                    <option value="">-- Select country --</option>
                    {
                        countries.map(countryObj => {
                            const {available, country} = countryObj
                            const {_id, title} = country

                            const titleFormatted = !available ? `${title}` : title

                            return (
                                <option key={_id} value={_id} disabled={!available}>
                                    {titleFormatted}
                                </option>
                            )
                        })
                    }
                </select>
                <small>Can't select countries in other shipping zones</small>
            </div>
        )
    }
}

export default ShippingZoneListCountries
