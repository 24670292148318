import React, {Component} from 'react'
import PropTypes from 'prop-types'
import {ReactComponent as IconDown} from '../../../../statics/assets/images/icon_down.svg'
// import { handleInputChange } from 'react-select/dist/utils-2db2ca57.cjs.prod'
import {Input, Select} from "antd"


class CustomInputMultiSelect extends Component {
    constructor(props) {
        super(props)

        this.state = {
            selected: [],
        }
    }

    componentDidMount() {
        const {selected} = this.props

        if (!!selected && selected.length > 0) {
            this.setState({
                selected: [...selected],
            }) 
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const {selected} = this.props

        if (prevProps.selected !== selected) {
            this.setState({
                selected: [...selected],
            })
        }
    }


    _onFilter = () => {
        const {onChangeFilters, name} = this.props

        if (typeof onChangeFilters === 'function') {
            onChangeFilters(name, this.state.selected)

            this.setState({
                openPopup: false
            })
        }
    }

    
    _handleChangeInput = (e) => {
        const {selected,} = this.state
        const inputValue = e[e.length-1]
        const reg = /^-?\d*(\.\d*)?$/
        if((reg.test(inputValue) && inputValue !== "-") || inputValue === "" || e.length<=0){

            this.setState({
                selected: e
            })
            console.log(e, selected, inputValue)
            setTimeout(() => {
                this._onFilter()
            }, 300);
        } return
    }

    render() {
        const {label, listFilters, placeholder, loading} = this.props
        const {selected} = this.state
        const {Option} = Select
        const OptionNumber = listFilters.map(item=> {return <Option key={item.value}>{item.value}</Option>})

        return (
            <div className='CustomMultiSelect'>
                <div className="font-weight-500 LabelFilter mb-1">{label}</div>
                <div className='px-0 py-0 border-0' 
                     onClick={loading===false? this._openPopup: null} 
                     style={{cursor:(loading!==false? "not-allowed": "pointer")}}
                >
                <Select 
                    className=' w-100'
                    mode="tags"
                    placeholder={placeholder}
                    style={{cursor:(loading!==false? "not-allowed": "pointer")}}
                    value={selected}
                    onChange={this._handleChangeInput}
                    allowClear
                >
                    {OptionNumber}
                </Select>
                </div>
            </div>
        )
    }
}

CustomInputMultiSelect.propTypes = {
    label: PropTypes.string.isRequired,
    listFilters: PropTypes.array.isRequired,
    placeholder: PropTypes.string.isRequired,
    onChangeFilters: PropTypes.func.isRequired,
    selected: PropTypes.any,
    name: PropTypes.string
}

export default CustomInputMultiSelect
