import React from 'react'
import {Link} from "react-router-dom"
import ColumnDate from './ColumnDate'
import {ReactComponent as IconEdit} from '../../../../statics/assets/images/edit-pen.svg'

const TableRow = (props) => {
    const {item, permissionsData} = props

    return (
        <tr>
            <td className="DiscountName">{item.name || ''}</td>
            <td>{item.type || ''}</td>
            <td className="text-right">{item.type === 'fixed' && '$'}{item.amount || 0}{item.type === 'percentage' && '%'}</td>
            <td>
                {
                    !!item.namespace && item.namespace.length > 0 && item.namespace.map(item =>
                        <span key={item} className="mr-2">{item}</span>
                    )
                }
            </td>
            <td>
                {item.started_at && (
                    <span className="d-block">From: <ColumnDate dateTime={item.started_at || ''}/></span>
                )}
                {item.finished_at && (
                    <span className="d-block">To: <ColumnDate dateTime={item.finished_at || ''}/></span>
                )}
            </td>
            <td><ColumnDate dateTime={item.created_at || ''}/></td>
            <td><ColumnDate dateTime={item.updated_at || ''}/></td>
            <td>{item.active ? <span className="badge badge-success">Active</span> :
                <span className="badge badge-secondary">Inactive</span>}</td>
            <td className="text-center"><Link to={`/a/discounts/${item._id}`}>
                {permissionsData.includes('ffm_fulfillment_discounts_create') ?
                    <IconEdit width={20} height={20}/> : 'Details'
                }
            </Link>
            </td>
        </tr>
    )
}

export default TableRow
