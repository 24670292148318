import React, {Component} from 'react'
import PropTypes from 'prop-types'
import {Input} from 'antd'

class ProductTypes extends Component {
    state = {
        types: this.props.types,
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.types !== this.props.types) {
            this.setState({
                types: this.props.types,
            })
        }
    }

    _handleChangeInput = e => {
        const {value} = e.target

        this.setState({
            types: value,
        })
    }

    _handleFormSubmit = value => {
        this.props.onChangeFilterState({'types': value})
    }

    render() {
        const {types} = this.state

        return (
            <div className="ProductTypes mr-3 mb-3">
                <div className="font-weight-500 LabelFilter mb-1">Product types:</div>

                <Input.Search value={types} placeholder='Enter to search...' onChange={this._handleChangeInput}
                              onSearch={this._handleFormSubmit} style={{width: 220}} allowClear/>
            </div>
        )
    }
}

ProductTypes.propTypes = {
    onChangeFilterState: PropTypes.func.isRequired,
    types: PropTypes.string.isRequired,
}

export default ProductTypes
