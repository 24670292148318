import React from 'react'
import PropTypes from 'prop-types'

CategoriesSearch.propTypes = {
    search: PropTypes.string,
    handleChangeState: PropTypes.func.isRequired
}

function CategoriesSearch(props) {
    const {search, handleChangeState} = props

    const _handleChangeInputSearch = (e) => {
        const {value} = e.target

        if (typeof handleChangeState === 'function') {
            handleChangeState(value)
        }
    }

    return (
        <div className='CategoriesSearch'>
            <form action="">
                <input
                    type="text"
                    className="form-control"
                    value={search || ""}
                    placeholder="Search for title..."
                    onChange={_handleChangeInputSearch}
                />
            </form>
        </div>
    )
}

export default CategoriesSearch
