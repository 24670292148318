import React, {Component} from 'react'
import PropTypes from 'prop-types'
import ReactCountryFlag from 'react-country-flag'
import {getOrderFulfilledItems} from '../../../services/api/OrdersServices'
import ShippingLabelActions from './ShippingLabelActions'
import StatusStyle from '../../shared/StatusStyle'
import {Link} from 'react-router-dom'
import {Popover} from 'antd'
import OrderDimension from "./OrderDimension"

class ShippingLabelDetails extends Component {
    state = {
        items: [],
        error: '',
    }

    componentDidMount() {
        this._fetchFulfilledItems()
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.order._id !== this.props.order._id) {
            this._fetchFulfilledItems()
        }
    }

    _fetchFulfilledItems = async () => {
        try {
            const {order} = this.props
            const {data, success, message} = await getOrderFulfilledItems(order._id)

            if (!success) throw new Error(message)

            const items = data.reduce((acc, pack) => {
                return [...acc, ...pack.items]
            }, [])

            this.setState({
                items: items,
                error: '',
            })
        } catch (e) {
            this.setState({
                error: e.message,
            })
        }
    }

    _getAddress = (addressObj) => {
        const {address, full_name, country_code, country, city, state, postal_code, phone, email, is_checked, is_valid, error_message} = addressObj
        const errorMessage = <div style={{width: 300}}>{error_message}</div>

        return (
            <div className='ShippingAddress p-3'>
                <div className='d-flex'>
                    <div className='flex-grow-1'>
                        {
                            !!full_name &&
                            <div className='font-weight-bold mb-2'>{full_name}</div>
                        }
                        {
                            !!address &&
                            <div className='mb-2'>{address}</div>
                        }
                        <div
                            className='mb-2'>{city}{!!state && ` - ${state}`}{!!postal_code && ` - ${postal_code}`}</div>
                        {
                            !!country_code &&
                            <div className='d-flex align-items-baseline mb-2'>
                                <span className='mr-3'><ReactCountryFlag code={country_code} svg/></span>
                                <span>{country}</span>
                            </div>
                        }
                        {
                            !!phone &&
                            <div className='mb-2'>
                                <i className='fas fa-phone mr-3'/>{phone}
                            </div>
                        }
                        {
                            !!email &&
                            <div><i className='fa fa-envelope mr-3'/>{email}</div>
                        }
                    </div>

                    {
                        is_checked &&
                        <div className='AddressStatus ml-4'>
                            {
                                is_valid
                                    ?
                                    <i className="fas fa-check-circle text-success"/>
                                    :
                                    <Popover placement='bottom' content={errorMessage} trigger='hover'>
                                        <i className="fas fa-exclamation-circle text-danger"/>
                                    </Popover>
                            }
                        </div>
                    }
                </div>
            </div>
        )
    }

    render() {
        const {order, onChangeDimension} = this.props
        const {id: code, fulfillment_status, _id} = order
        const address = this._getAddress(order.address)
        const {items} = this.state

        return (
            <div className='ShippingLabelDetails'>
                <OrderDimension onChangeDimension={onChangeDimension} order={order}/>

                <div className='row mb-3'>
                    <div className='col-4'>Code:</div>
                    <div className='col-8'><Link to={`/a/orders/${_id}`}>#{code}</Link></div>
                </div>

                <div className='row align-items-baseline mb-3'>
                    <div className='col-4'>Address:</div>
                    <div className='col-8'>{address}</div>
                </div>

                <div className='row mb-3'>
                    <div className='col-4'>Fulfillment status:</div>
                    <div className='col-8'><StatusStyle status={fulfillment_status}/></div>
                </div>

                {
                    items.length > 0 &&
                    <>
                        <div className='row mb-3'>
                            <div className='col-4'>Quantity:</div>
                            <div className='col-8'>{items.length}</div>
                        </div>

                        <div className='row Items'>
                            <div className='col-4'>Items:</div>
                            <div className='col-8'>
                                {
                                    items.length > 0 &&
                                    <ul>
                                        {
                                            items.map((item, index) => {
                                                return <li
                                                    key={`${item._id}_${index}`}>{item.product.title} x {item.quantity}</li>
                                            })
                                        }
                                    </ul>
                                }
                            </div>
                        </div>
                    </>
                }

                <div className='mt-3'>
                    <ShippingLabelActions {...this.props}/>
                </div>
            </div>
        )
    }
}

ShippingLabelDetails.propTypes = {
    order: PropTypes.object.isRequired,
    onChangeDimension: PropTypes.func.isRequired,
}

export default ShippingLabelDetails
