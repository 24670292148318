import React, { useState, useEffect } from 'react'
import SuppliersSelector from '../../components/SuppliersSelector'
import ShippingPlansSelector from '../../components/ShippingPlansSelector'
import { getListShippingZones } from "../../../../services/api/ShippingServices"

import { Checkbox, Input, Select } from 'antd'

const GeneralPage = (props) => {

  const {
    zone, shipping_plan, supplier,
    handleChangeSupplier, store,
    handleChangeShippingPlan,
    handleChangeZone,
    handleCheckAllStore,
    allStore,
    handleChangeAddStore,
  } = props

  const [list_zone, setList_zone] = useState([])
  const [err, setErr] = useState('')
  const [message, setMessage] = useState('')
  const [searchStore, setSearchStore] = useState('')

  const onChange = (e) => {
    handleCheckAllStore()
  }

  const onChangeStore = (e) => {
    // handleChangeAddStore(e.target.value)
    setSearchStore('')
    handleChangeAddStore(e)
  }

  const _handleChange = option => {
    const _selectedZone = (option !== undefined) ? option : ''
    handleChangeZone(_selectedZone)
  }

  useEffect(() => {
    const _fetchListZone = async () => {
      try {
        const { success, data, message } = await getListShippingZones()
        if (!success) {
          setMessage(message)
        }
        setList_zone(
          () => (
            data.map((item, index) => {
              return {
                id: item._id,
                value: index,
                label: item.title,
                supplier: item.supplier
              }
            })
          )
        )
      } catch (e) {
        setErr(e.message || e)
      }
    }

    _fetchListZone()
  }, [])

  const newZone = !!supplier ? list_zone.filter(i => i.supplier === supplier) : list_zone

  const handleSearch = (field, value) => {
    if (value && (value.includes(',') || value.includes(' '))) {
      if (field === 'Store') {
        const newValue = [...new Set([...value.replace(/ /g, ',').split(',').map(i => i.trim()).filter(i => i), ...store])]
        handleChangeAddStore(newValue)
        setSearchStore('')
      }
    } else {
      if (field === 'Store') {
        return setSearchStore(value)
      }
    }
  }

  return (
    <div className="GeneralPage py-4 px-5">
      <div className="General_Select">
        <div className="itemFilter mb-3">
          <div className="font-weight-500 LabelFilter mb-1">Supplier<span className="text-danger ml-1">*</span></div>
          <SuppliersSelector
            supplier={supplier}
            handleSelectSupplier={handleChangeSupplier}
            width={'350px'}
            placeholder="Choose supplier"
          />
        </div>

        <div className="itemFilter mb-3">
          <div className="font-weight-500 LabelFilter mb-1">Shipping plan<span className="text-danger ml-1">*</span></div>
          <ShippingPlansSelector
            shippingPlan={shipping_plan}
            handleChangeShippingPlan={handleChangeShippingPlan}
            width={'350px'}
            placeholder="Choose shipping plan"
          />
        </div>

        <div className="itemFilter mb-3">
          <div className="font-weight-500 LabelFilter mb-1">Zone<span className="text-danger ml-1">*</span></div>
          <div className="ZonesSelector w-auto">
            <Select
              size={'large'}
              allowClear
              showArrow
              defaultValue={zone}
              value={zone || []}
              style={{ width: '350px' }}
              onChange={_handleChange}
              placeholder="Choose zone"
            >
              {
                newZone.length && newZone.map(item => {
                  return <Select.Option value={item.id} key={item.id}>{item.label}</Select.Option>
                })
              }
            </Select>
            {err && (
              <p className='text-danger'>{err}</p>
            )}
            {message &&
              <p className='text-primary'>{message}</p>
            }
          </div>
        </div>
      </div>

      <div className="General_store mt-5">
        <div className="Store_all d-flex flex-column">
          <span className="font-weight-bold">Choose All or input value</span>
          <Checkbox className="py-3 w-25" checked={allStore} onChange={onChange}>All store</Checkbox>
        </div>
        <div className="Create_store">
          <Input.Group compact >
            {/* <Input style={{ width: 350 }}
              placeholder="Store name"
              disabled={allStore}
              value={!!store ? store : ''}
              onChange={onChangeStore}
              className="py-2 rounded"
            /> */}
            <Select
              style={{ width: '350px' }}
              placeholder="Store name"
              disabled={allStore}
              value={!!store ? store : []}
              onChange={onChangeStore}
              onSearch={(e) => handleSearch('Store', e)}
              className="py-2 rounded"
              allowClear
              showArrow
              mode={'tags'}
              searchValue={searchStore}
            />
          </Input.Group>
        </div>

      </div>
    </div>
  )
}

export default GeneralPage