import React from 'react'
import PropTypes from 'prop-types'
import SwitchCarrierItem from "./SwitchCarrierItem"

SwitchCarrierBody.propTypes = {
    listRequest: PropTypes.array,
    error: PropTypes.string
}

function SwitchCarrierBody(props) {
    const {listRequest, error} = props

    return (
        <tbody className='RequestBody'>
        {
            !!listRequest && listRequest.length > 0 ? listRequest.map((item) => (
                    <SwitchCarrierItem key={item._id} item={item}/>
                )) :
                <tr>
                    <td colSpan={6}>No data</td>
                </tr>
        }

        {
            !!error && error.length > 0 && <tr>
                <td colSpan={6} className="text-danger">{error}</td>
            </tr>
        }
        </tbody>
    )
}

export default SwitchCarrierBody
